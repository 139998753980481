import { Dayjs } from "lib/dayjs";
import Decimal from "decimal.js";
import { Override } from "@metronome-industries/schedule-utils";

export type DescribeFragment =
  import("./fragments.graphql").Override__DescribeFragment;

const valuesToRecordOfStrings = (
  values?: Array<{ name: string; value: string | null }> | undefined | null,
): Record<string, string> | undefined => {
  if (!values?.length) {
    return undefined;
  }

  return Object.fromEntries(
    values
      .filter(
        (entry): entry is { name: string; value: string } =>
          entry.value != null,
      )
      .map(({ name, value }) => [name, value]),
  );
};

function describeAppliesTo(
  now: Dayjs,
  override: DescribeFragment,
): Override.AppliesToDescription | null {
  return override.override_specifiers?.map((specifier) => ({
    productId: specifier.product_id ?? undefined,
    productTags: specifier.product_tags ?? undefined,
    pricingGroupValues: valuesToRecordOfStrings(specifier.pricing_group_values),
    presentationGroupValues: valuesToRecordOfStrings(
      specifier.presentation_group_values,
    ),
    commitIds: specifier.commit_ids ?? undefined,
  }));
}

function describeRateChange(
  override: DescribeFragment,
): Override.Change.Rate | null {
  if (!override.change) {
    return null;
  }

  switch (override.change.__typename) {
    case "OverwriteOverride":
      switch (override.change.new_rate.__typename) {
        case "TieredRate":
          return {
            type: "tiered_overwrite",
            tiers: override.change.new_rate.tiers.map((tier) => ({
              size: tier.size ? new Decimal(tier.size) : undefined,
              unitPrice: new Decimal(tier.unit_price),
            })),
          };
        case "FlatRate":
          return {
            type: "flat_overwrite",
            unitPrice: new Decimal(override.change.new_rate.unit_price),
          };

        case "SubscriptionRate":
          return {
            type: "subscription_overwrite",
            isProrated: override.change.new_rate.is_prorated,
            quantity: new Decimal(override.change.new_rate.quantity),
            unitPrice: new Decimal(override.change.new_rate.unit_price),
          };

        case "PercentageRate":
          return {
            type: "percentage_overwrite",
            fraction: new Decimal(override.change.new_rate.fraction),
            useListPrices: override.change.new_rate.use_list_prices,
          };

        case "CustomRate":
          return {
            type: "custom_overwrite",
            value: override.change.new_rate.value,
          };
        default:
          override.change.new_rate satisfies never;
          throw new Error("every RateCardEntryOverride type must be handled");
      }
    case "MultiplierOverride":
      return {
        type: "multiplier",
        multiplier: new Decimal(override.change.multiplier),
        priority:
          override.change.priority != null
            ? new Decimal(override.change.priority)
            : null,
      };
    case "TieredOverride":
      return {
        type: "tiered_override",
        tiers: override.change.tiers.map((t) => ({
          multiplier: new Decimal(t.multiplier),
          size: t.size != null ? new Decimal(t.size) : undefined,
        })),
        priority: new Decimal(override.change.tiered_override_priority),
      };
    default:
      override.change satisfies never;
      throw new Error(
        "every RateCardEntryOverride type must be handled explicitly",
      );
  }
}

export function describeOverride(
  now: Dayjs,
  fragment: DescribeFragment,
): Override.Description {
  const rateChange = describeRateChange(fragment);
  const entitlementChange: Override.Change.Entitlement | null =
    fragment.entitled == null
      ? null
      : {
          type: "entitlement",
          entitled: fragment.entitled,
        };

  return new Override.Description({
    id: fragment.id,
    startingAt: new Date(fragment.starting_at),
    endingBefore: fragment.ending_before
      ? new Date(fragment.ending_before)
      : null,
    appliesTo: describeAppliesTo(now, fragment),
    changes: [entitlementChange ?? [], rateChange ?? []].flat(),
    target: fragment.override_target,
    type: fragment.type,
  });
}
