import { useSearchParams } from "react-router-dom";

function get(params: URLSearchParams, key: string) {
  return params.get(key) ?? "";
}

/**
 * Given a url param key, returns the existing param value and a
 * helper function to set a new value while preserving state
 * of other existing params (if any).
 */
export const useSearchParam = (
  paramKey: string,
): [
  string,
  (paramValue: string | null | ((value: string) => string | null)) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const param = get(searchParams, paramKey);

  return [
    param,
    (value) =>
      setSearchParams(
        (prev) => {
          const next = new URLSearchParams(prev);
          const nextValue =
            typeof value === "function" ? value(get(prev, paramKey)) : value;

          if (!nextValue) {
            next.delete(paramKey);
          } else {
            next.set(paramKey, nextValue);
          }

          return next;
        },
        {
          replace: true,
        },
      ),
  ];
};
