import React, { useState } from "react";
import { DraftPlan } from "lib/plans/types";
import styles from "../../index.module.less";
import { toWordsOrdinal } from "number-to-words";
import { Body, Headline } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { NumericInput } from "design-system";
import { useDraftPlan } from "../../../../context";
import { InputSection, WizardSplitPage } from "components/Wizard";
import { TermPreview } from "../TermPreview";
import { getUtcStartOfDay } from "lib/time";
import { addYears } from "date-fns";
import { addRamp, removeRamp, changeRamp, clearRamps } from "./changeRamps";
import { getRampStartPeriods } from "lib/plans/ramps";

export const isDefinePricingRampsDone = (plan: DraftPlan) => {
  if (!plan.hasPriceRamps) {
    // If hasPriceRamps is falsy then we're good. Either it's not set yet (at which point this step shouldn't exist), or it's set to false
    // at which point this step shouldn't exist.
    return true;
  }

  // For every ramp assert that durationInPeriods is not undefined, greater than zero, and an integer
  return (plan.ramps || []).every(
    (ramp) =>
      ramp.durationInPeriods && Number.isInteger(ramp.durationInPeriods),
  );
};

interface PricingRampInputProps {
  onChange: (value: number | null) => void;
  onRemove: () => void;
  durationInPeriods?: number;
  rampIndex: number;
}
const PricingRampInput: React.FC<PricingRampInputProps> = (props) => {
  let errorMsg = "";
  if (props.durationInPeriods !== undefined) {
    if (!Number.isInteger(props.durationInPeriods)) {
      errorMsg = "Ramp lengths must be whole numbers";
    } else if (props.durationInPeriods <= 0) {
      errorMsg = "A pricing ramp must last for at least 1 billing period";
    }
  }

  return (
    <div className={styles.rampContainer}>
      <NumericInput
        name={
          "Length of " + toWordsOrdinal(props.rampIndex + 1) + " pricing ramp"
        }
        placeholder="Number of billing periods"
        value={props.durationInPeriods}
        onChange={props.onChange}
        error={errorMsg}
        success={
          !!props.durationInPeriods && Number.isInteger(props.durationInPeriods)
        }
        className={styles.rampInput}
      />
      <Body className={styles.inputUnit}>billing periods</Body>
      {props.rampIndex > 0 && (
        <IconButton
          onClick={props.onRemove}
          theme="tertiary"
          icon="xCircle"
          className="mb-[-4px]"
        />
      )}
    </div>
  );
};

export const PricingRampPage: React.FC = () => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const [tempRamp, setTempRamp] = useState<number>();
  const ramps =
    draftPlan.ramps && draftPlan.ramps.length ? draftPlan.ramps : [{}];
  const rampStarts = getRampStartPeriods(ramps);

  const rampStartsWithTemp = new Set(rampStarts);

  if (tempRamp !== undefined) {
    rampStartsWithTemp.add(tempRamp);
  }
  const previewRamps = Array.from(rampStartsWithTemp)
    .sort((a, b) => (a < b ? -1 : 1))
    .map((rampStart) => ({
      rampStart,
      temp: tempRamp === rampStart,
    }));

  return (
    <WizardSplitPage
      preview={
        <TermPreview
          billingFrequency={draftPlan.billingFrequency}
          billingAnchor={draftPlan.billingDayOfPeriod}
          ramps={previewRamps}
          startDate={
            draftPlan.hasCustomer
              ? draftPlan.customerPlanInfo?.startDate
              : new Date()
          }
          endDate={
            draftPlan.hasCustomer
              ? draftPlan.customerPlanInfo?.endDate
              : getUtcStartOfDay(
                  addYears(
                    new Date(),
                    draftPlan.defaultLength ? draftPlan.defaultLength / 12 : 1,
                  ),
                )
          }
          trialLength={draftPlan.trialSpec?.length}
          draft={!draftPlan.hasCustomer}
          planName={draftPlan.name ?? ""}
          planDescription={draftPlan.description ?? ""}
          hasCustomer={draftPlan.hasCustomer ?? false}
          customerName={draftPlan.customerPlanInfo?.customerName}
          onMouseEnter={(periodIndex: number) => {
            if (periodIndex >= rampStarts[rampStarts.length - 1]) {
              setTempRamp(periodIndex + 1);
            }
          }}
          onMouseLeave={() => {
            setTempRamp(undefined);
          }}
          onClick={(periodIndex: number) => {
            setTempRamp(undefined);
            if (periodIndex >= rampStarts[rampStarts.length - 1]) {
              setDraftPlan(addRamp(draftPlan, periodIndex + 1));
            }
          }}
          onResetRamps={() => {
            setDraftPlan(clearRamps(draftPlan));
          }}
        />
      }
    >
      <InputSection
        header="Add the length of each pricing ramp"
        subtitle={`Pricing ramps are based on the billing frequency of your plan, and designed for a set number of billing periods. Assume that once all ramps are complete, the plan will have regular pricing. Your ramps will be visualized in the ${
          draftPlan.hasCustomer ? "" : "example "
        }plan life cycle.`}
      >
        <Headline level={6}>
          Enter ramp length or use the interactive plan life cycle
        </Headline>
        {ramps.map((ramp, i) => {
          return (
            <PricingRampInput
              key={i}
              rampIndex={i}
              durationInPeriods={ramp.durationInPeriods}
              onRemove={() => {
                setDraftPlan(removeRamp(draftPlan, i));
              }}
              onChange={(v) => {
                if (v !== null) {
                  setDraftPlan(changeRamp(draftPlan, i, v));
                }
              }}
            />
          );
        })}
        <Button
          onClick={() => {
            setDraftPlan(addRamp(draftPlan));
          }}
          text="Add another ramp"
          theme="linkGray"
          leadingIcon="plus"
        />
      </InputSection>
    </WizardSplitPage>
  );
};
