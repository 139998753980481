import React from "react";
import { Popup } from "components/Popup";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { useArchiveCustomerMutation } from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";
import { reportToSentry } from "lib/errors/sentry";

interface ArchiveCustomerModalProps {
  onClose: () => void;
  customerId: string;
  customerName: string;
}

const ArchiveCustomerModal: React.FC<ArchiveCustomerModalProps> = ({
  onClose,
  customerId,
  customerName,
}) => {
  const [archiveCustomer, archiveCustomerResults] = useArchiveCustomerMutation({
    update: (cache) => {
      cache.evict({
        fieldName: "Customer",
      });
    },
  });
  const pushMessage = useSnackbar();

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            loading={archiveCustomerResults.loading}
            disabled={archiveCustomerResults.loading}
            onClick={async () => {
              try {
                await archiveCustomer({
                  variables: {
                    customer_id: customerId,
                  },
                });
                pushMessage({
                  content: `Customer "${customerName}" archived`,
                  type: "success",
                });
              } catch (e: any) {
                reportToSentry(e);
                pushMessage({
                  content: `Failed to archive customer: ${e.message}`,
                  type: "error",
                });
              }
              onClose();
            }}
            text="Archive customer"
            theme="linkDestructive"
          />
        </>
      }
      isOpen
      onRequestClose={onClose}
      title="Are you sure you want to archive this customer?"
    >
      <Body level={2}>
        Are you sure you want to archive the <strong>{customerName}</strong>{" "}
        customer? Once a customer is archived it can no longer be used, and will
        no longer appear on the Customer list by default.
      </Body>
    </Popup>
  );
};

export default ArchiveCustomerModal;
