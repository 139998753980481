import React from "react";
import { Button } from "tenaissance/components/Button";
import { Column, Table } from "tenaissance/components/Table";
import { Tooltip } from "tenaissance/components/Tooltip";
import { Timestamp } from "tenaissance/components/Timestamp";
import { CopyableID } from "tenaissance/components/CopyableID";
import { twMerge } from "tenaissance/twMerge";

interface RowButtonOptions {
  isSettingsDisabled?: boolean;
  isEditMappingDisabled?: boolean;
  editMappingButtonOnClick?: () => void;
  settingsButtonOnClick?: () => void;
  toolTipText?: string;
}

export interface IntegrationRow {
  name: string;
  integrationDisplayDetails: React.ReactNode;
  ids?: {
    tooltip: string;
    id: string;
  }[];
  dateConnected?: Date;
  rowButtonOptions?: RowButtonOptions;
  rowRoutePath?: string;
}

interface IntegrationRowWithId extends IntegrationRow {
  id: string;
}

const SettingsButton = ({
  isSettingsDisabled,
  settingsButtonOnClick,
}: Pick<RowButtonOptions, "isSettingsDisabled" | "settingsButtonOnClick">) => (
  <Button
    text=""
    theme="tertiary"
    leadingIcon="settings01"
    disabled={isSettingsDisabled || false}
    onClick={settingsButtonOnClick}
  />
);

const RowActionButtons = ({
  isEditMappingDisabled,
  editMappingButtonOnClick,
  toolTipText,
  isSettingsDisabled,
  settingsButtonOnClick,
}: RowButtonOptions) => (
  <div className="flex items-center">
    <Button
      text="Edit mapping"
      theme="tertiary"
      disabled={isEditMappingDisabled || false}
      onClick={editMappingButtonOnClick}
    />
    {toolTipText ? (
      <Tooltip label={toolTipText}>
        <SettingsButton
          isSettingsDisabled={isSettingsDisabled}
          settingsButtonOnClick={settingsButtonOnClick}
        />
      </Tooltip>
    ) : (
      <SettingsButton
        isSettingsDisabled={isSettingsDisabled}
        settingsButtonOnClick={settingsButtonOnClick}
      />
    )}
  </div>
);

const tableColumns: Column<IntegrationRowWithId>[] = [
  {
    id: "1",
    accessorKey: "integrationDisplayDetails",
    header: "Integration",
    cell: (props: { getValue: () => React.ReactElement }) => {
      return props.getValue();
    },
    tooltipContent: "Integrations connected to this account",
    enableSorting: false,
  },
  {
    id: "2",
    accessorKey: "ids",
    header: "IDs",
    size: 450,
    cell: (props: {
      getValue: () => { id: string; tooltip: string }[] | undefined;
    }) => {
      const ids = props.getValue();
      if (!ids) return null;
      return (
        <div className="flex flex-col">
          {ids.map(({ id, tooltip }, idx: number) => (
            <Tooltip label={tooltip}>
              <CopyableID
                id={id}
                className={twMerge(
                  "text-core-slate text-xs font-normal leading-[18px]",
                  idx % 2 === 0 && ids.length > 1 ? "mb-xs" : "",
                )}
              />
            </Tooltip>
          ))}
        </div>
      );
    },
    tooltipContent: "Account specific IDs",
    enableSorting: false,
  },
  {
    id: "3",
    size: 350,
    accessorKey: "dateConnected",
    header: "Date connected (UTC)",
    cell: (props: { getValue: () => Date | undefined }) => {
      const dateConnected = props.getValue();
      if (!dateConnected) return null;
      return (
        <div>
          <Timestamp dateTime={dateConnected} />
        </div>
      );
    },
    tooltipContent: "Date this integration was connected to Metronome",
    enableSorting: false,
  },
  {
    id: "4",
    accessorKey: "rowButtonOptions",
    header: "",
    cell: (props: { getValue: () => RowButtonOptions }) => {
      const {
        isEditMappingDisabled,
        isSettingsDisabled,
        editMappingButtonOnClick,
        settingsButtonOnClick,
        toolTipText,
      } = props.getValue();
      return (
        <RowActionButtons
          isEditMappingDisabled={isEditMappingDisabled}
          isSettingsDisabled={isSettingsDisabled}
          editMappingButtonOnClick={editMappingButtonOnClick}
          settingsButtonOnClick={settingsButtonOnClick}
          toolTipText={toolTipText}
        />
      );
    },
  },
];

interface IntegrationTableProps {
  rows: IntegrationRow[];
  loading: boolean;
}

export const IntegrationsTable = ({ rows, loading }: IntegrationTableProps) => {
  const rowsWithId = rows.map((row, index) => ({
    ...row,
    id: index.toString(),
  }));
  return (
    <Table
      title="Active integrations"
      columns={tableColumns}
      loading={loading}
      data={rowsWithId}
      rowRoutePath={(row) => {
        return row.original?.rowRoutePath ?? undefined;
      }}
    />
  );
};
