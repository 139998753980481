import Decimal from "decimal.js";
import { PricedPricingFactor } from "lib/plans/types";

import { getRecurrenceText } from "lib/time";
import {
  BillingFrequencyEnum_Enum,
  ChargeTypeEnum_Enum,
  CompositeChargeTypeEnum_Enum,
  TieringModeEnum_Enum,
} from "types/generated-graphql/__types__";
import { FlatFee, PlanPricedProductPricingFactor, Tier } from "./index";

/**
 * Returns charge type description according to the Gnomenclature doc:
 * https://www.notion.so/teammetronome/Gnomenclature-Glossary-b7ecf1bbfb7449a0bbb368ba44fbc43c#e3e17233af544f6a9ff4788152126591
 */
export function getRowType(fee: PricedPricingFactor): string;
export function getRowType(fee: PlanPricedProductPricingFactor): string;
export function getRowType(
  fee: PricedPricingFactor | PlanPricedProductPricingFactor,
): string {
  let chargeType =
    "ProductPricingFactor" in fee
      ? fee.ProductPricingFactor.charge_type_enum
      : fee.chargeType;
  const flatFee = "FlatFees" in fee ? fee.FlatFees?.[0] : fee.flatFees?.[0];
  const seatPrice =
    "SeatPrices" in fee ? fee.SeatPrices?.[0] : fee.seatPrices?.[0];
  const prices = "Prices" in fee ? fee.Prices : fee.prices;
  const volumePricing =
    "tiering_mode" in fee
      ? fee.tiering_mode === TieringModeEnum_Enum.Volume
      : fee.volumePricing;

  // This is to handle draft plans that do not have charge type set
  if (!chargeType) {
    if (flatFee) {
      chargeType = ChargeTypeEnum_Enum.Flat;
    } else {
      chargeType = ChargeTypeEnum_Enum.Usage;
    }
  }

  switch (chargeType) {
    case ChargeTypeEnum_Enum.Usage:
      return `${
        volumePricing
          ? "Volume usage-based"
          : prices && prices?.length > 1
            ? "Tiered usage-based"
            : "Usage-based"
      }`;

    case ChargeTypeEnum_Enum.Seat:
      return `Usage-based seat${
        seatPrice?.is_prorated ? " (prorate if applicable)" : ""
      }`;

    case ChargeTypeEnum_Enum.Composite:
      const compositeCharges =
        "CompositeCharges" in fee ? fee.CompositeCharges : fee.compositeCharge;
      const isTiered = compositeCharges?.length && compositeCharges.length > 1;
      const isMinimum =
        compositeCharges &&
        compositeCharges.length &&
        compositeCharges[0].type === CompositeChargeTypeEnum_Enum.Minimum;
      return isMinimum
        ? "Minimum"
        : isTiered
          ? "Tiered composite charge"
          : "Composite charge";
    case ChargeTypeEnum_Enum.Flat:
      if (!flatFee) {
        return "Fixed recurring";
      } else {
        const [isOneTimeAdvance, isAdvance] =
          "collection_schedule" in flatFee
            ? [
                flatFee.collection_schedule === "ONE_TIME_ADVANCE",
                flatFee.collection_schedule === "ADVANCE",
              ]
            : [
                flatFee.collectionSchedule === "ONE_TIME_ADVANCE",
                flatFee.collectionSchedule === "ADVANCE",
              ];
        const collectionInterval =
          "collection_interval" in flatFee
            ? flatFee.collection_interval
            : flatFee.collectionInterval;
        const isProrated =
          "is_prorated" in flatFee
            ? !!flatFee.is_prorated
            : !!flatFee.isProrated;
        const isTiered =
          "FlatFees" in fee
            ? fee.FlatFees && fee.FlatFees.length > 1
            : fee.flatFees && fee.flatFees.length > 1;

        if (isOneTimeAdvance) {
          return isTiered
            ? "Tiered advanced charge (one-time charge)"
            : "Advanced charge (one-time charge)";
        } else if (isAdvance) {
          return isTiered
            ? isProrated
              ? `Tiered advanced charge (every ${collectionInterval} billing periods, prorate if applicable)`
              : `Tiered advanced charge (every ${collectionInterval} billing periods)`
            : isProrated
              ? `Advanced charge (every ${collectionInterval} billing periods, prorate if applicable)`
              : `Advanced charge (every ${collectionInterval} billing periods)`;
        } else {
          return isTiered
            ? isProrated
              ? "Tiered fixed recurring (prorate if applicable)"
              : "Tiered fixed recurring"
            : isProrated
              ? "Fixed recurring (prorate if applicable)"
              : "Fixed recurring";
        }
      }
    default:
      throw new Error("Invalid charge");
  }
}

/**
 * Returns null for a tiered fee; otherwise returns the fee's price.
 */
export function getRowRate(
  pppf: PlanPricedProductPricingFactor,
): string | null {
  if (pppf.Prices.length === 1) {
    return pppf.Prices[0].value;
  } else if (pppf.SeatPrices && pppf.SeatPrices.length === 1) {
    return pppf.SeatPrices[0].value;
  } else if (pppf.FlatFees && pppf.FlatFees.length === 1) {
    return pppf.FlatFees[0].value;
  } else if (pppf.CompositeCharges && pppf.CompositeCharges.length === 1) {
    if (pppf.CompositeCharges[0]?.CompositeChargeTiers?.[0]?.value) {
      return pppf.CompositeCharges[0].CompositeChargeTiers[0].value;
    }
  }

  return null;
}

/**
 * Returns undefined for a usage charge; otherwise returns the fee's quantity.
 */
export function getRowQuantity(pppf: PlanPricedProductPricingFactor) {
  switch (pppf.ProductPricingFactor.charge_type_enum) {
    case ChargeTypeEnum_Enum.Flat:
      return pppf.FlatFees?.[0]?.quantity
        ? new Decimal(pppf.FlatFees[0].quantity)
        : undefined;
    case ChargeTypeEnum_Enum.Seat:
      return pppf.SeatPrices?.[0]?.initial_quantity
        ? new Decimal(pppf.SeatPrices[0].initial_quantity)
        : undefined;
    case ChargeTypeEnum_Enum.Composite:
      return pppf.CompositeCharges?.[0]?.quantity !== undefined
        ? new Decimal(pppf.CompositeCharges[0].quantity)
        : undefined;
    case ChargeTypeEnum_Enum.Usage:
      return undefined;
  }
}

/**
 * Returns undefined for a usage charge; otherwise returns the fee's quantity.
 */
export function getRowProrated(pppf: PlanPricedProductPricingFactor) {
  switch (pppf.ProductPricingFactor.charge_type_enum) {
    case ChargeTypeEnum_Enum.Flat:
      return !!pppf.FlatFees?.[0]?.is_prorated;
    case ChargeTypeEnum_Enum.Seat:
      return !!pppf.SeatPrices?.[0]?.is_prorated;
  }

  return false;
}

/**
 * Returns charge name, including "recurs every n billing periods", if applicable.
 */
export function getRowName(
  pppf: PlanPricedProductPricingFactor,
  billingFrequency: BillingFrequencyEnum_Enum,
): string {
  const flatFee = pppf.FlatFees?.[0];
  return flatFee?.collection_schedule === "ADVANCE"
    ? `${pppf.ProductPricingFactor.name} - ${getRecurrenceText(
        flatFee?.collection_interval ?? 1,
        billingFrequency,
      )}`
    : pppf.ProductPricingFactor.name;
}

/**
 * Returns null for a non-tiered fee; otherwise returns the fee's tiers.
 * For tiered flat fees, this function also computes the quantity for
 * each tier (note: this may need to change if we start accepting non-integer
 * values for `metric_minimum`).
 */
export function getRowTiers(
  pppf: PlanPricedProductPricingFactor,
): Tier[] | null {
  // usage tiers
  if (pppf.Prices.length > 1) {
    return pppf.Prices;
  }

  if ((pppf.SeatPrices?.length ?? 0) > 1) {
    return pppf.SeatPrices;
  }

  // no flat fee OR flat fee without tiers
  if (!pppf.FlatFees || pppf.FlatFees?.length <= 1) {
    return null;
  }
  // tiered flat fee
  const flatFees: FlatFee[] = pppf.FlatFees;
  const qty = new Decimal(pppf.FlatFees?.[0]?.quantity);
  return flatFees.map((ff, i): Tier => {
    const min = ff.metric_minimum;
    const remaining = qty.minus(min);
    const nextMin = flatFees[i + 1]?.metric_minimum ?? Infinity;
    const size = new Decimal(nextMin).minus(min);
    return {
      metric_minimum: ff.metric_minimum,
      value: ff.value,
      quantity: Decimal.max(0, Decimal.min(remaining, size)),
    };
  });
}

export function getRowCompositePricingFactors(
  pppf: PlanPricedProductPricingFactor,
): { id: string; name: string }[] | undefined {
  if (pppf.CompositeCharges?.[0]?.CompositeChargePricingFactors?.length) {
    return pppf.CompositeCharges[0].CompositeChargePricingFactors.map(
      (ccpf) => ccpf.ProductPricingFactor,
    );
  }
  return undefined;
}
