/* a64648da3d65d55d337fd578715eb4f15c7081f5
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllAlertsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  search?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AllAlertsQuery = { __typename?: 'Query', alerts: Array<{ __typename?: 'Alert', id: string, name: string, threshold: string, alert_type: Types.AlertTypeEnum_Enum, filters: object | null, customer_count: number, Customer: { __typename?: 'Customer', id: string, name: string } | null, Plan: { __typename?: 'Plan', id: string, name: string } | null, CreditType: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string } | null }> };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const AllAlertsDocument = gql`
    query AllAlerts($environment_type: EnvironmentTypeEnum_enum!, $search: String) {
  alerts(
    environment_type: $environment_type
    types: [ACTIVE]
    search: $search
    limit: 100
  ) {
    id
    name
    threshold
    alert_type
    filters
    Customer {
      id
      name
      __environment_type: environment_type
    }
    Plan {
      id
      name
      __environment_type: environment_type
    }
    CreditType {
      ...CreditType
      __environment_type: environment_type
    }
    BillableMetric {
      id
      name
      __environment_type: environment_type
    }
    customer_count
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useAllAlertsQuery__
 *
 * To run a query within a React component, call `useAllAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAlertsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAllAlertsQuery(baseOptions: Apollo.QueryHookOptions<AllAlertsQuery, AllAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAlertsQuery, AllAlertsQueryVariables>(AllAlertsDocument, options);
      }
export function useAllAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAlertsQuery, AllAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAlertsQuery, AllAlertsQueryVariables>(AllAlertsDocument, options);
        }
export type AllAlertsQueryHookResult = ReturnType<typeof useAllAlertsQuery>;
export type AllAlertsLazyQueryHookResult = ReturnType<typeof useAllAlertsLazyQuery>;
export type AllAlertsQueryResult = Apollo.QueryResult<AllAlertsQuery, AllAlertsQueryVariables>;