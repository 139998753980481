import React from "react";
import { usePricingUnitsQuery } from "pages/GeneralSettings/components/PricingUnitsTab/pricingUnits.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { CreditType } from "types/credit-types";
import { useNavigate } from "lib/useNavigate";
import { useRoutePathMatches } from "lib/routes/useRoutePathMatches";
import CurrenciesTable from "./CurrenciesTable";
import CustomPricingUnitsTable from "./CustomPricingUnitsTable";

export const PricingUnits: React.FC = () => {
  const { environmentType } = useEnvironment();
  const { data, loading } = usePricingUnitsQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const navigate = useNavigate();
  const isCustomPricingUnits = useRoutePathMatches([
    "/offering/pricing-units/custom-pricing-units",
  ]);
  const navigationButtons = [
    {
      text: "Currencies",
      onClick: () => navigate("/offering/pricing-units"),
      isActive: !isCustomPricingUnits,
    },
    {
      text: "Custom pricing units",
      onClick: () => navigate("/offering/pricing-units/custom-pricing-units"),
      isActive: !!isCustomPricingUnits,
    },
  ];

  const creditTypes: CreditType[] = data?.CreditType ?? [];
  const currencies = creditTypes.filter((ct) => ct.client_id === null);

  return isCustomPricingUnits ? (
    <CustomPricingUnitsTable
      loading={loading}
      titleButtons={navigationButtons}
      creditTypes={creditTypes}
    />
  ) : (
    <CurrenciesTable
      loading={loading}
      titleButtons={navigationButtons}
      currencies={currencies}
    />
  );
};
