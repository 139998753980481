import classnames from "classnames";
import { Badge } from "design-system";
import { BillingMetricAggregateEnum_Enum } from "types/generated-graphql/__types__";
import styles from "./index.module.less";
import React from "react";

export const SimpleBadge: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Badge
      theme="primary"
      type="light"
      className={classnames(
        "text-primary-600",
        "bg-primary-50",
        "!font-bold",
        styles.billableMetricBadge,
      )}
      key={text}
    >
      {text}
    </Badge>
  );
};

export const GroupKeyBadge: React.FC<{ fieldName?: Set<string> }> = ({
  fieldName,
}) => {
  if (fieldName) {
    const fieldNameString = [...fieldName]
      .map((fieldNameItem) => `"${fieldNameItem}"`)
      .join(",");
    return (
      <Badge
        theme="primary"
        type="light"
        className={classnames(styles.billableMetricBadge, styles.group, "mr-4")}
        key={fieldNameString}
      >
        {fieldName.size === 1 &&
        fieldName.has("__METRONOME_INTERNAL__EVENT_TYPE")
          ? "event_type"
          : `properties[${fieldNameString}]`}
      </Badge>
    );
  }
  return (
    <Badge
      theme="grey"
      type="light"
      className={classnames(styles.billableMetricBadge, styles.noGroupKey)}
    >
      GROUP_KEY
    </Badge>
  );
};

export const AggregationBadge: React.FC<{
  aggregation?: BillingMetricAggregateEnum_Enum;
  keys?: string[] | null;
}> = ({ aggregation, keys }) => {
  if (!aggregation || (!aggregation && !keys)) {
    return (
      <Badge
        theme="grey"
        type="light"
        className={classnames(styles.billableMetricBadge, styles.noGroupKey)}
      >
        AGGREGATE(properties[property])
      </Badge>
    );
  }

  let badgeClass: string | undefined;
  switch (aggregation) {
    case BillingMetricAggregateEnum_Enum.Sum:
      badgeClass = styles.sum;
      break;
    case BillingMetricAggregateEnum_Enum.Max:
      badgeClass = styles.max;
      break;
    case BillingMetricAggregateEnum_Enum.Unique:
      badgeClass = styles.unique;
      break;
    case BillingMetricAggregateEnum_Enum.Count:
      badgeClass = styles.count;
      break;
  }

  let formattedField = "";
  if (keys?.length) {
    const fieldName = keys[0];
    if (fieldName === "__METRONOME_INTERNAL__EVENT_TYPE") {
      formattedField = "event_type";
    } else {
      formattedField = `properties["${fieldName}"]`;
    }
  }

  const aggregationName =
    aggregation === BillingMetricAggregateEnum_Enum.Latest
      ? "SEAT"
      : aggregation.toUpperCase();
  return (
    <Badge
      theme="grey"
      className={classnames(styles.billableMetricBadge, badgeClass)}
      type="dark"
    >
      {`${aggregationName}(${formattedField})`}
    </Badge>
  );
};
