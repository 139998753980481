import React from "react";
import "style/index.css";
import { twMerge } from "tenaissance/twMerge";
import { Checkbox } from "../Checkbox";
import { OptionGroupContext } from "./OptionGroupContext";
import { RadioButton } from "../RadioButton";

interface OptionGroupSubComponents {
  /** Use Checkboxes when 1 or more options are available to the user */
  Checkbox: typeof Checkbox;
  /** Use RadioButtons when only 1 option is available to the user */
  RadioButton: typeof RadioButton;
}

export type OptionGroupProps = {
  /** Children should always be of OptionGroup.Checkbox */
  children: React.ReactNode[];
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Default - "wide"; "wide" = 768px | "narrow" = 343px */
  size?: "wide" | "narrow";
};

/**
 * Checkboxes allow users to select one or more items from a set, and can also be used to turn
 * an option on or off.
 *  */
export const OptionGroup: React.FC<OptionGroupProps> &
  OptionGroupSubComponents = ({ children, className, size = "wide" }) => {
  const classnames = twMerge(
    "flex flex-col space-y-lg",
    size === "wide" ? "w-[768px]" : "w-[343px]",
    className,
  );

  return (
    <div className={classnames}>
      <OptionGroupContext.Provider value={true}>
        {children}
      </OptionGroupContext.Provider>
    </div>
  );
};

OptionGroup.Checkbox = Checkbox;
OptionGroup.RadioButton = RadioButton;

OptionGroup.displayName = "OptionGroup";
