import React from "react";
import { useForm } from "react-hook-form";
import Decimal from "decimal.js";

import { Headline, InputContainer, Subtitle } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { ProductsQuery } from "./addOneTimeCharge.graphql";
import {
  creditsFromInput,
  prefixForCreditType,
  RoundedCurrency,
} from "lib/credits";
import { parseCreditInputValue } from "components/Input";
import styles from "./addOneTimeCharge.module.less";
import type { PricingFactor } from "./ChooseProductInfo";
import type { CreditType } from "types/credit-types";
import { COMMON_POPUP_PROPS } from ".";

interface PricingDetails {
  description: string;
  price: string;
  quantity: string;
}

interface Props {
  creditType: CreditType;
  product: ProductsQuery["products"][0];
  pricingFactor: PricingFactor;
  goBack: () => void;
  onCancel: () => void;
  isSubmitting: boolean;
  onSubmit: (results: {
    price: Decimal;
    quantity: Decimal;
    description: string;
  }) => void;
}

export const DefineCharge: React.FC<Props> = (props) => {
  const {
    handleSubmit,
    watch,
    formState: { errors },
    register,
  } = useForm<PricingDetails>({
    defaultValues: {
      price: "",
      quantity: "1",
    },
  });

  const parsePrice = (value: string) =>
    parseCreditInputValue(value, {
      creditType: props.creditType,
      allowZeroAmounts: true,
    });

  const price = parsePrice(watch("price"));
  const quantity = new Decimal(watch("quantity") || 0);
  const description = watch("description");

  const onSubmit = handleSubmit((data) => {
    props.onSubmit({
      price: creditsFromInput(data.price, props.creditType),
      quantity: new Decimal(data.quantity),
      description: data.description,
    });
  });

  return (
    <Popup
      {...COMMON_POPUP_PROPS}
      className="!w-[600px] md:!w-[848px]"
      onRequestClose={props.onCancel}
      actions={
        <div className="flex flex-grow justify-between">
          <Button
            onClick={props.goBack}
            text="Back"
            theme="linkGray"
            leadingIcon="arrowLeft"
          />
          <div className="flex gap-8">
            <Button onClick={props.onCancel} text="Cancel" theme="linkGray" />
            <Button
              key="primary"
              loading={props.isSubmitting}
              disabled={props.isSubmitting || Object.keys(errors).length !== 0}
              onClick={onSubmit}
              text="Add One-Time Charge"
              theme="primary"
            />
          </div>
        </div>
      }
    >
      <form
        onSubmit={onSubmit}
        className="flex max-h-[50vh] flex-col overflow-auto"
      >
        <div className="flex flex-row">
          <div className="flex-grow pb-12 pr-12">
            <InputContainer
              name="Price"
              error={errors.price?.message}
              prefix={prefixForCreditType(props.creditType)}
            >
              <input
                type="text"
                {...register("price", {
                  required: "Price is required",
                  validate: (value) => parsePrice(value).error,
                })}
                value={price.cleanInput}
              />
            </InputContainer>
          </div>
          <div className="flex-grow">
            <InputContainer name="Quantity" error={errors.quantity?.message}>
              <input
                type="number"
                placeholder="Quantity"
                {...register("quantity", {
                  required: "Quantity is required",
                  validate: (v) => {
                    const d = new Decimal(v);
                    if (d.lte(0)) {
                      return "Quantity must be greater than 0";
                    }
                    if (d.toFixed(0) !== d.toString()) {
                      return "Quantity must be a whole number";
                    }
                  },
                })}
              />
            </InputContainer>
          </div>
        </div>

        <InputContainer
          name="Description (optional)"
          error={errors.description?.message}
          className="pb-24"
        >
          <input
            type="text"
            placeholder="Description"
            {...register("description")}
          />
        </InputContainer>

        <div className="flex flex-shrink flex-grow flex-col justify-center pb-24">
          <div>
            <p className="pb-8 text-gray-800">
              This is a representation of how this product will appear on an
              invoice. Please edit the charge name, price, and quantity as
              needed before saving.
            </p>
            <div className={styles.previewChargesTable}>
              <div className={styles.previewChargesHeader}>
                <Headline level={5} className={styles.productName}>
                  {props.product.name}
                </Headline>
              </div>
              <div className="grid grid-cols-4">
                <Subtitle className="m-12">Name</Subtitle>
                <Subtitle className="m-12">Price</Subtitle>
                <Subtitle className="m-12">Quantity</Subtitle>
                <Subtitle className="m-12">Total</Subtitle>

                <div className="m-12">
                  {props.pricingFactor.name}
                  {description ? ` - ${description}` : ""}
                </div>
                <div className="m-12">
                  <RoundedCurrency
                    amount={price.value}
                    creditType={props.creditType}
                  />
                </div>
                <div className="m-12">{quantity.toString()}</div>
                <div className="m-12">
                  <RoundedCurrency
                    amount={price.value.mul(quantity)}
                    creditType={props.creditType}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* hack to enable submit via enter in the form */}
        <button type="submit" className="hidden"></button>
      </form>
    </Popup>
  );
};
