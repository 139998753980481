import React from "react";
import styles from "./index.module.less";
import { Copy } from "components/Copy";
import { Icon, Tooltip } from "design-system";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism-tomorrow.css";
import { useUIMode } from "lib/useUIMode";
import { Button } from "tenaissance/components/Button";
import { Icon as TenaissanceIcon } from "tenaissance/components/Icon";
import { useSnackbar } from "components/Snackbar";
import { twMerge } from "tailwind-merge";

type CodeBlockProps = {
  code: string;
  title: string;
  language: "json" | "sql";
  onChange?: (code: string) => void;
  minWidth?: number;
  readOnly?: boolean;
  error?: string | boolean;
  valid?: boolean;
  formatFn?: (code: string) => void;
  createExampleFn?: () => void;
  validatorResult?: ValidatorState;
};

type ValidatorState = {
  valid: boolean | undefined;
  message: string;
};

const PIXELS_PER_CHARACTER = 9;

export const CodeBlock: React.FC<CodeBlockProps> = ({
  code,
  title,
  onChange,
  minWidth,
  readOnly,
  language,
  formatFn,
  createExampleFn,
  validatorResult,
}) => {
  const { newUIEnabled } = useUIMode();
  const lines = code.split("\n");
  /* Avoids text wrapping */
  const width: number = lines.reduce((maxLineLength, line) => {
    return Math.max(
      line.length * PIXELS_PER_CHARACTER,
      maxLineLength,
      minWidth ?? 500,
    );
  }, 0);
  const pushMessage = useSnackbar();

  const highlightWithLineNumber = (code: string): React.ReactNode => {
    return highlight(code, languages[language], language)
      .split("\n")
      .map(
        (line, i) =>
          `<span><span class="lineNumber">${i + 1}</span>${line}</span>`,
      )
      .join("\n");
  };

  const highlightWithLineNumberNewUI = (code: string): React.ReactNode => {
    return highlight(code, languages[language], language)
      .split("\n")
      .map(
        (line, i) =>
          `<span><span class="absolute left-0 text-xs text-white h-full text-center w-[32px] font-mono border-r">${i + 1}</span>${line}</span>`,
      )
      .join("\n");
  };
  const oldUI = (
    <div className={styles.codeBlock}>
      <div className={styles.titleBar}>
        {title}
        <div className={styles.rightBar}>
          {formatFn && (
            <a onClick={() => formatFn(code)} className={styles.copyPayload}>
              <Tooltip content="Format payload">
                <Icon icon="documentText" />
              </Tooltip>
            </a>
          )}
          <Copy text={code}>
            <a className={styles.copyPayload}>
              <Tooltip content="Copy payload">
                <Icon icon="copy" />
              </Tooltip>
            </a>
          </Copy>
        </div>
      </div>
      <div className={styles.codeEditor}>
        <div style={{ minWidth: width }}>
          <div className={styles.editorDivider}>
            <div className={styles.editorDividerLineNumber} />
          </div>
          <Editor
            value={code}
            onValueChange={
              !readOnly && onChange ? (code) => onChange(code) : () => {}
            }
            highlight={highlightWithLineNumber}
            className="editor"
            readOnly={readOnly}
          />
          <div className={styles.editorDivider}>
            <div className={styles.editorDividerLineNumber} />
          </div>
        </div>
      </div>
    </div>
  );

  const newUI = (
    <div className="overflow-hidden rounded-large border border-gray-200">
      <div className="flex items-center justify-between p-12 text-base font-medium">
        {title}
        <div className="flex items-center gap-16">
          {formatFn && (
            <Button
              text="Format"
              leadingIcon="list"
              theme="secondary"
              onClick={() => formatFn(code)}
            />
          )}
          <Button
            text="Copy"
            leadingIcon="copy01"
            theme="secondary"
            onClick={async () => {
              await navigator.clipboard.writeText(code);
              pushMessage({
                content: "Copied!",
                durationMS: 1000,
                type: "success",
              });
            }}
          />
          {createExampleFn && (
            <Button
              text="Create an example"
              leadingIcon="plus"
              theme="primary"
              onClick={createExampleFn}
            />
          )}
        </div>
      </div>
      <div>
        <div>
          <div className="w-full overflow-hidden bg-gray-900">
            <div className="h-12 w-32 border-r border-gray-200" />
          </div>
          <Editor
            value={code}
            onValueChange={
              !readOnly && onChange ? (code) => onChange(code) : () => {}
            }
            highlight={highlightWithLineNumberNewUI}
            className="bg-gray-900 text-xs leading-4 text-white [&>pre]:pl-[42px!important] [&>textarea]:pl-[42px!important] [&>textarea]:outline-none"
            readOnly={readOnly}
          />
          <div className="w-full overflow-hidden bg-gray-900">
            <div className="h-12 w-32 border-r border-gray-200" />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-center px-16 py-12">
        <div
          className={twMerge(
            "flex w-full flex-row items-center rounded-large border border-gray-200 px-8 py-8 text-xs",
            validatorResult?.valid === false
              ? "text-error-600"
              : "text-success-600",
          )}
        >
          <TenaissanceIcon
            icon={
              validatorResult?.valid === false
                ? "alertCircle"
                : validatorResult?.valid === undefined
                  ? "pencilLine"
                  : "lightning01"
            }
            size={16}
            className={`m-4 align-middle ${validatorResult?.valid === undefined ? "text-gray-900" : ""}`}
          />
          {validatorResult?.valid === false && validatorResult.message}
          {validatorResult?.valid === undefined && "Create an example to test"}
          {validatorResult?.valid === true && "Successfully matched!"}
        </div>
      </div>
    </div>
  );

  return newUIEnabled ? newUI : oldUI;
};
