import React, { useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "lib/useNavigate";
import { useSnackbar } from "components/Snackbar";
import { Body, Subtitle } from "design-system";
import { Wizard, WizardSection } from "components/Wizard";
import { DraftPlan, PlanDetails } from "lib/plans/types";
import { planToDraftPlan, getDraftPlanInsert } from "lib/plans/draftPlan";
import { usePlanQuery } from "lib/plans/queries.graphql";
import {
  useEditPlanMutation,
  usePlanActiveAndFutureCustomersQuery,
  useCreatePlanDataQuery,
  CreatePlanDataQuery,
} from "../data/queries.graphql";
import { DraftPlanContext } from "../context";
import { planTermsSection } from "../steps/PlanTerms";
import { addProductsSection } from "../steps/AddProducts";
import { pricingSection } from "../steps/Pricing";
import { EditInterstitial } from "../components/EditInterstitial";
import { previewPlanEditsSection } from "../steps/PreviewPlan";
import { NavigationPrompt } from "components/Popup";
import { MAX_ALLOWED_CUSTOMERS_FOR_ADD_PLAN_TO_CUSTOMER } from "../steps/PlanTerms/components/AddCustomerToPlan";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useFeatureFlag } from "lib/launchdarkly";
import { useUIMode } from "../../../lib/useUIMode";

const getSections: (
  draftPlan: DraftPlan,
  data: CreatePlanDataQuery,
  hasPreviewedPlan: boolean,
  onPreviewPlan: () => void,
  existingPlan: PlanDetails,
  hasCustomers: boolean,
  hasCustomPricing: boolean,
  allowAnnualSeats: boolean,
) => WizardSection[] = (
  draftPlan,
  data,
  hasPreviewedPlan,
  onPreviewPlan,
  existingPlan,
  hasCustomers,
  hasCustomPricing,
  allowAnnualSeats,
) => [
  planTermsSection(
    draftPlan,
    data,
    hasCustomers,
    true,
    undefined,
    hasCustomPricing,
  ),
  addProductsSection(draftPlan, data, allowAnnualSeats, true),
  pricingSection(draftPlan, data, hasCustomPricing, true),
  previewPlanEditsSection(
    draftPlan,
    hasPreviewedPlan,
    onPreviewPlan,
    data,
    existingPlan,
    hasCustomers,
    hasCustomPricing,
  ),
];

const EditPlan: React.FC = () => {
  const navigate = useNavigate();
  const { id: planId } = useParams<{ id?: string }>();
  if (!planId) {
    throw new Error("Need a plan id to edit");
  }
  const { data: existingPlanData, loading: planLoading } = usePlanQuery({
    variables: {
      id: planId,
    },
  });
  const largePlans: string[] | undefined = useFeatureFlag(
    "large-plan-edit-ids",
    [],
  );
  const allowAnnualSeats = useFeatureFlag("annual-seats", false);

  const skipLoadingActiveCustomersData = !!largePlans?.includes(planId);
  const { data: activeCustomersData, loading: activeCustomersLoading } =
    usePlanActiveAndFutureCustomersQuery({
      variables: {
        plan_id: planId,
        offset: 0,
        limit: 1,
      },
      skip: skipLoadingActiveCustomersData,
    });
  const { environmentType } = useEnvironment();
  const { data: productsData, loading: productsLoading } =
    useCreatePlanDataQuery({
      variables: {
        limit: MAX_ALLOWED_CUSTOMERS_FOR_ADD_PLAN_TO_CUSTOMER,
        environment_type: environmentType,
      },
    });

  const [editPlanMutation, editPlanResult] = useEditPlanMutation();
  const pushMessage = useSnackbar();

  const [draftPlan, setDraftPlan] = useState<DraftPlan>({ revision: 0 }); // Plan gets overwritten in effect
  useEffect(() => {
    if (!planLoading) {
      if (!existingPlanData?.Plan_by_pk) {
        throw new Error("Error loading plan info");
      }
      // leave off the id and supply an arbitrary revision here because
      // the edit shouldn't actually be saved as a draft
      const draftFromExistingPlan: DraftPlan = {
        startingOn: null,
        ...planToDraftPlan(existingPlanData?.Plan_by_pk),
        revision: 0,
      };
      setDraftPlan(draftFromExistingPlan);
    }
  }, [planLoading]);
  const { newUIEnabled } = useUIMode();
  const [passedLandingPage, setPassedLandingPage] = useState<boolean>(false);
  const [hasPreviewedPlan, setHasPreviewedPlan] = useState<boolean>(false);
  const [isFinishedSaving, setIsFinishedSaving] = useState<boolean>(false);
  const onClose = () =>
    navigate(`${newUIEnabled ? "/offering" : ""}/plans/${planId}`);

  if (planLoading || productsLoading || activeCustomersLoading) {
    return null;
  }

  if (!existingPlanData?.Plan_by_pk) {
    throw new Error("Error loading plan info");
  }
  if (!productsData?.products) {
    throw new Error("Error loading product info");
  }
  if (activeCustomersData === undefined && !skipLoadingActiveCustomersData) {
    throw new Error("Error loading active customers info");
  }

  if (!passedLandingPage) {
    return (
      <EditInterstitial
        onClose={onClose}
        onContinue={() =>
          flushSync(() => {
            setPassedLandingPage(true);
          })
        }
        planName={existingPlanData.Plan_by_pk.name}
        activeAndFutureCustomers={activeCustomersData}
      />
    );
  }

  const hasCustomers =
    (activeCustomersData?.Plan_by_pk?.customer_count ?? 0) > 0;

  const sections = getSections(
    draftPlan,
    productsData,
    hasPreviewedPlan,
    () => setHasPreviewedPlan(true),
    existingPlanData.Plan_by_pk,
    hasCustomers,
    !!activeCustomersData?.custom_pricing_on_plan.aggregate?.count,
    allowAnnualSeats ?? false,
  );

  const onDone = async () => {
    let planInput;
    try {
      planInput = getDraftPlanInsert(draftPlan);
    } catch (err: any) {
      pushMessage({ content: err.message, type: "error" });
      return;
    }
    try {
      await editPlanMutation({
        variables: {
          plan_id: planId,
          new_plan: planInput,
          starting_on: draftPlan.startingOn?.toISOString(),
        },
        update: (cache) => {
          // Evict all customer plans since we changed the plan id in place.

          // Dear sir/madame,
          //
          // Yes, it seems like there should be a better way to do this, but
          // after hours of looking for where this stuff is cached/referenced,
          // this is the best I was able to come up with. If future engineers
          // want to make this better, the trick will be to figure out what
          // field on the root query is holding on to these and evict that. Be
          // sure to git blame this comment and find the bug attached to the PR
          // so you can make sure you're testing the right scenario.
          //
          // Best of luck,
          // smarx
          for (const id of Object.keys(cache.extract()).filter((k) =>
            k.startsWith("CustomerPlan:"),
          )) {
            cache.evict({ id });
          }
          // Evict all customers since we changed the plan id in place and
          // draft invoices (cost calculations have changed) are attached to
          // customers.
          cache.evict({ fieldName: "Customer_by_pk" });
          // Clean up anything attached to the Customers
          cache.gc();
        },
      });
    } catch (err: any) {
      pushMessage({
        content: `Failed to edit plan: ${err.message}`,
        type: "error",
      });
      return;
    }

    if (editPlanResult.error) {
      pushMessage({
        content: `Failed to edit plan: ${editPlanResult.error.message}`,
        type: "error",
      });
      return;
    }

    flushSync(() => {
      setIsFinishedSaving(true);
    });

    onClose();
  };
  return (
    <DraftPlanContext.Provider
      value={{
        draftPlan,
        setDraftPlan: (newDraftPlan: DraftPlan) => {
          flushSync(() => {
            setHasPreviewedPlan(false);
            setDraftPlan(newDraftPlan);
          });
        },
      }}
    >
      <NavigationPrompt
        title="Exit plan editor?"
        navActionName="Exit plan editor"
        disabled={isFinishedSaving}
      >
        <Body level={2}>
          To save edits to a plan, proceed to the Preview Edits step to review
          changes. By closing the editor all changes will be lost.
        </Body>
        <Body level={2}>Are you sure you want to leave the plan editor?</Body>
      </NavigationPrompt>
      <Wizard
        sections={sections}
        onClose={onClose}
        onDone={onDone}
        doneButtonTitle="Finalize and save edits"
        title="Edit plan"
        subtitle={
          draftPlan?.name ? (
            <Subtitle level={4}>{draftPlan.name}</Subtitle>
          ) : null
        }
        loading={editPlanResult.loading}
      />
    </DraftPlanContext.Provider>
  );
};

export default EditPlan;
