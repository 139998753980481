import React from "react";
import Decimal from "decimal.js";
import classNames from "classnames";

interface Props {
  progress: Decimal;
  total: Decimal;
  isExpired?: boolean;
}

export const ProgressBar: React.FC<Props> = (props) => {
  const percent = props.progress
    .div(props.total)
    .mul(100)
    .abs()
    .toNearest(1, Decimal.ROUND_UP)
    .round()
    .clamp(0, 100);

  return (
    <div className="border-primary-large relative w-full border-collapse overflow-clip rounded-large border bg-primary-50">
      <div
        className={classNames(
          "h-8 overflow-hidden rounded-r-large text-center",
          props.isExpired
            ? "bg-gray-400"
            : props.progress.isPositive()
              ? "bg-primary-500"
              : "bg-error-500",
        )}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
};
