import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { Dayjs } from "lib/date";

import {
  ContractPricing_RateCardIdsFragment,
  ContractPricing_RateCardNamesFragment,
} from "./fragments.graphql";
import Decimal from "decimal.js";
import { CreditType } from "types/credit-types";

export namespace Types {
  export type RateCardIds = ContractPricing_RateCardIdsFragment;
  export type RateCardNames = ContractPricing_RateCardNamesFragment;
  export type RateCard<T extends RateCardIds> = T["rate_cards"][number];

  export type ProductIds =
    import("./fragments.graphql").ContractPricing_ProductIdsFragment;
  export type ProductTags =
    import("./fragments.graphql").ContractPricing_ProductTagsFragment;
  export type ProductTypes =
    import("./fragments.graphql").ContractPricing_ProductTypesFragment;
  export type ProductNames =
    import("./fragments.graphql").ContractPricing_ProductNamesFragment;

  export type Product<T extends ProductIds> = T["products"][number];

  export type OverrideOverwriteRate = {
    type: "overwrite";
    newRate:
      | {
          type: "flat";
          unitPrice: number;
          creditType: CreditType;
        }
      | {
          type: "subscription";
          unitPrice: number;
          quantity: number;
          isProrated: boolean;
        }
      | {
          type: "percentage";
          fraction: number;
          useListPrices?: boolean;
        };
  };

  export type Override = {
    id?: string;
    entitled?: boolean;
    productId?: string;
    tags?: string[];
    startingAt: string;
    endingBefore?: string;
    rate?:
      | {
          type: "multiplier";
          multiplier: Decimal;
          baseRate?: Decimal;
          priority?: Decimal;
        }
      | OverrideOverwriteRate;
  };
}

const productByIdCache = new WeakMap<
  Types.ProductIds,
  Map<string, Types.Product<Types.ProductIds>>
>();
const rateCardByIdCache = new WeakMap<
  Types.RateCardIds,
  Map<string, Types.RateCard<Types.RateCardIds>>
>();

function getProductsById<P extends Types.ProductIds>(
  pricing: P,
): Map<string, Types.Product<P>> {
  const cached = productByIdCache.get(pricing) as
    | Map<string, Types.Product<P>>
    | undefined;

  if (cached) {
    return cached;
  }

  const map = new Map(pricing.products.map((p) => [p.id, p]));
  productByIdCache.set(pricing, map);
  return map;
}

function getRateCardsById<P extends Types.RateCardIds>(
  pricing: P,
): Map<string, Types.RateCard<P>> {
  const cached = rateCardByIdCache.get(pricing) as
    | Map<string, Types.RateCard<P>>
    | undefined;

  if (cached) {
    return cached;
  }

  const map = new Map(pricing.rate_cards.map((r) => [r.id, r]));
  rateCardByIdCache.set(pricing, map);
  return map;
}

export function getRateCards<P extends Types.RateCardIds>(
  pricing: P,
): Types.RateCard<P>[] {
  return pricing.rate_cards;
}

export function getRateCard<P extends Types.RateCardIds>(
  pricing: P,
  id: string,
) {
  const rateCard = getRateCardsById(pricing).get(id);
  if (!rateCard) {
    throw new Error(`Unknown rate card id: ${id}`);
  }
  return rateCard;
}

function getProduct<P extends Types.ProductIds>(pricing: P, productId: string) {
  const product = getProductsById(pricing).get(productId);
  if (!product) {
    throw new Error(`Unknown product id: ${productId}`);
  }
  return product;
}

export function getProductName(
  pricing: Types.ProductNames,
  productId: string,
  now: Dayjs,
) {
  return ProductListItem.getName(getProduct(pricing, productId), now);
}
