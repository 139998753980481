import React from "react";
import { Navigate } from "react-router-dom";

import { useEnvironment } from "./context";

/**
 * Validates that the user has access to the current environment and
 * if not redirects to an environment they do have access to. We only
 * use this on the `/` route, to avoid people being redirected to
 * unexpectedly different envs when following links to envs they don't
 * have access to.
 *
 * If we had a front-end server that could do this redirect for us, we
 * would likely do this in the authentication callback, but we don't so
 * we fake it here.
 */
export const EnvironmentRedirect: React.FC<{ to: string }> = ({ to }) => {
  const { environment, environments, prefixEnvironmentUrl } = useEnvironment();

  if (!environment.enabled) {
    const anyOtherEnv = environments.find((e) => e.enabled);
    if (anyOtherEnv) {
      return <Navigate to={`${anyOtherEnv.urlSlug}${to}`} replace />;
    }
  }
  const currentEnvironmentUrl = prefixEnvironmentUrl(environment, to);
  return <Navigate to={currentEnvironmentUrl} replace />;
};

/**
 * This wrapper component only redirects the user if their current environment is invalid.
 * If their current environment is valid, render its children.
 */
export const RedirectEnvironmentIfInvalid: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { environment, environments, setEnvironment } = useEnvironment();

  if (!environment.enabled) {
    const anyOtherEnv = environments.find((e) => e.enabled);
    if (anyOtherEnv) {
      setEnvironment(anyOtherEnv);
    }
  }

  return <>{children}</>;
};
