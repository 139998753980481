import React, { useEffect } from "react";
import "/src/tenaissance/tenaissance.css";
import { twMerge } from "tenaissance/twMerge";
import { Icon, IconName } from "../Icon";
import { IconButton } from "../IconButton";
import Modal from "react-modal";

if (process.env.NODE_ENV !== "test") {
  if (!!document.getElementById("storybook-root")) {
    Modal.setAppElement("#storybook-root");
  } else {
    Modal.setAppElement("#root");
  }
}

interface SideSheetHeaderProps {
  icon?: IconName;
  onClose: () => void;
  supportingText?: string;
  title: string;
}
const SideSheetHeader: React.FC<SideSheetHeaderProps> = ({
  icon,
  onClose,
  supportingText,
  title,
}) => {
  return (
    <div className="pt-3xl mb-3xl flex w-full flex-row-reverse items-start justify-between">
      <IconButton icon="xClose" onClick={onClose} theme="tertiary" size="sm" />
      <div className="flex items-center">
        {icon && <Icon icon={icon} size={36} className="mr-xl" />}
        <div className="flex flex-col">
          <h3 className="text-xl font-semibold text-black">{title}</h3>
          {supportingText && (
            <h5 className="text-sm text-gray-600">{supportingText}</h5>
          )}
        </div>
      </div>
    </div>
  );
};

type ReactElementSet =
  | [React.ReactElement]
  | [React.ReactElement, React.ReactElement]
  | [React.ReactElement, React.ReactElement, React.ReactElement];

interface SideSheetFooterProps {
  leadingAction?: React.ReactElement;
  trailingActions?: ReactElementSet;
}
const SideSheetFooter: React.FC<SideSheetFooterProps> = ({
  leadingAction,
  trailingActions,
}) => {
  return (
    <div
      className={twMerge(
        "p-xl bottom-none fixed flex h-[72px] w-[540px] items-start border-t-[1px] border-gray-200 bg-white",
        !!trailingActions && !leadingAction ? "justify-end" : "justify-between",
      )}
    >
      {leadingAction}
      {trailingActions && (
        <div className="space-x-lg flex flex-row-reverse space-x-reverse">
          {trailingActions.map((a, idx) => (
            <div key={idx}>{a}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export type SideSheetProps = SideSheetHeaderProps &
  SideSheetFooterProps & {
    /** Customize the component with additional Tailwind classes */
    className?: string;
    /** Content to be placed inside the SideSheet */
    children?: React.ReactNode;
    /** Used to open and cloe the SideSheet */
    isOpen: boolean;
  };

/**
 * SideSheets allow for content to be available to the user without taking up extra space on the screen.
 * They behave similar to a modal, but with the added benefit of taking up less prominent screen real
 * estate and are easier to scroll for longer content requirements.
 */
export const SideSheet: React.FC<SideSheetProps> = ({
  className,
  children,
  icon,
  isOpen = false,
  onClose,
  supportingText,
  leadingAction,
  trailingActions,
  title,
}) => {
  useEffect(() => {
    // prevent background scrolling
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <Modal
      className={twMerge(
        "rounded-none animate-fadeInThenSlideFromRight relative h-screen w-[540px] overflow-auto bg-white shadow-xl outline-none",
        !isOpen && "animate-fadeOutThenSlideToRight",
        className,
      )}
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={300}
      overlayClassName={twMerge(
        "bg-gray-950/70 flex fixed inset-0 items-stretch justify-end z-modal animate-fadeIn opacity-0",
        !isOpen && "opacity-100 animate-fadeOut",
      )}
    >
      <div className="px-xl">
        <SideSheetHeader
          title={title}
          icon={icon}
          onClose={onClose}
          supportingText={supportingText}
        />
        <div className="mb-[72px]">{children}</div>
      </div>
      {(leadingAction || trailingActions) && (
        <SideSheetFooter
          leadingAction={leadingAction}
          trailingActions={trailingActions}
        />
      )}
    </Modal>
  );
};
