import React from "react";
import { DraftPlan } from "lib/plans/types";
import { WizardSection, WizardSubStep } from "components/Wizard";
import { CreatePlanDataQuery } from "../../data/queries.graphql";
import { CustomCreditType } from "types/credit-types";
import { getRamps, getRampStartPeriod } from "lib/plans/ramps";
import {
  isProductRampPricingDone,
  isRampPricingDone,
  isAllProductPricingDone,
  isInvoiceMinimumDone,
  isOveragePricingDone,
} from "./doneChecks";
import { PriceRampPage } from "./components/PriceRampPage";
import { pricingRampTitle } from "./components/PriceProductTable";
import { InvoiceMinimumPage } from "./components/InvoiceMinimumPage";
import { OverageRatePage } from "./components/OverageRatePage";

import { customPricingUnitsForPlan } from "./doneChecks";

export const pricingSection: (
  draftPlan: DraftPlan,
  data: CreatePlanDataQuery,
  hasCustomPricing?: boolean,
  editing?: boolean,
) => WizardSection = (draftPlan, data, hasCustomPricing, editing) => {
  const customPricingUnits = customPricingUnitsForPlan(draftPlan);

  const creditTypeIdToCreditType: Record<string, CustomCreditType> =
    Object.fromEntries(
      data.CreditType.filter((ct) => customPricingUnits.has(ct.id)).map(
        (ct) => [ct.id, ct as CustomCreditType],
      ),
    );

  return {
    title: "Pricing",
    isDone: isAllProductPricingDone(data, draftPlan),
    icon: "logoUsd",
    subStepGroups: getRamps(draftPlan)
      .map((ramp, i) => {
        const rampDone = isRampPricingDone(data, draftPlan, i);
        const subSteps = (draftPlan.selectedProductIds ?? [])
          .map<WizardSubStep>((productId) => {
            const product = data.products.find((p) => p.id === productId);
            return {
              header: "Price your products",
              title: product?.name,
              tooltip: product?.description,
              component: (
                <PriceRampPage
                  key={i}
                  data={data}
                  productId={productId}
                  rampIndex={i}
                  hasCustomPricing={hasCustomPricing}
                  editing={editing}
                />
              ),
              isDone: isProductRampPricingDone(data, draftPlan, productId, i),
              isNavigable: true, // when visible, these should always be navigable
              skipIfDone: true,
            };
          })
          .concat(
            draftPlan.hasMinimums
              ? [
                  {
                    header: "Add an invoice minimum",
                    title: "Invoice minimum",
                    component: (
                      <InvoiceMinimumPage
                        key={["invoiceMinimum", i].join()}
                        data={data}
                        rampIndex={i}
                      />
                    ),
                    isDone: isInvoiceMinimumDone(
                      getRampStartPeriod(i, getRamps(draftPlan)),
                      draftPlan,
                    ),
                  },
                ]
              : [],
          );
        return {
          title: draftPlan.hasPriceRamps
            ? pricingRampTitle(ramp.durationInPeriods, i, draftPlan)
            : undefined,
          isDone: rampDone,
          subSteps: subSteps.map((subStep, i) => ({
            ...subStep,
            // if it's the last subStep for the ramp, make sure the whole ramp is complete before allowing continue
            isDone: subStep.isDone && (i !== subSteps.length - 1 || rampDone),
          })),
        };
      })
      .concat(
        Object.values(creditTypeIdToCreditType).length > 0
          ? [
              {
                title: "Overage rates",
                isDone: isOveragePricingDone(draftPlan),
                subSteps: Object.values(creditTypeIdToCreditType)
                  .sort((a, b) => (a.id < b.id ? 1 : -1))
                  .map<WizardSubStep>((ct, index, cts) => ({
                    header: "Overage rates",
                    title: ct.name ?? "--",
                    component: (
                      <OverageRatePage
                        creditTypesToPrice={Array.from(cts)}
                        data={data}
                      />
                    ),
                    isDone: isOveragePricingDone(draftPlan),
                    isNavigable: true, // when visible, these should always be navigable
                    skipIfDone: true,
                  })),
              },
            ]
          : [],
      ),
  };
};
