import { AllFlagsLDClient } from "launchdarkly-react-client-sdk";
import { TypedDocumentNode } from "@apollo/client";
import React from "react";
import { Environment } from "../../environmentSwitcher/context";
import { CustomReportType } from "types/generated-graphql/__types__";

export type BaseReport = {
  name: string;
  needsDates: boolean;
  needsEnvironment: boolean;
  isAllowedForUser: (
    launchDarkly: AllFlagsLDClient["ldClient"] | undefined,
  ) => boolean;
  type?: string;
};

export type ReportConfig<T> = BaseReport & {
  name: string;
  pageSize: number;
  queryDocument: TypedDocumentNode<T>;
  isAllowedForUser: (
    launchDarkly: AllFlagsLDClient["ldClient"] | undefined,
  ) => boolean;
  nextCursor: (page: T) => string | null;
  additionalDescription?: React.ReactNode;
} & (
    | {
        needsEnvironment: false;
        needsDates: true;
        dataToCSV: (
          pages: T[],
          inclusiveStartDate: Date,
          exclusiveEndDate: Date,
        ) => string[][];
      }
    | {
        needsEnvironment: false;
        needsDates: false;
        dataToCSV: (pages: T[]) => string[][];
      }
    | {
        needsEnvironment: true;
        needsDates: true;
        dataToCSV: (
          pages: T[],
          environment: Environment,
          prefixUrl: (url: string) => string,
        ) => string[][];
      }
  );

export type SOXReport = BaseReport & {
  type: string;
  customReportType: CustomReportType;
  canSendToWorkato?: boolean;
  restrictEndDateToToday: boolean;
};

export const DEFAULT_PAGE_SIZE: number = 500;
export const REPORT_DATE_FORMAT: string = "MM/DD/YYYY";
