import { useLocation } from "react-router-dom";
import { reportToSentry } from "lib/errors/sentry";

export function useLocationState(): Record<string, unknown> | undefined {
  const state = useLocation().state;
  if (!state) {
    return undefined;
  }

  if (typeof state === "object" && state !== null) {
    return state;
  }

  reportToSentry(new TypeError("expected location state to be an object"));

  return {};
}
