import React from "react";
import { useFeatureFlag } from "lib/launchdarkly";

const LOCAL_STORAGE_KEY = "contracts-enabled";

function readLocalStorage() {
  return localStorage.getItem(LOCAL_STORAGE_KEY) !== null;
}

function writeLocalStorage(value: boolean) {
  if (value) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, "true");
  } else {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
}

interface LocalContractSettings {
  canToggle: boolean;
  enabled: boolean;
  setEnabled(value: boolean): void;
}

const Context = React.createContext<LocalContractSettings | null>(null);
export const __Testing__LocalContractSettingsContext = Context;

export const LocalContractSettingsProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const featureFlag = !!useFeatureFlag("allow-contracts", false);
  const toggleFeatureFlag = !!useFeatureFlag("allow-contracts-toggle", false);
  const [enabledInLocalStorage, setCachedState] =
    React.useState(readLocalStorage());

  const canToggle = featureFlag && toggleFeatureFlag;
  const enabled = canToggle ? enabledInLocalStorage : featureFlag;

  // listen for changes in other tabs
  window.addEventListener("storage", (event) => {
    if (event.key === LOCAL_STORAGE_KEY) {
      setCachedState(readLocalStorage());
    }
  });

  const localContractSettings = React.useMemo(
    (): LocalContractSettings => ({
      canToggle,
      enabled,
      setEnabled: (value) => {
        if (!canToggle) {
          return;
        }

        writeLocalStorage(value);
        setCachedState(value);
      },
    }),
    [canToggle, enabled, setCachedState],
  );

  return (
    <Context.Provider value={localContractSettings}>
      {children}
    </Context.Provider>
  );
};

export function useLocalContractSettings() {
  const context = React.useContext(Context);
  if (context === null) {
    throw new Error(
      "useLocalContractSettings must be used within a LocalContractSettingsProvider",
    );
  }
  return context;
}
