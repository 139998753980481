/* 364a440cd49005f8e892747f16dac523258da9e9
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertNetSuiteCustomerIdMutationVariables = Types.Exact<{
  netsuite_customer_id: Types.Scalars['String'];
  customer_id: Types.Scalars['uuid'];
}>;


export type UpsertNetSuiteCustomerIdMutation = { __typename?: 'Mutation', upsert_customer_configs: Array<{ __typename?: 'CustomerConfig', id: string, value: string }> };


export const UpsertNetSuiteCustomerIdDocument = gql`
    mutation UpsertNetSuiteCustomerId($netsuite_customer_id: String!, $customer_id: uuid!) {
  upsert_customer_configs(
    customer_id: $customer_id
    inputs: [{key: "netsuite_customer_id", value: $netsuite_customer_id}]
  ) {
    id
    value
  }
}
    `;
export type UpsertNetSuiteCustomerIdMutationFn = Apollo.MutationFunction<UpsertNetSuiteCustomerIdMutation, UpsertNetSuiteCustomerIdMutationVariables>;

/**
 * __useUpsertNetSuiteCustomerIdMutation__
 *
 * To run a mutation, you first call `useUpsertNetSuiteCustomerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNetSuiteCustomerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNetSuiteCustomerIdMutation, { data, loading, error }] = useUpsertNetSuiteCustomerIdMutation({
 *   variables: {
 *      netsuite_customer_id: // value for 'netsuite_customer_id'
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useUpsertNetSuiteCustomerIdMutation(baseOptions?: Apollo.MutationHookOptions<UpsertNetSuiteCustomerIdMutation, UpsertNetSuiteCustomerIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertNetSuiteCustomerIdMutation, UpsertNetSuiteCustomerIdMutationVariables>(UpsertNetSuiteCustomerIdDocument, options);
      }
export type UpsertNetSuiteCustomerIdMutationHookResult = ReturnType<typeof useUpsertNetSuiteCustomerIdMutation>;
export type UpsertNetSuiteCustomerIdMutationResult = Apollo.MutationResult<UpsertNetSuiteCustomerIdMutation>;
export type UpsertNetSuiteCustomerIdMutationOptions = Apollo.BaseMutationOptions<UpsertNetSuiteCustomerIdMutation, UpsertNetSuiteCustomerIdMutationVariables>;