import React from "react";
import { twMerge } from "tailwind-merge";

export type StatusLightProps = {
  status: "on" | "off";
};

/**
 * Colored circle representing different states.
 */
export const StatusLight: React.FC<StatusLightProps> = (props) => {
  return (
    <div
      className={twMerge(
        "h-md w-md rounded-full",
        props.status === "on" && "bg-green-medium",
        props.status === "off" && "bg-grey-200",
      )}
    ></div>
  );
};
