import { dayjs } from "lib/dayjs";
import { RightPane } from "components/Popup";
import React, { useState } from "react";
import styles from "./index.module.less";
import { Body, Headline, Label, Subtitle } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { Toggle, DateInput, Input, TextArea } from "design-system";
import { CreditInput } from "components/Input";
import { HelpCircleTooltip } from "design-system";
import {
  displayCostBasis,
  displayCreditTypeName,
  RoundedCurrency,
  USD_CREDIT_ID,
  USD_CREDIT_TYPE,
} from "lib/credits";
import classNames from "classnames";
import { PricingUnitSelector } from "components/PricingUnitSelector";
import { CreditType } from "types/credit-types";
import Decimal from "decimal.js";
import IssueCreditGrantPriority from "../IssueCreditGrantPriority";
import { useSnackbar } from "components/Snackbar";
import { GetNonExpiredCreditGrantsDocument } from "../../data/credit-overview.graphql";
import {
  useIssueCreditGrantMutation,
  useIssueCreditGrantInfoQuery,
} from "./queries.graphql";
import { Select } from "design-system";
import {
  getUserFacingErrorMessage,
  giveUserFacingErrorMessage,
} from "lib/errors/errorHandling";
import { clearCustomerInvoicesFromCache } from "../../lib/cache";
import { BillingProviderEnum_Enum } from "types/generated-graphql/__types__";
import { useFeatureFlag } from "lib/launchdarkly";
import { billingProviderOptions } from "lib/billingProvider/billingProviderOptions";
import { RolloverSettingsInputs } from "components/RolloverSettingsInputs";
import {
  RolloverSettingsInput,
  useEnableRolloverCreditGrants,
} from "lib/rolloverSettings";

type IssuedCreditGrant = {
  customer_id: string;
  name: string;
  effectiveAt: Date;
  expiresBefore: Date;
  priority: number;
  amountPaid: number;
  amountGranted: number;
  amountPaidCreditType: CreditType;
  amountGrantedCreditType: CreditType;
  reason: string;
  billingProvider: BillingProviderEnum_Enum | undefined;
};

interface IssueCreditGrantPaneProps {
  customerId: string;
  onCreditGrantIssued: (creditGrant: IssuedCreditGrant) => void;
  onClose: () => void;
}
export const IssueCreditGrantPane: React.FC<IssueCreditGrantPaneProps> = ({
  customerId,
  onCreditGrantIssued,
  onClose,
}) => {
  const pushMessage = useSnackbar();
  const [grantName, setGrantName] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [effectiveAt, setEffectiveAt] = useState<Date>();
  const [expiresBefore, setExpiresBefore] = useState<Date>();
  const [pricingUnit, setPricingUnit] = useState<CreditType>();
  const [creditGrantType, setCreditGrantType] = useState<string>("");
  const [amountPaidPricingUnit, setAmountPaidPricingUnit] =
    useState<CreditType>(USD_CREDIT_TYPE);
  const [amountPaid, setAmountPaid] = useState<number | null>(null);
  const [amountGranted, setAmountGranted] = useState<number | null>(null);
  const [priority, setPriority] = useState<number | null>(null);
  const [rawPriority, setRawPriority] = useState<string>("");
  const [formErrorMsg, setFormErrorMsg] = useState<string>("");
  const [isPurchase, setPurchase] = useState<boolean>(false);
  const [invoiceDate, setInvoiceDate] = useState<Date>();
  const [isProductSpecificCredit, setIsProductSpecificCredit] =
    useState<boolean>(false);
  const [selectedProductIds, setSelectedProductIds] = useState<string[] | null>(
    null,
  );
  const [rolloverSettingsFormState, setRolloverSettingsFormState] = useState<{
    value: RolloverSettingsInput | undefined;
    error?: string;
  }>({ value: undefined });

  const [billingProvider, setBillingProvider] = useState<
    BillingProviderEnum_Enum | undefined
  >(undefined);

  const canSetBillingProvider = useFeatureFlag(
    "set-billing-provider-on-credit-grant",
    false,
  );
  const enableRolloverCreditGrants = useEnableRolloverCreditGrants();
  const canBackdateGrants =
    useFeatureFlag<boolean>(
      "allow-backdate-credit-grants-past-invoice-dates",
      false,
    ) ?? false;

  const { data, loading } = useIssueCreditGrantInfoQuery({
    variables: {
      customer_id: customerId,
    },
  });

  // For customers with no finalized invoices, we use an arbitrary date in the "distant past": 2017-01-01.
  const minEffectiveDate =
    data?.customer?.end_date_of_last_finalized_invoice && !canBackdateGrants
      ? dayjs.utc(data?.customer?.end_date_of_last_finalized_invoice)
      : dayjs.utc("2017-01-01");
  const minExpiryDate = dayjs
    .utc(effectiveAt || minEffectiveDate)
    .add(1, "day")
    .toDate();

  const [issueCreditGrant, issueCreditGrantResults] =
    useIssueCreditGrantMutation();

  const fieldsAreValid = () => {
    return !(
      grantName.trim() === "" ||
      amountPaid === null ||
      amountGranted === null ||
      priority === null ||
      !pricingUnit ||
      !effectiveAt ||
      !expiresBefore ||
      (isPurchase && !invoiceDate) ||
      (isProductSpecificCredit &&
        (selectedProductIds === null || selectedProductIds.length === 0)) ||
      rolloverSettingsFormState.error !== undefined
    );
  };

  const submitForm = async () => {
    if (
      grantName.trim() === "" ||
      amountPaid === null ||
      amountGranted === null ||
      priority === null ||
      !pricingUnit ||
      !effectiveAt ||
      !expiresBefore ||
      (isProductSpecificCredit &&
        (selectedProductIds === null || selectedProductIds.length === 0))
    ) {
      return;
    }

    const creditGrant = {
      customer_id: customerId,
      name: grantName.trim(),
      effective_at: effectiveAt.toISOString(),
      expires_before: expiresBefore.toISOString(),
      invoice_date: invoiceDate?.toISOString(),
      priority: priority.toString(),
      amount_paid: amountPaid.toString(),
      amount_granted: amountGranted.toString(),
      amount_paid_credit_type_id: amountPaidPricingUnit.id,
      amount_granted_credit_type_id: pricingUnit.id,
      reason: reason.trim(),
      billing_provider: billingProvider ? billingProvider : null,
      product_ids: selectedProductIds,
      credit_grant_type:
        creditGrantType.trim() !== "" ? creditGrantType.trim() : null,
      rollover_settings: rolloverSettingsFormState.value,
    };
    try {
      if (!canSetBillingProvider && creditGrant.billing_provider) {
        const error = new Error();
        giveUserFacingErrorMessage(
          error,
          "Client is not enabled to set billing provider on credit grant",
        );
        throw error;
      }

      await issueCreditGrant({
        variables: { credit_grant: creditGrant },
        refetchQueries: [
          {
            query: GetNonExpiredCreditGrantsDocument,
            variables: {
              customer_id: customerId,
              credit_type_id: pricingUnit.id,
            },
          },
        ],
        update(cache) {
          cache.evict({
            id: `Customer:${customerId}`,
            fieldName: "CreditGrants",
          });
          clearCustomerInvoicesFromCache(cache, customerId);
          cache.gc();
        },
      });
      setFormErrorMsg("");
      pushMessage({ content: "New grant issued!", type: "success" });
      const issuedCreditGrant = {
        ...creditGrant,
        effectiveAt: effectiveAt,
        expiresBefore: expiresBefore,
        priority: priority,
        amountPaid: amountPaid,
        amountGranted: amountGranted,
        amountPaidCreditType: amountPaidPricingUnit,
        amountGrantedCreditType: pricingUnit,
        billingProvider: billingProvider,
      };
      onCreditGrantIssued(issuedCreditGrant);
      onClose();
    } catch (e) {
      const msg = getUserFacingErrorMessage(e);
      setFormErrorMsg(msg);
      pushMessage({
        content: `Failed to issue grant: ${msg}`,
        type: "error",
      });
      return;
    }
  };

  const creditGrantTypeEnabled = useFeatureFlag<boolean>(
    "credit-grant-type-ui",
    false,
  );

  return (
    <RightPane isOpen onRequestClose={onClose}>
      <div className={styles.titleSection}>
        <Headline level={6}>Issue a new grant</Headline>
        <IconButton onClick={onClose} theme="secondary" icon="xClose" />
      </div>
      <Body level={2} className={styles.description}>
        A credit grant is a named credit ledger with an associated priority,
        expiration, and cost basis. They will be automatically consumed by
        applicable invoices in your predetermined order.
      </Body>
      {formErrorMsg && (
        <Body level={2} className={styles.error}>
          {formErrorMsg}
        </Body>
      )}
      <Input
        className={classNames(styles.gray, styles.grantName)}
        name="Grant name"
        placeholder="Enter name"
        value={grantName}
        success={grantName.trim() !== ""}
        onChange={setGrantName}
      />
      <TextArea
        className={classNames(styles.gray, styles.reason)}
        name="Reason (optional)"
        placeholder="Enter reason"
        success={reason.trim() !== ""}
        value={reason}
        onChange={setReason}
      />
      {creditGrantTypeEnabled && (
        <div className="mb-8 mt-12 ">
          <Input
            className="mb-8 mt-4 [&>label]:text-gray-600"
            name="Credit grant type (optional)"
            tooltip="Credit grant type is an optional metadata field designed for organizing credit grants and enabling more advanced filtering capabilities."
            placeholder="Enter a value"
            value={creditGrantType}
            success={creditGrantType.trim() !== ""}
            onChange={setCreditGrantType}
          />
        </div>
      )}
      <div className={styles.productIdContainer}>
        <Toggle
          checked={isProductSpecificCredit}
          label={
            <Label className={styles.productSpecificCredit}>
              Product-specific credit
              <HelpCircleTooltip content="Does this credit grant apply to only a specific product or products? (By default, a credit grant applies to all products.)" />
            </Label>
          }
          onChange={(v) => {
            if (v) {
              setSelectedProductIds([]);
            } else {
              setSelectedProductIds(null);
            }
            setIsProductSpecificCredit(v);
          }}
        />
        {isProductSpecificCredit ? (
          <>
            <div className={styles.productId}>
              <Subtitle level={4} className={styles.productIdLabel}>
                Credit grant applies to
              </Subtitle>
              <HelpCircleTooltip content="The order in which you select products here will determine the order in which credits apply to line items on the invoice" />
            </div>
            <Select
              multiSelect
              options={(data?.products ?? []).map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              onChange={(v) => setSelectedProductIds(v)}
              value={selectedProductIds ?? []}
              placeholder="Select products"
              disabled={loading || !isProductSpecificCredit}
              loading={loading}
            />
          </>
        ) : null}
      </div>
      <div className={classNames(styles.gray, styles.pricingUnit)}>
        <PricingUnitSelector
          onChange={setPricingUnit}
          selectedCreditTypeId={pricingUnit?.id}
          allowCreation={true}
        />
      </div>
      <CreditInput
        name="Amount of credit granted"
        placeholder={`12.45 ${
          pricingUnit && pricingUnit.id != USD_CREDIT_ID
            ? displayCreditTypeName(pricingUnit)
            : ""
        }`.trim()}
        creditType={pricingUnit || USD_CREDIT_TYPE}
        className={classNames(styles.gray, styles.creditGranted)}
        allowZeroAmounts={false}
        success={amountGranted !== null}
        onChange={setAmountGranted}
        disabled={!pricingUnit}
      />
      <div className={classNames(styles.gray, styles.amountPaid)}>
        <CreditInput
          name="Amount paid"
          placeholder="123.45"
          creditType={amountPaidPricingUnit}
          className={classNames(styles.gray, styles.amountPaidInput)}
          allowZeroAmounts={true}
          initialValue={amountPaid !== null ? amountPaid.toString() : ""}
          success={amountPaid !== null}
          onChange={setAmountPaid}
          disabled={!pricingUnit}
        />
        <div className={styles.amountPaidPricingUnit}>
          <PricingUnitSelector
            onChange={(v) => {
              setAmountPaidPricingUnit(v);
              setAmountPaid(null);
            }}
            fiatOnly={true}
            allowCreation={false}
            selectedCreditTypeId={amountPaidPricingUnit.id}
            showName={false}
          />
        </div>
      </div>
      <CostBasis
        pricingUnit={pricingUnit}
        amountPaid={amountPaid}
        amountPaidPricingUnit={amountPaidPricingUnit}
        amountGranted={amountGranted}
      />
      <Subtitle level={4} className={styles.creditPurchaseText}>
        Would you like to invoice the customer for this credit grant?
        <HelpCircleTooltip content="Invoice the customer for the amount paid for this grant. This will be a new invoice issued on your desired date." />
      </Subtitle>
      <Toggle
        checked={isPurchase}
        label={
          <>
            Invoice customer for amount paid{" "}
            {amountPaid == null ? (
              "--"
            ) : (
              <RoundedCurrency
                amount={new Decimal(amountPaid)}
                creditType={amountPaidPricingUnit}
              />
            )}
          </>
        }
        onChange={() => setPurchase(!isPurchase)}
      />
      {isPurchase && (
        <DateInput
          disabled={!isPurchase}
          className={classNames(
            styles.gray,
            styles.datePickers,
            styles.invoiceDate,
          )}
          name="Invoice date"
          tooltip="Invoice date is the date this invoice will be issued."
          value={invoiceDate}
          isUTC
          success={!!invoiceDate}
          minDate={minEffectiveDate.toDate()}
          onChange={(d) => {
            const newInvoiceDate = dayjs.utc(d).toDate();
            setInvoiceDate(newInvoiceDate);
          }}
        />
      )}
      <div className={styles.datePickers}>
        <DateInput
          disabled={loading}
          className={classNames(styles.gray, styles.effectiveAt)}
          name="Effective date"
          tooltip={
            canBackdateGrants
              ? undefined
              : "The effective date must not be earlier than the end date of the most recent finalized invoice."
          }
          value={effectiveAt}
          minDate={minEffectiveDate.toDate()}
          isUTC
          success={!!effectiveAt}
          onChange={(d) => {
            const newEffectiveAtDate = dayjs.utc(d).toDate();
            setEffectiveAt(newEffectiveAtDate);
            if (expiresBefore && newEffectiveAtDate >= expiresBefore) {
              setExpiresBefore(undefined);
            }
          }}
        />
        <DateInput
          className={classNames(styles.gray, styles.expiresBefore)}
          name="Expiry date"
          tooltip="We recommend aligning the expiration to your customer’s billing period."
          value={expiresBefore}
          minDate={minExpiryDate}
          success={!!expiresBefore}
          isUTC
          onChange={(d) => setExpiresBefore(dayjs.utc(d).toDate())}
        />
      </div>
      {canSetBillingProvider && (
        <Select
          options={billingProviderOptions}
          value={billingProvider ?? ""}
          className="mb-32"
          placeholder="Select"
          name="Billing provider"
          tooltip="Associate a billing provider with this credit grant. This can be modified by editing the credit grant."
          onChange={(bp) => {
            if (bp === "") {
              setBillingProvider(undefined);
            } else {
              setBillingProvider(bp as BillingProviderEnum_Enum);
            }
          }}
        />
      )}
      <div className="mb-16">
        <IssueCreditGrantPriority
          customerId={customerId}
          creditType={pricingUnit}
          newGrantName={grantName}
          newGrantPriority={rawPriority}
          effectiveAt={effectiveAt}
          expiresBefore={expiresBefore}
          onChange={(newPriority, newRawPriority) => {
            setPriority(newPriority);
            setRawPriority(newRawPriority);
          }}
          productIds={selectedProductIds}
        />
      </div>
      {enableRolloverCreditGrants && (
        <div className="mb-16">
          {pricingUnit ? (
            <RolloverSettingsInputs
              value={rolloverSettingsFormState.value}
              onChange={(input) => {
                setRolloverSettingsFormState((s) => ({
                  ...s,
                  value: input,
                  error: undefined,
                }));
              }}
              onError={(error) => {
                setRolloverSettingsFormState((s) => ({ ...s, error }));
              }}
              pricingUnit={pricingUnit}
            />
          ) : (
            <RolloverSettingsInputs
              disabled
              disabledReason="Choose a pricing unit first to configure a rollover."
            />
          )}
        </div>
      )}
      <div className="mt-auto flex gap-8 self-end">
        <Button onClick={onClose} text="Cancel" theme="linkGray" />
        <Button
          loading={issueCreditGrantResults.loading}
          disabled={!fieldsAreValid() || issueCreditGrantResults.loading}
          onClick={submitForm}
          text="Save"
          theme="primary"
        />
      </div>
    </RightPane>
  );
};

interface CostBasisProps {
  pricingUnit?: CreditType;
  amountPaid: number | null;
  amountPaidPricingUnit: CreditType;
  amountGranted: number | null;
}
const CostBasis: React.FC<CostBasisProps> = ({
  pricingUnit,
  amountPaid,
  amountPaidPricingUnit,
  amountGranted,
}) => {
  let costBasisText = "Enter amounts to see cost basis";
  if (pricingUnit && amountPaid !== null && amountGranted !== null) {
    costBasisText = displayCostBasis(
      new Decimal(amountPaid).div(amountGranted),
      pricingUnit,
      amountPaidPricingUnit,
    );
  }
  return (
    <Body level={2} className={styles.costBasis}>
      {costBasisText}
    </Body>
  );
};
