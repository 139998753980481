import { Base } from "@metronome-industries/schedule-utils";
import { useMemo } from "react";
import { ApolloError } from "@apollo/client";
import { useGetOverridesTableDataQuery } from "./data.graphql";
import { useNow } from "lib/date";
import { useCommitProductNames } from "hooks/useCommitProductNames";
import { ExternalCommitType } from "types/generated-graphql/__types__";
import { useFeatureFlag } from "lib/launchdarkly";

type UseOverridesTableDataArgs = {
  customerId: string;
  contractId: string;
  rateCardId: string;
};
type UseOverridesTableDataReturn = {
  data: {
    productsById: Map<string, { id: string; name: string }>;
    commitsById: Map<string, { id: string; name: string }>;
    creditTypeByProductId: Map<string, Base.CreditType>;
  };
  loading: boolean;
  error: ApolloError | undefined;
};

/**
 * React hook that returns data needed for overrides table in the customer's contract overview page.
 */
export function useOverridesTableData(
  args: UseOverridesTableDataArgs,
): UseOverridesTableDataReturn {
  const now = useNow();
  const overridesQuery = useGetOverridesTableDataQuery({
    variables: { customerId: args.customerId, rateCardId: args.rateCardId },
  });
  const canEditContract = useFeatureFlag<boolean>(
    "allow-contract-editing",
    false,
  );
  const commitNamesQuery = useCommitProductNames({
    customerId: args.customerId,
    contractId: args.contractId,
    externalType: ExternalCommitType.Commit,
    include_v2_fields: canEditContract ?? false,
  });

  const productsById = useMemo(() => {
    const productsById = new Map<string, { id: string; name: string }>();
    for (const product of overridesQuery.data?.products_and_rate_cards
      .products ?? []) {
      productsById.set(product.id, {
        id: product.id,
        name: product.current.name,
      });
    }
    return productsById;
  }, [overridesQuery.data?.products_and_rate_cards.products]);

  const commitsById = useMemo(() => {
    const commitsById = new Map<string, { id: string; name: string }>();
    for (const commit of commitNamesQuery.data) {
      commitsById.set(commit.id, {
        id: commit.id,
        name: commit.productName,
      });
    }
    return commitsById;
  }, [
    overridesQuery.data?.Customer_by_pk?.commits,
    now,
    commitNamesQuery.data,
  ]);

  const creditTypeByProductId = useMemo(() => {
    const creditTypeByProductId = new Map<string, Base.CreditType>();
    for (const product of overridesQuery.data?.products_and_rate_cards.rate_card
      .rates_per_product ?? []) {
      if ("credit_type" in product.rates[0].rate) {
        creditTypeByProductId.set(product.product.id, {
          id: product.rates[0].rate.credit_type.id,
          name: product.rates[0].rate.credit_type.name,
          clientId: product.rates[0].rate.credit_type.client_id,
          environmentType: product.rates[0].rate.credit_type.environment_type,
        });
      }
    }
    return creditTypeByProductId;
  }, [
    overridesQuery.data?.products_and_rate_cards.rate_card.rates_per_product,
  ]);

  return {
    data: { productsById, commitsById, creditTypeByProductId },
    loading: overridesQuery.loading || commitNamesQuery.loading,
    error: overridesQuery.error,
  };
}
