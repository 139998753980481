import { dayjs } from "lib/dayjs";
import { Decimal } from "decimal.js";
import { v4 as uuid } from "uuid";

// TODO: avoid importing from graphql files in pages/
import { FetchDraftPlanQueryResult } from "pages/PlanWizards/data/queries.graphql";
import { CustomCreditType, FiatCreditType } from "types/credit-types";
import {
  ChargeTypeEnum_Enum,
  CollectionScheduleEnum_Enum,
  CreditTypeConversionInput,
  MinimumInput,
  PlanInput,
  PricedProductInput,
  RecurringCreditGrantInput,
  TieringModeEnum_Enum,
  TrialSpecInput,
} from "types/generated-graphql/__types__";

import { getPriceRampDurations } from "./ramps";

import {
  billingDayOfPeriodToEnum,
  collectionScheduleToEnum,
  CompositeCharge,
  DraftPlan as UIDraftPlan,
  Minimum,
  PlanDetails,
  PricedPricingFactor,
  PricedProduct,
  RecurringCreditGrant,
  TrialSpec,
} from "./types";

interface BackendDraftPlan
  extends NonNullable<
    NonNullable<FetchDraftPlanQueryResult["data"]>["DraftPlan_by_pk"]
  > {
  data: {
    selectedProductIds?: string[];
  };
}

/**
 * Pricing factors that were priced on a draft plan before composite charges
 * were created will not have a `chargeType`, so we must infer its type from
 * other data.
 */
export function getChargeType(pricedPricingFactor: PricedPricingFactor) {
  if (pricedPricingFactor.chargeType) {
    return pricedPricingFactor.chargeType;
  } else {
    return !!pricedPricingFactor.prices?.length
      ? ChargeTypeEnum_Enum.Usage
      : !!pricedPricingFactor.flatFees?.length
        ? ChargeTypeEnum_Enum.Flat
        : ChargeTypeEnum_Enum.Composite;
  }
}

export function serializeDraftPlan(plan: UIDraftPlan) {
  return {
    ...plan,
    id: undefined,
    revision: undefined,
    selectedProductIds: plan.selectedProductIds ?? [],
  };
}

export function deserializeDraftPlan(plan: BackendDraftPlan): UIDraftPlan {
  const deserializedDraft: UIDraftPlan = {
    ...plan.data,
    id: plan.id,
    revision: plan.revision,
    selectedProductIds: plan.data.selectedProductIds ?? [],
  };

  return {
    ...deserializedDraft,
    customerPlanInfo: deserializedDraft.customerPlanInfo
      ? {
          ...deserializedDraft.customerPlanInfo,
          startDate: deserializedDraft.customerPlanInfo.startDate
            ? dayjs.utc(deserializedDraft.customerPlanInfo.startDate).toDate()
            : deserializedDraft.customerPlanInfo.startDate,
          endDate: deserializedDraft.customerPlanInfo.endDate
            ? dayjs.utc(deserializedDraft.customerPlanInfo.endDate).toDate()
            : deserializedDraft.customerPlanInfo.endDate,
        }
      : undefined,
  };
}

function deserializeCompositeCharge(
  compositeCharge: PlanDetails["PricedProducts"][0]["PricedProductPricingFactors"][0]["CompositeCharges"][0],
): CompositeCharge[] {
  return compositeCharge.CompositeChargeTiers.map((tier) => ({
    value: tier.value,
    compositeMinimum: Number(tier.composite_minimum ?? 0),
    quantity: new Decimal(compositeCharge.quantity ?? 0),
    type: compositeCharge.type,
    pricingFactors: compositeCharge.CompositeChargePricingFactors.map(
      (ccpf) => ({
        id: ccpf.ProductPricingFactor.id,
        name: ccpf.ProductPricingFactor.name,
      }),
    ),
  }));
}

// This function returns a DraftPlan object from Plan data fetched from the
// database. It should have a defined value for every field in a DraftPlan,
// because it was completely filled out when it was saved to the database.
// However, it won't have an `id` field, because that may cause conflicts
// with plan editing or forking. The `revision` field is also not included.
// startingOn is not included because it will never be set from the database,
// the user has to set it themselves when editing the plan
export function planToDraftPlan(
  plan: PlanDetails,
): Required<Omit<UIDraftPlan, "id" | "revision" | "startingOn">> {
  const startPeriodSet = new Set<number>(
    plan.PricedProducts.flatMap((pp) =>
      pp.PricedProductPricingFactors.map((pppf) => Number(pppf.start_period)),
    ),
  );
  const deserializedPriceRamps = getPriceRampDurations(startPeriodSet);

  // In a DraftPlan, the number of pricedProducts is equal to the number
  // of unique Products selected for the plan, and each draft pricedProduct
  // has a list of pricing factors that specify the startPeriod and prices
  // for that pricing factor.
  const deserializedPricedProducts: PricedProduct[] = plan.PricedProducts.map(
    (pp) => {
      const pricedPricingFactors: PricedPricingFactor[] = [];
      const pricingFactorIdSet = new Set<string>(
        pp.PricedProductPricingFactors.map((pppf) => {
          return pppf.ProductPricingFactor.id;
        }),
      );

      // In a DraftPlan, there needs to be a priced productPricingFactor for
      // every ramp / pricingFactor combination. (e.g. if a Product has 4
      // pricing factors and the plan has 3 ramps, there should be 15 elements
      // in the Product's productPricingFactor list)
      // This is unlike in the database, where PricedProductPricingFactor
      // start_period values must be unique for each pricing factor, but not
      // necessarily map 1:1 to every existing start_period on that plan.
      Array.from(startPeriodSet).forEach((startPeriod) => {
        Array.from(pricingFactorIdSet).forEach((pfId) => {
          const pppfWithStartPeriod = pp.PricedProductPricingFactors.find(
            (pppf) =>
              pppf.ProductPricingFactor.id === pfId &&
              Number(pppf.start_period) === startPeriod,
          );
          if (pppfWithStartPeriod) {
            pricedPricingFactors.push({
              pricingFactorId: pppfWithStartPeriod.ProductPricingFactor.id,
              startPeriod: startPeriod,
              tierResetFrequency: pppfWithStartPeriod.tier_reset_frequency,
              volumePricing:
                pppfWithStartPeriod.tiering_mode ===
                TieringModeEnum_Enum.Volume,
              blockPricing:
                pppfWithStartPeriod.Prices.length &&
                pppfWithStartPeriod.Prices[0].block_size
                  ? {
                      size: Number(pppfWithStartPeriod.Prices[0].block_size),
                      roundingBehavior:
                        pppfWithStartPeriod.Prices[0].block_rounding_behavior ??
                        undefined,
                    }
                  : undefined,
              prices: pppfWithStartPeriod.Prices.map((price) => {
                return {
                  value: price.value.toString(),
                  metricMinimum: Number(price.metric_minimum),
                  key: uuid(),
                };
              }),
              seatPrices: pppfWithStartPeriod.SeatPrices.map((seatPrice) => {
                return {
                  ...seatPrice,
                  key: uuid(),
                };
              }),
              chargeType:
                pppfWithStartPeriod.ProductPricingFactor.charge_type_enum,
              flatFees: pppfWithStartPeriod.FlatFees?.map((ff) => {
                return {
                  value: ff.value.toString(),
                  metricMinimum: Number(ff.metric_minimum),
                  quantity: new Decimal(ff.quantity),
                  isProrated: ff.is_prorated,
                  collectionSchedule: ff.collection_schedule,
                  collectionInterval: ff.collection_interval,
                  key: uuid(),
                };
              }),
              compositeCharge: pppfWithStartPeriod.CompositeCharges?.flatMap(
                (cc) => deserializeCompositeCharge(cc),
              ),
              skipRamp: pppfWithStartPeriod.skip_ramp,
            });
          } else {
            const pricingFactorsSortedByStartPeriod =
              pp.PricedProductPricingFactors.filter(
                (pppf) => pppf.ProductPricingFactor.id === pfId,
              ).sort((a, b) => Number(b.start_period) - Number(a.start_period));
            const mostRecentPricingFactor =
              pricingFactorsSortedByStartPeriod.find(
                (pf) => Number(pf.start_period) < startPeriod,
              );
            if (mostRecentPricingFactor) {
              pricedPricingFactors.push({
                pricingFactorId:
                  mostRecentPricingFactor.ProductPricingFactor.id,
                startPeriod: startPeriod,
                tierResetFrequency:
                  mostRecentPricingFactor.tier_reset_frequency,
                volumePricing:
                  mostRecentPricingFactor.tiering_mode ===
                  TieringModeEnum_Enum.Volume,
                prices: mostRecentPricingFactor.Prices.map((price) => {
                  return {
                    value: price.value.toString(),
                    metricMinimum: Number(price.metric_minimum),
                    key: uuid(),
                  };
                }),
                seatPrices: mostRecentPricingFactor.SeatPrices.map(
                  (seatPrice) => {
                    return {
                      ...seatPrice,
                      key: uuid(),
                    };
                  },
                ),
                chargeType:
                  mostRecentPricingFactor.ProductPricingFactor.charge_type_enum,
                flatFees: mostRecentPricingFactor.FlatFees?.map((ff) => {
                  return {
                    value: ff.value.toString(),
                    metricMinimum: Number(ff.metric_minimum),
                    quantity: new Decimal(ff.quantity),
                    collectionSchedule: ff.collection_schedule,
                    collectionInterval: ff.collection_interval,
                    isProrated: ff.is_prorated,
                    key: uuid(),
                  };
                }),
                compositeCharge:
                  mostRecentPricingFactor.CompositeCharges?.flatMap((cc) =>
                    deserializeCompositeCharge(cc),
                  ),
                skipRamp: mostRecentPricingFactor.skip_ramp,
                blockPricing:
                  mostRecentPricingFactor.Prices.length &&
                  mostRecentPricingFactor.Prices[0].block_size
                    ? {
                        size: Number(
                          mostRecentPricingFactor.Prices[0].block_size,
                        ),
                        roundingBehavior:
                          mostRecentPricingFactor.Prices[0]
                            .block_rounding_behavior ?? undefined,
                      }
                    : undefined,
              });
            }
          }
        });
      });

      return {
        id: pp.id,
        productId: pp.Product.id,
        creditType: pp.CreditType,
        pricingFactors: pricedPricingFactors,
      };
    },
  );

  const deserializedMinimums: Minimum[] = [];
  const invoiceMinimums = plan.Minimums;
  if (invoiceMinimums.length) {
    Array.from(startPeriodSet).forEach((startPeriod) => {
      const startPeriodMin = invoiceMinimums.find(
        (min) => Number(min.start_period) === startPeriod,
      );
      if (startPeriodMin) {
        deserializedMinimums.push({
          startPeriod: startPeriod,
          value: startPeriodMin.value.toString(),
          creditType: startPeriodMin.CreditType,
        });
      } else {
        const minimumsSortedByStartPeriod = invoiceMinimums.sort(
          (a, b) => Number(b.start_period) - Number(a.start_period),
        );
        const mostRecentInvoiceMinimum = minimumsSortedByStartPeriod.find(
          (min) => Number(min.start_period) < startPeriod,
        );
        if (mostRecentInvoiceMinimum) {
          deserializedMinimums.push({
            startPeriod: startPeriod,
            value: mostRecentInvoiceMinimum.value.toString(),
            creditType: mostRecentInvoiceMinimum.CreditType,
          });
        }
      }
    });
  }

  return {
    name: plan.name,
    description: plan.description,
    hasPriceRamps: plan.PricedProducts.some((pp) =>
      pp.PricedProductPricingFactors.some(
        (pppf) => Number(pppf.start_period) > 0,
      ),
    ),
    hasMinimums: !!plan.Minimums.length,
    billingFrequency: plan.billing_frequency,
    billingProvider: plan.billing_provider,
    billingDayOfPeriod: plan.service_period_start_type,
    hasTrial: !!plan.TrialSpec,
    trialSpec: plan.TrialSpec
      ? {
          length: plan.TrialSpec.length_in_days
            ? Number(plan.TrialSpec.length_in_days)
            : undefined,
          caps: plan.TrialSpec.TrialSpecSpendingCaps.map((c) => ({
            amount: new Decimal(c.amount),
            creditTypeId: c.CreditType.id,
          })),
        }
      : null,
    ramps: deserializedPriceRamps,
    selectedProductIds: plan.PricedProducts.map((pp) => pp.Product.id),
    originalProductIds: plan.PricedProducts.map((pp) => pp.Product.id),
    pricedProducts: deserializedPricedProducts,
    creditTypeConversions: plan.CreditTypeConversions.map((conv) => ({
      customCreditType: conv.CustomCreditType as CustomCreditType,
      fiatCreditType: conv.FiatCreditType as FiatCreditType,
      startPeriod: Number(conv.start_period),
      toFiatConversionFactor: conv.to_fiat_conversion_factor
        ? Number(conv.to_fiat_conversion_factor)
        : undefined,
    })),
    minimums: deserializedMinimums,
    defaultLength: plan.default_length_months,
    // When editing / duplicating a plan we don't want to re-assign the customer plan so we just null it out
    hasCustomer: false,
    customerPlanInfo: null,
    hasRecurringGrant: plan.RecurringCreditGrants.length > 0,
    recurringGrant: plan.RecurringCreditGrants.length
      ? {
          name: plan.RecurringCreditGrants[0].name,
          reason: plan.RecurringCreditGrants[0].reason ?? undefined,
          amountGranted: plan.RecurringCreditGrants[0].amount_granted,
          amountGrantedCreditType:
            plan.RecurringCreditGrants[0].AmountGrantedCreditType,
          amountPaid: plan.RecurringCreditGrants[0].amount_paid,
          amountPaidCreditType: plan.RecurringCreditGrants[0]
            .AmountPaidCreditType as FiatCreditType,
          effectiveDuration: plan.RecurringCreditGrants[0].effective_duration,
          priority: plan.RecurringCreditGrants[0].priority,
          productIds: plan.RecurringCreditGrants[0].product_ids ?? undefined,
          requireProductIds:
            (plan.RecurringCreditGrants[0].product_ids?.length ?? 0) > 0,
          recurrence: plan.RecurringCreditGrants[0].recurrence_interval
            ? {
                duration:
                  plan.RecurringCreditGrants[0].recurrence_duration ??
                  undefined,
                interval: plan.RecurringCreditGrants[0].recurrence_interval,
              }
            : null,
          sendInvoice: plan.RecurringCreditGrants[0].send_invoice,
        }
      : null,
    seatBillingFrequency: plan.seat_billing_frequency ?? null,
  };
}

export function getDraftPlanInsert(draftPlan: UIDraftPlan): PlanInput {
  if (!draftPlan.name || !draftPlan.description) {
    throw new Error("Cannot save plan without name and description");
  }

  if (!draftPlan.pricedProducts?.length) {
    throw new Error("Cannot save plan without products");
  }

  if (!draftPlan.billingFrequency || !draftPlan.billingDayOfPeriod) {
    throw new Error("Cannot save plan without billing period information");
  }

  const pricedProductsInsert: PricedProductInput[] = (
    draftPlan.pricedProducts ?? []
  ).map((pp, i) => {
    if (!pp.creditType) {
      throw new Error(
        "Cannot save plan without pricing units for all products",
      );
    }

    const ordering = draftPlan.selectedProductIds?.indexOf(pp.productId);
    if (ordering === undefined || ordering === -1) {
      throw new Error(
        `Missing ordering information for product ${pp.productId}`,
      );
    }
    return {
      credit_type_id: pp.creditType?.id,
      product_id: pp.productId,
      ordering: ordering,
      pricing_factors: pp.pricingFactors.map((pf) => {
        if (pf.startPeriod === undefined) {
          throw new Error("Cannot save plan without ramp definitions");
        }
        const chargeType = getChargeType(pf);
        if (chargeType === ChargeTypeEnum_Enum.Flat) {
          if (!pf.flatFees) {
            throw new Error("Cannot save plan with undefined flatFees");
          }
          return {
            charge_type_enum: ChargeTypeEnum_Enum.Flat,
            tiering_mode: pf.volumePricing
              ? TieringModeEnum_Enum.Volume
              : TieringModeEnum_Enum.Standard,
            tier_reset_frequency: pf.tierResetFrequency,
            prices: pf.flatFees?.map((ff) => {
              if (!ff.value) {
                throw new Error("Cannot save plan with valueless flat fee");
              }
              return {
                metric_minimum: ff.metricMinimum?.toString() ?? "0",
                value: ff.value,
                quantity: (pf.flatFees?.[0]?.quantity ?? 1).toString(),
                is_prorated: pf.flatFees?.[0]?.isProrated ?? false,
                collection_interval: (ff.collectionInterval ?? 1).toString(),
                collection_schedule: pf.flatFees?.[0]?.collectionSchedule
                  ? collectionScheduleToEnum(pf.flatFees[0].collectionSchedule)
                  : CollectionScheduleEnum_Enum.Arrears,
              };
            }),
            product_pricing_factor_id: pf.pricingFactorId,
            start_period: pf.startPeriod.toString(),
            skip_ramp: pf.skipRamp ?? false,
          };
        } else if (chargeType === ChargeTypeEnum_Enum.Composite) {
          if (!pf.compositeCharge) {
            throw new Error("Cannot save plan with undefined compositeCharge");
          }
          return {
            charge_type_enum: ChargeTypeEnum_Enum.Composite,
            tiering_mode: pf.volumePricing
              ? TieringModeEnum_Enum.Volume
              : TieringModeEnum_Enum.Standard,
            prices: pf.compositeCharge?.map((cc) => {
              if (!cc.value) {
                throw new Error(
                  "Cannot save plan with valueless composite charge",
                );
              }
              return {
                quantity: (pf.compositeCharge?.[0]?.quantity ?? 0).toString(),
                value: cc.value,
                composite_charge_type: cc.type,
                composite_minimum: cc.compositeMinimum?.toString() ?? "0",
                product_pricing_factor_ids:
                  pf.compositeCharge?.[0]?.pricingFactors?.map((pf) => pf.id),
              };
            }),
            product_pricing_factor_id: pf.pricingFactorId,
            start_period: pf.startPeriod.toString(),
            skip_ramp: pf.skipRamp ?? false,
          };
        } else if (chargeType === ChargeTypeEnum_Enum.Usage) {
          if (!pf.prices || !pf.prices.length) {
            throw new Error("Cannot save plan without prices for all charges");
          }
          return {
            charge_type_enum: ChargeTypeEnum_Enum.Usage,
            tiering_mode: pf.volumePricing
              ? TieringModeEnum_Enum.Volume
              : TieringModeEnum_Enum.Standard,
            tier_reset_frequency: pf.tierResetFrequency,
            prices: pf.prices.map((price) => {
              if (price.value === undefined) {
                throw new Error("Cannot save plan while missing price value");
              }
              return {
                metric_minimum: price.metricMinimum?.toString(),
                value: price.value,
                blocks: pf.blockPricing
                  ? {
                      size: pf.blockPricing.size.toString(),
                      rounding_behavior: pf.blockPricing.roundingBehavior,
                    }
                  : null,
              };
            }),
            product_pricing_factor_id: pf.pricingFactorId,
            start_period: pf.startPeriod.toString(),
            skip_ramp: pf.skipRamp ?? false,
          };
        } else if (chargeType === ChargeTypeEnum_Enum.Seat) {
          if (!pf.seatPrices || !pf.seatPrices.length) {
            throw new Error("Cannot save plan without prices for all charges");
          }
          return {
            charge_type_enum: ChargeTypeEnum_Enum.Seat,
            prices: pf.seatPrices?.map((price) => ({
              metric_minimum: "0",
              value: price.value ?? "0",
              quantity: price.initial_quantity?.toString() ?? "0",
              is_prorated: price.is_prorated ?? false,
            })),
            product_pricing_factor_id: pf.pricingFactorId,
            start_period: pf.startPeriod.toString(),
            skip_ramp: pf.skipRamp ?? false,
          };
        } else {
          throw new Error("Missing charge type");
        }
      }),
    };
  });

  const minimumsInsert: MinimumInput[] = (draftPlan.minimums ?? []).map(
    (min) => {
      if (!min.creditType) {
        throw new Error(
          "Cannot save plan without pricing units for all minimums",
        );
      }
      if (min.value === undefined) {
        throw new Error("Cannot save plan without values for all minimums");
      }

      return {
        credit_type_id: min.creditType.id,
        name: "Invoice minimum",
        start_period: min.startPeriod.toString(),
        value: min.value,
      };
    },
  );

  const creditTypeConversionsInsert: CreditTypeConversionInput[] = (
    draftPlan.creditTypeConversions ?? []
  ).map((conversion) => {
    return {
      custom_credit_type_id: conversion.customCreditType.id,
      fiat_currency_credit_type_id: conversion.fiatCreditType.id,
      start_period: conversion.startPeriod.toString(),
      to_fiat_conversion_factor:
        conversion.toFiatConversionFactor?.toString() ?? "0",
    };
  });

  const trialSpecInsert: TrialSpecInput | undefined = serializeTrialSpec(
    draftPlan.hasTrial,
    draftPlan.trialSpec,
  );

  const recurringCreditGrantInsert: RecurringCreditGrantInput | undefined =
    serializeRecurringCreditGrant(
      draftPlan.hasRecurringGrant,
      draftPlan.recurringGrant,
    );

  return {
    name: draftPlan.name,
    description: draftPlan.description,
    pricedProducts: pricedProductsInsert,
    minimums: minimumsInsert,
    creditTypeConversions: creditTypeConversionsInsert,
    billingFrequency: draftPlan.billingFrequency,
    billingProvider: draftPlan.billingProvider,
    servicePeriodStartType: billingDayOfPeriodToEnum(
      draftPlan.billingDayOfPeriod,
    ),
    defaultLength: draftPlan.defaultLength,
    trialSpec: trialSpecInsert,
    recurringCreditGrant: recurringCreditGrantInsert,
    seatBillingFrequency: draftPlan.seatBillingFrequency,
  };
}

export const removeProductsFromDraftPlan = (
  draftPlan: UIDraftPlan,
  productIds: string[],
): UIDraftPlan => {
  const newSelectedProductIds = (draftPlan.selectedProductIds ?? []).filter(
    (id) => !productIds.includes(id),
  );

  const newPricedProducts = (draftPlan.pricedProducts ?? []).filter(
    (pp) => !productIds.find((id) => id === pp.productId),
  );

  const newGrantProducts = (draftPlan.recurringGrant?.productIds ?? []).filter(
    (p) => !productIds.find((id) => id === p),
  );

  const recurringGrant = draftPlan.recurringGrant
    ? {
        ...draftPlan.recurringGrant,
        productIds: newGrantProducts.length > 0 ? newGrantProducts : undefined,
      }
    : undefined;

  return {
    ...draftPlan,
    selectedProductIds: newSelectedProductIds,
    pricedProducts: newPricedProducts,
    recurringGrant,
  };
};

type SpendingCap = Exclude<
  RequiredNonNullable<UIDraftPlan>["trialSpec"]["caps"],
  undefined
>[0];

function isTrialSpendingCapsComplete(
  caps: SpendingCap[],
): caps is Required<SpendingCap>[] {
  return caps.every((c) => c.amount && c.creditTypeId);
}

export function serializeTrialSpec(
  hasTrial: boolean | undefined,
  trialSpec: TrialSpec | undefined | null,
): TrialSpecInput | undefined {
  let trialSpecInsert: TrialSpecInput | undefined = undefined;

  if (hasTrial) {
    const caps = trialSpec?.caps;
    if (
      !trialSpec ||
      !trialSpec.length ||
      (caps && !isTrialSpendingCapsComplete(caps))
    ) {
      throw new Error("Cannot save plan with trial without trial details");
    }
    trialSpecInsert = {
      length_in_days: trialSpec.length,
      spending_caps:
        caps?.map((c) => ({
          amount: c.amount.toString(),
          credit_type_id: c.creditTypeId,
        })) ?? [],
    };
  }
  return trialSpecInsert;
}

function serializeRecurringCreditGrant(
  hasRecurringGrant: boolean | undefined,
  recurringGrant: RecurringCreditGrant | null | undefined,
): RecurringCreditGrantInput | undefined {
  if (hasRecurringGrant && recurringGrant) {
    if (!isRecurringCreditGrantValid(recurringGrant)) {
      throw new Error(
        "Cannot insert plan with incomplete recurring credit grant",
      );
    }
    return {
      amount_granted: recurringGrant.amountGranted ?? "",
      amount_granted_credit_type_id:
        recurringGrant.amountGrantedCreditType?.id ?? "",
      amount_paid: recurringGrant.amountPaid ?? "",
      amount_paid_credit_type_id: recurringGrant.amountPaidCreditType?.id ?? "",
      effective_duration: recurringGrant.effectiveDuration ?? 1,
      name: recurringGrant.name ?? "",
      priority: recurringGrant.priority ?? "1",
      product_ids: recurringGrant.productIds,
      reason: recurringGrant.reason,
      recurrence_duration: recurringGrant.recurrence?.duration,
      recurrence_interval: recurringGrant.recurrence?.interval,
      send_invoice: recurringGrant.sendInvoice ?? false,
    };
  }

  return undefined;
}
function checkPositiveInteger(value: number | undefined) {
  return value && value > 0 && new Decimal(value).isInt();
}

export function isRecurringCreditGrantValid(
  recurringGrant: RecurringCreditGrant,
) {
  return (
    recurringGrant.name &&
    recurringGrant.amountGrantedCreditType &&
    recurringGrant.amountGranted &&
    recurringGrant.amountPaidCreditType &&
    recurringGrant.amountPaid &&
    (recurringGrant.recurrence === null ||
      (recurringGrant.recurrence &&
        checkPositiveInteger(recurringGrant.recurrence.interval) &&
        (recurringGrant.recurrence.duration == null ||
          checkPositiveInteger(recurringGrant.recurrence.duration)))) &&
    checkPositiveInteger(recurringGrant.effectiveDuration) &&
    (!recurringGrant.requireProductIds ||
      (recurringGrant.productIds?.length ?? 0) > 0) &&
    recurringGrant.priority &&
    Number(recurringGrant.priority) > 0
  );
}
