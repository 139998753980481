import React from "react";
import { useRequiredParam } from "lib/routes/params";

import { CustomerTab } from "pages/Customer/components/CustomerTab";
import { AuditLogTable } from "components/AuditLogTable";

const AuditLogsTab: React.FC = () => {
  const customerId = useRequiredParam("customerId");

  return (
    <CustomerTab>
      <AuditLogTable resourceType="customer" resourceID={customerId} />
    </CustomerTab>
  );
};

export default AuditLogsTab;
