import React from "react";
import { Popup } from "components/Popup";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { useSnackbar } from "components/Snackbar";
import { TokensQuery } from "../../data/tokens.graphql";
import { useArchiveTokenMutation } from "./queries.graphql";

type Token = TokensQuery["list_tokens"][0];

interface ArchiveTokenModalProps {
  onClose: () => void;
  token: Token;
}

const ArchiveTokenModal: React.FC<ArchiveTokenModalProps> = ({
  onClose,
  token,
}) => {
  const [archiveToken, archiveTokenResults] = useArchiveTokenMutation();
  const pushMessage = useSnackbar();

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            disabled={token.id === undefined || archiveTokenResults.loading}
            loading={archiveTokenResults.loading}
            onClick={async () => {
              if (token.id) {
                await archiveToken({
                  variables: {
                    id: token.id,
                  },
                });
                pushMessage({
                  content: "Token deactivated",
                  type: "success",
                });
              }
              onClose();
            }}
            text="Deactivate token"
            theme="linkDestructive"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Are you sure you want to deactivate this token?"
    >
      <Body level={2}>
        Requests made with this API token will no longer be accepted. This
        change may take up to 5 minutes to apply and cannot be undone.
      </Body>
    </Popup>
  );
};

export default ArchiveTokenModal;
