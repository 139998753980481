import React from "react";
import { Headline } from "design-system";

import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";

import { FormController, Form } from "lib/FormController";
import { RightPane } from "components/Popup";

import { useConfirmClose } from "../../components/ConfirmClose";
import { FooterBar } from "../../components/FooterBar";

interface Props<S extends Form.Shape> extends React.PropsWithChildren {
  edit?: Form.Update<S>;
  onSave: (valid: Form.Values<S>) => void;
  onCancel: () => void;
  onDelete?: () => void;

  ctrl: FormController<S>;
  title: string;
}

export function AdditionalTermFlyover<S extends Form.Shape>(props: Props<S>) {
  const [confirmingClose, confirmClose] = useConfirmClose(
    props.ctrl,
    props.onCancel,
  );

  const onSubmit = FormController.useSubmitHandler(props.ctrl, (valid) => {
    props.onSave(valid);
  });

  return (
    <>
      {confirmingClose}
      <RightPane
        isOpen
        onRequestClose={confirmClose}
        size="xl"
        contentClassName="!p-0"
      >
        <form className="flex h-full flex-col" onSubmit={onSubmit}>
          <header className="flex items-center border-b border-deprecated-gray-100 bg-gray-50 px-12 py-8">
            <Headline level={6} className="grow">
              {props.title}
            </Headline>
            <IconButton
              className="m-0"
              onClick={() => props.onCancel()}
              theme="secondary"
              icon="xClose"
            />
          </header>
          <div className="flex grow flex-col gap-32 overflow-y-auto p-12">
            {props.children}
          </div>
          <FooterBar
            left={
              props.onDelete ? (
                <Button
                  onClick={props.onDelete}
                  text="Delete"
                  theme="linkDestructive"
                  leadingIcon="trash03"
                />
              ) : null
            }
            right={
              <>
                <Button
                  onClick={() => props.onCancel()}
                  text="Cancel"
                  theme="linkGray"
                />
                <Button
                  disabled={!props.ctrl.appearsValid()}
                  text={props.edit ? "Save" : "Create"}
                  theme="primary"
                  type="submit"
                />
              </>
            }
          />
        </form>
      </RightPane>
    </>
  );
}
