import React, { useEffect } from "react";
import { InputSection, WizardSplitPage } from "../../../../components/Wizard";
import { Body, Select } from "../../../../design-system";
import { useDraftPlan } from "../../context";
import { DraftPlan } from "../../../../lib/plans/types";
import { BillingFrequencyEnum_Enum } from "types/generated-graphql/__types__";

export const annualSeatsConfigIsDone = (draftPlan: DraftPlan) => {
  return !!(
    draftPlan.seatBillingFrequency || draftPlan.seatBillingFrequency === null
  );
};

export const AnnualSeatsPage: React.FC<{ editing: boolean }> = ({
  editing,
}) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  useEffect(() => {
    if (draftPlan.seatBillingFrequency === undefined) {
      setDraftPlan({
        ...draftPlan,
        seatBillingFrequency: null,
      });
    }
  }, []);
  return (
    <WizardSplitPage>
      <InputSection
        header=""
        subtitle={
          <Body level={1}>
            Seats can align to the plan’s invoicing frequency, or they can be
            billed annually from the start date of the plan.
          </Body>
        }
      >
        <Select
          name="Seat invoice frequency"
          placeholder=""
          options={[
            {
              value: "NONE",
              label: "Align to plan invoicing frequency",
            },
            {
              value: "ANNUAL",
              label: "Bill annually",
            },
          ]}
          onChange={(v) => {
            setDraftPlan({
              ...draftPlan,
              seatBillingFrequency:
                v === "ANNUAL" ? BillingFrequencyEnum_Enum.Annual : null,
            });
          }}
          disabled={editing}
          value={draftPlan.seatBillingFrequency ?? "NONE"}
        />
      </InputSection>
    </WizardSplitPage>
  );
};
