import React, { forwardRef, useImperativeHandle } from "react";
import "style/index.css";
import { useEventCountsQuery } from "./queries.graphql";
import { dayjs } from "lib/dayjs";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { EventGraphCard } from "tenaissance/components/EventGraphCard";

interface EventsGraphProps {
  startingAfter: Date;
  endingBefore: Date;
  ingestAliases?: string[];
  duplicates?: boolean;
  billableMetricIDs?: string[];
  // TODO(PENG-2087): implement reference markers for transaction IDs
  transaction_ids?: string[];
}

export type EventsGraphRef = {
  refetch: () => void;
};

export const EventsGraph = forwardRef((props: EventsGraphProps, ref) => {
  const { environmentType } = useEnvironment();
  const startingAfter = dayjs.utc(props.startingAfter);
  const endingBefore = dayjs.utc(props.endingBefore);

  const { data, loading, refetch } = useEventCountsQuery({
    variables: {
      environment_type: environmentType,
      starting_after: startingAfter.format(),
      ending_before: endingBefore.format(),
      ingest_aliases: props.ingestAliases,
      billable_metric_ids: props.billableMetricIDs,
    },
  });

  useImperativeHandle(ref, () => ({
    refetch: () => {
      void refetch();
    },
  }));

  return (
    <EventGraphCard
      data={data}
      loading={loading}
      startingAfter={startingAfter}
      endingBefore={endingBefore}
      eventsToShow={
        props.duplicates === undefined
          ? "all"
          : props.duplicates
            ? "duplicatesOnly"
            : "nonDuplicatesOnly"
      }
    />
  );
});
