import Decimal from "decimal.js";
import { RecurringSchedule } from "@metronome-industries/schedule-utils";
import { Schema } from "../Schema";

export function resolveRecurringSchedule(
  recurringSchedule: Schema.Types.RecurringSchedule,
) {
  const { frequency, amountDistribution } = recurringSchedule;
  const startDate = new Date(recurringSchedule.startDate);
  const endDate = new Date(recurringSchedule.endDate);
  const unitPrice = new Decimal(recurringSchedule.unitPrice);
  const quantity = new Decimal(recurringSchedule.quantity);

  return RecurringSchedule.resolve({
    amountDistribution,
    frequency,
    startDate,
    endDate,
    unitPrice,
    quantity,
  });
}
