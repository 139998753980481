import React from "react";
import BillableMetricPlans from "./tabs/Plans";
import BillableMetricProducts from "./tabs/Products";
import BillableMetricSummary from "./tabs/Summary";
import { Navigate, RouteObject, useLocation } from "react-router-dom";
import { useRequiredParam } from "lib/routes/params";

import {
  BillableMetricDetailDocument,
  useBillableMetricDetailQuery,
  useSetBillableMetricNameMutation,
} from "./queries.graphql";
import { AppShell } from "components/PageContainer";

import NotFoundPage from "pages/404";
import { TextSkeleton } from "components/Skeleton";
import { EmptyState } from "tenaissance/components/EmptyState";
import { PopoverMenu } from "components/PopoverMenu";
import { useActions } from "lib/billableMetrics/actions";
import { IconButton } from "tenaissance/components/IconButton";
import { MetricType } from "lib/billableMetrics/types";
import { useSeatMetricDetailQuery } from "pages/NewBillableMetric/queries.graphql";
import { useUIMode } from "lib/useUIMode";
import EditNameModal from "tenaissance/components/EditNameModal";
import { Breadcrumbs } from "lib/breadcrumbs";

const BillableMetricRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath: string = `/offering${pathname}`;
  return <Navigate to={newPath} replace />;
};

interface Tab {
  link: string;
  name: string;
  component: React.FC<{ billableMetricId: string; metricType: MetricType }>;
}

const tabs: Tab[] = [
  {
    link: "",
    name: "Summary",
    component: BillableMetricSummary,
  },
  {
    link: "plans",
    name: "Plans",
    component: BillableMetricPlans,
  },
  {
    link: "products",
    name: "Products",
    component: BillableMetricProducts,
  },
];

const BillableMetric: React.FC<{ tab: Tab; metricType: MetricType }> = ({
  tab,
  metricType,
}) => {
  const [showEditNameModal, setShowEditNameModal] = React.useState(false);
  const billableMetricId = useRequiredParam("id");
  const {
    data: billableMetricDetail,
    loading: billableMetricLoading,
    error: billableMetricError,
  } = useBillableMetricDetailQuery({
    variables: {
      billable_metric_id: billableMetricId,
    },
    skip: metricType !== "billable",
  });

  const {
    data: seatMetricDetail,
    loading: seatMetricLoading,
    error: seatMetricError,
  } = useSeatMetricDetailQuery({
    variables: {
      seat_metric_id: billableMetricId,
    },
    skip: metricType !== "seat",
  });

  const [setBillableMetricNameMutation] = useSetBillableMetricNameMutation();
  const { getActions, archiveModal, copyMetricModal } = useActions();
  const error = billableMetricError || seatMetricError;
  const loading = billableMetricLoading || seatMetricLoading;
  const metric =
    billableMetricDetail?.BillableMetric ?? seatMetricDetail?.seat_metric;

  const { mode } = useUIMode();

  if (loading) {
    return (
      <AppShell title={{ state: "loading" }}>
        <div className="pt-12">
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
        </div>
      </AppShell>
    );
  } else if (error) {
    return (
      <AppShell title="Error">
        <EmptyState
          icon="barLineChart"
          mainText="We ran into an issue loading this billable metric"
          supportingText="Don't worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
        />
      </AppShell>
    );
  } else if (!metric) {
    return <NotFoundPage />;
  }

  const actions = getActions({ ...metric, metricType });

  const headerAction = actions && (
    <PopoverMenu positions={["bottom"]} align="end" options={actions}>
      {(onClick) => (
        <IconButton onClick={onClick} theme="secondary" icon="dotsVertical" />
      )}
    </PopoverMenu>
  );

  let availableTabs: Tab[] | undefined = tabs;
  if (mode === "contracts-only") {
    // remove the tabs for contracts-only
    availableTabs = undefined;
  }

  return (
    <AppShell
      authDoc={BillableMetricDetailDocument}
      authVariables={{ billable_metric_id: billableMetricId }}
      title={metric.name}
      headerProps={{
        breadcrumbs:
          metricType === "billable"
            ? Breadcrumbs.from(
                {
                  label: "Offering",
                  routePath: `/offering/${mode === "plans-only" ? "plans" : "rate-cards"}`,
                },
                {
                  label: "Billable metrics",
                  routePath: "/offering/billable-metrics",
                },
              )
            : Breadcrumbs.from(
                {
                  label: "Offering",
                  routePath: `/offering/${mode === "plans-only" ? "plans" : "rate-cards"}`,
                },
                {
                  label: "Billable metrics",
                  routePath: "/offering/billable-metrics",
                },
                {
                  label: "Seats",
                  routePath: "/offering/billable-metrics/seats",
                },
              ),
        tabs:
          availableTabs &&
          availableTabs.map((t) => ({
            name: t.name,
            exactMatch: t.link === "",
            path: t.link,
          })),
        basePath: `offering/billable-metrics/${
          metricType === "seat" ? "seats/" : ""
        }${billableMetricId}`,
        editTitleAction: () => {
          setShowEditNameModal(true);
        },
        actions: headerAction,
      }}
    >
      {archiveModal}
      {copyMetricModal}
      <tab.component
        billableMetricId={billableMetricId}
        metricType={metricType}
      />
      {showEditNameModal && (
        <EditNameModal
          title="Edit billable metric name"
          name={metric.name ?? ""}
          onSave={async (name) => {
            await setBillableMetricNameMutation({
              variables: {
                billable_metric_id: metric.id,
                name,
              },
            });
            setShowEditNameModal(false);
          }}
          onClose={() => {
            setShowEditNameModal(false);
          }}
        />
      )}
    </AppShell>
  );
};

export const BillableMetricRoutes: RouteObject[] = [
  {
    path: "billable-metrics/:id",
    children: [
      ...tabs.map((tab) => ({
        ...(tab.link ? { path: tab.link } : { index: true }),
        element: <BillableMetricRedirect />,
      })),
      { path: "*", element: <NotFoundPage /> },
    ],
  },
  {
    path: "billable-metrics/seats/:id",
    children: [
      ...tabs.map((tab) => ({
        ...(tab.link ? { path: tab.link } : { index: true }),
        element: <BillableMetricRedirect />,
      })),
      { path: "*", element: <NotFoundPage /> },
    ],
  },
  {
    path: "offering/billable-metrics/:id",
    children: [
      ...tabs.map((tab) => ({
        ...(tab.link ? { path: tab.link } : { index: true }),
        element: <BillableMetric tab={tab} metricType="billable" />,
      })),
      { path: "*", element: <NotFoundPage /> },
    ],
  },
  {
    path: "offering/billable-metrics/seats/:id",
    children: [
      ...tabs.map((tab) => ({
        ...(tab.link ? { path: tab.link } : { index: true }),
        element: <BillableMetric tab={tab} metricType="seat" />,
      })),
      { path: "*", element: <NotFoundPage /> },
    ],
  },
];
