import React from "react";
import { ProductContext } from "./ProductContext";
import { TextInput } from "tenaissance/components/Input";
import { Toggle } from "tenaissance/components/Toggle";

export const NetSuiteFields: React.FC = () => {
  const {
    netSuiteItemId,
    setNetSuiteItemId,
    netsuiteEnabled,
    usageProductFields,
    setUsageProductFields,
    subscriptionProductFields,
    setSubscriptionProductFields,
    compositeProductFields,
    setCompositeProductFields,
    refundableProductsEnabled,
    isRefundable,
    setIsRefundable,
    productType,
  } = ProductContext.useContainer();

  let netSuiteOverageItemId = undefined;
  switch (productType) {
    case "usage":
      netSuiteOverageItemId = usageProductFields.netSuiteOverageItemId;
      break;
    case "subscription":
      netSuiteOverageItemId = subscriptionProductFields.netSuiteOverageItemId;
      break;
    case "composite":
      netSuiteOverageItemId = compositeProductFields.netSuiteOverageItemId;
      break;
  }

  return productType === "fixed" ? (
    <div className="mt-[24px] flex flex-col gap-[24px]">
      <TextInput
        fullWidth
        placeholder="Enter ID"
        label="NetSuite internal item ID"
        value={netSuiteItemId}
        onChange={(meta: { value: string }) => {
          setNetSuiteItemId(meta.value);
        }}
      />
      {refundableProductsEnabled && (
        <Toggle
          toggled={isRefundable}
          onChange={(meta: { toggled: boolean }) => {
            setIsRefundable(meta.toggled);
          }}
          label="Refundable"
        />
      )}
    </div>
  ) : (
    <>
      {netsuiteEnabled ? (
        <>
          <TextInput
            fullWidth
            placeholder="Enter ID"
            label="NetSuite internal item ID"
            value={netSuiteItemId}
            onChange={(meta: { value: string }) => {
              setNetSuiteItemId(meta.value);
            }}
          />
          {productType !== "proService" && (
            <TextInput
              fullWidth
              placeholder="Enter ID"
              label="NetSuite overage item ID"
              value={netSuiteOverageItemId}
              onChange={(meta: { value: string }) => {
                switch (productType) {
                  case "usage":
                    setUsageProductFields({
                      ...usageProductFields,
                      netSuiteOverageItemId: meta.value.trim(),
                    });
                    break;
                  case "subscription":
                    setSubscriptionProductFields({
                      ...subscriptionProductFields,
                      netSuiteOverageItemId: meta.value.trim(),
                    });
                    break;
                  case "composite":
                    setCompositeProductFields({
                      ...compositeProductFields,
                      netSuiteOverageItemId: meta.value.trim(),
                    });
                    break;
                }
              }}
            />
          )}
          {refundableProductsEnabled && (
            <Toggle
              toggled={isRefundable}
              onChange={(meta: { toggled: boolean }) => {
                setIsRefundable(meta.toggled);
              }}
              label="Refundable"
            />
          )}
        </>
      ) : null}
    </>
  );
};
