import React, { useCallback } from "react";
import { RateCardContext } from "./RateCardContext";
import { SectionHeader } from "tenaissance/components/SectionHeader";
import { TagsInput } from "tenaissance/components/Input/TagsInput";
import { Button } from "tenaissance/components/Button";
import { Tooltip } from "tenaissance/components/Tooltip";

export const Step2: React.FC = () => {
  const { dimensionalProducts, setDimensionalProductKeyValues } =
    RateCardContext.useContainer();

  const handleCopyToAll = useCallback(
    (productId: string, key: string, values: string[]) => {
      const updatedDimensionalProducts = dimensionalProducts
        .filter(
          (p) =>
            p.id !== productId &&
            p.pricingGroupKeyValues.some((p) => p.key === key),
        )
        .map((p) => {
          const keyValues = p.pricingGroupKeyValues.find((p) => p.key === key);
          if (keyValues) {
            return {
              productId: p.id,
              key: key,
              values,
            };
          }

          // this should never happen due to the filter above
          throw new Error("Key not found");
        });

      if (updatedDimensionalProducts.length > 0) {
        setDimensionalProductKeyValues(updatedDimensionalProducts);
      }
    },
    [dimensionalProducts, setDimensionalProductKeyValues],
  );

  return (
    <div className="flex flex-col gap-32">
      <SectionHeader
        title="Define possible values for each dimensional pricing key"
        subtitle={[
          "Dimensional pricing lets a product have multiple rates based on its pricing keys. For example, you can choose to charge different rates depending on the region in which usage was incurred.",
          "Specify possible values for each key. On the next page, you can rate or remove combinations.",
        ]}
        bottomBorder={false}
      />
      {dimensionalProducts.map((product) => (
        <div
          key={product.id}
          className="rounded-xl min-w-[500px] overflow-auto shadow-sm"
        >
          <div className="py-lg px-3xl flex items-center bg-gray-50">
            <span className="text-md text-core-slate truncate font-semibold">
              {product.name}
            </span>
          </div>
          <div className="p-3xl flex flex-col gap-16">
            {product.pricingGroupKeyValues.map((pair) => {
              const keyToCopy = dimensionalProducts
                .filter((p) => p.id !== product.id)
                .some((p) =>
                  p.pricingGroupKeyValues.some((p) => p.key === pair.key),
                )
                ? pair.key
                : null;

              const tooltipContent = keyToCopy
                ? pair.values.length
                  ? `Copy values to all "${keyToCopy}"`
                  : "No values to copy"
                : "No other products to copy values to";
              return (
                <div key={pair.key} className="flex flex-col gap-12">
                  <span className="truncate text-sm font-semibold text-gray-700">
                    {pair.key}
                  </span>
                  <div className="flex flex-row items-end gap-12">
                    <TagsInput
                      label="Values"
                      allowTagClose
                      className="max-w-[100%]"
                      placeholder="Specify possible values for this dimensional pricing key"
                      containerClassName="w-full"
                      value={pair.values}
                      onChange={({ value }) => {
                        setDimensionalProductKeyValues([
                          {
                            productId: product.id,
                            key: pair.key,
                            values: value,
                          },
                        ]);
                      }}
                    />
                    <Tooltip label={tooltipContent}>
                      <Button
                        text="Copy to all"
                        leadingIcon="copy01"
                        disabled={!keyToCopy || pair.values.length === 0}
                        onClick={() => {
                          handleCopyToAll(product.id, pair.key, pair.values);
                        }}
                        theme="tertiary"
                      />
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
