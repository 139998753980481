import React from "react";
import { DateInput, Input, Select } from "design-system";
import { useLocation, useNavigate } from "react-router-dom";
import { AuditLogTable } from "components/AuditLogTable";

function useURLParamState(key: string) {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  return [
    searchParams.get(key),
    (value: string | null) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }

      navigate(
        {
          search: searchParams.toString(),
        },
        {
          replace: true,
        },
      );
    },
  ] as const;
}

const useURLParamDateState = (key: string) => {
  const [value, setValue] = useURLParamState(key);

  return [
    value ? new Date(value) : null,
    (date: Date | null) => {
      setValue(date ? date.toISOString() : null);
    },
  ] as const;
};

export const AuditLogsTab: React.FC = () => {
  const [startingOnOrAfter, setStartingOnOrAfter] =
    useURLParamDateState("starting_on");
  const [endingBefore, setEndingBefore] = useURLParamDateState("ending_before");
  const [resourceTypeSearch, setResourceTypeSearch] =
    React.useState<string>("");
  const [resourceType, setResourceType] = useURLParamState("resource_type");
  const [resourceID, setResourceID] = useURLParamState("resource_id");

  const resourceTypes = [
    "customer",
    "draft_plan",
    "credit_grant",
    "product",
    "plan",
    "customer_plan",
    "billable_metric",
    "invoice",
    "webhook",
    "user",
    "token",
    "one_time_product_charge",
    "contract",
    "customer_managed_field",
  ];
  return (
    <>
      <div className="flex gap-8 pb-16 pt-8">
        <DateInput
          value={startingOnOrAfter ?? undefined}
          onChange={(d) => setStartingOnOrAfter(d)}
          name="Starting after"
          isUTC
          className="flex-1"
          error={
            startingOnOrAfter &&
            endingBefore &&
            startingOnOrAfter > endingBefore
              ? "Start date must be before end date"
              : undefined
          }
        />
        <DateInput
          value={endingBefore ?? undefined}
          onChange={(d) => setEndingBefore(d)}
          name="Ending before"
          isUTC
          className="flex-1"
          error={
            startingOnOrAfter &&
            endingBefore &&
            startingOnOrAfter > endingBefore
              ? "Start date must be before end date"
              : undefined
          }
        />
        <Select
          name="Resource type"
          clearable
          options={(resourceTypeSearch
            ? [{ label: resourceTypeSearch, value: resourceTypeSearch }]
            : []
          ).concat(resourceTypes.map((type) => ({ label: type, value: type })))}
          onSearch={setResourceTypeSearch}
          placeholder="Resource type"
          value={resourceType ?? ""}
          onChange={setResourceType}
          className="flex-1"
          error={
            resourceID && !resourceType
              ? "Resource Type is required when searching for a resource"
              : undefined
          }
        />
        <Input
          name="Resource ID"
          value={resourceID ?? ""}
          onChange={setResourceID}
          placeholder="Resource ID"
          className="flex-1"
          error={
            resourceType && !resourceID
              ? "Resource ID is required when searching for a resource"
              : undefined
          }
        />
      </div>
      <AuditLogTable
        startingOn={startingOnOrAfter ?? undefined}
        endingBefore={endingBefore ?? undefined}
        resourceType={resourceType ?? undefined}
        resourceID={resourceID ?? undefined}
      />
    </>
  );
};
