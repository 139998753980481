import React, { useEffect, useState } from "react";
import { Caption, Subtitle, Icon } from "design-system";
import { Copy } from "components/Copy";

import styles from "./index.module.less";
import { formatDistance } from "date-fns";
import classnames from "classnames";

import { useGetClientQuery } from "./data.graphql";
import { QA_WOLF_CLIENT_IDS } from "lib/auth/constants";

type ImpersonationBannerProps = {
  email: string;
  expiration: Date;
  role: string;
};

const GnomeHouseLink: React.FC<{ clientId?: string; role?: string }> = ({
  clientId,
  role,
}) => {
  const params = new URLSearchParams();
  params.set("client_id", clientId ?? "");
  params.set("role", role ?? "admin");
  params.set("path", window.location.pathname);

  return (
    <a
      className={styles.gnomeHouseLink}
      href={`https://gnome.house/tools/impersonate?${params.toString()}`}
    >
      <Icon icon="home" />
      <Caption level={1} className={styles.caption}>
        Gnome House
      </Caption>
    </a>
  );
};

export const ImpersonationBanner: React.FC<ImpersonationBannerProps> = (
  props,
) => {
  const { data } = useGetClientQuery();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(id);
  });

  // Hide the banner if the user is impersonating the test user
  // QA wolf uses this email to impersonate users for testing so it
  // does not have to hit the login endpoint for most tests
  if (
    props.email === "test-user@example.com" &&
    QA_WOLF_CLIENT_IDS.includes(data?.Client[0].id ?? "")
  ) {
    return null;
  }

  if (props.expiration.valueOf() <= currentTime.valueOf()) {
    return (
      <div className={classnames(styles.impersonationBanner, styles.expired)}>
        <GnomeHouseLink clientId={data?.Client[0].id} role={props.role} />
        <Subtitle className={styles.impersonationMessage}>
          Impersonation session has expired.
        </Subtitle>
      </div>
    );
  }

  return (
    <div className={styles.impersonationBanner}>
      <GnomeHouseLink clientId={data?.Client[0].id} role={props.role} />
      <Subtitle className={styles.impersonationMessage}>
        <b>
          {props.email} ({props.role})
        </b>{" "}
        is impersonating
        {data?.Client?.length ? (
          <b>
            {" "}
            {data.Client[0].name} ({data.Client[0].account_type.toLowerCase()})
            - {data.Client[0].id} <Copy text={data.Client[0].id} />)
          </b>
        ) : null}
        . Impersonation will expire in{" "}
        <b>{formatDistance(props.expiration, currentTime)}</b>.
      </Subtitle>
    </div>
  );
};
