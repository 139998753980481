import {
  InvoiceCorrectionItem,
  SequenceNumberInput,
} from "types/generated-graphql/__types__";

import { CreateInvoiceCorrectionMutationVariables } from "./correctInvoice.graphql";
import { giveUserFacingErrorMessage } from "lib/errors/errorHandling";

export const prepareVariablesForCorrectInvoice = (
  invoiceId: string,
  reason: string,
  memo: string,
  sequenceNumbers: SequenceNumberInput[],
  correctionItems: Record<string, InvoiceCorrectionItem & { input?: number }>,
  preview: boolean,
  issueDate?: Date,
): CreateInvoiceCorrectionMutationVariables => {
  const filteredCorrectionItems = Object.values(correctionItems)
    // Filter out items that have no pointer value
    .filter(
      (c) =>
        !(
          Object.keys(c.line_item_pointer ?? {}).length === 0 &&
          Object.keys(c.contract_line_item_pointer ?? {}).length === 0
        ),
    )
    .map((c) => ({
      ...c,
      input: undefined,
    }));

  if (filteredCorrectionItems.length === 0) {
    throw giveUserFacingErrorMessage(new Error(), "No correction items found");
  }

  return {
    correction: {
      invoice_id: invoiceId,
      reason,
      memo,
      sequence_numbers: sequenceNumbers,
      correction_items: filteredCorrectionItems,
      issue_date: issueDate?.toISOString(),
    },
    preview,
  };
};
