import React from "react";
import "/src/tenaissance/tenaissance.css";
import { dayjs } from "lib/dayjs";
import {
  BaseStatusPillsFieldsFragment,
  StatusPillsFieldsFragment,
} from "../Invoices/queries.graphql";
import { BADGE_VARIANTS, Badge } from "tenaissance/components/Badge";
import { Icon, IconName } from "tenaissance/components/Icon";
import Icons from "../../../../icons";
import { v4 as uuid } from "uuid";
import { capitalizeFirstLetter, toSentenceCase } from "tenaissance/lib/utils";

export const getBillingProviderStatusContent = (
  invoice: StatusPillsFieldsFragment,
) => {
  const contents: React.ReactNode[] = [];

  if (
    invoice.status !== "DRAFT" &&
    !(
      invoice.__typename === "ParentInvoice" ||
      invoice.__typename === "AdHocPlanInvoice"
    ) &&
    invoice.billing_provider_invoice
  ) {
    let badgeTheme: keyof typeof BADGE_VARIANTS;
    let copy: string = invoice.billing_provider_invoice.external_status;

    switch (invoice.billing_provider_invoice.external_status) {
      case "DELETED":
      case "UNCOLLECTIBLE":
      case "VOID":
      case "PAYMENT_FAILED": {
        badgeTheme = "error";
        break;
      }
      case "INVALID_REQUEST_ERROR": {
        badgeTheme = "error";
        copy = "FAILED";
        break;
      }
      case "FINALIZED":
      case "PAID": {
        badgeTheme = "success";
        break;
      }
      case "DRAFT":
      case "SKIPPED":
      case "SENT":
      case "QUEUED": {
        badgeTheme = "gray";
        break;
      }
      case undefined: {
        badgeTheme = "gray";
        copy = "CREATED";
        break;
      }
      default: {
        console.warn(
          `Unknown external status: ${invoice.billing_provider_invoice.external_status}`,
        );
        badgeTheme = "gray";
      }
    }

    const providerIcon = () => {
      if (invoice.billing_provider_invoice) {
        let possibleIcon =
          invoice.billing_provider_invoice.billing_provider.toLocaleLowerCase();
        if (
          invoice.billing_provider_invoice.billing_provider ===
          "QUICKBOOKS_ONLINE"
        ) {
          possibleIcon = "quickbooks";
        } else if (
          invoice.billing_provider_invoice.billing_provider ===
          "AWS_MARKETPLACE"
        ) {
          possibleIcon = "aws";
        }
        if (possibleIcon in Icons) {
          return (
            <Icon
              viewBox="0 0 32 32"
              icon={possibleIcon as IconName}
              showRaw={true}
              size={16}
            />
          );
        } else {
          return null;
        }
      }
      return null;
    };

    contents.push(
      <Badge
        key={uuid()}
        theme={badgeTheme}
        label={
          <div className="gap-x-xs flex items-center">
            {providerIcon()}
            {toSentenceCase(copy)}
          </div>
        }
        tooltipLabel={
          invoice.billing_provider_invoice.billing_provider_error
            ? invoice.billing_provider_invoice.billing_provider_error
            : `${capitalizeFirstLetter(invoice.billing_provider_invoice.billing_provider)} billing provider`
        }
      />,
    );
  }

  return contents;
};

const getMainInvoiceStatusBadgeContent = (
  invoice: BaseStatusPillsFieldsFragment,
) => {
  let theme: keyof typeof BADGE_VARIANTS | undefined;
  let copy: string | undefined;

  const contents: React.ReactNode[] = [];

  if (invoice.__typename === "CreditPurchaseInvoice") {
    if (dayjs(invoice.issued_at).isBefore(new Date())) {
      theme = "success";
      copy = "ISSUED";
    } else {
      theme = "gray";
      copy = "SCHEDULED";
    }
  } else {
    switch (invoice.status) {
      case "DRAFT": {
        theme = "gray";
        copy = "DRAFT";
        break;
      }
      case "FINALIZED": {
        theme = "success";
        copy = invoice.on_hold ? "ON HOLD" : "FINALIZED";
        break;
      }
      case "PENDING_FINALIZATION": {
        theme = "azure-blue";
        copy = "PENDING FINALIZATION";
        break;
      }
      case "FINALIZED_IN_OLD_SYSTEM": {
        // This status is "internal", the resolvers should never return it. That said, to satisfy the type checker, we need to handle it.
        throw new Error(
          "Received finalized invoice from old system, which should not be exposed to callers ever",
        );
      }
      default: {
        console.warn("Unknown invoice status", invoice.status);
        break;
      }
    }
  }

  if (theme && copy) {
    contents.push(
      <Badge key={uuid()} theme={theme} label={toSentenceCase(copy)} />,
    );
  }

  return contents;
};

export const StatusPills: React.FC<{
  invoice: StatusPillsFieldsFragment;
}> = ({ invoice }) => {
  const contents: React.ReactNode[] = [];

  if (invoice.status === "VOID") {
    return (
      <div>
        <Badge label="Void" theme="error" />
      </div>
    );
  }

  contents.push(...getMainInvoiceStatusBadgeContent(invoice));
  contents.push(...getBillingProviderStatusContent(invoice));

  return <div className="gap-y-xs flex flex-col items-start">{contents}</div>;
};
