import React, { useEffect } from "react";
import "style/index.css";
import { twMerge } from "tenaissance/twMerge";
import { Icon, IconName } from "../Icon";
import { IconButton } from "../IconButton";
import ReactModal from "react-modal";
import { ReactComponent as Rings } from "./rings.svg";
import { Button, ButtonProps } from "../Button";
import { Checkbox, CheckboxProps } from "../Checkbox";

if (process.env.NODE_ENV !== "test") {
  if (!!document.getElementById("storybook-root")) {
    ReactModal.setAppElement("#storybook-root");
  } else {
    ReactModal.setAppElement("#root");
  }
}

export interface ModalProps extends React.PropsWithChildren {
  /** Default - horizontal; Set the alignment of the Modal buttons. */
  buttonLayout?: "horizontal" | "vertical" | "alignedRight";
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Customize the overlay component with additional Tailwind classes */
  overlayClassName?: string;
  /**
   * These extra actions will force the `buttonLayout: alignedRight` to allow
   * room for a tertiary action.
   * */
  extraActions?: {
    /** A third Button action */
    tertiaryAction?: React.ReactElement<ButtonProps>;
    /** A checkbox in the buttons.  Always the first item. */
    checkboxAction?: React.ReactElement<CheckboxProps>;
  };
  /** Default - left; Set the alignment of the copy, as well as an icon if present. */
  headerLayout?: "left" | "center" | "horizontalLeft";
  /** Render an Icon above the header copy */
  icon?: IconName;
  /** Used to open and close the Modal */
  isOpen: boolean;
  /** Button actions, rendered towards the bottom of the Modal */
  modalButtons?:
    | [React.ReactElement<ButtonProps>]
    | [React.ReactElement<ButtonProps>, React.ReactElement<ButtonProps>];
  /** Callback handler for closing the Modal. */
  onClose: () => void;
  /**
   * Default - true; Show an "X" icon button to close the Modal. Clicking outside the
   * Modal will always close it.
   */
  showCloseButton?: boolean;
  /** Render a horizontal divider between the header and any content or action buttons */
  showDivider?: boolean;
  /** Default - md; md: 400px, lg: 540px */
  size?: "md" | "lg";
  /** Main text string for the Modal */
  title?: string;
  /**
   * De-emphasized copy that lives under the `text` prop.
   * Use this to provide more context to the user about the content of the Modal.
   * */
  supportingText?: string;
  /** Allows displaying components in the modal that are outside the boundaries of the popup */
  showOverflow?: boolean;
}

/**
 * !! PARTIAL IMPLEMENTATION !!
 * - missing checkbox option, waiting for tComponent
 *
 * A modal is a secondary window that communicates or provides an action inside the same
 * process. They’re incredibly useful for communicating additional information, collecting
 * information, or directing users without forcing them to leave the page. When modals are
 * used unnecessarily, they can become intrusive and annoying for the user.
 */
export const Modal: React.FC<ModalProps> = ({
  buttonLayout = "horizontal",
  className,
  overlayClassName,
  extraActions,
  headerLayout = "left",
  icon,
  isOpen = false,
  modalButtons,
  onClose,
  showCloseButton = true,
  showDivider = false,
  size = "md",
  supportingText,
  title,
  children,
  showOverflow,
}) => {
  useEffect(() => {
    // prevent background scrolling
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <ReactModal
      className={twMerge(
        "relative rounded-xl bg-white shadow-xl",
        size === "md" ? "w-[400px]" : "w-[540px]",
        !showOverflow && "overflow-hidden",
        className,
      )}
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={300}
      overlayClassName={twMerge(
        "bg-gray-950/70 flex fixed items-center inset-0 justify-center z-[1] animate-fadeIn opacity-0",
        !isOpen && "opacity-100 animate-fadeOut",
        overlayClassName,
      )}
    >
      <div className="relative p-3xl">
        {showCloseButton && (
          <IconButton
            icon="xClose"
            onClick={onClose}
            theme="tertiary"
            size="lg"
            className={twMerge(
              "absolute top-xl z-[1]",
              headerLayout === "horizontalLeft"
                ? "right-3xl top-xl"
                : "right-[28px]",
            )}
          />
        )}
        <div
          className={twMerge(
            "flex w-full items-center",
            headerLayout === "horizontalLeft" && "items-start",
            headerLayout === "left" && "flex-col items-start",
            headerLayout === "center" &&
              "flex-col items-center justify-center text-center",
            !!children ? "mb-2xl" : "mb-3xl",
          )}
        >
          {icon && (
            <div
              className={twMerge(
                "relative flex h-3xl w-3xl items-center justify-center",
                headerLayout === "horizontalLeft" && "mx-[28px] mt-[15px]",
                headerLayout === "left" && "mb-[28px] ml-lg",
                headerLayout === "center" && "mb-[28px]",
              )}
            >
              <Icon icon={icon} size={24} />
              <Rings className="absolute" />
            </div>
          )}
          <div className="isolate flex max-w-[350px] flex-col">
            <h3 className="mb-md text-lg font-semibold text-core-slate">
              {title}
            </h3>
            {supportingText && (
              <h5 className="text-sm text-gray-600">{supportingText}</h5>
            )}
          </div>
        </div>
        {showDivider && <hr className="w-full text-gray-200" />}
        <div className="relative z-auto">{children}</div>
        <div
          className={twMerge(
            "isolate mt-4xl flex items-center gap-lg",
            buttonLayout === "alignedRight" && "justify-end",
            !!extraActions && "justify-between",
            buttonLayout === "alignedRight" &&
              !!extraActions?.checkboxAction &&
              "justify-end",
          )}
        >
          {extraActions?.tertiaryAction && (
            <Button
              {...extraActions.tertiaryAction.props}
              theme="linkGray"
              size="sm"
            />
          )}
          {extraActions?.checkboxAction && (
            <Checkbox {...extraActions.checkboxAction.props} />
          )}
          <div
            className={twMerge(
              "flex",
              buttonLayout === "horizontal" &&
                "w-full flex-row-reverse space-x-lg space-x-reverse",
              buttonLayout === "vertical" && "w-full flex-col space-y-lg",
              (buttonLayout === "alignedRight" || !!extraActions) &&
                "flex-row-reverse space-x-lg space-x-reverse",
            )}
          >
            {modalButtons?.map((a, idx) => (
              <React.Fragment key={idx}>
                <Button {...a.props} showFullWidth={true} />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
