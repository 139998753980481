import React from "react";

import { toDayjs, Dayjs, maybeToDayjs, useNow } from "lib/date";

import { DefaultTimeframe } from "./DefaultTimeframe";

export interface DateSequence {
  bounds: DateSequence.Boundary[];
  timeframe: DefaultTimeframe;
  frequency: DateSequence.Size | undefined;
  size: DateSequence.Size | undefined;
}

export namespace DateSequence {
  const UNITS_DESC = ["year", "month", "week", "day"] as const;
  export type Unit = (typeof UNITS_DESC)[number];
  export type Range = [startingAt: string, endingBefore: string];
  export type Boundary = string | Range | undefined;
  export type Size = [number, Unit];

  export function create(
    bounds: Boundary[],
    timeframe: DefaultTimeframe,
  ): DateSequence {
    // the rate at which we expect bounds to be defined
    let frequency: Size | undefined;

    // get the index of the first non-empty bound which is followed by a non-empty bound
    const contiguousI = bounds.findIndex((b, i) => b && bounds[i + 1]);
    if (contiguousI !== -1) {
      const start = getStart(bounds[contiguousI]);
      const end = getStart(bounds[contiguousI + 1]);
      // make TS happy, start and end will always be defined because of findIndex()
      if (start && end) {
        frequency = getSize([start, end]);
      }
    }

    // the size of ranges in the bounds, if none are defined the size is assumed to equal the frequency
    // we track this separately so that we can support overlapping ranges, ie each segment lasts for two
    // months but you get access to a new segment every month
    let size: Size | undefined;
    const range = bounds.find((b): b is Range => Array.isArray(b));
    if (range) {
      size = getSize(range);
    }

    return {
      bounds,
      timeframe,
      frequency,
      size,
    };
  }

  interface ProviderProps extends React.PropsWithChildren {
    seq: DateSequence;
    index: number;
  }

  /**
   * Provide a time range to the child component via the DefaultTimeframe
   * context which follows the sequence of dates provided.
   */
  export const Provider: React.FC<ProviderProps> = (props) => {
    const now = useNow();
    const [startingAt, endingBefore] = get(props.seq, props.index, now);

    return (
      <DefaultTimeframe.Provider
        startingAt={startingAt}
        endingBefore={endingBefore}
      >
        {props.children}
      </DefaultTimeframe.Provider>
    );
  };

  function getStart(bound: Boundary) {
    return Array.isArray(bound) ? bound[0] : bound;
  }

  function getSize(range: Range): Size | undefined {
    for (const unit of UNITS_DESC) {
      const diff = toDayjs(range[1]).diff(toDayjs(range[0]), unit, true);
      if (Math.floor(diff) === diff) {
        return [diff, unit];
      }
    }
  }

  function add(date: string, [amt, unit]: Size): string {
    return toDayjs(date).add(amt, unit).toISOString();
  }

  function range(seq: DateSequence, startingAt: string): Range {
    return [
      startingAt,
      add(startingAt, seq.size ?? seq.frequency ?? [1, "month"]),
    ];
  }

  function get(seq: DateSequence, index: number, now: Dayjs): Range {
    if (index < 0) {
      throw new Error("index must be >= 0");
    }

    const bound = seq.bounds[index];
    if (bound) {
      if (Array.isArray(bound)) {
        return bound;
      }

      const startingAt = maybeToDayjs(getStart(bound))?.toISOString();
      if (startingAt) {
        return range(seq, startingAt);
      }
    }

    if (index === 0) {
      return range(seq, seq.timeframe.startingAt ?? now.toISOString());
    }

    const prev = get(seq, index - 1, now);
    if (seq.frequency) {
      return range(seq, add(prev[0], seq.frequency));
    } else {
      return range(seq, prev[1]);
    }
  }
}
