import pluralize from "pluralize";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Decimal from "decimal.js";

import { twMerge } from "../../twMerge";
import { InputContainer, InputContainerProps } from "./InputContainer";
import styles from "./DaysInput.module.css";

type DaysInputProps = InputContainerProps & {
  value?: number;
  placeholder?: string;
  onChange: (value: number | undefined) => void;
  minimumDays?: number;
};

export const DaysInput: React.FC<DaysInputProps> = (props) => {
  const [valueWidth, setValueWidth] = useState<number>(0);

  const [textValue, setTextValue] = useState<string>(String(props.value ?? ""));
  const [error, setError] = useState<string | boolean>("");

  useEffect(() => {
    if (props.disabled) {
      setTextValue(String(props.value ?? ""));
    } else if (textValue === "") {
      if (props.value) {
        setTextValue(String(props.value));
      }
    }
  }, [props.value, props.disabled]);
  const hiddenInputRef = useCallback<React.RefCallback<HTMLDivElement>>(
    (node) => {
      if (node) {
        setValueWidth(node.clientWidth);
      }
    },
    [textValue],
  );
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <InputContainer
      {...props}
      error={error || props.error}
      className={twMerge(styles.daysInputContainer, props.className)}
    >
      <input
        disabled={props.disabled}
        placeholder={props.placeholder ?? "Number of days"}
        value={textValue}
        type="number"
        onChange={(event) => {
          let currentValue: string | null = event.currentTarget.value;
          setTextValue(currentValue);
          if (currentValue === "") {
            setError("");
          } else {
            const value = new Decimal(currentValue);
            if (value.isNaN()) {
              setError("Must be numerical");
              currentValue = null;
            } else if (
              props.minimumDays !== undefined &&
              value.lessThan(props.minimumDays)
            ) {
              setError(`Must be greater than ${props.minimumDays}`);
              currentValue = null;
            } else {
              setError("");
            }
          }
          props.onChange(
            currentValue ? Number(event.currentTarget.value) : undefined,
          );
        }}
        ref={inputRef}
      />
      <div
        className={styles.days}
        style={{
          left: `${valueWidth + 16}px`,
          display: textValue !== "" ? "flex" : "none",
        }}
      >
        {pluralize("day", Number(textValue))}
      </div>
      <div ref={hiddenInputRef} className={styles.hidden}>
        {textValue}
      </div>
    </InputContainer>
  );
};
