import React, { useEffect, useState } from "react";
import { Button } from "tenaissance/components/Button";
import {
  useCreateOrUpdateSalesforceCensusModelsMutation,
  useCreateOrUpdateSalesforceCensusSyncsMutation,
  useRefreshSalesforceDestinationObjectsMutation,
  useSetSalesforceCensusWorkspaceEnvironmentVariablesMutation,
} from "./queries.graphql";
import { Icon } from "tenaissance/components/Icon";
import { ApolloError } from "@apollo/client";
import { twMerge } from "tenaissance/twMerge";
import { GetSalesforceWorkspaceDocument } from "../IntegrationsTab/queries.graphql";
import { CensusDestinationTypeEnum } from "types/generated-graphql/__types__";

const PROVISION_STEP_INITIAL = 0;
const PROVISION_STEP_ENV_VARIABLES = 1;
const PROVISION_STEP_MODELS = 2;
const PROVISION_STEP_REFRESH_OBJECTS = 3;
const PROVISION_STEP_SYNCS = 4;
const PROVISION_STEP_COMPLETE = 5;

interface CompleteSetupProps {
  isWorkspaceCreated: boolean;
  isSalesforceDestinationLinked: boolean;
  syncOnlyLinkedAccounts: boolean | undefined;
  onSuccess: () => void;
}

export const CompleteSetup: React.FC<CompleteSetupProps> = ({
  isWorkspaceCreated,
  isSalesforceDestinationLinked,
  syncOnlyLinkedAccounts,
  onSuccess,
}) => {
  const [currentProvisionStep, setCurrentProvisionStep] = useState(
    PROVISION_STEP_INITIAL,
  );
  const [isProvisioning, setIsProvisioning] = useState(false);
  const [provisioningError, setProvisioningError] = useState<ApolloError>();

  const completeSetupDisabled =
    !isWorkspaceCreated ||
    !isSalesforceDestinationLinked ||
    syncOnlyLinkedAccounts === undefined;

  const [
    setSalesforceCensusWorkspaceEnvironmentVariablesMutation,
    { loading: envLoading, error: envError },
  ] = useSetSalesforceCensusWorkspaceEnvironmentVariablesMutation();
  const [
    createOrUpdateSalesforceCensusModelsMutation,
    { loading: modelsLoading, error: modelsError },
  ] = useCreateOrUpdateSalesforceCensusModelsMutation();
  const [
    refreshSalesforceDestinationObjectsMutation,
    { loading: refreshLoading, error: refreshError },
  ] = useRefreshSalesforceDestinationObjectsMutation();
  const [
    createOrUpdateSalesforceCensusSyncsMutation,
    { loading: syncsLoading, error: syncsError },
  ] = useCreateOrUpdateSalesforceCensusSyncsMutation();

  // Update error state if any mutation fails
  useEffect(() => {
    if (envError || modelsError || refreshError || syncsError) {
      setProvisioningError(
        envError || modelsError || refreshError || syncsError,
      );
      setIsProvisioning(false);
    }
  }, [envError, modelsError, refreshError, syncsError]);

  const completeSetup = async () => {
    if (
      isProvisioning ||
      completeSetupDisabled ||
      syncOnlyLinkedAccounts === undefined
    ) {
      return;
    }

    setIsProvisioning(true);
    setProvisioningError(undefined);

    try {
      // We need to go through each step sequentially to ensure we fully provision
      // a workspace. We are intentionally not using break statements here to fall
      // through to the next step. A switch statement is a natural choice here since
      // a step could fail, and we want to start again at that step.
      switch (currentProvisionStep) {
        case PROVISION_STEP_INITIAL:
          setCurrentProvisionStep(PROVISION_STEP_ENV_VARIABLES);
        case PROVISION_STEP_ENV_VARIABLES:
          await setSalesforceCensusWorkspaceEnvironmentVariablesMutation({
            variables: {
              environment_variables: {
                only_linked_accounts: syncOnlyLinkedAccounts,
              },
            },
          });
          setCurrentProvisionStep(PROVISION_STEP_MODELS);
        case PROVISION_STEP_MODELS:
          await createOrUpdateSalesforceCensusModelsMutation();
          setCurrentProvisionStep(PROVISION_STEP_REFRESH_OBJECTS);
        case PROVISION_STEP_REFRESH_OBJECTS:
          await refreshSalesforceDestinationObjectsMutation();
          setCurrentProvisionStep(PROVISION_STEP_SYNCS);
        case PROVISION_STEP_SYNCS:
          await createOrUpdateSalesforceCensusSyncsMutation({
            // Refetch the workspace to move the salesforce integration
            // into the Active integrations list without a page refresh
            refetchQueries: [
              {
                query: GetSalesforceWorkspaceDocument,
                variables: {
                  destinationType: CensusDestinationTypeEnum.Salesforce,
                },
              },
            ],
          });
          setCurrentProvisionStep(PROVISION_STEP_COMPLETE);
          onSuccess();
        default:
          break;
      }
    } catch (e) {
      setIsProvisioning(false);
    }
  };

  const formatErrorMsg = (e: ApolloError) => {
    if (e.message.startsWith("Salesforce sync creation failed for entity")) {
      return "The Metronome Salesforce package was not installed or the authenticated user does not have permissions to all required objects. Please contact your Salesforce administrator.";
    }
    return "There was an error provisioning this workspace. Please try again. If the problem persists reach out to your Metronome representative.";
  };

  return (
    <div className="flex flex-col">
      <Button
        text={provisioningError ? "Retry setup" : "Complete setup"}
        className="mb-3xl"
        disabled={completeSetupDisabled || isProvisioning}
        loading={isProvisioning}
        onClick={completeSetup}
      />
      {currentProvisionStep > PROVISION_STEP_INITIAL && (
        <>
          {provisioningError ? (
            <div className="p-xl rounded-xl mb-3xl flex border-2 border-error-200 bg-error-50">
              <span className="text-sm text-error-600">
                {formatErrorMsg(provisioningError)}
              </span>
            </div>
          ) : (
            <div className="mb-lg text-sm text-gray-600">
              We are setting up your workspace. This may take a minute.
            </div>
          )}
          <ProvisionStep
            stepName="Set up environment variables"
            loading={envLoading}
            error={!!envError}
            complete={currentProvisionStep > PROVISION_STEP_ENV_VARIABLES}
          />
          <ProvisionStep
            stepName="Create Census datasets"
            loading={modelsLoading}
            error={!!modelsError}
            complete={currentProvisionStep > PROVISION_STEP_MODELS}
          />
          <ProvisionStep
            stepName="Refresh Salesforce objects"
            loading={refreshLoading}
            error={!!refreshError}
            complete={currentProvisionStep > PROVISION_STEP_REFRESH_OBJECTS}
          />
          <ProvisionStep
            stepName="Create Census syncs"
            loading={syncsLoading}
            error={!!syncsError}
            complete={currentProvisionStep > PROVISION_STEP_SYNCS}
          />
        </>
      )}
    </div>
  );
};

interface ProvisionStepProps {
  stepName: string;
  loading: boolean;
  error: boolean;
  complete: boolean;
}
const ProvisionStep: React.FC<ProvisionStepProps> = ({
  stepName,
  loading,
  error,
  complete,
}) => {
  const color = loading
    ? "text-core-slate"
    : error
      ? "text-error-600"
      : complete
        ? "text-core-jade-green"
        : "text-gray-500";

  return (
    <div className={twMerge("mb-lg inline-flex items-center", color)}>
      <Icon
        icon={
          loading ? "loading02" : error ? "alertCircle" : "checkCircleBroken"
        }
        className={twMerge(
          "mr-md pointer-events-none",
          loading ? "animate-spin" : "",
        )}
        size={16}
      />
      <span className={twMerge("text-sm", color)}>{stepName}</span>
    </div>
  );
};
