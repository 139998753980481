import React, { useState } from "react";
import "style/index.css";
import { useAuthCheck } from "lib/useAuthCheck";
import { useNow } from "lib/date";

import { AddPlanToCustomerDocument } from "pages/AddPlanToCustomer/queries.graphql";
import { dayjs } from "lib/dayjs";
import { useUIMode } from "lib/useUIMode";
import { useCustomerBillingOverviewQuery } from "pages/Contracts/Customer/BillingOverview/data.graphql";
import { CreateContractDocument } from "pages/Contracts/Customer/Contracts/Create/data.graphql";
import { useRequiredParam } from "lib/routes/params";
import { useApolloResp } from "pages/Contracts/lib/ApolloResp";
import { ContractOrPlan } from "pages/Contracts/lib/ContractOrPlan";
import { Column, Table } from "tenaissance/components/Table";
import { Dropdown, DropdownItem } from "tenaissance/components/Dropdown";
import { Timestamp } from "tenaissance/components/Timestamp";
import { Badge } from "tenaissance/components/Badge";
import { capitalizeFirstLetter } from "tenaissance/lib/utils";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Button } from "tenaissance/components/Button";
import { Card } from "tenaissance/components/Card";

type FilterOptions = "all" | "active" | "upcoming" | "expired" | "archived";
const FilterOptions = {
  ALL: "all" as FilterOptions,
  ACTIVE: "active" as FilterOptions,
  UPCOMING: "upcoming" as FilterOptions,
  EXPIRED: "expired" as FilterOptions,
  ARCHIVED: "archived" as FilterOptions,
};
const filterOptionsArray: FilterOptions[] = [
  FilterOptions.ALL,
  FilterOptions.ACTIVE,
  FilterOptions.UPCOMING,
  FilterOptions.EXPIRED,
  FilterOptions.ARCHIVED,
];

export const ContractsAndPlansTable: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const { mode } = useUIMode();
  const req = useApolloResp(
    useCustomerBillingOverviewQuery({ variables: { id: customerId } }),
  );
  const loading = req.state === "loading";
  const now = useNow();
  const canCreateContract = useAuthCheck(CreateContractDocument, false);
  const canCreatePlan = useAuthCheck(AddPlanToCustomerDocument, false);
  const [filter, setFilter] = useState<FilterOptions[]>([
    FilterOptions.ACTIVE,
    FilterOptions.EXPIRED,
    FilterOptions.UPCOMING,
  ]);

  const tableFilter = () => (
    <Dropdown
      key="status dropdown"
      icon="filterLines"
      hideChevron={true}
      label="Status"
      children={filterOptionsArray.map((o, idx) => (
        <DropdownItem
          key={`${idx}-${o}`}
          label={capitalizeFirstLetter(o)}
          value={o}
          selected={filter.includes(o)}
          onClick={(meta) => {
            const val = meta.value as FilterOptions;
            if (filter.includes(val)) {
              setFilter(filter.filter((f) => f !== val));
            } else if (val === FilterOptions.ALL) {
              setFilter([FilterOptions.ALL]);
            } else {
              setFilter([
                ...filter.filter((f) => f !== FilterOptions.ALL),
                val,
              ]);
            }
          }}
          isCheckbox={true}
        />
      ))}
    />
  );

  const addContractOrPlan = () => {
    if (mode === "contracts-and-plans") {
      return (
        <Dropdown
          key="contract or plan dropdown"
          icon="plus"
          label="Add"
          hideChevron={true}
          children={[
            <DropdownItem
              value="Contract"
              label="Contract"
              disabled={!canCreateContract}
              linkTo={`/customers/${customerId}/contracts/add`}
            />,
            <DropdownItem
              value="Plan"
              label="Plan"
              disabled={!canCreatePlan}
              linkTo={`/customers/${customerId}/plans/add`}
            />,
          ]}
        />
      );
    } else {
      return (
        <Button
          disabled={mode === "plans-only" ? !canCreatePlan : !canCreateContract}
          text="Add"
          leadingIcon="plus"
          linkTo={
            mode === "plans-only"
              ? `/customers/${customerId}/plans/add`
              : `/customers/${customerId}/contracts/add`
          }
        />
      );
    }
  };

  let title = "Contracts & plans";
  if (mode === "plans-only") title = "Plans";
  if (mode === "contracts-only") title = "Contracts";

  let noMatchingContractsOrPlansText = "contracts/plans";
  if (mode === "contracts-only") noMatchingContractsOrPlansText = "contracts";
  if (mode === "plans-only") noMatchingContractsOrPlansText = "plans";

  const contractsAndPlans = [];
  if (req.state === "success") {
    contractsAndPlans.push(...(req.customer.contracts ?? []));
    contractsAndPlans.push(...(req.customer.CustomerPlans ?? []));
  }

  if (req.state === "success" && contractsAndPlans.length === 0) {
    // customer has no contracts or plans yet
    let mainText = "contracts/plans",
      supportingText = "contracts or plans";

    if (mode === "contracts-only") {
      mainText = "contracts";
      supportingText = "contracts";
    }
    if (mode === "plans-only") {
      mainText = "plans";
      supportingText = "plans";
    }
    return (
      <Card title={title} headerActions={[addContractOrPlan()]}>
        <div className="my-3xl flex w-full flex-col items-center text-gray-600">
          <h3 className="text-md font-semibold text-black">
            No active {mainText}
          </h3>
          <div>This customer has no {supportingText}</div>
        </div>
      </Card>
    );
  }

  const contractsAndPlansToShow = contractsAndPlans.filter((contractOrPlan) => {
    const status = ContractOrPlan.getStatus(contractOrPlan, now);
    if (filter.length === 0) {
      return false;
    } else if (filter[0] === FilterOptions.ALL) {
      return true;
    } else {
      switch (status) {
        case "active":
        case "ending-soon":
          return filter.includes(FilterOptions.ACTIVE);
        case "active-soon":
        case "upcoming":
          return filter.includes(FilterOptions.UPCOMING);
        case "archived":
          return filter.includes(FilterOptions.ARCHIVED);
        case "inactive (renewed)":
        case "inactive (superseded)":
        case "recently-ended":
        case "ended":
          return filter.includes(FilterOptions.EXPIRED);
        default:
          status satisfies never;
          throw new Error(`Unhandled contract or plan status case: ${status}`);
      }
    }
  });

  type ContractOrPlan = (typeof contractsAndPlansToShow)[0];
  const tableColumns: Column<ContractOrPlan>[] = [
    {
      id: "1",
      accessorKey: "name",
      header: mode !== "plans-only" ? "Name & rate card" : "Name",
      supportingText:
        mode !== "plans-only"
          ? (rowData) => {
              if (rowData.__typename === "Contract") {
                return rowData.rate_card?.name ?? "--";
              }
              return "--";
            }
          : undefined,
      cell: (props) => (
        <div
          title={ContractOrPlan.getName(props.row.original)}
          className="max-w-[250px] truncate"
        >
          {ContractOrPlan.getName(props.row.original)}
        </div>
      ),
    },
    {
      id: "status",
      isDisplay: true,
      header: "Status",
      cell: (props) => {
        const status = ContractOrPlan.getStatus(props.row.original, now);
        return (
          <Badge
            label={capitalizeFirstLetter(status)}
            theme={
              status === "active"
                ? "azure-blue"
                : status === "active-soon"
                  ? "gray"
                  : "warning"
            }
          />
        );
      },
    },
    {
      id: "starting_at",
      header: "Starting at (UTC)",
      sortingFn: (a, b) => {
        return (
          (a.getValue("starting_at") as Date).getTime() -
          (b.getValue("starting_at") as Date).getTime()
        );
      },
      accessorFn: (row) => {
        if (row.__typename === "CustomerPlan") {
          return dayjs.utc(row.start_date).toDate();
        } else {
          return dayjs.utc(row.starting_at).toDate();
        }
      },
      cell: (props) => {
        return <Timestamp dateTime={props.getValue()} />;
      },
    },
    {
      id: "4",
      accessorKey: "ending_before",
      header: "Ending before (UTC)",
      cell: (props) => {
        if (props.row.original.__typename === "CustomerPlan") {
          return props.row.original.cancellation_date ? (
            <Timestamp
              dateTime={dayjs(props.row.original.cancellation_date).toDate()}
            />
          ) : (
            "--"
          );
        } else {
          return props.getValue() ? (
            <Timestamp dateTime={dayjs(props.getValue()).toDate()} />
          ) : (
            "--"
          );
        }
      },
    },
  ];

  return (
    <Table
      topBarActions={[tableFilter(), addContractOrPlan()]}
      title={title}
      paginationOptions={{
        type: "clientSide",
        pageSize: 5,
      }}
      defaultSort={[{ id: "starting_at", desc: true }]}
      emptyState={
        <EmptyState
          className="h-[250px] overflow-hidden"
          mainText={`No ${noMatchingContractsOrPlansText}`}
          supportingText={`This customer has no ${noMatchingContractsOrPlansText}${filter.length === 0 || filter[0] !== FilterOptions.ALL ? " for the given filter" : ""}`}
          icon="file05"
        />
      }
      loading={loading}
      columns={tableColumns}
      data={contractsAndPlansToShow}
      rowRoutePath={(row) => {
        if (row.original.__typename === "CustomerPlan") {
          return `/customers/${customerId}/plans/${row.original.id}`;
        } else {
          return `/customers/${row.original.customer.id}/contracts/${row.original.id}`;
        }
      }}
    />
  );
};
