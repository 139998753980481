import React from "react";

import { CreditInput } from "components/Input";
import { USD_CREDIT_TYPE } from "lib/credits";

import { Schema } from "../../Schema";
import { useOverrideCtrl } from "./OverrideCtrl";
import { NumericInput, Select } from "design-system";
import { CreditType } from "types/credit-types";

const useSubscriptionRateCtrl = useOverrideCtrl.child(Schema.SubscriptionRate, {
  read(parent, creditType: CreditType, isProrated: boolean) {
    const rate = parent.get("rate");
    return rate?.type === "overwrite" && rate.newRate.type === "subscription"
      ? {
          ...rate.newRate,
          creditType,
          isProrated,
        }
      : undefined;
  },
  write: (child) => ({
    rate: {
      type: "overwrite",
      newRate: child.getUnvalidatedInputs(),
    },
  }),
});

interface Props {
  parent: ReturnType<typeof useOverrideCtrl>;
  creditType?: CreditType;
  isProrated: boolean;
}

export const OverwriteSubscriptionRate: React.FC<Props> = (props) => {
  const rateCreditType = props.creditType ?? USD_CREDIT_TYPE;
  const ctrl = useSubscriptionRateCtrl(
    props.parent,
    rateCreditType,
    props.isProrated,
  );

  return (
    <>
      <CreditInput
        {...ctrl.props.CreditInput("unitPrice", {
          name: "Adjustment",
          tooltip: "Overwrites the price of the subscription",
          creditType: rateCreditType,
          placeholder: "0.00",
          allowZeroAmounts: true,
        })}
      />
      <NumericInput
        {...ctrl.props.NumericInput("quantity", {
          name: "Quantity",
          placeholder: "1",
          tooltip: "Set the quantity of the subscription",
        })}
      />
      <Select
        {...ctrl.props.Select("creditType", {
          placeholder: "",
          className: "hidden",
          options: [
            {
              label: rateCreditType.name,
              value: rateCreditType,
            },
          ],
        })}
      />
    </>
  );
};
