import { Dayjs, distanceFrom, toDayjs } from "lib/date";
import { Contract } from "../Contract";
import { CustomerPlan } from "../CustomerPlan";
import {
  CustomerListContractStatusFragment,
  CustomerListPlanStatusFragment,
} from "../Customer/fragments.graphql";

export type CustomerStatusFragment =
  | CustomerListContractStatusFragment
  | CustomerListPlanStatusFragment;

type StatusFragment = Contract.StatusFragment | CustomerPlan.StatusFragment;
type NameFragment = Contract.NameFragment | CustomerPlan.NameFragment;

export type Status =
  | "upcoming"
  | "active-soon"
  | "active"
  | "archived"
  | "ending-soon"
  | "recently-ended"
  | "ended"
  | "inactive (superseded)"
  | "inactive (renewed)";

function fork<
  ContractFragment extends { __typename?: "Contract" },
  PlanFragment extends { __typename?: "CustomerPlan" },
  Args extends any[],
  ContractResult,
  PlanResult,
>(
  item: ContractFragment | PlanFragment,
  contractFn: (item: ContractFragment, ...args: Args) => ContractResult,
  planFn: (item: PlanFragment, ...args: Args) => PlanResult,
  ...args: Args
): ContractResult | PlanResult {
  switch (item.__typename) {
    case "Contract":
      return contractFn(item, ...args);
    case "CustomerPlan":
      return planFn(item, ...args);
    default:
      throw new Error(`invalid __typename ${item.__typename}`);
  }
}

export function getName(item: NameFragment): string {
  return fork(item, Contract.getName, CustomerPlan.getName);
}

export function getCustomerStatus(item: CustomerStatusFragment, now: Dayjs) {
  if (item.__typename === "CustomerContractStatus" && item.archived_at) {
    return "archived";
  }

  const start = distanceFrom(now, toDayjs(item.starting_at));
  const end = item.ending_before
    ? distanceFrom(now, toDayjs(item.ending_before))
    : null;

  if (!start.isInPast) {
    return start.distDays >= 30 ? "upcoming" : "active-soon";
  }

  if (end?.isInPast) {
    return end.distDays <= 7 ? "recently-ended" : "ended";
  }

  return end && end?.distDays <= 30 ? "ending-soon" : "active";
}

export function getStatus(item: StatusFragment, now: Dayjs) {
  return fork(item, Contract.getStatus, CustomerPlan.getStatus, now);
}

export function isActiveCustomerStatus(
  item: CustomerStatusFragment,
  now: Dayjs,
): boolean {
  switch (getCustomerStatus(item, now)) {
    case "active":
    case "ending-soon":
      return true;
    default:
      return false;
  }
}
