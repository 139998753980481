import React, { useState } from "react";
import { useAuthCheck } from "lib/useAuthCheck";
import { ClientConfigKeyEnum_Enum } from "types/generated-graphql/__types__";
import {
  SaveClientConfigAndDeleteInvoiceDaysUntilDueDocument,
  SaveClientConfigWithInvoiceDaysUntilDueDocument,
} from "../queries.graphql";
import { StripeSettingsToggleProps } from ".";
import { Toggle } from "tenaissance/components/Toggle";

export const SkipZeroDollarInvoicesToggle: React.FC<
  StripeSettingsToggleProps
> = ({ isChecked, handleToggleOnChange }) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);
  const canSaveClientConfig = !!useAuthCheck(
    [
      SaveClientConfigWithInvoiceDaysUntilDueDocument,
      SaveClientConfigAndDeleteInvoiceDaysUntilDueDocument,
    ],
    true,
    {
      client_config: ClientConfigKeyEnum_Enum.SkipZeroDollarInvoices,
    },
  ).allowed;

  return (
    <Toggle
      toggled={isCheckedValue}
      label="Skip zero-total invoices"
      disabled={!canSaveClientConfig}
      onChange={(meta: { toggled: boolean }) =>
        handleToggleOnChange(
          meta.toggled,
          "skip_zero_dollar_invoices",
          setIsCheckedValue,
        )
      }
      supportingText="Only send invoices for non-zero amounts"
    />
  );
};
