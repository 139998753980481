import { Schema } from "../Schema";
import { FormController } from "lib/FormController";
import { ProductsQuery, useProductsQuery } from "./data.graphql";
import { ProductListItem } from "../../lib/ProductListItem";
import { useNow } from "lib/date";
import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import {
  ListBillableMetricsQuery,
  useCreateCompositeProductMutation,
  useCreateFixedProductMutation,
  useCreateProServiceProductMutation,
  useCreateSubscriptionProductMutation,
  useCreateUsageProductMutation,
  useListBillableMetricsQuery,
} from "../CreateAndEditProductModal/data.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useFeatureFlag } from "lib/launchdarkly";
import {
  BillingMetricAggregateEnum_Enum,
  ConversionOperation,
} from "types/generated-graphql/__types__";
import { shouldShowProductCreatedModal } from "./ProductCreatedModal";
import { useSnackbar } from "components/Snackbar";
import { useNavigate } from "lib/useNavigate";

const useCreateProductController = FormController.createHook(
  Schema.CreateProductInput,
  {
    init(snapshotKey: string) {
      const snapshot = FormController.parseJsonSnapshot(
        Schema.CreateProductInput,
        sessionStorage.getItem(snapshotKey),
      );
      return { ...snapshot, type: snapshot?.type ?? "usage" };
    },
  },
);

type Products = ProductsQuery["contract_pricing"]["products"];
type ProductListItem = Products[0];

const getProductLastEditedDate = (product: ProductListItem) => {
  if (!product.updates.length) {
    return new Date(product.current.created_at);
  }

  const lastEditedDates = product.updates.map(
    (update) => new Date(update.created_at),
  );

  return lastEditedDates.reduce((a, b) => (a > b ? a : b));
};

type BillingMetric = {
  __typename?: "BillableMetric";
  id: string;
  sql: string | null;
  aggregate: BillingMetricAggregateEnum_Enum;
  name: string;
  fancy_plan: object | null;
};

export type UsageProductType = {
  id: string;
  name: string;
  type: {
    billableMetric: BillingMetric | null | undefined;
    pricingGroupKeys: string[] | null;
    typeName: string;
  };
  lastEdited: Date;
};

export type BillableMetricsType =
  ListBillableMetricsQuery["billable_metrics"][number] & {
    selected: boolean;
  };

type ProductTypeEnum =
  | "usage"
  | "fixed"
  | "composite"
  | "subscription"
  | "proService";

const useProductContext = () => {
  // save snapshot on every change of the form
  const snapshotKey = `product-create-v2`;
  const ctrl = useCreateProductController(snapshotKey);
  const [productName, setProductNameInternal] = useState<string>(
    ctrl.get("name") ?? "",
  );
  const setProductName = useCallback(
    (name: string) => {
      setProductNameInternal(name);
      ctrl.update({ name });
    },
    [setProductNameInternal],
  );

  const [tags, setTagsInternal] = useState<string[]>(ctrl.get("tags") ?? []);

  const setTags = useCallback(
    (tags: string[]) => {
      setTagsInternal(tags);
      ctrl.update({ tags });
    },
    [setTagsInternal],
  );

  const [netSuiteItemId, setNetSuiteInternalItemId] = useState<string>(
    ctrl.get("netSuiteInternalItemId") ?? "",
  );

  const setNetSuiteItemId = useCallback(
    (netSuiteInternalId: string) => {
      setNetSuiteInternalItemId(netSuiteInternalId);
      ctrl.update({ netSuiteInternalItemId: netSuiteInternalId });
    },
    [setNetSuiteInternalItemId],
  );

  const [isRefundable, setIsRefundableInternal] = useState<boolean>(
    ctrl.get("isRefundable") ?? false,
  );

  const setIsRefundable = useCallback(
    (isRefundable: boolean) => {
      setIsRefundableInternal(isRefundable);
      ctrl.update({ isRefundable: isRefundable });
    },
    [setIsRefundableInternal],
  );

  const [productType, setProductTypeInternal] = useState<ProductTypeEnum>(
    ctrl.get("type") ?? "usage",
  );

  const switchProduct = (type: ProductTypeEnum) => {
    switch (type) {
      case "usage":
        setUsageProductFields(usageProductFields);
        setSelectedMetric(undefined);
        break;
      case "composite":
        setCompositeProductFields(compositeProductFields);
        break;
      case "subscription":
        setSubscriptionProductFields(subscriptionProductFields);
        break;
      case "proService":
        ctrl.update({
          netSuiteInternalItemId: netSuiteItemId,
          product: { type: "proService" },
        });
        break;
      case "fixed":
        ctrl.update({
          netSuiteInternalItemId: netSuiteItemId,
          product: { type: "fixed" },
        });
        break;
    }
  };

  const setProductType = useCallback(
    (type: ProductTypeEnum) => {
      setProductTypeInternal(type);
      ctrl.update({ type });
      switchProduct(type);
    },
    [setProductTypeInternal],
  );

  const product = ctrl.get("product");
  let billableMetricId: Schema.Types.CreateUsageProductInput["billableMetricId"] =
    "";
  let netSuiteOverageItemId:
    | Schema.Types.CreateUsageProductInput["netSuiteOverageItemId"]
    | Schema.Types.CreateCompositeProductInput["netSuiteOverageItemId"]
    | Schema.Types.SubscriptionProductInput["netSuiteOverageItemId"]
    | Schema.Types.ProServiceProductInput["netSuiteOverageItemId"] = "";
  let quantityConversion: Schema.Types.CreateUsageProductInput["quantityConversion"];
  let quantityRounding: Schema.Types.CreateUsageProductInput["quantityRounding"];
  let pricingGroupKey: Schema.Types.CreateUsageProductInput["pricingGroupKey"];
  let presentationGroupKey: Schema.Types.CreateUsageProductInput["presentationGroupKey"];
  let compositeProductIds: Schema.Types.CreateCompositeProductInput["compositeProductIds"] =
    [];
  let compositeTags: Schema.Types.CreateCompositeProductInput["compositeTags"] =
    [];
  let excludeFreeUsage: Schema.Types.CreateCompositeProductInput["excludeFreeUsage"] =
    false;
  const isUsageProduct = product?.type === "usage";
  const isCompositeProduct = product?.type === "composite";
  const isSubscriptionProduct = product?.type === "subscription";
  if (isUsageProduct) {
    billableMetricId = product.billableMetricId;
    netSuiteOverageItemId = product.netSuiteOverageItemId ?? "";
    quantityRounding = product.quantityRounding;
    pricingGroupKey = product.pricingGroupKey;
    presentationGroupKey = product.presentationGroupKey;
  } else if (isCompositeProduct) {
    netSuiteOverageItemId = product.netSuiteOverageItemId ?? "";
    compositeProductIds = product.compositeProductIds ?? [];
    compositeTags = product.compositeTags ?? [];
    excludeFreeUsage = product.excludeFreeUsage ?? false;
  } else if (isSubscriptionProduct) {
    netSuiteOverageItemId = product.netSuiteOverageItemId ?? "";
  }

  const [subscriptionProductFields, setSubscriptionProductFieldsInternal] =
    useState<Partial<Schema.Types.SubscriptionProductInput>>({
      netSuiteOverageItemId,
    });

  const setSubscriptionProductFields = useCallback(
    (
      subscriptionFieldObject: Partial<Schema.Types.SubscriptionProductInput>,
    ) => {
      setSubscriptionProductFieldsInternal({ ...subscriptionFieldObject });
      ctrl.update({
        product: { type: "subscription", ...subscriptionFieldObject },
      });
    },
    [setSubscriptionProductFieldsInternal],
  );

  const [usageProductFields, setUsageProductFieldsInternal] = useState<
    Partial<Schema.Types.CreateUsageProductInput>
  >({
    billableMetricId,
    netSuiteOverageItemId,
    quantityConversion,
    quantityRounding,
    pricingGroupKey,
    presentationGroupKey,
  });

  const setUsageProductFields = useCallback(
    (usageFieldObject: Partial<Schema.Types.CreateUsageProductInput>) => {
      setUsageProductFieldsInternal({ ...usageFieldObject });
      ctrl.update({ product: { type: "usage", ...usageFieldObject } });
    },
    [setUsageProductFieldsInternal],
  );

  const [compositeProductFields, setCompositeProductFieldsInternal] = useState<
    Partial<Schema.Types.CreateCompositeProductInput>
  >({
    compositeProductIds,
    compositeTags,
    netSuiteOverageItemId,
    excludeFreeUsage,
  });

  const setCompositeProductFields = useCallback(
    (
      compositeFieldObject: Partial<Schema.Types.CreateCompositeProductInput>,
    ) => {
      setCompositeProductFieldsInternal({ ...compositeFieldObject });
      ctrl.update({ product: { type: "composite", ...compositeFieldObject } });
    },
    [setCompositeProductFieldsInternal],
  );

  useEffect(() => {
    sessionStorage.setItem(snapshotKey, JSON.stringify(ctrl.snapshot()));
  }, [ctrl]);

  const clearSnapshot = () => {
    sessionStorage.removeItem(snapshotKey);
  };
  // Get list of usage products for compositeProductIds selection
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useProductsQuery();
  const allProducts = productsData?.contract_pricing.products ?? [];

  const usageProducts: UsageProductType[] = allProducts.flatMap((p) =>
    p.__typename === "UsageProductListItem" ||
    p.__typename === "SubscriptionProductListItem"
      ? {
          id: p.id,
          name: p.current.name,
          type: {
            billableMetric:
              "billable_metric" in p.current
                ? p.current.billable_metric
                : undefined,
            pricingGroupKeys:
              "pricing_group_key" in p.current
                ? p.current.pricing_group_key
                : null,
            typeName: p.__typename,
          },
          lastEdited: getProductLastEditedDate(p),
        }
      : [],
  );

  const { environmentType } = useEnvironment();

  // Get billable metrics for usage product creation
  const {
    data: billableMetricsData,
    loading: billableMetricsLoading,
    error: billableMetricsError,
  } = useListBillableMetricsQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const [createCompositeProductMutation, createCompositeProductResult] =
    useCreateCompositeProductMutation();
  const [createFixedProductMutation, createFixedProductResult] =
    useCreateFixedProductMutation();
  const [createUsageProductMutation, createUsageProductResult] =
    useCreateUsageProductMutation();
  const [createSubscriptionProductMutation, createSubscriptionProductResult] =
    useCreateSubscriptionProductMutation();
  const [createProServiceProductMutation, createProServiceProductResult] =
    useCreateProServiceProductMutation();

  const createCompositeProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const result = await createCompositeProductMutation({
      variables: {
        ...baseVariables,
        compositeProductIds: valid.product.compositeProductIds,
        compositeTags: valid.product.compositeTags,
        netSuiteOverageItemId: valid.product.netSuiteOverageItemId?.trim(),
        excludeFreeUsage: valid.product.excludeFreeUsage,
      },
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return result.data?.create_composite_product_list_item?.id;
  };

  const createFixedProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const fixedProductResult = await createFixedProductMutation({
      variables: baseVariables,
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return fixedProductResult.data?.create_fixed_product_list_item?.id;
  };

  const createUsageProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const usageProductResult = await createUsageProductMutation({
      variables: {
        ...baseVariables,
        billableMetricId: valid.product.billableMetricId,
        netSuiteOverageItemId: valid.product.netSuiteOverageItemId?.trim(),
        quantityConversion: valid.product.quantityConversion
          ? {
              conversion_factor:
                valid.product.quantityConversion.conversionFactor.toString(),
              name: valid.product.quantityConversion.name,
              operation:
                valid.product.quantityConversion.operation === "Divide"
                  ? ConversionOperation.Divide
                  : ConversionOperation.Multiply,
            }
          : undefined,
        quantityRounding: valid.product.quantityRounding
          ? {
              decimal_places: valid.product.quantityRounding.decimalPlaces,
              rounding_method: valid.product.quantityRounding.roundingMethod,
            }
          : undefined,
        pricingGroupKey: valid.product.pricingGroupKey,
        presentationGroupKey: valid.product.presentationGroupKey,
      },
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return usageProductResult.data?.create_usage_product_list_item?.id;
  };

  const createSubscriptionProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const subscriptionProductResult = await createSubscriptionProductMutation({
      variables: {
        ...baseVariables,
        netSuiteOverageItemId: valid.product.netSuiteOverageItemId?.trim(),
      },
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return subscriptionProductResult.data?.create_subscription_product_list_item
      ?.id;
  };

  const createProServiceProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const proServiceProductResult = await createProServiceProductMutation({
      variables: baseVariables,
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return proServiceProductResult.data?.create_pro_service_product_list_item
      ?.id;
  };

  const isSubmitting =
    createCompositeProductResult.loading ||
    createFixedProductResult.loading ||
    createUsageProductResult.loading;

  const [billableMetrics, setBillableMetricsInternal] = useState<
    BillableMetricsType[]
  >([]);

  useEffect(() => {
    if (billableMetricsData) {
      // We currently only support count, sum and max billable metrics
      const countSumAndMaxBillableMetrics = [
        ...(billableMetricsData?.billable_metrics ?? [])
          .filter((bm) => ["count", "sum", "max"].includes(bm.aggregate))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ];
      setBillableMetricsInternal(
        countSumAndMaxBillableMetrics.map((bm) => ({
          ...bm,
          selected: false,
        })),
      );
    }
  }, [billableMetricsData]);

  const setSelectedMetric = useCallback(
    (selectedMetric: BillableMetricsType | undefined) => {
      // upon switching product types, clear the selected metric
      if (!selectedMetric) {
        setBillableMetricsInternal((prev) =>
          prev.map((metric) => ({ ...metric, selected: false })),
        );
        setUsageProductFields({
          ...usageProductFields,
          billableMetricId: "",
        });
        return;
      }
      // only one metric can be selected at a time
      // only update if the selected metric is different from the current one
      setBillableMetricsInternal((prev) =>
        prev.map((metric) => {
          if (metric.id === selectedMetric.id) {
            if (metric.selected) {
              return metric;
            }
            return { ...metric, selected: true };
          }

          if (!metric.selected) {
            return metric;
          }

          return { ...metric, selected: false };
        }),
      );
      if (usageProductFields.billableMetricId !== selectedMetric.id) {
        setUsageProductFields({
          ...usageProductFields,
          billableMetricId: selectedMetric.id,
        });
      }
    },
    [setBillableMetricsInternal, usageProductFields, setUsageProductFields],
  );

  const now = useNow();

  const tagsFromDb = [
    ...new Set(
      allProducts.flatMap((product) => ProductListItem.getTags(product, now)),
    ),
  ];

  const existingTagValues = new Set(
    tagsFromDb.concat(ctrl.state.fields["tags"].value ?? []),
  ); // we need the existing tag values in the options for them to render properly

  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const netsuiteEnabled = nonGAContractFeaturesEnabled?.includes("NETSUITE");

  const excludeFreeUsageEnabled = nonGAContractFeaturesEnabled?.includes(
    "COMPOSITE_EXCLUDE_FREE_USAGE",
  );

  const allowProfessionalServices = nonGAContractFeaturesEnabled?.includes(
    "PROFESSIONAL_SERVICES",
  );

  const refundableProductsEnabled = nonGAContractFeaturesEnabled?.includes(
    "REFUNDABLE_PRODUCTS",
  );

  const isSaveDisabled =
    !ctrl.isValid() ||
    createCompositeProductResult.loading ||
    createFixedProductResult.loading ||
    createUsageProductResult.loading ||
    createSubscriptionProductResult.loading ||
    createProServiceProductResult.loading;

  const productTypeOptions: {
    label: string;
    value: "composite" | "fixed" | "usage" | "subscription" | "proService";
    hidden?: boolean | undefined;
  }[] = [
    { label: "Usage", value: "usage" as const },
    { label: "Fixed", value: "fixed" as const },
    { label: "Composite", value: "composite" as const },
    {
      label: "Subscription",
      value: "subscription" as const,
    },
  ];

  if (allowProfessionalServices) {
    productTypeOptions.push({
      label: "Professional service",
      value: "proService" as const,
    });
  }

  const [createdProductId, setCreatedProductId] = useState<string | null>(null);
  const [showProductCreatedModal, setShowProductCreatedModal] = useState(false);

  const pushMessage = useSnackbar();
  const pushSuccessMessage = (type: string, productName: string) => {
    pushMessage({
      content: `Successfully created new ${type === "proService" ? "professional service" : type} product: ${productName}`,
      type: "success",
    });
  };
  const pushErrorMessage = (type: string, e: unknown) =>
    pushMessage({
      content: `Failed to create new ${type} product: ${e}`,
      type: "error",
    });

  const navigate = useNavigate();

  const onSubmitAndAddAnother = FormController.useSubmitHandler(
    ctrl,
    async (valid) => {
      const baseVariables = {
        ...valid,
        name: valid.name.trim(),
        tags: valid.tags,
        netSuiteInternalItemId: valid.netSuiteInternalItemId?.trim(),
      };
      switch (valid.product.type) {
        case "composite": {
          try {
            await createCompositeProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("composite", e);
            return;
          }
          break;
        }
        case "fixed": {
          try {
            await createFixedProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("fixed", e);
            return;
          }
          break;
        }
        case "usage": {
          try {
            await createUsageProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("usage", e);
            return;
          }
          break;
        }
        case "subscription":
          try {
            await createSubscriptionProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("subscription", e);
            return;
          }
          break;
        case "proService": {
          try {
            await createProServiceProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("proService", e);
            return;
          }
          break;
        }
        default: {
          valid.product satisfies never;
        }
      }
      sessionStorage.setItem(snapshotKey, "");
      ctrl.reset();

      pushSuccessMessage(valid.product.type, valid.name);
    },
  );

  const useOnSubmit = (onClose?: (newProductId?: string) => void) => {
    return FormController.useSubmitHandler(ctrl, async (valid) => {
      const baseVariables = {
        ...valid,
        name: valid.name.trim(),
        tags: valid.tags,
        netSuiteInternalItemId: valid.netSuiteInternalItemId?.trim(),
      };

      switch (valid.product.type) {
        case "composite":
          await handleProductCreation(
            createCompositeProduct,
            "composite",
            baseVariables,
            valid,
            onClose,
          );
          break;
        case "fixed":
          await handleProductCreation(
            createFixedProduct,
            "fixed",
            baseVariables,
            valid,
            onClose,
          );
          break;
        case "usage":
          await handleProductCreation(
            createUsageProduct,
            "usage",
            baseVariables,
            valid,
            onClose,
          );
          break;
        case "subscription":
          await handleProductCreation(
            createSubscriptionProduct,
            "subscription",
            baseVariables,
            valid,
            onClose,
          );
          break;
        case "proService":
          await handleProductCreation(
            createProServiceProduct,
            "proService",
            baseVariables,
            valid,
            onClose,
          );
          break;
        default:
          valid.product as never; // TypeScript safeguard
      }
    });
  };

  const handleProductCreation = async (
    createProductFn: Function,
    productType: string,
    baseVariables: any,
    valid: any,
    onClose?: (newProductId?: string) => void,
  ) => {
    try {
      const newProductId = await createProductFn(baseVariables, valid);
      if (newProductId) {
        sessionStorage.setItem(snapshotKey, "");
        ctrl.reset();
        if (shouldShowProductCreatedModal()) {
          setCreatedProductId(newProductId);
          setShowProductCreatedModal(true);
        } else {
          pushSuccessMessage(productType, valid.name);
          onClose
            ? onClose(newProductId)
            : navigate(`/offering/products?product_id=${newProductId}`);
        }
      }
    } catch (e) {
      pushErrorMessage(productType, e);
    }
  };

  return {
    usageProducts,
    tagsFromDb,
    productsData,
    productsLoading,
    productsError,
    clearSnapshot,
    isSubmitting,
    productName,
    setProductName,
    tags,
    setTags,
    snapshotKey,
    billableMetricsLoading,
    billableMetricsError,
    billableMetrics,
    setSelectedMetric,
    netsuiteEnabled,
    excludeFreeUsageEnabled,
    allowProfessionalServices,
    refundableProductsEnabled,
    netSuiteItemId,
    setNetSuiteItemId,
    productType,
    setProductType,
    isRefundable,
    setIsRefundable,
    existingTagValues,
    isSaveDisabled,
    usageProductFields,
    setUsageProductFields,
    compositeProductFields,
    setCompositeProductFields,
    subscriptionProductFields,
    setSubscriptionProductFields,
    showProductCreatedModal,
    createdProductId,
    setShowProductCreatedModal,
    useOnSubmit,
    onSubmitAndAddAnother,
  };
};

export const ProductContext = createContainer(useProductContext);
