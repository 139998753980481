import React, { useEffect } from "react";
import classNames from "classnames";

import styles from "./index.module.less";
import { PageHeader } from "components/PageHeader";
import { ImpersonationBanner } from "components/ImpersonationBanner";
import { useAuth, useCurrentUser } from "lib/auth";
import { Tabs, TabProps } from "components/Tabs";
import { Badge, BadgeProps } from "design-system";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { Breadcrumbs } from "lib/breadcrumbs";
import { useAuthCheck } from "lib/useAuthCheck";
import { EmptyState } from "tenaissance/components/EmptyState";
import { DocumentNode } from "graphql";
import { Sidebar } from "../Sidebar";
import { Sidebar as T10Sidebar } from "tenaissance/components/Sidebar";
import {
  PageHeaderProps,
  PageHeader as T10PageHeader,
} from "tenaissance/components/PageHeader";
import { AuthCheck } from "../AuthCheck";
import { RedirectEnvironmentIfInvalid } from "lib/environmentSwitcher/EnvironmentRedirect";

interface FullScreenProps extends React.PropsWithChildren {
  title?: string;
}

const NonProdModeHeader: React.FC<{
  environment: string;
}> = ({ environment }) => {
  return (
    <div className={styles.sandboxModeHeader} data-testid="sandbox-mode-header">
      <div className={styles.label}>{environment}</div>
    </div>
  );
};

export const FullScreenPage: React.FC<FullScreenProps> = ({
  title,
  children,
}) => {
  const auth = useAuth();
  const sandboxMode = useEnvironment();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (title) {
      document.title = `${title} | Metronome`;
    } else {
      document.title = "Metronome";
    }

    return () => {
      document.title = "Metronome";
    };
  }, [title]);

  return (
    <>
      <div className="grow-0">
        {auth.impersonated && (
          <ImpersonationBanner
            email={user?.email ?? "loading"}
            expiration={auth.impersonationSessionExpires}
            role={user?.role ?? "loading"}
          />
        )}
      </div>
      <div className="grow-0">
        {sandboxMode.environmentType !== EnvironmentTypeEnum_Enum.Production &&
        sandboxMode.supported ? (
          <NonProdModeHeader environment={sandboxMode.environment.name} />
        ) : null}
      </div>
      <div className="flex shrink grow flex-col overflow-hidden bg-white">
        {children}
      </div>
    </>
  );
};

interface PageContainerProps extends React.PropsWithChildren {
  title: string | Breadcrumbs;
  /** Action that is rendered in the header */
  action?: React.ReactNode;
  /** Action that should be rendered in the tab bar */
  tabsAction?: React.ReactNode;
  badge?: BadgeProps;
  hideHeader?: boolean;
  tabs?: TabProps[];
  editButton?: React.ReactNode;
  authDoc?: DocumentNode;
  authVariables?: any;
  disableContainerScroll?: boolean;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  title,
  action,
  tabsAction: tabAction,
  badge,
  children,
  hideHeader,
  tabs,
  editButton,
  authDoc,
  authVariables,
  disableContainerScroll,
}) => {
  const { allowed } = useAuthCheck(authDoc, true, authVariables);
  return (
    <FullScreenPage
      title={typeof title === "string" ? title : title.toPageTitle()}
    >
      <div className={styles.innerContainer}>
        {process.env.NODE_ENV !== "test" && <Sidebar />}
        <div className={styles.main}>
          {!hideHeader && (
            <div>
              <PageHeader
                title={title}
                badge={
                  badge && (
                    <div data-testid="page-header-badge">
                      <Badge theme={badge.theme} type={badge.type}>
                        {badge.children}
                      </Badge>
                    </div>
                  )
                }
                action={action}
                editButton={editButton}
                type="primary"
              />
            </div>
          )}
          {tabs && (
            <div className={styles.tabs}>
              <Tabs tabs={tabs} />
              <div className="flex items-center">{tabAction}</div>
            </div>
          )}
          {allowed ? (
            <div
              className={classNames(styles.content, {
                "overflow-hidden": disableContainerScroll,
              })}
            >
              {children}
            </div>
          ) : (
            <EmptyState
              icon="alertCircle"
              mainText="You do not have access to view this page"
              supportingText="Please contact your administrator for more information."
            />
          )}
        </div>
      </div>
    </FullScreenPage>
  );
};

interface AppShellProps extends React.PropsWithChildren {
  title: string | { state: "loading" };
  headerProps?: Omit<PageHeaderProps, "title">;
  hideHeader?: boolean;
  // Block access to the page if the user is not authorized to access the query specified below
  authDoc?: DocumentNode;
  authVariables?: any;
}

/**
 * The AppShell is the primary layout of the application (tenaissance edition).
 */
export const AppShell: React.FC<AppShellProps> = ({
  title,
  headerProps,
  hideHeader,
  children,
  authDoc,
  authVariables,
}) => {
  return (
    <RedirectEnvironmentIfInvalid>
      <FullScreenPage title={typeof title === "string" ? title : undefined}>
        <div className="flex flex-1 overflow-hidden">
          <T10Sidebar />
          <div className="flex flex-1 flex-col overflow-hidden">
            {!hideHeader && <T10PageHeader title={title} {...headerProps} />}
            <AuthCheck authDoc={authDoc} authVars={authVariables}>
              <div className="p-xl flex-1 overflow-auto">{children}</div>
            </AuthCheck>
          </div>
        </div>
      </FullScreenPage>
    </RedirectEnvironmentIfInvalid>
  );
};
