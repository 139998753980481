import React from "react";
import { Input, Body, Subtitle } from "design-system";

type ProductInfoSectionProps = {
  sectionHeader: string;
  name: string | null;
  onChangeName: (v: string) => void;
  description: string | null;
  onChangeDescription: (v: string) => void;
};
export const ProductInfoSection: React.FC<ProductInfoSectionProps> = ({
  sectionHeader,
  name,
  onChangeName,
  description,
  onChangeDescription,
}) => {
  return (
    <div>
      <div className="border-b-gray-100 mb-4 border-b px-0 py-8">
        <Subtitle level={1}>{sectionHeader}</Subtitle>
      </div>
      <Body level={2}>
        Name and description: Your product name will appear on customer's
        invoices. The description only appear in the Metronome UI.
      </Body>
      <div className="mt-12 flex flex-row items-center gap-12">
        <Input
          name="Product name"
          placeholder="Enter the customer-facing product name"
          className="grow"
          value={name ?? ""}
          onChange={onChangeName}
          error={name !== null && name === ""}
        />
        <Input
          name="Product description"
          placeholder="Enter a short description of this product"
          className="flex-grow-[2]"
          value={description ?? ""}
          onChange={onChangeDescription}
          error={description !== null && description === ""}
        />
      </div>
    </div>
  );
};
