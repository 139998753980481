import React from "react";
import classNames from "classnames";

interface Props {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const FooterBar: React.FC<Props> = (props) => (
  <div
    className={classNames(
      "flex grow-0 flex-row justify-between gap-4 p-12",
      "border-t border-t-gray-100",
      "shadow-[0px_-1px_16px_0px_rgba(0,_0,_0,_0.12)]",
    )}
  >
    <div className="flex gap-12">{props.left}</div>
    <div className="flex gap-12">{props.right}</div>
  </div>
);
