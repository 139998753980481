import { useSearchParams } from "react-router-dom";

export interface ListOpts {
  sort: ListOpts.Sort;
  statuses: ListOpts.Status[] | null;
  hideZero: boolean;
}

export namespace ListOpts {
  export type Status = (typeof LIST_OPTS_VALUES)["status"][number];
  export type Sort = (typeof LIST_OPTS_VALUES)["sort"][number];
}

export const LIST_OPTS_LABELS: {
  sort: Record<ListOpts.Sort, string>;
  status: Record<ListOpts.Status, string>;
} = {
  sort: {
    date_desc: "Newest first",
    date_asc: "Oldest first",
    amount_desc: "Largest first",
    amount_asc: "Smallest first",
  },
  status: {
    "pending-finalization": "Pending Finalization",
    draft: "Draft",
    finalized: "Finalized",
    voided: "Voided",
  },
};

export const LIST_OPTS_VALUES = {
  status: ["draft", "finalized", "pending-finalization", "voided"],
  sort: ["date_desc", "date_asc", "amount_desc", "amount_asc"],
} as const;

export const DEFAULT_LIST_OPTS: ListOpts = {
  sort: "date_desc",
  statuses: null,
  hideZero: false,
};

function parseStatus(v: unknown): ListOpts.Status[] | null {
  if (typeof v === "string") {
    const inputs = v
      .split(",")
      .map((s) => s.trim())
      .filter((s) => LIST_OPTS_VALUES.status.includes(s as any));
    if (inputs.length) {
      return inputs as ListOpts.Status[];
    }
  }

  return DEFAULT_LIST_OPTS.statuses;
}

function parseSortBy(v: unknown): ListOpts.Sort {
  return LIST_OPTS_VALUES.sort.includes(v as any)
    ? (v as ListOpts.Sort)
    : DEFAULT_LIST_OPTS.sort;
}

export function useListOpts(): [
  value: ListOpts,
  update: (update: Partial<ListOpts>) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();
  const listOpts: ListOpts = {
    sort: parseSortBy(searchParams.get("sort")),
    statuses: parseStatus(searchParams.get("statuses")),
    hideZero: searchParams.get("hideZero") === "true",
  };

  function setListOpts(update: Partial<ListOpts>) {
    const { sort, statuses, hideZero } = { ...listOpts, ...update };
    const newOpts = new URLSearchParams(searchParams);
    if (sort === DEFAULT_LIST_OPTS.sort) {
      newOpts.delete("sort");
    } else {
      newOpts.set("sort", sort);
    }
    if (statuses === null) {
      newOpts.delete("statuses");
    } else {
      newOpts.set("statuses", statuses.join(","));
    }
    if (!hideZero) {
      newOpts.delete("hideZero");
    } else {
      newOpts.set("hideZero", "true");
    }
    setSearchParams(newOpts, {
      replace: true,
    });
  }

  return [listOpts, setListOpts];
}
