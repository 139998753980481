import React from "react";
import { Popup } from "components/Popup";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { useArchivePlanMutation } from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";

type Plan = {
  id: string;
  name: string;
};

interface ArchivePlanModalProps {
  onClose: () => void;
  plan: Plan;
}

const ArchivePlanModal: React.FC<ArchivePlanModalProps> = ({
  onClose,
  plan,
}) => {
  const [archivePlan, archivePlanResults] = useArchivePlanMutation({
    update(cache) {
      cache.evict({
        fieldName: "plans",
      });
      // Products that couldn't be archived because they were used by this plan
      // might now be archivable! So we need to evict the cache so that page
      // can show the archive option.
      cache.evict({
        fieldName: "products",
      });
    },
  });

  const pushMessage = useSnackbar();

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            disabled={plan.id === undefined || archivePlanResults.loading}
            loading={archivePlanResults.loading}
            onClick={async () => {
              if (plan.id) {
                try {
                  await archivePlan({
                    variables: {
                      plan_id: plan.id,
                    },
                  });
                } catch (e) {
                  pushMessage({
                    content: `Something went wrong: Check that '${plan.name}' is not in use by any current or future customers`,
                    type: "error",
                  });
                }
              }
              onClose();
            }}
            text="Archive plan"
            theme="linkDestructive"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Are you sure you want to archive this plan?"
    >
      <Body level={2}>
        Are you sure you want to archive <strong>{plan.name}</strong>? Once a
        plan is archived it can no longer be used, and will no longer appear on
        the overview by default.
      </Body>
    </Popup>
  );
};

export default ArchivePlanModal;
