import React from "react";
import { Modal } from "../Modal";
import { Button } from "../Button";

type Props = {
  element: string;
  supportingText?: string;
  onCancel: () => void;
  onArchive: () => Promise<void>;
};

export default function ArchiveModal({
  element,
  supportingText,
  onCancel,
  onArchive,
}: Props) {
  const [saving, setSaving] = React.useState(false);
  return (
    <Modal
      icon="trash01"
      title={`Archive ${element}`}
      onClose={onCancel}
      isOpen={true}
      showCloseButton={true}
      modalButtons={[
        <Button
          key="archive"
          onClick={async () => {
            try {
              setSaving(true);
              await onArchive();
            } finally {
              setSaving(false);
            }
          }}
          leadingIcon="trash01"
          theme="linkDestructive"
          text={`Archive ${element}`}
          loading={saving}
        />,
        <Button
          key="cancel"
          onClick={onCancel}
          text="Cancel"
          theme="tertiary"
        />,
      ]}
    >
      {supportingText && (
        <p className="text-sm text-gray-500">{supportingText}</p>
      )}
    </Modal>
  );
}
