import React, { useState } from "react";
import { Button } from "tenaissance/components/Button";
import { Takeover } from "tenaissance/components/Takeover";
import { useNavigate } from "lib/useNavigate";
import { Checkbox } from "tenaissance/components/Checkbox";
import { IntroEmptyState } from "tenaissance/components/IntroEmptyState";

export const shouldShowPreviewPage = () =>
  localStorage.getItem("show-rate-card-create-preview") === null;

interface RateCardCreateLandingPageProps {
  setShowPreviewPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RateCardCreateLandingPage: React.FC<
  RateCardCreateLandingPageProps
> = ({ setShowPreviewPage }) => {
  const navigate = useNavigate();

  const [dontShowPreviewAgainClicked, setDontShowPreviewAgainClicked] =
    useState(false);

  const handleContinueClick = () => {
    if (dontShowPreviewAgainClicked)
      localStorage.setItem("show-rate-card-create-preview", "false");
    setShowPreviewPage(false);
  };

  return (
    <Takeover
      maxContainerWidth="max-w-[616px]"
      isOpen={true}
      onClose={() => navigate("/contract-pricing/rate-cards")}
      title="Create a rate card"
      footerTrailingButtons={[
        <Checkbox
          size="sm"
          className="mr-[12px] flex flex-row items-center"
          label="Don't show again"
          checked={dontShowPreviewAgainClicked}
          onClick={(meta: { checked: boolean }) =>
            setDontShowPreviewAgainClicked(meta.checked)
          }
        />,
        <Button
          className="w-[128px] justify-center"
          onClick={handleContinueClick}
          text="Continue"
          theme="primary"
          type="submit"
        />,
      ]}
      footerLeadingButton={
        <Button
          className="w-[128px] justify-center"
          text="Back"
          onClick={() => {
            navigate("/offering/rate-cards");
          }}
          theme="secondary"
        />
      }
      children={
        <IntroEmptyState
          icon="data"
          mainText="Welcome to the Metronome rate card creator"
          supportingText="A rate card outlines default product pricing and access for a group of customers. You can then override these values when creating a customer's contract"
          bullets={[
            {
              mainText: "Add products",
              supportingText:
                "Select products to add to this rate card. Products can be usage-based, subscriptions, or composite.",
            },
            {
              mainText: "Add dimensional pricing (optional)",
              supportingText:
                "If you select products with dimensional pricing keys you’ll have an additional step to define values for each pricing key. This will enable a single product to have variable rates based on the properties of an event.",
            },
            {
              mainText: "Rate products",
              supportingText:
                "Set up default pricing. Rates are associated with start and end dates, so you can build in pricing and entitlement changes over time. Entitlements determine if customers get access to a product by default.",
            },
          ]}
        />
      }
    ></Takeover>
  );
};
