import { DateInput } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { useSnackbar } from "components/Snackbar";
import { Body } from "design-system";
import React, { useState } from "react";

import { useMigrateCreditGrantsMutation } from "./queries.graphql";
import { clearCustomerInvoicesFromCache } from "../../lib/cache";
import { dayjs } from "lib/dayjs";

interface MigrateCreditGrantsModalProps {
  onClose: () => void;
  customerId: string;
  freeCreditsProductId: string;
  prepaidCreditsProductId: string;
}

const MigrateCreditGrantsModal: React.FC<MigrateCreditGrantsModalProps> = ({
  onClose,
  customerId,
  freeCreditsProductId,
  prepaidCreditsProductId,
}) => {
  const [migrateCreditGrants, migrateCreditGrantsResults] =
    useMigrateCreditGrantsMutation({
      update(cache) {
        clearCustomerInvoicesFromCache(cache, customerId);
        cache.evict({
          id: `Customer:${customerId}`,
          fieldName: "commits",
        });
      },
    });

  const pushMessage = useSnackbar();

  const [expiryDate, setExpiryDate] = useState<Date>();
  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            disabled={migrateCreditGrantsResults.loading}
            loading={migrateCreditGrantsResults.loading}
            onClick={async () => {
              if (customerId && expiryDate) {
                try {
                  await migrateCreditGrants({
                    variables: {
                      customer_id: customerId,
                      free_credits_product_id: freeCreditsProductId,
                      prepaid_credits_product_id: prepaidCreditsProductId,
                      contract_credit_priority_override: "25",
                      expire_rolled_over_credits_after:
                        expiryDate.toISOString(),
                    },
                  });
                  pushMessage({
                    content: "Migrated grants successfully",
                    type: "success",
                  });
                } catch (e: any) {
                  pushMessage({
                    content: `An error occurred. Please contact us for support.`,
                    type: "error",
                  });
                }
              }
              onClose();
            }}
            text="Migrate credit grants"
            theme="primary"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Migrate credit grants"
    >
      <Body level={2}>
        Only choose to migrate credit grants if a Paygo or Explore customer is
        upgrading to an enterprise contract. Enter the date their Enterprise
        contract starts below.
      </Body>
      <DateInput
        value={expiryDate}
        success={!!expiryDate}
        isUTC
        onChange={(d) => setExpiryDate(dayjs.utc(d).toDate())}
      />
    </Popup>
  );
};

export default MigrateCreditGrantsModal;
