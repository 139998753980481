import React, { useEffect } from "react";
import { useExchangeStripeCodeForCredentialsMutation } from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";
import { useNavigate } from "lib/useNavigate";
import { useEnvironment } from "lib/environmentSwitcher/context";

const StripeRedirect: React.FC = () => {
  const { environmentType } = useEnvironment();
  const [exchangeStripeCodeForCredentials] =
    useExchangeStripeCodeForCredentialsMutation();
  const pushMessage = useSnackbar();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    void (async () => {
      if (params.has("error")) {
        pushMessage({
          content: params.get("error_description"),
          type: "error",
        });
        navigate("/settings/general/integrations", { replace: true });
        return;
      }
      try {
        const { data } = await exchangeStripeCodeForCredentials({
          variables: {
            environment_type: environmentType,
            code: params.get("code") || "",
          },
          update(cache) {
            cache.evict({
              fieldName: "BillingProviderToken",
            });
          },
        });
        if (!data?.store_stripe_credentials.success) {
          throw new Error("Did not receive success");
        }
        pushMessage({ content: "Connected with Stripe", type: "success" });
      } catch (err) {
        pushMessage({
          content: "Connection with Stripe failed",
          type: "error",
        });
      }
      navigate("/settings/general/integrations", { replace: true });
      return;
    })();
  }, [window.location.search]);

  return null;
};

export default StripeRedirect;
