import React from "react";
import classnames from "classnames";
import { Headline } from "design-system";

import { Breadcrumbs } from "lib/breadcrumbs";
import { PageHeaderBreadcrumbs } from "lib/breadcrumbs/PageHeaderBreadcrumbs";

import styles from "./index.module.less";

interface PageHeaderProps {
  title: string | Breadcrumbs;
  type: "primary" | "secondary";
  subtitle?: React.ReactNode;
  badge?: React.ReactNode;
  action?: React.ReactNode;
  className?: string;
  editButton?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  action,
  badge,
  subtitle,
  type,
  className,
  editButton,
}) => {
  return (
    <div className={classnames(className, styles.header, styles[type])}>
      {typeof title === "string" ? (
        <div className={styles.title}>
          <Headline level={type === "primary" ? 5 : 6} className="py-4">
            {title}
          </Headline>
          {editButton ? editButton : null}
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
      ) : (
        <PageHeaderBreadcrumbs breadcrumbs={title} className={styles.title}>
          {editButton ? editButton : null}
          <div className={styles.subtitle}>{subtitle}</div>
        </PageHeaderBreadcrumbs>
      )}
      {badge}
      {action && (
        <div data-testid="header-action" className={styles.headerAction}>
          {action}
        </div>
      )}
    </div>
  );
};
