import { isApolloError, type ApolloError } from "@apollo/client";

type ErrorWithUserFacingMessage = Error & { userFacingMessage: string };

export function isError(e: unknown): e is Error {
  return e instanceof Error;
}

export function toError(e: unknown): Error {
  if (isError(e)) {
    return e;
  }

  return new Error(`${e} thrown`);
}

export class UserFacingError extends Error {
  constructor(public readonly userFacingMessage: string) {
    super(userFacingMessage);
  }
}

export function giveUserFacingErrorMessage(
  e: Error,
  message: string,
): ErrorWithUserFacingMessage {
  return Object.assign(e, {
    userFacingMessage: message,
  });
}

export function hasUserFacingErrorMessage(
  e: unknown,
): e is ErrorWithUserFacingMessage {
  return isError(e) && typeof (e as any).userFacingMessage === "string";
}

export function getUserFacingErrorMessage(
  e: unknown,
  defaultError: string = `An unknown error occurred. Please try again.`,
) {
  const err = toError(e);

  if (hasUserFacingErrorMessage(err)) {
    return err.userFacingMessage;
  }

  if (isApolloError(err) && err.graphQLErrors.length > 0) {
    return err.graphQLErrors[0].message;
  }

  return defaultError;
}

function getGqlErrorCode(err: unknown): string | null {
  if (!isError(err)) {
    return null;
  }

  if (isApolloError(err) && err.graphQLErrors.length > 0) {
    return err.graphQLErrors[0].extensions?.code ?? null;
  }

  return null;
}

export function isGqlNotFoundError(err: unknown): err is ApolloError {
  return getGqlErrorCode(err) === "NOT_FOUND";
}
