import React from "react";

import {
  AvatarWithName,
  Headline,
  Hyperlink,
  Tooltip,
  Icon,
} from "design-system";
import { CopyableID } from "components/CopyableID";
import { EmptyState } from "tenaissance/components/EmptyState";
import { TextSkeleton } from "components/Skeleton";
import NotFoundPage from "pages/404";

import { useBillableMetricDetailQuery } from "pages/BillableMetric/queries.graphql";
import { DefinitionDisplay } from "components/BillableMetricsDefinition";
import { MetricType } from "lib/billableMetrics/types";
import { useSeatMetricDetailQuery } from "pages/NewBillableMetric/queries.graphql";
import { BillingMetricAggregateEnum_Enum } from "types/generated-graphql/__types__";
import { useDocsLink } from "lib/docs-link";

const BillableMetricSummary: React.FC<{
  billableMetricId: string;
  metricType: MetricType;
}> = ({ billableMetricId, metricType }) => {
  const docsLink = useDocsLink({
    contractsPath: "connect-metronome/create-billable-metrics/",
    plansPath: "invoicing/how-billing-works/set-up-billable-metrics/",
  });

  const {
    data: billableMetricDetail,
    loading: billableMetricLoading,
    error: billableMetricError,
  } = useBillableMetricDetailQuery({
    variables: {
      billable_metric_id: billableMetricId,
    },
    skip: metricType !== "billable",
  });
  const {
    data: seatMetricDetail,
    loading: seatMetricLoading,
    error: seatMetricError,
  } = useSeatMetricDetailQuery({
    variables: {
      seat_metric_id: billableMetricId,
    },
    skip: metricType !== "seat",
  });

  const error = billableMetricError || seatMetricError;
  const loading = billableMetricLoading || seatMetricLoading;
  const metric =
    billableMetricDetail?.BillableMetric ?? seatMetricDetail?.seat_metric;
  const aggregate =
    metricType === "billable"
      ? billableMetricDetail?.BillableMetric?.aggregate
      : BillingMetricAggregateEnum_Enum.Latest;
  const aggregate_keys: string[] =
    metricType === "billable"
      ? (billableMetricDetail?.BillableMetric?.aggregate_keys as string[]) ?? []
      : seatMetricDetail?.seat_metric?.aggregate_key
        ? [seatMetricDetail?.seat_metric?.aggregate_key]
        : [];

  const group_keys = billableMetricDetail?.BillableMetric?.group_keys
    ? (
        billableMetricDetail?.BillableMetric?.group_keys as (
          | string
          | string[]
        )[]
      ).map((groupKey) =>
        typeof groupKey === "string"
          ? new Set<string>([groupKey])
          : new Set<string>(groupKey),
      )
    : undefined;

  if (loading) {
    return (
      <div className="pt-12">
        <TextSkeleton />
        <TextSkeleton />
        <TextSkeleton />
        <TextSkeleton />
      </div>
    );
  } else if (error) {
    return (
      <EmptyState
        icon="barLineChart"
        mainText="We ran into an issue loading this billable metric"
        supportingText="Don't worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
      />
    );
  }
  if (!metric) {
    return <NotFoundPage />;
  }

  return (
    <div className="pt-12" data-testid="tab-billable-metric-summary">
      {/* Subheader */}
      {metric.Creator && (
        <div className="mb-12 flex items-center border-b border-b-grey-100 pb-12">
          <AvatarWithName {...metric.Creator} />
          <div className="ml-12">
            <CopyableID id={metric.id} label="metric ID" />
          </div>
        </div>
      )}

      {/* Design */}
      <div className="flex">
        <Headline level={6}>Definition</Headline>
        <div className="ml-12 flex items-center">
          <Hyperlink routePath={docsLink} target="_blank">
            <Tooltip content="Open Metronome docs">
              <Icon icon="book" />
            </Tooltip>
          </Hyperlink>
        </div>
      </div>
      {billableMetricDetail?.BillableMetric?.sql ? (
        <DefinitionDisplay rawSql={billableMetricDetail.BillableMetric.sql} />
      ) : (
        <DefinitionDisplay
          aggregate={aggregate}
          aggregateKeys={aggregate_keys}
          groupKeys={group_keys}
          filter={metric.filter}
          allowGroupKeys={metricType === "billable"}
        />
      )}
    </div>
  );
};

export default BillableMetricSummary;
