import {
  Badge,
  Body,
  Caption,
  Checkbox,
  DaysInput,
  Headline,
  Hyperlink,
  Icon,
  Input,
  Label,
  Select,
  Sidenote,
  Toggle,
  Tooltip,
} from "design-system";
import { Button } from "tenaissance/components/Button";
import { CreditInput, NumberInput } from "components/Input";
import { PricingUnitSelector } from "components/PricingUnitSelector";
import { InternalLink } from "components/Typography";
import Decimal from "decimal.js";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useNavigate } from "lib/useNavigate";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import {
  AlertTypeEnum_Enum,
  InvoiceExternalTypeEnum,
  ManagedEntityEnum_Enum,
} from "types/generated-graphql/__types__";
import { AppShell, PageContainer } from "components/PageContainer";
import { useSnackbar } from "components/Snackbar";
import {
  ALL_ALERTS,
  ALL_CONTRACT_ALERTS,
  ALL_PLAN_ALERTS,
  generateAlertFilterStrings,
  generateAlertPolicyString,
  useCanEvaluateAlertForAllCustomers,
  useCanUseAlertType,
  useHasAccessToAlerts,
} from "lib/alerts";
import { USD_CREDIT_ID, displayCreditTypeName } from "lib/credits";
import { CreditType } from "types/credit-types";
import NotFoundPage from "../404";
import {
  useBillableMetricSearchLazyQuery,
  useCreateAlertMutation,
  useCustomerPlanSearchLazyQuery,
  useNewAlertDataQuery,
  useGetCustomFieldKeysQuery,
} from "./queries.graphql";
import { FooterBar } from "pages/Contracts/Customer/Contracts/Create/components/FooterBar";
import { useFeatureFlag } from "lib/launchdarkly";
import {
  CustomFieldAdvancedFilters,
  CustomFieldFilter,
} from "./components/AdvancedFilters";
import { InvoiceTypesFilters } from "./components/AdvancedFilters/InvoiceTypesFilters";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { useUIMode } from "lib/useUIMode";
import { twMerge } from "tenaissance/twMerge";
import { useDocsLink } from "lib/docs-link";

type AlertInputs = {
  amount: boolean;
  days: boolean;
  percentage: boolean;
  unitAmount: boolean;

  billableMetric: boolean;
  creditType: boolean;
  usdCreditTypeOnly: boolean;
};

const NewAlert: React.FC = () => {
  const alertTypesDocs = useDocsLink({
    contractsPath: "manage-product-access/create-manage-alerts/#alert-types",
    plansPath: "connecting-metronome/alerts/#alert-types",
  });
  const webhooksDocs = useDocsLink({
    contractsPath: "developer-resources/use-api/webhooks/",
    plansPath: "developer-resources/using-the-api/webhooks",
  });

  const navigate = useNavigate();
  const pushMessage = useSnackbar();
  const { newUIEnabled, mode } = useUIMode();
  const [alertCreditType, setAlertCreditType] = useState<CreditType>();
  const [alertName, setAlertName] = useState<string>("");
  const [alertAudience, setAlertAudience] = useState<{
    type: "plan" | "customer" | "all";
    id?: string;
  }>();
  const [billableMetric, setBillableMetric] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >();
  const [alertThreshold, setAlertThreshold] = useState<number | null>(null);
  const [alertType, setAlertType] = useState<AlertTypeEnum_Enum>(
    AlertTypeEnum_Enum.LowCreditBalanceReached,
  );
  const [evaluateOnCreate, setEvaluateOnCreate] = useState<boolean>(true);
  const { environmentType } = useEnvironment();
  const { data, loading } = useNewAlertDataQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const [triggerSearchResults, searchResults] =
    useCustomerPlanSearchLazyQuery();
  const [triggerBillableMetricSearchResults, billableMetricSearchResults] =
    useBillableMetricSearchLazyQuery();
  const [createAlert, { loading: submitLoading }] = useCreateAlertMutation();
  const alertsEnabled = useHasAccessToAlerts();
  const creditBalanceAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowCreditBalanceReached,
  );
  const spendAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.SpendThresholdReached,
  );
  const daysLeftInPlanAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowRemainingDaysInPlanReached,
  );
  const creditPercentageAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowRemainingCreditPercentageReached,
  );
  const billableMetricAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.UsageThresholdReached,
  );
  const monthlySpendAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.MonthlyInvoiceTotalSpendThresholdReached,
  );
  const commitDaysAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowRemainingDaysForCommitSegmentReached,
  );
  const commitBalanceAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowRemainingCommitBalanceReached,
  );
  const commitPercentageAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowRemainingCommitPercentageReached,
  );
  const contractCreditDaysAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowRemainingDaysForContractCreditSegmentReached,
  );
  const contractCreditBalanceAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached,
  );
  const contractCreditAndCommitBalanceAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
  );
  const contractCreditPercentageAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.LowRemainingContractCreditPercentageReached,
  );
  const invoiceTotalReachedAlertsEnabled = useCanUseAlertType(
    AlertTypeEnum_Enum.InvoiceTotalReached,
  );
  const canEvaluateNewAlertForAllCustomers =
    useCanEvaluateAlertForAllCustomers();
  const [advancedFilterOpen, setAdvancedFilterOpen] = useState<boolean>(false);
  const [creditGrantFilters, setCreditGrantFilters] = useState<string[]>([]);

  const [contractCustomFieldFilters, setContractCustomFieldFilters] = useState<
    CustomFieldFilter[]
  >([]);
  const [commitCustomFieldFilters, setCommitCustomFieldFilters] = useState<
    CustomFieldFilter[]
  >([]);
  const [
    contractCreditCustomFieldFilters,
    setContractCreditCustomFieldFilters,
  ] = useState<CustomFieldFilter[]>([]);
  const [invoiceTypesFilters, setInvoiceTypesFilters] = useState<
    InvoiceExternalTypeEnum[]
  >([]);
  const contractsEnabled = useContractsEnabled();

  const creditGrantAlertFiltersEnabled = useFeatureFlag<boolean>(
    "credit-grant-alert-filters-ui",
    false,
  );

  const spendAlertFiltersEnabled = useFeatureFlag<boolean>(
    "spend-alert-filters-ui",
    false,
  );

  const [
    availableContractCustomFieldKeys,
    setAvailableContractCustomFieldKeys,
  ] = useState<string[]>([]);

  const [availableCommitCustomFieldKeys, setAvailableCommitCustomFieldKeys] =
    useState<string[]>([]);

  const [
    availableContractCreditCustomFieldKeys,
    setAvailableContractCreditCustomFieldKeys,
  ] = useState<string[]>([]);

  const { data: availableCustomFieldKeysData } = useGetCustomFieldKeysQuery();
  const showCPUWork = useFeatureFlag<boolean>("contract-cpus", false);

  useEffect(() => {
    setAvailableCommitCustomFieldKeys(
      availableCustomFieldKeysData?.ManagedFieldKey?.filter(
        (cf) => cf.entity === ManagedEntityEnum_Enum.Commit,
      ).map((cf) => cf.key) ?? [],
    );

    setAvailableContractCreditCustomFieldKeys(
      availableCustomFieldKeysData?.ManagedFieldKey?.filter(
        (cf) => cf.entity === ManagedEntityEnum_Enum.ContractCredit,
      ).map((cf) => cf.key) ?? [],
    );

    setAvailableContractCustomFieldKeys(
      availableCustomFieldKeysData?.ManagedFieldKey.filter(
        (cf) => cf.entity === ManagedEntityEnum_Enum.Contract,
      ).map((cf) => cf.key) ?? [],
    );
  }, [availableCustomFieldKeysData]);

  if (alertsEnabled === undefined) {
    return null;
  }
  if (!alertsEnabled) {
    return <NotFoundPage />;
  }

  if (!data || loading) {
    return null;
  }

  const alertsForUserMode =
    mode === "plans-only"
      ? ALL_PLAN_ALERTS
      : mode === "contracts-only"
        ? ALL_CONTRACT_ALERTS
        : ALL_ALERTS;

  const alertTypeOptions = [
    ...(creditBalanceAlertsEnabled
      ? [
          {
            label: "Credit balance",
            value: AlertTypeEnum_Enum.LowCreditBalanceReached,
          },
        ]
      : []),
    ...(spendAlertsEnabled
      ? [
          {
            label: "Spend",
            value: AlertTypeEnum_Enum.SpendThresholdReached,
          },
        ]
      : []),
    ...(daysLeftInPlanAlertsEnabled
      ? [
          {
            label: "Customer plan",
            value: AlertTypeEnum_Enum.LowRemainingDaysInPlanReached,
          },
        ]
      : []),
    ...(creditPercentageAlertsEnabled
      ? [
          {
            label: "Credit percentage",
            value: AlertTypeEnum_Enum.LowRemainingCreditPercentageReached,
          },
        ]
      : []),
    ...(billableMetricAlertsEnabled
      ? [
          {
            label: "Billable metric",
            value: AlertTypeEnum_Enum.UsageThresholdReached,
          },
        ]
      : []),
    ...(monthlySpendAlertsEnabled
      ? [
          {
            label: "Monthly spend",
            value: AlertTypeEnum_Enum.MonthlyInvoiceTotalSpendThresholdReached,
          },
        ]
      : []),
    ...(commitDaysAlertsEnabled
      ? [
          {
            label: "Days in commitment",
            value: AlertTypeEnum_Enum.LowRemainingDaysForCommitSegmentReached,
          },
        ]
      : []),
    ...(commitBalanceAlertsEnabled
      ? [
          {
            label: "Commitment balance",
            value: AlertTypeEnum_Enum.LowRemainingCommitBalanceReached,
          },
        ]
      : []),
    ...(commitPercentageAlertsEnabled
      ? [
          {
            label: "Commitment percentage",
            value: AlertTypeEnum_Enum.LowRemainingCommitPercentageReached,
          },
        ]
      : []),
    ...(contractCreditDaysAlertsEnabled
      ? [
          {
            label: "Days in contract credit",
            value:
              AlertTypeEnum_Enum.LowRemainingDaysForContractCreditSegmentReached,
          },
        ]
      : []),
    ...(contractCreditBalanceAlertsEnabled
      ? [
          {
            label: "Contract credit balance",
            value: AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached,
          },
        ]
      : []),
    ...(contractCreditPercentageAlertsEnabled
      ? [
          {
            label: "Contract credit percentage",
            value:
              AlertTypeEnum_Enum.LowRemainingContractCreditPercentageReached,
          },
        ]
      : []),
    ...(contractCreditAndCommitBalanceAlertsEnabled
      ? [
          {
            label: "Contract credit and commit balance",
            value:
              AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
          },
        ]
      : []),
    ...(invoiceTotalReachedAlertsEnabled
      ? [
          {
            label: "Invoice total",
            value: AlertTypeEnum_Enum.InvoiceTotalReached,
          },
        ]
      : []),
  ].filter((alert) => alertsForUserMode.has(alert.value));

  // We default the alertType to LowCreditBalanceReached when setting up the state. However,
  // if the client is not authorized for this alert type we need to update the state to one
  // they are authorized for so the drop downs all work as expected.
  if (
    alertType === AlertTypeEnum_Enum.LowCreditBalanceReached &&
    !creditBalanceAlertsEnabled
  ) {
    setAlertType(alertTypeOptions[0].value);
  }
  const alertTypeForLabel = alertType || alertTypeOptions[0].value;
  const selectLabel = [
    AlertTypeEnum_Enum.LowRemainingDaysInPlanReached,
    AlertTypeEnum_Enum.LowRemainingDaysForCommitSegmentReached,
    AlertTypeEnum_Enum.LowRemainingDaysForContractCreditSegmentReached,
  ].includes(alertTypeForLabel)
    ? "ends in"
    : "reaches";
  let customerCount: string = data.Client[0]?.customer_count.count.toString();
  if (data.Client[0]?.customer_count.estimated) {
    customerCount = `~${customerCount}`;
  }
  const audienceOptions: { label: JSX.Element; value: string }[] = [
    ...(searchResults?.data?.Plan.map((p) => ({
      label: (
        <div className="flex items-center justify-between">
          <Label>{p.name}</Label>
          <Badge type="light" theme="grey" className="uppercase text-gray-600">
            Plan: {pluralize("customer", p.customer_count, true)}
          </Badge>
        </div>
      ),
      value: `plan|${p.id}`,
    })) ?? []),

    ...(searchResults?.data?.Customer.map((c) => ({
      label: (
        <div className="flex items-center justify-between">
          <Label>{c.name}</Label>
          <Badge type="light" theme="grey" className="uppercase text-gray-600">
            Customer
          </Badge>
        </div>
      ),
      value: `customer|${c.id}`,
    })) ?? []),
  ];

  const clearBillableMetricData = () => {
    setBillableMetric(undefined);
    if (billableMetricSearchResults) {
      billableMetricSearchResults.data = undefined;
    }
  };

  const contractFiltersAreValid = () => {
    // check that all filters in the list have been filled out
    return (
      alertType !== AlertTypeEnum_Enum.SpendThresholdReached ||
      contractCustomFieldFilters.every((filter) => filter.key && filter.value)
    );
  };

  const commitFiltersAreValid = () => {
    if (
      ![
        AlertTypeEnum_Enum.LowRemainingCommitBalanceReached,
        AlertTypeEnum_Enum.LowRemainingCommitPercentageReached,
        AlertTypeEnum_Enum.LowRemainingDaysForCommitSegmentReached,
      ].includes(alertType)
    ) {
      return true;
    }

    return commitCustomFieldFilters.every(
      (filter) =>
        filter.key &&
        filter.value &&
        filter.entity === ManagedEntityEnum_Enum.Commit,
    );
  };

  const contractCreditFiltersAreValid = () => {
    if (
      ![
        AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached,
        AlertTypeEnum_Enum.LowRemainingContractCreditPercentageReached,
        AlertTypeEnum_Enum.LowRemainingDaysForContractCreditSegmentReached,
      ].includes(alertType)
    ) {
      return true;
    }

    return contractCreditCustomFieldFilters.every(
      (filter) =>
        filter.key &&
        filter.value &&
        filter.entity === ManagedEntityEnum_Enum.ContractCredit,
    );
  };

  const contractCreditAndCommitFiltersAreValid = () => {
    if (
      alertType !==
      AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached
    ) {
      return true;
    }

    const commitFiltersValid = commitCustomFieldFilters.every(
      (filter) =>
        filter.key &&
        filter.value &&
        filter.entity === ManagedEntityEnum_Enum.Commit,
    );
    const contractCreditFiltersValid = contractCreditCustomFieldFilters.every(
      (filter) =>
        filter.key &&
        filter.value &&
        filter.entity === ManagedEntityEnum_Enum.ContractCredit,
    );
    return commitFiltersValid && contractCreditFiltersValid;
  };

  const formatFilters = () => {
    const credit_grant_type_filters = creditGrantFilters.length
      ? {
          credit_grant_type: creditGrantFilters,
        }
      : {};

    const custom_field_filters =
      contractCustomFieldFilters.length ||
      commitCustomFieldFilters.length ||
      contractCreditCustomFieldFilters.length
        ? {
            custom_fields: contractCustomFieldFilters
              .concat(commitCustomFieldFilters)
              .concat(contractCreditCustomFieldFilters)
              .map((filter) => {
                return {
                  entity: filter.entity,
                  key: filter.key,
                  value: filter.value,
                };
              }),
          }
        : {};
    const invoice_types_filters = invoiceTypesFilters.length
      ? { invoice_types: invoiceTypesFilters }
      : {};
    return {
      ...credit_grant_type_filters,
      ...custom_field_filters,
      ...invoice_types_filters,
    };
  };

  const getAlertFilterString = () => {
    const alertFilters = generateAlertFilterStrings(
      {
        credit_grant_type: creditGrantFilters,
        custom_fields: contractCustomFieldFilters
          .concat(commitCustomFieldFilters)
          .concat(contractCreditCustomFieldFilters),
        invoice_types: invoiceTypesFilters,
      },
      alertType ===
        AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
    );
    if (alertFilters && alertFilters.length === 0) {
      // render an empty state
      return ["--"];
    }
    return alertFilters;
  };

  const alertFilters = getAlertFilterString();

  const creditTypeAlerts = [
    AlertTypeEnum_Enum.InternalLowCreditBalanceReached,
    AlertTypeEnum_Enum.LowCreditBalanceReached,
    AlertTypeEnum_Enum.LowRemainingCreditPercentageReached,
    AlertTypeEnum_Enum.LowRemainingDaysOfCreditReached,
    AlertTypeEnum_Enum.MonthlyInvoiceTotalSpendThresholdReached,
    AlertTypeEnum_Enum.SpendThresholdReached,
    AlertTypeEnum_Enum.LowRemainingCommitBalanceReached,
    AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached,
    AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
    AlertTypeEnum_Enum.InvoiceTotalReached,
  ];
  const showAdvancedFilters =
    (alertType === AlertTypeEnum_Enum.SpendThresholdReached &&
      spendAlertFiltersEnabled) ||
    ((alertType === AlertTypeEnum_Enum.LowRemainingCreditPercentageReached ||
      alertType === AlertTypeEnum_Enum.LowCreditBalanceReached) &&
      creditGrantAlertFiltersEnabled) ||
    [
      AlertTypeEnum_Enum.LowRemainingCommitBalanceReached,
      AlertTypeEnum_Enum.LowRemainingCommitPercentageReached,
      AlertTypeEnum_Enum.LowRemainingDaysForCommitSegmentReached,
      AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached,
      AlertTypeEnum_Enum.LowRemainingContractCreditPercentageReached,
      AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
      AlertTypeEnum_Enum.LowRemainingDaysForContractCreditSegmentReached,
      AlertTypeEnum_Enum.InvoiceTotalReached,
    ].includes(alertType);
  // Each alert type has.. a number of options for inputs. This object
  // collects each and then is used to generate the underlying components
  // ("ValueInput" and "UnitsInput" closures below)
  const inputs = {
    amount: [
      AlertTypeEnum_Enum.InternalLowCreditBalanceReached,
      AlertTypeEnum_Enum.LowCreditBalanceReached,
      AlertTypeEnum_Enum.MonthlyInvoiceTotalSpendThresholdReached,
      AlertTypeEnum_Enum.SpendThresholdReached,
      AlertTypeEnum_Enum.LowRemainingCommitBalanceReached,
      AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached,
      AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
      AlertTypeEnum_Enum.InvoiceTotalReached,
    ].includes(alertType),
    unitAmount: [AlertTypeEnum_Enum.UsageThresholdReached].includes(alertType),
    days: [
      AlertTypeEnum_Enum.LowRemainingDaysForCommitSegmentReached,
      AlertTypeEnum_Enum.LowRemainingDaysForContractCreditSegmentReached,
      AlertTypeEnum_Enum.LowRemainingDaysInPlanReached,
      AlertTypeEnum_Enum.LowRemainingDaysOfCreditReached,
    ].includes(alertType),
    percentage: [
      AlertTypeEnum_Enum.LowRemainingCommitPercentageReached,
      AlertTypeEnum_Enum.LowRemainingContractCreditPercentageReached,
      AlertTypeEnum_Enum.LowRemainingCreditPercentageReached,
    ].includes(alertType),
    billableMetric: [AlertTypeEnum_Enum.UsageThresholdReached].includes(
      alertType,
    ),

    creditType: (showCPUWork
      ? creditTypeAlerts.concat([
          AlertTypeEnum_Enum.LowRemainingCommitPercentageReached,
          AlertTypeEnum_Enum.LowRemainingContractCreditPercentageReached,
        ])
      : creditTypeAlerts
    ).includes(alertType),
    usdCreditTypeOnly: (showCPUWork
      ? []
      : [
          AlertTypeEnum_Enum.LowRemainingCommitBalanceReached,
          AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached,
          AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
        ]
    ).includes(alertType),
  };

  if (inputs.usdCreditTypeOnly && alertCreditType?.id !== USD_CREDIT_ID) {
    // enforce USD credit type if we're required to do so
    setAlertCreditType({
      id: USD_CREDIT_ID,
      name: "USD",
      client_id: null,
      environment_type: null,
    });
  }

  const AlertValueInput = (inputs: AlertInputs) => {
    if (inputs.amount) {
      const displayedCreditType = alertCreditType || {
        id: "",
        name: "",
        client_id: null,
        environment_type: null,
      };
      return (
        <CreditInput
          placeholder={`123.45 ${displayCreditTypeName(displayedCreditType)}`}
          creditType={displayedCreditType}
          onChange={setAlertThreshold}
          disabled={!alertCreditType && !inputs.usdCreditTypeOnly}
          className="mr-8 w-[200px] shrink-0"
          allowZeroAmounts
        />
      );
    }

    if (inputs.days) {
      // LowRemainingDaysInPlan only supports min days >= 1, but
      // the other days-related ones are allowed to evaluate when they
      // have zero days remaining.
      const minDays =
        alertType === AlertTypeEnum_Enum.LowRemainingDaysInPlanReached ? 1 : 0;

      return (
        <DaysInput
          className="mr-8 w-[200px] shrink-0"
          onChange={(days) => setAlertThreshold(days ?? null)}
          minimumDays={minDays}
        />
      );
    }

    if (inputs.percentage) {
      return (
        <NumberInput
          placeholder="Enter percentage"
          maxValue={100}
          onChange={setAlertThreshold}
          error={
            alertThreshold !== null &&
            (new Decimal(alertThreshold).greaterThan(100) ||
              new Decimal(alertThreshold).lessThanOrEqualTo(0))
          }
          disabled={!alertCreditType && inputs.creditType}
          suffix="%"
          className="mr-8 w-[200px] shrink-0"
        />
      );
    }

    if (inputs.unitAmount) {
      return (
        <NumberInput
          placeholder="Enter unit amount"
          onChange={setAlertThreshold}
          className="mr-8 w-[200px] shrink-0"
          error={
            alertThreshold !== null &&
            new Decimal(alertThreshold).lessThanOrEqualTo(0)
          }
        />
      );
    }
  };

  const AlertUnitsInput = (inputs: AlertInputs) => {
    if (inputs.billableMetric) {
      return (
        <Select
          options={
            billableMetricSearchResults?.data?.BillableMetric.map((bm) => ({
              label: bm.name,
              value: `${bm.id}|${bm.name}`,
            })) ?? []
          }
          onChange={(value) => {
            if (!value) {
              clearBillableMetricData();
              return;
            }
            const [id, name] = value.split("|");
            setBillableMetric({ id: id, name: name });
          }}
          onSearch={(value) => {
            if (!value) {
              return;
            }
            void triggerBillableMetricSearchResults({
              variables: {
                filter: `%${value}%`,
                environment_type: environmentType,
              },
            });
          }}
          loading={billableMetricSearchResults?.loading}
          value={`${billableMetric?.id}|${billableMetric?.name}`}
          className="mr-8 w-[200px] shrink-0"
          placeholder="Search billable metrics"
          __internalComponentOverrides={{
            DropdownIndicator: () => null,
          }}
        />
      );
    }

    if (inputs.creditType) {
      return (
        <PricingUnitSelector
          onChange={(creditType) => {
            setAlertCreditType(creditType);
            if (inputs.percentage) {
              setAlertThreshold(null);
            }
          }}
          disabled={inputs.usdCreditTypeOnly}
          selectedCreditTypeId={
            inputs.usdCreditTypeOnly ? USD_CREDIT_ID : alertCreditType?.id ?? ""
          }
          placeholder="Select pricing unit"
          className="mr-8 !w-[200px] shrink-0"
          fiatOnly={
            alertType ===
              AlertTypeEnum_Enum.MonthlyInvoiceTotalSpendThresholdReached ||
            alertType === AlertTypeEnum_Enum.InvoiceTotalReached
          }
          allowCreation={false}
          showName={false}
        />
      );
    }
  };

  const sectionHeaderStyles =
    "mt-12 -mr-12 mb-8 ml-0 pt-0 pr-12 pb-8 pl-0 border-b border-gray-100";

  const content = (
    <>
      <div
        className={twMerge(
          newUIEnabled ? "flex flex-col" : "h-full overflow-hidden",
        )}
      >
        <div>
          <div className={sectionHeaderStyles}>
            <Headline level={6}>Step 1: Alert info</Headline>
          </div>
          <div className="mb-[30px] pr-[350px]">
            <Body level={1}>
              Name your new alert. The name will appear in the Metronome UI and
              inside the Customer Alerts API.
            </Body>
            <Input
              placeholder="Enter the alert name"
              value={alertName}
              onChange={(v) => setAlertName(v)}
              className="mt-[6px] max-w-[350px]"
            />
          </div>
        </div>
        <div>
          <div className={sectionHeaderStyles}>
            <Headline level={6}>Step 2: Alert policy</Headline>
          </div>
          <div className="mb-[30px]">
            <Body level={1}>
              Determine what will trigger this alert for a customer. Learn more
              about our{" "}
              <Hyperlink routePath={alertTypesDocs} target="_blank">
                alert types
              </Hyperlink>
              .
            </Body>
            <div className="mb-12 mt-8 w-min rounded-large border border-solid border-gray-100 bg-gray-50">
              <div className="inline-flex w-full items-center pb-8 pl-12 pr-4 pt-12">
                <Select
                  options={alertTypeOptions}
                  placeholder={alertTypeOptions[0].label}
                  value={alertType.toString()}
                  onChange={(v: string) => {
                    setAlertType(v as AlertTypeEnum_Enum);
                    setAlertThreshold(null);
                    clearBillableMetricData();
                    setCreditGrantFilters([]);
                    setAdvancedFilterOpen(false);
                    setContractCustomFieldFilters([]);
                    setCommitCustomFieldFilters([]);
                    setContractCreditCustomFieldFilters([]);
                  }}
                  className="mr-8 w-[200px] shrink-0"
                />
                <Select
                  options={[{ label: selectLabel, value: selectLabel }]}
                  placeholder={selectLabel}
                  value={selectLabel}
                  onChange={() => {}}
                  className="mr-8 w-[124px] shrink-0"
                />
                {AlertValueInput(inputs)}
                {AlertUnitsInput(inputs)}
              </div>
              {showAdvancedFilters && (
                <div className="mb-12 ml-12 mt-4">
                  <div
                    onClick={() => {
                      // reset filters on open or close
                      setContractCustomFieldFilters([]);
                      setCreditGrantFilters([]);
                      setCommitCustomFieldFilters([]);
                      setContractCreditCustomFieldFilters([]);
                      setAdvancedFilterOpen(!advancedFilterOpen);
                    }}
                  >
                    <div className="flex cursor-pointer items-center">
                      {advancedFilterOpen ? (
                        <Icon
                          icon="chevronDown"
                          className="mr-4 text-primary-600"
                        />
                      ) : (
                        <Icon
                          icon="chevronForward"
                          className="mr-4 text-primary-600"
                        />
                      )}
                      <Caption level={2} className="uppercase text-primary-600">
                        Advanced filters
                      </Caption>
                    </div>
                  </div>
                  {spendAlertFiltersEnabled &&
                    advancedFilterOpen &&
                    alertType === AlertTypeEnum_Enum.SpendThresholdReached && (
                      <CustomFieldAdvancedFilters
                        advancedFilters={contractCustomFieldFilters}
                        setAdvancedFilters={setContractCustomFieldFilters}
                        availableCustomFieldKeys={
                          availableContractCustomFieldKeys
                        }
                        entityType={ManagedEntityEnum_Enum.Contract}
                      />
                    )}
                  {showAdvancedFilters &&
                    advancedFilterOpen &&
                    [
                      AlertTypeEnum_Enum.LowRemainingCommitBalanceReached,
                      AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
                      AlertTypeEnum_Enum.LowRemainingCommitPercentageReached,
                      AlertTypeEnum_Enum.LowRemainingDaysForCommitSegmentReached,
                    ].includes(alertType) && (
                      <CustomFieldAdvancedFilters
                        advancedFilters={commitCustomFieldFilters}
                        setAdvancedFilters={setCommitCustomFieldFilters}
                        availableCustomFieldKeys={
                          availableCommitCustomFieldKeys
                        }
                        entityType={ManagedEntityEnum_Enum.Commit}
                      />
                    )}
                  {showAdvancedFilters &&
                    advancedFilterOpen &&
                    alertType ===
                      AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached && (
                      <Sidenote className="align-left ml-[20px] mr-8 mt-12 whitespace-nowrap pb-8">
                        or
                      </Sidenote>
                    )}
                  {showAdvancedFilters &&
                    advancedFilterOpen &&
                    [
                      AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached,
                      AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
                      AlertTypeEnum_Enum.LowRemainingContractCreditPercentageReached,
                      AlertTypeEnum_Enum.LowRemainingDaysForContractCreditSegmentReached,
                    ].includes(alertType) && (
                      <CustomFieldAdvancedFilters
                        advancedFilters={contractCreditCustomFieldFilters}
                        setAdvancedFilters={setContractCreditCustomFieldFilters}
                        availableCustomFieldKeys={
                          availableContractCreditCustomFieldKeys
                        }
                        entityType={ManagedEntityEnum_Enum.ContractCredit}
                      />
                    )}
                  {creditGrantAlertFiltersEnabled &&
                    advancedFilterOpen &&
                    (alertType ===
                      AlertTypeEnum_Enum.LowRemainingCreditPercentageReached ||
                      alertType ===
                        AlertTypeEnum_Enum.LowCreditBalanceReached) && (
                      <div className="ml-12 mt-4 inline-flex items-center">
                        <Caption level={2} className="mr-8 text-gray-dark">
                          Credit grant type is:
                        </Caption>
                        <Input
                          data-testid="advanced-filters"
                          placeholder="value"
                          onChange={(val) => {
                            setCreditGrantFilters([val]);
                          }}
                          className="w-[300px]"
                          value={creditGrantFilters[0]}
                        />
                      </div>
                    )}
                  {alertType === AlertTypeEnum_Enum.InvoiceTotalReached &&
                  advancedFilterOpen ? (
                    <InvoiceTypesFilters
                      invoiceTypes={invoiceTypesFilters}
                      setInvoiceTypes={setInvoiceTypesFilters}
                      contractsEnabled={contractsEnabled}
                    />
                  ) : null}
                </div>
              )}
            </div>

            <Caption level={1}>Your new policy</Caption>
            <Badge
              theme="primary"
              type="light"
              className="mb-12 mt-4 font-normal text-primary-600"
            >
              {generateAlertPolicyString(
                alertThreshold,
                alertCreditType,
                alertType,
                billableMetric?.name,
              )}
            </Badge>

            {showAdvancedFilters && (
              <div className="mb-12">
                <Caption level={1}>Advanced filters</Caption>
                {alertFilters.map((filter, idx) => {
                  return (
                    <span key={filter}>
                      <Badge
                        theme="primary"
                        type="light"
                        className="!w-max-content mr-8 mt-4 font-normal text-primary-600"
                      >
                        {filter}
                      </Badge>
                      {idx !== alertFilters.length - 1 && (
                        <span className="mr-8 text-xxs font-normal text-gray-600">
                          OR
                        </span>
                      )}
                    </span>
                  );
                })}
              </div>
            )}

            <div className="flex flex-row items-center gap-4">
              <Tooltip
                content="You can only evaluate this rule immediately if it does not apply to all customers"
                disabled={
                  canEvaluateNewAlertForAllCustomers ||
                  alertAudience?.type !== "all"
                }
              >
                <Toggle
                  label="Evaluate customers that already meet the threshold"
                  checked={evaluateOnCreate}
                  onChange={(v) => setEvaluateOnCreate(v)}
                  disabled={
                    !canEvaluateNewAlertForAllCustomers &&
                    alertAudience?.type === "all"
                  }
                />
              </Tooltip>
              <Tooltip
                content="If on, this alert will immediately evaluate on customers
                  that already meet the alert threshold when it is created. Turn this off
                  to only evaluate future customers that trigger the alert threshold."
              >
                <Icon icon="helpCircle" className="text-base text-gray-600" />
              </Tooltip>
            </div>
          </div>
        </div>
        <div>
          <div className={sectionHeaderStyles}>
            <Headline level={6}>Step 3: Select customers</Headline>
          </div>
          <div className="mb-[30px] pr-[350px]">
            <Body level={1}>
              Select the customers this alert will apply to. An alert can apply
              to a single customer, customers on a plan, or all customers.
            </Body>
            <div className="inline-flex items-center">
              <Select
                options={audienceOptions}
                onChange={(value) => {
                  if (!value) {
                    setAlertAudience(undefined);
                    return;
                  }
                  const [type, id] = value.split("|");
                  setAlertAudience({ type: type as "plan" | "customer", id });
                }}
                onSearch={(value) => {
                  if (!value) {
                    return;
                  }
                  void triggerSearchResults({
                    variables: {
                      filter: `%${value}%`,
                      environment_type: environmentType,
                    },
                  });
                }}
                loading={searchResults.loading}
                value={`${alertAudience?.type ?? ""}|${
                  alertAudience?.id ?? ""
                }`}
                className="mr-8 w-[400px] pt-4"
                placeholder="Search customers or plans"
                disabled={alertAudience?.type === "all"}
                __internalComponentOverrides={{
                  DropdownIndicator: () => null,
                }}
              />
              <Tooltip
                content="You can only apply to all customers if you do not choose to immediately evaluate customers that already meet the threshold"
                disabled={
                  canEvaluateNewAlertForAllCustomers || !evaluateOnCreate
                }
              >
                <Checkbox
                  checked={alertAudience?.type === "all"}
                  onChange={(v) => {
                    setAlertAudience(v ? { type: "all" } : undefined);
                  }}
                  label={`Apply to all customers (${customerCount})`}
                  className="text-gray-600"
                  disabled={
                    !canEvaluateNewAlertForAllCustomers && evaluateOnCreate
                  }
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div>
          <div className={sectionHeaderStyles}>
            <Headline level={6}>Webhook integration</Headline>
          </div>
          <div className="mb-[30px] pr-[350px]">
            <Body level={1}>
              All webhook URLs connected to Metronome (from the Webhooks tab)
              will receive HTTP POST requests when the alert is triggered. For
              security, Metronome will pass only the <code>alert_id</code> and{" "}
              <code>customer_id</code> through the webhook payload. From the
              Customer Alerts API you can pull and view all other customer alert
              status and alert metadata. Learn more about our{" "}
              <Hyperlink routePath={webhooksDocs} target="_blank">
                webhooks
              </Hyperlink>
              .
            </Body>
            <div className="mt-8 max-w-[830px] border border-gray-100 bg-gray-50 p-8">
              <Body level={2} className="mb-4 text-gray-700">
                Connected webhooks
              </Body>
              {data.Webhook.length > 0 ? (
                data.Webhook.map((wh) => (
                  <div
                    className=" flex items-center justify-between rounded-medium border border-gray-100 bg-white p-8"
                    key={wh.id}
                  >
                    <Label className=" font-normal">{wh.url}</Label>
                    <Icon
                      icon="checkmarkCircle"
                      className=" text-success-600"
                    />
                  </div>
                ))
              ) : (
                <Label className=" text-gray-600">
                  No connected webhooks. Alerts will appear in the Metronome UI,
                  but to connect to external tools{" "}
                  <InternalLink routePath="/developer/webhooks">
                    add a webhook
                  </InternalLink>
                  .
                </Label>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={newUIEnabled ? "-mx-16 -mb-16" : "-mx-12"}>
        <FooterBar
          right={
            <>
              <Button
                onClick={() =>
                  navigate(newUIEnabled ? "/connections/alerts" : "/alerts")
                }
                text="Cancel"
                theme="linkGray"
              />
              <Button
                loading={submitLoading}
                disabled={
                  alertName === "" ||
                  alertAudience === undefined ||
                  alertThreshold === null ||
                  (alertCreditType === undefined && inputs.creditType) ||
                  (billableMetric === undefined && inputs.billableMetric) ||
                  !(
                    contractFiltersAreValid() &&
                    commitFiltersAreValid() &&
                    contractCreditFiltersAreValid() &&
                    contractCreditAndCommitFiltersAreValid()
                  ) ||
                  submitLoading
                }
                onClick={async () => {
                  // The Alert table requires a credit type id. For alert types that
                  // are unrelated to credit types we just use USD_CREDIT_ID to keep the
                  // db happy
                  const creditTypeId =
                    !inputs.creditType || inputs.usdCreditTypeOnly
                      ? USD_CREDIT_ID
                      : alertCreditType?.id;
                  if (!creditTypeId) {
                    return;
                  }

                  try {
                    const { data } = await createAlert({
                      variables: {
                        object: {
                          alert_type: alertType,
                          credit_type_id: creditTypeId,
                          customer_id:
                            alertAudience?.type === "customer"
                              ? alertAudience.id
                              : undefined,
                          plan_id:
                            alertAudience?.type === "plan"
                              ? alertAudience.id
                              : undefined,
                          name: alertName,
                          threshold: new Decimal(
                            alertThreshold ?? 0,
                          ).toString(),
                          webhooks_enabled: true,
                          billable_metric_id: billableMetric?.id,
                          filters: formatFilters(),
                          evaluate_on_create: evaluateOnCreate,
                        },
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "Alert",
                        });
                        cache.evict({
                          fieldName: "alerts",
                        });
                        cache.evict({
                          fieldName: "Customer",
                        });
                        cache.evict({
                          fieldName: "Customer_by_pk",
                        });
                        cache.gc();
                      },
                    });
                    const newAlertId = data?.insert_Alert_one?.id;
                    if (newAlertId) {
                      pushMessage({
                        content: "Alert successfully created",
                        type: "success",
                      });
                      navigate(
                        `${newUIEnabled ? "/connections" : ""}/alerts/${newAlertId}`,
                      );
                    }
                  } catch (e) {
                    pushMessage({
                      content: "Failed to create alert, please try again",
                      type: "error",
                    });
                    throw e;
                  }
                }}
                text="Save"
                theme="primary"
              />
            </>
          }
        />
      </div>
    </>
  );
  return newUIEnabled ? (
    <AppShell title="Add a new alert">{content}</AppShell>
  ) : (
    <PageContainer title="Add a new alert">{content}</PageContainer>
  );
};

export default NewAlert;
