import React from "react";
import {
  DesignSystemConfig,
  DesignSystemConfigProvider as Provider,
} from "design-system";

import { useEnvironment } from "lib/environmentSwitcher/context";

export const DesignSystemConfigProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { prefixUrl } = useEnvironment();

  const config = React.useMemo(
    (): DesignSystemConfig => ({
      routePathToHref: prefixUrl,
    }),
    [prefixUrl],
  );

  return <Provider config={config}>{children}</Provider>;
};
