export function sum(...args: number[]) {
  return args.reduce((sum, n) => sum + n, 0);
}

export function groupBy<T, U extends string | number | symbol>(
  list: T[],
  keyGetter: (arg0: T) => U,
) {
  const map = {} as Record<U, T[]>;
  list.forEach((item) => {
    const key = keyGetter(item);
    map[key] = map[key] || [];
    map[key].push(item);
  });
  return map;
}

export function removeEmpty<T>(arr: (T | undefined | null)[]): T[] {
  return arr.filter((x) => x !== undefined && x !== null) as T[];
}

export function assertUnreachable(x: never, message?: string): never {
  throw new Error(message ?? "Didn't expect to get here");
}

/**
 * Converts "hello world" to "Hello world".
 */
export function toSentenceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Return new array where every value is unique
 */
export function arrayUniq<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}
