import React from "react";
import { Subtitle } from "design-system";
import { RightPane } from "components/Popup";
import { TablePanel } from "components/TablePanel";
import { printDateTime, toDayjs } from "lib/date";
import { RateCardAliasScheduleSegment } from "types/generated-graphql/__types__";

interface AliasProps {
  aliases: RateCardAliasScheduleSegment[];
  onRequestClose: () => void;
}

export const RateCardAliases: React.FC<AliasProps> = ({
  aliases,
  onRequestClose,
}) => {
  const renderName = (row: RateCardAliasScheduleSegment) => (
    <Subtitle>{row.data.alias}</Subtitle>
  );

  const renderStartingAt = (row: RateCardAliasScheduleSegment) => (
    <Subtitle>
      {row.starting_at ? printDateTime(toDayjs(row.starting_at)) : "--"}
    </Subtitle>
  );

  const renderEndingBefore = (row: RateCardAliasScheduleSegment) => (
    <Subtitle>
      {row.ending_before ? printDateTime(toDayjs(row.ending_before)) : "--"}
    </Subtitle>
  );

  return (
    <RightPane
      size="lg"
      isOpen
      onRequestClose={onRequestClose}
      contentClassName="!p-0"
    >
      <div className="p-12">
        <TablePanel
          title="Rate Card Aliases"
          data={aliases}
          columns={[
            { id: "name", header: "Name", render: renderName },
            {
              id: "starting_at",
              header: "Starting at",
              render: renderStartingAt,
            },
            {
              id: "ending_before",
              header: "Ending before",
              render: renderEndingBefore,
            },
          ]}
        />
      </div>
    </RightPane>
  );
};
