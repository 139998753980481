import React, { useState } from "react";

import { Input } from "design-system";
import { Filter, OptionType } from "components/Filter";
import { PageContainer } from "components/PageContainer";
import { CustomerTable } from "./components/CustomerTable";
import { SearchTooltip } from "components/SearchTooltip";
import { useNavigate } from "lib/useNavigate";
import { useSearchParam } from "lib/routes/useSearchParam";
import useDebounce from "lib/debounce";
import {
  BillingConfigFilters,
  CUSTOMER_OPTIONS,
  CUSTOMER_OPTIONS_DEFAULT,
} from "./filters";
import { InsertCustomerDocument } from "pages/NewCustomer/newCustomer.graphql";
import { CustomerListDocument } from "./data/overview.graphql";
import { EmptyState } from "tenaissance/components/EmptyState";
import { useFeatureFlag } from "lib/launchdarkly";
import { GatedButton } from "../../components/GatedButton";

const Overview = () => {
  const [customerFilters, setCustomerFilters] = useState<readonly OptionType[]>(
    CUSTOMER_OPTIONS_DEFAULT,
  );

  const disableCustomerPage = useFeatureFlag("disabled-customer-page", false);

  const navigate = useNavigate();
  const addCustomer = () => navigate("/customers/new");
  const [searchQuery, setSearchQuery] = useSearchParam("q");
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 400);

  return (
    <PageContainer
      title="Customers"
      authDoc={CustomerListDocument}
      action={
        <div className="flex flex-row items-center">
          <SearchTooltip searchText="customers">
            <Input
              type="search"
              placeholder="Search"
              value={searchQuery}
              onChange={setSearchQuery}
              leftIcon="search"
              className="w-[208px]"
            />
          </SearchTooltip>
          <Filter
            value={customerFilters}
            options={CUSTOMER_OPTIONS}
            onChange={setCustomerFilters}
            onReset={() => setCustomerFilters(CUSTOMER_OPTIONS_DEFAULT)}
          />
          <GatedButton
            doc={InsertCustomerDocument}
            onClick={addCustomer}
            className="-mt-[2px] ml-12"
            data-testid="add-new-customer-button"
            text="Add new customer"
            theme="primary"
            leadingIcon="plus"
            size="sm"
          />
        </div>
      }
    >
      {disableCustomerPage && !debouncedSearchQuery ? (
        <EmptyState
          icon="searchSm"
          mainText="We'll be back soon with a refreshed customer page"
          supportingText="Use the search at the top of the page to find customers."
        />
      ) : (
        <CustomerTable
          customerStatus={
            customerFilters.find((f) => f.group === "customer_status")?.value ||
            "none"
          }
          billingConfigFilters={customerFilters
            .filter((f) => f.group === "billing_provider")
            .map((f) => f.value as BillingConfigFilters)}
          searchQuery={debouncedSearchQuery}
        />
      )}
    </PageContainer>
  );
};

export default Overview;
