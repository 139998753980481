import React from "react";
import { Body, DateInput } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { useChangeContractIssueDateMutation } from "./changeIssueDate.graphql";
import { useSnackbar } from "components/Snackbar";

interface ChangeIssueDateModalProps {
  onClose: () => void;
  currentIssueDate: Date;
  invoiceId: string;
}

const ChangeIssueDateModal: React.FC<ChangeIssueDateModalProps> = ({
  onClose,
  currentIssueDate,
  invoiceId,
}) => {
  const [issueDate, setIssueDate] = React.useState<Date | undefined>(
    currentIssueDate,
  );
  const [changeIssueDate, changeIssueDateMutationResult] =
    useChangeContractIssueDateMutation();
  const pushMessage = useSnackbar();

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            loading={changeIssueDateMutationResult.loading}
            disabled={!issueDate || issueDate === currentIssueDate}
            onClick={async () => {
              if (!issueDate) {
                return;
              }
              try {
                await changeIssueDate({
                  variables: {
                    invoice_id: invoiceId,
                    date: issueDate.toISOString(),
                  },
                  update(cache) {
                    cache.evict({
                      fieldName: "Invoice",
                    });
                    cache.evict({
                      fieldName: "invoices",
                    });
                    cache.evict({
                      fieldName: "Customer",
                    });
                    cache.evict({
                      fieldName: "Customer_by_pk",
                    });
                    cache.gc();
                  },
                });
                pushMessage({
                  type: "success",
                  content: "Updated invoice successfully",
                });
              } catch (e: any) {
                pushMessage({
                  content: `Failed to update invoice`,
                  type: "error",
                });
                return;
              }
              onClose();
            }}
            text="Change Issue Date"
            theme="primary"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Change Invoice Issue Date"
    >
      <Body level={2}>
        This action will change the issue date of the invoice. If the new date
        is in the past, the invoice may be immediately finalized if it would
        have been finalized on the new date.
      </Body>
      <div className="my-24 grid">
        <div className="pr-12">
          <DateInput
            isUTC
            onChange={(v) => setIssueDate(v)}
            name="New Issue Date"
            value={issueDate}
            placement="bottom-start"
          />
        </div>
      </div>
    </Popup>
  );
};

export default ChangeIssueDateModal;
