import { Dayjs } from "lib/dayjs";

import { Contract } from "pages/Contracts/lib/Contract";
import { ContractTerms } from "pages/Contracts/lib/ContractTerms";
import { AdditionalTermRow } from "pages/Contracts/components/AdditionalTermsTable";

import { AdditionalTermsTableFragment } from "./data.graphql";

export type Term<C extends AdditionalTermsTableFragment> =
  | C["discounts"][number]
  | C["scheduled_charges"][number]
  | C["amendments"][number]["discounts"][number]
  | C["amendments"][number]["scheduled_charges"][number]
  | C["reseller_royalties"][number]
  | C["amendments"][number]["reseller_royalties"][number]
  | C["pro_services"][number];

export function getTerms<C extends AdditionalTermsTableFragment>(
  contract: C,
  now: Dayjs,
  opts: {
    includeAmendments: boolean;
  },
): Term<C>[] {
  return [
    ...contract.discounts,
    ...contract.scheduled_charges,
    ...contract.pro_services,
    ...(opts.includeAmendments
      ? contract.amendments.flatMap((amendment) => [
          ...amendment.discounts,
          ...amendment.scheduled_charges,
          ...amendment.pro_services,
        ])
      : []),
    ...Contract.getResellerRoyaltiesStates(
      contract,
      opts.includeAmendments,
      now,
    ).values(),
  ];
}

export function getAmendmentTerms<C extends AdditionalTermsTableFragment>(
  amendment: C["amendments"][number],
): Term<C>[] {
  return [
    ...amendment.discounts,
    ...amendment.scheduled_charges,
    ...amendment.pro_services,
    ...amendment.reseller_royalties,
  ];
}

export function getRowsFromTerms<C extends AdditionalTermsTableFragment>(
  terms: Term<C>[],
  now: Dayjs,
  onTermClicked?: (term: Term<C>) => void,
) {
  return terms.map(
    (term): AdditionalTermRow => ({
      name: ContractTerms.getName(term, now),
      type: ContractTerms.getType(term),
      onClick: onTermClicked ? () => onTermClicked(term) : undefined,
      invoiceCount: ContractTerms.getInvoiceCount(term),
      startDate: ContractTerms.getStartDate(term),
      endDate: ContractTerms.getEndDate(term),
      rate: ContractTerms.getTermRateProps(term),
    }),
  );
}
