import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { CodeBlock } from "components/CodeBlock";
import Ajv from "ajv/dist/ajv";
import { JSONSchema7 } from "json-schema";
import {
  Condition,
  conditionsToJsonSchema,
} from "@metronome-industries/json-schema-conditions";
import { Button } from "tenaissance/components/Button";

type JsonSchemaValidatorProps = {
  conditions: Condition[];
  initialPayload?: string;
  showCreateExampleButton?: boolean;
  submitButtonOptions?: {
    onSubmit: (payload: string) => void;
    submitButtonText: string;
  };
};

type ValidatorState = {
  valid: boolean | undefined;
  message: string;
};

function fakeEventForSchema(filter: JSONSchema7) {
  const body = {} as Record<string, any>;
  for (const propKey in filter.properties) {
    const propDef = filter.properties[propKey];
    if (typeof propDef === "object" && propDef.type) {
      switch (propDef.type) {
        case "string":
          if (propDef.enum) {
            body[propKey] = propDef.enum[0];
          } else {
            body[propKey] = `${propKey}1`;
          }
          break;
        case "number":
          body[propKey] = 1;
          break;
        case "boolean":
          body[propKey] = true;
          break;
        case "object":
          body[propKey] = fakeEventForSchema(propDef);
          break;
        default: // case for multi-type
          if (propDef.type.length && propDef.type[0]) {
            if (propDef.type.includes("number")) {
              body[propKey] = 1;
            } else if (propDef.type.includes("string")) {
              body[propKey] = `${propKey}1`;
            } else if (propDef.type.includes("boolean")) {
              body[propKey] = true;
            }
          }
          break;
      }
    } else if (typeof propDef === "object" && !propDef.not) {
      body[propKey] = fakeEventForSchema(propDef);
    } else if (
      typeof propDef === "object" &&
      propDef.not &&
      typeof propDef.not === "object"
    ) {
      body[propKey] = propDef.not.enum?.[0]
        ? "not_" +
          propDef.not.enum.map((e) => (e || "null").toString()).join("_")
        : `${propKey}1`;
    }
  }
  if (filter.required && filter.required.length) {
    for (const propKey in filter.required) {
      if (!body[filter.required[propKey]]) {
        body[filter.required[propKey]] = 1;
      }
    }
  }
  return body;
}

export function makeFakeEvent({
  filter,
  customerId,
  transactionId,
  timestamp,
}: {
  filter: JSONSchema7;
  customerId?: string;
  transactionId?: string;
  timestamp?: string;
}) {
  return {
    ...fakeEventForSchema(filter),
    transaction_id: transactionId || uuid(),
    customer_id: customerId || "<replace this with a customer id>",
    timestamp: timestamp || new Date().toISOString(),
  };
}

const JsonSchemaValidator: React.FC<JsonSchemaValidatorProps> = ({
  conditions,
  initialPayload,
  showCreateExampleButton,
  submitButtonOptions,
}) => {
  const [payload, setPayload] = useState(initialPayload || "");
  const [validatorResult, setValidatorResult] = useState<ValidatorState>({
    valid: undefined,
    message: "",
  });
  const [syntaxError, setSyntaxError] = useState<string | undefined>(undefined);
  const isError = !!syntaxError || validatorResult.valid === false;

  useEffect(() => {
    try {
      if (!payload) {
        return setValidatorResult({
          valid: undefined,
          message: "",
        });
      }
      const ajv = new Ajv({ strict: true });
      const json = JSON.parse(payload);
      /* If we get here there are no syntax errors */
      setSyntaxError(undefined);
      const jsonSchema = conditionsToJsonSchema(conditions);
      const validate = ajv.compile(jsonSchema);
      validate(json);
      if (validate.errors?.length) {
        const dataPath = validate.errors[0].dataPath ?? "";
        const message =
          (dataPath && dataPath + ": ") + (validate.errors[0].message ?? "");
        setValidatorResult({
          valid: false,
          message: message.length
            ? message[0].toUpperCase() + message.slice(1)
            : "",
        });
      } else {
        setValidatorResult({
          valid: true,
          message: "Success",
        });
      }
    } catch (error: any) {
      setValidatorResult({
        valid: undefined,
        message: "",
      });
      setSyntaxError(error.message);
    }
  }, [conditions, payload]);

  return (
    <>
      <CodeBlock
        title="Example event payload"
        language="json"
        code={payload}
        onChange={(code) => setPayload(code)}
        valid={validatorResult.valid}
        error={
          isError && ((syntaxError && "JSON Syntax error") || "Does not match")
        }
        formatFn={(code) => {
          try {
            const jsonObject = JSON.parse(code);
            setPayload(JSON.stringify(jsonObject, undefined, 2));
          } catch (error: any) {
            setSyntaxError(error.message);
          }
        }}
        createExampleFn={
          showCreateExampleButton
            ? () => {
                const jsonSchema = conditionsToJsonSchema(conditions);
                setPayload(
                  JSON.stringify(
                    makeFakeEvent({ filter: jsonSchema }),
                    undefined,
                    2,
                  ),
                );
              }
            : undefined
        }
        validatorResult={
          syntaxError ? { valid: false, message: syntaxError } : validatorResult
        }
      />
      <div className="flex flex-row">
        {submitButtonOptions && (
          <Button
            onClick={() => {
              submitButtonOptions.onSubmit(payload);
              const event = JSON.parse(payload);
              event.transaction_id = uuid();
              setPayload(JSON.stringify(event, undefined, 2));
            }}
            disabled={!validatorResult.valid}
            text={submitButtonOptions.submitButtonText}
            theme="primary"
          />
        )}
      </div>
    </>
  );
};

export default JsonSchemaValidator;
