import React from "react";
import classNames from "classnames";
import { Headline, Body } from "design-system";

interface Props extends React.PropsWithChildren {
  title: string;
  type?: string;
  /**
   * One or more buttons/items (wrap multiple in a fragment) to display in the
   * top right of the section. They will automatically be layed out in a row
   * with 12px of spacing.
   */
  actions?: React.ReactNode;
  description?: string;
  className?: string;
}

export const Section: React.FC<Props> = (props) => {
  return (
    <section className="pl-12">
      <div className="flex">
        <div className="flex grow gap-12 py-8">
          {props.type ? (
            <Headline level={6} className="text-gray-700">
              {props.type + ":"}
            </Headline>
          ) : (
            ""
          )}
          <Headline level={6}>{props.title}</Headline>
        </div>
        {props.actions ? (
          <div className="mr-12 flex gap-12">{props.actions}</div>
        ) : undefined}
      </div>
      {props.description && (
        <Body level={1} className="text-gray-700">
          {props.description}
        </Body>
      )}
      <div className={classNames("mb-[36px] mr-12", props.className)}>
        {props.children}
      </div>
    </section>
  );
};
