import React, { useState } from "react";
import { WizardFullPage } from "components/Wizard";
import {
  CreatePlanDataQuery,
  FetchCustomPricingQuery,
  useFetchCustomPricingQuery,
} from "../../data/queries.graphql";
import { PlanDetails } from "lib/plans/types";
import { SortFunctions, Table, TableSkeleton } from "components/Table";
import { useDraftPlan } from "pages/PlanWizards/context";
import { serializeDraftPlanToPlan } from "pages/PlanWizards/steps/PreviewPlan";
import { PlanPreviewModal } from "./components";
import { renderDate, dayBefore } from "lib/time";
import styles from "./index.module.less";

interface PreviewCustomPricingProps {
  data: CreatePlanDataQuery;
  previousPlan: PlanDetails;
}
export const PreviewCustomPricing: React.FC<PreviewCustomPricingProps> = (
  props,
) => {
  const [targetCustomPricing, setTargetCustomPricing] = useState<
    FetchCustomPricingQuery["CustomPricing"][0] | undefined
  >(undefined);
  const { draftPlan } = useDraftPlan();
  const { data, loading } = useFetchCustomPricingQuery({
    variables: {
      plan_id: props.previousPlan.id,
    },
  });

  if (loading) {
    return (
      <WizardFullPage>
        <div className={styles.customerList}>
          <TableSkeleton
            columnNames={[
              "Customer name",
              "Plan start",
              "Plan end",
              "Price adjustments",
            ]}
          />
        </div>
      </WizardFullPage>
    );
  }
  if (!data) {
    throw new Error("Error loading customers data");
  }
  const plan = serializeDraftPlanToPlan(
    draftPlan,
    props.data.products,
    props.data.CreditType,
  );

  const customerPlans =
    data?.CustomPricing.map((cp) => {
      return {
        id: cp.id,
        name: cp.CustomerPlan.Customer.name,
        startDate: cp.CustomerPlan.start_date,
        cancellationDate: cp.CustomerPlan.cancellation_date,
        customPricingType: cp.PricedProductPricingFactorAdjustments.length
          ? "Charge level price adjustments"
          : "",
        customPricing: cp,
      };
    }) ?? [];

  return (
    <WizardFullPage>
      <div className={styles.customerList}>
        <PlanPreviewModal
          plan={plan}
          previousPlan={props.previousPlan}
          customPricing={targetCustomPricing}
          onClose={() => setTargetCustomPricing(undefined)}
        />
        <Table
          maxPageSize={20}
          onRowClick={(row) => {
            setTargetCustomPricing(row.customPricing);
          }}
          columns={[
            {
              id: "name",
              header: "Customer name",
              render: (row) => {
                return <span>{row.name}</span>;
              },
              sortable: true,
              comparator: SortFunctions.String((row) => row.name),
              textWrappable: true,
            },
            {
              id: "startDate",
              header: "Plan start",
              render: (row) => {
                return (
                  <span>
                    {renderDate(new Date(row.startDate), {
                      isUtc: true,
                      excludeUtcLabel: true,
                    })}
                  </span>
                );
              },
              sortable: true,
              comparator: SortFunctions.Date((row) => new Date(row.startDate)),
            },
            {
              id: "cancelDate",
              header: "Plan end",
              render: (row) => {
                return (
                  <span>
                    {row.cancellationDate
                      ? renderDate(dayBefore(new Date(row.cancellationDate)), {
                          isUtc: true,
                          excludeUtcLabel: true,
                        })
                      : "Recurring"}
                  </span>
                );
              },
              sortable: true,
              comparator: SortFunctions.Date((row) =>
                row.cancellationDate
                  ? new Date(row.cancellationDate)
                  : undefined,
              ),
            },
            {
              id: "customPricingType",
              header: "Price adjustments",
              render: (row) => {
                return <span>{row.customPricingType}</span>;
              },
            },
          ]}
          data={customerPlans}
        />
      </div>
    </WizardFullPage>
  );
};
