import { AllCommitsQuery, ContractCommitDetailsFragment } from "./data.graphql";
import { Commit } from "pages/Contracts/lib/Commit";

export function findCommit(
  customer: AllCommitsQuery["customer"],
  commitId: string,
) {
  const customerCommit = customer?.commits.find((c) => c.id === commitId);
  if (customerCommit) {
    return customerCommit;
  }

  for (const contract of customer?.contracts ?? []) {
    const contractCommits = getAllCommits(contract);
    const contractCommit = contractCommits.find((c) => c.id === commitId);
    if (contractCommit) {
      return contractCommit;
    }
  }
  return undefined;
}
export function getAllCommits(contract: ContractCommitDetailsFragment) {
  const commits =
    "v2_fields" in contract
      ? contract.v2_fields?.commits_union ?? []
      : [
          ...contract.commits_union,
          ...contract.amendments.flatMap((a) => a.commits_union),
        ];

  const rollover = commits.filter((c) => Commit.isRollover(c));
  const unexpired = commits.filter(
    (c) => !Commit.isRollover(c) && !Commit.isExpired(c),
  );
  const expired = commits
    .filter((c) => !Commit.isRollover(c) && Commit.isExpired(c))
    .sort((a, b) => {
      // Expired commits at the end, sorted by most recently expired
      const aEnd = Commit.getDateRange(a)?.end?.valueOf() ?? -Infinity;
      const bEnd = Commit.getDateRange(b)?.end?.valueOf() ?? -Infinity;
      return bEnd - aEnd;
    });

  return [...rollover, ...unexpired, ...expired];
}
