import { Override, Base } from "@metronome-industries/schedule-utils";
import { displayCreditsInCurrencyWithoutRounding } from "lib/credits";
import {
  transformRateCreditType,
  Percent,
  Subscription,
  Custom,
  Tiered,
  printFraction,
} from "pages/Contracts/components/RateScheduleTable";
import { TieredMultiplier } from "./TieredMultiplier";
import React from "react";

type OverrideChangeRateTableCellProps = {
  rate?: Override.Change.Rate;
  creditType?: Base.CreditType;
  isNewUI?: boolean;
};

/**
 * Format `Override.Change.Rate` for display in a table cell.
 */
export const OverrideChangeRateTableCell: React.FC<
  OverrideChangeRateTableCellProps
> = ({ rate, creditType }) => {
  if (!rate) {
    return "--";
  }
  switch (rate.type) {
    case "flat_overwrite":
      return displayCreditsInCurrencyWithoutRounding(
        rate.unitPrice,
        transformRateCreditType(creditType),
      );

    case "percentage_overwrite":
      return <Percent {...rate} isNewUI />;

    case "subscription_overwrite":
      return (
        <Subscription
          {...rate}
          creditType={transformRateCreditType(creditType)}
          isNewUI
        />
      );

    case "custom_overwrite":
      return <Custom />;

    case "tiered_overwrite":
      return (
        <Tiered
          tiers={rate.tiers}
          creditType={transformRateCreditType(creditType)}
          isNewUI
        />
      );

    case "tiered_override":
      return <TieredMultiplier tiers={rate.tiers} />;

    case "multiplier":
      return printFraction(rate.multiplier);
    default:
      rate satisfies never;
      return "--";
  }
};
