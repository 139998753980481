import { AppShell } from "components/PageContainer";
import { ParagraphSkeleton } from "components/Skeleton";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { isGqlNotFoundError } from "lib/errors/errorHandling";
import { useRequiredParam } from "lib/routes/params";
import React from "react";

import { printDate, toDayjs, useNow } from "lib/date";
import { useAuthCheck } from "lib/useAuthCheck";
import {
  USD_CREDIT_TYPE,
  displayCreditTypeName,
  displayCreditsInCurrencyWithoutRounding,
} from "lib/credits";
import Decimal from "decimal.js";
import { useFeatureFlag } from "lib/launchdarkly";
import RateCardTable from "./RateCardTable";
import { UpdateRateCardDocument } from "pages/Contracts/Pricing/CreateAndEditRateCard";
import NotFoundPage from "pages/404";
import {
  useRateCardQuery,
  useUpdateRateCardArchivedMutation,
} from "pages/Contracts/Pricing/RateCardsDetails/data.graphql";
import { Breadcrumbs } from "lib/breadcrumbs";
import EditNameModal from "tenaissance/components/EditNameModal";
import { useUpdateRateCardMutation } from "pages/Contracts/Pricing/CreateAndEditRateCard/data.graphql";
import { reportToSentry } from "lib/errors/sentry";
import { useSnackbar } from "components/Snackbar";
import { AvatarWithName } from "tenaissance/components/Avatar";
import { CopyableID } from "tenaissance/components/CopyableID";
import {
  Dropdown,
  DropdownHR,
  DropdownItem,
} from "tenaissance/components/Dropdown";
import RateCardAliasesFlyout from "./RateCardAliasesFlyout";
import ArchiveModal from "tenaissance/components/ArchiveModal";
import { RateCardDownloadModal } from "./RateCardDownloadModal";
import { Button } from "tenaissance/components/Button";
import { useSearchParams } from "react-router-dom";
import { ContractProductFlyover } from "pages/Contracts/Pricing/ContractProductFlyover";
import { useNavigate } from "lib/useNavigate";
import { useUIMode } from "lib/useUIMode";

export const RateCardDetails: React.FC = () => {
  const [showDownloadCsvModal, setShowDownloadCsvModal] = React.useState(false);
  const [showEditNameModal, setShowEditNameModal] = React.useState(false);
  const [showArchiveModal, setShowArchiveModal] = React.useState(false);
  const [productIdParam] = useSearchParams();
  const productId = productIdParam.get("productId");
  const navigate = useNavigate();
  const [updateRateCard] = useUpdateRateCardMutation();

  const [showRateCardAliasesFlyout, setShowRateCardAliasesFlyout] =
    React.useState(false);
  const pushMessage = useSnackbar();
  const rateCardId = useRequiredParam("id");

  const rateCardReq = useRateCardQuery({
    variables: {
      id: rateCardId,
    },
  });
  const now = useNow();
  const rateCard = rateCardReq.data?.contract_pricing.rate_card;
  const fiatCreditType = rateCard?.fiat_credit_type
    ? {
        ...rateCard?.fiat_credit_type,
        client_id: null,
        environment_type: null,
      }
    : USD_CREDIT_TYPE;

  const { mode } = useUIMode();

  const [updateRateCardArchived] = useUpdateRateCardArchivedMutation({
    update(cache) {
      cache.evict({ id: `RateCardMetadata:${rateCardId}` });
      cache.evict({ fieldName: "contract_pricing" });
    },
  });

  const canEditRateCard = !!useAuthCheck(UpdateRateCardDocument, true).allowed;
  /*
  const canEditRateCardProductOrder = !!useAuthCheck(
    MoveRateCardProductsDocument,
    true,
  ).allowed;
  */

  const canArchiveRateCard = useFeatureFlag("enable-archive-rate-card", false);
  if (isGqlNotFoundError(rateCardReq.error)) {
    return <NotFoundPage />;
  }

  const pageContent = (
    <>
      {rateCardReq.loading || !rateCard ? (
        <div className="pb-24 pl-12 pr-12 pt-24">
          <ParagraphSkeleton className="w-1/4 min-w-[400px]" />
        </div>
      ) : rateCardReq.error ? (
        <ErrorEmptyState
          title="We ran into an issue loading this rate card"
          error={rateCardReq.error}
        />
      ) : (
        <div>
          <div className="flex flex-row gap-3xl py-xl">
            <div>
              <div className="text-gray-700 text-sm font-semibold">
                Created by
              </div>
              <AvatarWithName
                id={rateCard.Creator.id}
                name={`${rateCard.Creator.name} (last edited ${printDate(toDayjs(rateCard.updated_at))})`}
                deprecated_at={rateCard.Creator.deprecated_at}
              />
            </div>
            <div>
              <div className="text-gray-700 text-sm font-semibold">
                Rate card ID
              </div>
              <CopyableID
                id={rateCard.id}
                className="font-normal text-gray-600"
              />
            </div>
            <div>
              <div className="text-gray-700 text-sm font-semibold">
                Currency
              </div>
              <div className="text-sm font-normal text-gray-600">
                {displayCreditTypeName(fiatCreditType)}
              </div>
            </div>
            {rateCard.credit_type_conversions?.map((conversion, i) => (
              <div key={i}>
                <div className="text-gray-700 text-sm font-semibold">
                  {conversion.custom_credit_type.name}
                </div>
                <div className="text-sm font-normal text-gray-600">
                  {`${displayCreditsInCurrencyWithoutRounding(
                    new Decimal(conversion.fiat_per_custom_credit),
                    fiatCreditType,
                  )} per ${conversion.custom_credit_type.name}`}
                </div>
              </div>
            ))}
          </div>
          <div>
            <RateCardTable rateCardId={rateCardId} />
          </div>
        </div>
      )}
    </>
  );
  const headerActions = [
    <Button
      key="addRate"
      leadingIcon="plus"
      text="Add a rate"
      disabled={!canEditRateCard}
      linkTo={`/offering/rate-cards/${rateCardId}/add-rate`}
    />,
    <Dropdown
      buttonTheme="tertiary"
      icon="dotsVertical"
      hideChevron={true}
      disabled={rateCardReq.loading}
    >
      <DropdownItem
        label="Download CSV"
        icon="download01"
        value="download"
        onClick={() => {
          setShowDownloadCsvModal(true);
        }}
      />
      <DropdownHR />
      <DropdownItem
        label="Manage aliases"
        icon="arrowNarrowUpRight"
        value="manageAliases"
        onClick={() => {
          setShowRateCardAliasesFlyout(true);
        }}
      />
      <DropdownItem
        label="Manage custom fields"
        icon="arrowNarrowUpRight"
        value="manageCustomFields"
        linkTo={`/connections/custom-fields/rate-card/${rateCardId}`}
      />
      {canArchiveRateCard && (
        <>
          <DropdownHR />
          <DropdownItem
            label="Archive rate card"
            icon="trash01"
            value="archive"
            disabled={!canEditRateCard}
            onClick={() => {
              setShowArchiveModal(true);
            }}
          />
        </>
      )}
    </Dropdown>,
  ];
  return (
    <AppShell
      title={rateCard?.name || { state: "loading" }}
      headerProps={{
        actions: headerActions,
        basePath: `rate-cards/${rateCardId}`,
        breadcrumbs: Breadcrumbs.from(
          {
            label: "Offering",
            routePath: `/offering/${mode === "plans-only" ? "plans" : "rate-cards"}`,
          },
          {
            label: "Rate cards",
            routePath: "/offering/rate-cards",
          },
        ),
        ...(rateCard && !rateCard.archived && canEditRateCard
          ? {
              editTitleAction: () => {
                setShowEditNameModal(true);
              },
            }
          : {}),
        ...(rateCard?.archived
          ? {
              titleBadge: {
                label: "Archived",
                theme: "warning",
                className: "!font-normal",
              },
            }
          : {}),
        editTitleAction: () => {
          setShowEditNameModal(true);
        },
      }}
    >
      {pageContent}
      {showArchiveModal && (
        <ArchiveModal
          element="rate card"
          supportingText="Are you sure you want to archive this rate card? An archived rate card can no longer be used on new contracts. Any contracts currently pointing to this rate card will continue to work."
          onCancel={() => {
            setShowArchiveModal(false);
          }}
          onArchive={async () => {
            try {
              await updateRateCardArchived({
                variables: {
                  id: rateCardId,
                  archived: true,
                },
              });
              pushMessage({
                content: "Rate card archived",
                type: "success",
              });
            } catch (e) {
              reportToSentry(e);
              pushMessage({
                content: `Failed to archive rate card: ${e}`,
                type: "error",
              });
            }
            setShowArchiveModal(false);
          }}
        />
      )}
      {showDownloadCsvModal && (
        <RateCardDownloadModal
          at={now}
          rateCardId={rateCardId}
          onStartDownload={() => {
            pushMessage({
              type: "info",
              content: "Preparing download...",
            });
          }}
          onComplete={() => {
            pushMessage({
              type: "success",
              content: "Downloaded CSV file",
            });
            setShowDownloadCsvModal(false);
          }}
          onCancel={() => {
            setShowDownloadCsvModal(false);
          }}
          onError={() => {
            pushMessage({
              type: "error",
              content: "Failed to download CSV file. Please try again.",
            });
            setShowDownloadCsvModal(false);
          }}
        />
      )}
      {showEditNameModal && (
        <EditNameModal
          title="Edit rate card name"
          name={rateCard?.name ?? ""}
          onSave={async (name) => {
            try {
              await updateRateCard({
                variables: {
                  rateCardId,
                  name,
                },
                update(cache) {
                  cache.evict({ fieldName: "products_and_rate_cards" });
                  cache.evict({ fieldName: "contract_pricing" });
                },
              });
              pushMessage({
                content: "Rate card name updated",
                type: "success",
              });
            } catch (e) {
              reportToSentry(e);
              pushMessage({
                content: `Failed to edit rate card: ${e}`,
                type: "error",
              });
            }
            setShowEditNameModal(false);
          }}
          onClose={() => {
            setShowEditNameModal(false);
          }}
        />
      )}
      {showRateCardAliasesFlyout && (
        <RateCardAliasesFlyout
          onSave={async (updatedAliases) => {
            try {
              await updateRateCard({
                variables: {
                  rateCardId,
                  name: rateCard?.name ?? "",
                  aliases: updatedAliases.map((a) => ({
                    name: a.data.alias,
                    starting_at: a.starting_at,
                    ending_before: a.ending_before,
                  })),
                },
                update(cache) {
                  cache.evict({ fieldName: "products_and_rate_cards" });
                  cache.evict({ fieldName: "contract_pricing" });
                },
              });
              pushMessage({
                content: "Rate card aliases updated",
                type: "success",
              });
            } catch (e) {
              reportToSentry(e);
              pushMessage({
                content: `Failed to update rate card aliases: ${e}`,
                type: "error",
              });
            }

            setShowRateCardAliasesFlyout(false);
          }}
          onClose={() => {
            setShowRateCardAliasesFlyout(false);
          }}
          aliases={rateCard?.aliases ?? []}
        />
      )}
      {productId && (
        <ContractProductFlyover
          productId={productId}
          onRequestClose={() =>
            navigate(`/offering/rate-cards/${rateCardId}`, { replace: true })
          }
        />
      )}
    </AppShell>
  );
};
