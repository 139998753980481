import { AppShell, PageContainer } from "components/PageContainer";
import { dayjs } from "lib/dayjs";
import { FormController } from "lib/FormController";
import { useNavigate } from "lib/useNavigate";
import React, { useMemo } from "react";

import { Schema } from "../Schema";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { RateCardDetailsSection } from "./RateCardDetails";
import { Rates } from "./Rates";
import { FooterBar } from "pages/Contracts/Customer/Contracts/Create/components/FooterBar";
import { useSnackbar } from "components/Snackbar";
import { useRequiredParam } from "lib/routes/params";
import {
  useGetCustomCreditTypesQuery,
  useRateCardEditDetailsQuery,
  useUpdateRateCardMutation,
} from "./data.graphql";
import {
  formAliasesAsGraphqlAliases,
  formRatesAsGraphqlRates,
} from "./RateCardCreate";
import { reportToSentry } from "lib/errors/sentry";
import { useFeatureFlag } from "lib/launchdarkly";
import { useRateCardQuery } from "../RateCardsDetails/data.graphql";
import {
  Tier,
  ScalarRateScheduleSegment,
} from "types/generated-graphql/__types__";
import { CreditType } from "types/credit-types";
import { USD_CREDIT_TYPE } from "lib/credits";
import { useUIMode } from "../../../../lib/useUIMode";
import { disableDimensionalPricingForLegacyGigaRateCardClientsFlagName } from "lib/dimensionalPricing";

function useController(
  rateCard: Partial<Schema.Types.RateCardInput>,
  snapshotKey: string,
) {
  const useRateCardEditController = FormController.createHook(
    Schema.RateCardInput,
    {
      init({
        existingRateCard,
        snapshotKey,
      }: {
        existingRateCard: Partial<Schema.Types.RateCardInput>;
        snapshotKey: string;
      }) {
        const snapshot = FormController.parseJsonSnapshot(
          Schema.RateCardInput,
          sessionStorage.getItem(snapshotKey),
        );
        return { ...existingRateCard, ...snapshot };
      },
    },
  );

  const ctrl = useRateCardEditController({
    existingRateCard: { ...rateCard, rates: [] },
    snapshotKey,
  });

  // save snapshot on every change of the form
  React.useEffect(() => {
    sessionStorage.setItem(snapshotKey, JSON.stringify(ctrl.snapshot()));
  }, [ctrl]);

  function clearSnapshot() {
    sessionStorage.removeItem(snapshotKey);
  }

  return {
    ctrl,
    clearSnapshot,
  };
}

const EditRateCardForm: React.FC<{
  rateCard: Schema.Types.RateCardInput;
  rateCardId: string;
  fiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  creditTypesLoading?: boolean;
  creditTypesError?: Error;
}> = ({
  rateCard,
  rateCardId,
  fiatCreditType,
  customCreditTypes,
  creditTypesLoading,
  creditTypesError,
}) => {
  const snapshotKey = `rate-card-edit ${rateCardId}`;
  const { ctrl, clearSnapshot } = useController(
    { ...rateCard, rates: [] },
    snapshotKey,
  );

  const disableDimensionalPricing = useFeatureFlag(
    disableDimensionalPricingForLegacyGigaRateCardClientsFlagName,
    false,
  );

  const navigate = useNavigate();

  const { newUIEnabled } = useUIMode();
  const backToRateCardList = () => {
    clearSnapshot();
    navigate(
      newUIEnabled ? "/offering/rate-cards" : "/contract-pricing/rate-cards",
    );
  };

  const [updateRateCardMutation, updateRateCardResult] =
    useUpdateRateCardMutation();

  const pushMessage = useSnackbar();
  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      const result = await updateRateCardMutation({
        variables: {
          rateCardId,
          name: valid.name.trim(),
          description: valid.description?.trim(),
          additionalRates: formRatesAsGraphqlRates(valid.rates),
          aliases: formAliasesAsGraphqlAliases(valid.aliases ?? []),
        },
        update(cache) {
          cache.evict({ fieldName: "products_and_rate_cards" });
          cache.evict({ fieldName: "contract_pricing" });
        },
      });
      const id = result.data?.update_rate_card?.id;
      if (id) {
        clearSnapshot();
        navigate(`/contract-pricing/rate-cards/${rateCardId}`);
        pushMessage({
          type: "success",
          content: "Rate card updated",
        });
      }
    } catch (e) {
      reportToSentry(e);
      pushMessage({
        content: `Failed to edit rate card: ${e}`,
        type: "error",
      });
    }
  });

  return (
    <form className="h-full" onSubmit={onSubmit}>
      <div className="-mx-12 flex h-full flex-col overflow-hidden">
        <div className="grow overflow-auto">
          <RateCardDetailsSection
            ctrl={ctrl}
            isEdit
            customCreditTypes={customCreditTypes}
            creditTypesLoading={creditTypesLoading}
            creditTypesError={creditTypesError}
          />
          {!disableDimensionalPricing && (
            <Rates
              ctrl={ctrl}
              existingRates={rateCard.rates}
              fiatCreditType={fiatCreditType}
              customCreditTypes={customCreditTypes}
              creditTypesLoading={creditTypesLoading}
            />
          )}
        </div>

        <FooterBar
          right={
            <>
              <Button
                onClick={backToRateCardList}
                text="Cancel"
                theme="linkGray"
              />
              <Button
                disabled={!ctrl.appearsValid() || updateRateCardResult.loading}
                text="Save"
                theme="primary"
                type="submit"
              />
            </>
          }
        ></FooterBar>
      </div>
    </form>
  );
};

export const EditRateCard: React.FC = () => {
  const rateCardId = useRequiredParam("id");
  const disableDimensionalPricing = useFeatureFlag(
    disableDimensionalPricingForLegacyGigaRateCardClientsFlagName,
    false,
  );

  const rateCardReq = useRateCardQuery({
    variables: { id: rateCardId },
    skip: !disableDimensionalPricing,
  });

  const rateCardEditDetailsReq = useRateCardEditDetailsQuery({
    variables: { id: rateCardId, rateCountLimit: "1000" },
    skip: disableDimensionalPricing,
  });

  // do not query for rates if dimensional pricing is disabled
  const rateCard = disableDimensionalPricing
    ? rateCardReq.data?.contract_pricing.rate_card
    : rateCardEditDetailsReq.data?.products_and_rate_cards.rate_card;

  const navigate = useNavigate();

  const { newUIEnabled } = useUIMode();
  const backToRateCardList = () => {
    navigate(
      newUIEnabled ? "/offering/rate-cards" : "/contract-pricing/rate-cards",
    );
  };

  const convertTieredRates = (tiers: Tier[]): Schema.Types.Tier[] => {
    return tiers.reduce<Schema.Types.Tier[]>((acc, t, index) => {
      // previous last unit will never be undefined, but need to do this to satisfy TS
      const prevLastUnit = index > 0 ? acc[index - 1].lastUnit || 0 : 0;

      const lastUnit =
        t.size !== undefined ? prevLastUnit + Number(t.size) : undefined;

      acc.push({
        lastUnit: lastUnit,
        unitPrice: Number(t.unit_price),
      });

      return acc;
    }, []);
  };

  const { data, loading, error } = useGetCustomCreditTypesQuery();
  const customCreditTypes = data?.CreditType ?? [];
  const fiatCreditType = rateCard?.fiat_credit_type ?? USD_CREDIT_TYPE;
  const allCreditTypesMapById = Object.fromEntries(
    [fiatCreditType, ...customCreditTypes].map((ct) => [ct.id, ct]),
  );

  const mapRateToPrice = (
    rate: ScalarRateScheduleSegment["rate"],
  ): Schema.Types.Rate["price"] => {
    switch (rate.__typename) {
      case "ScalarFlatRate":
        return {
          type: "flat",
          price: Number(rate.unit_price),
        };
      case "SubscriptionRate":
        return {
          type: "subscription",
          price: Number(rate.unit_price),
          quantity: Number(rate.quantity),
          isProrated: rate.is_prorated,
        };
      case "PercentageRate":
        return {
          type: "percentage",
          useListPrices: rate.use_list_prices,
          fraction: Number(rate.fraction),
        };
      case "TieredRate":
        return {
          type: "tiered",
          tiers: convertTieredRates(rate.tiers),
        };
      case "CustomRate":
        return {
          type: "custom",
        };
      default:
        throw new Error("Rate type is undefined");
    }
  };

  const mapRateToCreditType = (
    rate: ScalarRateScheduleSegment["rate"],
  ): Schema.Types.Rate["creditType"] | undefined => {
    switch (rate.__typename) {
      case "ScalarFlatRate":
        return allCreditTypesMapById[rate.credit_type_id];
      case "SubscriptionRate":
      case "TieredRate":
        return allCreditTypesMapById[rate.credit_type.id];
      case "PercentageRate":
      case "CustomRate":
        return undefined;
      default:
        throw new Error("Rate type is undefined");
    }
  };

  const rateCardInput: Schema.Types.RateCardInput | undefined = useMemo(() => {
    if (!rateCard) return undefined;
    const input: Schema.Types.RateCardInput = {
      name: rateCard.name || "",
      description: rateCard.description || undefined,
      rates: [],
      fiatCreditTypeId: rateCard.fiat_credit_type.id,
      fiatCreditTypeName: rateCard.fiat_credit_type.name,
      creditTypeConversions: rateCard.credit_type_conversions?.map(
        (conversion) => ({
          custom_credit_type_id: conversion.custom_credit_type.id,
          custom_credit_type_name: conversion.custom_credit_type.name,
          fiat_per_custom_credit: Number(conversion.fiat_per_custom_credit),
        }),
      ),
    };

    if (
      !disableDimensionalPricing &&
      rateCard.__typename === "RateCard" &&
      !rateCard.rate_schedule.next_page
    ) {
      const START_OF_TODAY_UTC = dayjs.utc().startOf("day").toISOString();
      input.rates = rateCard.rate_schedule.scalar_segments.map(
        (r): Schema.Types.Rate => {
          return {
            productId: r.product_id,
            startingAt: r.starting_at ?? START_OF_TODAY_UTC,
            entitled: r.entitled ? "enable" : "disable",
            id: r.id,
            price: mapRateToPrice(r.rate),
            commitPrice: r.commit_rate
              ? mapRateToPrice(r.commit_rate)
              : undefined,
            creditType: mapRateToCreditType(r.rate),
          };
        },
      );
    }
    return input;
  }, [rateCard, disableDimensionalPricing]);

  const pageContent = (
    <>
      {rateCardInput ? (
        <EditRateCardForm
          rateCard={rateCardInput}
          rateCardId={rateCardId}
          fiatCreditType={fiatCreditType}
          customCreditTypes={customCreditTypes}
          creditTypesLoading={loading}
          creditTypesError={error}
        />
      ) : null}
    </>
  );

  const headerAction = (
    <IconButton onClick={backToRateCardList} theme="secondary" icon="xClose" />
  );

  return newUIEnabled ? (
    <AppShell
      title="Edit your rate card"
      headerProps={{ actions: [headerAction] }}
    >
      {pageContent}
    </AppShell>
  ) : (
    <PageContainer
      title="Edit your rate card"
      disableContainerScroll
      action={headerAction}
    >
      {pageContent}
    </PageContainer>
  );
};
