import React, { useState } from "react";
import { MenuItemProps } from "components/PopoverMenu";
import ArchiveCustomerModal from "./components/ArchiveCustomerModal";
import { Tooltip } from "design-system";
import { useAuthCheck } from "lib/useAuthCheck";
import { ArchiveCustomerDocument } from "./components/ArchiveCustomerModal/queries.graphql";

interface ArchiveCustomer {
  id: string;
  name: string;
  archived_at: string | null;
}

export const useActions = () => {
  const [archiveCustomer, setArchiveCustomer] =
    useState<ArchiveCustomer | null>(null);

  const { allowed: canArchive } = useAuthCheck(ArchiveCustomerDocument);

  const getActions = (customer: ArchiveCustomer, hasPlan: boolean) => {
    const actions: MenuItemProps[] = [];
    if (!customer) {
      throw new Error("Error creating Customer actions");
    }

    if (customer.archived_at === null) {
      actions.push({
        disabled: hasPlan || !canArchive,
        onClick: () => setArchiveCustomer(customer),
        content: !canArchive ? (
          <Tooltip content="You do not have permission to archive customers">
            Archive...
          </Tooltip>
        ) : hasPlan ? (
          <Tooltip content="Customers with plans cannot be archived">
            Archive...
          </Tooltip>
        ) : (
          "Archive..."
        ),
      });
    }

    return actions;
  };

  const archiveModal = archiveCustomer ? (
    <ArchiveCustomerModal
      customerId={archiveCustomer.id}
      customerName={archiveCustomer.name}
      onClose={() => setArchiveCustomer(null)}
    />
  ) : null;

  return { getActions, archiveModal };
};
