import React from "react";
import CopyAssetToEnvironmentModal from ".";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useApolloClient } from "@apollo/client";
import {
  BillableMetricDetailQuery,
  BillableMetricDetailDocument,
  BillableMetricDetailQueryVariables,
  InsertBillableMetricMutation,
  InsertBillableMetricMutationVariables,
  InsertBillableMetricDocument,
  SeatMetricDetailQuery,
  SeatMetricDetailDocument,
  SeatMetricDetailQueryVariables,
  InsertSeatMetricMutation,
  InsertSeatMetricMutationVariables,
  InsertSeatMetricDocument,
} from "pages/NewBillableMetric/queries.graphql";
import { createApolloClient } from "lib/apollo";
import { useAuth } from "lib/auth";
import { MetricType } from "lib/billableMetrics/types";

interface CopyBillableMetricModalProps {
  metricId: string;
  metricName: string;
  metricType: MetricType;
  onClose: () => void;
}

const CopyBillableMetricModal: React.FC<CopyBillableMetricModalProps> = ({
  metricName,
  metricId,
  metricType,
  onClose,
}) => {
  const client = useApolloClient();
  const auth = useAuth();
  const { prefixEnvironmentUrl } = useEnvironment();

  return (
    <CopyAssetToEnvironmentModal
      onClose={onClose}
      copyAsset={async (targetEnv) => {
        if (metricType === "billable") {
          const {
            data: { BillableMetric: originalMetric },
          } = await client.query<
            BillableMetricDetailQuery,
            BillableMetricDetailQueryVariables
          >({
            query: BillableMetricDetailDocument,
            variables: {
              billable_metric_id: metricId,
            },
          });

          if (originalMetric === null) {
            throw new Error(
              `Billable metric to clone to new environment not found: ${metricId}`,
            );
          }

          const aggregate_keys = originalMetric.aggregate_keys as string[];
          const aggregate_key = aggregate_keys?.[0];
          const group_keys = originalMetric.group_keys as string[] | undefined;

          const mutateClient = createApolloClient(
            process.env.GRAPHQL_URL as string,
            auth,
            targetEnv.type,
            process.env.USE_METRONOME_SYSTEM_HEADERS === "1",
          );
          const copiedMetric = await mutateClient.mutate<
            InsertBillableMetricMutation,
            InsertBillableMetricMutationVariables
          >({
            mutation: InsertBillableMetricDocument,
            variables: {
              object: {
                aggregate: originalMetric.aggregate,
                aggregate_key,
                filter: originalMetric.filter,
                group_keys,
                name: originalMetric.name,
                is_draft: false,
              },
            },
            update(cache) {
              cache.evict({
                fieldName: "BillableMetric",
              });
              cache.evict({
                fieldName: "billable_metrics",
              });
            },
          });

          return prefixEnvironmentUrl(
            targetEnv,
            `/billable-metrics/${copiedMetric.data?.create_billable_metric?.id}`,
          );
        } else if (metricType === "seat") {
          const {
            data: { seat_metric: originalMetric },
          } = await client.query<
            SeatMetricDetailQuery,
            SeatMetricDetailQueryVariables
          >({
            query: SeatMetricDetailDocument,
            variables: {
              seat_metric_id: metricId,
            },
          });

          if (originalMetric === null) {
            throw new Error(
              `Seat metric to clone to new environment not found: ${metricId}`,
            );
          }

          const mutateClient = createApolloClient(
            process.env.GRAPHQL_URL as string,
            auth,
            targetEnv.type,
            process.env.USE_METRONOME_SYSTEM_HEADERS === "1",
          );
          const copiedMetric = await mutateClient.mutate<
            InsertSeatMetricMutation,
            InsertSeatMetricMutationVariables
          >({
            mutation: InsertSeatMetricDocument,
            variables: {
              input: {
                name: originalMetric.name,
                filter: originalMetric.filter,
                aggregate_key: originalMetric.aggregate_key,
                is_draft: false,
              },
            },
            update(cache) {
              cache.evict({
                fieldName: "SeatMetric",
              });
              cache.evict({
                fieldName: "seat_metrics",
              });
            },
          });

          return prefixEnvironmentUrl(
            targetEnv,
            `/billable-metrics/seats/${copiedMetric.data?.create_seat_metric?.id}`,
          );
        }

        throw new Error(`Unknown metric type: ${metricType}`);
      }}
      assetName={metricName}
      assetType="Billable metric"
    />
  );
};

export default CopyBillableMetricModal;
