import React from "react";

import { twMerge } from "../../twMerge";
import { useDesignSystemConfig } from "../../config";

interface HeadlineProps {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: string; // enforce that you don't put a link or something in the title,
  className?: string;
}

const sharedClasses = "not-italic m-0";

export const Headline: React.FC<HeadlineProps> = ({
  level,
  children,
  className,
}) => {
  switch (level) {
    case 1:
      return (
        <h1
          className={twMerge(
            sharedClasses,
            "text-4xl",
            "leading-7",
            "text-default-font",
            "font-medium",
            className,
          )}
        >
          {children}
        </h1>
      );
    case 2:
      return (
        <h2
          className={twMerge(
            sharedClasses,
            "text-3xl",
            "leading-6",
            "text-default-font",
            "font-medium",
            className,
          )}
        >
          {children}
        </h2>
      );
    case 3:
      return (
        <h3
          className={twMerge(
            sharedClasses,
            "text-2xl",
            "leading-5",
            "text-default-font",
            "font-medium",
            className,
          )}
        >
          {children}
        </h3>
      );
    case 4:
      return (
        <h4
          className={twMerge(
            sharedClasses,
            "text-xl",
            "leading-4",
            "text-default-font",
            "font-medium",
            className,
          )}
        >
          {children}
        </h4>
      );
    case 5:
      return (
        <h5
          className={twMerge(
            sharedClasses,
            "text-lg",
            "leading-3",
            "text-default-font",
            "font-medium",
            "py-4",
            "px-0",
            className,
          )}
        >
          {children}
        </h5>
      );
    case 6:
      return (
        <h6
          className={twMerge(
            sharedClasses,
            "text-base",
            "leading-3",
            "text-default-font",
            "font-medium",
            className,
          )}
        >
          {children}
        </h6>
      );
    default:
      return (
        <h1
          className={twMerge(
            sharedClasses,
            "text-4xl",
            "leading-7",
            "text-default-font",
            "font-medium",
            className,
          )}
        >
          {children}
        </h1>
      );
  }
};

interface SubtitleProps extends React.PropsWithChildren {
  level?: 1 | 2 | 3 | 4;
  className?: string;
}

export const Subtitle: React.FC<SubtitleProps> = ({
  level,
  children,
  className,
}) => {
  switch (level) {
    case 1:
      return (
        <h6
          className={twMerge(
            sharedClasses,
            "text-sm",
            "leading-3",
            "text-default-font",
            "font-normal",
            className,
          )}
        >
          {children}
        </h6>
      );
    case 2:
      return (
        <h6
          className={twMerge(
            sharedClasses,
            "text-sm",
            "leading-1",
            "text-default-font",
            "font-medium",
            className,
          )}
        >
          {children}
        </h6>
      );
    case 3:
      return (
        <h6
          className={twMerge(
            sharedClasses,
            "text-xs",
            "leading-2",
            "text-default-font",
            "font-medium",
            className,
          )}
        >
          {children}
        </h6>
      );
    case 4:
      return (
        <h6
          className={twMerge(
            sharedClasses,
            "text-xs",
            "leading-1",
            "text-default-font",
            "font-medium",
            className,
          )}
        >
          {children}
        </h6>
      );
    default:
      return (
        <h6
          className={twMerge(
            sharedClasses,
            "text-sm",
            "leading-3",
            "text-default-font",
            "font-normal",
            className,
          )}
        >
          {children}
        </h6>
      );
  }
};

interface SidenoteProps extends React.PropsWithChildren {
  className?: string;
}

export const Sidenote: React.FC<SidenoteProps> = (props) => {
  return (
    <span
      className={twMerge(
        sharedClasses,
        "text-xs",
        "leading-1",
        "text-gray-700",
        "font-medium",
        props.className,
      )}
    >
      {props.children}
    </span>
  );
};

interface BodyProps extends React.PropsWithChildren {
  level?: 1 | 2;
  className?: string;
  noBottomMargin?: boolean;
}

export const Body: React.FC<BodyProps> = ({
  level,
  children,
  className,
  noBottomMargin,
}) => {
  switch (level) {
    case 1:
      return (
        <p
          className={twMerge(
            sharedClasses,
            "text-sm",
            "leading-3",
            "text-default-font",
            "font-normal",
            "p-0",
            noBottomMargin !== true && "mb-[14px]",
            className,
          )}
        >
          {children}
        </p>
      );
    case 2:
      return (
        <p
          className={twMerge(
            sharedClasses,
            "text-xs",
            "leading-2",
            "text-default-font",
            "font-normal",
            "p-0",
            noBottomMargin !== true && "mb-12",
            className,
          )}
        >
          {children}
        </p>
      );
    default:
      return (
        <p
          className={twMerge(
            sharedClasses,
            "text-sm",
            "leading-3",
            "text-default-font",
            "font-normal",
            className,
          )}
        >
          {children}
        </p>
      );
  }
};

interface LabelProps extends React.PropsWithChildren {
  className?: string;
}

export const Label: React.FC<LabelProps> = (props) => {
  return (
    <label
      className={twMerge(
        sharedClasses,
        "text-xs",
        "leading-1",
        "text-default-font",
        "font-medium",
        props.className,
      )}
    >
      {props.children}
    </label>
  );
};

interface CaptionProps extends React.PropsWithChildren {
  className?: string;
  level?: 1 | 2;
}

export const Caption: React.FC<CaptionProps> = ({
  children,
  className,
  level,
}) => {
  switch (level) {
    case 1:
      return (
        <h6
          className={twMerge(
            sharedClasses,
            "text-xxs",
            "leading-1",
            "text-gray-400",
            "font-medium",
            "uppercase",
            className,
          )}
        >
          {children}
        </h6>
      );
    case 2:
      return (
        <h6
          className={twMerge(
            sharedClasses,
            "text-xxs",
            "leading-1",
            "text-gray-400",
            "font-semibold",
            className,
          )}
        >
          {children}
        </h6>
      );
    default:
      return (
        <h6
          className={twMerge(
            sharedClasses,
            "text-xxs",
            "leading-1",
            "text-gray-400",
            "font-medium",
            "uppercase",
            className,
          )}
        >
          {children}
        </h6>
      );
  }
};

interface HyperlinkProps extends React.PropsWithChildren {
  routePath: string;
  target?: string;
  className?: string;
}

export const Hyperlink: React.FC<HyperlinkProps> = ({
  children,
  routePath,
  target = "_self",
  className,
}) => {
  const { routePathToHref } = useDesignSystemConfig();

  return (
    <a
      href={routePathToHref(routePath)}
      target={target}
      className={twMerge(
        sharedClasses,
        "text-deprecated-primary-600",
        "font-normal",
        "no-underline",
        "hover:underline",
        className,
      )}
    >
      {children}
    </a>
  );
};
