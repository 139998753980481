import React from "react";
import "style/index.css";
import { dayjs } from "lib/dayjs";
import {
  BaseStatusPillsFieldsFragment,
  StatusPillsFieldsFragment,
} from "../Invoices/queries.graphql";
import { Badge, BADGE_VARIANTS } from "tenaissance/components/Badge";
import { Icon, IconName } from "tenaissance/components/Icon";
import Icons from "../../../../icons";
import { v4 as uuid } from "uuid";
import { capitalizeFirstLetter, toSentenceCase } from "tenaissance/lib/utils";
import { twMerge } from "tenaissance/twMerge";
import { useEnvironment } from "lib/environmentSwitcher/context";
import {
  BillingProviderEnum_Enum,
  EnvironmentTypeEnum_Enum,
  InvoiceExternalStatusEnum_Enum,
} from "types/generated-graphql/__types__";
import { useFeatureFlag } from "lib/launchdarkly";

const getResellerRoyaltyBadge = (invoice: StatusPillsFieldsFragment) => {
  if (!("reseller_royalty_type" in invoice)) {
    return null;
  }
  const resellerRoyalty = invoice.reseller_royalty_type;

  return (
    <Badge
      theme="gray"
      className="ml-4 flex flex-row items-center"
      key="reseller-royalty"
      label={resellerRoyalty ?? "DIRECT"}
    />
  );
};

const getBillingProviderStatusContent = (
  invoice: StatusPillsFieldsFragment,
  environmentType: EnvironmentTypeEnum_Enum,
  showResellerRoyalty?: boolean,
) => {
  let contents: React.ReactNode[] = [];

  if (
    invoice.status !== "DRAFT" &&
    !(
      invoice.__typename === "ParentInvoice" ||
      invoice.__typename === "AdHocPlanInvoice"
    ) &&
    invoice.billing_provider_invoice
  ) {
    let badgeTheme: keyof typeof BADGE_VARIANTS;
    let copy: string = invoice.billing_provider_invoice.external_status;

    switch (invoice.billing_provider_invoice.external_status) {
      case "DELETED":
      case "UNCOLLECTIBLE":
      case "VOID":
      case "PAYMENT_FAILED": {
        badgeTheme = "error";
        break;
      }
      case "INVALID_REQUEST_ERROR": {
        badgeTheme = "error";
        copy = "FAILED";
        break;
      }
      case "FINALIZED":
      case "PAID": {
        badgeTheme = "success";
        break;
      }
      case "DRAFT":
      case "SKIPPED":
      case "SENT":
      case "QUEUED": {
        badgeTheme = "gray";
        break;
      }
      case undefined: {
        badgeTheme = "gray";
        copy = "CREATED";
        break;
      }
      default: {
        console.warn(
          `Unknown external status: ${invoice.billing_provider_invoice.external_status}`,
        );
        badgeTheme = "gray";
      }
    }

    const providerIcon = () => {
      if (invoice.billing_provider_invoice) {
        let possibleIcon =
          invoice.billing_provider_invoice.billing_provider.toLocaleLowerCase();
        if (
          invoice.billing_provider_invoice.billing_provider ===
          "QUICKBOOKS_ONLINE"
        ) {
          possibleIcon = "quickbooks";
        } else if (
          invoice.billing_provider_invoice.billing_provider ===
          "AWS_MARKETPLACE"
        ) {
          possibleIcon = "aws";
        }
        if (possibleIcon in Icons) {
          return (
            <Icon
              viewBox="0 0 32 32"
              icon={possibleIcon as IconName}
              showRaw={true}
              size={16}
            />
          );
        } else {
          return null;
        }
      }
      return null;
    };

    let stripeInvoiceURL: string | null = null;
    if (
      invoice.billing_provider_invoice.billing_provider ===
        BillingProviderEnum_Enum.Stripe &&
      invoice.billing_provider_invoice.billing_provider_invoice_id &&
      invoice.billing_provider_invoice.external_status !==
        InvoiceExternalStatusEnum_Enum.Deleted
    ) {
      stripeInvoiceURL = `https://dashboard.stripe.com${
        environmentType !== EnvironmentTypeEnum_Enum.Production ? "/test" : ""
      }/invoices/${invoice.billing_provider_invoice.billing_provider_invoice_id}`;
    }

    contents.push(
      <Badge
        key={uuid()}
        theme={badgeTheme}
        icon={stripeInvoiceURL ? "arrowNarrowUpRight" : undefined}
        iconPosition={stripeInvoiceURL ? "right" : undefined}
        onClick={() => {
          if (stripeInvoiceURL) {
            window.open(stripeInvoiceURL, "_blank");
          }
        }}
        label={
          <div className="flex items-center gap-x-xs truncate">
            {providerIcon()}
            {toSentenceCase(copy)}
          </div>
        }
        tooltipLabel={
          invoice.billing_provider_invoice.billing_provider_error
            ? invoice.billing_provider_invoice.billing_provider_error
            : `${capitalizeFirstLetter(invoice.billing_provider_invoice.billing_provider)}`
        }
      />,
    );
  }
  if (showResellerRoyalty) {
    contents.push(getResellerRoyaltyBadge(invoice));
  }

  if (contents.length > 1) {
    contents = [
      <div className="flex" key="bp-status-div">
        {...contents}
      </div>,
    ];
  }

  return contents;
};

const getMainInvoiceStatusBadgeContent = (
  invoice: BaseStatusPillsFieldsFragment,
  className?: string,
) => {
  let theme: keyof typeof BADGE_VARIANTS | undefined;
  let copy: string | undefined;

  const contents: React.ReactNode[] = [];

  if (invoice.__typename === "CreditPurchaseInvoice") {
    if (dayjs(invoice.issued_at).isBefore(new Date())) {
      theme = "success";
      copy = "ISSUED";
    } else {
      theme = "gray";
      copy = "SCHEDULED";
    }
  } else {
    switch (invoice.status) {
      case "DRAFT": {
        theme = "gray";
        copy = "DRAFT";
        break;
      }
      case "FINALIZED": {
        theme = "success";
        copy = invoice.on_hold ? "ON HOLD" : "FINALIZED";
        break;
      }
      case "PENDING_FINALIZATION": {
        theme = "azure-blue";
        copy = "PENDING FINALIZATION";
        break;
      }
      case "FINALIZED_IN_OLD_SYSTEM": {
        // This status is "internal", the resolvers should never return it. That said, to satisfy the type checker, we need to handle it.
        throw new Error(
          "Received finalized invoice from old system, which should not be exposed to callers ever",
        );
      }
      default: {
        console.warn("Unknown invoice status", invoice.status);
        break;
      }
    }
  }

  if (theme && copy) {
    contents.push(
      <Badge
        className={className}
        key={uuid()}
        theme={theme}
        label={toSentenceCase(copy)}
      />,
    );
  }

  return contents;
};

export const StatusPills: React.FC<{
  className?: string;
  invoice: StatusPillsFieldsFragment;
  stackVertically: boolean;
}> = ({ className, invoice, stackVertically }) => {
  const contents: React.ReactNode[] = [];
  const { environmentType } = useEnvironment();
  const showResellerRoyalty = useFeatureFlag("show-reseller-royalty", false);

  if (invoice.status === "VOID") {
    contents.push(<Badge label="Void" theme="error" />);
  } else {
    contents.push(
      ...getMainInvoiceStatusBadgeContent(invoice, environmentType),
    );
    contents.push(
      ...getBillingProviderStatusContent(
        invoice,
        environmentType,
        showResellerRoyalty,
      ),
    );
  }

  return (
    <div
      className={twMerge(
        "flex items-start gap-xs",
        stackVertically && "flex-col",
        className,
      )}
    >
      {contents}
    </div>
  );
};
