import { useFeatureFlag } from "lib/launchdarkly";

// LaunchDarkly config type
type DraftInvoiceConfig = {
  enabled: boolean;
  schemas?: string[];
};

const DRAFT_INVOICE_SCHEMA_TYPE = "draft_invoices";

export const PREQUEL_API_URL = "https://9ccb13.prequel.co";

export const useDraftInvoicesExportEnabled = () => {
  const dataExportDraftInvoicesConfig = useFeatureFlag<
    DraftInvoiceConfig | undefined
  >("data-export-draft-invoice-pusher", undefined);
  return (
    dataExportDraftInvoicesConfig &&
    dataExportDraftInvoicesConfig.enabled &&
    dataExportDraftInvoicesConfig.schemas &&
    dataExportDraftInvoicesConfig.schemas.includes(DRAFT_INVOICE_SCHEMA_TYPE)
  );
};

export function useDataExportEnabled() {
  const dataExportPricingAndPackagingEnabled = useFeatureFlag<
    boolean | undefined
  >("data-export-pricing-and-packaging", undefined);
  const dataExportRawEventsEnabled = useFeatureFlag<boolean | undefined>(
    "data-export-raw-events",
    undefined,
  );
  const dataExportDraftInvoicesEnabled = useDraftInvoicesExportEnabled();
  return (
    dataExportPricingAndPackagingEnabled ||
    dataExportRawEventsEnabled ||
    dataExportDraftInvoicesEnabled
  );
}
