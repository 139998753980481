import React from "react";
import { TextSkeleton } from "components/Skeleton";
import { useCustomerCountForPlanQuery } from "tenaissance/pages/Offering/tabs/Plans/queries.graphql";
import pluralize from "pluralize";

const CustomerCountCell: React.FC<{ planId: string }> = ({ planId }) => {
  const { data, loading, error } = useCustomerCountForPlanQuery({
    variables: { plan_id: planId },
  });

  if (loading) {
    return <TextSkeleton />;
  }
  if (error) {
    return <span>-</span>;
  }
  const customerCount = data?.Plan_by_pk?.customer_count ?? 0;
  return (
    <span>{`${customerCount} ${pluralize("customer", customerCount)}`}</span>
  );
};

export default CustomerCountCell;
