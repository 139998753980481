import React from "react";
import { ButtonDropdown } from "design-system";

import { Button } from "tenaissance/components/Button";

import { ContractPricing } from "pages/Contracts/lib/ContractPricing";
import { useNow, toDayjs, printDate, Dayjs } from "lib/date";

import { Section } from "../../components/Section";
import { Schema } from "../../Schema";
import { Pricing } from "../types";
import { RateSchedulePanel } from "pages/Contracts/components/RateSchedulePanel";
import {
  Base,
  Overridden,
  Override,
} from "@metronome-industries/schedule-utils";
import { describeNewOverride } from "../../lib/Override";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { useRateCardProductCountQuery } from "./data.graphql";
import { CreditType } from "types/credit-types";
import {
  USD_CREDIT_TYPE,
  displayCreditsInCurrencyWithoutRounding,
} from "lib/credits";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import Decimal from "decimal.js";

interface Props {
  rateCardId?: string;
  ctrl: Schema.Types.CreateSharedCtrl;
  pricing: Pricing;
  baseContractOverrideDescriptions: Override.Description[];
  allProducts: Array<
    ProductListItem.IdFragment &
      ProductListItem.NameFragment &
      ProductListItem.TypeFragment
  >;
  contract: {
    starting_at: string | null;
    ending_before: string | null;
  };
  onAddOverride(productId?: string, creditType?: CreditType): void;
  onEditOverride(overrideId: string, creditType?: CreditType): void;
  onViewOverrides(): void;
}

function generateDropdownItems(
  overrides: Schema.Types.Override[],
  now: Dayjs,
  pricing: Pricing,
  onEditOverride: (overrideId: string, creditType: CreditType) => void,
) {
  if (overrides.length === 0) {
    return [{ label: "No pending overrides", disabled: true }];
  }

  return overrides.map((o) => {
    let rateLabel = "--";
    if (o.rate) {
      if (o.rate.type === "multiplier") {
        rateLabel = `${o.rate.multiplier} multiplier`;
      } else if (o.rate.type === "overwrite" && "unitPrice" in o.rate.newRate) {
        rateLabel = `${displayCreditsInCurrencyWithoutRounding(new Decimal(o.rate.newRate.unitPrice), o.rate.newRate.creditType)} overwrite`;
        // TODO: handle more overwrite types?
      } else {
        rateLabel = `${o.rate.type}`;
      }
    }

    const productLabel = o.productId
      ? ContractPricing.getProductName(pricing, o.productId, now)
      : "";
    const tagLabel =
      o.tags && o.tags.length > 0
        ? o.tags.map((tag) => `Product tag ${tag}`).join(", ")
        : "";

    // A single override can modify a product and multiple product tags
    const combinedLabel = [productLabel, tagLabel]
      .filter((label) => label)
      .join(", ");

    const overrideCreditType =
      o.rate?.type === "overwrite" && o.rate.newRate.type !== "percentage"
        ? o.rate.newRate.creditType
        : undefined;

    return {
      label: (
        <>
          {rateLabel}
          <br />
          {combinedLabel || "Tag-based override"}
          <br />
          {printDate(toDayjs(o.startingAt))}
        </>
      ),
      onClick: () =>
        onEditOverride(o.id, overrideCreditType ?? USD_CREDIT_TYPE),
    };
  });
}

export const RatesSection: React.FC<Props> = (props) => {
  const now = useNow();
  const overrides = props.ctrl.get("overrides") ?? [];
  const rateCard = props.rateCardId
    ? ContractPricing.getRateCard(props.pricing, props.rateCardId)
    : undefined;
  // If user changes scheme to lowest multiplier, reset priority for all pending multiplier overrides
  React.useEffect(() => {
    if (
      props.ctrl.get("multiplierOverridePrioritization") !== "LOWEST_MULTIPLIER"
    ) {
      return;
    }

    const updatedOverrides = overrides.map((override) => {
      if (override.rate?.type === "multiplier") {
        return {
          ...override,
          rate: {
            ...override.rate,
            priority: undefined,
          },
        };
      }
      return override;
    });

    props.ctrl.update({
      overrides: updatedOverrides,
    });
  }, [props.ctrl.get("multiplierOverridePrioritization")]);
  const dropdownItems = generateDropdownItems(
    overrides,
    now,
    props.pricing,
    props.onEditOverride,
  );

  const resp = useRateCardProductCountQuery({
    skip: !props.rateCardId,
    variables: {
      rateCardId: props.rateCardId ?? "",
    },
  });

  if (!rateCard) {
    return null;
  }

  const hasRates =
    (resp.data?.contract_pricing.rate_card.product_count ?? 0) > 0;

  const hasOverrides =
    dropdownItems.length > 0 &&
    dropdownItems[0].label !== "No pending overrides";

  // Add "View all overrides" only if there are pending overrides
  const dropdownItemsWithViewAllOverrides = hasOverrides
    ? [
        ...dropdownItems,
        {
          label: "View all overrides",
          onClick: () => props.onViewOverrides(),
        },
      ]
    : dropdownItems;

  return (
    <Section
      title="Rates"
      actions={
        <div className="flex">
          <Button
            disabled={!hasRates}
            onClick={() => props.onAddOverride()}
            className="rounded-r-none border-r border-r-white"
            text="Add an override"
            theme="primary"
            leadingIcon="plus"
          />
          <ButtonDropdown
            disabled={!hasRates}
            title={`${overrides.length || "0"}`}
            buttonTheme="primary"
            buttonType="fill"
            className="rounded-l-none"
            items={dropdownItemsWithViewAllOverrides}
            faket10={true}
          />
        </div>
      }
      description="Build a customer's entitled products, rates, and any additional overrides."
    >
      {rateCard && (
        <RateSchedulePanel
          title={rateCard.name}
          startingAt={
            props.contract.starting_at
              ? new Date(props.contract.starting_at)
              : now.toDate()
          }
          endingBefore={
            props.contract.ending_before
              ? new Date(props.contract.ending_before)
              : null
          }
          rateCardId={rateCard.id}
          overrides={[
            props.baseContractOverrideDescriptions,
            props.ctrl
              .get("overrides")
              ?.map((o) => describeNewOverride(now, props.allProducts, o)) ??
              [],
          ].flat()}
          onRowClick={(row) => {
            const rate = row.rate;
            const isPendingOverride =
              "override" in rate &&
              rate.override.id &&
              props.ctrl
                .get("overrides")
                ?.find((o) => o.id === rate.override.id);
            if (isPendingOverride) {
              props.onEditOverride(
                rate.override.id ?? "",
                getCreditTypeFromRate(rate),
              );
            } else {
              props.onAddOverride(row.product.id, getCreditTypeFromRate(rate));
            }
          }}
          renderT10Table={false}
        />
      )}
    </Section>
  );
};

const getCreditTypeFromRate = (
  rate: Overridden.Rate | Base.Rate,
): CreditType | undefined => {
  if (
    (rate.type === "overridden_multiplier_rate" ||
      rate.type === "tiered_override") &&
    rate.base.type !== "percentage"
  ) {
    return {
      id: rate.base.creditType?.id ?? USD_CREDIT_TYPE.id,
      name: rate.base.creditType?.name ?? USD_CREDIT_TYPE.name,
      client_id: rate.base.creditType?.clientId ?? null,
      environment_type:
        (rate.base.creditType?.environmentType as EnvironmentTypeEnum_Enum) ??
        null,
    };
  }
  if (
    rate.type === "percentage" ||
    rate.type === "overridden_percentage_rate" ||
    rate.type === "overridden_multiplier_rate" ||
    rate.type === "tiered_override"
  ) {
    return undefined;
  }
  return {
    id: rate.creditType?.id ?? USD_CREDIT_TYPE.id,
    name: rate.creditType?.name ?? USD_CREDIT_TYPE.name,
    client_id: rate.creditType?.clientId ?? null,
    environment_type:
      (rate.creditType?.environmentType as EnvironmentTypeEnum_Enum) ?? null,
  };
};
