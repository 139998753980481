import React from "react";
import { CustomerTab } from "../../components/CustomerTab";
import { AlertsTable } from "components/AlertsTable";
import { useFetchCustomerAlertsQuery } from "./queries.graphql";
import { useRequiredParam } from "lib/routes/params";
import { EmptyState } from "tenaissance/components/EmptyState";
import NotFoundPage from "../../../404";
import { useHasAccessToAlerts } from "../../../../lib/alerts";
import { CreateAlertDocument } from "pages/NewAlert/queries.graphql";
import { useUIMode } from "../../../../lib/useUIMode";
import { GatedButton } from "components/GatedButton";

export const AlertsTabContent: React.FC<{ customerId: string }> = ({
  customerId,
}) => {
  const { newUIEnabled } = useUIMode();
  const { data, loading } = useFetchCustomerAlertsQuery({
    variables: {
      customer_id: customerId,
    },
  });

  if (!loading && data?.Customer_by_pk?.alerts.length === 0) {
    return (
      <EmptyState
        icon="bell03"
        mainText={"This customer doesn't have any alerts yet"}
        supportingText="Create your first alert."
        actions={[
          <GatedButton
            linkTo={`${newUIEnabled ? "/connections" : ""}/alerts/new`}
            doc={CreateAlertDocument}
            text="Create alert"
            theme="primary"
            leadingIcon="plus"
          />,
        ]}
      />
    );
  }

  return (
    <AlertsTable
      alerts={
        data?.Customer_by_pk?.alerts.map((a) => ({
          customer_status: a.customer_status ?? undefined,
          ...a.alert,
        })) ?? []
      }
      loading={loading}
      singleCustomer={true}
    />
  );
};

export const AlertsTab: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const alertsEnabled = useHasAccessToAlerts();

  if (!alertsEnabled) {
    return <NotFoundPage />;
  }

  return (
    <CustomerTab>
      <div className="-mt-12">
        <AlertsTabContent customerId={customerId} />
      </div>
    </CustomerTab>
  );
};

export default AlertsTab;
