import React from "react";
import { Breadcrumb, Breadcrumbs } from "lib/breadcrumbs";
import { Icon } from "../Icon";
import { Link } from "react-router-dom";
import { useEnvironment } from "../../../lib/environmentSwitcher/context";

interface PageHeaderBreadcrumbsProps {
  breadcrumbs: Breadcrumbs;
}

function interlace(array: Breadcrumb[]): React.ReactElement[] {
  const result: React.ReactElement[] = [];

  for (let i = 0; i < array.length; i++) {
    if (i > 0) {
      result.push(
        <Icon icon="chevronRight" key={`separator-${i}`} size={10} />,
      );
    }

    const bc = array[i];
    result.push(<PageHeaderBreadcrumb key={bc.key} bc={bc} />);
  }

  return result;
}

const PageHeaderBreadcrumb: React.FC<{ bc: Breadcrumb }> = ({ bc }) => {
  const { prefixUrl } = useEnvironment();
  if (bc.type === "back") {
    throw new Error(`Unsupported breadcrumb type ${bc.type}`);
  }
  if (bc.badge) {
    throw new Error("Breadcrumb badges are not supported in t10");
  }

  if (bc.type === "loading") {
    return (
      <div className="my-xs h-md w-[60px] animate-pulse rounded-sm bg-gray-200" />
    );
  }

  if (!bc.routePath) {
    return (
      <span className="text-xs text-deprecated-gray-medium">{bc.label}</span>
    );
  }

  return (
    <Link
      key={bc.key}
      to={prefixUrl(bc.routePath)}
      className="text-xs text-deprecated-gray-medium hover:text-core-slate"
    >
      {bc.label}
    </Link>
  );
};

/**
 Breadcrumbs used in the page header. This handles rendering only, the breadcrumb specification and logic is located in the `lib/breadcrumbs` module.
 */
export const PageHeaderBreadcrumbs: React.FC<PageHeaderBreadcrumbsProps> = ({
  breadcrumbs,
}) => {
  return (
    <div className="flex items-center gap-md">
      {interlace(breadcrumbs.toArray(false))}
    </div>
  );
};
