import { v4 as uuid } from "uuid";
import { FormController } from "lib/FormController";
import { Schema } from "../../Schema";
import { CommitConsumptionRateTypeEnum } from "types/generated-graphql/__types__";

export type CommitFlyoverController = ReturnType<
  typeof useCommitFlyoverController
>;

export const useCommitFlyoverController = FormController.createHook(
  Schema.CommitFlyoverRoot,
  {
    init: (edit?: Schema.Types.CommitFlyoverRoot) => {
      const newCommit = {
        temporaryId: `temp-id:${uuid()}`,
        rateType: CommitConsumptionRateTypeEnum.ListRate,
      };
      return edit ?? newCommit;
    },
  },
);
