import React from "react";
import "/src/tenaissance/tenaissance.css";
import { Card } from "tenaissance/components/Card";
import Decimal from "decimal.js";
import { dayjs } from "lib/dayjs";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { Link } from "react-router-dom";
import { Icon } from "tenaissance/components/Icon";

interface EventsData {
  duplicates: Array<{
    __typename?: "MRI_EventCountWindow";
    starting_on: string;
    count: string;
  }>;
  non_duplicates: Array<{
    __typename?: "MRI_EventCountWindow";
    starting_on: string;
    count: string;
  }>;
}

interface EventGraphProps {
  data?: EventsData;
  loading: boolean;
  startingAfter?: dayjs.Dayjs;
  endingBefore?: dayjs.Dayjs;
  headerActions?: React.ReactNode[];
  eventsToShow: "all" | "duplicatesOnly" | "nonDuplicatesOnly";
}

export const EventGraphCard: React.FC<EventGraphProps> = ({
  data,
  loading,
  startingAfter: startingAfterInput,
  endingBefore: endingBeforeInput,
  headerActions,
  eventsToShow,
}) => {
  const { prefixUrl } = useEnvironment();
  const today = dayjs().utc().endOf("day");
  // By default, show the last 30 days
  const startingAfterDate = startingAfterInput ?? today.subtract(30, "days");
  const endingBeforeDate = endingBeforeInput ?? today;
  let chartXAxisLabel: string;
  let tooltipDateRangeText: string;
  // if endingBefore is today, the date range is the Last n days
  if (endingBeforeDate.isSame(today, "day")) {
    const numDaysLookback = endingBeforeDate.diff(startingAfterDate, "days");
    if (numDaysLookback === 0) {
      chartXAxisLabel = `Today`;
      tooltipDateRangeText = `today`;
    } else if (numDaysLookback === 1) {
      chartXAxisLabel = `Last day`;
      tooltipDateRangeText = `in the last day`;
    } else {
      chartXAxisLabel = `Last ${numDaysLookback} days`;
      tooltipDateRangeText = `in the last ${numDaysLookback} days`;
    }
  } else {
    // date range is a custom date range
    // if start and end date aren't in the same year, render the start date year
    const startDateFormattedLabel = startingAfterDate.isSame(
      endingBeforeDate,
      "year",
    )
      ? startingAfterDate.format("MMM D")
      : startingAfterDate.format("MMM D, YYYY");
    const endDateFormattedLabel = endingBeforeDate.format("MMM D, YYYY");
    chartXAxisLabel = `${startDateFormattedLabel} - ${endDateFormattedLabel} (UTC)`;
    tooltipDateRangeText = `from ${startDateFormattedLabel} to ${endDateFormattedLabel}`;
  }

  if (data?.duplicates.length !== data?.non_duplicates.length) {
    throw new Error("Data length mismatch");
  }

  let totalEventCount = new Decimal(0);
  let totalDuplicates = new Decimal(0);

  const eventsByDay =
    data?.non_duplicates
      .map(({ starting_on, count }, i) => {
        totalEventCount = totalEventCount.add(count);
        totalDuplicates = totalDuplicates.add(data?.duplicates[i].count);

        return {
          date: dayjs(starting_on).endOf("day").toDate(),
          // Here, we assume both arrays are the same length (checked above) and in the same order (ensured by resolver)
          total: Number(count) + Number(data?.duplicates[i].count),
          duplicates: Number(data?.duplicates[i].count),
        };
      })
      .sort((a, b) => a.date.getTime() - b.date.getTime()) ?? [];

  const showNonDuplicates =
    eventsToShow === "all" || eventsToShow === "nonDuplicatesOnly";
  const showDuplicates =
    eventsToShow === "all" || eventsToShow === "duplicatesOnly";
  const chartData = [
    ...(showNonDuplicates
      ? [
          {
            name: "Total",
            yAxisUnit: "events",
            data:
              eventsByDay.map(({ date, total }) => ({
                date,
                value: total,
              })) ?? [],
          },
        ]
      : []),
    ...(showDuplicates
      ? [
          {
            name: "Duplicates",
            yAxisUnit: "events",
            data:
              eventsByDay.map(({ date, duplicates }) => ({
                date,
                value: duplicates,
              })) ?? [],
          },
        ]
      : []),
  ];

  return (
    <Card
      loading={loading}
      title="Event stream"
      headerActions={headerActions}
      metrics={[
        ...(showNonDuplicates
          ? [
              {
                value: totalEventCount,
                label: "Total",
                tooltipContent: `Total number of events sent ${tooltipDateRangeText}.`,
              },
            ]
          : []),
        ...(showDuplicates
          ? [
              {
                value: totalDuplicates,
                label: (
                  <Link
                    to={prefixUrl("/connections/events?duplicates=true")}
                    className="flex items-center"
                  >
                    <Icon
                      icon="arrowNarrowUpRight"
                      size={16}
                      className="mr-sm"
                    />
                    Duplicates
                  </Link>
                ),
                tooltipContent: `Events sent ${tooltipDateRangeText} with a transaction ID that matched an existing event.`,
              },
            ]
          : []),
      ]}
      children={
        <Card.Chart
          chartData={chartData}
          type="area"
          yAxisLabel="Events"
          xAxisLabel={chartXAxisLabel}
          legendPostion="top"
        />
      }
    />
  );
};
