import React from "react";
import styles from "./index.module.less";
import { Tooltip } from "design-system";

export const WarningDot: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Tooltip content={message}>
      <span className={styles.warningDot} />
    </Tooltip>
  );
};
