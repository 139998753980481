import React, { useState } from "react";
import { useRequiredParam } from "lib/routes/params";
import classnames from "classnames";
import { CustomerTab } from "pages/Customer/components/CustomerTab";

import styles from "./index.module.less";
import { Body, Headline, Subtitle, Badge, Icon } from "design-system";
import { useCustomerInfoQuery } from "../../data/customer_details.graphql";
import {
  useCustomerPlansQuery,
  CustomerPlansQuery,
} from "./data/plans.graphql";
import { renderDate } from "lib/time";
import { EmptyState } from "tenaissance/components/EmptyState";
import { TextSkeleton } from "components/Skeleton";
import { dayjs } from "lib/dayjs";

import { ListRow } from "pages/Customer/components/ListRow";
import PlanPanel from "./components/PlanPanel";
import { useNavigate } from "lib/useNavigate";
import { AddPlanToCustomerDocument } from "pages/AddPlanToCustomer/queries.graphql";
import { GatedButton } from "../../../../components/GatedButton";
const PlansTab: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const navigate = useNavigate();
  const { data: customerInfo, loading: customerInfoLoading } =
    useCustomerInfoQuery({
      variables: {
        customer_id: customerId,
      },
    });
  const { data: plansData, loading: plansLoading } = useCustomerPlansQuery({
    variables: {
      customer_id: customerId,
    },
  });
  const [selectedListItem, setSelectedListItem] = useState<string | null>(null);
  const addPlan = () => navigate(`/customers/${customerId}/plans/add`);

  if (
    customerInfo &&
    customerInfo.Customer &&
    plansData?.CustomerPlan.length === 0
  ) {
    return (
      <CustomerTab>
        <EmptyState
          icon="file05"
          mainText={`${customerInfo.Customer.name} doesn't have any plans.`}
          supportingText="Once you add a plan, you'll see its details here."
          actions={[
            <GatedButton
              onClick={addPlan}
              doc={AddPlanToCustomerDocument}
              text="Add new plan"
              theme="primary"
              leadingIcon="plus"
            />,
          ]}
        />
      </CustomerTab>
    );
  }

  const plans = plansData?.CustomerPlan || [];

  let selectedPlan = plans.find((p) => selectedListItem === p.id);
  if (selectedListItem === null) {
    if (plans.length > 0) {
      setSelectedListItem(plans[0].id);
      selectedPlan = plans[0];
    }
  }

  return (
    <CustomerTab>
      <div className={styles.container}>
        <div className={styles.leftColumn}>
          {customerInfoLoading || plansLoading ? (
            <RowSkeleton />
          ) : (
            <>
              <div className={styles.row}>
                <div className={styles.header}>
                  <Headline level={5}>Plans</Headline>
                  <GatedButton
                    onClick={addPlan}
                    doc={AddPlanToCustomerDocument}
                    text="Start New Plan"
                    theme="primary"
                    leadingIcon="plus"
                  />
                </div>
              </div>
              {plans.map((customerPlan, i) => (
                <PlanRow
                  customerPlan={customerPlan}
                  key={i}
                  onClick={() => setSelectedListItem(customerPlan.id)}
                  selected={selectedListItem === customerPlan.id}
                />
              ))}
            </>
          )}
        </div>
        <div className={styles.rightColumn}>
          {selectedPlan && customerInfo?.Customer && (
            <PlanPanel
              customerPlan={selectedPlan}
              customerName={customerInfo.Customer.name}
              customerId={customerId}
            />
          )}
        </div>
      </div>
    </CustomerTab>
  );
};

interface PlanRowProps {
  customerPlan: CustomerPlansQuery["CustomerPlan"][0];
  onClick: () => void;
  selected: boolean;
}

const PlanRow: React.FC<PlanRowProps> = ({
  customerPlan,
  onClick,
  selected,
}) => {
  const now = new Date();
  const start = new Date(customerPlan.start_date);
  const end = customerPlan.cancellation_date
    ? dayjs
        .utc(customerPlan.cancellation_date)
        .subtract(1, "day")
        .endOf("day")
        .toDate()
    : null;
  const active = start < now && !(end && now >= end);
  return (
    <div
      className={classnames(styles.planRow, {
        [styles.selectedPlan]: selected,
        [styles.dropShadowHover]: !selected,
      })}
      onClick={onClick}
    >
      <ListRow
        title={
          <Headline level={6} className={styles.planTitle}>
            {customerPlan.Plan.name}
          </Headline>
        }
        subtitle={
          <Body level={2} className={styles.planId}>
            {customerPlan.Plan.id}
          </Body>
        }
        secondaryTitle={
          active ? (
            <Badge theme="success" type={selected ? "dark" : "light"}>
              ACTIVE
            </Badge>
          ) : (
            <Badge theme="grey" type={selected ? "dark" : "light"}>
              INACTIVE
            </Badge>
          )
        }
        detail={
          <div className={styles.datesRow}>
            <div
              className={classnames(styles.dateLabel, {
                [styles.activeStart]: active,
              })}
            >
              <Icon icon="today" className={styles.icon} />
              <Subtitle level={4}>
                {`${start < now ? "Started" : "Starts"} ${renderDate(start, {
                  isUtc: true,
                })}`}
              </Subtitle>
            </div>
            {end && (
              <div className={styles.dateLabel}>
                <Icon icon="timer" className={styles.icon} />
                <Subtitle level={4}>
                  {`Expire${end > now ? "s" : "d"} ${renderDate(end, {
                    isUtc: true,
                  })}`}
                </Subtitle>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

const RowSkeleton: React.FC = () => {
  return (
    <div className={styles.row}>
      <TextSkeleton />
      <TextSkeleton />
      <TextSkeleton />
      <TextSkeleton />
    </div>
  );
};

export default PlansTab;
