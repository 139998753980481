export const handleCopyClick = async (
  textToCopy: string,
  setCopiedState: (state: string | null) => void,
) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    setCopiedState(textToCopy);

    setTimeout(() => {
      setCopiedState(null);
    }, 2000);
  } catch (error) {
    alert("Failed to copy to clipboard. Please try again.");
  }
};
