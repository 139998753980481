import classnames from "classnames";
import React from "react";

import { TextSkeleton } from "components/Skeleton";
import { Subtitle } from "design-system";
import styles from "./index.module.less";

type SelectableCard = {
  id?: string;
  content: string;
  onClick?: () => void;
  icon: React.ReactNode;
  className?: string;
  selected?: boolean;
};

interface SelectableCardsProps {
  cards: SelectableCard[];
  clickable?: boolean;
  loading?: boolean;
}

export const SelectableCards: React.FC<SelectableCardsProps> = ({
  cards,
  clickable,
  loading,
}) => {
  return (
    <div className={styles.cards}>
      {cards.map((card, i) => {
        const { id, content, onClick, icon, className, selected } = card;
        return loading ? (
          <div>
            <TextSkeleton />
            <TextSkeleton />
            <TextSkeleton />
          </div>
        ) : (
          <a
            key={id ?? i}
            onClick={onClick}
            className={classnames(styles.card, className, {
              [styles.selected]: !!selected,
              [styles.clickable]: !!clickable,
            })}
          >
            <Subtitle
              level={4}
              className={classnames(styles.content, {
                [styles.selected]: !!selected,
              })}
            >
              {content}
            </Subtitle>
            <div className={styles.icon}>{icon}</div>
          </a>
        );
      })}
    </div>
  );
};
