import React, { useState } from "react";

import { Checkbox, Input } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";

import { Body } from "design-system";
import { useSnackbar } from "components/Snackbar";
import { Select } from "design-system";
import {
  useAddAwsMarketplaceForCustomerMutation,
  useInsertCustomerBillingProviderConfigurationMutation,
} from "./queries.graphql";
import { useFeatureFlag } from "../../../../../../lib/launchdarkly";
import { CustomerSettingsWritePlansAndOrContracts } from "../../../../../../lib/billingProvider/billingProviderSettings";

type AWSMarketplaceSettingsModalProps = {
  onClose: (status?: { isSuccess: true }) => void;
  customerId: string;
} & CustomerSettingsWritePlansAndOrContracts;

const evictCacheOptions = {
  update(cache: any) {
    cache.evict({
      fieldName: "aws_billing_provider_configs",
    });
    cache.evict({
      fieldName: "customer_billing_provider_configurations",
    });
    cache.evict({
      fieldName: "BillingProviderCustomer",
    });
    cache.evict({
      fieldName: "CustomerConfig",
    });
    cache.evict({
      fieldName: "Customer_aggregate",
    });
  },
};

export const CONTRACTS_ONLY_WHEN_ANOTHER_BILLING_PROVIDER_IS_CONFIGURED_ON_PLANS =
  "NOTE: This will add configuration that only works with new contracts, since another billing provider has been configured on the plan.";

export const AWSMarketplaceSettingsModal: React.FC<
  AWSMarketplaceSettingsModalProps
> = ({ onClose, customerId, plansAndOrContracts }) => {
  const awsSubscriptionEnabled = useFeatureFlag<boolean>(
    "aws-marketplace-subscription-products",
    false,
  );
  const pushMessage = useSnackbar();
  const [awsCustomerId, setAWSCustomerId] = useState<string>("");
  const [awsRegion, setAWSRegion] = useState<string>("us-east-1");
  const [awsProductCode, setAWSProductCode] = useState<string>("");
  const [awsIsSubscription, setAWSIsSubscription] = useState<boolean>(false);
  const [
    updateSettingsPlansAndOrContracts,
    {
      loading: updateSettingsPlansAndContractsLoading,
      error: updateSettingsPlansAndContractsError,
    },
  ] = useAddAwsMarketplaceForCustomerMutation(evictCacheOptions);
  const [
    updateSettingsContractsOnly,
    {
      loading: updateSettingsContractsOnlyLoading,
      error: updateSettingsContractsOnlyError,
    },
  ] = useInsertCustomerBillingProviderConfigurationMutation(evictCacheOptions);

  const loading =
    updateSettingsPlansAndContractsLoading ||
    updateSettingsContractsOnlyLoading;
  const error =
    updateSettingsPlansAndContractsError || updateSettingsContractsOnlyError;
  const updateSettingsAction = async () => {
    if (awsCustomerId && awsRegion && awsProductCode) {
      if (plansAndOrContracts === "contracts_only") {
        await updateSettingsContractsOnly({
          variables: {
            customer_id: customerId,
            configuration: {
              aws_customer_id: awsCustomerId,
              aws_product_code: awsProductCode,
              aws_region: awsRegion,
              aws_is_subscription_product: awsIsSubscription,
            },
          },
        });
      } else {
        // writes to plans table, and optionally contracts table if delivery method
        // (client-level contracts config) is set up
        await updateSettingsPlansAndOrContracts({
          variables: {
            customer_id: customerId,
            aws_customer_id: awsCustomerId,
            aws_product_code: awsProductCode,
            aws_region: awsRegion,
            aws_is_subscription_product: awsIsSubscription,
          },
        });
      }
      pushMessage({
        content: "AWS Marketplace configuration saved",
        type: "success",
      });
      onClose({ isSuccess: true });
    }
  };
  const actionButtons = (
    <>
      <Button
        key="primary"
        disabled={!awsCustomerId || !awsRegion || !awsProductCode || loading}
        onClick={updateSettingsAction}
        loading={loading}
        text="Add AWS Marketplace configuration"
        theme="primary"
      />
    </>
  );
  let errorText = "";
  let isCustomerError = false;
  let isRegionError = false;
  if (!loading) {
    if (error) {
      if (error.graphQLErrors.length > 0) {
        errorText = error.graphQLErrors[0].message;
      } else {
        errorText = "An unknown error occurred. Please try again.";
      }
      isRegionError = errorText === "Invalid region specified";
      isCustomerError = errorText === "Invalid AWS customer id specified";
    }
  }
  return (
    <Popup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={() => onClose()}
      title="AWS Marketplace configuration"
    >
      <Body level={2}>
        To add the AWS Marketplace profile you need the customer’s associated
        AWS customer ID, their product code and the region they are being
        metered in. If you haven't made special arrangements for the region
        choose us-east-1.
        {plansAndOrContracts === "contracts_only" && (
          <>
            <br />
            <br />
            {
              CONTRACTS_ONLY_WHEN_ANOTHER_BILLING_PROVIDER_IS_CONFIGURED_ON_PLANS
            }
          </>
        )}
      </Body>
      {errorText && !isRegionError && !isCustomerError && (
        <div className="mb-12 text-error-600">{errorText}</div>
      )}
      <Input
        name="AWS Customer ID"
        placeholder="Enter AWS customer ID"
        value={awsCustomerId ?? ""}
        className="mb-12"
        onChange={setAWSCustomerId}
        autoFocus
        error={
          !loading &&
          !isRegionError &&
          (isCustomerError ? errorText : Boolean(errorText))
        }
      />
      <Input
        name="Product code"
        placeholder="Enter AWS product code"
        value={awsProductCode ?? ""}
        className="mb-12"
        onChange={setAWSProductCode}
        error={
          !loading && !isRegionError && !isCustomerError && Boolean(errorText)
        }
      />
      <div className="text-body-400 mb-12">
        <Select
          name="Region"
          options={[
            { label: "af-south-1", value: "af-south-1" },
            { label: "ap-east-1", value: "ap-east-1" },
            { label: "ap-northeast-1", value: "ap-northeast-1" },
            { label: "ap-northeast-2", value: "ap-northeast-2" },
            { label: "ap-northeast-3", value: "ap-northeast-3" },
            { label: "ap-south-1", value: "ap-south-1" },
            { label: "ap-southeast-1", value: "ap-southeast-1" },
            { label: "ap-southeast-2", value: "ap-southeast-2" },
            { label: "ca-central-1", value: "ca-central-1" },
            { label: "cn-north-1", value: "cn-north-1" },
            { label: "cn-northwest-1", value: "cn-northwest-1" },
            { label: "eu-central-1", value: "eu-central-1" },
            { label: "eu-north-1", value: "eu-north-1" },
            { label: "eu-south-1", value: "eu-south-1" },
            { label: "eu-west-1", value: "eu-west-1" },
            { label: "eu-west-2", value: "eu-west-2" },
            { label: "eu-west-3", value: "eu-west-3" },
            { label: "me-south-1", value: "me-south-1" },
            { label: "sa-east-1", value: "sa-east-1" },
            { label: "us-east-1", value: "us-east-1" },
            { label: "us-east-2", value: "us-east-2" },
            { label: "us-gov-east-1", value: "us-gov-east-1" },
            { label: "us-gov-west-1", value: "us-gov-west-1" },
            { label: "us-west-1", value: "us-west-1" },
            { label: "us-west-2", value: "us-west-2" },
          ]}
          onChange={setAWSRegion}
          value={awsRegion ?? ""}
          placeholder="Select"
          error={!loading && isRegionError && errorText}
        />
      </div>
      {awsSubscriptionEnabled ? (
        <Checkbox
          checked={awsIsSubscription}
          label="Hourly usage-based pricing (per-unit)"
          onChange={(value) => setAWSIsSubscription(value)}
        ></Checkbox>
      ) : null}
    </Popup>
  );
};

export default AWSMarketplaceSettingsModal;
