import { z } from "zod";

import { FormController, FormReader } from "lib/FormController";

import { Schema } from "../../Schema";

const ParentFields = z.object({
  billingScheduleFrequency: z
    .enum(Schema.BILLING_SCHEDULE_FREQUENCY)
    .default("one"),
  billingSchedule: Schema.BillingSchedule,
});

type ParentShape = (typeof ParentFields)["shape"];
export type ParentCtrl = FormController<ParentShape>;

export const RootSchema = z.object({
  frequency: z.enum(Schema.BILLING_SCHEDULE_FREQUENCY),
  schedule: Schema.BillingSchedule,
});
type RootShape = (typeof RootSchema)["shape"];

export const useRootCtrl = FormController.makeChildHook(RootSchema, {
  read(parent: FormReader<ParentShape>) {
    return {
      frequency: parent.get("billingScheduleFrequency"),
      schedule: parent.get("billingSchedule"),
    };
  },
  write(child: FormReader<RootShape>) {
    return {
      billingScheduleFrequency: child.get("frequency"),
      billingSchedule: child.get("schedule"),
    };
  },
});

export type RootCtrl = ReturnType<typeof useRootCtrl>;
