import React from "react";
import { Icon, IconName } from "../Icon";
import { ReactComponent as FeatureIcon } from "./FeatureIcon.svg";

export const IntroEmptyState: React.FC<{
  icon: IconName;
  mainText: string;
  supportingText: string;
  bullets: { mainText: string; supportingText: string }[];
}> = (props) => {
  return (
    <div className="flex flex-col">
      <div className="relative">
        <FeatureIcon className="absolute left-[45%] top-[30px]" />
        <Icon icon="cube03" className="absolute left-[48%] top-[60px]" />
        <div className="mb-2xl mt-[160px] text-center">
          <div className="mb-2 text-xl font-semibold text-core-slate">
            {props.mainText}
          </div>
          <div className="text-md font-normal text-gray-600">
            {props.supportingText}
          </div>
        </div>
      </div>
      <div className="ml-24 inline-flex w-[560px] flex-col items-start justify-items-center gap-[24px]">
        {props.bullets.map((bullet, i) => (
          <div
            className="inline-flex items-center justify-start gap-4 self-stretch"
            key={i}
          >
            <Icon
              icon="dot"
              className="mr-4 shrink-0 items-center self-start"
            />
            <div className="gap-0.5 inline-flex flex-col items-start justify-center">
              <div className="font-semibold text-core-slate">
                {bullet.mainText}
              </div>
              <div className="text-gray-600">{bullet.supportingText}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
