import React from "react";
import { RouteObject, Navigate, Outlet, useLocation } from "react-router-dom";

import { ContractsRouteSwitch } from "lib/contracts/ContractsRouteSwitch";
import { EnvironmentRedirect } from "lib/environmentSwitcher/EnvironmentRedirect";

//t10 pages
import T10Overview from "tenaissance/pages/Overview";

// Legacy customer routes
import Overview from "pages/Overview";
import APITokens from "pages/APITokens";
import Events from "pages/Events";
import Webhooks from "pages/Webhooks";
import Alerts from "pages/Alerts";
import AddPlanToCustomer from "pages/AddPlanToCustomer";
import NewCustomer from "pages/NewCustomer";
import UsageTab, { Usage } from "pages/Customer/tabs/Usage";
import PlansTab from "pages/Customer/tabs/Plans";
import InvoicesTab from "pages/Customer/tabs/Invoices";
import SettingsTab from "pages/Customer/tabs/Settings";
import CreditsTab from "pages/Customer/tabs/Credits";
import AlertsTab from "pages/Customer/tabs/Alerts";
import AuditLogsTab from "pages/Customer/tabs/AuditLogs";

// Contract-based customer routes
import { CustomerBillingOverview } from "./Customer/BillingOverview/BillingOverview";
import { CustomersList } from "./CustomersList/CustomersList";
import { CustomerCredits } from "./Customer/Credits/CustomerCredits";
import { CustomerAlerts } from "./Customer/Alerts/CustomerAlerts";
import { CustomerSettings } from "./Customer/Settings/CustomerSettings";
import { CustomerAuditLogs } from "./Customer/AuditLogs/AuditLogs";
import { CustomerAuditLogs as T10CustomerAuditLogs } from "tenaissance/pages/Customer/tabs/AuditLogs";
import {
  ContractCreatePage,
  AmendmentCreate,
  CustomerContractOverview,
  CustomerContractLifecycle,
  CustomerContractInvoices,
  CustomerContractCommits,
  CustomerContractSettings,
} from "./Customer/Contracts";
import {
  ContractCustomerPlanOverview,
  ContractCustomerPlanInvoices,
} from "./Customer/Plans";

// contract-pricing routes
import { ContractProductList } from "./Pricing/ContractProductList";
import { RateCardsList } from "./Pricing/RateCardsList";
import { RateCardDetails } from "./Pricing/RateCardsDetails";

// Other routes
import { CorrectInvoice } from "../CorrectInvoice";
import { CustomerUsage } from "./Customer/Usage/CustomerUsage";
import { RateCardCreate } from "./Pricing/CreateAndEditRateCard/RateCardCreate";
import { EditRateCard } from "./Pricing/CreateAndEditRateCard";
import {
  LegacyDesignCustomerCommitOverview,
  CustomerCommitsOverview,
  CustomerCommitOverview,
} from "./Customer/Contracts/Commits";
import { RateCardCreateV2 } from "../../tenaissance/pages/Offering/tabs/RateCards/RateCardCreate";
import { Step1 } from "../../tenaissance/pages/Offering/tabs/RateCards/Step1";
import { Step2 } from "../../tenaissance/pages/Offering/tabs/RateCards/Step2";
import { Step3 } from "../../tenaissance/pages/Offering/tabs/RateCards/Step3";
import { NewUIRouteSwitch } from "../../tenaissance/lib/routeSwitch";
import { CreateProduct } from "./Pricing/CreateAndEditProductV2/CreateProduct";
import { Customer } from "tenaissance/pages/Customer";
import { CustomerInvoices } from "tenaissance/pages/Customer/tabs/Invoices";
import { Commits } from "tenaissance/pages/Customer/tabs/Commits";
import { CustomerOverview as T10CustomerOverview } from "tenaissance/pages/Customer/tabs/Overview";
import { CustomerAlerts as T10CustomerAlerts } from "tenaissance/pages/Customer/tabs/Alerts";
import { CustomerSettings as T10CustomerSettings } from "tenaissance/pages/Customer/tabs/Settings";
import { CustomerCredits as T10CustomerCredits } from "tenaissance/pages/Customer/tabs/Credits";

export const CommitsRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/commits([\/?#]|$)/,
    "/commits-and-credits$1",
  );
  return <Navigate to={newPath} replace />;
};

export const BalancesRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/balances([\/?#]|$)/,
    "/commits-and-credits$1",
  );
  return <Navigate to={newPath} replace />;
};

export const ContractPricingRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/contract-pricing([\/?#]|$)/,
    "/offering$1",
  );
  return <Navigate to={newPath} replace />;
};

export const ContractCustomersRoutes: RouteObject[] = [
  {
    index: true,
    element: (
      <NewUIRouteSwitch
        disabled={
          <ContractsRouteSwitch
            enabled={<EnvironmentRedirect to="/customers" />}
            disabled={<EnvironmentRedirect to="/overview" />}
          />
        }
        enabled={<T10Overview />}
      />
    ),
  },
  {
    path: "overview",
    element: (
      <NewUIRouteSwitch
        disabled={
          <ContractsRouteSwitch
            enabled={<EnvironmentRedirect to="/customers" />}
            disabled={<Overview />}
          />
        }
        enabled={<EnvironmentRedirect to="/" />}
      />
    ),
  },
  {
    path: "alerts",
    element: (
      <NewUIRouteSwitch
        disabled={<Alerts />}
        enabled={<EnvironmentRedirect to="/connections/alerts" />}
      />
    ),
  },
  {
    path: "customers",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <NewUIRouteSwitch
            disabled={
              <ContractsRouteSwitch
                enabled={<CustomersList />}
                disabled={<EnvironmentRedirect to="/overview" />}
              />
            }
            enabled={<CustomersList />}
          />
        ),
      },
      {
        path: "new",
        element: <NewCustomer />,
      },
      {
        path: ":customerId",
        children: [
          {
            index: true,
            element: (
              <NewUIRouteSwitch
                disabled={
                  <ContractsRouteSwitch
                    enabled={<CustomerBillingOverview />}
                    disabled={<InvoicesTab />}
                  />
                }
                enabled={
                  <Customer>
                    <T10CustomerOverview />
                  </Customer>
                }
              />
            ),
          },

          // CUSTOMER LEVEL STUFF
          {
            path: "commits/*",
            element: (
              <NewUIRouteSwitch
                disabled={<CommitsRedirect />}
                enabled={<CommitsRedirect />}
              />
            ),
          },
          {
            path: "balances/*",
            element: (
              <NewUIRouteSwitch
                disabled={<BalancesRedirect />}
                enabled={<BalancesRedirect />}
              />
            ),
          },
          {
            path: "commits-and-credits",
            children: [
              {
                index: true,
                element: (
                  <NewUIRouteSwitch
                    disabled={
                      <ContractsRouteSwitch
                        enabled={<CustomerCommitsOverview />}
                      />
                    }
                    enabled={
                      <Customer>
                        <Commits />
                      </Customer>
                    }
                  />
                ),
              },
              {
                path: ":commitId",
                children: [
                  {
                    index: true,
                    element: (
                      <NewUIRouteSwitch
                        disabled={
                          <ContractsRouteSwitch
                            enabled={<LegacyDesignCustomerCommitOverview />}
                          />
                        }
                        enabled={<CustomerCommitOverview />}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "credits",
            element: (
              <NewUIRouteSwitch
                disabled={
                  <ContractsRouteSwitch
                    enabled={<CustomerCredits />}
                    disabled={<CreditsTab />}
                  />
                }
                enabled={
                  <Customer>
                    <T10CustomerCredits />
                  </Customer>
                }
              />
            ),
          },
          {
            path: "usage",
            element: (
              <NewUIRouteSwitch
                disabled={
                  <ContractsRouteSwitch
                    enabled={<CustomerUsage />}
                    disabled={<UsageTab />}
                  />
                }
                enabled={
                  <Customer>
                    <Usage />
                  </Customer>
                }
              />
            ),
          },
          {
            path: "alerts",
            element: (
              <NewUIRouteSwitch
                disabled={
                  <ContractsRouteSwitch
                    enabled={<CustomerAlerts />}
                    disabled={<AlertsTab />}
                  />
                }
                enabled={
                  <Customer>
                    <T10CustomerAlerts />
                  </Customer>
                }
              />
            ),
          },
          {
            path: "settings",
            element: (
              <NewUIRouteSwitch
                disabled={
                  <ContractsRouteSwitch
                    enabled={<CustomerSettings />}
                    disabled={<SettingsTab />}
                  />
                }
                enabled={
                  <Customer>
                    <T10CustomerSettings />
                  </Customer>
                }
              />
            ),
          },
          {
            path: "audit-logs",
            element: (
              <NewUIRouteSwitch
                disabled={
                  <ContractsRouteSwitch
                    enabled={<CustomerAuditLogs />}
                    disabled={<AuditLogsTab />}
                  />
                }
                enabled={
                  <Customer>
                    <T10CustomerAuditLogs />
                  </Customer>
                }
              />
            ),
          },
          {
            path: "invoices",
            children: [
              {
                index: true,
                element: (
                  <NewUIRouteSwitch
                    enabled={
                      <Customer>
                        <CustomerInvoices />
                      </Customer>
                    }
                  />
                ),
              },
              {
                path: ":invoiceId",
                children: [
                  {
                    index: true,
                    element: (
                      <NewUIRouteSwitch
                        enabled={
                          <Customer>
                            <CustomerInvoices />
                          </Customer>
                        }
                        disabled={
                          <ContractsRouteSwitch disabled={<InvoicesTab />} />
                        }
                      />
                    ),
                  },
                  {
                    path: "correct",
                    element: (
                      <NewUIRouteSwitch
                        disabled={
                          <ContractsRouteSwitch disabled={<CorrectInvoice />} />
                        }
                        enabled={<CorrectInvoice />}
                      />
                    ),
                  },
                ],
              },
            ],
          },

          {
            path: "contracts/add",
            children: [
              {
                index: true,
                element: (
                  <NewUIRouteSwitch
                    disabled={
                      <ContractsRouteSwitch enabled={<ContractCreatePage />} />
                    }
                    enabled={<ContractCreatePage />}
                  />
                ),
              },
            ],
          },

          // CUSTOMER CONTRACT
          {
            path: "contracts/:contractId",
            children: [
              {
                index: true,
                element: (
                  <NewUIRouteSwitch
                    disabled={
                      <ContractsRouteSwitch
                        enabled={<CustomerContractOverview />}
                      />
                    }
                    enabled={<CustomerContractOverview />}
                  />
                ),
              },
              {
                path: "amend",
                element: (
                  <NewUIRouteSwitch
                    disabled={
                      <ContractsRouteSwitch enabled={<AmendmentCreate />} />
                    }
                    enabled={<AmendmentCreate />}
                  />
                ),
              },
              {
                path: "lifecycle",
                children: [
                  {
                    index: true,
                    element: (
                      <NewUIRouteSwitch
                        disabled={
                          <ContractsRouteSwitch
                            enabled={<CustomerContractLifecycle />}
                          />
                        }
                        enabled={<CustomerContractLifecycle />}
                      />
                    ),
                  },
                  {
                    path: ":lifecycleId",
                    children: [
                      {
                        index: true,
                        element: (
                          <NewUIRouteSwitch
                            disabled={
                              <ContractsRouteSwitch
                                enabled={<CustomerContractLifecycle />}
                              />
                            }
                            enabled={<CustomerContractLifecycle />}
                          />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "invoices",
                children: [
                  {
                    index: true,
                    element: (
                      <NewUIRouteSwitch
                        disabled={
                          <ContractsRouteSwitch
                            enabled={<CustomerContractInvoices />}
                          />
                        }
                        enabled={<CustomerContractInvoices />}
                      />
                    ),
                  },
                  {
                    path: ":invoiceId",
                    children: [
                      {
                        index: true,
                        element: (
                          <NewUIRouteSwitch
                            disabled={
                              <ContractsRouteSwitch
                                enabled={<CustomerContractInvoices />}
                              />
                            }
                            enabled={<CustomerContractInvoices />}
                          />
                        ),
                      },
                      {
                        path: "correct",
                        element: (
                          <NewUIRouteSwitch
                            disabled={
                              <ContractsRouteSwitch
                                enabled={<CorrectInvoice />}
                              />
                            }
                            enabled={<CorrectInvoice />}
                          />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "commits/*",
                element: <CommitsRedirect />,
              },
              {
                path: "balances/*",
                element: <BalancesRedirect />,
              },
              {
                path: "commits-and-credits",
                children: [
                  {
                    index: true,
                    element: (
                      <NewUIRouteSwitch
                        disabled={
                          <ContractsRouteSwitch
                            enabled={<CustomerContractCommits />}
                          />
                        }
                        enabled={<CustomerContractCommits />}
                      />
                    ),
                  },
                  {
                    path: ":commitId",
                    element: (
                      <NewUIRouteSwitch
                        disabled={
                          <ContractsRouteSwitch
                            enabled={<CustomerContractCommits />}
                          />
                        }
                        enabled={<CustomerContractCommits />}
                      />
                    ),
                  },
                ],
              },
              {
                path: "settings",
                element: (
                  <NewUIRouteSwitch
                    disabled={
                      <ContractsRouteSwitch
                        enabled={<CustomerContractSettings />}
                      />
                    }
                  />
                  // TODO: delete this, not linked to from anywhere
                ),
              },
            ],
          },

          // PLANS
          {
            path: "plans",
            children: [
              {
                index: true,
                element: (
                  <NewUIRouteSwitch
                    disabled={<ContractsRouteSwitch disabled={<PlansTab />} />}
                    enabled={<EnvironmentRedirect to="/customers" />}
                  />
                ),
              },
              {
                path: "add",
                element: (
                  <NewUIRouteSwitch
                    disabled={<AddPlanToCustomer />}
                    enabled={<AddPlanToCustomer />}
                  />
                ),
              },
              {
                path: ":customerPlanId",
                children: [
                  {
                    index: true,
                    element: (
                      <NewUIRouteSwitch
                        disabled={
                          <ContractsRouteSwitch
                            enabled={<ContractCustomerPlanOverview />}
                          />
                        }
                        enabled={<ContractCustomerPlanOverview />}
                      />
                    ),
                  },
                  {
                    path: "invoices",
                    children: [
                      {
                        index: true,
                        element: (
                          <NewUIRouteSwitch
                            disabled={
                              <ContractsRouteSwitch
                                enabled={<ContractCustomerPlanInvoices />}
                              />
                            }
                            enabled={<ContractCustomerPlanInvoices />}
                          />
                        ),
                      },
                      {
                        path: ":invoiceId",
                        children: [
                          {
                            index: true,
                            element: (
                              <NewUIRouteSwitch
                                disabled={
                                  <ContractsRouteSwitch
                                    enabled={<ContractCustomerPlanInvoices />}
                                  />
                                }
                                enabled={<ContractCustomerPlanInvoices />}
                              />
                            ),
                          },
                          {
                            path: "correct",
                            element: (
                              <NewUIRouteSwitch
                                disabled={
                                  <ContractsRouteSwitch
                                    enabled={<CorrectInvoice />}
                                  />
                                }
                                enabled={<CorrectInvoice />}
                              />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "developer",
    children: [
      {
        path: "events",
        element: (
          <NewUIRouteSwitch
            disabled={<Events />}
            enabled={<EnvironmentRedirect to="/connections/events" />}
          />
        ),
      },
      {
        path: "tokens",
        element: (
          <NewUIRouteSwitch
            disabled={<APITokens />}
            enabled={
              <EnvironmentRedirect to="/connections/api-tokens-webhooks" />
            }
          />
        ),
      },
      {
        path: "webhooks",
        element: (
          <NewUIRouteSwitch
            disabled={<Webhooks />}
            enabled={
              <EnvironmentRedirect to="/connections/api-tokens-webhooks" />
            }
          />
        ),
      },
    ],
  },
  {
    path: "contract-pricing",
    element: (
      <NewUIRouteSwitch
        disabled={<ContractsRouteSwitch enabled={<Outlet />} />}
        enabled={<ContractPricingRedirect />}
      />
    ),
    children: [
      {
        path: "rate-cards",
        element: (
          <NewUIRouteSwitch
            disabled={<RateCardsList />}
            enabled={<ContractPricingRedirect />}
          />
        ),
      },
      {
        path: "rate-cards/:id",
        element: (
          <NewUIRouteSwitch
            disabled={<RateCardDetails />}
            enabled={<ContractPricingRedirect />}
          />
        ),
      },
      {
        path: "rate-cards/new",
        element: (
          <NewUIRouteSwitch
            disabled={<RateCardCreate />}
            enabled={<ContractPricingRedirect />}
          />
        ),
      },
      {
        path: "rate-cards/create",
        element: (
          <NewUIRouteSwitch
            disabled={<RateCardCreateV2 />}
            enabled={<ContractPricingRedirect />}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <NewUIRouteSwitch
                disabled={<Step1 />}
                enabled={<ContractPricingRedirect />}
              />
            ),
            index: true,
          },
          {
            path: "dimensional-pricing",
            element: (
              <NewUIRouteSwitch
                disabled={<Step2 />}
                enabled={<ContractPricingRedirect />}
              />
            ),
          },
          {
            path: "rate-products",
            element: (
              <NewUIRouteSwitch
                disabled={<Step3 />}
                enabled={<ContractPricingRedirect />}
              />
            ),
          },
        ],
      },
      {
        path: "rate-cards/:id/edit",
        element: (
          <NewUIRouteSwitch
            disabled={<EditRateCard />}
            enabled={<ContractPricingRedirect />}
          />
        ),
      },
      {
        path: "products",
        children: [
          {
            index: true,
            element: (
              <NewUIRouteSwitch
                disabled={<ContractProductList />}
                enabled={<ContractPricingRedirect />}
              />
            ),
          },
          {
            path: ":id",
            element: (
              <NewUIRouteSwitch
                disabled={<ContractProductList />}
                enabled={<ContractPricingRedirect />}
              />
            ),
          },
          {
            path: "create",
            element: <CreateProduct />,
          },
        ],
      },
    ],
  },
];
