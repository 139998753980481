import React, { useState } from "react";
import { ButtonGroup, ButtonProps } from "tenaissance/components/ButtonGroup";
import { CreditType } from "types/credit-types";
import { Table } from "tenaissance/components/Table";
import { displayCreditTypeName } from "lib/credits";
import { CopyableID } from "tenaissance/components/CopyableID";
import { useSearcher } from "lib/search/useSearcher";
import { EmptyState } from "tenaissance/components/EmptyState";
import { OFFERING_PAGES_TABLE_PAGE_SIZE } from "../../constants";

interface CurrenciesTableProps {
  loading: boolean;
  titleButtons: ButtonProps[];
  currencies: CreditType[];
}

const CurrenciesTable: React.FC<CurrenciesTableProps> = ({
  titleButtons,
  loading,
  currencies,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const searcher = useSearcher(currencies, {
    keys: ["name"],
    threshold: 0.1,
    includeScore: true,
  });
  const searchResults = searcher(searchTerm);

  return (
    <Table
      title={<ButtonGroup buttons={titleButtons} />}
      loading={loading}
      columns={[
        {
          id: "name",
          header: "Name",
          cell: (props) => props.getValue(),
          accessorFn: (ct) => displayCreditTypeName(ct),
          sortingFn: (rowA, rowB) => {
            return displayCreditTypeName(rowA.original).localeCompare(
              displayCreditTypeName(rowB.original),
            );
          },
        },
        {
          id: "id",
          header: "ID",
          cell: (props) => props.getValue(),
          accessorFn: (ct) => <CopyableID id={ct.id} />,
          sortingFn: (rowA, rowB) => {
            return rowA.id.localeCompare(rowB.id);
          },
        },
      ]}
      data={searchResults}
      searchOptions={{ showSearch: true, onSearch: setSearchTerm }}
      paginationOptions={{
        type: "clientSide",
        pageSize: OFFERING_PAGES_TABLE_PAGE_SIZE,
      }}
      emptyState={
        searchTerm ? (
          <EmptyState
            icon="searchSm"
            mainText="No currencies match your query"
          />
        ) : (
          <EmptyState
            icon="currencyDollar"
            mainText="There are no currencies"
          />
        )
      }
    />
  );
};

export default CurrenciesTable;
