import React from "react";
import {
  useBillableMetricPlansQuery,
  useSeatMetricPlansQuery,
  PlanCardDetailsFragment,
} from "./queries.graphql";
import { CardProps, Cards } from "components/Cards";
import { AvatarWithName, Badge, Tooltip } from "design-system";
import { EmptyState } from "tenaissance/components/EmptyState";
import { MetricType } from "lib/billableMetrics/types";
import { CopyableID } from "components/CopyableID";
import { renderDate } from "lib/time";
import { DocsLink } from "tenaissance/components/DocsLink";

type UniquePlans = {
  [key: string]: PlanCardDetailsFragment;
};

const planCardDetails = (plan: PlanCardDetailsFragment): CardProps => {
  return {
    routePath: `/offering/plans/${plan.id}`,
    id: plan.id,
    title: plan.name,
    subtitle: <CopyableID id={plan.id} label="plan ID" />,
    content: plan.description,
    badge:
      plan.deprecated_at !== null ? (
        <Badge theme="warning" type="dark">
          ARCHIVED
        </Badge>
      ) : null,
    statistics: [
      {
        icon: "people",
        value: plan.customer_count ?? 0,
        label: "customer",
      },
      {
        icon: "cart",
        value: plan.PricedProducts_aggregate.aggregate?.count ?? 0,
        label: "product",
      },
    ],
    footer:
      plan.Actor != null ? (
        <Tooltip
          content={
            <>
              Created by {plan.Actor.name}
              <br />
              {renderDate(new Date(plan.created_at), {
                isUtc: false,
              })}
            </>
          }
        >
          <AvatarWithName {...plan.Actor} />
        </Tooltip>
      ) : undefined,
  };
};

const BillableMetricPlans: React.FC<{
  billableMetricId: string;
  metricType: MetricType;
}> = ({ billableMetricId, metricType }) => {
  const { data: billableMetricPlans, loading: billableMetricPlansLoading } =
    useBillableMetricPlansQuery({
      variables: { billable_metric_id: billableMetricId },
      skip: metricType !== "billable",
    });

  const { data: seatMetricPlans, loading: seatMetricPlansLoading } =
    useSeatMetricPlansQuery({
      variables: { seat_metric_id: billableMetricId },
      skip: metricType !== "seat",
    });

  const products =
    billableMetricPlans?.BillableMetric?.products ??
    seatMetricPlans?.seat_metric.products ??
    [];
  const loading = billableMetricPlansLoading || seatMetricPlansLoading;
  const plansById = products.reduce((uniquePlans: UniquePlans, product) => {
    return product.plans.reduce((agg, plan) => {
      agg[plan.id] = plan;
      return agg;
    }, uniquePlans);
  }, {});
  const plans = Object.values(plansById);
  const cards = plans.map((plan) => planCardDetails(plan));

  if (!loading && plans.length === 0) {
    return (
      <EmptyState
        icon="file05"
        mainText="This billable metric has no active plans"
        actions={[<DocsLink plansPath="pricing-and-packaging" />]}
      />
    );
  }
  return <Cards cards={cards} loading={loading} />;
};

export default BillableMetricPlans;
