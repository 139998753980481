import React from "react";
import "/src/tenaissance/tenaissance.css";
import { twMerge } from "tenaissance/twMerge";
import { Icon } from "../Icon";
import { type ColorsWithBG } from "tenaissance/colors";

const SIZE_VARIANTS = {
  sm: "h-3xl py-[3px] px-md text-xs",
  md: "h-3xl py-xxs px-[9px] text-sm",
  lg: "h-[28px] py-xs px-[10px] text-sm",
};

const CLOSE_SIZE_VARIANTS = {
  sm: "h-[14px] w-[14px]",
  md: "h-xl w-xl",
  lg: "h-2xl w-2xl",
};

const PADDING_VARIANTS = {
  sm: "pr-[3px]",
  md: "pr-[3px]",
  lg: "pr-xs",
};

export type TagProps = {
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Default: bg-core-jade-green - select the color for the dot */
  dotColor?: ColorsWithBG;
  /** Label string for the Tag */
  label: string;
  /** Add a leading green dot next to the label */
  showDot?: boolean;
  /** Default: md - sm: 14px, md: 16px, lg: 20px */
  size?: "sm" | "md" | "lg";
  /**  Will render a trailing close button to remove/close this tag */
  onClose?: () => void;
};

/**
 * Tags (also known as “chips”) are compact elements that allow users to enter information,
 * make selections, filter content, or trigger actions. They’re similar to badges, but are
 * more commonly used in inputs to allow users to select multiple items at once.
 *  */
export const Tag: React.FC<TagProps> = ({
  className,
  dotColor = "bg-core-jade-green",
  label,
  showDot,
  size = "md",
  onClose,
}) => {
  const classnames = twMerge(
    "inline-flex items-center border border-gray-200 bg-white rounded-sm text-md group",
    SIZE_VARIANTS[size],
    !!onClose && PADDING_VARIANTS[size],
    className,
  );

  return (
    <div
      className={classnames}
      onClick={onClose}
      role={!!onClose ? "button" : "status"}
    >
      {showDot && (
        <div
          className={twMerge(
            "h-sm w-sm rounded-full ml-[-1px]",
            dotColor,
            size === "sm" ? "mr-sm" : "mr-md",
          )}
        />
      )}
      {label}
      {onClose && (
        <Icon
          className={twMerge(
            "p-xxs ml-[3px] rounded-[3px] group-hover:bg-gray-50",
            CLOSE_SIZE_VARIANTS[size],
          )}
          icon="xClose"
        />
      )}
    </div>
  );
};
