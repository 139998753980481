import React from "react";
import { Copy } from "tenaissance/components/Copy";
import { Button } from "tenaissance/components/Button";
import { twMerge } from "tenaissance/twMerge";
import { Tooltip } from "tenaissance/components/Tooltip";

type CopyableIDProps = {
  id: string;
  className?: string;
  label?: string;
  hideID?: boolean;
};

export const CopyableID: React.FC<CopyableIDProps> = ({
  id,
  className,
  label,
  hideID,
}) => {
  const tooltipLabel = label ? `Copy ${label}` : "Copy value";

  const copyButton = (
    <Button
      text={hideID ? "" : id}
      leadingIcon="copy01"
      theme="linkGray"
      className={twMerge("font-normal", className)}
    />
  );

  return label ? (
    <Tooltip label={tooltipLabel}>
      <Copy text={id}>{copyButton}</Copy>
    </Tooltip>
  ) : (
    <Copy text={id}>{copyButton}</Copy>
  );
};
