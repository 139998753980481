import React from "react";
import { withErrorBoundary } from "@sentry/react";
import { TextSkeleton } from "../../../../components/Skeleton";
import {
  CustomerTotalBillingsQuery,
  useCustomerTotalBillingsQuery,
} from "../data.graphql";
import { RoundedCurrency } from "../../../../lib/credits";
import Decimal from "decimal.js";
import { reportToSentry } from "../../../../lib/errors/sentry";

const Fallback = () => <>--</>;

export const CustomerLifetimeBillingsCell: React.FC<{ customerId: string }> =
  withErrorBoundary(
    ({ customerId }) => {
      const { data, loading, error } = useCustomerTotalBillingsQuery({
        variables: { customer_id: customerId },
      });

      if (loading) {
        return <TextSkeleton />;
      }

      const totalBillingsToDisplay = getTotalBillingsToDisplay(data);

      if (error || !totalBillingsToDisplay) {
        return <Fallback />;
      }

      return (
        <RoundedCurrency
          amount={new Decimal(totalBillingsToDisplay.amount)}
          creditType={totalBillingsToDisplay.credit_type}
        />
      );
    },
    {
      fallback: <Fallback />,
      onError: (e) => {
        reportToSentry(e);
      },
    },
  );

export function getTotalBillingsToDisplay(
  data: CustomerTotalBillingsQuery | undefined,
) {
  // Customers can have multiple "total billings" for each credit type, we're assuming that the
  // most useful one to display is the one with the highest amount. We also are filtering out non-fiat
  // credit types by checking that the credit type has no client_id since invoice totals are always in
  // fiat currencies.
  return data?.Customer_by_pk?.revenue_overview.total_billings.results
    .filter((r) => !r.credit_type.client_id)
    .sort((a, b) => Number(b.amount) - Number(a.amount))[0];
}
