import React from "react";
import {
  BaseIntegrationCard,
  IntegrationCardProps,
} from "./BaseIntegrationCard";
import { ReactComponent as MicrosoftLogo } from "tenaissance/icons/microsoft.svg";

export type AzureIntegrationCardProps = Omit<
  IntegrationCardProps,
  "title" | "subheader" | "description" | "logo"
>;

export const AzureIntegrationCard = (
  propOverrides: AzureIntegrationCardProps,
) => {
  return (
    <BaseIntegrationCard
      title="Azure Marketplace"
      subheader="Marketplace billing provider"
      description="The Azure marketplace enables qualified partners to market and sell their software to Azure customers."
      {...propOverrides}
      logo={<MicrosoftLogo />}
    />
  );
};
