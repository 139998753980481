import React, { useState } from "react";
import { InputSection } from "components/Wizard";
import { CreditInput } from "components/Input";
import { Decimal } from "decimal.js";
import { Subtitle } from "design-system";
import { Button } from "tenaissance/components/Button";
import { DaysInput } from "design-system";
import {
  creditConversionString,
  displayCreditTypeName,
  RoundedCurrency,
} from "lib/credits";
import {
  CreditTypeConversion,
  TrialSpec,
  TrialSpecSpendingCap,
} from "lib/plans/types";
import { Toggle } from "design-system";
import { CreditAmount, CreditType } from "types/credit-types";
import { PricingUnitSelector } from "components/PricingUnitSelector";

interface TrialSpecSpendingCapInputProps {
  trialSpendingCap?: TrialSpecSpendingCap;
  creditTypes: CreditType[];
  disabled?: boolean;
  conversions?: CreditTypeConversion[];
  addTrialCap: () => void;
  setTrialSpecSpendingCaps: (
    caps: TrialSpecSpendingCap[] | undefined | null,
  ) => void;
  removeTrialSpendingCap: () => void;
  spentAmount?: CreditAmount;
  allowPricingUnitCreation?: boolean;
}

export const TrialSpecSpendingCapInputSection: React.FC<
  TrialSpecSpendingCapInputProps
> = (props) => {
  const [edited, setEdited] = useState(false);
  const capCreditType = props.creditTypes.find(
    (ct) => ct.id === props.trialSpendingCap?.creditTypeId,
  );
  // Note that this only picks the first conversion rate (doesn't display any additional data
  // if the conversion rates are ramped)
  const capConversion =
    capCreditType && capCreditType.client_id
      ? props.conversions?.find(
          (conversion) => conversion.customCreditType.id === capCreditType.id,
        )
      : undefined;
  return (
    <InputSection
      header="Trial price cap (optional)"
      subtitle="If the trial has a cap on how much a customer can receive for free before they’ll have to pay, assign that cap here."
    >
      {props.trialSpendingCap ? (
        <div className="mt-8 flex flex-col items-baseline gap-8">
          {!edited &&
          props.spentAmount &&
          capCreditType?.id === props.spentAmount.credit_type.id ? (
            <Subtitle level={4} className="mx-8 my-0">
              <RoundedCurrency
                amount={new Decimal(props.spentAmount.amount)}
                creditType={props.spentAmount.credit_type}
              />{" "}
              consumed{" "}
              {props.trialSpendingCap.amount !== undefined ? (
                <>
                  (
                  <RoundedCurrency
                    amount={new Decimal(props.trialSpendingCap.amount).sub(
                      props.spentAmount.amount,
                    )}
                    creditType={capCreditType}
                  />{" "}
                  remaining)
                </>
              ) : (
                ""
              )}
            </Subtitle>
          ) : null}
          <div className="w-[208px]">
            <PricingUnitSelector
              onChange={(ct) => {
                setEdited(true);
                props.setTrialSpecSpendingCaps([
                  { creditTypeId: ct.id, amount: undefined },
                ]);
              }}
              selectedCreditTypeId={capCreditType?.id}
              disabled={props.disabled}
              allowCreation={props.allowPricingUnitCreation}
            />
          </div>

          {capCreditType && (
            <CreditInput
              placeholder={`123.45 ${displayCreditTypeName(capCreditType)}`}
              creditType={capCreditType}
              className="w-[208px]"
              initialValue={props.trialSpendingCap?.amount?.toString()}
              onChange={(credits) => {
                setEdited(true);
                props.setTrialSpecSpendingCaps([
                  {
                    creditTypeId: props.trialSpendingCap?.creditTypeId,
                    amount: credits === null ? undefined : new Decimal(credits),
                  },
                ]);
              }}
              disabled={props.disabled}
            />
          )}
          {capCreditType?.client_id && (
            <Subtitle level={4} className="mx-8 my-0">
              {capConversion && capConversion.toFiatConversionFactor
                ? creditConversionString(capConversion)
                : "Overage rates for your pricing unit are applied in the last step."}
            </Subtitle>
          )}
          <Button
            onClick={() => {
              setEdited(true);
              props.removeTrialSpendingCap();
            }}
            disabled={props.disabled}
            text="Remove pricing cap"
            theme="secondary"
          />
        </div>
      ) : (
        <div className="mt-8 flex items-center">
          <Button
            onClick={() => {
              setEdited(true);
              props.addTrialCap();
            }}
            disabled={props.disabled}
            theme="secondary"
            leadingIcon="plus"
            text="Add trial cap"
          />
        </div>
      )}
    </InputSection>
  );
};

interface TrialSpecInputProps {
  trialSpec: TrialSpec | null | undefined;
  creditTypes: CreditType[];
  disabled?: boolean;
  conversions?: CreditTypeConversion[];
  setTrialSpec: (trialSpec: TrialSpec | undefined | null) => void;
  showTrialToggle: boolean;
  allowPricingUnitCreation?: boolean;
}

export const TrialSpecInputSection: React.FC<TrialSpecInputProps> = (props) => {
  return (
    <>
      <InputSection
        header="Set a length for this trial"
        subtitle="Metronome will generate one invoice at the end of the trial. After the trial, normal plan pricing will begin."
      >
        {props.showTrialToggle && (
          <Toggle
            checked={!!props.trialSpec}
            label="Enable trial period"
            onChange={(checked) => props.setTrialSpec(checked ? {} : null)}
          />
        )}
        <DaysInput
          className="w-[208px]"
          disabled={props.disabled}
          value={props.trialSpec?.length}
          onChange={(v) => {
            props.setTrialSpec({
              ...props.trialSpec,
              length: v,
            });
          }}
        />
      </InputSection>
      <TrialSpecSpendingCapInputSection
        trialSpendingCap={
          props.trialSpec?.caps?.length ? props.trialSpec.caps[0] : undefined
        }
        creditTypes={props.creditTypes}
        disabled={props.disabled}
        conversions={props.conversions}
        addTrialCap={() =>
          props.setTrialSpec({
            ...props.trialSpec,
            caps: [{}],
          })
        }
        setTrialSpecSpendingCaps={(caps) => {
          if (caps !== null) {
            props.setTrialSpec({
              ...props.trialSpec,
              caps: caps,
            });
          }
        }}
        removeTrialSpendingCap={() =>
          props.setTrialSpec({
            ...props.trialSpec,
            caps: [],
          })
        }
        allowPricingUnitCreation={props.allowPricingUnitCreation}
      />
    </>
  );
};
