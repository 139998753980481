import React from "react";
import { Icon, IconName, Themes } from "design-system";
import classnames from "classnames";
import styles from "./index.module.less";

interface ListRowProps {
  icon?: IconName;
  iconColor?: Themes;
  title: React.ReactNode;
  badge?: React.ReactNode;
  secondaryTitle?: React.ReactNode;
  subtitle?: React.ReactNode;
  detail?: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
}

export const ListRow: React.FC<ListRowProps> = ({
  selected,
  onClick,
  title,
  subtitle,
  badge,
  detail,
  secondaryTitle,
  icon,
  iconColor = "primary",
}) => {
  return (
    <div
      className={classnames(styles.listRow, {
        [styles.selected]: selected,
        [styles.clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      <div>
        <div className={styles.header}>
          <div className={styles.listTitle}>
            {icon ? (
              <Icon
                icon={icon}
                className={classnames(styles.icon, styles[iconColor])}
              />
            ) : null}
            {title}
            {badge && <div className={styles.listBadge}>{badge}</div>}
          </div>
          {secondaryTitle ?? null}
        </div>
        <div>{subtitle ?? null}</div>
        {detail && <div className={styles.metadataRow}>{detail}</div>}
      </div>
    </div>
  );
};
