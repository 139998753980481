import { Body, DateInput, Input, Select, Subtitle } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import React, { useCallback, useEffect, useState } from "react";
import { Section } from "pages/Contracts/Customer/Contracts/Create/components/Section";

import {
  useCreditTypeConversionController,
  type RootFormController,
  type useRateCardCreateController,
} from "./RateCardCreate";
import { dayjs } from "lib/dayjs";
import {
  USD_CREDIT_ID,
  USD_CREDIT_TYPE,
  displayCreditTypeName,
  displayCreditsInCurrencyWithoutRounding,
} from "lib/credits";
import { CreditType } from "types/credit-types";
import { CreditInput } from "components/Input";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useFeatureFlag } from "lib/launchdarkly";
import { TablePanel } from "components/TablePanel";
import Decimal from "decimal.js";

interface Props {
  ctrl: RootFormController;
  isEdit?: boolean;
  fiatCreditTypes?: CreditType[];
  customCreditTypes: CreditType[];
  creditTypesLoading?: boolean;
  creditTypesError?: Error;
}

interface Alias {
  name: string;
  startingAt?: string;
  endingBefore?: string;
}

export const CreditTypeConversionInput: React.FC<{
  index: number;
  parent: ReturnType<typeof useRateCardCreateController>;
  fiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  removeCreditTypeConversion: () => void;
}> = ({
  index,
  parent,
  fiatCreditType,
  customCreditTypes,
  removeCreditTypeConversion,
}) => {
  const creditTypeConversionCtrl = useCreditTypeConversionController(
    parent,
    index,
  );
  const selectedCreditType = customCreditTypes.find(
    (ct) => ct.id === creditTypeConversionCtrl.get("custom_credit_type_id"),
  );
  useEffect(() => {
    creditTypeConversionCtrl.update({
      ...creditTypeConversionCtrl.getUnvalidatedInputs(),
      custom_credit_type_name: selectedCreditType?.name,
    });
  }, [selectedCreditType]);

  return (
    <div key={index} className="grid grid-cols-4 items-center gap-12 pb-12">
      <Select
        {...creditTypeConversionCtrl.props.Select("custom_credit_type_id", {
          name: "Custom pricing unit",
          placeholder: "Select pricing unit",
          options: customCreditTypes.map((ct) => ({
            label: displayCreditTypeName(ct),
            value: ct.id,
          })),
        })}
      />
      <CreditInput
        {...creditTypeConversionCtrl.props.CreditInput(
          "fiat_per_custom_credit",
          {
            creditType: fiatCreditType,
            name: "Conversion",
            placeholder: "123.45",
            allowZeroAmounts: true,
            tooltip:
              "The conversion rate will be used when the customer does not have sufficient credits or prepaid commits in the custom pricing unit to cover their consumption.",
          },
        )}
      />
      <div className="flex items-center">
        <Subtitle
          level={4}
          className="mr-[64px] mt-[16px] font-normal text-grey-400"
        >
          {`per ${selectedCreditType ? selectedCreditType.name : "1 custom unit"}`}
        </Subtitle>
        <IconButton
          className="-ml-[10px] mt-[16px]"
          onClick={removeCreditTypeConversion}
          theme="tertiary"
          icon="xCircle"
        />
      </div>
    </div>
  );
};

export const RateCardDetailsSection: React.FC<Props> = ({
  ctrl,
  isEdit,
  fiatCreditTypes,
  customCreditTypes,
  creditTypesLoading,
  creditTypesError,
}) => {
  const [aliases, setAliases] = useState<Alias[]>(ctrl.get("aliases") || []);
  const addAlias = () => {
    setAliases([
      ...aliases,
      { name: "", startingAt: undefined, endingBefore: undefined },
    ]);
  };
  const updateAliasState = (updatedAliases: Alias[]) => {
    setAliases(updatedAliases);
    ctrl.update({ aliases: updatedAliases });
  };
  const updateAlias = (index: number, key: string, value: string | Date) => {
    let updatedValue = value;
    if (key === "startingAt" || key === "endingBefore") {
      if (value instanceof Date) {
        // Set the time to UTC midnight and format as an ISO string
        updatedValue = dayjs(value).utc().startOf("day").toISOString();
      }
    }
    const updatedAliases = aliases.map((alias, idx) =>
      idx === index ? { ...alias, [key]: updatedValue } : alias,
    );
    updateAliasState(updatedAliases);
  };
  const removeAlias = (index: number) => {
    const updatedAliases = aliases.filter((_, idx) => idx !== index);
    updateAliasState(updatedAliases);
  };

  const showCPUWork = useFeatureFlag<boolean>("contract-cpus", false);
  const showCurrencyWork = useFeatureFlag<boolean>(
    "contract-currencies",
    false,
  );
  if (!showCurrencyWork && !isEdit && !ctrl.get("fiatCreditTypeId")) {
    ctrl.update({
      fiatCreditTypeId: USD_CREDIT_ID,
    });
  }
  const creditTypeConversions = ctrl.get("creditTypeConversions") ?? [];
  const addCreditTypeConversion = useCallback(() => {
    const newCreditTypeConversions = [
      ...creditTypeConversions,
      {
        custom_credit_type_id: "",
        custom_credit_type_name: "",
        fiat_per_credit_type: undefined,
      },
    ];
    ctrl.update({ creditTypeConversions: newCreditTypeConversions });
  }, [ctrl, creditTypeConversions]);
  const removeCreditTypeConversion = (index: number) => {
    const updatedCreditTypeConversions = creditTypeConversions.filter(
      (_, idx) => idx !== index,
    );
    ctrl.update({ creditTypeConversions: updatedCreditTypeConversions });
  };

  const fiatCreditType = {
    id: ctrl.get("fiatCreditTypeId") ?? USD_CREDIT_TYPE.id,
    name: ctrl.get("fiatCreditTypeName") ?? USD_CREDIT_TYPE.name,
    client_id: null,
    environment_type: null,
  };

  return (
    <>
      <Section
        title="Rate card details"
        className="flex w-3/4 flex-col gap-24 pt-12"
      >
        <div className="grid grid-cols-4 gap-12">
          <Input
            {...ctrl.props.Input("name", {
              placeholder: "Enter name",
              name: "Rate card name",
            })}
          />
          {showCurrencyWork || isEdit ? (
            <Select
              {...ctrl.props.Select("fiatCreditTypeId", {
                name: "Currency",
                placeholder: "Choose currency",
                disabled: isEdit,
                options:
                  isEdit || !fiatCreditTypes
                    ? [
                        {
                          label: fiatCreditType.name,
                          value: fiatCreditType.id,
                        },
                      ]
                    : fiatCreditTypes.map((ct) => ({
                        label: displayCreditTypeName(ct),
                        value: ct.id,
                      })),
                tooltip: isEdit
                  ? "Once selected, currency may not be changed"
                  : "",
              })}
            />
          ) : (
            ""
          )}
          <Input
            {...ctrl.props.Input("description", {
              placeholder: "Enter description",
              name: "Rate card description (optional)",
              className: "col-span-4",
            })}
          />
        </div>
        <div>
          <Subtitle>Aliases (optional)</Subtitle>
          <Body level={2}>
            Reference a rate card via an alias. This alias can be used when
            provisioning a customer to a contract.
          </Body>
          {aliases.length === 0 && (
            <Button
              onClick={addAlias}
              text="Add alias"
              theme="secondary"
              leadingIcon="plus"
            />
          )}
          {aliases.map((alias, index) => (
            <div key={index} className="grid grid-cols-4 gap-12 pb-12">
              <Input
                value={alias.name}
                name="Alias name"
                onChange={(e) => updateAlias(index, "name", e)}
                placeholder="Enter alias name"
              />
              <DateInput
                isUTC
                name="Starting at (optional)"
                value={
                  alias.startingAt
                    ? dayjs.utc(alias.startingAt).toDate()
                    : undefined
                }
                onChange={(e) => updateAlias(index, "startingAt", e)}
                placeholder="Select"
              />
              <DateInput
                isUTC
                name="Ending before (optional)"
                value={
                  alias.endingBefore
                    ? dayjs.utc(alias.endingBefore).toDate()
                    : undefined
                }
                onChange={(e) => updateAlias(index, "endingBefore", e)}
                placeholder="Select"
              />
              <IconButton
                className="-ml-[10px] mt-[16px]"
                onClick={() => removeAlias(index)}
                theme="tertiary"
                icon="xCircle"
              />
            </div>
          ))}
          {aliases.length > 0 && (
            <Button
              onClick={addAlias}
              className="mt-4"
              text="Add another alias"
              theme="secondary"
              leadingIcon="plus"
            />
          )}
        </div>
        {showCPUWork && !isEdit && customCreditTypes.length > 0 && (
          <div>
            <Subtitle>Custom pricing units (optional)</Subtitle>
            <Body level={2}>
              Use custom pricing for your product offerings. You can use as many
              of these as you want.
            </Body>
            {creditTypesError ? (
              <ErrorEmptyState
                title="Failed to fetch pricing units"
                error={creditTypesError}
              />
            ) : (
              <>
                {creditTypeConversions.length === 0 && (
                  <Button
                    onClick={addCreditTypeConversion}
                    disabled={
                      creditTypesLoading || !ctrl.get("fiatCreditTypeId")
                    }
                    loading={creditTypesLoading}
                    text="Add custom pricing unit"
                    theme="secondary"
                    leadingIcon="plus"
                  />
                )}
                {creditTypeConversions.map((_, index) => (
                  <CreditTypeConversionInput
                    key={index}
                    index={index}
                    parent={ctrl}
                    fiatCreditType={fiatCreditType}
                    customCreditTypes={customCreditTypes}
                    removeCreditTypeConversion={() =>
                      removeCreditTypeConversion(index)
                    }
                  />
                ))}
                {creditTypeConversions.length > 0 && (
                  <Button
                    onClick={addCreditTypeConversion}
                    className="mt-4"
                    text="Add another custom pricing unit"
                    theme="secondary"
                    leadingIcon="plus"
                  />
                )}
              </>
            )}
          </div>
        )}
      </Section>
      {isEdit && creditTypeConversions.length ? (
        <Section title="Custom pricing units">
          <TablePanel
            title="Custom pricing units"
            data={creditTypeConversions}
            columns={[
              {
                id: "pricingUnit",
                header: "Name",
                cellClassName: "w-[250px]",
                textWrappable: true,
                render: (ctc) => ctc.custom_credit_type_name,
              },
              {
                id: "conversion",
                header: "Conversion",
                textWrappable: true,
                align: "right",
                render: (ctc) => (
                  <div className="flex flex-col">
                    {displayCreditsInCurrencyWithoutRounding(
                      new Decimal(ctc.fiat_per_custom_credit),
                      fiatCreditType,
                    )}
                  </div>
                ),
              },
            ]}
          />
        </Section>
      ) : (
        ""
      )}
    </>
  );
};
