import React from "react";
import { Toggle } from "design-system";

import { useLocalContractSettings } from "./LocalContractSettings";

export const ContractsEnabledSwitch: React.FC = () => {
  const settings = useLocalContractSettings();

  if (!settings.canToggle) {
    return null;
  }

  return (
    <div className="p-12 pb-0">
      <Toggle
        checked={settings.enabled}
        label="Enable contracts"
        theme="grey"
        onChange={(val) => {
          settings.setEnabled(val);
        }}
      />
    </div>
  );
};
