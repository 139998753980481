import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import "style/index.css";
import { twMerge } from "tenaissance/twMerge";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender,
  SortingState,
  type SortDirection,
  type SortingFn,
  type Row,
  type Column as TanStackColumn,
  type OnChangeFn,
  DeepKeys,
} from "@tanstack/react-table";
import { Icon, IconName } from "tenaissance/components/Icon";
import { Tooltip } from "tenaissance/components/Tooltip";
import useDebounce from "lib/debounce";
import { Button } from "tenaissance/components/Button";
import Papa from "papaparse";
import { EmptyState } from "../EmptyState";
import { InternalLink } from "components/Typography";
import { ConditionalWrapper } from "lib/conditionalWrapper";

const ELLIPSIS_PAGE_NUM = -1;

/**
 * Vanity Icons are optional for overviews with only a few rows
 */
const VANITY_ICONS: IconName[] = [
  "building01",
  "building02",
  "building03",
  "building04",
  "building05",
  "building06",
  "building07",
  "building08",
];
const VANITY_GRADIENTS: string[] = [
  "from-core-slate to-core-dark-green bg-gradient-to-tr text-white",
  "from-core-dark-green to-core-jade-green bg-gradient-to-r text-white",
  "from-core-mint to-core-jade-green bg-gradient-to-tr text-gray-950",
  "from-core-blue-mint to-core-hot-green bg-gradient-to-tr text-gray-950",
];

function getInnerHeight(element: HTMLElement) {
  const computedStyle = getComputedStyle(element);
  const paddingTop = parseFloat(computedStyle.paddingTop);
  const paddingBottom = parseFloat(computedStyle.paddingBottom);
  return element.clientHeight - paddingTop - paddingBottom;
}

interface SortIconProps {
  dir: SortDirection | false;
}

const SortIcon: React.FC<SortIconProps> = ({ dir }) => {
  const icon = dir === "asc" ? "arrowUp" : "arrowDown";
  return <Icon className="ml-xs" icon={icon} size={14} />;
};

interface LoadingStateProps {
  columnCount: number;
  rowCount: number;
}
const LoadingState: React.FC<LoadingStateProps> = ({
  columnCount,
  rowCount,
}) => {
  return (
    <>
      {new Array(rowCount).fill(0).map((_, i) => {
        return (
          <tr key={i} className="animate-pulse">
            {new Array(columnCount).fill(0).map((_, j) => {
              return (
                <td key={j}>
                  <div className="flex px-3xl py-xl">
                    <div className="my-sm h-lg w-full max-w-[500px] rounded-sm bg-gray-200" />
                  </div>
                </td>
              );
            })}
            <td />
          </tr>
        );
      })}
    </>
  );
};

const calculatePagesToShow = (
  currentPage: number,
  totalPages: number,
): number[] => {
  if (totalPages < 7) {
    return Array.from(Array(totalPages), (_, i) => i);
  }

  if ([0, 1, totalPages - 2, totalPages - 1].includes(currentPage)) {
    return [
      0,
      1,
      2,
      ELLIPSIS_PAGE_NUM,
      totalPages - 3,
      totalPages - 2,
      totalPages - 1,
    ];
  }

  if (currentPage === 2) {
    return [0, 1, 2, 3, ELLIPSIS_PAGE_NUM, totalPages - 2, totalPages - 1];
  }

  if (currentPage === totalPages - 3) {
    return [
      0,
      ELLIPSIS_PAGE_NUM,
      totalPages - 4,
      totalPages - 3,
      totalPages - 2,
      totalPages - 1,
    ];
  }

  const pagesToShow = [0, ELLIPSIS_PAGE_NUM];

  if (currentPage !== 1 && currentPage !== totalPages - 1) {
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(currentPage + 1, totalPages);
      i++
    ) {
      pagesToShow.push(i);
    }
  }

  pagesToShow.push(ELLIPSIS_PAGE_NUM, totalPages - 1);

  return pagesToShow;
};

const PageIndexNavigation: React.FC<{
  pageIndexNavigationOptions: pageIndexNavigationOptions;
}> = ({ pageIndexNavigationOptions }) => {
  const { currentPage, tablePageCount, setPageIndex } =
    pageIndexNavigationOptions;
  return (
    <div>
      {calculatePagesToShow(currentPage, tablePageCount).map((p, idx) => {
        if (p === ELLIPSIS_PAGE_NUM) {
          return (
            <div key={idx} className="mx-sm inline-flex">
              ...
            </div>
          );
        }
        return (
          <Button
            text={`${p + 1}`}
            theme="tertiary"
            key={idx}
            disabled={currentPage === p}
            onClick={() => setPageIndex(p)}
            className={currentPage === p ? "bg-gray-50" : undefined}
          />
        );
      })}
    </div>
  );
};

const PaginationButtons: React.FC<{ paginationOptions: PaginationOptions }> = ({
  paginationOptions,
}) => {
  const { type } = paginationOptions;

  if (type === "loadMore") {
    const { loadMoreButton } = paginationOptions;
    return (
      <Button
        text="Load More"
        theme="secondary"
        disabled={loadMoreButton.disabled}
        onClick={loadMoreButton.onClick}
      />
    );
  }
  if (type === "prevNext") {
    const { paginationButtons, pageIndexNavigationOptions } = paginationOptions;
    const prevButton = paginationButtons.find(
      (button) => button.page === "prev",
    );
    const nextButton = paginationButtons.find(
      (button) => button.page === "next",
    );
    let indexNavigation;
    if (
      pageIndexNavigationOptions !== undefined &&
      pageIndexNavigationOptions.tablePageCount > 1
    ) {
      indexNavigation = (
        <PageIndexNavigation
          pageIndexNavigationOptions={pageIndexNavigationOptions}
        />
      );
    }

    return (
      <>
        {prevButton && (
          <Button
            key="paginationPrevButton"
            text="Previous"
            leadingIcon="arrowLeft"
            theme="secondary"
            disabled={prevButton.disabled}
            onClick={prevButton.onClick}
          />
        )}
        {indexNavigation}
        {nextButton && (
          <Button
            key="paginationNextButton"
            text="Next"
            trailingIcon="arrowRight"
            theme="secondary"
            disabled={nextButton.disabled}
            onClick={nextButton.onClick}
          />
        )}
      </>
    );
  }
  return null;
};

type CellProps<T> = {
  /**
   * Typically the primitive value (number, string, boolean) that will be rendered to the table cell
   */
  getValue: () => any;

  row: Row<T>;
};

type AccessorKeyColumn<T> = {
  /**
   * The key of the row object to use when extracting the value for the column. The resulting
   * value should be a primitive to support built-in sorting.
   * (i.e. "starting_at" for a Date value or "rate_card.name" for the string value in an object)
   * */
  accessorKey: DeepKeys<T>;
};

type AccessorFnColumn<T> = {
  /**
   *  The function to use when extracting the value for the column, given the row is a deeply nested
   *  object or array. The returning value should be a primitive to support built-in sorting.
   * (i.e. `accessorFn: ({ rate_card }) => rate_card?.name ?? ""` which handles undefined | null cases )
   * */
  accessorFn: (row: T) => any;
};

type BaseColumn<T> = {
  /** A unique identifier for the column */
  id: string;
  /**
   * A display column is used for things like row actions (edit, delete) as well as rendering related information.
   *  As such, these will not have filtering or sorting on it.
   * */
  isDisplay?: boolean;
  /**
   * A rendering function for the cell of a column, often as `cell: (props) => props.getValue()`.
   * This should result in a string or ReactElement.
   * */
  cell: (props: CellProps<T>) => React.ReactNode;
  /** Header for the Column, rendered at the top */
  header?: (() => React.ReactNode) | string;
  /**
   *  Default: undefined - If the column requires some context, the supplied tooltipContent will
   *  surface next to the Column header as a help icon. Hovering over the icon will render the tooltip.
   * */
  tooltipContent?: string;
  /** Default: true - Whether the column should be sortable or not */
  enableSorting?: boolean;
  /**
   *  Provide a custom sorting function for the column, if it is a special property or if you want to sort on
   *  a nested object or array.
   * */
  sortingFn?: SortingFn<T>;
  /** Default: text - Lead text style applies a heavier font weight and darker text color */
  textStyle?: "text" | "leadText";
  /** Add a second, de-emphasized line of text underneath the main text */
  supportingText?: React.ReactNode | ((rowData: T) => React.ReactNode);
  /**
   *  Vanity icon will prepend a random icon to a cell using leadText style. These are optional,
   *  and are often used just for overviews with few rows.
   * */
  showVanityIcon?: boolean;
  /** If provided, the column will apply a width in px to the header specified. */
  size?: number;
  /** If provided, the column will add this to its classes */
  cellClassName?: string | ((rowData: T) => string);
  /** If provided, the header column will add this to its classes */
  headerClassName?: string;
  /** Justify the contents in the cell. */
  align?: "right" | "left";
};

type DisplayColumn<T> = BaseColumn<T> & {
  /**
   * A display column is used for things like row actions (edit, delete) as well as rendering related information.
   *  As such, these will not have filtering or sorting on it.
   * */
  isDisplay: true;
};

export type DataColumn<T> = BaseColumn<T> &
  (AccessorKeyColumn<T> | AccessorFnColumn<T>);

export type Column<T> = DisplayColumn<T> | DataColumn<T>;

export interface ObjectWithId {
  id: string;
}

type Page = "prev" | "next";

type PrevNextPaginationOptions = {
  /**
   * For pagination that is fetched based on a number of rows per page. Dev should provide both `prev` and `next`
   * button logic for better UX.
   */
  type: "prevNext";
  paginationButtons: {
    /** Submit whether this is the `prev` or `next` page.*/
    page: Page;
    /** Disable the button if the user is on the current page, or if there is no data to be fetched in that direction. */
    disabled?: boolean;
    /** Whether the page of data the user is on is the current page */
    selected?: boolean;
    onClick: () => void;
  }[];
  pageSize?: never;
  /** Callback for when the number of rows per page is changed, usually in response to the screen size changing
   * If you don't provide this, the page size will be calculated based on the length of `data`.
   */
  onPageSizeChange?: (PageSize: number) => void;
  /** Minimum page size to show.  pageSize can be updated based on screen real estate while this
   * will ensure that the page size is never below this value.
   */
  minimumPageSize?: number;
  /** Optional params to provide if you want to display page index navigation.   */
  pageIndexNavigationOptions?: pageIndexNavigationOptions;
};

type LoadMorePaginationOptions = {
  /**
   * Provide the user a single button to fetch more data from a query, at a pre-determined row size.
   * Additional data should be expected to be concatenated to the previous data set, so that rows
   * are added to the bottom of the Table.
   */
  type: "loadMore";
  loadMoreButton: {
    /** Fire off an additional fetch of data */
    onClick: () => void;
    /** Disable the Load More button if there is no more data to be fetched */
    disabled?: boolean;
  };
  pageSize?: never;
  /** Callback for when the number of rows per page is changed, usually in response to the screen size changing */
  onPageSizeChange?: (PageSize: number) => void;
  /** Minimum page size to show.  pageSize can be updated based on screen real estate while this
   * will ensure that the page size is never below this value.
   */
  minimumPageSize?: number;
};

type ClientSidePaginationOptions = {
  /** Render pagination options at the bottom of the table and turn on basic client-side pagination. */
  type: "clientSide";
  paginationButtons?: never;
  /** Default: 15 - Supply to the table the full number of pages of data to help support client-side pagination */
  pageSize?: number;
  /** Minimum page size to show.  pageSize can be updated based on screen real estate while this
   * will ensure that the page size is never below this value.
   */
  minimumPageSize?: number;
};

type PaginationOptions =
  | LoadMorePaginationOptions
  | PrevNextPaginationOptions
  | ClientSidePaginationOptions;

type pageIndexNavigationOptions = {
  /** The current page, zero-indexed. */
  currentPage: number;
  /** The total number of pages. */
  tablePageCount: number;
  /** The function that updates the page index. */
  setPageIndex: (p: number) => void;
};

export interface TableProps<T extends ObjectWithId> {
  /** Definition of the Columns for the Table, this should be an array of objects */
  columns: Column<T>[];
  /** Data for the Table. Be sure to structure the data as an array of objects, who's keys correspond to an accessorKey in the Column */
  data: T[];
  /** Default sort for the table */
  defaultSort?: SortingState;
  /**
   * An `<EmptyState />` component can be passed to the `emptyState` prop. Use this as an opportunity to customize the case
   * of no data for a new user, or an empty search result for the Table.
   */
  emptyState?: React.ReactElement<typeof EmptyState>;
  /** Boolean value indicating whether the data being supplied is still loading or not */
  loading?: boolean;
  /** Add a clickable option for the table row that leads to an internal page */
  rowRoutePath?: string | ((row: Row<T>) => string | undefined);
  /** If set, when the row is clicked, the route will replace the current history entry */
  rowRoutePathReplace?: boolean;
  /** If set, call this function when the row is clicked.  */
  rowOnClick?: (row: T) => void;
  searchOptions?: {
    /**
     * Render a search bar and turn on basic client-side search, assuming all data is present.
     * If you want to use server-generated queries you must also pass an `onSearch` prop.
     * */
    showSearch?: boolean;
    /**
     * Requires showSearch: true; Pass a search function callback that will give the caller access to the query,
     *  while also using our debounced search input. Be sure that the `loading` prop is passed correctly while
     *  searching to give the user an intermediary state while data is being fetched. */
    onSearch?: (query: string) => void;

    /** A placeholder for the search bar */
    searchPlaceholder?: string;

    /** className for the search input */
    searchClassname?: string;
  };
  /**
   * Turning this on will render an Export button at the top of the table which will take the headers and data
   * and export to a CSV file. To provide a custom version, create a Button and add it to the `topBarActions` prop.
   */
  showExport?: boolean;
  /**
   * Set of options for pagination, with support for the following types:
   *  "loadMore" - single button to fetch more rows of data which should be concatenated to the previous set
   *  "cursor" - provides both previous and next buttons, along with page numbers if supplied
   *  "clientSide" - full set of paged buttons, but must provide pageSize as well.
   */
  paginationOptions?: PaginationOptions;
  /** Title for the table, rendered above the `<thead>` */
  title?: string | React.ReactElement;
  /** Set of options for various table-wide actions (search, filter, export, etc) */
  topBarActions?: React.ReactElement[];
  /** Whether the top bar actions are left or right of the search bar  */
  topBarActionsPosition?: "left" | "right";
  /** Default: true,
   * If set to false, pagination will not be reset to the first page when page-altering state changes
   * e.g. data is updated, filters change, grouping changes, etc.
   */
  autoResetPageIndex?: boolean;
  /**
   * Optionally pin the last column in the table so that it remains visible regardless of the width of the table's parent.
   * This is useful when you have a lot of table columns and want to save some horizontal space, but also show the user
   * that there is more for them to scroll.
   */
  pinLastColumn?: boolean;
  /** Optionally override the table's default sorting behavior. Useful when sorting
   * is handled serverside.
   */
  sortOptions?: {
    sortOrder: SortingState;
    onSortingChange: OnChangeFn<SortingState>;
  };

  /**
   * Optionally pins the row height to 52px. Defaults to true.
   */
  useFixedRowHeight?: boolean;
}

/**
 * Data tables display information in a grid-like format of rows and columns.
 *  They organize information in a way that’s easy to scan so that users can look
 *  for patterns and develop insights from data.
 * */
export function Table<T extends ObjectWithId>({
  columns,
  data: propData,
  emptyState,
  loading,
  rowRoutePath,
  paginationOptions,
  showExport,
  searchOptions: {
    showSearch,
    onSearch,
    searchPlaceholder,
    searchClassname,
  } = {},
  title,
  topBarActions,
  topBarActionsPosition = "right",
  defaultSort,
  autoResetPageIndex = true,
  pinLastColumn,
  rowRoutePathReplace,
  sortOptions,
  useFixedRowHeight = true,
  rowOnClick,
}: TableProps<T>) {
  const {
    type: paginationType,
    pageSize: pageSizeProp,
    minimumPageSize,
  } = paginationOptions || {};

  const parentContainerRef = React.useRef<HTMLDivElement>(null);
  const tableWrapperRef = React.useRef<HTMLDivElement>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);

  const getCommonPinningStyles = (column: TanStackColumn<T>): CSSProperties => {
    const isPinned = column.getIsPinned();
    if (!isPinned || !pinLastColumn) return {};

    const showBoxShadow =
      tableRef.current &&
      tableWrapperRef.current &&
      tableRef.current.getBoundingClientRect().width >
        tableWrapperRef.current.getBoundingClientRect().width;

    return {
      boxShadow: showBoxShadow
        ? "0px 24px 48px -12px rgb(16  24  40 / 0.18)"
        : undefined,
      right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
      position: isPinned ? "sticky" : "relative",
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
      background: "white",
    };
  };

  const DEFAULT_MIN_PAGE_SIZE = 10;

  const [data, setData] = useState(propData);
  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedQuery = useDebounce(searchQuery.trim(), 400);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSizeProp ?? DEFAULT_MIN_PAGE_SIZE,
  });

  const calculateGreedyPageSize = useCallback(() => {
    // currently this doesn't quite work with the prevNext
    if (paginationOptions?.type === "prevNext") {
      // when pagination type is prevNext, we don't need to calculate the page size
      return;
    }
    const tableContainer = parentContainerRef.current;
    if (!tableContainer) {
      return;
    }
    const parentElement = parentContainerRef.current.parentElement;
    if (!parentElement) {
      return;
    }

    const tbody = parentContainerRef.current.querySelector("tbody");
    if (!tbody) {
      return;
    }

    const chromeHeight = tableContainer.clientHeight - tbody.clientHeight;
    const idealTableHeight = getInnerHeight(parentElement) - chromeHeight;

    // magic constant for the row height. If we change the styles we should update this, but we'll calculate it next based on actual row heights
    // so it's just a safe default
    let rowHeight = 71;
    let maxActualHeight = 0;

    tbody.querySelectorAll("tr").forEach((row) => {
      maxActualHeight = Math.max(maxActualHeight, row.clientHeight);
    });
    if (maxActualHeight > 0) {
      rowHeight = maxActualHeight;
    }
    const newPageSize = Math.floor(idealTableHeight / rowHeight);
    return newPageSize;
  }, [parentContainerRef]);

  useEffect(() => {
    // calculated page size is mainly only used for clientside pagination.
    // for pagination type prevNext, this code is only relevant if you provided an onPageSizeChange handler
    const calculatedPageSize =
      // use the page size prop if provided
      pageSizeProp ??
      Math.max(
        // otherwise, get the max of the greedy page size, the minimum page size prop, and the default min page size
        calculateGreedyPageSize() ?? 0,
        minimumPageSize ?? DEFAULT_MIN_PAGE_SIZE,
      );
    setPagination((prev) => ({
      ...prev,
      pageSize: calculatedPageSize,
    }));
    window.addEventListener("resize", () => {
      // when the page is resized, recalculate the page size
      const calculatedPageSize =
        pageSizeProp ??
        Math.max(
          calculateGreedyPageSize() ?? 0,
          minimumPageSize ?? DEFAULT_MIN_PAGE_SIZE,
        );

      setPagination((prev) => ({
        ...prev,
        pageSize: calculatedPageSize,
      }));
    });
    return () => {
      window.removeEventListener("resize", calculateGreedyPageSize);
    };
  }, [setPagination, calculateGreedyPageSize, loading, data]);

  useEffect(() => {
    if (paginationOptions && paginationOptions.type !== "clientSide") {
      paginationOptions.onPageSizeChange?.(pagination.pageSize);
    }
  }, [pagination.pageSize, paginationOptions]);

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedQuery);
    }
  }, [debouncedQuery, onSearch]);

  useEffect(() => {
    setData(propData);
  }, [propData]);

  const exportCSV = useCallback(
    (rows: Row<T>[]) => {
      const rowData = rows.map((row) => {
        return row
          .getVisibleCells()
          .map((cell) => cell.renderValue()?.toString());
      });
      const csv = Papa.unparse([columns.map((c) => c.header), ...rowData], {
        header: true, // Specify that the first row should be treated as headers
      });

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "table_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [title],
  );

  const showTopBar =
    !!title || showExport || showSearch || topBarActions?.length;
  const defaultSize = 50;
  const table = useReactTable({
    defaultColumn: {
      size: defaultSize,
      minSize: defaultSize,
    },
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel:
      paginationType === "clientSide" ? getPaginationRowModel() : undefined,
    onPaginationChange: setPagination,
    manualPagination:
      paginationType === "prevNext" || paginationType === "loadMore",
    autoResetPageIndex,
    onGlobalFilterChange: showSearch && !onSearch ? setSearchQuery : undefined,
    globalFilterFn: "auto",
    getRowId: (originalRow) => {
      return originalRow.id;
    },
    state: {
      pagination,
      globalFilter: onSearch ? undefined : searchQuery,
      columnPinning: pinLastColumn
        ? {
            right: [columns[columns.length - 1].id],
          }
        : {},
      ...(sortOptions !== undefined && {
        sorting: sortOptions.sortOrder,
      }),
    },
    initialState: {
      sorting: defaultSort,
    },
    meta: {
      rowRoutePath: (row) => row.original.rowRoutePath,
    },
    ...(sortOptions !== undefined && {
      manualSorting: true,
      onSortingChange: sortOptions.onSortingChange,
    }),
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const tableClasses = twMerge(
    "border-deprecated-spacing-none table-auto border-separate border border-gray-200 w-full rounded-xl",
    showTopBar && "rounded-t-none",
    !!paginationType && "rounded-b-none",
  );

  const showPagination = !loading && !!paginationType;

  const renderPagination = useCallback(() => {
    if (paginationType === "clientSide") {
      const tablePageCount = table.getPageCount();

      if (tablePageCount > 1) {
        return (
          <div className="flex h-[68px] justify-between rounded-b-xl border border-t-0 border-gray-200 px-3xl pb-xl pt-lg">
            <Button
              text="Previous"
              leadingIcon="arrowLeft"
              theme="secondary"
              disabled={!table.getCanPreviousPage()}
              onClick={table.previousPage}
            />
            <PageIndexNavigation
              pageIndexNavigationOptions={{
                currentPage: pagination.pageIndex,
                tablePageCount: tablePageCount,
                setPageIndex: (p: number) => table.setPageIndex(p),
              }}
            />
            <Button
              text="Next"
              trailingIcon="arrowRight"
              theme="secondary"
              disabled={!table.getCanNextPage()}
              onClick={table.nextPage}
            />
          </div>
        );
      }
      return null;
    } else if (!!paginationOptions) {
      return (
        <div className="flex h-[68px] justify-between rounded-b-xl border border-t-0 border-gray-200 px-3xl pb-xl pt-lg">
          <PaginationButtons paginationOptions={paginationOptions} />
        </div>
      );
    }
  }, [paginationType, pagination]);

  const getColumnWidth = useCallback(
    (colSize: number, index: number) => {
      if (!loading && data.length === 0) {
        return defaultSize;
      } else if (colSize === defaultSize && index === 0) {
        return "auto";
      }
      return colSize;
    },
    [data, loading],
  );

  return (
    <div
      className="min-w-[500px] overflow-auto rounded-xl shadow-sm"
      ref={parentContainerRef}
    >
      {showTopBar && (
        <div className="flex min-h-7xl items-center justify-between rounded-t-xl border border-b-0 border-gray-200 px-3xl py-lg">
          <span className="truncate text-md font-semibold text-black">
            {title}
          </span>
          <div
            className={twMerge(
              "flex justify-end space-x-[8px]",
              !title && "flex-1", // if there's no title, the top bar can take the full width of the table header
            )}
          >
            {topBarActionsPosition === "left" && topBarActions}
            {showSearch && (
              // TODO(dalvarez) - update to tenaissance Input component
              <input
                value={searchQuery}
                onChange={handleSearchChange}
                className={twMerge(
                  "font-lg border-block rounded-sm border p-sm",
                  searchClassname,
                )}
                placeholder={searchPlaceholder ?? "Search"}
              />
            )}
            {topBarActionsPosition === "right" && topBarActions}
            {showExport && (
              <>
                <Button
                  text="Export"
                  leadingIcon="share02"
                  theme="secondary"
                  onClick={() => exportCSV(table.getRowModel().rows)}
                />
              </>
            )}
          </div>
        </div>
      )}
      <div ref={tableWrapperRef} className="overflow-x-auto">
        <table ref={tableRef} className={tableClasses}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="h-[44px] bg-gray-50">
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={twMerge(
                      "whitespace-nowrap bg-gray-50 p-xl text-left text-xs font-medium text-gray-600",
                      !showTopBar && "first:rounded-tl-xl last:rounded-tr-xl",
                      header.column.columnDef.isDisplay && "w-xxs",
                      header.column.columnDef.headerClassName,
                      header.column.columnDef.align === "left" && "text-left",
                      header.column.columnDef.align === "right" && "text-right",
                    )}
                    style={{
                      ...getCommonPinningStyles(header.column),
                      width: getColumnWidth(header.column.getSize(), index),
                    }}
                  >
                    <div
                      className={twMerge(
                        "inline-flex items-center px-md",
                        header.column.getCanSort() &&
                          "cursor-pointer rounded-4xl py-xs hover:bg-gray-200",
                      )}
                      role={
                        header.column.getCanSort() ? "button" : "columnheader"
                      }
                      onClick={
                        header.column.getCanSort()
                          ? header.column.getToggleSortingHandler()
                          : undefined
                      }
                    >
                      {header.column.columnDef.header &&
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      {loading}
                      {header.column.columnDef.tooltipContent && (
                        <Tooltip label={header.column.columnDef.tooltipContent}>
                          <Icon icon="helpCircle" size={14} className="ml-xs" />
                        </Tooltip>
                      )}

                      {header.column.getIsSorted() && (
                        <SortIcon dir={header.column.getIsSorted()} />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {!loading && data.length === 0 ? (
              <tr>
                <td colSpan={columns.length}>
                  <div className="flex h-full w-full items-center justify-center py-xl">
                    {emptyState || (
                      <EmptyState icon="faceSad" mainText="No data available" />
                    )}
                  </div>
                </td>
              </tr>
            ) : loading ? (
              <LoadingState
                columnCount={columns.length}
                rowCount={pagination.pageSize}
              />
            ) : (
              table.getRowModel().rows.map((row) => {
                const rowLink =
                  typeof rowRoutePath === "function"
                    ? rowRoutePath(row)
                    : rowRoutePath;

                return (
                  <tr
                    key={row.id}
                    className={twMerge(
                      "h-[52px] bg-white",
                      (rowLink || rowOnClick) &&
                        "cursor-pointer hover:bg-gray-50",
                      "className" in row.original
                        ? (row.original.className as string)
                        : "",
                    )}
                    id={row.id}
                    onClick={() => {
                      if (!rowLink && rowOnClick) {
                        rowOnClick(row.original);
                      }
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const cellClassName =
                        typeof cell.column.columnDef.cellClassName ===
                        "function"
                          ? cell.column.columnDef.cellClassName(row.original)
                          : cell.column.columnDef.cellClassName;

                      const cellSupportingText = cell.column.columnDef
                        .supportingText
                        ? typeof cell.column.columnDef.supportingText ===
                          "function"
                          ? cell.column.columnDef.supportingText(row.original)
                          : cell.column.columnDef.supportingText
                        : undefined;

                      const cellAlignment = cell.column.columnDef.align;

                      return (
                        <td
                          key={cell.id}
                          className={twMerge(
                            "whitespace-nowrap border-t border-gray-200 text-sm font-normal text-gray-600",
                            !!rowLink && "relative",
                            cell.column.columnDef.textStyle === "leadText" &&
                              "font-medium text-core-slate",
                            cell.column.columnDef.isDisplay &&
                              "w-xxs whitespace-nowrap",
                            cellClassName,
                          )}
                          style={
                            useFixedRowHeight
                              ? {
                                  ...getCommonPinningStyles(cell.column),
                                  height: 52,
                                }
                              : {}
                          }
                        >
                          <ConditionalWrapper
                            condition={!!rowLink}
                            wrapper={(children) => (
                              <InternalLink
                                routePath={rowLink ?? ""}
                                replace={rowRoutePathReplace}
                                className="" // needed to omit existing styles
                              >
                                {children}
                              </InternalLink>
                            )}
                          >
                            <div
                              className={twMerge(
                                "inline-flex h-full w-full items-center px-3xl py-xs",
                                !!rowLink && "relative",
                                cell.column.columnDef.isDisplay &&
                                  "justify-end",
                              )}
                            >
                              {cell.column.columnDef.textStyle === "leadText" &&
                                cell.column.columnDef.showVanityIcon && (
                                  <div
                                    className={twMerge(
                                      "mr-lg h-4xl w-4xl rounded-sm p-md",
                                      VANITY_GRADIENTS[
                                        Math.floor(
                                          Math.random() *
                                            VANITY_GRADIENTS.length,
                                        )
                                      ],
                                    )}
                                  >
                                    <Icon
                                      icon={
                                        VANITY_ICONS[
                                          Math.floor(
                                            Math.random() * VANITY_ICONS.length,
                                          )
                                        ]
                                      }
                                      size={16}
                                    />
                                  </div>
                                )}
                              <div
                                className={twMerge(
                                  "flex h-full w-full",
                                  cellSupportingText
                                    ? "flex-col justify-center"
                                    : "space-x-deprecated-[4px] items-center",
                                  cellAlignment === "left" &&
                                    "justify-start text-left",
                                  cellAlignment === "right" &&
                                    "justify-end text-right",
                                )}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                                {cellSupportingText && (
                                  <Tooltip
                                    label={cellSupportingText}
                                    overflowOnly={true}
                                  >
                                    <span className="max-h-[50px] max-w-md overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal">
                                      {cellSupportingText}
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </ConditionalWrapper>
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {showPagination && renderPagination()}
    </div>
  );
}
