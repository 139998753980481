import React, { useEffect, useState } from "react";
import { useOptionalParam } from "lib/routes/params";

import { Button } from "tenaissance/components/Button";

import {
  ContractProductFlyover__BillableMetricFragment,
  SendFakeSyntheticUsageEventMutation,
  useSendFakeSyntheticUsageEventMutation,
} from "./data.graphql";
import JsonSchemaValidator, {
  makeFakeEvent,
} from "components/JsonSchemaValidator";
import { jsonSchemaToConditions } from "@metronome-industries/json-schema-conditions";
import { useFeatureFlag } from "lib/launchdarkly";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";

type SendMatchingUsageEventButtonProps = {
  billableMetric: ContractProductFlyover__BillableMetricFragment;
  showCustomizationControls?: boolean;
};

export const SendMatchingUsageEventButton: React.FC<
  SendMatchingUsageEventButtonProps
> = ({ billableMetric, showCustomizationControls }) => {
  const sandboxMode = useEnvironment();

  const allowFakeUsageProduction =
    useFeatureFlag<boolean>("allow-fake-usage-production", false) ?? false;

  const customerId = useOptionalParam("customerId");

  const showFakeUsageButton: boolean =
    !!customerId &&
    (sandboxMode.environmentType === EnvironmentTypeEnum_Enum.Production
      ? allowFakeUsageProduction
      : true);

  const [results, setResults] = useState<
    SendFakeSyntheticUsageEventMutation["send_fake_synthetic_usage_event"][]
  >([]);

  const [
    sendFakeSyntheticUsageEventRequest,
    sendFakeSyntheticUsageEventResult,
  ] = useSendFakeSyntheticUsageEventMutation();

  useEffect(() => {
    if (sendFakeSyntheticUsageEventResult.data) {
      const data = sendFakeSyntheticUsageEventResult.data;
      setResults([...results, data.send_fake_synthetic_usage_event]);
      sendFakeSyntheticUsageEventResult.reset();
    }
  }, [sendFakeSyntheticUsageEventResult]);

  async function sendEvent(payload?: string) {
    if (!customerId) {
      setResults([
        ...results,
        {
          error: "No customer id provided",
          success: false,
          transaction_id: null,
        },
      ]);
      return;
    }
    await sendFakeSyntheticUsageEventRequest({
      variables: {
        billable_metric_id: billableMetric.id,
        customer_id: customerId,
        json_payload:
          payload ||
          JSON.stringify(
            makeFakeEvent({ filter: billableMetric.filter, customerId }),
          ),
      },
    });
  }

  return (
    <>
      {showCustomizationControls && (
        <JsonSchemaValidator
          conditions={jsonSchemaToConditions(billableMetric.filter)}
          initialPayload={JSON.stringify(
            makeFakeEvent({ filter: billableMetric.filter, customerId }),
            undefined,
            2,
          )}
          submitButtonOptions={
            showFakeUsageButton
              ? {
                  async onSubmit(payload) {
                    await sendEvent(payload);
                  },
                  submitButtonText: "Send matching usage event",
                }
              : undefined
          }
        ></JsonSchemaValidator>
      )}
      {!showCustomizationControls && showFakeUsageButton && (
        <div className="text-right">
          <Button
            onClick={async () => sendEvent()}
            text="Send matching usage event"
            theme="primary"
          />
        </div>
      )}
      <div className="text-right">
        {results.map((result, idx) => (
          <div
            key={idx}
            className={result.error ? "my-4 text-error-600" : "my-4"}
          >
            {result.error ? (
              result.error
            ) : (
              <a
                className="hover:underline"
                href={`/developer/events?transactionID=${result.transaction_id}`}
              >
                Event sent {result.transaction_id}
              </a>
            )}
          </div>
        ))}
      </div>
    </>
  );
};
