import React from "react";
import Decimal from "decimal.js";
import { DateInput, NumericInput } from "design-system";

import { CreditInput } from "components/Input";
import { USD_CREDIT_TYPE, RoundedCurrency } from "lib/credits";

import { Schema } from "../../Schema";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { RootCtrl, useRootCtrl } from "./RootCtrl";
import { useMode } from "./mode";

// For one-time charges, the whole schedule is just one item
export const useOneTimeCtrl = useRootCtrl.child(Schema.FixedScheduleItem, {
  read(parent, timeframe: DefaultTimeframe) {
    const schedule = parent.get("schedule");

    if (!schedule || schedule.type !== "fixed" || schedule.items.length !== 1) {
      return {
        date: timeframe.startingAt,
      };
    }

    const {
      id,
      date = timeframe.startingAt,
      unitPrice,
      quantity,
    } = schedule.items[0];
    return {
      id,
      date,
      unitPrice,
      quantity,
    };
  },
  write(child) {
    return {
      schedule: {
        type: "fixed",
        items: [child.getUnvalidatedInputs()],
      },
    };
  },
});

export const OneTimeSchedule: React.FC<{
  parent: RootCtrl;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = useOneTimeCtrl(props.parent, timeframe);
  const qty = ctrl.get("quantity");
  const unitPrice = ctrl.get("unitPrice");
  const mode = useMode();

  return (
    <div className="col-span-3 grid grid-cols-3 gap-12">
      <DateInput
        {...ctrl.props.DateInput("date", {
          name: mode === "invoice" ? "Invoice at" : "Apply to invoice on",
        })}
      />

      <NumericInput
        {...ctrl.props.NumericInput("quantity", {
          placeholder: "1",
          name: "Quantity",
        })}
      />
      <CreditInput
        {...ctrl.props.CreditInput("unitPrice", {
          name: mode === "discount" ? "Discount" : "Unit price",
          creditType: USD_CREDIT_TYPE,
          placeholder: "123.45",
          allowZeroAmounts: true,
        })}
      />

      {qty !== undefined &&
        unitPrice !== undefined &&
        typeof qty === "number" &&
        typeof unitPrice === "number" &&
        unitPrice >= 0 && (
          <div className="col-div-3">
            <p className="text-grey-200">
              {mode === "invoice" ? "Invoice total: " : "Total discount: "}
              <RoundedCurrency
                amount={new Decimal(qty).mul(unitPrice)}
                creditType={USD_CREDIT_TYPE}
              />
            </p>
          </div>
        )}
    </div>
  );
};
