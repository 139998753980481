import React from "react";
import { ReactComponent as MicrosoftLogo } from "tenaissance/icons/microsoft.svg";
import { ReactComponent as AWSMarketPlaceLogo } from "tenaissance/icons/aws.svg";
import { ReactComponent as GCPMarketplaceLogo } from "tenaissance/icons/gcp.svg";
import { ReactComponent as SalesForceLogo } from "tenaissance/icons/salesforce.svg";
import { ReactComponent as StripeLogo } from "tenaissance/icons/stripe.svg";
import { ReactComponent as NetsuiteLogo } from "tenaissance/icons/netsuite.svg";
import { ReactComponent as WorkdayLogo } from "tenaissance/icons/workday.svg";
import { ReactComponent as QuickBooksOnlineLogo } from "tenaissance/icons/quickbooks.svg";
import { Badge } from "tenaissance/components/Badge";
import { Tooltip } from "tenaissance/components/Tooltip";
import { BadgeGroup } from "tenaissance/components/BadgeGroup";
import { CopyableID } from "tenaissance/components/CopyableID";
import { Icon } from "tenaissance/components/Icon";
import { StatusLight } from "components/StatusLight";

export interface IntegrationDetailProps {
  integrationName: string;
  supportingText: string;
  isActive?: boolean;
  isClickable: boolean;
  displayedSettings?: Record<
    string,
    | string
    | {
        value: string;
        hasBadgeLabel: true;
      }
  >;
  titleComponent?: React.ReactNode;
}

const renderSVG = (integrationName: string) => {
  switch (integrationName) {
    case "Azure Marketplace":
      return <MicrosoftLogo />;
    case "AWS Marketplace":
      return <AWSMarketPlaceLogo />;
    case "GCP Marketplace":
      return <GCPMarketplaceLogo />;
    case "Salesforce":
      return (
        <div className="h-[32px] w-[32px] pt-8">
          <SalesForceLogo />
        </div>
      );
    case "Stripe":
      return <StripeLogo />;
    case "NetSuite":
      return <NetsuiteLogo />;
    case "Workday":
      return <WorkdayLogo />;
    case "Quickbooks Online":
      return <QuickBooksOnlineLogo />;
    default:
      return null;
  }
};

export const IntegrationDetails: React.FC<IntegrationDetailProps> = ({
  integrationName,
  supportingText,
  isActive,
  isClickable,
  displayedSettings,
  titleComponent,
}) => {
  return (
    <div className="flex gap-lg">
      <div className="float-left flex items-center">
        {renderSVG(integrationName)}
      </div>
      <div className="float-right flex flex-col justify-between">
        <div className="flex items-center text-md font-medium leading-5 text-core-slate">
          {titleComponent ? (
            <div className="flex items-center">
              {titleComponent}
              {isActive !== undefined && (
                <div className="ml-8">
                  <StatusLight status={isActive ? "on" : "off"} />
                </div>
              )}
            </div>
          ) : (
            <Tooltip
              disabled={isActive === undefined}
              label={isActive ? "Active" : "Inactive"}
            >
              <div className="flex items-center">
                {isClickable ? (
                  <Icon icon="arrowNarrowUpRight" size={20} />
                ) : null}
                <span>{integrationName}</span>
                {isActive !== undefined && (
                  <div className="ml-8">
                    <StatusLight status={isActive ? "on" : "off"} />
                  </div>
                )}
              </div>
            </Tooltip>
          )}
        </div>
        <div className="text-xs font-normal leading-[18px]">
          {supportingText}
        </div>
        {displayedSettings && (
          <div className="mt-[8px] grid gap-y-[6px] text-xs font-normal leading-[18px]">
            {Object.entries(displayedSettings).map(([key, value], index) => (
              <Tooltip key={`integration-details-config-${index}`} label={key}>
                {typeof value === "object" && value.hasBadgeLabel === true ? (
                  <BadgeGroup badgeLabel={key} mainLabel={value.value} />
                ) : (
                  <Badge label={<CopyableID id={value.toString()} />} />
                )}
              </Tooltip>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
