import React, { useCallback } from "react";
import { Schema } from "../../../../../pages/Contracts/Pricing/Schema";
import { CreditType } from "types/credit-types";
import { TextInput } from "tenaissance/components/Input";
import { displayCreditTypeName, USD_CREDIT_ID } from "lib/credits";
import { ConversionModal } from "./ConversionModal";
import { DropdownHR, DropdownItem } from "tenaissance/components/Dropdown";
import { IconName } from "tenaissance/components/Icon";

interface Props {
  disabled?: boolean;
  price: number | undefined;
  rateCreditType: CreditType;
  rateCardFiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  creditTypeConversions: Schema.Types.CreditTypeConversion[];
  onPriceChange: (price: number | undefined) => void;
  onCreditTypeChange: (
    creditType: CreditType,
    conversion?: Schema.Types.CreditTypeConversion,
  ) => void;
  allowEditCreditConversions: boolean;
  disableChangingCreditTypes: boolean;
}

export const CurrencyInput: React.FC<Props> = ({
  price,
  rateCreditType,
  rateCardFiatCreditType,
  customCreditTypes,
  creditTypeConversions,
  onPriceChange,
  onCreditTypeChange,
  disabled,
  allowEditCreditConversions,
  disableChangingCreditTypes,
}) => {
  const [showConversionForCreditType, setShowConversionForCreditType] =
    React.useState<{ creditType?: CreditType } | null>(null);

  const onChange = useCallback(
    ({ value }: { value: string }) => {
      // If the user types a decimal point followed by any number of zeroes,
      // avoid calling onPriceChange, as doing so would change the value to be
      // "0" (the decimal point would be lost).
      if (/^\.0+$/.test(value)) {
        return;
      }
      onPriceChange(value === "" ? undefined : Number(value));
    },
    [onPriceChange],
  );

  const leadingIcon: IconName | undefined =
    rateCreditType.id === USD_CREDIT_ID ? "currencyDollar" : undefined;

  return (
    <>
      {showConversionForCreditType && (
        <ConversionModal
          onClose={() => {
            setShowConversionForCreditType(null);
          }}
          onSave={(conversion) => {
            // TODO(ekaragiannis) - save the conversion
            onCreditTypeChange(conversion.customCreditType, {
              custom_credit_type_id: conversion.customCreditType.id,
              custom_credit_type_name: conversion.customCreditType.name,
              fiat_per_custom_credit: conversion.conversionRate,
            });

            setShowConversionForCreditType(null);
          }}
          fiatCreditType={rateCardFiatCreditType}
          conversionRate={
            creditTypeConversions.find(
              (c) =>
                c.custom_credit_type_id ===
                showConversionForCreditType.creditType?.id,
            )?.fiat_per_custom_credit
          }
          customCreditType={showConversionForCreditType.creditType}
        />
      )}
      <TextInput
        type="number"
        className="min-w-[200px]"
        disabled={disabled}
        value={price !== undefined ? price.toString() : undefined}
        fullWidth
        disableClear
        variantOptions={{
          leadingIcon,
          variant: "dropdown",
          dropdownOptions: {
            disabled: disableChangingCreditTypes,
            label: displayCreditTypeName(rateCreditType),
            children: [
              <DropdownItem
                label={displayCreditTypeName(rateCardFiatCreditType)}
                value={rateCardFiatCreditType.id}
                onClick={() => {
                  if (rateCreditType === rateCardFiatCreditType) {
                    return;
                  }

                  onCreditTypeChange(rateCardFiatCreditType);
                }}
              />,
              <DropdownHR />,
              ...customCreditTypes
                .filter(
                  (c) =>
                    allowEditCreditConversions ||
                    creditTypeConversions.find(
                      (ctc) => ctc.custom_credit_type_id === c.id,
                    ),
                )
                .map((creditType) => {
                  const conversion = creditTypeConversions.find(
                    (c) => c.custom_credit_type_id === creditType.id,
                  );
                  return (
                    <DropdownItem
                      label={displayCreditTypeName(creditType)}
                      value={creditType.id}
                      subtext={
                        conversion
                          ? `${conversion.fiat_per_custom_credit} ${displayCreditTypeName(
                              rateCardFiatCreditType,
                            )} per ${displayCreditTypeName(creditType)}`
                          : undefined
                      }
                      onClick={() => {
                        if (rateCreditType?.id === creditType.id) {
                          return;
                        }

                        const customCreditType = customCreditTypes.find(
                          (c) => c.id === creditType.id,
                        );

                        if (conversion && customCreditType) {
                          onCreditTypeChange(customCreditType);
                        } else {
                          // we need to show the config so they can set a credit type conversion
                          setShowConversionForCreditType({ creditType });
                        }
                      }}
                      secondaryAction={
                        conversion && allowEditCreditConversions
                          ? {
                              icon: "settings01",
                              theme: "tertiary",
                              size: "sm",
                              onClick: (e) => {
                                e.stopPropagation();
                                setShowConversionForCreditType({ creditType });
                              },
                            }
                          : undefined
                      }
                    />
                  );
                }),
            ],
          },
        }}
        onChange={onChange}
      />
    </>
  );
};
