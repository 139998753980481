import React from "react";
import {
  BillingDayOfPeriod,
  collectionScheduleToEnum,
  DraftPlan,
} from "lib/plans/types";
import { FetchCustomerPlansQuery } from "../../../../data/queries.graphql";
import { useDraftPlan } from "../../../../context";
import styles from "../../index.module.less";
import { Select } from "design-system";
import { LoadingSpinner } from "design-system";
import { InputSection, WizardSplitPage } from "components/Wizard";
import { TermPreview } from "../TermPreview";
import { getUtcStartOfDay } from "lib/time";
import { PlanLengthInput } from "components/PlanLengthInput";
import { Tooltip } from "design-system";
import { Checkbox } from "design-system";
import { dayjs } from "lib/dayjs";
import { TrialSpecInputSection } from "components/TrialSpecInput";
import { PlanQuery } from "lib/plans/queries.graphql";
import {
  BillingFrequencyEnum_Enum,
  BillingProviderEnum_Enum,
  ChargeTypeEnum_Enum,
} from "types/generated-graphql/__types__";
import { CreditType } from "types/credit-types";
import { Body } from "design-system";
import { useFeatureFlag } from "lib/launchdarkly";
import { billingProviderOptions } from "lib/billingProvider/billingProviderOptions";

export type Plan = Exclude<PlanQuery["Plan_by_pk"], null>;

export const BILLING_SCHEDULE_STEP_NAVIGATION_KEY = "set-billing-schedule";

export const isBillingScheduleDone = (plan: DraftPlan) => {
  return !!(
    plan.billingFrequency &&
    plan.billingDayOfPeriod &&
    (plan.hasCustomer
      ? plan.customerPlanInfo?.datesError === null
      : plan.defaultLength !== undefined) &&
    (!plan.hasTrial ||
      (plan.trialSpec?.length &&
        plan.trialSpec.length > 0 &&
        (plan.trialSpec.caps ?? []).every(
          (cap) => cap.creditTypeId && cap.amount,
        )))
  );
};

interface SetBillingSchedulePageProps {
  existingCustomerPlans: FetchCustomerPlansQuery | undefined;
  disabledForEditing?: boolean;
  creditTypes: CreditType[];
}

export const SetBillingSchedulePage: React.FC<SetBillingSchedulePageProps> = (
  props,
) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const billingFrequencyOptions: Array<{
    label: string;
    value: BillingFrequencyEnum_Enum;
  }> = [
    {
      label: "Monthly",
      value: BillingFrequencyEnum_Enum.Monthly,
    },
    {
      label: "Biweekly",
      value: BillingFrequencyEnum_Enum.SemiMonthly,
    },
    {
      label: "Quarterly",
      value: BillingFrequencyEnum_Enum.Quarterly,
    },
    {
      label: "Semiannual",
      value: BillingFrequencyEnum_Enum.SemiAnnual,
    },
    {
      label: "Annual",
      value: BillingFrequencyEnum_Enum.Annual,
    },
  ];
  const billingDayOfMonthOptions: Array<{
    label: string;
    value: BillingDayOfPeriod;
  }> = [
    {
      label: "1st of the month",
      value: "FIRST_OF_MONTH",
    },
    {
      label: "Based on the plan start date",
      value: "START_OF_PLAN",
    },
  ];

  const canSetBillingProvider = useFeatureFlag(
    "set-billing-provider-on-plan",
    false,
  );

  const customerPlanInfo = draftPlan.customerPlanInfo;
  const existingPlansForCustomer = props.existingCustomerPlans?.CustomerPlan;
  if (draftPlan.hasCustomer && !existingPlansForCustomer) {
    return <LoadingSpinner />;
  }

  return (
    <WizardSplitPage
      preview={
        <TermPreview
          billingFrequency={draftPlan.billingFrequency}
          billingAnchor={draftPlan.billingDayOfPeriod}
          startDate={
            draftPlan.hasCustomer
              ? draftPlan.customerPlanInfo?.startDate
              : new Date()
          }
          endDate={
            draftPlan.hasCustomer
              ? draftPlan.customerPlanInfo?.endDate
              : draftPlan.defaultLength === null
                ? null
                : getUtcStartOfDay(
                    dayjs(new Date())
                      .add(
                        draftPlan.defaultLength !== undefined
                          ? draftPlan.defaultLength / 12
                          : 1,
                        "year",
                      )
                      .toDate(),
                  )
          }
          trialLength={draftPlan.trialSpec?.length}
          draft={!draftPlan.hasCustomer}
          hasCustomer={draftPlan.hasCustomer ?? false}
          customerName={draftPlan.customerPlanInfo?.customerName}
          planName={draftPlan.name ?? ""}
          planDescription={draftPlan.description ?? ""}
        />
      }
    >
      {draftPlan.hasCustomer && customerPlanInfo && existingPlansForCustomer ? (
        <InputSection
          header="Set a start and end date for this plan"
          subtitle={`Determine when ${customerPlanInfo.customerName}’s plan will start and end.`}
        >
          <PlanLengthInput
            pricedProducts={
              draftPlan.pricedProducts?.length
                ? draftPlan.pricedProducts.map((pp) => {
                    return {
                      PricedProductPricingFactors: pp.pricingFactors.map(
                        (pf) => {
                          return {
                            startPeriod: Number(pf.startPeriod),
                            productPricingFactor: {
                              id: pf.pricingFactorId,
                              // name isn't available via the draftPlan
                            },
                            flatFee:
                              pf.chargeType === ChargeTypeEnum_Enum.Flat &&
                              pf.flatFees?.length
                                ? {
                                    collectionSchedule: pf.flatFees?.[0]
                                      ?.collectionSchedule
                                      ? collectionScheduleToEnum(
                                          pf.flatFees[0]?.collectionSchedule,
                                        )
                                      : null,
                                    collectionInterval:
                                      pf.flatFees?.[0]?.collectionInterval ??
                                      null,
                                    isProrated: false, // todo GET-1920
                                  }
                                : null,
                          };
                        },
                      ),
                    };
                  })
                : []
            }
            existingPlansForCustomer={existingPlansForCustomer}
            onChange={(v) =>
              setDraftPlan({
                ...draftPlan,
                customerPlanInfo: {
                  ...customerPlanInfo,
                  startDate: v.startDate,
                  endDate: v.endDate,
                  datesError: v.error,
                },
              })
            }
            planBillingFrequency={draftPlan.billingFrequency}
            planServicePeriodStartType={draftPlan.billingDayOfPeriod}
            value={{
              startDate: customerPlanInfo.startDate,
              endDate: customerPlanInfo.endDate,
              error: customerPlanInfo.datesError,
            }}
          />
        </InputSection>
      ) : (
        <InputSection
          header="Default plan length"
          subtitle="The start and end date of a plan is applied when this plan is provisioned to a customer. This default length can be modified when provisioning a customer."
        >
          <div className={styles.dropdownContainer}>
            <Select
              options={[
                { label: "1 year", value: "12" },
                { label: "2 years", value: "24" },
                { label: "3 years", value: "36" },
              ]}
              onChange={(v) =>
                setDraftPlan({
                  ...draftPlan,
                  defaultLength: Number.parseInt(v),
                })
              }
              value={draftPlan.defaultLength?.toString() ?? ""}
              placeholder="Select"
              name="Plan length"
            />
          </div>
          <Checkbox
            checked={draftPlan.defaultLength === null}
            onChange={(checked) =>
              setDraftPlan({
                ...draftPlan,
                defaultLength: checked ? null : undefined,
              })
            }
            label="No default length"
          />
        </InputSection>
      )}
      <InputSection
        header="Invoicing frequency"
        subtitle={
          <>
            <Body>
              Metronome produces an invoice for each customer on a regular
              schedule. Usage-based charges will be billed at that frequency.
              Fixed charges can occur less often if you so choose. For example,
              customers might pay a fixed amount each quarter but be billed for
              usage every month. In that case, you should choose monthly
              billing.
            </Body>
            Invoice periods always start and end at midnight UTC.
          </>
        }
      >
        <Tooltip
          content="Invoicing frequency cannot be modified for plans with customers."
          disabled={!props.disabledForEditing}
          inline
        >
          <div className={styles.dropdownContainer}>
            <Select
              name="Invoicing frequency"
              options={billingFrequencyOptions}
              value={draftPlan.billingFrequency ?? ""}
              onChange={(v) =>
                setDraftPlan({
                  ...draftPlan,
                  billingFrequency: v as BillingFrequencyEnum_Enum,
                  billingDayOfPeriod:
                    v === BillingFrequencyEnum_Enum.SemiMonthly
                      ? "FIRST_OF_MONTH"
                      : v === BillingFrequencyEnum_Enum.Annual
                        ? "START_OF_PLAN"
                        : v === BillingFrequencyEnum_Enum.SemiAnnual
                          ? "START_OF_PLAN"
                          : draftPlan.billingDayOfPeriod,
                })
              }
              placeholder={billingFrequencyOptions[0].label}
              disabled={props.disabledForEditing}
            />
          </div>
        </Tooltip>
      </InputSection>
      <InputSection
        header="Billing day of month"
        subtitle="For monthly or quarterly billing, choose what date of the month each billing period should start."
      >
        <Tooltip
          content={
            props.disabledForEditing
              ? "Billing day of month cannot be modified for plans with customers."
              : `${
                  draftPlan.billingFrequency ===
                  BillingFrequencyEnum_Enum.Annual
                    ? "Annual billing periods must begin at the start of the plan."
                    : draftPlan.billingFrequency ===
                        BillingFrequencyEnum_Enum.SemiAnnual
                      ? "Semiannual billing periods must begin at the start of the plan."
                      : "Biweekly billing periods must start on the 1st of each month."
                }`
          }
          disabled={
            draftPlan.billingFrequency !==
              BillingFrequencyEnum_Enum.SemiMonthly &&
            draftPlan.billingFrequency !== BillingFrequencyEnum_Enum.Annual &&
            draftPlan.billingFrequency !==
              BillingFrequencyEnum_Enum.SemiAnnual &&
            !props.disabledForEditing
          }
          inline
        >
          <div className={styles.dropdownContainer}>
            <Select
              name="Billing day of month"
              options={billingDayOfMonthOptions}
              value={draftPlan.billingDayOfPeriod ?? ""}
              onChange={(v) =>
                setDraftPlan({
                  ...draftPlan,
                  billingDayOfPeriod: v as BillingDayOfPeriod,
                  billingFrequency: draftPlan.billingFrequency,
                })
              }
              placeholder={billingDayOfMonthOptions[0].label}
              disabled={
                draftPlan.billingFrequency ===
                  BillingFrequencyEnum_Enum.SemiMonthly ||
                draftPlan.billingFrequency ===
                  BillingFrequencyEnum_Enum.Annual ||
                draftPlan.billingFrequency ===
                  BillingFrequencyEnum_Enum.SemiAnnual ||
                props.disabledForEditing
              }
            />
          </div>
        </Tooltip>
      </InputSection>
      {canSetBillingProvider && (
        <InputSection
          header="Billing provider"
          subtitle="Choose billing provider associated with this plan. This can be modified by editing the plan."
        >
          <div className={styles.dropdownContainer}>
            <Select
              options={billingProviderOptions}
              value={draftPlan.billingProvider ?? ""}
              placeholder="Select"
              name="Billing provider"
              onChange={(v) => {
                if (v === "") {
                  setDraftPlan({
                    ...draftPlan,
                    billingProvider: undefined,
                  });
                } else {
                  setDraftPlan({
                    ...draftPlan,
                    billingProvider: v as BillingProviderEnum_Enum,
                  });
                }
              }}
            />
          </div>
        </InputSection>
      )}
      {draftPlan.hasTrial && (
        <TrialSpecInputSection
          creditTypes={props.creditTypes}
          disabled={props.disabledForEditing}
          conversions={draftPlan.creditTypeConversions}
          showTrialToggle={false}
          trialSpec={draftPlan.trialSpec}
          setTrialSpec={(trialSpec) =>
            setDraftPlan({ ...draftPlan, trialSpec: trialSpec })
          }
          allowPricingUnitCreation
        />
      )}
    </WizardSplitPage>
  );
};
