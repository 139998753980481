import { RateScheduleRowWithId } from "./RateCardTable";
import { renderRateScheduleTableCell } from "pages/Contracts/components/RateScheduleTable";

type Props = {
  rateSegment: RateScheduleRowWithId;
  isCommitRate: boolean;
};

export default function RateCell({ rateSegment, isCommitRate }: Props) {
  return renderRateScheduleTableCell(
    rateSegment,
    isCommitRate,
    true /* is new ui */,
  );
}
