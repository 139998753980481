import React from "react";
import {
  BaseIntegrationCard,
  IntegrationCardProps,
} from "./BaseIntegrationCard";
import { ReactComponent as SalesforceLogo } from "tenaissance/icons/salesforce.svg";

type SalesforceIntegrationCardProps = Omit<
  IntegrationCardProps,
  "title" | "subheader" | "description" | "logo"
>;

export const SalesforceIntegrationCard: React.FC<
  SalesforceIntegrationCardProps
> = ({ ...propOverrides }) => {
  return (
    <BaseIntegrationCard
      title="Salesforce"
      subheader="Customer relationship management"
      description="Enrich your customer data by automatically importing Metronome usage and invoicing data."
      {...propOverrides}
      logo={
        <div className="h-[32px] w-[32px] pt-8">
          <SalesforceLogo />
        </div>
      }
    />
  );
};
