import React, { useEffect, useState, useRef, useCallback } from "react";

export function useKonamiCode(callback: () => void): void {
  const sequence: string[] = [
    "ArrowUp",
    "ArrowUp",
    "ArrowDown",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowLeft",
    "ArrowRight",
    "b",
    "a",
    "Enter",
  ];

  const buffer = useRef<string[]>([]);

  const keySequence = useCallback(
    (event: KeyboardEvent) => {
      if (event.defaultPrevented) return;

      if (event.key === sequence[buffer.current.length]) {
        buffer.current = [...buffer.current, event.key];
      } else {
        buffer.current = [];
      }

      if (buffer.current.length === sequence.length) {
        const bufferString = buffer.current.toString();
        const sequenceString = sequence.toString();

        if (sequenceString === bufferString) {
          callback();
        }
      }
    },
    [sequence],
  );

  useEffect(() => {
    document.addEventListener("keydown", keySequence);
    return () => document.removeEventListener("keydown", keySequence);
  }, [keySequence]);
}

export const PongLogoBars: React.FC<{
  barColor: string;
  onGameEnd: () => void;
}> = ({ barColor, onGameEnd }) => {
  const PADDLE_HEIGHT = 8;
  const BALL_SIZE = 2;
  const PADDLE_SPEED = 0.7;

  // Game state
  const [leftPaddleY, setLeftPaddleY] = useState(16);
  const [rightPaddleY, setRightPaddleY] = useState(16);
  const [ballPosition, setBallPosition] = useState({ x: 14.5703, y: 16 });
  const [ballVelocity, setBallVelocity] = useState({ x: 0.15, y: 0.1 });

  // Magic constants extracted from SVG
  const PLAY_AREA_TOP = 8;
  const PLAY_AREA_BOTTOM = 23.3022;
  const LEFT_PADDLE_X = 10.2852;
  const LEFT_PADDLE_WIDTH = 12.7335 - 10.2852;
  const RIGHT_PADDLE_X = 18.8535;
  const RIGHT_PADDLE_WIDTH = 21.3019 - 18.8535;
  const PLAYER_PADDLE_ORIGIN_Y = (9.8363 + 17.7934) / 2; // 13.81485
  const AI_PADDLE_ORIGIN_Y = (12.8967 + 20.8539) / 2; // 16.8753

  const getRightPaddleY = (time: number) => {
    return 16 + Math.sin(time / 1000) * 3;
  };

  // Game loop
  useEffect(() => {
    let animationFrameId: number;
    const gameLoop = (currentTime: number) => {
      const newRightPaddleY = getRightPaddleY(currentTime);
      setRightPaddleY(newRightPaddleY);

      setBallPosition((prevPos) => {
        const nextPos = {
          x: prevPos.x + ballVelocity.x,
          y: prevPos.y + ballVelocity.y,
        };

        let newVelocity = { ...ballVelocity };

        // Vertical bounds checking
        if (
          nextPos.y - BALL_SIZE / 2 <= PLAY_AREA_TOP ||
          nextPos.y + BALL_SIZE / 2 >= PLAY_AREA_BOTTOM
        ) {
          newVelocity.y = -ballVelocity.y;
          setBallVelocity(newVelocity);
          return { ...prevPos, y: prevPos.y + newVelocity.y };
        }

        const ballLeft = nextPos.x - BALL_SIZE / 2;
        const ballRight = nextPos.x + BALL_SIZE / 2;
        const ballTop = nextPos.y - BALL_SIZE / 2;
        const ballBottom = nextPos.y + BALL_SIZE / 2;

        const leftPaddleLeft = LEFT_PADDLE_X;
        const leftPaddleRight = LEFT_PADDLE_X + LEFT_PADDLE_WIDTH;
        const leftPaddleTop = leftPaddleY - PADDLE_HEIGHT / 2;
        const leftPaddleBottom = leftPaddleY + PADDLE_HEIGHT / 2;

        const rightPaddleLeft = RIGHT_PADDLE_X;
        const rightPaddleRight = RIGHT_PADDLE_X + RIGHT_PADDLE_WIDTH;
        const rightPaddleTop = newRightPaddleY - PADDLE_HEIGHT / 2;
        const rightPaddleBottom = newRightPaddleY + PADDLE_HEIGHT / 2;

        // Left paddle collision
        if (
          ballRight >= leftPaddleLeft &&
          ballLeft <= leftPaddleRight &&
          ballBottom >= leftPaddleTop &&
          ballTop <= leftPaddleBottom &&
          ballVelocity.x < 0 // Ball moving towards the left paddle
        ) {
          newVelocity.x = Math.abs(ballVelocity.x);
          newVelocity.y = ballVelocity.y + (Math.random() - 0.5) * 0.1;
          setBallVelocity(newVelocity);
          return { ...prevPos, x: leftPaddleRight + BALL_SIZE / 2 };
        }

        // Right paddle collision
        if (
          ballLeft <= rightPaddleRight &&
          ballRight >= rightPaddleLeft &&
          ballBottom >= rightPaddleTop &&
          ballTop <= rightPaddleBottom &&
          ballVelocity.x > 0 // Ball moving towards the right paddle
        ) {
          newVelocity.x = -Math.abs(ballVelocity.x);
          newVelocity.y = ballVelocity.y + (Math.random() - 0.5) * 0.1;
          setBallVelocity(newVelocity);
          return { ...prevPos, x: rightPaddleLeft - BALL_SIZE / 2 };
        }

        // Game over conditions
        if (
          nextPos.x < LEFT_PADDLE_X - BALL_SIZE ||
          nextPos.x > RIGHT_PADDLE_X + RIGHT_PADDLE_WIDTH + BALL_SIZE
        ) {
          setBallPosition({ x: 14.5703, y: 16 });
          setBallVelocity({ x: 0.15, y: 0.1 });
          onGameEnd();
          return { x: 14.5703, y: 16 };
        }

        return nextPos;
      });

      animationFrameId = requestAnimationFrame(gameLoop);
    };

    animationFrameId = requestAnimationFrame(gameLoop);

    return () => cancelAnimationFrame(animationFrameId);
  }, [ballVelocity, leftPaddleY]);

  // Keyboard controls
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        setLeftPaddleY((y) =>
          Math.max(PLAY_AREA_TOP + PADDLE_HEIGHT / 2, y - PADDLE_SPEED),
        );
      } else if (e.key === "ArrowDown") {
        setLeftPaddleY((y) =>
          Math.min(PLAY_AREA_BOTTOM - PADDLE_HEIGHT / 2, y + PADDLE_SPEED),
        );
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, []);

  return (
    <>
      {/* Player paddle */}
      <path
        d="M10.2852 9.8363H12.7335V17.7934H10.2852V9.8363Z"
        transform={`translate(0,${leftPaddleY - PLAYER_PADDLE_ORIGIN_Y})`}
        fill={barColor}
      />

      {/* Ball */}
      <rect
        x={ballPosition.x - BALL_SIZE / 2}
        y={ballPosition.y - BALL_SIZE / 2}
        width={BALL_SIZE}
        height={BALL_SIZE}
        fill={barColor}
      />

      {/* AI paddle */}
      <path
        d="M18.8535 12.8967H21.3019V20.8539H18.8535V12.8967Z"
        transform={`translate(0,${rightPaddleY - AI_PADDLE_ORIGIN_Y})`}
        fill={barColor}
      />
    </>
  );
};
