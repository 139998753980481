import React from "react";
import Decimal from "decimal.js";
import { Tooltip } from "design-system";

import { SortRule, SortFunctions } from "components/Table";
import { Panel } from "components/Panel";
import { TablePanel } from "components/TablePanel";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";

import { Invoice } from "pages/Contracts/lib/Invoice";
import { RoundedCurrency } from "lib/credits";
import { RecentInvoiceFragment } from "./data.graphql";
import { renderDate } from "lib/time";
import { ColWidths } from "pages/Contracts/lib/ColWidths";
import { useUIMode } from "../../../../lib/useUIMode";

interface Props {
  loading: boolean;
  /**
   * We don't support loading additional pages of invoices right now,
   * we just load as many as the API supports and use this prop to
   * indicate to the user that the results are truncated.
   */
  truncated: boolean;
  invoices: RecentInvoiceFragment[];
  defaultSortBy: SortRule[];
  error?: unknown;
}

export function RecentInvoicesTable(props: Props) {
  const { newUIEnabled } = useUIMode();
  if (props.error) {
    return (
      <Panel>
        <ErrorEmptyState
          title="We ran into an error loading invoices."
          error={props.error}
        />
      </Panel>
    );
  }

  return (
    <TablePanel
      noInPanelTableHeaderStyling
      className="border-0"
      maxPageSize={5}
      maxItems={
        !props.loading && props.truncated ? props.invoices.length : undefined
      }
      loading={props.loading}
      skeletonRows={3}
      rowRoutePath={(row) => {
        try {
          return Invoice.getRoutePath(row, newUIEnabled);
        } catch (e) {
          return undefined;
        }
      }}
      data={props.invoices}
      defaultSortBy={props.defaultSortBy}
      columns={[
        {
          id: "date",
          header: "Issue date (UTC)",
          cellClassName: ColWidths.DATE,
          sortable: true,
          comparator: SortFunctions.Date(
            (invoice) => new Date(invoice.issued_at),
          ),
          render: (invoice) =>
            renderDate(new Date(invoice.issued_at), {
              isUtc: true,
              excludeUtcLabel: true,
            }),
        },
        {
          id: "plan",
          header: "Associated contract or plan",
          sortable: true,
          comparator: SortFunctions.String((invoice) =>
            Invoice.getContractOrPlanName(invoice),
          ),
          render: (invoice) => {
            try {
              Invoice.getRoutePath(invoice, newUIEnabled);
              return Invoice.getContractOrPlanName(invoice);
            } catch (e) {
              return (
                <Tooltip content="We are unable to render invoices of this type at this time">
                  {Invoice.getContractOrPlanName(invoice)}
                </Tooltip>
              );
            }
          },
        },
        {
          id: "total",
          header: "Total due",
          align: "right",
          cellClassName: ColWidths.CREDITS,
          sortable: true,
          comparator: SortFunctions.Number((invoice) => Number(invoice.total)),
          render: (invoice) => (
            <RoundedCurrency
              amount={new Decimal(invoice.total)}
              creditType={invoice.credit_type}
            />
          ),
        },
        {
          id: "status",
          header: "Status",
          align: "right",
          cellClassName: ColWidths.STATUS,
          sortable: true,
          comparator: SortFunctions.String((invoice) => invoice.status),
          render: (invoice) => Invoice.renderStatusPills(invoice),
        },
      ]}
      emptyState={
        <p className="p-24 text-center text-sm text-gray-500">
          No invoices to show
        </p>
      }
    />
  );
}
