import React, { useEffect } from "react";
import "style/index.css";
import { Button } from "../Button";
import { twMerge } from "tenaissance/twMerge";
import useDebounce from "lib/debounce";
import { ConditionalWrapper } from "lib/conditionalWrapper";
import * as monaco from "monaco-editor";
import { SQLValidationError } from "lib/sql";

interface CodeBlockProps {
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** If the parent element of the CodeBlock will have an adjustable width, setting this to true will allow the CodeBlock to appropriately adjust its width */
  canAdjustWidth?: boolean;
  /** Text content of the CodeBlock. */
  code: string;
  /** Default - 580px */
  height?: number;
  /** Currently supporting sql syntax, will allow for other options later on. */
  language?: "sql";
  /** Provide a call back to receive code changes if the editor is being updated by the user. */
  onChange?: (code: string) => void;
  /** Provide a call back to receive debounced code changes if the editor is being updated by the user. */
  onTypingComplete?: (code: string) => void;
  /** If there is code being provided that should not be changed or interacted with, set `isReadOnly` to `true` */
  isReadOnly?: boolean;
  /**
   * Provide a callback to be executed by the user to run the code block. This will render a button on top of the
   * editor for them to kick it off.
   */
  onRun?: (code: string) => void;
  /**
   * Whether or not we should disable the run button.
   */
  runButtonDisabled?: boolean;
  /**
   * A light or dark theme for visualizing the `CodeBlock` currently supporting "vs" and "vs-dark" themes.
   * This will be updated to support metronome colors/themes in the future.
   * */
  theme?: "light" | "dark";

  errors?: SQLValidationError[];

  /**
   * A completion provider for the code editor.
   */
  completionProvider?: monaco.languages.CompletionItemProvider;
}

/**
 * !! PARTIAL IMPLEMENTATION !!
 * CodeBlock is both an editor and a code viewer. You can drop it in to a page that will allow users
 * to provide their own SQL for a Billable Metric, or render an example API response for consumers of
 * an endpoint.
 */
export const CodeBlock: React.FC<CodeBlockProps> = ({
  className,
  code,
  height = 580,
  language = "sql",
  onChange,
  onTypingComplete,
  onRun,
  isReadOnly,
  theme = "light",
  canAdjustWidth = false,
  runButtonDisabled = false,
  errors,
  completionProvider,
}) => {
  const debouncedCode = useDebounce(code, 2000);

  useEffect(() => {
    if (onTypingComplete) {
      onTypingComplete(debouncedCode);
    }
  }, [debouncedCode, onTypingComplete]);

  return (
    <div
      className={twMerge("relative font-mono", className)}
      style={{
        height: `${height}px`,
      }}
    >
      {!!onRun && (
        <Button
          className="absolute right-[5px] top-[5px] z-[1]"
          text="Run"
          leadingIcon="play"
          theme="secondary"
          size="sm"
          onClick={() => onRun(code)}
          disabled={runButtonDisabled}
        />
      )}
      <ConditionalWrapper
        condition={canAdjustWidth}
        wrapper={(children) => (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {children}
          </div>
        )}
      >
        <MonacoEditor
          language={language}
          value={code}
          options={{
            codeLens: false,
            contextmenu: false,
            fontSize: 14,
            overviewRulerBorder: false,
            renderLineHighlight: "none",
            minimap: {
              enabled: false,
            },
            overviewRulerLanes: 0,
            language: language,
            padding: {
              top: !!onRun ? 45 : 24,
              bottom: 24,
            },
            readOnly: isReadOnly,
            wordWrap: "on",
            automaticLayout: true,
            quickSuggestions: {
              other: "on",
              comments: "on",
              strings: "on",
            },
          }}
          className={twMerge(
            "[&_div.view-lines_span]:font-mono",
            theme === "light"
              ? "border-gray-200 bg-white"
              : "border-core-slate bg-black",
          )}
          onChange={onChange}
          errors={errors ?? []}
          completionProvider={completionProvider}
        />
      </ConditionalWrapper>
    </div>
  );
};

type MonacoEditorProps = {
  value: string;
  language: string;
  options?: monaco.editor.IStandaloneEditorConstructionOptions;
  className: string;
  onChange?: (code: string) => void;
  errors: SQLValidationError[];
  completionProvider?: monaco.languages.CompletionItemProvider;
};

class MonacoEditor extends React.Component<MonacoEditorProps> {
  private _model?: monaco.editor.ITextModel;
  private _editor?: monaco.editor.IStandaloneCodeEditor;
  private _node: HTMLDivElement | null = null;
  private _completionProviderDisposable?: monaco.IDisposable;

  componentDidUpdate(prevProps: MonacoEditorProps): void {
    if (this._model) {
      monaco.editor.setModelMarkers(
        this._model,
        "",
        this.props.errors.map((err) => ({
          ...err,
          severity: monaco.MarkerSeverity.Error,
        })),
      );
    }

    if (this.props.completionProvider !== prevProps.completionProvider) {
      if (this._completionProviderDisposable) {
        this._completionProviderDisposable.dispose();
      }
      if (this.props.completionProvider) {
        this._completionProviderDisposable =
          monaco.languages.registerCompletionItemProvider(
            this.props.language,
            this.props.completionProvider,
          );
      }
    }
  }

  componentDidMount() {
    const { value, language, options, onChange, completionProvider } =
      this.props;
    if (completionProvider) {
      this._completionProviderDisposable =
        monaco.languages.registerCompletionItemProvider(
          this.props.language,
          completionProvider,
        );
    }
    this._model = monaco.editor.createModel(value, language);
    if (this._node) {
      this._editor = monaco.editor.create(this._node, options);
      this._editor.setModel(this._model);
    }

    this._model.onDidChangeContent(() => {
      if (this._model && onChange) {
        onChange(this._model.getValue());
      }
    });
  }

  componentWillUnmount() {
    this._editor?.dispose();
    this._completionProviderDisposable?.dispose();
  }

  render() {
    return (
      <div
        className={twMerge("h-full w-full", this.props.className)}
        ref={(c) => (this._node = c)}
      />
    );
  }
}
