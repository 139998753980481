import React from "react";
import { TextSkeleton } from "components/Skeleton";
import { useCustomerCountForPlanQuery } from "tenaissance/pages/Offering/tabs/Plans/queries.graphql";
import { Badge } from "tenaissance/components/Badge";

const StatusCell: React.FC<{ planId: string }> = ({ planId }) => {
  const { data, loading, error } = useCustomerCountForPlanQuery({
    variables: { plan_id: planId },
  });

  if (loading) {
    return <TextSkeleton />;
  }
  if (error) {
    return <span>-</span>;
  }
  const customerCount = data?.Plan_by_pk?.customer_count ?? 0;
  if (customerCount > 0) {
    return <Badge label="Active" theme="success" />;
  } else {
    return <Badge label="Published" theme="azure-blue" />;
  }
};

export default StatusCell;
