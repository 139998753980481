export function upsertById<T extends { id: any }>(arr: T[], item: T) {
  let found = false;

  const newArr = arr.map((i) => {
    if (i.id === item.id) {
      found = true;
      return item;
    } else {
      return i;
    }
  });

  return found ? newArr : [...newArr, item];
}
