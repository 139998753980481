import React from "react";
import { Navigate, RouteObject, useLocation } from "react-router-dom";

import { useAuth } from "lib/auth";

import { BillableMetricRoutes } from "pages/BillableMetric";
import { GeneralSettingsRoute } from "pages/GeneralSettings";
import StripeRedirect from "pages/StripeRedirect";
import NotFoundPage from "pages/404";
import { NewMetricRoutes } from "pages/NewBillableMetric/BillableMetricV2";
import { PlanRoute } from "pages/Plan";
import Account from "pages/Account";
import { EnvSlug$ } from "lib/environmentSwitcher/context";
import { ContractCustomersRoutes } from "pages/Contracts/ContractRoutes";
import Reporting from "./pages/Reporting";
import { ConnectionsRoutes } from "tenaissance/pages/Connections";
import { OfferingRoutes } from "tenaissance/pages/Offering";
import { SettingsRoutes } from "./tenaissance/pages/Settings";
import { EnvironmentRedirect } from "lib/environmentSwitcher/EnvironmentRedirect";

const CustomFieldsRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/custom-fields([\/?#]|$)/,
    "/connections/custom-fields$1",
  );
  return <Navigate to={newPath} replace />;
};

const Logout: React.FC = () => {
  const auth = useAuth();
  auth.logout();
  return null;
};

const ENV_SLUGS = ["/sandbox", "/env-uat", "/env-qa", "/env-staging"];

/**
 * Routes that exist both in and out of sandbox mode.
 */
const EnvRoutes: RouteObject[] = [
  ...ContractCustomersRoutes,

  GeneralSettingsRoute,
  ...OfferingRoutes,
  ...ConnectionsRoutes,
  ...SettingsRoutes,
  {
    path: "settings/stripe/redirect_callback/*",
    element: <StripeRedirect />,
  },
  {
    path: "developer/events/*",
    element: <EnvironmentRedirect to="/connections/events" />,
  },
  {
    path: "developer/tokens",
    element: <EnvironmentRedirect to="/connections/api-tokens-webhooks" />,
  },
  {
    path: "developer/webhooks",
    element: <EnvironmentRedirect to="/connections/api-tokens-webhooks" />,
  },
  {
    path: "products",
    element: <EnvironmentRedirect to="/offering/products" />,
  },
  {
    path: "products/new",
    element: <EnvironmentRedirect to="/offering/products/create" />,
  },
  {
    path: "products/:id/edit",
    element: <EnvironmentRedirect to="/offering/products" />,
  },
  {
    path: "products/:id",
    element: <EnvironmentRedirect to="/offering/products" />,
  },
  {
    path: "billable-metrics",
    element: <EnvironmentRedirect to="/offering/billable-metrics" />,
  },
  {
    path: "billable-metrics/seats",
    element: <EnvironmentRedirect to="/offering/billable-metrics/seats" />,
  },
  ...NewMetricRoutes,
  ...BillableMetricRoutes,
  {
    path: "plans",
    element: <EnvironmentRedirect to="/offering/plans" />,
  },
  {
    path: "plans/new/:id?",
    element: <EnvironmentRedirect to="/offering/plans/new" />,
  },
  {
    path: "plans/edit/:id",
    element: <EnvironmentRedirect to="/offering/plans" />,
  },
  PlanRoute,
  {
    path: "alerts",
    element: <EnvironmentRedirect to="/connections/alerts" />,
  },
  {
    path: "alerts/new",
    element: <EnvironmentRedirect to="/connections/alerts/new" />,
  },
  {
    path: "alerts/:id",
    element: <EnvironmentRedirect to="/connections/alerts" />,
  },
  {
    path: "reports",
    element: <EnvironmentRedirect to="/reporting" />,
  },
  {
    path: "reporting",
    element: <Reporting />,
  },
  {
    path: "custom-fields/:entity/:id",
    element: <CustomFieldsRedirect />,
  },
  // Data Export edit.  Yes data export can only be done once per client, but confluent
  // has a special client which doesnt have the PRODUCTION metronome env enabled
  // so this 404s.  But they also cant run the one at root because then it will use
  // the production scoped jwt, which doesnt have perms to data export :(
  {
    path: "settings/general/integrations/data-export/update/:destinationId",
    element: <EnvironmentRedirect to="/connections/data-export" />,
  },
];

export const AppRoutes: RouteObject[] = [
  /* --------------------------------------------------- */
  /* Redirects from old pages to new pages */
  /* --------------------------------------------------- */
  {
    path: "/developer/integration-pipeline/*",
    element: <EnvironmentRedirect to="/developer/events" />,
  },
  {
    path: "/sandbox/developer/integration-pipeline/*",
    element: <EnvironmentRedirect to="/developer/events" />,
  },
  {
    path: "/settings/billing-configuration",
    element: <EnvironmentRedirect to="/settings/general/integrations" />,
  },
  {
    path: "/settings/billing-configuration/billing-providers",
    element: <EnvironmentRedirect to="/settings/general/integrations" />,
  },
  {
    path: "/settings/billing-configuration/pricing-units",
    element: <EnvironmentRedirect to="/settings/general/pricing-units" />,
  },
  {
    path: "/settings/stripe",
    element: <EnvironmentRedirect to="/settings/general/integrations" />,
  },

  /* --------------------------------------------------- */
  /* Routes that don't exist in sandbox mode */
  /* --------------------------------------------------- */
  {
    path: "/settings/general/integrations/data-export/new",
    element: <EnvironmentRedirect to="/connections/data-export" />,
  },
  {
    path: "/account",
    element: <Account />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },

  /* --------------------------------------------------- */
  /* Routes that exist in and outside environments */
  /* --------------------------------------------------- */
  ...ENV_SLUGS.map(
    (slug): RouteObject => ({
      path: `${slug}/*`,
      handle: {
        [EnvSlug$]: slug,
      },
      children: [...EnvRoutes, { path: "*", element: <NotFoundPage /> }],
    }),
  ),
  // include the default "PRODUCTION" environment
  {
    path: "/*",
    handle: {
      [EnvSlug$]: "",
    },
    children: [...EnvRoutes, { path: "*", element: <NotFoundPage /> }],
  },
];
