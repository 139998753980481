import React from "react";

type Mode = "invoice" | "discount";

const ctx = React.createContext<Mode>("invoice");

export function useMode() {
  return React.useContext(ctx);
}

export const ModeProvider = ctx.Provider;
