import {
  RolloverAmountFormData,
  RolloverAmountInput,
  rolloverSettingsInputSchema,
} from "lib/rolloverSettings";
import { Label, Select } from "design-system";
import React, { useState } from "react";
import { CreditInput } from "components/Input";
import Decimal from "decimal.js";
import { PercentageInput } from "components/PercentageInput";
import { CreditType } from "types/credit-types";

type RolloverAmountInputsProps = {
  value?: RolloverAmountFormData;
  onChange: (rolloverAmount: RolloverAmountInput) => void;
  onError?: (error: string) => void;
  pricingUnit: CreditType;
};

export const RolloverAmountInputs: React.FC<RolloverAmountInputsProps> = (
  props,
) => {
  const [amountInput, setAmountInput] = useState<
    RolloverAmountFormData | undefined
  >(props.value ?? { max_amount: undefined });

  let amountType: string | undefined;
  let amountValue: string | undefined;
  if (amountInput) {
    if ("max_percentage" in amountInput) {
      amountType = "MAX_PERCENTAGE";
      amountValue = amountInput.max_percentage?.value;
    } else if ("max_amount" in amountInput) {
      amountType = "MAX_AMOUNT";
      amountValue = amountInput.max_amount?.value;
    } else {
      throw new Error(`Unknown rollover amount input '${amountInput}'`);
    }
  }

  const handleChange = (newAmount: RolloverAmountFormData) => {
    setAmountInput(newAmount);
    const result =
      rolloverSettingsInputSchema.shape.rollover_amount.safeParse(newAmount);
    if (result.success) {
      props.onChange(result.data);
    } else {
      props.onError?.("Invalid rollover amount config");
    }
  };

  return (
    <div>
      <Label className="text-gray-600">Rollover amount</Label>
      <div className="flex flex-col gap-16">
        <div className="flex gap-8">
          {amountType === "MAX_AMOUNT" && (
            <CreditInput
              className="w-full"
              placeholder=""
              creditType={props.pricingUnit}
              initialValue={amountValue}
              disabled={amountType === undefined}
              onChange={(value) => {
                handleChange({
                  max_amount: {
                    value:
                      value !== null
                        ? new Decimal(value).toString()
                        : undefined,
                  },
                });
              }}
            />
          )}
          {amountType === "MAX_PERCENTAGE" && (
            <PercentageInput
              className="w-full"
              placeholder=""
              value={
                amountValue ? new Decimal(amountValue).times(100) : undefined
              }
              disabled={amountType === undefined}
              onChange={(percent) => {
                handleChange({
                  max_percentage: { value: percent.div(100).toString() },
                });
              }}
              onError={(error) => {
                props.onError?.(error);
              }}
            />
          )}
          <Select
            className="w-full"
            placeholder="Rollover type"
            value={amountType ?? ""}
            options={[
              { label: "amount of original grant", value: "MAX_AMOUNT" },
              { label: "% of original grant", value: "MAX_PERCENTAGE" },
            ]}
            onChange={(option) => {
              if (option === "MAX_PERCENTAGE") {
                handleChange({ max_percentage: {} });
              } else if (option === "MAX_AMOUNT") {
                handleChange({ max_amount: {} });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
