import React from "react";

import { Body, Headline, Icon } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { RightPane } from "components/Popup";
import { SelectableCards } from "components/SelectableCards";
import { Charge } from "pages/PlanWizards/steps/Pricing/components/CompositeChargePane";
import styles from "./index.module.less";

interface CompositePricingFactorsPaneProps {
  onRequestClose: () => void;
  name: string;
  compositePricingFactorIds: string[];
  pricingFactorsByProduct: {
    name: string;
    productPricingFactors: Charge[];
  }[];
}

export const CompositePricingFactorsPane: React.FC<
  CompositePricingFactorsPaneProps
> = ({
  onRequestClose,
  name,
  compositePricingFactorIds,
  pricingFactorsByProduct,
}) => {
  const selectedPricingFactorsByProduct = pricingFactorsByProduct
    // Filter out products that have no pricing factors associated with the composite charge
    .filter(
      (product) =>
        product.productPricingFactors.filter((ppf) =>
          compositePricingFactorIds.includes(ppf.id),
        ).length,
    )
    .map((product) => ({
      name: product.name,
      // Filter out pricing factors that aren't associated with the composite charge
      productPricingFactors: product.productPricingFactors.filter((ppf) =>
        compositePricingFactorIds.includes(ppf.id),
      ),
    }));

  return (
    <RightPane
      isOpen={true}
      onRequestClose={onRequestClose}
      className={styles.popup}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Headline level={6}>Composite charge</Headline>
          <div>
            <IconButton
              onClick={onRequestClose}
              theme="secondary"
              icon="xClose"
            />
          </div>
        </div>
        <div className={styles.popupContent}>
          <div>
            <Body level={2}>Charges associated with {name}</Body>
          </div>
          <div>
            {selectedPricingFactorsByProduct.map((product, i) => {
              return (
                <div className={styles.charges} key={i}>
                  <div className={styles.productName}>{product.name}</div>
                  <SelectableCards
                    cards={product.productPricingFactors.map((ppf) => {
                      return {
                        id: ppf.id,
                        content: ppf.name,
                        icon: (
                          <Icon
                            icon="checkmarkCircle"
                            className={styles.selected}
                          />
                        ),
                        selected: true,
                      };
                    })}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </RightPane>
  );
};
