// This function is borrowed from the api repo. The frontend generates these to
// use in documentation links. The API then repeats the process and returns the
// appropriate client-specific documentation.

import { base32 } from "rfc4648";
import crypto from "crypto";
import { Buffer } from "buffer";
import { useCurrentUser } from "./auth";
import { useUIMode } from "./useUIMode";

// We're just using a "secret" here to key the HMAC. This is simply to avoid
// collisions. No need for anything secure here. This secret is shared with the
// API (which validates these).
const INSECURE_SECRET = "aAu0KOAY6Kw0wGcF";
function generateDocsToken(clientID: string, nonce: number = 0): string {
  const hmac = crypto.createHmac("sha256", INSECURE_SECRET);
  hmac.update(new Uint8Array([nonce]));
  hmac.update(new Uint8Array(Buffer.from(clientID)));

  return base32
    .stringify(
      Buffer.concat([
        new Uint8Array(Buffer.from([nonce])),
        new Uint8Array(hmac.digest().subarray(0, 4)),
      ]),
      { pad: false },
    )
    .toLowerCase();
}

export function useDocsLink(paths?: {
  contractsPath?: string;
  plansPath?: string;
}): string {
  const { clientID } = useCurrentUser();
  const { mode } = useUIMode();

  const useContracts =
    // contracts only OR
    mode === "contracts-only" ||
    // can go either way and there's a contracts path (or no path at all)
    (mode == "contracts-and-plans" &&
      (paths?.contractsPath !== undefined || paths?.plansPath === undefined));

  const [path, fragment] = (
    (useContracts ? paths?.contractsPath : paths?.plansPath) ?? ""
  ).split("#");

  const token = generateDocsToken(clientID ?? "");

  const trimmed = path.replace(/^\//, "");

  return `https://${!useContracts ? "plans-" : ""}docs.metronome.com/${trimmed}?client_id=${token}${fragment ? `#${fragment}` : ""}`;
}
