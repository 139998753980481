import React from "react";
import { type RouteObject } from "react-router";

import NotFoundPage from "pages/404";

import DataExportTab from "./components/DataExportTab";
import IntegrationsTab from "./components/IntegrationsTab";
import CustomFieldsTab from "./components/CustomFieldsTab";
import PricingUnitsTab from "./components/PricingUnitsTab";
import InvoicingTab from "./components/InvoicingTab";
import { TabProps } from "components/Tabs";
import { AuditLogsTab } from "./components/AuditLogsTab";
import { EnvironmentRedirect } from "lib/environmentSwitcher/EnvironmentRedirect";

const tabs = [
  {
    link: "integrations",
    name: "Integrations",
    component: IntegrationsTab,
  },
  {
    link: "data-export",
    name: "Data Export",
    component: DataExportTab,
  },
  {
    link: "custom-fields",
    name: "Custom fields",
    component: CustomFieldsTab,
  },
  {
    link: "invoicing",
    name: "Invoicing",
    component: InvoicingTab,
  },
  {
    link: "pricing-units",
    name: "Pricing units",
    component: PricingUnitsTab,
  },
  {
    link: "audit-logs",
    name: "Audit logs",
    component: AuditLogsTab,
  },
];

export const INVOICING_TAB_FEATURE_FLAG = "invoicing-settings-tab";

export class TabFilter {
  invoicingTabEnabled: boolean;
  constructor(invoicingTabEnabled: boolean) {
    this.invoicingTabEnabled = invoicingTabEnabled;
  }

  filter = (t: TabProps): boolean => {
    return !t.routePath?.endsWith("invoicing") || this.invoicingTabEnabled;
  };
}

export const GeneralSettingsRoute: RouteObject = {
  path: "settings/general",
  children: [
    { index: true, element: <EnvironmentRedirect to="integrations" /> },
    ...tabs.map((tab) => ({
      ...(tab.link ? { path: tab.link } : { index: true }),
      element: <EnvironmentRedirect to="/connections/events" />,
    })),
    { path: "*", element: <NotFoundPage /> },
  ],
};
