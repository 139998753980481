import React, { useCallback, useState } from "react";

import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { twMerge } from "tenaissance/twMerge";
import { Modal, ModalProps } from "../Modal";
import { TextInput } from "../Input";

type PartialModalProps = Pick<ModalProps, "icon" | "supportingText">;

interface EditNameButtonProps {
  /** allow an empty string for name */
  allowEmpty?: boolean;
  className?: string;
  currentName: string;
  label: string;
  loading: boolean;
  title: string;
  updateName: (name: string) => Promise<void>;
  modalOptions?: PartialModalProps;
  hintText?: string;
  placeholderText?: string;
}

export const EditNameButton: React.FC<EditNameButtonProps> = ({
  allowEmpty,
  className,
  currentName,
  label,
  loading,
  title,
  updateName,
  modalOptions = {},
  hintText,
  placeholderText,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState(currentName);
  const { icon = "pencil01", supportingText } = modalOptions;
  const [prevCurrentName, setPrevCurrentName] = useState<string>();
  if (currentName !== prevCurrentName) {
    setPrevCurrentName(currentName);
    setName(currentName);
  }

  const save = async () => {
    if (!name && !allowEmpty) {
      return;
    }
    await updateName(name);
    setIsModalOpen(false);
  };
  const close = async () => {
    setName(currentName);
    setIsModalOpen(false);
  };

  const ref = useCallback((node: HTMLInputElement) => {
    if (node) {
      node.select();
    }
  }, []);

  const iconButtonClassNames = twMerge("hover:bg-transparent", className);

  return (
    <>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={close}
          icon="checkCircle"
          title={title}
          supportingText={supportingText}
          modalButtons={[
            <Button
              disabled={(!name?.length && !allowEmpty) || loading}
              loading={loading}
              onClick={save}
              text="Save"
              theme="primary"
            />,
            <Button
              onClick={close}
              disabled={loading}
              text="Cancel"
              theme="secondary"
            />,
          ]}
        >
          <TextInput
            hintText={hintText}
            label={label}
            placeholder={placeholderText}
            value={name}
            onChange={(meta) => setName(meta.value)}
            ref={ref}
          />
        </Modal>
      )}
      <IconButton
        onClick={() => setIsModalOpen(true)}
        theme="tertiary"
        icon={icon}
        size="sm"
        className={iconButtonClassNames}
      />
    </>
  );
};
