import React, { useState } from "react";

import styles from "./index.module.less";

import { Icon, Subtitle } from "design-system";

import classnames from "classnames";

interface TipBoxProps {
  title: React.ReactNode;
  body: React.ReactNode;
}

interface TipBoxInternalProps extends TipBoxProps {
  top: number;
  isFloating: boolean;
}

export const TipBox: React.FC<TipBoxInternalProps> = ({
  title,
  body,
  isFloating = false,
  top,
}) => {
  return (
    <div
      className={classnames(styles.tipBox, {
        [styles.floating]: isFloating,
      })}
      style={{ top: top }}
    >
      <div className={styles.left}>
        <Icon icon="bulb" className={styles.tipIcon} />
      </div>
      <div className={styles.right}>
        <Subtitle level={2} className={styles.tipTitle}>
          {title}
        </Subtitle>
        <div className={styles.tipBody}>{body}</div>
      </div>
    </div>
  );
};

type Tips = [
  setTip: (tip: TipBoxProps | null) => void,
  tipsComponent: React.ReactNode,
];

const TipsContext = React.createContext<Tips>([() => {}, null]);

export const TipsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [tipBoxProps, setTipBoxProps] = useState<TipBoxProps | null>(null);
  const [tipPos, setTipPos] = useState<number>(0);

  return (
    <TipsContext.Provider
      value={[
        setTipBoxProps,
        tipBoxProps ? (
          <TipBox {...tipBoxProps} isFloating={true} top={tipPos} />
        ) : null,
      ]}
    >
      <div
        className="flex h-screen w-screen flex-col overflow-hidden"
        onFocusCapture={(e) => {
          // This moves the tip to be vertically aligned with the focused element.
          setTipPos(e.target.getBoundingClientRect().top);
        }}
      >
        {children}
      </div>
    </TipsContext.Provider>
  );
};
