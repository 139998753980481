import { PageContainer } from "components/PageContainer";
import { SortFunctions, Table } from "components/Table";
import React, { useState } from "react";
import { CreateUserModal } from "./components/CreateUserModal";

import styles from "./index.module.less";

import { Avatar, Tooltip } from "design-system";
import { useCurrentUser } from "lib/auth";
import { useFeatureFlag } from "lib/launchdarkly";
import { renderDateTime } from "lib/time";
import { UserAuthTypeEnum_Enum } from "types/generated-graphql/__types__";
import { ArchiveUserButton } from "./components/ArchiveUserButton";
import {
  CreateUserDocument,
  GetAllUsersDocument,
  useGetAllUsersQuery,
} from "./queries.graphql";
import { GatedButton } from "components/GatedButton";

const Team: React.FC<{}> = () => {
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const { data, loading, refetch } = useGetAllUsersQuery();
  const { user: currentUser } = useCurrentUser();
  const ssoEnabled = useFeatureFlag("sso-entitlement", false);

  const addButton = (
    <Tooltip
      content="Clients with SSO enabled can not invite new users through Metronome"
      disabled={!ssoEnabled}
    >
      <GatedButton
        doc={CreateUserDocument}
        onClick={() => setCreateUserModalOpen(true)}
        disabled={ssoEnabled ?? true}
        text="Add user"
        theme="primary"
        leadingIcon="plus"
        size="sm"
      />
    </Tooltip>
  );

  return (
    <>
      {createUserModalOpen && (
        <CreateUserModal
          onClose={() => setCreateUserModalOpen(false)}
          refetchUsers={refetch}
          existingUserEmailDomains={
            new Set(
              (data?.all_users ?? []).map(
                (user) => user.email.split("@").slice(-1)[0],
              ),
            )
          }
        />
      )}
      <PageContainer
        title="Team"
        action={addButton}
        authDoc={GetAllUsersDocument}
      >
        <Table
          loading={loading}
          data={data?.all_users || []}
          getRowTheme={(u) =>
            u.deprecated_at === null ? "enabled" : "disabled"
          }
          maxPageSize={30}
          defaultSortBy={[
            {
              id: "name",
              desc: true,
            },
          ]}
          columns={[
            {
              id: "name",
              header: "Name",
              render: (u) => (
                <div className={styles.userNameCell}>
                  <Avatar userName={u.name} size={22} userID={u.id} />
                  <div className={styles.userName}>{u.name}</div>
                </div>
              ),
              sortable: true,
              comparator: SortFunctions.String((u) => u.name),
              textWrappable: true,
            },
            {
              id: "email",
              header: "Email",
              render: (u) => u.email,
              sortable: true,
              comparator: SortFunctions.String((u) => u.email),
            },
            {
              header: "Role",
              id: "role",
              sortable: true,
              comparator: SortFunctions.String((u) => u.role ?? ""),
              render: (u) => u.role,
            },
            {
              header: "Created",
              id: "createdAt",
              render: (u) => renderDateTime(new Date(u.created_at), true),
              sortable: true,
              comparator: SortFunctions.Date((u) => new Date(u.created_at)),
              align: "left",
            },
            {
              header: "",
              id: "actions",
              render: (u) =>
                u.deprecated_at === null ? (
                  <ArchiveUserButton
                    user={u}
                    disabled={
                      u.id === currentUser?.id ||
                      u.auth_type === UserAuthTypeEnum_Enum.Saml
                    }
                  />
                ) : (
                  `Deactivated on ${renderDateTime(
                    new Date(u.deprecated_at),
                    false,
                  )}`
                ),
              align: "right",
            },
          ]}
        />
      </PageContainer>
    </>
  );
};

export default Team;
