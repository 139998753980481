import React, { useState } from "react";
import { Popup } from "components/Popup";
import { Body, NumericInput } from "design-system";

import { Button } from "tenaissance/components/Button";

interface TierResetFrequencyModalProps {
  initialValue: number | undefined;
  onRequestClose: (resetFrequency?: number) => void;
}

export const TierResetFrequencyModal: React.FC<
  TierResetFrequencyModalProps
> = ({ initialValue, onRequestClose }) => {
  const [resetFrequency, setResetFrequency] = useState<number | undefined>(
    initialValue ?? 1,
  );

  const isValid = resetFrequency !== undefined && resetFrequency >= 0;

  return (
    <Popup
      actions={[
        <Button
          onClick={() => onRequestClose()}
          key="cancel-button"
          text="Cancel"
          theme="linkGray"
        />,
        <Button
          onClick={() => {
            if (isValid) {
              onRequestClose(resetFrequency);
            }
          }}
          disabled={!isValid}
          key="save-button"
          text="Save"
          theme="primary"
        />,
      ]}
      title="Edit tier reset period"
      isOpen={true}
      onRequestClose={() => onRequestClose()}
    >
      <Body level={2} className="mb-12">
        Customize the number of billing periods until the usage associated with
        a tiered price resets to zero. For example, if your billing period is
        set to monthly and you want tier usage to reset to zero only after 12
        months (i.e. 12 billings periods), then set the value to 12.
      </Body>
      <Body level={2} className="mb-4 font-medium">
        Reset tier usage every...
      </Body>
      <div className="mb-12 flex items-center">
        <NumericInput
          placeholder="1"
          value={resetFrequency}
          onChange={(v) => {
            setResetFrequency(v ?? undefined);
          }}
          error={!isValid ? "Must be a non negative integer" : undefined}
          className="mr-12 w-1/6"
        />
        <Body level={2} noBottomMargin>
          {" "}
          billing periods
        </Body>
      </div>
      <Body level={2} className="mb-4">
        Note: Enter 0 to never reset usage during the customer's plan duration
      </Body>
    </Popup>
  );
};
