import React from "react";
import "style/index.css";
import { useNow } from "lib/date";

import { type Dayjs, dayjs } from "lib/dayjs";
import { useUIMode } from "lib/useUIMode";
import {
  InvoiceDateFragment,
  RecentInvoiceFragment,
  useRecentCustomerInvoicesQuery,
} from "pages/Contracts/Customer/BillingOverview/data.graphql";
import { useRequiredParam } from "lib/routes/params";
import { Column, Table } from "tenaissance/components/Table";
import { Timestamp } from "tenaissance/components/Timestamp";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Card } from "tenaissance/components/Card";
import { Invoice } from "pages/Contracts/lib/Invoice";
import { Tooltip } from "tenaissance/components/Tooltip";
import { StatusPills } from "./StatusPills";
import Decimal from "decimal.js";
import { RoundedCurrency } from "lib/credits";

function toDateRange(now: Dayjs): {
  startDate: string | null;
  endDate: string | null;
} {
  return {
    startDate: now.subtract(6, "months").startOf("month").toISOString(),
    endDate: now.toISOString(),
  };
}

function renderDate(invoice: InvoiceDateFragment): React.ReactElement {
  switch (invoice.__typename) {
    case "AdHocPlanInvoice":
    case "ArrearsInvoice":
    case "ContractUsageInvoice":
    case "AdhocContractUsageInvoice":
      return (
        <Timestamp
          dateTime={dayjs.utc(invoice.inclusive_start_date).toDate()}
          endDateTime={dayjs.utc(invoice.exclusive_end_date).toDate()}
        />
      );

    case "AdvanceInvoice":
    case "ContractPostpaidTrueupInvoice":
    case "ContractRefundInvoice":
    case "ContractScheduledInvoice":
    case "ContractProServiceInvoice":
    case "CorrectionInvoice":
    case "CreditPurchaseInvoice":
    case "ParentInvoice":
    case "SeatPurchaseInvoice":
      return <Timestamp dateTime={dayjs.utc(invoice.issued_at).toDate()} />;
  }
}

export const InvoicesTable: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const { mode } = useUIMode();
  const now = useNow();
  const today = now.startOf("day");
  const { startDate, endDate } = toDateRange(today.add(1, "day"));
  const recentInvoicesReq = useRecentCustomerInvoicesQuery({
    variables: {
      customerId,
      inclusiveStartDate: startDate,
      exclusiveEndDate: endDate,
    },
  });

  if (
    recentInvoicesReq.loading === false &&
    recentInvoicesReq.data?.Customer_by_pk?.recentInvoices.invoices.length === 0
  ) {
    // customer has no invoices yet
    return (
      <Card title="Recent invoices">
        <div className="my-3xl flex w-full flex-col items-center text-gray-600">
          <h3 className="text-md font-semibold text-black">No invoices</h3>
          <div>This customer has no invoices for the last 6 months</div>
        </div>
      </Card>
    );
  }

  const tableColumns: Column<RecentInvoiceFragment>[] = [
    {
      id: "1",
      accessorFn: (row) => renderDate(row),
      header: "Invoice",
      size: 100,
      cell: (props) => props.getValue(),
      supportingText:
        mode !== "plans-only"
          ? (rowData) => {
              try {
                return (
                  <div
                    title={Invoice.getContractOrPlanName(rowData)}
                    className="ml-md max-w-[300px] truncate"
                  >
                    {Invoice.getContractOrPlanName(rowData)}
                  </div>
                );
              } catch (e) {
                return (
                  <Tooltip label="We are unable to render invoices of this type at this time">
                    <div
                      title={Invoice.getContractOrPlanName(rowData)}
                      className="max-w-[300px] truncate"
                    >
                      {Invoice.getContractOrPlanName(rowData)}
                    </div>
                  </Tooltip>
                );
              }
            }
          : undefined,
    },
    {
      id: "3",
      accessorKey: "total",
      header: "Total",
      cell: (props) => {
        const amount = new Decimal(props.getValue());
        const creditType = props.row.original.credit_type;
        return <RoundedCurrency amount={amount} creditType={creditType} />;
      },
    },
    {
      id: "status",
      isDisplay: true,
      header: "Status",
      cell: (props) => (
        <StatusPills stackVertically={false} invoice={props.row.original} />
      ),
    },
  ];

  return (
    <Table
      title="Recent invoices"
      paginationOptions={{
        type: "clientSide",
        pageSize: 10,
      }}
      emptyState={<EmptyState icon="searchSm" mainText="No invoices" />}
      loading={recentInvoicesReq.loading}
      columns={tableColumns}
      data={
        recentInvoicesReq.data?.Customer_by_pk?.recentInvoices.invoices ?? []
      }
      rowRoutePath={(row) => {
        try {
          return Invoice.getRoutePath(row.original);
        } catch (e) {
          return undefined;
        }
      }}
    />
  );
};
