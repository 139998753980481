import React, { useState } from "react";

import { PricingUnitSelector } from "components/PricingUnitSelector";
import { Tooltip } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Caption, Headline, Subtitle } from "design-system";
import { getRamps, getRampStartPeriod } from "lib/plans/ramps";
import {
  BlockConfiguration,
  CollectionSchedule,
  CompositeCharge,
  DraftPlan,
  FlatFee,
  Price,
  PricedPricingFactor,
  PricedProduct,
  PriceRamp,
} from "lib/plans/types";
import { CustomCreditType, FiatCreditType } from "types/credit-types";
import {
  BillingFrequencyEnum_Enum,
  ChargeTypeEnum_Enum,
  SeatPrice,
} from "types/generated-graphql/__types__";
import { useDraftPlan } from "../../../../context";
import { CreatePlanDataQuery } from "../../../../data/queries.graphql";
import {
  getProductPricingFactorForRamp,
  getUpdateBlockPricingMerged,
  getUpdateCompositeChargeMerged,
  getUpdateCreditTypeForProductRampMerged,
  getUpdateFlatFeeMerged,
  getUpdatePricedProductMerged,
  getUpdateSeatPriceMerged,
  reorderProduct,
} from "../../actions";
import { AdvanceChargePane } from "../AdvanceChargePane";
import styles from "./index.module.less";
import { PriceProductTableRow } from "./row";
import {
  CompositeChargePane,
  ProductWithPricedProduct,
} from "../CompositeChargePane";
import { useSnackbar } from "components/Snackbar";

interface PriceProductTableProps {
  rampStart: number;
  rampDuration: number | undefined;
  rampIndex: number;
  ramps: PriceRamp[];
  product: CreatePlanDataQuery["products"][0];
  pricedProduct: PricedProduct;
  // When pricing a composite charge, the user must select which product pricing factors
  // the composite charge will depend on.
  allProducts: ProductWithPricedProduct[];
  creditTypes: CreatePlanDataQuery["CreditType"];
  autoFocus: boolean;
  hasCustomPricing?: boolean;
  editing?: boolean;
  // for ordering purposes
  index: number;
}

export const pricingFactorStartPeriodSort = (
  a: PricedPricingFactor,
  b: PricedPricingFactor,
) => {
  if (a.startPeriod === undefined) {
    return 1;
  } else if (b.startPeriod === undefined) {
    return -1;
  }

  if (a.startPeriod < b.startPeriod) {
    return -1;
  } else if (a.startPeriod > b.startPeriod) {
    return 1;
  }
  return 0;
};
/**
 * This function returns the start period of a collection interval per ramp (for an advanced charge)
 * For example if a plan had ramps like: [3, 3, 3, 3, infinity] and the collection intervals were [6, 6, 3, 1, 12]
 * this function would return [0, 0, 6, 9, 12] the elements of this array represent the start periods of each
 * collection interval.
 *
 * With this data it's much simpler to calculate if something is a skip ramp or in flight.
 */
export const rampToCollectionIntervalStartMap = (
  pricingFactors: PricedPricingFactor[],
) => {
  const sortedPricingFactors = [...pricingFactors].sort(
    pricingFactorStartPeriodSort,
  );
  const intervals = sortedPricingFactors.map((pf) => ({
    startPeriod: pf.startPeriod,
    collectionInterval: pf.flatFees?.[0]?.collectionInterval,
  }));

  let currentCollectionIntervalStartRamp: number | undefined;
  let currentCollectionInterval: number | undefined;
  return intervals.map((interval) => {
    if (currentCollectionInterval === interval.collectionInterval) {
      return currentCollectionIntervalStartRamp;
    } else {
      currentCollectionInterval = interval.collectionInterval;
      currentCollectionIntervalStartRamp = interval.startPeriod;
      return interval.startPeriod;
    }
  });
};

/**
 * This function checks whether or not a given pricing factor will be a skip ramp. One simple case is the
 * one-time charge case which will skip every ramp after creation. For multi-ramp collection intervals
 * it is more complicated since we need to skip ramps while an advanced charge is "in-flight".
 *
 * An example: For a ramp schedule of 3, 3, 3, 3, infinity and a collection interval of 12.
 * The second through fourth ramps would be considered "in-flight" because these billing periods have been paid for
 */
export const isSkipRamp = (
  pricingFactors: PricedPricingFactor[],
  pricingFactorId: string,
  rampStartPeriod: number,
  rampIndex: number,
  collectionInterval: number | undefined,
  collectionSchedule: CollectionSchedule | undefined,
) => {
  const targetPricingFactors = pricingFactors.filter(
    (pf) => pf.pricingFactorId === pricingFactorId,
  );
  if (collectionSchedule === "ONE_TIME_ADVANCE") {
    // On time advanced charges are only collected in the first ramp
    return rampStartPeriod !== 0;
  } else if (
    collectionInterval === undefined ||
    collectionSchedule !== "ADVANCE"
  ) {
    // If it doesn't have a collection interval or it's not an advanced charge do not skip
    return false;
  }

  const intervalSchedule =
    rampToCollectionIntervalStartMap(targetPricingFactors);
  const periodsSinceLastIntervalChange =
    rampStartPeriod - (intervalSchedule[rampIndex] ?? 0);

  return periodsSinceLastIntervalChange % collectionInterval !== 0;
};

export const PriceProductTable = React.forwardRef<
  HTMLDivElement,
  PriceProductTableProps
>((props, ref) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const [advanceChargePaneState, setAdvanceChargePaneState] = useState<{
    fees: FlatFee[];
    pricingFactorId: string;
  } | null>(null);
  const [compositeChargePaneState, setCompositeChargePaneState] = useState<{
    initialCompositeCharge: CompositeCharge[];
    pricingFactorId: string;
  } | null>(null);
  const productCreditType = props.pricedProduct.creditType;
  const onChangeUsageTiers = (
    newTiers: Price[],
    volumePricing: boolean | undefined,
    pricingFactorId: string,
    tierResetFrequency: number | undefined,
  ) => {
    setDraftPlan(
      getUpdatePricedProductMerged(
        newTiers,
        volumePricing,
        tierResetFrequency,
        props.product.id,
        pricingFactorId,
        props.rampStart,
        draftPlan,
      ),
    );
  };
  const onChangeFlatFee = (flatFees: FlatFee[], pricingFactorId: string) => {
    setDraftPlan(
      getUpdateFlatFeeMerged(
        flatFees,
        props.product.id,
        pricingFactorId,
        props.rampStart,
        draftPlan,
      ),
    );
  };
  const onChangeSeatPrice = (
    seatPrices: SeatPrice[],
    pricingFactorId: string,
  ) => {
    setDraftPlan(
      getUpdateSeatPriceMerged(
        seatPrices,
        props.product.id,
        pricingFactorId,
        props.rampStart,
        draftPlan,
      ),
    );
  };
  const onChangeCompositeCharge = (
    compositeCharge: CompositeCharge[],
    pricingFactorId: string,
  ) => {
    setDraftPlan(
      getUpdateCompositeChargeMerged(
        compositeCharge,
        props.product.id,
        pricingFactorId,
        props.rampStart,
        draftPlan,
      ),
    );
  };

  const onChangeBlockPricing = (
    blockPricing: BlockConfiguration | undefined,
    pricingFactorId: string,
  ) => {
    setDraftPlan(
      getUpdateBlockPricingMerged(
        blockPricing,
        props.product.id,
        pricingFactorId,
        props.rampStart,
        draftPlan,
      ),
    );
  };

  const onReorderProduct = (direction: "up" | "down") => {
    setDraftPlan(reorderProduct(draftPlan, props.product.id, direction));
  };

  const pushMessage = useSnackbar();

  return (
    <div className={styles.container} ref={ref}>
      {advanceChargePaneState && (
        <AdvanceChargePane
          onRequestClose={() => setAdvanceChargePaneState(null)}
          onRequestSave={(pricingFactorId, flatFees) => {
            onChangeFlatFee(flatFees, pricingFactorId);
            setAdvanceChargePaneState(null);
          }}
          rampStart={props.rampStart}
          rampIndex={props.rampIndex}
          ramps={props.ramps}
          billingFrequency={
            draftPlan.billingFrequency ?? BillingFrequencyEnum_Enum.Monthly
          }
          billingDayOfPeriod={draftPlan.billingDayOfPeriod ?? "FIRST_OF_MONTH"}
          initialState={advanceChargePaneState.fees}
          pricingFactorId={advanceChargePaneState.pricingFactorId}
          pricedProduct={props.pricedProduct}
          planStart={draftPlan.customerPlanInfo?.startDate}
          planEnd={draftPlan.customerPlanInfo?.endDate ?? undefined}
        />
      )}
      {compositeChargePaneState && (
        <CompositeChargePane
          onRequestClose={() => setCompositeChargePaneState(null)}
          onRequestSave={(pricingFactorId, compositeCharge) => {
            onChangeCompositeCharge(compositeCharge, pricingFactorId);
            setCompositeChargePaneState(null);
          }}
          initialCharge={compositeChargePaneState.initialCompositeCharge}
          pricingFactorId={compositeChargePaneState.pricingFactorId}
          creditTypeId={props.pricedProduct.creditType?.id ?? ""}
          allProducts={props.allProducts}
        />
      )}
      <div className="flex flex-row items-baseline bg-deprecated-primary-50 px-12 py-8">
        <Tooltip content={props.product.description}>
          <Headline level={5} className={styles.productName}>
            {props.product.name}
          </Headline>
        </Tooltip>
        <Subtitle level={4}>
          {pricingRampTitle(props.rampDuration, props.rampIndex, draftPlan)}
        </Subtitle>
        <div className="ml-auto flex flex-row gap-8 self-start">
          <IconButton
            onClick={() => onReorderProduct("up")}
            disabled={props.index === 0}
            theme="secondary"
            icon="chevronUp"
            size="sm"
          />
          <IconButton
            onClick={() => onReorderProduct("down")}
            disabled={props.index === props.allProducts.length - 1}
            theme="secondary"
            icon="chevronDown"
            size="sm"
          />
        </div>
      </div>
      <div className={styles.creditContainer}>
        <div className={styles.creditType}>
          <PricingUnitSelector
            onChange={(ct) => {
              const updatedPlan = getUpdateCreditTypeForProductRampMerged(
                ct as FiatCreditType | CustomCreditType,
                props.product.id,
                draftPlan,
              );
              setDraftPlan(updatedPlan);

              // Composite pricing factors must use the same pricing unit as the
              // composite charge's pricing unit. We display the snackbar if changing
              // the pricing unit results in a change to a composite charge's
              // associated pricing factors.
              let didCompositePricingFactorsChange = false;
              const oldPricedProducts = draftPlan.pricedProducts ?? [];
              const newPricedProducts = updatedPlan.pricedProducts ?? [];
              oldPricedProducts.forEach((oldPP) => {
                const newPP = newPricedProducts.find(
                  (pp) => pp.productId === oldPP.productId,
                );
                if (newPP) {
                  oldPP.pricingFactors.forEach((oldPPPF) => {
                    if (oldPPPF.chargeType === ChargeTypeEnum_Enum.Composite) {
                      const newPPPF = newPP.pricingFactors.find(
                        (pf) => pf.pricingFactorId === oldPPPF.pricingFactorId,
                      );
                      const oldCCPF =
                        oldPPPF.compositeCharge?.[0]?.pricingFactors ?? [];
                      const newCCPF =
                        newPPPF?.compositeCharge?.[0]?.pricingFactors ?? [];
                      if (oldCCPF.length > newCCPF.length) {
                        didCompositePricingFactorsChange = true;
                      }
                    }
                  });
                }
              });
              if (didCompositePricingFactorsChange) {
                pushMessage({
                  content:
                    "Check the charges associated with your composite charge(s).",
                  type: "warning",
                });
              }
            }}
            selectedCreditTypeId={productCreditType?.id}
            disabled={props.rampIndex !== 0}
            autoFocus={props.autoFocus}
            allowCreation
          />
        </div>
      </div>
      <div className={styles.pricingTable}>
        <div className={styles.pricingTableHeader}>
          <Caption level={2} className={styles.pricingTableHeaderText}>
            Charges
          </Caption>
        </div>
        <div className={styles.pricingTableHeader}>
          <Caption level={2} className={styles.pricingTableHeaderText}>
            Type
          </Caption>
        </div>
        <div className={styles.pricingTableHeader}>
          <Caption level={2} className={styles.pricingTableHeaderText}>
            Rate
          </Caption>
        </div>
        <div className={styles.pricingTableHeader}>
          <Caption level={2} className={styles.pricingTableHeaderText}>
            Charge options
          </Caption>
        </div>
        <div className={styles.pricingTableHeader} />
        {props.product.ProductPricingFactors.map((pricingFactor) => {
          const pricedPricingFactor = getProductPricingFactorForRamp(
            props.pricedProduct,
            pricingFactor.id,
            props.rampStart,
          );
          const flatFees = pricedPricingFactor?.flatFees;
          const blockConfiguration = pricedPricingFactor?.blockPricing;
          const hasVolumePricing = pricedPricingFactor?.volumePricing;
          return (
            <PriceProductTableRow
              key={pricingFactor.id}
              creditType={productCreditType}
              pricingFactor={pricingFactor}
              pricedProduct={props.pricedProduct}
              isNewProduct={
                !draftPlan.originalProductIds?.includes(props.product.id)
              }
              onChangeUsageTiers={(
                newTiers,
                volumePricing,
                tierResetFrequency,
              ) =>
                onChangeUsageTiers(
                  newTiers,
                  volumePricing,
                  pricingFactor.id,
                  tierResetFrequency,
                )
              }
              onChangeFlatFee={(flatFee) =>
                onChangeFlatFee(flatFee, pricingFactor.id)
              }
              onChangeSeatPrice={(seatPrice) =>
                onChangeSeatPrice(seatPrice, pricingFactor.id)
              }
              onEditAdvanceCharge={(existingFees: FlatFee[]) => {
                setAdvanceChargePaneState({
                  fees: existingFees,
                  pricingFactorId: pricingFactor.id,
                });
              }}
              onChangeCompositeCharge={(compositeCharge) =>
                onChangeCompositeCharge(compositeCharge, pricingFactor.id)
              }
              onEditCompositeChargePane={(existingCompositeCharge) => {
                setCompositeChargePaneState({
                  initialCompositeCharge: existingCompositeCharge,
                  pricingFactorId: pricingFactor.id,
                });
              }}
              hasAdvancedChargeInFlight={isSkipRamp(
                props.pricedProduct.pricingFactors,
                pricingFactor.id,
                props.rampStart,
                props.rampIndex,
                flatFees?.[0]?.collectionInterval,
                flatFees?.[0]?.collectionSchedule,
              )}
              rampStart={props.rampStart}
              tierResetFrequency={pricedPricingFactor.tierResetFrequency}
              hasCustomPricing={props.hasCustomPricing}
              blockPricing={blockConfiguration}
              onChangeBlockPricing={(blockPricing) =>
                onChangeBlockPricing(blockPricing, pricingFactor.id)
              }
              hasVolumePricing={hasVolumePricing}
              hasRamps={draftPlan.hasPriceRamps}
              editing={props.editing}
            />
          );
        })}
      </div>
    </div>
  );
});

export const pricingRampTitle = (
  rampDuration: number | undefined,
  rampIndex: number,
  draftPlan: DraftPlan,
) => {
  const allRamps = getRamps(draftPlan);
  if (allRamps.length < 2) {
    return "";
  }
  const startPeriod = getRampStartPeriod(rampIndex, allRamps);
  if (rampDuration === 1) {
    return `Billing period ${startPeriod + 1} (Ramp ${rampIndex + 1})`;
  } else if (rampIndex < allRamps.length - 1) {
    return `Billing periods ${startPeriod + 1}-${
      startPeriod + (rampDuration ?? 0)
    } (Ramp ${rampIndex + 1})`;
  }
  return `Billing periods ${startPeriod + 1}+`;
};
