export const ColWidths = {
  CREDITS: "w-[175px]",
  ITEM_COUNT: "w-[150px]",
  ACTOR: "w-[200px]",
  DATE: "w-[250px]",
  RATE: "w-[200px]",
  BILL_SCHEDULE: "w-[100px]",
  STATUS: "w-[300px] max-[1600px]:w-[150px]",
  PRIORITY: "w-[125px]",
  LEVEL: "w-[150px]",
  CONSUMED: "w-[200px]",
  ACCESS_SCHEDULE: "w-[225px]",
  INVOICED: "w-[150px]",
};
