import React from "react";

import Decimal from "decimal.js";
import classNames from "classnames";
import { CreditType } from "../../types/credit-types";
import { Body, Caption } from "design-system";
import { RoundedCurrency, displayCreditTypeName } from "../../lib/credits";
import { BlockSkeleton } from "components/Skeleton";

type CreditMetricProps = {
  isPrimary: boolean;
  label: string;
  suffix?: string;
} & (
  | {
      loading: true;
      amount?: Decimal;
      creditType?: CreditType;
    }
  | {
      loading?: false;
      amount: Decimal;
      creditType: CreditType;
    }
);
export const CreditMetric: React.FC<CreditMetricProps> = ({
  isPrimary,
  label,
  loading,
  amount,
  creditType,
  suffix,
}) => {
  const content = loading ? (
    <div className={isPrimary ? "h-32" : "h-[26px]"}>
      <BlockSkeleton />
    </div>
  ) : (
    <>
      <span
        className={classNames(
          "mr-4 inline-block font-medium text-default-font",
          isPrimary ? "text-xl leading-4" : "text-base leading-3",
          isPrimary ? "text-success-700 " : "text-gray-800",
        )}
      >
        <RoundedCurrency creditType={creditType} amount={amount} hideSuffix />
      </span>
      <Body
        level={2}
        className={
          isPrimary
            ? "mb-0 inline-block text-success-700"
            : "mb-0 inline-block text-gray-800"
        }
      >
        {displayCreditTypeName(creditType)}
        {suffix}
      </Body>
    </>
  );

  return (
    <div className={isPrimary ? "mr-24" : "mr-12 mt-[6px]"}>
      <Caption level={1} className="mb-4 uppercase">
        {label}
      </Caption>
      {content}
    </div>
  );
};
