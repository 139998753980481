import React, { useMemo, useState } from "react";
import "style/index.css";
import { useRequiredParam } from "lib/routes/params";
import { Card } from "tenaissance/components/Card";
import { Dropdown, DropdownItem } from "tenaissance/components/Dropdown";
import { Tooltip } from "tenaissance/components/Tooltip";
import { Icon } from "tenaissance/components/Icon";
import { useCustomerBillingsQuery } from "./queries.graphql";
import { dayjs } from "lib/dayjs";
import {
  RoundedCurrency,
  USD_CREDIT_ID,
  prefixForCreditType,
  roundedCurrencyString,
} from "lib/credits";
import { CreditType } from "types/credit-types";
import Decimal from "decimal.js";
import { LoadingBlob } from "tenaissance/components/LoadingBlob";
import { ContractProductFlyover } from "pages/Contracts/Pricing/ContractProductFlyover";
import { Button } from "tenaissance/components/Button";

const convertCreditTypeLabel = (creditType: CreditType) =>
  `${prefixForCreditType(creditType)}${creditType.id === USD_CREDIT_ID ? "USD" : creditType.name}`;

export const BillingsCard: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const { data, loading } = useCustomerBillingsQuery({
    variables: { id: customerId },
  });
  const revenueOverview = data?.Customer[0]?.revenue_overview;
  const [selectedCreditTypeID, setSelectedCreditTypeID] = useState<string>();
  const [selectedProductId, setSelectedProductId] = useState<string>();

  const creditTypes: CreditType[] = useMemo(() => {
    const allCreditTypes = [
      ...(revenueOverview?.total_billings?.results ?? []),
      ...(revenueOverview?.top_plan_charges?.results ?? []),
      ...(revenueOverview?.top_products?.results ?? []),
    ]
      .map((result) => result.credit_type)
      .filter(
        (value, index, self) =>
          self.findIndex((v) => v.id === value.id) === index,
      )
      .sort((a, _) => (a.client_id === null ? -1 : 1));

    if (allCreditTypes.length > 0) {
      setSelectedCreditTypeID(allCreditTypes[0].id);
    }
    return allCreditTypes;
  }, [data]);

  const selectedCreditType = creditTypes.find(
    (o) => o.id === selectedCreditTypeID,
  );

  const totalBillings = revenueOverview?.total_billings.results.find(
    (r) => r.credit_type.id === selectedCreditTypeID,
  );

  const current_month_for_top_spending = dayjs
    // either top_products or top_plan_charges will have the ending_before date
    .utc(revenueOverview?.top_products?.ending_before)
    .subtract(1, "month")
    .format("MMMM");

  const topCharges = useMemo(() => {
    if (!data || !selectedCreditTypeID || !revenueOverview) return [];

    const products = revenueOverview.top_products.results.filter(
      (r) => r.credit_type.id === selectedCreditTypeID,
    );
    const plans = revenueOverview.top_plan_charges.results.filter(
      (r) => r.credit_type.id === selectedCreditTypeID,
    );

    return [...products, ...plans]
      .sort((a, b) =>
        new Decimal(b.amount_billed)
          .minus(new Decimal(a.amount_billed))
          .toNumber(),
      )
      .slice(0, 4);
  }, [data, selectedCreditTypeID]);
  const customer = data?.Customer[0];

  const customerProvisionDate = customer
    ? dayjs.min([
        dayjs.utc(customer.created_at),
        ...customer.contracts.map((c) => dayjs.utc(c.starting_at)),
        ...customer.plans.map((p) => dayjs.utc(p.start_date)),
      ])
    : undefined;

  const renderLifetimeBillingsAmount = () => {
    // strict check for null because fiat currencies have a client_id = null
    const selectedCreditTypeIsCPU =
      selectedCreditType?.client_id !== undefined &&
      selectedCreditType?.client_id !== null;
    if (selectedCreditTypeIsCPU) {
      // Invoice totals are always in fiat currencies, so CPUs don't have
      // lifetime billings
      return "--";
    }
    // default to 0.00 if amount doesn't exist
    const amountString = totalBillings?.amount ?? "0.00";
    if (selectedCreditType) {
      const amount = isNaN(Number(amountString))
        ? new Decimal(0)
        : new Decimal(amountString);
      return (
        <RoundedCurrency
          hideSuffix
          amount={amount}
          creditType={selectedCreditType}
        />
      );
    }
    return amountString;
  };

  return (
    <div>
      {selectedProductId && (
        <ContractProductFlyover
          productId={selectedProductId}
          onRequestClose={() => setSelectedProductId(undefined)}
        />
      )}
      <div className="mb-md flex items-center">
        <div className="flex-grow text-lg font-semibold">Billings</div>
        {creditTypes.length > 0 && selectedCreditType && (
          <Dropdown
            selectedOption={convertCreditTypeLabel(selectedCreditType)}
            icon="filterLines"
            label="Credit Type"
            children={creditTypes.map((o) => (
              <DropdownItem
                key={o.id}
                label={convertCreditTypeLabel(o)}
                value={o.id}
                onClick={() => setSelectedCreditTypeID(o.id)}
              />
            ))}
          />
        )}
      </div>
      <div className="flex gap-x-xl">
        <Card variant="overview" wrapContents={true}>
          <div>
            Customer since {customerProvisionDate?.format("MMMM YYYY")}
            <div>
              <div className="mt-4xl">Lifetime</div>
              <h3 className="mt-lg text-display-md font-semibold">
                {renderLifetimeBillingsAmount()}
              </h3>
            </div>
          </div>
        </Card>
        <div className="flex-grow">
          <Card
            fullHeight
            wrapContents={false}
            title={
              current_month_for_top_spending ? (
                <div className="flex items-center gap-x-xs">
                  {`Top products in ${current_month_for_top_spending}`}
                  <Tooltip label="Top products are calculated by the line item spend before commits or credits are applied.">
                    <Icon icon="helpCircle" size={14} />
                  </Tooltip>
                </div>
              ) : (
                <LoadingBlob />
              )
            }
            loading={loading}
            metrics={
              selectedCreditType
                ? topCharges.map((charge) => {
                    const isProduct = "product" in charge;
                    const name = isProduct
                      ? charge.product.name
                      : charge.plan_charge.name;
                    const delta = new Decimal(
                      charge.month_over_month_change.percent_change ?? 0,
                    );
                    return {
                      value: new Decimal(charge.amount_billed),
                      label: (
                        <Button
                          className="flex items-center"
                          leadingIcon="arrowNarrowUpRight"
                          theme="linkGray"
                          text={name ?? ""}
                          linkTo={
                            isProduct
                              ? undefined
                              : `/offering/plans/products/${charge.plan_charge.product_id}`
                          }
                          onClick={() =>
                            setSelectedProductId(
                              isProduct ? charge.product.id : undefined,
                            )
                          }
                        />
                      ),
                      creditType: selectedCreditType,
                      deltaPercentValue: delta,
                      deltaLabel: "MoM ",
                      deltaType: "trend",
                      deltaColor: delta.gt(0) ? "success" : "gray",
                      badgeTooltipLabel: delta.isZero()
                        ? "No change from previous month"
                        : `${delta.isPositive() ? "Up" : "Down"} ${delta.abs().toFixed(0)}% from previous month`,
                      badgeTooltipSubLabel: roundedCurrencyString(
                        new Decimal(
                          charge.month_over_month_change
                            .previous_period_total_billed ?? 0,
                        ),
                        selectedCreditType,
                      ),
                    };
                  })
                : undefined
            }
          >
            {topCharges.length === 0 ? (
              <div className="my-3xl flex w-full flex-col items-center text-gray-600">
                <h3 className="text-md font-semibold text-black">
                  No{" "}
                  {selectedCreditType
                    ? convertCreditTypeLabel(selectedCreditType)
                    : ""}{" "}
                  spend in {current_month_for_top_spending}
                </h3>
                <div>
                  This customer did not incur usage that resulted in line item
                  spend in {current_month_for_top_spending}.
                </div>
              </div>
            ) : null}
          </Card>
        </div>
      </div>
    </div>
  );
};
