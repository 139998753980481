import { UI_MODE } from "./useUIMode";

type ContractOrPlanOptions = {
  uiMode: UI_MODE;
  pluralize?: boolean | "plans-only" | "contracts-only";
  conjunctive?: " and " | " or " | "/";
  titleCase?: boolean;
};

/**
 * Helper to return "contract and/or plan" text in a variety of commonly used human readable formats.
 */
export function contractOrPlanText({
  uiMode,
  pluralize = false,
  conjunctive = " or ",
  titleCase = false,
}: ContractOrPlanOptions) {
  let contractText = "contract";
  let planText = "plan";

  if (titleCase) {
    contractText = titleCaseWord(contractText);
    planText = titleCaseWord(planText);
  }
  if (pluralize) {
    if (typeof pluralize === "string") {
      if (pluralize === "plans-only") {
        planText += "s";
      } else if (pluralize === "contracts-only") {
        contractText += "s";
      }
    } else {
      contractText += "s";
      planText += "s";
    }
  }

  if (uiMode === "contracts-and-plans") {
    return `${contractText}${conjunctive}${planText}`;
  } else if (uiMode === "contracts-only" || uiMode === "off") {
    return contractText;
  } else if (uiMode === "plans-only") {
    return planText;
  } else {
    uiMode satisfies never;
    return contractText;
  }
}

function titleCaseWord(text: string): string {
  return text.charAt(0).toUpperCase() + text.substring(1);
}
