import { SOXReport } from "../reportConfig";
import { CustomReportType } from "types/generated-graphql/__types__";

const soxComplianceInvoiceReport: SOXReport = {
  name: "SOX compliance invoice report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("SOX_COMPLIANCE_INVOICE"),
  customReportType: CustomReportType.Sox,
  restrictEndDateToToday: false,
};

const soxAuditLogReport: SOXReport = {
  name: "SOX audit log report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient?.variation("available-report-types", []).includes("SOX_AUDIT_LOG"),
  customReportType: CustomReportType.SoxAuditReport,
  restrictEndDateToToday: true,
};

const usageBasedBillingFinalizationReport: SOXReport = {
  name: "UBB invoice extract report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("UBB_INVOICE_FINALIZATION"),
  customReportType: CustomReportType.UbbInvoiceFinalization,
  restrictEndDateToToday: true,
  canSendToWorkato: false,
};

const customerContractRateCardAndConfigurationReport: SOXReport = {
  name: "Customer, contract, and rate card configuration report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("CUSTOMER_CONTRACT_RATE_CARD_CONFIGURATION"),
  customReportType:
    CustomReportType.CustomerContractRateCardAndConfigurationReport,
  restrictEndDateToToday: true,
};

const userAndSystemAccessReport: SOXReport = {
  name: "User and system access report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("USER_AND_SYSTEM_ACCESS"),
  customReportType: CustomReportType.UserAndSystemAccessReport,
  restrictEndDateToToday: true,
};
export {
  soxComplianceInvoiceReport,
  soxAuditLogReport,
  usageBasedBillingFinalizationReport,
  customerContractRateCardAndConfigurationReport,
  userAndSystemAccessReport,
};
