import React from "react";
import { FullScreenPage } from "components/PageContainer";
import { Icon, Body, Headline } from "design-system";

import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { MetronomeLogo } from "tenaissance/components/MetronomeLogo";
import { InternalLink } from "components/Typography";

type LandingProps = {
  onClose: () => void;
  onContinue: () => void;
  newCustomer: boolean;
  customerName: string;
};

export const Landing: React.FC<LandingProps> = (props) => {
  return (
    <FullScreenPage title="Add a plan">
      <div className="flex flex-grow flex-col items-center justify-center bg-gray-50 text-center">
        <InternalLink routePath="/" className="absolute left-[20px] top-[20px]">
          <MetronomeLogo theme="greyscale" badgeOnly />
        </InternalLink>
        <IconButton
          onClick={props.onClose}
          className="absolute right-12 top-12"
          theme="secondary"
          icon="xClose"
        />
        <div className="mb-24 flex h-[80px] w-[80px] items-center justify-center rounded-[40px] bg-primary-500 text-3xl text-white">
          <Icon icon="receipt" />
        </div>
        <Headline level={4}>{`Add a plan to ${
          props.newCustomer ? "your new customer" : props.customerName
        }?`}</Headline>
        <Body className="m-8 mb-24 max-w-[850px] text-gray-700 md:mx-20">
          To enable invoicing for this customer, select a pricing plan and
          choose start and end dates.
        </Body>
        <div className="flex flex-row gap-12">
          <Button
            onClick={props.onClose}
            text="Add a plan later"
            theme="linkGray"
          />
          <Button
            onClick={props.onContinue}
            text="Add a plan"
            theme="primary"
          />
        </div>
      </div>
    </FullScreenPage>
  );
};
