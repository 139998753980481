/* 70fcd4724b71bf80eff5b4cf051a87d63972b4d1
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnvironmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EnvironmentsQuery = { __typename?: 'Query', environments: Array<{ __typename?: 'Environment', id: string, name: string, slug: string, enabled: boolean, type: Types.EnvironmentTypeEnum_Enum | null }> };


export const EnvironmentsDocument = gql`
    query Environments {
  environments {
    id
    name
    slug
    enabled
    type
  }
}
    `;

/**
 * __useEnvironmentsQuery__
 *
 * To run a query within a React component, call `useEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnvironmentsQuery(baseOptions?: Apollo.QueryHookOptions<EnvironmentsQuery, EnvironmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnvironmentsQuery, EnvironmentsQueryVariables>(EnvironmentsDocument, options);
      }
export function useEnvironmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentsQuery, EnvironmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnvironmentsQuery, EnvironmentsQueryVariables>(EnvironmentsDocument, options);
        }
export type EnvironmentsQueryHookResult = ReturnType<typeof useEnvironmentsQuery>;
export type EnvironmentsLazyQueryHookResult = ReturnType<typeof useEnvironmentsLazyQuery>;
export type EnvironmentsQueryResult = Apollo.QueryResult<EnvironmentsQuery, EnvironmentsQueryVariables>;