import React from "react";
import Decimal from "decimal.js";
import { Headline } from "design-system";

import { toDayjs } from "lib/date";
import { InvoiceStatusEnum } from "types/generated-graphql/__types__";
import { InvoiceSkeleton, Invoice } from "components/Invoice";
import { EmptyState } from "tenaissance/components/EmptyState";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { FilterDropdown, DropdownItem } from "components/Filter";
import { Invoice as InvoiceType } from "components/Invoice/types";

import {
  useListOpts,
  DEFAULT_LIST_OPTS,
  LIST_OPTS_LABELS,
  LIST_OPTS_VALUES,
  ListOpts,
} from "./InvoiceListOpts";
import { InvoicesListItemFragment } from "./fragments.graphql";
import { InvoiceList } from "./InvoicesList";
import { isFinalizable } from "pages/Customer/tabs/Invoices";
import { twMerge } from "tenaissance/twMerge";
import { useUIMode } from "lib/useUIMode";

interface Props {
  type?: "contract" | "plan";
  warning?: string;
  loading: boolean;
  error: Error | undefined;
  invoices: InvoicesListItemFragment[];
  activeInvoiceId?: string;
  setActiveInvoiceId: (invoiceId?: string) => void;
  customerId: string;
  renderInvoicePlanOrContractLink?: (
    customerId: string,
    invoice: InvoiceType,
  ) => React.ReactNode;
}

const INVOICE_STATUS_TO_LIST_STATUS: Record<
  InvoiceStatusEnum,
  ListOpts.Status
> = {
  [InvoiceStatusEnum.Draft]: "draft",
  [InvoiceStatusEnum.Finalized]: "finalized",
  [InvoiceStatusEnum.FinalizedInOldSystem]: "finalized",
  [InvoiceStatusEnum.PendingFinalization]: "pending-finalization",
  [InvoiceStatusEnum.Void]: "voided",
};

// used as a fallback for non-date sort methods
const fallbackSort = sorter("date_desc");
function sorter(mode: ListOpts.Sort) {
  return (a: InvoicesListItemFragment, b: InvoicesListItemFragment): number => {
    const reverseMultiplier = mode.endsWith("_desc") ? -1 : 1;
    // Short circuit for an invoice that is regenerated
    // Note that it's a bi-directional link so if a doesn't have the link, b won't either
    // Note that this doesn't work if an invoice has been regenerated multiple times :(
    if (
      a.__typename === "ContractUsageInvoice" ||
      a.__typename === "ContractScheduledInvoice" ||
      a.__typename === "ContractPostpaidTrueupInvoice"
    ) {
      if (a.regenerated_to?.id === b.id) return 1;
      else if (a.regenerated_from?.id === b.id) return -1;
    }

    // If a correction issue date is the same as the original,
    // display the correction above the original
    if (a.__typename === "CorrectionInvoice") {
      if (a.issued_at === b.issued_at) return -1;
      return 1;
    }

    switch (mode) {
      case "date_asc":
      case "date_desc":
        return (
          toDayjs(a.issued_at).diff(toDayjs(b.issued_at)) * reverseMultiplier
        );
      case "amount_asc":
      case "amount_desc":
        return (
          new Decimal(a.total).sub(b.total).toNumber() * reverseMultiplier ||
          fallbackSort(a, b)
        );
    }
  };
}

export const InvoicesLayout: React.FC<Props> = ({
  type,
  invoices,
  loading,
  error,
  activeInvoiceId,
  setActiveInvoiceId,
  customerId,
  renderInvoicePlanOrContractLink,
}) => {
  const [listOpts, setListOpts] = useListOpts();
  const { newUIEnabled } = useUIMode();

  if (error) {
    return (
      <ErrorEmptyState
        title={
          type
            ? `We were unable to load invoices for this ${type}`
            : `We were unable to load invoices for this customer`
        }
        error={error}
      />
    );
  }

  const filteredAndSortedInvoices = invoices
    .filter((invoice) => {
      if (listOpts.hideZero && new Decimal(invoice.total).isZero()) {
        return false;
      }

      return (
        !listOpts.statuses ||
        listOpts.statuses.includes(
          INVOICE_STATUS_TO_LIST_STATUS[invoice.status as InvoiceStatusEnum],
        )
      );
    })
    .sort(sorter(listOpts.sort));

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="flex h-full w-full grow flex-row overflow-hidden">
        <div
          className={twMerge(
            "h-full w-1/3 max-w-lg shrink-0 grow-0 border-r border-r-gray-light pr-12",
            newUIEnabled ? "overflow-auto" : "overflow-scroll",
          )}
        >
          <div className="flex flex-row items-center">
            <Headline
              level={5}
              className="my-12 grow text-sm font-medium leading-1"
            >
              Invoice history
            </Headline>
            <FilterDropdown
              size="small"
              align="start"
              onReset={() => setListOpts(DEFAULT_LIST_OPTS)}
              menu={[
                {
                  label: "Sort order",
                  items: LIST_OPTS_VALUES.sort.map((value) => ({
                    type: "button",
                    label: LIST_OPTS_LABELS.sort[value],
                    active: listOpts.sort === value,
                    onClick: () => setListOpts({ sort: value }),
                  })),
                },
                {
                  label: "Filter by status",
                  items: [
                    {
                      type: "checkbox",
                      checked: listOpts.statuses === null,
                      label: "All",
                      onClick: () => {
                        setListOpts({ statuses: null });
                      },
                    },
                    ...LIST_OPTS_VALUES.status.map(
                      (value): DropdownItem => ({
                        type: "checkbox",
                        checked: listOpts.statuses?.includes(value) || false,
                        label: LIST_OPTS_LABELS.status[value],
                        onClick: () => {
                          const checked =
                            listOpts.statuses?.includes(value) || false;
                          const statuses = checked
                            ? listOpts.statuses?.filter((s) => s !== value)
                            : [...(listOpts.statuses ?? []), value];

                          setListOpts({
                            statuses: statuses?.length ? statuses : null,
                          });
                        },
                      }),
                    ),
                  ],
                },
                {
                  label: "Filter by value",
                  items: [
                    {
                      type: "checkbox",
                      checked: listOpts.hideZero,
                      label: "Hide zero value invoices",
                      onClick: () => {
                        setListOpts({ hideZero: !listOpts.hideZero });
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <InvoiceList
            loading={loading}
            activeInvoiceId={activeInvoiceId}
            setActiveInvoiceId={setActiveInvoiceId}
            invoices={filteredAndSortedInvoices}
          />
        </div>
        <div
          className={twMerge(
            "flex h-full shrink grow flex-col",
            newUIEnabled ? "overflow-auto" : "overflow-scroll",
          )}
        >
          {loading || (!activeInvoiceId && invoices.length) ? (
            <InvoiceSkeleton />
          ) : !activeInvoiceId ? (
            <EmptyState
              icon="receiptCheck"
              mainText="No invoices found for this customer"
              supportingText=""
            />
          ) : (
            <Invoice
              invoiceID={activeInvoiceId}
              customerID={customerId}
              isFinalizable={isFinalizable(
                activeInvoiceId,
                filteredAndSortedInvoices,
              )}
              renderInvoicePlanOrContractLink={renderInvoicePlanOrContractLink}
            />
          )}
        </div>
      </div>
    </div>
  );
};
