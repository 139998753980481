import { Select } from "design-system";
import React, { useMemo } from "react";
import { useOptionalParam, useRequiredParam } from "lib/routes/params";
import { useNow } from "lib/date";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { OverrideCtrl } from "../OverrideCtrl";
import { Schema } from "pages/Contracts/Customer/Contracts/Create/Schema";
import { useCommitProductNames } from "hooks/useCommitProductNames";
import { ExternalCommitType } from "types/generated-graphql/__types__";

type OverrideSpecifierCommitsSelectProps = {
  /** Commits that haven't been persisted yet and were created in the contract create/aemend form. */
  newCommits: Schema.Types.CommitFlyoverRoot[];
  /** Form controller of the override form */
  overrideCtrl: OverrideCtrl;
  /** All of the customer's products */
  allProducts: Array<
    ProductListItem.IdFragment &
      ProductListItem.NameFragment &
      ProductListItem.TypeFragment
  >;
};

export const OverrideSpecifierCommitsSelect: React.FC<
  OverrideSpecifierCommitsSelectProps
> = ({ newCommits, overrideCtrl, allProducts }) => {
  const now = useNow();
  const customerId = useRequiredParam("customerId");
  const contractId = useOptionalParam("contractId");

  const {
    data: serverCommits,
    loading,
    error,
  } = useCommitProductNames({
    customerId,
    contractId,
    externalType: ExternalCommitType.Commit,
  });

  const productById = useMemo(() => {
    const productById = new Map<string, { id: string; name: string }>();
    for (const product of allProducts) {
      productById.set(product.id, {
        id: product.id,
        name: ProductListItem.getName(product, now),
      });
    }
    return productById;
  }, [allProducts, now]);

  const selectOptions = useMemo(() => {
    const selectOptions: { label: string; value: string }[] = [];

    for (const commit of serverCommits) {
      selectOptions.push({
        label: `${commit.productName} (${commit.id})`,
        value: commit.id,
      });
    }
    for (const commit of newCommits) {
      const maybeProduct = productById.get(commit.productId);
      const label = maybeProduct
        ? `${maybeProduct.name} (${commit.productId})`
        : commit.productId;
      selectOptions.push({
        label: label,
        value: commit.temporaryId,
      });
    }

    return selectOptions;
  }, [serverCommits, newCommits, now, productById]);

  return (
    <Select
      {...overrideCtrl.props.MultiSelect("commitIds", {
        name: "Select applicable commits",
        className: "flex-1",
        placeholder: "Applies to all if none are selected",
        loading: loading,
        disabled: error !== undefined,
        options: selectOptions,
      })}
      error={error ? `Failed to load commits` : undefined}
    />
  );
};
