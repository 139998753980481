import React from "react";
import { Popup } from "components/Popup";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { useArchiveProductMutation } from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";

interface ArchiveProductModalProps {
  onClose: () => void;
  productId: string;
  productName: string;
}

const ArchiveProductModal: React.FC<ArchiveProductModalProps> = ({
  onClose,
  productId,
  productName,
}) => {
  const [archiveProduct, archiveProductResults] = useArchiveProductMutation({
    update: (cache) => {
      // Billable metrics that couldn't be archived because they were used by
      // this product might now be archivable! So we need to evict the cache
      // so that page can show the archive option.
      cache.evict({
        fieldName: "billable_metrics",
      });
    },
  });
  const pushMessage = useSnackbar();

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            loading={archiveProductResults.loading}
            disabled={archiveProductResults.loading}
            onClick={async () => {
              try {
                await archiveProduct({
                  variables: {
                    product_id: productId,
                  },
                });
              } catch (e: any) {
                pushMessage({
                  content: `Failed to archive product: ${e.message}`,
                  type: "error",
                });
              }
              onClose();
            }}
            text="Archive product"
            theme="linkDestructive"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Are you sure you want to archive this product?"
    >
      <Body level={2}>
        Are you sure you want to archive the <strong>{productName}</strong>{" "}
        product? Once a product is archived it can no longer be used, and will
        no longer appear on the overview by default.
      </Body>
    </Popup>
  );
};

export default ArchiveProductModal;
