import React from "react";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { TextInput } from "../Input";

type Props = {
  title: string;
  name: string;
  onClose: () => void;
  onSave: (name: string) => Promise<void>;
};

export default function EditNameModal({ title, name, onClose, onSave }: Props) {
  const [updatedName, setUpdatedName] = React.useState(name);
  const [saving, setSaving] = React.useState(false);
  return (
    <Modal
      title={title}
      onClose={onClose}
      isOpen={true}
      showCloseButton={true}
      modalButtons={[
        <Button
          key="save"
          onClick={async () => {
            try {
              setSaving(true);
              await onSave(updatedName);
            } finally {
              setSaving(false);
            }
          }}
          text="Save"
          disabled={!updatedName}
          loading={saving}
        />,
        <Button
          key="cancel"
          onClick={onClose}
          text="Cancel"
          theme="secondary"
        />,
      ]}
    >
      <TextInput
        value={updatedName}
        onChange={(e) => setUpdatedName(e.value)}
        autofocus
        fullWidth
      />
    </Modal>
  );
}
