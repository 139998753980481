import React from "react";
import "style/index.css";
import { twMerge } from "../../twMerge";
import icons from "tenaissance/icons";
import { Link } from "react-router-dom";
import { TooltipProps, Tooltip } from "tenaissance/components/Tooltip";

interface SectionLabelProps {
  /** Label string for the main header */
  title: string;
  /** Label string for the sub-header */
  subtitle?: string;
  /** Optional class name for the subtitle */
  subtitleClassName?: string;
  /** Optional class name for the title */
  titleClassName?: string;
  leadingIcon?: keyof typeof icons;
  href?: string;
  tooltip?: TooltipProps;
}

/**
 * !! PARTIAL IMPLEMENTATION !!
 * Section headers provide content and actions related to a section
 * of the current screen.
 *
 * https://www.figma.com/design/qZYCfNZZdsFfxF7SrO8gaN/Metronome-Design-System-2.0?node-id=381-26078&node-type=frame&t=eH9keLRDeKlX0Ixu-0
 *
 * TODO:
 * - implement tooltip
 * - implement secondary text
 */
export const SectionLabel: React.FC<SectionLabelProps> = ({
  title,
  subtitle,
  subtitleClassName,
  titleClassName,
  leadingIcon,
  href,
  tooltip,
}) => {
  const LeadingIcon = leadingIcon && icons[leadingIcon];
  return (
    <WithLink to={href}>
      <WithTooltip {...tooltip}>
        <div className={twMerge("flex flex-row items-center gap-xs")}>
          {LeadingIcon && <LeadingIcon viewBox="0 0 24 24" />}
          <div className="flex-row">
            <div
              className={twMerge(
                "text-sm font-semibold text-core-slate",
                titleClassName,
              )}
            >
              {title}
            </div>
            {subtitle ? (
              <div
                className={twMerge(
                  "text-sm font-normal text-gray-500",
                  subtitleClassName,
                )}
              >
                {subtitle}
              </div>
            ) : null}
          </div>
        </div>
      </WithTooltip>
    </WithLink>
  );
};

const WithLink: React.FC<{ to?: string; children: React.ReactNode }> = (
  props,
) => {
  if (props.to) {
    return <Link to={props.to}>{props.children}</Link>;
  } else {
    return props.children;
  }
};

const WithTooltip: React.FC<TooltipProps & { children: React.ReactNode }> = ({
  children,
  ...tooltipProps
}) => {
  if (tooltipProps.label) {
    return <Tooltip {...tooltipProps}>{children}</Tooltip>;
  } else {
    return children;
  }
};
