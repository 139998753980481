import classNames from "classnames";
import React from "react";
import styles from "./index.module.less";

type GroupInfoProps = {
  groupKey: string;
  groupValue?: string;
  className?: string;
};

export const GroupInfo: React.FC<GroupInfoProps> = ({
  groupKey,
  groupValue,
  className,
}) => {
  const eventTypeGroupKey = "__METRONOME_INTERNAL__EVENT_TYPE";
  return (
    <span className={classNames(styles.groupInfo, className)}>
      {groupKey === eventTypeGroupKey ? "event_type" : groupKey}
      {groupValue && `: ${groupValue}`}
    </span>
  );
};
