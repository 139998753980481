import React, { useMemo } from "react";
import { Button } from "tenaissance/components/Button";
import { CopyableID } from "tenaissance/components/CopyableID";
import { DatePicker } from "tenaissance/components/DatePicker";
import { EmptyState } from "tenaissance/components/EmptyState";
import { IconButton } from "tenaissance/components/IconButton";
import { TextInput } from "tenaissance/components/Input";
import { SideSheet } from "tenaissance/components/SideSheet";
import { Table } from "tenaissance/components/Table";
import { RateCardAliasScheduleSegment } from "types/generated-graphql/__types__";
import { Timestamp } from "tenaissance/components/Timestamp";

type Props = {
  onClose: () => void;
  onSave: (aliases: RateCardAliasScheduleSegment[]) => Promise<void>;
  aliases: RateCardAliasScheduleSegment[];
};
export default function RateCardAliasesFlyout({
  onClose,
  onSave,
  aliases: currentAliases,
}: Props) {
  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedAliases, setEditedAliases] = React.useState<
    RateCardAliasScheduleSegment[]
  >([]);

  const canSave = useMemo(
    () => editedAliases.every((a) => !!a.data.alias),
    [editedAliases],
  );

  const trailingActions:
    | [React.ReactElement]
    | [React.ReactElement, React.ReactElement] = useMemo(() => {
    if (isEditing) {
      return [
        <Button
          disabled={!canSave || isSaving || editedAliases.length === 0}
          loading={isSaving}
          onClick={async () => {
            try {
              setIsSaving(true);
              await onSave(editedAliases);
            } finally {
              setIsSaving(false);
            }
          }}
          text="Save"
        />,
        <Button
          disabled={isSaving}
          onClick={() => {
            setIsEditing(false);
          }}
          text="Cancel"
          theme="secondary"
        />,
      ];
    } else {
      return [<Button onClick={onClose} text="Done" />];
    }
  }, [isEditing, editedAliases, onSave, setIsEditing]);

  const leadingAction = useMemo(() => {
    if (isEditing) {
      return undefined;
    } else {
      return (
        <Button
          theme="tertiary"
          leadingIcon="plus"
          onClick={() => {
            setEditedAliases([
              {
                data: {
                  alias: "",
                },
                starting_at: null,
                ending_before: null,
              },
            ]);
            setIsEditing(true);
          }}
          text="Add"
        />
      );
    }
  }, [isEditing, setIsEditing]);

  return (
    <SideSheet
      isOpen={true}
      onClose={onClose}
      title="Rate card aliases"
      supportingText="Reference a rate card via an alias. This alias can be used when provisioning a customer to a contract."
      trailingActions={trailingActions}
      leadingAction={leadingAction}
    >
      <div className="flex flex-col gap-3xl">
        {isEditing ? (
          <div className="flex flex-col gap-3xl">
            {editedAliases.map((alias, index) => (
              <div key={index} className="flex flex-col gap-lg">
                <div className="flex flex-row gap-lg">
                  <TextInput
                    type="text"
                    disableClear
                    fullWidth
                    helpText="Alias name"
                    label="Alias"
                    value={alias.data.alias}
                    onChange={(e) => {
                      setEditedAliases((currentAliases) => [
                        ...currentAliases.slice(0, index),
                        {
                          ...currentAliases[index],
                          data: {
                            ...currentAliases[index].data,
                            alias: e.value,
                          },
                        },
                        ...currentAliases.slice(index + 1),
                      ]);
                    }}
                  />
                  <IconButton
                    className="self-end"
                    icon="trash01"
                    theme="secondary"
                    onClick={() => {
                      setEditedAliases((currentAliases) => {
                        const newAliases = [...currentAliases];
                        newAliases.splice(index, 1);
                        return newAliases;
                      });
                    }}
                  />
                </div>
                <div className="flex flex-row gap-lg">
                  <DatePicker
                    openToDate={
                      alias.starting_at
                        ? new Date(alias.starting_at)
                        : undefined
                    }
                    onDateApply={(value) => {
                      setEditedAliases((currentAliases) => [
                        ...currentAliases.slice(0, index),
                        {
                          ...currentAliases[index],
                          starting_at: value?.toISOString() ?? null,
                        },
                        ...currentAliases.slice(index + 1),
                      ]);
                    }}
                    value={
                      alias.starting_at
                        ? new Date(alias.starting_at)
                        : undefined
                    }
                  />
                  <DatePicker
                    text="Ending before"
                    openToDate={
                      alias.ending_before
                        ? new Date(alias.ending_before)
                        : undefined
                    }
                    onDateApply={(value) => {
                      setEditedAliases((currentAliases) => [
                        ...currentAliases.slice(0, index),
                        {
                          ...currentAliases[index],
                          ending_before: value?.toISOString() ?? null,
                        },
                        ...currentAliases.slice(index + 1),
                      ]);
                    }}
                    value={
                      alias.ending_before
                        ? new Date(alias.ending_before)
                        : undefined
                    }
                  />
                </div>
              </div>
            ))}
            <Button
              text="Add alias"
              leadingIcon="plus"
              onClick={() => {
                setEditedAliases((aliases) => [
                  ...aliases,
                  {
                    data: {
                      alias: "",
                    },
                    starting_at: null,
                    ending_before: null,
                  },
                ]);
              }}
            />
          </div>
        ) : currentAliases.length === 0 ? (
          <EmptyState
            icon="searchLg"
            mainText="No aliases"
            supportingText="Add an alias to this rate card"
            actions={[
              <Button
                text="Add"
                leadingIcon="plus"
                onClick={() => {
                  setEditedAliases([
                    {
                      data: {
                        alias: "",
                      },
                      starting_at: null,
                      ending_before: null,
                    },
                  ]);
                  setIsEditing(true);
                }}
              />,
            ]}
          />
        ) : (
          <>
            <Table
              data={currentAliases.map((alias, idx) => ({
                ...alias,
                id: idx.toString(),
              }))}
              columns={[
                {
                  id: "name",
                  header: "Name",
                  accessorKey: "data.alias",
                  cell: (props: { getValue: () => string }) => {
                    return (
                      <CopyableID
                        id={props.getValue()}
                        className="font-normal text-gray-600"
                      />
                    );
                  },
                },
                {
                  id: "starting_at",
                  header: "Starting at",
                  accessorKey: "starting_at",
                  cell: (props: { getValue: () => string }) => {
                    const startingAt = props.getValue();
                    if (startingAt) {
                      return (
                        <Timestamp dateOnly dateTime={new Date(startingAt)} />
                      );
                    }
                    return <div className="px-md">--</div>;
                  },
                },
                {
                  id: "ending_before",
                  header: "Ending before",
                  accessorKey: "ending_before",
                  cell: (props: { getValue: () => string }) => {
                    const endingBefore = props.getValue();
                    if (endingBefore) {
                      return (
                        <Timestamp dateOnly dateTime={new Date(endingBefore)} />
                      );
                    }
                    return <div className="px-md">--</div>;
                  },
                },
              ]}
            />
          </>
        )}
      </div>
    </SideSheet>
  );
}
