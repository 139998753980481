export const CORRECTION_REASONS = [
  "Billing Error",
  "Billing Adjustment",
  "Bank Fee",
  "Business Decision",
  "Credit/Rebill",
  "Make Good",
  "Order Amendment",
  "Sales Tax Adjustment",
  "Tax Exempt",
  "Uncollectable",
  "Write-off of small balance",
];
