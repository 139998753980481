import React, { useEffect } from "react";
import { CustomerPlanInfo, useCustomerPlan } from "../../context";
import { WizardFullPage } from "components/Wizard";
import { InputSection } from "components/Wizard/components/InputSection";
import { Input, NumericInput } from "design-system";
import { Select } from "design-system";
import { CreditType } from "types/credit-types";
import { useGetStripeCustomerQuery } from "../../queries.graphql";
import { Body } from "design-system";
import { useEnvironment } from "lib/environmentSwitcher/context";

const getNetPaymentTermsError = (customerPlan: CustomerPlanInfo) => {
  const days = customerPlan.netPaymentTermsDays || 0;
  if (days < 0 || days != Math.floor(days)) {
    return "Net payment terms must be a whole, non-negative number of days";
  }
};

export const planBillingIsDone = (customerPlan: CustomerPlanInfo): boolean => {
  return !!(
    customerPlan.planBillingViewed &&
    (!customerPlan.stripeConfig || customerPlan.stripeConfig.valid) &&
    !getNetPaymentTermsError(customerPlan)
  );
};

interface PlanBillingProps {
  customerName: string;
  creditTypes: Array<CreditType>;
  hasConfiguredStripe: boolean;
  existingStripeConfig: {
    billing_provider_customer_id: string;
    stripe_collection_method: string;
  } | null;
  netPaymentTermsDays: number | undefined;
  netPaymentTermsDaysClientDefault: number | undefined;
}

export const PlanBilling: React.FC<PlanBillingProps> = (props) => {
  const [customerPlan, setCustomerPlan] = useCustomerPlan();

  // load initial state once
  useEffect(() => {
    const newCustomerPlan = {
      ...customerPlan,
      planBillingViewed: true,
    };
    if (props.existingStripeConfig) {
      newCustomerPlan.stripeConfig = {
        billingProviderCustomerId:
          props.existingStripeConfig.billing_provider_customer_id,
        collectionMethod: props.existingStripeConfig
          .stripe_collection_method as "send_invoice" | "charge_automatically",
        valid: true,
      };
    }
    if (customerPlan.netPaymentTermsDays === undefined) {
      newCustomerPlan.netPaymentTermsDays =
        props.netPaymentTermsDaysClientDefault ?? undefined;
    }
    setCustomerPlan(newCustomerPlan);
  }, []);

  const { environmentType } = useEnvironment();

  const { data, loading, error } = useGetStripeCustomerQuery({
    skip: customerPlan.stripeConfig?.billingProviderCustomerId == null,
    variables: {
      environment_type: environmentType,
      stripe_customer_id:
        customerPlan.stripeConfig?.billingProviderCustomerId ?? "",
    },
  });
  useEffect(() => {
    if (customerPlan.stripeConfig) {
      setCustomerPlan({
        ...customerPlan,
        stripeConfig: {
          ...customerPlan.stripeConfig,
          valid: !!(
            customerPlan.stripeConfig.billingProviderCustomerId &&
            customerPlan.stripeConfig.collectionMethod
          ),
        },
      });
    }
  }, [JSON.stringify(customerPlan.stripeConfig), data]);

  const netPaymentTermsError = getNetPaymentTermsError(customerPlan);

  return (
    <WizardFullPage>
      {props.hasConfiguredStripe && (
        <InputSection
          header="Configure Stripe"
          subtitle="Add Stripe as a billing provider to automatically invoice this customer."
        >
          <div className="mb-12 flex flex-row items-start">
            <div className="min-h-[75px] w-[372px]">
              <Input
                name="Stripe ID"
                placeholder="Enter Stripe customer ID"
                value={
                  customerPlan.stripeConfig?.billingProviderCustomerId ?? ""
                }
                success={
                  !!(
                    data?.stripeCustomer?.name != null ||
                    (customerPlan.stripeConfig?.billingProviderCustomerId &&
                      customerPlan.stripeConfig.billingProviderCustomerId ===
                        props.existingStripeConfig
                          ?.billing_provider_customer_id)
                  )
                }
                error={
                  (error?.message
                    ? "Failed to validate Stripe ID"
                    : undefined) ||
                  (customerPlan.stripeConfig?.billingProviderCustomerId &&
                  !loading &&
                  data?.stripeCustomer === null
                    ? "Enter a valid Stripe ID"
                    : undefined)
                }
                onChange={(v) =>
                  setCustomerPlan({
                    ...customerPlan,
                    stripeConfig:
                      v.length > 0
                        ? {
                            ...customerPlan.stripeConfig,
                            billingProviderCustomerId: v,
                          }
                        : undefined,
                  })
                }
              />
              <Body level={2}>{data?.stripeCustomer?.name ?? ""}</Body>
            </div>
            <div className="ml-[22px] w-[208px]">
              <Select
                name="Collection Method"
                options={[
                  {
                    label: "Charge automatically",
                    value: "charge_automatically",
                  },
                  { label: "Send invoice", value: "send_invoice" },
                ]}
                value={customerPlan.stripeConfig?.collectionMethod ?? ""}
                disabled={!customerPlan.stripeConfig}
                onChange={(v) =>
                  setCustomerPlan({
                    ...customerPlan,
                    stripeConfig: {
                      ...customerPlan.stripeConfig,
                      collectionMethod: v as
                        | "charge_automatically"
                        | "send_invoice",
                    },
                  })
                }
                placeholder="Select"
              />
            </div>
          </div>
        </InputSection>
      )}
      <InputSection
        header="Add custom net payment terms"
        subtitle="Set the number of days until a customer’s full payment is due after an invoice has been issued. If charged automatically, net payment terms are not applicable."
      >
        <div className="mb-12 flex flex-row items-start">
          <NumericInput
            name="Net payment terms"
            placeholder="Enter number of days"
            value={customerPlan.netPaymentTermsDays ?? undefined}
            error={netPaymentTermsError}
            min={0}
            precision={0}
            onChange={(v) =>
              setCustomerPlan({
                ...customerPlan,
                netPaymentTermsDays: v,
              })
            }
          />
        </div>
      </InputSection>
    </WizardFullPage>
  );
};
