import React, { useState } from "react";
import { TextInput } from "tenaissance/components/Input";
import { handleCopyClick } from "pages/GeneralSettings/hooks/copyToken";

export const StripeToken: React.FC<{ tokenID: string }> = ({ tokenID }) => {
  const [copiedTokenID, setCopiedTokenID] = useState<string | null>(null);
  const copyToken = async (tokenID: string) => {
    await handleCopyClick(tokenID, setCopiedTokenID);
  };
  return (
    <TextInput
      label="Account ID"
      value={tokenID}
      disabled
      variantOptions={{
        button: {
          leadingIcon: "copy01",
          onClick: async () => {
            await copyToken(tokenID);
          },
          text: copiedTokenID === tokenID ? "Copied" : "Copy",
          theme: "secondary",
        },
        variant: "button",
      }}
      fullWidth={true}
    />
  );
};
