import React from "react";

import { PopConfirm } from "components/PopConfirm";
import { Button } from "tenaissance/components/Button";
import { useAuth } from "lib/auth";

import { useArchiveUserMutation } from "./queries.graphql";

export const DeleteAccountButton: React.FC<{ disabled: boolean }> = ({
  disabled,
}) => {
  const { userID, logout, impersonated } = useAuth();
  const [archiveUserMutation, archiveUserResults] = useArchiveUserMutation();

  const archiveUser = async () => {
    await archiveUserMutation({ variables: { id: userID } });
    logout();
  };

  return (
    <PopConfirm
      content={`Are you sure you want to delete your account? You'll immediately lose access to your Metronome organization.`}
      onConfirm={archiveUser}
      position="bottom"
      type="error"
    >
      <Button
        disabled={impersonated || archiveUserResults.loading || disabled}
        loading={archiveUserResults.loading}
        text="Delete account"
        theme="linkDestructive"
      />
    </PopConfirm>
  );
};
