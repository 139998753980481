import z from "zod";
import { Input, Body, DateInput, Toggle, Tooltip } from "design-system";
import {
  RolloverSettingsInput,
  RolloverSettingsInputFieldErrors,
  RolloverSettingsFormData,
  rolloverSettingsInputSchema,
} from "lib/rolloverSettings";
import React, { useState } from "react";
import { RolloverAmountInputs } from "./RolloverAmountInputs";
import styles from "./index.module.less";
import { CreditType } from "types/credit-types";

type RolloverSettingsInputsProps =
  | {
      disabled: true;
      disabledReason: string;
      value?: undefined;
      onChange?: undefined;
      onError?: undefined;
      pricingUnit?: undefined;
      initialPriority?: undefined;
    }
  | {
      disabled?: false;
      value?: RolloverSettingsInput;
      onChange: (input: RolloverSettingsInput | undefined) => void;
      onError?: (message: string) => void;
      pricingUnit: CreditType;
      initialPriority?: string;
    };

export const RolloverSettingsInputs: React.FC<RolloverSettingsInputsProps> = (
  props,
) => {
  const [fieldErrors, setFieldErrors] =
    useState<RolloverSettingsInputFieldErrors>();
  const [isRolloverToggleOn, setIsRolloverToggleOn] = useState<boolean>(
    props.value !== undefined,
  );
  const [settingsInput, setSettingsInput] = useState<
    Partial<RolloverSettingsFormData> | undefined
  >({
    priority: props.initialPriority,
    ...props.value,
  });
  const handleChange = (newSettings: RolloverSettingsFormData | undefined) => {
    setSettingsInput(newSettings);

    const result = z
      .union([rolloverSettingsInputSchema, z.undefined()])
      .safeParse(newSettings);
    if (result.success) {
      setFieldErrors(undefined);
      props.onChange?.(result.data);
    } else {
      setFieldErrors(result.error.flatten().fieldErrors);
      props.onError?.("Invalid rollover settings");
    }
  };

  return (
    <div className="flex flex-col gap-16">
      <div>
        <p className="text-gray-600">Add a credit rollover</p>
        <Body level={2} noBottomMargin>
          Enable a single rollover for an expired credit grant. The rollover
          grant will be generated and included in the ledger. Once created, no
          further rollovers can be applied to this specific grant.
        </Body>
      </div>
      <Tooltip
        disabled={!props.disabled}
        content={props.disabled ? props.disabledReason : ""}
        position={["left"]}
      >
        <Toggle
          label="Rollover credits"
          disabled={props.disabled}
          checked={isRolloverToggleOn}
          onChange={(checked) => {
            if (checked) {
              if (props.value === undefined) {
                props.onError?.("Rollover settings are empty");
              }
            } else {
              handleChange(undefined);
            }
            setIsRolloverToggleOn(checked);
          }}
          compact
        />
      </Tooltip>
      {isRolloverToggleOn && !props.disabled && (
        <div className="flex flex-col gap-16">
          <RolloverAmountInputs
            value={settingsInput?.rollover_amount}
            onChange={(rollover_amount) => {
              handleChange({ ...settingsInput, rollover_amount });
            }}
            onError={(error) => {
              props.onError?.(error);
            }}
            pricingUnit={props.pricingUnit}
          />
          <div className={styles.datePickers}>
            <DateInput
              className={styles.gray}
              name="Expiry date"
              isUTC
              value={
                settingsInput?.expiry_date
                  ? new Date(settingsInput.expiry_date)
                  : undefined
              }
              onChange={(expiry_date) =>
                handleChange({
                  ...settingsInput,
                  expiry_date: expiry_date.toISOString(),
                })
              }
            />
          </div>
          <Input
            className={styles.gray}
            type="number"
            name="Priority"
            placeholder=""
            value={settingsInput?.priority ?? ""}
            onChange={(priority) =>
              handleChange({ ...settingsInput, priority })
            }
            error={fieldErrors?.priority?.[0]}
          />
        </div>
      )}
    </div>
  );
};
