import React from "react";

import FlipMove from "react-flip-move";
import classnames from "classnames";
import { IconName, Icon } from "design-system";

import styles from "./SnackStack.module.less";

type Type = "info" | "success" | "error" | "warning";
export type Message = {
  type?: Type;
  content: React.ReactNode;
  durationMS?: number;
  id: string;
};

function iconForType(type: Type): IconName {
  switch (type) {
    case "success":
      return "checkmarkCircle";
    case "error":
      return "alertCircle";
    case "warning":
      return "warning";
    case "info":
      return "informationCircle";
  }
}

const Snack: React.FC<{ message: Message }> = (props) => {
  const type = props.message.type || "info";

  return (
    <div className={styles.snack}>
      <Icon
        icon={iconForType(type)}
        className={classnames(styles.icon, styles[type])}
      />
      {props.message.content}
    </div>
  );
};

type SnackStackProps = {
  messages: Message[];
};

export const SnackStack: React.FC<SnackStackProps> = (props) => {
  return (
    <div className={classnames(styles.stack, "z-toast")}>
      <FlipMove
        duration={150}
        enterAnimation={{
          from: { transform: "translateY(100%)", opacity: "0" },
          to: { transform: "", opacity: "" },
        }}
        leaveAnimation={{
          from: { transform: "scaleY(100%)", opacity: "1" },
          to: { transform: "scaleY(0%)", opacity: "0" },
        }}
      >
        {props.messages.map((m) => (
          <div key={m.id}>
            <Snack message={m} />
          </div>
        ))}
      </FlipMove>
    </div>
  );
};
