import React, { Dispatch, SetStateAction } from "react";

import { OptionGroup } from "tenaissance/components/OptionGroup";
import classNames from "classnames";
import { RadioButton } from "tenaissance/components/RadioButton";

interface DataSyncStepProps {
  isWorkspaceCreated: boolean;
  isSalesforceDestinationLinked: boolean;
  setSyncOnlyLinkedAccounts: Dispatch<SetStateAction<undefined | boolean>>;
}

export const DataSyncStep: React.FC<DataSyncStepProps> = ({
  isWorkspaceCreated,
  isSalesforceDestinationLinked,
  setSyncOnlyLinkedAccounts,
}) => {
  const disableButtons = !isWorkspaceCreated || !isSalesforceDestinationLinked;

  const salesforceCustomerRadioButtons = [
    <RadioButton
      key={1}
      label="Sync all customers"
      value="syncAllCustomers"
      supportingText="Sync all customers in Metronome, regardless if they exist in Salesforce. This will generate a Salesforce customer profile for any customer created in Metronome."
      className={classNames(
        "text-sm leading-5 text-gray-600",
        disableButtons ? "bg-gray-50" : "",
      )}
      name="salesforceCustomerRadioButtons"
      disabled={disableButtons}
      size="sm"
      onChange={({ checked }) => {
        setSyncOnlyLinkedAccounts(!checked);
      }}
    />,
    <RadioButton
      key={2}
      label="Sync only Salesforce customers"
      value="syncOnlySalesforceCustomers"
      supportingText="Only sync data for customers that already exist in Salesforce."
      className={classNames(
        "text-sm leading-5 text-gray-600",
        disableButtons ? "bg-gray-50" : "",
      )}
      name="salesforceCustomerRadioButtons"
      disabled={disableButtons}
      size="sm"
      onChange={({ checked }) => {
        setSyncOnlyLinkedAccounts(checked);
      }}
    />,
  ];
  return (
    <div className="mb-3xl">
      <h4 className="mb-lg text-sm font-semibold text-black">
        4. Determine what data to sync
      </h4>
      <div className="mb-lg text-sm text-gray-600">
        Choose between syncing all Metronome customers or just those linked to
        Salesforce.
      </div>
      <OptionGroup
        children={salesforceCustomerRadioButtons}
        className="w-full"
      ></OptionGroup>
    </div>
  );
};
