import React, { useState } from "react";
import { Popup } from "components/Popup";
import { Copy } from "components/Copy";

import { useInsertTokenMutation } from "../../data/tokens.graphql";
import { Body, Input, Icon } from "design-system";

import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";

import { useEnvironment } from "lib/environmentSwitcher/context";

type CreateTokenModalProps = {
  onClose: () => void;
};

export const CreateTokenModal: React.FC<CreateTokenModalProps> = ({
  onClose,
}) => {
  const { environmentType } = useEnvironment();
  const [name, setName] = useState<string | null>(null);

  const [createToken, createTokenResult] = useInsertTokenMutation({
    update: (cache) => cache.evict({ fieldName: "Tokens" }),
  });

  if (createTokenResult.data && createTokenResult.data.create_api_token) {
    const doneButton = (
      <Button key="primary" onClick={onClose} text="Done" theme="primary" />
    );

    return (
      <Popup
        title="Your new API token"
        isOpen
        onRequestClose={onClose}
        actions={doneButton}
        className="w-[600px]"
      >
        <Body level={2}>
          You have successfully created your new token.
          <br />
          <Icon icon="informationCircle" className="mr-4 text-primary-600" />
          Make sure to copy your token. You won't be able to see it again.
        </Body>
        <Input
          name="Token name"
          value={name || ""}
          placeholder="Descriptive token name"
          disabled
        />
        <div className="flex items-end justify-end">
          <Input
            name="Token string"
            value={createTokenResult.data.create_api_token.token_string}
            placeholder="Token string"
            disabled
            className="w-full"
          />
          <Copy text={createTokenResult.data.create_api_token.token_string}>
            <IconButton className="mb-4" theme="tertiary" icon="copy02" />
          </Copy>
        </div>
      </Popup>
    );
  }

  const actionButton = (
    <Button
      key="primary"
      onClick={async () => {
        if (!name) {
          // Button should be disabled in this case, but this is to appease Typescript below.
          return;
        }
        await createToken({
          variables: {
            environment_type: environmentType,
            name,
          },
        });
        if (
          createTokenResult.data &&
          !createTokenResult.data.create_api_token
        ) {
          onClose();
        }
      }}
      disabled={!name || createTokenResult.loading || createTokenResult.called}
      loading={createTokenResult.loading || createTokenResult.called}
      text="Create new token"
      theme="primary"
    />
  );
  return (
    <Popup
      title="Create new API token"
      isOpen
      onRequestClose={onClose}
      actions={actionButton}
    >
      <Body level={2}>
        This API token will have read and write access to your data inside of
        Metronome. Please use a descriptive name, as resources modified with
        this API token will reference the token name.
      </Body>
      <Input
        name="Token name"
        value={name || ""}
        onChange={setName}
        error={name !== null && !name ? "You must provide a name" : undefined}
        placeholder="Descriptive token name"
        autoFocus={true}
      />
    </Popup>
  );
};
