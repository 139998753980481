import React, { useState, useEffect } from "react";

import {
  ProductsQuery,
  useProductsQuery,
} from "pages/Products/data/queries.graphql";

import { AvatarWithName, Input, Tooltip } from "design-system";
import { Badge } from "tenaissance/components/Badge";
import { IconButton } from "tenaissance/components/IconButton";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Filter, OptionType } from "components/Filter";
import { SearchTooltip } from "components/SearchTooltip";
import useDebounce from "lib/debounce";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { renderDate } from "lib/time";
import { useNavigate } from "lib/useNavigate";
import { useSearchParam } from "lib/routes/useSearchParam";
import { gatedAction, useAuthCheck } from "lib/useAuthCheck";
import { NewProductDocument } from "pages/NewProduct/queries.graphql";
import { EditProductDocument } from "pages/EditProduct/data/queries.graphql";
import { MenuItemProps, PopoverMenu } from "components/PopoverMenu";
import { Table } from "tenaissance/components/Table";
import pluralize from "pluralize";
import { ProductTypeEnum } from "types/generated-graphql/__types__";
import { GatedButton } from "components/GatedButton";
import {
  PRODUCT_OPTIONS,
  PRODUCT_OPTIONS_DEFAULT,
} from "pages/Products/filters";
import { ArchiveProductDocument } from "pages/Products/components/ArchiveProductModal/queries.graphql";
import ArchiveProductModal from "pages/Products/components/ArchiveProductModal";
import { ButtonGroup, ButtonProps } from "tenaissance/components/ButtonGroup";
import { useUIMode } from "lib/useUIMode";
import { dayjs } from "lib/date";
import { Timestamp } from "tenaissance/components/Timestamp";
import { OFFERING_PAGES_TABLE_PAGE_SIZE } from "../../constants";

interface ProductsTableProps extends React.PropsWithChildren {
  titleButtons: ButtonProps[];
}
export type Product = ProductsQuery["products"][0];
const NUM_ROWS = 15;

export const ProductsTable: React.FC<ProductsTableProps> = (props) => {
  const { environmentType } = useEnvironment();

  const [filters, setFilters] = useState<readonly OptionType[]>(
    PRODUCT_OPTIONS_DEFAULT,
  );
  const [productToArchive, setProductToArchive] = useState<Product | null>(
    null,
  );

  const types: ProductTypeEnum[] = [];
  const includeActive = filters.some(
    (f) => f.value === "has_plan" || f.value === "no_plan",
  );
  const includeArchived = filters.some((f) => f.value === "archived");

  if (includeActive) types.push(ProductTypeEnum.Active);
  if (includeArchived) types.push(ProductTypeEnum.Archived);

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useSearchParam("q");
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 400);

  const [pageNumberToCursor, setPageNumberToCursor] = React.useState<string[]>(
    [],
  );
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentCursor, setCurrentCursor] = React.useState<string | undefined>(
    undefined,
  );
  const { mode } = useUIMode();

  useEffect(() => {
    // Reset cursor and page when changing a filter or adding a search param
    setPageNumberToCursor([]);
    setCurrentPage(0);
    setCurrentCursor(undefined);
  }, [debouncedSearchQuery, filters]);

  const { data, loading, error } = useProductsQuery({
    variables: {
      environment_type: environmentType,
      types,
      limit: NUM_ROWS + 1,
      cursor: currentCursor,
      search: debouncedSearchQuery || undefined,
    },
    skip: types.length === 0,
  });

  const products: Product[] = data?.products || [];
  const allProducts = products || [];
  const currentPageProducts = allProducts.slice(0, NUM_ROWS);
  const hasMore = allProducts.length > NUM_ROWS;

  // We need to filter client-side for "has_plan" and "no_plan" since the
  // graphql query doesn't have active_plan_count to differentiate between the two
  const filteredProducts = currentPageProducts.filter((p) => {
    for (const filterOption of filters) {
      if (filterOption.group === "product_status") {
        switch (filterOption.value) {
          case "has_plan": // "Active"
            if (!p.deprecated_at && p.active_plan_count) return true;
            break;
          case "no_plan": // "Published"
            if (!p.deprecated_at && p.active_plan_count === 0) return true;
            break;
          case "archived":
            if (p.deprecated_at) return true;
            break;
        }
      }
    }
    return false;
  });

  useEffect(() => {
    // This handles an edge case with client-side filtering for "Active / has_plan"
    // and "Published / no_plan". See note on `filteredProducts`
    // ---
    // EDGE CASE: We have full page of results, but there are 0 products matching
    // client-side filter for the current page. There are more pages...
    // In this case, we need to fetch the next page.
    //
    // EXAMPLE:
    //   - Say page size is 2
    //   - Products are: [no_plan_prod1, no_plan_prod2, no_plan_prod3, has_plan_prod4]
    //   - We are filtering client-side for "active / has_plan"
    //   - In this case, page 1 will display 0 results, so we need to trigger next page
    //   - The next page will then display the last item: has_plan_prod4
    if (filteredProducts.length === 0 && hasMore) {
      goNextPage();
    }
  }, [filteredProducts]);

  const goNextPage = () => {
    const newCursor = filteredProducts[NUM_ROWS - 1].id;
    setPageNumberToCursor({
      ...pageNumberToCursor,
      [currentPage + 1]: newCursor,
    });
    setCurrentPage(currentPage + 1);
    setCurrentCursor(newCursor);
  };

  const canArchiveProduct = !!useAuthCheck(ArchiveProductDocument, true)
    .allowed;
  const canEditProduct = !!useAuthCheck(EditProductDocument, true).allowed;

  const getActions = (product: Product) => {
    let actions: MenuItemProps[] = [];
    if (!product) {
      throw new Error("Error creating Product actions");
    }
    if (product.deprecated_at === null) {
      actions = [
        {
          content: "Manage custom fields...",
          onClick: () => navigate(`/custom-fields/product/${product.id}`),
        },
        gatedAction(canEditProduct, {
          content: "Edit product...",
          onClick: () =>
            navigate(`/offering/plans/products/${product.id}/edit`),
        }),
        gatedAction(canArchiveProduct, {
          disabled: !!product.active_plan_count,
          onClick: () => setProductToArchive(product),
          content: product.active_plan_count ? (
            <Tooltip content="Products in use cannot be archived">
              Archive product...
            </Tooltip>
          ) : (
            "Archive product..."
          ),
        }),
      ];
    }
    return actions;
  };

  const archiveModal = productToArchive && (
    <ArchiveProductModal
      onClose={() => setProductToArchive(null)}
      productId={productToArchive.id}
      productName={productToArchive.name}
    />
  );

  const emptyState = () => {
    if (error) {
      return (
        <EmptyState
          mainText="We ran into an issue loading your products"
          supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
          icon="shoppingCart01"
        />
      );
    } else if (!error && !loading && filteredProducts.length === 0) {
      if (
        debouncedSearchQuery.length === 0 &&
        allProducts.length === 0 &&
        includeActive
      ) {
        return (
          <EmptyState
            mainText="You don't have any products yet."
            supportingText="Once you add products, you'll see their details here."
            icon="shoppingCart01"
            actions={[
              <GatedButton
                doc={NewProductDocument}
                onClick={() => navigate("/offering/plans/products/new")}
                text="Add product"
                theme="primary"
                leadingIcon="plus"
                size="sm"
              />,
            ]}
          />
        );
      } else {
        return (
          <EmptyState
            mainText="No products found"
            supportingText="No products match this filter."
            icon="shoppingCart01"
          />
        );
      }
    }
  };

  return (
    <>
      {archiveModal}
      <Table
        title={
          mode === "plans-only" ? (
            "Product catalog"
          ) : (
            <ButtonGroup buttons={props.titleButtons} />
          )
        }
        loading={loading}
        rowRoutePath={(row) => `/offering/plans/products/${row.id}`}
        data={filteredProducts}
        emptyState={emptyState()}
        columns={[
          {
            id: "name",
            header: "Name",
            cell: (props) => props.getValue(),
            accessorKey: "name",
          },
          {
            id: "plan-count",
            header: "Plan count",
            cell: (props) => props.getValue(),
            accessorFn: (product) => product.active_plan_count,
          },
          {
            id: "charge-count",
            header: "Charge count",
            cell: (props) => props.getValue(),
            accessorFn: (product) => {
              const count =
                product.ProductPricingFactors_aggregate.aggregate?.count ?? 0;

              return `${count} ${pluralize("Charge", count)}`;
            },
          },
          {
            id: "user",
            header: "User",
            cell: (props) => props.getValue(),
            accessorFn: (product) =>
              product.Actor && (
                <Tooltip
                  content={
                    <>
                      Created by {product.Actor.name}
                      <br />
                      {renderDate(new Date(product.created_at), {
                        isUtc: false,
                      })}
                    </>
                  }
                >
                  <AvatarWithName {...product.Actor} />
                </Tooltip>
              ),
          },
          {
            id: "last_edited",
            header: "Last edited",
            cell: (props) => (
              <Timestamp dateTime={props.getValue()} dateOnly={true} />
            ),
            accessorFn: (product) =>
              dayjs.utc(new Date(product.updated_at)).toDate(),
          },
          {
            id: "status",
            header: "Status",
            cell: (props) => props.getValue(),
            accessorFn: (product) => {
              if (product.deprecated_at !== null) {
                return <Badge label="Archived" theme="warning" />;
              } else if (product.active_plan_count) {
                return <Badge label="Active" theme="success" />;
              } else {
                return <Badge label="Published" theme="azure-blue" />;
              }
            },
          },
          {
            id: "actions",
            header: "",
            cell: (product) => (
              <PopoverMenu
                positions={["bottom"]}
                align="end"
                options={getActions(product.getValue())}
              >
                {(onClick) => (
                  <IconButton
                    onClick={onClick}
                    theme="tertiary"
                    icon="dotsVertical"
                  />
                )}
              </PopoverMenu>
            ),
            accessorFn: (r) => r,
          },
        ]}
        paginationOptions={{
          type: "clientSide",
          pageSize: OFFERING_PAGES_TABLE_PAGE_SIZE,
        }}
        topBarActions={[
          <div className="flex flex-row items-center" key={0}>
            <SearchTooltip searchText="products">
              <Input
                type="search"
                placeholder="Search"
                value={searchQuery}
                onChange={setSearchQuery}
                leftIcon="search"
                className="w-[208px]"
              />
            </SearchTooltip>
            <Filter
              value={filters}
              options={PRODUCT_OPTIONS}
              onChange={setFilters}
              onReset={() => setFilters(PRODUCT_OPTIONS_DEFAULT)}
            />
            <GatedButton
              doc={NewProductDocument}
              className="ml-12"
              onClick={() => navigate("/offering/plans/products/new")}
              text="Add"
              theme="primary"
              leadingIcon="plus"
              size="sm"
            />
          </div>,
        ]}
      />
    </>
  );
};
