import React from "react";

import styles from "./index.module.less";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { useDeleteSalesforceAccountIdMutation } from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";

type SalesforceConnectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  customerId: string;
};

export const DeleteSalesforceConnectionModal: React.FC<
  SalesforceConnectionModalProps
> = ({ isOpen, onClose, customerId }) => {
  const pushMessage = useSnackbar();
  const [deleteSalesforceAccountIdMutation, { loading }] =
    useDeleteSalesforceAccountIdMutation();

  return (
    <Popup
      isOpen={isOpen}
      onRequestClose={onClose}
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            onClick={async () => {
              await deleteSalesforceAccountIdMutation({
                variables: {
                  customer_id: customerId,
                },
                update: (cache) => {
                  cache.evict({
                    fieldName: "CustomerConfig",
                  });
                },
              });
              pushMessage({
                content: "Salesforce account ID deleted",
                type: "success",
              });
              onClose();
            }}
            loading={loading}
            disabled={loading}
            text="Yes, Delete Account ID"
            theme="linkDestructive"
          />
        </>
      }
      title="Are you sure you want to delete this Salesforce account ID?"
      className={styles.salesforceConnectionModal}
    >
      <Body level={1} className={styles.description}>
        Deleting the Salesforce account ID will prevent further uploads of
        Metronome data to Salesforce for this customer.
      </Body>
    </Popup>
  );
};
