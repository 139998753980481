import type { JSONSchema7 } from "json-schema";
import React from "react";
import {
  Condition,
  Field,
  jsonSchemaToConditions,
} from "@metronome-industries/json-schema-conditions";
import styles from "./index.module.less";

const conditionsSort = (a: Condition, b: Condition) => {
  return a.field.join(".") < b.field.join(".") ? -1 : 1;
};

const HighlightedKeyword: React.FC<{ keyword: string }> = ({ keyword }) => {
  return <span className={styles.operator}>{keyword}</span>;
};

const DisplayField: React.FC<{ field: Field }> = ({ field }) => {
  if (field.length > 1) {
    return (
      <>
        <span className={styles.field}>{field[0]}</span>[
        <span className={styles.string}>{`"${field[1]}"`}</span>]
      </>
    );
  }
  return <span className={styles.field}>{field[0]}</span>;
};

const FilterRow: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div>
      <span className={styles.filter}>{children}</span>
    </div>
  );
};

const EnumValues: React.FC<{ values: string[] }> = ({ values }) => {
  const formattedValues = values.map((value, index) => {
    if (values.length === 1) {
      return (
        <span key={index}>
          (<span className={styles.string}>{`"${value}"`}</span>)
        </span>
      );
    }

    if (values.length === index + 1) {
      return (
        <span key={index}>
          <span className={styles.string}>{`"${value}"`}</span>)
        </span>
      );
    } else if (index === 0) {
      return (
        <span key={index}>
          (<span className={styles.string}>{`"${value}"`}</span>,{" "}
        </span>
      );
    }
    return (
      <span key={index}>
        <span className={styles.string}>{`"${value}"`}</span>
        {", "}
      </span>
    );
  });
  return <>{formattedValues}</>;
};

export const JSONSchemaDisplay: React.FC<{ jsonSchema: JSONSchema7 }> = ({
  jsonSchema,
}) => {
  let conditions: Condition[] = [];
  try {
    conditions = jsonSchemaToConditions(jsonSchema).sort(conditionsSort);
  } catch (error) {
    return (
      <div className={styles.displayCase}>
        <FilterRow>
          This billable metric uses advanced filtering that cannot be displayed
          here.
        </FilterRow>
      </div>
    );
  }
  if (conditions.length === 0) {
    return (
      <div className={styles.displayCase}>
        <FilterRow>Matches all usage events</FilterRow>
      </div>
    );
  }
  return <ConditionsDisplay conditions={conditions} />;
};

export const ConditionsDisplay: React.FC<{ conditions: Condition[] }> = ({
  conditions,
}) => {
  let keyCount = 0;
  const display: JSX.Element[] = [];
  conditions.forEach((condition) => {
    if (condition.required !== undefined) {
      display.push(
        <FilterRow key={keyCount++}>
          <DisplayField field={condition.field} />
          <HighlightedKeyword
            keyword={condition.required ? " exists" : " does not exist"}
          />
        </FilterRow>,
      );
    }
    if (condition.enum !== undefined && condition.enum.values.length) {
      display.push(
        <FilterRow key={keyCount++}>
          {condition.required === undefined && (
            <HighlightedKeyword keyword="when " />
          )}
          <DisplayField field={condition.field} />
          {condition.required === undefined && (
            <HighlightedKeyword keyword=" exists " />
          )}
          {condition.required === undefined && (
            <DisplayField field={condition.field} />
          )}
          {condition.enum.not ? (
            <HighlightedKeyword keyword=" not in " />
          ) : (
            <HighlightedKeyword keyword=" in " />
          )}
          <EnumValues values={condition.enum.values} />
        </FilterRow>,
      );
    }
  });

  return <div className={styles.displayCase}>{display}</div>;
};
