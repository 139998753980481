import React from "react";

import { Timestamp } from "tenaissance/components/Timestamp";
import { AvatarWithName, Body, Headline, Label } from "design-system";
import { Button } from "tenaissance/components/Button";
import { IconButton } from "tenaissance/components/IconButton";
import { RightPane } from "components/Popup";
import { AuditLogStatusPill } from "../AuditLogStatusPill";
import { useGetAuditLogByIdQuery } from "components/AuditLogTable/auditlogs.graphql";
import { BlockSkeleton } from "components/Skeleton";
import { ClientResourceTooltip } from "../ClientResourceTooltip";
import {
  linkFromResource,
  prettyResourceType,
} from "components/AuditLogTable/lib/resources";

export const AuditLogPane: React.FC<{
  id: string;
  requestTimestamp: string;
  onClose: () => void;
}> = (props) => {
  const { data, loading } = useGetAuditLogByIdQuery({
    variables: {
      log_id: props.id,
      requestTimestamp: props.requestTimestamp,
    },
  });

  if (loading) {
    return (
      <RightPane isOpen>
        <BlockSkeleton />
      </RightPane>
    );
  }

  if (!data?.audit_log) {
    props.onClose();
    return null;
  }

  const linkToResource = linkFromResource(
    data.audit_log.resource_type,
    data.audit_log.resource_id,
  );

  return (
    <RightPane isOpen onRequestClose={props.onClose} className="text-gray-600">
      <header className="flex items-center pb-16">
        <Headline level={6} className="grow">
          Details
        </Headline>
        <IconButton
          theme="tertiary"
          icon="x"
          className="m-0"
          onClick={props.onClose}
        />
      </header>
      <Label>Summary</Label>
      <Body level={2} className="border-b border-deprecated-gray-100 pb-12">
        {data?.audit_log.description ?? "No summary available"}
      </Body>
      <div className="grid grid-cols-2 gap-16">
        <div>
          <Label>Time (UTC)</Label>
          <div>
            <Timestamp dateTime={new Date(data.audit_log.request_timestamp)} />
          </div>
        </div>
        <div>
          <Label>Actor</Label>
          <AvatarWithName {...data.audit_log.actor} />
        </div>
        <div>
          <Label>Status</Label>
          <AuditLogStatusPill status={data.audit_log.status} size="sm" />
        </div>
        <div>
          <Label>Action</Label>
          <div>{data.audit_log.action}</div>
        </div>
        <div className="col-span-2">
          <Label>Resource</Label>
          <div className="nowrap">
            {data.audit_log.resource_type === "client" ? (
              <ClientResourceTooltip />
            ) : (
              <>
                {prettyResourceType(data.audit_log.resource_type)}:{" "}
                {data.audit_log.resource_id}
                {linkToResource ? (
                  <Button
                    size="sm"
                    linkTo={linkToResource}
                    text={`View ${prettyResourceType(data.audit_log.resource_type).toLowerCase()}`}
                    theme="primary"
                    trailingIcon="linkExternal01"
                    className="mt-8"
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </RightPane>
  );
};
