/* 040740f7937cc6ee281d8bfd204d08d1b252fae9
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFixedProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFixedProductsQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> }> } };

type ProductListItemId_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', id: string };

type ProductListItemId_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', id: string };

type ProductListItemId_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', id: string };

type ProductListItemId_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', id: string };

type ProductListItemId_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', id: string };

export type ProductListItemIdFragment = ProductListItemId_CompositeProductListItem_Fragment | ProductListItemId_FixedProductListItem_Fragment | ProductListItemId_ProServiceProductListItem_Fragment | ProductListItemId_SubscriptionProductListItem_Fragment | ProductListItemId_UsageProductListItem_Fragment;

type ProductListItemName_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

export type ProductListItemNameFragment = ProductListItemName_CompositeProductListItem_Fragment | ProductListItemName_FixedProductListItem_Fragment | ProductListItemName_ProServiceProductListItem_Fragment | ProductListItemName_SubscriptionProductListItem_Fragment | ProductListItemName_UsageProductListItem_Fragment;

export type GetProServiceProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProServiceProductsQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> }> } };

export type GetAllProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllProductsQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> }> } };

type ProductListItemTags_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

export type ProductListItemTagsFragment = ProductListItemTags_CompositeProductListItem_Fragment | ProductListItemTags_FixedProductListItem_Fragment | ProductListItemTags_ProServiceProductListItem_Fragment | ProductListItemTags_SubscriptionProductListItem_Fragment | ProductListItemTags_UsageProductListItem_Fragment;

export type GetProductNamesQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type GetProductNamesQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> }> } };

export const ProductListItemIdFragmentDoc = gql`
    fragment ProductListItemId on ProductListItem {
  ... on FixedProductListItem {
    id
  }
  ... on ProServiceProductListItem {
    id
  }
  ... on UsageProductListItem {
    id
  }
  ... on SubscriptionProductListItem {
    id
  }
  ... on CompositeProductListItem {
    id
  }
}
    `;
export const ProductListItemNameFragmentDoc = gql`
    fragment ProductListItemName on ProductListItem {
  ... on FixedProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
}
    `;
export const ProductListItemTagsFragmentDoc = gql`
    fragment ProductListItemTags on ProductListItem {
  ... on FixedProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
}
    `;
export const GetFixedProductsDocument = gql`
    query GetFixedProducts {
  contract_pricing {
    products(types: [FIXED]) {
      __typename
      ...ProductListItemId
      ...ProductListItemName
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}`;

/**
 * __useGetFixedProductsQuery__
 *
 * To run a query within a React component, call `useGetFixedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFixedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFixedProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFixedProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetFixedProductsQuery, GetFixedProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFixedProductsQuery, GetFixedProductsQueryVariables>(GetFixedProductsDocument, options);
      }
export function useGetFixedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFixedProductsQuery, GetFixedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFixedProductsQuery, GetFixedProductsQueryVariables>(GetFixedProductsDocument, options);
        }
export type GetFixedProductsQueryHookResult = ReturnType<typeof useGetFixedProductsQuery>;
export type GetFixedProductsLazyQueryHookResult = ReturnType<typeof useGetFixedProductsLazyQuery>;
export type GetFixedProductsQueryResult = Apollo.QueryResult<GetFixedProductsQuery, GetFixedProductsQueryVariables>;
export const GetProServiceProductsDocument = gql`
    query GetProServiceProducts {
  contract_pricing {
    products(types: [PRO_SERVICE]) {
      __typename
      ...ProductListItemId
      ...ProductListItemName
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}`;

/**
 * __useGetProServiceProductsQuery__
 *
 * To run a query within a React component, call `useGetProServiceProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProServiceProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProServiceProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProServiceProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProServiceProductsQuery, GetProServiceProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProServiceProductsQuery, GetProServiceProductsQueryVariables>(GetProServiceProductsDocument, options);
      }
export function useGetProServiceProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProServiceProductsQuery, GetProServiceProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProServiceProductsQuery, GetProServiceProductsQueryVariables>(GetProServiceProductsDocument, options);
        }
export type GetProServiceProductsQueryHookResult = ReturnType<typeof useGetProServiceProductsQuery>;
export type GetProServiceProductsLazyQueryHookResult = ReturnType<typeof useGetProServiceProductsLazyQuery>;
export type GetProServiceProductsQueryResult = Apollo.QueryResult<GetProServiceProductsQuery, GetProServiceProductsQueryVariables>;
export const GetAllProductsDocument = gql`
    query GetAllProducts {
  contract_pricing {
    products {
      __typename
      ...ProductListItemId
      ...ProductListItemName
      ...ProductListItemTags
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}
${ProductListItemTagsFragmentDoc}`;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
      }
export function useGetAllProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
        }
export type GetAllProductsQueryHookResult = ReturnType<typeof useGetAllProductsQuery>;
export type GetAllProductsLazyQueryHookResult = ReturnType<typeof useGetAllProductsLazyQuery>;
export type GetAllProductsQueryResult = Apollo.QueryResult<GetAllProductsQuery, GetAllProductsQueryVariables>;
export const GetProductNamesDocument = gql`
    query GetProductNames($ids: [uuid!]!) {
  contract_pricing {
    products(ids: $ids) {
      __typename
      ...ProductListItemId
      ...ProductListItemName
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}`;

/**
 * __useGetProductNamesQuery__
 *
 * To run a query within a React component, call `useGetProductNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductNamesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetProductNamesQuery(baseOptions: Apollo.QueryHookOptions<GetProductNamesQuery, GetProductNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductNamesQuery, GetProductNamesQueryVariables>(GetProductNamesDocument, options);
      }
export function useGetProductNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductNamesQuery, GetProductNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductNamesQuery, GetProductNamesQueryVariables>(GetProductNamesDocument, options);
        }
export type GetProductNamesQueryHookResult = ReturnType<typeof useGetProductNamesQuery>;
export type GetProductNamesLazyQueryHookResult = ReturnType<typeof useGetProductNamesLazyQuery>;
export type GetProductNamesQueryResult = Apollo.QueryResult<GetProductNamesQuery, GetProductNamesQueryVariables>;