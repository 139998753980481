import React from "react";

import { Headline } from "design-system";
import {
  CustomerContractLayout,
  useMinimumContractFromRoute,
} from "./CustomerContractLayout";

export const CustomerContractSettings: React.FC = () => {
  const req = useMinimumContractFromRoute();

  return (
    <CustomerContractLayout
      rootReq={req}
      content={() => (
        <>
          <Headline level={5}>Settings</Headline>
        </>
      )}
    />
  );
};
