import React from "react";
import Modal from "react-modal";
import { unstable_useBlocker } from "react-router-dom";
import classnames from "classnames";

import styles from "./index.module.less";
import { Headline } from "design-system";

import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";

interface SharedPopupProps extends React.PropsWithChildren {
  className?: string;
  isOpen: boolean;
  onRequestClose?: () => void;
}
interface PopupProps extends SharedPopupProps {
  actions: React.ReactNode;
  title: string;
  size?: "md" | "lg" | "auto";
  /** For content in a regular Popup where you want the flex container to stretch to the full width of the parent */
  fullContent?: boolean;
}

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

export const Popup: React.FC<PopupProps> = ({
  actions,
  className,
  isOpen,
  onRequestClose,
  title,
  children,
  size,
  fullContent = false,
}) => {
  return (
    <Modal
      className={classnames(
        styles.popover,
        {
          [styles.medium]: size === "md" || !size,
          [styles.large]: size === "lg",
        },
        className,
      )}
      isOpen={isOpen}
      overlayClassName={classnames(styles.overlay, "z-modal")}
      onRequestClose={onRequestClose}
    >
      <div className={styles.title}>
        <Headline level={6}>{title}</Headline>
        <IconButton
          className={styles.closeIcon}
          onClick={onRequestClose}
          theme="tertiary"
          icon="xClose"
        />
      </div>
      <div className={styles.contentContainer}>
        <div
          className={classnames(styles.content, { [styles.full]: fullContent })}
        >
          {children}
        </div>
      </div>
      <div className={styles.actions}>{actions}</div>
    </Modal>
  );
};

export const RightPane: React.FC<
  SharedPopupProps & {
    secondPane?: React.ReactNode;
    size?: "md" | "lg" | "xl";
    contentClassName?: string;
  }
> = (props) => {
  return (
    <Modal
      className={classnames(
        styles.popover,
        styles.rightPane,
        {
          [styles.withSecondPane]: !!props.secondPane,
          [styles.size_md]: props.size === "md" || !props.size,
          [styles.size_lg]: props.size === "lg",
          [styles.size_xl]: props.size === "xl",
        },
        props.className,
      )}
      isOpen={props.isOpen}
      overlayClassName={classnames(
        styles.overlay,
        styles.rightPaneOverlay,
        "z-modal",
      )}
      onRequestClose={props.onRequestClose}
    >
      <div className={styles.contentContainer}>
        <div className={classnames(styles.content, props.contentClassName)}>
          {props.children}
        </div>
        {props.secondPane && (
          <div
            className={classnames(
              styles.content,
              styles.secondPane,
              props.contentClassName,
            )}
          >
            {props.secondPane}
          </div>
        )}
      </div>
    </Modal>
  );
};

interface NavigationPromptProps extends React.PropsWithChildren {
  title: string;
  navActionName?: string;
  disabled?: boolean;
}

export const NavigationPrompt: React.FC<NavigationPromptProps> = (props) => {
  const blocker = unstable_useBlocker(!props.disabled);

  if (blocker.state !== "blocked") {
    return null;
  }

  return (
    <>
      <Popup
        isOpen
        title={props.title}
        actions={
          <>
            <Button
              onClick={() => {
                blocker.reset?.();
              }}
              text="Cancel"
              theme="linkGray"
            />
            <Button
              onClick={() => {
                blocker.proceed?.();
              }}
              text={props.navActionName ?? "OK"}
              theme="primary"
            />
          </>
        }
        onRequestClose={() => blocker.reset?.()}
      >
        {props.children}
      </Popup>
    </>
  );
};
