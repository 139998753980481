import { ConnectedCustomerIntegrationsTableRowSpec } from ".";
import { subHours } from "date-fns";

/**
 * Business logic to determine whether to show marketplace integration as 'active' or 'disabled'
 * in the UI.
 */
export function isIntegrationActive(
  rowSpec: ConnectedCustomerIntegrationsTableRowSpec,
): boolean {
  const now = new Date();
  switch (rowSpec.name) {
    case "AWS Marketplace": {
      const expirationDate = rowSpec.settings.expirationTimestamp ?? now;
      return (
        expirationDate > subHours(now, 2) && !rowSpec.settings.meteringDisabled
      );
    }
    case "Azure Marketplace": {
      const expirationDate = rowSpec.settings.expirationDate ?? now;
      return (
        !rowSpec.settings.meteringDisabled &&
        expirationDate > subHours(now, 2) &&
        rowSpec.settings.subscriptionStatus === "Subscribed"
      );
    }
    case "Stripe": {
      return rowSpec.settings?.customerId !== undefined;
    }
    case "Netsuite": {
      return rowSpec.settings?.netSuiteCustomerId !== undefined;
    }
    case "Salesforce": {
      return rowSpec.settings?.salesforceAccountId !== undefined;
    }
    case "GCP Marketplace": {
      return rowSpec.settings?.connectionDate !== undefined;
    }
    default: {
      rowSpec satisfies never;
      return false;
    }
  }
}
