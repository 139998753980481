import React from "react";
import { useSnackbar } from "components/Snackbar";
import { Popup } from "components/Popup";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { useFinalizeInvoicesMutation } from "./finalizeInvoice.graphql";

interface FinalizeInvoiceModalProps {
  onClose: () => void;
  invoiceId: string;
  customerId: string;
}

const FinalizeInvoiceModal: React.FC<FinalizeInvoiceModalProps> = ({
  onClose,
  invoiceId,
  customerId,
}) => {
  const [finalizeInvoiceMutation, finalizeInvoiceMutationResult] =
    useFinalizeInvoicesMutation();
  const pushMessage = useSnackbar();

  const finalizeInvoice = async () => {
    await finalizeInvoiceMutation({
      variables: {
        invoice_id: invoiceId,
        customer_id: customerId,
      },
      update(cache) {
        cache.evict({ fieldName: "mri_invoice" });
        cache.evict({ fieldName: "mri_invoices" });
        cache.evict({ fieldName: "Customer" });
        cache.evict({ fieldName: "Customer_by_pk" });
        cache.gc();
      },
    });
  };

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            loading={finalizeInvoiceMutationResult.loading}
            onClick={async () => {
              try {
                await finalizeInvoice();
                pushMessage({
                  type: "success",
                  content: "Successfully finalized invoice",
                });
              } catch (e: any) {
                pushMessage({
                  content: `Failed to finalize invoice. Please make sure older invoices for this customer are finalized first, including those on other plans/contracts.`,
                  type: "error",
                });
              }
              onClose();
            }}
            text="Finalize invoice"
            theme="primary"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Finalize invoice"
    >
      <Body level={2}>
        This action will immediately queue this invoice for finalization and, if
        applicable, the invoice will be posted to a billing provider.
      </Body>
    </Popup>
  );
};

export default FinalizeInvoiceModal;
