import React, { useState } from "react";

import { FullScreenPage } from "components/PageContainer";
import { PlanActiveAndFutureCustomersQueryResult } from "../../data/queries.graphql";
import { inputSafeStr } from "./input";

type ActiveAndFutureCustomers = PlanActiveAndFutureCustomersQueryResult["data"];

type EditInterstitialProps = {
  onClose: () => void;
  onContinue: () => void;
  planName: string;
  activeAndFutureCustomers: ActiveAndFutureCustomers;
};

import styles from "./index.module.less";
import { Input, Body, Headline, Icon } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { WizardFooter } from "components/Wizard";
import pluralize from "pluralize";
import { MetronomeLogo } from "tenaissance/components/MetronomeLogo";
import { InternalLink } from "components/Typography";

const getConfirmationText = (
  activeAndFutureCustomers: ActiveAndFutureCustomers,
) => {
  const numCurrent =
    activeAndFutureCustomers?.Plan_by_pk?.active_customer_count ?? 0;
  const numFuture =
    activeAndFutureCustomers?.Plan_by_pk?.future_customer_count ?? 0;
  const numMarketplace =
    activeAndFutureCustomers?.current_marketplace_customers?.aggregate?.count ??
    0;
  const numCustomPricing =
    activeAndFutureCustomers?.custom_pricing_on_plan?.aggregate?.count ?? 0;
  const singleCustomer = activeAndFutureCustomers?.CustomerPlan[0];
  const singleCustomerName = singleCustomer
    ? inputSafeStr(singleCustomer.Customer.name)
    : undefined;

  const customPricingWarning =
    numCustomPricing > 0 ? (
      <>
        {" "}
        <b>
          {pluralize("customer", numCustomPricing, true)} with price adjustments
        </b>{" "}
        {pluralize("is", numCustomPricing)} on this plan. Customers with price
        overrides will not have updated pricing.
      </>
    ) : (
      ""
    );

  if (numCurrent + numFuture === 0) {
    return null;
  } else if (numCurrent === 1 && numMarketplace === 1 && singleCustomer) {
    return {
      body: (
        <>
          Currently, <b>{singleCustomerName}</b> is on this plan. This customer
          is billed through the AWS Marketplace, which means they're billed each
          hour and have already been billed for some usage this billing period.
          If you edit this plan and this customer's invoice total decreases, you
          may need to issue a manual refund for the excess already billed.
          Contact a Metronome representative if you have questions.
          {customPricingWarning}
        </>
      ),
      value: singleCustomerName,
    };
  } else if (numCurrent === 1 && numFuture === 0 && singleCustomer) {
    return {
      body: (
        <>
          Currently, <b>{singleCustomerName}</b> is on this plan. Any edits will
          be reflected in their current billing cycle.
          {customPricingWarning}
        </>
      ),
      value: singleCustomerName,
    };
  } else if (numCurrent === 0 && numFuture === 1 && singleCustomer) {
    return {
      body: (
        <>
          Currently, <b>{singleCustomerName}</b> is scheduled to start on this
          plan. Any edits will be reflected in their first billing cycle.
          {customPricingWarning}
        </>
      ),
      value: singleCustomerName,
    };
  } else {
    const currentCustomerString = pluralize(
      "current customer",
      numCurrent,
      true,
    );
    const futureCustomerString = pluralize("future customer", numFuture, true);
    const marketplaceWarning =
      numMarketplace > 0 ? (
        <>
          <b>{pluralize("current customer", numMarketplace, true)}</b>{" "}
          {pluralize("is", numMarketplace)} being billed through the AWS
          Marketplace, which means they're billed each hour and have already
          been billed for some usage this billing period. If you edit this plan
          and such a customer's invoice total decreases, you may need to issue a
          manual refund for the excess already billed. Contact a Metronome
          representative if you have questions.
        </>
      ) : (
        ""
      );

    return {
      body:
        numFuture === 0 ? (
          <>
            There are <b>{currentCustomerString}</b> on this plan. Any edits
            will be reflected in their current billing cycle.
            {marketplaceWarning}
            {customPricingWarning}
          </>
        ) : numCurrent === 0 ? (
          <>
            There are <b>{futureCustomerString}</b> scheduled to start on this
            plan. Any edits will be reflected in their first billing cycle.
            {marketplaceWarning}
            {customPricingWarning}
          </>
        ) : (
          <>
            There are{" "}
            <b>
              {currentCustomerString} and {futureCustomerString}
            </b>{" "}
            on this plan. For customers currently on this plan, any edits will
            be reflected in their current billing cycle. {marketplaceWarning}
            {customPricingWarning}
          </>
        ),
      value: pluralize("customer", numCurrent + numFuture, true),
    };
  }
};

export const EditInterstitial: React.FC<EditInterstitialProps> = (props) => {
  const [confirmationInput, setConfirmationInput] = useState("");
  const confirmText = getConfirmationText(props.activeAndFutureCustomers);
  return (
    <FullScreenPage title="New plan">
      <div className={styles.container}>
        <InternalLink routePath="/" className={styles.logo}>
          <MetronomeLogo theme="greyscale" badgeOnly />
        </InternalLink>
        <IconButton
          onClick={props.onClose}
          className={styles.closeButton}
          theme="secondary"
          icon="xClose"
        />
        <div className={styles.icon}>
          <Icon icon="receipt" />
        </div>
        <Headline level={4}>Plan editing</Headline>
        <Body className={styles.bodyText}>
          Edits to a plan will modify the pricing and charges for all associated
          customers on the plan. When finalizing the change, you'll review your
          edits and their customer impact.
        </Body>
        {confirmText && (
          <>
            <hr className={styles.divider} />
            <Body className={styles.bodyText}>
              {confirmText?.body}
              <br />
              <br />
              Are you sure you want to make edits? If so, enter{" "}
              <b>{confirmText.value} </b>
              below:
            </Body>
            <Input
              placeholder={`${confirmText.value}`}
              value={confirmationInput}
              onChange={setConfirmationInput}
            />
          </>
        )}
      </div>
      <WizardFooter
        onClickBack={props.onClose}
        backLabel="Cancel"
        onClickContinue={props.onContinue}
        continueLabel="Continue"
        continueDisabled={
          !!(confirmText && confirmationInput !== confirmText.value)
        }
      />
    </FullScreenPage>
  );
};
