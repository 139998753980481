import React from "react";
import { DraftPlan } from "lib/plans/types";
import { useDraftPlan } from "../../../../context";
import { InputSection, WizardSplitPage } from "components/Wizard";
import { Input, TextArea } from "design-system";

export const isNameAndDescriptionDone = (draftPlan: DraftPlan) => {
  return !!(
    draftPlan.name &&
    draftPlan.name.length &&
    draftPlan.description &&
    draftPlan.description.length
  );
};

export const SetNameAndDescriptionPage: React.FC = () => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  return (
    <WizardSplitPage>
      <InputSection
        header="What's the name of this plan?"
        subtitle="This name only appears in the Metronome UI."
      >
        <Input
          name="Plan name"
          placeholder="Enter the plan name"
          value={draftPlan.name || ""}
          onChange={(v) => setDraftPlan({ ...draftPlan, name: v })}
          error={draftPlan.name === "" ? "You must provide a name" : undefined}
        />
      </InputSection>
      <InputSection
        header="Add a description."
        subtitle="A good description will help you easily differentiate among plans as you scale."
      >
        <TextArea
          minRows={3}
          name="Plan description"
          placeholder="Enter a description of this plan"
          value={draftPlan.description || ""}
          onChange={(v) =>
            setDraftPlan({
              ...draftPlan,
              description: v,
            })
          }
          error={
            draftPlan.description === ""
              ? "You must provide a description"
              : undefined
          }
        />
      </InputSection>
    </WizardSplitPage>
  );
};
