import React from "react";

import { EventDetailsQuery } from "../../queries.graphql";

import styles from "./index.module.less";
import { Subtitle, Icon, IconName } from "design-system";
import classnames from "classnames";
import { renderDateTimeInUTC } from "lib/time";

type Event = EventDetailsQuery["mri_events"][0];

type SummaryProps = {
  event: Event;
};

export const Summary: React.FC<SummaryProps> = ({ event }) => {
  const hasBillableMetrics =
    !!event?.billable_metrics?.length || !!event?.seat_metrics.length;

  let summaryState: "success" | "warning" | "error" = "success";
  let summaryIcon: IconName = "checkmarkCircle";

  const processedAtSummary = event.processedAt
    ? `It was processed on ${renderDateTimeInUTC(
        new Date(event.processedAt),
        false, // render as string
        false, // show UTC label
      )}`
    : "";

  let summaryText = (
    <>
      This event was ingested, but it didn’t match a billable metric or
      customer. {processedAtSummary}
    </>
  );

  if (event.is_duplicate) {
    summaryText = (
      <>
        This event was ingested, but the <code>transaction_id</code> was a
        duplicate of a previously ingested event.
      </>
    );
    summaryState = "error";
    summaryIcon = "warning";
  } else if (event?.customer) {
    if (hasBillableMetrics) {
      summaryText = (
        <>
          This event was ingested and matched a billable metric.{" "}
          {processedAtSummary}
        </>
      );
    } else {
      summaryText = (
        <>
          This event was ingested but didn't match a billable metric.{" "}
          {processedAtSummary}
        </>
      );
      summaryState = "error";
      summaryIcon = "warning";
    }
  } else {
    if (hasBillableMetrics) {
      summaryText = (
        <>
          This event was ingested but didn't match a customer_id.{" "}
          {processedAtSummary}
        </>
      );
      summaryState = "error";
      summaryIcon = "warning";
    } else {
      summaryState = "error";
      summaryIcon = "warning";
    }
  }
  return (
    <div className={styles.summary}>
      <Subtitle level={2} className={styles.title}>
        <Icon
          className={classnames(styles.statusIcon, styles[summaryState])}
          icon={summaryIcon}
        />
        <div>{summaryText}</div>
      </Subtitle>
    </div>
  );
};
