import React, { useState } from "react";

import styles from "./index.module.less";

import {
  UpdateIngestAliasesDocument,
  useGetCustomerSettingsInfoQuery,
} from "./queries.graphql";

import { Statistic } from "components/Statistic";
import { Body, Caption, Headline, Tooltip } from "design-system";
import { Button } from "tenaissance/components/Button";
import { TextSkeleton } from "components/Skeleton";
import { CopyableID } from "components/CopyableID";
import { EditCustomerNameButton } from "pages/Customer/components/EditCustomerNameButton";
import { startOfHour } from "date-fns";
import ArchiveCustomerModal from "lib/customers/components/ArchiveCustomerModal";
import { GatedButton } from "components/GatedButton";
import { useNavigate } from "lib/useNavigate";
import { ArchiveCustomerDocument } from "lib/customers/components/ArchiveCustomerModal/queries.graphql";
import { twMerge } from "../../../../../../design-system/twMerge";
import {
  AddIngestAliasModal,
  DeleteIngestAliasButton,
} from "../../../../../../components/IngestAliases";
import { useUIMode } from "lib/useUIMode";

type IdentifierSectionProps = {
  customerId: string;
};

export const IdentifierSection: React.FC<IdentifierSectionProps> = ({
  customerId,
}) => {
  const [ingestAliasModalOpen, setIngestAliasModalOpen] = useState(false);
  const { newUIEnabled } = useUIMode();
  const navigate = useNavigate();
  const [customerArchiveModalOpen, setCustomerArchiveModalOpen] =
    useState(false);

  const { data, loading } = useGetCustomerSettingsInfoQuery({
    variables: {
      customer_id: customerId,
      date: startOfHour(new Date()).toISOString(),
    },
  });

  let ingestAliasBlock: React.ReactElement;
  if (loading) {
    ingestAliasBlock = <TextSkeleton />;
  } else if (!data?.Customer?.CustomerIngestAliases.length) {
    ingestAliasBlock = (
      <Caption level={2} className={styles.noIngestAliases}>
        No ingest aliases
      </Caption>
    );
  } else {
    ingestAliasBlock = (
      <ul>
        {data.Customer.CustomerIngestAliases.map(({ ingest_alias }, _, all) => (
          <li
            key={ingest_alias}
            className={twMerge(styles.ingestAlias, "flex items-center")}
          >
            {ingest_alias}
            <DeleteIngestAliasButton
              customerId={customerId}
              ingestAlias={ingest_alias}
              currentAliases={all.map(({ ingest_alias }) => ingest_alias)}
            />
          </li>
        ))}
      </ul>
    );
  }

  const canArchive =
    (data?.Customer?.active_customer_plans.length || 0) === 0 &&
    data?.Customer?.archived_at === null;
  const archiveDisabledReason =
    data?.Customer?.archived_at !== null
      ? "Customer already archived"
      : "Customers with plans cannot be archived";
  return (
    <>
      {ingestAliasModalOpen && (
        <AddIngestAliasModal
          customerName={data?.Customer?.name ?? customerId}
          currentAliases={
            data?.Customer?.CustomerIngestAliases.map(
              ({ ingest_alias }) => ingest_alias,
            ) ?? []
          }
          onClose={() => setIngestAliasModalOpen(false)}
          customerId={customerId}
        />
      )}
      {customerArchiveModalOpen && data?.Customer && (
        <ArchiveCustomerModal
          customerId={data.Customer.id}
          customerName={data.Customer.name}
          onClose={() => {
            setCustomerArchiveModalOpen(false);
          }}
        />
      )}
      <div className="flex flex-row">
        <div className="grow">
          <Headline level={5}>Customer name and IDs</Headline>
          <Body className={styles.subtitle}>
            Customer name and IDs associated to the customer.
          </Body>
        </div>
        <div>
          <Tooltip content={archiveDisabledReason} disabled={canArchive}>
            <GatedButton
              className="mr-12"
              disabled={!canArchive}
              onClick={() => {
                setCustomerArchiveModalOpen(true);
              }}
              doc={ArchiveCustomerDocument}
              text="Archive Customer"
              theme="linkDestructive"
            />
          </Tooltip>
        </div>
      </div>
      <div className={styles.topRow}>
        <Statistic caption="Customer name">
          {data?.Customer?.name === undefined ? (
            <TextSkeleton />
          ) : (
            <div className=" flex items-center	">
              <Body>{data.Customer.name}</Body>
              <EditCustomerNameButton
                customerId={customerId}
                currentName={data.Customer.name}
              />
            </div>
          )}
        </Statistic>
        <Statistic caption="Customer ID">
          <div className={styles.id}>
            <CopyableID id={customerId} label="customer ID" />
          </div>
        </Statistic>
        <Statistic caption="Custom fields">
          <Button
            onClick={() =>
              navigate(
                `${newUIEnabled ? "/connections" : ""}/custom-fields/customer/${customerId}`,
              )
            }
            text="Manage"
            theme="secondary"
          />
        </Statistic>
      </div>
      <div className="flex items-center">
        <Caption>Ingest aliases</Caption>
        <GatedButton
          onClick={() => setIngestAliasModalOpen(true)}
          doc={UpdateIngestAliasesDocument}
          text="Add ingest alias"
          theme="linkGray"
          leadingIcon="plus"
          size="sm"
        />
      </div>
      {ingestAliasBlock}
    </>
  );
};
