import { SOXReport } from "../reportConfig";
import { CustomReportType } from "types/generated-graphql/__types__";

const soxComplianceInvoiceReport: SOXReport = {
  name: "SOX compliance invoice report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("SOX_COMPLIANCE_INVOICE"),
  customReportType: CustomReportType.Sox,
  restrictEndDateToToday: false,
};

const soxAuditLogReport: SOXReport = {
  name: "SOX audit log report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient?.variation("available-report-types", []).includes("SOX_AUDIT_LOG"),
  customReportType: CustomReportType.SoxAuditReport,
  restrictEndDateToToday: true,
};

const usageBasedBillingFinalizationReport: SOXReport = {
  name: "UBB invoice extract report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("UBB_INVOICE_FINALIZATION"),
  customReportType: CustomReportType.UbbInvoiceFinalization,
  restrictEndDateToToday: true,
  canSendToWorkato: false,
};

export {
  soxComplianceInvoiceReport,
  soxAuditLogReport,
  usageBasedBillingFinalizationReport,
};
