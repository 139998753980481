import React from "react";
import { Badge } from "tenaissance/components/Badge";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useSearchParam } from "lib/routes/useSearchParam";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useDebounce } from "lib/debounce";
import { dayjs } from "lib/dayjs";
import { startOfHour } from "date-fns";
import { Table } from "tenaissance/components/Table";
import { EmptyState } from "tenaissance/components/EmptyState";
import { AppShell } from "components/PageContainer";
import { Filter, OptionType } from "tenaissance/components/Filter";
import { TextInput } from "tenaissance/components/Input";
import { InsertCustomerDocument } from "pages/NewCustomer/newCustomer.graphql";
import { useUIMode } from "lib/useUIMode";
import { useNow } from "lib/date";
import { useSearchCustomersQuery, useListCustomerQuery } from "./data.graphql";
import { CustomerStatus } from "./components/CustomerStatus";
import {
  ArchivedFilter,
  Order_By,
  type Customer_Bool_Exp,
} from "types/generated-graphql/__types__";
import { Customer } from "../lib/Customer";
import { useFeatureFlag } from "lib/launchdarkly";
import { GatedButton } from "components/GatedButton";
import { renderDate, renderDateTimeInUTC } from "lib/time";
import { CustomerLifetimeBillingsCell } from "./components/CustomerLifetimeBillings";
import { Tooltip } from "tenaissance/components/Tooltip";
import { Icon } from "tenaissance/components/Icon";

const NUM_ROWS = 15;

const PROVISION_STATUS_FILTERS: OptionType[] = [
  {
    label: "Active Customers",
    type: "single",
    value: "notArchived",
  },
  {
    label: "Archived Customers",
    type: "single",
    value: "archived",
  },
  {
    label: "All Customers",
    type: "single",
    value: "all",
  },
];

const PLAN_STATUS_FILTERS: OptionType[] = [
  {
    label: "All Plans Status",
    type: "single",
    value: "all_plan_status",
  },
  {
    label: "No Plan",
    type: "single",
    value: "no_plan",
  },
  {
    label: "Expired Plan",
    type: "single",
    value: "expired_plan",
  },
  {
    label: "Plan Expires in 30 Days",
    type: "single",
    value: "plan_expires_30d",
  },
];

const MAX_PAGE_COUNT = 1_000_000;

interface State {
  searchQuery: string;
  pageIndex: number;
  pageSize: number;
  provisionStatusFilter: OptionType;
  planStatusFilter: OptionType;
}

export const CustomersList: React.FC = () => {
  const now = useNow();
  const { mode } = useUIMode();
  const { environmentType } = useEnvironment();

  // sync search query with url
  const [searchQueryInUrl, setSearchQuery] = useSearchParam("s");
  const [provisionStatusInUrl, setProvisionStatus] =
    useSearchParam("provision_status");
  const [planStatusInUrl, setPlanStatus] = useSearchParam("plan_status");

  // make initial state on mount, DON'T change with url updates
  const initialState = React.useMemo(
    (): State => ({
      searchQuery: searchQueryInUrl,
      provisionStatusFilter: PROVISION_STATUS_FILTERS[0],
      planStatusFilter: PLAN_STATUS_FILTERS[0],
      pageIndex: 0,
      pageSize: NUM_ROWS,
    }),
    [],
  );
  const [state, updateState] = React.useState<State>(initialState);

  // sync external changes of the URL with the state
  React.useEffect(() => {
    updateState((s): State => {
      const provisionFilter =
        PROVISION_STATUS_FILTERS.find(
          (f) => f.value === provisionStatusInUrl,
        ) || PROVISION_STATUS_FILTERS[0];

      const planFilter =
        PLAN_STATUS_FILTERS.find((f) => f.value === planStatusInUrl) ||
        PLAN_STATUS_FILTERS[0];

      if (
        s.searchQuery === searchQueryInUrl &&
        s.provisionStatusFilter.value === provisionFilter.value &&
        s.planStatusFilter.value === planFilter.value
      ) {
        return s;
      }

      return {
        ...s,
        searchQuery: searchQueryInUrl,
        provisionStatusFilter: provisionFilter,
        planStatusFilter: planFilter,
        pageIndex: 0,
      };
    });
  }, [searchQueryInUrl, provisionStatusInUrl, planStatusInUrl]);

  // debounce search query so we don't send too many queries
  const debouncedSearchQuery = useDebounce(state.searchQuery, 500);

  // when the debounced query updates, sync it to the URL
  React.useEffect(() => {
    if (debouncedSearchQuery !== searchQueryInUrl) {
      setSearchQuery(debouncedSearchQuery);
    }
  }, [
    debouncedSearchQuery,
    // disabled because we only want to invalidate the effect when the debounced query changes
    // if this was enabled then it would always store the search query in the URL when it is updated
    // by external sources, like the Customer link in the navigation.
    /*, searchQueryInUrl*/
  ]);

  const disableCustomerPage = useFeatureFlag("disabled-customer-page", false);

  const current_time = startOfHour(new Date()).toISOString();
  const listFilterAndOrder = React.useMemo(() => {
    const filters: Customer_Bool_Exp[] = [];

    // Add provision filter
    if (state.provisionStatusFilter.value === "notArchived") {
      filters.push({
        _or: [
          {
            archived_at: {
              _is_null: true,
            },
          },
          {
            archived_at: {
              _gt: now.toISOString(),
            },
          },
        ],
      });
    } else if (state.provisionStatusFilter.value === "archived") {
      filters.push({
        archived_at: {
          _lte: now.toISOString(),
        },
      });
    }

    // Add plan filter only if it's not "all"
    if (
      state.planStatusFilter &&
      state.planStatusFilter.value !== "all_plan_status"
    ) {
      if (state.planStatusFilter.value === "no_plan") {
        filters.push({
          _and: [
            {
              _not: {
                CustomerPlans: {
                  start_date: {
                    _lte: current_time,
                  },
                },
              },
            },
          ],
        });
      } else if (state.planStatusFilter.value === "expired_plan") {
        filters.push({
          _and: [
            {
              _not: {
                CustomerPlans: {
                  _and: [
                    {
                      start_date: {
                        _lte: current_time,
                      },
                    },
                    {
                      _or: [
                        {
                          cancellation_date: { _is_null: true },
                        },
                        {
                          cancellation_date: { _gte: current_time },
                        },
                      ],
                    },
                  ],
                },
              },
            },
            {
              CustomerPlans: {
                start_date: {
                  _lte: current_time,
                },
              },
            },
          ],
        });
      } else if (state.planStatusFilter.value === "plan_expires_30d") {
        const latestAcceptedCancellationDate = dayjs
          .utc(current_time)
          .add(30, "days")
          .toISOString();

        filters.push({
          _and: [
            {
              CustomerPlans: {
                _and: [
                  {
                    start_date: {
                      _lte: current_time,
                    },
                  },
                  {
                    cancellation_date: {
                      _lte: latestAcceptedCancellationDate,
                    },
                  },
                  {
                    cancellation_date: {
                      _gte: current_time,
                    },
                  },
                  {
                    cancellation_date: {
                      _is_null: false,
                    },
                  },
                ],
              },
            },
          ],
        });
      }
    }

    return {
      order_by: [{ name: Order_By.AscNullsLast }],
      filter: {
        _and: filters,
      },
    };
  }, [state.provisionStatusFilter, state.planStatusFilter]);

  const listCustomerResponse = useListCustomerQuery({
    skip: !!debouncedSearchQuery || disableCustomerPage,
    variables: {
      environment_type: environmentType,
      limit: state.pageSize,
      offset: state.pageIndex * state.pageSize,
      ...listFilterAndOrder,
    },
  });

  const archivedFilter = React.useMemo(() => {
    if (state.provisionStatusFilter.value === "archived") {
      return ArchivedFilter.Archived;
    }
    if (state.provisionStatusFilter.value === "notArchived") {
      return ArchivedFilter.NotArchived;
    }

    return ArchivedFilter.Both;
  }, [state.provisionStatusFilter]);

  const searchCustomersResponse = useSearchCustomersQuery({
    skip: !debouncedSearchQuery,
    variables: {
      environment_type: environmentType,
      query: debouncedSearchQuery,
      archived: archivedFilter,
    },
  });

  const { req, totalCount, rows } = debouncedSearchQuery
    ? {
        req: searchCustomersResponse,
        totalCount: searchCustomersResponse.data?.searchCustomers.length ?? 0,
        rows: (searchCustomersResponse.data?.searchCustomers ?? []).slice(
          state.pageIndex * state.pageSize,
          (state.pageIndex + 1) * state.pageSize,
        ),
      }
    : {
        req: listCustomerResponse,
        totalCount:
          state.provisionStatusFilter.value === "archived"
            ? listCustomerResponse.data?.totalArchivedCustomers[0]
                ?.customer_count.count ?? 0
            : listCustomerResponse.data?.totalNonArchivedCustomers[0]
                ?.customer_count.count ?? 0,
        rows: listCustomerResponse.data?.Customer ?? [],
      };

  const pageCount = React.useMemo(() => {
    const actualCount = Math.ceil(totalCount / state.pageSize);
    return actualCount > MAX_PAGE_COUNT ? MAX_PAGE_COUNT : actualCount;
  }, [totalCount, state.pageSize]);

  const actions = (
    <div className="flex flex-row items-center gap-12">
      <TextInput
        value={state.searchQuery}
        onChange={(meta: { value: string }) => {
          updateState(
            (s): State => ({
              ...s,
              searchQuery: meta.value,
              pageIndex: 0,
            }),
          );
        }}
        type="search"
        placeholder="Search"
        className="w-[300px]"
      />
      <Filter
        key={state.provisionStatusFilter.value}
        headerContent="Provision status"
        selectedFilters={[state.provisionStatusFilter]}
        options={PROVISION_STATUS_FILTERS}
        onChange={(newFilters) => {
          if (newFilters.length > 0) {
            updateState((s) => ({
              ...s,
              provisionStatusFilter: newFilters[0],
            }));
            setProvisionStatus(newFilters[0].value);
          }
        }}
      />
      {(mode === "contracts-and-plans" || mode === "plans-only") && (
        <Filter
          key={state.planStatusFilter.value}
          headerContent="Plan status"
          selectedFilters={[state.planStatusFilter]}
          options={PLAN_STATUS_FILTERS}
          onChange={(newFilters) => {
            if (newFilters.length > 0) {
              updateState((s) => ({
                ...s,
                planStatusFilter: newFilters[0],
              }));
              setPlanStatus(newFilters[0].value);
            }
          }}
        />
      )}
      <GatedButton
        doc={InsertCustomerDocument}
        className="h-[34px]"
        text="Add"
        theme="primary"
        leadingIcon="plus"
        linkTo="/customers/new"
      />
    </div>
  );

  const displayPageContent = () => {
    if (req.error) {
      return (
        <ErrorEmptyState
          title="We ran into an issue loading your customers"
          error={req.error}
        />
      );
    }

    return disableCustomerPage && !debouncedSearchQuery ? (
      <EmptyState
        icon="searchSm"
        mainText="We'll be back soon with a refreshed customer page"
        supportingText="Use the search at the top of the page to find customers."
      />
    ) : (
      <>
        <Table
          title="Directory"
          loading={req.loading}
          data={rows}
          rowRoutePath={(row) => `/customers/${row.id}`}
          columns={[
            {
              id: "customer",
              header: "Name",
              cell: (props) => props.getValue(),
              accessorFn: (row) => (
                <span
                  className={
                    Customer.isInactive(row, now) ? "text-gray-400" : ""
                  }
                >
                  {Customer.isArchived(row, now) && (
                    <Badge label="Archived" theme="warning" />
                  )}
                  {" " + row.name}
                </span>
              ),
              enableSorting: false,
            },
            {
              id: "created_at",
              header: "Date created (UTC)",
              cell: (props) => props.getValue(),
              accessorFn: (row) => (
                <span
                  title={renderDateTimeInUTC(new Date(row.created_at), false)}
                >
                  {renderDate(new Date(row.created_at), {
                    isUtc: true,
                    excludeUtcLabel: true,
                  })}
                </span>
              ),
              enableSorting: false,
            },
            {
              id: "lifetime-billings",
              header: () => <LifetimeBillingsHeader />,
              cell: (props) => props.getValue(),
              accessorFn: (row) => (
                <CustomerLifetimeBillingsCell customerId={row.id} />
              ),
              align: "right",
              enableSorting: false,
            },
            {
              id: "status",
              header: "Provision status",
              cell: (props) => props.getValue(),
              accessorFn: (row) => <CustomerStatus customer={row} />,
              enableSorting: false,
            },
          ]}
          paginationOptions={{
            type: "prevNext",
            pageIndexNavigationOptions: {
              currentPage: state.pageIndex,
              tablePageCount: pageCount,
              setPageIndex: (newPageIndex) => {
                updateState(
                  (s): State => ({
                    ...s,
                    pageIndex: newPageIndex,
                  }),
                );
              },
            },
            paginationButtons: [
              {
                page: "prev",
                onClick: () => {
                  updateState(
                    (s): State => ({
                      ...s,
                      pageIndex: s.pageIndex - 1,
                    }),
                  );
                },
                disabled: state.pageIndex === 0,
              },
              {
                page: "next",
                disabled: pageCount === 0 || state.pageIndex === pageCount - 1,
                onClick: () => {
                  updateState(
                    (s): State => ({
                      ...s,
                      pageIndex: s.pageIndex + 1,
                    }),
                  );
                },
              },
            ],
          }}
          topBarActions={[actions]}
          emptyState={
            <EmptyState
              icon="briefcase01"
              mainText={
                !debouncedSearchQuery && !state.planStatusFilter
                  ? "You haven't added any customers yet."
                  : "No matching customers found."
              }
              supportingText={
                !debouncedSearchQuery && !state.planStatusFilter
                  ? "Start by adding your first customer to begin tracking customer information in Metronome."
                  : `Try a different ${debouncedSearchQuery.length ? "search term" : "filter"}.`
              }
            />
          }
        />
      </>
    );
  };
  return <AppShell title="Customers">{displayPageContent()}</AppShell>;
};

const LifetimeBillingsHeader: React.FC<{}> = () => (
  <Tooltip label="Lifetime billings are based only on finalized invoices and represent the total for the fiat credit type with the highest cumulative value.">
    <div className="flex flex-row items-center gap-4">
      Lifetime billings
      <Icon icon="helpCircle" size={14} />
    </div>
  </Tooltip>
);
