import React from "react";

import { ChooseProductInfo, ProductInfo } from "./ChooseProductInfo";
import { DefineCharge } from "./DefineCharge";
import { CreditType } from "types/credit-types";
import { useSnackbar } from "components/Snackbar";
import { useAddOneTimeChargeMutation } from "./addOneTimeCharge.graphql";
import { getUserFacingErrorMessage } from "lib/errors/errorHandling";

export const COMMON_POPUP_PROPS = {
  key: "add-one-time-charge-to-invoice",
  title: "Add one-time charge to invoice",
  isOpen: true,
};

interface Props {
  customerId: string;
  customerPlanId: string;
  invoiceStartDate: Date;
  creditType: CreditType;
  onClose: () => void;
}

export const AddOneTimeChargeModal: React.FC<Props> = ({
  customerId,
  customerPlanId,
  invoiceStartDate,
  creditType,
  onClose,
}) => {
  const pushMessage = useSnackbar();
  const [step, setStep] = React.useState(1);
  const [productInfo, setProductInfo] = React.useState<ProductInfo>();

  const [addOneTimeCharge, { loading: isSubmitting }] =
    useAddOneTimeChargeMutation({
      onCompleted() {
        pushMessage({
          content: "One-time charge added",
          type: "success",
        });
        onClose();
      },
      onError(error) {
        const msg = getUserFacingErrorMessage(error);
        pushMessage({
          content: `Failed to create one-time charge: ${msg}`,
          type: "error",
        });
      },
      update(cache) {
        cache.evict({ id: `Customer:${customerId}` });
      },
    });

  return !productInfo || step === 1 ? (
    <ChooseProductInfo
      onCancel={onClose}
      previousInfo={productInfo}
      onNext={(info) => {
        setProductInfo(info);
        setStep(2);
      }}
    />
  ) : (
    <DefineCharge
      creditType={creditType}
      {...productInfo}
      onCancel={onClose}
      goBack={() => {
        setStep(1);
      }}
      onSubmit={(results) => {
        void addOneTimeCharge({
          variables: {
            customer_plan_id: customerPlanId,
            date: invoiceStartDate.toISOString(),
            product_pricing_factor_id: productInfo.pricingFactor.id,
            quantity: results.quantity.toString(),
            price: results.price.toString(),
            description: results.description,
          },
        });
      }}
      isSubmitting={isSubmitting}
    />
  );
};
