/* a393ad679e607c2eec447537afc3c945d211f023
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveCustomerMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type ArchiveCustomerMutation = { __typename?: 'Mutation', archive_customer: { __typename?: 'Customer', id: string, archived_at: string | null } };


export const ArchiveCustomerDocument = gql`
    mutation ArchiveCustomer($customer_id: uuid!) {
  archive_customer(customer_id: $customer_id) {
    id
    archived_at
    __environment_type: environment_type
  }
}
    `;
export type ArchiveCustomerMutationFn = Apollo.MutationFunction<ArchiveCustomerMutation, ArchiveCustomerMutationVariables>;

/**
 * __useArchiveCustomerMutation__
 *
 * To run a mutation, you first call `useArchiveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCustomerMutation, { data, loading, error }] = useArchiveCustomerMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useArchiveCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveCustomerMutation, ArchiveCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveCustomerMutation, ArchiveCustomerMutationVariables>(ArchiveCustomerDocument, options);
      }
export type ArchiveCustomerMutationHookResult = ReturnType<typeof useArchiveCustomerMutation>;
export type ArchiveCustomerMutationResult = Apollo.MutationResult<ArchiveCustomerMutation>;
export type ArchiveCustomerMutationOptions = Apollo.BaseMutationOptions<ArchiveCustomerMutation, ArchiveCustomerMutationVariables>;