import React, { useMemo, useState } from "react";
import {
  Input,
  Body,
  Caption,
  Subtitle,
  HelpCircleTooltip,
  Icon,
} from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import Fuse from "fuse.js";
import {
  BillableMetric,
  SeatMetric,
  SelectedMetrics,
  SelectedSeats,
} from "../../types";
import { useFeatureFlag } from "lib/launchdarkly";
import { ToggleButtons } from "components/ToggleButtons";
import { MetricType } from "lib/billableMetrics/types";

interface SelectBillableMetricsSectionProps {
  sectionTitle: string;
  allBillableMetrics: BillableMetric[];
  selectedMetrics: SelectedMetrics;
  allSeats: SeatMetric[];
  selectedSeats: SelectedSeats;
  selectAllSeats: (seats: SeatMetric[]) => void;
  deselectAllSeats: (seats: SeatMetric[]) => void;
  addSeat: (metric: SeatMetric) => void;
  removeSeat: (id: string) => void;
  selectAllMetrics: (billableMetrics: BillableMetric[]) => void;
  deselectAllMetrics: (billableMetrics: BillableMetric[]) => void;
  addMetric: (metric: BillableMetric) => void;
  removeMetric: (id: string) => void;
  existingProductInfo?: {
    group_key: string | null;
    metrics: BillableMetric[];
    seats: SeatMetric[];
  };
}

export const SelectBillableMetricsSection: React.FC<
  SelectBillableMetricsSectionProps
> = (props) => {
  const [metricType, setMetricType] = useState<MetricType>("billable");
  const seatsEnabled = useFeatureFlag("seats", false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const billableMetricsFuse = useMemo(() => {
    return new Fuse(props.allBillableMetrics, {
      keys: ["name", { name: "id", weight: 0.1 }],
      threshold: 0.4,
    });
  }, [props.allBillableMetrics]);
  const seatsFuse = useMemo(() => {
    return new Fuse(props.allSeats, {
      keys: ["name", { name: "id", weight: 0.1 }],
      threshold: 0.4,
    });
  }, [props.allSeats]);

  const billableMetrics = searchQuery
    ? billableMetricsFuse.search(searchQuery).map((match) => match.item)
    : props.allBillableMetrics;

  const seatMetrics = searchQuery
    ? seatsFuse.search(searchQuery).map((match) => match.item)
    : props.allSeats;

  const hasSelectedBillableMetrics =
    Object.keys(props.selectedMetrics).length > 0 ||
    (props.existingProductInfo?.metrics.length ?? 0) > 0;
  const hasSelectedSeats =
    Object.keys(props.selectedSeats).length > 0 ||
    (props.existingProductInfo?.seats.length ?? 0) > 0;

  const allSelected =
    metricType === "billable"
      ? billableMetrics.every((bm) => !!props.selectedMetrics[bm.id])
      : seatMetrics.every((seat) => !!props.selectedSeats[seat.id]);
  return (
    <div>
      <div className="border-gray-100 mb-4 flex w-full flex-row items-center gap-4 border-b py-8">
        <Subtitle level={1}>{props.sectionTitle}</Subtitle>
        <HelpCircleTooltip content="Usage-based charges are based on billable metrics. Pricing, tiering, and terms are applied when adding products to a plan." />
      </div>
      <Body level={2}>
        {seatsEnabled
          ? "Create usage-based charges from different metering types (billable metrics and seats). Pricing and terms are applied in plans."
          : "Create usage-based charges from billable metrics."}
      </Body>
      {seatsEnabled && (
        <ToggleButtons
          className="!mb-8"
          value={metricType}
          buttonProps={[
            {
              useGreyBackground: true,
              label: "Standard metrics",
              value: "billable-metrics",
              selected: metricType === "billable",
              onChange: (_) => {
                setMetricType("billable");
              },
            },
            {
              useGreyBackground: true,
              label: "Seat metrics",
              value: "seats",
              selected: metricType === "seat",
              onChange: (_) => {
                setMetricType("seat");
              },
            },
          ]}
        />
      )}
      <div className="row flex gap-24">
        <div className="border-gray-100 w-[50%] rounded-medium border bg-gray-50 p-12">
          <div className="mb-8 flex flex-row items-center">
            <Input
              type="search"
              leftIcon="search"
              placeholder="Search"
              onChange={setSearchQuery}
              className="mr-4 flex-grow"
            />
            {props.existingProductInfo ? null : allSelected ? (
              <IconButton
                onClick={() => {
                  if (metricType === "billable") {
                    props.deselectAllMetrics(billableMetrics);
                  } else {
                    props.deselectAllSeats(seatMetrics);
                  }
                }}
                theme="secondary"
                icon="checkCircle"
              />
            ) : (
              <IconButton
                onClick={() => {
                  if (metricType === "billable") {
                    props.selectAllMetrics(billableMetrics);
                  } else {
                    props.selectAllSeats(seatMetrics);
                  }
                }}
                theme="secondary"
                icon="checkCircle"
              />
            )}
          </div>
          <div className="flex h-[350px] flex-col gap-4 overflow-auto">
            {metricType === "billable"
              ? billableMetrics
                  .filter(
                    (metric) =>
                      !props.existingProductInfo?.group_key ||
                      ((metric.group_keys ?? []) as string[]).includes(
                        props.existingProductInfo.group_key,
                      ),
                  )
                  .map((metric, i) => {
                    const metricOnExistingProduct =
                      props.existingProductInfo?.metrics.some(
                        (m) => m.id === metric.id,
                      );
                    const selected =
                      !!props.selectedMetrics[metric.id] ||
                      metricOnExistingProduct;

                    return (
                      <SelectableMetricCard
                        selected={selected}
                        disabled={!!metricOnExistingProduct}
                        metricName={metric.name}
                        onClick={() => {
                          if (selected) {
                            props.removeMetric(metric.id);
                          } else {
                            props.addMetric(metric);
                          }
                        }}
                      />
                    );
                  })
              : seatMetrics.map((seat) => {
                  const metricOnExistingProduct =
                    props.existingProductInfo?.seats.some(
                      (m) => m.id === seat.id,
                    );
                  const selected =
                    !!props.selectedSeats[seat.id] || metricOnExistingProduct;

                  return (
                    <SelectableMetricCard
                      selected={selected}
                      disabled={!!metricOnExistingProduct}
                      metricName={seat.name}
                      onClick={() => {
                        if (selected) {
                          props.removeSeat(seat.id);
                        } else {
                          props.addSeat(seat);
                        }
                      }}
                    />
                  );
                })}
          </div>
        </div>
        <div className="w-[50%]">
          <div className="row border-gray-100 mb-8 flex items-center gap-4 border-b pb-8">
            <Icon icon="pricetags" className="text-gray-400" />
            <Subtitle level={4} className="text-gray-500">
              Selected usage-based charges
            </Subtitle>
          </div>
          <div className="h-[380px] overflow-auto">
            {!hasSelectedBillableMetrics && !hasSelectedSeats ? (
              <Caption level={2} className="pt-12 text-center text-gray-400">
                {seatsEnabled
                  ? "Start selecting billable metrics and seats"
                  : "Start selecting billable metrics"}
              </Caption>
            ) : (
              <div className="flex flex-col gap-4 overflow-auto">
                {seatsEnabled && hasSelectedBillableMetrics && (
                  <Subtitle level={4} className="text-gray-700">
                    Billable metrics
                  </Subtitle>
                )}
                {props.existingProductInfo?.metrics.map((m) => (
                  <SelectedMetricCard
                    key={m.id}
                    metricName={m.name}
                    deletable={false}
                    onDeleteClick={() => {}}
                  />
                ))}
                {Object.values(props.selectedMetrics).map((m) => (
                  <SelectedMetricCard
                    key={m.metricId}
                    metricName={m.name}
                    deletable={true}
                    onDeleteClick={() => props.removeMetric(m.metricId)}
                  />
                ))}
                {seatsEnabled && hasSelectedSeats && (
                  <Subtitle level={4} className="text-gray-700 mt-8">
                    Seats
                  </Subtitle>
                )}
                {props.existingProductInfo?.seats.map((m) => (
                  <SelectedMetricCard
                    key={m.id}
                    metricName={m.name}
                    deletable={false}
                    onDeleteClick={() => {}}
                  />
                ))}
                {Object.values(props.selectedSeats).map((m) => (
                  <SelectedMetricCard
                    key={m.metricId}
                    metricName={m.name}
                    deletable={true}
                    onDeleteClick={() => props.removeSeat(m.metricId)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface SelectableMetricCardProps {
  metricName: string;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
}

const SelectableMetricCard: React.FC<SelectableMetricCardProps> = ({
  metricName,
  selected,
  disabled,
  onClick,
}) => (
  <div
    className={[
      "row flex w-full items-center justify-between rounded-medium border border-deprecated-primary-100 hover:bg-deprecated-primary-50",
      selected ? "bg-deprecated-primary-50" : "bg-white",
      disabled ? "bg-gray-50" : "cursor-pointer",
    ].join(" ")}
    onClick={onClick}
  >
    <Body level={2} className="!m-8 text-deprecated-primary-600">
      {metricName}
    </Body>
    <Icon
      icon="checkmarkCircle"
      className={[
        "!m-8 hover:text-deprecated-primary-600",
        selected ? "text-deprecated-primary-600" : "text-gray-400",
      ].join(" ")}
    />
  </div>
);

interface SelectedMetricCardProps {
  metricName: string;
  deletable: boolean;
  onDeleteClick: () => void;
}

const SelectedMetricCard: React.FC<SelectedMetricCardProps> = ({
  metricName,
  deletable,
  onDeleteClick,
}) => (
  <div className="row flex w-full items-center justify-between rounded-medium border border-deprecated-primary-100 bg-deprecated-primary-50">
    <Body level={2} className="!m-8 text-deprecated-primary-600">
      {metricName}
    </Body>
    <IconButton
      disabled={!deletable}
      onClick={onDeleteClick}
      theme="tertiary"
      icon="xCircle"
    />
  </div>
);
