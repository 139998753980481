import Decimal from "decimal.js";
import React, { useMemo, useState } from "react";
import { type Column } from "components/Table";
import { TablePanel } from "components/TablePanel";
import { Input, Tooltip } from "design-system";
import { CellWithSubtitle } from "../CellWithSubtitle";
import { EmptyState } from "tenaissance/components/EmptyState";
import { useRequiredParam } from "lib/routes/params";
import { useOverridesTableData } from "./data";
import { renderDateTimeInUTC } from "lib/time";
import {
  Override,
  OverrideSpecifier,
} from "@metronome-industries/schedule-utils";
import { OverrideChangeRateTableCell } from "components/Table/cellRenderers/OverrideChangeRateTableCell";
import { arrayUniq } from "lib/util";

type OverridesTableWithData = {
  overrides: Override.Description[];
  hidePriorityColumn?: boolean;
};

export function OverridesTableWithData(props: OverridesTableWithData) {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");
  const { data, loading, error } = useOverridesTableData({
    customerId,
    contractId,
  });
  return (
    <OverridesTable
      {...data}
      overrides={props.overrides}
      hidePriorityColumn={props.hidePriorityColumn}
      loading={loading}
      error={error}
    />
  );
}

type OverridesTableProps = {
  overrides: Override.Description[];
  productsById: Map<string, { id: string; name: string }>;
  commitsById: Map<string, { id: string; name: string }>;
  hidePriorityColumn?: boolean;
  error?: Error | undefined;
  loading?: boolean;
};

/**
 * A table that shows contract and commit-specific overrides.
 */
export function OverridesTable({
  overrides,
  productsById,
  commitsById,
  error,
  loading,
  hidePriorityColumn,
}: OverridesTableProps) {
  const [searchQuery, setSearchQuery] = useState<string>();
  const tableColumns: Column<Override.Description>[] = [
    {
      id: "productOrTag",
      header: "Applicable products & tags",
      render: (override) => {
        if (!override.appliesTo || override.appliesTo.length === 0) {
          return (
            <span className="text-error-500">Error: Unknown specifier</span>
          );
        } else if (override.appliesTo.length === 1) {
          const { primaryText, groupValuesText, error } =
            getProductDescriptionFromOverrideSpecifier(
              override.appliesTo[0],
              productsById,
            );
          if (error) {
            return <span className="text-error-500">{primaryText}</span>;
          }
          return (
            <CellWithSubtitle title={primaryText} subtitle={groupValuesText} />
          );
        } else {
          return (
            <CellWithSubtitle
              title={
                <div className="flex flex-wrap items-center gap-8">
                  <Tooltip
                    content={
                      <ul>
                        {override.appliesTo.map((specifier) => {
                          const { primaryText, groupValuesText } =
                            getProductDescriptionFromOverrideSpecifier(
                              specifier,
                              productsById,
                            );
                          return (
                            <li>
                              {`${primaryText}${groupValuesText ? ` (${groupValuesText})` : ""}`}
                            </li>
                          );
                        })}
                      </ul>
                    }
                  >
                    Multiple specifiers
                  </Tooltip>
                </div>
              }
            />
          );
        }
      },
    },
    {
      id: "priority",
      header: "Priority",
      disabled: hidePriorityColumn ?? false,
      sortable: true,
      comparator: (
        overrideA: Override.Description,
        overrideB: Override.Description,
      ) => {
        const priorityA = getOverridePriority(overrideA);
        const priorityB = getOverridePriority(overrideB);

        if (priorityA === undefined && priorityB === undefined) return 0;
        if (priorityA === undefined) return -1;
        if (priorityB === undefined) return 1;
        return priorityA.minus(priorityB).toNumber();
      },
      render: (override) => {
        return getOverridePriority(override)?.toString() ?? "--";
      },
    },
    {
      id: "startingAt",
      header: "Starting at (UTC)",
      render: (override) => {
        return override.startingAt
          ? renderDateTimeInUTC(override.startingAt, false, true)
          : "--";
      },
    },
    {
      id: "endingBefore",
      header: "Ending before (UTC)",
      render: (override) => {
        return override.endingBefore
          ? renderDateTimeInUTC(override.endingBefore, false, true)
          : "--";
      },
    },
    {
      id: "type",
      header: "Type",
      render: (override) => {
        const commitIds = arrayUniq(
          override.appliesTo?.flatMap(
            (specifier) => specifier.commitIds ?? [],
          ) ?? [],
        );
        const commitNames =
          commitIds.length > 0
            ? commitIds.map((id) => commitsById.get(id)?.name ?? id).join(", ")
            : undefined;

        let typeText =
          override.type === "COMMIT_SPECIFIC" ? "Commit specific" : "--";
        const rateChange = override.rateChange;

        if (rateChange) {
          switch (rateChange.type) {
            case "custom_overwrite":
              typeText = "Custom";
              break;
            case "flat_overwrite":
            case "percentage_overwrite":
            case "subscription_overwrite":
            case "tiered_overwrite":
              typeText = "Overwrite";
              break;
            case "multiplier":
            case "tiered_override":
              typeText = "Multiplier";
              break;
            default:
              rateChange satisfies never;
              typeText = "Unknown";
          }
          if (override.type === "COMMIT_SPECIFIC") {
            typeText = "Commit specific " + typeText.toLowerCase();
          }
        }

        return (
          <div className="max-w-xs">
            <CellWithSubtitle
              title={
                <div className="flex flex-wrap items-center gap-8">
                  {typeText}
                </div>
              }
              subtitle={commitNames}
            />
          </div>
        );
      },
    },
    {
      id: "adjustment",
      header: "Adjustment",
      render: (override) => {
        const change = override.rateChange;
        if (!change) {
          return "--";
        }
        return (
          <CellWithSubtitle
            title={<OverrideChangeRateTableCell rate={change} />}
            subtitle={<OverrideTargetText override={override} />}
          />
        );
      },
    },
    {
      id: "entitlement",
      header: "Entitlement",
      render: (override) => {
        return override.entitled === null
          ? "--"
          : override.entitled
            ? "Enabled"
            : "Disabled";
      },
    },
  ];

  const filteredOverrides = useMemo(() => {
    if (!loading) {
      if (!searchQuery) {
        return overrides;
      }
      return overrides.filter((override) => {
        return matchOverrideBySearchQuery(searchQuery, override, productsById);
      });
    }
    return [];
  }, [overrides, searchQuery, loading]);

  return (
    <TablePanel<Override.Description>
      title="Overrides"
      controls={
        <div className="flex items-center gap-8">
          <Input
            type="search"
            placeholder="Search overrides"
            value={searchQuery}
            onChange={(v) => setSearchQuery(v)}
            tooltip="Filter by product name, tags or override type"
          />
        </div>
      }
      columns={tableColumns}
      data={error ? [] : filteredOverrides}
      loading={loading}
      skeletonRows={3}
      emptyState={
        error ? (
          <EmptyState
            mainText="Failed to load overrides"
            icon="alertCircle"
            className="max-h-[200px]"
          />
        ) : (
          <EmptyState
            mainText="No overrides found"
            icon="searchSm"
            className="max-h-[200px]"
          />
        )
      }
    />
  );
}

function getProductDescriptionFromOverrideSpecifier(
  specifier: OverrideSpecifier,
  productsById: Map<string, { id: string; name: string }>,
): { primaryText: string; groupValuesText?: string; error?: boolean } {
  // TODO: We probably want separate presentations for the different group key types but for
  // now we just merge them because we haven't thought of a good UI for them yet.
  const groupValues = {
    ...specifier.pricingGroupValues,
    ...specifier.presentationGroupValues,
  };
  if (specifier.productId) {
    return {
      primaryText:
        productsById.get(specifier.productId)?.name ?? specifier.productId,
      groupValuesText: maybeRenderGroupValues(groupValues),
    };
  } else if (specifier.productTags && specifier.productTags.length > 0) {
    return {
      primaryText: specifier.productTags.join(", "),
      groupValuesText: maybeRenderGroupValues(groupValues),
    };
  } else if (Object.keys(groupValues).length > 0) {
    return {
      primaryText: renderGroupValues(groupValues),
    };
  } else {
    return {
      primaryText: "Error: unknown product(s)",
      error: true,
    };
  }
}

function renderGroupValues(groupValues: Record<string, string>): string {
  return Object.entries(groupValues)
    .map(([key, value]) => {
      return `${key}: ${value}`;
    })
    .join(", ");
}

function maybeRenderGroupValues(
  groupValues: Record<string, string>,
): string | undefined {
  if (Object.keys(groupValues).length > 0) {
    return renderGroupValues(groupValues);
  }
  return undefined;
}

function matchOverrideBySearchQuery(
  query: string,
  override: Override.Description,
  productsById: Map<string, { id: string; name: string }>,
) {
  const productNames =
    override.appliesTo?.map((specifier) => {
      return getProductDescriptionFromOverrideSpecifier(
        specifier,
        productsById,
      );
    }) ?? [];
  return productNames.some(({ primaryText, groupValuesText }) => {
    return (
      primaryText.toLowerCase().includes(query.toLowerCase()) ||
      (groupValuesText?.toLowerCase().includes(query.toLowerCase()) ?? false)
    );
  });
}

function getOverridePriority(
  description: Override.Description,
): Decimal | undefined {
  const rateChange = description.rateChange;
  if (!rateChange) {
    return undefined;
  }
  switch (rateChange.type) {
    case "tiered_override":
    case "multiplier":
      return rateChange.priority ?? undefined;
    case "custom_overwrite":
    case "flat_overwrite":
    case "percentage_overwrite":
    case "subscription_overwrite":
    case "tiered_overwrite":
      return undefined;
    default:
      rateChange satisfies never;
      return undefined;
  }
}

const OverrideTargetText: React.FC<{ override: Override.Description }> = (
  props,
) => {
  if (props.override.type === "COMMIT_SPECIFIC") {
    if (props.override.target === "COMMIT_RATE") {
      return "On commit rate";
    } else if (
      props.override.target == null ||
      props.override.target === "LIST_RATE"
    ) {
      return "On list rate";
    }
  }
  return undefined;
};
