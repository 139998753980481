/* 156a466c284518e08f7657593e8c272d8c070d67
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCreditGrantDeductionsQueryVariables = Types.Exact<{
  credit_grant_id: Types.Scalars['uuid'];
}>;


export type GetCreditGrantDeductionsQuery = { __typename?: 'Query', CreditGrantDeduction: Array<{ __typename?: 'CreditGrantDeduction', id: string }> };


export const GetCreditGrantDeductionsDocument = gql`
    query GetCreditGrantDeductions($credit_grant_id: uuid!) {
  CreditGrantDeduction(where: {CreditGrant: {id: {_eq: $credit_grant_id}}}) {
    id
  }
}
    `;

/**
 * __useGetCreditGrantDeductionsQuery__
 *
 * To run a query within a React component, call `useGetCreditGrantDeductionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditGrantDeductionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditGrantDeductionsQuery({
 *   variables: {
 *      credit_grant_id: // value for 'credit_grant_id'
 *   },
 * });
 */
export function useGetCreditGrantDeductionsQuery(baseOptions: Apollo.QueryHookOptions<GetCreditGrantDeductionsQuery, GetCreditGrantDeductionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreditGrantDeductionsQuery, GetCreditGrantDeductionsQueryVariables>(GetCreditGrantDeductionsDocument, options);
      }
export function useGetCreditGrantDeductionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreditGrantDeductionsQuery, GetCreditGrantDeductionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreditGrantDeductionsQuery, GetCreditGrantDeductionsQueryVariables>(GetCreditGrantDeductionsDocument, options);
        }
export type GetCreditGrantDeductionsQueryHookResult = ReturnType<typeof useGetCreditGrantDeductionsQuery>;
export type GetCreditGrantDeductionsLazyQueryHookResult = ReturnType<typeof useGetCreditGrantDeductionsLazyQuery>;
export type GetCreditGrantDeductionsQueryResult = Apollo.QueryResult<GetCreditGrantDeductionsQuery, GetCreditGrantDeductionsQueryVariables>;