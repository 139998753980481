import React from "react";
import {
  getSemiMonthlyServicePeriodDates,
  getServicePeriodDates,
  monthsPerServicePeriod,
  ServicePeriodDates,
} from "../../../PlanTerms/components/TermPreview/billingDates";
import styles from "./index.module.less";
import { Body, Caption, Subtitle } from "design-system";
import { getUtcStartOfDay, renderDate, renderDateRange } from "lib/time";
import { addDays, addMonths, startOfYear } from "date-fns";
import { BillingDayOfPeriod, PriceRamp } from "lib/plans/types";
import { InvoiceIllustration } from "../../../PlanTerms/components/TermPreview";
import { AdvanceChargeCollectionSchedule } from "./types";
import classNames from "classnames";
import { Tooltip, Icon } from "design-system";
import { dayjs } from "lib/dayjs";

import { BillingFrequencyEnum_Enum } from "types/generated-graphql/__types__";
interface Ramp extends PriceRamp {
  startPeriod: number;
  collectionInterval?: number;
}

interface AdvanceChargePreviewProps {
  billingFrequency: BillingFrequencyEnum_Enum;
  billingDayOfPeriod: BillingDayOfPeriod;
  collectionSchedule: AdvanceChargeCollectionSchedule | undefined;
  ramps: Ramp[];
  rampStart: number;
  planStart?: Date;
  planEnd?: Date;
}

function getTooltip(
  hasAdvanceCharge: boolean,
  collectionSchedule: AdvanceChargeCollectionSchedule | undefined,
  chargePeriodStart: Date,
  chargePeriodEnd: Date | null,
  invoiceDate: Date,
  rampIndex: number,
  servicePeriodIndex: number,
  prevRamp: boolean,
) {
  if (prevRamp) {
    return (
      <Subtitle level={3} className={styles.tooltip}>
        This invoice was configured on a previous Pricing Ramp. To edit, go back
        to this ramp.
      </Subtitle>
    );
  }
  return (
    <>
      <Subtitle level={3} className={styles.tooltip}>{`Ramp ${
        rampIndex + 1
      } | Billing Period ${servicePeriodIndex + 1}`}</Subtitle>
      {hasAdvanceCharge ? (
        collectionSchedule === "ONE_TIME_ADVANCE" &&
        servicePeriodIndex === 0 ? (
          <Subtitle level={3} className={styles.tooltip}>
            In-advance charge invoiced at the start of a plan,{" "}
            {renderDate(chargePeriodStart, {
              isUtc: true,
            })}
          </Subtitle>
        ) : (
          <Subtitle level={3} className={styles.tooltip}>
            In-advance charge for{" "}
            {chargePeriodEnd
              ? renderDateRange(
                  chargePeriodStart,
                  chargePeriodEnd,
                  {
                    isUtc: true,
                  },
                  false,
                )
              : `${renderDate(chargePeriodStart, {
                  isUtc: true,
                })}`}
          </Subtitle>
        )
      ) : (
        <Subtitle level={3} className={styles.tooltip}>
          Standard invoice issues on {renderDate(invoiceDate, { isUtc: true })}
        </Subtitle>
      )}
    </>
  );
}

function computeChargeEnd(
  startDate: Date,
  billingFrequency: BillingFrequencyEnum_Enum,
  billingDayOfPeriod: BillingDayOfPeriod,
  collectionInterval: number,
) {
  const start = dayjs.utc(startDate);
  let servicePeriods: ServicePeriodDates[];
  if (billingFrequency === BillingFrequencyEnum_Enum.SemiMonthly) {
    servicePeriods = getSemiMonthlyServicePeriodDates(
      start.toDate(),
      start
        .add(
          Math.ceil(
            collectionInterval * monthsPerServicePeriod(billingFrequency),
          ),
          "month",
        )
        .toDate(),
      false,
    );
  } else {
    servicePeriods = getServicePeriodDates(
      start.toDate(),
      start
        .add(
          Math.ceil(
            collectionInterval * monthsPerServicePeriod(billingFrequency),
          ),
          "month",
        )
        .toDate(),
      billingDayOfPeriod === "FIRST_OF_MONTH",
      billingFrequency,
      false,
    );
  }
  return addDays(servicePeriods[collectionInterval - 1].end_date, -1);
}

function computeAdvancedCharges(
  servicePeriodsWithRampIndex: { rampIndex: number }[],
  ramps: Ramp[],
) {
  let lastCollectionInterval: number | undefined;
  let servicePeriodsAtLastCollectionIntervalChange: number | undefined;
  return servicePeriodsWithRampIndex.map(
    ({ rampIndex }, servicePeriodIndex) => {
      const currentCollectionInterval =
        ramps[rampIndex].collectionInterval ?? 0;
      if (!lastCollectionInterval) {
        lastCollectionInterval = currentCollectionInterval;
        // servicePeriodIndex is 0 indexed so this is one behind the actual service period
        servicePeriodsAtLastCollectionIntervalChange = servicePeriodIndex;
      } else {
        if (currentCollectionInterval !== lastCollectionInterval) {
          // servicePeriodIndex is 0 indexed so this is one behind the actual service period
          servicePeriodsAtLastCollectionIntervalChange = servicePeriodIndex;
          lastCollectionInterval = currentCollectionInterval;
        }
      }
      // servicePeriodIndex is zero indexed but collection interval is not.
      // We add 1 to service period to keep it aligned with the actual service period
      return (
        (servicePeriodIndex +
          1 -
          (servicePeriodsAtLastCollectionIntervalChange ?? 0)) %
          currentCollectionInterval ===
        0
      );
    },
  );
}

export const AdvanceChargePreview: React.FC<AdvanceChargePreviewProps> = (
  props,
) => {
  // if the plan has dates, we use those to generate the service periods for the preview
  // if the plan has no dates, we want to clamp the number of periods to between
  // 12/24/4/2/1 and 48 periods (depending on monthly/semi-monthly/quarterly/annual/semi-annual)
  // by default, we'll show enough periods to cover all the ramps, and 12 periods for the last ramp
  const servicePeriodsPerYear =
    12 / monthsPerServicePeriod(props.billingFrequency);
  const numPeriodsToShow = Math.max(
    Math.min(
      props.ramps.reduce((prev, curr) => {
        return prev + (curr.durationInPeriods ?? 18);
      }, 0),
      48,
    ),
    servicePeriodsPerYear,
  );
  let servicePeriods: ServicePeriodDates[];
  switch (props.billingFrequency) {
    case BillingFrequencyEnum_Enum.SemiMonthly:
      servicePeriods = getSemiMonthlyServicePeriodDates(
        props.planStart ?? startOfYear(new Date()),
        props.planEnd ??
          getUtcStartOfDay(
            addMonths(
              startOfYear(new Date()),
              Math.ceil(
                numPeriodsToShow *
                  monthsPerServicePeriod(props.billingFrequency),
              ),
            ),
          ),
        true,
      );
      break;
    case BillingFrequencyEnum_Enum.Annual:
    case BillingFrequencyEnum_Enum.SemiAnnual:
      servicePeriods = getServicePeriodDates(
        props.planStart ?? startOfYear(new Date()),
        props.planEnd ??
          getUtcStartOfDay(
            addMonths(
              startOfYear(new Date()),
              numPeriodsToShow * monthsPerServicePeriod(props.billingFrequency),
            ),
          ),
        false,
        props.billingFrequency,
        true,
      );
      break;
    default:
      // MONTHLY or QUARTERLY
      servicePeriods = getServicePeriodDates(
        props.planStart ?? startOfYear(new Date()),
        props.planEnd ??
          getUtcStartOfDay(
            addMonths(
              startOfYear(new Date()),
              numPeriodsToShow * monthsPerServicePeriod(props.billingFrequency),
            ),
          ),
        props.billingDayOfPeriod === "FIRST_OF_MONTH",
        props.billingFrequency,
        true,
      );
  }

  const servicePeriodsWithRampIndex = servicePeriods.map((sp, i) => {
    const rampIndex = props.ramps.findIndex(
      ({ startPeriod }) => i < startPeriod,
    );
    return {
      servicePeriod: sp,
      rampIndex: (rampIndex === -1 ? props.ramps.length : rampIndex) - 1,
    };
  });

  const hasAdvancedChargePerServicePeriod = computeAdvancedCharges(
    servicePeriodsWithRampIndex,
    props.ramps,
  );

  return (
    <div className={styles.preview}>
      {servicePeriodsWithRampIndex.map(
        ({ servicePeriod: sp, rampIndex }, servicePeriodIndex) => {
          const spIndexInRamp =
            servicePeriodIndex - props.ramps[rampIndex].startPeriod;
          const lastPeriodInRamp =
            spIndexInRamp ===
            (props.ramps[rampIndex].durationInPeriods ?? 0) - 1;
          const collectionInterval = lastPeriodInRamp
            ? props.ramps[rampIndex + 1].collectionInterval
            : props.ramps[rampIndex].collectionInterval;
          const hasAdvanceCharge =
            hasAdvancedChargePerServicePeriod[servicePeriodIndex];

          const chargeEnd =
            hasAdvanceCharge && collectionInterval
              ? computeChargeEnd(
                  sp.end_date,
                  props.billingFrequency,
                  props.billingDayOfPeriod,
                  collectionInterval,
                )
              : null;

          if (
            servicePeriodIndex === 0 &&
            (props.collectionSchedule === "ONE_TIME_ADVANCE" ||
              (props.collectionSchedule && collectionInterval))
          ) {
            return (
              <div
                key={servicePeriodIndex}
                className={classNames(styles.invoiceContainer, {
                  [styles.prevRamp]: servicePeriodIndex < props.rampStart,
                })}
              >
                <div className={styles.firstInvoices}>
                  <div
                    className={classNames(styles.firstInvoice, styles.invoice)}
                  >
                    <InvoiceIllustration theme="primary" />
                    <div className={styles.content}>
                      <Subtitle>Invoice 1</Subtitle>
                      <Body level={2}>
                        {renderDate(sp.start_date, {
                          isUtc: true,
                          excludeUtcLabel: true,
                          excludeYear: true,
                        })}
                      </Body>
                      <Body className={styles.bottom} level={2}>
                        In-advance charge invoiced
                      </Body>
                      <div className={styles.tooltipContainer}>
                        <Tooltip
                          content={getTooltip(
                            true,
                            props.collectionSchedule,
                            sp.start_date,
                            props.collectionSchedule === "ADVANCE" &&
                              collectionInterval
                              ? computeChargeEnd(
                                  sp.start_date,
                                  props.billingFrequency,
                                  props.billingDayOfPeriod,
                                  collectionInterval,
                                )
                              : null,
                            sp.start_date,
                            rampIndex,
                            servicePeriodIndex,
                            servicePeriodIndex < props.rampStart,
                          )}
                          inline
                        >
                          <Icon icon="helpCircle" />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className={styles.invoice}>
                    <InvoiceIllustration
                      theme={hasAdvanceCharge ? "primary" : undefined}
                    />
                    <div className={styles.content}>
                      <Subtitle>Invoice 2</Subtitle>
                      <Body level={2}>
                        {renderDateRange(
                          sp.start_date,
                          addDays(sp.end_date, -1),
                          {
                            isUtc: true,
                            excludeUtcLabel: true,
                            excludeYear: true,
                          },
                          false,
                        )}
                      </Body>
                      {hasAdvanceCharge && (
                        <Body className={styles.bottom} level={2}>
                          In-advance charge invoiced
                        </Body>
                      )}
                      <div className={styles.tooltipContainer}>
                        <Tooltip
                          content={getTooltip(
                            hasAdvanceCharge,
                            props.collectionSchedule,
                            sp.end_date,
                            chargeEnd,
                            addDays(sp.end_date, 1),
                            rampIndex,
                            servicePeriodIndex,
                            servicePeriodIndex < props.rampStart &&
                              !lastPeriodInRamp,
                          )}
                          inline
                        >
                          <Icon icon="helpCircle" />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(styles.billingPeriod, styles.active)}
                >
                  <Caption level={2}>Billing period 1</Caption>
                </div>
              </div>
            );
          }

          return (
            <div
              key={servicePeriodIndex}
              className={classNames(styles.invoiceContainer, {
                [styles.prevRamp]: servicePeriodIndex < props.rampStart,
              })}
            >
              <div className={styles.invoice}>
                <InvoiceIllustration
                  theme={hasAdvanceCharge ? "primary" : undefined}
                />
                <div className={styles.content}>
                  <Subtitle>
                    Invoice{" "}
                    {servicePeriodIndex +
                      (props.collectionSchedule === "ONE_TIME_ADVANCE" ||
                      (props.collectionSchedule && collectionInterval)
                        ? 2
                        : 1)}
                  </Subtitle>
                  <Body level={2}>
                    {renderDateRange(
                      sp.start_date,
                      addDays(sp.end_date, -1),
                      { isUtc: true, excludeUtcLabel: true, excludeYear: true },
                      false,
                    )}
                  </Body>
                  {hasAdvanceCharge && (
                    <Body className={styles.bottom} level={2}>
                      In-advance charge invoiced
                    </Body>
                  )}
                  <div className={styles.tooltipContainer}>
                    <Tooltip
                      content={getTooltip(
                        hasAdvanceCharge,
                        props.collectionSchedule,
                        sp.end_date,
                        chargeEnd,
                        addDays(sp.end_date, 1),
                        rampIndex,
                        servicePeriodIndex,
                        servicePeriodIndex < props.rampStart &&
                          !lastPeriodInRamp,
                      )}
                      inline
                    >
                      <Icon icon="helpCircle" />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div
                className={classNames(styles.billingPeriod, {
                  [styles.active]: hasAdvanceCharge,
                })}
              >
                <Caption level={2}>
                  Billing period {servicePeriodIndex + 1}
                </Caption>
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};
