/* 166a2244d02e510f30914eeb3d4f1c28b56dd595
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsertBillableMetricMutationVariables = Types.Exact<{
  object: Types.BillableMetricInput;
}>;


export type InsertBillableMetricMutation = { __typename?: 'Mutation', create_billable_metric: { __typename?: 'BillableMetric', id: string, name: string } };

export type BillableMetricDetailQueryVariables = Types.Exact<{
  billable_metric_id: Types.Scalars['uuid'];
}>;


export type BillableMetricDetailQuery = { __typename?: 'Query', BillableMetric: { __typename?: 'BillableMetric', id: string, name: string, aggregate: Types.BillingMetricAggregateEnum_Enum, aggregate_keys: object | null, group_keys: object | null, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, sql: string | null, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null } | null };

export type BillableMetricDetailsFragment = { __typename?: 'BillableMetric', id: string, name: string, aggregate: Types.BillingMetricAggregateEnum_Enum, aggregate_keys: object | null, group_keys: object | null, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, sql: string | null, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null };

export type InsertSeatMetricMutationVariables = Types.Exact<{
  input: Types.SeatMetricInput;
}>;


export type InsertSeatMetricMutation = { __typename?: 'Mutation', create_seat_metric: { __typename?: 'SeatMetric', id: string, name: string } };

export type SeatMetricDetailQueryVariables = Types.Exact<{
  seat_metric_id: Types.Scalars['uuid'];
}>;


export type SeatMetricDetailQuery = { __typename?: 'Query', seat_metric: { __typename?: 'SeatMetric', id: string, name: string, aggregate_key: string, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } } };

export type SeatMetricDetailsFragment = { __typename?: 'SeatMetric', id: string, name: string, aggregate_key: string, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } };

export type ValidateFancyBillableMetricQueryVariables = Types.Exact<{
  sql: Types.Scalars['String'];
}>;


export type ValidateFancyBillableMetricQuery = { __typename?: 'Query', validate_fancy_metric: { __typename?: 'FancyMetricValidationResponse', success: boolean, validation_errors: Array<{ __typename?: 'FancyMetricError', message: string, location: { __typename?: 'FancyMetricErrorLocation', start_line: number, start_column: number, end_line: number, end_column: number } | null }> | null } };

export type InsertFancyMetricMutationVariables = Types.Exact<{
  object: Types.FancyMetricInput;
}>;


export type InsertFancyMetricMutation = { __typename?: 'Mutation', create_fancy_metric: { __typename?: 'BillableMetric', id: string, name: string } };

export type ExecuteFancySqlQueryVariables = Types.Exact<{
  input: Types.ExecuteFancySqlInput;
}>;


export type ExecuteFancySqlQuery = { __typename?: 'Query', execute_fancy_sql: { __typename?: 'ExecuteFancySQLResult', rows: Array<Record<string, string | null>>, columns: Array<{ __typename?: 'ExecuteFancySQLResultColumn', name: string }> } };

export const BillableMetricDetailsFragmentDoc = gql`
    fragment BillableMetricDetails on BillableMetric {
  id
  name
  aggregate
  aggregate_keys
  group_keys
  filter
  created_at
  is_draft
  deleted_at
  Creator {
    name
    id
    deprecated_at
  }
  active_plan_count
  active_product_count
  sql
  __environment_type: environment_type
}
    `;
export const SeatMetricDetailsFragmentDoc = gql`
    fragment SeatMetricDetails on SeatMetric {
  id
  name
  aggregate_key
  filter
  created_at
  is_draft
  deleted_at
  Creator {
    name
    id
    deprecated_at
  }
  active_plan_count
  active_product_count
}
    `;
export const InsertBillableMetricDocument = gql`
    mutation InsertBillableMetric($object: BillableMetricInput!) {
  create_billable_metric(input: $object) {
    id
    name
    __environment_type: environment_type
  }
}
    `;
export type InsertBillableMetricMutationFn = Apollo.MutationFunction<InsertBillableMetricMutation, InsertBillableMetricMutationVariables>;

/**
 * __useInsertBillableMetricMutation__
 *
 * To run a mutation, you first call `useInsertBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBillableMetricMutation, { data, loading, error }] = useInsertBillableMetricMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertBillableMetricMutation(baseOptions?: Apollo.MutationHookOptions<InsertBillableMetricMutation, InsertBillableMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertBillableMetricMutation, InsertBillableMetricMutationVariables>(InsertBillableMetricDocument, options);
      }
export type InsertBillableMetricMutationHookResult = ReturnType<typeof useInsertBillableMetricMutation>;
export type InsertBillableMetricMutationResult = Apollo.MutationResult<InsertBillableMetricMutation>;
export type InsertBillableMetricMutationOptions = Apollo.BaseMutationOptions<InsertBillableMetricMutation, InsertBillableMetricMutationVariables>;
export const BillableMetricDetailDocument = gql`
    query BillableMetricDetail($billable_metric_id: uuid!) {
  BillableMetric: BillableMetric_by_pk(id: $billable_metric_id) {
    id
    ...BillableMetricDetails
    __environment_type: environment_type
  }
}
    ${BillableMetricDetailsFragmentDoc}`;

/**
 * __useBillableMetricDetailQuery__
 *
 * To run a query within a React component, call `useBillableMetricDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableMetricDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableMetricDetailQuery({
 *   variables: {
 *      billable_metric_id: // value for 'billable_metric_id'
 *   },
 * });
 */
export function useBillableMetricDetailQuery(baseOptions: Apollo.QueryHookOptions<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>(BillableMetricDetailDocument, options);
      }
export function useBillableMetricDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>(BillableMetricDetailDocument, options);
        }
export type BillableMetricDetailQueryHookResult = ReturnType<typeof useBillableMetricDetailQuery>;
export type BillableMetricDetailLazyQueryHookResult = ReturnType<typeof useBillableMetricDetailLazyQuery>;
export type BillableMetricDetailQueryResult = Apollo.QueryResult<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>;
export const InsertSeatMetricDocument = gql`
    mutation InsertSeatMetric($input: SeatMetricInput!) {
  create_seat_metric(input: $input) {
    id
    name
  }
}
    `;
export type InsertSeatMetricMutationFn = Apollo.MutationFunction<InsertSeatMetricMutation, InsertSeatMetricMutationVariables>;

/**
 * __useInsertSeatMetricMutation__
 *
 * To run a mutation, you first call `useInsertSeatMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSeatMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSeatMetricMutation, { data, loading, error }] = useInsertSeatMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertSeatMetricMutation(baseOptions?: Apollo.MutationHookOptions<InsertSeatMetricMutation, InsertSeatMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSeatMetricMutation, InsertSeatMetricMutationVariables>(InsertSeatMetricDocument, options);
      }
export type InsertSeatMetricMutationHookResult = ReturnType<typeof useInsertSeatMetricMutation>;
export type InsertSeatMetricMutationResult = Apollo.MutationResult<InsertSeatMetricMutation>;
export type InsertSeatMetricMutationOptions = Apollo.BaseMutationOptions<InsertSeatMetricMutation, InsertSeatMetricMutationVariables>;
export const SeatMetricDetailDocument = gql`
    query SeatMetricDetail($seat_metric_id: uuid!) {
  seat_metric(seat_metric_id: $seat_metric_id) {
    id
    ...SeatMetricDetails
  }
}
    ${SeatMetricDetailsFragmentDoc}`;

/**
 * __useSeatMetricDetailQuery__
 *
 * To run a query within a React component, call `useSeatMetricDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeatMetricDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeatMetricDetailQuery({
 *   variables: {
 *      seat_metric_id: // value for 'seat_metric_id'
 *   },
 * });
 */
export function useSeatMetricDetailQuery(baseOptions: Apollo.QueryHookOptions<SeatMetricDetailQuery, SeatMetricDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeatMetricDetailQuery, SeatMetricDetailQueryVariables>(SeatMetricDetailDocument, options);
      }
export function useSeatMetricDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeatMetricDetailQuery, SeatMetricDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeatMetricDetailQuery, SeatMetricDetailQueryVariables>(SeatMetricDetailDocument, options);
        }
export type SeatMetricDetailQueryHookResult = ReturnType<typeof useSeatMetricDetailQuery>;
export type SeatMetricDetailLazyQueryHookResult = ReturnType<typeof useSeatMetricDetailLazyQuery>;
export type SeatMetricDetailQueryResult = Apollo.QueryResult<SeatMetricDetailQuery, SeatMetricDetailQueryVariables>;
export const ValidateFancyBillableMetricDocument = gql`
    query ValidateFancyBillableMetric($sql: String!) {
  validate_fancy_metric(sql: $sql) {
    success
    validation_errors {
      message
      location {
        start_line
        start_column
        end_line
        end_column
      }
    }
  }
}
    `;

/**
 * __useValidateFancyBillableMetricQuery__
 *
 * To run a query within a React component, call `useValidateFancyBillableMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateFancyBillableMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateFancyBillableMetricQuery({
 *   variables: {
 *      sql: // value for 'sql'
 *   },
 * });
 */
export function useValidateFancyBillableMetricQuery(baseOptions: Apollo.QueryHookOptions<ValidateFancyBillableMetricQuery, ValidateFancyBillableMetricQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateFancyBillableMetricQuery, ValidateFancyBillableMetricQueryVariables>(ValidateFancyBillableMetricDocument, options);
      }
export function useValidateFancyBillableMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateFancyBillableMetricQuery, ValidateFancyBillableMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateFancyBillableMetricQuery, ValidateFancyBillableMetricQueryVariables>(ValidateFancyBillableMetricDocument, options);
        }
export type ValidateFancyBillableMetricQueryHookResult = ReturnType<typeof useValidateFancyBillableMetricQuery>;
export type ValidateFancyBillableMetricLazyQueryHookResult = ReturnType<typeof useValidateFancyBillableMetricLazyQuery>;
export type ValidateFancyBillableMetricQueryResult = Apollo.QueryResult<ValidateFancyBillableMetricQuery, ValidateFancyBillableMetricQueryVariables>;
export const InsertFancyMetricDocument = gql`
    mutation InsertFancyMetric($object: FancyMetricInput!) {
  create_fancy_metric(input: $object) {
    id
    name
    __environment_type: environment_type
  }
}
    `;
export type InsertFancyMetricMutationFn = Apollo.MutationFunction<InsertFancyMetricMutation, InsertFancyMetricMutationVariables>;

/**
 * __useInsertFancyMetricMutation__
 *
 * To run a mutation, you first call `useInsertFancyMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFancyMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFancyMetricMutation, { data, loading, error }] = useInsertFancyMetricMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertFancyMetricMutation(baseOptions?: Apollo.MutationHookOptions<InsertFancyMetricMutation, InsertFancyMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFancyMetricMutation, InsertFancyMetricMutationVariables>(InsertFancyMetricDocument, options);
      }
export type InsertFancyMetricMutationHookResult = ReturnType<typeof useInsertFancyMetricMutation>;
export type InsertFancyMetricMutationResult = Apollo.MutationResult<InsertFancyMetricMutation>;
export type InsertFancyMetricMutationOptions = Apollo.BaseMutationOptions<InsertFancyMetricMutation, InsertFancyMetricMutationVariables>;
export const ExecuteFancySqlDocument = gql`
    query ExecuteFancySQL($input: ExecuteFancySQLInput!) {
  execute_fancy_sql(input: $input) {
    columns {
      name
    }
    rows
  }
}
    `;

/**
 * __useExecuteFancySqlQuery__
 *
 * To run a query within a React component, call `useExecuteFancySqlQuery` and pass it any options that fit your needs.
 * When your component renders, `useExecuteFancySqlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExecuteFancySqlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteFancySqlQuery(baseOptions: Apollo.QueryHookOptions<ExecuteFancySqlQuery, ExecuteFancySqlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExecuteFancySqlQuery, ExecuteFancySqlQueryVariables>(ExecuteFancySqlDocument, options);
      }
export function useExecuteFancySqlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExecuteFancySqlQuery, ExecuteFancySqlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExecuteFancySqlQuery, ExecuteFancySqlQueryVariables>(ExecuteFancySqlDocument, options);
        }
export type ExecuteFancySqlQueryHookResult = ReturnType<typeof useExecuteFancySqlQuery>;
export type ExecuteFancySqlLazyQueryHookResult = ReturnType<typeof useExecuteFancySqlLazyQuery>;
export type ExecuteFancySqlQueryResult = Apollo.QueryResult<ExecuteFancySqlQuery, ExecuteFancySqlQueryVariables>;