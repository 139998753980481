import { DateInput } from "design-system";
import { Button } from "tenaissance/components/Button";
import Papa from "papaparse";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { Popup } from "components/Popup";
import React, { useState } from "react";
import {
  GetAllContractRatesOnEffectiveDateQuery,
  useGetAllContractRatesOnEffectiveDateLazyQuery,
} from "./data.graphql";
import { reportToSentry } from "lib/errors/sentry";
import { Dayjs, dayjs } from "lib/dayjs";
import {
  formatRate,
  stringToCsvDownload,
} from "pages/Contracts/lib/ContractPricing/RatesDownload";
import { Rate } from "pages/Contracts/lib/ContractPricing/RatesDownload";

function asCsv(
  dataPages: GetAllContractRatesOnEffectiveDateQuery[],
  at: Dayjs,
) {
  const productsById = new Map(
    dataPages
      .map(
        (data) =>
          data.customer?.contract?.rate_schedule.products_on_segments || [],
      )
      .flat()
      .map((product) => [product.id, product]),
  );

  const getProduct = (id: string) => {
    const product = productsById.get(id);
    if (!product) {
      throw new Error(`Product with id ${id} not found`);
    }
    return product;
  };

  const rows = dataPages
    .map((data) => data.customer?.contract?.rate_schedule.scalar_segments || [])
    .flat()
    .map(
      ({
        starting_at,
        ending_before,
        pricing_groups,
        product_id,
        list_rate,
        override_rate,
      }) => {
        const product = getProduct(product_id);
        const product_name = ProductListItem.getName(product, at);
        const product_tags = ProductListItem.getTags(product, at);
        const formattedListRate = formatRate(list_rate);
        const creditTypeId = getCreditTypeId(list_rate);
        const formattedOverrideRate = override_rate
          ? formatRate(override_rate)
          : undefined;
        const formattedManagedFields = product.managed_fields.length
          ? Object.fromEntries(
              product.managed_fields.map(({ ManagedFieldKey, value }) => [
                ManagedFieldKey.key,
                value,
              ]),
            )
          : {};

        return {
          starting_at,
          ending_before,
          pricing_group_values: JSON.stringify(pricing_groups),
          product_id: product.id,
          product_name_current: product_name,
          product_tags_current: JSON.stringify(product_tags),
          custom_fields: JSON.stringify(formattedManagedFields),
          list_rate: JSON.stringify(formattedListRate),
          override_rate: JSON.stringify(formattedOverrideRate),
          credit_type_id: creditTypeId,
        };
      },
    );
  const csvContent = Papa.unparse(rows);
  return csvContent;
}

function getCreditTypeId(rate: Rate): string | undefined {
  if ("credit_type" in rate) {
    return rate.credit_type.id;
  }
  if ("credit_type_id" in rate) {
    return rate.credit_type_id;
  }
  return undefined;
}

interface ContractRatesDownloadModalProps {
  contractId: string;
  customerId: string;
  at: Dayjs;
  onStartDownload: () => void;
  onComplete: () => void;
  onError?: () => void;
  onCancel: () => void;
}

export const ContractRatesDownloadModal: React.FC<
  ContractRatesDownloadModalProps
> = (props) => {
  const [ratesRequest, ratesResult] =
    useGetAllContractRatesOnEffectiveDateLazyQuery();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    dayjs.utc(props.at).startOf("day"),
  );

  const loading = ratesResult.loading;
  async function onSubmit() {
    props.onStartDownload();
    let next_page: string | null = null;
    const dataPages = [];
    const at = (selectedDate ?? props.at).toISOString();
    do {
      const result = await ratesRequest({
        variables: {
          customerId: props.customerId,
          contractId: props.contractId,
          limit: 10000,
          cursor: next_page,
          at,
        },
      });

      if (result.error) {
        reportToSentry(ratesResult.error);
        if (props.onError) {
          props.onError();
        }
      }

      if (!result.data) {
        return;
      }
      dataPages.push(result.data);
      next_page =
        result.data.customer?.contract?.rate_schedule.next_page ?? null;
    } while (next_page);
    const csvContent = asCsv(dataPages, props.at);

    stringToCsvDownload(
      csvContent,
      `contract-${props.contractId}-rates-on-${at}.csv`,
    );
    props.onComplete();
  }

  return (
    <Popup
      isOpen
      onRequestClose={props.onCancel}
      title="Download Rates CSV"
      actions={
        <>
          <Button
            loading={false}
            onClick={props.onCancel}
            text="Cancel"
            theme="linkGray"
          />
          <Button
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
            text="Download"
            theme="primary"
            type="submit"
          />
        </>
      }
    >
      <p className="my-8">
        Download all effective rates for UTC midnight on the selected date.
      </p>
      <DateInput
        name="Effective Date"
        onChange={(v) => {
          setSelectedDate(dayjs.utc(v).startOf("day"));
        }}
        value={selectedDate.toDate()}
        isUTC={true}
      />
    </Popup>
  );
};
