import React, { useState } from "react";
import { Icon, Caption } from "design-system";
import { twMerge } from "design-system/twMerge";

type SqlFunctionDescriptionProps = {
  name: string;
  sample: string;
  description: string;
  args: { name: string; description: string; isOptional: boolean }[];
};

export const SqlFunctionDescription: React.FC<SqlFunctionDescriptionProps> = ({
  name,
  sample,
  description,
  args,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const argsString = `${args.map((arg) => (arg.isOptional ? `[${arg.name}]` : arg.name)).join(", ")}`;
  return (
    <button
      className={twMerge(
        "mb-0 w-full truncate text-wrap px-16 py-4 text-start",
        isExpanded ? "bg-gray-50" : "",
      )}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div>
        <div className="flex items-center font-mono text-xs text-gray-800">
          <div className="mr-8 h-12">
            <Icon icon={isExpanded ? "caretDown" : "caretForward"} />
          </div>
          <span className="font-mono font-semibold">{name}</span>({argsString})
        </div>
      </div>
      {isExpanded && (
        <div>
          <div className="border-b-gray-100 mx-8 flex flex-col gap-16 border-b py-8">
            <div className="gap-4">
              <Caption className="text-gray-700">SAMPLE</Caption>
              <p className="font-mono text-xs font-normal">{sample}</p>
            </div>
            <div className="gap-4">
              <Caption className="text-gray-700">DESCRIPTION</Caption>
              <p className="text-wrap text-xs font-normal">{description}</p>
            </div>
          </div>
          <div className="mx-8 flex flex-col gap-16 py-8 pb-0">
            {args.map((arg) => (
              <div className="gap-4">
                <Caption className="font-medium normal-case text-deprecated-gray-900">
                  {`${arg.name}${arg.isOptional ? " - optional" : ""}`}
                </Caption>
                <p className="text-wrap text-xs font-normal">
                  {arg.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </button>
  );
};

export const allSQLFunctions: SqlFunctionDescriptionProps[] = [
  {
    name: "COUNT",
    sample: "COUNT(event_type)",
    description:
      "Returns the total number of non-null records for the specified expression",
    args: [
      {
        name: "expr1",
        description:
          "An expression or column name that defines the values to be aggregated",
        isOptional: false,
      },
      {
        name: "expr2, ...",
        description:
          "Additional expression(s) to count. For example, you could count the number of distinct combinations of country and region.",
        isOptional: true,
      },
    ],
  },
  {
    name: "DISTINCT",
    sample: "DISTINCT event_type",
    description: "Returns the distinct values for the specified expression",
    args: [
      {
        name: "expr",
        description:
          "An expression or column name that defines the values to be aggregated",
        isOptional: false,
      },
    ],
  },
  {
    name: "SUM",
    sample: "SUM(properties.amount)",
    description: "Returns the sum of all values for the specified expression",
    args: [
      {
        name: "expr",
        description:
          "An expression or column name that defines the values to be aggregated",
        isOptional: false,
      },
    ],
  },
  {
    name: "MAX",
    sample: "MAX(properties.amount)",
    description:
      "Returns the largest value of all rows for the specified expression.",
    args: [
      {
        name: "expr",
        description:
          "An expression or column name that defines the values to be aggregated",
        isOptional: false,
      },
    ],
  },
  {
    name: "MIN",
    sample: "MIN(properties.amount)",
    description:
      "Returns the smallest value of all rows for the specified expression.",
    args: [
      {
        name: "expr",
        description:
          "An expression or column name that defines the values to be aggregated",
        isOptional: false,
      },
    ],
  },
  {
    name: "AVG",
    sample: "AVG(properties.amount)",
    description:
      "Returns the average value of an expression by summing all values and dividing by the number of non-null rows.",
    args: [
      {
        name: "expr",
        description:
          "An expression or column name that defines the values to be aggregated",
        isOptional: false,
      },
    ],
  },
  {
    name: "LATEST",
    sample: "LATEST(properties.amount)",
    description:
      "Returns the latest value of an expression by the event timestamp.",
    args: [
      {
        name: "expr",
        description:
          "An expression or column name that defines the values to be aggregated",
        isOptional: false,
      },
    ],
  },
  {
    name: "EARLIEST",
    sample: "EARLIEST(properties.amount)",
    description:
      "Returns the earliest value of an expression by the event timestamp.",
    args: [
      {
        name: "expr",
        description:
          "An expression or column name that defines the values to be aggregated",
        isOptional: false,
      },
    ],
  },
  {
    name: "GREATEST",
    sample: "GREATEST(expr1, expr2)",
    description:
      "Returns the greatest value from a list of columns or expressions.",
    args: [
      {
        name: "expr1",
        description: "First column or expression to compare.",
        isOptional: false,
      },
      {
        name: "expr2, ...",
        description: "Additional columns or expressions to compare.",
        isOptional: true,
      },
    ],
  },
  {
    name: "LEAST",
    sample: "LEAST(expr1, expr2)",
    description:
      "Returns the lowest value from a list of columns or expressions.",
    args: [
      {
        name: "expr1",
        description: "First column or expression to compare.",
        isOptional: false,
      },
      {
        name: "expr2, ...",
        description: "Additional columns or expressions to compare.",
        isOptional: true,
      },
    ],
  },
  {
    name: "ROUND",
    sample: "ROUND(amount, 2)",
    description:
      "Rounds a numeric expression to the specified number of decimal places.",
    args: [
      {
        name: "expr",
        description: "Numeric expression to round.",
        isOptional: false,
      },
      {
        name: "decimal_places",
        description: "Number of decimal places to round to. Default is 0.",
        isOptional: true,
      },
    ],
  },
  {
    name: "CEIL",
    sample: "CEIL(expr)",
    description:
      "Returns the smallest integer greater than or equal to a specified numeric value.",
    args: [
      {
        name: "expr",
        description: "Numeric expression to round up.",
        isOptional: false,
      },
    ],
  },
  {
    name: "FLOOR",
    sample: "FLOOR(expr)",
    description:
      "Returns the largest integer less than or equal to a specified numeric value.",
    args: [
      {
        name: "expr",
        description: "Numeric expression to round down.",
        isOptional: false,
      },
    ],
  },
];
