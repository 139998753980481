import { ApolloCache } from "@apollo/client";

export function clearCustomerInvoicesFromCache(
  cache: ApolloCache<unknown>,
  customerID: string,
) {
  // We evict
  cache.evict({
    id: `Customer:${customerID}`,
    fieldName: "mri_invoices",
  });
  // Also evict any top level invoices
  cache.evict({
    fieldName: "mri_invoice",
  });
}
