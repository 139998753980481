import React, { useState } from "react";
import { useUIMode } from "lib/useUIMode";
import NotFoundPage from "pages/404";
import { View } from "./view";
import { APITokens } from "./APITokens";
import { Webhooks } from "./Webhooks";

export const APITokensAndWebhooks: React.FC = () => {
  const { newUIEnabled } = useUIMode();
  const [selectedView, setSelectedView] = useState<View>("API_TOKENS");

  if (!newUIEnabled) {
    return <NotFoundPage />;
  }

  return selectedView === "API_TOKENS" ? (
    <APITokens setSelectedView={setSelectedView} />
  ) : (
    <Webhooks setSelectedView={setSelectedView} />
  );
};
