import React from "react";
import { Badge } from "design-system";
import { Badge as T10Badge } from "../../../tenaissance/components/Badge";
import { useUIMode } from "lib/useUIMode";

export default function MetricBadge({
  deletedAt,
}: {
  deletedAt: string | null;
}) {
  const { newUIEnabled } = useUIMode();
  if (deletedAt !== null) {
    return newUIEnabled ? (
      <T10Badge label="Archived" theme="warning" className="mr-8" />
    ) : (
      <Badge theme="warning" type="dark" className="mr-8">
        ARCHIVED
      </Badge>
    );
  }

  return null;
}
