/* ba19c4d04663f05fcb2afac391ee2aeee2ea8303
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomerCustomFieldsQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type GetCustomerCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, CustomerManagedFields: Array<{ __typename?: 'CustomerManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetChargeCustomFieldsQueryVariables = Types.Exact<{
  ppf_id: Types.Scalars['uuid'];
}>;


export type GetChargeCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, ChargeManagedFields: Array<{ __typename?: 'ChargeManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetProductCustomFieldsQueryVariables = Types.Exact<{
  product_id: Types.Scalars['uuid'];
}>;


export type GetProductCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, ProductManagedFields: Array<{ __typename?: 'ProductManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetPlanCustomFieldsQueryVariables = Types.Exact<{
  plan_id: Types.Scalars['uuid'];
}>;


export type GetPlanCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, PlanManagedFields: Array<{ __typename?: 'PlanManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetCreditGrantCustomFieldsQueryVariables = Types.Exact<{
  credit_grant_id: Types.Scalars['uuid'];
}>;


export type GetCreditGrantCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, CreditGrantManagedFields: Array<{ __typename?: 'CreditGrantManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetCustomerPlanCustomFieldsQueryVariables = Types.Exact<{
  customer_plan_id: Types.Scalars['uuid'];
}>;


export type GetCustomerPlanCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, CustomerPlanManagedFields: Array<{ __typename?: 'CustomerPlanManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetCommitCustomFieldsQueryVariables = Types.Exact<{
  commit_id: Types.Scalars['uuid'];
}>;


export type GetCommitCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, CommitManagedFields: Array<{ __typename?: 'CommitManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetContractCustomFieldsQueryVariables = Types.Exact<{
  contract_id: Types.Scalars['uuid'];
}>;


export type GetContractCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, ContractManagedFields: Array<{ __typename?: 'ContractManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetContractProductCustomFieldsQueryVariables = Types.Exact<{
  contract_product_id: Types.Scalars['uuid'];
}>;


export type GetContractProductCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, ContractProductManagedFields: Array<{ __typename?: 'ContractProductManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetRateCardCustomFieldsQueryVariables = Types.Exact<{
  rate_card_id: Types.Scalars['uuid'];
}>;


export type GetRateCardCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, RateCardManagedFields: Array<{ __typename?: 'RateCardManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetAlertCustomFieldsQueryVariables = Types.Exact<{
  alert_id: Types.Scalars['uuid'];
}>;


export type GetAlertCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, AlertManagedFields: Array<{ __typename?: 'AlertManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetInvoiceCustomFieldsQueryVariables = Types.Exact<{
  invoice_id: Types.Scalars['uuid'];
}>;


export type GetInvoiceCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, InvoiceManagedFields: Array<{ __typename?: 'InvoiceManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetContractCreditCustomFieldsQueryVariables = Types.Exact<{
  contract_credit_id: Types.Scalars['uuid'];
}>;


export type GetContractCreditCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, ContractCreditManagedFields: Array<{ __typename?: 'ContractCreditManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetScheduledChargeCustomFieldsQueryVariables = Types.Exact<{
  scheduled_charge_id: Types.Scalars['uuid'];
}>;


export type GetScheduledChargeCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, ScheduledChargeManagedFields: Array<{ __typename?: 'ScheduledChargeManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type GetProServiceCustomFieldsQueryVariables = Types.Exact<{
  pro_service_id: Types.Scalars['uuid'];
}>;


export type GetProServiceCustomFieldsQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum, ProServiceManagedFields: Array<{ __typename?: 'ProServiceManagedField', id: string, value: string, updated_at: string, Updater: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> }> };

export type SetManagedFieldOnCustomerMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnCustomerMutation = { __typename?: 'Mutation', insert_CustomerManagedField_one: { __typename?: 'CustomerManagedField', id: string } | null };

export type SetManagedFieldOnChargeMutationVariables = Types.Exact<{
  ppf_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnChargeMutation = { __typename?: 'Mutation', insert_ChargeManagedField_one: { __typename?: 'ChargeManagedField', id: string } | null };

export type SetManagedFieldOnProductMutationVariables = Types.Exact<{
  product_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnProductMutation = { __typename?: 'Mutation', insert_ProductManagedField_one: { __typename?: 'ProductManagedField', id: string } | null };

export type SetManagedFieldOnPlanMutationVariables = Types.Exact<{
  plan_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnPlanMutation = { __typename?: 'Mutation', insert_PlanManagedField_one: { __typename?: 'PlanManagedField', id: string } | null };

export type SetManagedFieldOnCustomerPlanMutationVariables = Types.Exact<{
  customer_plan_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnCustomerPlanMutation = { __typename?: 'Mutation', insert_CustomerPlanManagedField_one: { __typename?: 'CustomerPlanManagedField', id: string } | null };

export type SetManagedFieldOnCreditGrantMutationVariables = Types.Exact<{
  credit_grant_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnCreditGrantMutation = { __typename?: 'Mutation', insert_CreditGrantManagedField_one: { __typename?: 'CreditGrantManagedField', id: string } | null };

export type SetManagedFieldOnCommitMutationVariables = Types.Exact<{
  commit_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnCommitMutation = { __typename?: 'Mutation', insert_CommitManagedField_one: { __typename?: 'CommitManagedField', id: string } | null };

export type SetManagedFieldOnContractMutationVariables = Types.Exact<{
  contract_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnContractMutation = { __typename?: 'Mutation', insert_ContractManagedField_one: { __typename?: 'ContractManagedField', id: string } | null };

export type SetManagedFieldOnContractProductMutationVariables = Types.Exact<{
  contract_product_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnContractProductMutation = { __typename?: 'Mutation', insert_ContractProductManagedField_one: { __typename?: 'ContractProductManagedField', id: string } | null };

export type SetManagedFieldOnRateCardMutationVariables = Types.Exact<{
  rate_card_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnRateCardMutation = { __typename?: 'Mutation', insert_RateCardManagedField_one: { __typename?: 'RateCardManagedField', id: string } | null };

export type SetManagedFieldOnAlertMutationVariables = Types.Exact<{
  alert_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnAlertMutation = { __typename?: 'Mutation', insert_AlertManagedField_one: { __typename?: 'AlertManagedField', id: string } | null };

export type SetManagedFieldOnInvoiceMutationVariables = Types.Exact<{
  invoice_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnInvoiceMutation = { __typename?: 'Mutation', insert_InvoiceManagedField_one: { __typename?: 'InvoiceManagedField', id: string } | null };

export type SetManagedFieldOnContractCreditMutationVariables = Types.Exact<{
  contract_credit_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnContractCreditMutation = { __typename?: 'Mutation', insert_ContractCreditManagedField_one: { __typename?: 'ContractCreditManagedField', id: string } | null };

export type SetManagedFieldOnScheduledChargeMutationVariables = Types.Exact<{
  scheduled_charge_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnScheduledChargeMutation = { __typename?: 'Mutation', insert_ScheduledChargeManagedField_one: { __typename?: 'ScheduledChargeManagedField', id: string } | null };

export type SetManagedFieldOnProServiceMutationVariables = Types.Exact<{
  pro_service_id: Types.Scalars['uuid'];
  key_id: Types.Scalars['uuid'];
  value: Types.Scalars['String'];
}>;


export type SetManagedFieldOnProServiceMutation = { __typename?: 'Mutation', insert_ProServiceManagedField_one: { __typename?: 'ProServiceManagedField', id: string } | null };

export type DeleteManagedFieldOnCustomerMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnCustomerMutation = { __typename?: 'Mutation', delete_CustomerManagedField_by_pk: { __typename?: 'CustomerManagedField', id: string } | null };

export type DeleteManagedFieldOnChargeMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnChargeMutation = { __typename?: 'Mutation', delete_ChargeManagedField_by_pk: { __typename?: 'ChargeManagedField', id: string } | null };

export type DeleteManagedFieldOnProductMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnProductMutation = { __typename?: 'Mutation', delete_ProductManagedField_by_pk: { __typename?: 'ProductManagedField', id: string } | null };

export type DeleteManagedFieldOnPlanMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnPlanMutation = { __typename?: 'Mutation', delete_PlanManagedField_by_pk: { __typename?: 'PlanManagedField', id: string } | null };

export type DeleteManagedFieldOnCustomerPlanMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnCustomerPlanMutation = { __typename?: 'Mutation', delete_CustomerPlanManagedField_by_pk: { __typename?: 'CustomerPlanManagedField', id: string } | null };

export type DeleteManagedFieldOnCreditGrantMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnCreditGrantMutation = { __typename?: 'Mutation', delete_CreditGrantManagedField_by_pk: { __typename?: 'CreditGrantManagedField', id: string } | null };

export type DeleteManagedFieldOnCommitMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnCommitMutation = { __typename?: 'Mutation', delete_CommitManagedField_by_pk: { __typename?: 'CommitManagedField', id: string } | null };

export type DeleteManagedFieldOnContractMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnContractMutation = { __typename?: 'Mutation', delete_ContractManagedField_by_pk: { __typename?: 'ContractManagedField', id: string } | null };

export type DeleteManagedFieldOnContractProductMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnContractProductMutation = { __typename?: 'Mutation', delete_ContractProductManagedField_by_pk: { __typename?: 'ContractProductManagedField', id: string } | null };

export type DeleteManagedFieldOnRateCardMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnRateCardMutation = { __typename?: 'Mutation', delete_RateCardManagedField_by_pk: { __typename?: 'RateCardManagedField', id: string } | null };

export type DeleteManagedFieldOnAlertMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnAlertMutation = { __typename?: 'Mutation', delete_AlertManagedField_by_pk: { __typename?: 'AlertManagedField', id: string } | null };

export type DeleteManagedFieldOnInvoiceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnInvoiceMutation = { __typename?: 'Mutation', delete_InvoiceManagedField_by_pk: { __typename?: 'InvoiceManagedField', id: string } | null };

export type DeleteManagedFieldOnContractCreditMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnContractCreditMutation = { __typename?: 'Mutation', delete_ContractCreditManagedField_by_pk: { __typename?: 'ContractCreditManagedField', id: string } | null };

export type DeleteManagedFieldOnScheduledChargeMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnScheduledChargeMutation = { __typename?: 'Mutation', delete_ScheduledChargeManagedField_by_pk: { __typename?: 'ScheduledChargeManagedField', id: string } | null };

export type DeleteManagedFieldOnProServiceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteManagedFieldOnProServiceMutation = { __typename?: 'Mutation', delete_ProServiceManagedField_by_pk: { __typename?: 'ProServiceManagedField', id: string } | null };


export const GetCustomerCustomFieldsDocument = gql`
    query GetCustomerCustomFields($customer_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: Customer}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    CustomerManagedFields(where: {Customer: {id: {_eq: $customer_id}}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetCustomerCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetCustomerCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerCustomFieldsQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useGetCustomerCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerCustomFieldsQuery, GetCustomerCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerCustomFieldsQuery, GetCustomerCustomFieldsQueryVariables>(GetCustomerCustomFieldsDocument, options);
      }
export function useGetCustomerCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerCustomFieldsQuery, GetCustomerCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerCustomFieldsQuery, GetCustomerCustomFieldsQueryVariables>(GetCustomerCustomFieldsDocument, options);
        }
export type GetCustomerCustomFieldsQueryHookResult = ReturnType<typeof useGetCustomerCustomFieldsQuery>;
export type GetCustomerCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetCustomerCustomFieldsLazyQuery>;
export type GetCustomerCustomFieldsQueryResult = Apollo.QueryResult<GetCustomerCustomFieldsQuery, GetCustomerCustomFieldsQueryVariables>;
export const GetChargeCustomFieldsDocument = gql`
    query GetChargeCustomFields($ppf_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: Charge}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    ChargeManagedFields(where: {ProductPricingFactor: {id: {_eq: $ppf_id}}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetChargeCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetChargeCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeCustomFieldsQuery({
 *   variables: {
 *      ppf_id: // value for 'ppf_id'
 *   },
 * });
 */
export function useGetChargeCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetChargeCustomFieldsQuery, GetChargeCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChargeCustomFieldsQuery, GetChargeCustomFieldsQueryVariables>(GetChargeCustomFieldsDocument, options);
      }
export function useGetChargeCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChargeCustomFieldsQuery, GetChargeCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChargeCustomFieldsQuery, GetChargeCustomFieldsQueryVariables>(GetChargeCustomFieldsDocument, options);
        }
export type GetChargeCustomFieldsQueryHookResult = ReturnType<typeof useGetChargeCustomFieldsQuery>;
export type GetChargeCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetChargeCustomFieldsLazyQuery>;
export type GetChargeCustomFieldsQueryResult = Apollo.QueryResult<GetChargeCustomFieldsQuery, GetChargeCustomFieldsQueryVariables>;
export const GetProductCustomFieldsDocument = gql`
    query GetProductCustomFields($product_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: Product}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    ProductManagedFields(where: {Product: {id: {_eq: $product_id}}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetProductCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetProductCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCustomFieldsQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>(GetProductCustomFieldsDocument, options);
      }
export function useGetProductCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>(GetProductCustomFieldsDocument, options);
        }
export type GetProductCustomFieldsQueryHookResult = ReturnType<typeof useGetProductCustomFieldsQuery>;
export type GetProductCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetProductCustomFieldsLazyQuery>;
export type GetProductCustomFieldsQueryResult = Apollo.QueryResult<GetProductCustomFieldsQuery, GetProductCustomFieldsQueryVariables>;
export const GetPlanCustomFieldsDocument = gql`
    query GetPlanCustomFields($plan_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: Plan}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    PlanManagedFields(where: {Plan: {id: {_eq: $plan_id}}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetPlanCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetPlanCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanCustomFieldsQuery({
 *   variables: {
 *      plan_id: // value for 'plan_id'
 *   },
 * });
 */
export function useGetPlanCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetPlanCustomFieldsQuery, GetPlanCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanCustomFieldsQuery, GetPlanCustomFieldsQueryVariables>(GetPlanCustomFieldsDocument, options);
      }
export function useGetPlanCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanCustomFieldsQuery, GetPlanCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanCustomFieldsQuery, GetPlanCustomFieldsQueryVariables>(GetPlanCustomFieldsDocument, options);
        }
export type GetPlanCustomFieldsQueryHookResult = ReturnType<typeof useGetPlanCustomFieldsQuery>;
export type GetPlanCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetPlanCustomFieldsLazyQuery>;
export type GetPlanCustomFieldsQueryResult = Apollo.QueryResult<GetPlanCustomFieldsQuery, GetPlanCustomFieldsQueryVariables>;
export const GetCreditGrantCustomFieldsDocument = gql`
    query GetCreditGrantCustomFields($credit_grant_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: CreditGrant}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    CreditGrantManagedFields(where: {CreditGrant: {id: {_eq: $credit_grant_id}}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetCreditGrantCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetCreditGrantCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditGrantCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditGrantCustomFieldsQuery({
 *   variables: {
 *      credit_grant_id: // value for 'credit_grant_id'
 *   },
 * });
 */
export function useGetCreditGrantCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetCreditGrantCustomFieldsQuery, GetCreditGrantCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreditGrantCustomFieldsQuery, GetCreditGrantCustomFieldsQueryVariables>(GetCreditGrantCustomFieldsDocument, options);
      }
export function useGetCreditGrantCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreditGrantCustomFieldsQuery, GetCreditGrantCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreditGrantCustomFieldsQuery, GetCreditGrantCustomFieldsQueryVariables>(GetCreditGrantCustomFieldsDocument, options);
        }
export type GetCreditGrantCustomFieldsQueryHookResult = ReturnType<typeof useGetCreditGrantCustomFieldsQuery>;
export type GetCreditGrantCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetCreditGrantCustomFieldsLazyQuery>;
export type GetCreditGrantCustomFieldsQueryResult = Apollo.QueryResult<GetCreditGrantCustomFieldsQuery, GetCreditGrantCustomFieldsQueryVariables>;
export const GetCustomerPlanCustomFieldsDocument = gql`
    query GetCustomerPlanCustomFields($customer_plan_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: CustomerPlan}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    CustomerPlanManagedFields(where: {CustomerPlan: {id: {_eq: $customer_plan_id}}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetCustomerPlanCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetCustomerPlanCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerPlanCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerPlanCustomFieldsQuery({
 *   variables: {
 *      customer_plan_id: // value for 'customer_plan_id'
 *   },
 * });
 */
export function useGetCustomerPlanCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerPlanCustomFieldsQuery, GetCustomerPlanCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerPlanCustomFieldsQuery, GetCustomerPlanCustomFieldsQueryVariables>(GetCustomerPlanCustomFieldsDocument, options);
      }
export function useGetCustomerPlanCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerPlanCustomFieldsQuery, GetCustomerPlanCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerPlanCustomFieldsQuery, GetCustomerPlanCustomFieldsQueryVariables>(GetCustomerPlanCustomFieldsDocument, options);
        }
export type GetCustomerPlanCustomFieldsQueryHookResult = ReturnType<typeof useGetCustomerPlanCustomFieldsQuery>;
export type GetCustomerPlanCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetCustomerPlanCustomFieldsLazyQuery>;
export type GetCustomerPlanCustomFieldsQueryResult = Apollo.QueryResult<GetCustomerPlanCustomFieldsQuery, GetCustomerPlanCustomFieldsQueryVariables>;
export const GetCommitCustomFieldsDocument = gql`
    query GetCommitCustomFields($commit_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: Commit}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    CommitManagedFields(where: {commit_id: {_eq: $commit_id}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetCommitCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetCommitCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommitCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommitCustomFieldsQuery({
 *   variables: {
 *      commit_id: // value for 'commit_id'
 *   },
 * });
 */
export function useGetCommitCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetCommitCustomFieldsQuery, GetCommitCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommitCustomFieldsQuery, GetCommitCustomFieldsQueryVariables>(GetCommitCustomFieldsDocument, options);
      }
export function useGetCommitCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommitCustomFieldsQuery, GetCommitCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommitCustomFieldsQuery, GetCommitCustomFieldsQueryVariables>(GetCommitCustomFieldsDocument, options);
        }
export type GetCommitCustomFieldsQueryHookResult = ReturnType<typeof useGetCommitCustomFieldsQuery>;
export type GetCommitCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetCommitCustomFieldsLazyQuery>;
export type GetCommitCustomFieldsQueryResult = Apollo.QueryResult<GetCommitCustomFieldsQuery, GetCommitCustomFieldsQueryVariables>;
export const GetContractCustomFieldsDocument = gql`
    query GetContractCustomFields($contract_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: Contract}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    ContractManagedFields(where: {contract_id: {_eq: $contract_id}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetContractCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetContractCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCustomFieldsQuery({
 *   variables: {
 *      contract_id: // value for 'contract_id'
 *   },
 * });
 */
export function useGetContractCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetContractCustomFieldsQuery, GetContractCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractCustomFieldsQuery, GetContractCustomFieldsQueryVariables>(GetContractCustomFieldsDocument, options);
      }
export function useGetContractCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractCustomFieldsQuery, GetContractCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractCustomFieldsQuery, GetContractCustomFieldsQueryVariables>(GetContractCustomFieldsDocument, options);
        }
export type GetContractCustomFieldsQueryHookResult = ReturnType<typeof useGetContractCustomFieldsQuery>;
export type GetContractCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetContractCustomFieldsLazyQuery>;
export type GetContractCustomFieldsQueryResult = Apollo.QueryResult<GetContractCustomFieldsQuery, GetContractCustomFieldsQueryVariables>;
export const GetContractProductCustomFieldsDocument = gql`
    query GetContractProductCustomFields($contract_product_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: ContractProduct}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    ContractProductManagedFields(
      where: {contract_product_id: {_eq: $contract_product_id}}
    ) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetContractProductCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetContractProductCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractProductCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractProductCustomFieldsQuery({
 *   variables: {
 *      contract_product_id: // value for 'contract_product_id'
 *   },
 * });
 */
export function useGetContractProductCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetContractProductCustomFieldsQuery, GetContractProductCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractProductCustomFieldsQuery, GetContractProductCustomFieldsQueryVariables>(GetContractProductCustomFieldsDocument, options);
      }
export function useGetContractProductCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractProductCustomFieldsQuery, GetContractProductCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractProductCustomFieldsQuery, GetContractProductCustomFieldsQueryVariables>(GetContractProductCustomFieldsDocument, options);
        }
export type GetContractProductCustomFieldsQueryHookResult = ReturnType<typeof useGetContractProductCustomFieldsQuery>;
export type GetContractProductCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetContractProductCustomFieldsLazyQuery>;
export type GetContractProductCustomFieldsQueryResult = Apollo.QueryResult<GetContractProductCustomFieldsQuery, GetContractProductCustomFieldsQueryVariables>;
export const GetRateCardCustomFieldsDocument = gql`
    query GetRateCardCustomFields($rate_card_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: RateCard}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    RateCardManagedFields(where: {rate_card_id: {_eq: $rate_card_id}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetRateCardCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetRateCardCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateCardCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateCardCustomFieldsQuery({
 *   variables: {
 *      rate_card_id: // value for 'rate_card_id'
 *   },
 * });
 */
export function useGetRateCardCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetRateCardCustomFieldsQuery, GetRateCardCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRateCardCustomFieldsQuery, GetRateCardCustomFieldsQueryVariables>(GetRateCardCustomFieldsDocument, options);
      }
export function useGetRateCardCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRateCardCustomFieldsQuery, GetRateCardCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRateCardCustomFieldsQuery, GetRateCardCustomFieldsQueryVariables>(GetRateCardCustomFieldsDocument, options);
        }
export type GetRateCardCustomFieldsQueryHookResult = ReturnType<typeof useGetRateCardCustomFieldsQuery>;
export type GetRateCardCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetRateCardCustomFieldsLazyQuery>;
export type GetRateCardCustomFieldsQueryResult = Apollo.QueryResult<GetRateCardCustomFieldsQuery, GetRateCardCustomFieldsQueryVariables>;
export const GetAlertCustomFieldsDocument = gql`
    query GetAlertCustomFields($alert_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: Alert}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    AlertManagedFields(where: {Alert: {id: {_eq: $alert_id}}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetAlertCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetAlertCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertCustomFieldsQuery({
 *   variables: {
 *      alert_id: // value for 'alert_id'
 *   },
 * });
 */
export function useGetAlertCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetAlertCustomFieldsQuery, GetAlertCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlertCustomFieldsQuery, GetAlertCustomFieldsQueryVariables>(GetAlertCustomFieldsDocument, options);
      }
export function useGetAlertCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlertCustomFieldsQuery, GetAlertCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlertCustomFieldsQuery, GetAlertCustomFieldsQueryVariables>(GetAlertCustomFieldsDocument, options);
        }
export type GetAlertCustomFieldsQueryHookResult = ReturnType<typeof useGetAlertCustomFieldsQuery>;
export type GetAlertCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetAlertCustomFieldsLazyQuery>;
export type GetAlertCustomFieldsQueryResult = Apollo.QueryResult<GetAlertCustomFieldsQuery, GetAlertCustomFieldsQueryVariables>;
export const GetInvoiceCustomFieldsDocument = gql`
    query GetInvoiceCustomFields($invoice_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: Invoice}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    InvoiceManagedFields(where: {_and: [{invoice_id: {_eq: $invoice_id}}]}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetInvoiceCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceCustomFieldsQuery({
 *   variables: {
 *      invoice_id: // value for 'invoice_id'
 *   },
 * });
 */
export function useGetInvoiceCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceCustomFieldsQuery, GetInvoiceCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceCustomFieldsQuery, GetInvoiceCustomFieldsQueryVariables>(GetInvoiceCustomFieldsDocument, options);
      }
export function useGetInvoiceCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceCustomFieldsQuery, GetInvoiceCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceCustomFieldsQuery, GetInvoiceCustomFieldsQueryVariables>(GetInvoiceCustomFieldsDocument, options);
        }
export type GetInvoiceCustomFieldsQueryHookResult = ReturnType<typeof useGetInvoiceCustomFieldsQuery>;
export type GetInvoiceCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetInvoiceCustomFieldsLazyQuery>;
export type GetInvoiceCustomFieldsQueryResult = Apollo.QueryResult<GetInvoiceCustomFieldsQuery, GetInvoiceCustomFieldsQueryVariables>;
export const GetContractCreditCustomFieldsDocument = gql`
    query GetContractCreditCustomFields($contract_credit_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: ContractCredit}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    ContractCreditManagedFields(
      where: {contract_credit_id: {_eq: $contract_credit_id}}
    ) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetContractCreditCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetContractCreditCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCreditCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCreditCustomFieldsQuery({
 *   variables: {
 *      contract_credit_id: // value for 'contract_credit_id'
 *   },
 * });
 */
export function useGetContractCreditCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetContractCreditCustomFieldsQuery, GetContractCreditCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractCreditCustomFieldsQuery, GetContractCreditCustomFieldsQueryVariables>(GetContractCreditCustomFieldsDocument, options);
      }
export function useGetContractCreditCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractCreditCustomFieldsQuery, GetContractCreditCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractCreditCustomFieldsQuery, GetContractCreditCustomFieldsQueryVariables>(GetContractCreditCustomFieldsDocument, options);
        }
export type GetContractCreditCustomFieldsQueryHookResult = ReturnType<typeof useGetContractCreditCustomFieldsQuery>;
export type GetContractCreditCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetContractCreditCustomFieldsLazyQuery>;
export type GetContractCreditCustomFieldsQueryResult = Apollo.QueryResult<GetContractCreditCustomFieldsQuery, GetContractCreditCustomFieldsQueryVariables>;
export const GetScheduledChargeCustomFieldsDocument = gql`
    query GetScheduledChargeCustomFields($scheduled_charge_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: ScheduledCharge}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    ScheduledChargeManagedFields(
      where: {scheduled_charge_id: {_eq: $scheduled_charge_id}}
    ) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetScheduledChargeCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetScheduledChargeCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledChargeCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledChargeCustomFieldsQuery({
 *   variables: {
 *      scheduled_charge_id: // value for 'scheduled_charge_id'
 *   },
 * });
 */
export function useGetScheduledChargeCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetScheduledChargeCustomFieldsQuery, GetScheduledChargeCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledChargeCustomFieldsQuery, GetScheduledChargeCustomFieldsQueryVariables>(GetScheduledChargeCustomFieldsDocument, options);
      }
export function useGetScheduledChargeCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledChargeCustomFieldsQuery, GetScheduledChargeCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledChargeCustomFieldsQuery, GetScheduledChargeCustomFieldsQueryVariables>(GetScheduledChargeCustomFieldsDocument, options);
        }
export type GetScheduledChargeCustomFieldsQueryHookResult = ReturnType<typeof useGetScheduledChargeCustomFieldsQuery>;
export type GetScheduledChargeCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetScheduledChargeCustomFieldsLazyQuery>;
export type GetScheduledChargeCustomFieldsQueryResult = Apollo.QueryResult<GetScheduledChargeCustomFieldsQuery, GetScheduledChargeCustomFieldsQueryVariables>;
export const GetProServiceCustomFieldsDocument = gql`
    query GetProServiceCustomFields($pro_service_id: uuid!) {
  ManagedFieldKey(
    where: {_and: [{entity: {_eq: ProService}}, {disabled_at: {_is_null: true}}]}
  ) {
    id
    key
    entity
    ProServiceManagedFields(where: {pro_service_id: {_eq: $pro_service_id}}) {
      id
      value
      updated_at
      Updater {
        id
        deprecated_at
        name
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetProServiceCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetProServiceCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProServiceCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProServiceCustomFieldsQuery({
 *   variables: {
 *      pro_service_id: // value for 'pro_service_id'
 *   },
 * });
 */
export function useGetProServiceCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetProServiceCustomFieldsQuery, GetProServiceCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProServiceCustomFieldsQuery, GetProServiceCustomFieldsQueryVariables>(GetProServiceCustomFieldsDocument, options);
      }
export function useGetProServiceCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProServiceCustomFieldsQuery, GetProServiceCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProServiceCustomFieldsQuery, GetProServiceCustomFieldsQueryVariables>(GetProServiceCustomFieldsDocument, options);
        }
export type GetProServiceCustomFieldsQueryHookResult = ReturnType<typeof useGetProServiceCustomFieldsQuery>;
export type GetProServiceCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetProServiceCustomFieldsLazyQuery>;
export type GetProServiceCustomFieldsQueryResult = Apollo.QueryResult<GetProServiceCustomFieldsQuery, GetProServiceCustomFieldsQueryVariables>;
export const SetManagedFieldOnCustomerDocument = gql`
    mutation SetManagedFieldOnCustomer($customer_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_CustomerManagedField_one(
    object: {customer_id: $customer_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: CustomerManagedField_customer_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnCustomerMutationFn = Apollo.MutationFunction<SetManagedFieldOnCustomerMutation, SetManagedFieldOnCustomerMutationVariables>;

/**
 * __useSetManagedFieldOnCustomerMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnCustomerMutation, { data, loading, error }] = useSetManagedFieldOnCustomerMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnCustomerMutation, SetManagedFieldOnCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnCustomerMutation, SetManagedFieldOnCustomerMutationVariables>(SetManagedFieldOnCustomerDocument, options);
      }
export type SetManagedFieldOnCustomerMutationHookResult = ReturnType<typeof useSetManagedFieldOnCustomerMutation>;
export type SetManagedFieldOnCustomerMutationResult = Apollo.MutationResult<SetManagedFieldOnCustomerMutation>;
export type SetManagedFieldOnCustomerMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnCustomerMutation, SetManagedFieldOnCustomerMutationVariables>;
export const SetManagedFieldOnChargeDocument = gql`
    mutation SetManagedFieldOnCharge($ppf_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_ChargeManagedField_one(
    object: {product_pricing_factor_id: $ppf_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: ChargeManagedField_product_pricing_factor_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnChargeMutationFn = Apollo.MutationFunction<SetManagedFieldOnChargeMutation, SetManagedFieldOnChargeMutationVariables>;

/**
 * __useSetManagedFieldOnChargeMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnChargeMutation, { data, loading, error }] = useSetManagedFieldOnChargeMutation({
 *   variables: {
 *      ppf_id: // value for 'ppf_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnChargeMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnChargeMutation, SetManagedFieldOnChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnChargeMutation, SetManagedFieldOnChargeMutationVariables>(SetManagedFieldOnChargeDocument, options);
      }
export type SetManagedFieldOnChargeMutationHookResult = ReturnType<typeof useSetManagedFieldOnChargeMutation>;
export type SetManagedFieldOnChargeMutationResult = Apollo.MutationResult<SetManagedFieldOnChargeMutation>;
export type SetManagedFieldOnChargeMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnChargeMutation, SetManagedFieldOnChargeMutationVariables>;
export const SetManagedFieldOnProductDocument = gql`
    mutation SetManagedFieldOnProduct($product_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_ProductManagedField_one(
    object: {product_id: $product_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: ProductManagedField_product_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnProductMutationFn = Apollo.MutationFunction<SetManagedFieldOnProductMutation, SetManagedFieldOnProductMutationVariables>;

/**
 * __useSetManagedFieldOnProductMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnProductMutation, { data, loading, error }] = useSetManagedFieldOnProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnProductMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnProductMutation, SetManagedFieldOnProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnProductMutation, SetManagedFieldOnProductMutationVariables>(SetManagedFieldOnProductDocument, options);
      }
export type SetManagedFieldOnProductMutationHookResult = ReturnType<typeof useSetManagedFieldOnProductMutation>;
export type SetManagedFieldOnProductMutationResult = Apollo.MutationResult<SetManagedFieldOnProductMutation>;
export type SetManagedFieldOnProductMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnProductMutation, SetManagedFieldOnProductMutationVariables>;
export const SetManagedFieldOnPlanDocument = gql`
    mutation SetManagedFieldOnPlan($plan_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_PlanManagedField_one(
    object: {plan_id: $plan_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: PlanManagedField_plan_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnPlanMutationFn = Apollo.MutationFunction<SetManagedFieldOnPlanMutation, SetManagedFieldOnPlanMutationVariables>;

/**
 * __useSetManagedFieldOnPlanMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnPlanMutation, { data, loading, error }] = useSetManagedFieldOnPlanMutation({
 *   variables: {
 *      plan_id: // value for 'plan_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnPlanMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnPlanMutation, SetManagedFieldOnPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnPlanMutation, SetManagedFieldOnPlanMutationVariables>(SetManagedFieldOnPlanDocument, options);
      }
export type SetManagedFieldOnPlanMutationHookResult = ReturnType<typeof useSetManagedFieldOnPlanMutation>;
export type SetManagedFieldOnPlanMutationResult = Apollo.MutationResult<SetManagedFieldOnPlanMutation>;
export type SetManagedFieldOnPlanMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnPlanMutation, SetManagedFieldOnPlanMutationVariables>;
export const SetManagedFieldOnCustomerPlanDocument = gql`
    mutation SetManagedFieldOnCustomerPlan($customer_plan_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_CustomerPlanManagedField_one(
    object: {customer_plan_id: $customer_plan_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: CustomerPlanManagedField_customer_plan_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnCustomerPlanMutationFn = Apollo.MutationFunction<SetManagedFieldOnCustomerPlanMutation, SetManagedFieldOnCustomerPlanMutationVariables>;

/**
 * __useSetManagedFieldOnCustomerPlanMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnCustomerPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnCustomerPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnCustomerPlanMutation, { data, loading, error }] = useSetManagedFieldOnCustomerPlanMutation({
 *   variables: {
 *      customer_plan_id: // value for 'customer_plan_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnCustomerPlanMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnCustomerPlanMutation, SetManagedFieldOnCustomerPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnCustomerPlanMutation, SetManagedFieldOnCustomerPlanMutationVariables>(SetManagedFieldOnCustomerPlanDocument, options);
      }
export type SetManagedFieldOnCustomerPlanMutationHookResult = ReturnType<typeof useSetManagedFieldOnCustomerPlanMutation>;
export type SetManagedFieldOnCustomerPlanMutationResult = Apollo.MutationResult<SetManagedFieldOnCustomerPlanMutation>;
export type SetManagedFieldOnCustomerPlanMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnCustomerPlanMutation, SetManagedFieldOnCustomerPlanMutationVariables>;
export const SetManagedFieldOnCreditGrantDocument = gql`
    mutation SetManagedFieldOnCreditGrant($credit_grant_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_CreditGrantManagedField_one(
    object: {credit_grant_id: $credit_grant_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: CreditGrantManagedField_credit_grant_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnCreditGrantMutationFn = Apollo.MutationFunction<SetManagedFieldOnCreditGrantMutation, SetManagedFieldOnCreditGrantMutationVariables>;

/**
 * __useSetManagedFieldOnCreditGrantMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnCreditGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnCreditGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnCreditGrantMutation, { data, loading, error }] = useSetManagedFieldOnCreditGrantMutation({
 *   variables: {
 *      credit_grant_id: // value for 'credit_grant_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnCreditGrantMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnCreditGrantMutation, SetManagedFieldOnCreditGrantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnCreditGrantMutation, SetManagedFieldOnCreditGrantMutationVariables>(SetManagedFieldOnCreditGrantDocument, options);
      }
export type SetManagedFieldOnCreditGrantMutationHookResult = ReturnType<typeof useSetManagedFieldOnCreditGrantMutation>;
export type SetManagedFieldOnCreditGrantMutationResult = Apollo.MutationResult<SetManagedFieldOnCreditGrantMutation>;
export type SetManagedFieldOnCreditGrantMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnCreditGrantMutation, SetManagedFieldOnCreditGrantMutationVariables>;
export const SetManagedFieldOnCommitDocument = gql`
    mutation SetManagedFieldOnCommit($commit_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_CommitManagedField_one(
    object: {commit_id: $commit_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: CommitManagedField_commit_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnCommitMutationFn = Apollo.MutationFunction<SetManagedFieldOnCommitMutation, SetManagedFieldOnCommitMutationVariables>;

/**
 * __useSetManagedFieldOnCommitMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnCommitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnCommitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnCommitMutation, { data, loading, error }] = useSetManagedFieldOnCommitMutation({
 *   variables: {
 *      commit_id: // value for 'commit_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnCommitMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnCommitMutation, SetManagedFieldOnCommitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnCommitMutation, SetManagedFieldOnCommitMutationVariables>(SetManagedFieldOnCommitDocument, options);
      }
export type SetManagedFieldOnCommitMutationHookResult = ReturnType<typeof useSetManagedFieldOnCommitMutation>;
export type SetManagedFieldOnCommitMutationResult = Apollo.MutationResult<SetManagedFieldOnCommitMutation>;
export type SetManagedFieldOnCommitMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnCommitMutation, SetManagedFieldOnCommitMutationVariables>;
export const SetManagedFieldOnContractDocument = gql`
    mutation SetManagedFieldOnContract($contract_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_ContractManagedField_one(
    object: {contract_id: $contract_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: ContractManagedField_contract_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnContractMutationFn = Apollo.MutationFunction<SetManagedFieldOnContractMutation, SetManagedFieldOnContractMutationVariables>;

/**
 * __useSetManagedFieldOnContractMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnContractMutation, { data, loading, error }] = useSetManagedFieldOnContractMutation({
 *   variables: {
 *      contract_id: // value for 'contract_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnContractMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnContractMutation, SetManagedFieldOnContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnContractMutation, SetManagedFieldOnContractMutationVariables>(SetManagedFieldOnContractDocument, options);
      }
export type SetManagedFieldOnContractMutationHookResult = ReturnType<typeof useSetManagedFieldOnContractMutation>;
export type SetManagedFieldOnContractMutationResult = Apollo.MutationResult<SetManagedFieldOnContractMutation>;
export type SetManagedFieldOnContractMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnContractMutation, SetManagedFieldOnContractMutationVariables>;
export const SetManagedFieldOnContractProductDocument = gql`
    mutation SetManagedFieldOnContractProduct($contract_product_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_ContractProductManagedField_one(
    object: {contract_product_id: $contract_product_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: ContractProductManagedField_contract_product_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnContractProductMutationFn = Apollo.MutationFunction<SetManagedFieldOnContractProductMutation, SetManagedFieldOnContractProductMutationVariables>;

/**
 * __useSetManagedFieldOnContractProductMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnContractProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnContractProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnContractProductMutation, { data, loading, error }] = useSetManagedFieldOnContractProductMutation({
 *   variables: {
 *      contract_product_id: // value for 'contract_product_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnContractProductMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnContractProductMutation, SetManagedFieldOnContractProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnContractProductMutation, SetManagedFieldOnContractProductMutationVariables>(SetManagedFieldOnContractProductDocument, options);
      }
export type SetManagedFieldOnContractProductMutationHookResult = ReturnType<typeof useSetManagedFieldOnContractProductMutation>;
export type SetManagedFieldOnContractProductMutationResult = Apollo.MutationResult<SetManagedFieldOnContractProductMutation>;
export type SetManagedFieldOnContractProductMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnContractProductMutation, SetManagedFieldOnContractProductMutationVariables>;
export const SetManagedFieldOnRateCardDocument = gql`
    mutation SetManagedFieldOnRateCard($rate_card_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_RateCardManagedField_one(
    object: {rate_card_id: $rate_card_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: RateCardManagedField_rate_card_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnRateCardMutationFn = Apollo.MutationFunction<SetManagedFieldOnRateCardMutation, SetManagedFieldOnRateCardMutationVariables>;

/**
 * __useSetManagedFieldOnRateCardMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnRateCardMutation, { data, loading, error }] = useSetManagedFieldOnRateCardMutation({
 *   variables: {
 *      rate_card_id: // value for 'rate_card_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnRateCardMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnRateCardMutation, SetManagedFieldOnRateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnRateCardMutation, SetManagedFieldOnRateCardMutationVariables>(SetManagedFieldOnRateCardDocument, options);
      }
export type SetManagedFieldOnRateCardMutationHookResult = ReturnType<typeof useSetManagedFieldOnRateCardMutation>;
export type SetManagedFieldOnRateCardMutationResult = Apollo.MutationResult<SetManagedFieldOnRateCardMutation>;
export type SetManagedFieldOnRateCardMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnRateCardMutation, SetManagedFieldOnRateCardMutationVariables>;
export const SetManagedFieldOnAlertDocument = gql`
    mutation SetManagedFieldOnAlert($alert_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_AlertManagedField_one(
    object: {alert_id: $alert_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: AlertManagedField_alert_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnAlertMutationFn = Apollo.MutationFunction<SetManagedFieldOnAlertMutation, SetManagedFieldOnAlertMutationVariables>;

/**
 * __useSetManagedFieldOnAlertMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnAlertMutation, { data, loading, error }] = useSetManagedFieldOnAlertMutation({
 *   variables: {
 *      alert_id: // value for 'alert_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnAlertMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnAlertMutation, SetManagedFieldOnAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnAlertMutation, SetManagedFieldOnAlertMutationVariables>(SetManagedFieldOnAlertDocument, options);
      }
export type SetManagedFieldOnAlertMutationHookResult = ReturnType<typeof useSetManagedFieldOnAlertMutation>;
export type SetManagedFieldOnAlertMutationResult = Apollo.MutationResult<SetManagedFieldOnAlertMutation>;
export type SetManagedFieldOnAlertMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnAlertMutation, SetManagedFieldOnAlertMutationVariables>;
export const SetManagedFieldOnInvoiceDocument = gql`
    mutation SetManagedFieldOnInvoice($invoice_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_InvoiceManagedField_one(
    object: {invoice_id: $invoice_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: InvoiceManagedField_invoice_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnInvoiceMutationFn = Apollo.MutationFunction<SetManagedFieldOnInvoiceMutation, SetManagedFieldOnInvoiceMutationVariables>;

/**
 * __useSetManagedFieldOnInvoiceMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnInvoiceMutation, { data, loading, error }] = useSetManagedFieldOnInvoiceMutation({
 *   variables: {
 *      invoice_id: // value for 'invoice_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnInvoiceMutation, SetManagedFieldOnInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnInvoiceMutation, SetManagedFieldOnInvoiceMutationVariables>(SetManagedFieldOnInvoiceDocument, options);
      }
export type SetManagedFieldOnInvoiceMutationHookResult = ReturnType<typeof useSetManagedFieldOnInvoiceMutation>;
export type SetManagedFieldOnInvoiceMutationResult = Apollo.MutationResult<SetManagedFieldOnInvoiceMutation>;
export type SetManagedFieldOnInvoiceMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnInvoiceMutation, SetManagedFieldOnInvoiceMutationVariables>;
export const SetManagedFieldOnContractCreditDocument = gql`
    mutation SetManagedFieldOnContractCredit($contract_credit_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_ContractCreditManagedField_one(
    object: {contract_credit_id: $contract_credit_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: ContractCreditManagedField_contract_credit_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnContractCreditMutationFn = Apollo.MutationFunction<SetManagedFieldOnContractCreditMutation, SetManagedFieldOnContractCreditMutationVariables>;

/**
 * __useSetManagedFieldOnContractCreditMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnContractCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnContractCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnContractCreditMutation, { data, loading, error }] = useSetManagedFieldOnContractCreditMutation({
 *   variables: {
 *      contract_credit_id: // value for 'contract_credit_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnContractCreditMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnContractCreditMutation, SetManagedFieldOnContractCreditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnContractCreditMutation, SetManagedFieldOnContractCreditMutationVariables>(SetManagedFieldOnContractCreditDocument, options);
      }
export type SetManagedFieldOnContractCreditMutationHookResult = ReturnType<typeof useSetManagedFieldOnContractCreditMutation>;
export type SetManagedFieldOnContractCreditMutationResult = Apollo.MutationResult<SetManagedFieldOnContractCreditMutation>;
export type SetManagedFieldOnContractCreditMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnContractCreditMutation, SetManagedFieldOnContractCreditMutationVariables>;
export const SetManagedFieldOnScheduledChargeDocument = gql`
    mutation SetManagedFieldOnScheduledCharge($scheduled_charge_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_ScheduledChargeManagedField_one(
    object: {scheduled_charge_id: $scheduled_charge_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: ScheduledChargeManagedField_scheduled_charge_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnScheduledChargeMutationFn = Apollo.MutationFunction<SetManagedFieldOnScheduledChargeMutation, SetManagedFieldOnScheduledChargeMutationVariables>;

/**
 * __useSetManagedFieldOnScheduledChargeMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnScheduledChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnScheduledChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnScheduledChargeMutation, { data, loading, error }] = useSetManagedFieldOnScheduledChargeMutation({
 *   variables: {
 *      scheduled_charge_id: // value for 'scheduled_charge_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnScheduledChargeMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnScheduledChargeMutation, SetManagedFieldOnScheduledChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnScheduledChargeMutation, SetManagedFieldOnScheduledChargeMutationVariables>(SetManagedFieldOnScheduledChargeDocument, options);
      }
export type SetManagedFieldOnScheduledChargeMutationHookResult = ReturnType<typeof useSetManagedFieldOnScheduledChargeMutation>;
export type SetManagedFieldOnScheduledChargeMutationResult = Apollo.MutationResult<SetManagedFieldOnScheduledChargeMutation>;
export type SetManagedFieldOnScheduledChargeMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnScheduledChargeMutation, SetManagedFieldOnScheduledChargeMutationVariables>;
export const SetManagedFieldOnProServiceDocument = gql`
    mutation SetManagedFieldOnProService($pro_service_id: uuid!, $key_id: uuid!, $value: String!) {
  insert_ProServiceManagedField_one(
    object: {pro_service_id: $pro_service_id, key_id: $key_id, value: $value}
    on_conflict: {constraint: ProServiceManagedField_pro_service_id_key_id_key, update_columns: [value]}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type SetManagedFieldOnProServiceMutationFn = Apollo.MutationFunction<SetManagedFieldOnProServiceMutation, SetManagedFieldOnProServiceMutationVariables>;

/**
 * __useSetManagedFieldOnProServiceMutation__
 *
 * To run a mutation, you first call `useSetManagedFieldOnProServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagedFieldOnProServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagedFieldOnProServiceMutation, { data, loading, error }] = useSetManagedFieldOnProServiceMutation({
 *   variables: {
 *      pro_service_id: // value for 'pro_service_id'
 *      key_id: // value for 'key_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetManagedFieldOnProServiceMutation(baseOptions?: Apollo.MutationHookOptions<SetManagedFieldOnProServiceMutation, SetManagedFieldOnProServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManagedFieldOnProServiceMutation, SetManagedFieldOnProServiceMutationVariables>(SetManagedFieldOnProServiceDocument, options);
      }
export type SetManagedFieldOnProServiceMutationHookResult = ReturnType<typeof useSetManagedFieldOnProServiceMutation>;
export type SetManagedFieldOnProServiceMutationResult = Apollo.MutationResult<SetManagedFieldOnProServiceMutation>;
export type SetManagedFieldOnProServiceMutationOptions = Apollo.BaseMutationOptions<SetManagedFieldOnProServiceMutation, SetManagedFieldOnProServiceMutationVariables>;
export const DeleteManagedFieldOnCustomerDocument = gql`
    mutation DeleteManagedFieldOnCustomer($id: uuid!) {
  delete_CustomerManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnCustomerMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnCustomerMutation, DeleteManagedFieldOnCustomerMutationVariables>;

/**
 * __useDeleteManagedFieldOnCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnCustomerMutation, { data, loading, error }] = useDeleteManagedFieldOnCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnCustomerMutation, DeleteManagedFieldOnCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnCustomerMutation, DeleteManagedFieldOnCustomerMutationVariables>(DeleteManagedFieldOnCustomerDocument, options);
      }
export type DeleteManagedFieldOnCustomerMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnCustomerMutation>;
export type DeleteManagedFieldOnCustomerMutationResult = Apollo.MutationResult<DeleteManagedFieldOnCustomerMutation>;
export type DeleteManagedFieldOnCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnCustomerMutation, DeleteManagedFieldOnCustomerMutationVariables>;
export const DeleteManagedFieldOnChargeDocument = gql`
    mutation DeleteManagedFieldOnCharge($id: uuid!) {
  delete_ChargeManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnChargeMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnChargeMutation, DeleteManagedFieldOnChargeMutationVariables>;

/**
 * __useDeleteManagedFieldOnChargeMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnChargeMutation, { data, loading, error }] = useDeleteManagedFieldOnChargeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnChargeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnChargeMutation, DeleteManagedFieldOnChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnChargeMutation, DeleteManagedFieldOnChargeMutationVariables>(DeleteManagedFieldOnChargeDocument, options);
      }
export type DeleteManagedFieldOnChargeMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnChargeMutation>;
export type DeleteManagedFieldOnChargeMutationResult = Apollo.MutationResult<DeleteManagedFieldOnChargeMutation>;
export type DeleteManagedFieldOnChargeMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnChargeMutation, DeleteManagedFieldOnChargeMutationVariables>;
export const DeleteManagedFieldOnProductDocument = gql`
    mutation DeleteManagedFieldOnProduct($id: uuid!) {
  delete_ProductManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnProductMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnProductMutation, DeleteManagedFieldOnProductMutationVariables>;

/**
 * __useDeleteManagedFieldOnProductMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnProductMutation, { data, loading, error }] = useDeleteManagedFieldOnProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnProductMutation, DeleteManagedFieldOnProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnProductMutation, DeleteManagedFieldOnProductMutationVariables>(DeleteManagedFieldOnProductDocument, options);
      }
export type DeleteManagedFieldOnProductMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnProductMutation>;
export type DeleteManagedFieldOnProductMutationResult = Apollo.MutationResult<DeleteManagedFieldOnProductMutation>;
export type DeleteManagedFieldOnProductMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnProductMutation, DeleteManagedFieldOnProductMutationVariables>;
export const DeleteManagedFieldOnPlanDocument = gql`
    mutation DeleteManagedFieldOnPlan($id: uuid!) {
  delete_PlanManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnPlanMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnPlanMutation, DeleteManagedFieldOnPlanMutationVariables>;

/**
 * __useDeleteManagedFieldOnPlanMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnPlanMutation, { data, loading, error }] = useDeleteManagedFieldOnPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnPlanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnPlanMutation, DeleteManagedFieldOnPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnPlanMutation, DeleteManagedFieldOnPlanMutationVariables>(DeleteManagedFieldOnPlanDocument, options);
      }
export type DeleteManagedFieldOnPlanMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnPlanMutation>;
export type DeleteManagedFieldOnPlanMutationResult = Apollo.MutationResult<DeleteManagedFieldOnPlanMutation>;
export type DeleteManagedFieldOnPlanMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnPlanMutation, DeleteManagedFieldOnPlanMutationVariables>;
export const DeleteManagedFieldOnCustomerPlanDocument = gql`
    mutation DeleteManagedFieldOnCustomerPlan($id: uuid!) {
  delete_CustomerPlanManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnCustomerPlanMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnCustomerPlanMutation, DeleteManagedFieldOnCustomerPlanMutationVariables>;

/**
 * __useDeleteManagedFieldOnCustomerPlanMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnCustomerPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnCustomerPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnCustomerPlanMutation, { data, loading, error }] = useDeleteManagedFieldOnCustomerPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnCustomerPlanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnCustomerPlanMutation, DeleteManagedFieldOnCustomerPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnCustomerPlanMutation, DeleteManagedFieldOnCustomerPlanMutationVariables>(DeleteManagedFieldOnCustomerPlanDocument, options);
      }
export type DeleteManagedFieldOnCustomerPlanMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnCustomerPlanMutation>;
export type DeleteManagedFieldOnCustomerPlanMutationResult = Apollo.MutationResult<DeleteManagedFieldOnCustomerPlanMutation>;
export type DeleteManagedFieldOnCustomerPlanMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnCustomerPlanMutation, DeleteManagedFieldOnCustomerPlanMutationVariables>;
export const DeleteManagedFieldOnCreditGrantDocument = gql`
    mutation DeleteManagedFieldOnCreditGrant($id: uuid!) {
  delete_CreditGrantManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnCreditGrantMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnCreditGrantMutation, DeleteManagedFieldOnCreditGrantMutationVariables>;

/**
 * __useDeleteManagedFieldOnCreditGrantMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnCreditGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnCreditGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnCreditGrantMutation, { data, loading, error }] = useDeleteManagedFieldOnCreditGrantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnCreditGrantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnCreditGrantMutation, DeleteManagedFieldOnCreditGrantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnCreditGrantMutation, DeleteManagedFieldOnCreditGrantMutationVariables>(DeleteManagedFieldOnCreditGrantDocument, options);
      }
export type DeleteManagedFieldOnCreditGrantMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnCreditGrantMutation>;
export type DeleteManagedFieldOnCreditGrantMutationResult = Apollo.MutationResult<DeleteManagedFieldOnCreditGrantMutation>;
export type DeleteManagedFieldOnCreditGrantMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnCreditGrantMutation, DeleteManagedFieldOnCreditGrantMutationVariables>;
export const DeleteManagedFieldOnCommitDocument = gql`
    mutation DeleteManagedFieldOnCommit($id: uuid!) {
  delete_CommitManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnCommitMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnCommitMutation, DeleteManagedFieldOnCommitMutationVariables>;

/**
 * __useDeleteManagedFieldOnCommitMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnCommitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnCommitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnCommitMutation, { data, loading, error }] = useDeleteManagedFieldOnCommitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnCommitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnCommitMutation, DeleteManagedFieldOnCommitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnCommitMutation, DeleteManagedFieldOnCommitMutationVariables>(DeleteManagedFieldOnCommitDocument, options);
      }
export type DeleteManagedFieldOnCommitMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnCommitMutation>;
export type DeleteManagedFieldOnCommitMutationResult = Apollo.MutationResult<DeleteManagedFieldOnCommitMutation>;
export type DeleteManagedFieldOnCommitMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnCommitMutation, DeleteManagedFieldOnCommitMutationVariables>;
export const DeleteManagedFieldOnContractDocument = gql`
    mutation DeleteManagedFieldOnContract($id: uuid!) {
  delete_ContractManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnContractMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnContractMutation, DeleteManagedFieldOnContractMutationVariables>;

/**
 * __useDeleteManagedFieldOnContractMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnContractMutation, { data, loading, error }] = useDeleteManagedFieldOnContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnContractMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnContractMutation, DeleteManagedFieldOnContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnContractMutation, DeleteManagedFieldOnContractMutationVariables>(DeleteManagedFieldOnContractDocument, options);
      }
export type DeleteManagedFieldOnContractMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnContractMutation>;
export type DeleteManagedFieldOnContractMutationResult = Apollo.MutationResult<DeleteManagedFieldOnContractMutation>;
export type DeleteManagedFieldOnContractMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnContractMutation, DeleteManagedFieldOnContractMutationVariables>;
export const DeleteManagedFieldOnContractProductDocument = gql`
    mutation DeleteManagedFieldOnContractProduct($id: uuid!) {
  delete_ContractProductManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnContractProductMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnContractProductMutation, DeleteManagedFieldOnContractProductMutationVariables>;

/**
 * __useDeleteManagedFieldOnContractProductMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnContractProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnContractProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnContractProductMutation, { data, loading, error }] = useDeleteManagedFieldOnContractProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnContractProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnContractProductMutation, DeleteManagedFieldOnContractProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnContractProductMutation, DeleteManagedFieldOnContractProductMutationVariables>(DeleteManagedFieldOnContractProductDocument, options);
      }
export type DeleteManagedFieldOnContractProductMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnContractProductMutation>;
export type DeleteManagedFieldOnContractProductMutationResult = Apollo.MutationResult<DeleteManagedFieldOnContractProductMutation>;
export type DeleteManagedFieldOnContractProductMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnContractProductMutation, DeleteManagedFieldOnContractProductMutationVariables>;
export const DeleteManagedFieldOnRateCardDocument = gql`
    mutation DeleteManagedFieldOnRateCard($id: uuid!) {
  delete_RateCardManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnRateCardMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnRateCardMutation, DeleteManagedFieldOnRateCardMutationVariables>;

/**
 * __useDeleteManagedFieldOnRateCardMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnRateCardMutation, { data, loading, error }] = useDeleteManagedFieldOnRateCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnRateCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnRateCardMutation, DeleteManagedFieldOnRateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnRateCardMutation, DeleteManagedFieldOnRateCardMutationVariables>(DeleteManagedFieldOnRateCardDocument, options);
      }
export type DeleteManagedFieldOnRateCardMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnRateCardMutation>;
export type DeleteManagedFieldOnRateCardMutationResult = Apollo.MutationResult<DeleteManagedFieldOnRateCardMutation>;
export type DeleteManagedFieldOnRateCardMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnRateCardMutation, DeleteManagedFieldOnRateCardMutationVariables>;
export const DeleteManagedFieldOnAlertDocument = gql`
    mutation DeleteManagedFieldOnAlert($id: uuid!) {
  delete_AlertManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnAlertMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnAlertMutation, DeleteManagedFieldOnAlertMutationVariables>;

/**
 * __useDeleteManagedFieldOnAlertMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnAlertMutation, { data, loading, error }] = useDeleteManagedFieldOnAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnAlertMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnAlertMutation, DeleteManagedFieldOnAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnAlertMutation, DeleteManagedFieldOnAlertMutationVariables>(DeleteManagedFieldOnAlertDocument, options);
      }
export type DeleteManagedFieldOnAlertMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnAlertMutation>;
export type DeleteManagedFieldOnAlertMutationResult = Apollo.MutationResult<DeleteManagedFieldOnAlertMutation>;
export type DeleteManagedFieldOnAlertMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnAlertMutation, DeleteManagedFieldOnAlertMutationVariables>;
export const DeleteManagedFieldOnInvoiceDocument = gql`
    mutation DeleteManagedFieldOnInvoice($id: uuid!) {
  delete_InvoiceManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnInvoiceMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnInvoiceMutation, DeleteManagedFieldOnInvoiceMutationVariables>;

/**
 * __useDeleteManagedFieldOnInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnInvoiceMutation, { data, loading, error }] = useDeleteManagedFieldOnInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnInvoiceMutation, DeleteManagedFieldOnInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnInvoiceMutation, DeleteManagedFieldOnInvoiceMutationVariables>(DeleteManagedFieldOnInvoiceDocument, options);
      }
export type DeleteManagedFieldOnInvoiceMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnInvoiceMutation>;
export type DeleteManagedFieldOnInvoiceMutationResult = Apollo.MutationResult<DeleteManagedFieldOnInvoiceMutation>;
export type DeleteManagedFieldOnInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnInvoiceMutation, DeleteManagedFieldOnInvoiceMutationVariables>;
export const DeleteManagedFieldOnContractCreditDocument = gql`
    mutation DeleteManagedFieldOnContractCredit($id: uuid!) {
  delete_ContractCreditManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnContractCreditMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnContractCreditMutation, DeleteManagedFieldOnContractCreditMutationVariables>;

/**
 * __useDeleteManagedFieldOnContractCreditMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnContractCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnContractCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnContractCreditMutation, { data, loading, error }] = useDeleteManagedFieldOnContractCreditMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnContractCreditMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnContractCreditMutation, DeleteManagedFieldOnContractCreditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnContractCreditMutation, DeleteManagedFieldOnContractCreditMutationVariables>(DeleteManagedFieldOnContractCreditDocument, options);
      }
export type DeleteManagedFieldOnContractCreditMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnContractCreditMutation>;
export type DeleteManagedFieldOnContractCreditMutationResult = Apollo.MutationResult<DeleteManagedFieldOnContractCreditMutation>;
export type DeleteManagedFieldOnContractCreditMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnContractCreditMutation, DeleteManagedFieldOnContractCreditMutationVariables>;
export const DeleteManagedFieldOnScheduledChargeDocument = gql`
    mutation DeleteManagedFieldOnScheduledCharge($id: uuid!) {
  delete_ScheduledChargeManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnScheduledChargeMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnScheduledChargeMutation, DeleteManagedFieldOnScheduledChargeMutationVariables>;

/**
 * __useDeleteManagedFieldOnScheduledChargeMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnScheduledChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnScheduledChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnScheduledChargeMutation, { data, loading, error }] = useDeleteManagedFieldOnScheduledChargeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnScheduledChargeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnScheduledChargeMutation, DeleteManagedFieldOnScheduledChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnScheduledChargeMutation, DeleteManagedFieldOnScheduledChargeMutationVariables>(DeleteManagedFieldOnScheduledChargeDocument, options);
      }
export type DeleteManagedFieldOnScheduledChargeMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnScheduledChargeMutation>;
export type DeleteManagedFieldOnScheduledChargeMutationResult = Apollo.MutationResult<DeleteManagedFieldOnScheduledChargeMutation>;
export type DeleteManagedFieldOnScheduledChargeMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnScheduledChargeMutation, DeleteManagedFieldOnScheduledChargeMutationVariables>;
export const DeleteManagedFieldOnProServiceDocument = gql`
    mutation DeleteManagedFieldOnProService($id: uuid!) {
  delete_ProServiceManagedField_by_pk(id: $id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteManagedFieldOnProServiceMutationFn = Apollo.MutationFunction<DeleteManagedFieldOnProServiceMutation, DeleteManagedFieldOnProServiceMutationVariables>;

/**
 * __useDeleteManagedFieldOnProServiceMutation__
 *
 * To run a mutation, you first call `useDeleteManagedFieldOnProServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagedFieldOnProServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagedFieldOnProServiceMutation, { data, loading, error }] = useDeleteManagedFieldOnProServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManagedFieldOnProServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagedFieldOnProServiceMutation, DeleteManagedFieldOnProServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagedFieldOnProServiceMutation, DeleteManagedFieldOnProServiceMutationVariables>(DeleteManagedFieldOnProServiceDocument, options);
      }
export type DeleteManagedFieldOnProServiceMutationHookResult = ReturnType<typeof useDeleteManagedFieldOnProServiceMutation>;
export type DeleteManagedFieldOnProServiceMutationResult = Apollo.MutationResult<DeleteManagedFieldOnProServiceMutation>;
export type DeleteManagedFieldOnProServiceMutationOptions = Apollo.BaseMutationOptions<DeleteManagedFieldOnProServiceMutation, DeleteManagedFieldOnProServiceMutationVariables>;