import React, { useState } from "react";

import { FullScreenPage } from "components/PageContainer";
import { PlanActiveAndFutureCustomersQueryResult } from "../../data/queries.graphql";
import { inputSafeStr } from "./input";
import { useFeatureFlag } from "lib/launchdarkly";
import { usePlanActiveCustomerPlanDataQuery } from "../../data/queries.graphql";

type ActiveAndFutureCustomers = PlanActiveAndFutureCustomersQueryResult["data"];

type EditInterstitialProps = {
  onClose: () => void;
  onContinue: () => void;
  planName: string;
  planId: string;
  activeAndFutureCustomers: ActiveAndFutureCustomers;
};

import styles from "./index.module.less";
import { Input, Body, Headline, Icon } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { WizardFooter } from "components/Wizard";
import pluralize from "pluralize";
import { MetronomeLogo } from "tenaissance/components/MetronomeLogo";
import { InternalLink } from "components/Typography";

type ConfirmTextProps = {
  confirmTextBody: React.ReactNode;
  confirmTextValue: string;
  setContinueButtonEnabled: (value: boolean) => void;
};

const ConfirmText: React.FC<ConfirmTextProps> = ({
  confirmTextBody,
  confirmTextValue,
  setContinueButtonEnabled,
}) => {
  const [confirmationInput, setConfirmationInput] = useState("");

  function onConfirmationInputChange(value: string) {
    setConfirmationInput(value);
    if (value === confirmTextValue) {
      setContinueButtonEnabled(true);
    } else {
      setContinueButtonEnabled(false);
    }
  }

  return (
    <>
      <hr className={styles.divider} />
      <Body className={styles.bodyText}>
        {confirmTextBody}
        <br />
        <br />
        Are you sure you want to make edits? If so, enter{" "}
        <b>{confirmTextValue} </b>
        below:
      </Body>
      <Input
        placeholder={`${confirmTextValue}`}
        value={confirmationInput}
        onChange={onConfirmationInputChange}
      />
    </>
  );
};

type ConfirmTextSingleCustomerProps = {
  numCurrent: number;
  numFuture: number;
  numMarketplace: number;
  customPricingWarning: React.ReactNode;
  setContinueButtonEnabled: (value: boolean) => void;
  planId: string;
};

const ConfirmTextSingleCustomer: React.FC<ConfirmTextSingleCustomerProps> = ({
  numCurrent,
  numFuture,
  numMarketplace,
  customPricingWarning,
  setContinueButtonEnabled,
  planId,
}) => {
  const largePlanEdits: string[] | undefined = useFeatureFlag(
    "large-plan-edit-ids",
    [],
  );
  const skipLoadingCustomerData = !!largePlanEdits?.includes(planId);
  const { data: singleCustomerData, loading: singleCustomerLoading } =
    usePlanActiveCustomerPlanDataQuery({
      variables: {
        plan_id: planId,
        offset: 0,
        limit: 1,
      },
      skip: skipLoadingCustomerData,
    });

  if (singleCustomerLoading) {
    return null;
  }

  const singleCustomer = singleCustomerData?.CustomerPlan?.[0];
  const singleCustomerName = singleCustomer
    ? inputSafeStr(singleCustomer.Customer.name)
    : undefined;

  let confirmTextBody: React.ReactElement | undefined;
  let confirmTextValue: string | undefined;
  if (numCurrent === 1 && numMarketplace === 1 && singleCustomer) {
    confirmTextBody = (
      <>
        Currently, <b>{singleCustomerName}</b> is on this plan. This customer is
        billed through the AWS Marketplace, which means they're billed each hour
        and have already been billed for some usage this billing period. If you
        edit this plan and this customer's invoice total decreases, you may need
        to issue a manual refund for the excess already billed. Contact a
        Metronome representative if you have questions.
        {customPricingWarning}
      </>
    );
    confirmTextValue = singleCustomerName;
  } else if (numCurrent === 1 && numFuture === 0 && singleCustomer) {
    confirmTextBody = (
      <>
        Currently, <b>{singleCustomerName}</b> is on this plan. Any edits will
        be reflected in their current billing cycle.
        {customPricingWarning}
      </>
    );
    confirmTextValue = singleCustomerName;
  } else if (numCurrent === 0 && numFuture === 1 && singleCustomer) {
    confirmTextBody = (
      <>
        Currently, <b>{singleCustomerName}</b> is scheduled to start on this
        plan. Any edits will be reflected in their first billing cycle.
        {customPricingWarning}
      </>
    );
    confirmTextValue = singleCustomerName;
  }

  if (confirmTextBody && confirmTextValue) {
    return (
      <ConfirmText
        confirmTextBody={confirmTextBody}
        confirmTextValue={confirmTextValue}
        setContinueButtonEnabled={setContinueButtonEnabled}
      />
    );
  } else {
    setContinueButtonEnabled(true);
    return null;
  }
};

type ConfirmTextWrapperProps = {
  activeAndFutureCustomers: ActiveAndFutureCustomers;
  setContinueButtonEnabled: (value: boolean) => void;
  planId: string;
};

const ConfirmTextWrapper: React.FC<ConfirmTextWrapperProps> = ({
  activeAndFutureCustomers,
  setContinueButtonEnabled,
  planId,
}) => {
  const numCurrent =
    activeAndFutureCustomers?.Plan_by_pk?.active_customer_count ?? 0;
  const numFuture =
    activeAndFutureCustomers?.Plan_by_pk?.future_customer_count ?? 0;
  const numMarketplace =
    activeAndFutureCustomers?.current_marketplace_customers?.aggregate?.count ??
    0;
  const numCustomPricing =
    activeAndFutureCustomers?.custom_pricing_on_plan?.aggregate?.count ?? 0;

  if (numCurrent + numFuture === 0) {
    // don't return confirm text if there are no customers
    setContinueButtonEnabled(true);
    return null;
  }

  const customPricingWarning =
    numCustomPricing > 0 ? (
      <>
        {" "}
        <b>
          {pluralize("customer", numCustomPricing, true)} with price adjustments
        </b>{" "}
        {pluralize("is", numCustomPricing)} on this plan. Customers with price
        overrides will not have updated pricing.
      </>
    ) : (
      ""
    );

  const isSingleCustomer = numCurrent + numFuture === 1;
  if (isSingleCustomer) {
    return (
      <ConfirmTextSingleCustomer
        numCurrent={numCurrent}
        numFuture={numFuture}
        numMarketplace={numMarketplace}
        setContinueButtonEnabled={setContinueButtonEnabled}
        customPricingWarning={customPricingWarning}
        planId={planId}
      />
    );
  }

  const currentCustomerString = pluralize("current customer", numCurrent, true);
  const futureCustomerString = pluralize("future customer", numFuture, true);
  const marketplaceWarning =
    numMarketplace > 0 ? (
      <>
        <b>{pluralize("current customer", numMarketplace, true)}</b>{" "}
        {pluralize("is", numMarketplace)} being billed through the AWS
        Marketplace, which means they're billed each hour and have already been
        billed for some usage this billing period. If you edit this plan and
        such a customer's invoice total decreases, you may need to issue a
        manual refund for the excess already billed. Contact a Metronome
        representative if you have questions.
      </>
    ) : (
      ""
    );

  const confirmTextBody =
    numFuture === 0 ? (
      <>
        There are <b>{currentCustomerString}</b> on this plan. Any edits will be
        reflected in their current billing cycle.
        {marketplaceWarning}
        {customPricingWarning}
      </>
    ) : numCurrent === 0 ? (
      <>
        There are <b>{futureCustomerString}</b> scheduled to start on this plan.
        Any edits will be reflected in their first billing cycle.
        {marketplaceWarning}
        {customPricingWarning}
      </>
    ) : (
      <>
        There are{" "}
        <b>
          {currentCustomerString} and {futureCustomerString}
        </b>{" "}
        on this plan. For customers currently on this plan, any edits will be
        reflected in their current billing cycle. {marketplaceWarning}
        {customPricingWarning}
      </>
    );
  const confirmTextValue = pluralize("customer", numCurrent + numFuture, true);

  return (
    <ConfirmText
      confirmTextBody={confirmTextBody}
      confirmTextValue={confirmTextValue}
      setContinueButtonEnabled={setContinueButtonEnabled}
    />
  );
};

export const EditInterstitial: React.FC<EditInterstitialProps> = (props) => {
  const [continueButtonEnabled, setContinueButtonEnabled] = useState(false);

  return (
    <FullScreenPage title="New plan">
      <div className={styles.container}>
        <InternalLink routePath="/" className={styles.logo}>
          <MetronomeLogo theme="greyscale" badgeOnly />
        </InternalLink>
        <IconButton
          onClick={props.onClose}
          className={styles.closeButton}
          theme="secondary"
          icon="xClose"
        />
        <div className={styles.icon}>
          <Icon icon="receipt" />
        </div>
        <Headline level={4}>Plan editing</Headline>
        <Body className={styles.bodyText}>
          Edits to a plan will modify the pricing and charges for all associated
          customers on the plan. When finalizing the change, you'll review your
          edits and their customer impact.
        </Body>
        <ConfirmTextWrapper
          activeAndFutureCustomers={props.activeAndFutureCustomers}
          setContinueButtonEnabled={setContinueButtonEnabled}
          planId={props.planId}
        />
      </div>
      <WizardFooter
        onClickBack={props.onClose}
        backLabel="Cancel"
        onClickContinue={props.onContinue}
        continueLabel="Continue"
        continueDisabled={!continueButtonEnabled}
      />
    </FullScreenPage>
  );
};
