import React from "react";
import styles from "./index.module.less";
import { BillingDayOfPeriod } from "lib/plans/types";
import { Body, Caption, Headline, Subtitle, Themes } from "design-system";
import { Button } from "tenaissance/components/Button";
import { addYears, addSeconds } from "date-fns";
import {
  getUtcEndDate,
  getUtcStartOfDay,
  renderDate,
  renderDateRange,
} from "lib/time";
import {
  getAnnualServicePeriodDates,
  getMonthlyServicePeriodDates,
  getQuarterlyServicePeriodDates,
  getSemiAnnualServicePeriodDates,
  getSemiMonthlyServicePeriodDates,
  ServicePeriodDates,
} from "./billingDates";
import classNames from "classnames";
import { Statistic, Statistics } from "components/Statistic";
import { billingFrequencyFriendlyName } from "lib/plans/planPeriods";
import { BillingFrequencyEnum_Enum } from "types/generated-graphql/__types__";

interface Ramp {
  rampStart: number;
  temp: boolean;
}

const Blank: React.FC = () => <div className={styles.blank} />;

export const InvoiceIllustration: React.FC<{
  theme?: Themes;
  faded?: boolean;
}> = ({ theme, faded }) => {
  return (
    <svg
      viewBox="0 0 105 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        styles.InvoiceIllustration,
        theme && styles[theme],
        { [styles.faded]: faded },
      )}
    >
      <path
        d="M0 135V9C0 6.79086 1.79086 5 4 5H82.8993L98.9963 20.1412V135C98.9963 137.209 97.2054 139 94.9963 139H4C1.79086 139 0 137.209 0 135Z"
        className={styles.background}
      />
      <path
        d="M4 131V4C4 1.79086 5.79086 0 7.99999 0H87.7367L103.996 15.2542V131C103.996 133.209 102.205 135 99.9962 135H8C5.79086 135 4 133.209 4 131Z"
        className={styles.foreground}
      />
      <path
        d="M87.69 15.2729L104 15.2729L87.74 0L87.69 15.2729Z"
        className={styles.background}
      />
    </svg>
  );
};

const InvoiceSkeleton: React.FC = () => (
  <svg
    width="55"
    height="74"
    viewBox="0 0 55 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b)">
      <path
        d="M1 70.6146V3.38599C1 2.21656 1.94801 1.26855 3.11744 1.26855H45.3052L53.9081 9.34352V70.6146C53.9081 71.784 52.9601 72.732 51.7907 72.732H3.11744C1.94801 72.732 1 71.784 1 70.6146Z"
        fill="#F4F5F7"
      />
      <path
        d="M1 70.6146V3.38599C1 2.21656 1.94801 1.26855 3.11744 1.26855H45.3052L53.9081 9.34352V70.6146C53.9081 71.784 52.9601 72.732 51.7907 72.732H3.11744C1.94801 72.732 1 71.784 1 70.6146Z"
        stroke="#F4F5F7"
      />
    </g>
    <defs>
      <filter
        id="filter0_b"
        x="-3.5"
        y="-3.23145"
        width="61.9081"
        height="80.4635"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

type ExampleServicePeriodsProps = {
  startDate: Date;
  endDate: Date;
  trialLength?: number;
  billingFrequency: BillingFrequencyEnum_Enum;
  billingAnchor: BillingDayOfPeriod;
  ramps?: Ramp[];
  truncateLastPeriod: boolean;
  onClick?: (periodIndex: number) => void;
  onMouseEnter?: (periodIndex: number) => void;
  onMouseLeave?: (periodIndex: number) => void;
};

const THEME_ORDER: Themes[] = [
  "primary",
  "success",
  "warning",
  "error",
  "grey",
];

const ExampleServicePeriods: React.FC<ExampleServicePeriodsProps> = (props) => {
  let servicePeriods: ServicePeriodDates[];
  switch (props.billingFrequency) {
    case BillingFrequencyEnum_Enum.SemiMonthly:
      servicePeriods = getSemiMonthlyServicePeriodDates(
        props.startDate,
        props.endDate,
        props.truncateLastPeriod,
        props.trialLength,
      );
      break;
    case BillingFrequencyEnum_Enum.Quarterly:
      servicePeriods = getQuarterlyServicePeriodDates(
        props.startDate,
        props.endDate,
        props.billingAnchor === "FIRST_OF_MONTH",
        props.truncateLastPeriod,
        props.trialLength,
      );
      break;
    case BillingFrequencyEnum_Enum.Annual:
      servicePeriods = getAnnualServicePeriodDates(
        props.startDate,
        props.endDate,
        props.truncateLastPeriod,
        props.trialLength,
      );
      break;
    case BillingFrequencyEnum_Enum.SemiAnnual:
      servicePeriods = getSemiAnnualServicePeriodDates(
        props.startDate,
        props.endDate,
        props.truncateLastPeriod,
        props.trialLength,
      );
      break;
    default:
      // MONTHLY
      servicePeriods = getMonthlyServicePeriodDates(
        props.startDate,
        props.endDate,
        props.billingAnchor === "FIRST_OF_MONTH",
        props.truncateLastPeriod,
        props.trialLength,
      );
  }

  return (
    <div className={styles.ExampleServicePeriods}>
      {servicePeriods.map((sp, i) => {
        const servicePeriodIndex = props.trialLength ? i - 1 : i;
        const isTrial = servicePeriodIndex === -1;
        const rampIndex = isTrial
          ? undefined
          : props.ramps?.findIndex(
              (ramp) => ramp.rampStart > servicePeriodIndex,
            );

        return (
          <div
            className={styles.invoice}
            key={i}
            onClick={() =>
              !isTrial && props.onClick && props.onClick(servicePeriodIndex)
            }
            onMouseEnter={() =>
              !isTrial &&
              props.onMouseEnter &&
              props.onMouseEnter(servicePeriodIndex)
            }
            onMouseLeave={() =>
              !isTrial &&
              props.onMouseLeave &&
              props.onMouseLeave(servicePeriodIndex)
            }
          >
            <InvoiceIllustration
              theme={
                isTrial
                  ? "success"
                  : rampIndex === undefined
                    ? undefined
                    : THEME_ORDER[(rampIndex - 1) % THEME_ORDER.length]
              }
              faded={
                isTrial ||
                !!(
                  props.ramps &&
                  rampIndex &&
                  rampIndex !== -1 &&
                  props.ramps[rampIndex].temp
                )
              }
            />
            <div className={styles.content}>
              <Subtitle>Invoice {i + 1}</Subtitle>
              <Body level={2}>
                {renderDateRange(
                  sp.start_date,
                  addSeconds(sp.end_date, -1),
                  { isUtc: true, excludeUtcLabel: true, excludeYear: true },
                  false,
                )}
              </Body>
              {rampIndex !== undefined && rampIndex !== -1 && (
                <Body className={styles.pricingRamp} level={2}>
                  Pricing ramp: {rampIndex}
                </Body>
              )}
              {isTrial && (
                <Body className={styles.trialInvoice} level={2}>
                  Trial period
                </Body>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface PlanLifecycleProps {
  startDate?: Date;
  endDate?: Date;
  trialLength?: number;
  perpetual?: boolean;
  billingFrequency?: BillingFrequencyEnum_Enum;
  billingAnchor?: BillingDayOfPeriod;
  ramps?: Ramp[];
  draft: boolean;
  onClick?: (periodIndex: number) => void;
  onMouseEnter?: (periodIndex: number) => void;
  onMouseLeave?: (periodIndex: number) => void;
  onResetRamps?: () => void;
}

const PlanLifecycle: React.FC<PlanLifecycleProps> = (props) => {
  let content = (
    <div className={styles.invoiceSkeletons}>
      {Array.from(Array(12)).map((i, j) => (
        <div className={styles.invoiceSkeleton} key={j}>
          <InvoiceSkeleton />
        </div>
      ))}
    </div>
  );
  if (props.startDate && (props.endDate || props.perpetual)) {
    const startDate = getUtcStartOfDay(props.startDate);
    const endDate = getUtcEndDate(
      props.endDate ?? addYears(props.startDate, 1),
    );
    content = (
      <>
        <Body>
          {props.perpetual ? (
            `Plan start date of ${renderDate(startDate, {
              isUtc: true,
            })} (displaying example one year length)`
          ) : props.draft ? (
            <>
              Example plan for the dates&nbsp;
              {renderDateRange(startDate, endDate, { isUtc: true }, false)}
            </>
          ) : null}
        </Body>
        {props.billingFrequency && props.billingAnchor ? (
          <ExampleServicePeriods
            startDate={startDate}
            endDate={endDate}
            trialLength={props.trialLength}
            billingFrequency={props.billingFrequency}
            billingAnchor={props.billingAnchor}
            ramps={props.ramps}
            truncateLastPeriod={!props.perpetual}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
          />
        ) : (
          <Body level={2} className={styles.emptyState}>
            Add your plan’s billing frequency
            <br />
            and billing day of month to see a preview.
          </Body>
        )}
      </>
    );
  }

  return (
    <div className={styles.planLifecycle}>
      <div className={styles.heading}>
        <Caption>
          {props.draft ? "Example plan lifecycle" : "Plan lifecycle"}
        </Caption>
        {props.onResetRamps && (
          <Button
            onClick={props.onResetRamps}
            text="Reset"
            theme="linkGray"
            leadingIcon="refreshCw01"
          />
        )}
      </div>
      {content}
    </div>
  );
};

type TermPreviewProps = {
  planName: string;
  planDescription: string;
  hasCustomer: boolean;
  customerName: string | undefined;
  startDate?: Date;
  endDate?: Date | null;
  trialLength?: number;
  billingFrequency?: BillingFrequencyEnum_Enum;
  billingAnchor?: BillingDayOfPeriod;
  ramps?: Ramp[];
  draft: boolean;
  onClick?: (periodIndex: number) => void;
  onMouseEnter?: (periodIndex: number) => void;
  onMouseLeave?: (periodIndex: number) => void;
  onResetRamps?: () => void;
};

export const TermPreview: React.FC<TermPreviewProps> = (props) => {
  return (
    <div className={styles.termPreview}>
      <div className={styles.header}>
        <Headline level={6}>Plan overview</Headline>
      </div>
      <div className={styles.planDetails}>
        <div className={styles.planName}>
          <Subtitle level={1}>{props.planName}</Subtitle>
        </div>
        <div className={styles.planContent}>
          <Body level={2}>{props.planDescription}</Body>
          <div className={styles.planStatistics}>
            <Statistics>
              <Statistic caption="Customer">
                {props.hasCustomer ? (
                  props.customerName ? (
                    <Subtitle level={4}>{props.customerName}</Subtitle>
                  ) : (
                    <Blank />
                  )
                ) : (
                  <Subtitle level={4}>No customer</Subtitle>
                )}
              </Statistic>
              <Statistic caption="Plan Length">
                {props.startDate && props.endDate ? (
                  <Subtitle level={4}>
                    {renderDateRange(
                      props.startDate,
                      props.endDate,
                      { isUtc: true },
                      false,
                    )}
                  </Subtitle>
                ) : props.startDate && props.endDate === null ? (
                  <Subtitle level={4}>
                    {renderDate(props.startDate, { isUtc: true })} - Perpetual
                  </Subtitle>
                ) : (
                  <Blank />
                )}
              </Statistic>
              <Statistic caption="Billing Frequency">
                {props.billingFrequency && props.billingAnchor ? (
                  <Subtitle level={4}>
                    {billingFrequencyFriendlyName(
                      props.billingFrequency,
                      props.billingAnchor,
                    )}
                  </Subtitle>
                ) : (
                  <Blank />
                )}
              </Statistic>
            </Statistics>
          </div>
          <PlanLifecycle
            startDate={props.startDate}
            trialLength={props.trialLength}
            draft={props.draft}
            billingFrequency={props.billingFrequency}
            billingAnchor={props.billingAnchor}
            ramps={props.ramps}
            perpetual={props.endDate === null}
            endDate={props.endDate ?? undefined}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onResetRamps={props.onResetRamps}
          />
        </div>
      </div>
    </div>
  );
};
