import React from "react";

export type DefaultTimeframe = {
  startingAt: string | undefined;
  endingBefore: string | undefined;
};

export namespace DefaultTimeframe {
  const Context = React.createContext<DefaultTimeframe | null>(null);

  export interface ProviderProps extends React.PropsWithChildren {
    startingAt?: string | null;
    endingBefore?: string | null;
  }

  export const Provider: React.FC<ProviderProps> = (props) => {
    const timeframe = React.useMemo(
      (): DefaultTimeframe => ({
        startingAt: props.startingAt ?? undefined,
        endingBefore: props.endingBefore ?? undefined,
      }),
      [props.startingAt, props.endingBefore],
    );

    return (
      <Context.Provider value={timeframe}>{props.children}</Context.Provider>
    );
  };

  export function useFromContext() {
    const ctx = React.useContext(Context);
    if (!ctx) {
      throw new Error("unable to get contract timeframe from context");
    }
    return ctx;
  }
}
