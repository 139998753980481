/* 918dc1a4163ea721500e0d010c2e1f733011187c
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewAlertDataQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type NewAlertDataQuery = { __typename?: 'Query', Client: Array<{ __typename?: 'Client', id: string, customer_count: { __typename?: 'CustomerCount', count: number, estimated: boolean } }>, Webhook: Array<{ __typename?: 'Webhook', id: string, url: string }> };

export type CustomerPlanSearchQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  filter: Types.Scalars['String'];
}>;


export type CustomerPlanSearchQuery = { __typename?: 'Query', Customer: Array<{ __typename?: 'Customer', id: string, name: string }>, Plan: Array<{ __typename?: 'Plan', id: string, name: string, customer_count: number }> };

export type BillableMetricSearchQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  filter: Types.Scalars['String'];
}>;


export type BillableMetricSearchQuery = { __typename?: 'Query', BillableMetric: Array<{ __typename?: 'BillableMetric', name: string, id: string, environment_type: Types.EnvironmentTypeEnum_Enum }> };

export type CreateAlertMutationVariables = Types.Exact<{
  object: Types.Alert_Insert_Input;
}>;


export type CreateAlertMutation = { __typename?: 'Mutation', insert_Alert_one: { __typename?: 'Alert', id: string } | null };

export type GetCustomFieldKeysQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomFieldKeysQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, key: string, entity: Types.ManagedEntityEnum_Enum }> };


export const NewAlertDataDocument = gql`
    query NewAlertData($environment_type: EnvironmentTypeEnum_enum!) {
  Client {
    id
    customer_count(environment_type: $environment_type) {
      count
      estimated
    }
  }
  Webhook(where: {environment_type: {_eq: $environment_type}}) {
    id
    url
    __environment_type: environment_type
  }
}
    `;

/**
 * __useNewAlertDataQuery__
 *
 * To run a query within a React component, call `useNewAlertDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewAlertDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewAlertDataQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useNewAlertDataQuery(baseOptions: Apollo.QueryHookOptions<NewAlertDataQuery, NewAlertDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewAlertDataQuery, NewAlertDataQueryVariables>(NewAlertDataDocument, options);
      }
export function useNewAlertDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewAlertDataQuery, NewAlertDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewAlertDataQuery, NewAlertDataQueryVariables>(NewAlertDataDocument, options);
        }
export type NewAlertDataQueryHookResult = ReturnType<typeof useNewAlertDataQuery>;
export type NewAlertDataLazyQueryHookResult = ReturnType<typeof useNewAlertDataLazyQuery>;
export type NewAlertDataQueryResult = Apollo.QueryResult<NewAlertDataQuery, NewAlertDataQueryVariables>;
export const CustomerPlanSearchDocument = gql`
    query CustomerPlanSearch($environment_type: EnvironmentTypeEnum_enum!, $filter: String!) {
  Customer(
    where: {name: {_ilike: $filter}, environment_type: {_eq: $environment_type}}
    limit: 25
  ) {
    id
    name
    __environment_type: environment_type
  }
  Plan(
    where: {name: {_ilike: $filter}, environment_type: {_eq: $environment_type}, deprecated_at: {_is_null: true}}
    limit: 25
  ) {
    id
    name
    customer_count(status: ACTIVE)
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCustomerPlanSearchQuery__
 *
 * To run a query within a React component, call `useCustomerPlanSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPlanSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPlanSearchQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomerPlanSearchQuery(baseOptions: Apollo.QueryHookOptions<CustomerPlanSearchQuery, CustomerPlanSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerPlanSearchQuery, CustomerPlanSearchQueryVariables>(CustomerPlanSearchDocument, options);
      }
export function useCustomerPlanSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerPlanSearchQuery, CustomerPlanSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerPlanSearchQuery, CustomerPlanSearchQueryVariables>(CustomerPlanSearchDocument, options);
        }
export type CustomerPlanSearchQueryHookResult = ReturnType<typeof useCustomerPlanSearchQuery>;
export type CustomerPlanSearchLazyQueryHookResult = ReturnType<typeof useCustomerPlanSearchLazyQuery>;
export type CustomerPlanSearchQueryResult = Apollo.QueryResult<CustomerPlanSearchQuery, CustomerPlanSearchQueryVariables>;
export const BillableMetricSearchDocument = gql`
    query BillableMetricSearch($environment_type: EnvironmentTypeEnum_enum!, $filter: String!) {
  BillableMetric(
    where: {name: {_ilike: $filter}, environment_type: {_eq: $environment_type}, deleted_at: {_is_null: true}, is_draft: {_eq: false}}
    limit: 25
  ) {
    name
    id
    environment_type
    __environment_type: environment_type
  }
}
    `;

/**
 * __useBillableMetricSearchQuery__
 *
 * To run a query within a React component, call `useBillableMetricSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableMetricSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableMetricSearchQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBillableMetricSearchQuery(baseOptions: Apollo.QueryHookOptions<BillableMetricSearchQuery, BillableMetricSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillableMetricSearchQuery, BillableMetricSearchQueryVariables>(BillableMetricSearchDocument, options);
      }
export function useBillableMetricSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillableMetricSearchQuery, BillableMetricSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillableMetricSearchQuery, BillableMetricSearchQueryVariables>(BillableMetricSearchDocument, options);
        }
export type BillableMetricSearchQueryHookResult = ReturnType<typeof useBillableMetricSearchQuery>;
export type BillableMetricSearchLazyQueryHookResult = ReturnType<typeof useBillableMetricSearchLazyQuery>;
export type BillableMetricSearchQueryResult = Apollo.QueryResult<BillableMetricSearchQuery, BillableMetricSearchQueryVariables>;
export const CreateAlertDocument = gql`
    mutation CreateAlert($object: Alert_insert_input!) {
  insert_Alert_one(object: $object) {
    id
    __environment_type: environment_type
  }
}
    `;
export type CreateAlertMutationFn = Apollo.MutationFunction<CreateAlertMutation, CreateAlertMutationVariables>;

/**
 * __useCreateAlertMutation__
 *
 * To run a mutation, you first call `useCreateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertMutation, { data, loading, error }] = useCreateAlertMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateAlertMutation(baseOptions?: Apollo.MutationHookOptions<CreateAlertMutation, CreateAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAlertMutation, CreateAlertMutationVariables>(CreateAlertDocument, options);
      }
export type CreateAlertMutationHookResult = ReturnType<typeof useCreateAlertMutation>;
export type CreateAlertMutationResult = Apollo.MutationResult<CreateAlertMutation>;
export type CreateAlertMutationOptions = Apollo.BaseMutationOptions<CreateAlertMutation, CreateAlertMutationVariables>;
export const GetCustomFieldKeysDocument = gql`
    query GetCustomFieldKeys {
  ManagedFieldKey(where: {is_active: {_eq: true}}) {
    id
    key
    entity
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetCustomFieldKeysQuery__
 *
 * To run a query within a React component, call `useGetCustomFieldKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFieldKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFieldKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomFieldKeysQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>(GetCustomFieldKeysDocument, options);
      }
export function useGetCustomFieldKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>(GetCustomFieldKeysDocument, options);
        }
export type GetCustomFieldKeysQueryHookResult = ReturnType<typeof useGetCustomFieldKeysQuery>;
export type GetCustomFieldKeysLazyQueryHookResult = ReturnType<typeof useGetCustomFieldKeysLazyQuery>;
export type GetCustomFieldKeysQueryResult = Apollo.QueryResult<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>;