import { useCallback, useEffect, useMemo, useState } from "react";
import { Schema } from "pages/Contracts/Pricing/Schema";
import { FormController } from "lib/FormController";
import { useGetAllCreditTypesQuery } from "pages/Contracts/Pricing/CreateAndEditRateCard/data.graphql";
import { filterAndSortCreditTypes } from "pages/Contracts/lib/CreditTypes";
import { createContainer } from "unstated-next";
import { useProductsQuery } from "./data.graphql";
import { USD_CREDIT_ID, USD_CREDIT_TYPE } from "lib/credits";
import { FiatCreditType } from "types/credit-types";
import {
  categorizeProducts,
  getDefaultRates,
  ProductListItem,
} from "./contextUtils";
import { deepEqual } from "fast-equals";
import { useRateCardQuery } from "pages/Contracts/Pricing/RateCardsDetails/data.graphql";

type DimensionalProduct =
  Schema.Types.UnifiedRateCardInput["dimensionalProducts"][0];
/**
 * We will only be using the form controller for validation and snapshotting.
 */
const useRateCardV2Controller = FormController.createHook(
  Schema.UnifiedRateCardInput,
  {
    init({
      existingRateCard,
      snapshotKey,
    }: {
      existingRateCard: Partial<Schema.Types.UnifiedRateCardInput>;
      snapshotKey: string;
    }) {
      const snapshot = FormController.parseJsonSnapshot(
        Schema.UnifiedRateCardInput,
        sessionStorage.getItem(snapshotKey),
      );

      if (snapshot && !snapshot?.fiatCreditTypeId) {
        snapshot.fiatCreditTypeId = USD_CREDIT_TYPE.id;
      }

      if (!existingRateCard) {
        return snapshot;
      }
      return { ...existingRateCard, ...snapshot };
    },
  },
);

export type RateProductEnum =
  | "usageRates"
  | "subscriptionRates"
  | "compositeRates";

type InitialState = {
  rateCardId?: string;
};

function useRateCardContext({ rateCardId }: InitialState = {}) {
  const isEdit = !!rateCardId;
  const { data, loading: loadingCreditTypes } = useGetAllCreditTypesQuery();
  const { fiatCreditTypes, customCreditTypes } = useMemo(
    () => filterAndSortCreditTypes(data?.CreditType ?? []),
    [data?.CreditType],
  );
  const {
    data: products,
    loading: loadingProducts,
    error: productDataError,
  } = useProductsQuery();

  /**
   * Only contains products which show up on the rate card form.
   * For now, this removes professional services and fix products.
   */
  const filteredProductsByType = useMemo(() => {
    if (products) {
      const filteredProducts = products.contract_pricing.products.filter(
        (product) => {
          return (
            product.__typename === "UsageProductListItem" ||
            product.__typename === "SubscriptionProductListItem" ||
            product.__typename === "CompositeProductListItem"
          );
        },
      );
      return filteredProducts;
    } else {
      return [];
    }
  }, [products]);

  const snapshotKey = isEdit
    ? `rate-card-edit-v2-${rateCardId}`
    : "rate-card-create-v2";

  const rateCardReq = useRateCardQuery({
    variables: { id: rateCardId ?? "" },
    skip: !isEdit,
  });

  /**
   * This will be used to store the snapshot of the form and do final validation.
   */
  const ctrl = useRateCardV2Controller({
    existingRateCard: {},
    snapshotKey,
  });

  useEffect(() => {
    if (isEdit && !rateCardReq.loading) {
      const snapshot = FormController.parseJsonSnapshot(
        Schema.UnifiedRateCardInput,
        sessionStorage.getItem(snapshotKey),
      );
      const rateCard = rateCardReq.data?.contract_pricing.rate_card;

      const creditTypeConversions =
        rateCard?.credit_type_conversions?.map((c) => ({
          custom_credit_type_id: c.custom_credit_type.id,
          custom_credit_type_name: c.custom_credit_type.name,
          fiat_per_custom_credit:
            rateCard?.fiat_credit_type.id === USD_CREDIT_ID
              ? Number(c.fiat_per_custom_credit) / 100.0
              : Number(c.fiat_per_custom_credit),
        })) ?? [];

      ctrl.update({
        ...snapshot,
        name: rateCard?.name,
        description: rateCard?.description ?? undefined,
        aliases: [],
        creditTypeConversions,
        fiatCreditTypeId: rateCard?.fiat_credit_type.id,
        fiatCreditTypeName: rateCard?.fiat_credit_type.name,
      });

      setName(rateCard?.name ?? "");
      setCreditTypeConversions(creditTypeConversions);
      if (rateCard?.fiat_credit_type.id && rateCard?.fiat_credit_type.name) {
        setFiatCreditType({
          id: rateCard?.fiat_credit_type.id,
          name: rateCard?.fiat_credit_type.name,
          client_id: null,
          environment_type: null,
        });
      }
    }
  }, [rateCardReq]);

  // save snapshot on every change of the form
  useEffect(() => {
    sessionStorage.setItem(snapshotKey, JSON.stringify(ctrl.snapshot()));
  }, [ctrl]);

  function clearSnapshot() {
    sessionStorage.removeItem(snapshotKey);
  }

  const productsMap = useMemo(() => {
    const data = new Map<string, ProductListItem>();
    return (
      Array.from(filteredProductsByType.values()).reduce((acc, product) => {
        acc.set(product.id, product);
        return acc;
      }, data) ?? data
    );
  }, [filteredProductsByType]);

  const [creditTypeConversions, setCreditTypeConversions] = useState<
    Schema.Types.CreditTypeConversion[]
  >(ctrl.get("creditTypeConversions") ?? []);

  const conversionRateChange = useCallback(
    (conversion: Schema.Types.CreditTypeConversion) => {
      setCreditTypeConversions((conversions) => {
        const conversionIndex = conversions.findIndex(
          (c) => c.custom_credit_type_id === conversion.custom_credit_type_id,
        );
        if (conversionIndex >= 0) {
          return [
            ...conversions.slice(0, conversionIndex),
            conversion,
            ...conversions.slice(conversionIndex + 1),
          ];
        } else {
          return [...conversions, conversion];
        }
      });
    },
    [setCreditTypeConversions],
  );

  useEffect(() => {
    ctrl.update({ creditTypeConversions });
  }, [creditTypeConversions]);

  const [selectedProducts, setSelectedProductsInternal] = useState<string[]>(
    ctrl.get("selectedProducts") ?? [],
  );
  const [dimensionalProducts, setDimensionalProducts] = useState<
    DimensionalProduct[]
  >(ctrl.get("dimensionalProducts") ?? []);

  const [fiatCreditType, setFiatCreditType] =
    useState<FiatCreditType>(USD_CREDIT_TYPE);

  // update fiat credit type if changed and not a custom credit type
  useEffect(() => {
    // only update if it has changed though
    const currentFiatCreditType = ctrl.get("fiatCreditTypeId");
    if (currentFiatCreditType !== fiatCreditType.id) {
      const usageRates = rates.usageRates.map((rate) => ({
        ...rate,
        creditType: fiatCreditType,
      }));

      const subscriptionRates = rates.subscriptionRates.map((rate) => ({
        ...rate,
        creditType: fiatCreditType,
      }));

      const compositeRates = rates.compositeRates.map((rate) => ({
        ...rate,
        creditType: fiatCreditType,
      }));

      setRates({
        usageRates,
        subscriptionRates,
        compositeRates,
      });

      ctrl.update({
        fiatCreditTypeId: fiatCreditType.id,
        usageRates,
        subscriptionRates,
        compositeRates,
        creditTypeConversions: [],
      });
    }
  }, [fiatCreditType]);

  const setRateStartingAtDate = useCallback(
    (type: RateProductEnum, date?: Date) => {
      if (date) {
        setRates((rateTypes) => {
          return {
            ...rateTypes,
            [type]: rateTypes[type].map((rate) => {
              return {
                ...rate,
                startingAt: date.toISOString(),
              };
            }),
          };
        });

        ctrl.update({
          [type]: ctrl.get(type)?.map((rate) => {
            return {
              ...rate,
              startingAt: date?.toISOString() ?? undefined,
            };
          }),
        });
      }
    },
    [],
  );

  const setRateEndingBeforeDate = useCallback(
    (type: RateProductEnum, date?: Date) => {
      setRates((rateTypes) => {
        return {
          ...rateTypes,
          [type]: rateTypes[type].map((rate) => {
            return {
              ...rate,
              endingBefore: date?.toISOString(),
            };
          }),
        };
      });

      ctrl.update({
        [type]: ctrl.get(type)?.map((rate) => {
          return {
            ...rate,
            endingBefore: date?.toISOString() ?? undefined,
          };
        }),
      });
    },
    [],
  );

  const [rates, setRates] = useState<{
    usageRates: Schema.Types.Rate[];
    subscriptionRates: Schema.Types.Rate[];
    compositeRates: Schema.Types.Rate[];
  }>({
    usageRates: ctrl.get("usageRates") ?? [],
    subscriptionRates: ctrl.get("subscriptionRates") ?? [],
    compositeRates: ctrl.get("compositeRates") ?? [],
  });

  useEffect(() => {
    ctrl.update({
      usageRates: rates.usageRates,
      subscriptionRates: rates.subscriptionRates,
      compositeRates: rates.compositeRates,
    });
  }, [rates]);

  /**
   * Updates rates by merging partial object. Only the rates selected by the rate selector
   * will be updated
   */
  const patchProductRates = useCallback(
    (
      type: RateProductEnum,
      rateSelector: {
        productId: string;
        pricingGroupValues?: Record<string, string>;
      },
      update: Partial<NonNullable<Schema.Types.AddRateInput["rates"]>[number]>,
    ) => {
      setRates((rateTypes) => {
        return {
          ...rateTypes,
          [type]: rateTypes[type].map((rate) => {
            if (
              rateSelector.productId === rate.productId &&
              (!rateSelector.pricingGroupValues ||
                deepEqual(
                  rateSelector.pricingGroupValues,
                  rate.pricingGroupValues,
                ))
            ) {
              return {
                ...rate,
                ...update,
              };
            }
            return rate;
          }),
        };
      });
    },
    [setRates],
  );

  const editRate = useCallback(
    (
      type: RateProductEnum,
      update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      setRates((rateTypes) => {
        const rates = rateTypes[type];
        // TODO(ekaragiannis) - we need to be able to updates the credit type for all ones in the product
        const originalIndex = rates.findIndex((r) => r.id === update.id);
        if (originalIndex >= 0) {
          // if the change was to the credit type, then we need to update all subrates for that product
          const currentRate = rates[originalIndex];
          if (currentRate.creditType?.id !== update.creditType?.id) {
            return {
              ...rateTypes,
              [type]: rates.map((r, index) => {
                if (index === originalIndex) {
                  return update;
                } else if (r.productId === update.productId) {
                  return {
                    ...r,
                    creditType: update.creditType,
                  };
                } else {
                  return r;
                }
              }),
            };
          } else {
            return {
              ...rateTypes,
              [type]: [
                ...rates.slice(0, originalIndex),
                update,
                ...rates.slice(originalIndex + 1),
              ],
            };
          }
        } else {
          return rateTypes;
        }
      });
    },
    [setRates],
  );

  const addRateOverride = useCallback(
    (
      type: RateProductEnum,
      previousRate: Schema.Types.Rate,
      update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      setRates((rateTypes) => {
        const rates = rateTypes[type];
        const originalIndex = rates.findLastIndex(
          (r) =>
            r.productId === previousRate.productId &&
            deepEqual(r.pricingGroupValues, previousRate.pricingGroupValues),
        );
        return {
          ...rateTypes,
          [type]: [
            ...rates.slice(0, originalIndex + 1),
            update,
            ...rates.slice(originalIndex + 1),
          ],
        };
      });
    },
    [setRates],
  );

  const removeRateOverride = useCallback(
    (
      type: RateProductEnum,
      update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      setRates((rateTypes) => {
        const rates = rateTypes[type];
        const originalIndex = rates.findIndex((r) => r.id === update.id);
        if (originalIndex >= 0) {
          return {
            ...rateTypes,
            [type]: [
              ...rates.slice(0, originalIndex),
              ...rates.slice(originalIndex + 1),
            ],
          };
        }

        return rateTypes;
      });
    },
    [setRates],
  );

  const addCommitRate = useCallback(
    (
      type: RateProductEnum,
      rate: Schema.Types.Rate,
      commitRate: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      setRates((rateTypes) => {
        const rates = rateTypes[type];
        const originalIndex = rates.findLastIndex(
          (r) => r.productId === rate.productId && r.id === rate.id,
        );
        return {
          ...rateTypes,
          [type]: [
            ...rates.slice(0, originalIndex),
            {
              ...rates[originalIndex],
              hasCommitRate: true,
            },
            { ...commitRate, isCommitRate: true, hasCommitRate: false },
            ...rates.slice(originalIndex + 1),
          ],
        };
      });
    },
    [setRates],
  );

  const removeCommitRate = useCallback(
    (
      type: RateProductEnum,
      commitRate: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      setRates((rateTypes) => {
        const rates = rateTypes[type];
        let originalIndex = rates.findIndex((r) => r.id === commitRate.id);
        if (!rates[originalIndex].isCommitPrice) {
          originalIndex += 1;
        }
        if (originalIndex >= 1) {
          return {
            ...rateTypes,
            [type]: [
              ...rates.slice(0, originalIndex - 1),
              {
                ...rates[originalIndex - 1],
                hasCommitRate: false,
              },
              ...rates.slice(originalIndex + 1),
            ],
          };
        }

        return rateTypes;
      });
    },
    [setRates],
  );

  /**
   * Update the selected products and set the dimensional products.
   * This will also ensure we have rate entries for each product.
   * This may add / remove rates / sub-rates based on the selected products.
   */
  const setSelectedProducts = useCallback(
    (selectedProducts: string[]) => {
      const currentDimensionalProducts = dimensionalProducts;
      const {
        dimensionalProducts: newDimensionalProducts,
        nonDimensionalProducts,
      } = categorizeProducts(selectedProducts, productsMap);

      const finalDimensionalProducts = newDimensionalProducts.map((dp) => {
        const currentProduct = currentDimensionalProducts.find(
          (cdp) =>
            cdp.id === dp.id &&
            dp.pricingGroupKeyValues.every((pair) =>
              cdp.pricingGroupKeyValues.some((cpair) => cpair.key === pair.key),
            ),
        );
        if (currentProduct) {
          return currentProduct;
        } else {
          return dp;
        }
      });
      const { usageRates, subscriptionRates, compositeRates } = getDefaultRates(
        rates.usageRates,
        rates.subscriptionRates ?? [],
        rates.compositeRates ?? [],
        nonDimensionalProducts,
        finalDimensionalProducts,
        fiatCreditType,
      );

      setDimensionalProducts(finalDimensionalProducts);
      setSelectedProductsInternal(selectedProducts);
      ctrl.update({
        selectedProducts,
        dimensionalProducts: finalDimensionalProducts,
        usageRates,
        subscriptionRates,
        compositeRates,
      });

      setRates({
        usageRates,
        subscriptionRates,
        compositeRates,
      });
    },
    [productsMap, rates],
  );

  const removeProduct = useCallback(
    (productId: string) => {
      setSelectedProducts(selectedProducts.filter((p) => p !== productId));
    },
    [setRates, selectedProducts, setSelectedProducts],
  );

  const [name, setNameInternal] = useState(ctrl.get("name") ?? "");
  const setName = useCallback(
    (val: string) => {
      setNameInternal(val);
      ctrl.update({ name: val });
    },
    [setNameInternal],
  );

  const [description, setDescriptionInternal] = useState(
    ctrl.get("description") ?? "",
  );
  const setDescription = useCallback(
    (val: string) => {
      setDescriptionInternal(val);
      ctrl.update({ description: val });
    },
    [setDescriptionInternal],
  );

  const [aliases, setAliasesInternal] = useState<Schema.Types.RateCardAlias[]>(
    ctrl.get("aliases") ?? [],
  );
  const setAliases = useCallback(
    (aliases: Schema.Types.RateCardAlias[]) => {
      setAliasesInternal(aliases);
      ctrl.update({ aliases });
    },
    [setAliasesInternal],
  );

  const hasValidRates = useMemo(() => {
    return (
      ctrl.isValid() &&
      (!dimensionalProducts ||
        dimensionalProducts.every((dp) =>
          dp.pricingGroupKeyValues.every((pgv) =>
            pgv.values.some((v) => v.length > 0),
          ),
        ))
    );
  }, [ctrl]);

  return {
    loading: loadingCreditTypes || loadingProducts,
    fiatCreditTypes: fiatCreditTypes as FiatCreditType[],
    customCreditTypes,
    clearSnapshot,
    products: filteredProductsByType,
    productsMap,
    productDataError,
    fiatCreditType,
    editRate,
    addRateOverride,
    removeRateOverride,
    addCommitRate,
    removeCommitRate,
    removeProduct,
    setFiatCreditType,
    conversionRateChange,
    setRateStartingAtDate,
    setRateEndingBeforeDate,
    aliases,
    setAliases,
    rates,
    name,
    setName,
    description,
    setDescription,
    dimensionalProducts,
    hasValidRates,
    selectedProducts,
    creditTypeConversions,
    setSelectedProducts,
    patchProductRates,
    setDimensionalProductKeyValues: (
      values: Array<{ productId: string; key: string; values: string[] }>,
    ) => {
      const dimensionalProducts = ctrl.get("dimensionalProducts") ?? [];
      const updatedDimensionalProducts = dimensionalProducts.map((dp) => {
        const product = values.find((v) => v.productId === dp.id);
        if (product) {
          return {
            ...dp,
            pricingGroupKeyValues: dp.pricingGroupKeyValues.map((pair) => {
              if (pair.key === product.key) {
                return {
                  ...pair,
                  values: product.values,
                };
              }

              return pair;
            }),
          };
        } else {
          return dp;
        }
      });

      const { nonDimensionalProducts } = categorizeProducts(
        selectedProducts,
        productsMap,
      );
      const { usageRates, subscriptionRates, compositeRates } = getDefaultRates(
        rates.usageRates ?? [],
        rates.subscriptionRates ?? [],
        rates.compositeRates ?? [],
        nonDimensionalProducts,
        updatedDimensionalProducts,
        fiatCreditType,
      );

      setRates({
        usageRates,
        subscriptionRates,
        compositeRates,
      });

      setDimensionalProducts(updatedDimensionalProducts);
      ctrl.update({
        dimensionalProducts: updatedDimensionalProducts,
        usageRates,
        subscriptionRates,
        compositeRates,
      });
    },
  };
}

export const RateCardContext = createContainer(useRateCardContext);
