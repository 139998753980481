import React, { useCallback, useRef, useState } from "react";
import "/src/tenaissance/tenaissance.css";
import { twMerge } from "tenaissance/twMerge";
import { Icon, IconName } from "../Icon";
import { Tooltip } from "../Tooltip";
import { Tag } from "../Tag";
import { DefaultInput } from ".";
import { v4 as uuid } from "uuid";

const SIZE_VARIANTS = {
  sm: "min-h-[36px] py-sm px-lg",
  md: "min-h-[40px] py-md px-lg",
  lg: "min-h-[44px] py-[10px] px-[14px]",
};

export type TagsInputProps = Omit<
  DefaultInput<string[]>,
  "variantOptions" | "value" | "onChange" | "onBlur" | "onKeyDown"
> & {
  /** Default: true - render a close icon on each tag, along with click to close */
  allowTagClose?: boolean;
  /** Add an onChange callback to receive the list of strings as they are updated in the list */
  onChange?: (meta: { value: string[] }) => void;
  /** Provide a callback to receive input changes if user clicks on the Input. */
  onClick?: (meta: { value: string[] }) => void;
  /** Initialize the TagsInput with a set of strings  */
  value?: string[];
  /** Allow customizing the container wrapper div */
  containerClassName?: string;
  /** Leading icon to be placed within input field */
  leadingIcon?: IconName;
  /** Allow duplicate tag values, defaults to false */
  allowDuplicates?: boolean;
  /** Set the input to full width */
  fullWidth?: boolean;
  /** Allow typing into the input field */
  isTypingEnabled?: boolean;
};

/**
 * TagsInput fields allow users to enter strings and create a series of tags rendered within the field.
 * Each tag can be removed, either by clicking on the tag itself or hitting 'Backspace' to remove the
 * last tag.
 * */
export function TagsInput({
  allowTagClose = true,
  className,
  containerClassName,
  isInvalid,
  placeholder,
  helpText,
  hintText,
  disabled,
  label,
  dropdownState,
  size = "md",
  onClick,
  onChange,
  value,
  leadingIcon,
  allowDuplicates,
  fullWidth,
  isTypingEnabled = true,
}: TagsInputProps) {
  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    ({ currentTarget }) => {
      if (currentTarget.value && onChange) {
        setInputValue("");
        if (!allowDuplicates && value?.includes(currentTarget.value)) {
          return;
        }

        onChange({
          value: [...(value ?? []), currentTarget.value.toString()],
        });
      }
    },
    [value, onChange],
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const { key } = event;
        if (onChange) {
          if (key === "Enter") {
            event.preventDefault();

            if (!inputValue || !inputValue.trim()) return;

            if (!allowDuplicates && value?.includes(inputValue)) {
              setInputValue("");
              return;
            }
            onChange({
              value: [...(value ?? []), inputValue.toString()],
            });
            setInputValue("");
          }
          if (key === "Backspace" && !inputValue) {
            onChange({ value: value?.slice(0, -1) ?? [] });
          }
        }
      },
      [value, onChange, inputValue],
    );

  const handleOnClick = useCallback(() => {
    inputRef?.current?.focus();
    if (onClick) onClick({ value: value ?? [] });
  }, [inputRef?.current, onClick]);

  const classnames = twMerge(
    fullWidth ? "w-full" : "max-w-[400px]",
    "border border-gray-200 bg-white rounded-md text-md shadow-xs items-center flex-1 h-fit block",
    isInvalid && "focus-within:ring-error-600/[.24]",
    SIZE_VARIANTS[size],
    disabled && "bg-gray-50",
    dropdownState && "relative",
    leadingIcon && "flex flex-row",
    className,
  );

  return (
    <div className={containerClassName}>
      {label && (
        <label className="mb-sm text-sm text-black" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="focus-within:ring-core-jade-green/[.24] focus-within:rounded-md flex focus-within:ring-4">
        <div onClick={handleOnClick} className={classnames}>
          {!disabled
            ? leadingIcon && (
                <Icon
                  icon={leadingIcon}
                  className="ml-sm mr-xs pointer-events-none shrink-0"
                  size={20}
                />
              )
            : null}
          <div className="space-x-sm mr-sm flex flex-wrap items-center">
            {value?.map((tagLabel, index) => {
              const handleClose = () =>
                onChange?.({ value: value.filter((_, i) => i !== index) });
              return (
                <Tag
                  key={uuid()}
                  label={tagLabel}
                  onClose={allowTagClose ? handleClose : undefined}
                />
              );
            })}
            <div className="grid-cols-[0 min-content] inline-grid flex-auto">
              <input
                id={label}
                ref={inputRef}
                className="h-full w-full min-w-[2px] text-black [grid-area:1/1] placeholder:text-gray-500 focus-within:outline-none disabled:bg-gray-50"
                placeholder={!value?.length ? placeholder : undefined}
                disabled={disabled}
                value={inputValue}
                onBlur={(e) => handleOnBlur(e)}
                onChange={(e) => setInputValue(e.currentTarget.value)}
                onKeyDown={handleKeyDown}
                readOnly={!isTypingEnabled}
              />
            </div>
          </div>
          {helpText && (
            <div className={twMerge(fullWidth ? "ml-auto" : "")}>
              <Tooltip label={helpText}>
                <Icon icon="helpCircle" size={15} />
              </Tooltip>
            </div>
          )}
          {!disabled
            ? dropdownState && (
                <Icon
                  className="pointer-events-none absolute right-[10px] top-[10px]"
                  size={16}
                  icon={dropdownState === "open" ? "chevronUp" : "chevronDown"}
                />
              )
            : null}
        </div>
      </div>
      {hintText && (
        <div
          className={twMerge(
            "mt-sm text-sm text-gray-600",
            isInvalid && "text-error-600",
          )}
        >
          {hintText}
        </div>
      )}
    </div>
  );
}
