import React, { useMemo, useState } from "react";
import { Headline, HelpCircleTooltip, Input, Label } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { useInsertCustomerMutation } from "./newCustomer.graphql";
import { useNavigate } from "lib/useNavigate";
import { useSnackbar } from "components/Snackbar";
import { AppShell } from "components/PageContainer";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { isApolloError } from "@apollo/client";
import { Breadcrumbs } from "lib/breadcrumbs";

type AddIngestAliasSectionProps = {
  ingestAliases: string[];
  errors: string[];
  setIngestAliases: (value: any) => void;
};

const AddIngestAliasSectionLabel: React.FC<{ handleClick: () => void }> = ({
  handleClick,
}) => {
  return (
    <div className="mb-8 flex items-center justify-between">
      <div className="flex">
        <Label>Add ingest alias (optional) </Label>
        <HelpCircleTooltip content="Add a new ingest alias to this customer. Metronome will associate any usage events using this alias." />
      </div>
      <Button
        onClick={handleClick}
        className="mr-[10px]"
        text="Add another alias"
        theme="linkGray"
        leadingIcon="plus"
      />
    </div>
  );
};
const AddIngestAliasSection: React.FC<AddIngestAliasSectionProps> = ({
  ingestAliases,
  errors,
  setIngestAliases,
}) => {
  const handleChange = (newValue: string, atIndex: number) => {
    if (atIndex >= ingestAliases.length) {
      setIngestAliases((prevAliases: string[]) => [...prevAliases, ""]);
    } else {
      setIngestAliases((prevAliases: string[]) =>
        prevAliases.map((alias: string, index: number) =>
          atIndex === index ? newValue : alias,
        ),
      );
    }
  };
  const removeAlias = (indexToRemove: number) => {
    setIngestAliases((prevAliases: string[]) =>
      prevAliases.filter((alias, index) => index !== indexToRemove),
    );
  };

  return (
    <div>
      <AddIngestAliasSectionLabel
        handleClick={() => handleChange("", ingestAliases.length)}
      />
      <div className="mb-24 flex w-[433px] flex-col gap-12">
        {ingestAliases.map((alias, index) => {
          return (
            <div className="flex items-center" key={index}>
              <Input
                placeholder="e.g. customer@acmecorp.com"
                value={alias}
                onChange={(value) => handleChange(value, index)}
                className="grow"
                error={errors[index]}
              />
              <IconButton
                onClick={() => removeAlias(index)}
                className={errors[index] ? "mb-[16px]" : ""}
                theme="tertiary"
                icon="xCircle"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const NewCustomer: React.FC = () => {
  const [customerName, setCustomerName] = useState("");
  const [ingestAliases, setIngestAliases] = useState([""]);
  const [errorAliases, setErrorAliases] = useState([""]);
  const { environmentType } = useEnvironment();
  const contractsEnabled = useContractsEnabled();

  const errors = useMemo(() => {
    return ingestAliases.map((alias, index) => {
      if (alias && ingestAliases.indexOf(alias) !== index) {
        return "This alias is already in use.";
      } else if (alias && errorAliases.includes(alias)) {
        return "This alias is already in use with another customer.";
      } else {
        return "";
      }
    });
  }, [ingestAliases, errorAliases]);

  const [insertCustomerMutation, insertCustomerResult] =
    useInsertCustomerMutation();
  const navigate = useNavigate();
  const pushMessage = useSnackbar();

  const createCustomer = async () => {
    if (!customerName) {
      return;
    }
    try {
      const result = await insertCustomerMutation({
        variables: {
          name: customerName,
          ingestAliases: ingestAliases.filter((alias) => !!alias),
          environmentType,
        },
        update(cache) {
          cache.evict({ fieldName: "Customer" });
        },
      });
      if (result.data?.create_customer?.id) {
        pushMessage({
          content: "Customer created successfully",
          type: "success",
        });
        navigate(
          contractsEnabled
            ? `/customers/${result.data.create_customer.id}`
            : `/customers/${result.data.create_customer.id}/plans/add?new=true`,
        );
      }
    } catch (e: any) {
      if (isApolloError(e) && e.graphQLErrors[0].extensions.conflicts?.length) {
        const errors = e.graphQLErrors[0].extensions.conflicts
          .filter((conflict: any) => !!conflict?.ingest_alias)
          .map((conflict: any) => conflict.ingest_alias);
        setErrorAliases(errors);
      } else {
        pushMessage({
          content: "Error while creating a customer",
          type: "error",
        });
      }
    }
  };

  return (
    <AppShell
      title="Create new customer"
      headerProps={{
        breadcrumbs: Breadcrumbs.from({
          label: "Customers",
          routePath: "/customers",
        }),
      }}
    >
      <div className="mb-24 flex flex-col items-center pt-[120px]">
        <div className="mb-32 flex flex-col items-center">
          <Headline level={3}>Add a new customer</Headline>
        </div>
        <div className="flex w-[400px] flex-col">
          <Input
            name="Customer name"
            placeholder="e.g. AcmeCorp"
            value={customerName}
            onChange={setCustomerName}
            className="mb-12"
          />

          <AddIngestAliasSection
            ingestAliases={ingestAliases}
            setIngestAliases={setIngestAliases}
            errors={errors}
          />

          <Button
            onClick={createCustomer}
            disabled={
              !customerName ||
              insertCustomerResult.loading ||
              errors.some((value) => !!value)
            }
            loading={insertCustomerResult.loading}
            text="Add new customer"
            theme="primary"
            className="w-[400px] justify-center"
          />
        </div>
      </div>
    </AppShell>
  );
};

export default NewCustomer;
