import React, { useState } from "react";
import { dayjs } from "lib/dayjs";
import { useLocation } from "react-router-dom";
import { useRequiredParam } from "lib/routes/params";
import { useNavigate } from "lib/useNavigate";
import NotFoundPage from "pages/404";
import {
  BillingProviderEnum_Enum,
  TrialSpecInput,
} from "types/generated-graphql/__types__";
import {
  AddCustomerBillingProviderMutationVariables,
  AddPlanToCustomerDataQuery,
  AddPlanToCustomerMutationVariables,
  useAddCustomerBillingProviderMutation,
  useAddStripeCollectionMethodConfigMutation,
  useAddPlanToCustomerDataQuery,
  useAddPlanToCustomerMutation,
} from "./queries.graphql";
import { PlanQuery, usePlanQuery } from "lib/plans/queries.graphql";
import { Landing } from "./components/Landing";
import { Wizard, WizardSection } from "components/Wizard";
import { Subtitle } from "design-system";
import { Context, CustomerPlanInfo } from "./context";
import { selectingPlanIsDone, SelectPlan } from "./steps/SelectPlan";
import {
  previewingSelectedPlanIsDone,
  PreviewSelectedPlan,
} from "./steps/PreviewSelectedPlan";
import { planLengthIsDone, SetPlanLength } from "./steps/SetPlanLength";
import { PlanBilling, planBillingIsDone } from "./steps/PlanBilling";
import {
  PreviewFinalPlan,
  previewingFinalPlanIsDone,
} from "./steps/PreviewFinalPlan";
import { useSnackbar } from "../../components/Snackbar";
import { getUtcEndDate, getUtcStartOfDay } from "../../lib/time";
import { serializeTrialSpec } from "../../lib/plans/draftPlan";
import {
  isCustomPricingValid,
  serializeCreditTypeConversionAdjustments,
  serializeCustomPricing,
} from "../../lib/customPricing";
import { CustomPricing } from "./steps/CustomPricing";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { useUIMode } from "../../lib/useUIMode";

const getSections = (
  plan: CustomerPlanInfo,
  data: {
    [K in keyof AddPlanToCustomerDataQuery]: NonNullable<
      AddPlanToCustomerDataQuery[K]
    >;
  },
  selectedPlan: PlanQuery | null,
  lastFinalizedInvoiceDate: Date,
): WizardSection[] => {
  const existingStripeConfig = data.Customer_by_pk.BillingProviderCustomers
    .length
    ? {
        billing_provider_customer_id:
          data.Customer_by_pk.BillingProviderCustomers[0]
            .billing_provider_customer_id,
        stripe_collection_method: data.Customer_by_pk.CustomerConfigs.length
          ? data.Customer_by_pk.CustomerConfigs[0].value
          : "send_invoice",
      }
    : null;

  return [
    {
      title: "Select a plan",
      isDone: previewingSelectedPlanIsDone(plan),
      subStepGroups: [
        {
          isDone: previewingSelectedPlanIsDone(plan),
          subSteps: [
            {
              header: "Select a plan",
              component: <SelectPlan plans={data.plans} />,
              isDone: selectingPlanIsDone(plan),
            },
            {
              header: "Preview your selected plan",
              component: <PreviewSelectedPlan selectedPlan={selectedPlan} />,
              isDone: previewingSelectedPlanIsDone(plan),
            },
          ],
        },
      ],
    },
    {
      title: "Plan terms",
      isDone: planLengthIsDone(plan),
      subStepGroups: [
        {
          isDone: planLengthIsDone(plan),
          subSteps: [
            {
              header: "Plan terms",
              isDone: planLengthIsDone(plan),
              component: (
                <SetPlanLength
                  selectedPlan={selectedPlan}
                  existingPlans={data.CustomerPlan}
                  customerName={data.Customer_by_pk.name}
                  creditTypes={data.CreditType}
                  lastFinalizedInvoiceDate={lastFinalizedInvoiceDate}
                />
              ),
            },
          ],
        },
      ],
    },
    plan.customPricing
      ? {
          title: "Price adjustments",
          isDone: isCustomPricingValid(
            selectedPlan,
            plan.customPricing,
            plan.creditTypeConversionAdjustments,
          ),
          subStepGroups: [
            {
              isDone: isCustomPricingValid(
                selectedPlan,
                plan.customPricing,
                plan.creditTypeConversionAdjustments,
              ),
              subSteps: [
                {
                  header: "Apply price adjustments",
                  isDone: isCustomPricingValid(
                    selectedPlan,
                    plan.customPricing,
                    plan.creditTypeConversionAdjustments,
                  ),
                  component: (
                    <CustomPricing
                      selectedPlan={selectedPlan}
                      creditTypes={data.CreditType}
                    />
                  ),
                },
              ],
            },
          ],
        }
      : null,
    {
      title: "Billing",
      isDone: planBillingIsDone(plan),
      subStepGroups: [
        {
          isDone: planBillingIsDone(plan),
          subSteps: [
            {
              header: "Configure Billing",
              isDone: planBillingIsDone(plan),
              component: (
                <PlanBilling
                  creditTypes={data.CreditType}
                  hasConfiguredStripe={!!data.BillingProviderToken.length}
                  existingStripeConfig={existingStripeConfig}
                  customerName={data.Customer_by_pk.name}
                  netPaymentTermsDays={plan.netPaymentTermsDays ?? undefined}
                  netPaymentTermsDaysClientDefault={
                    data?.NetPaymentTermsDaysDefault?.length
                      ? parseInt(data.NetPaymentTermsDaysDefault[0].value)
                      : undefined
                  }
                />
              ),
            },
          ],
        },
      ],
    },
    {
      title: "Preview plan",
      isDone: previewingFinalPlanIsDone(plan),
      subStepGroups: [
        {
          isDone: previewingFinalPlanIsDone(plan),
          subSteps: [
            {
              header: data.Customer_by_pk.name,
              isDone: previewingFinalPlanIsDone(plan),
              component: (
                <PreviewFinalPlan
                  selectedPlan={selectedPlan}
                  creditTypes={data.CreditType}
                />
              ),
            },
          ],
        },
      ],
    },
  ].filter(Boolean) as WizardSection[];
};

const AddPlanToCustomer: React.FC = () => {
  const { environmentType } = useEnvironment();
  const customerId = useRequiredParam("customerId");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [landingPassed, setLandingPassed] = useState(false);
  const { data, loading } = useAddPlanToCustomerDataQuery({
    variables: {
      customer_id: customerId,
      environment_type: environmentType,
    },
  });

  const contractsEnabled = useContractsEnabled();
  const { newUIEnabled } = useUIMode();
  const pushMessage = useSnackbar();
  const [customerPlan, setCustomerPlan] = useState<CustomerPlanInfo>({});
  const { data: selectedPlan, loading: selectedPlanLoading } = usePlanQuery({
    variables: { id: customerPlan.Plan?.id || "" },
    skip: !customerPlan.Plan,
  });

  const [AddPlanToCustomerMutation, { loading: submitLoading }] =
    useAddPlanToCustomerMutation();
  const [addCustomerBillingProviderMutation] =
    useAddCustomerBillingProviderMutation();
  const [addStripeCollectionMethodConfigMutation] =
    useAddStripeCollectionMethodConfigMutation();

  // For customers with no finalized invoices, we use the min plan start date
  // defined in validatePlanDates.ts.
  const lastFinalizedInvoiceDate: Date = data?.Customer_by_pk
    ?.end_date_of_last_finalized_invoice
    ? new Date(data.Customer_by_pk.end_date_of_last_finalized_invoice)
    : dayjs.utc().subtract(10, "year").toDate();

  if (loading) {
    return null; // show the spinner
  }

  if (data?.Customer_by_pk == null) {
    return <NotFoundPage />;
  }
  const sections = getSections(
    customerPlan,
    { ...data, Customer_by_pk: data.Customer_by_pk }, // to make the type happy
    selectedPlan ?? null,
    lastFinalizedInvoiceDate,
  );

  const onClose = () =>
    navigate(
      contractsEnabled || newUIEnabled
        ? `/customers/${customerId}`
        : `/customers/${customerId}/plans`,
    );
  const onDone = async () => {
    if (
      !customerPlan.Plan ||
      !customerPlan.planStartDate ||
      customerPlan.planEndDate === undefined ||
      customerPlan.planDatesError !== null ||
      customerPlan.trialSpec === undefined
    ) {
      throw new Error("Attempting to add a plan without finishing wizard");
    }

    let trialSpecInput: TrialSpecInput | undefined;
    if (selectedPlan?.Plan_by_pk?.TrialSpec) {
      if (customerPlan.trialSpec) {
        // If the original plan has a trial and we have a trial, check if they're different before serializing
        const originalTrial = selectedPlan.Plan_by_pk.TrialSpec;
        const newTrial = customerPlan.trialSpec;
        if (
          // check the trial length
          originalTrial.length_in_days !== String(newTrial.length ?? "") ||
          // check the number of spending caps
          originalTrial.TrialSpecSpendingCaps.length !==
            newTrial.caps?.length ||
          // check that each spending cap has a matching credit type and amount as the original
          originalTrial.TrialSpecSpendingCaps.some((originalCap) => {
            const matchingCap = newTrial.caps?.find(
              (newCap) => newCap.creditTypeId === originalCap.CreditType.id,
            );
            return (
              !matchingCap ||
              matchingCap.amount?.toString() !== originalCap.amount
            );
          })
        ) {
          trialSpecInput = serializeTrialSpec(
            !!customerPlan.trialSpec,
            customerPlan.trialSpec,
          );
        } else {
          // If the trial specs match, don't insert a new one
          trialSpecInput = undefined;
        }
      } else if (customerPlan.trialSpec === null) {
        // If the original plan has a trial but this customer shouldn't, just set a trial with length = 0
        trialSpecInput = {
          length_in_days: 0,
          spending_caps: [],
        };
      }
    } else {
      // If the original plan has no trial, serialize the trial spec normally
      trialSpecInput = serializeTrialSpec(
        !!customerPlan.trialSpec,
        customerPlan.trialSpec,
      );
    }
    const AddPlanToCustomerArgs: AddPlanToCustomerMutationVariables = {
      customer_id: customerId,
      plan_id: customerPlan.Plan.id,
      start_date: getUtcStartOfDay(customerPlan.planStartDate).toISOString(),
      end_date: customerPlan.planEndDate
        ? getUtcEndDate(customerPlan.planEndDate).toISOString()
        : null,
      trial_spec: trialSpecInput,
      custom_pricing: serializeCustomPricing(customerPlan.customPricing),
      net_payment_terms_days: customerPlan.netPaymentTermsDays,
      credit_type_conversion_adjustments:
        serializeCreditTypeConversionAdjustments(
          customerPlan.creditTypeConversionAdjustments,
        ),
    };
    const planBillingProviderArgs: AddCustomerBillingProviderMutationVariables =
      {};
    if (customerPlan.stripeConfig) {
      planBillingProviderArgs.stripeConfig = {
        customer_id: customerId,
        billing_provider: BillingProviderEnum_Enum.Stripe,
        billing_provider_customer_id:
          customerPlan.stripeConfig.billingProviderCustomerId,
      };
    }

    try {
      await AddPlanToCustomerMutation({
        variables: AddPlanToCustomerArgs,
        update(cache) {
          cache.evict({
            // We evict the entire customer so invoices will also be regenerated.
            // This could potentially be more targeted by evicting certain
            // fields, like CreditGrants and invoices, but this is a bit "safer"
            // in that it over-invalidates.
            id: `Customer:${customerId}`,
          });
          // we evict all Customer objects so that the Overview page filters get updated
          cache.evict({
            fieldName: "Customer",
          });
          cache.evict({
            fieldName: "CustomerPlan",
          });
          /* Evict customer by pk to refetch customer detail page queries */
          cache.evict({
            fieldName: "Customer_by_pk",
          });
          /* Evict plan so plan customer count gets updated */
          if (customerPlan.Plan?.id) {
            cache.evict({
              id: `Plan:${customerPlan.Plan?.id}`,
            });
          }
          cache.gc();
        },
      });
      if (Object.keys(planBillingProviderArgs.stripeConfig || {}).length > 0) {
        await addCustomerBillingProviderMutation({
          variables: planBillingProviderArgs,
          update(cache) {
            // no id field for this type
            cache.evict({
              fieldName: "BillingProviderCustomer",
            });
          },
        });
      }

      if (customerPlan.stripeConfig?.collectionMethod) {
        await addStripeCollectionMethodConfigMutation({
          variables: {
            customer_id: customerId,
            stripeConfigCollectionMethod:
              customerPlan.stripeConfig.collectionMethod,
          },
        });
      }
      pushMessage({
        content: "Plan successfully added",
        type: "success",
      });
      onClose();
    } catch (e) {
      pushMessage({
        content: "Failed to add plan",
        type: "error",
      });
      throw e;
    }
  };

  if (!landingPassed) {
    return (
      <Landing
        onClose={onClose}
        onContinue={() => setLandingPassed(true)}
        newCustomer={searchParams.get("new") === "true"}
        customerName={data.Customer_by_pk.name}
      />
    );
  }
  return (
    <Context.Provider
      value={{ state: customerPlan, setState: setCustomerPlan }}
    >
      <Wizard
        sections={sections}
        onClose={onClose}
        closeWarning={`Are you sure you want to leave the wizard? You'll lose any changes.`}
        title="Add a plan"
        subtitle={
          customerPlan.Plan?.name ? (
            <Subtitle level={4}>{customerPlan.Plan?.name}</Subtitle>
          ) : null
        }
        loading={selectedPlanLoading || submitLoading}
        onDone={onDone}
      />
    </Context.Provider>
  );
};

export default AddPlanToCustomer;
