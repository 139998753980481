import React, { ReactElement } from "react";
import classNames from "classnames";
import styles from "./index.module.less";
import { Body, Headline } from "design-system";

interface InputSectionProps extends React.PropsWithChildren {
  header: string;
  subtitle: string | ReactElement;
  className?: string;
}

export const InputSection: React.FC<InputSectionProps> = (props) => {
  return (
    <div className={classNames(props.className, styles.inputSection)}>
      <Headline level={6}>{props.header}</Headline>
      <Body>{props.subtitle}</Body>
      {props.children}
    </div>
  );
};
