import { useSnackbar } from "components/Snackbar";
import { FormController } from "lib/FormController";
import React, { useState } from "react";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";

import {
  ContractOverviewQuery,
  useEditContractEndDateMutation,
} from "./data.graphql";
import { Schema } from "../Create/Schema";
import { dayjs } from "lib/dayjs";
import { DatePicker } from "tenaissance/components/DatePicker";
import { SideSheet } from "tenaissance/components/SideSheet";
import { useExistingCustomerContractsQuery } from "../Create/data.graphql";
import { ContractOverlapConfirmationModal } from "../Create/ContractOverlapConfirmationModal";
import { doContractsOverlap } from "../../../lib/Contract/Contract";

const useEditContractEndDateController = FormController.createHook(
  Schema.EditContractEndDateInput,
  {
    init({ endingBefore }) {
      return {
        endingBefore: endingBefore,
      };
    },
  },
);

interface EditContractEndDateFlyoverProps {
  onSuccess: () => void;
  onCancel: () => void;
  contract: Exclude<
    Exclude<ContractOverviewQuery["customer"], null>["contract"],
    null
  >;
  customerId: string;
}

export const EditContractEndDateFlyover: React.FC<
  EditContractEndDateFlyoverProps
> = ({ onSuccess, onCancel, contract, customerId }) => {
  const pushMessage = useSnackbar();

  const [
    showContractOverlapConfirmationModal,
    setShowContractOverlapConfirmationModal,
  ] = React.useState(false);

  const [pendingEditContractEndDateInput, setPendingEditContractEndDateInput] =
    React.useState<Schema.Types.EditContractEndDateInput | null>(null);

  const [editContractEndDateMutation, { loading: editContractLoading }] =
    useEditContractEndDateMutation();
  const existingContractsReq = useExistingCustomerContractsQuery({
    variables: {
      customerId,
    },
  });
  const loading = editContractLoading || existingContractsReq.loading;
  const existingContracts = (
    existingContractsReq.data?.Customer_by_pk?.contracts ?? []
  ).filter((c) => !c.archived_at);

  const ctrl = useEditContractEndDateController({
    endingBefore: contract.ending_before
      ? new Date(contract.ending_before).toISOString()
      : "",
  });
  const [selectedEndingBefore, setSelectedEndingBefore] = useState<
    Date | undefined
  >(contract.ending_before ? new Date(contract.ending_before) : undefined);

  const editContract = async (
    editContractEndDateInput: Schema.Types.EditContractEndDateInput,
  ) => {
    try {
      const res = await editContractEndDateMutation({
        variables: {
          contract_id: contract.id,
          customer_id: contract.customer.id,
          ending_before: editContractEndDateInput.endingBefore,
        },
      });

      if (res.data?.update_contract_end_date.id) {
        pushMessage({
          content: "Successfully updated contract end date",
          type: "success",
        });
      }

      onSuccess();
    } catch (e) {
      pushMessage({
        content: `Failed to update contract end date: ${e}`,
        type: "error",
      });
    }
  };

  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    const overlappingContracts = existingContracts.filter(
      (existingContract) => {
        if (contract.id === existingContract.id) {
          return false;
        }

        return doContractsOverlap(
          {
            startingAt: contract.starting_at,
            endingBefore: valid.endingBefore ?? null,
          },
          {
            startingAt: existingContract.starting_at,
            endingBefore: existingContract.ending_before,
          },
        );
      },
    );

    if (overlappingContracts.length > 0) {
      setPendingEditContractEndDateInput(valid);
      setShowContractOverlapConfirmationModal(true);
      return;
    }

    await editContract(valid);
  });

  const trailingActionButtons:
    | [React.ReactElement, React.ReactElement]
    | undefined = [
    <Button
      onClick={onSubmit}
      loading={loading}
      text="Save"
      theme="primary"
      type="submit"
    />,
    <Button onClick={onCancel} text="Cancel" theme="secondary" />,
  ];

  return (
    <>
      {showContractOverlapConfirmationModal &&
        pendingEditContractEndDateInput !== null && (
          <ContractOverlapConfirmationModal
            onSave={async () => {
              setShowContractOverlapConfirmationModal(false);
              await editContract(pendingEditContractEndDateInput);
            }}
            onClose={() => {
              setShowContractOverlapConfirmationModal(false);
            }}
          />
        )}
      <SideSheet
        title="Edit contract end date"
        isOpen
        onClose={onCancel}
        trailingActions={trailingActionButtons}
      >
        <form className="flex h-full flex-col" onSubmit={onSubmit}>
          <input type="submit" className="hidden" />
          <div className="flex-grow">
            <Body level={2} className="text-gray-700">
              Ending a contract early will:
            </Body>
            <ul
              className="text-gray-700 mb-12 text-xs"
              style={{ listStyleType: "disc", paddingLeft: "20px" }}
            >
              <li>Impact draft usage statements.</li>
              <li>Truncate credit and commit access schedules.</li>
              <li>
                Remove draft scheduled invoices after the updated contract end
                date, except for postpaid commit true-ups.
              </li>
            </ul>
            <Body level={2} className="text-gray-700">
              Finalized invoices will be unchanged (void scheduled invoices to
              remove them, and void and regenerate usage statements to
              incorporate the new end date).
              <br />
              <br />
              Moving the date into the future will only extend the contract
              length. Credit and commit access schedules, invoice schedules, and
              dates for additional terms are not extended.
            </Body>
            <div>
              <DatePicker
                text="Ending before"
                onDateApply={(date) => {
                  ctrl.update({
                    endingBefore: date?.toISOString(),
                  });
                  setSelectedEndingBefore(date);
                }}
                value={
                  // changes the date and time back to UTC
                  selectedEndingBefore
                    ? dayjs
                        .utc(selectedEndingBefore)
                        .subtract(
                          dayjs(selectedEndingBefore).utcOffset(),
                          "minute",
                        )
                        .toDate()
                    : undefined
                }
                allowClear
              />
            </div>
          </div>
        </form>
      </SideSheet>
    </>
  );
};
