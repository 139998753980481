import React, { useCallback } from "react";
import "style/index.css";
import {
  ComposedChart,
  YAxis,
  XAxis,
  Text,
  CartesianGrid,
  Bar,
  Line,
  Area,
  Legend,
  ResponsiveContainer,
  Tooltip as RechartsToolTip,
  type DefaultLegendContentProps,
  Label,
} from "recharts-next";
import { renderDate } from "lib/time";
import { Tooltip } from "../Tooltip";
import { twMerge } from "tenaissance/twMerge";
// import { CardContext } from "../Card/CardContext";

const VALID_LINE_AREA_COLORS = [
  "text-core-deep-denim",
  "text-core-jade-green",
  "text-core-gold",
  "text-core-indigo",
  "text-core-midnight",
  "text-warning-600",
  "text-core-vibrant-magenta",
  "text-core-dark-green",
  "text-core-hot-green",
  "text-core-azure-blue",
];

const VALID_BAR_COLORS = [
  "text-core-dark-green",
  "text-core-hot-green",
  "text-core-deep-denim",
  "text-core-gold",
  "text-core-indigo",
  "text-warning-600",
  "text-core-vibrant-magenta",
  "text-core-jade-green",
  "text-core-azure-blue",
  "text-core-midnight",
];

const CHART_BOTTOM_MARGIN = 20;
const CHART_LEFT_MARGIN_WITH_YAXIS_LABEL = 42;
const APPROX_CHARACTER_WIDTH = 8;
const YAXIS_WIDTH = 34;

type AxisProps = {
  payload: {
    value: number;
  };
  width: number;
};

type TimeSeriesDatum = {
  date: Date;
  value: number;
};

type TimeSeries = {
  name: string;
  data: TimeSeriesDatum[];
  yAxisUnit?: string;
};

type DataWithUnixTimeObject = { date: number }[];

interface ChartProps {
  /**
   * The source data array for the chart, in which each element is an object.
   *  Each object must specify a "name" associated to each category (string value) in time series charts.
   *  The data array in there will have objects containing Date values paired with number values.
   * */
  chartData: TimeSeries[];
  /** Default: none - Place the legend for the chart either above the graph or to the right of the graph. */
  legendPostion?: "right" | "top" | "none";
  /**
   * Default: false - Stacked bars highlight the sum of the bars for a given XAxis tick,
   *  and the YAxis will adjust accordingly
   */
  stackedBars?: boolean;
  /** Title string for the Chart */
  title?: string;
  /**
   * Default: line - Type of chart "line" | "bar" | "area"
   */
  type?: "line" | "bar" | "area";
  /** Label for the XAxis */
  xAxisLabel?: string;
  /** Label for the YAxis */
  yAxisLabel?: string;
}

/**
 * This logic is for figuring out the appropriate width for yAxis area.
 * N.B. - If we allowed for chart domain to be a prop, we could base this
 * off domain max instead.
 * */
const getMaxValueLength = (data: DataWithUnixTimeObject) =>
  Math.max(
    ...data.flatMap((obj) =>
      Object.entries(obj)
        .filter(([key]) => key !== "date")
        .map(([, value]) => value),
    ),
  ).toString().length + 1;

const YTick = (props: AxisProps) => {
  let tickValue = props.payload.value.toLocaleString();

  return (
    <Text
      {...props}
      x={props.width - 8} // take back 8px to give space b/w this and chart
      textAnchor="end"
      verticalAnchor="end"
      fill="currentcolor"
      className="text-xs font-normal text-gray-600"
    >
      {tickValue}
    </Text>
  );
};

const XTick = (props: AxisProps) => {
  const date = new Date(props.payload.value);
  const dateLabel = renderDate(date, {
    excludeYear: true,
    isUtc: true,
    excludeUtcLabel: true,
  });
  return (
    <Text {...props} className="text-xs font-normal text-gray-600">
      {dateLabel}
    </Text>
  );
};

const CustomTooltip: React.FC<any> = ({
  active,
  payload,
  label,
  reversePayload,
}) => {
  if (active && payload && payload.length) {
    const dateLabel = renderDate(label, {
      excludeYear: true,
      isUtc: true,
      excludeUtcLabel: true,
    });

    return (
      <Tooltip
        label={dateLabel}
        subLabel={(reversePayload ? payload.reverse() : payload).map(
          (p: { name: string; value: number; unit?: string }, i: number) => (
            <div
              key={i}
            >{`${p.name}: ${p.value.toLocaleString()} ${p.unit ?? ""}`}</div>
          ),
        )}
      />
    );
  }

  return null;
};

/**
 * !! PARTIAL IMPLEMENTATION !!
 *  A chart is a graphical representation of series of data. Line and bar charts are useful
 *  line visualizations to show trends over time and compare several data sets.
 *  These are commonly used in dashboard UI design.
 */
export const Chart: React.FC<ChartProps> = ({
  chartData,
  legendPostion = "none",
  stackedBars = false,
  title,
  type = "line",
  xAxisLabel,
  yAxisLabel,
}) => {
  /**
   *  Leaving in placeholder for CardContext; will come into play for next Card/Chart changes. See GET-4811 for more.
   * */
  // const myNumber = useContext(CardContext);
  const dataWithUnixTime = chartData[0].data.map((datum, datumIndex) => ({
    date: datum.date.valueOf(),

    ...Object.fromEntries(
      chartData.map((line, lineIndex) => [
        lineIndex.toString(),
        line.data[datumIndex].value,
      ]),
    ),
  }));

  const renderLegend = useCallback(
    (props: DefaultLegendContentProps) => {
      const { payload } = props;
      if (!payload) return null;

      return (
        <ul
          className={twMerge(
            legendPostion === "right" ? "pl-xl text-left" : "text-right",
          )}
        >
          {payload.map((entry, index) => (
            <li
              className={twMerge(
                "truncate text-xs font-medium text-gray-600",
                legendPostion === "right" ? "block" : "mr-lg inline-block",
              )}
              key={`item-${index}`}
            >
              <div
                className={twMerge(
                  "flex items-center",
                  legendPostion === "top" && "justify-end",
                )}
              >
                <div
                  className={twMerge(
                    "mr-[5px] inline-block h-md w-md rounded-full bg-[currentcolor] align-middle",
                    type === "bar"
                      ? VALID_BAR_COLORS[index]
                      : VALID_LINE_AREA_COLORS[index],
                  )}
                />
                {entry.value}
              </div>
            </li>
          ))}
        </ul>
      );
    },
    [legendPostion, type],
  );

  return (
    <ResponsiveContainer width="100%" minHeight={250}>
      <ComposedChart
        data={dataWithUnixTime}
        margin={{
          left: yAxisLabel ? CHART_LEFT_MARGIN_WITH_YAXIS_LABEL : undefined,
          bottom: CHART_BOTTOM_MARGIN,
        }}
      >
        {type === "area" && (
          <defs>
            {chartData.map((_, i) => (
              <linearGradient
                key={`color${i}`}
                id={`color${i}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  className={VALID_LINE_AREA_COLORS[i]}
                  stopColor="currentcolor"
                  stopOpacity={0.1}
                />
                <stop
                  offset="100%"
                  className={VALID_LINE_AREA_COLORS[i]}
                  stopColor="#currentcolor"
                  stopOpacity={0}
                />
              </linearGradient>
            ))}
          </defs>
        )}

        <CartesianGrid
          vertical={false}
          stroke="currentcolor"
          className="text-gray-200"
        />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tick={XTick}
          interval="equidistantPreserveStart"
          type="category"
        >
          <Label
            value={xAxisLabel}
            fill="currentcolor"
            className="font-medium text-gray-600"
            fontSize={12}
            position="bottom"
            offset={7} // move label away from actual axis line
          />
        </XAxis>
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={YTick}
          width={
            getMaxValueLength(dataWithUnixTime) * APPROX_CHARACTER_WIDTH + 8
          } // extra 8px for space
          dx={yAxisLabel ? YAXIS_WIDTH : undefined}
        >
          <Label
            value={yAxisLabel}
            fill="currentcolor"
            className="font-medium text-gray-600"
            angle={-90}
            fontSize={12}
            position="left"
            offset={30} // move label away from actual axis line
          />
        </YAxis>
        <RechartsToolTip
          content={<CustomTooltip reversePayload={stackedBars} />}
        />
        {legendPostion !== "none" && (
          <Legend
            verticalAlign="top"
            align="right"
            layout={legendPostion === "right" ? "vertical" : undefined}
            width={legendPostion === "right" ? 100 : undefined}
            content={renderLegend}
          />
        )}

        {chartData.map((d, i) => {
          if (type === "bar") {
            return (
              <Bar
                className={VALID_BAR_COLORS[i]}
                dataKey={i}
                fill="currentcolor"
                isAnimationActive={false}
                key={i}
                name={d.name}
                stroke="currentcolor"
                radius={[1, 1, 0, 0]}
                stackId={stackedBars ? "yes" : undefined}
                unit={d.yAxisUnit}
                maxBarSize={32}
              />
            );
          } else if (type === "area") {
            return (
              <Area
                dataKey={i}
                className={VALID_LINE_AREA_COLORS[i]}
                dot={false}
                isAnimationActive={false}
                key={i}
                name={d.name}
                type="linear"
                unit={d.yAxisUnit}
                stroke="currentcolor"
                fillOpacity={1}
                fill={`url(#${`color${i}`})`}
              />
            );
          } else {
            return (
              <Line
                className={VALID_LINE_AREA_COLORS[i]}
                dataKey={i}
                dot={false}
                isAnimationActive={false}
                key={i}
                name={d.name}
                type="linear"
                stroke="currentcolor"
                strokeWidth={2}
                unit={d.yAxisUnit}
              />
            );
          }
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
