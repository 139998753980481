import Papa from "papaparse";

export const downloadCSV = (fileName: string, csv: string[][]) => {
  const csvString = Papa.unparse(csv);
  let blob: Blob | undefined = new Blob([csvString], { type: "csv" });
  const url = window.URL.createObjectURL(blob);
  const aTag = document.createElement("a");
  aTag.textContent = "download";

  aTag.download = fileName;
  aTag.href = url;
  document.body.appendChild(aTag);
  aTag.click();

  /* Clean up */
  document.body.removeChild(aTag);
  window.URL.revokeObjectURL(url);
  blob = undefined; // Remove all references to the blob so it can be garbage collected
};
