import React, { useState } from "react";
import classNames from "classnames";

import styles from "./index.module.less";
import { Popover, PopoverProps } from "react-tiny-popover";
import { InternalLink } from "components/Typography";

export interface MenuItemProps {
  active?: boolean;
  content: React.ReactNode;
  disabled?: boolean;
  underlined?: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
  routePath?: string;
  hide?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = (item) => {
  const { content, active, disabled, underlined } = item;
  const className = classNames(styles.menuItem, {
    [styles.active]: active,
    [styles.disabled]: disabled,
    [styles.underlined]: underlined,
  });

  if (item.routePath && !item.disabled) {
    return (
      <InternalLink
        className={className}
        routePath={item.routePath}
        onClick={item.onClick}
      >
        {content}
      </InternalLink>
    );
  }

  return (
    <div className={className} onClick={disabled ? undefined : item.onClick}>
      {content}
    </div>
  );
};

interface PopoverMenuProps
  extends Pick<PopoverProps, "positions" | "align" | "contentLocation"> {
  options: MenuItemProps[];
  children: (
    onClick: (e: React.MouseEvent) => void,
    isOpen: boolean,
  ) => JSX.Element;
  fullWidth?: boolean;
  transitionDuration?: number;
  className?: string;
}

export const PopoverMenu: React.FC<PopoverMenuProps> = ({
  options,
  children,
  fullWidth,
  transitionDuration,
  className,
  ...popoverOptions
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = options.flatMap((option, index) => {
    if (option.hide) {
      return [];
    }

    return (
      <MenuItem
        key={index}
        content={option.content}
        routePath={option.routePath}
        active={option.active}
        disabled={option.disabled}
        underlined={option.underlined}
        onClick={(e) => {
          setIsOpen(false);
          if (option.onClick) {
            option.onClick(e);
          }
        }}
      />
    );
  });

  return (
    <Popover
      containerClassName={styles.menu}
      isOpen={isOpen}
      onClickOutside={() => setIsOpen(false)}
      content={({ childRect: { width } }) => (
        <div
          data-testid="popover-menu"
          style={{ width: fullWidth ? width : undefined }}
          onClick={(e) => e.preventDefault()}
        >
          {menuItems}
        </div>
      )}
      {...popoverOptions}
    >
      {children((e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
      }, isOpen)}
    </Popover>
  );
};
