import React from "react";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { NotFoundPage } from "pages/404";
import { TextSkeleton, ParagraphSkeleton } from "components/Skeleton";
import { PageContainer } from "components/PageContainer";
import { Breadcrumbs } from "lib/breadcrumbs";
import { useRequiredParam } from "lib/routes/params";
import { useHasAccessToAlerts } from "lib/alerts";
import { ApolloResp } from "../lib/ApolloResp";

interface Props<T extends ApolloResp<unknown>> {
  rootReq: T;
  breadcrumbs?: Breadcrumbs | ((req: T & { state: "success" }) => Breadcrumbs);
  sidebar?:
    | React.ReactNode
    | ((req: T & { state: "success" }) => React.ReactNode);
  content?:
    | React.ReactNode
    | ((req: T & { state: "success" }) => React.ReactNode);
}

export function CustomerLayout<T extends ApolloResp<unknown>>(props: Props<T>) {
  const customerId = useRequiredParam("customerId");
  const alertsEnabled = !!useHasAccessToAlerts();
  const basePath = `/customers/${customerId}`;

  const tabs = [
    {
      name: "Billing overview",
      routePath: basePath,
    },
    {
      name: "Contract commits and credits",
      routePath: `${basePath}/commits-and-credits`,
    },
    {
      name: "Plan credits",
      routePath: `${basePath}/credits`,
    },
    alertsEnabled
      ? {
          name: "Alerts",
          routePath: `${basePath}/alerts`,
        }
      : [],
    {
      name: "Usage",
      routePath: `${basePath}/usage`,
    },
    {
      name: "Settings",
      routePath: `${basePath}/settings`,
    },
    {
      name: "Audit logs",
      routePath: `${basePath}/audit-logs`,
    },
  ].flat();

  if (props.rootReq.state === "error") {
    return (
      <PageContainer title="Failed to load customer" hideHeader>
        <ErrorEmptyState
          title="We ran into an error loading this customer"
          error={props.rootReq.error}
        />
      </PageContainer>
    );
  }

  if (props.rootReq.state === "not found") {
    return <NotFoundPage />;
  }

  const breadcrumbs =
    props.rootReq.state === "loading"
      ? Breadcrumbs.loading
      : typeof props.breadcrumbs === "function"
        ? props.breadcrumbs(props.rootReq as T & { state: "success" })
        : props.breadcrumbs;

  const content =
    typeof props.content !== "function" ? (
      props.content
    ) : props.rootReq.state === "loading" ? (
      <ParagraphSkeleton numLines={4} className="mt-12" />
    ) : (
      props.content(props.rootReq as T & { state: "success" })
    );

  const sidebar =
    typeof props.sidebar !== "function" ? (
      props.sidebar
    ) : props.rootReq.state === "loading" ? (
      <ParagraphSkeleton />
    ) : (
      props.sidebar(props.rootReq as T & { state: "success" })
    );

  return (
    <PageContainer
      title={breadcrumbs ?? Breadcrumbs.none}
      tabs={tabs.map((t) =>
        props.rootReq.state === "loading"
          ? {
              name: <TextSkeleton className="my-[10px] mr-8 !w-[60px]" />,
              onClick: () => {},
            }
          : t,
      )}
    >
      <div className="mb-12 h-full w-full max-w-[2400px] gap-24 lg:flex lg:flex-row">
        <div className="h-full shrink grow overflow-x-hidden">{content}</div>
        {sidebar && (
          <div className="min-h-[200px] w-[300px] shrink-0 grow-0">
            {sidebar}
          </div>
        )}
      </div>
    </PageContainer>
  );
}
