/* 06c52fd9c83ad60b28ce526aa9ae42f2618c9c22
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSalesforceCensusProvisionedStateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSalesforceCensusProvisionedStateQuery = { __typename?: 'Query', get_census_provisioned_state: { __typename?: 'CensusWorkspaceProvisionedState', id: string, is_provisioned: boolean, source_configured: boolean, destination_configured: boolean, environment_variables: { [key: string]: string | number | boolean } } | null };

export type CreateCensusWorkspaceMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateCensusWorkspaceMutation = { __typename?: 'Mutation', create_census_workspace: { __typename?: 'CensusWorkspace', id: string } };

export type GetSalesforceDestinationLinkMutationVariables = Types.Exact<{
  redirect_uri: Types.Scalars['String'];
}>;


export type GetSalesforceDestinationLinkMutation = { __typename?: 'Mutation', generate_census_destination_connect_link: { __typename?: 'CensusDestinationConnectLink', id: string, uri: string } };

export type SetSalesforceCensusWorkspaceEnvironmentVariablesMutationVariables = Types.Exact<{
  environment_variables: Types.Scalars['FlatKeyValueMap'];
}>;


export type SetSalesforceCensusWorkspaceEnvironmentVariablesMutation = { __typename?: 'Mutation', set_census_workspace_environment_variables: { __typename?: 'MutationResponse', success: boolean, error: string | null } };

export type CreateOrUpdateSalesforceCensusModelsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateOrUpdateSalesforceCensusModelsMutation = { __typename?: 'Mutation', create_or_update_census_models: { __typename?: 'MutationResponse', success: boolean, error: string | null } };

export type RefreshSalesforceDestinationObjectsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RefreshSalesforceDestinationObjectsMutation = { __typename?: 'Mutation', refresh_census_destination_objects: { __typename?: 'MutationResponse', success: boolean, error: string | null } };

export type CreateOrUpdateSalesforceCensusSyncsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateOrUpdateSalesforceCensusSyncsMutation = { __typename?: 'Mutation', create_or_update_census_syncs: { __typename?: 'MutationResponse', success: boolean, error: string | null } };


export const GetSalesforceCensusProvisionedStateDocument = gql`
    query getSalesforceCensusProvisionedState {
  get_census_provisioned_state(destination_type: SALESFORCE) {
    id
    is_provisioned
    source_configured
    destination_configured
    environment_variables
  }
}
    `;

/**
 * __useGetSalesforceCensusProvisionedStateQuery__
 *
 * To run a query within a React component, call `useGetSalesforceCensusProvisionedStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesforceCensusProvisionedStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesforceCensusProvisionedStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSalesforceCensusProvisionedStateQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>(GetSalesforceCensusProvisionedStateDocument, options);
      }
export function useGetSalesforceCensusProvisionedStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>(GetSalesforceCensusProvisionedStateDocument, options);
        }
export type GetSalesforceCensusProvisionedStateQueryHookResult = ReturnType<typeof useGetSalesforceCensusProvisionedStateQuery>;
export type GetSalesforceCensusProvisionedStateLazyQueryHookResult = ReturnType<typeof useGetSalesforceCensusProvisionedStateLazyQuery>;
export type GetSalesforceCensusProvisionedStateQueryResult = Apollo.QueryResult<GetSalesforceCensusProvisionedStateQuery, GetSalesforceCensusProvisionedStateQueryVariables>;
export const CreateCensusWorkspaceDocument = gql`
    mutation createCensusWorkspace {
  create_census_workspace(destination_type: SALESFORCE) {
    id
    __environment_type: environment_type
  }
}
    `;
export type CreateCensusWorkspaceMutationFn = Apollo.MutationFunction<CreateCensusWorkspaceMutation, CreateCensusWorkspaceMutationVariables>;

/**
 * __useCreateCensusWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateCensusWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCensusWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCensusWorkspaceMutation, { data, loading, error }] = useCreateCensusWorkspaceMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCensusWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateCensusWorkspaceMutation, CreateCensusWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCensusWorkspaceMutation, CreateCensusWorkspaceMutationVariables>(CreateCensusWorkspaceDocument, options);
      }
export type CreateCensusWorkspaceMutationHookResult = ReturnType<typeof useCreateCensusWorkspaceMutation>;
export type CreateCensusWorkspaceMutationResult = Apollo.MutationResult<CreateCensusWorkspaceMutation>;
export type CreateCensusWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateCensusWorkspaceMutation, CreateCensusWorkspaceMutationVariables>;
export const GetSalesforceDestinationLinkDocument = gql`
    mutation getSalesforceDestinationLink($redirect_uri: String!) {
  generate_census_destination_connect_link(
    destination_type: SALESFORCE
    redirect_uri: $redirect_uri
  ) {
    id
    uri
  }
}
    `;
export type GetSalesforceDestinationLinkMutationFn = Apollo.MutationFunction<GetSalesforceDestinationLinkMutation, GetSalesforceDestinationLinkMutationVariables>;

/**
 * __useGetSalesforceDestinationLinkMutation__
 *
 * To run a mutation, you first call `useGetSalesforceDestinationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSalesforceDestinationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSalesforceDestinationLinkMutation, { data, loading, error }] = useGetSalesforceDestinationLinkMutation({
 *   variables: {
 *      redirect_uri: // value for 'redirect_uri'
 *   },
 * });
 */
export function useGetSalesforceDestinationLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetSalesforceDestinationLinkMutation, GetSalesforceDestinationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSalesforceDestinationLinkMutation, GetSalesforceDestinationLinkMutationVariables>(GetSalesforceDestinationLinkDocument, options);
      }
export type GetSalesforceDestinationLinkMutationHookResult = ReturnType<typeof useGetSalesforceDestinationLinkMutation>;
export type GetSalesforceDestinationLinkMutationResult = Apollo.MutationResult<GetSalesforceDestinationLinkMutation>;
export type GetSalesforceDestinationLinkMutationOptions = Apollo.BaseMutationOptions<GetSalesforceDestinationLinkMutation, GetSalesforceDestinationLinkMutationVariables>;
export const SetSalesforceCensusWorkspaceEnvironmentVariablesDocument = gql`
    mutation setSalesforceCensusWorkspaceEnvironmentVariables($environment_variables: FlatKeyValueMap!) {
  set_census_workspace_environment_variables(
    destination_type: SALESFORCE
    environment_variables: $environment_variables
  ) {
    success
    error
  }
}
    `;
export type SetSalesforceCensusWorkspaceEnvironmentVariablesMutationFn = Apollo.MutationFunction<SetSalesforceCensusWorkspaceEnvironmentVariablesMutation, SetSalesforceCensusWorkspaceEnvironmentVariablesMutationVariables>;

/**
 * __useSetSalesforceCensusWorkspaceEnvironmentVariablesMutation__
 *
 * To run a mutation, you first call `useSetSalesforceCensusWorkspaceEnvironmentVariablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSalesforceCensusWorkspaceEnvironmentVariablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSalesforceCensusWorkspaceEnvironmentVariablesMutation, { data, loading, error }] = useSetSalesforceCensusWorkspaceEnvironmentVariablesMutation({
 *   variables: {
 *      environment_variables: // value for 'environment_variables'
 *   },
 * });
 */
export function useSetSalesforceCensusWorkspaceEnvironmentVariablesMutation(baseOptions?: Apollo.MutationHookOptions<SetSalesforceCensusWorkspaceEnvironmentVariablesMutation, SetSalesforceCensusWorkspaceEnvironmentVariablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSalesforceCensusWorkspaceEnvironmentVariablesMutation, SetSalesforceCensusWorkspaceEnvironmentVariablesMutationVariables>(SetSalesforceCensusWorkspaceEnvironmentVariablesDocument, options);
      }
export type SetSalesforceCensusWorkspaceEnvironmentVariablesMutationHookResult = ReturnType<typeof useSetSalesforceCensusWorkspaceEnvironmentVariablesMutation>;
export type SetSalesforceCensusWorkspaceEnvironmentVariablesMutationResult = Apollo.MutationResult<SetSalesforceCensusWorkspaceEnvironmentVariablesMutation>;
export type SetSalesforceCensusWorkspaceEnvironmentVariablesMutationOptions = Apollo.BaseMutationOptions<SetSalesforceCensusWorkspaceEnvironmentVariablesMutation, SetSalesforceCensusWorkspaceEnvironmentVariablesMutationVariables>;
export const CreateOrUpdateSalesforceCensusModelsDocument = gql`
    mutation createOrUpdateSalesforceCensusModels {
  create_or_update_census_models(destination_type: SALESFORCE) {
    success
    error
  }
}
    `;
export type CreateOrUpdateSalesforceCensusModelsMutationFn = Apollo.MutationFunction<CreateOrUpdateSalesforceCensusModelsMutation, CreateOrUpdateSalesforceCensusModelsMutationVariables>;

/**
 * __useCreateOrUpdateSalesforceCensusModelsMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateSalesforceCensusModelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateSalesforceCensusModelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateSalesforceCensusModelsMutation, { data, loading, error }] = useCreateOrUpdateSalesforceCensusModelsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateOrUpdateSalesforceCensusModelsMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateSalesforceCensusModelsMutation, CreateOrUpdateSalesforceCensusModelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateSalesforceCensusModelsMutation, CreateOrUpdateSalesforceCensusModelsMutationVariables>(CreateOrUpdateSalesforceCensusModelsDocument, options);
      }
export type CreateOrUpdateSalesforceCensusModelsMutationHookResult = ReturnType<typeof useCreateOrUpdateSalesforceCensusModelsMutation>;
export type CreateOrUpdateSalesforceCensusModelsMutationResult = Apollo.MutationResult<CreateOrUpdateSalesforceCensusModelsMutation>;
export type CreateOrUpdateSalesforceCensusModelsMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateSalesforceCensusModelsMutation, CreateOrUpdateSalesforceCensusModelsMutationVariables>;
export const RefreshSalesforceDestinationObjectsDocument = gql`
    mutation refreshSalesforceDestinationObjects {
  refresh_census_destination_objects(destination_type: SALESFORCE) {
    success
    error
  }
}
    `;
export type RefreshSalesforceDestinationObjectsMutationFn = Apollo.MutationFunction<RefreshSalesforceDestinationObjectsMutation, RefreshSalesforceDestinationObjectsMutationVariables>;

/**
 * __useRefreshSalesforceDestinationObjectsMutation__
 *
 * To run a mutation, you first call `useRefreshSalesforceDestinationObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshSalesforceDestinationObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshSalesforceDestinationObjectsMutation, { data, loading, error }] = useRefreshSalesforceDestinationObjectsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshSalesforceDestinationObjectsMutation(baseOptions?: Apollo.MutationHookOptions<RefreshSalesforceDestinationObjectsMutation, RefreshSalesforceDestinationObjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshSalesforceDestinationObjectsMutation, RefreshSalesforceDestinationObjectsMutationVariables>(RefreshSalesforceDestinationObjectsDocument, options);
      }
export type RefreshSalesforceDestinationObjectsMutationHookResult = ReturnType<typeof useRefreshSalesforceDestinationObjectsMutation>;
export type RefreshSalesforceDestinationObjectsMutationResult = Apollo.MutationResult<RefreshSalesforceDestinationObjectsMutation>;
export type RefreshSalesforceDestinationObjectsMutationOptions = Apollo.BaseMutationOptions<RefreshSalesforceDestinationObjectsMutation, RefreshSalesforceDestinationObjectsMutationVariables>;
export const CreateOrUpdateSalesforceCensusSyncsDocument = gql`
    mutation createOrUpdateSalesforceCensusSyncs {
  create_or_update_census_syncs(destination_type: SALESFORCE, trigger_sync: true) {
    success
    error
  }
}
    `;
export type CreateOrUpdateSalesforceCensusSyncsMutationFn = Apollo.MutationFunction<CreateOrUpdateSalesforceCensusSyncsMutation, CreateOrUpdateSalesforceCensusSyncsMutationVariables>;

/**
 * __useCreateOrUpdateSalesforceCensusSyncsMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateSalesforceCensusSyncsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateSalesforceCensusSyncsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateSalesforceCensusSyncsMutation, { data, loading, error }] = useCreateOrUpdateSalesforceCensusSyncsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateOrUpdateSalesforceCensusSyncsMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateSalesforceCensusSyncsMutation, CreateOrUpdateSalesforceCensusSyncsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateSalesforceCensusSyncsMutation, CreateOrUpdateSalesforceCensusSyncsMutationVariables>(CreateOrUpdateSalesforceCensusSyncsDocument, options);
      }
export type CreateOrUpdateSalesforceCensusSyncsMutationHookResult = ReturnType<typeof useCreateOrUpdateSalesforceCensusSyncsMutation>;
export type CreateOrUpdateSalesforceCensusSyncsMutationResult = Apollo.MutationResult<CreateOrUpdateSalesforceCensusSyncsMutation>;
export type CreateOrUpdateSalesforceCensusSyncsMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateSalesforceCensusSyncsMutation, CreateOrUpdateSalesforceCensusSyncsMutationVariables>;