import React, { useMemo } from "react";
import { getGroupKeys } from "lib/billableMetrics";
import { Column, Table } from "tenaissance/components/Table";
import { RadioButton } from "tenaissance/components/RadioButton";
import { AvatarWithName } from "design-system";
import { Timestamp } from "tenaissance/components/Timestamp";
import { SectionHeader } from "tenaissance/components/SectionHeader";
import { InputDropdown } from "tenaissance/components/InputDropdown";
import { DropdownItem } from "tenaissance/components/Dropdown";
import { TextInput } from "tenaissance/components/Input";
import { Toggle } from "tenaissance/components/Toggle";
import { ButtonGroup } from "tenaissance/components/ButtonGroup";
import {
  ConversionOperation,
  RoundingMethod,
} from "types/generated-graphql/__types__";
import { Badge } from "tenaissance/components/Badge";
import { BillableMetricsType, ProductContext } from "./ProductContext";
import { NetSuiteFields } from "./NetSuiteFields";

export const UsageProductFields: React.FC = () => {
  const {
    billableMetrics,
    setSelectedMetric,
    productsLoading,
    usageProductFields,
    setUsageProductFields,
  } = ProductContext.useContainer();
  const allGroupKeys = useMemo(() => {
    const billableMetric = billableMetrics.find(
      (bm) => bm.id === usageProductFields.billableMetricId,
    );

    if (!billableMetric) {
      return [];
    }

    return getGroupKeys(billableMetric).map((key) =>
      Array.isArray(key) ? key : [key],
    );
  }, [usageProductFields.billableMetricId]);

  const validGroupKeyOptions = useMemo(() => {
    if (!allGroupKeys?.length) {
      return [];
    }
    const inUseKeys = (usageProductFields.presentationGroupKey ?? []).concat(
      usageProductFields.pricingGroupKey ?? [],
    );
    const filteredGroupKeys = allGroupKeys.filter((key) => {
      return inUseKeys.every((k) => key.includes(k));
    });

    const deduplicatedKeys = Array.from(new Set(filteredGroupKeys.flat()));

    return deduplicatedKeys.map((key) => {
      const applicableCompoundKeys = filteredGroupKeys
        .filter((item) => item.includes(key))
        .map((item) => {
          return Array.isArray(item) ? `[${item.join(", ")}]` : item;
        });

      return {
        label: key,
        value: key,
        subtext: applicableCompoundKeys.length
          ? `${applicableCompoundKeys.join(", ")}`
          : undefined,
      };
    });
  }, [
    allGroupKeys,
    usageProductFields.presentationGroupKey,
    usageProductFields.pricingGroupKey,
  ]);

  const [conversionFactorError, setConversionFactorError] = React.useState<
    string | null
  >(null);
  const [roundQuantityError, setRoundQuantityError] = React.useState<
    string | null
  >(null);

  const billableMetricColumns: Column<BillableMetricsType>[] = useMemo(
    () => [
      {
        id: "1",
        header: "Name",
        cell: ({ row }) => {
          const { name, selected } = row.original;
          return (
            <RadioButton
              label={name}
              checked={selected}
              onClick={() => setSelectedMetric(row.original)}
              value=""
            />
          );
        },
        accessorKey: "name",
        enableSorting: false,
      },
      {
        id: "2",
        accessorKey: "aggregate",
        cell: ({ row }) => {
          const aggregate = row.original.aggregate;
          const sql = row.original.sql;

          const getBadgeLabel = () => {
            if (sql) {
              return "SQL";
            }
            switch (aggregate) {
              case "sum":
                return "Sum";
              case "count":
                return "Count";
              case "max":
                return "Max";
              case "unique":
                return "Unique";
              default:
                return "";
            }
          };

          const badgeLabel = getBadgeLabel();

          const getBadgeTheme = () => {
            if (sql) {
              return "success";
            }
            switch (aggregate) {
              case "sum":
                return "indigo";
              case "count":
                return "deep-denim";
              case "max":
                return "warning";
              case "unique":
                return "midnight";
              default:
                return undefined;
            }
          };

          const badgeTheme = getBadgeTheme();

          return <Badge label={badgeLabel} theme={badgeTheme} />;
        },
        header: "Aggregate",
        enableSorting: false,
      },
      {
        id: "3",
        header: "User",
        cell: ({ row }) => {
          const creator = row.original.Creator;
          return (
            <div className="pl-8">
              {creator && <AvatarWithName {...creator} />}
            </div>
          );
        },
        accessorKey: "Creator.name",
        enableSorting: false,
      },
      {
        id: "4",
        accessorKey: "updated_at",
        cell: ({ row }) => {
          return <Timestamp dateTime={new Date(row.original.updated_at)} />;
        },
        header: () => {
          return <div className="flex">Last edited (UTC)</div>;
        },
        enableSorting: false,
      },
    ],
    [],
  );

  const validateConversionFactor = (value: string): string | null => {
    if (!value) {
      return null;
    }

    const numberValue = Number(value);

    if (isNaN(numberValue)) {
      return "Conversion factor must be a number";
    } else if (numberValue <= 0) {
      return "Conversion factor must be greater than 0";
    }

    return null;
  };

  const handleConversionFactorChange = (meta: { value: string }) => {
    const error = validateConversionFactor(meta.value);
    setConversionFactorError(error);

    const updatedQuantityConversionOperation =
      usageProductFields.quantityConversion?.operation;

    if (!error && updatedQuantityConversionOperation) {
      setUsageProductFields({
        ...usageProductFields,
        quantityConversion: {
          operation: updatedQuantityConversionOperation,
          conversionFactor: parseFloat(meta.value),
        },
      });
    } else {
      setUsageProductFields({
        ...usageProductFields,
        ...usageProductFields.quantityConversion,
        quantityConversion: usageProductFields.quantityConversion,
      });
    }
  };

  const conversionFactorHintText = () => {
    if (conversionFactorError) {
      return conversionFactorError;
    } else {
      if (usageProductFields.quantityConversion?.operation === "Divide") {
        return "The quantity will be divided by the conversion factor";
      } else if (
        usageProductFields.quantityConversion?.operation === "Multiply"
      ) {
        return "The quantity will be multiplied by the conversion factor";
      } else {
        return "";
      }
    }
  };

  const validateRoundingConversion = (value: string): string | null => {
    const numberValue = Number(value);

    if (isNaN(numberValue)) {
      return "Decimal places must be a number";
    } else if (numberValue < 0) {
      return "Decimal places must be greater than or equal to 0";
    } else if (numberValue % 1 !== 0) {
      return "Decimal places must be an integer";
    }

    return null;
  };

  const handleRoundingChange = (meta: { value: string }) => {
    const error = validateRoundingConversion(meta.value);
    setRoundQuantityError(error);

    const updatedRoundingMethod =
      usageProductFields.quantityRounding?.roundingMethod;

    if (!error && updatedRoundingMethod) {
      setUsageProductFields({
        ...usageProductFields,
        quantityRounding: {
          roundingMethod: updatedRoundingMethod,
          decimalPlaces: Number(meta.value).valueOf(),
        },
      });
    } else {
      setUsageProductFields({
        ...usageProductFields,
        ...usageProductFields.quantityRounding,
        quantityRounding: usageProductFields.quantityRounding,
      });
    }
  };

  const roundingHintText = () => {
    if (roundQuantityError) {
      return roundQuantityError;
    } else {
      return "The number of decimal place to round. Enter 0 to round to the nearest integer.";
    }
  };

  return (
    <div className="mt-[40px] grid">
      <div className="grid gap-[24px]">
        <SectionHeader
          title="Select a billable metric"
          subtitle="Usage products are associated to a billable metric. This will determine how events are filtered and aggregated."
          bottomBorder={false}
        />
        <div className="ml-4 w-full">
          <Table
            title="Billable metrics"
            columns={billableMetricColumns}
            data={billableMetrics}
            loading={productsLoading}
            paginationOptions={{
              type: "clientSide",
              minimumPageSize: 10,
            }}
            key="metrics-table"
            searchOptions={{
              showSearch: true,
            }}
            autoResetPageIndex={false}
          />
        </div>
        {(allGroupKeys?.length ?? 0) > 0 && (
          <div className="mt-20">
            <SectionHeader
              title="Define presentation and dimensional pricing groups (optional)"
              subtitle="Presentation keys allow you to group events on an invoice. Dimensional pricing enables a single product to have variable rates based on the properties of an event."
              bottomBorder={false}
            />
            <div className="ml-4 mt-20">
              <InputDropdown
                value={usageProductFields.presentationGroupKey}
                hintText="Presentation keys define how to group events on an invoice (i.e. group events by environment)"
                label="Presentation keys"
                placeholder="Select keys"
                tagsVariant={true}
                onChangeTags={(meta: { value: string[] }) => {
                  setUsageProductFields({
                    ...usageProductFields,
                    presentationGroupKey: meta.value,
                  });
                }}
                leadingIcon="searchSm"
                fullWidth
              >
                {validGroupKeyOptions.map((u, index) => (
                  <DropdownItem
                    label={u.label}
                    value={u.value}
                    subtext={u.subtext}
                    key={"presentationGroupKey" + index}
                    onClick={({ selected }) => {
                      const prevTags =
                        usageProductFields.presentationGroupKey ?? [];
                      selected
                        ? setUsageProductFields({
                            ...usageProductFields,
                            presentationGroupKey: prevTags.filter(
                              (t) => t !== u.value,
                            ),
                          })
                        : setUsageProductFields({
                            ...usageProductFields,
                            presentationGroupKey: [...prevTags, u.value],
                          });
                    }}
                    selected={usageProductFields.presentationGroupKey?.includes(
                      u.value,
                    )}
                  />
                ))}
              </InputDropdown>
            </div>
            <div className="ml-4 mt-20">
              <InputDropdown
                value={usageProductFields.pricingGroupKey}
                hintText="Dimensional pricing keys enable you to price events uniquely based on their values"
                label="Dimensional pricing keys"
                placeholder="Select keys"
                tagsVariant={true}
                onChangeTags={(meta: { value: string[] }) => {
                  setUsageProductFields({
                    ...usageProductFields,
                    pricingGroupKey: meta.value,
                  });
                }}
                leadingIcon="searchSm"
                fullWidth
              >
                {validGroupKeyOptions.map((u, index) => (
                  <DropdownItem
                    label={u.label}
                    value={u.value}
                    subtext={u.subtext}
                    key={"pricingGroupKey" + index}
                    onClick={({ selected }) => {
                      const prevTags = usageProductFields.pricingGroupKey ?? [];
                      selected
                        ? setUsageProductFields({
                            ...usageProductFields,
                            pricingGroupKey: prevTags.filter(
                              (t) => t !== u.value,
                            ),
                          })
                        : setUsageProductFields({
                            ...usageProductFields,
                            pricingGroupKey: [...prevTags, u.value],
                          });
                    }}
                    selected={usageProductFields.pricingGroupKey?.includes(
                      u.value,
                    )}
                  />
                ))}
              </InputDropdown>
            </div>
          </div>
        )}
        <NetSuiteFields />
      </div>
      <div className="mt-[40px] grid gap-[24px]">
        <SectionHeader
          title="Add quantity conversion and rounding to your usage product (optional)"
          subtitle="These options allow you to adjust the quantity on the invoice before pricing is applied. They can be used used together or independently."
          bottomBorder={false}
        />
        <Toggle
          toggled={!!usageProductFields.quantityConversion}
          onChange={({ toggled }) => {
            if (toggled) {
              setUsageProductFields({
                ...usageProductFields,
                quantityConversion: {
                  conversionFactor: 1,
                  operation: ConversionOperation.Multiply,
                },
              });
            } else {
              setUsageProductFields({
                ...usageProductFields,
                quantityConversion: undefined,
              });
            }
          }}
          label="Convert quantity"
          supportingText="Convert the total quantity on the invoice to support use cases like, bytes to giga-bytes."
          // TODO: add tooltip
          // tooltip="You can specify a conversion factor which will apply to the quantity before pricing."
        />
        {usageProductFields.quantityConversion && (
          <div className="flex flex-col gap-[24px]">
            <ButtonGroup
              buttons={[
                {
                  onClick: () => {
                    setUsageProductFields({
                      ...usageProductFields,
                      quantityConversion: {
                        conversionFactor:
                          usageProductFields.quantityConversion
                            ?.conversionFactor ?? 0,
                        operation: ConversionOperation.Multiply,
                      },
                    });
                  },
                  text: "Multiply quantity",
                  isActive:
                    usageProductFields.quantityConversion.operation ===
                    "Multiply",
                },
                {
                  onClick: () => {
                    setUsageProductFields({
                      ...usageProductFields,
                      quantityConversion: {
                        conversionFactor:
                          usageProductFields.quantityConversion
                            ?.conversionFactor ?? 0,
                        operation: ConversionOperation.Divide,
                      },
                    });
                  },
                  text: "Divide quantity",
                  isActive:
                    usageProductFields.quantityConversion.operation ===
                    "Divide",
                },
              ]}
            />
            <div className="gap-lg flex flex-row">
              <TextInput
                label="Conversion factor"
                placeholder="Enter the conversion"
                fullWidth
                isInvalid={!!conversionFactorError}
                value={
                  usageProductFields.quantityConversion?.conversionFactor
                    ? usageProductFields.quantityConversion?.conversionFactor.toString()
                    : ""
                }
                onChange={handleConversionFactorChange}
                hintText={conversionFactorHintText()}
              />
            </div>
          </div>
        )}
        <Toggle
          toggled={!!usageProductFields.quantityRounding}
          onChange={({ toggled }) => {
            if (toggled) {
              setUsageProductFields({
                ...usageProductFields,
                quantityRounding: {
                  roundingMethod: RoundingMethod.Ceiling,
                  decimalPlaces: 0,
                },
              });
            } else {
              setUsageProductFields({
                ...usageProductFields,
                quantityRounding: undefined,
              });
            }
          }}
          label="Round quantity"
          supportingText="Rounding is applied after quantity conversion, supporting use cases including block pricing."
          // TODO: add tooltip
          // tooltip="You can specify a rounding configuration which will apply to the quantity before pricing."
        />
        {usageProductFields.quantityRounding && (
          <div className="flex flex-col gap-[24px]">
            <ButtonGroup
              buttons={[
                {
                  onClick: () => {
                    setUsageProductFields({
                      ...usageProductFields,
                      quantityRounding: {
                        roundingMethod: RoundingMethod.Ceiling,
                        decimalPlaces: 0,
                      },
                    });
                  },
                  text: "Round up",
                  isActive:
                    usageProductFields.quantityRounding?.roundingMethod ===
                    "Ceiling",
                },
                {
                  onClick: () => {
                    setUsageProductFields({
                      ...usageProductFields,
                      quantityRounding: {
                        roundingMethod: RoundingMethod.HalfUp,
                        decimalPlaces: 0,
                      },
                    });
                  },
                  text: "Round by half",
                  isActive:
                    usageProductFields.quantityRounding?.roundingMethod ===
                    "HalfUp",
                },
                {
                  onClick: () => {
                    setUsageProductFields({
                      ...usageProductFields,
                      quantityRounding: {
                        roundingMethod: RoundingMethod.Floor,
                        decimalPlaces: 0,
                      },
                    });
                  },
                  text: "Round down",
                  isActive:
                    usageProductFields.quantityRounding?.roundingMethod ===
                    "Floor",
                },
              ]}
            />
            <div className="gap-lg flex flex-row">
              <TextInput
                label="Decimal places"
                placeholder="Enter the conversion"
                fullWidth
                isInvalid={!!roundQuantityError}
                value={
                  usageProductFields.quantityRounding?.decimalPlaces
                    ? usageProductFields.quantityRounding?.decimalPlaces.toString()
                    : ""
                }
                onChange={handleRoundingChange}
                hintText={roundingHintText()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
