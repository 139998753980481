import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, Tooltip } from "design-system";
import { CopyableID } from "tenaissance/components/CopyableID";

export const TransactionIDCell: React.FC<{
  event_id: string;
  transaction_id: string;
  duplicate: boolean;
}> = ({ transaction_id, duplicate, event_id }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  params.set("transactionID", transaction_id);
  params.set("eventID", event_id);
  const filterLink = `${location.pathname}?${params.toString()}`;
  return (
    <div className="text-font-color text-12 leading-20 [a]:no-underline flex items-center font-mono font-normal [&:focus]:underline [&:hover]:underline">
      {duplicate ? (
        <Tooltip content="This event was not ingested because it had the same transaction ID of previously ingested event.">
          <Icon
            icon="close"
            className="-ml-[2px] mr-[6px] text-[16px] !text-error-600"
          />
        </Tooltip>
      ) : null}
      <CopyableID id={transaction_id} label="transaction ID" hideID />
      <Link to={filterLink}>{transaction_id}</Link>
    </div>
  );
};
