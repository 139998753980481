/* dd44c22603762c3d1ec5417a5c3cf78107b803e0
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchivePlanMutationVariables = Types.Exact<{
  plan_id: Types.Scalars['uuid'];
}>;


export type ArchivePlanMutation = { __typename?: 'Mutation', archive_plan: { __typename?: 'Plan', id: string, deprecated_at: string | null } };


export const ArchivePlanDocument = gql`
    mutation ArchivePlan($plan_id: uuid!) {
  archive_plan(plan_id: $plan_id) {
    id
    deprecated_at
    __environment_type: environment_type
  }
}
    `;
export type ArchivePlanMutationFn = Apollo.MutationFunction<ArchivePlanMutation, ArchivePlanMutationVariables>;

/**
 * __useArchivePlanMutation__
 *
 * To run a mutation, you first call `useArchivePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePlanMutation, { data, loading, error }] = useArchivePlanMutation({
 *   variables: {
 *      plan_id: // value for 'plan_id'
 *   },
 * });
 */
export function useArchivePlanMutation(baseOptions?: Apollo.MutationHookOptions<ArchivePlanMutation, ArchivePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchivePlanMutation, ArchivePlanMutationVariables>(ArchivePlanDocument, options);
      }
export type ArchivePlanMutationHookResult = ReturnType<typeof useArchivePlanMutation>;
export type ArchivePlanMutationResult = Apollo.MutationResult<ArchivePlanMutation>;
export type ArchivePlanMutationOptions = Apollo.BaseMutationOptions<ArchivePlanMutation, ArchivePlanMutationVariables>;