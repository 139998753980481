import { DraftPlan } from "lib/plans/types";
import {
  getPriceRampDurations,
  getRamps,
  getRampStartPeriods,
} from "lib/plans/ramps";

// The main use case for the newStartPeriod is to add a ramp at the end of the existing ramps
// If new start period is in the middle of an existing ramp, it will split the ramp on the new start period
export const addRamp = (draftPlan: DraftPlan, newStartPeriod?: number) => {
  if (newStartPeriod == null) {
    return {
      ...draftPlan,
      ramps: draftPlan.ramps ? draftPlan.ramps.concat({}) : [{}],
    };
  }
  const startPeriods = new Set(getRampStartPeriods(getRamps(draftPlan)));
  startPeriods.add(newStartPeriod);
  return {
    ...draftPlan,
    ramps: getPriceRampDurations(startPeriods),
  };
};

export const removeRamp = (draftPlan: DraftPlan, i: number) => {
  if (!draftPlan.ramps || draftPlan.ramps.length <= i) {
    throw new Error(
      `Not enough ramps in draft plan to remove ramp at index ${i}`,
    );
  }
  const removedRampLength = draftPlan.ramps[i].durationInPeriods;
  const removedStartPeriod = draftPlan.ramps
    .slice(0, i)
    .reduce(
      (sum, ramp) =>
        ramp.durationInPeriods !== undefined && sum !== undefined
          ? sum + ramp.durationInPeriods
          : undefined,
      0 as number | undefined,
    );
  let pricedProducts = draftPlan.pricedProducts;
  let minimums = draftPlan.minimums;
  if (removedRampLength && removedStartPeriod !== undefined) {
    // Remove all minumums and pricing factors from the ramp we're removing and shift all
    // later ones to have a correct start period.
    if (pricedProducts) {
      pricedProducts = pricedProducts.map((pp) => ({
        ...pp,
        pricingFactors: pp.pricingFactors
          .filter((ppf) => ppf.startPeriod !== removedStartPeriod)
          .map((ppf) => ({
            ...ppf,
            startPeriod:
              ppf.startPeriod && ppf.startPeriod > removedStartPeriod
                ? ppf.startPeriod - removedRampLength
                : ppf.startPeriod,
          })),
      }));
    }
    if (minimums) {
      minimums = minimums
        .filter((m) => m.startPeriod !== removedStartPeriod)
        .map((m) => ({
          ...m,
          startPeriod:
            m.startPeriod && m.startPeriod > removedStartPeriod
              ? m.startPeriod - removedRampLength
              : m.startPeriod,
        }));
    }
  }
  return {
    ...draftPlan,
    ramps: [...draftPlan.ramps.slice(0, i), ...draftPlan.ramps.slice(i + 1)],
    pricedProducts,
    minimums,
  };
};

export const changeRamp = (draftPlan: DraftPlan, i: number, value: number) => {
  // Allow implicitly setting the first ramp with changeRamp at index 0.
  const ramps =
    draftPlan.ramps && draftPlan.ramps.length ? draftPlan.ramps : [{}];
  if (ramps.length <= i) {
    throw new Error(
      `Not enough ramps in draft plan to change ramp at index ${i}`,
    );
  }
  const changedRampLength = ramps[i].durationInPeriods;
  const changedRampStartPeriod = ramps
    .slice(0, i)
    .reduce(
      (sum, ramp) =>
        ramp.durationInPeriods !== undefined && sum !== undefined
          ? sum + ramp.durationInPeriods
          : undefined,
      0 as number | undefined,
    );
  const startPeriodShift = changedRampLength
    ? value - changedRampLength
    : undefined;
  let pricedProducts = draftPlan.pricedProducts;
  let minimums = draftPlan.minimums;
  if (startPeriodShift && changedRampStartPeriod !== undefined) {
    // Shift all later minimums and pricing factors to have the correct start period.
    if (pricedProducts) {
      pricedProducts = pricedProducts.map((pp) => ({
        ...pp,
        pricingFactors: pp.pricingFactors.map((ppf) => ({
          ...ppf,
          startPeriod:
            ppf.startPeriod && ppf.startPeriod > changedRampStartPeriod
              ? ppf.startPeriod + startPeriodShift
              : ppf.startPeriod,
        })),
      }));
    }
    if (minimums) {
      minimums = minimums.map((m) => ({
        ...m,
        startPeriod:
          m.startPeriod && m.startPeriod > changedRampStartPeriod
            ? m.startPeriod + startPeriodShift
            : m.startPeriod,
      }));
    }
  }
  return {
    ...draftPlan,
    ramps: [
      ...ramps.slice(0, i),
      { durationInPeriods: value },
      ...ramps.slice(i + 1),
    ],
    pricedProducts,
    minimums,
  };
};

export const clearRamps = (draftPlan: DraftPlan) => {
  return {
    ...draftPlan,
    ramps: undefined,
    pricedProducts: draftPlan.pricedProducts?.map((pp) => ({
      ...pp,
      pricingFactors: pp.pricingFactors.filter(
        (pppf) => pppf.startPeriod === 0,
      ),
    })),
    minimums: draftPlan.minimums?.filter((m) => m.startPeriod === 0),
  };
};
