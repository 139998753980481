import React, { useState } from "react";
import { Badge, ButtonDropdown } from "design-system";

import { TablePanel } from "components/TablePanel";
import { Column, SortFunctions, SortRule } from "components/Table";
import { useAuthCheck } from "lib/useAuthCheck";
import { printDateRange, useNow, toDayjs } from "lib/date";

import {
  CustomerBillingOverviewFragment,
  useIsDeltaStreamEnabledQuery,
} from "./data.graphql";
import { AddPlanToCustomerDocument } from "pages/AddPlanToCustomer/queries.graphql";
import { CreateContractDocument } from "../Contracts/Create/data.graphql";
import { ContractOrPlan } from "../../lib/ContractOrPlan";
import { ColWidths } from "pages/Contracts/lib/ColWidths";
import { FilterDropdown } from "components/Filter";

interface Props {
  loading: boolean;
  customer?: CustomerBillingOverviewFragment;
  defaultSortBy: SortRule[];
}

type Filter = {
  active: boolean;
  upcoming: boolean;
  ended: boolean;
  archived: boolean;
};

const DEFAULT_FILTER: Filter = {
  active: true,
  upcoming: true,
  ended: true,
  archived: false,
};

export const ConnectedContractsAndPlans: React.FC<Props> = (props) => {
  const now = useNow();
  const canCreateContract = useAuthCheck(CreateContractDocument, false);
  const canCreatePlan = useAuthCheck(AddPlanToCustomerDocument, false);
  const { data: isDeltaStreamEnabled } = useIsDeltaStreamEnabledQuery();

  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const contractsAndPlans = [
    ...(props.customer?.contracts ?? []),
    ...(props.customer?.CustomerPlans ?? []),
  ];
  const contractsAndPlansToShow = contractsAndPlans.filter((contractOrPlan) => {
    const status = ContractOrPlan.getStatus(contractOrPlan, now);

    switch (status) {
      case "active":
      case "ending-soon":
        return filter.active;
      case "active-soon":
      case "upcoming":
        return filter.upcoming;
      case "archived":
        return filter.archived;
      case "inactive (renewed)":
      case "inactive (superseded)":
      case "recently-ended":
      case "ended":
        return filter.ended;
      default:
        const exhaustiveCheck: never = status;
        throw new Error(
          `Unhandled contract or plan status case: ${exhaustiveCheck}`,
        );
    }
  });
  type ContractOrPlan = (typeof contractsAndPlansToShow)[0];
  function removeBillingProviderRowIfNotDeltaStream(
    column: Column<ContractOrPlan>,
  ) {
    if (!isDeltaStreamEnabled?.is_delta_stream_enabled) {
      return column.id !== "billing-provider";
    } else {
      return true;
    }
  }

  return (
    <TablePanel
      title="Contracts & plans"
      loading={props.loading}
      skeletonRows={1}
      data={contractsAndPlansToShow}
      rowRoutePath={(row) => ContractOrPlan.getRoutePath(row)}
      controls={
        !props.customer ? undefined : (
          <div className="flex gap-8">
            <FilterDropdown
              className="test"
              size="small"
              align="end"
              position="top"
              onReset={() => setFilter(DEFAULT_FILTER)}
              menu={[
                {
                  label: "Filter by status",
                  items: [
                    {
                      type: "checkbox",
                      checked: filter.active,
                      label: "Active",
                      onClick: () =>
                        setFilter({ ...filter, active: !filter.active }),
                    },
                    {
                      type: "checkbox",
                      checked: filter.upcoming,
                      label: "Upcoming",
                      onClick: () =>
                        setFilter({ ...filter, upcoming: !filter.upcoming }),
                    },
                    {
                      type: "checkbox",
                      checked: filter.ended,
                      label: "Ended",
                      onClick: () =>
                        setFilter({ ...filter, ended: !filter.ended }),
                    },
                    {
                      type: "checkbox",
                      checked: filter.archived,
                      label: "Archived",
                      onClick: () =>
                        setFilter({ ...filter, archived: !filter.archived }),
                    },
                  ],
                },
              ]}
            />
            <ButtonDropdown
              title="NEW CONTRACT OR PLAN"
              faket10={true}
              buttonType="fill"
              buttonTheme="primary"
              items={[
                {
                  label: "New contract...",
                  disabled: !canCreateContract,
                  routePath: `/customers/${props.customer?.id}/contracts/add`,
                },
                {
                  label: "New plan...",
                  disabled: !canCreatePlan,
                  routePath: `/customers/${props.customer?.id}/plans/add`,
                },
              ]}
            />
          </div>
        )
      }
      defaultSortBy={props.defaultSortBy}
      columns={[
        {
          id: "dates",
          header: "Effective dates",
          cellClassName: ColWidths.DATE,
          sortable: true,
          comparator: SortFunctions.Date(
            (row: ContractOrPlan) =>
              new Date(
                row.__typename === "Contract"
                  ? row.starting_at
                  : row.start_date,
              ),
          ),
          render: (row: ContractOrPlan): string => {
            switch (row.__typename) {
              case "Contract":
                return printDateRange(
                  now,
                  toDayjs(row.starting_at),
                  row.ending_before ? toDayjs(row.ending_before) : null,
                );
              case "CustomerPlan":
                return printDateRange(
                  now,
                  toDayjs(row.start_date),
                  row.cancellation_date ? toDayjs(row.cancellation_date) : null,
                );
            }
          },
        },
        {
          id: "name",
          header: "Name",
          sortable: true,
          comparator: SortFunctions.String((row: ContractOrPlan) =>
            ContractOrPlan.getName(row),
          ),
          render: (row: ContractOrPlan) => ContractOrPlan.getName(row),
        },
        {
          id: "rate-card-name",
          header: "Rate card name",
          sortable: true,
          comparator: SortFunctions.String((row: ContractOrPlan) =>
            ContractOrPlan.getRateCardName(row),
          ),
          render: (row: ContractOrPlan) => ContractOrPlan.getRateCardName(row),
        },
        {
          id: "billing-provider",
          header: "Billing provider",
          sortable: true,
          comparator: SortFunctions.String(ContractOrPlan.getBillingProvider),
          render: ContractOrPlan.getBillingProvider,
        },
        {
          id: "type",
          header: "Type",
          align: "right" as const,
          cellClassName: "w-[100px]",
          sortable: true,
          comparator: SortFunctions.String((row: ContractOrPlan) =>
            row.__typename === "Contract" ? "Contract" : "Plan",
          ),
          render(row: ContractOrPlan) {
            return row.__typename === "Contract" ? "Contract" : "Plan";
          },
        },
        {
          id: "status",
          header: "Status",
          align: "right" as const,
          cellClassName: ColWidths.STATUS,
          sortable: true,
          comparator: SortFunctions.String((row: ContractOrPlan) =>
            ContractOrPlan.getStatus(row, now).toString(),
          ),
          render(row: ContractOrPlan) {
            const status = ContractOrPlan.getStatus(row, now);

            return (
              <Badge
                theme={
                  status === "active"
                    ? "primary"
                    : status === "ending-soon" ||
                        status === "ended" ||
                        status.startsWith("inactive")
                      ? "warning"
                      : "grey"
                }
                type="light"
              >
                {status.toUpperCase()}
              </Badge>
            );
          },
        },
      ].filter(removeBillingProviderRowIfNotDeltaStream)}
      emptyState={
        <p className="p-24 text-center text-sm text-gray-500">
          {contractsAndPlans.length === 0
            ? "Customer does not have any connected contracts or plans"
            : "No contracts or plans match the selected filters."}
        </p>
      }
    />
  );
};
