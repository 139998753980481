import React from "react";
import { useEnvironment } from "./context";
import { Toggle, Tooltip, Select } from "design-system";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";

const environmentOrder = [
  EnvironmentTypeEnum_Enum.Production,
  EnvironmentTypeEnum_Enum.Uat,
  EnvironmentTypeEnum_Enum.Qa,
  EnvironmentTypeEnum_Enum.Staging,
  EnvironmentTypeEnum_Enum.Sandbox,
];

const EnvironmentSwitcher: React.FC<{}> = () => {
  const {
    environments,
    supported,
    environment,
    changingSupported,
    setEnvironment,
  } = useEnvironment();

  const showToggle = environments.length === 2;
  const enabledEnvironments = environments.filter((e) => e.enabled);
  const hideSwitcher = enabledEnvironments.length < 2;

  return (
    <div
      className={[
        "mt-auto w-full px-12 pt-[10px]",
        (hideSwitcher || !supported) && "invisible",
      ]
        .filter((s) => !!s)
        .join(" ")}
    >
      {showToggle ? (
        <Tooltip
          content={`Can't switch ${
            environment.id === "SANDBOX" ? "out of" : "into"
          } sandbox mode while viewing a specific resource.`}
          disabled={changingSupported}
        >
          <Toggle
            checked={environment.id === "SANDBOX"}
            label="Sandbox mode"
            theme="warning"
            disabled={!changingSupported}
            onChange={(val) => {
              const newEnvironment = environments.find(
                (e) => e.id === (val ? "SANDBOX" : "PRODUCTION"),
              );

              if (newEnvironment) {
                setEnvironment(newEnvironment);
              }
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          content="Can't switch environments while viewing a specific resource."
          disabled={changingSupported}
        >
          <Select
            disabled={!changingSupported}
            menuPlacement="top"
            className="w-full"
            onChange={(val) => {
              const newEnvironment = environments.find((e) => e.id === val);

              if (newEnvironment) {
                setEnvironment(newEnvironment);
              }
            }}
            placeholder="Environment"
            value={environment.id}
            options={environmentOrder
              .map((et) => environments.find((e) => e.type === et))
              .filter((e) => !!e)
              .map((e) => ({
                label: e?.name ?? "",
                value: e?.id ?? "",
                disabled: !(e?.enabled ?? true),
              }))}
          ></Select>
        </Tooltip>
      )}
    </div>
  );
};

export default EnvironmentSwitcher;
