import React, { useState } from "react";

import { Body, Input, Select } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { useSnackbar } from "components/Snackbar";

import {
  useGetStripeCustomerQuery,
  useInsertCustomerBillingProviderConfigurationMutation,
  useUpdateStripeBillingProviderCustomerMutation,
  UpdateStripeBillingProviderCustomerMutation,
  InsertCustomerBillingProviderConfigurationMutation,
  UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation,
  useUpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation,
} from "./queries.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { ApolloCache, FetchResult } from "@apollo/client";
import { Tooltip } from "tenaissance/components/Tooltip";
import { reportToSentry } from "lib/errors/sentry";
import { PlansAndOrContracts } from "lib/billingProvider/billingProviderSettings";

type StripeProvisioningStatus = {
  hasBillingProviderOnPlan: boolean;
  hasStripeOnPlan: boolean;
  hasStripeOnContract: boolean;
  hasStripeBillingProviderToken: boolean;
  hasStripeBillingProviderDeliveryMethod: boolean;
};

type StripeSettingsModalProps = {
  onClose: (deleteConfig?: boolean) => void;
  customerId: string;
  // currently a customer can only have one stripe configuration for contracts
  // if that changes, this will need to be updated
  edit?: PlansAndOrContracts | false;
  stripeCustomerID?: string;
  stripeCollectionMethod?: string;
} & StripeProvisioningStatus;

const evictCacheOptions = {
  update(cache: ApolloCache<any>) {
    cache.evict({
      fieldName: "stripe_billing_provider_configs",
    });
    cache.evict({
      fieldName: "customer_billing_provider_configurations",
    });
    cache.evict({
      fieldName: "BillingProviderCustomer",
    });
    cache.evict({
      fieldName: "CustomerConfig",
    });
    cache.evict({
      fieldName: "Customer_aggregate",
    });
  },
};

type CreateOrEditStripeActions = {
  pushMessageAndClose: (message: string, type: "error" | "success") => void;
  upsertBillingProviderCustomer: () => Promise<
    FetchResult<UpdateStripeBillingProviderCustomerMutation> | undefined
  >;
  upsertCustomerBillingProviderConfiguration: () => Promise<
    FetchResult<InsertCustomerBillingProviderConfigurationMutation> | undefined
  >;
  upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration: () => Promise<
    | FetchResult<UpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation>
    | undefined
  >;
};

// Since all of our mutations are upserts, this makes sure that editing doesn't accidentally
// create a new configuration when it should be updating an existing one. In the case where
// a customer has a plan configuration and a contract configuration with different stripe
// customer ids, "editMode" makes sure we only edit they one they selected in the UI.
export const editStripeSettingsAction = async (
  {
    pushMessageAndClose,
    upsertBillingProviderCustomer,
    upsertCustomerBillingProviderConfiguration,
    upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration,
  }: CreateOrEditStripeActions,
  {
    hasBillingProviderOnPlan,
    hasStripeOnPlan,
    hasStripeOnContract,
  }: StripeProvisioningStatus,
  editMode: PlansAndOrContracts,
) => {
  if (!hasBillingProviderOnPlan && !hasStripeOnPlan && !hasStripeOnContract) {
    pushMessageAndClose(
      "Cannot edit a nonexisting Stripe configuration",
      "error",
    );
    reportToSentry("Cannot edit a nonexisting Stripe configuration");
    return;
  } else if (
    hasStripeOnPlan &&
    hasBillingProviderOnPlan &&
    !hasStripeOnContract
  ) {
    await upsertBillingProviderCustomer();
  } else if (
    !hasStripeOnPlan &&
    !hasBillingProviderOnPlan &&
    hasStripeOnContract
  ) {
    await upsertCustomerBillingProviderConfiguration();
  } else if (
    hasBillingProviderOnPlan &&
    !hasStripeOnPlan &&
    hasStripeOnContract
  ) {
    await upsertCustomerBillingProviderConfiguration();
  } else if (
    hasBillingProviderOnPlan &&
    hasStripeOnPlan &&
    hasStripeOnContract
  ) {
    switch (editMode) {
      case PlansAndOrContracts.plansAndContracts:
        await upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration();
        break;
      case PlansAndOrContracts.plansOnly:
        await upsertBillingProviderCustomer();
        break;
      case PlansAndOrContracts.contractsOnly:
        await upsertCustomerBillingProviderConfiguration();
        break;
      default:
        editMode satisfies never;
        pushMessageAndClose("An unexpected error occurred", "error");
        return;
    }
  } else {
    pushMessageAndClose("An unexpected error occurred", "error");
    return;
  }

  pushMessageAndClose("Stripe configuration saved", "success");
};

export const createStripeSettingsAction = async (
  {
    pushMessageAndClose,
    upsertBillingProviderCustomer,
    upsertCustomerBillingProviderConfiguration,
    upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration,
  }: CreateOrEditStripeActions,
  {
    hasBillingProviderOnPlan,
    hasStripeOnPlan,
    hasStripeOnContract,
    hasStripeBillingProviderToken,
    hasStripeBillingProviderDeliveryMethod,
  }: StripeProvisioningStatus,
) => {
  if (hasStripeBillingProviderToken && hasStripeBillingProviderDeliveryMethod) {
    if (!hasBillingProviderOnPlan && !hasStripeOnPlan && !hasStripeOnContract) {
      await upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration();
    } else if (
      hasBillingProviderOnPlan &&
      hasStripeOnPlan &&
      !hasStripeOnContract
    ) {
      await upsertCustomerBillingProviderConfiguration();
    } else if (
      !hasBillingProviderOnPlan &&
      !hasStripeOnPlan &&
      hasStripeOnContract
    ) {
      await upsertBillingProviderCustomer();
    } else if (
      (hasBillingProviderOnPlan || hasStripeOnPlan) &&
      hasStripeOnContract
    ) {
      pushMessageAndClose(
        "Only one stripe configuration is allowed at a time",
        "error",
      );
      return;
    } else {
      pushMessageAndClose("An unexpected error occurred", "error");
      return;
    }
  } else if (hasStripeBillingProviderToken) {
    if (!hasBillingProviderOnPlan && !hasStripeOnPlan) {
      await upsertBillingProviderCustomer();
    } else if (hasBillingProviderOnPlan && hasStripeOnPlan) {
      pushMessageAndClose(
        "Only one stripe configuration is allowed at a time",
        "error",
      );
      return;
    } else {
      pushMessageAndClose("An unexpected error occurred", "error");
      return;
    }
  } else if (hasStripeBillingProviderDeliveryMethod) {
    if (!hasStripeOnContract) {
      await upsertCustomerBillingProviderConfiguration();
    } else if (hasStripeOnContract) {
      pushMessageAndClose(
        "Only one stripe configuration is allowed at a time",
        "error",
      );
      return;
    } else {
      pushMessageAndClose("An unexpected error occurred", "error");
      return;
    }
  } else {
    pushMessageAndClose(
      "Account-level Stripe configuration is required.  Contact your Metronome representative for assistance.",
      "error",
    );
    return;
  }

  pushMessageAndClose("Stripe configuration saved", "success");
};

const StripeSettingsModal: React.FC<StripeSettingsModalProps> = ({
  onClose,
  customerId,
  edit = false,
  stripeCustomerID = "",
  stripeCollectionMethod = "charge_automatically",
  hasStripeBillingProviderToken,
  hasStripeBillingProviderDeliveryMethod,
  hasBillingProviderOnPlan,
  hasStripeOnPlan,
  hasStripeOnContract,
}) => {
  const { environmentType } = useEnvironment();
  const pushMessage = useSnackbar();
  const [updatedStripeCustomerId, setUpdatedStripeCustomerId] =
    useState<string>(stripeCustomerID);
  const [updatedStripeCollectionMethod, setUpdatedStripeCollectionMethod] =
    useState<string>(stripeCollectionMethod);
  const [updateBillingProviderCustomer] =
    useUpdateStripeBillingProviderCustomerMutation(evictCacheOptions);
  const [updateCustomerBillingProviderConfiguration] =
    useInsertCustomerBillingProviderConfigurationMutation(evictCacheOptions);
  const [updateBillingProviderCustomerAndCustomerBillingProviderConfiguration] =
    useUpdateBillingProviderCustomerAndCustomerBillingProviderConfigurationMutation(
      evictCacheOptions,
    );
  const { data, loading } = useGetStripeCustomerQuery({
    skip: updatedStripeCustomerId === null,
    variables: {
      environment_type: environmentType,
      stripe_customer_id: updatedStripeCustomerId ?? "",
    },
  });

  const pushMessageAndClose = (message: string, type: "error" | "success") => {
    pushMessage({
      content: message,
      type,
    });
    onClose();
  };

  const upsertBillingProviderCustomer = async () => {
    if (!updatedStripeCustomerId || !updatedStripeCollectionMethod) {
      return;
    }
    return await updateBillingProviderCustomer({
      variables: {
        billing_provider_customer_id: updatedStripeCustomerId,
        stripe_collection_method: updatedStripeCollectionMethod,
        customer_id: customerId,
      },
    });
  };

  const upsertCustomerBillingProviderConfiguration = async () => {
    if (!updatedStripeCustomerId || !updatedStripeCollectionMethod) {
      return;
    }
    return await updateCustomerBillingProviderConfiguration({
      variables: {
        customer_id: customerId,
        configuration: {
          stripe_customer_id: updatedStripeCustomerId,
          stripe_collection_method: updatedStripeCollectionMethod,
        },
      },
    });
  };
  const upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration =
    async () => {
      if (!updatedStripeCustomerId || !updatedStripeCollectionMethod) {
        return;
      }
      return await updateBillingProviderCustomerAndCustomerBillingProviderConfiguration(
        {
          variables: {
            stripe_customer_id: updatedStripeCustomerId,
            stripe_collection_method: updatedStripeCollectionMethod,
            customer_id: customerId,
          },
        },
      );
    };

  const createOrEditStripeActions: CreateOrEditStripeActions = {
    pushMessageAndClose,
    upsertBillingProviderCustomer,
    upsertCustomerBillingProviderConfiguration,
    upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration,
  };

  const actionButtons = (
    <>
      {edit &&
        (hasStripeOnContract ? (
          <Tooltip label="Stripe cannot be disabled after being set on a contract">
            <Button
              key="delete"
              onClick={() => onClose(true)}
              text="Disable Stripe"
              theme="linkGray"
              disabled={hasStripeOnContract}
            />
          </Tooltip>
        ) : (
          <Button
            key="delete"
            onClick={() => onClose(true)}
            text="Disable Stripe"
            theme="linkGray"
          />
        ))}
      <Button
        key="primary"
        disabled={!updatedStripeCustomerId || !updatedStripeCollectionMethod}
        onClick={
          edit
            ? async (_e) => {
                await editStripeSettingsAction(
                  createOrEditStripeActions,
                  {
                    hasBillingProviderOnPlan,
                    hasStripeOnPlan,
                    hasStripeOnContract,
                    hasStripeBillingProviderDeliveryMethod,
                    hasStripeBillingProviderToken,
                  },
                  edit,
                );
              }
            : async (_e) => {
                await createStripeSettingsAction(createOrEditStripeActions, {
                  hasBillingProviderOnPlan,
                  hasStripeOnPlan,
                  hasStripeOnContract,
                  hasStripeBillingProviderDeliveryMethod,
                  hasStripeBillingProviderToken,
                });
              }
        }
        text={edit ? "Save changes" : "Add Stripe"}
        theme="primary"
      />
    </>
  );

  return (
    <Popup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={() => onClose(false)}
      title="Stripe configuration"
    >
      <Body level={2}>
        {edit
          ? "Configuration details. "
          : `To add the Stripe profile you need the customer’s associated Stripe customer ID. Once entered we’ll display the customer name.${
              environmentType === EnvironmentTypeEnum_Enum.Sandbox
                ? " This is a sandbox customer so it can only be linked to a Stripe test mode account."
                : ""
            }`}

        <br />
        <br />

        {edit &&
          hasStripeOnPlan &&
          !hasStripeOnContract &&
          "Changes to the configuration apply only to plans; contract configurations will remain unchanged."}
        {edit &&
          !hasStripeOnPlan &&
          hasStripeOnContract &&
          "Changes to the configuration apply only to contracts; plan configurations will remain unchanged."}
        {!edit &&
          !hasStripeOnPlan &&
          hasStripeOnContract &&
          "This configuration will apply to all plan invoices."}
        {!edit &&
          hasStripeOnPlan &&
          !hasStripeOnContract &&
          "This will enable setting up Stripe for contracts."}
      </Body>
      <Input
        name="Stripe ID"
        placeholder="Enter Stripe customer ID"
        value={updatedStripeCustomerId ?? ""}
        onChange={setUpdatedStripeCustomerId}
        autoFocus
        success={data?.stripeCustomer?.name != null}
        error={
          updatedStripeCustomerId != null &&
          !loading &&
          data?.stripeCustomer?.name == null
            ? "Enter a valid Stripe ID"
            : undefined
        }
      />
      <Body level={2}>{data?.stripeCustomer?.name ?? ""}</Body>
      <Select
        name="Collection Method"
        options={[
          { label: "Charge automatically", value: "charge_automatically" },
          { label: "Send invoice", value: "send_invoice" },
        ]}
        onChange={setUpdatedStripeCollectionMethod}
        value={updatedStripeCollectionMethod ?? ""}
        placeholder="Select"
      />
    </Popup>
  );
};

export default StripeSettingsModal;
