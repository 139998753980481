export function linkFromResource(
  resourceType: string,
  resourceID: string,
): string | null {
  // TODO add more resource types here as needed if we can deep link to them from the audit log info
  if (resourceType === "customer") {
    return `/customers/${resourceID}`;
  } else if (resourceType === "plan") {
    return `/plans/${resourceID}`;
  } else if (resourceType === "draft_plan") {
    return `/plans/new/${resourceID}`;
  } else if (resourceType === "product") {
    return `/products/${resourceID}`;
  } else if (resourceType === "billable_metric") {
    return `/billable-metrics/${resourceID}`;
  } else if (resourceType === "webhook") {
    return `/developer/webhooks`;
  } else if (resourceType === "user") {
    return "/settings/team";
  } else if (resourceType === "tokens") {
    return "/developer/tokens";
  } else if (resourceType === "customer_managed_field") {
    return `/settings/general/custom-fields`;
  } else if (resourceType === "rate_card") {
    return `/contract-pricing/rate-cards/${resourceID}`;
  } else if (resourceType === "alert") {
    return `/alerts/${resourceID}`;
  }
  return null;
}

export function prettyResourceType(resourceType: string): string {
  return resourceType
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .replace(
      /\w\S*/g,
      (txt: string) =>
        txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
}
