import { FilterOptions } from "../../components/Filter";

export const PLAN_OPTIONS: FilterOptions = {
  plan_status: {
    label: "Plan Status",
    options: [
      {
        label: "Active & published",
        value: "active",
        group: "plan_status",
        type: "multi",
      },
      {
        label: "Draft",
        value: "draft",
        group: "plan_status",
        type: "multi",
      },
      {
        label: "Archived",
        value: "archived",
        group: "plan_status",
        type: "multi",
      },
    ],
  },
};

export const PLAN_OPTIONS_DEFAULT = [
  PLAN_OPTIONS.plan_status.options[0],
  PLAN_OPTIONS.plan_status.options[1],
]; // Active, Draft
