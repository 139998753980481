/* 8acc088cd63de048a890ad639736a384c6f418be
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomersWithStripeEnabledCountQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type GetCustomersWithStripeEnabledCountQuery = { __typename?: 'Query', Customer_aggregate: { __typename?: 'Customer_aggregate', aggregate: { __typename?: 'Customer_aggregate_fields', count: number } | null } };

export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation = { __typename?: 'Mutation', delete_BillingProviderToken: { __typename?: 'BillingProviderToken_mutation_response', returning: Array<{ __typename?: 'BillingProviderToken', account_id: string }> } | null, delete_BillingProviderCustomer: { __typename?: 'BillingProviderCustomer_mutation_response', returning: Array<{ __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string }> } | null };

export type GetClientConfigQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type GetClientConfigQuery = { __typename?: 'Query', ClientConfig: Array<{ __typename?: 'ClientConfig', id: string, value: string, key: Types.ClientConfigKeyEnum_Enum }> };

export type SaveClientConfigWithInvoiceDaysUntilDueMutationVariables = Types.Exact<{
  leave_invoices_in_draft_value: Types.Scalars['String'];
  skip_zero_dollar_invoices_value: Types.Scalars['String'];
  export_invoice_sub_line_items_value: Types.Scalars['String'];
  include_zero_quantity_sub_line_items_value: Types.Scalars['String'];
  stripe_invoice_quantity_always_string_value: Types.Scalars['String'];
  invoice_days_until_due_value: Types.Scalars['String'];
}>;


export type SaveClientConfigWithInvoiceDaysUntilDueMutation = { __typename?: 'Mutation', leave_invoices_in_draft: { __typename?: 'ClientConfig', id: string, value: string } | null, skip_zero_dollar_invoices: { __typename?: 'ClientConfig', id: string, value: string } | null, export_invoice_sub_line_items: { __typename?: 'ClientConfig', id: string, value: string } | null, include_zero_quantity_sub_line_items: { __typename?: 'ClientConfig', id: string, value: string } | null, stripe_invoice_quantity_always_string: { __typename?: 'ClientConfig', id: string, value: string } | null, invoice_days_until_due: { __typename?: 'ClientConfig', id: string, value: string } | null };

export type SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationVariables = Types.Exact<{
  leave_invoices_in_draft_value: Types.Scalars['String'];
  skip_zero_dollar_invoices_value: Types.Scalars['String'];
  export_invoice_sub_line_items_value: Types.Scalars['String'];
  include_zero_quantity_sub_line_items_value: Types.Scalars['String'];
  stripe_invoice_quantity_always_string_value: Types.Scalars['String'];
}>;


export type SaveClientConfigAndDeleteInvoiceDaysUntilDueMutation = { __typename?: 'Mutation', leave_invoices_in_draft: { __typename?: 'ClientConfig', id: string, value: string } | null, skip_zero_dollar_invoices: { __typename?: 'ClientConfig', id: string, value: string } | null, export_invoice_sub_line_items: { __typename?: 'ClientConfig', id: string, value: string } | null, include_zero_quantity_sub_line_items: { __typename?: 'ClientConfig', id: string, value: string } | null, stripe_invoice_quantity_always_string: { __typename?: 'ClientConfig', id: string, value: string } | null, deleted_invoices_days_until_due: { __typename?: 'ClientConfig_mutation_response', returning: Array<{ __typename?: 'ClientConfig', id: string }> } | null };


export const GetCustomersWithStripeEnabledCountDocument = gql`
    query GetCustomersWithStripeEnabledCount($environment_type: EnvironmentTypeEnum_enum!) {
  Customer_aggregate(
    where: {BillingProviderCustomers: {billing_provider: {_eq: STRIPE}}, environment_type: {_eq: $environment_type}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCustomersWithStripeEnabledCountQuery__
 *
 * To run a query within a React component, call `useGetCustomersWithStripeEnabledCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersWithStripeEnabledCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersWithStripeEnabledCountQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useGetCustomersWithStripeEnabledCountQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>(GetCustomersWithStripeEnabledCountDocument, options);
      }
export function useGetCustomersWithStripeEnabledCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>(GetCustomersWithStripeEnabledCountDocument, options);
        }
export type GetCustomersWithStripeEnabledCountQueryHookResult = ReturnType<typeof useGetCustomersWithStripeEnabledCountQuery>;
export type GetCustomersWithStripeEnabledCountLazyQueryHookResult = ReturnType<typeof useGetCustomersWithStripeEnabledCountLazyQuery>;
export type GetCustomersWithStripeEnabledCountQueryResult = Apollo.QueryResult<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>;
export const DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersDocument = gql`
    mutation DeleteBillingProviderTokenAndAllStripeBillingProviderCustomers {
  delete_BillingProviderToken(where: {billing_provider: {_eq: STRIPE}}) {
    returning {
      account_id
      __environment_type: environment_type
    }
  }
  delete_BillingProviderCustomer(where: {billing_provider: {_eq: STRIPE}}) {
    returning {
      billing_provider_customer_id
    }
  }
}
    `;
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationFn = Apollo.MutationFunction<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>;

/**
 * __useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation__
 *
 * To run a mutation, you first call `useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, { data, loading, error }] = useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>(DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersDocument, options);
      }
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationHookResult = ReturnType<typeof useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation>;
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationResult = Apollo.MutationResult<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation>;
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationOptions = Apollo.BaseMutationOptions<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>;
export const GetClientConfigDocument = gql`
    query GetClientConfig($environment_type: EnvironmentTypeEnum_enum!) {
  ClientConfig(
    where: {key: {_in: [leave_invoices_in_draft, skip_zero_dollar_invoices, export_invoice_sub_line_items, include_zero_quantity_sub_line_items, stripe_invoice_quantity_always_string, invoice_days_until_due]}, environment_type: {_eq: $environment_type}}
  ) {
    id
    value
    key
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetClientConfigQuery__
 *
 * To run a query within a React component, call `useGetClientConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientConfigQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useGetClientConfigQuery(baseOptions: Apollo.QueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
      }
export function useGetClientConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
        }
export type GetClientConfigQueryHookResult = ReturnType<typeof useGetClientConfigQuery>;
export type GetClientConfigLazyQueryHookResult = ReturnType<typeof useGetClientConfigLazyQuery>;
export type GetClientConfigQueryResult = Apollo.QueryResult<GetClientConfigQuery, GetClientConfigQueryVariables>;
export const SaveClientConfigWithInvoiceDaysUntilDueDocument = gql`
    mutation SaveClientConfigWithInvoiceDaysUntilDue($leave_invoices_in_draft_value: String!, $skip_zero_dollar_invoices_value: String!, $export_invoice_sub_line_items_value: String!, $include_zero_quantity_sub_line_items_value: String!, $stripe_invoice_quantity_always_string_value: String!, $invoice_days_until_due_value: String!) {
  leave_invoices_in_draft: insert_ClientConfig_one(
    object: {key: leave_invoices_in_draft, value: $leave_invoices_in_draft_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  skip_zero_dollar_invoices: insert_ClientConfig_one(
    object: {key: skip_zero_dollar_invoices, value: $skip_zero_dollar_invoices_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  export_invoice_sub_line_items: insert_ClientConfig_one(
    object: {key: export_invoice_sub_line_items, value: $export_invoice_sub_line_items_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  include_zero_quantity_sub_line_items: insert_ClientConfig_one(
    object: {key: include_zero_quantity_sub_line_items, value: $include_zero_quantity_sub_line_items_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  stripe_invoice_quantity_always_string: insert_ClientConfig_one(
    object: {key: stripe_invoice_quantity_always_string, value: $stripe_invoice_quantity_always_string_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  invoice_days_until_due: insert_ClientConfig_one(
    object: {key: invoice_days_until_due, value: $invoice_days_until_due_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
}
    `;
export type SaveClientConfigWithInvoiceDaysUntilDueMutationFn = Apollo.MutationFunction<SaveClientConfigWithInvoiceDaysUntilDueMutation, SaveClientConfigWithInvoiceDaysUntilDueMutationVariables>;

/**
 * __useSaveClientConfigWithInvoiceDaysUntilDueMutation__
 *
 * To run a mutation, you first call `useSaveClientConfigWithInvoiceDaysUntilDueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClientConfigWithInvoiceDaysUntilDueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClientConfigWithInvoiceDaysUntilDueMutation, { data, loading, error }] = useSaveClientConfigWithInvoiceDaysUntilDueMutation({
 *   variables: {
 *      leave_invoices_in_draft_value: // value for 'leave_invoices_in_draft_value'
 *      skip_zero_dollar_invoices_value: // value for 'skip_zero_dollar_invoices_value'
 *      export_invoice_sub_line_items_value: // value for 'export_invoice_sub_line_items_value'
 *      include_zero_quantity_sub_line_items_value: // value for 'include_zero_quantity_sub_line_items_value'
 *      stripe_invoice_quantity_always_string_value: // value for 'stripe_invoice_quantity_always_string_value'
 *      invoice_days_until_due_value: // value for 'invoice_days_until_due_value'
 *   },
 * });
 */
export function useSaveClientConfigWithInvoiceDaysUntilDueMutation(baseOptions?: Apollo.MutationHookOptions<SaveClientConfigWithInvoiceDaysUntilDueMutation, SaveClientConfigWithInvoiceDaysUntilDueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveClientConfigWithInvoiceDaysUntilDueMutation, SaveClientConfigWithInvoiceDaysUntilDueMutationVariables>(SaveClientConfigWithInvoiceDaysUntilDueDocument, options);
      }
export type SaveClientConfigWithInvoiceDaysUntilDueMutationHookResult = ReturnType<typeof useSaveClientConfigWithInvoiceDaysUntilDueMutation>;
export type SaveClientConfigWithInvoiceDaysUntilDueMutationResult = Apollo.MutationResult<SaveClientConfigWithInvoiceDaysUntilDueMutation>;
export type SaveClientConfigWithInvoiceDaysUntilDueMutationOptions = Apollo.BaseMutationOptions<SaveClientConfigWithInvoiceDaysUntilDueMutation, SaveClientConfigWithInvoiceDaysUntilDueMutationVariables>;
export const SaveClientConfigAndDeleteInvoiceDaysUntilDueDocument = gql`
    mutation SaveClientConfigAndDeleteInvoiceDaysUntilDue($leave_invoices_in_draft_value: String!, $skip_zero_dollar_invoices_value: String!, $export_invoice_sub_line_items_value: String!, $include_zero_quantity_sub_line_items_value: String!, $stripe_invoice_quantity_always_string_value: String!) {
  leave_invoices_in_draft: insert_ClientConfig_one(
    object: {key: leave_invoices_in_draft, value: $leave_invoices_in_draft_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  skip_zero_dollar_invoices: insert_ClientConfig_one(
    object: {key: skip_zero_dollar_invoices, value: $skip_zero_dollar_invoices_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  export_invoice_sub_line_items: insert_ClientConfig_one(
    object: {key: export_invoice_sub_line_items, value: $export_invoice_sub_line_items_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  include_zero_quantity_sub_line_items: insert_ClientConfig_one(
    object: {key: include_zero_quantity_sub_line_items, value: $include_zero_quantity_sub_line_items_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  stripe_invoice_quantity_always_string: insert_ClientConfig_one(
    object: {key: stripe_invoice_quantity_always_string, value: $stripe_invoice_quantity_always_string_value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value}
  ) {
    id
    value
    __environment_type: environment_type
  }
  deleted_invoices_days_until_due: delete_ClientConfig(
    where: {key: {_eq: invoice_days_until_due}}
  ) {
    returning {
      id
      __environment_type: environment_type
    }
  }
}
    `;
export type SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationFn = Apollo.MutationFunction<SaveClientConfigAndDeleteInvoiceDaysUntilDueMutation, SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationVariables>;

/**
 * __useSaveClientConfigAndDeleteInvoiceDaysUntilDueMutation__
 *
 * To run a mutation, you first call `useSaveClientConfigAndDeleteInvoiceDaysUntilDueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClientConfigAndDeleteInvoiceDaysUntilDueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClientConfigAndDeleteInvoiceDaysUntilDueMutation, { data, loading, error }] = useSaveClientConfigAndDeleteInvoiceDaysUntilDueMutation({
 *   variables: {
 *      leave_invoices_in_draft_value: // value for 'leave_invoices_in_draft_value'
 *      skip_zero_dollar_invoices_value: // value for 'skip_zero_dollar_invoices_value'
 *      export_invoice_sub_line_items_value: // value for 'export_invoice_sub_line_items_value'
 *      include_zero_quantity_sub_line_items_value: // value for 'include_zero_quantity_sub_line_items_value'
 *      stripe_invoice_quantity_always_string_value: // value for 'stripe_invoice_quantity_always_string_value'
 *   },
 * });
 */
export function useSaveClientConfigAndDeleteInvoiceDaysUntilDueMutation(baseOptions?: Apollo.MutationHookOptions<SaveClientConfigAndDeleteInvoiceDaysUntilDueMutation, SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveClientConfigAndDeleteInvoiceDaysUntilDueMutation, SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationVariables>(SaveClientConfigAndDeleteInvoiceDaysUntilDueDocument, options);
      }
export type SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationHookResult = ReturnType<typeof useSaveClientConfigAndDeleteInvoiceDaysUntilDueMutation>;
export type SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationResult = Apollo.MutationResult<SaveClientConfigAndDeleteInvoiceDaysUntilDueMutation>;
export type SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationOptions = Apollo.BaseMutationOptions<SaveClientConfigAndDeleteInvoiceDaysUntilDueMutation, SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationVariables>;