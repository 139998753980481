import React from "react";

import { useLDClient } from "launchdarkly-react-client-sdk";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { NotFoundPage } from "pages/404";

interface Props {
  enabled?: React.ReactNode;
  disabled?: React.ReactNode;
  children?: undefined;
  require?: string[];
}

/** Render one of the two elements based on whether contracts are enabled */
export const ContractsRouteSwitch: React.FC<Props> = (props) => {
  const ld = useLDClient();

  const enabled =
    useContractsEnabled() &&
    (!props.require || props.require.every((f) => !!ld?.variation(f, false)));

  return (
    <>
      {enabled
        ? props.enabled ?? <NotFoundPage />
        : props.disabled ?? <NotFoundPage />}
    </>
  );
};
