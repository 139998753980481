import React, { forwardRef, useImperativeHandle } from "react";
import { useEventsQuery } from "./queries.graphql";
import { Table } from "tenaissance/components/Table";
// TODO: eliminate pages/Events imports from this file
import { CSVDownloadButton } from "pages/Events/components/CSVDownloadButton";
import { CustomerCell } from "pages/Events/components/CustomerCell";
import { TransactionIDCell } from "pages/Events/components/TransactionIDCell";
import { EmptyState } from "tenaissance/components/EmptyState";
import { dayjs } from "lib/date";
import { Timestamp } from "tenaissance/components/Timestamp";

const NUM_ROWS = 15;

export type EventsTableRef = {
  refetch: () => void;
};

type EventsTableProps = {
  startingAfter: Date;
  endingBefore: Date;
  ingestAliases?: string[];
  duplicates?: boolean;
  billableMetricIDs?: string[];
  ref?: React.Ref<EventsTableRef>;
};

export const EventsTable = forwardRef((props: EventsTableProps, ref) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageNumberToCursor, setPageNumberToCursor] = React.useState<
    (string | null)[]
  >([null]);
  const [cursor, setCursor] = React.useState<string | null>(null);

  const { data, loading, refetch } = useEventsQuery({
    variables: {
      limit: NUM_ROWS + 1,
      starting_after: props.startingAfter.toISOString(),
      ending_before: props.endingBefore.toISOString(),
      ingest_aliases: props.ingestAliases,
      duplicates: props.duplicates,
      billable_metric_ids: props.billableMetricIDs,
      cursor: cursor,
    },
  });

  // Reset pagination if the props change (except for the ref)
  React.useEffect(() => {
    setPageNumberToCursor([null]);
    setCursor(null);
    setCurrentPage(0);
  }, [JSON.stringify({ ...props, ref: null })]);

  useImperativeHandle(ref, () => ({
    refetch: () => {
      setCursor(null);
      setCurrentPage(0);
      void refetch();
    },
  }));

  // We always fetch NUM_ROWS+1 events
  // We display (NUM_ROWS) events in the table
  const eventsData = data?.mri_events_v2.events.slice(0, NUM_ROWS) || [];
  // If the data contains more than NUM_ROWS rows, there's another page
  const hasMore = !!data?.mri_events_v2.cursor;

  // Function to go to the next page of events
  const goNextPage = () => {
    // note that we should have disabled this button if there is no more data (i.e. hasMore is false)
    const newCursor = data?.mri_events_v2.cursor ?? null;
    // Increment the current page number
    setCurrentPage(currentPage + 1);
    // Add the new cursor
    setPageNumberToCursor([...pageNumberToCursor, newCursor]);
    setCursor(newCursor);
  };

  // Function to go to the previous page of events
  const goPrevPage = () => {
    // Get the ending timestamp for the previous page
    const newCursor = pageNumberToCursor[currentPage - 1];
    if (!newCursor) {
      // If there is no previous page, reset to the first page
      setCurrentPage(0);
      setCursor(null);
    } else {
      // Set the ending timestamp to the previous page's ending timestamp
      setCursor(newCursor);
      // Decrement the current page number
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div>
      <Table
        title="Event log"
        loading={loading}
        topBarActions={[
          <CSVDownloadButton
            key={0}
            disabled={loading}
            startingAfter={props.startingAfter}
            endingBefore={props.endingBefore}
            ingest_aliases={props.ingestAliases}
            duplicates={props.duplicates}
            billableMetricIDs={props.billableMetricIDs}
          />,
        ]}
        columns={[
          {
            id: "transactionId",
            header: "Transaction ID",
            cell: (props) => props.getValue(),
            accessorFn: (event) => (
              <TransactionIDCell
                event_id={event.id}
                transaction_id={event.transaction_id}
                duplicate={event.is_duplicate}
              />
            ),
            enableSorting: false,
          },
          {
            id: "customer",
            header: "Customer",
            cell: (props) => props.getValue(),
            accessorFn: (event) => (
              <CustomerCell ingest_alias={event.customer_id} />
            ),
            enableSorting: false,
          },
          {
            id: "eventType",
            header: "Event type",
            cell: (props) => props.getValue(),
            accessorFn: (event) => event.event_type,
            enableSorting: false,
          },
          {
            id: "timestamp",
            header: "Timestamp (UTC)",
            cell: (props) => <Timestamp dateTime={props.getValue()} />,
            accessorFn: (event) => {
              return dayjs.utc(event.timestamp).toDate();
            },
            enableSorting: false,
          },
          {
            id: "processedAt",
            header: "Processed at (UTC)",
            cell: (props) => props.getValue(),
            accessorFn: (event) => {
              if (!event.processedAt) {
                if (event.is_duplicate) {
                  // duplicate events don't get processed, so they don't have a processedAt date
                  return "Duplicate";
                }
                return "-";
              }
              const transitionDate = new Date("2022-09-23T00:00:00.000Z");
              const processedAt = new Date(event.processedAt);
              if (processedAt < transitionDate) {
                // this value is not valid before Sep 23, 2022, because that is when we migrated to druid
                return "-";
              }

              const processedAtTime = dayjs.utc(event.processedAt).toDate();
              return <Timestamp dateTime={processedAtTime} />;
            },
            enableSorting: false,
          },
        ]}
        data={eventsData}
        sortOptions={{
          sortOrder: [{ id: "processedAt", desc: true }],
          onSortingChange: () => {}, // hardcoded sorting just to show the arrow
        }}
        paginationOptions={{
          type: "prevNext",
          paginationButtons: [
            {
              page: "prev",
              onClick: () => {
                goPrevPage();
              },
              disabled: currentPage == 0,
            },
            {
              page: "next",
              onClick: () => {
                goNextPage();
              },
              disabled: !hasMore,
            },
          ],
        }}
        emptyState={
          <EmptyState
            icon="activity"
            mainText="No events found"
            supportingText="No events that matched the provided filters were found. Try adjusting the filters or date range."
          />
        }
      />
    </div>
  );
});
