import React, { useMemo, useState } from "react";
import { useApolloResp } from "pages/Contracts/lib/ApolloResp";
import { Table } from "tenaissance/components/Table";
import { ButtonGroup } from "tenaissance/components/ButtonGroup";
import { GatedButton, GatedIconButton } from "components/GatedButton";
import { Timestamp } from "tenaissance/components/Timestamp";
import { View } from "./view";
import {
  DeleteWebhookDocument,
  InsertWebhookDocument,
  TestWebhooksDocument,
  useDeleteWebhookMutation,
  useWebhooksQuery,
} from "pages/Webhooks/data/webhooks.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { CreateWebhookModal } from "pages/Webhooks/components/CreateWebhookModal";
import { TestWebhookModal } from "pages/Webhooks/components/TestWebhookModal";
import { PopConfirm } from "components/PopConfirm";
import { useSnackbar } from "components/Snackbar";

export const Webhooks: React.FC<{
  setSelectedView: (view: View) => void;
}> = ({ setSelectedView }) => {
  const { environmentType } = useEnvironment();
  const pushMessage = useSnackbar();
  const webhooksResult = useApolloResp(
    useWebhooksQuery({ variables: { environment_type: environmentType } }),
  );
  const [deleteWebhook] = useDeleteWebhookMutation({
    update(cache) {
      cache.evict({ fieldName: "Webhook" });
    },
  });
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [testModalOpen, setTestModalOpen] = useState(false);
  const table = useMemo(
    () => (
      <Table
        loading={webhooksResult.state === "loading"}
        topBarActions={[
          <div key={1} className="flex flex-1 justify-between">
            <ButtonGroup
              buttons={[
                {
                  text: "API tokens",
                  onClick: () => setSelectedView("API_TOKENS"),
                  isActive: false,
                },
                {
                  text: "Webhooks",
                  onClick: () => setSelectedView("WEBHOOKS"),
                  isActive: true,
                },
              ]}
            />
            <div className="flex flex-row items-center">
              <GatedButton
                doc={TestWebhooksDocument}
                className="ml-12"
                onClick={() => setTestModalOpen(true)}
                text="Send test notification"
                theme="secondary"
                leadingIcon="plus"
                size="sm"
              />
              <GatedButton
                doc={InsertWebhookDocument}
                className="ml-12"
                onClick={() => setCreateModalOpen(true)}
                text="Add"
                theme="primary"
                leadingIcon="plus"
                size="sm"
              />
            </div>
          </div>,
        ]}
        columns={[
          {
            id: "name",
            header: "Name",
            cell: (props) => props.getValue(),
            accessorKey: "name",
          },
          {
            id: "url",
            header: "URL",
            cell: (props) => props.getValue(),
            accessorKey: "url",
          },
          {
            id: "created_at",
            header: "Created",
            cell: (props) => (
              <Timestamp dateTime={new Date(props.getValue())} />
            ),
            accessorKey: "created_at",
          },
          {
            id: "actions",
            header: "",
            cell: (props) => (
              <PopConfirm
                content={
                  <>
                    Delete <b>{props.getValue().name}</b>? This cannot be
                    undone, and notifications will immediately stop being sent
                    to this URL.
                  </>
                }
                onConfirm={async () => {
                  await deleteWebhook({
                    variables: { id: props.getValue().id },
                  });
                  pushMessage({
                    content: "Webhook deleted",
                    type: "success",
                  });
                }}
                type="error"
              >
                <GatedIconButton
                  doc={DeleteWebhookDocument}
                  theme="tertiary"
                  icon="trash03"
                  size="sm"
                />
              </PopConfirm>
            ),
            accessorFn: (r) => r,
            enableSorting: false,
          },
        ]}
        data={webhooksResult.state === "success" ? webhooksResult.Webhook : []}
        paginationOptions={{
          type: "clientSide",
          pageSize: 9,
        }}
        defaultSort={[{ id: "name", desc: false }]}
      />
    ),
    [webhooksResult],
  );
  return (
    <>
      {createModalOpen && (
        <CreateWebhookModal onClose={() => setCreateModalOpen(false)} />
      )}
      {testModalOpen && (
        <TestWebhookModal onClose={() => setTestModalOpen(false)} />
      )}
      {table}
    </>
  );
};
