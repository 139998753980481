import React from "react";
import { PlansTable } from "./PlansTable";
import { ProductsTable } from "./ProductsTable";
import { useNavigate } from "lib/useNavigate";
import { useRoutePathMatches } from "lib/routes/useRoutePathMatches";
import { useUIMode } from "lib/useUIMode";

export const PlansContainer: React.FC = () => {
  const navigate = useNavigate();
  const { mode } = useUIMode();

  const isProducts = useRoutePathMatches(["/offering/plans/products"]);

  const buttons = [
    {
      text: "Plans",
      onClick: () => navigate("/offering/plans"),
      isActive: !isProducts,
    },
    {
      text: "Products",
      onClick: () => navigate("/offering/plans/products"),
      isActive: !!isProducts,
    },
  ];

  if (mode === "plans-only") {
    return <PlansTable titleButtons={[]} />;
  }
  return isProducts ? (
    <ProductsTable titleButtons={buttons} />
  ) : (
    <PlansTable titleButtons={buttons} />
  );
};
