import { dayjs } from "lib/dayjs";

import React, { useState } from "react";
import styles from "./index.module.less";
import { Caption, Subtitle } from "design-system";
import { Badge } from "design-system";
import classNames from "classnames";
import { InputContainer, InputContainerProps } from "design-system";
import Decimal from "decimal.js";
import { useGetNonExpiredCreditGrantsQuery } from "../../data/credit-overview.graphql";
import { renderDate } from "lib/time";
import { Tooltip } from "design-system";
import { CreditType } from "types/credit-types";
import { sortCreditGrants } from "../CreditGrantList";
import { BillingProviderEnum_Enum } from "types/generated-graphql/__types__";

type PriorityCreditGrant = {
  name: string;
  priority: string;
  effectiveAt: Date;
  expiresBefore: Date;
  createdAt: Date;
  billingProvider: BillingProviderEnum_Enum | null;
  tooltip?: string;
  isNew: boolean;
  voidedAt: Date | null;
  products: { id: string; name: string }[] | null;
};

export type PriorityInputProps = InputContainerProps & {
  placeholder: string;
  onChange?: (priority: number | null, rawInput: string) => void;
  initialValue?: string;
};
export const PriorityInput: React.FC<PriorityInputProps> = (props) => {
  const [value, setValue] = useState(props.initialValue || "");
  const [error, setError] = useState("");

  const onChange = (value: string) => {
    setValue(value);

    let valueNumber: Decimal | null = null;
    let error = "";
    try {
      valueNumber = new Decimal(value || 0);
      if (value === "") {
        error = "Cannot be empty.";
      } else if (valueNumber.isNaN()) {
        error = "Must be numerical.";
      } else if (valueNumber.lessThanOrEqualTo(0)) {
        error = "Must be a positive amount.";
      }
    } catch (err: unknown) {
      error = "Must be numerical.";
    }
    setError(error);

    if (props.onChange) {
      const priority =
        error || valueNumber === null || value === ""
          ? null
          : valueNumber.toNumber();
      props.onChange(priority, value);
    }
  };

  return (
    <InputContainer {...props} error={error || props.error}>
      <input
        value={value}
        min={0}
        type="string" // type=number inputs don't emit onChange events when you type strings, which makes it hard to use the component, so instead just use text here :/
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </InputContainer>
  );
};

interface PriorityBoxProps {
  priority: string;
  grantName: string;
  isNew?: boolean;
  tooltip?: string;
}
const PriorityBox: React.FC<PriorityBoxProps> = ({
  priority,
  grantName,
  isNew,
  tooltip,
}) => {
  const priorityBox = (
    <div className={styles.priority}>
      <div className={styles.priorityLeft}>
        <Caption level={1} className={styles.priorityLabel}>
          {`Priority ${priority}`}
        </Caption>
        <Subtitle
          level={3}
          className={isNew ? styles.movable : styles.notMovable}
        >
          {grantName}
        </Subtitle>
      </div>
      {isNew && (
        <Badge theme="primary" type="light" className={styles.new}>
          NEW
        </Badge>
      )}
    </div>
  );

  if (tooltip) {
    return <Tooltip content={tooltip}>{priorityBox}</Tooltip>;
  } else {
    return priorityBox;
  }
};

interface CreditGrantPriorityProps {
  customerId: string;
  creditType?: CreditType;
  newGrantName: string;
  newGrantPriority: string;
  effectiveAt?: Date;
  expiresBefore?: Date;
  billingProvider?: BillingProviderEnum_Enum | null;
  onChange: (priority: number | null, rawInput: string) => void;
  productIds: string[] | null;
}
const IssueCreditGrantPriority: React.FC<CreditGrantPriorityProps> = ({
  customerId,
  creditType,
  newGrantName,
  newGrantPriority,
  effectiveAt,
  expiresBefore,
  billingProvider,
  onChange,
  productIds,
}) => {
  let initialPriority: number | null = null;
  try {
    initialPriority = new Decimal(newGrantPriority).toNumber();
  } catch {}

  const [priority, setPriority] = useState<number | null>(initialPriority);
  const [rawPriority, setRawPriority] = useState<string>(newGrantPriority);

  const { data, loading } = useGetNonExpiredCreditGrantsQuery({
    variables: {
      customer_id: customerId,
      credit_type_id: creditType?.id,
    },
    skip: !creditType?.id,
  });

  const displaySortedCreditGrants = () => {
    if (!loading && data?.creditGrants && data.creditGrants.length > 0) {
      const creditGrants = data.creditGrants.map<PriorityCreditGrant>((ct) => {
        return {
          name: ct.name,
          priority: ct.priority,
          effectiveAt: new Date(ct.effective_at),
          expiresBefore: new Date(ct.expires_before),
          createdAt: new Date(ct.created_at),
          billingProvider: ct.billing_provider,
          tooltip: `Expiry: ${renderDate(new Date(ct.expires_before), {
            isUtc: true,
            excludeUtcLabel: true,
          })}`,
          isNew: false,
          voidedAt: ct.voided_at ? new Date(ct.voided_at) : null,
          products: ct.products,
        };
      });
      const now = new Date();
      creditGrants.push({
        name: newGrantName,
        priority: priority ? priority.toString() : "",
        effectiveAt: effectiveAt || now,
        expiresBefore: expiresBefore || dayjs.utc(now).add(1, "day").toDate(),
        createdAt: now,
        billingProvider: billingProvider ?? null,
        isNew: true,
        voidedAt: null,
        products: productIds?.length
          ? productIds.map((p) => ({ id: p, name: "" }))
          : null,
      });
      creditGrants.sort(sortCreditGrants);

      return (
        <div className={styles.priorities}>
          {creditGrants.map((cg, idx) => (
            <PriorityBox
              key={idx}
              priority={cg.priority}
              grantName={cg.name}
              isNew={cg.isNew}
              tooltip={cg.tooltip}
            />
          ))}
        </div>
      );
    }

    return (
      <div className={styles.priorities}>
        <PriorityBox
          priority={newGrantPriority}
          grantName={newGrantName}
          isNew
        />
      </div>
    );
  };

  return (
    <>
      <div className={classNames(styles.gray, styles.priorityInput)}>
        <PriorityInput
          name="Priority"
          placeholder="Enter priority"
          tooltip="Grant priority determines the order credits get consumed. Grants at the same priority are then consumed by earlier expiry and balance amount."
          initialValue={rawPriority}
          success={!!priority}
          onChange={(newPriority, newRawPriority) => {
            setPriority(newPriority);
            setRawPriority(newRawPriority);
            onChange(newPriority, newRawPriority);
          }}
        />
      </div>
      {displaySortedCreditGrants()}
    </>
  );
};

export default IssueCreditGrantPriority;
