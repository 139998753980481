/* ab99fb1790b55b57c73ec4b133f0021701e43445
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExchangeStripeCodeForCredentialsMutationVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  code: Types.Scalars['String'];
}>;


export type ExchangeStripeCodeForCredentialsMutation = { __typename?: 'Mutation', store_stripe_credentials: { __typename?: 'MutationResponse', success: boolean } };


export const ExchangeStripeCodeForCredentialsDocument = gql`
    mutation ExchangeStripeCodeForCredentials($environment_type: EnvironmentTypeEnum_enum!, $code: String!) {
  store_stripe_credentials(environment_type: $environment_type, code: $code) {
    success
  }
}
    `;
export type ExchangeStripeCodeForCredentialsMutationFn = Apollo.MutationFunction<ExchangeStripeCodeForCredentialsMutation, ExchangeStripeCodeForCredentialsMutationVariables>;

/**
 * __useExchangeStripeCodeForCredentialsMutation__
 *
 * To run a mutation, you first call `useExchangeStripeCodeForCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeStripeCodeForCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeStripeCodeForCredentialsMutation, { data, loading, error }] = useExchangeStripeCodeForCredentialsMutation({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useExchangeStripeCodeForCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<ExchangeStripeCodeForCredentialsMutation, ExchangeStripeCodeForCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExchangeStripeCodeForCredentialsMutation, ExchangeStripeCodeForCredentialsMutationVariables>(ExchangeStripeCodeForCredentialsDocument, options);
      }
export type ExchangeStripeCodeForCredentialsMutationHookResult = ReturnType<typeof useExchangeStripeCodeForCredentialsMutation>;
export type ExchangeStripeCodeForCredentialsMutationResult = Apollo.MutationResult<ExchangeStripeCodeForCredentialsMutation>;
export type ExchangeStripeCodeForCredentialsMutationOptions = Apollo.BaseMutationOptions<ExchangeStripeCodeForCredentialsMutation, ExchangeStripeCodeForCredentialsMutationVariables>;