import React, { useEffect, useState } from "react";
import styles from "./index.module.less";
import { useGetCustomFieldKeysQuery } from "../../queries.graphql";
import { Body, Select, Subtitle, Tooltip } from "design-system";
import { Button } from "tenaissance/components/Button";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useSnackbar } from "components/Snackbar";
import { Popup } from "components/Popup";
import classnames from "classnames";
import { useApolloClient } from "@apollo/client";
import { downloadCSV } from "lib/reports";
import { ReportConfig } from "lib/reports/reportTypes/reportConfig";
import CustomFieldsTable from "./CustomFieldsTable";

const CustomFieldsTab: React.FC = () => {
  const { environmentType } = useEnvironment();
  const { data, loading } = useGetCustomFieldKeysQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  return (
    <CustomFieldsTable
      loading={loading}
      customFieldKeys={data?.ManagedFieldKey ?? []}
    />
  );
};

export const ExportCustomFieldsValuesModal: React.FC<{
  availableReports: ReportConfig<any>[];
  onClose: () => void;
}> = ({ availableReports, onClose }) => {
  const [selectedReport, setSelectedReport] =
    useState<ReportConfig<unknown> | null>();
  const [buildingReport, setBuildingReport] = useState(false);
  const client = useApolloClient();
  const pushMessage = useSnackbar();

  useEffect(() => {
    if (availableReports.length && !selectedReport) {
      setSelectedReport(availableReports[0]);
    }
  }, [availableReports, selectedReport]);

  const generateReport = async () => {
    if (!selectedReport) {
      return;
    }
    if (buildingReport) {
      return;
    }
    const { pageSize } = selectedReport;
    setBuildingReport(true);

    const query = selectedReport.queryDocument;
    const pages = [];
    let cursor = undefined;
    try {
      while (true) {
        const response = await client.query({
          query,
          variables: {
            limit: pageSize + 1,
            cursor,
          },
        });
        const page: any = response.data;
        pages.push(page);
        const nextCursor = selectedReport.nextCursor(page);
        if (nextCursor) {
          cursor = nextCursor;
          continue;
        }
        break;
      }
    } catch (e: any) {
      pushMessage({
        content: "Failed to generate report.",
        type: "error",
      });
      /* Enable more downloads */
      setBuildingReport(false);
      return;
    }
    let rows: string[][] = [];
    if (!selectedReport.needsDates && !selectedReport.needsEnvironment) {
      rows = selectedReport.dataToCSV(pages);
    }
    const reportName = selectedReport.name;
    downloadCSV(`${reportName}.csv`, rows);
    /* Enable more downloads */
    setBuildingReport(false);
  };

  return (
    <Popup
      actions={
        <>
          <Button
            onClick={() => {
              onClose();
            }}
            text="Cancel"
            theme="linkGray"
          />
          <Tooltip
            disabled={!!selectedReport}
            content="Select an entity to export its custom fields"
          >
            <Button
              onClick={generateReport}
              loading={buildingReport}
              text="Download CSV"
              theme="primary"
              leadingIcon="download02"
            />
          </Tooltip>
        </>
      }
      isOpen={true}
      onRequestClose={() => onClose()}
      title="Export custom field values"
      className={classnames(styles.popover, "pl-2")}
    >
      <Body level={2}>Select the entity you’d like to export.</Body>
      <div
        className={classnames(
          styles.formGroup,
          styles.exportToolBody,
          "pl-2 flex flex-row",
        )}
      >
        <div className="flex w-[200px] flex-col">
          <Subtitle level={4} className="text-gray-500">
            Select an entity
          </Subtitle>
          <Select
            placeholder="Select an entity"
            options={availableReports.map((report) => ({
              label: report.name.slice(0, -1 * "Custom Fields".length),
              value: report.name,
            }))}
            value={selectedReport?.name}
            onChange={(value) => {
              setSelectedReport(
                availableReports.find((report) => report.name === value) ??
                  null,
              );
            }}
            disabled={buildingReport}
          />
        </div>
      </div>
    </Popup>
  );
};
export default CustomFieldsTab;
