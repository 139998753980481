import React from "react";
import { Select } from "design-system";
import { v4 as uuid } from "uuid";

import { OneTimeSchedule } from "./OneTimeSchedule";
import { RecurringSchedule } from "./RecurringSchedule";
import { CustomSchedule } from "./CustomSchedule";
import { useRootCtrl, ParentCtrl } from "./RootCtrl";
import { Schema } from "../../Schema";
import { RecurringScheduleFrequency } from "types/generated-graphql/__types__";
import { ModeProvider } from "./mode";

function transformSchedule(
  newFreq: Schema.Types.BillingScheduleFrequency,
  prevSchedule: Schema.Types.BillingSchedule | undefined,
): Schema.Types.BillingSchedule {
  switch (newFreq) {
    case "none":
      return {
        type: "fixed",
        items: [],
      };
    case "one":
      // truncate to one fixed item
      return {
        type: "fixed",
        items:
          prevSchedule?.type === "fixed" ? prevSchedule.items.slice(0, 1) : [],
      };
    case "custom":
      return {
        type: "fixed",
        items:
          prevSchedule?.type === "fixed" && prevSchedule.items.length
            ? prevSchedule.items
            : [
                {
                  id: uuid(),
                } as Schema.Types.FixedScheduleItem,
              ],
      };
    case RecurringScheduleFrequency.Annual:
    case RecurringScheduleFrequency.Monthly:
    case RecurringScheduleFrequency.Quarterly:
    case RecurringScheduleFrequency.SemiAnnual:
      // preserve existing schedule if it's also recurring
      return {
        ...(prevSchedule?.type === "recurring" ? prevSchedule : {}),
        type: "recurring",
      } as Schema.Types.BillingSchedule;
  }
}

interface Props {
  ctrl: ParentCtrl;
  mode: "invoice" | "discount";
  allowEmpty?: boolean;
}

export const BillingSchedule: React.FC<Props> = (props) => {
  const ctrl = useRootCtrl(props.ctrl);

  return (
    <ModeProvider value={props.mode}>
      <div className="grid grid-cols-3 gap-12">
        <Select
          {...ctrl.props.Select("frequency", {
            name: "Frequency",
            placeholder: "Select",
            options: [
              {
                label: "None (free)",
                value: "none",
                hidden: !props.allowEmpty,
              },
              {
                label: "One-time",
                value: "one",
              },
              {
                label: "Scheduled monthly",
                value: RecurringScheduleFrequency.Monthly,
              },
              {
                label: "Scheduled quarterly",
                value: RecurringScheduleFrequency.Quarterly,
              },
              {
                label: "Scheduled semi-annually",
                value: RecurringScheduleFrequency.SemiAnnual,
              },
              {
                label: "Scheduled annually",
                value: RecurringScheduleFrequency.Annual,
              },
              {
                label: "Custom",
                value: "custom",
              },
            ],
            map(update) {
              if (!update.frequency) {
                return update;
              }

              return {
                frequency:
                  update.frequency === "none" && !props.allowEmpty
                    ? "one"
                    : update.frequency,
                schedule: transformSchedule(
                  update.frequency,
                  ctrl.get("schedule"),
                ),
              };
            },
          })}
        />
        {((): React.ReactElement | null => {
          const freq = ctrl.get("frequency");
          switch (freq) {
            case "one":
              return <OneTimeSchedule parent={ctrl} />;
            case RecurringScheduleFrequency.Annual:
            case RecurringScheduleFrequency.Monthly:
            case RecurringScheduleFrequency.Quarterly:
            case RecurringScheduleFrequency.SemiAnnual:
              return <RecurringSchedule parent={ctrl} frequency={freq} />;
            case "custom":
              return (
                <CustomSchedule parent={ctrl} allowEmpty={!!props.allowEmpty} />
              );
            case "none":
            case undefined:
              return null;
          }
        })()}
      </div>
    </ModeProvider>
  );
};
