import React from "react";
import MetricsTable from "./MetricsTable";
import { SeatsTable } from "./SeatsTable";
import { useNavigate } from "lib/useNavigate";
import { useRoutePathMatches } from "lib/routes/useRoutePathMatches";
import { useFeatureFlag } from "../../../../../lib/launchdarkly";

export const BillableMetricsContainer: React.FC = () => {
  const navigate = useNavigate();

  const isSeats = useRoutePathMatches(["/offering/billable-metrics/seats"]);

  const seatsEnabled = useFeatureFlag<boolean>("seats", false);

  const buttons = [
    {
      text: "Metrics",
      onClick: () => navigate("/offering/billable-metrics"),
      isActive: !isSeats,
    },
    {
      text: "Seats",
      onClick: () => navigate("/offering/billable-metrics/seats"),
      isActive: !!isSeats,
    },
  ];
  return isSeats ? (
    <SeatsTable titleButtons={buttons} />
  ) : (
    <MetricsTable titleButtons={seatsEnabled ? buttons : []} />
  );
};
