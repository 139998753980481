import Fuse from "fuse.js";
import React, { useMemo, useState } from "react";

import {
  PlansDocument,
  PlansQuery,
  useActiveProductsQuery,
  useDraftPlansQuery,
  usePlansQuery,
  DraftPlansQuery,
} from "./data/queries.graphql";
import { PlanTypeEnum } from "types/generated-graphql/__types__";

import { AvatarWithName, Badge, Input, Tooltip } from "design-system";
import { CopyableID } from "components/CopyableID";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Filter, OptionType } from "components/Filter";
import ArchivePlanModal from "./components/ArchivePlanModal";
import DeleteDraftPlanModal from "./components/DeletePlanModal";
import { SearchTooltip } from "components/SearchTooltip";
import useDebounce from "lib/debounce";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { renderDate } from "lib/time";
import { useNavigate } from "lib/useNavigate";
import { useSearchParam } from "lib/routes/useSearchParam";
import { PLAN_OPTIONS, PLAN_OPTIONS_DEFAULT } from "./filters";
import CopyPlanModal from "components/CopyAssetToEnvironmentModal/CopyPlanModal";
import { NewPlanDocument } from "pages/PlanWizards/data/queries.graphql";
import { NewProductDocument } from "pages/NewProduct/queries.graphql";
import { PlansContainer } from "./PlansContainer";
import { Column, SimpleTable } from "components/SimpleTable";
import { TableSkeleton } from "components/Table";
import { ActionMenu, DraftPlanActionMenu } from "./components/ActionMenu";
import CustomerCountCell from "./components/CustomerCountCell";
import pluralize from "pluralize";
import StatusCell from "./components/StatusCell";
import { GatedButton } from "../../components/GatedButton";
import { PlansContainer as T10PlansContainer } from "../../tenaissance/pages/Offering/tabs/Plans/PlansContainer";
import { useUIMode } from "../../lib/useUIMode";
import { DocsLink } from "tenaissance/components/DocsLink";

export type Plan = PlansQuery["plans"][0];
export type DraftPlan = DraftPlansQuery["DraftPlan"][0];
type DraftPlanData = {
  name?: string;
  description?: string;
  selectedProductIds?: string[];
};

const NUM_ROWS = 15;
const PROMPT_ADD_PRODUCT_COPY =
  "Once you create a product, then you can build plans.";

function isDraftPlan(plan: Plan | DraftPlan): plan is DraftPlan {
  return plan.__typename === "DraftPlan";
}

const Plans: React.FC = () => {
  const { environmentType } = useEnvironment();
  const navigate = useNavigate();

  const [planToCopy, setPlanToCopy] = useState<Plan | null>(null);
  const [planToDelete, setPlanToDelete] = useState<DraftPlan | null>(null);
  const [planToArchive, setPlanToArchive] = useState<Plan | null>(null);
  const [planFilters, setPlanFilters] =
    useState<OptionType[]>(PLAN_OPTIONS_DEFAULT);
  const [sortOrder, setSortOrder] = useState<{
    column: string;
    direction: "asc" | "desc";
  } | null>(null);

  const [searchQuery, setSearchQuery] = useSearchParam("q");
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 400);
  const isPlanFilterSelected = (value: "active" | "draft" | "archived") =>
    planFilters.some((f) => f.group === "plan_status" && f.value === value);

  const {
    data: activePlans,
    loading: activePlansLoading,
    error: activePlansError,
  } = usePlansQuery({
    variables: {
      environment_type: environmentType,
      plan_type: PlanTypeEnum.ActiveOnly,
    },
    skip: false,
  });
  const {
    data: archivedPlans,
    loading: archivedPlansLoading,
    error: archivedPlansError,
  } = usePlansQuery({
    variables: {
      environment_type: environmentType,
      plan_type: PlanTypeEnum.ArchivedOnly,
    },
    skip: !isPlanFilterSelected("archived"),
  });
  const {
    data: draftPlansData,
    loading: draftPlansLoading,
    error: draftPlansError,
  } = useDraftPlansQuery({
    variables: { environment_type: environmentType },
  });
  const {
    data: activeProducts,
    loading: activeProductsLoading,
    error: activeProductsError,
  } = useActiveProductsQuery({
    variables: { environment_type: environmentType },
  });

  // Even if we aren't showing active and/or draft plans at the moment, we block
  // on them to show the page because we want to show the correct empty state
  // (no plans match filter vs no plans at all). However, we only block on the
  // archived plans query returning if we're going to show them.
  const loading =
    activePlansLoading ||
    draftPlansLoading ||
    activeProductsLoading ||
    (isPlanFilterSelected("archived") && archivedPlansLoading);

  const error =
    activePlansError ||
    draftPlansError ||
    activeProductsError ||
    archivedPlansError;

  const plans: Plan[] = [];
  if (isPlanFilterSelected("active") && activePlans) {
    plans.push(...activePlans?.plans);
  }
  if (isPlanFilterSelected("archived") && archivedPlans) {
    plans.push(...archivedPlans?.plans);
  }

  const planFuse = useMemo(() => {
    return new Fuse(plans ?? [], {
      ignoreLocation: true,
      includeScore: true,
      useExtendedSearch: true,
      keys: [
        { name: "id", weight: 1.0 },
        { name: "name", weight: 1.0 },
        { name: "description", weight: 0.7 },
        {
          name: "PricedProducts.PricedProductPricingFactors.ProductPricingFactor.name",
          weight: 0.4,
        },
        { name: "PricedProducts.Product.name", weight: 0.4 },
      ],
      threshold: 0.35,
    });
  }, [plans]);

  const draftPlans = isPlanFilterSelected("draft")
    ? draftPlansData?.DraftPlan ?? []
    : [];

  const draftPlanFuse = useMemo(() => {
    return new Fuse(draftPlans ?? [], {
      ignoreLocation: true,
      includeScore: true,
      useExtendedSearch: true,
      keys: [
        { name: "id", weight: 1.0 },
        { name: "data.name", weight: 1.0 },
        { name: "data.description", weight: 0.8 },
      ],
      threshold: 0.35,
    });
  }, [draftPlans]);

  const filteredPlans = [
    ...(debouncedSearchQuery
      ? planFuse.search(debouncedSearchQuery).map((match) => {
          return { ...match.item, score: match.score || 0 };
        })
      : plans.map((plan) => {
          return { ...plan, score: 0 };
        })),
  ];

  const filteredDraftPlans = [
    ...(debouncedSearchQuery
      ? draftPlanFuse.search(debouncedSearchQuery).map((match) => {
          return { ...match.item, score: match.score || 0 };
        })
      : draftPlans.map((draftPlan) => {
          return { ...draftPlan, score: 0 };
        })),
  ];

  const hasProducts =
    activeProducts?.products && activeProducts.products.length > 0;

  const addPlanButton = (
    <GatedButton
      doc={NewPlanDocument}
      className="ml-12"
      onClick={() => (hasProducts ? navigate("/plans/new") : undefined)}
      disabled={!hasProducts}
      text="Add new plan"
      theme="primary"
      leadingIcon="plus"
      size="sm"
    />
  );

  const deleteDraftPlanModal = planToDelete && (
    <DeleteDraftPlanModal
      onClose={() => {
        setPlanToDelete(null);
      }}
      plan={planToDelete}
    />
  );

  const archivePlanModal = planToArchive && (
    <ArchivePlanModal
      onClose={() => {
        setPlanToArchive(null);
      }}
      plan={planToArchive}
    />
  );

  const copyPlanModal = planToCopy && (
    <CopyPlanModal
      onClose={() => {
        setPlanToCopy(null);
      }}
      planId={planToCopy.id}
      planName={planToCopy.name}
    />
  );

  const { newUIEnabled } = useUIMode();
  let columns: (Column<Plan | DraftPlan> & { key: string })[] = [
    {
      header: "Plan name",
      key: "name",
      alignment: "top-left",
      sort: sortOrder?.column === "name" ? sortOrder.direction : "none",
      /**
       * this strange max-width style ensures that long descriptions
       * are truncated rather than stretching the table cell. 900px
       * is a rough guess at the amount of space necessary for the
       * side nav and the other table columns
       */
      headerClassName: "max-w-[calc(100vw_-_900px)]",
      render: (plan) => (
        <>
          <div>
            {plan.id && <CopyableID id={plan.id} label="plan ID" hideID />}
            {isDraftPlan(plan)
              ? (plan.data as DraftPlanData).name ?? ""
              : plan.name}
          </div>
          {"description" in plan && plan.description && (
            <div
              className="truncate text-xs text-gray-medium"
              title={plan.description}
            >
              {plan.description}
            </div>
          )}
        </>
      ),
    },
    {
      header: "Customers",
      key: "customer_count",
      alignment: "top-right",
      render: (plan) => {
        if (isDraftPlan(plan)) {
          return <span>0 customers</span>;
        }
        return <CustomerCountCell planId={plan.id} />;
      },
    },
    {
      header: "Products",
      key: "product_count",
      alignment: "top-right",
      sort:
        sortOrder?.column === "product_count" ? sortOrder.direction : "none",
      render: (plan) => {
        const productCount = isDraftPlan(plan)
          ? (plan.data as DraftPlanData).selectedProductIds?.length ?? 0
          : plan.PricedProducts_aggregate.aggregate?.count ?? 0;
        return (
          <span>{`${productCount} ${pluralize("product", productCount)}`}</span>
        );
      },
    },
    {
      header: "Created by",
      key: "created_by",
      alignment: "top-left",
      sort: sortOrder?.column === "created_by" ? sortOrder.direction : "none",
      render: (plan) => {
        const creator = isDraftPlan(plan) ? plan.Creator : plan.Actor;
        return creator ? (
          <Tooltip
            content={
              <>
                Created by {creator.name}
                <br />
                {renderDate(new Date(plan.created_at), {
                  isUtc: false,
                })}
              </>
            }
          >
            <AvatarWithName {...creator} />
          </Tooltip>
        ) : null;
      },
    },
    {
      header: "Last edited",
      key: "last_edited",
      alignment: "top-right",
      sort: sortOrder?.column === "last_edited" ? sortOrder.direction : "none",
      render: (plan) => {
        const availableDate = isDraftPlan(plan)
          ? plan.created_at
          : plan.updated_at;
        return renderDate(new Date(availableDate), {
          isUtc: false,
        });
      },
    },
    {
      header: "Status",
      key: "status",
      alignment: "top-left",
      sort: sortOrder?.column === "status" ? sortOrder.direction : "none",
      render: (plan) => {
        if (isDraftPlan(plan)) {
          return (
            <Badge theme="grey" type="light">
              DRAFT
            </Badge>
          );
        } else if (plan.deprecated_at !== null) {
          return (
            <Badge theme="warning" type="light">
              ARCHIVED
            </Badge>
          );
        } else {
          return <StatusCell planId={plan.id} />;
        }
      },
    },
    {
      header: "",
      key: "actions",
      alignment: "top-right",
      render: (plan) => {
        if (isDraftPlan(plan)) {
          return (
            <DraftPlanActionMenu
              plan={plan}
              setPlanToDelete={setPlanToDelete}
            />
          );
        } else {
          return (
            <ActionMenu
              plan={plan}
              setPlanToArchive={setPlanToArchive}
              setPlanToCopy={setPlanToCopy}
            />
          );
        }
      },
    },
  ];

  const sortPlans = (plans: ((Plan | DraftPlan) & { score: number })[]) => {
    // Let's use the score to sort as default if no sort order is set
    if (!sortOrder) return plans.sort((a, b) => a.score - b.score);
    const sortDirection = sortOrder.direction === "asc" ? 1 : -1;
    switch (sortOrder.column) {
      case "name":
        return plans.sort((a, b) => {
          const aName = isDraftPlan(a)
            ? (a.data as DraftPlanData).name ?? ""
            : a.name;
          const bName = isDraftPlan(b)
            ? (b.data as DraftPlanData).name ?? ""
            : b.name;
          return aName.localeCompare(bName) * sortDirection;
        });
      case "product_count":
        return plans.sort((a, b) => {
          const aCount = isDraftPlan(a)
            ? (a.data as DraftPlanData).selectedProductIds?.length ?? 0
            : a.PricedProducts_aggregate.aggregate?.count ?? 0;
          const bCount = isDraftPlan(b)
            ? (b.data as DraftPlanData).selectedProductIds?.length ?? 0
            : b.PricedProducts_aggregate.aggregate?.count ?? 0;
          return (aCount - bCount) * sortDirection;
        });
      case "created_by":
        return plans.sort((a, b) => {
          const aCreator = isDraftPlan(a) ? a.Creator : a.Actor;
          const bCreator = isDraftPlan(b) ? b.Creator : b.Actor;
          if (!aCreator || !bCreator) return 0;
          return aCreator.name.localeCompare(bCreator.name) * sortDirection;
        });
      case "last_edited":
        return plans.sort((a, b) => {
          const aDate = isDraftPlan(a) ? a.created_at : a.updated_at;
          const bDate = isDraftPlan(b) ? b.created_at : b.updated_at;
          return (
            (new Date(aDate).valueOf() - new Date(bDate).valueOf()) *
            sortDirection
          );
        });
      case "status":
        return plans.sort((a, b) => {
          const aStatus = isDraftPlan(a)
            ? "draft"
            : a.deprecated_at !== null
              ? "archived"
              : "active";
          const bStatus = isDraftPlan(b)
            ? "draft"
            : b.deprecated_at !== null
              ? "archived"
              : "active";
          return aStatus.localeCompare(bStatus) * sortDirection;
        });
      default:
        return plans;
    }
  };

  let contents = (
    <TableSkeleton
      numRows={NUM_ROWS}
      columnNames={columns.map((c) =>
        typeof c.header === "string" ? c.header : "",
      )}
    />
  );

  if (error) {
    contents = (
      <EmptyState
        icon="shoppingCart01"
        mainText="We ran into an issue loading your plans"
        supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
      />
    );
  } else if (!loading) {
    if (plans.length === 0 && draftPlansData?.DraftPlan.length === 0) {
      contents = (
        <EmptyState
          icon="file05"
          mainText="You don't have any plans."
          supportingText={
            hasProducts
              ? undefined
              : "Once you create a product, then you can build plans."
          }
          actions={[
            <DocsLink plansPath="pricing-and-packaging/how-pricing-and-packaging-works/" />,
            hasProducts ? (
              <GatedButton
                doc={NewPlanDocument}
                theme="primary"
                onClick={() => navigate("/offering/plans/new")}
                text="Create a plan"
              />
            ) : (
              <GatedButton
                leadingIcon="plus"
                theme="primary"
                doc={NewProductDocument}
                onClick={() => navigate("/offering/plans/products/new")}
                text="Create a product"
              />
            ),
          ]}
        />
      );
    } else if (filteredPlans.length === 0 && filteredDraftPlans.length === 0) {
      contents = (
        <EmptyState
          icon="file05"
          mainText="No matching plans found."
          supportingText={`Try a different ${
            searchQuery.length ? "search term" : "filter"
          }.`}
        />
      );
    } else {
      contents = (
        <>
          {deleteDraftPlanModal}
          {archivePlanModal}
          {copyPlanModal}
          <SimpleTable
            columns={columns}
            data={sortPlans([...filteredPlans, ...filteredDraftPlans])}
            rowRoutePath={(plan) => {
              return isDraftPlan(plan)
                ? `/plans/new/${plan.id}`
                : `/plans/${plan.id}`;
            }}
            onSortClick={(index) => {
              const columnKey = columns[index].key;
              if (sortOrder?.column !== columnKey) {
                setSortOrder({ column: columnKey, direction: "asc" });
              } else if (sortOrder.direction === "asc") {
                setSortOrder({ column: columnKey, direction: "desc" });
              } else {
                setSortOrder(null);
              }
            }}
          />
        </>
      );
    }
  }

  return newUIEnabled ? (
    <T10PlansContainer />
  ) : (
    <PlansContainer
      action={
        hasProducts ? (
          <div className="flex flex-row items-center">
            {!loading && (plans.length || draftPlansData?.DraftPlan.length) ? (
              <>
                <SearchTooltip searchText="plans">
                  <Input
                    type="search"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={setSearchQuery}
                    leftIcon="search"
                    className="w-[208px]"
                  />
                </SearchTooltip>
                <Filter
                  value={planFilters}
                  options={PLAN_OPTIONS}
                  onChange={setPlanFilters}
                  onReset={() => setPlanFilters(PLAN_OPTIONS_DEFAULT)}
                />
              </>
            ) : null}
            {addPlanButton}
          </div>
        ) : (
          <Tooltip content={PROMPT_ADD_PRODUCT_COPY}>{addPlanButton}</Tooltip>
        )
      }
      authDoc={PlansDocument}
    >
      {contents}
    </PlansContainer>
  );
};

export default Plans;
