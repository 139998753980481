/* 3a9de61e6a58b6a4b79244bd71cd3756e54f8c8a
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
type InvoiceType_AdHocPlanInvoice_Fragment = { __typename: 'AdHocPlanInvoice' };

type InvoiceType_AdhocContractUsageInvoice_Fragment = { __typename: 'AdhocContractUsageInvoice' };

type InvoiceType_AdvanceInvoice_Fragment = { __typename: 'AdvanceInvoice' };

type InvoiceType_ArrearsInvoice_Fragment = { __typename: 'ArrearsInvoice' };

type InvoiceType_ContractPostpaidTrueupInvoice_Fragment = { __typename: 'ContractPostpaidTrueupInvoice' };

type InvoiceType_ContractProServiceInvoice_Fragment = { __typename: 'ContractProServiceInvoice' };

type InvoiceType_ContractRefundInvoice_Fragment = { __typename: 'ContractRefundInvoice' };

type InvoiceType_ContractScheduledInvoice_Fragment = { __typename: 'ContractScheduledInvoice' };

type InvoiceType_ContractUsageInvoice_Fragment = { __typename: 'ContractUsageInvoice' };

type InvoiceType_CorrectionInvoice_Fragment = { __typename: 'CorrectionInvoice' };

type InvoiceType_CreditPurchaseInvoice_Fragment = { __typename: 'CreditPurchaseInvoice' };

type InvoiceType_ParentInvoice_Fragment = { __typename: 'ParentInvoice' };

type InvoiceType_SeatPurchaseInvoice_Fragment = { __typename: 'SeatPurchaseInvoice' };

export type InvoiceTypeFragment = InvoiceType_AdHocPlanInvoice_Fragment | InvoiceType_AdhocContractUsageInvoice_Fragment | InvoiceType_AdvanceInvoice_Fragment | InvoiceType_ArrearsInvoice_Fragment | InvoiceType_ContractPostpaidTrueupInvoice_Fragment | InvoiceType_ContractProServiceInvoice_Fragment | InvoiceType_ContractRefundInvoice_Fragment | InvoiceType_ContractScheduledInvoice_Fragment | InvoiceType_ContractUsageInvoice_Fragment | InvoiceType_CorrectionInvoice_Fragment | InvoiceType_CreditPurchaseInvoice_Fragment | InvoiceType_ParentInvoice_Fragment | InvoiceType_SeatPurchaseInvoice_Fragment;

type InvoiceRoutePath_AdHocPlanInvoice_Fragment = { __typename: 'AdHocPlanInvoice', id: string, plan: { __typename?: 'Plan', id: string }, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_AdhocContractUsageInvoice_Fragment = { __typename: 'AdhocContractUsageInvoice', id: string, contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } }, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_AdvanceInvoice_Fragment = { __typename: 'AdvanceInvoice', id: string, customer_plan: { __typename?: 'CustomerPlan', id: string, Customer: { __typename?: 'Customer', id: string } } | null, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_ArrearsInvoice_Fragment = { __typename: 'ArrearsInvoice', id: string, customer_plan: { __typename?: 'CustomerPlan', id: string, Customer: { __typename?: 'Customer', id: string } } | null, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_ContractPostpaidTrueupInvoice_Fragment = { __typename: 'ContractPostpaidTrueupInvoice', id: string, contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } }, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_ContractProServiceInvoice_Fragment = { __typename: 'ContractProServiceInvoice', id: string, contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } }, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_ContractRefundInvoice_Fragment = { __typename: 'ContractRefundInvoice', id: string, contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } }, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_ContractScheduledInvoice_Fragment = { __typename: 'ContractScheduledInvoice', id: string, contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } }, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_ContractUsageInvoice_Fragment = { __typename: 'ContractUsageInvoice', id: string, contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } }, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_CorrectionInvoice_Fragment = { __typename: 'CorrectionInvoice', id: string, correction_record: { __typename?: 'InvoiceCorrectionRecord', original_invoice: { __typename: 'ArrearsInvoice', customer_plan: { __typename?: 'CustomerPlan', id: string, Customer: { __typename?: 'Customer', id: string } } | null } | { __typename: 'ContractPostpaidTrueupInvoice' } | { __typename: 'ContractScheduledInvoice', contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } } } | { __typename: 'ContractUsageInvoice', contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } } } }, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_CreditPurchaseInvoice_Fragment = { __typename: 'CreditPurchaseInvoice', id: string, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_ParentInvoice_Fragment = { __typename: 'ParentInvoice', id: string, customer: { __typename?: 'Customer', id: string } };

type InvoiceRoutePath_SeatPurchaseInvoice_Fragment = { __typename: 'SeatPurchaseInvoice', id: string, customer: { __typename?: 'Customer', id: string } };

export type InvoiceRoutePathFragment = InvoiceRoutePath_AdHocPlanInvoice_Fragment | InvoiceRoutePath_AdhocContractUsageInvoice_Fragment | InvoiceRoutePath_AdvanceInvoice_Fragment | InvoiceRoutePath_ArrearsInvoice_Fragment | InvoiceRoutePath_ContractPostpaidTrueupInvoice_Fragment | InvoiceRoutePath_ContractProServiceInvoice_Fragment | InvoiceRoutePath_ContractRefundInvoice_Fragment | InvoiceRoutePath_ContractScheduledInvoice_Fragment | InvoiceRoutePath_ContractUsageInvoice_Fragment | InvoiceRoutePath_CorrectionInvoice_Fragment | InvoiceRoutePath_CreditPurchaseInvoice_Fragment | InvoiceRoutePath_ParentInvoice_Fragment | InvoiceRoutePath_SeatPurchaseInvoice_Fragment;

export type CustomerPlanRoutePathFragment = { __typename?: 'CustomerPlan', id: string, Customer: { __typename?: 'Customer', id: string } };

export type ContractRoutePathFragment = { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } };

type InvoiceName_AdHocPlanInvoice_Fragment = { __typename: 'AdHocPlanInvoice', plan: { __typename?: 'Plan', name: string } };

type InvoiceName_AdhocContractUsageInvoice_Fragment = { __typename: 'AdhocContractUsageInvoice', contract: { __typename?: 'Contract', name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null } };

type InvoiceName_AdvanceInvoice_Fragment = { __typename: 'AdvanceInvoice', customer_plan: { __typename?: 'CustomerPlan', Plan: { __typename?: 'Plan', id: string, name: string } } | null };

type InvoiceName_ArrearsInvoice_Fragment = { __typename: 'ArrearsInvoice', customer_plan: { __typename?: 'CustomerPlan', Plan: { __typename?: 'Plan', id: string, name: string } } | null };

type InvoiceName_ContractPostpaidTrueupInvoice_Fragment = { __typename: 'ContractPostpaidTrueupInvoice', contract: { __typename?: 'Contract', name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null } };

type InvoiceName_ContractProServiceInvoice_Fragment = { __typename: 'ContractProServiceInvoice', contract: { __typename?: 'Contract', name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null } };

type InvoiceName_ContractRefundInvoice_Fragment = { __typename: 'ContractRefundInvoice', contract: { __typename?: 'Contract', name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null } };

type InvoiceName_ContractScheduledInvoice_Fragment = { __typename: 'ContractScheduledInvoice', contract: { __typename?: 'Contract', name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null } };

type InvoiceName_ContractUsageInvoice_Fragment = { __typename: 'ContractUsageInvoice', contract: { __typename?: 'Contract', name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null } };

type InvoiceName_CorrectionInvoice_Fragment = { __typename: 'CorrectionInvoice' };

type InvoiceName_CreditPurchaseInvoice_Fragment = { __typename: 'CreditPurchaseInvoice' };

type InvoiceName_ParentInvoice_Fragment = { __typename: 'ParentInvoice' };

type InvoiceName_SeatPurchaseInvoice_Fragment = { __typename: 'SeatPurchaseInvoice' };

export type InvoiceNameFragment = InvoiceName_AdHocPlanInvoice_Fragment | InvoiceName_AdhocContractUsageInvoice_Fragment | InvoiceName_AdvanceInvoice_Fragment | InvoiceName_ArrearsInvoice_Fragment | InvoiceName_ContractPostpaidTrueupInvoice_Fragment | InvoiceName_ContractProServiceInvoice_Fragment | InvoiceName_ContractRefundInvoice_Fragment | InvoiceName_ContractScheduledInvoice_Fragment | InvoiceName_ContractUsageInvoice_Fragment | InvoiceName_CorrectionInvoice_Fragment | InvoiceName_CreditPurchaseInvoice_Fragment | InvoiceName_ParentInvoice_Fragment | InvoiceName_SeatPurchaseInvoice_Fragment;

export type CustomerPlanNameFragment = { __typename?: 'CustomerPlan', Plan: { __typename?: 'Plan', id: string, name: string } };

export type ContractNameFragment = { __typename?: 'Contract', name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null };

type InvoiceDate_AdHocPlanInvoice_Fragment = { __typename: 'AdHocPlanInvoice', inclusive_start_date: string, exclusive_end_date: string, issued_at: string };

type InvoiceDate_AdhocContractUsageInvoice_Fragment = { __typename: 'AdhocContractUsageInvoice', inclusive_start_date: string, exclusive_end_date: string, issued_at: string };

type InvoiceDate_AdvanceInvoice_Fragment = { __typename: 'AdvanceInvoice', issued_at: string };

type InvoiceDate_ArrearsInvoice_Fragment = { __typename: 'ArrearsInvoice', inclusive_start_date: string, exclusive_end_date: string, issued_at: string };

type InvoiceDate_ContractPostpaidTrueupInvoice_Fragment = { __typename: 'ContractPostpaidTrueupInvoice', issued_at: string };

type InvoiceDate_ContractProServiceInvoice_Fragment = { __typename: 'ContractProServiceInvoice', issued_at: string };

type InvoiceDate_ContractRefundInvoice_Fragment = { __typename: 'ContractRefundInvoice', issued_at: string };

type InvoiceDate_ContractScheduledInvoice_Fragment = { __typename: 'ContractScheduledInvoice', issued_at: string };

type InvoiceDate_ContractUsageInvoice_Fragment = { __typename: 'ContractUsageInvoice', inclusive_start_date: string, exclusive_end_date: string, issued_at: string };

type InvoiceDate_CorrectionInvoice_Fragment = { __typename: 'CorrectionInvoice', issued_at: string };

type InvoiceDate_CreditPurchaseInvoice_Fragment = { __typename: 'CreditPurchaseInvoice', issued_at: string };

type InvoiceDate_ParentInvoice_Fragment = { __typename: 'ParentInvoice', inclusive_start_date: string, exclusive_end_date: string, issued_at: string };

type InvoiceDate_SeatPurchaseInvoice_Fragment = { __typename: 'SeatPurchaseInvoice', issued_at: string };

export type InvoiceDateFragment = InvoiceDate_AdHocPlanInvoice_Fragment | InvoiceDate_AdhocContractUsageInvoice_Fragment | InvoiceDate_AdvanceInvoice_Fragment | InvoiceDate_ArrearsInvoice_Fragment | InvoiceDate_ContractPostpaidTrueupInvoice_Fragment | InvoiceDate_ContractProServiceInvoice_Fragment | InvoiceDate_ContractRefundInvoice_Fragment | InvoiceDate_ContractScheduledInvoice_Fragment | InvoiceDate_ContractUsageInvoice_Fragment | InvoiceDate_CorrectionInvoice_Fragment | InvoiceDate_CreditPurchaseInvoice_Fragment | InvoiceDate_ParentInvoice_Fragment | InvoiceDate_SeatPurchaseInvoice_Fragment;

type InvoiceStatus_AdHocPlanInvoice_Fragment = { __typename: 'AdHocPlanInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_AdhocContractUsageInvoice_Fragment = { __typename: 'AdhocContractUsageInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_AdvanceInvoice_Fragment = { __typename: 'AdvanceInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_ArrearsInvoice_Fragment = { __typename: 'ArrearsInvoice', is_trial: boolean, status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_ContractPostpaidTrueupInvoice_Fragment = { __typename: 'ContractPostpaidTrueupInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_ContractProServiceInvoice_Fragment = { __typename: 'ContractProServiceInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_ContractRefundInvoice_Fragment = { __typename: 'ContractRefundInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_ContractScheduledInvoice_Fragment = { __typename: 'ContractScheduledInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_ContractUsageInvoice_Fragment = { __typename: 'ContractUsageInvoice', reseller_royalty_type: Types.ResellerType | null, status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_CorrectionInvoice_Fragment = { __typename: 'CorrectionInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_CreditPurchaseInvoice_Fragment = { __typename: 'CreditPurchaseInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type InvoiceStatus_ParentInvoice_Fragment = { __typename: 'ParentInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type InvoiceStatus_SeatPurchaseInvoice_Fragment = { __typename: 'SeatPurchaseInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

export type InvoiceStatusFragment = InvoiceStatus_AdHocPlanInvoice_Fragment | InvoiceStatus_AdhocContractUsageInvoice_Fragment | InvoiceStatus_AdvanceInvoice_Fragment | InvoiceStatus_ArrearsInvoice_Fragment | InvoiceStatus_ContractPostpaidTrueupInvoice_Fragment | InvoiceStatus_ContractProServiceInvoice_Fragment | InvoiceStatus_ContractRefundInvoice_Fragment | InvoiceStatus_ContractScheduledInvoice_Fragment | InvoiceStatus_ContractUsageInvoice_Fragment | InvoiceStatus_CorrectionInvoice_Fragment | InvoiceStatus_CreditPurchaseInvoice_Fragment | InvoiceStatus_ParentInvoice_Fragment | InvoiceStatus_SeatPurchaseInvoice_Fragment;

type StatusPillsFields_AdHocPlanInvoice_Fragment = { __typename: 'AdHocPlanInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_AdhocContractUsageInvoice_Fragment = { __typename: 'AdhocContractUsageInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_AdvanceInvoice_Fragment = { __typename: 'AdvanceInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_ArrearsInvoice_Fragment = { __typename: 'ArrearsInvoice', is_trial: boolean, status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_ContractPostpaidTrueupInvoice_Fragment = { __typename: 'ContractPostpaidTrueupInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_ContractProServiceInvoice_Fragment = { __typename: 'ContractProServiceInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_ContractRefundInvoice_Fragment = { __typename: 'ContractRefundInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_ContractScheduledInvoice_Fragment = { __typename: 'ContractScheduledInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_ContractUsageInvoice_Fragment = { __typename: 'ContractUsageInvoice', reseller_royalty_type: Types.ResellerType | null, status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_CorrectionInvoice_Fragment = { __typename: 'CorrectionInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_CreditPurchaseInvoice_Fragment = { __typename: 'CreditPurchaseInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

type StatusPillsFields_ParentInvoice_Fragment = { __typename: 'ParentInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type StatusPillsFields_SeatPurchaseInvoice_Fragment = { __typename: 'SeatPurchaseInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string, billing_provider_invoice: { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum } | null };

export type StatusPillsFieldsFragment = StatusPillsFields_AdHocPlanInvoice_Fragment | StatusPillsFields_AdhocContractUsageInvoice_Fragment | StatusPillsFields_AdvanceInvoice_Fragment | StatusPillsFields_ArrearsInvoice_Fragment | StatusPillsFields_ContractPostpaidTrueupInvoice_Fragment | StatusPillsFields_ContractProServiceInvoice_Fragment | StatusPillsFields_ContractRefundInvoice_Fragment | StatusPillsFields_ContractScheduledInvoice_Fragment | StatusPillsFields_ContractUsageInvoice_Fragment | StatusPillsFields_CorrectionInvoice_Fragment | StatusPillsFields_CreditPurchaseInvoice_Fragment | StatusPillsFields_ParentInvoice_Fragment | StatusPillsFields_SeatPurchaseInvoice_Fragment;

type BaseStatusPillsFields_AdHocPlanInvoice_Fragment = { __typename: 'AdHocPlanInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_AdhocContractUsageInvoice_Fragment = { __typename: 'AdhocContractUsageInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_AdvanceInvoice_Fragment = { __typename: 'AdvanceInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_ArrearsInvoice_Fragment = { __typename: 'ArrearsInvoice', is_trial: boolean, status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_ContractPostpaidTrueupInvoice_Fragment = { __typename: 'ContractPostpaidTrueupInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_ContractProServiceInvoice_Fragment = { __typename: 'ContractProServiceInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_ContractRefundInvoice_Fragment = { __typename: 'ContractRefundInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_ContractScheduledInvoice_Fragment = { __typename: 'ContractScheduledInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_ContractUsageInvoice_Fragment = { __typename: 'ContractUsageInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_CorrectionInvoice_Fragment = { __typename: 'CorrectionInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_CreditPurchaseInvoice_Fragment = { __typename: 'CreditPurchaseInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_ParentInvoice_Fragment = { __typename: 'ParentInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

type BaseStatusPillsFields_SeatPurchaseInvoice_Fragment = { __typename: 'SeatPurchaseInvoice', status: Types.InvoiceStatusEnum, on_hold: boolean, issued_at: string };

export type BaseStatusPillsFieldsFragment = BaseStatusPillsFields_AdHocPlanInvoice_Fragment | BaseStatusPillsFields_AdhocContractUsageInvoice_Fragment | BaseStatusPillsFields_AdvanceInvoice_Fragment | BaseStatusPillsFields_ArrearsInvoice_Fragment | BaseStatusPillsFields_ContractPostpaidTrueupInvoice_Fragment | BaseStatusPillsFields_ContractProServiceInvoice_Fragment | BaseStatusPillsFields_ContractRefundInvoice_Fragment | BaseStatusPillsFields_ContractScheduledInvoice_Fragment | BaseStatusPillsFields_ContractUsageInvoice_Fragment | BaseStatusPillsFields_CorrectionInvoice_Fragment | BaseStatusPillsFields_CreditPurchaseInvoice_Fragment | BaseStatusPillsFields_ParentInvoice_Fragment | BaseStatusPillsFields_SeatPurchaseInvoice_Fragment;

export type BillingProviderInvoiceFragment = { __typename?: 'BillingProviderInvoice', id: string, billing_provider: Types.BillingProviderEnum_Enum, billing_provider_invoice_id: string | null, billing_provider_error: string | null, issued_at: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum };

export const InvoiceTypeFragmentDoc = gql`
    fragment InvoiceType on MRI_Invoice {
  __typename
}
    `;
export const CustomerPlanRoutePathFragmentDoc = gql`
    fragment CustomerPlanRoutePath on CustomerPlan {
  id
  Customer {
    id
    __environment_type: environment_type
  }
}
    `;
export const ContractRoutePathFragmentDoc = gql`
    fragment ContractRoutePath on Contract {
  id
  customer {
    id
    __environment_type: environment_type
  }
}
    `;
export const InvoiceRoutePathFragmentDoc = gql`
    fragment InvoiceRoutePath on MRI_Invoice {
  __typename
  id
  customer {
    id
    __environment_type: environment_type
  }
  ... on AdHocPlanInvoice {
    plan {
      id
      __environment_type: environment_type
    }
  }
  ... on AdvanceInvoice {
    customer_plan {
      ...CustomerPlanRoutePath
    }
  }
  ... on ArrearsInvoice {
    customer_plan {
      ...CustomerPlanRoutePath
    }
  }
  ... on ContractScheduledInvoice {
    contract {
      ...ContractRoutePath
    }
  }
  ... on ContractProServiceInvoice {
    contract {
      ...ContractRoutePath
    }
  }
  ... on ContractUsageInvoice {
    contract {
      ...ContractRoutePath
    }
  }
  ... on AdhocContractUsageInvoice {
    contract {
      ...ContractRoutePath
    }
  }
  ... on ContractRefundInvoice {
    contract {
      ...ContractRoutePath
    }
  }
  ... on ContractPostpaidTrueupInvoice {
    contract {
      ...ContractRoutePath
    }
  }
  ... on CorrectionInvoice {
    correction_record {
      original_invoice {
        __typename
        ... on ArrearsInvoice {
          customer_plan {
            ...CustomerPlanRoutePath
          }
        }
        ... on ContractScheduledInvoice {
          contract {
            ...ContractRoutePath
          }
        }
        ... on ContractUsageInvoice {
          contract {
            ...ContractRoutePath
          }
        }
      }
    }
  }
}
    ${CustomerPlanRoutePathFragmentDoc}
${ContractRoutePathFragmentDoc}`;
export const CustomerPlanNameFragmentDoc = gql`
    fragment CustomerPlanName on CustomerPlan {
  Plan {
    id
    name
    __environment_type: environment_type
  }
}
    `;
export const ContractNameFragmentDoc = gql`
    fragment ContractName on Contract {
  name
  starting_at
  rate_card {
    id
    name
  }
}
    `;
export const InvoiceNameFragmentDoc = gql`
    fragment InvoiceName on MRI_Invoice {
  __typename
  ... on AdvanceInvoice {
    customer_plan {
      ...CustomerPlanName
    }
  }
  ... on AdHocPlanInvoice {
    plan {
      name
      __environment_type: environment_type
    }
  }
  ... on ArrearsInvoice {
    customer_plan {
      ...CustomerPlanName
    }
  }
  ... on ContractScheduledInvoice {
    contract {
      ...ContractName
    }
  }
  ... on ContractProServiceInvoice {
    contract {
      ...ContractName
    }
  }
  ... on ContractUsageInvoice {
    contract {
      ...ContractName
    }
  }
  ... on AdhocContractUsageInvoice {
    contract {
      ...ContractName
    }
  }
  ... on ContractRefundInvoice {
    contract {
      ...ContractName
    }
  }
  ... on ContractPostpaidTrueupInvoice {
    contract {
      ...ContractName
    }
  }
}
    ${CustomerPlanNameFragmentDoc}
${ContractNameFragmentDoc}`;
export const InvoiceDateFragmentDoc = gql`
    fragment InvoiceDate on MRI_Invoice {
  __typename
  issued_at
  ... on ArrearsInvoice {
    inclusive_start_date
    exclusive_end_date
  }
  ... on ContractUsageInvoice {
    inclusive_start_date
    exclusive_end_date
  }
  ... on AdhocContractUsageInvoice {
    inclusive_start_date
    exclusive_end_date
  }
  ... on ParentInvoice {
    inclusive_start_date
    exclusive_end_date
  }
  ... on AdHocPlanInvoice {
    inclusive_start_date
    exclusive_end_date
  }
}
    `;
export const BaseStatusPillsFieldsFragmentDoc = gql`
    fragment BaseStatusPillsFields on MRI_Invoice {
  status
  on_hold
  __typename
  issued_at
  ... on ArrearsInvoice {
    is_trial
  }
}
    `;
export const BillingProviderInvoiceFragmentDoc = gql`
    fragment BillingProviderInvoice on BillingProviderInvoice {
  id
  billing_provider
  billing_provider_invoice_id
  billing_provider_error
  issued_at
  due_date
  external_status
  __environment_type: environment_type
}
    `;
export const StatusPillsFieldsFragmentDoc = gql`
    fragment StatusPillsFields on MRI_Invoice {
  ...BaseStatusPillsFields
  ... on AdHocPlanInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on ArrearsInvoice {
    is_trial
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on CreditPurchaseInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on AdvanceInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on SeatPurchaseInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on CorrectionInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on ContractScheduledInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on ContractProServiceInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on ContractUsageInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
    reseller_royalty_type
  }
  ... on AdhocContractUsageInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on ContractRefundInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
  ... on ContractPostpaidTrueupInvoice {
    billing_provider_invoice {
      ...BillingProviderInvoice
      __environment_type: environment_type
    }
  }
}
    ${BaseStatusPillsFieldsFragmentDoc}
${BillingProviderInvoiceFragmentDoc}`;
export const InvoiceStatusFragmentDoc = gql`
    fragment InvoiceStatus on MRI_Invoice {
  ...StatusPillsFields
}
    ${StatusPillsFieldsFragmentDoc}`;