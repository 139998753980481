/* 854afe5d2268d6f7066770ec150c2919ee7e2abf
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCreditGrantMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
  reason?: Types.InputMaybe<Types.Scalars['String']>;
  expires_before: Types.Scalars['timestamptz'];
  billing_provider?: Types.InputMaybe<Types.BillingProviderEnum_Enum>;
  credit_grant_type?: Types.InputMaybe<Types.Scalars['String']>;
  rollover_settings?: Types.InputMaybe<Types.RolloverSettingsInput>;
}>;


export type UpdateCreditGrantMutation = { __typename?: 'Mutation', update_credit_grant: { __typename?: 'CreditGrant', id: string, name: string, reason: string | null, expires_before: string, billing_provider: Types.BillingProviderEnum_Enum | null, credit_grant_type: string | null } };

export type LatestInvoiceFinalizationDateQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type LatestInvoiceFinalizationDateQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, end_date_of_last_finalized_invoice: string | null } | null };


export const UpdateCreditGrantDocument = gql`
    mutation UpdateCreditGrant($id: uuid!, $name: String!, $reason: String, $expires_before: timestamptz!, $billing_provider: BillingProviderEnum_enum, $credit_grant_type: String, $rollover_settings: RolloverSettingsInput) {
  update_credit_grant(
    id: $id
    name: $name
    reason: $reason
    expires_before: $expires_before
    billing_provider: $billing_provider
    credit_grant_type: $credit_grant_type
    rollover_settings: $rollover_settings
  ) {
    id
    name
    reason
    expires_before
    billing_provider
    credit_grant_type
    __environment_type: environment_type
  }
}
    `;
export type UpdateCreditGrantMutationFn = Apollo.MutationFunction<UpdateCreditGrantMutation, UpdateCreditGrantMutationVariables>;

/**
 * __useUpdateCreditGrantMutation__
 *
 * To run a mutation, you first call `useUpdateCreditGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditGrantMutation, { data, loading, error }] = useUpdateCreditGrantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      reason: // value for 'reason'
 *      expires_before: // value for 'expires_before'
 *      billing_provider: // value for 'billing_provider'
 *      credit_grant_type: // value for 'credit_grant_type'
 *      rollover_settings: // value for 'rollover_settings'
 *   },
 * });
 */
export function useUpdateCreditGrantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreditGrantMutation, UpdateCreditGrantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCreditGrantMutation, UpdateCreditGrantMutationVariables>(UpdateCreditGrantDocument, options);
      }
export type UpdateCreditGrantMutationHookResult = ReturnType<typeof useUpdateCreditGrantMutation>;
export type UpdateCreditGrantMutationResult = Apollo.MutationResult<UpdateCreditGrantMutation>;
export type UpdateCreditGrantMutationOptions = Apollo.BaseMutationOptions<UpdateCreditGrantMutation, UpdateCreditGrantMutationVariables>;
export const LatestInvoiceFinalizationDateDocument = gql`
    query LatestInvoiceFinalizationDate($customer_id: uuid!) {
  customer: Customer_by_pk(id: $customer_id) {
    id
    end_date_of_last_finalized_invoice
    __environment_type: environment_type
  }
}
    `;

/**
 * __useLatestInvoiceFinalizationDateQuery__
 *
 * To run a query within a React component, call `useLatestInvoiceFinalizationDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestInvoiceFinalizationDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestInvoiceFinalizationDateQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useLatestInvoiceFinalizationDateQuery(baseOptions: Apollo.QueryHookOptions<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>(LatestInvoiceFinalizationDateDocument, options);
      }
export function useLatestInvoiceFinalizationDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>(LatestInvoiceFinalizationDateDocument, options);
        }
export type LatestInvoiceFinalizationDateQueryHookResult = ReturnType<typeof useLatestInvoiceFinalizationDateQuery>;
export type LatestInvoiceFinalizationDateLazyQueryHookResult = ReturnType<typeof useLatestInvoiceFinalizationDateLazyQuery>;
export type LatestInvoiceFinalizationDateQueryResult = Apollo.QueryResult<LatestInvoiceFinalizationDateQuery, LatestInvoiceFinalizationDateQueryVariables>;