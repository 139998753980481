/* 781009a660976109c3b4d4b085d2be764f4abc41
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlansQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  plan_type: Types.PlanTypeEnum;
}>;


export type PlansQuery = { __typename?: 'Query', plans: Array<{ __typename?: 'Plan', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, updated_at: string, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, PricedProductPricingFactors: Array<{ __typename?: 'PricedProductPricingFactor', id: string, ProductPricingFactor: { __typename?: 'ProductPricingFactor', id: string, name: string } }>, Product: { __typename?: 'Product', id: string, name: string } }>, PricedProducts_aggregate: { __typename?: 'PricedProduct_aggregate', aggregate: { __typename?: 'PricedProduct_aggregate_fields', count: number } | null } }> };

export type DraftPlansQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type DraftPlansQuery = { __typename?: 'Query', DraftPlan: Array<{ __typename?: 'DraftPlan', id: string, data: object, created_at: string, revision: number, Creator: { __typename?: 'Actor', id: string, name: string, deprecated_at: string | null } | null }> };

export type ActiveProductsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type ActiveProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string }> };

export type CustomerCountForPlanQueryVariables = Types.Exact<{
  plan_id: Types.Scalars['uuid'];
}>;


export type CustomerCountForPlanQuery = { __typename?: 'Query', Plan_by_pk: { __typename?: 'Plan', id: string, customer_count: number } | null };


export const PlansDocument = gql`
    query Plans($environment_type: EnvironmentTypeEnum_enum!, $plan_type: PlanTypeEnum!) {
  plans(environment_type: $environment_type, plan_type: $plan_type) {
    id
    name
    description
    created_at
    deprecated_at
    updated_at
    Actor {
      name
      id
      deprecated_at
    }
    PricedProducts {
      id
      PricedProductPricingFactors {
        id
        ProductPricingFactor {
          id
          name
          __environment_type: environment_type
        }
      }
      Product {
        id
        name
        __environment_type: environment_type
      }
    }
    PricedProducts_aggregate(distinct_on: product_id) {
      aggregate {
        count
      }
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      plan_type: // value for 'plan_type'
 *   },
 * });
 */
export function usePlansQuery(baseOptions: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const DraftPlansDocument = gql`
    query DraftPlans($environment_type: EnvironmentTypeEnum_enum!) {
  DraftPlan(where: {environment_type: {_eq: $environment_type}}) {
    id
    data
    created_at
    revision
    Creator {
      id
      name
      deprecated_at
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useDraftPlansQuery__
 *
 * To run a query within a React component, call `useDraftPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftPlansQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useDraftPlansQuery(baseOptions: Apollo.QueryHookOptions<DraftPlansQuery, DraftPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DraftPlansQuery, DraftPlansQueryVariables>(DraftPlansDocument, options);
      }
export function useDraftPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DraftPlansQuery, DraftPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DraftPlansQuery, DraftPlansQueryVariables>(DraftPlansDocument, options);
        }
export type DraftPlansQueryHookResult = ReturnType<typeof useDraftPlansQuery>;
export type DraftPlansLazyQueryHookResult = ReturnType<typeof useDraftPlansLazyQuery>;
export type DraftPlansQueryResult = Apollo.QueryResult<DraftPlansQuery, DraftPlansQueryVariables>;
export const ActiveProductsDocument = gql`
    query ActiveProducts($environment_type: EnvironmentTypeEnum_enum!) {
  products(environment_type: $environment_type, types: [ACTIVE]) {
    id
    __environment_type: environment_type
  }
}
    `;

/**
 * __useActiveProductsQuery__
 *
 * To run a query within a React component, call `useActiveProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveProductsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useActiveProductsQuery(baseOptions: Apollo.QueryHookOptions<ActiveProductsQuery, ActiveProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveProductsQuery, ActiveProductsQueryVariables>(ActiveProductsDocument, options);
      }
export function useActiveProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveProductsQuery, ActiveProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveProductsQuery, ActiveProductsQueryVariables>(ActiveProductsDocument, options);
        }
export type ActiveProductsQueryHookResult = ReturnType<typeof useActiveProductsQuery>;
export type ActiveProductsLazyQueryHookResult = ReturnType<typeof useActiveProductsLazyQuery>;
export type ActiveProductsQueryResult = Apollo.QueryResult<ActiveProductsQuery, ActiveProductsQueryVariables>;
export const CustomerCountForPlanDocument = gql`
    query CustomerCountForPlan($plan_id: uuid!) {
  Plan_by_pk(id: $plan_id) {
    id
    customer_count: customer_count(status: ACTIVE)
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCustomerCountForPlanQuery__
 *
 * To run a query within a React component, call `useCustomerCountForPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCountForPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCountForPlanQuery({
 *   variables: {
 *      plan_id: // value for 'plan_id'
 *   },
 * });
 */
export function useCustomerCountForPlanQuery(baseOptions: Apollo.QueryHookOptions<CustomerCountForPlanQuery, CustomerCountForPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerCountForPlanQuery, CustomerCountForPlanQueryVariables>(CustomerCountForPlanDocument, options);
      }
export function useCustomerCountForPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerCountForPlanQuery, CustomerCountForPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerCountForPlanQuery, CustomerCountForPlanQueryVariables>(CustomerCountForPlanDocument, options);
        }
export type CustomerCountForPlanQueryHookResult = ReturnType<typeof useCustomerCountForPlanQuery>;
export type CustomerCountForPlanLazyQueryHookResult = ReturnType<typeof useCustomerCountForPlanLazyQuery>;
export type CustomerCountForPlanQueryResult = Apollo.QueryResult<CustomerCountForPlanQuery, CustomerCountForPlanQueryVariables>;