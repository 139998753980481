import React from "react";
import { Column, SortFunctions, Table } from "../Table";
import { Subtitle, Badge, Tooltip } from "design-system";
import {
  generateAlertPolicyGroupKeyFilterString,
  generateAlertPolicyInvoiceTypesFilterString,
  generateAlertPolicyString,
} from "lib/alerts";
import { CreditType } from "types/credit-types";
import {
  AlertTypeEnum_Enum,
  CustomerAlertCompositeStatusEnum,
} from "types/generated-graphql/__types__";
import AlertsCustomerCell from "pages/Customer/tabs/Alerts/AlertsCustomerCell";
import { useUIMode } from "../../lib/useUIMode";

const MAX_ALERTS = 100;

interface AlertRow {
  id: string;
  name: string;
  threshold: string;
  CreditType: CreditType;
  alert_type: AlertTypeEnum_Enum;
  Customer: { id: string; name: string } | null;
  Plan: { id: string; name: string } | null;
  BillableMetric: { id: string; name: string } | null;
  filters: object | null;
  customer_status?: CustomerAlertCompositeStatusEnum;

  // TODO: add back in when we can make this not time out in the query.
  // in_alarm_count: number;

  // evaluating: boolean;
}

interface AlertsTableProps {
  loading: boolean;
  singleCustomer: boolean;
  alerts: AlertRow[];
}

export const AlertsTable: React.FC<AlertsTableProps> = ({
  alerts,
  loading,
  singleCustomer,
}) => {
  const { newUIEnabled } = useUIMode();
  const sortable = alerts.length < MAX_ALERTS;
  const columns: Column<AlertRow>[] = [
    {
      id: "name",
      header: "Alert name",
      textWrappable: true,
      render: (alert) => <Subtitle level={3}>{alert.name}</Subtitle>,
      sortable,
      comparator: SortFunctions.String((a) => a.name),
    },
    {
      id: "policy",
      header: "Alert policy",
      render: (alert) => {
        const groupKeyFilterString = generateAlertPolicyGroupKeyFilterString(
          alert.filters,
        );
        const invoiceTypesFilterString =
          generateAlertPolicyInvoiceTypesFilterString(alert.filters);
        return (
          <div className="flex flex-col items-start gap-4">
            <Badge
              theme="primary"
              type="light"
              className="font-normal text-primary-600"
            >
              {generateAlertPolicyString(
                alert.threshold,
                alert.CreditType,
                alert.alert_type,
                alert.BillableMetric?.name,
              )}
            </Badge>

            {groupKeyFilterString || invoiceTypesFilterString ? (
              <Tooltip
                inline={true}
                content={
                  <>
                    {groupKeyFilterString}
                    {invoiceTypesFilterString}
                  </>
                }
              >
                <Badge
                  theme="primary"
                  type="light"
                  className="inline-block max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap font-normal text-primary-600"
                >
                  {groupKeyFilterString}
                  {invoiceTypesFilterString}
                </Badge>
              </Tooltip>
            ) : null}
          </div>
        );
      },
      sortable,
      comparator: SortFunctions.String((a) =>
        generateAlertPolicyString(
          a.threshold,
          a.CreditType,
          a.alert_type,
          a.BillableMetric?.name,
        ),
      ),
    },
    {
      id: "customer",
      header: "Customer",
      render: (alert) => {
        return <AlertsCustomerCell alertId={alert.id} />;
      },
      sortable,
      comparator: SortFunctions.String(
        (a) => a.Customer?.name ?? a.Plan?.name ?? "All customers",
      ),
    },
    // TODO: Add this back when we can query in-alarm status again without
    // timing out.
    // {
    //   id: "status",
    //   header: "Alert status",
    //   // Show the number of customers in alarm, if any, else if there are any customers who haven't been evaluated show new, else OK
    //   render: (alert) => {
    //     return (
    //       <div className={classnames(styles.tableRow, styles.statusRow)}>
    //         <Subtitle level={3}>
    //           {alert.in_alarm_count > 0 ? (
    //             <Badge
    //               type="dark"
    //               theme="grey"
    //               className={styles.alertingBadge}
    //             >
    //               {singleCustomer ? "" : alert.in_alarm_count} alerting
    //             </Badge>
    //           ) : alert.evaluating ? (
    //             <Badge type="light" theme="primary">
    //               NEW
    //             </Badge>
    //           ) : (
    //             <Badge type="light" theme="grey">
    //               EVALUATING
    //               {/* TODO: Put this back in when we have in-alarm state
    //                   again. */}
    //               {/* OK */}
    //             </Badge>
    //           )}
    //         </Subtitle>
    //       </div>
    //     );
    //   },
    //   align: "right",
    //   sortable,
    //   comparator: (a, b) =>
    //     a.in_alarm_count || b.in_alarm_count
    //       ? SortFunctions.Number<AlertRow>((c) => c.in_alarm_count)(a, b)
    //       : a.evaluating && !b.evaluating ? -1 : 1,
    // },
    {
      id: "customer_status",
      header: "Alert status",
      disabled: !singleCustomer,
      render: (alert) => {
        return (
          <div className="flex flex-col items-start gap-4">
            <Badge
              theme={
                alert.customer_status ===
                CustomerAlertCompositeStatusEnum.InAlarm
                  ? "error"
                  : "primary"
              }
              type="light"
            >
              {alert.customer_status ??
                CustomerAlertCompositeStatusEnum.Evaluating}
            </Badge>
          </div>
        );
      },
      sortable: false,
    },
  ];

  return (
    <Table
      data={alerts}
      loading={loading}
      columns={columns}
      rowRoutePath={(a) =>
        `${newUIEnabled ? "/connections" : ""}/alerts/${a.id}`
      }
      defaultSortBy={[
        { id: "status", desc: true },
        { id: "name", desc: true },
      ]}
      maxPageSize={20}
      maxItems={MAX_ALERTS}
    />
  );
};
