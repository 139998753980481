import React, { useEffect, useState } from "react";

import { Popup } from "components/Popup";
import { Button } from "tenaissance/components/Button";
import { Input } from "design-system";
import { Body } from "design-system";

interface ChangePasswordModalProps {
  onClose: () => void;
}

// This should be kept in sync with https://github.com/Metronome-Industries/metronome-substrate/blob/main/modules/auth/global/main.tf#L212-L214
// Auth0 will enforce the rules no matter what but the user UX is better if the frontend also does.
const MIN_LENGTH = 10;

import { useChangePasswordMutation } from "./queries.graphql";

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  onClose,
}) => {
  const [changePassword, changePasswordResult] = useChangePasswordMutation();
  const [currentPassword, setCurrentPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [isCurrentPasswordInvalid, setIsCurrentPasswordInvalid] =
    useState(false);
  const [isPasswordTooWeak, setIsPasswordTooWeak] = useState(false);

  const valid = !!(
    currentPassword &&
    newPassword &&
    newPassword.length >= MIN_LENGTH &&
    newPassword === confirmPassword
  );

  useEffect(() => {
    if (
      changePasswordResult.error?.graphQLErrors?.[0]?.extensions?.code ===
      "BAD_USER_INPUT"
    ) {
      if (
        changePasswordResult.error.graphQLErrors[0].extensions.argument ===
        "current_password"
      ) {
        setIsCurrentPasswordInvalid(true);
      } else if (
        changePasswordResult.error.graphQLErrors[0].extensions.argument ===
        "new_password"
      ) {
        setIsPasswordTooWeak(true);
      }
    }
  }, [changePasswordResult.error]);

  // As the user changes the current password field, remove errors
  useEffect(() => {
    setIsCurrentPasswordInvalid(false);
  }, [currentPassword]);

  useEffect(() => {
    setIsPasswordTooWeak(false);
  }, [newPassword]);

  const save = async () => {
    if (!currentPassword || !newPassword) {
      return;
    }

    await changePassword({
      variables: {
        currentPassword,
        newPassword,
      },
    });

    onClose();
  };

  const actionButtons = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        disabled={!valid || changePasswordResult.loading}
        onClick={save}
        loading={changePasswordResult.loading}
        text="Save"
        theme="primary"
      />
    </>
  );

  let newPasswordError: string | undefined = undefined;
  if (isPasswordTooWeak) {
    newPasswordError = "Password is too weak";
  }
  if (newPassword && newPassword?.length < MIN_LENGTH) {
    newPasswordError = `Password must be at least ${MIN_LENGTH} characters`;
  }

  return (
    <Popup
      actions={actionButtons}
      isOpen
      onRequestClose={onClose}
      title="Change your password"
    >
      <Input
        value={currentPassword ?? ""}
        onChange={setCurrentPassword}
        name="Enter current password"
        error={isCurrentPasswordInvalid ? "Incorrect password" : undefined}
        placeholder="••••••••••••••"
        type="password"
      />
      <Body className="mt-8" level={2}>
        Enter a new password for your Metronome account. Your password must be
        at least {MIN_LENGTH} characters long.
      </Body>
      <Input
        value={newPassword ?? ""}
        onChange={setNewPassword}
        name="Enter new password"
        placeholder="••••••••••••••"
        type="password"
        disabled={!currentPassword}
        error={newPasswordError}
      />
      <Input
        value={confirmPassword ?? ""}
        onChange={setConfirmPassword}
        name="Confirm new password"
        placeholder="••••••••••••••"
        type="password"
        disabled={!currentPassword}
        error={
          confirmPassword && confirmPassword !== newPassword
            ? "Passwords do not match"
            : undefined
        }
      />
    </Popup>
  );
};
