import React, { useState } from "react";
import { useNavigate } from "lib/useNavigate";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useAllAlertsQuery } from "./queries.graphql";
import { Button } from "tenaissance/components/Button";
import { AlertsTable } from "tenaissance/components/AlertsTable";
import { GatedButton } from "components/GatedButton";
import { CreateAlertDocument } from "pages/NewAlert/queries.graphql";
import { EmptyState } from "tenaissance/components/EmptyState";

export const Alerts: React.FC = () => {
  const { environmentType } = useEnvironment();
  const [search, setSearch] = useState<string | undefined>();
  const { data, loading } = useAllAlertsQuery({
    variables: {
      environment_type: environmentType,
      search: search,
    },
  });
  const navigate = useNavigate();

  return (
    <AlertsTable
      loading={loading}
      emptyState={
        <EmptyState
          icon="bell03"
          mainText="There are no alerts"
          supportingText="Alerts allow you to monitor your customers and billing. You can use alerts to manage product access, gate customer spend, set usage thresholds, and monitor commitment drawdowns."
          actions={[
            <Button
              linkTo="https://docs.metronome.com/manage-product-access/create-manage-alerts/"
              isExternalLink
              text="Learn more"
              theme="tertiary"
              leadingIcon="linkExternal01"
            />,
            <GatedButton
              linkTo="/connections/alerts/new"
              doc={CreateAlertDocument}
              text="Create alert"
              theme="primary"
              leadingIcon="plus"
            />,
          ]}
        />
      }
      searchOptions={{
        showSearch: true,
        onSearch: (v) => setSearch(v),
      }}
      alertsData={data?.alerts ?? []}
      topBarActions={[
        <Button
          text="Add"
          leadingIcon="plus"
          onClick={() => navigate("/connections/alerts/new")}
        />,
      ]}
    />
  );
};
