import React, { useState } from "react";
import { Select } from "design-system";

import { FormController } from "lib/FormController";
import { useNow } from "lib/date";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";

import { Schema } from "../../Schema";
import { BillingSchedule } from "../../components/BillingSchedule";

import { AdditionalTermFlyover } from "./AdditionalTermFlyover";
import { useGetFixedProductsQuery } from "./data.graphql";
import { CreateProductModal } from "pages/Contracts/Pricing/CreateAndEditProductModal";
import { Button } from "tenaissance/components/Button";

const useScheduledChargeCtrl = FormController.createHook(
  Schema.ScheduledCharge,
  {
    init(props: Props) {
      return props.edit ?? {};
    },
  },
);

interface Props {
  edit?: Schema.Types.ScheduledCharge;
  onSave: (override: Schema.Types.ScheduledCharge) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

export const ScheduledChargeFlyover: React.FC<Props> = (props) => {
  const ctrl = useScheduledChargeCtrl(props);
  const now = useNow();
  const products = useGetFixedProductsQuery();
  const [showNewProductModal, setShowNewProductModal] = useState(false);

  return (
    <>
      {showNewProductModal && (
        <CreateProductModal
          enforceType="fixed"
          onClose={(newProductId) => {
            setShowNewProductModal(false);
            ctrl.update({
              productId: newProductId,
            });
          }}
        />
      )}
      <AdditionalTermFlyover
        {...props}
        ctrl={ctrl}
        title={props.edit ? "Edit scheduled charge" : "Add a scheduled charge"}
      >
        <Select
          {...ctrl.props.Select("productId", {
            name: "Product",
            placeholder: "Select a product",
            options: [
              {
                // Dummy UUID so error doesn't show while creating a new product
                value: "0ab465e4-cf01-4b70-ba31-7a377db38299",
                label: (
                  <Button
                    onClick={() => setShowNewProductModal(true)}
                    text="Add a product"
                    theme="linkGray"
                    leadingIcon="plus"
                  />
                ),
              },
              ...(products.data?.contract_pricing.products.map((product) => ({
                label: ProductListItem.getName(product, now),
                value: product.id,
              })) ?? []),
            ],
          })}
        />

        {ctrl.get("productId") && (
          <BillingSchedule ctrl={ctrl} mode="invoice" />
        )}
      </AdditionalTermFlyover>
    </>
  );
};
