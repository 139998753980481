import React from "react";

import { AppShell, PageContainer } from "components/PageContainer";
import { ParagraphSkeleton } from "components/Skeleton";

import { useNavigate } from "lib/useNavigate";
import { useSearchParam } from "lib/routes/useSearchParam";
import { useRequiredParam } from "lib/routes/params";
import { Breadcrumbs } from "lib/breadcrumbs";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";

import { useApolloResp } from "pages/Contracts/lib/ApolloResp";
import NotFoundPage from "pages/404";

import { CommitCard, parseCommitTab } from "./CommitCard";
import { useAsyncAllCommitUsage } from "../Usage";

import { useAllCommitsQuery } from "./data.graphql";
import { ExternalCommitType } from "types/generated-graphql/__types__";
import { findCommit } from "./findCommit";
import { Commit } from "pages/Contracts/lib/Commit";
import { dayjs } from "lib/dayjs";

export const LegacyDesignCustomerCommitOverview: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const commitId = useRequiredParam("commitId");

  const navigate = useNavigate();
  const activeTabParam = parseCommitTab(useSearchParam("tab")[0]);

  const req = useApolloResp(
    useAllCommitsQuery({
      variables: { customerId },
    }),
  );
  const asyncUsage = useAsyncAllCommitUsage({ customerId });

  React.useEffect(() => {
    if (req.state === "success") {
      const commit = req.customer.commits.find((c) => c.id === commitId);
      if (commit?.contract?.id) {
        navigate(
          `/customers/${customerId}/contracts/${commit.contract.id}/commits-and-credits`,
          {
            replace: true,
          },
        );
      }
    }
  }, [commitId, req]);

  if (req.state === "error") {
    return (
      <PageContainer title="Failed to load customer" hideHeader>
        <ErrorEmptyState
          title="We ran into an error loading this customer"
          error={req.error}
        />
      </PageContainer>
    );
  }

  if (req.state === "loading") {
    return <ParagraphSkeleton numLines={6} className="w-4/5 xl:w-3/4" />;
  }

  if (req.state === "not found") {
    return <NotFoundPage />;
  }

  const customer = req.customer;
  const commit = findCommit(req.customer, commitId);
  const routePath = `/customers/${customerId}/commits-and-credits/${commitId}`;
  if (!commit) {
    return <NotFoundPage />;
  }

  const pageTitle =
    commit.name ??
    commit.product.initial.name ??
    (commit.__typename === "PrepaidCommit" &&
    commit.external_type === ExternalCommitType.Credit
      ? "Credit"
      : "Commit");

  return (
    <PageContainer
      title={Breadcrumbs.from(
        {
          type: "back",
          label: "Back to customer list",
          routePath: "/customers",
        },
        {
          label: customer.name,
          routePath: `/customers/${customer.id}/commits-and-credits`,
        },
        {
          label: pageTitle,
          routePath,
        },
      )}
    >
      <CommitCard
        key={commit.id}
        commit={commit}
        focus={commit.id === commitId}
        activeTab={activeTabParam || "ledger"}
        asyncUsage={asyncUsage}
        onTabChange={(tab) => {
          navigate(`${routePath}?tab=${tab}`, {
            replace: true,
          });
        }}
      />
    </PageContainer>
  );
};

export const CustomerCommitOverview: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const commitId = useRequiredParam("commitId");

  const navigate = useNavigate();
  const activeTabParam = parseCommitTab(useSearchParam("tab")[0]);

  const req = useApolloResp(
    useAllCommitsQuery({
      variables: { customerId },
    }),
  );
  const asyncUsage = useAsyncAllCommitUsage({ customerId });

  let pageContent: React.ReactNode = null;
  let pageTitle: string | { state: "loading" } = { state: "loading" };
  if (req.state === "error") {
    pageTitle = "Error";
    pageContent = (
      <ErrorEmptyState
        title="We ran into an error loading this customer"
        error={req.error}
      />
    );
  } else if (req.state === "loading") {
    pageContent = <ParagraphSkeleton numLines={6} className="w-4/5 xl:w-3/4" />;
  } else if (req.state === "not found") {
    pageTitle = "Commit";
    pageContent = <NotFoundPage />;
  } else {
    const commit = findCommit(req.customer, commitId);
    if (!commit) {
      pageTitle = "Commit";
      pageContent = <NotFoundPage />;
    } else {
      pageTitle = Commit.getName(commit, dayjs());
      const routePath = `/customers/${customerId}/commits-and-credits/${commitId}`;
      pageContent = (
        <CommitCard
          commit={commit}
          activeTab={activeTabParam || "ledger"}
          asyncUsage={asyncUsage}
          onTabChange={(tab) => {
            navigate(`${routePath}?tab=${tab}`, {
              replace: true,
            });
          }}
        />
      );
    }
  }

  return (
    <AppShell
      title={pageTitle}
      headerProps={{
        breadcrumbs:
          req.state === "success" || req.state === "loading"
            ? Breadcrumbs.from(
                {
                  label: "Customers",
                  routePath: "/customers",
                },
                req.state === "loading"
                  ? { type: "loading", label: "" }
                  : {
                      label: req.customer.name,
                      routePath: `/customers/${customerId}`,
                    },
                {
                  label: "Contract commits and credits",
                  routePath: `/customers/${customerId}/commits-and-credits`,
                },
              )
            : undefined,
      }}
    >
      {pageContent}
    </AppShell>
  );
};
