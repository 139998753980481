import React from "react";
import {
  Environment,
  useEnvironment,
} from "../../../lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "../../../types/generated-graphql/__types__";
import { Tooltip } from "../Tooltip";
import { Dropdown, DropdownItem } from "../Dropdown";
import { Button } from "../Button";

const environmentOrder = [
  EnvironmentTypeEnum_Enum.Production,
  EnvironmentTypeEnum_Enum.Uat,
  EnvironmentTypeEnum_Enum.Qa,
  EnvironmentTypeEnum_Enum.Staging,
  EnvironmentTypeEnum_Enum.Sandbox,
];

export const EnvironmentSwitcher: React.FC<{}> = () => {
  const {
    environments,
    environment,
    changingSupported,
    setEnvironment,
    supported,
  } = useEnvironment();

  const options = environmentOrder
    .map((et) => environments.find((e) => e.type === et))
    .filter<Environment>((e): e is Environment => !!e && e.enabled)
    .map((e) => (
      <DropdownItem
        key={e.id}
        label={e.name}
        onClick={({ value }) => {
          const newEnvironment = environments.find((e) => e.id === value);

          if (newEnvironment) {
            setEnvironment(newEnvironment);
          }
        }}
        value={e.id}
      />
    ));

  return (
    <div className="flex w-[128px] flex-1">
      {supported ? (
        <Tooltip
          label="Can't switch environments while viewing a specific resource."
          disabled={changingSupported}
          fullWidth={true}
        >
          <Dropdown
            disabled={!changingSupported}
            selectedOption={environment.name}
            label={environment.name}
            fullWidth={true}
            buttonSize="sm"
          >
            {options}
          </Dropdown>
        </Tooltip>
      ) : (
        <Tooltip
          label="Environment switching is disabled for organization-wide pages"
          fullWidth={true}
        >
          <Button
            text="Account"
            trailingIcon="userEdit"
            theme="secondary"
            size="sm"
            disabled={true}
            showFullWidth={true}
          />
        </Tooltip>
      )}
    </div>
  );
};
