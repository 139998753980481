/* 6679b50f607f047c8dc8e769081dced101087f80
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListBillableMetricsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type ListBillableMetricsQuery = { __typename?: 'Query', billable_metrics: Array<{ __typename?: 'BillableMetric', id: string, name: string, group_keys: object | null, sql: string | null }>, seat_metrics: { __typename?: 'PagedSeatMetricsResult', metrics: Array<{ __typename?: 'SeatMetric', id: string, name: string }> } };

export type NewProductMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  description: Types.Scalars['String'];
  pricing_factors: Types.ProductPricingFactor_Arr_Rel_Insert_Input;
  group_key?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type NewProductMutation = { __typename?: 'Mutation', insert_Product_one: { __typename?: 'Product', id: string } | null };


export const ListBillableMetricsDocument = gql`
    query ListBillableMetrics($environment_type: EnvironmentTypeEnum_enum!) {
  billable_metrics(environment_type: $environment_type, types: [ACTIVE]) {
    id
    name
    group_keys
    sql
    __environment_type: environment_type
  }
  seat_metrics(types: [ACTIVE]) {
    metrics {
      id
      name
    }
  }
}
    `;

/**
 * __useListBillableMetricsQuery__
 *
 * To run a query within a React component, call `useListBillableMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBillableMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBillableMetricsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useListBillableMetricsQuery(baseOptions: Apollo.QueryHookOptions<ListBillableMetricsQuery, ListBillableMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBillableMetricsQuery, ListBillableMetricsQueryVariables>(ListBillableMetricsDocument, options);
      }
export function useListBillableMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBillableMetricsQuery, ListBillableMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBillableMetricsQuery, ListBillableMetricsQueryVariables>(ListBillableMetricsDocument, options);
        }
export type ListBillableMetricsQueryHookResult = ReturnType<typeof useListBillableMetricsQuery>;
export type ListBillableMetricsLazyQueryHookResult = ReturnType<typeof useListBillableMetricsLazyQuery>;
export type ListBillableMetricsQueryResult = Apollo.QueryResult<ListBillableMetricsQuery, ListBillableMetricsQueryVariables>;
export const NewProductDocument = gql`
    mutation NewProduct($name: String!, $description: String!, $pricing_factors: ProductPricingFactor_arr_rel_insert_input!, $group_key: String) {
  insert_Product_one(
    object: {name: $name, description: $description, ProductPricingFactors: $pricing_factors, group_key: $group_key}
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type NewProductMutationFn = Apollo.MutationFunction<NewProductMutation, NewProductMutationVariables>;

/**
 * __useNewProductMutation__
 *
 * To run a mutation, you first call `useNewProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newProductMutation, { data, loading, error }] = useNewProductMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      pricing_factors: // value for 'pricing_factors'
 *      group_key: // value for 'group_key'
 *   },
 * });
 */
export function useNewProductMutation(baseOptions?: Apollo.MutationHookOptions<NewProductMutation, NewProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewProductMutation, NewProductMutationVariables>(NewProductDocument, options);
      }
export type NewProductMutationHookResult = ReturnType<typeof useNewProductMutation>;
export type NewProductMutationResult = Apollo.MutationResult<NewProductMutation>;
export type NewProductMutationOptions = Apollo.BaseMutationOptions<NewProductMutation, NewProductMutationVariables>;