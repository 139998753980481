/* 45e18d1cd45cdbea182c540212f4c77ebc953fda
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomersWithStripeEnabledCountQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type GetCustomersWithStripeEnabledCountQuery = { __typename?: 'Query', Customer_aggregate: { __typename?: 'Customer_aggregate', aggregate: { __typename?: 'Customer_aggregate_fields', count: number } | null } };

export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation = { __typename?: 'Mutation', delete_BillingProviderToken: { __typename?: 'BillingProviderToken_mutation_response', returning: Array<{ __typename?: 'BillingProviderToken', account_id: string }> } | null, delete_BillingProviderCustomer: { __typename?: 'BillingProviderCustomer_mutation_response', returning: Array<{ __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string }> } | null };

export type GetClientConfigQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type GetClientConfigQuery = { __typename?: 'Query', Client: Array<{ __typename?: 'Client', id: string }>, ClientConfig: Array<{ __typename?: 'ClientConfig', id: string, value: string, key: Types.ClientConfigKeyEnum_Enum }>, list_delivery_methods: { __typename?: 'ListDeliveryMethodsResponse', delivery_methods: Array<{ __typename?: 'BillingProviderDeliveryMethod', id: string, billing_provider: Types.BillingProviderEnum_Enum, delivery_method: Types.BillingProviderDeliveryMethod_Enum, delivery_method_configuration: { [key: string]: string | number | boolean } }> } };

export type DualWriteClientConfigMutationVariables = Types.Exact<{
  configuration: Types.Scalars['FlatKeyValueMap'];
  configKeysToRemove?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type DualWriteClientConfigMutation = { __typename?: 'Mutation', update_client_billing_provider_configurations: { __typename?: 'MutationResponse', success: boolean } };


export const GetCustomersWithStripeEnabledCountDocument = gql`
    query GetCustomersWithStripeEnabledCount($environment_type: EnvironmentTypeEnum_enum!) {
  Customer_aggregate(
    where: {BillingProviderCustomers: {billing_provider: {_eq: STRIPE}}, environment_type: {_eq: $environment_type}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCustomersWithStripeEnabledCountQuery__
 *
 * To run a query within a React component, call `useGetCustomersWithStripeEnabledCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersWithStripeEnabledCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersWithStripeEnabledCountQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useGetCustomersWithStripeEnabledCountQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>(GetCustomersWithStripeEnabledCountDocument, options);
      }
export function useGetCustomersWithStripeEnabledCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>(GetCustomersWithStripeEnabledCountDocument, options);
        }
export type GetCustomersWithStripeEnabledCountQueryHookResult = ReturnType<typeof useGetCustomersWithStripeEnabledCountQuery>;
export type GetCustomersWithStripeEnabledCountLazyQueryHookResult = ReturnType<typeof useGetCustomersWithStripeEnabledCountLazyQuery>;
export type GetCustomersWithStripeEnabledCountQueryResult = Apollo.QueryResult<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>;
export const DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersDocument = gql`
    mutation DeleteBillingProviderTokenAndAllStripeBillingProviderCustomers {
  delete_BillingProviderToken(where: {billing_provider: {_eq: STRIPE}}) {
    returning {
      account_id
      __environment_type: environment_type
    }
  }
  delete_BillingProviderCustomer(where: {billing_provider: {_eq: STRIPE}}) {
    returning {
      billing_provider_customer_id
    }
  }
}
    `;
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationFn = Apollo.MutationFunction<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>;

/**
 * __useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation__
 *
 * To run a mutation, you first call `useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, { data, loading, error }] = useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>(DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersDocument, options);
      }
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationHookResult = ReturnType<typeof useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation>;
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationResult = Apollo.MutationResult<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation>;
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationOptions = Apollo.BaseMutationOptions<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>;
export const GetClientConfigDocument = gql`
    query GetClientConfig($environment_type: EnvironmentTypeEnum_enum!) {
  Client {
    id
  }
  ClientConfig(
    where: {key: {_in: [leave_invoices_in_draft, skip_zero_dollar_invoices, export_invoice_sub_line_items, include_zero_quantity_sub_line_items, stripe_invoice_quantity_always_string, invoice_days_until_due, set_effective_at_date_to_inclusive_period_end]}, environment_type: {_eq: $environment_type}}
  ) {
    id
    value
    key
    __environment_type: environment_type
  }
  list_delivery_methods {
    delivery_methods {
      id
      billing_provider
      delivery_method
      delivery_method_configuration
      __environment_type: environment_type
    }
  }
}
    `;

/**
 * __useGetClientConfigQuery__
 *
 * To run a query within a React component, call `useGetClientConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientConfigQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useGetClientConfigQuery(baseOptions: Apollo.QueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
      }
export function useGetClientConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
        }
export type GetClientConfigQueryHookResult = ReturnType<typeof useGetClientConfigQuery>;
export type GetClientConfigLazyQueryHookResult = ReturnType<typeof useGetClientConfigLazyQuery>;
export type GetClientConfigQueryResult = Apollo.QueryResult<GetClientConfigQuery, GetClientConfigQueryVariables>;
export const DualWriteClientConfigDocument = gql`
    mutation DualWriteClientConfig($configuration: FlatKeyValueMap!, $configKeysToRemove: [String!]) {
  update_client_billing_provider_configurations(
    billing_provider: STRIPE
    delivery_method: DIRECT_TO_BILLING_PROVIDER
    configuration: $configuration
    config_keys_to_remove: $configKeysToRemove
  ) {
    success
  }
}
    `;
export type DualWriteClientConfigMutationFn = Apollo.MutationFunction<DualWriteClientConfigMutation, DualWriteClientConfigMutationVariables>;

/**
 * __useDualWriteClientConfigMutation__
 *
 * To run a mutation, you first call `useDualWriteClientConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDualWriteClientConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dualWriteClientConfigMutation, { data, loading, error }] = useDualWriteClientConfigMutation({
 *   variables: {
 *      configuration: // value for 'configuration'
 *      configKeysToRemove: // value for 'configKeysToRemove'
 *   },
 * });
 */
export function useDualWriteClientConfigMutation(baseOptions?: Apollo.MutationHookOptions<DualWriteClientConfigMutation, DualWriteClientConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DualWriteClientConfigMutation, DualWriteClientConfigMutationVariables>(DualWriteClientConfigDocument, options);
      }
export type DualWriteClientConfigMutationHookResult = ReturnType<typeof useDualWriteClientConfigMutation>;
export type DualWriteClientConfigMutationResult = Apollo.MutationResult<DualWriteClientConfigMutation>;
export type DualWriteClientConfigMutationOptions = Apollo.BaseMutationOptions<DualWriteClientConfigMutation, DualWriteClientConfigMutationVariables>;