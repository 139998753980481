import React, { useState } from "react";
import { Badge } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";

import { CustomerPlansQuery } from "../data/plans.graphql";
import { useNavigate } from "lib/useNavigate";
import styles from "../index.module.less";
import { PlanPreview } from "components/PlanPreview";
import { PopoverMenu, MenuItemProps } from "components/PopoverMenu";
import CancelPlanModal from "./CancelPlanModal";
import { SetTrialPane } from "./SetTrialPane";
import { dayjs } from "lib/dayjs";
import pluralize from "pluralize";
import { CustomCreditType, FiatCreditType } from "types/credit-types";
import { Decimal } from "decimal.js";
import classnames from "classnames";
import { unserializeCustomPricing } from "lib/customPricing";
import { EditNetPaymentTermsModal } from "./EditNetPaymentTermsModal";
import { gatedAction, useAuthCheck } from "lib/useAuthCheck";
import { CancelPlanDocument } from "./CancelPlanModal/queries.graphql";
import { EditNetPaymentTermsDocument } from "./EditNetPaymentTermsModal/queries.graphql";
import { UpdateCustomerPlanTrialDocument } from "./SetTrialPane/queries.graphql";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";

type CustomerPlan = CustomerPlansQuery["CustomerPlan"][number];

export interface PlanPanelProps {
  customerPlan: CustomerPlan;
  customerId: string;
  customerName: string;
}

function usePlanInfo(customerPlan: CustomerPlan | null) {
  return React.useMemo(() => {
    if (customerPlan === null) {
      return { start: null, end: null, active: null };
    }
    const now = new Date();
    const start = new Date(customerPlan.start_date);
    const end = customerPlan.cancellation_date
      ? new Date(customerPlan.cancellation_date)
      : null;
    const active = start < now && !(end && now >= end);

    return { now, start, end, active };
  }, [customerPlan]);
}

export function usePlanMenus({
  customerPlan,
  customerId,
  customerName,
}: Omit<PlanPanelProps, "customerPlan"> & {
  customerPlan: CustomerPlan | null;
}) {
  const { now, start, end } = usePlanInfo(customerPlan);
  const navigate = useNavigate();
  const canEndPlan = !!useAuthCheck(CancelPlanDocument, true).allowed;
  const canEditNPT = !!useAuthCheck(EditNetPaymentTermsDocument, true).allowed;
  const canEditTrial = !!useAuthCheck(UpdateCustomerPlanTrialDocument, true)
    .allowed;

  const [openMenu, setOpenMenu] = useState<
    "cancelPlan" | "editTrial" | "editNPT"
  >();

  if (customerPlan === null || start === null) {
    return {
      menuOptions: [],
      openMenuComponent: null,
    };
  }

  const menuOptions: MenuItemProps[] = [
    {
      content: "Manage custom fields...",
      disabled: false,
      onClick: () =>
        navigate(`/custom-fields/customer-plan/${customerPlan.id}`),
    },
  ];
  if (customerPlan.trial_spec) {
    menuOptions.push(
      gatedAction(canEditTrial, {
        content: "Edit trial...",
        disabled: customerPlan.already_invoiced,
        onClick: () => {
          setOpenMenu("editTrial");
        },
      }),
    );
  } else {
    menuOptions.push(
      gatedAction(canEditTrial, {
        content: "Add trial",
        disabled: customerPlan.already_invoiced,
        onClick: () => {
          setOpenMenu("editTrial");
        },
      }),
    );
  }
  menuOptions.push(
    gatedAction(canEditNPT, {
      content: "Edit net payment terms...",
      disabled: false,
      onClick: () => {
        setOpenMenu("editNPT");
      },
    }),
  );
  menuOptions.push(
    gatedAction(canEndPlan, {
      content: "End plan...",
      disabled: !!(end && end < now),
      onClick: () => {
        setOpenMenu("cancelPlan");
      },
    }),
  );

  const creditTypeConversions = customerPlan.Plan.CreditTypeConversions.map(
    (conv) => {
      return {
        startPeriod: Number(conv.start_period),
        toFiatConversionFactor: Number(conv.to_fiat_conversion_factor),
        customCreditType: conv.CustomCreditType as CustomCreditType,
        fiatCreditType: conv.FiatCreditType as FiatCreditType,
      };
    },
  );

  if (openMenu === "cancelPlan") {
    return {
      menuOptions,
      openMenuComponent: (
        <CancelPlanModal
          customerPlan={customerPlan}
          onClose={() => setOpenMenu(undefined)}
          customerName={customerName}
          customerId={customerId}
        />
      ),
    };
  }

  if (openMenu === "editNPT") {
    return {
      menuOptions,
      openMenuComponent: (
        <EditNetPaymentTermsModal
          onClose={() => setOpenMenu(undefined)}
          customerPlanId={customerPlan.id}
          initialNetPaymentTerms={customerPlan.net_payment_terms_days}
        />
      ),
    };
  }

  if (openMenu === "editTrial") {
    return {
      menuOptions,
      openMenuComponent: (
        <SetTrialPane
          trialStartDate={start}
          trialLengthInDays={
            customerPlan.trial_spec
              ? Number(customerPlan.trial_spec.length_in_days)
              : 0
          }
          trialSpendingCap={
            customerPlan.trial_spec?.TrialSpecSpendingCaps.length
              ? customerPlan.trial_spec.TrialSpecSpendingCaps[0]
              : null
          }
          trialRemainingSpend={
            customerPlan.mri_remaining_trial_spending_caps.length
              ? {
                  amount: new Decimal(
                    customerPlan.mri_remaining_trial_spending_caps[0].amount,
                  ),
                  credit_type:
                    customerPlan.mri_remaining_trial_spending_caps[0]
                      .credit_type,
                }
              : undefined
          }
          customerPlanId={customerPlan.id}
          customerName={customerName}
          creditTypes={Array.from(
            new Set(
              customerPlan.Plan.PricedProducts.flatMap(
                (pp) => pp.CreditType,
              ).concat(
                customerPlan.Plan.CreditTypeConversions.flatMap(
                  (conv) => conv.FiatCreditType,
                ),
              ),
            ),
          )}
          conversions={creditTypeConversions}
          onClose={() => {
            setOpenMenu(undefined);
          }}
        />
      ),
    };
  }

  return {
    menuOptions,
    openMenuComponent: null,
  };
}

export const PlanPanelContent: React.FC<
  PlanPanelProps & {
    menuOptions?: MenuItemProps[];
    customPlanBasics?: React.ReactElement;
  }
> = (props) => {
  const { customerPlan, menuOptions } = props;
  const { end, active } = usePlanInfo(customerPlan);

  const trialEndDayjs = dayjs.min([
    dayjs
      .utc(customerPlan.start_date)
      .add(Number(customerPlan.trial_spec?.length_in_days ?? 0), "day"),
    ...(end ? [dayjs.utc(end)] : []),
  ]);
  const daysLeftInTrial = trialEndDayjs.diff(dayjs.utc().endOf("day"), "day");
  const trialIsInPast = dayjs.utc().isSameOrAfter(trialEndDayjs);

  const customPricing = unserializeCustomPricing(
    customerPlan.CustomPricings.find((cp) => {
      return cp.plan_id === customerPlan.Plan.id;
    }),
  );

  return (
    <>
      {props.customPlanBasics}
      <PlanPreview
        theme="contracts"
        plan={{
          ...customerPlan.Plan,
          TrialSpec: customerPlan.trial_spec,
          net_payment_terms_days:
            customerPlan.net_payment_terms_days ?? undefined,
        }}
        hidePlanBasics={!!props.customPlanBasics}
        smallPlanPreview
        collapsible={true}
        customPricing={customPricing}
        actions={
          menuOptions ? (
            <PopoverMenu
              positions={["bottom"]}
              align="start"
              options={menuOptions}
            >
              {(onClick) => (
                <IconButton
                  onClick={onClick}
                  theme="secondary"
                  icon="settings01"
                />
              )}
            </PopoverMenu>
          ) : undefined
        }
        badge={
          <Badge
            type={active && !trialIsInPast ? "dark" : "light"}
            theme={active ? "success" : "grey"}
            className={classnames(styles.stateBadge, {
              [styles.trialBadge]: active && !trialIsInPast,
            })}
          >
            {active
              ? !trialIsInPast
                ? daysLeftInTrial === 0
                  ? `TRIAL PERIOD (< 1 day remaining)`
                  : `TRIAL PERIOD (${daysLeftInTrial} ${pluralize(
                      "day",
                      daysLeftInTrial,
                    )} remaining)`
                : "ACTIVE"
              : "INACTIVE"}
          </Badge>
        }
        creditTypeConversionAdjustments={customerPlan?.CreditTypeConversionAdjustments?.map(
          (adjustment) => ({
            customCreditType: {
              ...adjustment.CustomCreditType,
              // client_id should always be non-null for a custom credit type and null for a fiat
              // credit type. However, they both share the same underlying CreditType type,
              // which has the field typed as `string | null`. Therefore, we need to do explicit
              // casting here to make things type-check.
              client_id: adjustment.CustomCreditType.client_id as string,
              environment_type: adjustment.CustomCreditType
                .environment_type as EnvironmentTypeEnum_Enum,
            },
            fiatCreditType: {
              ...adjustment.FiatCreditType,
              client_id: null,
              environment_type: null,
            },
            startPeriod: new Decimal(adjustment.start_period).toNumber(),
            toFiatConversionFactor: new Decimal(
              adjustment.to_fiat_conversion_factor,
            ).toNumber(),
          }),
        )}
      />
    </>
  );
};

const PlanPanel: React.FC<PlanPanelProps> = (props) => {
  const { menuOptions, openMenuComponent } = usePlanMenus(props);

  return (
    <div>
      {openMenuComponent}
      <div className={styles.planPanel}>
        <PlanPanelContent {...props} menuOptions={menuOptions} />
      </div>
    </div>
  );
};

export default PlanPanel;
