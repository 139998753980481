import React from "react";
import "style/index.css";
import { twMerge } from "tenaissance/twMerge";
import { Tooltip } from "../Tooltip";

type ProgressUnit = {
  label: string;
  value: number;
  presentationValue?: React.ReactNode;
};

type ExceededMessage = {
  label: string;
  subLabel?: string;
};

export type ProgressBarProps = {
  /**
   * If the sum of all `values` is greater than the `total`, you can optionally provide an error message in a Tooltip.
   * You can provide both a `label` and a `subLabel`*/
  exceededMessage?: ExceededMessage;
  /** Include additional strings or actions in above the legend for the ProgressBar */
  headerContent?: React.ReactNode[];
  /** Optionally provide a user-readble `total`. For example, ProgressBar can render "$50,000 USD" instead of 50000 as it's `total` */
  presentationTotal?: React.ReactNode;
  /**
   * If the sum of all `values` is greater than the `total`, you can choose to add a small affordance (a pulsing red block at the end)
   * with a message in a Tooltip
   * */
  showExceeded?: boolean;
  /** The total number that ProgressBar will consider "100%" of what is being measured */
  total: number;
  /**
   * The value or values that will be considered in calculating how close to 100% (aka `total`) the value being measured is.
   * Include a label to give context to what is being measured. Optionally include a `presentationValue` that would be rendered next
   * to the ProgressBar. (i.e. "$38,854.25 USD" instead of 3885425).
   * */
  values: ProgressUnit | ProgressUnit[];
};

const VALID_PROGRESS_COLORS = [
  "bg-core-deep-denim",
  "bg-core-jade-green",
  "bg-core-gold",
  "bg-core-indigo",
  "bg-core-midnight",
  "bg-warning-600",
  "bg-core-vibrant-magenta",
  "bg-core-dark-green",
  "bg-core-hot-green",
  "bg-core-azure-blue",
];

const getPercentage = (value: number, total: number) => {
  if (total === 0) {
    return 0;
  }
  return (value / total) * 100;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  exceededMessage,
  headerContent,
  presentationTotal,
  showExceeded,
  total,
  values,
}) => {
  const hasExceededTotal = Array.isArray(values)
    ? values.reduce((sum, current) => sum + current.value, 0) > total
    : values.value > total;

  return (
    <div className="w-full bg-white">
      <div className="mb-lg flex">
        <div className="flex flex-1 flex-col justify-end gap-y-md">
          {headerContent && (
            <div className="flex gap-x-md">
              {headerContent.map((action, i) => (
                <div className="self-center" key={i}>
                  {action}
                </div>
              ))}
            </div>
          )}

          <ul className="flex">
            {Array.isArray(values) ? (
              values.map((value, index) => (
                <li
                  className="mr-lg inline-block truncate text-xs font-medium text-gray-600"
                  key={`item-${index}`}
                >
                  <div className="flex items-center justify-end">
                    <div
                      className={twMerge(
                        "mr-[5px] inline-block h-md w-md rounded-full bg-[currentcolor] align-middle",
                        VALID_PROGRESS_COLORS[index],
                      )}
                    />
                    {`${value.label}: ${value.presentationValue ?? value.value}`}
                  </div>
                </li>
              ))
            ) : (
              <li className="mr-lg inline-block truncate text-xs font-medium text-gray-600">
                <div className="flex items-center justify-end">
                  <div
                    className={twMerge(
                      "mr-[5px] inline-block h-md w-md rounded-full bg-[currentcolor] align-middle",
                      VALID_PROGRESS_COLORS[0],
                    )}
                  />
                  {`${values.label}: `}
                  {values.presentationValue ?? values.value}
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className="flex flex-col items-end justify-end gap-y-xxs">
          <div className="text-xs leading-[18px] text-gray-500">Total</div>
          <div className="text-md font-semibold text-gray-500">
            {presentationTotal ?? total}
          </div>
        </div>
      </div>

      <div className="relative flex h-md w-full rounded-full">
        {Array.isArray(values) ? (
          values.map((v, idx) => (
            <div
              key={idx}
              className="relative"
              style={{ width: `${getPercentage(v.value, total)}%` }}
            >
              <div
                className={twMerge(
                  "h-md rounded-full",
                  VALID_PROGRESS_COLORS[idx],
                )}
              />
              {idx !== 0 && (
                <div className="absolute top-none h-md w-xs bg-white [clip-path:_ellipse(100%_50%_at_left_center)]" />
              )}
            </div>
          ))
        ) : (
          <div
            className={twMerge("h-md rounded-full", VALID_PROGRESS_COLORS[0])}
            style={{ width: `${getPercentage(values.value, total)}%` }}
          />
        )}

        {showExceeded && hasExceededTotal ? (
          <div className="relative left-[-8px] w-[11px] animate-pulse">
            <Tooltip
              label={exceededMessage?.label ?? "Total Exceeded"}
              subLabel={exceededMessage?.subLabel}
            >
              <div className="h-md rounded-r-full bg-core-vibrant-magenta" />
            </Tooltip>
          </div>
        ) : (
          <div className="relative flex-1">
            <div className="h-md rounded-full bg-gray-200" />
            <div className="absolute top-none h-md w-xs bg-white [clip-path:_ellipse(100%_50%_at_left_center)]" />
          </div>
        )}
      </div>
    </div>
  );
};
