import React from "react";
import Decimal from "decimal.js";
import { Badge } from "design-system";

import { InPanelTable } from "components/TablePanel";
import { TextSkeleton } from "components/Skeleton";
import { TinyErrorState } from "lib/errors/TinyErrorState";
import { getUserFacingErrorMessage } from "lib/errors/errorHandling";
import { useNow, toDayjs, printDate, isInRange } from "lib/date";

import { ColWidths } from "../../../lib/ColWidths";
import type { TabProps } from "./CommitCard";
import { RoundedCurrency } from "lib/credits";

export const TabSegments: React.FC<TabProps> = ({ commit, asyncUsage }) => {
  const now = useNow();

  return (
    <InPanelTable
      data={commit.access_schedule.schedule_items}
      theadClassName="bg-deprecated-gray-lightest"
      columns={[
        {
          id: "name",
          header: "Segment",
          render: (row) => (
            <>
              {printDate(toDayjs(row.date))}
              {isInRange(now, toDayjs(row.date), toDayjs(row.end_date)) ? (
                <Badge theme="primary" type="light" className="ml-8">
                  ACTIVE
                </Badge>
              ) : null}
            </>
          ),
        },
        {
          id: "start",
          header: "Effective start",
          align: "right",
          cellClassName: ColWidths.DATE,
          render: (row) => printDate(toDayjs(row.date)),
        },
        {
          id: "end",
          header: "Effective end",
          align: "right",
          cellClassName: ColWidths.DATE,
          render: (row) => printDate(toDayjs(row.end_date)),
        },
        {
          id: "amt",
          header: "Amount committed",
          align: "right",
          cellClassName: ColWidths.CREDITS,
          render: (row) => (
            <RoundedCurrency
              amount={new Decimal(row.amount)}
              creditType={commit.access_schedule.credit_type}
            />
          ),
        },
        {
          id: "consumed",
          header: "Amount consumed",
          align: "right",
          cellClassName: ColWidths.CREDITS,
          render: (row) => {
            if (!asyncUsage) {
              return null;
            }

            if (asyncUsage.loading) {
              return <TextSkeleton />;
            }

            if (asyncUsage.error) {
              return (
                <TinyErrorState
                  message={`Unable to load usage: ${getUserFacingErrorMessage(
                    asyncUsage.error,
                  )}`}
                />
              );
            }

            const { used } = asyncUsage.forCommitAccessSegment(
              commit.id,
              row.id,
            );

            return (
              <RoundedCurrency
                amount={used}
                creditType={commit.access_schedule.credit_type}
              />
            );
          },
        },
      ]}
    />
  );
};
