import React from "react";

import { PageContainer } from "components/PageContainer";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useHasAccessToAlerts } from "lib/alerts";
import { NotFoundPage } from "pages/404";
import { AlertsTabContent } from "pages/Customer/tabs/Alerts";

import { useCustomerFromRoute } from "../../lib/Customer";
import { Breadcrumbs } from "lib/breadcrumbs";
import { CustomerLayout } from "../CustomerLayout";

export const CustomerAlerts: React.FC = () => {
  const req = useCustomerFromRoute();
  const alertsEnabled = useHasAccessToAlerts();

  if (req.state === "error") {
    return (
      <PageContainer title="Failed to load customer" hideHeader>
        <ErrorEmptyState
          title="We ran into an error loading this customer"
          error={req.error}
        />
      </PageContainer>
    );
  }

  if (req.state === "not found" || !alertsEnabled) {
    return <NotFoundPage />;
  }

  return (
    <CustomerLayout
      rootReq={req}
      breadcrumbs={({ customer }) =>
        Breadcrumbs.from(
          {
            type: "back",
            label: "Back to customer list",
            routePath: "/customers",
          },
          {
            label: customer.name,
            routePath: `/customers/${customer.id}`,
          },
        )
      }
      content={({ customer }) => (
        <div className="flex h-full flex-col pt-12">
          <AlertsTabContent customerId={customer.id} />
        </div>
      )}
    />
  );
};
