import React from "react";
import "style/index.css";
import { Card } from "tenaissance/components/Card";
import { useClientCustomerGrowthQuery } from "./queries.graphql";
import { Button } from "tenaissance/components/Button";
import Decimal from "decimal.js";
import { dayjs } from "lib/dayjs";

export const CustomerGrowthCard: React.FC = () => {
  const { data, loading } = useClientCustomerGrowthQuery();
  const endingBeforeDate =
    data?.client_new_customers?.ending_before ??
    // default to the last day of last month if no data is available
    dayjs.utc().subtract(1, "month").endOf("month");
  const endDate = dayjs.utc(endingBeforeDate).subtract(1, "second");
  const month = endDate.format("MMMM");

  // Function to generate an array of the last 30 days
  const generateLast30Days = () => {
    const days = [];
    for (let i = 29; i >= 0; i--) {
      days.push(endDate.subtract(i, "day").startOf("day"));
    }
    return days;
  };

  // Create an array of existing data points with dayjs dates
  const existingData =
    data?.client_new_customers?.by_day.map((d) => ({
      date: dayjs(d.date).startOf("day"),
      count: Number(d.count),
    })) ?? [];

  // Generate zero-padded data for the last 30 days
  const zeroPaddedData = generateLast30Days().map((date) => {
    const matchingData = existingData.find((d) => d.date.isSame(date, "day"));
    return {
      date: date.toDate(),
      value: matchingData ? matchingData.count : 0,
    };
  });

  return (
    <Card
      loading={loading}
      title="Customer growth"
      headerActions={[
        <Button
          text="Customers"
          leadingIcon="arrowNarrowUpRight"
          linkTo="/customers"
          theme="secondary"
        />,
      ]}
      metrics={[
        {
          label: "Total",
          value: new Decimal(data?.Client[0].customer_count.count ?? 0),
        },
        {
          label: `Last month (${month})`,
          value: new Decimal(data?.client_new_customers?.total ?? 0),
        },
      ]}
      children={
        <Card.Chart
          chartData={[
            {
              name: "Customers Added",
              data: zeroPaddedData,
              yAxisUnit: "Customers",
            },
          ]}
          type="area"
          yAxisLabel="Customers"
          xAxisLabel={endDate.format("MMMM YYYY")}
          legendPostion="top"
        />
      }
    />
  );
};
