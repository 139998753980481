import React from "react";
import { DraftPlan } from "lib/plans/types";
import { WizardSection } from "components/Wizard";
import {
  CreatePlanDataQuery,
  FetchCustomerPlansQuery,
} from "../../data/queries.graphql";
import {
  AddCustomerToPlan,
  isAddCustomerToPlanDone,
  MAX_ALLOWED_CUSTOMERS_FOR_ADD_PLAN_TO_CUSTOMER,
} from "./components/AddCustomerToPlan";
import {
  isNameAndDescriptionDone,
  SetNameAndDescriptionPage,
} from "./components/SetNameAndDescription";
import {
  isDefinePricingRampsDone,
  PricingRampPage,
} from "./components/SetPricingRamps";
import { BasicInfoPage, isBasicInfoDone } from "./components/SetBasicInfo";
import {
  BILLING_SCHEDULE_STEP_NAVIGATION_KEY,
  isBillingScheduleDone,
  SetBillingSchedulePage,
} from "./components/SetBillingSchedule";

export const planTermsSection: (
  draftPlan: DraftPlan,
  data: CreatePlanDataQuery,
  hasCustomers: boolean,
  editing: boolean,
  existingPlans?: FetchCustomerPlansQuery,
  hasCustomPricing?: boolean,
) => WizardSection = (
  draftPlan,
  data,
  hasCustomers,
  editing,
  existingPlans,
  hasCustomPricing,
) => ({
  title: "Set plan terms",
  isDone:
    isNameAndDescriptionDone(draftPlan) &&
    isBasicInfoDone(draftPlan) &&
    (editing || isAddCustomerToPlanDone(draftPlan)) &&
    isBillingScheduleDone(draftPlan) &&
    isDefinePricingRampsDone(draftPlan),
  icon: "documents",
  subStepGroups: [
    {
      isDone:
        isNameAndDescriptionDone(draftPlan) &&
        isBasicInfoDone(draftPlan) &&
        (editing || isAddCustomerToPlanDone(draftPlan)) &&
        isBillingScheduleDone(draftPlan) &&
        isDefinePricingRampsDone(draftPlan),
      subSteps: [
        {
          header: "Your plan name and description",
          title: "Name and description",
          component: <SetNameAndDescriptionPage />,
          isDone: isNameAndDescriptionDone(draftPlan),
        },
        {
          header: "Tell us about your plan",
          title: "Basic info",
          component: (
            <BasicInfoPage
              editing={editing}
              hasCustomers={hasCustomers}
              hasCustomPricing={hasCustomPricing}
            />
          ),
          isDone: isBasicInfoDone(draftPlan),
        },
        ...(editing ||
        data.Customer.length === MAX_ALLOWED_CUSTOMERS_FOR_ADD_PLAN_TO_CUSTOMER
          ? []
          : [
              {
                header: "Add a customer to this plan",
                title: "Add a customer",
                component: <AddCustomerToPlan customers={data.Customer} />,
                isDone: isAddCustomerToPlanDone(draftPlan),
              },
            ]),
        {
          header: "Plan terms",
          title: "Plan and billing terms",
          component: (
            <SetBillingSchedulePage
              existingCustomerPlans={existingPlans}
              creditTypes={data.CreditType}
              disabledForEditing={editing && hasCustomers}
            />
          ),
          isDone: isBillingScheduleDone(draftPlan),
          navigationKey: BILLING_SCHEDULE_STEP_NAVIGATION_KEY,
        },
        ...(draftPlan.hasPriceRamps && !hasCustomPricing
          ? [
              {
                header: "Plan terms",
                title: "Pricing ramps",
                component: <PricingRampPage />,
                isDone: isDefinePricingRampsDone(draftPlan),
              },
            ]
          : []),
      ],
    },
  ],
});
