import { Button } from "tenaissance/components/Button";
import Papa from "papaparse";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import React, { useState } from "react";
import { reportToSentry } from "lib/errors/sentry";
import {
  formatRate,
  getRateCreditTypeId,
  stringToCsvDownload,
} from "pages/Contracts/lib/ContractPricing/RatesDownload";
import { Dayjs, dayjs } from "lib/dayjs";
import { RateScheduleEntitledFilter } from "types/generated-graphql/__types__";
import {
  GetAllRateOnEffectiveDateQuery,
  useGetAllRateOnEffectiveDateLazyQuery,
} from "pages/Contracts/Pricing/RateCardsDetails/data.graphql";
import { Modal } from "tenaissance/components/Modal";
import { Checkbox } from "tenaissance/components/Checkbox";
import { DatePicker } from "tenaissance/components/DatePicker";

function asCsv(dataPages: GetAllRateOnEffectiveDateQuery[], at: Dayjs) {
  const productsById = new Map(
    dataPages
      .map(
        (data) =>
          data.contract_pricing.rate_card.rate_schedule.products_on_segments,
      )
      .flat()
      .map((product) => [product.id, product]),
  );

  const getProduct = (id: string) => {
    const product = productsById.get(id);
    if (!product) {
      throw new Error(`Product with id ${id} not found`);
    }
    return product;
  };

  const rows = dataPages
    .map(
      (data) => data.contract_pricing.rate_card.rate_schedule.scalar_segments,
    )
    .flat()
    .map(
      ({
        starting_at,
        ending_before,
        entitled,
        pricing_groups,
        product_id,
        rate,
        commit_rate,
      }) => {
        const product = getProduct(product_id);
        const product_name = ProductListItem.getName(product, at);
        const product_tags = ProductListItem.getTags(product, at);
        const formattedRate = formatRate(rate);
        const formattedCommitRate = commit_rate
          ? formatRate(commit_rate)
          : null;
        const formattedManagedFields = product.managed_fields.length
          ? Object.fromEntries(
              product.managed_fields.map(({ ManagedFieldKey, value }) => [
                ManagedFieldKey.key,
                value,
              ]),
            )
          : {};
        const row = {
          starting_at,
          ending_before,
          entitled,
          pricing_group_values: JSON.stringify(pricing_groups) ?? "{}",
          product_id: product.id,
          product_name_current: product_name,
          product_tags_current: JSON.stringify(product_tags),
          custom_fields_current: JSON.stringify(formattedManagedFields),
          credit_type_id: getRateCreditTypeId(rate),
          rate: JSON.stringify(formattedRate),
          commit_rate: JSON.stringify(formattedCommitRate),
        };
        return row;
      },
    );
  const csvContent = Papa.unparse(rows);
  return csvContent;
}

interface RateCardDownloadModalProps {
  rateCardId: string;
  at: Dayjs;
  onStartDownload: () => void;
  onComplete: () => void;
  onError?: () => void;
  onCancel: () => void;
}

export const RateCardDownloadModal: React.FC<RateCardDownloadModalProps> = (
  props,
) => {
  const [ratesRequest, ratesResult] = useGetAllRateOnEffectiveDateLazyQuery();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    dayjs.utc(props.at).startOf("day"),
  );
  const [filterForEntitled, setFilterForEntitled] = useState<boolean>(true);

  const loading = ratesResult.loading;
  async function onSubmit() {
    props.onStartDownload();
    let next_page: string | null = null;
    const dataPages = [];
    const at = selectedDate.toISOString();
    do {
      const result = await ratesRequest({
        variables: {
          rateCardId: props.rateCardId,
          limit: "10000",
          cursor: next_page,
          at,
          entitled_filter: filterForEntitled
            ? RateScheduleEntitledFilter.Entitled
            : RateScheduleEntitledFilter.All,
        },
      });
      if (result.error) {
        reportToSentry(ratesResult.error);
        if (props.onError) {
          props.onError();
        }
      }
      if (!result.data) {
        return;
      }
      dataPages.push(result.data);
      next_page =
        result.data.contract_pricing.rate_card.rate_schedule.next_page;
    } while (next_page);
    const csvContent = asCsv(dataPages, props.at);
    stringToCsvDownload(
      csvContent,
      `rates-for-card-${props.rateCardId}-on-${at}.csv`,
    );
    props.onComplete();
  }

  return (
    <Modal
      title="Download Rates CSV"
      supportingText="Download rates at UTC midnight on the selected date."
      isOpen={true}
      onClose={props.onCancel}
      buttonLayout="alignedRight"
      showOverflow
      modalButtons={[
        <Button
          key="download"
          onClick={onSubmit}
          text="Download"
          disabled={loading}
          loading={loading}
        />,
        <Button
          key="cancel"
          onClick={props.onCancel}
          text="Cancel"
          theme="secondary"
        />,
      ]}
    >
      <div className="flex flex-col gap-sm">
        <div className="mb-lg">
          <DatePicker
            className="z-toast"
            text="Effective Date"
            value={selectedDate.toDate()}
            onDateApply={(v) => {
              setSelectedDate(dayjs.utc(v).startOf("day"));
            }}
          />
        </div>
        <Checkbox
          checked={filterForEntitled}
          onChange={() => setFilterForEntitled(!filterForEntitled)}
          label="Only include entitled rates"
        />
      </div>
    </Modal>
  );
};
