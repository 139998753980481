import React from "react";
import "style/index.css";
import { useEventsQuery } from "./queries.graphql";
import { Button } from "tenaissance/components/Button";
import { dayjs } from "lib/dayjs";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { EventGraphCard } from "tenaissance/components/EventGraphCard";

export const OverviewEventGraphCard: React.FC = () => {
  const { environmentType } = useEnvironment();
  const today = dayjs().utc().endOf("day");
  const { data, loading } = useEventsQuery({
    variables: {
      environment_type: environmentType,
      starting_after: today.subtract(30, "days").format(),
      ending_before: today.format(),
    },
  });

  return (
    <EventGraphCard
      data={data}
      loading={loading}
      headerActions={[
        <Button
          text="Events"
          leadingIcon="arrowNarrowUpRight"
          linkTo="/connections/events"
          theme="secondary"
        />,
      ]}
      eventsToShow="all"
    />
  );
};
