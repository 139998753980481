function isNum(value: unknown): value is number {
  return typeof value === "number" && Number.isFinite(value);
}

interface SortablePricingFactor {
  name: string;
  ordering: number | null;
}

export function sortPricingFactors(
  a: SortablePricingFactor,
  b: SortablePricingFactor,
) {
  return isNum(a.ordering) && isNum(b.ordering)
    ? a.ordering - b.ordering
    : !isNum(a.ordering) && !isNum(b.ordering)
      ? a.name.localeCompare(b.name)
      : isNum(a.ordering)
        ? -1
        : 1;
}
