import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { queryStringToFilter } from "pages/Events/lib/query";
import { EventFilters } from "pages/Events/components/EventFilters";
import { EventsTable, EventsTableRef } from "./EventsTable";
import { EventsGraph, EventsGraphRef } from "./EventsGraph";
import { DateRange } from "components/RelativeDateRangeSelector";
import { useCustomerByIdQuery, EventDetailsDocument } from "./queries.graphql";
import { AuthCheck } from "components/AuthCheck";
import { EventDetail } from "pages/Events/components/EventDetail";

const Events: React.FC = () => {
  const location = useLocation();
  const tableRef = React.useRef<EventsTableRef>(null);
  const graphRef = React.useRef<EventsGraphRef>(null);

  const filters = queryStringToFilter(location.search);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);

  const selectedCustomerResults = useCustomerByIdQuery({
    skip: !filters.customerID,
    variables: {
      id: filters.customerID ?? "",
    },
  });

  const filteredIngestAliases = filters.customerID
    ? selectedCustomerResults.data?.Customer_by_pk?.CustomerIngestAliases.map(
        (alias) => alias.ingest_alias,
      ).concat([filters.customerID])
    : filters.ingestAliases;

  return (
    <AuthCheck authDoc={EventDetailsDocument}>
      <EventFilters
        graphRef={graphRef}
        tableRef={tableRef}
        filters={filters}
        selectedCustomerResults={selectedCustomerResults}
        setDateRange={setDateRange}
      />
      <div className="flex flex-col gap-xl">
        <EventsGraph
          ref={graphRef}
          startingAfter={dateRange?.inclusiveStart ?? new Date()}
          endingBefore={dateRange?.exclusiveEnd ?? new Date()}
          ingestAliases={filteredIngestAliases}
          duplicates={filters.duplicates}
          billableMetricIDs={filters.billableMetrics}
          transaction_ids={filters.transactionIDs}
        />
        {filters.transactionIDs ? (
          <EventDetail
            eventID={filters.eventID}
            transactionIDs={filters.transactionIDs}
            startingAfter={dateRange?.inclusiveStart ?? new Date()}
            endingBefore={dateRange?.exclusiveEnd ?? new Date()}
            ingest_aliases={filteredIngestAliases}
            billableMetricIDs={filters.billableMetrics}
            duplicates={filters.duplicates}
          />
        ) : (
          <EventsTable
            startingAfter={dateRange?.inclusiveStart ?? new Date()}
            endingBefore={dateRange?.exclusiveEnd ?? new Date()}
            ingestAliases={filteredIngestAliases}
            billableMetricIDs={filters.billableMetrics}
            duplicates={filters.duplicates}
          />
        )}
      </div>
    </AuthCheck>
  );
};

export default Events;
