import React from "react";

import { AlertRulesDocument, useAlertRulesQuery } from "./queries.graphql";

import { Input } from "design-system";
import { AlertsTable } from "components/AlertsTable";
import { EmptyState } from "tenaissance/components/EmptyState";
import { PageContainer } from "components/PageContainer";
import { SearchTooltip } from "components/SearchTooltip";
import NotFoundPage from "../404";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useHasAccessToAlerts } from "../../lib/alerts";
import { useSearchParam } from "lib/routes/useSearchParam";
import useDebounce from "lib/debounce";
import { CreateAlertDocument } from "pages/NewAlert/queries.graphql";
import { GatedButton } from "../../components/GatedButton";

const Alerts: React.FC = () => {
  const { environmentType } = useEnvironment();
  const alertsEnabled = useHasAccessToAlerts();

  const [searchQuery, setSearchQuery] = useSearchParam("q");
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 400);
  const { data, loading } = useAlertRulesQuery({
    variables: {
      environment_type: environmentType,
      search: debouncedSearchQuery,
    },
  });

  if (alertsEnabled === undefined) {
    return null;
  }
  if (!alertsEnabled) {
    return <NotFoundPage />;
  }

  const headerActions = (
    <div className="flex items-center">
      <SearchTooltip searchText="alerts">
        <Input
          type="search"
          placeholder="Search"
          value={searchQuery}
          onChange={setSearchQuery}
          leftIcon="search"
          className="mr-12 w-[208px]"
        />
      </SearchTooltip>
      <GatedButton
        doc={CreateAlertDocument}
        linkTo="/alerts/new"
        text="Create alert"
        theme="primary"
        leadingIcon="plus"
        size="sm"
      />
    </div>
  );

  return (
    <PageContainer
      title="Alerts overview"
      action={headerActions}
      authDoc={AlertRulesDocument}
    >
      {(data?.alerts.length ?? 0) === 0 && !loading ? (
        debouncedSearchQuery ? (
          <EmptyState
            icon="bell03"
            mainText="No matching alerts found."
            supportingText="Try a different search term."
          />
        ) : (
          <EmptyState
            icon="bell03"
            mainText="You haven't created any alerts yet."
            supportingText="Create your first alert."
            actions={[
              <GatedButton
                doc={CreateAlertDocument}
                linkTo="/alerts/new"
                text="Create alert"
                theme="primary"
                leadingIcon="plus"
              />,
            ]}
          />
        )
      ) : (
        <AlertsTable
          alerts={data?.alerts ?? []}
          loading={loading}
          singleCustomer={false}
        />
      )}
    </PageContainer>
  );
};

export default Alerts;
