/* 32682355c7b5cf600db974fd6547b7aba410e751
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerInfoQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type CustomerInfoQuery = { __typename?: 'Query', Customer: { __typename?: 'Customer', id: string, name: string, archived_at: string | null, BillingProviderCustomers: Array<{ __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string, created_at: string }> } | null };


export const CustomerInfoDocument = gql`
    query CustomerInfo($customer_id: uuid!) {
  Customer: Customer_by_pk(id: $customer_id) {
    id
    name
    archived_at
    BillingProviderCustomers(where: {billing_provider: {_eq: STRIPE}}) {
      billing_provider_customer_id
      created_at
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCustomerInfoQuery__
 *
 * To run a query within a React component, call `useCustomerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerInfoQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useCustomerInfoQuery(baseOptions: Apollo.QueryHookOptions<CustomerInfoQuery, CustomerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerInfoQuery, CustomerInfoQueryVariables>(CustomerInfoDocument, options);
      }
export function useCustomerInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerInfoQuery, CustomerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerInfoQuery, CustomerInfoQueryVariables>(CustomerInfoDocument, options);
        }
export type CustomerInfoQueryHookResult = ReturnType<typeof useCustomerInfoQuery>;
export type CustomerInfoLazyQueryHookResult = ReturnType<typeof useCustomerInfoLazyQuery>;
export type CustomerInfoQueryResult = Apollo.QueryResult<CustomerInfoQuery, CustomerInfoQueryVariables>;