import Decimal from "decimal.js";
import {
  CommitInput,
  CommitInvoiceScheduleInput,
  CommitType,
  ResellerRoyaltyInput,
  ResellerRoyaltyOrUpdateInput,
  ResellerType,
  ScheduledChargeInput,
  DiscountInput,
  ScheduledChargeScheduleInput,
  ScheduledChargeScheduleItemInput,
  ExternalCommitType,
  ProServiceInput,
  OverrideSpecifierInput,
} from "types/generated-graphql/__types__";

import {
  CreateContractMutationVariables,
  AmendContractMutationVariables,
} from "./data.graphql";
import { Schema } from "./Schema";
import { convertToGraphqlTiers } from "pages/Contracts/Pricing/CreateAndEditRateCard/RateCardCreate";
import { USD_CREDIT_ID } from "lib/credits";

export function formatBillingSchedule(
  billingSchedule: Schema.Types.BillingSchedule,
  creditTypeId: string,
): ScheduledChargeScheduleInput & CommitInvoiceScheduleInput {
  switch (billingSchedule.type) {
    case "fixed":
      return {
        schedule_items: billingSchedule.items.map(
          (item): ScheduledChargeScheduleItemInput => ({
            date: item.date,
            quantity: new Decimal(item.quantity).toString(),
            unit_price: new Decimal(item.unitPrice).toString(),
          }),
        ),
        credit_type_id: creditTypeId,
      };
    case "recurring":
      return {
        recurring_schedule: {
          frequency: billingSchedule.frequency,
          end_date: billingSchedule.endDate,
          start_date: billingSchedule.startDate,
          amount_distribution: billingSchedule.amountDistribution,
          amount: new Decimal(billingSchedule.quantity)
            .mul(new Decimal(billingSchedule.unitPrice))
            .toString(),
          quantity: billingSchedule.quantity.toString(),
          unit_price: billingSchedule.unitPrice.toString(),
        },
        credit_type_id: creditTypeId,
      };
  }
}

const formatCommitOrCredit = (
  { commit, ...base }: Schema.Types.CommitFlyoverRoot,
  externalType: "commit" | "credit",
): CommitInput => ({
  name: base.name,
  netsuite_sales_order_id: base.netsuiteSalesOrderId,
  product_id: base.productId,
  rollover_fraction: commit.rolloverFraction
    ? new Decimal(commit.rolloverFraction).div(100).toString()
    : undefined,
  type: base.type,
  external_type:
    base.type !== CommitType.Prepaid
      ? undefined
      : {
          commit: ExternalCommitType.Commit,
          credit: ExternalCommitType.Credit,
        }[externalType],
  rate_type: base.rateType,
  applicable_product_ids: base.applicableProductIds,
  applicable_tags: base.applicableProductTags,
  description: base.description,
  priority: new Decimal(commit.priority).toString(),
  access_schedule: {
    schedule_items: commit.accessSchedule.map((item) => ({
      amount: new Decimal(item.amount).toString(),
      date: item.date,
      end_date: item.endDate,
    })),
    credit_type_id: commit.accessScheduleCreditTypeId,
  },
  invoice_schedule:
    commit.type === CommitType.Prepaid
      ? formatBillingSchedule(
          commit.billingSchedule,
          (externalType === "credit"
            ? USD_CREDIT_ID
            : commit.billingScheduleCreditTypeId) ?? USD_CREDIT_ID,
        )
      : formatBillingSchedule(
          {
            type: "fixed",
            items: commit.billingSchedule,
          },
          commit.accessScheduleCreditTypeId,
        ),
  temporary_id: base.temporaryId,
});

type SharedVarNames =
  | "overrides"
  | "discounts"
  | "commits"
  | "salesforce_opportunity_id"
  | "netsuite_sales_order_id"
  | "scheduled_charges"
  | "reseller_royalties"
  | "pro_services";

function formatRate(
  rate:
    | Schema.Types.OverwriteOverride
    | Schema.Types.MultiplierOverride
    | undefined,
) {
  switch (rate?.type) {
    case "multiplier":
      return {
        multiplier: new Decimal(rate.multiplier).toString(),
        priority: rate.priority ? new Decimal(rate.priority).toString() : null,
      };
    case "overwrite":
      const newRate = rate.newRate;
      switch (newRate.type) {
        case "flat":
          return {
            new_rate: {
              type: "FLAT",
              flat_rate: {
                unit_price: new Decimal(newRate.unitPrice).toString(),
                credit_type_id: newRate.creditType.id,
              },
            },
          };
        case "subscription":
          return {
            new_rate: {
              type: "SUBSCRIPTION",
              subscription_rate: {
                unit_price: new Decimal(newRate.unitPrice).toString(),
                quantity: new Decimal(newRate.quantity).toString(),
                is_prorated: newRate.isProrated,
                credit_type_id: newRate.creditType.id,
              },
            },
          };
        case "tiered":
          return {
            new_rate: {
              type: "TIERED",
              tiered_rate: {
                tiers: convertToGraphqlTiers(newRate.tiers),
                credit_type_id: newRate.creditType.id,
              },
            },
          };
        case "percentage":
          return {
            new_rate: {
              type: "PERCENTAGE",
              percentage_rate: {
                use_list_prices: false,
                fraction: new Decimal(newRate.fraction).div(100).toString(),
              },
            },
          };
      }
  }
}

function formatOverrideSpecifiers(
  o: NonNullable<Schema.Types.CreateSharedInput["overrides"]>[number],
): OverrideSpecifierInput[] | undefined {
  const overrideSpecifiers: OverrideSpecifierInput[] = [];

  if (o.productId) {
    overrideSpecifiers.push({
      product_id: o.productId,
      commit_ids: o.commitIds,
    });
  }

  // Create new override specifiers for each product tag to make sure they are OR'd.
  if (o.tags) {
    for (const tag of o.tags) {
      overrideSpecifiers.push({
        product_tags: [tag],
        commit_ids: o.commitIds,
      });
    }
  }

  return overrideSpecifiers.length > 0 ? overrideSpecifiers : undefined;
}

function getSharedVars(
  input: Schema.Types.CreateSharedInput,
): Pick<AmendContractMutationVariables, SharedVarNames> &
  Pick<
    CreateContractMutationVariables,
    SharedVarNames | "billing_provider_configuration"
  > {
  return {
    salesforce_opportunity_id: input.salesforceOpportunityId,
    netsuite_sales_order_id: input.netsuiteSalesOrderId,
    scheduled_charges: input.scheduledCharges?.map(
      (sc): ScheduledChargeInput => {
        const { productId, billingSchedule } = sc;
        return {
          product_id: productId,
          schedule: formatBillingSchedule(
            billingSchedule,
            sc.billingScheduleCreditTypeId,
          ),
        };
      },
    ),
    discounts: input.discounts?.map((d): DiscountInput => {
      const { productId, billingSchedule } = d;
      return {
        product_id: productId,
        schedule: formatBillingSchedule(
          billingSchedule,
          d.billingScheduleCreditTypeId,
        ),
      };
    }),
    pro_services: (input.proServices ?? []).map((ps): ProServiceInput => {
      const {
        description,
        maxAmount,
        netSuiteSalesOrderId,
        productId,
        quantity,
        unitPrice,
      } = ps;
      return {
        description,
        max_amount: new Decimal(maxAmount).toString(),
        netsuite_sales_order_id: netSuiteSalesOrderId,
        product_id: productId,
        quantity: new Decimal(quantity).toString(),
        unit_price: new Decimal(unitPrice).toString(),
      };
    }),
    commits: [
      ...(input.commits ?? []).map((c) => formatCommitOrCredit(c, "commit")),
      ...(input.credits ?? []).map((c) => formatCommitOrCredit(c, "credit")),
    ],
    overrides:
      input.overrides?.map((o) => ({
        ending_before: o.endingBefore ?? null,
        entitled: Schema.parseEntitled(o.entitled),
        starting_at: o.startingAt,
        rate: formatRate(o.rate),
        override_target: o.target,
        type: o.type,
        override_specifiers: formatOverrideSpecifiers(o),
      })) || [],
    reseller_royalties: input.resellerRoyalties?.map(
      (rr): ResellerRoyaltyInput & ResellerRoyaltyOrUpdateInput => {
        let metadata;
        switch (rr.metadata?.type) {
          case "aws":
            metadata = {
              aws_account_number: rr.metadata.awsAccountNumber,
              aws_payer_reference_id: rr.metadata.awsPayerReferenceId,
              aws_offer_id: rr.metadata.awsOfferId,
            };
            break;
          case "gcp":
            metadata = {
              gcp_account_id: rr.metadata.gcpAccountId,
              gcp_offer_id: rr.metadata.gcpOfferId,
            };
            break;
          default:
            metadata = undefined;
        }

        const resellerMap = {
          aws: ResellerType.Aws,
          awsProService: ResellerType.AwsProService,
          gcp: ResellerType.Gcp,
          gcpProService: ResellerType.GcpProService,
        };

        return {
          applicable_product_ids: rr.applicableProductIds,
          applicable_product_tags: rr.applicableProductTags,
          fraction: new Decimal(rr.percentage).div(100).toString(),
          starting_at: rr.startingAt,
          ending_before: rr.endingBefore,
          netsuite_reseller_id: rr.netSuiteResellerId,
          type: resellerMap[rr.type],
          ...metadata,
        };
      },
    ),
  };
}

export function getAmendMutationVars(
  customerId: string,
  contractId: string,
  input: Schema.Types.CreateAmendmentInput,
): AmendContractMutationVariables {
  return {
    customer_id: customerId,
    contract_id: contractId,
    effective_at: input.effectiveAt,
    ...getSharedVars(input),
  };
}

export function getCreateMutationVars(
  customerId: string,
  input: Schema.Types.CreateContractInput,
): CreateContractMutationVariables {
  return {
    customer_id: customerId,
    rate_card_id: input.rateCardId,
    name: input.name,
    starting_at: input.startingAt,
    ending_before: input.endingBefore,
    net_payment_terms_days: input.netPaymentTermsDays,
    usage_filter:
      input.usageFilterGroupKey && input.usageFilterGroupValues
        ? {
            new_filter: {
              group_key: input.usageFilterGroupKey,
              group_values: input.usageFilterGroupValues
                .split(",")
                .map((gv) => gv.trim()),
            },
            effective_at: input.startingAt,
          }
        : undefined,
    transition:
      input.transitionType && input.transitionPreviousContractId
        ? {
            type: input.transitionType,
            from_contract_id: input.transitionPreviousContractId,
          }
        : undefined,
    usage_invoice_frequency: input.usageInvoiceFrequency,
    billing_cycle_anchor: input.billingCycleAnchor,
    billing_cycle_anchor_date: input.billingCycleAnchorDate,
    multiplier_override_prioritization: input.multiplierOverridePrioritization,
    billing_provider_configuration:
      input?.billingProviderConfiguration || input?.billingProvider
        ? {
            billing_provider_configuration_id:
              input.billingProviderConfiguration
                ?.billing_provider_configuration_id,
            billing_provider:
              input?.billingProviderConfiguration?.billing_provider &&
              input?.billingProvider,
          }
        : null,
    ...getSharedVars(input),
  };
}
