import React, { useMemo } from "react";
import { SectionHeader } from "tenaissance/components/SectionHeader";
import { Column, Table } from "tenaissance/components/Table";
import { ButtonGroup } from "tenaissance/components/ButtonGroup";
import { Checkbox } from "tenaissance/components/Checkbox";
import { Badge } from "tenaissance/components/Badge";
import { Timestamp } from "tenaissance/components/Timestamp";
import { Toggle } from "tenaissance/components/Toggle";
import { ProductContext, UsageProductType } from "./ProductContext";
import { NetSuiteFields } from "./NetSuiteFields";

export const CompositeProductFields: React.FC = () => {
  const {
    excludeFreeUsageEnabled,
    usageProducts,
    productsLoading,
    tagsFromDb,
    compositeProductFields,
    setCompositeProductFields,
  } = ProductContext.useContainer();
  const [isProduct, setIsProduct] = React.useState(true);

  const usageProductColumns: Column<UsageProductType>[] = useMemo(
    () => [
      {
        id: "1",
        header: "Name",
        cell: ({ row }) => {
          const { id, name } = row.original;
          return (
            <Checkbox
              label={name}
              checked={compositeProductFields.compositeProductIds?.includes(id)}
              onClick={() => {
                const ids = compositeProductFields.compositeProductIds || [];
                if (ids.includes(id)) {
                  setCompositeProductFields({
                    ...compositeProductFields,
                    compositeProductIds: ids.filter((i) => i !== id),
                  });
                } else {
                  setCompositeProductFields({
                    ...compositeProductFields,
                    compositeProductIds: ids.concat([id]),
                  });
                }
              }}
            />
          );
        },
        accessorKey: "name",
        enableSorting: false,
      },
      {
        id: "2",
        accessorKey: "type.typeName",
        cell: ({ row }) => {
          const typeName = row.original.type?.typeName;
          const getBadgeLabel = () => {
            // Composite's can only have subscription and usage products as sub-products
            switch (typeName) {
              case "SubscriptionProductListItem":
                return "Subscription";
              case "UsageProductListItem":
                return "Usage";
              default:
                return "";
            }
          };

          const badgeLabel = getBadgeLabel();

          const getBadgeTheme = (badgeLabel: string) => {
            switch (badgeLabel) {
              case "Subscription":
                return "indigo";
              case "Usage":
                return "gray";
              default:
                return undefined;
            }
          };

          const badgeTheme = getBadgeTheme(badgeLabel);

          return <Badge label={badgeLabel} theme={badgeTheme} />;
        },
        header: "Type",
        enableSorting: false,
      },
      {
        id: "3",
        accessorKey: "lastEdited",
        cell: (props: { getValue: () => Date }) => {
          // toDo: [PENG-893] Add date rendering logic
          if (props.getValue()) {
            return <Timestamp dateTime={props.getValue()} />;
          } else {
            return null;
          }
        },
        header: () => {
          return <div className="flex">Last edited (UTC)</div>;
        },
        enableSorting: false,
      },
    ],
    [usageProducts, compositeProductFields],
  );

  const tagColumns: Column<{ id: string }>[] = useMemo(
    () => [
      {
        id: "name",
        header: "Name",
        cell: (props: { getValue: () => string }) => {
          const tag = props.getValue();
          return (
            <Checkbox
              label={tag}
              checked={compositeProductFields.compositeTags?.includes(tag)}
              onClick={() => {
                const tags = compositeProductFields.compositeTags || [];
                if (tags.includes(tag)) {
                  setCompositeProductFields({
                    ...compositeProductFields,
                    compositeTags: tags.filter((i) => i !== tag),
                  });
                } else {
                  setCompositeProductFields({
                    ...compositeProductFields,
                    compositeTags: tags.concat([tag]),
                  });
                }
              }}
            />
          );
        },
        accessorKey: "id",
        enableSorting: false,
      },
    ],
    [tagsFromDb, compositeProductFields],
  );
  return (
    <div className="mt-[40px] grid gap-[24px]">
      <SectionHeader
        title="Select the applicable products or tags"
        subtitle="These products define the rate of a composite product. The percent is based on the line item cost of the products selected."
        bottomBorder={false}
      />
      <div className="ml-4 w-full">
        {isProduct ? (
          <Table
            title={
              <ButtonGroup
                buttons={[
                  {
                    text: "Products",
                    onClick: () => setIsProduct(true),
                    isActive: isProduct,
                  },
                  {
                    text: "Tags",
                    onClick: () => setIsProduct(false),
                    isActive: !isProduct,
                  },
                ]}
              />
            }
            columns={usageProductColumns}
            searchOptions={{
              showSearch: true,
            }}
            data={usageProducts}
            loading={productsLoading}
            paginationOptions={{
              type: "clientSide",
              minimumPageSize: 10,
            }}
            autoResetPageIndex={false}
          />
        ) : (
          <Table
            title={
              <ButtonGroup
                buttons={[
                  {
                    text: "Products",
                    onClick: () => setIsProduct(true),
                    isActive: isProduct,
                  },
                  {
                    text: "Tags",
                    onClick: () => setIsProduct(false),
                    isActive: !isProduct,
                  },
                ]}
              />
            }
            columns={tagColumns}
            searchOptions={{
              showSearch: true,
            }}
            data={tagsFromDb.map((tag) => ({ id: tag }))}
            loading={productsLoading}
            paginationOptions={{
              type: "clientSide",
              minimumPageSize: 10,
            }}
            autoResetPageIndex={false}
          />
        )}
      </div>
      <NetSuiteFields />
      {excludeFreeUsageEnabled && (
        <Toggle
          toggled={compositeProductFields.excludeFreeUsage}
          onChange={(meta: { toggled: boolean }) => {
            setCompositeProductFields({
              ...compositeProductFields,
              excludeFreeUsage: meta.toggled,
            });
          }}
          label="Exclude free usage"
        />
      )}
    </div>
  );
};
