import React from "react";
import { Select } from "design-system";

import { FormController } from "lib/FormController";
import { useNow } from "lib/date";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";

import { Schema } from "../../Schema";
import { BillingSchedule } from "../../components/BillingSchedule";

import { AdditionalTermFlyover } from "./AdditionalTermFlyover";
import { useGetFixedProductsQuery } from "./data.graphql";

const useDiscountCtrl = FormController.createHook(Schema.Discount, {
  init(props: Props) {
    return props.edit ?? {};
  },
});

interface Props {
  edit?: Schema.Types.Discount;
  onSave: (override: Schema.Types.Discount) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

export const DiscountFlyover: React.FC<Props> = (props) => {
  const ctrl = useDiscountCtrl(props);
  const now = useNow();
  const fixedProducts = useGetFixedProductsQuery();

  return (
    <AdditionalTermFlyover
      {...props}
      ctrl={ctrl}
      title={props.edit ? "Edit discount" : "Add a discount"}
    >
      <Select
        {...ctrl.props.Select("productId", {
          name: "Product",
          placeholder: "Select a product",
          options:
            fixedProducts.data?.contract_pricing.products.map((p) => ({
              value: p.id,
              label: ProductListItem.getName(p, now),
            })) ?? [],
        })}
      />

      {ctrl.get("productId") && <BillingSchedule ctrl={ctrl} mode="discount" />}
    </AdditionalTermFlyover>
  );
};
