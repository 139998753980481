import React, { useState } from "react";
import { ButtonGroup, ButtonProps } from "tenaissance/components/ButtonGroup";
import { CreditType } from "types/credit-types";
import { Table } from "tenaissance/components/Table";
import { InsertCreditTypeDocument } from "components/PricingUnitSelector/queries.graphql";
import { GatedButton } from "components/GatedButton";
import NewPricingUnitModal from "components/PricingUnitSelector/NewPricingUnitModal";
import { displayCreditTypeName } from "lib/credits";
import { CopyableID } from "tenaissance/components/CopyableID";
import { useSearcher } from "lib/search/useSearcher";
import { EmptyState } from "tenaissance/components/EmptyState";
import { OFFERING_PAGES_TABLE_PAGE_SIZE } from "../../constants";

interface CustomPricingUnitsTableProps {
  loading: boolean;
  titleButtons: ButtonProps[];
  creditTypes: CreditType[];
}

const CustomPricingUnitsTable: React.FC<CustomPricingUnitsTableProps> = ({
  titleButtons,
  creditTypes,
  loading,
}) => {
  const customPricingUnits = creditTypes.filter((ct) => ct.client_id !== null);
  const [showCreatePricingUnitModal, setShowCreatePricingUnitModal] =
    useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const searcher = useSearcher(customPricingUnits, {
    keys: ["name"],
    threshold: 0.1,
    includeScore: true,
  });
  const searchResults = searcher(searchTerm);

  return (
    <>
      {showCreatePricingUnitModal ? (
        <NewPricingUnitModal
          onClose={() => setShowCreatePricingUnitModal(false)}
          // IMPORTANT: Pass *all credit types, including currencies* so we properly validate
          // the user doesn't create a pricing unit with the same name as a currency
          creditTypes={creditTypes}
        />
      ) : null}
      <Table
        title={<ButtonGroup buttons={titleButtons} />}
        loading={loading}
        columns={[
          {
            id: "name",
            header: "Name",
            cell: (props) => props.getValue(),
            accessorFn: (ct) => displayCreditTypeName(ct),
            sortingFn: (rowA, rowB) => {
              return displayCreditTypeName(rowA.original).localeCompare(
                displayCreditTypeName(rowB.original),
              );
            },
          },
          {
            id: "id",
            header: "ID",
            cell: (props) => props.getValue(),
            accessorFn: (ct) => <CopyableID id={ct.id} />,
            sortingFn: (rowA, rowB) => {
              return rowA.id.localeCompare(rowB.id);
            },
          },
        ]}
        data={searchResults}
        searchOptions={{ showSearch: true, onSearch: setSearchTerm }}
        paginationOptions={{
          type: "clientSide",
          pageSize: OFFERING_PAGES_TABLE_PAGE_SIZE,
        }}
        topBarActions={[
          <GatedButton
            onClick={() => setShowCreatePricingUnitModal(true)}
            doc={InsertCreditTypeDocument}
            text="Add"
            theme="primary"
            leadingIcon="plus"
          />,
        ]}
        emptyState={
          searchTerm ? (
            <EmptyState
              icon="searchSm"
              mainText="No custom pricing units match your query"
            />
          ) : (
            <EmptyState
              icon="currencyDollar"
              mainText="There are no custom pricing units"
              supportingText="Custom pricing units allow you to set prices using your own defined units of measurement that are later converted to the invoice's currency (e.g. USD)."
            />
          )
        }
      />
    </>
  );
};

export default CustomPricingUnitsTable;
