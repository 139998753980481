import React from "react";
import {
  GetInvoiceMetadataQuery,
  useGetInvoiceMetadataQuery,
} from "./queries.graphql";
import Decimal from "decimal.js";
import { FlyoverWithMetadataTable } from "components/FlyoverWithMetadataTable";

interface Props {
  onRequestClose: () => void;
  invoiceId: string;
  invoiceName: string;
}

export const InvoiceFlyover: React.FC<Props> = ({
  onRequestClose,
  invoiceId,
  invoiceName,
}) => {
  const { data, loading, error } = useGetInvoiceMetadataQuery({
    variables: {
      id: invoiceId,
    },
  });

  const invoice = data?.invoice;

  const getNetstuiteSalesOrderId = (
    invoice: GetInvoiceMetadataQuery["invoice"],
  ): string => {
    if (!invoice) return "";
    switch (invoice.__typename) {
      case "ContractScheduledInvoice":
      case "ContractRefundInvoice":
        let netsuiteSalesOrderId =
          invoice.amendment?.netsuite_sales_order_id ??
          invoice.contract?.netsuite_sales_order_id ??
          undefined;
        const commitSalesOrders = invoice.line_items.flatMap((li) =>
          li.__typename === "ContractCommitLineItem" &&
          li.commit_union.netsuite_sales_order_id
            ? li.commit_union.netsuite_sales_order_id
            : [],
        );
        if (commitSalesOrders.length > 0) {
          if (new Set(commitSalesOrders).size !== 1) {
            throw new Error(
              `Unhandled: invoice ${invoice.id} has line items with multiple commits with different sales orders`,
            );
          }
          netsuiteSalesOrderId = commitSalesOrders[0];
        }
        return netsuiteSalesOrderId ?? "";
      case "ContractPostpaidTrueupInvoice":
        if (invoice.line_items.length !== 1) {
          throw new Error(
            `ContractPostpaidTrueupInvoice must have exactly one line item, received ${invoice.line_items.length}`,
          );
        }
        if (
          invoice.line_items[0].__typename !== "ContractPostpaidTrueupLineItem"
        ) {
          throw new Error(
            `Expected line item type ContractPostpaidTrueupLineItem, received ${invoice.line_items[0].__typename}`,
          );
        }
        const commitFromLineItem = invoice.line_items[0].postpaid_commit;
        const contractCommit = invoice.contract.commits_union.find(
          (c) =>
            c.__typename === "PostpaidCommit" &&
            c.id === commitFromLineItem.id &&
            c.netsuite_sales_order_id,
        );
        if (contractCommit && contractCommit.netsuite_sales_order_id) {
          return contractCommit.netsuite_sales_order_id;
        }
        if (invoice.contract.netsuite_sales_order_id) {
          return invoice.contract.netsuite_sales_order_id;
        }
        const amendmentCommit = invoice.contract.amendments
          .flatMap((amendment) => amendment.commits_union)
          .find(
            (c) =>
              c.__typename === "PostpaidCommit" &&
              c.id === commitFromLineItem.id &&
              c.netsuite_sales_order_id,
          );
        if (amendmentCommit && amendmentCommit.netsuite_sales_order_id) {
          return amendmentCommit.netsuite_sales_order_id;
        }
        const amendmentWithSO = invoice.contract.amendments
          .filter((a) => a.netsuite_sales_order_id)
          .slice(-1)[0];
        if (amendmentWithSO && amendmentWithSO.netsuite_sales_order_id) {
          return amendmentWithSO.netsuite_sales_order_id;
        }
        return "";

      case "ContractProServiceInvoice":
        const li = invoice.line_items.find(
          (li) => "pro_service" in li && li.pro_service.netsuite_sales_order_id,
        );
        return (
          (li && "pro_service" in li
            ? li.pro_service.netsuite_sales_order_id
            : undefined) ??
          invoice.contract.amendments
            .filter((a) => a.netsuite_sales_order_id)
            .slice(-1)[0].netsuite_sales_order_id ??
          invoice.contract?.netsuite_sales_order_id ??
          ""
        );
      default:
        return "";
    }
  };

  type ResellerRoyaltyData = {
    reseller: string;
    netsuite_reseller_id: string;
    fraction: string;
    aws_account_number?: string | null;
    aws_payer_reference_id?: string | null;
    aws_offer_id?: string | null;
    gcp_account_id?: string | null;
    gcp_offer_id?: string | null;
  };

  const getResellerRoyalty = (
    invoice: GetInvoiceMetadataQuery["invoice"],
  ): ResellerRoyaltyData | null => {
    if (!invoice) return null;
    for (const li of invoice.line_items) {
      if (li.__typename === "ContractAWSRoyaltyLineItem") {
        const {
          netsuite_reseller_id,
          aws_account_number,
          aws_payer_reference_id,
          aws_offer_id,
          fraction,
        } = li.reseller_royalty;
        return {
          reseller: "AWS",
          fraction,
          netsuite_reseller_id,
          aws_account_number,
          aws_payer_reference_id,
          aws_offer_id,
        };
      }
      if (li.__typename === "ContractGCPRoyaltyLineItem") {
        const { netsuite_reseller_id, fraction, gcp_account_id, gcp_offer_id } =
          li.reseller_royalty;
        return {
          reseller: "GCP",
          fraction,
          netsuite_reseller_id,
          gcp_account_id,
          gcp_offer_id,
        };
      }
    }
    return null;
  };

  const mapInvoiceToRows = (): Map<string, string> => {
    if (!invoice) return new Map();

    const resellerRoyalty = getResellerRoyalty(invoice);

    return new Map([
      ["invoice_id", invoice.id],
      ["invoice_type", invoice.external_type ?? ""],
      ["netsuite_sales_order_id", getNetstuiteSalesOrderId(invoice) ?? ""],
      ["reseller", resellerRoyalty?.reseller ?? ""],
      [
        "reseller_royalty_percent",
        resellerRoyalty?.fraction
          ? `${new Decimal(resellerRoyalty.fraction).mul(100).toString()}%`
          : "",
      ],
      ["netsuite_reseller_id", resellerRoyalty?.netsuite_reseller_id ?? ""],
      ["aws_account_id", resellerRoyalty?.aws_account_number ?? ""],
      ["aws_payer_reference_id", resellerRoyalty?.aws_payer_reference_id ?? ""],
      ["aws_offer_id", resellerRoyalty?.aws_offer_id ?? ""],
      ["gcp_account_id", resellerRoyalty?.gcp_account_id ?? ""],
      ["gcp_offer_id", resellerRoyalty?.gcp_offer_id ?? ""],
    ]);
  };

  return (
    <FlyoverWithMetadataTable
      onRequestClose={onRequestClose}
      title={`${invoiceName} Invoice`}
      instanceType="invoice"
      error={error}
      loading={loading}
      metadataRows={mapInvoiceToRows()}
      options={{ removeEmptyValueRows: true }}
    />
  );
};
