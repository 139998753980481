import React, { useState } from "react";

import { Body, Headline, Icon } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { RightPane } from "components/Popup";
import { SelectableCards } from "components/SelectableCards";
import { CompositeCharge, PricedProduct } from "lib/plans/types";
import styles from "./index.module.less";
import {
  ChargeTypeEnum_Enum,
  CompositeChargeTypeEnum_Enum,
} from "types/generated-graphql/__types__";
import { CreatePlanDataQuery } from "../../../../data/queries.graphql";
import { twMerge } from "../../../../../../design-system/twMerge";

export type Charge = {
  id: string;
  name: string;
};

export interface ProductWithPricedProduct {
  productData: CreatePlanDataQuery["products"][0];
  pricedProduct: PricedProduct;
}

interface CompositeChargePaneProps {
  onRequestClose: () => void;
  onRequestSave: (
    pricingFactorId: string,
    compositeCharge: CompositeCharge[],
  ) => void;
  initialCharge: CompositeCharge[];
  pricingFactorId: string;
  creditTypeId: string;
  allProducts: ProductWithPricedProduct[];
  loading?: boolean;
}

export const CompositeChargePane: React.FC<CompositeChargePaneProps> = ({
  onRequestClose,
  onRequestSave,
  initialCharge,
  pricingFactorId,
  creditTypeId,
  allProducts,
  loading,
}) => {
  const [compositeCharge, setCompositeCharge] =
    useState<CompositeCharge[]>(initialCharge);

  const compositeChargeType = compositeCharge[0]?.type;

  const addProductPricingFactor = (charge: Charge) => {
    setCompositeCharge(
      compositeCharge.map((cc) => ({
        ...cc,
        pricingFactors: [...(cc.pricingFactors ?? []), charge],
      })),
    );
  };
  const removeProductPricingFactor = (charge: Charge) => {
    setCompositeCharge(
      compositeCharge.map((cc) => ({
        ...cc,
        pricingFactors: (cc.pricingFactors ?? []).filter(
          (pf) => pf.id !== charge.id,
        ),
      })),
    );
  };

  const eligiblePricingFactorsWithPricing: { id: string; name: string }[] = [];

  for (const product of allProducts) {
    const pfs = product.productData?.ProductPricingFactors ?? [];
    for (const pricingFactor of pfs) {
      // Composite charge can't rely on itself of course
      if (pricingFactor.id === pricingFactorId) {
        continue;
      }
      const pricedPricingFactor = product.pricedProduct.pricingFactors.find(
        (ppf) => ppf.pricingFactorId === pricingFactor.id,
      );

      // Composite charges can only depend on pricing factors that:
      //   - use the same credit type
      //   - are not also composite charges (except minimum charges can depend on percentage charges)
      if (
        product.pricedProduct.creditType?.id === creditTypeId &&
        (pricingFactor.charge_type_enum !== ChargeTypeEnum_Enum.Composite ||
          (compositeChargeType === CompositeChargeTypeEnum_Enum.Minimum &&
            pricedPricingFactor?.compositeCharge?.length &&
            pricedPricingFactor.compositeCharge[0].type ===
              CompositeChargeTypeEnum_Enum.Percentage))
      ) {
        eligiblePricingFactorsWithPricing.push({
          id: pricingFactor.id,
          name: pricingFactor.name,
        });
      }
    }
  }

  const eligiblePricingFactorIds = new Set(
    eligiblePricingFactorsWithPricing.map((pf) => pf.id),
  );

  const productsWithEligiblePricingFactors = allProducts.map((p) => ({
    name: p.productData.name,
    pricingFactors: p.productData.ProductPricingFactors.filter((pf) =>
      eligiblePricingFactorIds.has(pf.id),
    ),
  }));

  return (
    <RightPane
      isOpen={true}
      onRequestClose={() => onRequestClose()}
      className={styles.popup}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Headline level={6}>Select charges</Headline>
          <div>
            <IconButton
              onClick={() => onRequestClose()}
              theme="secondary"
              icon="xClose"
            />
          </div>
        </div>
        <div className={styles.popupContent}>
          <div>
            <Body level={2}>
              <p className={styles.text}>
                {initialCharge[0]?.type === CompositeChargeTypeEnum_Enum.Minimum
                  ? "Minimums allow you to designate a minimum for a single charge or across multiple charges."
                  : "Composite charges allow you to charge a percentage of the subtotal of specific charges."}{" "}
                Select those charges below (selected charges cannot be in a
                different pricing unit).
              </p>
              <p className={styles.text}>
                <Icon icon="warning" className={styles.warning} />
                Changing the pricing unit of a selected charge will disassociate
                that charge from this one.
              </p>
            </Body>
          </div>
          <div className={styles.selectButtons}>
            {(compositeCharge?.[0]?.pricingFactors ?? []).length <
            eligiblePricingFactorsWithPricing.length ? (
              <Button
                className={styles.selectAll}
                onClick={() =>
                  setCompositeCharge(
                    compositeCharge.map((cc) => ({
                      ...cc,
                      pricingFactors: eligiblePricingFactorsWithPricing,
                    })),
                  )
                }
                text="Select All"
                theme="linkGray"
              />
            ) : (
              <Button
                className={styles.deselectAll}
                onClick={() =>
                  setCompositeCharge(
                    compositeCharge.map((cc) => ({
                      ...cc,
                      pricingFactors: [],
                    })),
                  )
                }
                text="Deselect All"
                theme="linkGray"
              />
            )}
          </div>
          <div>
            {productsWithEligiblePricingFactors
              .filter((product) => product.pricingFactors.length > 0)
              .map((product, i) => {
                return (
                  <div className={styles.charges} key={i}>
                    <div className={styles.productName}>{product.name}</div>
                    <SelectableCards
                      loading={loading}
                      clickable
                      cards={(product.pricingFactors ?? []).map((ppf) => {
                        const isSelected = compositeCharge[0].pricingFactors
                          ?.map((pf) => pf.id)
                          .includes(ppf.id);
                        return {
                          onClick: () => {
                            isSelected
                              ? removeProductPricingFactor(ppf)
                              : addProductPricingFactor(ppf);
                          },
                          id: ppf.id,
                          content: ppf.name,
                          icon: isSelected ? (
                            <Icon
                              icon="checkmarkCircle"
                              className={styles.selected}
                            />
                          ) : (
                            <Icon
                              icon="checkmarkCircle"
                              className={styles.deselected}
                            />
                          ),
                          selected: isSelected,
                        };
                      })}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div className={twMerge(styles.actions, "flex gap-8")}>
          <Button
            onClick={() => onRequestClose()}
            text="Cancel"
            theme="linkGray"
          />
          <Button
            onClick={() => {
              onRequestSave(pricingFactorId, compositeCharge);
            }}
            text="Save"
            theme="primary"
          />
        </div>
      </div>
    </RightPane>
  );
};
