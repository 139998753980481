import { createCustomEqual } from "fast-equals";
import Decimal from "decimal.js";

export const deepEqualsWithDecimalSupport = createCustomEqual({
  createInternalComparator(compare) {
    return (a, b, _aKey, _bKey, _aParent, _bParent, state) => {
      if (compare(a, b, state)) {
        return true;
      }

      if (Decimal.isDecimal(a) && Decimal.isDecimal(b)) {
        return a.equals(b);
      }

      return false;
    };
  },
});
