import React from "react";

import { NotFoundPage } from "pages/404";
import { UI_MODE, useUIMode } from "../../lib/useUIMode";
import { NewUIElementSwitch } from "./elementSwitch";

interface Props {
  enabled?: React.ReactNode;
  /* if unspecified, render the element for all modes */
  enabledModes?: UI_MODE[];
  disabled?: React.ReactNode;
  children?: undefined;
}

/** Render one of the two elements based on whether new ui is enabled */
export const NewUIRouteSwitch: React.FC<Props> = (props) => {
  const { newUIEnabled } = useUIMode();
  return (
    <>
      {newUIEnabled ? (
        <NewUIElementSwitch
          element={props.enabled}
          enabledModes={props.enabledModes}
        />
      ) : (
        props.disabled ?? <NotFoundPage />
      )}
    </>
  );
};
