import { dayjs } from "lib/dayjs";
import {
  RevenueRecognitionDocument,
  RevenueRecognitionQuery,
} from "./queries.graphql";
import Decimal from "decimal.js";
import { USD_CREDIT_ID } from "lib/credits";
import { ReportConfig } from "../reportConfig";

function getDateFromInvoice(
  invoice: RevenueRecognitionQuery["Customer"][number]["invoices"]["invoices"][number],
  endOrStart: "end" | "start",
) {
  if (invoice.__typename === "ArrearsInvoice") {
    return dayjs(
      endOrStart === "end"
        ? invoice.exclusive_end_date
        : invoice.inclusive_start_date,
    );
  }
  return dayjs(invoice.issued_at);
}

const report: ReportConfig<RevenueRecognitionQuery> = {
  pageSize: 10,
  name: "Billed revenue detail",
  needsDates: true,
  needsEnvironment: false,
  dataToCSV: (pages, inclusiveStartDate, exclusiveEndDate) => {
    const headers = [
      "Customer ID",
      "Customer Name",
      "Ingestion ID",
      "Stripe ID",
      "Line Item",
      "Line Item Detail",
      "Billing Frequency",
      "Billing Date",
      "Units",
      "Credit Type",
      "Received Revenue",
      "In Advanced Revenue Total",
    ];

    function findBillingFrequency(chargeName: string) {
      /*
        Finds the billing frequency:
        1 - recurs every billing period
        n - recurs ever n billing periods
      */

      const lowerChargeName = chargeName.toLowerCase();
      if (
        lowerChargeName.includes("incremental monthly service fee") ||
        lowerChargeName.includes("recurs every month")
      ) {
        return 1;
      }

      const matches = lowerChargeName.match(/recurs every (\d+) months/);
      if (matches && matches.length > 1) {
        return parseInt(matches[1]);
      }

      return 0;
    }

    const rows = pages.flatMap((page) =>
      page.Customer.flatMap((customer) => {
        const inAdvancedCharges = new Map<
          string,
          Array<{
            lineItemName: string;
            chargeName: string;
            billingFrequency: string;
            quantity: string;
            total: string;
            inAdvancedTotal: string;
          }>
        >();
        const {
          id: customerId,
          name: customerName,
          ingest_aliases,
          BillingProviderCustomers,
          invoices,
        } = customer;

        const ingestAlias =
          ingest_aliases?.map((ia) => ia.ingest_alias)?.join(",") || "";
        const stripeId =
          BillingProviderCustomers.find(
            (bp) => bp.billing_provider === "STRIPE",
          )?.billing_provider_customer_id || "";

        if (customer.invoices.cursor) {
          throw new Error(
            "More than 1 page of invoices returned and this report doesn't support pagination yet.",
          );
        }

        return [...invoices.invoices]
          .sort((a, b) => {
            // This report is very fragile and relies on a very specific order
            // of invoices as returned by the legacy system. We try our best to emulate
            // that order here.
            const aStartDate = getDateFromInvoice(a, "start").valueOf();
            const bStartDate = getDateFromInvoice(b, "start").valueOf();
            if (aStartDate === bStartDate) {
              const orderedTypenames = ["AdvanceInvoice", "ArrearsInvoice"];
              return (
                orderedTypenames.indexOf(b.__typename) -
                orderedTypenames.indexOf(a.__typename)
              );
            }
            return aStartDate - bStartDate;
          })
          .flatMap((invoice) => {
            const invoicePlanId =
              invoice.__typename === "ArrearsInvoice" ||
              invoice.__typename === "AdvanceInvoice"
                ? invoice.plan?.id ?? "unknown"
                : "unknown";
            const invoiceItems = [];
            // see if there are any in advanced charges to push
            inAdvancedCharges.forEach((revs, key) => {
              if (!key.startsWith(invoicePlanId + "_")) {
                inAdvancedCharges.delete(key);
                return;
              }

              const data = revs.pop();
              if (data) {
                const {
                  lineItemName,
                  chargeName,
                  billingFrequency,
                  quantity,
                  total,
                  inAdvancedTotal,
                } = data;
                invoiceItems.push([
                  customerId,
                  customerName,
                  ingestAlias,
                  stripeId,
                  lineItemName,
                  chargeName,
                  billingFrequency,
                  getDateFromInvoice(invoice, "end").format("YYYY-MM-DD"),
                  quantity,
                  invoice.credit_type?.name,
                  total,
                  inAdvancedTotal,
                ]);
              } else {
                inAdvancedCharges.delete(key);
              }
            });

            invoiceItems.push(
              ...invoice.line_items.flatMap((lineItem) => {
                if (!("sub_line_items" in lineItem)) {
                  return [];
                }
                return lineItem.sub_line_items.map((subLineItem) => {
                  const total =
                    invoice.credit_type.id === USD_CREDIT_ID
                      ? new Decimal(subLineItem.total).div(100)
                      : new Decimal(subLineItem.total);

                  let chargeName = subLineItem.display_name;
                  // find billing frequency
                  const billingFrequency = findBillingFrequency(
                    subLineItem.display_name,
                  );
                  if (billingFrequency > 1) {
                    const futureRecognizedRevenue = [];
                    const inAdvancedTotal = total.toString();
                    for (let i = billingFrequency; i >= 2; i--) {
                      // need to amortize this over the billing frequency
                      futureRecognizedRevenue.push({
                        lineItemName: lineItem.display_name,
                        chargeName: `${chargeName} (${i} of ${billingFrequency})`,
                        billingFrequency: billingFrequency.toString(),
                        quantity: "0",
                        total: "0",
                        inAdvancedTotal,
                      });
                    }

                    chargeName = `${chargeName} (1 of ${billingFrequency})`;
                    inAdvancedCharges.set(
                      invoicePlanId + "_" + chargeName,
                      futureRecognizedRevenue,
                    );
                  }

                  return [
                    customerId,
                    customerName,
                    ingestAlias,
                    stripeId,
                    lineItem.display_name,
                    chargeName,
                    billingFrequency.toString(),
                    getDateFromInvoice(invoice, "end").format("YYYY-MM-DD"),
                    "quantity" in subLineItem ? subLineItem.quantity : "0",
                    invoice.credit_type?.name ?? "--",
                    total.toString(),
                    "",
                  ];
                });
              }),
            );

            return invoiceItems;
          });
      }),
    );

    return [
      headers,
      ...rows.filter((row) => {
        const endDate = dayjs(row[7]);
        return (
          endDate.valueOf() >= inclusiveStartDate.valueOf() &&
          endDate.valueOf() <= exclusiveEndDate.valueOf()
        );
      }),
    ];
  },
  queryDocument: RevenueRecognitionDocument,
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("AGENTSYNC_REVENUE_RECOGNITION"),
  nextCursor: (page) => page.Customer.at(-1)?.id ?? null,
};

export default report;
