import React, { useState } from "react";

import {
  GetActiveMarketplaceContractsQueryResult,
  GetClientConfigQueryResult,
  useGetActiveMarketplaceContractsQuery,
  useGetBillingProvidersSettingsQuery,
  useGetClientConfigQuery,
} from "./queries.graphql";
import {
  BillingProviderEnum_Enum,
  ClientConfig,
  ClientConfigKeyEnum_Enum,
  EnvironmentTypeEnum_Enum,
} from "types/generated-graphql/__types__";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useFeatureFlag } from "lib/launchdarkly";
import AWSMarketplaceSettingsModal from "../../components/AWSMarketplaceSettingsModal";
import AzureMarketplaceSettingsModal from "../../components/AzureMarketplaceSettingsModal";
import {
  AWSMarketplaceCustomerSettings,
  AzureMarketplaceCustomerSettings,
  BPCustomerSettings,
  CustomerIntegrationSettingsInfo,
  CustomerSettingsWritePlansAndOrContracts,
  GCPMarketplaceCustomerSettings,
  getNetSuiteCustomerIntegrationCardVisibility,
  MultiBPCustomerSettings,
  NetSuiteConnectionCustomerSettings,
  parseIntegrationCustomerSettings,
  PlansAndOrContracts,
  SalesforceConnectionCustomerSettings,
  StripeCustomerSettings,
} from "lib/billingProvider/billingProviderSettings";
import { Column, Table } from "tenaissance/components/Table";
import { SectionHeader } from "tenaissance/components/SectionHeader";
import {
  IntegrationDetailProps,
  IntegrationDetails,
} from "../../../../../GeneralSettings/components/IntegrationsTable/IntegrationDetails";
import { renderDate, renderDateTimeInUTC } from "lib/time";
import { AzureIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/AzureIntegrationCard";
import { AwsIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/AwsIntegrationCard";
import { StripeIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/StripeIntegrationCard";
import StripeSettingsModal from "../../components/StripeSettingsModal";
import { NetSuiteConnectionModal } from "../../components/NetSuiteConnectionModal";
import { SalesforceConnectionModal } from "../../components/SalesforceConnectionModal";
import { BaseIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/BaseIntegrationCard";
import { ReactComponent as NetSuiteLogo } from "tenaissance/icons/netsuite.svg";
import { SalesforceIntegrationCard } from "../../../../../GeneralSettings/components/IntegrationCards/SalesforceIntegrationCard";
import {
  Dropdown,
  DropdownHeader,
  DropdownItem,
} from "../../../../../../tenaissance/components/Dropdown";
import { useNavigate } from "../../../../../../lib/useNavigate";
import { useUIMode } from "../../../../../../lib/useUIMode";
import { EmptyState } from "../../../../../../tenaissance/components/EmptyState";
import { DisableStripeModal } from "../../components/DisableStripeModal";
import { Tooltip } from "tenaissance/components/Tooltip";
import { DeleteNetSuiteConnectionModal } from "../../components/DeleteNetSuiteConnectionModal";
import { DeleteSalesforceConnectionModal } from "../../components/DeleteSalesforceConnectionModal";
import { Link } from "react-router-dom";
import { Icon } from "../../../../../../tenaissance/components/Icon";
import { contractOrPlanText } from "lib/language";
import { isIntegrationActive } from "./logic";

const netsuiteLogoComponent = <NetSuiteLogo />;

type BillingProviderSectionProps = {
  customerID: string;
};

/**
 * Row data for the ConnectedCustomerIntegrationsTable before it gets 'converted' into an actual row.
 */
export type ConnectedCustomerIntegrationsTableRowSpec = {
  displayedSettings?: Record<
    string,
    string | { value: string; hasBadgeLabel: boolean } | undefined
  >;
  plan?: {
    id: string;
    name: string;
    billing_provider: BillingProviderEnum_Enum | null;
  };
  contractsAndPlan?: ParsedContractsAndPlan;
  onEdit?: () => void;
} & (
  | {
      name: "AWS Marketplace";
      settings: BPCustomerSettings<AWSMarketplaceCustomerSettings>;
    }
  | {
      name: "Azure Marketplace";
      settings: BPCustomerSettings<AzureMarketplaceCustomerSettings>;
    }
  | {
      name: "GCP Marketplace";
      settings: BPCustomerSettings<GCPMarketplaceCustomerSettings>;
    }
  | {
      name: "Stripe";
      titleComponent: React.ReactNode;
      settings: BPCustomerSettings<StripeCustomerSettings> | undefined;
    }
  | {
      name: "Netsuite";
      settings:
        | BPCustomerSettings<NetSuiteConnectionCustomerSettings>
        | undefined;
    }
  | {
      name: "Salesforce";
      settings:
        | BPCustomerSettings<SalesforceConnectionCustomerSettings>
        | undefined;
    }
);

function parseClientGracePeriodHours(
  clientConfig: GetClientConfigQueryResult["data"],
) {
  const getConfigKey = (
    clientConfig: Partial<ClientConfig>[] | undefined,
    key: ClientConfigKeyEnum_Enum,
  ) =>
    clientConfig?.length
      ? clientConfig.find((c) => c.key === key)?.value
      : undefined;
  // note: for now we determine if delta stream is enabled based on client
  // config (across billingProviders), not client's delivery method (per billingProvider).
  // this works b/c only CFLT is using it & we'll configure all billingProviders at once.
  // future: modify list_delivery_method resolver to target a specific clients
  return parseInt(
    getConfigKey(
      clientConfig?.ClientConfig,
      ClientConfigKeyEnum_Enum.GracePeriodHours,
    ) || "24",
  );
}

type ParsedContract = {
  id: string;
  name: string | null;
  customer_billing_provider_configuration: { id: string } | null;
};
type ParsedCustomerPlan = {
  id: string;
  name: string;
  billing_provider: BillingProviderEnum_Enum | null;
};
type ParsedContractsAndPlan = {
  contracts?: ParsedContract[];
  plan?: ParsedCustomerPlan;
};

function parseActiveContractsAndPlan(
  contractsAndPlans: GetActiveMarketplaceContractsQueryResult["data"],
  billingProvider: BillingProviderEnum_Enum,
): ParsedContractsAndPlan {
  const customerPlan =
    contractsAndPlans?.active_customer_plan_billing_provider
      ?.CustomerPlans?.[0];
  return {
    // the query already filters contracts by BP
    contracts: contractsAndPlans?.contracts_page.contracts,
    // The query does not filter plans by BP, but retrieves the singular active
    // plan. We can assume the active plan has the same billing provider as that
    // which is attached to the current BillingProviderCustomer configuration
    plan: customerPlan?.Plan && {
      id: customerPlan?.id,
      name: customerPlan?.Plan?.name,
      billing_provider: billingProvider,
    },
  };
}
export const BillingProviderSection: React.FC<BillingProviderSectionProps> = ({
  customerID,
}) => {
  // as of 11/19/2024 this is _almost_ rolled all the way out but not quite.
  // once it is we can delete a lot of code here
  const allowContractsEnabled = useFeatureFlag<boolean>(
    "allow-contracts",
    false,
  );

  return allowContractsEnabled ? (
    <BillingProviderSectionContractsAndPlan customerID={customerID} />
  ) : (
    <BillingProviderSectionPlanOnly customerID={customerID} />
  );
};

const BillingProviderSectionPlanOnly: React.FC<BillingProviderSectionProps> = ({
  customerID,
}) => {
  const [awsMarketplaceSettingsModalOpen, setAWSMarketplaceSettingsModalOpen] =
      useState(false),
    [azureMarketplaceSettingsModalOpen, setAzureMarketplaceSettingsModalOpen] =
      useState(false),
    [stripeSettingsModalOpen, setStripeSettingsModalOpen] = useState(false),
    [stripeSettingsDeleteModalOpen, setStripeSettingsDeleteModalOpen] =
      useState(false),
    [netsuiteConnectionModalOpen, setNetsuiteConnectionModalOpen] =
      useState(false),
    [netsuiteConnectionDeleteModalOpen, setNetsuiteConnectionDeleteModalOpen] =
      useState(false),
    [salesforceConnectionModalOpen, setSalesforceConnectionModalOpen] =
      useState(false),
    [
      salesforceConnectionDeleteModalOpen,
      setSalesforceConnectionDeleteModalOpen,
    ] = useState(false),
    { environmentType } = useEnvironment(),
    { data, loading } = useGetBillingProvidersSettingsQuery({
      variables: {
        customer_id: customerID,
        environment_type: environmentType,
      },
    }),
    netSuiteEnabled = useFeatureFlag<boolean>("netsuite", false);

  let awsSettings:
    | BPCustomerSettings<AWSMarketplaceCustomerSettings>
    | undefined;
  let azureSettings:
    | BPCustomerSettings<AzureMarketplaceCustomerSettings>
    | undefined;
  let stripeSettings: BPCustomerSettings<StripeCustomerSettings> | undefined;
  let salesforceConnectionSettings:
    | SalesforceConnectionCustomerSettings
    | undefined;
  let netSuiteConnectionSettings:
    | NetSuiteConnectionCustomerSettings
    | undefined;

  let customerSettingsInfo:
    | Partial<CustomerIntegrationSettingsInfo>
    | undefined;
  if (!loading && data) {
    customerSettingsInfo = parseIntegrationCustomerSettings(data);
    awsSettings = customerSettingsInfo.awsMultiSettings?.find(
      (settings) => settings.billingProviderCustomerID,
    );
    azureSettings = customerSettingsInfo.azureMultiSettings?.find(
      (settings) => settings.billingProviderCustomerID,
    );
    stripeSettings = customerSettingsInfo.stripeSettings?.find(
      (settings) => settings.billingProviderCustomerID,
    );
    salesforceConnectionSettings =
      customerSettingsInfo.salesforceConnectionSettings;
    netSuiteConnectionSettings =
      customerSettingsInfo.netSuiteConnectionSettings;
  }

  const netSuiteVisibility = getNetSuiteCustomerIntegrationCardVisibility(
    netSuiteConnectionSettings || {},
    netSuiteEnabled || false,
  );

  const awsIsConnected = !!awsSettings?.billingProviderCustomerID,
    azureIsConnected = !!azureSettings?.billingProviderCustomerID,
    stripeIsConnected = !!stripeSettings?.billingProviderCustomerID,
    netsuiteIsConnected = netSuiteVisibility?.isConnected,
    salesforceIsConnected = !!salesforceConnectionSettings?.connectionDate;

  const activePlan = data?.customer?.CustomerPlans?.[0]?.Plan;

  // Show connected integrations section if any integration is connected
  const showConnectedIntegrations =
    awsIsConnected ||
    azureIsConnected ||
    stripeIsConnected ||
    netsuiteIsConnected ||
    salesforceIsConnected;

  // Only one billing providers can be connected per customer/plan, so if one is
  // connected then all are.
  const billingProviderIsConnected =
    awsIsConnected || azureIsConnected || stripeIsConnected;
  // Show available integrations section if no billing providers
  // integration are connected, or if NetSuite or Salesforce are not connected.
  const showAvailableIntegrations =
    !billingProviderIsConnected ||
    !netsuiteIsConnected ||
    !salesforceIsConnected;

  return (
    <div>
      {showConnectedIntegrations && (
        <ConnectedCustomerIntegrationsTable
          customerID={customerID}
          plansOnly={true}
          rows={[
            !!awsSettings?.billingProviderCustomerID && {
              name: "AWS Marketplace" as const,
              settings: awsSettings,
              displayedSettings:
                awsSettings.customerId &&
                awsSettings.productCode &&
                awsSettings.region
                  ? {
                      "Customer ID": awsSettings.customerId,
                      "Product code": awsSettings.productCode,
                      Region: awsSettings.region,
                      "Is subscription product?": {
                        value: awsSettings?.isSubscriptionProduct
                          ? "Yes"
                          : "No",
                        hasBadgeLabel: true as const,
                      },
                      ...(awsSettings.expirationTimestamp
                        ? {
                            Expiration: {
                              value: renderDateTimeInUTC(
                                awsSettings.expirationTimestamp,
                                false,
                              ),
                              hasBadgeLabel: true,
                            },
                          }
                        : undefined),
                    }
                  : undefined,
              plan: activePlan,
            },
            !!azureSettings?.billingProviderCustomerID && {
              name: "Azure Marketplace" as const,
              settings: azureSettings,
              displayedSettings: {
                ...(azureSettings.subscriptionId
                  ? {
                      "Subscription ID": azureSettings.subscriptionId,
                    }
                  : undefined),
                ...(azureSettings.expirationDate
                  ? {
                      Expiration: {
                        value: renderDateTimeInUTC(
                          azureSettings.expirationDate,
                          false,
                        ),
                        hasBadgeLabel: true,
                      },
                    }
                  : undefined),
              },
              plan: activePlan,
            },
            !!stripeSettings?.billingProviderCustomerID && {
              name: "Stripe" as const,
              titleComponent: (
                <StripeTitleComponent
                  environmentType={environmentType}
                  stripeCustomerId={stripeSettings?.billingProviderCustomerID}
                />
              ),
              settings: stripeSettings,
              displayedSettings:
                stripeSettings?.customerId && stripeSettings?.collectionMethod
                  ? {
                      "Customer ID": stripeSettings?.customerId,
                      "Collection Method": {
                        value:
                          stripeSettings?.collectionMethod ===
                          "charge_automatically"
                            ? "Automatic"
                            : stripeSettings?.collectionMethod ===
                                "send_invoice"
                              ? "Send Invoice"
                              : stripeSettings?.collectionMethod,
                        hasBadgeLabel: true as const,
                      },
                    }
                  : undefined,
              plan: activePlan,
              onEdit: () => {
                setStripeSettingsModalOpen(true);
              },
            },
            {
              name: "Netsuite" as const,
              settings: netSuiteConnectionSettings,
              displayedSettings: netSuiteConnectionSettings?.netSuiteCustomerId
                ? {
                    "Customer ID":
                      netSuiteConnectionSettings?.netSuiteCustomerId,
                  }
                : undefined,
              onEdit: () => setNetsuiteConnectionModalOpen(true),
            },
            {
              name: "Salesforce" as const,
              settings: salesforceConnectionSettings,
              displayedSettings:
                salesforceConnectionSettings?.salesforceAccountId
                  ? {
                      "Account ID":
                        salesforceConnectionSettings?.salesforceAccountId,
                    }
                  : undefined,
              onEdit: () => setSalesforceConnectionModalOpen(true),
            },
          ]
            .filter(
              (
                v: ConnectedCustomerIntegrationsTableRowSpec | false,
              ): v is ConnectedCustomerIntegrationsTableRowSpec => !!v,
            )
            .map((props): ConnectedCustomerIntegrationRow | undefined =>
              props && props.settings?.connectionDate
                ? {
                    integrationName:
                      props?.name as ConnectedCustomerIntegrationRow["integrationName"],
                    integrationDisplayDetails: (
                      <IntegrationDetails
                        integrationName={props?.name}
                        titleComponent={props?.titleComponent}
                        displayedSettings={
                          "displayedSettings" in props
                            ? (props?.displayedSettings as IntegrationDetailProps["displayedSettings"])
                            : undefined
                        }
                        isClickable={false}
                        supportingText={renderDate(
                          props.settings.connectionDate,
                          {
                            isUtc: false,
                          },
                        )}
                        isActive={isIntegrationActive(props)}
                      />
                    ),
                    connectedContractsAndPlan:
                      "plan" in props && props?.plan
                        ? [
                            {
                              id: props.plan.id,
                              name: props.plan.name,
                              contractOrPlan: "plan" as const,
                            } as ConnectedContractOrPlan,
                          ]
                        : [],
                    dateConnected: props?.settings.connectionDate,
                    onEdit: "onEdit" in props ? props?.onEdit : undefined,
                    plansOnly: true,
                  }
                : undefined,
            )
            .filter((row): row is ConnectedCustomerIntegrationRow => !!row)}
        />
      )}

      {showAvailableIntegrations && (
        <div
          className={
            "grid gap-[12px]" + (showConnectedIntegrations ? " mt-[24px]" : "")
          }
        >
          <SectionHeader
            title="Available integrations"
            subtitle="Connect to new upstream and downstream datasets."
          ></SectionHeader>

          {/* Integration Cards */}
          {customerSettingsInfo?.clientHasAws &&
            !billingProviderIsConnected && (
              <AwsIntegrationCard
                className="w-full"
                cardProps={{ wrapContents: false }}
                buttonOptions={{
                  buttonOnClick: () => setAWSMarketplaceSettingsModalOpen(true),
                }}
              />
            )}

          {customerSettingsInfo?.clientHasAzure &&
            !billingProviderIsConnected && (
              <AzureIntegrationCard
                className="w-full"
                cardProps={{ wrapContents: false }}
                buttonOptions={{
                  buttonOnClick: () =>
                    setAzureMarketplaceSettingsModalOpen(true),
                }}
              />
            )}

          {customerSettingsInfo?.clientHasStripe &&
            !billingProviderIsConnected && (
              <StripeIntegrationCard
                className="w-full"
                cardProps={{ wrapContents: false }}
                buttonOptions={{
                  buttonOnClick: () => setStripeSettingsModalOpen(true),
                }}
              />
            )}

          {netSuiteVisibility.isAvailable && (
            <BaseIntegrationCard
              logo={netsuiteLogoComponent}
              title="Netsuite"
              subheader="Accounting/Revenue integration"
              description="Enter a NetSuite customer ID to send Sales Orders, Invoices, and Credit Memos to NetSuite."
              className="w-full"
              cardProps={{ wrapContents: false }}
              buttonOptions={{
                buttonOnClick: () => setNetsuiteConnectionModalOpen(true),
              }}
            />
          )}

          {!salesforceConnectionSettings?.connectionDate && (
            <SalesforceIntegrationCard
              className="w-full"
              cardProps={{ wrapContents: false }}
              buttonOptions={{
                buttonOnClick: () => setSalesforceConnectionModalOpen(true),
              }}
            />
          )}

          {/* Setup Integration Modals */}
          {awsMarketplaceSettingsModalOpen && (
            <AWSMarketplaceSettingsModal
              onClose={() => setAWSMarketplaceSettingsModalOpen(false)}
              customerId={customerID}
              plansAndOrContracts="plans_only"
            />
          )}

          {azureMarketplaceSettingsModalOpen && (
            <AzureMarketplaceSettingsModal
              onClose={() => setAzureMarketplaceSettingsModalOpen(false)}
              customerID={customerID}
              plansAndOrContracts="plans_only"
            />
          )}

          {stripeSettingsModalOpen && (
            <StripeSettingsModal
              onClose={(deleteConfig) => {
                setStripeSettingsModalOpen(false);
                if (deleteConfig) {
                  setStripeSettingsDeleteModalOpen(true);
                }
              }}
              edit={
                stripeSettings?.billingProviderCustomerID
                  ? PlansAndOrContracts.plansOnly
                  : false
              }
              customerId={customerID}
              stripeCustomerID={stripeSettings?.customerId}
              stripeCollectionMethod={stripeSettings?.collectionMethod}
              hasStripeBillingProviderToken={
                customerSettingsInfo?.hasStripeBillingProviderToken ?? false
              }
              hasStripeBillingProviderDeliveryMethod={false}
              hasBillingProviderOnPlan={
                customerSettingsInfo?.hasBillingProviderOnPlan ?? false
              }
              hasStripeOnPlan={!!stripeSettings?.billingProviderCustomerID}
              hasStripeOnContract={false}
            />
          )}

          {stripeSettingsDeleteModalOpen && (
            <DisableStripeModal
              onClose={() => setStripeSettingsDeleteModalOpen(false)}
              customerId={customerID}
            />
          )}

          {netsuiteConnectionModalOpen && (
            <NetSuiteConnectionModal
              isOpen={netsuiteConnectionModalOpen}
              onClose={(deleteConfig) => {
                setNetsuiteConnectionModalOpen(false);
                if (deleteConfig) {
                  setNetsuiteConnectionDeleteModalOpen(true);
                }
              }}
              netSuiteCustomerId={
                netSuiteConnectionSettings?.netSuiteCustomerId
              }
              customerId={customerID}
            />
          )}

          {netsuiteConnectionDeleteModalOpen && (
            <DeleteNetSuiteConnectionModal
              onClose={() => setNetsuiteConnectionDeleteModalOpen(false)}
              isOpen={netsuiteConnectionDeleteModalOpen}
              customerId={customerID}
            />
          )}

          {salesforceConnectionModalOpen && (
            <SalesforceConnectionModal
              isOpen={salesforceConnectionModalOpen}
              onClose={(deleteConfig) => {
                setSalesforceConnectionModalOpen(false);
                if (deleteConfig) {
                  setSalesforceConnectionDeleteModalOpen(true);
                }
              }}
              salesforceAccountId={
                salesforceConnectionSettings?.salesforceAccountId
              }
              customerId={customerID}
            />
          )}

          {salesforceConnectionDeleteModalOpen && (
            <DeleteSalesforceConnectionModal
              isOpen={salesforceConnectionDeleteModalOpen}
              onClose={() => setSalesforceConnectionDeleteModalOpen(false)}
              customerId={customerID}
            />
          )}
        </div>
      )}
    </div>
  );
};

type StripeModalState = {
  open: boolean;
  edit: PlansAndOrContracts | false;
  stripeCustomerId: string | undefined;
  stripeCollectionMethod: string | undefined;
};

const BillingProviderSectionContractsAndPlan: React.FC<
  BillingProviderSectionProps
> = ({ customerID }) => {
  const DEFAULT_STRIPE_MODAL_STATE: StripeModalState = {
    open: false,
    edit: false,
    stripeCustomerId: undefined,
    stripeCollectionMethod: undefined,
  };
  const [awsMarketplaceSettingsModalOpen, setAWSMarketplaceSettingsModalOpen] =
      useState(false),
    [azureMarketplaceSettingsModalOpen, setAzureMarketplaceSettingsModalOpen] =
      useState(false),
    [stripeSettingsModalOpen, setStripeSettingsModalOpen] =
      useState<StripeModalState>(DEFAULT_STRIPE_MODAL_STATE),
    [stripeSettingsDeleteModalOpen, setStripeSettingsDeleteModalOpen] =
      useState(false),
    [netsuiteConnectionModalOpen, setNetsuiteConnectionModalOpen] =
      useState(false),
    [netsuiteConnectionDeleteModalOpen, setNetsuiteConnectionDeleteModalOpen] =
      useState(false),
    [salesforceConnectionModalOpen, setSalesforceConnectionModalOpen] =
      useState(false),
    [
      salesforceConnectionDeleteModalOpen,
      setSalesforceConnectionDeleteModalOpen,
    ] = useState(false),
    { environmentType } = useEnvironment(),
    { data, loading } = useGetBillingProvidersSettingsQuery({
      variables: {
        customer_id: customerID,
        environment_type: environmentType,
      },
    }),
    netSuiteEnabled = useFeatureFlag<boolean>("netsuite", false),
    { data: clientConfig } = useGetClientConfigQuery();

  let awsMultiSettings:
    | MultiBPCustomerSettings<AWSMarketplaceCustomerSettings>
    | undefined;
  let azureMultiSettings:
    | MultiBPCustomerSettings<AzureMarketplaceCustomerSettings>
    | undefined;
  let stripeMultiSettings:
    | MultiBPCustomerSettings<StripeCustomerSettings>
    | undefined;
  let gcpMultiSettings:
    | MultiBPCustomerSettings<GCPMarketplaceCustomerSettings>
    | undefined;
  let salesforceConnectionSettings:
    | SalesforceConnectionCustomerSettings
    | undefined;
  let netSuiteConnectionSettings:
    | NetSuiteConnectionCustomerSettings
    | undefined;

  let customerSettingsInfo: CustomerIntegrationSettingsInfo | undefined;
  if (!loading && data) {
    customerSettingsInfo = parseIntegrationCustomerSettings(data);
    awsMultiSettings = customerSettingsInfo.awsMultiSettings;
    azureMultiSettings = customerSettingsInfo.azureMultiSettings;
    stripeMultiSettings = customerSettingsInfo.stripeSettings;
    salesforceConnectionSettings =
      customerSettingsInfo.salesforceConnectionSettings;
    netSuiteConnectionSettings =
      customerSettingsInfo.netSuiteConnectionSettings;
    gcpMultiSettings = customerSettingsInfo?.gcpSettings;
  }

  const clientGracePeriodHours = parseClientGracePeriodHours(clientConfig);
  const GRACE_PERIOD_BUFFER_HOURS = 2;
  const grace_period_hours = clientGracePeriodHours + GRACE_PERIOD_BUFFER_HOURS;

  const activeAwsContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.AwsMarketplace,
        customer_id: customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.AwsMarketplace,
  );

  const activeAzureContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.Azure,
        customer_id: customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.Azure,
  );

  const activeStripeContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.Stripe,
        customer_id: customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.Stripe,
  );

  const activeGCPContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.Gcp,
        customer_id: customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.Gcp,
  );

  const mapConnectedContractsAndPlan = (contractsAndPlan?: {
    contracts?: ParsedContract[];
    plan?: ParsedCustomerPlan;
  }): ConnectedContractOrPlan[] | undefined => {
    return contractsAndPlan?.contracts
      ?.map(
        (c, index): ConnectedContractOrPlan => ({
          id: c.id,
          name: c.name || `Contract ${index + 1}`,
          contractOrPlan: "contract" as const,
        }),
      )
      .concat(
        contractsAndPlan?.plan
          ? [
              {
                id: contractsAndPlan?.plan.id,
                name: contractsAndPlan?.plan.name,
                contractOrPlan: "plan" as const,
              } as ConnectedContractOrPlan,
            ]
          : [],
      );
  };

  function filterConnectedContractsAndPlan<
    T extends
      | SalesforceConnectionCustomerSettings
      | NetSuiteConnectionCustomerSettings
      | AWSMarketplaceCustomerSettings
      | AzureMarketplaceCustomerSettings
      | StripeCustomerSettings,
  >(
    currentConfig: BPCustomerSettings<T> | undefined,
    contractsAndPlan: {
      contracts?: ParsedContract[];
      plan?: ParsedCustomerPlan;
    },
  ): ParsedContractsAndPlan {
    const customerBillingProviderConfigurationID =
      currentConfig && "customerBillingProviderConfigurationID" in currentConfig
        ? currentConfig.customerBillingProviderConfigurationID
        : undefined;
    const billingProviderCustomerID =
      currentConfig && "billingProviderCustomerID" in currentConfig
        ? currentConfig.billingProviderCustomerID
        : undefined;

    return {
      contracts: contractsAndPlan.contracts?.filter(
        (c) =>
          c.customer_billing_provider_configuration?.id ===
          customerBillingProviderConfigurationID,
      ),
      plan: !!billingProviderCustomerID ? contractsAndPlan.plan : undefined,
    };
  }

  const netSuiteVisibility = getNetSuiteCustomerIntegrationCardVisibility(
    netSuiteConnectionSettings || {},
    netSuiteEnabled || false,
  );

  const awsIsConnected = !!awsMultiSettings?.length,
    azureIsConnected = !!azureMultiSettings?.length,
    gcpIsConnected = clientConfig?.is_delta_stream_enabled
      ? !!gcpMultiSettings?.length
      : false,
    stripeIsConnected = !!stripeMultiSettings?.length,
    netsuiteIsConnected = netSuiteVisibility.isConnected,
    salesforceIsConnected = !!salesforceConnectionSettings?.connectionDate;

  const bpConfiguredOnPlans =
    (awsIsConnected &&
      awsMultiSettings?.some(
        // settings with a billingProviderCustomerID are connected to a plan
        (setting) => !!setting.billingProviderCustomerID,
      )) ||
    (azureIsConnected &&
      azureMultiSettings?.some(
        (setting) => !!setting.billingProviderCustomerID,
      )) ||
    (stripeIsConnected &&
      stripeMultiSettings?.some(
        (setting) => !!setting.billingProviderCustomerID,
      ));

  // If the customer has their plan connected to another billing provider, we
  // should only allow them to connect contracts to subsequent integrations (
  // specifically marketplaces which support multi-BP). This is because only one
  // billing provider can be connected to a plan at a time.
  const setupNewMarketplaceConfigsOnPlansOrContracts: CustomerSettingsWritePlansAndOrContracts["plansAndOrContracts"] =
    bpConfiguredOnPlans ? "contracts_only" : "plans_and_contracts";

  // Show connected integrations section if any integration is connected
  const showConnectedIntegrations =
    awsIsConnected ||
    azureIsConnected ||
    gcpIsConnected ||
    stripeIsConnected ||
    netsuiteIsConnected ||
    salesforceIsConnected;

  // Show available integrations section always, since we can connect multiple
  const showAvailableIntegrations = true;

  return (
    <div>
      {showConnectedIntegrations && (
        <ConnectedCustomerIntegrationsTable
          customerID={customerID}
          rows={(
            [
              ...(awsMultiSettings ?? []).map(
                (awsSettings) =>
                  ({
                    name: "AWS Marketplace" as const,
                    settings: awsSettings,
                    displayedSettings:
                      awsSettings.customerId &&
                      awsSettings.productCode &&
                      awsSettings.region
                        ? {
                            "Customer ID": awsSettings.customerId,
                            "Product code": awsSettings.productCode,
                            Region: awsSettings.region,
                            "Is subscription product?": {
                              value: awsSettings?.isSubscriptionProduct
                                ? "Yes"
                                : "No",
                              hasBadgeLabel: true as const,
                            },
                            ...(awsSettings.expirationTimestamp
                              ? {
                                  Expiration: {
                                    value: renderDateTimeInUTC(
                                      awsSettings.expirationTimestamp,
                                      false,
                                    ),
                                    hasBadgeLabel: true,
                                  },
                                }
                              : undefined),
                          }
                        : undefined,
                    contractsAndPlan:
                      filterConnectedContractsAndPlan<AWSMarketplaceCustomerSettings>(
                        awsSettings,
                        activeAwsContractsAndPlan,
                      ),
                  }) satisfies ConnectedCustomerIntegrationsTableRowSpec,
              ),
              ...(azureMultiSettings ?? []).map((azureSettings) => ({
                name: "Azure Marketplace" as const,
                settings: azureSettings,
                displayedSettings: {
                  ...(azureSettings.subscriptionId
                    ? {
                        "Subscription ID": azureSettings.subscriptionId,
                      }
                    : undefined),
                  ...(azureSettings.expirationDate
                    ? {
                        Expiration: {
                          value: renderDateTimeInUTC(
                            azureSettings.expirationDate,
                            false,
                          ),
                          hasBadgeLabel: true,
                        },
                      }
                    : undefined),
                },
                contractsAndPlan:
                  filterConnectedContractsAndPlan<AzureMarketplaceCustomerSettings>(
                    azureSettings,
                    activeAzureContractsAndPlan,
                  ),
              })),
              ...(stripeMultiSettings ?? []).map((settings) => ({
                name: "Stripe" as const,
                titleComponent: (
                  <StripeTitleComponent
                    environmentType={environmentType}
                    stripeCustomerId={settings?.customerId ?? null}
                  />
                ),
                settings: settings,
                displayedSettings:
                  settings?.customerId && settings?.collectionMethod
                    ? {
                        "Customer ID": settings?.customerId,
                        "Collection Method": {
                          value:
                            settings?.collectionMethod ===
                            "charge_automatically"
                              ? "Automatic"
                              : settings?.collectionMethod === "send_invoice"
                                ? "Send Invoice"
                                : settings?.collectionMethod,
                          hasBadgeLabel: true as const,
                        },
                      }
                    : undefined,
                contractsAndPlan:
                  filterConnectedContractsAndPlan<StripeCustomerSettings>(
                    settings,
                    activeStripeContractsAndPlan,
                  ),
                onEdit: () => {
                  setStripeSettingsModalOpen({
                    open: true,
                    edit: getEditMode(settings),
                    stripeCustomerId: settings.customerId ?? "",
                    stripeCollectionMethod:
                      settings.collectionMethod ?? "charge_automatically",
                  });
                },
              })),
              ...(clientConfig?.is_delta_stream_enabled
                ? gcpMultiSettings ?? []
                : []
              ).map((gcpSettings) => ({
                name: "GCP Marketplace" as const,
                settings: gcpSettings,
                displayedSettings: undefined,
                contractsAndPlan:
                  filterConnectedContractsAndPlan<StripeCustomerSettings>(
                    gcpSettings,
                    activeGCPContractsAndPlan,
                  ),
                onEdit: () => {},
              })),
              {
                name: "Netsuite" as const,
                settings: netSuiteConnectionSettings,
                displayedSettings:
                  netSuiteConnectionSettings?.netSuiteCustomerId
                    ? {
                        "Customer ID":
                          netSuiteConnectionSettings?.netSuiteCustomerId,
                      }
                    : undefined,
                onEdit: () => setNetsuiteConnectionModalOpen(true),
              },
              {
                name: "Salesforce" as const,
                settings: salesforceConnectionSettings,
                displayedSettings:
                  salesforceConnectionSettings?.salesforceAccountId
                    ? {
                        "Account ID":
                          salesforceConnectionSettings?.salesforceAccountId,
                      }
                    : undefined,
                onEdit: () => setSalesforceConnectionModalOpen(true),
              },
            ] satisfies ConnectedCustomerIntegrationsTableRowSpec[]
          )
            .map(
              (props): ConnectedCustomerIntegrationRow | undefined =>
                props.settings?.connectionDate && {
                  integrationName:
                    props.name as ConnectedCustomerIntegrationRow["integrationName"],
                  integrationDisplayDetails: (
                    <IntegrationDetails
                      integrationName={props.name}
                      displayedSettings={
                        "displayedSettings" in props
                          ? (props?.displayedSettings as IntegrationDetailProps["displayedSettings"])
                          : undefined
                      }
                      isClickable={false}
                      titleComponent={
                        "titleComponent" in props ? props.titleComponent : null
                      }
                      isActive={
                        isIntegrationActive(props) ??
                        // Marketplace delta stream integrations are always "enabled"
                        ((props.name === "AWS Marketplace" ||
                          props.name === "Azure Marketplace" ||
                          props.name === "GCP Marketplace") &&
                          clientConfig?.is_delta_stream_enabled)
                      }
                      supportingText={renderDate(
                        props.settings.connectionDate,
                        {
                          isUtc: false,
                        },
                      )}
                    />
                  ),
                  connectedContractsAndPlan: mapConnectedContractsAndPlan(
                    "contractsAndPlan" in props
                      ? props?.contractsAndPlan
                      : undefined,
                  ),
                  dateConnected: props.settings.connectionDate,
                  onEdit: "onEdit" in props ? props?.onEdit : undefined,
                },
            )
            .filter((row): row is ConnectedCustomerIntegrationRow => !!row)}
        />
      )}

      {showAvailableIntegrations && (
        <div
          className={
            "grid gap-[12px]" + (showConnectedIntegrations ? " mt-[24px]" : "")
          }
        >
          <SectionHeader
            title="Available integrations"
            subtitle="Connect to new upstream and downstream datasets."
          ></SectionHeader>

          {/* Integration Cards */}
          {/* Marketplace integrations can be setup with multiple configs,
            so they are always shown as available */}
          {customerSettingsInfo?.clientHasAws && (
            <AwsIntegrationCard
              className="w-full"
              cardProps={{ wrapContents: false }}
              buttonOptions={{
                buttonOnClick: () => setAWSMarketplaceSettingsModalOpen(true),
                ...(clientConfig?.is_delta_stream_enabled && {
                  isDisabled: true,
                  hasTooltip: true,
                  tooltipContent:
                    getDeltaStreamGatingMessage("AWS Marketplace"),
                }),
              }}
            />
          )}

          {customerSettingsInfo?.clientHasAzure && (
            <AzureIntegrationCard
              className="w-full"
              cardProps={{ wrapContents: false }}
              buttonOptions={{
                buttonOnClick: () => setAzureMarketplaceSettingsModalOpen(true),
                ...(clientConfig?.is_delta_stream_enabled && {
                  isDisabled: true,
                  hasTooltip: true,
                  tooltipContent:
                    getDeltaStreamGatingMessage("Azure Marketplace"),
                }),
              }}
            />
          )}

          {stripeCanBeSetUp(customerSettingsInfo) && (
            <StripeIntegrationCard
              className="w-full"
              cardProps={{ wrapContents: false }}
              buttonOptions={{
                buttonOnClick: () =>
                  setStripeSettingsModalOpen({
                    ...DEFAULT_STRIPE_MODAL_STATE,
                    open: true,
                    stripeCollectionMethod:
                      stripeMultiSettings?.[0]?.collectionMethod,
                    stripeCustomerId: stripeMultiSettings?.[0]?.customerId,
                  }),
              }}
            />
          )}

          {netSuiteVisibility.isAvailable && (
            <BaseIntegrationCard
              logo={netsuiteLogoComponent}
              title="Netsuite"
              subheader="Accounting/Revenue integration"
              description="Send accounting/revenue information to NetSuite"
              className="w-full"
              cardProps={{ wrapContents: false }}
              buttonOptions={{
                buttonOnClick: () => setNetsuiteConnectionModalOpen(true),
              }}
            />
          )}

          {!salesforceIsConnected && (
            <SalesforceIntegrationCard
              className="w-full"
              cardProps={{ wrapContents: false }}
              buttonOptions={{
                buttonOnClick: () => setSalesforceConnectionModalOpen(true),
              }}
            />
          )}

          {/* Setup Integration Modals */}
          {awsMarketplaceSettingsModalOpen && (
            <AWSMarketplaceSettingsModal
              onClose={() => setAWSMarketplaceSettingsModalOpen(false)}
              customerId={customerID}
              plansAndOrContracts={setupNewMarketplaceConfigsOnPlansOrContracts}
            />
          )}

          {azureMarketplaceSettingsModalOpen && (
            <AzureMarketplaceSettingsModal
              onClose={() => setAzureMarketplaceSettingsModalOpen(false)}
              customerID={customerID}
              plansAndOrContracts={setupNewMarketplaceConfigsOnPlansOrContracts}
            />
          )}

          {stripeSettingsModalOpen.open && (
            <StripeSettingsModal
              onClose={(deleteConfig) => {
                setStripeSettingsModalOpen(DEFAULT_STRIPE_MODAL_STATE);
                if (deleteConfig) {
                  setStripeSettingsDeleteModalOpen(true);
                }
              }}
              edit={stripeSettingsModalOpen.edit}
              customerId={customerID}
              stripeCustomerID={stripeSettingsModalOpen.stripeCustomerId}
              stripeCollectionMethod={
                stripeSettingsModalOpen.stripeCollectionMethod
              }
              hasBillingProviderOnPlan={
                !!customerSettingsInfo?.hasBillingProviderOnPlan
              }
              hasStripeOnPlan={
                !!customerSettingsInfo?.hasStripeBillingProviderCustomer
              }
              hasStripeOnContract={
                !!customerSettingsInfo?.hasStripeBillingProviderConfiguration
              }
              hasStripeBillingProviderToken={
                !!customerSettingsInfo?.hasStripeBillingProviderToken
              }
              hasStripeBillingProviderDeliveryMethod={
                !!customerSettingsInfo?.hasStripeBillingProviderDeliveryMethod
              }
            />
          )}

          {stripeSettingsDeleteModalOpen && (
            <DisableStripeModal
              onClose={() => setStripeSettingsDeleteModalOpen(false)}
              customerId={customerID}
            />
          )}

          {netsuiteConnectionModalOpen && (
            <NetSuiteConnectionModal
              isOpen={netsuiteConnectionModalOpen}
              onClose={(deleteConfig) => {
                setNetsuiteConnectionModalOpen(false);
                if (deleteConfig) {
                  setNetsuiteConnectionDeleteModalOpen(true);
                }
              }}
              netSuiteCustomerId={
                netSuiteConnectionSettings?.netSuiteCustomerId
              }
              customerId={customerID}
            />
          )}

          {netsuiteConnectionDeleteModalOpen && (
            <DeleteNetSuiteConnectionModal
              onClose={() => setNetsuiteConnectionDeleteModalOpen(false)}
              isOpen={netsuiteConnectionDeleteModalOpen}
              customerId={customerID}
            />
          )}

          {salesforceConnectionModalOpen && (
            <SalesforceConnectionModal
              isOpen={salesforceConnectionModalOpen}
              onClose={(deleteConfig) => {
                setSalesforceConnectionModalOpen(false);
                if (deleteConfig) {
                  setSalesforceConnectionDeleteModalOpen(true);
                }
              }}
              salesforceAccountId={
                salesforceConnectionSettings?.salesforceAccountId
              }
              customerId={customerID}
            />
          )}

          {salesforceConnectionDeleteModalOpen && (
            <DeleteSalesforceConnectionModal
              isOpen={salesforceConnectionDeleteModalOpen}
              onClose={() => setSalesforceConnectionDeleteModalOpen(false)}
              customerId={customerID}
            />
          )}
        </div>
      )}
    </div>
  );
};

// Because we've made our resolvers and endpoints do an upsert for Confluent,
// we're currently limited to at most one stripe contract configuration per customer.
// They can also have a configuration for plans that may or may not match.
// Moving away from this would require a breaking change to endpoints that confluent
// uses.
export function stripeCanBeSetUp(
  customerSettings: CustomerIntegrationSettingsInfo | undefined,
) {
  if (!customerSettings) {
    return false;
  }

  const {
    hasStripeBillingProviderToken,
    hasStripeBillingProviderDeliveryMethod,
    hasStripeBillingProviderConfiguration,
    hasStripeBillingProviderCustomer,
    hasBillingProviderOnPlan,
  } = customerSettings;

  if (hasStripeBillingProviderToken && hasStripeBillingProviderDeliveryMethod) {
    const stripeIsUnavailable =
      (hasStripeBillingProviderConfiguration && hasBillingProviderOnPlan) ||
      (hasStripeBillingProviderCustomer &&
        hasStripeBillingProviderConfiguration);
    return !stripeIsUnavailable;
  } else if (hasStripeBillingProviderToken) {
    return !hasStripeBillingProviderCustomer;
  } else if (hasStripeBillingProviderDeliveryMethod) {
    return !hasStripeBillingProviderConfiguration;
  } else {
    return false;
  }
}

// It's possible for a customer to have a different stripe configuration for plans
// and contracts if they were set up via the API. They cannot have more than one
// contract configuration. If they choose to edit one of those configurations in the UI,
// this makes sure we edit the correct one.
function getEditMode(
  settings: BPCustomerSettings<StripeCustomerSettings>,
): PlansAndOrContracts | false {
  if (
    settings.customerBillingProviderConfigurationID &&
    settings.billingProviderCustomerID
  ) {
    return PlansAndOrContracts.plansAndContracts;
  } else if (settings.customerBillingProviderConfigurationID) {
    return PlansAndOrContracts.contractsOnly;
  } else if (settings.billingProviderCustomerID) {
    return PlansAndOrContracts.plansOnly;
  } else {
    // don't edit
    return false;
  }
}

type ConnectedContractOrPlan = {
  id: string;
  name: string;
  contractOrPlan: "plan" | "contract";
};

interface ConnectedCustomerIntegrationRow {
  integrationName:
    | "Stripe"
    | "AWS Marketplace"
    | "Azure Marketplace"
    | "Netsuite"
    | "Salesforce";
  titleComponent?: React.ReactElement;
  integrationDisplayDetails: React.JSX.Element;
  connectedContractsAndPlan: ConnectedContractOrPlan[] | undefined;
  dateConnected: Date;
  onEdit?: () => void;
  plansOnly?: boolean;
}

interface ConnectedCustomerIntegrationsTableProps {
  rows: ConnectedCustomerIntegrationRow[];
  customerID: string;
  plansOnly?: boolean;
}

const ConnectedCustomerIntegrationsTable = ({
  customerID,
  rows,
  ...tableProps
}: ConnectedCustomerIntegrationsTableProps) => {
  const tableColumns: Column<
    ConnectedCustomerIntegrationRow & { id: string }
  >[] = [
    {
      id: "Integration",
      accessorKey: "integrationDisplayDetails",
      header: "Integration",
      cell: (props: { getValue: () => React.ReactElement }) => {
        return props.getValue();
      },
      tooltipContent: "Integrations connected to this customer",
      enableSorting: false,
    },
    {
      id: "Connected date",
      accessorKey: "dateConnected",
      header: "Connected date (UTC)",
      cell: (props: { getValue: () => Date }) => {
        return renderDateTimeInUTC(props.getValue(), false, true);
      },
      tooltipContent: "Date the integration got connected to this customer",
      enableSorting: false,
    },
    {
      id: "Overflow",
      header: "",
      accessorFn: (row) => row,
      cell: (props: { getValue: () => ConnectedCustomerIntegrationRow }) => (
        <ConnectedCustomerIntegrationRowActionButtons
          {...props.getValue()}
          plansOnly={tableProps?.plansOnly}
          customerID={customerID}
        />
      ),
    },
  ];

  const rowsWithId = rows.map((row, index) => ({
    ...row,
    id: index.toString(),
  }));
  return (
    <Table
      title="Connected integrations"
      columns={tableColumns}
      data={rowsWithId}
      useFixedRowHeight={false}
      emptyState={
        <EmptyState
          icon="faceSad"
          mainText="No integrations connected"
          className="h-[240px] w-full"
        />
      }
    />
  );
};

const ConnectedCustomerIntegrationRowActionButtons: React.FC<
  ConnectedCustomerIntegrationRow & { customerID: string }
> = ({
  integrationName,
  connectedContractsAndPlan,
  onEdit,
  customerID,
  plansOnly,
}) => {
  const navigate = useNavigate();
  const { mode: uiMode } = useUIMode();
  const editDropdownItem = (
    <DropdownItem
      label={`Edit ${integrationName}`}
      value={`Edit ${integrationName}`}
      disabled={!onEdit}
      onClick={onEdit ? onEdit : () => {}}
    />
  );
  const noEditTooltipLabel =
    `Editing ${integrationName} customer configuration is not supported.` +
    (plansOnly ? "" : ` Setup a new configuration to attach to new contracts.`);
  return (
    <Dropdown icon="dotsVertical">
      <DropdownItem
        label="View integration"
        value="View integration"
        linkTo="/connections/integrations"
      />
      {!onEdit ? (
        <Tooltip label={noEditTooltipLabel}>{editDropdownItem}</Tooltip>
      ) : (
        editDropdownItem
      )}
      {!!connectedContractsAndPlan?.length && (
        <DropdownHeader
          text={`Connected ${contractOrPlanText({ pluralize: "contracts-only", conjunctive: "/", uiMode })}`}
        />
      )}
      {connectedContractsAndPlan?.map((contractOrPlan) => (
        <DropdownItem
          key={"contract-or-plan-" + contractOrPlan.id}
          label={contractOrPlan.name}
          value={contractOrPlan.name}
          icon="arrowUpRight"
          onClick={() =>
            navigate(
              `/customers/${customerID}/${contractOrPlan.contractOrPlan}s/${contractOrPlan.id}`,
            )
          }
        />
      ))}
    </Dropdown>
  );
};

export const getDeltaStreamGatingMessage = (integrationName: string) =>
  `${integrationName} cannot be configured in the Metronome UI for clients with active Delta Stream integrations.`;

interface StripeTitleComponentProps {
  environmentType: EnvironmentTypeEnum_Enum;
  stripeCustomerId: string | null;
}

const StripeTitleComponent: React.FC<StripeTitleComponentProps> = ({
  environmentType,
  stripeCustomerId,
}) => {
  if (stripeCustomerId) {
    return (
      <Tooltip label="Go to Stripe profile">
        <Link
          to={`https://dashboard.stripe.com${
            environmentType !== EnvironmentTypeEnum_Enum.Production
              ? "/test"
              : ""
          }/customers/${stripeCustomerId}`}
          className="flex items-center"
          target="_blank"
        >
          <Icon icon="share04" size={16} className="mr-sm" />
          Stripe
        </Link>
      </Tooltip>
    );
  } else {
    return <>Stripe</>;
  }
};
