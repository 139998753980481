import React from "react";
import { DocumentNode } from "graphql";

import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { PageContainer } from "components/PageContainer";

interface ProductsContainerProps extends React.PropsWithChildren {
  action: React.ReactNode;
  authDoc: DocumentNode;
}

export const ProductsContainer: React.FC<ProductsContainerProps> = (props) => {
  const contractsEnabled = useContractsEnabled();

  return (
    <PageContainer
      title={contractsEnabled ? "Plans overview" : "Products"}
      action={contractsEnabled ? null : props.action}
      tabsAction={contractsEnabled ? props.action : null}
      authDoc={props.authDoc}
      tabs={
        contractsEnabled
          ? [
              {
                name: "Plans",
                active: false,
                routePath: "/plans",
              },
              {
                name: "Products",
                active: true,
                routePath: "/products",
              },
            ]
          : undefined
      }
    >
      {props.children}
    </PageContainer>
  );
};
