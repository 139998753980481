import { Dayjs, distanceFrom, toDayjs } from "lib/date";
import { Contract } from "../Contract";
import { CustomerPlan } from "../CustomerPlan";
import {
  CustomerListContractStatusFragment,
  CustomerListPlanStatusFragment,
} from "../Customer/fragments.graphql";

export type CustomerStatusFragment =
  | CustomerListContractStatusFragment
  | CustomerListPlanStatusFragment;

export type StatusFragment =
  | Contract.StatusFragment
  | CustomerPlan.StatusFragment;
export type NameFragment = Contract.NameFragment | CustomerPlan.NameFragment;
export type RoutePathFragment =
  | Contract.RoutePathFragment
  | CustomerPlan.RoutePathFragment;
export type ActivityFragment =
  | Contract.ActivityFragment
  | CustomerPlan.ActivityFragment;
export type BillingProviderFragment =
  | Contract.BillingProviderFragment
  | CustomerPlan.BillingProviderFragment;

export type Status =
  | "upcoming"
  | "active-soon"
  | "active"
  | "archived"
  | "ending-soon"
  | "recently-ended"
  | "ended"
  | "inactive (superseded)"
  | "inactive (renewed)";

function fork<
  ContractFragment extends { __typename?: "Contract" },
  PlanFragment extends { __typename?: "CustomerPlan" },
  Args extends any[],
  ContractResult,
  PlanResult,
>(
  item: ContractFragment | PlanFragment,
  contractFn: (item: ContractFragment, ...args: Args) => ContractResult,
  planFn: (item: PlanFragment, ...args: Args) => PlanResult,
  ...args: Args
): ContractResult | PlanResult {
  switch (item.__typename) {
    case "Contract":
      return contractFn(item, ...args);
    case "CustomerPlan":
      return planFn(item, ...args);
    default:
      throw new Error(`invalid __typename ${item.__typename}`);
  }
}

export function getName(item: NameFragment): string {
  return fork(item, Contract.getName, CustomerPlan.getName);
}

export function getRoutePath(item: RoutePathFragment): string {
  return fork(item, Contract.getRoutePath, CustomerPlan.getRoutePath);
}

export function getActivityItems(item: ActivityFragment, now: Dayjs) {
  return fork(
    item,
    Contract.getActivityItems,
    CustomerPlan.getActivityItems,
    now,
  );
}

export function getCustomerStatus(item: CustomerStatusFragment, now: Dayjs) {
  if (item.__typename === "CustomerContractStatus" && item.archived_at) {
    return "archived";
  }

  const start = distanceFrom(now, toDayjs(item.starting_at));
  const end = item.ending_before
    ? distanceFrom(now, toDayjs(item.ending_before))
    : null;

  if (!start.isInPast) {
    return start.distDays >= 30 ? "upcoming" : "active-soon";
  }

  if (end?.isInPast) {
    return end.distDays <= 7 ? "recently-ended" : "ended";
  }

  return end && end?.distDays <= 30 ? "ending-soon" : "active";
}

export function getStatus(item: StatusFragment, now: Dayjs) {
  return fork(item, Contract.getStatus, CustomerPlan.getStatus, now);
}

export function getBillingProvider(item: BillingProviderFragment) {
  return fork(
    item,
    Contract.getBillingProvider,
    CustomerPlan.getBillingProvider,
  );
}

export function getRateCardName(item: NameFragment): string {
  switch (item.__typename) {
    case "Contract":
      return item.rate_card?.name || "--";
    case "CustomerPlan":
    default:
      return "--";
  }
}

export function getStartDate(item: StatusFragment): string {
  switch (item.__typename) {
    case "Contract":
      return item.starting_at;
    case "CustomerPlan":
      return item.start_date;
    default:
      throw new Error(`invalid __typename "${item.__typename}"`);
  }
}

export function getEndDate(item: StatusFragment): string | null {
  switch (item.__typename) {
    case "Contract":
      return item.ending_before;
    case "CustomerPlan":
      return item.cancellation_date;
    default:
      throw new Error(`invalid __typename "${item.__typename}"`);
  }
}

export function isActive(item: StatusFragment, now: Dayjs): boolean {
  switch (getStatus(item, now)) {
    case "active":
    case "ending-soon":
      return true;
    default:
      return false;
  }
}

export function isActiveCustomerStatus(
  item: CustomerStatusFragment,
  now: Dayjs,
): boolean {
  switch (getCustomerStatus(item, now)) {
    case "active":
    case "ending-soon":
      return true;
    default:
      return false;
  }
}
