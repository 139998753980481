import classnames from "classnames";
import { BillingMetricAggregateEnum_Enum } from "types/generated-graphql/__types__";
import styles from "./index.module.less";
import React from "react";
import { Caption } from "design-system";
import { HelpCircleTooltip } from "design-system";
import type { JSONSchema7 } from "json-schema";
import { Condition } from "@metronome-industries/json-schema-conditions";
import {
  AggregationBadge,
  GroupKeyBadge,
} from "components/BillableMetricsBadge";
import { ConditionsDisplay, JSONSchemaDisplay } from "components/jsonSchema";

const DesignLabel: React.FC<{ helpText: string; captionText: string }> = ({
  helpText,
  captionText,
}) => {
  return (
    <div className={styles.designLabel}>
      <Caption level={1} className={styles.designCaption}>
        {captionText}
      </Caption>
      <HelpCircleTooltip content={helpText} />
    </div>
  );
};

type DefinitionDisplayProps = {
  aggregate?: BillingMetricAggregateEnum_Enum;
  aggregateKeys?: string[];
  groupKeys?: Set<string>[];
  filter?: JSONSchema7;
  conditions?: Condition[];
  vertical?: boolean;
  allowGroupKeys?: boolean;
  rawSql?: string;
};

export const DefinitionDisplay: React.FC<DefinitionDisplayProps> = ({
  aggregate,
  aggregateKeys,
  groupKeys,
  filter,
  conditions,
  vertical,
  allowGroupKeys,
  rawSql,
}) => {
  return (
    <div
      className={classnames(styles.designContainer, {
        "inline-block": rawSql,
      })}
    >
      <div
        className={classnames(styles.designTopArea, {
          [styles.vertical]: vertical,
        })}
      >
        {rawSql && (
          <span className="whitespace-pre-wrap pb-8 font-mono text-xs leading-1 text-gray-800">
            {rawSql}
          </span>
        )}
        {/* Aggregate */}
        {aggregate && (
          <div className={styles.designSection}>
            <DesignLabel
              helpText="Each metric specifies an aggregator and, with the exception of count, a single property to aggregate over. Metronome currently supports the following aggregators: count, sum, max, and unique."
              captionText="Aggregate"
            />
            <AggregationBadge aggregation={aggregate} keys={aggregateKeys} />
          </div>
        )}

        {/* Group Keys */}
        {allowGroupKeys && (
          <div className={styles.designSection}>
            <DesignLabel
              helpText="A metric can optionally specify one or more properties to group by. This functions similarly to a group by clause in a SQL query. Groups don't affect pricing, but they can be displayed on invoices."
              captionText="Group_Keys"
            />
            {groupKeys ? (
              groupKeys.map((field, index) => (
                <GroupKeyBadge key={index} fieldName={field} />
              ))
            ) : (
              <GroupKeyBadge />
            )}
          </div>
        )}
      </div>

      {/* Filters */}
      {!rawSql && (
        <div className={styles.filterArea}>
          <DesignLabel
            helpText="Only usage events matching all filters will be aggregated."
            captionText="Filters"
          />
          {conditions ? (
            <ConditionsDisplay conditions={conditions} />
          ) : (
            <JSONSchemaDisplay jsonSchema={filter as JSONSchema7} />
          )}
        </div>
      )}
    </div>
  );
};
