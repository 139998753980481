/* 02a8f7b1d8c6328217cb65c6271f4989dfc4b231
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillingProviderInvoiceQueryVariables = Types.Exact<{
  snapshot_start: Types.Scalars['timestamptz'];
  snapshot_end: Types.Scalars['timestamptz'];
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type BillingProviderInvoiceQuery = { __typename?: 'Query', BillingProviderInvoice: Array<{ __typename?: 'BillingProviderInvoice', billing_provider: Types.BillingProviderEnum_Enum, billing_provider_error: string | null, billing_provider_invoice_id: string | null, due_date: string | null, external_status: Types.InvoiceExternalStatusEnum_Enum, id: string, invoice_id: string, issued_at: string | null, customer: { __typename?: 'Customer', id: string } | null }> };


export const BillingProviderInvoiceDocument = gql`
    query BillingProviderInvoice($snapshot_start: timestamptz!, $snapshot_end: timestamptz!, $limit: Int!, $cursor: uuid) {
  BillingProviderInvoice(
    order_by: {id: asc}
    limit: $limit
    where: {id: {_gt: $cursor}, issued_at: {_lte: $snapshot_end, _gte: $snapshot_start}}
  ) {
    billing_provider
    billing_provider_error
    billing_provider_invoice_id
    due_date
    external_status
    id
    invoice_id
    issued_at
    customer {
      id
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useBillingProviderInvoiceQuery__
 *
 * To run a query within a React component, call `useBillingProviderInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingProviderInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingProviderInvoiceQuery({
 *   variables: {
 *      snapshot_start: // value for 'snapshot_start'
 *      snapshot_end: // value for 'snapshot_end'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useBillingProviderInvoiceQuery(baseOptions: Apollo.QueryHookOptions<BillingProviderInvoiceQuery, BillingProviderInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingProviderInvoiceQuery, BillingProviderInvoiceQueryVariables>(BillingProviderInvoiceDocument, options);
      }
export function useBillingProviderInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingProviderInvoiceQuery, BillingProviderInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingProviderInvoiceQuery, BillingProviderInvoiceQueryVariables>(BillingProviderInvoiceDocument, options);
        }
export type BillingProviderInvoiceQueryHookResult = ReturnType<typeof useBillingProviderInvoiceQuery>;
export type BillingProviderInvoiceLazyQueryHookResult = ReturnType<typeof useBillingProviderInvoiceLazyQuery>;
export type BillingProviderInvoiceQueryResult = Apollo.QueryResult<BillingProviderInvoiceQuery, BillingProviderInvoiceQueryVariables>;