import React, { SVGProps } from "react";

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 12.5C0 5.596 5.596 0 12.5 0h175c6.904 0 12.5 5.596 12.5 12.5v175c0 6.904-5.596 12.5-12.5 12.5h-175C5.596 200 0 194.404 0 187.5v-175Z"
      fill="#0F2027"
    />
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.5 60.938H50v78.125h12.5V60.938Zm21.875 9.374h-12.5v40.626h12.5V70.312Zm9.375 28.126h12.5v40.625h-12.5V98.438Zm34.375-12.5h-12.5v40.625h12.5V85.937Zm9.375-25H150v78.125h-12.5V60.938Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(50 60.938)"
          d="M0 0h100v78.125H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export { Logo };
