import React, { useMemo, useReducer, useState } from "react";
import { useNavigate } from "lib/useNavigate";
import {
  Input,
  Select,
  Body,
  Caption,
  Headline,
  Hyperlink,
  Subtitle,
  HelpCircleTooltip,
} from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { ToggleButtons } from "components/ToggleButtons";
import { AppShell, PageContainer } from "components/PageContainer";
import { Table } from "components/Table";
import { Tooltip } from "design-system";
import { GroupInfo } from "components/GroupInfo";
import {
  useListBillableMetricsQuery,
  useNewProductMutation,
} from "./queries.graphql";
import {
  ChargeTypeEnum_Enum,
  ProductPricingFactor_Insert_Input,
} from "types/generated-graphql/__types__";
import { useEnvironment } from "lib/environmentSwitcher/context";
import {
  BillableMetric,
  NewProductAction,
  NewProductState,
  SeatMetric,
} from "./types";
import { SelectBillableMetricsSection } from "./components/SelectBillableMetricsSection";
import { ProductInfoSection } from "./components/ProductInfoSection";
import { useFeatureFlag } from "lib/launchdarkly";
import { useUIMode } from "../../lib/useUIMode";
import { useDocsLink } from "lib/docs-link";

const reducer: React.Reducer<NewProductState, NewProductAction> = (
  state,
  action,
) => {
  const recalculateGroupKeys = () => {
    state.selectedBillableMetricIntersectingGroupKeys = [];
    Object.values(state.metrics).forEach((metric, i) => {
      if (!metric.groupKeys) {
        state.selectedBillableMetricIntersectingGroupKeys = [];
      } else {
        if (i === 0) {
          state.selectedBillableMetricIntersectingGroupKeys = [
            ...metric.groupKeys,
          ];
        } else {
          state.selectedBillableMetricIntersectingGroupKeys =
            state.selectedBillableMetricIntersectingGroupKeys.filter(
              (groupKey) => metric.groupKeys.includes(groupKey),
            );
        }
      }
    });
    if (state.selectedBillableMetricIntersectingGroupKeys.length === 0) {
      state.groupByGroupKey = undefined;
      state.selectedGroupKey = undefined;
    }
    if (
      state.selectedGroupKey &&
      !state.selectedBillableMetricIntersectingGroupKeys.includes(
        state.selectedGroupKey,
      )
    ) {
      state.selectedGroupKey = undefined;
    }
  };

  switch (action.type) {
    case "SELECT_ALL_METRICS":
      action.data.forEach((m) => {
        if (!state.metrics[m.metricId]) {
          state.metrics[m.metricId] = {
            type: ChargeTypeEnum_Enum.Usage,
            name: m.name,
            displayName: m.name,
            metricId: m.metricId,
            groupKeys: m.groupKeys,
          };
        }
      });
      recalculateGroupKeys();
      break;
    case "DESELECT_ALL_METRICS":
      action.data.forEach((m) => {
        if (state.metrics[m.metricId]) {
          delete state.metrics[m.metricId];
        }
      });
      recalculateGroupKeys();
      break;
    case "SELECT_ALL_SEATS":
      action.data.forEach((m) => {
        if (!state.seats[m.metricId]) {
          state.seats[m.metricId] = {
            type: ChargeTypeEnum_Enum.Seat,
            name: m.name,
            displayName: m.name,
            metricId: m.metricId,
          };
        }
      });
      break;
    case "DESELECT_ALL_SEATS":
      action.data.forEach((m) => {
        if (state.seats[m.metricId]) {
          delete state.seats[m.metricId];
        }
      });
      break;
    case "ADD_METRIC":
      state.metrics[action.data.metricId] = {
        type: ChargeTypeEnum_Enum.Usage,
        name: action.data.name,
        displayName: action.data.name,
        metricId: action.data.metricId,
        groupKeys: action.data.groupKeys,
      };
      recalculateGroupKeys();
      break;
    case "REMOVE_METRIC":
      delete state.metrics[action.data.metricId];
      recalculateGroupKeys();
      break;
    case "EDIT_METRIC_NAME":
      // check if seat or metric
      if (state.metrics.hasOwnProperty(action.data.metricId)) {
        state.metrics[action.data.metricId] = {
          ...state.metrics[action.data.metricId],
          displayName: action.data.displayName,
        };
      } else {
        state.seats[action.data.metricId] = {
          ...state.seats[action.data.metricId],
          displayName: action.data.displayName,
        };
      }
      state.editingMetric = undefined;
      break;
    case "ADD_SEAT":
      state.seats[action.data.metricId] = {
        type: ChargeTypeEnum_Enum.Seat,
        name: action.data.name,
        displayName: action.data.name,
        metricId: action.data.metricId,
      };
      break;
    case "REMOVE_SEAT":
      delete state.seats[action.data.metricId];
      break;
    case "ADD_FEE":
      state.fees.push({
        type: ChargeTypeEnum_Enum.Flat,
        name: "",
      });
      break;
    case "REMOVE_FEE":
      const { index } = action.data;
      if (state.fees.length === 1 || index === state.fees.length - 1) {
        state.fees[index].name = "";
      } else {
        state.fees.splice(index, 1);
      }
      break;
    case "EDIT_FEE_NAME":
      state.fees[action.data.index].name = action.data.name;
      break;
    case "SELECT_CHARGE_TYPE":
      state.fees[action.data.index].type = action.data.chargeType;
      break;
    case "SELECT_YES_GROUPS":
      state.groupByGroupKey = true;
      if (state.selectedBillableMetricIntersectingGroupKeys.length === 1) {
        state.selectedGroupKey =
          state.selectedBillableMetricIntersectingGroupKeys[0];
      }
      break;
    case "SELECT_NO_GROUPS":
      state.groupByGroupKey = false;
      state.selectedGroupKey = undefined;
      break;
    case "SELECT_GROUP_KEY":
      state.selectedGroupKey = action.data.groupKey;
      break;
    case "CHANGE_EDITING_METRIC":
      state.editingMetric = action.data.metricId;
      break;
    default:
      return state;
  }
  return { ...state };
};
type GroupKeySectionProps = {
  groupByGroupKey: boolean | undefined;
  selectedGroupKey: string | undefined;
  intersectingGroupKeys: string[];
  onChangeShouldGroup: (v: string) => void;
  onChangeGroupKey: (v: string) => void;
};

const GroupKeySection: React.FC<GroupKeySectionProps> = (props) => {
  const hasOneIntersectingGroupKey = props.intersectingGroupKeys.length === 1;
  const hasMultipleIntersectingGroupKeys =
    props.intersectingGroupKeys.length > 1;

  const groupedMetricsDocs = useDocsLink({
    plansPath:
      "invoicing/how-billing-works/set-up-billable-metrics/#grouped-metrics",
  });

  return (
    <div className="mb-12">
      <Body level={2}>
        Because all the selected billable metrics have a common group key
        {hasOneIntersectingGroupKey && (
          <>
            {" "}
            (<GroupInfo groupKey={props.intersectingGroupKeys[0]} />)
          </>
        )}
        , you have the option of showing a grouped view of this product.
        Invoices will then show one line item per group.{" "}
        <Hyperlink target="_blank" routePath={groupedMetricsDocs}>
          Learn more about group keys.
        </Hyperlink>
      </Body>
      <Body level={2}>
        Use this toggle to switch between the grouped and ungrouped views. If
        you plan to use tiered pricing for this product, select{" "}
        <strong>Ungrouped</strong>.
      </Body>
      <div className="flex items-baseline gap-4">
        <Body level={2}>Choose a view:</Body>
        <ToggleButtons
          value={
            props.groupByGroupKey === undefined
              ? undefined
              : props.groupByGroupKey === true
                ? "grouped"
                : "ungrouped"
          }
          defaultButtonProps={{
            onChange: props.onChangeShouldGroup,
            useGreyBackground: true,
          }}
          buttonProps={[
            {
              value: "grouped",
              label: "Grouped",
            },
            {
              value: "ungrouped",
              label: "Ungrouped",
            },
          ]}
        />
      </div>
      {hasMultipleIntersectingGroupKeys && (
        <div className="w-[208px]">
          <Select
            options={props.intersectingGroupKeys.map((groupKey) => ({
              label: groupKey,
              value: groupKey,
            }))}
            onChange={props.onChangeGroupKey}
            value={props.selectedGroupKey ?? ""}
            placeholder="Select"
            name="Select a Group Key"
            disabled={!props.groupByGroupKey}
          />
        </div>
      )}
    </div>
  );
};

export type ChargeGroupRow = {
  type: "group";
  groupKey?: string;
  subRows: ChargeRow[];
};
export interface ChargeRow {
  name: string;
  metricName?: string;
  chargeId: string;
  type: ChargeTypeEnum_Enum;
  subRows: null;
}

export interface CompositeChargeRow extends ChargeRow {
  type: ChargeTypeEnum_Enum.Composite;
}
export interface FixedChargeRow extends ChargeRow {
  type: ChargeTypeEnum_Enum.Flat;
}
export interface UsageChargeRow extends ChargeRow {
  type: ChargeTypeEnum_Enum.Usage;
  metricName: string;
}

type PreviewChargesTableProps = {
  productName: string | null;
  data: Array<ChargeGroupRow | ChargeRow>;
  editingMetricId: string | undefined;
  onSetEditingMetricId: (newId: string | undefined) => void;
  onSaveName: (newName: string, chargeId: string) => void;
  allowEditChargeNames?: boolean;
  onUpdateChargeOrder?: (chargeId: string, direction: "up" | "down") => void;
};
export const PreviewChargesTable: React.FC<PreviewChargesTableProps> = ({
  productName,
  data,
  editingMetricId,
  onSetEditingMetricId,
  onSaveName,
  allowEditChargeNames,
  onUpdateChargeOrder,
}) => {
  const hasGroupedCharges = data.some((row) => !!row.subRows);
  return (
    <div className="rounded-large border border-grey-100">
      <div className="flex bg-primary-50 px-12 pb-12 pt-8 text-primary-700">
        <Headline
          level={5}
          className={["text-primary-700", !productName ? "italic" : ""].join(
            " ",
          )}
        >
          {productName ?? "Enter product name"}
        </Headline>
      </div>
      <div className="px-12">
        <Table
          noPageReset={true}
          nestedRows={hasGroupedCharges ? "visible" : undefined}
          data={data}
          columns={[
            {
              header: "Charges",
              id: "name",
              render: (row: ChargeGroupRow | ChargeRow) => {
                if (row.type === "group") {
                  if ("groupKey" in row && row.groupKey) {
                    return (
                      <Subtitle level={1}>
                        <GroupInfo
                          groupKey={row.groupKey}
                          groupValue="<GROUP_VALUE>"
                        />
                      </Subtitle>
                    );
                  } else {
                    return (
                      <Subtitle level={1}>Fixed and composite charges</Subtitle>
                    );
                  }
                } else if (
                  row.type === ChargeTypeEnum_Enum.Usage ||
                  row.type === ChargeTypeEnum_Enum.Seat
                ) {
                  return (
                    <MetricNameCell
                      metricId={row.chargeId}
                      metricDisplayName={row.name}
                      metricName={(row as UsageChargeRow).metricName}
                      isEditing={row.chargeId === editingMetricId}
                      setEditingMetricId={onSetEditingMetricId}
                      saveName={(newName: string) =>
                        onSaveName(newName, row.chargeId)
                      }
                    />
                  );
                } else if (allowEditChargeNames) {
                  return (
                    <MetricNameCell
                      metricId={row.chargeId}
                      metricDisplayName={row.name}
                      metricName={row.name}
                      isEditing={row.chargeId === editingMetricId}
                      setEditingMetricId={onSetEditingMetricId}
                      saveName={(newName: string) =>
                        onSaveName(newName, row.chargeId)
                      }
                    />
                  );
                }
                return <Subtitle level={2}>{row.name}</Subtitle>;
              },
            },
            {
              header: "Type",
              id: "type",
              render: (row) =>
                row.subRows
                  ? ""
                  : row.type === ChargeTypeEnum_Enum.Flat
                    ? "Fixed"
                    : row.type === ChargeTypeEnum_Enum.Composite
                      ? "Composite"
                      : row.type === ChargeTypeEnum_Enum.Seat
                        ? "Seat"
                        : "Usage-based",
            },
            {
              header: "Order",
              id: "order",
              disabled: !onUpdateChargeOrder,
              render: (row) => {
                if (row.type === "group" || !onUpdateChargeOrder) {
                  return null;
                } else {
                  return (
                    <div className="ml-8 flex flex-col gap-4">
                      <IconButton
                        className="h-[20px] w-[20px] stroke-[currentColor]"
                        onClick={() => onUpdateChargeOrder(row.chargeId, "up")}
                        theme="tertiary"
                        icon="chevronUp"
                      />
                      <IconButton
                        className="h-[20px] w-[20px] stroke-[currentColor]"
                        onClick={() =>
                          onUpdateChargeOrder(row.chargeId, "down")
                        }
                        theme="tertiary"
                        icon="chevronDown"
                      />
                    </div>
                  );
                }
              },
            },
          ]}
          emptyState={
            <div className="-mx-12 flex h-[130px] items-center justify-center border-t border-t-grey-100 px-12">
              <Caption level={2}>
                Start by selecting charges associated with your product
              </Caption>
            </div>
          }
        />
      </div>
    </div>
  );
};

const NewProduct: React.FC = () => {
  const seatsEnabled = useFeatureFlag("seats", false);
  const { environmentType } = useEnvironment();
  const { data } = useListBillableMetricsQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const [createProduct, { loading: submitLoading }] = useNewProductMutation({
    update(cache) {
      cache.evict({ fieldName: "products" });
      // If this was the first product using a billable metric, that metric
      // must now be refetched to learn that it's not archiveable.
      cache.evict({ fieldName: "billable_metrics" });
    },
  });
  const [name, setName] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [state, dispatch] = useReducer(reducer, {
    metrics: {},
    seats: {},
    fees: [{ type: ChargeTypeEnum_Enum.Flat, name: "" }],
    selectedGroupKey: undefined,
    groupByGroupKey: undefined,
    selectedBillableMetricIntersectingGroupKeys: [],
  });
  const navigate = useNavigate();
  const allMetrics = [...(data?.billable_metrics || [])]
    .filter((metric) => metric.sql === null)
    .sort((a, b) => (a.name < b.name ? -1 : 1)) as {
    id: string;
    name: string;
    group_keys: string[];
    sql: null;
  }[];

  const allSeats = [...(data?.seat_metrics?.metrics || [])].sort((a, b) =>
    a.name < b.name ? -1 : 1,
  ) as {
    id: string;
    name: string;
  }[];

  const addFlatFee = () => {
    dispatch({ type: "ADD_FEE" });
  };

  const removeFlatFee = (index: number) => {
    dispatch({ type: "REMOVE_FEE", data: { index } });
  };

  const selectAllMetrics = (billableMetrics: BillableMetric[]) => {
    dispatch({
      type: "SELECT_ALL_METRICS",
      data: billableMetrics.map((m) => ({
        metricId: m.id,
        name: m.name,
        groupKeys: (m.group_keys ?? []) as string[],
      })),
    });
  };

  const deselectAllMetrics = (billableMetrics: BillableMetric[]) => {
    dispatch({
      type: "DESELECT_ALL_METRICS",
      data: billableMetrics.map((m) => ({
        metricId: m.id,
        name: m.name,
      })),
    });
  };

  const selectAllSeats = (seats: SeatMetric[]) => {
    dispatch({
      type: "SELECT_ALL_SEATS",
      data: seats.map((m) => ({
        metricId: m.id,
        name: m.name,
      })),
    });
  };

  const deselectAllSeats = (seats: SeatMetric[]) => {
    dispatch({
      type: "DESELECT_ALL_SEATS",
      data: seats.map((m) => ({
        metricId: m.id,
        name: m.name,
      })),
    });
  };

  const { newUIEnabled } = useUIMode();
  const saveProduct = async () => {
    if (name && description) {
      const metricValues = Object.values(state.metrics);
      const pricingFactors: ProductPricingFactor_Insert_Input[] = metricValues
        .map<ProductPricingFactor_Insert_Input>((m, i) => ({
          billable_metric_id: m.metricId,
          name: m.displayName,
          ordering: i,
          charge_type_enum: ChargeTypeEnum_Enum.Usage,
        }))
        .concat(
          Object.values(state.seats).map<ProductPricingFactor_Insert_Input>(
            (m, i) => ({
              seat_metric_id: m.metricId,
              name: m.displayName,
              ordering: metricValues.length + i,
              charge_type_enum: ChargeTypeEnum_Enum.Seat,
            }),
          ),
        )
        .concat(
          state.fees
            .filter((f) => f.name.length > 0)
            .map((f) => ({ name: f.name, charge_type_enum: f.type })),
        );
      await createProduct({
        variables: {
          name: name,
          description: description,
          pricing_factors: {
            data: pricingFactors.map((pf, i) => ({ ...pf, ordering: i })),
          },
          group_key: state.selectedGroupKey,
        },
      });
      navigate(`${newUIEnabled ? "/offering/plans" : ""}/products`);
    }
  };

  const isStateValid = !(
    name &&
    description &&
    (state.fees.filter((f) => f.name.length > 0).length > 0 ||
      Object.keys(state.seats).length > 0 ||
      Object.keys(state.metrics).length > 0) &&
    // no common group keys between billable metric(s)
    (state.selectedBillableMetricIntersectingGroupKeys.length === 0 ||
      // selected 'no' to grouping
      state.groupByGroupKey === false ||
      // selected a group key
      (state.groupByGroupKey && state.selectedGroupKey))
  );

  const generateGroupedChargeRows = () => {
    const metrics = Object.values(state.metrics);
    if (state.selectedGroupKey) {
      return [
        {
          type: "group" as "group",
          groupKey: state.selectedGroupKey,
          subRows: metrics.map((metric) => ({
            name: metric.displayName ?? metric.name,
            metricName: metric.name,
            chargeId: metric.metricId,
            type: ChargeTypeEnum_Enum.Usage,
            subRows: null,
          })),
        },
      ];
    }
    return [];
  };
  const generateUngroupedChargeRows = (): ChargeRow[] | ChargeGroupRow[] => {
    const fees = Object.values(state.fees).filter((f) => f.name !== "");
    const feeChargeRows: (
      | FixedChargeRow
      | CompositeChargeRow
      | UsageChargeRow
    )[] = fees
      .map((fee) => ({
        name: fee.name,
        chargeId: fee.name,
        type: fee.type,
        subRows: null,
      }))
      .concat(
        Object.values(state.seats).map((seat) => ({
          name: seat.displayName ?? seat.name,
          metricName: seat.name,
          chargeId: seat.metricId,
          type: ChargeTypeEnum_Enum.Seat,
          subRows: null,
        })),
      );

    if (state.selectedGroupKey) {
      return fees.length
        ? [
            {
              type: "group" as "group",
              subRows: feeChargeRows,
            },
          ]
        : [];
    } else {
      const metrics = Object.values(state.metrics);
      const metricChargeRows: UsageChargeRow[] = metrics.map((metric) => ({
        name: metric.displayName ?? metric.name,
        metricName: metric.name,
        chargeId: metric.metricId,
        type: ChargeTypeEnum_Enum.Usage,
        subRows: null,
      }));

      return ([] as ChargeRow[]).concat(metricChargeRows).concat(feeChargeRows);
    }
  };
  const groupedCharges: ChargeGroupRow[] = generateGroupedChargeRows();
  const ungroupedCharges: ChargeGroupRow[] | ChargeRow[] =
    generateUngroupedChargeRows();

  const allCharges: Array<ChargeGroupRow | ChargeRow> = groupedCharges.length
    ? ungroupedCharges.length
      ? groupedCharges.concat(ungroupedCharges as ChargeGroupRow[])
      : groupedCharges
    : ungroupedCharges;

  // Memoize the table because a re-render doesn't correctly preserve the state of the editable cells
  const table = useMemo(() => {
    return (
      <PreviewChargesTable
        productName={name ?? null}
        data={allCharges}
        editingMetricId={state.editingMetric}
        onSetEditingMetricId={(newId: string | undefined) => {
          dispatch({
            type: "CHANGE_EDITING_METRIC",
            data: {
              metricId: newId,
            },
          });
        }}
        onSaveName={(newName: string, chargeId: string) => {
          dispatch({
            type: "EDIT_METRIC_NAME",
            data: {
              metricId: chargeId,
              displayName: newName,
            },
          });
        }}
      />
    );
  }, [state, name]);

  const action = (
    <IconButton
      onClick={() =>
        navigate(newUIEnabled ? "/offering/plans/products" : "/products")
      }
      className="mr-8"
      loading={submitLoading}
      theme="secondary"
      icon="xClose"
    />
  );
  const pageContent = (
    <>
      <div className="-mr-12 flex grow flex-col gap-[36px] overflow-auto py-12 pr-12">
        <ProductInfoSection
          sectionHeader="Step 1: Product info"
          name={name}
          onChangeName={setName}
          description={description}
          onChangeDescription={setDescription}
        />
        <SelectBillableMetricsSection
          sectionTitle={
            seatsEnabled
              ? "Step 2: Add usage-based charges"
              : "Step 2: Define how this product will be priced"
          }
          allBillableMetrics={allMetrics}
          allSeats={allSeats}
          selectedMetrics={state.metrics}
          selectedSeats={state.seats}
          selectAllMetrics={selectAllMetrics}
          deselectAllMetrics={deselectAllMetrics}
          selectAllSeats={selectAllSeats}
          deselectAllSeats={deselectAllSeats}
          addMetric={(metric) =>
            dispatch({
              type: "ADD_METRIC",
              data: {
                metricId: metric.id,
                name: metric.name,
                groupKeys: (metric.group_keys ?? []) as string[],
              },
            })
          }
          removeMetric={(metricId) =>
            dispatch({
              type: "REMOVE_METRIC",
              data: { metricId },
            })
          }
          addSeat={(metric) =>
            dispatch({
              type: "ADD_SEAT",
              data: {
                metricId: metric.id,
                name: metric.name,
              },
            })
          }
          removeSeat={(metricId) =>
            dispatch({
              type: "REMOVE_SEAT",
              data: { metricId },
            })
          }
        />
        <div>
          <div className="mb-4 flex w-full flex-row items-center border-b-[1px] border-grey-100 py-8">
            <Subtitle level={1}>
              Step 3: Add any applicable fixed or composite charges
            </Subtitle>
            <HelpCircleTooltip
              content={
                <div>
                  Fixed charges are not based on usage but on a pre-determined
                  quantity. Composite charges allow you to charge a percentage
                  of the subtotal of other charges. Common examples include
                  platform fees and support.
                  <br />
                  <br />
                  If a charge is not associated with one specific product, you
                  can create a new product with only that charge.
                </div>
              }
            />
          </div>
          <Body level={2}>
            Add additional charge types such as non-usage fixed charges and
            composite charges.
          </Body>

          <div className="flex flex-col gap-12">
            {state.fees.map((fee, index) => {
              return (
                <div className="flex flex-row items-center gap-12" key={index}>
                  <Input
                    className="w-[300px]"
                    name="Charge name"
                    placeholder="Enter a name as it should appear on invoices"
                    value={fee.name}
                    onChange={(v) =>
                      dispatch({
                        type: "EDIT_FEE_NAME",
                        data: {
                          index: index,
                          name: v,
                        },
                      })
                    }
                  />
                  <Select
                    className="w-[300px]"
                    value={fee.type}
                    tooltip={
                      <div>
                        <p>
                          A fixed charge can have a variety of pricing and
                          cadence options. They are often used for one-time fees
                          and recurring base fees.
                          <br />
                          <br />A composite charge is calculated as a percentage
                          of the total sum of specific charges. They are often
                          used for premium support fees.
                        </p>
                      </div>
                    }
                    options={[
                      {
                        value: ChargeTypeEnum_Enum.Flat,
                        label: "Fixed charge",
                      },
                      {
                        value: ChargeTypeEnum_Enum.Composite,
                        label: "Composite charge",
                      },
                    ]}
                    onChange={(v) => {
                      dispatch({
                        type: "SELECT_CHARGE_TYPE",
                        data: {
                          index: index,
                          chargeType: v,
                        },
                      });
                    }}
                    name="Charge type"
                    placeholder="Fixed charge"
                  />
                  {(index !== state.fees.length - 1 || fee.name !== "") && (
                    <IconButton
                      className="mb-[-4px] self-end"
                      onClick={() => removeFlatFee(index)}
                      theme="secondary"
                      icon="xCircle"
                    />
                  )}
                </div>
              );
            })}
          </div>

          <div className="ml-[19px] h-32 border-l border-dotted border-l-grey-100" />
          <div className="flex flex-row items-center gap-4">
            <IconButton onClick={addFlatFee} theme="secondary" icon="plus" />
            <Button
              onClick={addFlatFee}
              text="Add another recurring charge"
              theme="linkGray"
            />
          </div>
        </div>
        <PreviewProductSection
          sectionHeader="Step 4: Preview and customize your product"
          selectedBillableMetricIntersectingGroupKeys={
            state.selectedBillableMetricIntersectingGroupKeys
          }
          groupByGroupKey={state.groupByGroupKey}
          selectedGroupKey={state.selectedGroupKey}
          onChangeShouldGroup={(v) => {
            v === "grouped"
              ? dispatch({
                  type: "SELECT_YES_GROUPS",
                })
              : dispatch({
                  type: "SELECT_NO_GROUPS",
                });
          }}
          onChangeGroupKey={(v) =>
            dispatch({
              type: "SELECT_GROUP_KEY",
              data: {
                groupKey: v,
              },
            })
          }
          table={table}
        />
      </div>
      <div className="-mx-12 flex grow-0 flex-row items-center justify-end gap-8 bg-white px-24 py-12 shadow-inner">
        <Button
          onClick={() =>
            navigate(newUIEnabled ? "/offering/plans" : "/products")
          }
          text="Cancel"
          theme="linkGray"
        />
        <Button
          onClick={saveProduct}
          disabled={isStateValid || submitLoading}
          loading={submitLoading}
          text="Save"
          theme="primary"
        />
      </div>
    </>
  );
  return newUIEnabled ? (
    <AppShell title="New Product" headerProps={{ actions: action }}>
      {pageContent}
    </AppShell>
  ) : (
    <PageContainer
      disableContainerScroll
      title="Add a new product"
      action={action}
    >
      {pageContent}
    </PageContainer>
  );
};

type PreviewProductSectionProps = {
  sectionHeader: string;
  table: JSX.Element;
  selectedBillableMetricIntersectingGroupKeys?: string[];
  groupByGroupKey?: boolean;
  selectedGroupKey?: string;
  onChangeShouldGroup?: (v: string) => void;
  onChangeGroupKey?: (v: string) => void;
};
export const PreviewProductSection: React.FC<PreviewProductSectionProps> = ({
  sectionHeader,
  selectedBillableMetricIntersectingGroupKeys,
  groupByGroupKey,
  selectedGroupKey,
  onChangeShouldGroup,
  onChangeGroupKey,
  table,
}) => {
  return (
    <div>
      <div className="mb-4 w-full border-b border-b-grey-100 px-0 py-8">
        <Subtitle level={1}>{sectionHeader}</Subtitle>
      </div>
      <Body level={2}>
        Below is a representation of how this product will appear on an invoice.
        You can edit the display names of any billable metrics.
      </Body>
      {selectedBillableMetricIntersectingGroupKeys &&
        onChangeShouldGroup &&
        onChangeGroupKey &&
        selectedBillableMetricIntersectingGroupKeys.length > 0 && (
          <GroupKeySection
            groupByGroupKey={groupByGroupKey}
            selectedGroupKey={selectedGroupKey}
            intersectingGroupKeys={selectedBillableMetricIntersectingGroupKeys}
            onChangeShouldGroup={onChangeShouldGroup}
            onChangeGroupKey={onChangeGroupKey}
          />
        )}
      {table}
    </div>
  );
};

interface MetricNameCellProps {
  metricId: string;
  metricName: string;
  metricDisplayName: string;
  isEditing: boolean;
  setEditingMetricId: (newId: string | undefined) => void;
  saveName: (newName: string) => void;
}

const MetricNameCell: React.FC<MetricNameCellProps> = ({
  metricId,
  metricName,
  metricDisplayName,
  isEditing,
  setEditingMetricId,
  saveName,
}) => {
  const [displayName, setDisplayName] = useState(metricDisplayName);
  const onSubmit = () => {
    if (displayName) {
      saveName(displayName);
    }
  };
  const onCancel = () => {
    setEditingMetricId(undefined);
    setDisplayName(metricDisplayName);
  };

  return (
    <div
      className={[
        "flex flex-row gap-4",
        isEditing ? "items-end" : "items-center",
      ].join(" ")}
    >
      {isEditing ? (
        <div className="flex items-end gap-x-8">
          <Input
            name="Display name"
            className="min-w-[208px]"
            placeholder={metricName}
            value={displayName}
            onChange={setDisplayName}
            autoFocus={true}
            onKeyDown={(e) =>
              e.key === "Enter"
                ? onSubmit()
                : e.key === "Escape"
                  ? onCancel()
                  : null
            }
          />

          <Button
            onClick={onSubmit}
            text="Save"
            theme="linkGray"
            leadingIcon="pencil01"
            className="mb-8"
          />
          <Tooltip content={`Reset to original name: "${metricName}"`}>
            <Button
              onClick={() => {
                saveName(metricName);
              }}
              text="Reset"
              theme="linkGray"
              leadingIcon="refreshCw01"
              className="mb-8"
            />
          </Tooltip>
        </div>
      ) : (
        <>
          <Subtitle level={1}>{metricDisplayName}</Subtitle>
          <Tooltip content="Change the charge name on invoices">
            <Button
              onClick={() => {
                setEditingMetricId(metricId);
              }}
              text="Edit name"
              theme="linkGray"
              leadingIcon="pencil01"
            />
          </Tooltip>
          {metricDisplayName !== metricName && (
            <Tooltip content="Reset to original name">
              <Button
                onClick={() => saveName(metricName)}
                text="Edited"
                theme="secondary"
                leadingIcon="refreshCw01"
                size="sm"
              />
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default NewProduct;
