import React from "react";
import { Select, Subtitle } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Checkbox, Toggle } from "design-system";
import { Condition } from "@metronome-industries/json-schema-conditions";
import { Tooltip } from "design-system";
import { twMerge } from "design-system/twMerge";

type OperatorValues = "" | "In" | "Not in" | "Exists" | "Does not exist";

type Operator = {
  [key: string]: OperatorValues;
};

const OPERATOR: Operator = {
  NONE: "",
  IN: "In",
  NOT_IN: "Not in",
  EXISTS: "Exists",
  DOES_NOT_EXIST: "Does not exist",
};

const EnumOperatorOptions = [
  { label: OPERATOR.IN, value: OPERATOR.IN },
  { label: OPERATOR.NOT_IN, value: OPERATOR.NOT_IN },
];

const RequiredOperatorOptions = [
  { label: OPERATOR.EXISTS, value: OPERATOR.EXISTS },
  { label: OPERATOR.DOES_NOT_EXIST, value: OPERATOR.DOES_NOT_EXIST },
];

/*
  Rules:
  - Event type must always exist
  - Do not allow for an enum to be set if a property can not exist
  - Default to existence  (e.g. when an enum is created, required is set to true)
  - Duplicate properties are not allowed (should be validated against)
*/

const ConditionInput: React.FC<{
  condition: Condition;
  onChange: (condition: Condition) => void;
  onDelete: (condition: Condition) => void;
  validation: (propertyName: string) => boolean;
  maxEnums?: number;
  className?: string;
  hideLabels?: boolean;
  flexAggsLayout?: boolean;
  label?: string;
}> = ({
  condition,
  onChange,
  onDelete,
  validation,
  maxEnums,
  className,
  hideLabels,
  flexAggsLayout,
  label,
}) => {
  const isEventType = condition.field[0] === "event_type";
  const [propertyName, setPropertyName] = React.useState("");
  const [valueSearch, setValueSearch] = React.useState("");
  let operator: OperatorValues;
  if (condition.enum) {
    operator = condition.enum.not ? OPERATOR.NOT_IN : OPERATOR.IN;
  } else if (condition.required !== undefined && !isEventType) {
    operator = condition.required ? OPERATOR.EXISTS : OPERATOR.DOES_NOT_EXIST;
  } else {
    operator = OPERATOR.NONE;
  }

  let valueError: string | undefined = undefined;
  if (condition.enum && maxEnums && condition.enum.values.length > maxEnums) {
    valueError = `Maximum of ${maxEnums} values allowed`;
  }
  if (condition.enum && condition.enum.values.find((v) => v.length > 75)) {
    valueError = "Values must be less than 75 characters long";
  }

  return (
    <div
      className={twMerge(
        "rounded relative mx-0 mb-[10px] mt-4 border border-solid border-gray-100 bg-gray-50 p-8",
        className,
        !flexAggsLayout && "pt-12",
      )}
    >
      <div
        className={twMerge(
          "absolute right-0",
          hideLabels ? "top-[-30px]" : "top-0",
        )}
      >
        {!flexAggsLayout && !isEventType && (
          <Tooltip content="Delete filter">
            <IconButton
              onClick={() => onDelete(condition)}
              theme="tertiary"
              icon="trash03"
            />
          </Tooltip>
        )}
      </div>
      {flexAggsLayout && (
        <div
          className={twMerge(
            "flex",
            label ? "mb-8 items-center justify-between" : "justify-end",
            !isEventType && "pr-[46px]",
          )}
        >
          {label && (
            <Subtitle level={4} className="leading-1 text-grey-600">
              {label}
            </Subtitle>
          )}
          {isEventType ? (
            <Tooltip content="This will remove any filtering by event_type. You can still set additional properties below.">
              <Checkbox
                className="mx-0 mb-0 mt-4"
                checked={!condition.enum}
                disabled={!!condition.enum?.values.length}
                label="Accept all event_type values"
                onChange={(v) => {
                  if (v) {
                    /* If we accept all event types there should be no enums */
                    onChange({
                      ...condition,
                      enum: undefined,
                    });
                  } else {
                    /* Need to re-establish enum since it was deleted when this box was initially checked */
                    onChange({
                      ...condition,
                      enum: {
                        not: false,
                        values: [],
                      },
                    });
                  }
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              content="Filters set to 'in' or 'not in' can be set to optional to allow for optional values."
              disabled={
                [
                  OPERATOR.EXISTS,
                  OPERATOR.DOES_NOT_EXIST,
                  OPERATOR.NONE,
                ].includes(operator) || !condition.enum?.values.length
              }
            >
              <Toggle
                disabled={!condition.enum?.values.length}
                label="Optional"
                onChange={(v) => {
                  onChange({
                    ...condition,
                    /* An enum can only be optional (undefined) or required (true) */
                    required: v ? undefined : true,
                  });
                }}
                /* This can only exist for IN / NOT IN which means there will be an enum  */
                checked={!!condition.enum && condition.required === undefined}
              />
            </Tooltip>
          )}
        </div>
      )}
      <div className="flex flex-row gap-8">
        {!isEventType && (
          <div className="min-w-[185px]">
            <Select
              /* Only auto focus when it's a new condition */
              autoFocus={!condition.field[1]}
              name={hideLabels ? undefined : "Property"}
              placeholder="property_name"
              onSearch={setPropertyName}
              noOptionsMessage="Enter property name"
              onChange={(fieldName) => {
                const field = condition.field.slice();
                field[1] = fieldName;
                if (field[1]) {
                  onChange({
                    ...condition,
                    field,
                  });
                } else {
                  /* If the property was completely remove reset state */
                  onChange({
                    field: ["properties", ""],
                    enum: undefined,
                    required: undefined,
                  });
                }
                setPropertyName("");
              }}
              options={
                propertyName
                  ? [{ label: propertyName, value: propertyName }]
                  : condition.field[1]
                    ? [{ label: condition.field[1], value: condition.field[1] }]
                    : []
              }
              value={condition.field[1] ?? null}
              error={
                validation(condition.field[1])
                  ? false
                  : `Filters on the same property are not supported`
              }
            />
          </div>
        )}
        <div className="min-w-[100px]" data-testid="condition-operator">
          <Select
            name={hideLabels ? undefined : "Operator"}
            /* Don't allow operator to be changed to required types if we already have enum values */
            options={[
              ...EnumOperatorOptions,
              ...(isEventType ? [] : RequiredOperatorOptions),
            ]}
            value={operator}
            onChange={(v) => {
              switch (v) {
                case OPERATOR.EXISTS:
                case OPERATOR.DOES_NOT_EXIST:
                  /* Remove enum since we should only have a "required" on (DOES NOT) EXISTS */
                  onChange({
                    ...condition,
                    enum: undefined,
                    required: v === OPERATOR.EXISTS,
                  });
                  break;
                case OPERATOR.IN:
                case OPERATOR.NOT_IN:
                  /* Default to "required" to true, "required" can't be false for an enum */
                  onChange({
                    ...condition,
                    enum: {
                      not: v === OPERATOR.NOT_IN,
                      values: condition.enum?.values ?? [],
                    },
                    required: true,
                  });
                  break;
                default:
                  break;
              }
            }}
            disabled={
              /* This is disabled if property name isn't filled out (except for a event_type) */
              isEventType ? !condition.enum : !condition.field[1]
            }
            placeholder="Select"
          />
        </div>
        <div className="w-full" data-testid="condition-values">
          <Tooltip
            content="Filters set 'exists' cannot have values. Set to 'in' or 'not in' to add values."
            disabled={
              [OPERATOR.IN, OPERATOR.NOT_IN, OPERATOR.NONE].includes(
                operator,
              ) || !!condition.enum
            }
          >
            <Select
              name={hideLabels ? undefined : "Values"}
              multiSelect
              className="m-0 w-full"
              value={condition.enum?.values || []}
              placeholder="value"
              onChange={(values) => {
                onChange({
                  ...condition,
                  enum: {
                    not: !!condition.enum?.not,
                    values,
                  },
                  /* 
                    Event type is always required. 
                    If there are values in the tags, we should take the existing "required".
                    If there are no values in the tags we should go back to the default enum state (which is "required: true")
                  */
                  required: isEventType
                    ? true
                    : values.length
                      ? condition.required
                      : true,
                });
              }}
              onSearch={setValueSearch}
              options={(condition.enum?.values || [])
                .map((v) => ({
                  label: v,
                  value: v,
                }))
                .concat(
                  valueSearch
                    ? [{ label: valueSearch, value: valueSearch }]
                    : [],
                )}
              disabled={!condition.enum}
              error={valueError}
              __internalComponentOverrides={{
                DropdownIndicator: () => null,
              }}
            />
          </Tooltip>
        </div>
        {flexAggsLayout && !isEventType && (
          <Tooltip content="Delete filter">
            <IconButton
              icon="xClose"
              size="sm"
              theme="tertiary"
              onClick={() => onDelete(condition)}
            />
          </Tooltip>
        )}
      </div>
      {!flexAggsLayout && (
        <div className="flex justify-end">
          {isEventType ? (
            <Tooltip content="This will remove any filtering by event_type. You can still set additional properties bellow.">
              <Checkbox
                className="mx-0 mb-0 mt-4"
                checked={!condition.enum}
                disabled={!!condition.enum?.values.length}
                label="Accept all event_type values"
                onChange={(v) => {
                  if (v) {
                    /* If we accept all event types there should be no enums */
                    onChange({
                      ...condition,
                      enum: undefined,
                    });
                  } else {
                    /* Need to re-establish enum since it was deleted when this box was initially checked */
                    onChange({
                      ...condition,
                      enum: {
                        not: false,
                        values: [],
                      },
                    });
                  }
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              content="Filters set to 'in' or 'not in' can be set to optional to allow for optional values."
              disabled={
                [
                  OPERATOR.EXISTS,
                  OPERATOR.DOES_NOT_EXIST,
                  OPERATOR.NONE,
                ].includes(operator) || !condition.enum?.values.length
              }
            >
              <Toggle
                disabled={!condition.enum?.values.length}
                label="Optional"
                onChange={(v) => {
                  onChange({
                    ...condition,
                    /* An enum can only be optional (undefined) or required (true) */
                    required: v ? undefined : true,
                  });
                }}
                /* This can only exist for IN / NOT IN which means there will be an enum  */
                checked={!!condition.enum && condition.required === undefined}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default ConditionInput;
