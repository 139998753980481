import React from "react";
import {
  BaseIntegrationCard,
  IntegrationCardProps,
} from "./BaseIntegrationCard";
import { ReactComponent as StripeLogo } from "tenaissance/icons/stripe.svg";

export const StripeIntegrationCard = (
  propOverrides: Omit<
    IntegrationCardProps,
    "title" | "subheader" | "description" | "logo"
  >,
) => {
  return (
    <BaseIntegrationCard
      title="Stripe"
      subheader="Billing provider"
      description="Invoice customers directly through Stripe. Configuration is handled and managed directly inside Metronome."
      {...propOverrides}
      logo={<StripeLogo />}
    />
  );
};
