import React from "react";
import { AvatarWithName } from "design-system";

import { toDayjs, printDate, dayjs } from "lib/date";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useSearcher } from "lib/search/useSearcher";
import { Table } from "components/Table";
import { EmptyState } from "tenaissance/components/EmptyState";

import { ColWidths } from "../../lib/ColWidths";
import { useContractRateCardsListQuery } from "./data.graphql";
import { ContractPricingWrapper } from "../ContractPricingWrapper";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { useUIMode } from "lib/useUIMode";
import { Table as T10Table } from "tenaissance/components/Table";
import { Button } from "tenaissance/components/Button";
import { Timestamp } from "tenaissance/components/Timestamp";
import { OFFERING_PAGES_TABLE_PAGE_SIZE } from "tenaissance/pages/Offering/constants";

export const RateCardsList: React.FC = () => {
  const req = useContractRateCardsListQuery();
  const [searchTerm, setSearchTerm] = React.useState("");

  const rateCards = (req.data?.contract_pricing.rate_cards ?? []).map((rc) => ({
    ...rc,
    __search__productNames: rc.products.flatMap((p) =>
      ProductListItem.getAllNames(p),
    ),
  }));

  const searcher = useSearcher(rateCards, {
    keys: ["name"],
    threshold: 0.1,
    includeScore: true,
  });

  const searchResults = searcher(searchTerm);
  const { newUIEnabled } = useUIMode();

  if (req.error) {
    return (
      <ErrorEmptyState
        title="We ran into an error loading your rate cards"
        error={req.error}
      />
    );
  }

  if (!newUIEnabled) {
    return (
      <ContractPricingWrapper
        searchPlaceholder="Search rate cards"
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
        newButtonRoutePath="/contract-pricing/rate-cards/new"
        newButtonText="rate card"
      >
        {!req.loading && searchResults.length === 0 ? (
          searchTerm ? (
            <EmptyState
              icon="searchSm"
              mainText="No rate cards match your query"
            />
          ) : (
            <EmptyState
              icon="file05"
              mainText="There are no rate cards"
              supportingText="Please use the contract pricing API to create rate cards"
            />
          )
        ) : (
          <Table
            loading={req.loading}
            data={searchResults}
            rowRoutePath={(rateCard) =>
              `/contract-pricing/rate-cards/${rateCard.id}`
            }
            maxPageSize={30}
            defaultSortBy={[
              {
                id: "name",
                desc: false,
              },
            ]}
            columns={[
              {
                id: "name",
                header: "Rate card name",
                render: (rateCard) => rateCard.name,
                sortable: true,
                comparator(a, b) {
                  return a.name.localeCompare(b.name);
                },
              },
              {
                id: "products",
                header: "Product count",
                cellClassName: ColWidths.ITEM_COUNT,
                render: (rateCard) => `${rateCard.products.length}`,
                sortable: true,
                comparator(a, b) {
                  return a.products.length - b.products.length;
                },
              },
              {
                id: "creator",
                header: "Created by",
                cellClassName: ColWidths.ACTOR,
                render: (rateCard) => <AvatarWithName {...rateCard.Creator} />,
                sortable: true,
                comparator(a, b) {
                  return a.Creator.name.localeCompare(b.Creator.name);
                },
              },
              {
                id: "edited",
                cellClassName: ColWidths.DATE,
                header: "Last edited",
                sortable: true,
                comparator(a, b) {
                  return toDayjs(a.updated_at).diff(b.updated_at);
                },
                render: (rateCard) => printDate(toDayjs(rateCard.updated_at)),
              },
            ]}
          />
        )}
      </ContractPricingWrapper>
    );
  }

  return (
    <T10Table
      title="Rate card catalog"
      loading={req.loading}
      emptyState={
        searchTerm ? (
          <EmptyState
            icon="searchSm"
            mainText="No rate cards match your query"
            supportingText=""
          />
        ) : (
          <EmptyState
            icon="file05"
            mainText="No rate cards"
            supportingText="Rate cards provide a centralized vehicle to store and update pricing information for your products."
          />
        )
      }
      columns={[
        {
          id: "name",
          header: "Name",
          cell: (props) => props.getValue(),
          accessorFn: (row) => row.name,
        },
        {
          id: "productCount",
          header: "Product count",
          cell: (props) => props.getValue(),
          accessorFn: (row) => row.products.length,
        },
        {
          id: "user",
          header: "Created by",
          cell: (props) => (
            <AvatarWithName
              name={props.getValue().name}
              id={props.getValue().id}
              deprecated_at={props.getValue().deprecated_at}
            />
          ),
          accessorFn: (row) => row.Creator,
        },
        {
          id: "lastEdited",
          header: "Last edited (UTC)",
          cell: (props) => (
            <Timestamp dateTime={props.getValue()} dateOnly={true} />
          ),
          accessorFn: (row) => {
            return dayjs.utc(row.updated_at).toDate();
          },
        },
      ]}
      data={searchResults}
      searchOptions={{ showSearch: true, onSearch: setSearchTerm }}
      topBarActions={[
        <Button
          text="Add"
          linkTo="/offering/rate-cards/new"
          leadingIcon="plus"
          theme="primary"
          key={1}
        />,
      ]}
      rowRoutePath={(row) => `/offering/rate-cards/${row.id}`}
      paginationOptions={{
        type: "clientSide",
        pageSize: OFFERING_PAGES_TABLE_PAGE_SIZE,
      }}
      defaultSort={searchTerm ? [] : [{ id: "name", desc: false }]}
    />
  );
};
