import { dayjs } from "lib/dayjs";
import {
  GetNetsuiteInvoiceDeliveryStatusQuery,
  GetNetsuiteInvoiceDeliveryStatusDocument,
} from "./queries.graphql";
import { ResellerType } from "types/generated-graphql/__types__";
import { ReportConfig } from "../reportConfig";

/**
 * Most of this logic is duplicated from the API: https://github.com/Metronome-Industries/api/blob/main/src/handlers/invoices/graphql.ts#L504
 * we talked about moving some of this down a level into MRI, but that ended
 * up being a bit of a rabbit hole so short-term I'm duplicating the logic
 * here. I don't expect this to be the end state for this type of reporting
 * (famous last words), hopefully there will be some kind of UI eventually
 * to make this easier, but we need something for Databricks in the short term.
 *
 * Unrelated note:  I thought about leaving all of these data structures as objects
 * (it's kind of hard to know what's what with these long lists) but apparently
 * there's some funkiness in the order that Object.values returns things (especially
 * if you have an older browser) so I'm sticking with big lists to be safe
 * https://stackoverflow.com/questions/5525795/does-javascript-guarantee-object-property-order/38218582#38218582
 */

type Unpacked<T> = T extends (infer U)[] ? U : T;

type InvoiceResult = Unpacked<
  GetNetsuiteInvoiceDeliveryStatusQuery["invoices"]["invoices"]
>;

type InvoiceDates = [
  string, // issued_at
  string, // start_date
  string, // end_date
];

type BillingProviderInvoiceFieldsList = [
  string | null | undefined, // billing_provider_invoice_id
  string | undefined, // billing_provider
  string | null | undefined, // issued_at
  string | null | undefined, // external_status
  string | null | undefined, // billing_provider_error
  string | undefined, // updated_at
];

type ResellerRoyaltyData = [
  ResellerType | null, // reseller_type
  string, // netsuite_reseller_id
  string, // fraction
  string | null, // aws_account_number
  string | null, // aws_payer_reference_id
  string | null, // aws_offer_id
  string | null, // gcp_account_id
  string | null, // gcp_offer_id
];

type ContractMetadata = [
  string | null | undefined, // netsuite_sales_order_id
  string | null | undefined, // salesforce_opportunity_id
  string | null, // net_payment_terms_days
  string | undefined, // contract_id
  string | undefined, // amendment_id
];

function getBillingProviderInvoiceFromInvoice(
  invoice: InvoiceResult,
): BillingProviderInvoiceFieldsList {
  switch (invoice.__typename) {
    case "AdHocPlanInvoice":
    case "ArrearsInvoice":
    case "AdvanceInvoice":
    case "CreditPurchaseInvoice":
    case "CorrectionInvoice":
    case "SeatPurchaseInvoice":
    case "ContractUsageInvoice":
    case "AdhocContractUsageInvoice":
    case "ContractRefundInvoice":
    case "ContractScheduledInvoice":
    case "ContractPostpaidTrueupInvoice":
    case "ContractProServiceInvoice":
      return [
        invoice?.billing_provider_invoice?.billing_provider_invoice_id,
        invoice?.billing_provider_invoice?.billing_provider,
        invoice?.billing_provider_invoice?.issued_at,
        invoice?.billing_provider_invoice?.external_status,
        invoice?.billing_provider_invoice?.billing_provider_error,
        invoice?.billing_provider_invoice?.updated_at,
      ];
    case "ParentInvoice":
      return ["", "", "", "", "", ""];
    default:
      invoice satisfies never;
      throw new Error("Unreachable");
  }
}

function getDatesFromInvoice(invoice: InvoiceResult): InvoiceDates {
  switch (invoice.__typename) {
    case "ArrearsInvoice":
    case "AdHocPlanInvoice":
    case "ParentInvoice":
      return ["", invoice.inclusive_start_date, invoice.exclusive_end_date];
    case "AdvanceInvoice":
      return [
        "",
        invoice.issued_at,
        dayjs.utc(invoice.issued_at).add(1, "day").toISOString(),
      ];
    case "CreditPurchaseInvoice":
    case "CorrectionInvoice":
    case "ContractScheduledInvoice":
    case "ContractProServiceInvoice":
    case "ContractRefundInvoice":
    case "ContractPostpaidTrueupInvoice":
    case "SeatPurchaseInvoice":
      return ["", invoice.issued_at, invoice.issued_at];
    case "ContractUsageInvoice":
    case "AdhocContractUsageInvoice":
      return [
        invoice.issued_at,
        invoice.inclusive_start_date,
        invoice.exclusive_end_date,
      ];
    default:
      invoice satisfies never;
      throw new Error("Unreachable");
  }
}

export const getResellerRoyalty = (
  invoice: InvoiceResult,
): ResellerRoyaltyData => {
  for (const li of invoice.line_items) {
    if (li.__typename === "ContractAWSRoyaltyLineItem") {
      const {
        netsuite_reseller_id,
        fraction,
        aws_account_number,
        aws_payer_reference_id,
        aws_offer_id,
      } = li.reseller_royalty;

      return [
        ResellerType.Aws,
        netsuite_reseller_id,
        fraction,
        aws_account_number,
        aws_payer_reference_id,
        aws_offer_id,
        "",
        "",
      ];
    }

    if (li.__typename === "ContractGCPRoyaltyLineItem") {
      const { netsuite_reseller_id, fraction, gcp_account_id, gcp_offer_id } =
        li.reseller_royalty;

      return [
        ResellerType.Gcp,
        netsuite_reseller_id,
        fraction,
        "",
        "",
        "",
        gcp_account_id,
        gcp_offer_id,
      ];
    }
  }

  return [null, "", "", "", "", "", "", ""];
};

const getContractMetadata = (invoice: InvoiceResult): ContractMetadata => {
  switch (invoice.__typename) {
    case "ContractUsageInvoice":
    case "AdhocContractUsageInvoice":
      return [
        "",
        "",
        invoice.contract.net_payment_terms_days
          ? invoice.contract.net_payment_terms_days.toString()
          : null,
        invoice.contract.id,
        "",
      ];
    case "ContractScheduledInvoice":
    case "ContractRefundInvoice":
      let netsuiteSalesOrderId =
        invoice.amendment?.netsuite_sales_order_id ??
        invoice.contract?.netsuite_sales_order_id ??
        undefined;
      const commitSalesOrders = invoice.line_items.flatMap((li) =>
        li.__typename === "ContractCommitLineItem" &&
        li.commit_union.netsuite_sales_order_id
          ? li.commit_union.netsuite_sales_order_id
          : [],
      );
      if (commitSalesOrders.length > 0) {
        if (new Set(commitSalesOrders).size !== 1) {
          throw new Error(
            `Unhandled: invoice ${invoice.id} has line items with multiple commits with different sales orders`,
          );
        }
        netsuiteSalesOrderId = commitSalesOrders[0];
      }
      return [
        netsuiteSalesOrderId,
        invoice.contract.salesforce_opportunity_id,
        invoice.contract.net_payment_terms_days
          ? invoice.contract.net_payment_terms_days.toString()
          : null,
        invoice.contract.id,
        invoice.amendment?.id,
      ];
    case "ContractPostpaidTrueupInvoice":
      if (invoice.line_items.length !== 1) {
        throw new Error(
          `ContractPostpaidTrueupInvoice must have exactly one line item, received ${invoice.line_items.length}`,
        );
      }
      if (
        invoice.line_items[0].__typename !== "ContractPostpaidTrueupLineItem"
      ) {
        throw new Error(
          `Expected line item type ContractPostpaidTrueupLineItem, received ${invoice.line_items[0].__typename}`,
        );
      }
      type PartialPostpaidCommit = {
        id: string;
        netsuite_sales_order_id?: string | null;
        salesforce_opportunity_id?: string | null;
        amendment_id?: string;
      };

      const commitFromLineItem = invoice.line_items[0].postpaid_commit;
      const contractCommits: PartialPostpaidCommit[] =
        invoice.contract.commits_union.map((commit) => ({
          id: commit.id,
          netsuite_sales_order_id:
            commit.netsuite_sales_order_id ??
            invoice.contract.netsuite_sales_order_id,
          salesforce_opportunity_id: invoice.contract.salesforce_opportunity_id,
        }));
      const amendmentCommits: PartialPostpaidCommit[] =
        invoice.contract.amendments.flatMap((amendment) =>
          amendment.commits_union.flatMap((commit) => ({
            id: commit.id,
            netsuite_sales_order_id:
              commit.netsuite_sales_order_id ??
              amendment.netsuite_sales_order_id,
            salesforce_opportunity_id: amendment.salesforce_opportunity_id,
            amendment_id: amendment.id,
          })),
        );
      const partialPostpaidCommit = [
        ...contractCommits,
        ...amendmentCommits,
      ].find((c) => c.id === commitFromLineItem.id);

      if (!partialPostpaidCommit) {
        throw new Error(
          `Could not find postpaid trueup line item commit ${commitFromLineItem.id} in contract ${invoice.contract.id}`,
        );
      }

      return [
        partialPostpaidCommit.netsuite_sales_order_id,
        partialPostpaidCommit.salesforce_opportunity_id,
        invoice.contract.net_payment_terms_days
          ? invoice.contract.net_payment_terms_days.toString()
          : null,
        invoice.contract.id,
        partialPostpaidCommit.amendment_id,
      ];
    case "CorrectionInvoice":
      return ["", "", "", invoice.contract_nullable?.id, ""];
    default:
      return ["", "", "", "", ""];
  }
};

const buildUrl = (
  customerId: string,
  contractId: string | undefined,
  invoiceId: string,
) =>
  contractId
    ? `/customers/${customerId}/contracts/${contractId}/invoices/${invoiceId}`
    : `/customers/${customerId}/invoices/${invoiceId}`;

export const REPORT_HEADER = [
  "invoice_id",
  "invoice_status",
  "invoice_total",
  "customer_id",
  "customer_name",
  "environment",
  "invoice_issued_date",
  "invoice_start_date",
  "invoice_end_date",
  "external_entity_id",
  "billing_provider",
  "billing_provider_invoice_sent_at",
  "billing_provider_invoice_status",
  "billing_provider_invoice_error",
  "billing_provider_invoice_last_udpated",
  "reseller_type",
  "netsuite_reseller_id",
  "reseller_fraction",
  "aws_account_number",
  "aws_payer_reference_id",
  "aws_offer_id",
  "gcp_account_id",
  "gcp_offer_id",
  "netsuite_sales_order_id",
  "salesforce_opportunity_id",
  "net_payment_terms_days",
  "contract_id",
  "amendment_id",
  "url",
];

const report: ReportConfig<GetNetsuiteInvoiceDeliveryStatusQuery> = {
  name: "Netsuite invoice delivery",
  needsDates: true,
  needsEnvironment: true,
  pageSize: 10,
  dataToCSV: (pages, environment, prefixUrl) => {
    const environment_type = environment.type;

    const results = pages.flatMap((page) =>
      page.invoices.invoices.map((invoice) => {
        const contractMetadata = getContractMetadata(invoice);
        const invoiceUrl = prefixUrl(
          buildUrl(invoice.customer.id, contractMetadata[3], invoice.id),
        );
        return [
          invoice.id,
          invoice.status,
          invoice.total,
          invoice.customer.id,
          invoice.customer.name,
          environment_type,
          ...getDatesFromInvoice(invoice),
          ...getBillingProviderInvoiceFromInvoice(invoice),
          ...getResellerRoyalty(invoice),
          ...contractMetadata,
          `https://app.metronome.com${invoiceUrl}`,
        ].map((value) => value ?? "");
      }),
    );

    return [REPORT_HEADER, ...results];
  },
  queryDocument: GetNetsuiteInvoiceDeliveryStatusDocument,
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("NETSUITE_INVOICE_DELIVERY"),
  nextCursor: (page) => page.invoices.cursor,
};

export default report;
