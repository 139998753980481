import { Dayjs } from "lib/date";
import type {
  CustomerStatusFragment as StatusFragment,
  CustomerRoutePathFragment as RoutePathFragment,
} from "./fragments.graphql";
import { ContractOrPlan } from "../ContractOrPlan";
import {
  CustomerStatusFragment,
  getCustomerStatus,
} from "../ContractOrPlan/ContractOrPlan";

export type {
  CustomerStatusFragment as StatusFragment,
  CustomerRoutePathFragment as RoutePathFragment,
} from "./fragments.graphql";

export type Status = "recently-ended" | "active" | "active-soon";

export function getStatuses(item: StatusFragment, now: Dayjs) {
  const statuses: Record<Status, CustomerStatusFragment[]> = {
    "recently-ended": [],
    active: [],
    "active-soon": [],
  };

  for (const customerStatus of [...item.contract_status, ...item.plan_status]) {
    if (customerStatus.__typename === "CustomerContractStatus") {
      if (customerStatus.archived_at || !!customerStatus.transition_type) {
        continue;
      }
    }

    const status = getCustomerStatus(customerStatus, now);
    if (status === "ending-soon" || status === "active") {
      statuses.active.push(customerStatus);
    }

    if (status === "active-soon" || status === "recently-ended") {
      statuses[status].push(customerStatus);
    }
  }

  return statuses;
}

export function getRoutePath(item: RoutePathFragment) {
  return `/customers/${item.id}`;
}

export function isArchived(item: StatusFragment, now: Dayjs): boolean {
  return !!item.archived_at && now.isSameOrAfter(item.archived_at);
}

export function isInactive(item: StatusFragment, now: Dayjs): boolean {
  return (
    isArchived(item, now) ||
    (item.contract_status.every(
      (c) => !ContractOrPlan.isActiveCustomerStatus(c, now),
    ) &&
      item.plan_status.every(
        (p) => !ContractOrPlan.isActiveCustomerStatus(p, now),
      ))
  );
}
