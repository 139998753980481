import React, { useEffect } from "react";
import { CustomerPlanInfo, useCustomerPlan } from "../../context";
import { WizardFullPage } from "components/Wizard";
import { InputSection } from "components/Wizard/components/InputSection";
import { PlanQuery } from "lib/plans/queries.graphql";
import { PlanPreview } from "components/PlanPreview";
import { BlockSkeleton } from "components/Skeleton";

export const previewingSelectedPlanIsDone = (
  customerPlan: CustomerPlanInfo,
): boolean => {
  return !!(customerPlan.Plan && customerPlan.planPreviewed);
};

interface PreviewSelectedPlanProps {
  selectedPlan: PlanQuery | null;
}

export const PreviewSelectedPlan: React.FC<PreviewSelectedPlanProps> = (
  props,
) => {
  const [customerPlan, setCustomerPlan] = useCustomerPlan();

  useEffect(() => {
    setCustomerPlan({ ...customerPlan, planPreviewed: true });
  }, [customerPlan.Plan]);

  return (
    <WizardFullPage>
      <InputSection
        header="Selected plan"
        subtitle="Preview your selected plan"
      />
      {props.selectedPlan?.Plan_by_pk ? (
        <PlanPreview plan={props.selectedPlan.Plan_by_pk} collapsible={false} />
      ) : (
        <BlockSkeleton />
      )}
    </WizardFullPage>
  );
};
