import React from "react";
import classNames from "classnames";
import { Body, Label, Subtitle } from "design-system";
import Decimal from "decimal.js";
import pluralize from "pluralize";

import { Panel } from "components/Panel";
import { RoundedCurrency, displayCostBasis } from "lib/credits";
import { PlanDetailsFragment } from "lib/plans/queries.graphql";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { PlanPreviewInfo } from "components/PlanPreview";
import { DefinitionList } from "components/DefinitionList";

interface PlanRecurringGrantPreviewProps {
  recurringGrant: PlanDetailsFragment["RecurringCreditGrants"][0];
  plan: PlanPreviewInfo;
}

export const PlanRecurringGrantPreview: React.FC<
  PlanRecurringGrantPreviewProps
> = ({ recurringGrant, plan: { PricedProducts } }) => {
  const contractsEnabled = useContractsEnabled();

  const content = (
    <>
      <Label>{recurringGrant.name}</Label>
      <Body level={2}>{recurringGrant.reason}</Body>
      <div
        className={classNames("flex gap-[36px]", {
          ["border-gray-100 border-t pt-12"]: !contractsEnabled,
        })}
      >
        <DefinitionList
          list={[
            {
              label: "Total Grant",
              value: (
                <>
                  <RoundedCurrency
                    amount={new Decimal(recurringGrant.amount_granted)}
                    creditType={recurringGrant.AmountGrantedCreditType}
                  />
                  {" / "}
                  <RoundedCurrency
                    amount={new Decimal(recurringGrant.amount_paid)}
                    creditType={recurringGrant.AmountPaidCreditType}
                  />
                </>
              ),
            },
            {
              label: "Cost basis",
              value: displayCostBasis(
                new Decimal(recurringGrant.amount_paid).div(
                  recurringGrant.amount_granted,
                ),
                recurringGrant.AmountGrantedCreditType,
                recurringGrant.AmountPaidCreditType,
              ),
            },
            {
              label: "Frequency",
              value: recurringGrant.recurrence_interval
                ? `Every ${pluralize(
                    "billing period",
                    recurringGrant.recurrence_interval,
                    recurringGrant.recurrence_interval !== 1,
                  )} ${
                    recurringGrant.recurrence_duration
                      ? `until ${pluralize(
                          "grant",
                          recurringGrant.recurrence_duration,
                          true,
                        )} ${
                          recurringGrant.recurrence_duration === 1
                            ? "has"
                            : "have"
                        } been created`
                      : "until end of plan"
                  }`
                : "One time",
            },
            {
              label: "Grant expiry",
              value: pluralize(
                "month",
                recurringGrant.effective_duration,
                true,
              ),
            },
            {
              label: "Invoicing",
              value: recurringGrant.send_invoice ? (
                <>
                  <RoundedCurrency
                    amount={new Decimal(recurringGrant.amount_paid)}
                    creditType={recurringGrant.AmountPaidCreditType}
                  />{" "}
                  every time a grant is issued
                </>
              ) : (
                "None"
              ),
            },
            {
              label: "Priority",
              value: recurringGrant.priority,
            },
            {
              label: "Credits Apply To",
              className: "col-span-2",
              value:
                recurringGrant.product_ids?.length &&
                recurringGrant.product_ids
                  ?.map(
                    (productId) =>
                      PricedProducts.find((pr) => pr?.Product.id === productId)
                        ?.Product?.name ?? "",
                  )
                  .filter((p) => !!p)
                  .join(","),
            },
          ]}
        />
      </div>
    </>
  );

  if (contractsEnabled) {
    return (
      <Panel
        title="Credit grant"
        className="mb-24 mt-12"
        contentClassName="p-12"
      >
        {content}
      </Panel>
    );
  }

  return (
    <div>
      <Subtitle level={1}>Credit grant</Subtitle>
      <div className="mb-24 rounded-medium border border-deprecated-gray-100 p-12">
        {content}
      </div>
    </div>
  );
};
