import React from "react";
import { Popup } from "components/Popup";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { useDeleteDraftPlanMutation } from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";
import { DraftPlan } from "lib/plans/types";

interface DeleteDraftPlanModalProps {
  onClose: () => void;
  plan: DraftPlan;
}

const DeleteDraftPlanModal: React.FC<DeleteDraftPlanModalProps> = ({
  onClose,
  plan,
}) => {
  const [deleteDraftPlan, deleteDraftPlanResults] = useDeleteDraftPlanMutation({
    update: (cache) => {
      cache.evict({
        fieldName: "DraftPlan",
      });
    },
  });
  const pushMessage = useSnackbar();

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            disabled={plan.id === undefined || deleteDraftPlanResults.loading}
            loading={deleteDraftPlanResults.loading}
            onClick={async () => {
              if (plan.id) {
                await deleteDraftPlan({
                  variables: { draft_plan_id: plan.id },
                });
                pushMessage({
                  content: `${plan.name} has been deleted`,
                  type: "info",
                });
              } else {
                pushMessage({
                  content: "Something went wrong, could not delete plan",
                  type: "error",
                });
              }
              onClose();
            }}
            text="Delete plan"
            theme="linkDestructive"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Are you sure you want to delete this plan?"
    >
      <Body level={2}>
        Are you sure you want to delete <strong>{plan.name}</strong>? A deleted
        plan cannot be retrieved and all progress will be lost.
      </Body>
    </Popup>
  );
};

export default DeleteDraftPlanModal;
