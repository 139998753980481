import React from "react";
import classnames from "classnames";
import { Icon, IconName } from "design-system";

import { InternalLink } from "components/Typography";
import { useRoutePathMatches } from "lib/routes/useRoutePathMatches";

import styles from "./index.module.less";

interface TabsProps {
  tabs: TabProps[];
  type?: "primary" | "secondary";
  className?: string;
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  className,
  type = "primary",
}) => {
  return (
    <div className={classnames(styles.tabs, styles[type], className)}>
      {tabs.map((t, i) => (
        <Tab {...t} key={i} />
      ))}
    </div>
  );
};

export type TabProps = {
  name: React.ReactNode;
  active?: boolean;
  activePatterns?: string[];
  icon?: IconName;
} & (
  | {
      routePath: string;
      onClick?: undefined;
    }
  | {
      routePath?: undefined;
      onClick: () => void;
    }
);

const Tab: React.FC<TabProps> = ({
  name,
  routePath,
  active,
  activePatterns,
  onClick,
  icon,
}) => {
  const match = useRoutePathMatches(activePatterns ?? [routePath ?? ""]);
  const className = classnames(styles.tab, {
    [styles.active]: active ?? match,
  });

  const content = routePath ? (
    <InternalLink routePath={routePath} className={styles.tabName}>
      {icon && <Icon icon={icon} className={styles.tabIcon} />}
      {name}
    </InternalLink>
  ) : (
    <button onClick={onClick} className={styles.tabName}>
      {icon && <Icon icon={icon} className={styles.tabIcon} />}
      {name}
    </button>
  );
  return <div className={className}>{content}</div>;
};
