import type { FilterOptions } from "components/Filter";

export const enum BillingConfigFilters {
  None = "none",
  ChargeAutomatically = "charge_automatically",
  SendInvoice = "send_invoice",
  AwsMarketplace = "aws_marketplace",
}

export const ALL_BILLING_CONFIG_FILTERS = [
  BillingConfigFilters.None,
  BillingConfigFilters.ChargeAutomatically,
  BillingConfigFilters.SendInvoice,
  BillingConfigFilters.AwsMarketplace,
];

export const CUSTOMER_OPTIONS: FilterOptions = {
  customer_status: {
    label: "Customer Status",
    options: [
      {
        label: "All",
        value: "all",
        group: "customer_status",
        type: "single",
      },
      {
        label: "Expired plan",
        value: "expired_plan",
        group: "customer_status",
        type: "single",
      },
      {
        label: "Plan expires in 30 days",
        value: "plan_expires_30d",
        group: "customer_status",
        type: "single",
      },
      {
        label: "No plan",
        value: "no_plan",
        group: "customer_status",
        type: "single",
      },
      {
        label: "Archived",
        value: "archived",
        group: "customer_status",
        type: "single",
      },
    ],
  },
  billing_provider: {
    label: "Billing Provider",
    options: [
      {
        label: "Not configured",
        value: "none",
        group: "billing_provider",
        type: "multi",
      },
      {
        label: "Stripe (charge automatically)",
        value: "charge_automatically",
        group: "billing_provider",
        type: "multi",
      },
      {
        label: "Stripe (send invoice)",
        value: "send_invoice",
        group: "billing_provider",
        type: "multi",
      },
      {
        label: "AWS Marketplace",
        value: "aws_marketplace",
        group: "billing_provider",
        type: "multi",
      },
    ],
  },
};

export const CUSTOMER_OPTIONS_DEFAULT = [
  CUSTOMER_OPTIONS.customer_status.options[0],
  ...CUSTOMER_OPTIONS.billing_provider.options,
];
