/* ab7c3fad6f78e143ff1306842671d3baa33c0c42
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertSalesforceAccountIdMutationVariables = Types.Exact<{
  salesforce_account_id: Types.Scalars['String'];
  customer_id: Types.Scalars['uuid'];
}>;


export type UpsertSalesforceAccountIdMutation = { __typename?: 'Mutation', upsert_customer_configs: Array<{ __typename?: 'CustomerConfig', id: string, value: string }> };


export const UpsertSalesforceAccountIdDocument = gql`
    mutation UpsertSalesforceAccountID($salesforce_account_id: String!, $customer_id: uuid!) {
  upsert_customer_configs(
    customer_id: $customer_id
    inputs: [{key: "salesforce_account_id", value: $salesforce_account_id}]
  ) {
    id
    value
  }
}
    `;
export type UpsertSalesforceAccountIdMutationFn = Apollo.MutationFunction<UpsertSalesforceAccountIdMutation, UpsertSalesforceAccountIdMutationVariables>;

/**
 * __useUpsertSalesforceAccountIdMutation__
 *
 * To run a mutation, you first call `useUpsertSalesforceAccountIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSalesforceAccountIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSalesforceAccountIdMutation, { data, loading, error }] = useUpsertSalesforceAccountIdMutation({
 *   variables: {
 *      salesforce_account_id: // value for 'salesforce_account_id'
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useUpsertSalesforceAccountIdMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSalesforceAccountIdMutation, UpsertSalesforceAccountIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSalesforceAccountIdMutation, UpsertSalesforceAccountIdMutationVariables>(UpsertSalesforceAccountIdDocument, options);
      }
export type UpsertSalesforceAccountIdMutationHookResult = ReturnType<typeof useUpsertSalesforceAccountIdMutation>;
export type UpsertSalesforceAccountIdMutationResult = Apollo.MutationResult<UpsertSalesforceAccountIdMutation>;
export type UpsertSalesforceAccountIdMutationOptions = Apollo.BaseMutationOptions<UpsertSalesforceAccountIdMutation, UpsertSalesforceAccountIdMutationVariables>;