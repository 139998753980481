import React, { useState } from "react";
import { Table } from "tenaissance/components/Table";
import { Button } from "tenaissance/components/Button";
import { GetCustomFieldKeysQuery } from "../../queries.graphql";
import { useSearcher } from "lib/search/useSearcher";
import { AvatarWithName } from "design-system";
import { PopoverMenu } from "components/PopoverMenu";
import { IconButton } from "tenaissance/components/IconButton";
import { renderDateTime } from "lib/time";
import { gatedAction, useAuthCheck } from "lib/useAuthCheck";
import {
  DeleteCustomFieldKeyDocument,
  UpdateCustomFieldKeysDocument,
} from "../../queries.graphql";
import ArchiveModal from "tenaissance/components/ArchiveModal";
import { useSnackbar } from "components/Snackbar";
import { getUserFacingErrorMessage } from "lib/errors/errorHandling";
import { CreateOrEditCustomFieldModal } from "../CreateOrEditCustomFieldModal";
import { ExportCustomFieldsValuesModal } from ".";
import {
  chargeCustomFieldsReport,
  creditGrantCustomFieldsReport,
  customerCustomFieldsReport,
  productCustomFieldsReport,
} from "lib/reports/reportTypes/customFieldReports";
import { GatedButton } from "components/GatedButton";
import {
  AddCustomFieldKeysDocument,
  useDeleteCustomFieldKeyMutation,
} from "../../queries.graphql";
import { Filter, OptionType } from "tenaissance/components/Filter";
import { EmptyState } from "tenaissance/components/EmptyState";
import { useDocsLink } from "lib/docs-link";

interface NewCustomFieldsTableProps {
  loading: boolean;
  customFieldKeys: Array<CustomFieldKey>;
}

type ModalState =
  | {
      state: "closed";
    }
  | { state: "addCustomFieldModalOpen" }
  | { state: "archiveModalOpen"; activeCustomFieldKey: CustomFieldKey }
  | { state: "editModalOpen"; activeCustomFieldKey: CustomFieldKey }
  | { state: "exportCustomFieldValuesModalOpen" };

type CustomFieldKey = GetCustomFieldKeysQuery["ManagedFieldKey"][0];

const CustomFieldsTable: React.FC<NewCustomFieldsTableProps> = ({
  loading,
  customFieldKeys,
}) => {
  /* Search and filtering */
  const [searchTerm, setSearchTerm] = useState("");
  const [enabledFilters, setEnabledFilters] = useState<OptionType[]>([]);
  const allEntityOptions = customFieldKeys.map((customField) => {
    return customField.entity;
  });
  const entityFilterOptions: OptionType[] = Array.from(
    new Set(allEntityOptions),
  ).map((entityOption) => {
    return {
      label: entityOption,
      type: "multi",
      value: entityOption,
    };
  });
  const searcher = useSearcher(customFieldKeys, {
    keys: ["entity", "key"],
    threshold: 0.1,
    includeScore: true,
  });
  const searchResults = searcher(searchTerm);
  const enabledFilterEntities = new Set(
    enabledFilters.map((filter) => filter.value),
  );
  const filteredResults = searchResults.filter((result) => {
    if (enabledFilterEntities.size === 0) {
      return true;
    }
    return enabledFilterEntities.has(result.entity);
  });

  /* Modal options */
  const [modalState, setModalState] = useState<ModalState>({
    state: "closed",
  });
  const canEditCustomField = !!useAuthCheck(UpdateCustomFieldKeysDocument, true)
    .allowed;
  const canArchiveCustomField = !!useAuthCheck(
    DeleteCustomFieldKeyDocument,
    true,
  ).allowed;
  const pushMessage = useSnackbar();
  const [deleteCustomFieldKeyMutation] = useDeleteCustomFieldKeyMutation({
    update(cache) {
      cache.evict({
        fieldName: "ManagedFieldKey",
      });
    },
  });

  const addNewFieldKeyButton = (
    <GatedButton
      onClick={() => setModalState({ state: "addCustomFieldModalOpen" })}
      doc={AddCustomFieldKeysDocument}
      text="Add new field key"
      theme="primary"
      leadingIcon="plus"
    />
  );

  const docsLink = useDocsLink({
    contractsPath: "developer-resources/custom-fields/",
  });

  return (
    <>
      {modalState.state === "addCustomFieldModalOpen" && (
        <CreateOrEditCustomFieldModal
          mode="create"
          onClose={() => setModalState({ state: "closed" })}
        />
      )}
      {modalState.state === "exportCustomFieldValuesModalOpen" && (
        <ExportCustomFieldsValuesModal
          availableReports={[
            customerCustomFieldsReport,
            productCustomFieldsReport,
            creditGrantCustomFieldsReport,
            chargeCustomFieldsReport,
          ]}
          onClose={() => setModalState({ state: "closed" })}
        />
      )}
      {modalState.state === "editModalOpen" && (
        <CreateOrEditCustomFieldModal
          customFieldKey={{
            ...modalState.activeCustomFieldKey,
            updated_at: new Date().toISOString(),
          }}
          mode="edit"
          onClose={() => setModalState({ state: "closed" })}
        />
      )}
      {modalState.state === "archiveModalOpen" && (
        <ArchiveModal
          element="custom field"
          supportingText={`Are you sure you want to archive the ${modalState.activeCustomFieldKey.key} custom field on the ${modalState.activeCustomFieldKey.entity} entity?`}
          onCancel={() => setModalState({ state: "closed" })}
          onArchive={async () => {
            deleteCustomFieldKeyMutation({
              variables: {
                keyId: modalState.activeCustomFieldKey.id,
              },
            })
              .then(() => {
                pushMessage({
                  type: "success",
                  content: "Custom field archived successfully",
                });
                setModalState({ state: "closed" });
              })
              .catch((err) => {
                pushMessage({
                  type: "error",
                  content: getUserFacingErrorMessage(err),
                });
              });
          }}
        />
      )}
      <Table
        title="Custom fields"
        loading={loading}
        emptyState={
          searchTerm ? (
            <EmptyState
              icon="searchSm"
              mainText="No custom fields match your query"
            />
          ) : (
            <EmptyState
              icon="code02"
              mainText="There are no custom fields"
              supportingText="Custom fields are properties that you can add to Metronome objects to store metadata like foreign keys."
              actions={[
                <Button
                  text="Learn more"
                  leadingIcon="share03"
                  isExternalLink
                  linkTo={docsLink}
                  theme="tertiary"
                />,
                addNewFieldKeyButton,
              ]}
            />
          )
        }
        columns={[
          {
            id: "key",
            header: "Key",
            cell: (props) => props.getValue(),
            accessorFn: (row) => row.key,
          },
          {
            id: "entity",
            header: "Entity",
            cell: (props) => props.getValue(),
            accessorFn: (row) => row.entity,
          },
          {
            id: "unique_values_only",
            header: "Unique values only",
            cell: (props) => props.getValue().toString(),
            accessorFn: (row) => (row.enforce_uniqueness ? "Yes" : "No"),
          },
          {
            id: "created_by",
            header: "Created by",
            cell: (props) => props.getValue(),
            accessorFn: (row) =>
              row.Creator && (
                <AvatarWithName
                  name={row.Creator?.name}
                  id={row.Creator?.id}
                  deprecated_at={row.Creator?.deprecated_at}
                />
              ),
          },
          {
            id: "date_created",
            header: "Date created",
            cell: (props) => props.getValue(),
            accessorFn: (row) => renderDateTime(new Date(row.created_at), true),
          },
          {
            id: "actions",
            header: "",
            isDisplay: true,
            cell: (row) => (
              <div>
                <PopoverMenu
                  positions={["bottom"]}
                  align="end"
                  options={[
                    gatedAction(canEditCustomField, {
                      onClick: (e: React.MouseEvent<any>) => {
                        setModalState({
                          state: "editModalOpen",
                          activeCustomFieldKey: row.getValue(),
                        });
                      },
                      content: "Edit custom field",
                    }),
                    gatedAction(canArchiveCustomField, {
                      onClick: (e: React.MouseEvent<any>) => {
                        setModalState({
                          state: "archiveModalOpen",
                          activeCustomFieldKey: row.getValue(),
                        });
                      },
                      content: "Archive custom field",
                    }),
                    {
                      content: "Copy ID",
                      onClick: async () => {
                        try {
                          const customFieldId = row.getValue().id;
                          await navigator.clipboard.writeText(customFieldId);
                          pushMessage({
                            type: "success",
                            content: `Copied custom field ID ${customFieldId} to clipboard`,
                          });
                        } catch (err) {
                          pushMessage({
                            type: "error",
                            content:
                              "Failed to copy custom field ID to clipboard",
                          });
                        }
                      },
                    },
                  ]}
                >
                  {(onClick) => (
                    <IconButton
                      onClick={onClick}
                      theme="tertiary"
                      icon="dotsVertical"
                    />
                  )}
                </PopoverMenu>
              </div>
            ),
            accessorFn: (row) => row,
          },
        ]}
        data={filteredResults}
        searchOptions={{ showSearch: true, onSearch: setSearchTerm }}
        paginationOptions={{ type: "clientSide" }}
        topBarActions={[
          ...(entityFilterOptions.length > 1
            ? [
                <Filter
                  headerContent="Entity"
                  selectedFilters={enabledFilters}
                  options={entityFilterOptions}
                  onChange={(filters) => {
                    setEnabledFilters(filters);
                  }}
                />,
              ]
            : []),
          <Button
            text="Export field values"
            leadingIcon="download02"
            theme="secondary"
            onClick={() =>
              setModalState({ state: "exportCustomFieldValuesModalOpen" })
            }
          />,
          addNewFieldKeyButton,
        ]}
        pinLastColumn={true}
      />
    </>
  );
};

export default CustomFieldsTable;
