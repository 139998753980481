import React from "react";

import { components, type OptionProps } from "react-select";
import { Headline, Body, Icon, Select } from "design-system";

import { Button } from "tenaissance/components/Button";

import { Popup } from "components/Popup";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useSearcher } from "lib/search/useSearcher";
import {
  useProductsQuery,
  useProductPricingFactorsLazyQuery,
  ProductPricingFactorsQuery,
  ProductsQuery,
} from "./addOneTimeCharge.graphql";
import { sortPricingFactors } from "lib/products/pricingFactors";
import { COMMON_POPUP_PROPS } from ".";

type Product = ProductsQuery["products"][0];

export type PricingFactor = NonNullable<
  ProductPricingFactorsQuery["Product_by_pk"]
>["ProductPricingFactors"][0];

export interface ProductInfo {
  product: Product;
  pricingFactor: PricingFactor;
}

interface Props {
  onNext: (result: ProductInfo) => void;
  onCancel: () => void;
  previousInfo?: ProductInfo;
}

const MultiLineOption: React.FC<OptionProps<{ item: Product }>> = ({
  children,
  data,
  ...props
}) => (
  <components.Option data={data} {...props}>
    <p>{data.item.name}</p>
    <p className="text-xxs text-gray-500">{data.item.description}</p>
  </components.Option>
);

function getById<T extends { id: string }>(objs: T[] | null, id?: string) {
  return !id || !objs ? undefined : objs.find((o) => o.id === id);
}

export const ChooseProductInfo: React.FC<Props> = ({
  onNext,
  onCancel,
  previousInfo,
}) => {
  const { environmentType } = useEnvironment();
  const productsReq = useProductsQuery({ variables: { environmentType } });
  const [selectedProductId, setSelectedProductId] = React.useState<string>();
  const [productFilter, setProductFilter] = React.useState("");
  const [selectedPricingFactorId, setSelectedPricingFactorId] =
    React.useState<string>();
  const [pricingFactorFilter, setPricingFactorFilter] = React.useState("");
  const [fetchProductPricingFactors, productPricingFactorsResp] =
    useProductPricingFactorsLazyQuery();

  React.useEffect(() => {
    if (previousInfo) {
      void fetchProductPricingFactors({
        variables: { productId: previousInfo.product.id },
      });
    }
  }, []);

  const products = productsReq.data?.products ?? [];
  const ppfs = productPricingFactorsResp.data?.Product_by_pk
    ?.ProductPricingFactors
    ? Array.from(
        productPricingFactorsResp.data?.Product_by_pk?.ProductPricingFactors,
      ).sort(sortPricingFactors)
    : null;

  const selectedProduct = getById(
    products,
    selectedProductId ?? previousInfo?.product.id,
  );

  const selectedPricingFactor = getById(
    ppfs,
    selectedPricingFactorId ?? previousInfo?.pricingFactor.id,
  );

  const searchProducts = useSearcher(products, ["name", "description"]);
  const searchPpfs = useSearcher(ppfs ?? [], ["name"]);

  return (
    <Popup
      {...COMMON_POPUP_PROPS}
      className="!w-[600px]"
      onRequestClose={onCancel}
      actions={
        <>
          <Button onClick={onCancel} text="Cancel" theme="linkGray" />
          <Button
            disabled={!selectedProduct || !selectedPricingFactor}
            onClick={() => {
              if (!selectedProduct || !selectedPricingFactor) {
                return;
              }

              onNext({
                product: selectedProduct,
                pricingFactor: selectedPricingFactor,
              });
            }}
            text="Next"
            theme="primary"
          />
        </>
      }
    >
      {(() => {
        if (productsReq.data && !productsReq.data.products.length) {
          return (
            <>
              <Body className="my-32 flex justify-center align-middle">
                <Icon
                  icon="warningOutline"
                  className="mr-8 text-2xl text-deprecated-red-medium"
                />
                In order to add one-time charges to an invoice you must first
                create a product that only has fixed charges. Once created, that
                product and one of its fixed-charges can be used to add a
                one-time charge to this invoice.
              </Body>
              <div className="m-12 justify-center">
                <Button
                  text="Create a new product"
                  theme="primary"
                  linkTo="/products/new"
                />
              </div>
            </>
          );
        }

        return (
          <>
            <Headline level={2} className="text-lg">
              Select a product
            </Headline>
            <Body>
              What product is this one-time charge for? The product must have
              only fixed charges.
            </Body>
            <div className="my-24 grid grid-cols-2">
              <div className="pr-12">
                <Select
                  name="Products"
                  placeholder="Select a product"
                  value={selectedProduct?.id ?? ""}
                  loading={productsReq.loading}
                  disabled={productsReq.loading}
                  options={searchProducts(productFilter).map((p) => ({
                    label: p.name,
                    value: p.id,
                    item: p,
                  }))}
                  onChange={(value) => {
                    const product =
                      value && products.find((p) => p.id === value);
                    if (product) {
                      setSelectedProductId(product.id);
                      void fetchProductPricingFactors({
                        variables: { productId: product.id },
                      });
                    }
                  }}
                  onSearch={(value) => {
                    setProductFilter(value);
                  }}
                  __internalComponentOverrides={{
                    Option: MultiLineOption,
                  }}
                />
              </div>
              <div>
                <Select
                  name="Available Charges"
                  placeholder="Select a charge"
                  disabled={ppfs === null}
                  loading={productPricingFactorsResp.loading}
                  value={selectedPricingFactor?.id ?? ""}
                  options={searchPpfs(pricingFactorFilter).map((p) => ({
                    label: p.name,
                    value: p.id,
                  }))}
                  onSearch={(value) => {
                    setPricingFactorFilter(value);
                  }}
                  onChange={(value) => {
                    const pricingFactor =
                      value && ppfs?.find((p) => p.id === value);
                    if (pricingFactor) {
                      setSelectedPricingFactorId(pricingFactor.id);
                    }
                  }}
                />
              </div>
            </div>
          </>
        );
      })()}
    </Popup>
  );
};
