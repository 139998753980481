import { Popup } from "components/Popup";
import { Button } from "tenaissance/components/Button";
import { useSnackbar } from "components/Snackbar";
import { Body } from "design-system";
import React from "react";

import { useVoidCreditGrantMutation } from "./queries.graphql";
import { clearCustomerInvoicesFromCache } from "../../lib/cache";

interface VoidCreditGrantModalProps {
  onClose: () => void;
  creditGrantId: string;
  creditGrantName: string;
  customerId: string;
}

const VoidCreditGrantModal: React.FC<VoidCreditGrantModalProps> = ({
  onClose,
  creditGrantId,
  creditGrantName,
  customerId,
}) => {
  const [voidGrant, voidGrantResults] = useVoidCreditGrantMutation({
    update(cache) {
      clearCustomerInvoicesFromCache(cache, customerId);
    },
  });

  const pushMessage = useSnackbar();

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            disabled={creditGrantId === undefined || voidGrantResults.loading}
            loading={voidGrantResults.loading}
            onClick={async () => {
              if (creditGrantId) {
                try {
                  await voidGrant({
                    variables: {
                      credit_grant_id: creditGrantId,
                    },
                  });
                  pushMessage({
                    content: "Credit grant voided",
                    type: "success",
                  });
                } catch (e: any) {
                  pushMessage({
                    content: `Failed to void grant: ${e.message} Check that '${creditGrantName}' has not already been used or invoiced.`,
                    type: "error",
                  });
                }
              }
              onClose();
            }}
            text="Void credit grant"
            theme="linkDestructive"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Are you sure you want to void this grant?"
    >
      <Body level={2}>
        Are you sure you want to void <strong>{creditGrantName}</strong>? Once a
        grant is voided it can no longer be used.
      </Body>
    </Popup>
  );
};

export default VoidCreditGrantModal;
