import React, { useState } from "react";
import { useNavigate } from "lib/useNavigate";
import { AppShell, PageContainer } from "components/PageContainer";
import { Button } from "tenaissance/components/Button";
import { useSnackbar } from "components/Snackbar";
import {
  ChargeGroupRow,
  ChargeRow,
  FixedChargeRow,
  PreviewChargesTable,
  PreviewProductSection,
  UsageChargeRow,
} from "pages/NewProduct";
import {
  ProductEditingInfoQuery,
  useEditProductMutation,
} from "../../data/queries.graphql";
import { SelectBillableMetricsSection } from "../../../NewProduct/components/SelectBillableMetricsSection";
import { ProductInfoSection } from "../../../NewProduct/components/ProductInfoSection";
import { useListBillableMetricsQuery } from "../../../NewProduct/queries.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { BillableMetric, SelectedMetrics } from "../../../NewProduct/types";
import {
  ChargeTypeEnum_Enum,
  PricingFactorInput,
} from "types/generated-graphql/__types__";
import { useUIMode } from "../../../../lib/useUIMode";

type Product = NonNullable<ProductEditingInfoQuery["Product_by_pk"]>;

type ProductEditFormProps = {
  product: Product;
};

function billableMetricIsNotNull(
  bm: Omit<BillableMetric, "sql"> | null,
): bm is BillableMetric {
  return !!bm;
}

const ProductEditForm: React.FC<ProductEditFormProps> = ({ product }) => {
  const navigate = useNavigate();
  const pushMessage = useSnackbar();
  const { environmentType } = useEnvironment();
  const [editProductMutation, { loading: saving }] = useEditProductMutation();
  const { data } = useListBillableMetricsQuery({
    variables: { environment_type: environmentType },
  });
  const [productName, setProductName] = useState(product.name);
  const [productDescription, setProductDescription] = useState(
    product.description,
  );

  const existingMetrics = product.ProductPricingFactors.map(
    (ppf) => ppf.BillableMetric,
  ).filter<BillableMetric>(billableMetricIsNotNull);

  const [selectedMetrics, setSelectedMetrics] = useState<SelectedMetrics>({});

  const [pricingFactorNamesByPpfId, setPricingFactorNamesByPpfId] = useState<
    Record<string, string>
  >(
    Object.fromEntries(
      product?.ProductPricingFactors.map((ppf) => [ppf.id, ppf.name]),
    ),
  );
  const [editingMetricId, setEditingMetricId] = useState<string | undefined>(
    undefined,
  );

  const tableData: Array<ChargeGroupRow | ChargeRow> = [];
  const sortedPricingFactors = [...product.ProductPricingFactors]
    .sort((a, b) => (a.ordering ?? 0) - (b.ordering ?? 0))
    .map((ppf, index) => ({ ...ppf, ordering: index }));
  const [chargeIdOrdering, setChargeIdOrdering] = useState<{
    chargeIdToIndex: Record<string, number>;
    chargeIdsInOrder: string[];
  }>({
    chargeIdToIndex: Object.fromEntries(
      sortedPricingFactors.map((ppf) => [ppf.id, ppf.ordering]),
    ),
    chargeIdsInOrder: sortedPricingFactors.map((ppf) => ppf.id),
  });
  if (product.group_key) {
    const usagePricingFactors = sortedPricingFactors.filter(
      (ppf) => !!ppf.BillableMetric,
    );
    const subRows = usagePricingFactors
      .map<UsageChargeRow>((ppf, index) => ({
        chargeId: ppf.id,
        name: pricingFactorNamesByPpfId[ppf.id],
        metricName: ppf.BillableMetric?.name ?? "",
        type: ChargeTypeEnum_Enum.Usage,
        subRows: null,
      }))
      .concat(
        Object.values(selectedMetrics).map<UsageChargeRow>((m, index) => ({
          chargeId: m.metricId,
          name: m.displayName,
          metricName: m.name,
          type: ChargeTypeEnum_Enum.Usage,
          subRows: null,
        })),
      )
      .sort(
        (a, b) =>
          (chargeIdOrdering.chargeIdToIndex[a.chargeId] ?? 0) -
          (chargeIdOrdering.chargeIdToIndex[b.chargeId] ?? 0),
      );
    const groupHeaderRow: ChargeGroupRow = {
      type: "group",
      groupKey: product.group_key,
      subRows,
    };
    tableData.push(groupHeaderRow);
    tableData.push(
      ...sortedPricingFactors
        .filter((ppf) => !ppf.BillableMetric)
        .map((ppf, index) => {
          return {
            chargeId: ppf.id,
            name: pricingFactorNamesByPpfId[ppf.id],
            type: ChargeTypeEnum_Enum.Flat,
            subRows: null,
          } satisfies FixedChargeRow;
        })
        .sort(
          (a, b) =>
            (chargeIdOrdering.chargeIdToIndex[a.chargeId] ?? 0) -
            (chargeIdOrdering.chargeIdToIndex[b.chargeId] ?? 0),
        ),
    );
  } else {
    tableData.push(
      ...sortedPricingFactors
        .map((ppf, index) => {
          if (!ppf.charge_type_enum) {
            throw new Error("Pricing factor must have a charge type");
          }
          return {
            chargeId: ppf.id,
            name: pricingFactorNamesByPpfId[ppf.id],
            metricName: ppf.BillableMetric?.name ?? undefined,
            type: ppf.charge_type_enum,
            subRows: null,
          };
        })
        .concat(
          Object.values(selectedMetrics).map<UsageChargeRow>((m, index) => ({
            chargeId: m.metricId,
            name: m.displayName,
            metricName: m.name,
            type: ChargeTypeEnum_Enum.Usage,
            subRows: null,
          })),
        )
        .sort(
          (a, b) =>
            (chargeIdOrdering.chargeIdToIndex[a.chargeId] ?? 0) -
            (chargeIdOrdering.chargeIdToIndex[b.chargeId] ?? 0),
        ),
    );
  }

  const { newUIEnabled } = useUIMode();

  const onClose = () =>
    navigate(`${newUIEnabled ? "/offering/plans" : ""}/products/${product.id}`);
  const onSave = async () => {
    try {
      await editProductMutation({
        variables: {
          product_id: product.id,
          name: productName,
          description: productDescription,
          pricing_factors: sortedPricingFactors
            .map<PricingFactorInput>((ppf, i) => ({
              pricing_factor_id: ppf.id,
              name: pricingFactorNamesByPpfId[ppf.id] ?? ppf.name,
              billable_metric_id: ppf.BillableMetric?.id,
              ordering: chargeIdOrdering.chargeIdToIndex[ppf.id] ?? i,
              charge_type_enum: ppf.charge_type_enum
                ? ppf.charge_type_enum
                : !ppf.BillableMetric?.id
                  ? ChargeTypeEnum_Enum.Flat
                  : ChargeTypeEnum_Enum.Usage,
            }))
            .concat(
              Object.values(selectedMetrics).map<PricingFactorInput>(
                (metric, i) => ({
                  billable_metric_id: metric.metricId,
                  name: metric.displayName,
                  ordering:
                    chargeIdOrdering.chargeIdToIndex[metric.metricId] ??
                    sortedPricingFactors.length + i,
                  charge_type_enum: ChargeTypeEnum_Enum.Usage,
                }),
              ),
            ),
        },
        update(cache) {
          cache.evict({ id: `Product:${product.id}` });
        },
      });
    } catch (err) {
      pushMessage({
        type: "error",
        content: "Could not save changes",
      });
      return;
    }
    pushMessage({
      type: "success",
      content: "Saved product",
    });
    onClose();
  };

  const actions = [
    <Button onClick={onClose} text="Back" theme="linkGray" />,
    <Button
      onClick={onSave}
      disabled={productName === "" || productDescription === "" || saving}
      loading={saving}
      text="Save"
      theme="primary"
    />,
  ];
  const pageContent = (
    <>
      <ProductInfoSection
        sectionHeader="Enter general product information"
        name={productName}
        onChangeName={setProductName}
        description={productDescription}
        onChangeDescription={setProductDescription}
      />
      <SelectBillableMetricsSection
        sectionTitle="Add usage based charges"
        allBillableMetrics={data?.billable_metrics ?? []}
        selectedMetrics={selectedMetrics}
        /* FDE-99 - [UI] Support seats in product editing  */
        addSeat={() => {}}
        allSeats={[]} // seats aren't used in the edit flow
        removeSeat={() => {}} // seats aren't used in the edit flow
        selectedSeats={{}} // seats aren't used in the edit flow
        selectAllSeats={() => {}}
        deselectAllSeats={() => {}}
        selectAllMetrics={() => {}}
        deselectAllMetrics={() => {}}
        addMetric={(metric) => {
          selectedMetrics[metric.id] = {
            type: "metric",
            name: metric.name,
            displayName: metric.name,
            metricId: metric.id,
            groupKeys: (metric.group_keys ?? []) as string[],
          };
          setSelectedMetrics({ ...selectedMetrics });
          setChargeIdOrdering({
            chargeIdToIndex: {
              ...chargeIdOrdering.chargeIdToIndex,
              [metric.id]: chargeIdOrdering.chargeIdsInOrder.length,
            },
            chargeIdsInOrder: chargeIdOrdering.chargeIdsInOrder.concat(
              metric.id,
            ),
          });
        }}
        removeMetric={(metricId) => {
          delete selectedMetrics[metricId];
          setSelectedMetrics({ ...selectedMetrics });
          const newChargeIdsInOrder = [...chargeIdOrdering.chargeIdsInOrder];
          newChargeIdsInOrder.splice(
            chargeIdOrdering.chargeIdToIndex[metricId],
            1,
          );
          setChargeIdOrdering({
            chargeIdToIndex: Object.fromEntries(
              newChargeIdsInOrder.map((cId, i) => [cId, i]),
            ),
            chargeIdsInOrder: newChargeIdsInOrder,
          });
        }}
        /* FDE-99 - [UI] Support seats in product editing - TODO(ekaragiannis) - pass in existing seats */
        existingProductInfo={{
          group_key: product.group_key,
          metrics: existingMetrics,
          seats: [],
        }}
      />
      <PreviewProductSection
        sectionHeader="Preview and customize your product"
        table={
          <PreviewChargesTable
            allowEditChargeNames={true}
            productName={productName}
            data={tableData}
            editingMetricId={editingMetricId}
            onSetEditingMetricId={setEditingMetricId}
            onSaveName={(newName: string, chargeId: string) => {
              if (pricingFactorNamesByPpfId[chargeId]) {
                setPricingFactorNamesByPpfId({
                  ...pricingFactorNamesByPpfId,
                  [chargeId]: newName,
                });
              } else {
                setSelectedMetrics({
                  ...selectedMetrics,
                  [chargeId]: {
                    ...selectedMetrics[chargeId],
                    displayName: newName,
                  },
                });
              }

              setEditingMetricId(undefined);
            }}
            onUpdateChargeOrder={(chargeId, direction) => {
              const originalPos = chargeIdOrdering.chargeIdToIndex[chargeId];
              const updatedPos = originalPos + (direction === "up" ? -1 : 1);
              if (
                updatedPos < 0 ||
                updatedPos >= chargeIdOrdering.chargeIdsInOrder.length
              ) {
                return;
              }

              const updatedPosChargeId =
                chargeIdOrdering.chargeIdsInOrder[updatedPos];

              const updatedChargeIdsInOrder = [
                ...chargeIdOrdering.chargeIdsInOrder,
              ];
              updatedChargeIdsInOrder[originalPos] = updatedPosChargeId;
              updatedChargeIdsInOrder[updatedPos] = chargeId;

              setChargeIdOrdering({
                chargeIdToIndex: {
                  ...chargeIdOrdering.chargeIdToIndex,
                  [chargeId]: updatedPos,
                  [updatedPosChargeId]: originalPos,
                },
                chargeIdsInOrder: updatedChargeIdsInOrder,
              });
            }}
          />
        }
      />
    </>
  );
  return newUIEnabled ? (
    <AppShell title="Edit product" headerProps={{ actions: actions }}>
      {pageContent}
    </AppShell>
  ) : (
    <PageContainer title="Edit product" action={<div>{actions}</div>}>
      {pageContent}
    </PageContainer>
  );
};

export default ProductEditForm;
