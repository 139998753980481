import React from "react";
import { Button } from "tenaissance/components/Button";
import { Card } from "tenaissance/components/Card";
import { Tooltip } from "tenaissance/components/Tooltip";

interface ButtonOptions {
  buttonOnClick: () => void;
  isDisabled?: boolean;
}

export interface IntegrationCardProps {
  buttonOptions: ButtonOptions;
  logo: React.ReactNode;
  description: string;
  title: string;
  subheader: string;
  hasToolTip?: boolean;
  toolTipContent?: string;
  className?: string;
  cardProps?: React.ComponentProps<typeof Card>;
}

export const BaseIntegrationCard: React.FC<IntegrationCardProps> = ({
  buttonOptions,
  logo,
  description,
  title,
  subheader,
  hasToolTip = false,
  toolTipContent,
  className = "max-w-lg",
  cardProps = {},
}) => {
  const button = (
    <Button
      text="Get started"
      theme="secondary"
      size="sm"
      onClick={buttonOptions.buttonOnClick}
      disabled={buttonOptions.isDisabled || false}
    />
  );

  return (
    <div className={className}>
      <Card
        footerActions={[
          hasToolTip ? (
            <Tooltip label={toolTipContent}>{button}</Tooltip>
          ) : (
            button
          ),
        ]}
        {...cardProps}
      >
        <div className="flex items-center pb-24">
          <div className="pr-sm float-left">{logo}</div>
          <div className="float-right">
            <div className="leading-none text-left text-base font-semibold">
              {title}
            </div>
            <div className="text-left text-xs font-normal">{subheader}</div>
          </div>
        </div>
        <div className="w-80 text-left text-xs font-normal">{description}</div>
      </Card>
    </div>
  );
};
