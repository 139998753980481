import { dayjs, Dayjs } from "lib/dayjs";
import {
  DateOptions,
  renderDate,
  renderDateRange,
  renderDateTimeInUTC,
} from "lib/time";

export { dayjs, type Dayjs };

type DateInput = string | Date | Dayjs;
type MaybeDate = DateInput | null | undefined;

export function toDayjs(date: DateInput): Dayjs {
  return dayjs.utc(date);
}
export function maybeToDayjs(date: MaybeDate): Dayjs | undefined {
  return date == null ? undefined : toDayjs(date);
}
export function toISOString(date: DateInput): string {
  return toDayjs(date).toISOString();
}

export function earliest(a: DateInput, b: MaybeDate): Dayjs;
export function earliest(a: MaybeDate, b: DateInput): Dayjs;
export function earliest(a: MaybeDate, b: MaybeDate): Dayjs | undefined;
export function earliest(a: MaybeDate, b: MaybeDate): Dayjs | undefined {
  return a && b ? dayjs.min(toDayjs(a), toDayjs(b)) : maybeToDayjs(a ?? b);
}

export function latest(a: DateInput, b: MaybeDate): Dayjs;
export function latest(a: MaybeDate, b: DateInput): Dayjs;
export function latest(a: MaybeDate, b: MaybeDate): Dayjs | undefined;
export function latest(a: MaybeDate, b: MaybeDate): Dayjs | undefined {
  return a && b ? dayjs.max(toDayjs(a), toDayjs(b)) : maybeToDayjs(a ?? b);
}

export function toDate(date: DateInput): Date {
  return toDayjs(date).toDate();
}

export function distanceFrom(now: Dayjs, to: Dayjs) {
  to = toDayjs(to);

  const diff = to.diff(now, "milliseconds");
  return {
    isInPast: diff < 0,
    inFuture: diff > 0,
    distDays: Math.abs(to.diff(now, "days")),
  };
}

export function printDate(date: Dayjs) {
  return renderDate(toDate(date), { isUtc: true });
}

export function printDateTime(date: DateInput) {
  return renderDateTimeInUTC(toDate(date), false);
}

/**
 * Print a date range, if there is not end date then it's printed as an open-ended range,
 * indicating if the start date is in the past or the future.
 */
export function printDateRange(
  now: Dayjs,
  from: Dayjs,
  to: Dayjs | undefined | null,
  dateOptions?: Omit<DateOptions, "isUtc">,
) {
  if (!to) {
    if (now.isSameOrAfter(from)) {
      return `Started ${printDate(from)}`;
    }

    return `Starting ${printDate(from)}`;
  }

  return renderDateRange(
    from.toDate(),
    to.toDate(),
    { ...dateOptions, isUtc: true },
    false,
  );
}

/**
 * Determine if a date is within a given range. Uses milliseconds
 * accuracy and the start of the range is inclusive while the end
 * is exclusive.
 *
 * `endingBefore` is optional, if not provided then the range is
 * considered to be open-ended.
 */
export function isInRange(
  date: DateInput,
  startingAt: MaybeDate,
  endingBefore: MaybeDate,
) {
  if (!endingBefore) {
    return toDayjs(date).isSameOrAfter(startingAt, "ms");
  }

  if (!startingAt) {
    return toDayjs(date).isBefore(endingBefore, "ms");
  }

  return toDayjs(date).isBetween(startingAt, endingBefore, "ms", "[)");
}
