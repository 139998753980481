import React from "react";
import { BillingProviderSection } from "pages/Customer/tabs/Settings/sections/BillingProvider";
import { useRequiredParam } from "lib/routes/params";
import { CustomerManagementSection } from "pages/Contracts/Customer/Settings/CustomerSettings";

export const CustomerSettings: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  return (
    <div className="flex space-x-xl">
      <div className="basis-1/3">
        <CustomerManagementSection customerID={customerId} />
      </div>
      <div className="flex-grow">
        <BillingProviderSection customerID={customerId} />
      </div>
    </div>
  );
};
