import React from "react";

import styles from "./index.module.less";

type ImpersonationApprovalScreenProps = {
  onApprove: () => void;
  onDeny: () => void;
  email: string;
};

import { Button } from "tenaissance/components/Button";

export const ImpersonationApprovalScreen: React.FC<
  ImpersonationApprovalScreenProps
> = (props) => {
  return (
    <div className={styles.approvalScreen}>
      <p>Continue as</p>
      <p>
        <b>{props.email}</b>?
      </p>
      <div className={styles.actionButtons}>
        <Button onClick={props.onApprove} text="Log in" theme="primary" />
        <Button onClick={props.onDeny} text="Log out" theme="secondary" />
      </div>
    </div>
  );
};
