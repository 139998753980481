import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import { useEnvironment } from "lib/environmentSwitcher/context";

export function useRoutePathMatches(routePathPatterns: string[], end = true) {
  const { prefixUrl } = useEnvironment();
  const { pathname } = useLocation();

  return React.useMemo(() => {
    for (const pattern of routePathPatterns) {
      const match = matchPath({ path: prefixUrl(pattern), end }, pathname);
      if (match) {
        return match;
      }
    }
    return undefined;
  }, [
    prefixUrl,
    pathname,
    // avoid new arrays from invalidating our result if all patterns are equal
    routePathPatterns.join("!"),
  ]);
}
