import React, { useMemo, useState } from "react";
import { useUpdateIngestAliasesMutation } from "../../pages/Customer/tabs/Settings/sections/Identifiers/queries.graphql";
import { Popup } from "../Popup";
import { Button } from "tenaissance/components/Button";
import { Body, Input } from "../../design-system";
import { PopConfirm } from "../PopConfirm";
import { IconButton } from "tenaissance/components/IconButton";

type AddIngestAliasModalProps = {
  customerId: string;
  customerName: string;
  currentAliases: string[];
  onClose: () => void;
};
export const AddIngestAliasModal: React.FC<AddIngestAliasModalProps> = ({
  customerId,
  onClose,
  customerName,
  currentAliases,
}) => {
  const [alias, setAlias] = useState("");

  const isCurrentAlias = useMemo(() => currentAliases.includes(alias), [alias]);

  const [setIngestAliases, setIngestAliasResults] =
    useUpdateIngestAliasesMutation();

  const conflictingUserId =
    setIngestAliasResults.error?.graphQLErrors[0].extensions.conflicts[0]
      .customer_id;
  return (
    <Popup
      isOpen
      title="Add ingest alias"
      onRequestClose={onClose}
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />

          <Button
            disabled={!alias || setIngestAliasResults.loading}
            loading={setIngestAliasResults.loading}
            onClick={async () => {
              if (!alias) {
                return;
              }
              await setIngestAliases({
                variables: {
                  customer_id: customerId,
                  ingest_aliases: [...currentAliases, alias],
                },
                update(cache) {
                  cache.evict({
                    fieldName: "CustomerIngestAliases",
                  });
                },
              });
              onClose();
            }}
            text="Add ingest alias"
            theme="primary"
          />
        </>
      }
    >
      <Body level={2}>
        Add a new ingest alias to {customerName}. Metronome will associate any
        usage events using this alias with {customerName}.
      </Body>
      <Input
        className="mt-4"
        placeholder={customerName}
        name="Ingest alias"
        value={alias}
        onChange={setAlias}
        autoFocus
        error={
          conflictingUserId
            ? "This alias is already in use with another customer."
            : isCurrentAlias
              ? "This alias is already in use by the customer."
              : undefined
        }
      />
      {conflictingUserId && (
        <div className="mt-8 flex">
          <Button
            text="View customer"
            theme="secondary"
            linkTo={`/customers/${conflictingUserId}/settings`}
          />
        </div>
      )}
    </Popup>
  );
};
type DeleteIngestAliasButtonProps = {
  customerId: string;
  ingestAlias: string;
  currentAliases: string[];
};
export const DeleteIngestAliasButton: React.FC<
  DeleteIngestAliasButtonProps
> = ({ customerId, ingestAlias, currentAliases }) => {
  const [setIngestAliases, setIngestAliasResults] =
    useUpdateIngestAliasesMutation();

  return (
    <PopConfirm
      content={
        <>
          Delete <b>{ingestAlias}</b>? Events with this ID will no longer be
          associated with this customer. This will not change finalized
          invoices.
        </>
      }
      onConfirm={async () => {
        await setIngestAliases({
          variables: {
            customer_id: customerId,
            ingest_aliases: currentAliases.filter(
              (alias) => alias !== ingestAlias,
            ),
          },
        });
      }}
      type="error"
    >
      <IconButton
        loading={setIngestAliasResults.loading}
        disabled={setIngestAliasResults.loading}
        theme="tertiary"
        icon="trash03"
        size="sm"
        className="hover:bg-transparent"
      />
    </PopConfirm>
  );
};
