import React, { useMemo, useState, useEffect } from "react";
import "/src/tenaissance/tenaissance.css";

import { Card, Metric } from "tenaissance/components/Card";
import { Dropdown, DropdownItem } from "tenaissance/components/Dropdown";
import { Tooltip } from "tenaissance/components/Tooltip";
import { Icon } from "tenaissance/components/Icon";
import { useUIMode } from "lib/useUIMode";
import { dayjs } from "lib/dayjs";
import { Link } from "react-router-dom";
import {
  USD_CREDIT_ID,
  prefixForCreditType,
  roundedCurrencyString,
} from "lib/credits";
import { CreditType } from "types/credit-types";
import Decimal from "decimal.js";
import {
  useClientBillingsQuery,
  useTopClientPlansQuery,
  useTopClientProductsQuery,
} from "./queries.graphql";
import { LoadingBlob } from "tenaissance/components/LoadingBlob";

const convertCreditTypeLabel = (creditType: CreditType) =>
  `${prefixForCreditType(creditType)}${
    creditType.id === USD_CREDIT_ID ? "USD" : creditType.name
  }`;

function humanReadableUIMode(mode: string) {
  switch (mode) {
    case "plans-only":
      return "plans";
    case "contracts-only":
      return "contract products";
    default:
      return "contract products and plans";
  }
}

export const BillingsCard: React.FC = () => {
  const [selectedCreditTypeID, setSelectedCreditTypeID] = useState<string>();
  const { data, loading } = useClientBillingsQuery();
  const { mode } = useUIMode();

  const { data: topContractProducts, loading: topContractProductsLoading } =
    useTopClientProductsQuery({
      skip: mode === "plans-only",
    });
  const { data: topPlans, loading: topPlansLoading } = useTopClientPlansQuery({
    skip: mode === "contracts-only",
  });

  const creditTypeOptions: CreditType[] = useMemo(() => {
    // get credit type options across all of total billings, top products, and top plans
    const allResults = [
      ...(data?.client_overview?.total_billings?.results ?? []),
      ...(topContractProducts?.client_overview.top_products?.results ?? []),
      ...(topPlans?.client_overview.top_plans?.results ?? []),
    ];
    const totalBillingsDataLoaded =
      data?.client_overview?.total_billings?.results !== undefined;
    if (totalBillingsDataLoaded) {
      const allCreditTypes = allResults.map((result) => result.credit_type);
      // allCreditTypes can contain duplicates. Find all unique credit type
      // options.
      const uniqueCreditTypesMap: Record<string, CreditType> = {};
      allCreditTypes.forEach((creditType) => {
        uniqueCreditTypesMap[creditType.id] = creditType;
      });
      const uniqueCreditTypes = Object.values(uniqueCreditTypesMap);

      // sort to have fiat currencies at the top
      const options = uniqueCreditTypes.sort((ct, _) =>
        ct.client_id === null ? -1 : 1,
      );
      // move USD to the top
      const sortedOptions = [
        ...options.filter((option) => option.id === USD_CREDIT_ID),
        ...options.filter((option) => option.id !== USD_CREDIT_ID),
      ];
      return sortedOptions;
    }
    return [];
  }, [data, topContractProducts, topPlans]);

  useEffect(() => {
    if (!selectedCreditTypeID && creditTypeOptions.length > 0) {
      setSelectedCreditTypeID(creditTypeOptions[0].id);
    }
  }, [selectedCreditTypeID, creditTypeOptions]);

  const selectedCreditType = creditTypeOptions.find(
    (o) => o.id === selectedCreditTypeID,
  );

  const current_month_for_top_spending = dayjs
    .utc(
      topContractProducts?.client_overview.top_products?.ending_before ??
        topPlans?.client_overview.top_plans?.ending_before,
    )
    .subtract(1, "second")
    .format("MMMM");

  const topProducts = [
    ...(topContractProducts?.client_overview.top_products?.results ?? []),
    ...(topPlans?.client_overview.top_plans?.results ?? []),
  ]
    .filter((r) => r.credit_type.id === selectedCreditTypeID)
    .sort((a, b) =>
      new Decimal(b.amount_billed)
        .minus(new Decimal(a.amount_billed))
        .toNumber(),
    );

  let allTimeBillingsValue: Decimal | undefined = new Decimal(
    data?.client_overview.total_billings?.results.find(
      (r) => r.credit_type.id === selectedCreditTypeID,
    )?.amount ?? 0,
  );
  let totalInvoicedTooltipContent =
    "All finalized invoices in the selected currency or pricing unit. This includes commit and credit purchases.";
  let allTimeBillingsTooltipContent = `Total invoiced over the lifetime on Metronome. This includes all finalized invoices through the month of ${current_month_for_top_spending}.`;
  let lastMonthBillingsValue: Decimal | undefined = new Decimal(
    data?.client_overview.current_period_billings?.results.find(
      (r) => r.credit_type.id === selectedCreditTypeID,
    )?.amount ?? 0,
  );
  let lastMonthBillingsTooltipContent = `Total invoiced over the month of ${current_month_for_top_spending}. This includes all finalized invoices.`;

  // strict check for null because fiat currencies have a client_id = null
  const selectedCreditTypeIsCPU =
    selectedCreditType?.client_id !== undefined &&
    selectedCreditType?.client_id !== null;
  if (selectedCreditTypeIsCPU) {
    // since invoice totals are always in fiat currencies, there's no total invoiced amount for CPUs
    allTimeBillingsValue = undefined;
    lastMonthBillingsValue = undefined;
    totalInvoicedTooltipContent =
      "A custom pricing unit is currently selected. To view total invoiced amounts, please select a fiat currency (e.g. USD).";
    allTimeBillingsTooltipContent = `A custom pricing unit is currently selected. To view total invoiced amount, please select a fiat currency (e.g. USD).`;
    lastMonthBillingsTooltipContent = `A custom pricing unit is currently selected. To view the total invoiced over the month of ${current_month_for_top_spending}, please select a fiat currency (e.g. USD).`;
  }

  // Determine if we're in a loading state
  const isLoading =
    loading ||
    (mode === "contracts-only" && topContractProductsLoading) ||
    (mode === "plans-only" && topPlansLoading) ||
    (mode === "contracts-and-plans" &&
      (topContractProductsLoading || topPlansLoading));

  // Determine if we have no data (only after loading is complete)
  const hasNoData = !isLoading && topProducts.length === 0;

  return (
    <Card
      title="Billings"
      headerActions={
        creditTypeOptions.length > 0 && selectedCreditType
          ? [
              <Dropdown
                selectedOption={convertCreditTypeLabel(selectedCreditType)}
                icon="filterLines"
                label="Credit Type"
                children={creditTypeOptions.map((o) => (
                  <DropdownItem
                    key={o.id}
                    label={convertCreditTypeLabel(o)}
                    value={o.id}
                    onClick={() => {
                      setSelectedCreditTypeID(o.id);
                    }}
                  />
                ))}
              />,
            ]
          : undefined
      }
      children={
        <div>
          <div className="mb-2xl gap-x-xs flex items-center">
            <h3 className="text-md font-semibold">Total invoiced</h3>
            <Tooltip label={totalInvoicedTooltipContent}>
              <Icon icon="helpCircle" size={14} />
            </Tooltip>
          </div>
          <div className="mb-2xl flex gap-x-[40px]">
            {loading ? (
              <>
                <LoadingBlob />
                <LoadingBlob />
              </>
            ) : (
              <>
                <Metric
                  value={allTimeBillingsValue}
                  label="All time"
                  creditType={selectedCreditType}
                  tooltipContent={allTimeBillingsTooltipContent}
                />
                <Metric
                  value={lastMonthBillingsValue}
                  label={`Last month (${current_month_for_top_spending})`}
                  creditType={selectedCreditType}
                  tooltipContent={lastMonthBillingsTooltipContent}
                />
              </>
            )}
          </div>
          <div className="mb-lg gap-x-xs flex items-center">
            <h3 className="text-md font-semibold">
              Top {humanReadableUIMode(mode)} in{" "}
              {current_month_for_top_spending}
            </h3>
            <Tooltip
              label={`Top ${humanReadableUIMode(
                mode,
              )} are calculated by the line item spend before commits or credits are applied.`}
            >
              <Icon icon="helpCircle" size={14} />
            </Tooltip>
          </div>
          <div className="mb-lg gap-x-5xl gap-y-md flex flex-wrap">
            {isLoading ? (
              <>
                <LoadingBlob />
                <LoadingBlob />
                <LoadingBlob />
              </>
            ) : hasNoData ? (
              <div className="grow text-center">
                <h3 className="text-md font-semibold text-black">
                  No line item spend incurred in{" "}
                  {current_month_for_top_spending}
                </h3>
                <div>
                  You did not incur usage that resulted in line item spend
                  during {current_month_for_top_spending}.
                </div>
              </div>
            ) : (
              topProducts.slice(0, 4).map((topP) => {
                const name =
                  "product" in topP ? topP.product.name : topP.plan.name;
                const url =
                  "product" in topP
                    ? `/offering/products?product_id=${topP.product.id}`
                    : `/offering/plans/${topP.plan.id}`;
                const delta = new Decimal(
                  topP.month_over_month_change.percent_change || 0,
                );

                if (!selectedCreditType) {
                  // Needed to appease the typechecker, but this should never happen
                  return null;
                }

                return (
                  <Metric
                    key={"product" in topP ? topP.product.id : topP.plan.id}
                    creditType={selectedCreditType}
                    value={new Decimal(topP.amount_billed)}
                    label={
                      <Link to={url} className="flex items-center">
                        <Icon
                          icon="arrowNarrowUpRight"
                          size={16}
                          className="mr-sm"
                        />
                        {name}
                      </Link>
                    }
                    deltaPercentValue={delta}
                    deltaType="trend"
                    deltaColor={delta.gt(0) ? "success" : "gray"}
                    badgeTooltipLabel={
                      delta.isZero()
                        ? "No change"
                        : `${delta.isPositive() ? "Up" : "Down"} ${delta
                            .abs()
                            .toFixed(2)}% from previous month`
                    }
                    badgeTooltipSubLabel={roundedCurrencyString(
                      new Decimal(
                        topP.month_over_month_change
                          .previous_period_total_billed || 0,
                      ),
                      selectedCreditType,
                    )}
                  />
                );
              })
            )}
          </div>
        </div>
      }
    />
  );
};
