import { useRequiredParam } from "lib/routes/params";

import { useApolloResp, ApolloResp } from "../ApolloResp";
import {
  useCustomerFromRouteQuery,
  CustomerFromRouteQuery,
} from "./data.graphql";

export type CustomerFromRouteReq = ApolloResp<
  CustomerFromRouteQuery & {
    customer: NonNullable<CustomerFromRouteQuery["customer"]>;
  }
>;

export function useCustomerFromRoute(): CustomerFromRouteReq {
  const customerId = useRequiredParam("customerId");

  return useApolloResp(
    useCustomerFromRouteQuery({
      variables: { id: customerId },
    }),
  );
}
