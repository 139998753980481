import React, { useState } from "react";
import { StripeSettingsToggleProps } from ".";
import { Toggle } from "tenaissance/components/Toggle";

export const SublineItemsToggle: React.FC<StripeSettingsToggleProps> = ({
  isChecked,
  handleToggleOnChange,
}) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);
  return (
    <Toggle
      toggled={isCheckedValue}
      label="Display sub-line items on invoices"
      onChange={(meta: { toggled: boolean }) =>
        handleToggleOnChange(
          meta.toggled,
          "export_invoice_sub_line_items",
          setIsCheckedValue,
        )
      }
      supportingText="Display more info than just product names and totals"
    />
  );
};
