import React from "react";
import "style/index.css";
import { AppShell } from "components/PageContainer";
import { useCurrentUser } from "lib/auth";
import { BillingsCard } from "./components/BillingsCard";
import { CustomerGrowthCard } from "./components/CustomerGrowthCard";
import { OverviewEventGraphCard } from "./components/OverviewEventGraphCard";
import { AuthCheck } from "../../../components/AuthCheck";
import { ClientBillingsDocument } from "./components/BillingsCard/queries.graphql";

const Overview: React.FC = () => {
  const { user, loading: userLoading } = useCurrentUser();

  return (
    <AppShell
      title={userLoading || !user ? "Welcome" : `Welcome, ${user.name}!`}
    >
      <div className="flex flex-col gap-xl">
        <AuthCheck
          authDoc={ClientBillingsDocument}
          notAllowedComponent={null}
          loadingComponent={null}
        >
          <BillingsCard />
        </AuthCheck>
        <CustomerGrowthCard />
        <OverviewEventGraphCard />
      </div>
    </AppShell>
  );
};

export default Overview;
