import React from "react";

import { AppShell } from "components/PageContainer";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Button } from "tenaissance/components/Button";

export const NotFoundPage: React.FC = () => {
  const pageContents = (
    <EmptyState
      className="h-full"
      mainText="404 — Page not found."
      supportingText="Oops, we couldn’t find the page you’re looking for."
      icon="faceFrown"
      actions={[<Button text="Go home" linkTo="/" />]}
    />
  );
  return (
    <AppShell title="404" hideHeader>
      {pageContents}
    </AppShell>
  );
};

export default NotFoundPage;
