import React, { useState } from "react";

import { AppShell } from "components/PageContainer";
import { Body, Caption, Headline } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { useAuth, useCurrentUser } from "lib/auth";
import { TextSkeleton } from "components/Skeleton";

import { EditNameModal } from "./components/EditNameModal";
import { EditEmailModal } from "./components/EditEmailModal";
import { DeleteAccountButton } from "./components/DeleteAccountButton";
import { ChangePasswordModal } from "./components/ChangePasswordModal";
import { UserAuthTypeEnum_Enum } from "types/generated-graphql/__types__";

const Account: React.FC = () => {
  const { impersonated } = useAuth();
  const { user, loading } = useCurrentUser();

  const [modalOpen, setModalOpen] = useState<
    "name" | "email" | "password" | false
  >(false);
  const isPassword = user && user.auth_type === UserAuthTypeEnum_Enum.Password;
  const content = (
    <>
      {modalOpen === "name" && (
        <EditNameModal onClose={() => setModalOpen(false)} />
      )}
      {modalOpen === "email" && (
        <EditEmailModal onClose={() => setModalOpen(false)} />
      )}
      {modalOpen === "password" && (
        <ChangePasswordModal onClose={() => setModalOpen(false)} />
      )}
      <div className="mt-8">
        <Headline level={5} className="mb-4">
          Personal Information
        </Headline>
        <div>
          <Caption>Full name</Caption>
          <div className="flex items-center">
            {loading ? <TextSkeleton /> : <Body>{user?.name}</Body>}{" "}
            <IconButton
              disabled={impersonated || loading || !isPassword}
              onClick={() => setModalOpen("name")}
              theme="tertiary"
              icon="edit05"
              size="sm"
            />
          </div>
          <Caption>Email</Caption>
          <div className="flex items-center">
            {loading ? <TextSkeleton /> : <Body>{user?.email}</Body>}
            <IconButton
              disabled={impersonated || loading || !isPassword}
              onClick={() => setModalOpen("email")}
              theme="tertiary"
              icon="edit05"
              size="sm"
            />
          </div>
        </div>
      </div>
      {isPassword ||
        (!!user?.role && (
          <Headline level={5} className="mb-4">
            Account settings
          </Headline>
        ))}
      {isPassword && (
        <>
          <Caption>Password</Caption>
          <div className="flex items-center">
            <Body>••••••••••••••</Body>
            <IconButton
              disabled={impersonated || loading}
              onClick={() => setModalOpen("password")}
              theme="tertiary"
              icon="edit05"
              size="sm"
            />
          </div>
        </>
      )}
      {!!user?.role && (
        <>
          <Caption>Role</Caption>
          <div className="flex">
            <Body>{user?.role}</Body>
          </div>
        </>
      )}
    </>
  );
  return (
    <AppShell
      title="Account"
      headerProps={{ actions: <DeleteAccountButton disabled={!isPassword} /> }}
    >
      {content}
    </AppShell>
  );
};

export default Account;
