import { BlockConfiguration } from "./types";
import { RoundingBehaviorEnum_Enum } from "../../types/generated-graphql/__types__";

export function generateFeeName(
  feeName: string,
  blockConfiguration?: BlockConfiguration,
  tierResetFrequency?: number,
) {
  return `${feeName}${
    blockConfiguration
      ? ` (divided by ${blockConfiguration.size}${
          blockConfiguration.roundingBehavior
            ? ` - rounded ${
                blockConfiguration.roundingBehavior ===
                RoundingBehaviorEnum_Enum.Ceiling
                  ? "up"
                  : "down"
              }`
            : ""
        })`
      : ""
  }
  ${
    tierResetFrequency !== undefined && tierResetFrequency !== 1
      ? tierResetFrequency === 0
        ? " (does not reset)"
        : " (resets every " + tierResetFrequency + " billing periods)"
      : ""
  }
  `;
}
