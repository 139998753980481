import React from "react";
import {
  BillingFrequencyEnum_Enum,
  CollectionScheduleEnum_Enum,
} from "types/generated-graphql/__types__";
import { PlanQuery } from "./queries.graphql";
import { getUtcEndDate, getUtcStartOfDay } from "lib/time";
import { dayjs } from "lib/dayjs";

import { BillingDayOfPeriod } from "./types";
export type Plan = Exclude<PlanQuery["Plan_by_pk"], null>;
export interface FixedPricedProduct {
  PricedProductPricingFactors: PricedProductPricingFactor[];
}
interface PricedProductPricingFactor {
  startPeriod: number;
  productPricingFactor: {
    id: string;
    name?: string;
  };
  flatFee: PricedFlatFee | null;
}
export interface PricedFlatFee {
  collectionSchedule: CollectionScheduleEnum_Enum | null;
  collectionInterval: number | null;
  isProrated: boolean;
}

const formatPeriodRange = (start: dayjs.Dayjs, end: dayjs.Dayjs, now: Date) => {
  const startFormat =
    start.year() !== now.getFullYear() || end.year() !== now.getFullYear()
      ? "MMMM D, YYYY"
      : "MMMM D";
  const endFormat =
    end.year() !== now.getFullYear() ? "MMMM D, YYYY h:mm A" : "MMMM D h:mm A";
  return `${start.format(startFormat)} – ${end.utc().format(endFormat)} ${
    end.isUTC() && "(UTC)"
  }`;
};

const planPartialStartPeriodNoProrationWarning = (
  period: string,
): React.ReactNode => {
  return (
    <>
      <div>
        Based on the start date, this customer's first invoice will cover a
        partial billing period:
      </div>
      <div>{period}</div>
      <div>
        That invoice will include all recurring charges with no proration.
      </div>
    </>
  );
};

const planPartialEndPeriodNoProrationWarning = (
  period: string,
): React.ReactNode => {
  return (
    <>
      <div>
        Based on the end date, this customer's last invoice will cover a partial
        billing period:
      </div>
      <div>{period}</div>
      <div>
        That invoice will include all recurring charges with no proration.
      </div>
    </>
  );
};

const planPartialBillingPeriodProratedChargesWarning = (
  proratedChargeNames: Array<string | undefined>,
  totalFixedCharges: number,
  dateTypeName: "start" | "end",
): React.ReactNode => {
  if (proratedChargeNames.length === 1) {
    if (!proratedChargeNames[0]) {
      if (totalFixedCharges === 1) {
        return `Based on the ${dateTypeName} date, the fixed charge will be prorated.`;
      }
      return `Based on the ${dateTypeName} date, one of the fixed charges will be prorated.`;
    }
    return (
      <>
        <div>
          Based on the {dateTypeName} date, this charge will be prorated:
        </div>
        <div>{proratedChargeNames[0]}</div>
      </>
    );
  } else if (proratedChargeNames.length >= 1) {
    if (proratedChargeNames.every((name) => !!name)) {
      return (
        <>
          <div>
            Based on the {dateTypeName} date, these charges will be prorated:
          </div>
          {proratedChargeNames.map((name, i) => {
            return <div key={i}>{name}</div>;
          })}
        </>
      );
    } else {
      return `Based on the ${dateTypeName} date, some of the fixed charges will be prorated.`;
    }
  }
  return null;
};

const planUnalignedAdvanceChargesWarning = (
  chargeName: string,
  chargeInterval: string,
  happenedAlready: boolean,
): React.ReactNode => {
  return (
    <>
      <div>{`This plan has a recurring charge "${chargeName}" that ${
        happenedAlready ? "was invoiced" : "will be paid"
      } in advance for:`}</div>
      <div>{chargeInterval}</div>
      <div>without proration</div>
    </>
  );
};

const defaultPlanUnalignedAdvanceChargesWarning = `
If this plan has any fixed or advance charges, they may be charged for a partial interval without proration.
`;

export const billingFrequencyFriendlyName = (
  billingFrequency: BillingFrequencyEnum_Enum,
  servicePeriodStart: BillingDayOfPeriod,
) => {
  switch (billingFrequency) {
    case BillingFrequencyEnum_Enum.Monthly:
      switch (servicePeriodStart) {
        case "FIRST_OF_MONTH":
          return "Monthly (1st of the month)";
        case "START_OF_PLAN":
          return "Monthly (based on plan start date)";
        default:
          assertExhaustive(servicePeriodStart);
      }
      break;
    case BillingFrequencyEnum_Enum.SemiMonthly:
      return "Semi-monthly (1st and 16th of month)";
    case BillingFrequencyEnum_Enum.Quarterly:
      switch (servicePeriodStart) {
        case "FIRST_OF_MONTH":
          return "Quarterly (1st of the month)";
        case "START_OF_PLAN":
          return "Quarterly (based on plan start date)";
        default:
          assertExhaustive(servicePeriodStart);
      }
      break;
    case BillingFrequencyEnum_Enum.SemiAnnual:
      return "Semi-annual (based on plan start date)";
    case BillingFrequencyEnum_Enum.Annual:
      return "Annual (based on plan start date)";
    default:
      assertExhaustive(billingFrequency);
  }
};

// Returns a warning string if the given start date for the plan
// creates a partial period that might have a confusing recurring charge
// or creates problems with a recurring charge interval. If there's no
// warning to raise, returns null.
export const planStartDateWarning = (
  planBillingFrequency: BillingFrequencyEnum_Enum,
  planServicePeriodStartType: BillingDayOfPeriod,
  pricedProducts: FixedPricedProduct[],
  startDate: Date | undefined,
  now: Date,
): React.ReactNode | null => {
  if (startDate === undefined) {
    return null;
  }

  const flatFeePppfs = pricedProducts.flatMap((pp) =>
    pp.PricedProductPricingFactors.filter((pppf) => pppf.flatFee),
  );
  if (!flatFeePppfs.length) {
    // No recurring charges that would need the warning about proration.
    return null;
  }

  const startUtc = dayjs.utc(getUtcStartOfDay(startDate));
  const firstBillingPeriod = billingPeriodAtPlanIndex(
    planBillingFrequency,
    planServicePeriodStartType,
    startUtc,
    0,
  );
  if (firstBillingPeriod.isPartial) {
    const proratedCharges = pricedProducts.flatMap((pp) =>
      pp.PricedProductPricingFactors.filter((pppf) => pppf.flatFee?.isProrated),
    );
    if (proratedCharges.length) {
      return planPartialBillingPeriodProratedChargesWarning(
        proratedCharges.map((charge) => charge.productPricingFactor.name),
        flatFeePppfs.length,
        "start",
      );
    } else {
      return planPartialStartPeriodNoProrationWarning(
        formatPeriodRange(startUtc, firstBillingPeriod.endDate, now),
      );
    }
  }

  return null;
};

// Returns a warning string if the given end date for the plan
// creates a partial period that might have a confusing recurring charge
// or creates problems with a recurring charge interval. If there's no
// warning to raise, returns null.
export const planEndDateWarning = (
  planBillingFrequency: BillingFrequencyEnum_Enum,
  planServicePeriodStartType: BillingDayOfPeriod,
  pricedProducts: FixedPricedProduct[],
  startDate: Date | undefined,
  endDate: Date | null | undefined,
  now: Date,
): React.ReactNode | null => {
  if (startDate === undefined || endDate === undefined || endDate === null) {
    return null;
  }

  const flatFeePppfs = pricedProducts.flatMap((pp) =>
    pp.PricedProductPricingFactors.filter((pppf) => pppf.flatFee),
  );
  if (!flatFeePppfs.length) {
    // No recurring charges that would need the warning about proration.
    return null;
  }

  const startUtc = dayjs.utc(startDate).startOf("day");
  const endUtc = dayjs.utc(getUtcEndDate(endDate));

  const lastBillingPeriod = lastPlanBillingPeriod(
    planBillingFrequency,
    planServicePeriodStartType,
    startUtc,
    endUtc,
  );

  // 1) Check for a partial end period
  // Compute the billing period that would exist if this plan didn't have an end
  // date, and use that to check whether the actual last billing period is full.
  const lastFullBillingPeriod = billingPeriodAtPlanIndex(
    planBillingFrequency,
    planServicePeriodStartType,
    startUtc,
    lastBillingPeriod.index,
  );
  if (!endUtc.isSame(lastFullBillingPeriod.endDate)) {
    // We only support pro-rating arrears fees at the end of
    // a plan. (see GET-1943 for more info)
    const proratedArrearsCharges = pricedProducts.flatMap((pp) =>
      pp.PricedProductPricingFactors.filter(
        (pppf) =>
          pppf.flatFee?.isProrated &&
          pppf.flatFee.collectionSchedule ===
            CollectionScheduleEnum_Enum.Arrears,
      ),
    );
    if (proratedArrearsCharges.length) {
      return planPartialBillingPeriodProratedChargesWarning(
        proratedArrearsCharges.map(
          (charge) => charge.productPricingFactor.name,
        ),
        flatFeePppfs.length,
        "end",
      );
    } else {
      return planPartialEndPeriodNoProrationWarning(
        formatPeriodRange(lastBillingPeriod.startDate, endUtc, now),
      );
    }
  }

  // 2) Check if end date doesn't align with some recurring charge interval
  const unalignedAdvanceCharges = pricedPricingFactorsForBillingPeriodIndex(
    pricedProducts,
    lastBillingPeriod.index,
  ).flatMap((pppf) => {
    if (pppf.flatFee && pppf.flatFee.collectionSchedule === "ADVANCE") {
      if (pppf.flatFee.collectionInterval === null) {
        return {
          chargeName: pppf.productPricingFactor.name,
          chargeInterval: null,
        };
      }
      if (pppf.flatFee.collectionInterval > 1) {
        const chargeInterval = chargeIntervalAroundIndex(
          pppf,
          lastBillingPeriod.index,
        );
        if (
          chargeInterval &&
          chargeInterval.endIndexExclusive !== lastBillingPeriod.index + 1
        ) {
          return {
            chargeName: pppf.productPricingFactor.name,
            chargeInterval,
          };
        }
      }
    }

    return [];
  });

  if (unalignedAdvanceCharges.length) {
    // For now, just choose one of the unaligned charges to show a warning for.
    const advanceChargeWithInterval = unalignedAdvanceCharges.find(
      (charge) => charge.chargeInterval !== null,
    );
    if (advanceChargeWithInterval && advanceChargeWithInterval.chargeInterval) {
      const intervalStartPeriodInclusive = billingPeriodAtPlanIndex(
        planBillingFrequency,
        planServicePeriodStartType,
        startUtc,
        advanceChargeWithInterval.chargeInterval.startIndexInclusive,
      );
      const intervalEndPeriodExclusive = billingPeriodAtPlanIndex(
        planBillingFrequency,
        planServicePeriodStartType,
        startUtc,
        advanceChargeWithInterval.chargeInterval.endIndexExclusive,
      );
      const happenedAlready = intervalStartPeriodInclusive.startDate.isBefore(
        dayjs.utc(getUtcStartOfDay(now)),
      );
      if (advanceChargeWithInterval.chargeName) {
        return planUnalignedAdvanceChargesWarning(
          advanceChargeWithInterval.chargeName,
          formatPeriodRange(
            intervalStartPeriodInclusive.startDate,
            intervalEndPeriodExclusive.startDate,
            now,
          ),
          happenedAlready,
        );
      } else {
        return defaultPlanUnalignedAdvanceChargesWarning;
      }
    } else {
      return defaultPlanUnalignedAdvanceChargesWarning;
    }
  }
  return null;
};

const assertExhaustive = (x: never): never => x;

// This function computes the billing period at a specified index of a plan
// as though the plan doesn't have an end date. Use `lastPlanBillingPeriod`
// below to check for the actual dates of the last billing period.
// This logic should be kept in sync with the equivalent resolvers logic:
// https://github.com/Metronome-Industries/graphql-resolvers/blob/main/src/lib/billing-dates/index.ts
export const billingPeriodAtPlanIndex = (
  billingFrequency: BillingFrequencyEnum_Enum,
  servicePeriodStartType: BillingDayOfPeriod,
  startDate: dayjs.Dayjs,
  index: number,
) => {
  switch (servicePeriodStartType) {
    case "START_OF_PLAN":
      if (
        billingFrequency !== BillingFrequencyEnum_Enum.Monthly &&
        billingFrequency !== BillingFrequencyEnum_Enum.Quarterly &&
        billingFrequency !== BillingFrequencyEnum_Enum.Annual &&
        billingFrequency !== BillingFrequencyEnum_Enum.SemiAnnual
      ) {
        throw new Error(
          `Billing frequency '${billingFrequency}' is incompatible with StartOfPlan schedule`,
        );
      }
      switch (billingFrequency) {
        case BillingFrequencyEnum_Enum.Monthly:
          return {
            startDate: startDate.add(index, "month"),
            endDate: startDate.add(index + 1, "month"),
            isPartial: false,
          };
        case BillingFrequencyEnum_Enum.Quarterly:
          return {
            startDate: startDate.add(index * 3, "month"),
            endDate: startDate.add(index * 3 + 3, "month"),
            isPartial: false,
          };
        case BillingFrequencyEnum_Enum.Annual:
          return {
            startDate: startDate.add(index, "year"),
            endDate: startDate.add(index + 1, "year"),
            isPartial: false,
          };
        case BillingFrequencyEnum_Enum.SemiAnnual:
          return {
            startDate: startDate.add(index * 6, "month"),
            endDate: startDate.add(index * 6 + 6, "month"),
            isPartial: false,
          };
        default:
          assertExhaustive(billingFrequency);
      }
    case "FIRST_OF_MONTH":
      if (
        billingFrequency !== BillingFrequencyEnum_Enum.Monthly &&
        billingFrequency !== BillingFrequencyEnum_Enum.SemiMonthly &&
        billingFrequency !== BillingFrequencyEnum_Enum.Quarterly
      ) {
        throw new Error(
          `Billing frequency '${billingFrequency}' is incompatible with FirstOfMonth schedule`,
        );
      }
      switch (billingFrequency) {
        case BillingFrequencyEnum_Enum.Monthly:
          return {
            startDate:
              index === 0 ? startDate : startDate.add(index, "month").date(1),
            endDate: startDate.add(index + 1, "month").date(1),
            isPartial: index === 0 && startDate.date() !== 1,
          };
        case BillingFrequencyEnum_Enum.SemiMonthly:
          // These billing periods always end on the 1st or 16th of the month, alternating.
          const firstEndDate =
            startDate.date() < 16
              ? startDate.date(16)
              : startDate.add(1, "month").date(1);
          const secondEndDate =
            startDate.date() < 16
              ? startDate.add(1, "month").date(1)
              : startDate.add(1, "month").date(16);
          if (index === 0) {
            return {
              startDate,
              endDate: firstEndDate,
              isPartial: startDate.date() !== 1 && startDate.date() !== 16,
            };
          } else if (index % 2 === 0) {
            return {
              startDate: secondEndDate.add(index / 2 - 1, "month"),
              endDate: firstEndDate.add(index / 2, "month"),
              isPartial: false,
            };
          } else {
            return {
              startDate: firstEndDate.add((index - 1) / 2, "month"),
              endDate: secondEndDate.add((index - 1) / 2, "month"),
              isPartial: false,
            };
          }
        case BillingFrequencyEnum_Enum.Quarterly:
          return {
            startDate:
              index === 0
                ? startDate
                : startDate.add(index * 3, "month").date(1),
            endDate: startDate.add(index * 3 + 3, "month").date(1),
            isPartial: index === 0 && startDate.date() !== 1,
          };
        default:
          assertExhaustive(billingFrequency);
      }
      break;
    default:
      assertExhaustive(servicePeriodStartType);
  }
  throw new Error("unreachable");
};

// This computes roughly the inverse of billingPeriodAtPlanIndex. Given a
// date on a plan, it computes the index of the billing period it falls into.
// If the queryDate given is before the start of the plan, this returns undefined.
export const billingPeriodIndexForDate = (
  billingFrequency: BillingFrequencyEnum_Enum,
  servicePeriodStartType: BillingDayOfPeriod,
  planStartDate: dayjs.Dayjs,
  queryDate: dayjs.Dayjs,
) => {
  if (queryDate.isBefore(planStartDate)) {
    return undefined;
  }
  // TODO: we could actually do month math here if we need to speed this up
  let index = 0;
  // NOTE: we use isSameOrAfter because the endDate is exclusive (passing in a
  // queryDate that's the same as a billing period's endDate will return the index
  // of the _following_ billing period).
  while (
    queryDate.isSameOrAfter(
      billingPeriodAtPlanIndex(
        billingFrequency,
        servicePeriodStartType,
        planStartDate,
        index,
      ).endDate,
      "day",
    )
  ) {
    index += 1;
  }
  return index;
};

const lastPlanBillingPeriod = (
  planBillingFrequency: BillingFrequencyEnum_Enum,
  planServicePeriodStartType: BillingDayOfPeriod,
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
) => {
  let index = -1;
  let curStartDate = startDate;
  let curEndDate = startDate;

  // If the end dates are equal, then we're done because this
  // billing period ends the plan.
  while (endDate.isAfter(curEndDate)) {
    index += 1;
    const billingPeriod = billingPeriodAtPlanIndex(
      planBillingFrequency,
      planServicePeriodStartType,
      startDate,
      index,
    );
    curStartDate = billingPeriod.startDate;
    curEndDate = billingPeriod.endDate;
  }
  return { index, startDate: curStartDate, endDate };
};

const pricedPricingFactorsForBillingPeriodIndex = (
  pricedProducts: FixedPricedProduct[],
  index: number,
): PricedProductPricingFactor[] => {
  const pppfsInCorrectRamp: {
    [pricingFactorId: string]: PricedProductPricingFactor;
  } = {};
  for (const pp of pricedProducts) {
    for (const pppf of pp.PricedProductPricingFactors) {
      if (Number(pppf.startPeriod) <= index) {
        const pricingFactorId = pppf.productPricingFactor.id;
        const existingPppf = pppfsInCorrectRamp[pricingFactorId];
        if (
          !existingPppf ||
          Number(existingPppf.startPeriod) < Number(pppf.startPeriod)
        ) {
          pppfsInCorrectRamp[pricingFactorId] = pppf;
        }
      }
    }
  }
  return Object.values(pppfsInCorrectRamp);
};

const chargeIntervalAroundIndex = (
  charge: PricedProductPricingFactor,
  index: number,
) => {
  if (charge.flatFee && charge.flatFee.collectionInterval !== null) {
    const offset =
      (index - Number(charge.startPeriod)) % charge.flatFee.collectionInterval;
    const startIndexInclusive = index - offset;
    return {
      startIndexInclusive,
      endIndexExclusive:
        startIndexInclusive + charge.flatFee.collectionInterval,
    };
  }
  return null;
};
