/* 94cd683591267082d34d7e25ed4876ab1df70271
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRateCardMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  description?: Types.InputMaybe<Types.Scalars['String']>;
  rates?: Types.InputMaybe<Array<Types.RateCardEntryInput> | Types.RateCardEntryInput>;
  aliases?: Types.InputMaybe<Array<Types.RateCardAliasInput> | Types.RateCardAliasInput>;
  fiatCreditTypeId: Types.Scalars['String'];
  creditTypeConversions?: Types.InputMaybe<Array<Types.RateCardCreditTypeConversionInput> | Types.RateCardCreditTypeConversionInput>;
}>;


export type CreateRateCardMutation = { __typename?: 'Mutation', create_rate_card: { __typename?: 'RateCard', id: string } };

export type UpdateRateCardMutationVariables = Types.Exact<{
  rateCardId: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
  description?: Types.InputMaybe<Types.Scalars['String']>;
  additionalRates?: Types.InputMaybe<Array<Types.RateCardEntryInput> | Types.RateCardEntryInput>;
  aliases?: Types.InputMaybe<Array<Types.RateCardAliasInput> | Types.RateCardAliasInput>;
}>;


export type UpdateRateCardMutation = { __typename?: 'Mutation', update_rate_card: { __typename?: 'RateCard', id: string } };

export type AddRateMutationVariables = Types.Exact<{
  rateCardId: Types.Scalars['uuid'];
  productId: Types.Scalars['uuid'];
  effectiveAt: Types.Scalars['timestamptz'];
  entitled: Types.Scalars['Boolean'];
  rate: Types.RateInput;
  endingBefore?: Types.InputMaybe<Types.Scalars['timestamptz']>;
}>;


export type AddRateMutation = { __typename?: 'Mutation', add_rate: { __typename?: 'RateCardEntry', id: string, created_at: string, effective_at: string, ending_before: string | null, entitled: boolean, rate: { __typename: 'CustomRate' } | { __typename: 'FlatRate', unit_price: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename: 'PercentageRate', fraction: string, use_list_prices: boolean } | { __typename: 'SubscriptionRate', unit_price: string, quantity: string, is_prorated: boolean, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename: 'TieredRate', tiers: Array<{ __typename?: 'Tier', size: string | null, unit_price: string }>, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } } };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export type RateCardEditDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  rateCountLimit?: Types.InputMaybe<Types.Scalars['numeric']>;
}>;


export type RateCardEditDetailsQuery = { __typename?: 'Query', products_and_rate_cards: { __typename?: 'ProductsAndRateCards', rate_card: { __typename?: 'RateCard', id: string, name: string, description: string | null, aliases: Array<{ __typename?: 'RateCardAliasScheduleSegment', starting_at: string | null, ending_before: string | null, data: { __typename?: 'RateCardAliasData', alias: string } }>, rate_schedule: { __typename?: 'RateSchedule', next_page: string | null, scalar_segments: Array<import("types/generated-graphql/__types__").ScalarRateScheduleSegment> }, fiat_credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }, credit_type_conversions: Array<{ __typename?: 'RateCardCreditTypeConversion', fiat_per_custom_credit: string, custom_credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } }> | null } } };

export type ProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', products: Array<{ __typename: 'CompositeProductListItem', id: string, archived_at: string | null, initial: { __typename?: 'CompositeProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'FixedProductListItem', id: string, archived_at: string | null, initial: { __typename?: 'FixedProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'ProServiceProductListItem', id: string, archived_at: string | null, initial: { __typename?: 'ProServiceProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'SubscriptionProductListItem', id: string, archived_at: string | null, initial: { __typename?: 'SubscriptionProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'UsageProductListItem', id: string, archived_at: string | null, initial: { __typename?: 'UsageProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> }> } };

type ProductListItemId_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', id: string };

type ProductListItemId_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', id: string };

type ProductListItemId_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', id: string };

type ProductListItemId_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', id: string };

type ProductListItemId_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', id: string };

export type ProductListItemIdFragment = ProductListItemId_CompositeProductListItem_Fragment | ProductListItemId_FixedProductListItem_Fragment | ProductListItemId_ProServiceProductListItem_Fragment | ProductListItemId_SubscriptionProductListItem_Fragment | ProductListItemId_UsageProductListItem_Fragment;

type ProductListItemName_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

export type ProductListItemNameFragment = ProductListItemName_CompositeProductListItem_Fragment | ProductListItemName_FixedProductListItem_Fragment | ProductListItemName_ProServiceProductListItem_Fragment | ProductListItemName_SubscriptionProductListItem_Fragment | ProductListItemName_UsageProductListItem_Fragment;

type ProductListItemType_CompositeProductListItem_Fragment = { __typename: 'CompositeProductListItem' };

type ProductListItemType_FixedProductListItem_Fragment = { __typename: 'FixedProductListItem' };

type ProductListItemType_ProServiceProductListItem_Fragment = { __typename: 'ProServiceProductListItem' };

type ProductListItemType_SubscriptionProductListItem_Fragment = { __typename: 'SubscriptionProductListItem' };

type ProductListItemType_UsageProductListItem_Fragment = { __typename: 'UsageProductListItem' };

export type ProductListItemTypeFragment = ProductListItemType_CompositeProductListItem_Fragment | ProductListItemType_FixedProductListItem_Fragment | ProductListItemType_ProServiceProductListItem_Fragment | ProductListItemType_SubscriptionProductListItem_Fragment | ProductListItemType_UsageProductListItem_Fragment;

type ProductListItemTags_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

export type ProductListItemTagsFragment = ProductListItemTags_CompositeProductListItem_Fragment | ProductListItemTags_FixedProductListItem_Fragment | ProductListItemTags_ProServiceProductListItem_Fragment | ProductListItemTags_SubscriptionProductListItem_Fragment | ProductListItemTags_UsageProductListItem_Fragment;

type ProductListItemArchived_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', archived_at: string | null };

type ProductListItemArchived_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', archived_at: string | null };

type ProductListItemArchived_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', archived_at: string | null };

type ProductListItemArchived_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', archived_at: string | null };

type ProductListItemArchived_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', archived_at: string | null };

export type ProductListItemArchivedFragment = ProductListItemArchived_CompositeProductListItem_Fragment | ProductListItemArchived_FixedProductListItem_Fragment | ProductListItemArchived_ProServiceProductListItem_Fragment | ProductListItemArchived_SubscriptionProductListItem_Fragment | ProductListItemArchived_UsageProductListItem_Fragment;

export type GetCustomCreditTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomCreditTypesQuery = { __typename?: 'Query', CreditType: Array<{ __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }> };

export type GetAllCreditTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllCreditTypesQuery = { __typename?: 'Query', CreditType: Array<{ __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }> };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const ProductListItemIdFragmentDoc = gql`
    fragment ProductListItemId on ProductListItem {
  ... on FixedProductListItem {
    id
  }
  ... on ProServiceProductListItem {
    id
  }
  ... on UsageProductListItem {
    id
  }
  ... on SubscriptionProductListItem {
    id
  }
  ... on CompositeProductListItem {
    id
  }
}
    `;
export const ProductListItemNameFragmentDoc = gql`
    fragment ProductListItemName on ProductListItem {
  ... on FixedProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
}
    `;
export const ProductListItemTypeFragmentDoc = gql`
    fragment ProductListItemType on ProductListItem {
  __typename
}
    `;
export const ProductListItemTagsFragmentDoc = gql`
    fragment ProductListItemTags on ProductListItem {
  ... on FixedProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
}
    `;
export const ProductListItemArchivedFragmentDoc = gql`
    fragment ProductListItemArchived on ProductListItem {
  ... on FixedProductListItem {
    archived_at
  }
  ... on ProServiceProductListItem {
    archived_at
  }
  ... on UsageProductListItem {
    archived_at
  }
  ... on SubscriptionProductListItem {
    archived_at
  }
  ... on CompositeProductListItem {
    archived_at
  }
}
    `;
export const CreateRateCardDocument = gql`
    mutation CreateRateCard($name: String!, $description: String, $rates: [RateCardEntryInput!], $aliases: [RateCardAliasInput!], $fiatCreditTypeId: String!, $creditTypeConversions: [RateCardCreditTypeConversionInput!]) {
  create_rate_card(
    name: $name
    description: $description
    rates: $rates
    aliases: $aliases
    fiatCreditTypeId: $fiatCreditTypeId
    creditTypeConversions: $creditTypeConversions
  ) {
    id
  }
}
    `;
export type CreateRateCardMutationFn = Apollo.MutationFunction<CreateRateCardMutation, CreateRateCardMutationVariables>;

/**
 * __useCreateRateCardMutation__
 *
 * To run a mutation, you first call `useCreateRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateCardMutation, { data, loading, error }] = useCreateRateCardMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      rates: // value for 'rates'
 *      aliases: // value for 'aliases'
 *      fiatCreditTypeId: // value for 'fiatCreditTypeId'
 *      creditTypeConversions: // value for 'creditTypeConversions'
 *   },
 * });
 */
export function useCreateRateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateRateCardMutation, CreateRateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRateCardMutation, CreateRateCardMutationVariables>(CreateRateCardDocument, options);
      }
export type CreateRateCardMutationHookResult = ReturnType<typeof useCreateRateCardMutation>;
export type CreateRateCardMutationResult = Apollo.MutationResult<CreateRateCardMutation>;
export type CreateRateCardMutationOptions = Apollo.BaseMutationOptions<CreateRateCardMutation, CreateRateCardMutationVariables>;
export const UpdateRateCardDocument = gql`
    mutation UpdateRateCard($rateCardId: uuid!, $name: String!, $description: String, $additionalRates: [RateCardEntryInput!], $aliases: [RateCardAliasInput!]) {
  update_rate_card(
    id: $rateCardId
    name: $name
    description: $description
    additionalRates: $additionalRates
    aliases: $aliases
  ) {
    id
  }
}
    `;
export type UpdateRateCardMutationFn = Apollo.MutationFunction<UpdateRateCardMutation, UpdateRateCardMutationVariables>;

/**
 * __useUpdateRateCardMutation__
 *
 * To run a mutation, you first call `useUpdateRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRateCardMutation, { data, loading, error }] = useUpdateRateCardMutation({
 *   variables: {
 *      rateCardId: // value for 'rateCardId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      additionalRates: // value for 'additionalRates'
 *      aliases: // value for 'aliases'
 *   },
 * });
 */
export function useUpdateRateCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRateCardMutation, UpdateRateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRateCardMutation, UpdateRateCardMutationVariables>(UpdateRateCardDocument, options);
      }
export type UpdateRateCardMutationHookResult = ReturnType<typeof useUpdateRateCardMutation>;
export type UpdateRateCardMutationResult = Apollo.MutationResult<UpdateRateCardMutation>;
export type UpdateRateCardMutationOptions = Apollo.BaseMutationOptions<UpdateRateCardMutation, UpdateRateCardMutationVariables>;
export const AddRateDocument = gql`
    mutation AddRate($rateCardId: uuid!, $productId: uuid!, $effectiveAt: timestamptz!, $entitled: Boolean!, $rate: RateInput!, $endingBefore: timestamptz) {
  add_rate(
    rate_card_id: $rateCardId
    product_list_item_id: $productId
    effective_at: $effectiveAt
    entitled: $entitled
    rate: $rate
    ending_before: $endingBefore
  ) {
    id
    created_at
    effective_at
    ending_before
    entitled
    rate {
      __typename
      ... on FlatRate {
        unit_price
        credit_type {
          ...CreditType
          __environment_type: environment_type
        }
      }
      ... on SubscriptionRate {
        unit_price
        quantity
        is_prorated
        credit_type {
          ...CreditType
          __environment_type: environment_type
        }
      }
      ... on PercentageRate {
        fraction
        use_list_prices
      }
      ... on TieredRate {
        tiers {
          size
          unit_price
        }
        credit_type {
          ...CreditType
          __environment_type: environment_type
        }
      }
    }
  }
}
    ${CreditTypeFragmentDoc}`;
export type AddRateMutationFn = Apollo.MutationFunction<AddRateMutation, AddRateMutationVariables>;

/**
 * __useAddRateMutation__
 *
 * To run a mutation, you first call `useAddRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRateMutation, { data, loading, error }] = useAddRateMutation({
 *   variables: {
 *      rateCardId: // value for 'rateCardId'
 *      productId: // value for 'productId'
 *      effectiveAt: // value for 'effectiveAt'
 *      entitled: // value for 'entitled'
 *      rate: // value for 'rate'
 *      endingBefore: // value for 'endingBefore'
 *   },
 * });
 */
export function useAddRateMutation(baseOptions?: Apollo.MutationHookOptions<AddRateMutation, AddRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRateMutation, AddRateMutationVariables>(AddRateDocument, options);
      }
export type AddRateMutationHookResult = ReturnType<typeof useAddRateMutation>;
export type AddRateMutationResult = Apollo.MutationResult<AddRateMutation>;
export type AddRateMutationOptions = Apollo.BaseMutationOptions<AddRateMutation, AddRateMutationVariables>;
export const RateCardEditDetailsDocument = gql`
    query RateCardEditDetails($id: uuid!, $rateCountLimit: numeric = 1000) {
  products_and_rate_cards {
    rate_card(id: $id) {
      id
      name
      description
      aliases {
        data {
          alias
        }
        starting_at
        ending_before
      }
      rate_schedule(limit: $rateCountLimit, try_product_order_sort: true) {
        next_page
        scalar_segments
      }
      fiat_credit_type {
        ...CreditType
        __environment_type: environment_type
      }
      credit_type_conversions {
        custom_credit_type {
          ...CreditType
          __environment_type: environment_type
        }
        fiat_per_custom_credit
      }
    }
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useRateCardEditDetailsQuery__
 *
 * To run a query within a React component, call `useRateCardEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateCardEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateCardEditDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      rateCountLimit: // value for 'rateCountLimit'
 *   },
 * });
 */
export function useRateCardEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<RateCardEditDetailsQuery, RateCardEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RateCardEditDetailsQuery, RateCardEditDetailsQueryVariables>(RateCardEditDetailsDocument, options);
      }
export function useRateCardEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RateCardEditDetailsQuery, RateCardEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RateCardEditDetailsQuery, RateCardEditDetailsQueryVariables>(RateCardEditDetailsDocument, options);
        }
export type RateCardEditDetailsQueryHookResult = ReturnType<typeof useRateCardEditDetailsQuery>;
export type RateCardEditDetailsLazyQueryHookResult = ReturnType<typeof useRateCardEditDetailsLazyQuery>;
export type RateCardEditDetailsQueryResult = Apollo.QueryResult<RateCardEditDetailsQuery, RateCardEditDetailsQueryVariables>;
export const ProductsDocument = gql`
    query Products {
  contract_pricing {
    products(archived: ALL) {
      ...ProductListItemId
      ...ProductListItemName
      ...ProductListItemType
      ...ProductListItemTags
      ...ProductListItemArchived
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}
${ProductListItemTypeFragmentDoc}
${ProductListItemTagsFragmentDoc}
${ProductListItemArchivedFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const GetCustomCreditTypesDocument = gql`
    query GetCustomCreditTypes {
  CreditType(
    where: {client_id: {_is_null: false}, environment_type: {_is_null: false}, archived_at: {_is_null: true}}
  ) {
    ...CreditType
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useGetCustomCreditTypesQuery__
 *
 * To run a query within a React component, call `useGetCustomCreditTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomCreditTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomCreditTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomCreditTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomCreditTypesQuery, GetCustomCreditTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomCreditTypesQuery, GetCustomCreditTypesQueryVariables>(GetCustomCreditTypesDocument, options);
      }
export function useGetCustomCreditTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomCreditTypesQuery, GetCustomCreditTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomCreditTypesQuery, GetCustomCreditTypesQueryVariables>(GetCustomCreditTypesDocument, options);
        }
export type GetCustomCreditTypesQueryHookResult = ReturnType<typeof useGetCustomCreditTypesQuery>;
export type GetCustomCreditTypesLazyQueryHookResult = ReturnType<typeof useGetCustomCreditTypesLazyQuery>;
export type GetCustomCreditTypesQueryResult = Apollo.QueryResult<GetCustomCreditTypesQuery, GetCustomCreditTypesQueryVariables>;
export const GetAllCreditTypesDocument = gql`
    query GetAllCreditTypes {
  CreditType(where: {archived_at: {_is_null: true}}) {
    ...CreditType
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useGetAllCreditTypesQuery__
 *
 * To run a query within a React component, call `useGetAllCreditTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCreditTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCreditTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCreditTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCreditTypesQuery, GetAllCreditTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCreditTypesQuery, GetAllCreditTypesQueryVariables>(GetAllCreditTypesDocument, options);
      }
export function useGetAllCreditTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCreditTypesQuery, GetAllCreditTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCreditTypesQuery, GetAllCreditTypesQueryVariables>(GetAllCreditTypesDocument, options);
        }
export type GetAllCreditTypesQueryHookResult = ReturnType<typeof useGetAllCreditTypesQuery>;
export type GetAllCreditTypesLazyQueryHookResult = ReturnType<typeof useGetAllCreditTypesLazyQuery>;
export type GetAllCreditTypesQueryResult = Apollo.QueryResult<GetAllCreditTypesQuery, GetAllCreditTypesQueryVariables>;