import React from "react";
import { Condition } from "@metronome-industries/json-schema-conditions";
import { EventTypeInputDropdown } from "./EventTypeInputDropdown";
import { PropertyConditionInputDropdown } from "./PropertyConditionInputDropdown";

type OperatorValues = "" | "In" | "Not in" | "Exists" | "Does not exist";

type Operator = {
  [key: string]: OperatorValues;
};

const OPERATOR: Operator = {
  NONE: "",
  IN: "In",
  NOT_IN: "Not in",
  EXISTS: "Exists",
  DOES_NOT_EXIST: "Does not exist",
};

/*
  Rules:
  - Event type must always exist
  - Do not allow for an enum to be set if a property can not exist
  - Default to existence  (e.g. when an enum is created, required is set to true)
  - Duplicate properties are not allowed (should be validated against)
*/

const ConditionInputV2: React.FC<{
  condition: Condition;
  onChange: (condition: Condition) => void;
  onDelete: (condition: Condition) => void;
  validation: (propertyName: string) => boolean;
  maxEnums?: number;
  label?: string;
}> = ({ condition, onChange, onDelete, validation, maxEnums }) => {
  const isEventType = condition.field[0] === "event_type";

  if (isEventType) {
    return (
      <EventTypeInputDropdown
        value={condition.enum?.values || []}
        onChange={(values) =>
          onChange({
            ...condition,
            enum: { not: condition.enum?.not ?? false, values },
          })
        }
        filterType={
          !condition.enum ? "accept_all" : condition.enum.not ? "not_in" : "in"
        }
        onFilterTypeChange={(filterType) =>
          onChange({
            ...condition,
            enum:
              filterType === "accept_all"
                ? undefined
                : {
                    values: condition.enum?.values ?? [],
                    not: filterType === "not_in",
                  },
          })
        }
      />
    );
  } else {
    let operator: OperatorValues;
    if (condition.enum) {
      operator = condition.enum.not ? OPERATOR.NOT_IN : OPERATOR.IN;
    } else if (condition.required !== undefined && !isEventType) {
      operator = condition.required ? OPERATOR.EXISTS : OPERATOR.DOES_NOT_EXIST;
    } else {
      operator = OPERATOR.NONE;
    }
    return (
      <PropertyConditionInputDropdown
        propertyValue={condition.field[1] ?? null}
        onPropertyChange={(value) => {
          const field = condition.field.slice();
          field[1] = value;
          if (field[1]) {
            onChange({
              ...condition,
              field,
            });
          } else {
            /* If the property was completely remove reset state */
            onChange({
              ...condition,
              field: ["properties", ""],
            });
          }
        }}
        value={condition.enum?.values ?? []}
        onChange={(values) =>
          onChange({
            ...condition,
            enum: { not: condition.enum?.not ?? false, values },
          })
        }
        filterType={
          operator === OPERATOR.IN
            ? "in"
            : operator === OPERATOR.NOT_IN
              ? "not_in"
              : operator === OPERATOR.EXISTS
                ? "exists"
                : "does_not_exist"
        }
        onFilterTypeChange={(filterType) => {
          switch (filterType) {
            case "exists":
            case "does_not_exist":
              onChange({
                ...condition,
                enum: undefined,
                required: filterType === "exists",
              });
              break;
            case "in":
            case "not_in":
              onChange({
                ...condition,
                enum: {
                  not: filterType === "not_in",
                  values: condition.enum?.values ?? [],
                },
                required: true,
              });
              break;
            default:
              break;
          }
        }}
        onDelete={() => onDelete(condition)}
        isOptional={!!condition.enum && condition.required === undefined}
        onOptionalChange={(isOptional) => {
          onChange({
            ...condition,
            /* An enum can only be optional (undefined) or required (true) */
            required: isOptional ? undefined : true,
          });
        }}
      />
    );
  }
};

export default ConditionInputV2;
