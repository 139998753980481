import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = React.PropsWithChildren<{ text: string; className?: string }>;

import { IconButton } from "tenaissance/components/IconButton";
import { useSnackbar } from "components/Snackbar";

export const Copy: React.FC<Props> = ({ text, children, className }) => {
  const pushMessage = useSnackbar();

  const button = children || (
    <IconButton theme="tertiary" icon="copy01" size="sm" />
  );

  return (
    <div
      className="inline-flex"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <CopyToClipboard
        text={text}
        onCopy={() => {
          pushMessage({
            content: "Copied!",
            durationMS: 1000,
            type: "success",
          });
        }}
      >
        {button}
      </CopyToClipboard>
    </div>
  );
};
