import { CurrentStep, WizardSection } from "./components/Sidebar";

function findLastUndoneSection(
  steps: {
    isDone: boolean;
  }[],
) {
  const lastIndex = steps.findIndex((s) => !s.isDone);
  if (lastIndex === -1) {
    // If all the sections are done just return the last one.
    return steps.length - 1;
  }

  return lastIndex;
}

export function getActiveStep(steps: WizardSection[]): CurrentStep {
  const section = findLastUndoneSection(steps);
  const group = findLastUndoneSection(steps[section].subStepGroups);
  const subStep = findLastUndoneSection(
    steps[section].subStepGroups[group].subSteps,
  );

  return {
    section,
    group,
    subStep,
  };
}

export function findSubStepByNavigationKey(
  sections: WizardSection[],
  navigationKey: string,
): CurrentStep | undefined {
  for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
    const section = sections[sectionIndex];
    for (
      let groupIndex = 0;
      groupIndex < section.subStepGroups.length;
      groupIndex++
    ) {
      const group = section.subStepGroups[groupIndex];
      for (
        let subStepIndex = 0;
        subStepIndex < group.subSteps.length;
        subStepIndex++
      ) {
        const substep = group.subSteps[subStepIndex];
        if (substep.navigationKey === navigationKey) {
          return {
            section: sectionIndex,
            group: groupIndex,
            subStep: subStepIndex,
          };
        }
      }
    }
  }

  return undefined;
}
