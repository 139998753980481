import { ExternalCommitType } from "types/generated-graphql/__types__";
import {
  useGetCustomerCommitsQuery,
  useGetContractCommitsQuery,
} from "./data.graphql";
import { ApolloError } from "@apollo/client";
import { getName, isCredit } from "pages/Contracts/lib/Commit/Commit";
import { useNow } from "lib/date";

type UseCommitNamesArgs = {
  /**
   * Get commits by customer
   */
  customerId: string;
  /**
   * Get commits by contract
   */
  contractId?: string;
  /**
   * Filter commits by external type. If not provided, both commits/credits will be returned.
   */
  externalType?: ExternalCommitType;
  /**
   * Include v2 fields in the query
   */
  include_v2_fields: boolean;
};

type UseCommitNameResult = {
  data: Array<{ id: string; productName: string }>;
  loading: boolean;
  error: ApolloError | undefined;
};

/**
 * Fetch all commit IDs + associated product name for the given customer ID and contract ID.
 */
export function useCommitProductNames({
  customerId,
  contractId,
  externalType,
  include_v2_fields,
}: UseCommitNamesArgs): UseCommitNameResult {
  const now = useNow();
  const customerCommitsQuery = useGetCustomerCommitsQuery({
    variables: {
      customerId,
    },
  });
  const contractCommitsQuery = useGetContractCommitsQuery({
    variables: {
      customerId,
      contractId: contractId ?? "",
      include_v2_fields,
    },
    skip: contractId === undefined,
  });
  const contract = contractCommitsQuery.data?.Customer_by_pk?.contract;
  const contractCommits =
    "v2_fields" in (contract ?? {})
      ? contract?.v2_fields?.commits_union ?? []
      : [
          ...(contract?.commits_union ?? []),
          ...(contract?.amendments.flatMap((a) => a.commits_union ?? []) ?? []),
        ];
  const commits = [
    ...(customerCommitsQuery.data?.Customer_by_pk?.commits ?? []),
    ...(contractCommits ?? []),
  ];
  const commitNames = [];
  for (const commit of commits) {
    if (
      externalType &&
      externalType !== ExternalCommitType.Credit &&
      isCredit(commit)
    ) {
      continue;
    }
    commitNames.push({
      id: commit.id,
      productName: getName(commit, now),
    });
  }
  return {
    data: commitNames,
    error: customerCommitsQuery.error || contractCommitsQuery.error,
    loading: customerCommitsQuery.loading || contractCommitsQuery.loading,
  };
}
