import React from "react";
import classNames from "classnames";
import { Subtitle, ButtonDropdown, Badge } from "design-system";
import pluralize from "pluralize";

import { Panel } from "components/Panel";
import { ToggleButtons } from "components/ToggleButtons";
import { TextSkeleton } from "components/Skeleton";
import { DefinitionList } from "components/DefinitionList";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useNow, printDateRange } from "lib/date";

import { ProgressBar } from "../../../components/ProgressBar";

import { TabBillingSchedule } from "./TabBillingSchedule";
import { TabSegments } from "./TabSegments";
import { TabLedger } from "./TabLedger";
import { RoundedCurrency } from "lib/credits";
import Decimal from "decimal.js";
import { Commit } from "pages/Contracts/lib/Commit";
import {
  AddManualCommitLedgerEntryDocument,
  ChangeCommitEndDateDocument,
  CommitDetailsFragment,
  UpdateCommitScheduleDocument,
} from "./data.graphql";
import { AsyncCommitUsage } from "../Usage";
import { AddManualCommitLedgerEntryModal } from "./AddManualCommitLedgerEntryModal";
import { ChangeEndDateModal } from "./ChangeEndDateModal";
import { UpdatePostpaidEndModal } from "./UpdatePostpaidEndModal";
import { CommitMetadataFlyover } from "./CommitMetadataFlyover";
import { useAuthCheck } from "lib/useAuthCheck";

export type CommitTab = "ledger" | "access-schedule" | "invoice-schedule";
export function parseCommitTab(name: string): CommitTab | undefined {
  switch (name) {
    case "ledger":
    case "access-schedule":
    case "invoice-schedule":
      return name;
    default:
      return undefined;
  }
}

export interface TabProps {
  commit: CommitDetailsFragment;
  asyncUsage?: AsyncCommitUsage;
}

interface CommitCardProps {
  commit: CommitDetailsFragment;
  focus?: boolean;
  activeTab: CommitTab;
  asyncUsage?: AsyncCommitUsage;
  onTabChange: (tab: CommitTab) => void;
}

export const CommitCard: React.FC<CommitCardProps> = ({
  commit,
  focus,
  activeTab,
  asyncUsage,
  onTabChange,
}) => {
  const now = useNow();
  const [showBorder, setShowBorder] = React.useState(false);
  const panelRef = React.useRef<HTMLDivElement>(null);

  const [flyoverTarget, setFlyoverTarget] = React.useState<{
    type: "commit";
  }>();

  const [showAddManualCommitEntryModal, setShowAddManualCommitEntryModal] =
    React.useState(false);
  const { allowed: canAddManualCommitEntry } = useAuthCheck(
    AddManualCommitLedgerEntryDocument,
    true,
  );
  const [showChangeEndDateModal, setShowChangeEndDateModal] =
    React.useState(false);
  const { allowed: canChangeEndDate } = useAuthCheck(
    ChangeCommitEndDateDocument,
    true,
  );
  const [showUpdatePostpaidEndModal, setShowUpdatePostpaidEndModal] =
    React.useState(false);
  const { allowed: canUpdatePostpaidEnd } = useAuthCheck(
    UpdateCommitScheduleDocument,
    true,
  );

  React.useLayoutEffect(() => {
    if (!focus) {
      return;
    }

    panelRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    setShowBorder(true);
    const timeout = setTimeout(() => {
      setShowBorder(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const dates = Commit.getDateRange(commit);
  const usage = asyncUsage?.forCommit(commit.id);
  const isCredit = Commit.isCredit(commit);
  const isCommitOrCreditExpired = Commit.isExpired(commit);

  return (
    <>
      {/* Flyover */}
      {((): React.ReactElement | null => {
        if (!flyoverTarget) {
          return null;
        }

        const onRequestClose = () => setFlyoverTarget(undefined);

        switch (flyoverTarget.type) {
          case "commit":
            return (
              <CommitMetadataFlyover
                onRequestClose={onRequestClose}
                commit={commit}
              />
            );
        }
      })()}
      {showAddManualCommitEntryModal && (
        <AddManualCommitLedgerEntryModal
          onClose={() => {
            setShowAddManualCommitEntryModal(false);
          }}
          commit={commit}
        />
      )}
      {showUpdatePostpaidEndModal && commit.__typename === "PostpaidCommit" && (
        <UpdatePostpaidEndModal
          onClose={() => {
            setShowUpdatePostpaidEndModal(false);
          }}
          commit={commit}
        />
      )}
      {showChangeEndDateModal && commit.__typename === "PrepaidCommit" && (
        <ChangeEndDateModal
          onClose={() => {
            setShowChangeEndDateModal(false);
          }}
          commit={commit}
        />
      )}
      <Panel
        title={Commit.getName(commit, now)}
        subtitle={`${Commit.printType(commit)}${commit.description ? ` - ${commit.description}` : ""}`}
        badge={
          isCommitOrCreditExpired ? (
            <Badge theme="error" type="dark">
              EXPIRED
            </Badge>
          ) : (
            ""
          )
        }
        className={classNames(
          "my-24 transition-shadow duration-1000 first:mt-12",
          {
            ["shadow-[0_0_0_5px] shadow-deprecated-primary-100"]: showBorder,
          },
        )}
        ref={panelRef}
        controls={
          <ButtonDropdown
            buttonTheme="gray"
            buttonType="fill"
            icon="ellipsisVertical"
            items={[
              {
                label: "View metadata",
                onClick: () => setFlyoverTarget({ type: "commit" }),
              },
              {
                label: "Manage custom fields...",
                routePath: `/connections/custom-fields/${isCredit ? "contract-credit" : "commit"}/${commit.id}`,
              },
              ...(commit.__typename === "PrepaidCommit"
                ? [
                    {
                      label: "Change end date...",
                      onClick: () => setShowChangeEndDateModal(true),
                      disabled: !canChangeEndDate,
                      disabledTooltip:
                        "You do not have permission to perform this action",
                    },
                  ]
                : []),
              ...(commit.__typename === "PostpaidCommit"
                ? [
                    {
                      label: "Change end date...",
                      onClick: () => setShowUpdatePostpaidEndModal(true),
                      disabled: !canUpdatePostpaidEnd,
                      disabledTooltip:
                        "You do not have permission to perform this action",
                    },
                  ]
                : []),
              {
                label: "Add manual ledger entry...",
                onClick: () => setShowAddManualCommitEntryModal(true),
                disabled: !canAddManualCommitEntry,
                disabledTooltip:
                  "You do not have permission to perform this action",
              },
            ]}
          />
        }
      >
        <div className="mb-12 flex gap-24 p-12 pb-0">
          <DefinitionList
            list={[
              {
                label: "Priority",
                value: commit.priority,
              },
              {
                label: "Access schedule",
                value: dates?.start
                  ? printDateRange(now, dates.start, dates.end)
                  : null,
              },
              ...(!isCredit
                ? [
                    {
                      label: "Invoice schedule",
                      value:
                        "invoice_schedule" in commit
                          ? pluralize(
                              "invoice",
                              commit.invoice_schedule?.schedule_items.length ??
                                0,
                              true,
                            )
                          : null,
                    },
                  ]
                : []),
              ...(!isCredit
                ? [
                    {
                      label: "Total invoiced",
                      value: Commit.renderTotalCost(commit),
                    },
                  ]
                : []),
              {
                label: `Total ${isCredit ? "credit" : "commit"}`,
                value: Commit.renderTotalAmount(commit),
              },
              {
                label: "Rollover",
                value:
                  "rollover_fraction" in commit && commit.rollover_fraction
                    ? `${new Decimal(commit.rollover_fraction).mul(100)}%`
                    : null,
              },
            ]}
          />
        </div>
        {asyncUsage && (
          <div className="mt-12 p-12 pt-0">
            {asyncUsage.error ? (
              <ErrorEmptyState
                error={asyncUsage.error}
                title="Error loading usage"
              />
            ) : (
              <>
                <Subtitle
                  level={3}
                  className="flex justify-between uppercase text-deprecated-gray-darkest"
                >
                  <div>
                    Consumed:{" "}
                    {asyncUsage.loading || usage == null ? (
                      <TextSkeleton className="w-[100px]" />
                    ) : (
                      <RoundedCurrency
                        amount={usage.used}
                        creditType={commit.access_schedule.credit_type}
                      />
                    )}
                  </div>
                  <div>
                    Remaining:{" "}
                    {asyncUsage.loading || usage == null ? (
                      <TextSkeleton className="w-[100px]" />
                    ) : (
                      <RoundedCurrency
                        amount={usage.remaining}
                        creditType={commit.access_schedule.credit_type}
                      />
                    )}
                  </div>
                </Subtitle>
                {!asyncUsage.loading && (
                  <ProgressBar
                    progress={usage?.used ?? new Decimal(0)}
                    total={usage?.total ?? new Decimal(0)}
                    isExpired={isCommitOrCreditExpired}
                  />
                )}
              </>
            )}
          </div>
        )}

        <>
          <hr className="border-deprecated-gray-light" />
          <div className="p-12">
            <ToggleButtons<CommitTab>
              light
              value={activeTab}
              className="!mb-0"
              buttonProps={[
                {
                  value: "ledger",
                  label: "Ledger",
                  onChange: onTabChange,
                },
                {
                  value: "access-schedule",
                  label: "Access schedule",
                  onChange: onTabChange,
                },
                ...(!isCredit
                  ? [
                      {
                        value: "invoice-schedule" as const,
                        label: "Invoice schedule",
                        onChange: onTabChange,
                      },
                    ]
                  : []),
              ]}
            />
          </div>
          {activeTab === "access-schedule" ? (
            <TabSegments commit={commit} asyncUsage={asyncUsage} />
          ) : activeTab === "invoice-schedule" ? (
            <TabBillingSchedule commit={commit} asyncUsage={asyncUsage} />
          ) : activeTab === "ledger" ? (
            <TabLedger commit={commit} />
          ) : null}
        </>
      </Panel>
    </>
  );
};
