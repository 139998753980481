import React, { useContext } from "react";
import "/src/tenaissance/tenaissance.css";
import { twMerge } from "tenaissance/twMerge";
import { OptionGroupContext } from "../OptionGroup/OptionGroupContext";

const SIZE_VARIANTS = {
  sm: "h-xl w-xl",
  md: "h-2xl w-2xl",
};

export type RadioButtonProps = {
  /** Indicates whether or not RadioButton is checked */
  checked?: boolean;
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Indicates whether or not Checkbox is disabled */
  disabled?: boolean;
  /** The label for the input. */
  label?: string;
  /** Callback triggered when the state of the input changes */
  onChange?: (meta: { checked: boolean }) => void;
  /** Callback triggered when the input is clicked, regardless of state */
  onClick?: (meta: { checked: boolean }) => void;
  /** Default - "md"; sm = 16px | md = 20px */
  size?: "sm" | "md";
  /** Optional text for a checkbox that sits below the label. Use this to add context for a particular option */
  supportingText?: string;
  /** The value of the RadioButton input */
  value: string | number;
  /** The name of the RadioButton input used to group a set of RadioButtons together */
  name?: string;
};

/**
 * Radio buttons allow users to select just one option from a set, and can also be used to turn
 * an option on or off.
 *  */
export const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  className,
  disabled,
  label,
  onChange,
  onClick,
  size = "md",
  supportingText,
  value,
  name,
}) => {
  const isGroup = useContext(OptionGroupContext);
  const classnames = twMerge(
    "flex items-start",
    isGroup &&
      "border border-gray-200 p-xl rounded-xl has-[*_input:checked]:border-core-slate has-[*_input:checked]:border-2 has-[*_input:checked]:p-[15px] hover:border-gray-600 \
  focus-within:outline-none focus-within:ring-core-jade-green/[.24] focus-within:rounded-xl \
  focus-within:ring-4 cursor-pointer has-[*_input:disabled]:bg-gray-50 has-[*_input:disabled]:cursor-auto has-[*_input:disabled]:border-gray-200",
    className,
  );

  const inputClassnames = twMerge(
    "peer appearance-none rounded-full cursor-pointer border border-gray-200 \
    focus-within:outline-none focus-within:ring-core-jade-green/[.24] focus-within:rounded-full \
    focus-within:ring-4 checked:bg-core-slate disabled:bg-gray-50 checked:border-0 disabled:border",
    SIZE_VARIANTS[size],
    disabled && "border bg-gray-50 cursor-auto",
  );

  return (
    <label className={classnames}>
      <div className="mt-xxs relative inline-flex items-center justify-center">
        <input
          className={inputClassnames}
          type="radio"
          name={name}
          onClick={(e) => onClick?.({ checked: e.currentTarget.checked })}
          onChange={(e) => onChange?.({ checked: e.currentTarget.checked })}
          disabled={disabled}
          checked={checked}
          value={value}
        />
        <div
          className={twMerge(
            "rounded-full absolute hidden peer-checked:block",
            size === "sm" ? "h-sm w-sm" : "h-md w-md",
            disabled ? "bg-gray-200" : "bg-white",
          )}
        />
      </div>
      <div
        className={twMerge(
          "ml-md font-medium text-gray-600",
          size === "md" ? "text-md" : "text-sm",
        )}
      >
        <span className={disabled ? undefined : "cursor-pointer"}>{label}</span>
        {supportingText && <div className="font-normal">{supportingText}</div>}
      </div>
    </label>
  );
};
