import React, { useEffect } from "react";

import { dayjs } from "lib/dayjs";

import { useRequiredParam } from "lib/routes/params";

import styles from "./index.module.less";

import { CustomerTab } from "pages/Customer/components/CustomerTab";
import { useInvoicesQuery, InvoicesQuery } from "./queries.graphql";
import { Invoice, InvoiceSkeleton } from "../../../../components/Invoice";
import { Body, Headline, Icon, Subtitle } from "design-system";
import { StatusPills } from "../../../../components/Invoice/components/StatusPills";
import { renderDate, renderDateRange } from "lib/time";
import { RoundedCurrency } from "lib/credits";
import Decimal from "decimal.js";
import { useParams } from "react-router-dom";
import { useNavigate } from "lib/useNavigate";
import classNames from "classnames";
import { TextSkeleton } from "components/Skeleton";
import { EmptyState } from "tenaissance/components/EmptyState";
import NotFoundPage from "pages/404";
import { useRoutePathMatches } from "lib/routes/useRoutePathMatches";
import { InternalLink } from "components/Typography";
import { InvoiceStatusEnum } from "types/generated-graphql/__types__";
import { InvoicesListItemFragment } from "pages/Contracts/components/Invoices/fragments.graphql";

type Invoice = NonNullable<
  InvoicesQuery["Customer_by_pk"]
>["invoices"]["invoices"][0];

function getOldestDraftInvoiceIds(
  invoices: InvoicesListItemFragment[] | Invoice[],
): string[] {
  const drafts = invoices.filter(
    (invoice) =>
      invoice.status === InvoiceStatusEnum.Draft && invoice.issued_at,
  );
  if (drafts.length === 0) {
    return [];
  }
  const minDate = drafts.reduce((minDate, draft) => {
    const currentDate = new Date(draft.issued_at);
    return currentDate < minDate ? currentDate : minDate;
  }, new Date(drafts[0].issued_at));
  return drafts
    .filter(
      (draft) => new Date(draft.issued_at).getTime() === minDate.getTime(),
    )
    .map((draft) => draft.id);
}

export function isFinalizable(
  invoiceId: string,
  invoices: InvoicesListItemFragment[] | Invoice[],
) {
  const invoice = invoices.find((invoice) => invoice.id === invoiceId);
  if (!invoice) {
    return false;
  }
  // If the invoice isn't the oldest draft, we can't finalize
  if (!getOldestDraftInvoiceIds(invoices).includes(invoiceId)) {
    return false;
  }

  // If the invoice is in the future, we can't finalize it
  if (invoice.issued_at > new Date().toISOString()) {
    return false;
  }

  return true;
}

const InvoiceCardSkeleton: React.FC = () => {
  return (
    <div className={classNames(styles.InvoiceCard, styles.skeleton)}>
      <TextSkeleton />
      <TextSkeleton />
      <TextSkeleton />
      <TextSkeleton />
    </div>
  );
};

const InvoiceCard: React.FC<{
  customerId: string;
  invoice: Invoice;
}> = ({ invoice, customerId }) => {
  const routePath = `/customers/${customerId}/invoices/${invoice.id}`;
  const isActive = useRoutePathMatches([routePath]);

  let title =
    invoice.__typename === "CorrectionInvoice"
      ? "Invoice correction"
      : invoice.status === "DRAFT"
        ? "Draft invoice"
        : `Invoice issued${
            "corrections" in invoice && invoice.corrections.length
              ? " (corrected)"
              : ""
          }`;
  if (
    invoice.__typename === "CreditPurchaseInvoice" &&
    dayjs(invoice.issued_at).isAfter(new Date())
  ) {
    title = "Invoice scheduled";
  }
  if (invoice.status === "VOID") {
    title = "Invoice voided";
  }

  let dateRange = renderDate(new Date(invoice.issued_at), { isUtc: true });
  if (
    invoice.__typename === "AdHocPlanInvoice" ||
    invoice.__typename === "ArrearsInvoice" ||
    invoice.__typename === "ParentInvoice"
  ) {
    dateRange = renderDateRange(
      new Date(invoice.inclusive_start_date),
      dayjs.utc(invoice.exclusive_end_date).subtract(1, "s").toDate(),
      { isUtc: true },
      false,
    );
  }

  return (
    <InternalLink
      routePath={routePath}
      className={classNames(styles.InvoiceCard, {
        [styles.active]: isActive,
      })}
    >
      <div className={styles.header}>
        <Headline level={6}>{title}</Headline>
        <StatusPills invoice={invoice} compact light={!isActive} />
      </div>
      <div className={styles.dateRange}>
        <Icon icon="calendar" className={styles.icon} />
        {dateRange}
      </div>
      <div className={styles.dateRange}>
        <Icon icon="cash" className={styles.icon} />
        <RoundedCurrency
          amount={new Decimal(invoice.total)}
          creditType={invoice.credit_type}
        />
      </div>
    </InternalLink>
  );
};

const PlanTimelineCard: React.FC<{
  planName: string;
  type: "start" | "end";
  date: Date;
}> = ({ planName, type, date }) => {
  const title = type === "start" ? "New plan" : "Plan cancelled";

  return (
    <div className={styles.PlanTimelineCard}>
      <div className={styles.header}>
        <Icon icon="documentText" />
        <Headline level={6}>{title}</Headline>
        <div className={styles.date}>
          <Icon icon="today" className={styles.icon} />
          <Subtitle level={4}>
            {renderDate(new Date(date), {
              isUtc: true,
            })}
          </Subtitle>
        </div>
      </div>
      <Body level={2} className={styles.planName}>
        {planName}
      </Body>
    </div>
  );
};

type TimelineEntry =
  | Invoice
  | {
      __typename: "StartCustomerPlan"; // Bit of a bastardisation of __typename, but it works
      inclusiveStartDate: Date;
      planName: string;
      id: string;
    }
  | {
      __typename: "EndCustomerPlan"; // Bit of a bastardisation of __typename, but it works
      exclusiveEndDate: Date;
      planName: string;
      id: string;
    };

function getDateForTimelineEntry(e: TimelineEntry): Date {
  switch (e.__typename) {
    case "StartCustomerPlan":
      return e.inclusiveStartDate;
    case "EndCustomerPlan":
      return e.exclusiveEndDate;
    case "AdHocPlanInvoice":
    case "ArrearsInvoice":
    case "ParentInvoice":
      return new Date(e.inclusive_start_date);
    case "ContractScheduledInvoice":
    case "ContractProServiceInvoice":
    case "ContractUsageInvoice":
    case "AdhocContractUsageInvoice":
    case "ContractRefundInvoice":
    case "ContractPostpaidTrueupInvoice":
    case "CreditPurchaseInvoice":
    case "AdvanceInvoice":
    case "CorrectionInvoice":
    case "SeatPurchaseInvoice":
      return new Date(e.issued_at);
  }
  e satisfies never;
}

const InvoicesTab: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const { invoiceId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useInvoicesQuery({
    variables: {
      customer_id: customerId,
    },
  });

  useEffect(() => {
    if (!invoiceId && data?.Customer_by_pk?.invoices) {
      const mostRecentInvoice = data.Customer_by_pk.invoices.invoices[0];
      if (mostRecentInvoice) {
        navigate(`/customers/${customerId}/invoices/${mostRecentInvoice.id}`, {
          replace: true,
        });
      }
    }
  }, [invoiceId, data]);

  if (loading) {
    return (
      <CustomerTab noScroll={true}>
        <div className={styles.container}>
          <div className={styles.leftColumn}>
            <Subtitle level={2} className={styles.historySubtitle}>
              History
            </Subtitle>
            {[...Array(5)].map((_, i) => (
              <InvoiceCardSkeleton key={i} />
            ))}
          </div>
          <div className={styles.rightColumn}>
            <InvoiceSkeleton />
          </div>
        </div>
      </CustomerTab>
    );
  }

  if (error) {
    return (
      <CustomerTab noScroll={true}>
        <EmptyState
          icon="faceFrown"
          mainText="Error loading invoices."
          supportingText="Don't worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
        />
      </CustomerTab>
    );
  }

  if (!data?.Customer_by_pk) {
    return <NotFoundPage />;
  }

  const timelineEntries: TimelineEntry[] = [
    ...data.Customer_by_pk.invoices.invoices,
    ...data.Customer_by_pk.CustomerPlans.flatMap((cp) => {
      if (cp.cancellation_date) {
        return [
          {
            __typename: "StartCustomerPlan" as const,
            inclusiveStartDate: new Date(cp.start_date),
            id: cp.id,
            planName: cp.Plan.name,
          },
          {
            __typename: "EndCustomerPlan" as const,
            exclusiveEndDate: new Date(cp.cancellation_date),
            id: cp.id,
            planName: cp.Plan.name,
          },
        ];
      }
      return {
        __typename: "StartCustomerPlan" as const,
        inclusiveStartDate: new Date(cp.start_date),
        id: cp.id,
        planName: cp.Plan.name,
      };
    }),
  ]
    .filter((e) => {
      if (
        e.__typename === "StartCustomerPlan" ||
        e.__typename === "EndCustomerPlan"
      ) {
        return getDateForTimelineEntry(e) <= new Date();
      }
      return true;
    })
    .sort((a, b) => {
      const [aDate, bDate] = [a, b].map(getDateForTimelineEntry);

      if (aDate.getTime() === bDate.getTime()) {
        // If the dates are the same, we want to sort by the order of the tieBreakers array.
        // Things later in the array will appear lower on the page
        const tieBreakers: TimelineEntry["__typename"][] = [
          "ParentInvoice",
          "CorrectionInvoice",
          "CreditPurchaseInvoice",
          "ArrearsInvoice",
          "AdvanceInvoice",
          "AdHocPlanInvoice",
          "StartCustomerPlan",
          "EndCustomerPlan",
        ];
        return (
          tieBreakers.indexOf(a.__typename) - tieBreakers.indexOf(b.__typename)
        );
      }
      return bDate.getTime() - aDate.getTime();
    });

  let contents: React.ReactNode = <InvoiceSkeleton />;
  if (invoiceId) {
    contents = (
      <Invoice
        invoiceID={invoiceId}
        customerID={customerId}
        isFinalizable={isFinalizable(
          invoiceId,
          data.Customer_by_pk.invoices.invoices,
        )}
      />
    );
  } else if (!loading) {
    contents = (
      <EmptyState
        icon="receiptCheck"
        mainText="No invoices found for this customer"
        supportingText=""
      />
    );
  }

  return (
    <CustomerTab noScroll={true}>
      <div className={styles.container}>
        <div className={styles.leftColumn}>
          {timelineEntries.length ? (
            <Subtitle level={2} className={styles.historySubtitle}>
              History
            </Subtitle>
          ) : null}
          {timelineEntries.map((entry) => {
            if (entry.__typename === "StartCustomerPlan") {
              return (
                <PlanTimelineCard
                  key={`${entry.__typename}-${entry.id}`}
                  type="start"
                  date={entry.inclusiveStartDate}
                  planName={entry.planName}
                />
              );
            } else if (entry.__typename === "EndCustomerPlan") {
              return (
                <PlanTimelineCard
                  key={`${entry.__typename}-${entry.id}`}
                  type="end"
                  date={entry.exclusiveEndDate}
                  planName={entry.planName}
                />
              );
            } else {
              return (
                <InvoiceCard
                  key={`${entry.__typename}-${entry.id}`}
                  invoice={entry}
                  customerId={customerId}
                />
              );
            }
          })}
        </div>
        <div className={styles.rightColumn}>{contents}</div>
      </div>
    </CustomerTab>
  );
};

export default InvoicesTab;
