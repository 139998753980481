import React from "react";
import { Copy } from "../Copy";
import { Button } from "../Button";

type CopyableIDProps = {
  id: string;
  className?: string;
};

export const CopyableID: React.FC<CopyableIDProps> = (props) => {
  return (
    <Copy text={props.id}>
      <Button
        text={props.id}
        leadingIcon="copy01"
        theme="linkGray"
        className={props.className}
      />
    </Copy>
  );
};
