import React, { useState } from "react";
import { PageContainer } from "components/PageContainer";
import { useCustomerByIdQuery, EventDetailsDocument } from "./queries.graphql";
import { DateRange } from "components/RelativeDateRangeSelector";
import { EventsTable, EventsTableRef } from "./components/EventsTable";
import { queryStringToFilter } from "./lib/query";
import { useLocation } from "react-router-dom";
import { EventsGraph, EventsGraphRef } from "./components/EventGraph";
import { EventDetail } from "./components/EventDetail";
import { useUIMode } from "../../lib/useUIMode";
import { AuthCheck } from "../../components/AuthCheck";
import { EventFilters } from "./components/EventFilters";

export const Events = () => {
  const location = useLocation();
  const tableRef = React.useRef<EventsTableRef>(null);
  const graphRef = React.useRef<EventsGraphRef>(null);

  const filters = queryStringToFilter(location.search);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);

  const { newUIEnabled } = useUIMode();

  const selectedCustomerResults = useCustomerByIdQuery({
    skip: !filters.customerID,
    variables: {
      id: filters.customerID ?? "",
    },
  });

  const filteredIngestAliases = filters.customerID
    ? selectedCustomerResults.data?.Customer_by_pk?.CustomerIngestAliases.map(
        (alias) => alias.ingest_alias,
      ).concat([filters.customerID])
    : filters.ingestAliases;

  const content = (
    <div>
      <EventFilters
        graphRef={graphRef}
        tableRef={tableRef}
        filters={filters}
        selectedCustomerResults={selectedCustomerResults}
        setDateRange={setDateRange}
      />
      <EventsGraph
        ref={graphRef}
        startingAfter={dateRange?.inclusiveStart ?? new Date()}
        endingBefore={dateRange?.exclusiveEnd ?? new Date()}
        ingest_aliases={filteredIngestAliases}
        duplicates={filters.duplicates}
        billableMetricIDs={filters.billableMetrics}
        transaction_ids={filters.transactionIDs}
      />
      {filters.transactionIDs ? (
        <EventDetail
          eventID={filters.eventID}
          transactionIDs={filters.transactionIDs}
          startingAfter={dateRange?.inclusiveStart ?? new Date()}
          endingBefore={dateRange?.exclusiveEnd ?? new Date()}
          ingest_aliases={filteredIngestAliases}
          billableMetricIDs={filters.billableMetrics}
          duplicates={filters.duplicates}
        />
      ) : (
        <EventsTable
          ref={tableRef}
          startingAfter={dateRange?.inclusiveStart ?? new Date()}
          endingBefore={dateRange?.exclusiveEnd ?? new Date()}
          ingest_aliases={filteredIngestAliases}
          billableMetricIDs={filters.billableMetrics}
          duplicates={filters.duplicates}
        />
      )}
    </div>
  );

  return newUIEnabled ? (
    <AuthCheck authDoc={EventDetailsDocument}>{content}</AuthCheck>
  ) : (
    <PageContainer title="Events" authDoc={EventDetailsDocument}>
      {content}
    </PageContainer>
  );
};

export default Events;
