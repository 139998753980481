import { BillableMetric } from "types/generated-graphql/__types__";

const FANCY_USAGE_VALUE_COLUMN_NAME = "value";
export function getGroupKeys(
  billableMetric: Pick<BillableMetric, "fancy_plan" | "group_keys">,
): (string | string[])[] {
  if (billableMetric.fancy_plan) {
    const queryPlan =
      "queryPlan" in billableMetric.fancy_plan
        ? billableMetric.fancy_plan.queryPlan
        : billableMetric.fancy_plan;
    const allColumns = Object.keys(
      (queryPlan as any).exprs as Record<string, any>,
    );
    const valueColumn = allColumns.includes(FANCY_USAGE_VALUE_COLUMN_NAME)
      ? FANCY_USAGE_VALUE_COLUMN_NAME
      : allColumns[0];
    const groupColumns = allColumns.filter((c) => c !== valueColumn);
    if (groupColumns.length > 1) {
      return [groupColumns];
    } else {
      return groupColumns;
    }
  }

  if (!billableMetric.group_keys) {
    return [];
  }

  return billableMetric.group_keys as (string | string[])[];
}
