import React, { useEffect, useState } from "react";

import { Breadcrumbs } from "lib/breadcrumbs";

import { useCustomerFromRoute } from "../../lib/Customer";
import { CustomerLayout } from "../CustomerLayout";
import { Card } from "../../../../tenaissance/components/Card";
import {
  UpdateIngestAliasesDocument,
  useGetCustomerSettingsInfoQuery,
} from "../../../Customer/tabs/Settings/sections/Identifiers/queries.graphql";
import { startOfHour } from "date-fns";
import { Body, Tooltip } from "../../../../design-system";
import { GatedButton } from "../../../../components/GatedButton";
import { ArchiveCustomerDocument } from "../../../../lib/customers/components/ArchiveCustomerModal/queries.graphql";
import ArchiveCustomerModal from "../../../../lib/customers/components/ArchiveCustomerModal";
import { EditCustomerNameButton } from "../../../Customer/components/EditCustomerNameButton";
import { SectionLabel } from "../../../../tenaissance/components/SectionLabel";
import { CopyableID } from "../../../../tenaissance/components/CopyableID";
import { TextSkeleton } from "../../../../components/Skeleton";
import styles from "../../../Customer/tabs/Settings/sections/Identifiers/index.module.less";
import { twMerge } from "../../../../design-system/twMerge";
import {
  AddIngestAliasModal,
  DeleteIngestAliasButton,
} from "../../../../components/IngestAliases";
import { CustomField, formatCustomFields } from "../../../CustomFields";
import {
  useDeleteManagedFieldOnCustomerMutation,
  useGetCustomerCustomFieldsLazyQuery,
  useSetManagedFieldOnCustomerMutation,
} from "../../../CustomFields/customFields.graphql";
import { EditNameButton } from "../../../../components/EditNameButton";
import { Button } from "../../../../tenaissance/components/Button";
import { BillingProviderSection } from "../../../Customer/tabs/Settings/sections/BillingProvider";
import { useUIMode } from "../../../../lib/useUIMode";

export const CustomerSettings: React.FC = () => {
  const req = useCustomerFromRoute();

  return (
    <CustomerLayout
      rootReq={req}
      breadcrumbs={({ customer }) =>
        Breadcrumbs.from(
          {
            type: "back",
            label: "Back to customer list",
            routePath: "/customers",
          },
          {
            label: customer.name,
            routePath: `/customers/${customer.id}`,
          },
        )
      }
      content={({ customer }) => (
        <div className="flex h-full w-full space-x-12 pt-12">
          <div className="basis-1/3">
            <CustomerManagementSection customerID={customer.id} />
          </div>
          <div className="flex-grow">
            <BillingProviderSection customerID={customer.id} />
          </div>
        </div>
      )}
    />
  );
};

const MAX_COLLAPSED_INGEST_ALIASES = 5;

export const CustomerManagementSection: React.FC<{
  customerID: string;
}> = ({ customerID }) => {
  const [customerArchiveModalOpen, setCustomerArchiveModalOpen] =
    useState(false);
  const [ingestAliasModalOpen, setIngestAliasModalOpen] = useState(false);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const { newUIEnabled } = useUIMode();

  const {
    data: customerSettingsInfoData,
    loading: customerSettingsInfoLoading,
  } = useGetCustomerSettingsInfoQuery({
    variables: {
      customer_id: customerID,
      date: startOfHour(new Date()).toISOString(),
    },
  });
  const [
    getCustomerCustomFields,
    { data: customerCustomFieldsData, loading: customerCustomFieldsLoading },
  ] = useGetCustomerCustomFieldsLazyQuery();
  const [setCustomerCustomField, { loading: setCustomerCustomFieldsLoading }] =
    useSetManagedFieldOnCustomerMutation();
  const [
    deleteCustomerCustomFieldValue,
    { loading: deleteCustomerCustomFieldsLoading },
  ] = useDeleteManagedFieldOnCustomerMutation();
  useEffect(() => {
    void getCustomerCustomFields({
      variables: {
        customer_id: customerID,
      },
    });
  }, [customerID]);

  useEffect(() => {
    setCustomFields(
      formatCustomFields(customerCustomFieldsData?.ManagedFieldKey ?? []),
    );
  }, [customerCustomFieldsData]);

  const canArchive =
    (customerSettingsInfoData?.Customer?.active_customer_plans.length || 0) ===
      0 && customerSettingsInfoData?.Customer?.archived_at === null;
  const archiveDisabledReason =
    customerSettingsInfoData?.Customer?.archived_at !== null
      ? "Customer already archived"
      : "Customers with plans cannot be archived";

  const showLoadingState =
    customerSettingsInfoLoading ||
    !customerSettingsInfoData?.Customer ||
    customerCustomFieldsLoading;

  const [showAllIngestAliases, setShowAllIngestAliases] = useState(false);

  let ingestAliasBlock: React.ReactElement;
  if (customerSettingsInfoLoading) {
    ingestAliasBlock = <TextSkeleton />;
  } else if (
    !customerSettingsInfoData?.Customer?.CustomerIngestAliases.length
  ) {
    ingestAliasBlock = <>No ingest aliases</>;
  } else {
    const customerIngestAliases =
      customerSettingsInfoData.Customer.CustomerIngestAliases;
    ingestAliasBlock = (
      <>
        <ul>
          {customerIngestAliases
            .slice(
              0,
              showAllIngestAliases ? undefined : MAX_COLLAPSED_INGEST_ALIASES,
            )
            .map(({ ingest_alias }, _, all) => (
              <li key={ingest_alias} className="flex items-center">
                {ingest_alias}
                <DeleteIngestAliasButton
                  customerId={customerID}
                  ingestAlias={ingest_alias}
                  currentAliases={all.map(({ ingest_alias }) => ingest_alias)}
                />
              </li>
            ))}
        </ul>

        {!!customerIngestAliases &&
          customerIngestAliases.length > MAX_COLLAPSED_INGEST_ALIASES && (
            <Button
              leadingIcon={showAllIngestAliases ? "chevronUp" : "chevronDown"}
              theme="linkGray"
              onClick={(_) => setShowAllIngestAliases(!showAllIngestAliases)}
              text={
                showAllIngestAliases
                  ? "Collapse"
                  : `${customerIngestAliases?.length - MAX_COLLAPSED_INGEST_ALIASES} more`
              }
            />
          )}
      </>
    );
  }

  const [showAllCustomFields, setShowAllCustomFields] = useState(false);

  let customFieldsBlock: React.ReactElement;
  const collapsedCustomFieldsListThreshold = 5;
  if (customerCustomFieldsLoading) {
    customFieldsBlock = <TextSkeleton />;
  } else if (
    !customerCustomFieldsData?.ManagedFieldKey.length ||
    !customFields.length
  ) {
    customFieldsBlock = <>No custom fields</>;
  } else {
    customFieldsBlock = (
      <>
        <ul className="grid gap-[8px]">
          {customFields
            ?.slice(
              0,
              showAllCustomFields
                ? undefined
                : collapsedCustomFieldsListThreshold,
            )
            .map(({ key, value }, _, all) => (
              <li
                className={twMerge(styles.customFields, "flex flex-col")}
                key={key.id}
              >
                {key.value}
                <div className="flex flex-row gap-8">
                  {value ? (
                    <CopyableID id={value.value} className="font-normal" />
                  ) : (
                    <></>
                  )}
                  <EditNameButton
                    useAddIcon={false}
                    className="p-0"
                    title="Edit custom field value"
                    body={`Change the value of this Metronome customer's custom field key ${key.value}`}
                    label="Custom field value"
                    allowEmpty
                    currentName={value?.value || ""}
                    updateName={async (name?: string) => {
                      if (name) {
                        await setCustomerCustomField({
                          variables: {
                            customer_id: customerID,
                            key_id: key.id,
                            value: name,
                          },
                          update(cache) {
                            cache.evict({
                              fieldName: "ManagedFieldKey",
                            });
                          },
                        });
                      } else if (value) {
                        // delete custom field
                        await deleteCustomerCustomFieldValue({
                          variables: {
                            id: value.id,
                          },
                          update(cache) {
                            cache.evict({
                              fieldName: "ManagedFieldKey",
                            });
                          },
                        });
                      }
                    }}
                    loading={
                      setCustomerCustomFieldsLoading &&
                      deleteCustomerCustomFieldsLoading
                    }
                  />
                </div>
              </li>
            ))}
        </ul>

        {!!customFields &&
          customFields.length > collapsedCustomFieldsListThreshold && (
            <Button
              leadingIcon={showAllCustomFields ? "chevronUp" : "chevronDown"}
              theme="linkGray"
              onClick={(_) => setShowAllCustomFields(!showAllCustomFields)}
              text={
                showAllCustomFields
                  ? "Collapse"
                  : `${customFields.length - collapsedCustomFieldsListThreshold} more`
              }
            />
          )}
      </>
    );
  }

  return (
    <div>
      {customerArchiveModalOpen && customerSettingsInfoData?.Customer && (
        <ArchiveCustomerModal
          customerId={customerSettingsInfoData.Customer.id}
          customerName={customerSettingsInfoData.Customer.name}
          onClose={() => {
            setCustomerArchiveModalOpen(false);
          }}
        />
      )}
      {ingestAliasModalOpen && (
        <AddIngestAliasModal
          customerName={customerSettingsInfoData?.Customer?.name ?? customerID}
          currentAliases={
            customerSettingsInfoData?.Customer?.CustomerIngestAliases.map(
              ({ ingest_alias }) => ingest_alias,
            ) ?? []
          }
          onClose={() => setIngestAliasModalOpen(false)}
          customerId={customerID}
        />
      )}
      <Card
        wrapContents={false}
        title="Customer management"
        loading={showLoadingState}
        headerActions={[
          <Tooltip content={archiveDisabledReason} disabled={canArchive}>
            <GatedButton
              className="mr-12"
              disabled={!canArchive}
              onClick={() => {
                setCustomerArchiveModalOpen(true);
              }}
              leadingIcon="userX01"
              doc={ArchiveCustomerDocument}
              text="Archive customer"
              theme="linkDestructive"
            />
          </Tooltip>,
        ]}
      >
        <div className="pv-[12px] ph-[16px] grid max-h-[calc(100vh_-_50px)] gap-[24px] overflow-y-auto font-['Inter'] text-sm font-normal text-gray-600">
          <div className="grid gap-[12px]">
            <SectionLabel title="Name" />
            <div className="flex flex-row items-center">
              <Body className="text-sm font-normal text-gray-600">
                {customerSettingsInfoData?.Customer?.name}
              </Body>
              <EditCustomerNameButton
                customerId={customerID}
                currentName={customerSettingsInfoData?.Customer?.name || ""}
              />
            </div>
          </div>

          <div className="grid gap-[12px]">
            <SectionLabel title="ID" />
            <div className="flex flex-row items-center">
              <CopyableID id={customerID} className="font-normal"></CopyableID>
            </div>
          </div>

          <div className="grid gap-[12px]">
            <SectionLabel title="Ingest aliases" />
            {ingestAliasBlock}
            <GatedButton
              onClick={() => setIngestAliasModalOpen(true)}
              doc={UpdateIngestAliasesDocument}
              text="Add"
              theme="secondary"
              leadingIcon="plus"
              size="sm"
            />
          </div>

          <div className="grid gap-[12px]">
            <SectionLabel
              title="Custom fields"
              leadingIcon="arrowNarrowUpRight"
              href={
                newUIEnabled
                  ? `/connections/custom-fields/customer/${customerID}`
                  : `/custom-fields/customer/${customerID}`
              }
              tooltip={{
                label: "Edit custom fields",
              }}
            />
            {customFieldsBlock}
          </div>
        </div>
      </Card>
    </div>
  );
};
