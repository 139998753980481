/* 6a780b59f0bb0218814944f9f7cca743a23f907f
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  starting_after: Types.Scalars['timestamptz'];
  ending_before: Types.Scalars['timestamptz'];
}>;


export type EventsQuery = { __typename?: 'Query', duplicates: Array<{ __typename?: 'MRI_EventCountWindow', starting_on: string, count: string }>, non_duplicates: Array<{ __typename?: 'MRI_EventCountWindow', starting_on: string, count: string }> };


export const EventsDocument = gql`
    query Events($environment_type: EnvironmentTypeEnum_enum!, $starting_after: timestamptz!, $ending_before: timestamptz!) {
  duplicates: mri_event_count(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    duplicates: true
  ) {
    starting_on
    count
  }
  non_duplicates: mri_event_count(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    duplicates: false
  ) {
    starting_on
    count
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      starting_after: // value for 'starting_after'
 *      ending_before: // value for 'ending_before'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;