import { FormController } from "lib/FormController";
import { Schema } from "../Schema";

export const useRateCardCreateController = FormController.createHook(
  Schema.RateCardInput,
  {
    init(snapshotKey: string) {
      return FormController.parseJsonSnapshot(
        Schema.RateCardInput,
        sessionStorage.getItem(snapshotKey),
      );
    },
  },
);
export const useCreditTypeConversionController =
  useRateCardCreateController.child(Schema.CreditTypeConversion, {
    read(parent, index) {
      return parent.get("creditTypeConversions")?.[index];
    },
    write(child, parent, index) {
      const existingCreditTypeConversions =
        parent.get("creditTypeConversions") ?? [];
      return {
        creditTypeConversions: existingCreditTypeConversions.map((ctc, i) =>
          i === index ? child.getUnvalidatedInputs() : ctc,
        ),
      };
    },
  });

// For the first tier, size is equal to lastUnit
// For subsequent tiers, size is lastUnit - previous tier's lastUnit
export const convertToGraphqlTiers = (tiers: Schema.Types.Tier[]) => {
  return tiers.map((t, index) => {
    let size: number | undefined;
    if (index === 0) {
      size = t.lastUnit;
    } else {
      const prevLastUnit = tiers[index - 1].lastUnit;
      size =
        t.lastUnit !== undefined && prevLastUnit !== undefined
          ? t.lastUnit - prevLastUnit
          : undefined;
    }

    return {
      size: size !== undefined ? String(size) : undefined,
      unit_price: String(t.unitPrice),
    };
  });
};

export type RootFormController = ReturnType<typeof useRateCardCreateController>;
