import React, { useEffect } from "react";
import styles from "./index.module.less";
import { Select, Option as OptionType, Icon } from "design-system";
import {
  components,
  MenuProps,
  OptionProps,
  SingleValueProps,
  ValueContainerProps,
} from "react-select";
import { renderDateRange } from "lib/time";
import { Option } from "./components/Option";
import { Menu, DateInterval as MenuDateInterval } from "./components/Menu";
import { dayjs, Dayjs } from "lib/dayjs";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

const UTC_TIMEZONE = "Etc/UTC";

export type DateRange = {
  inclusiveStart: Date;
  exclusiveEnd: Date;
};

export type RelativeDateRanges = "7d" | "30d" | "60d" | "90d";

type DefaultValue = RelativeDateRanges | DateRange;

type RelativeDateRangeSelectorProps = {
  onChange: (range: DateRange) => void;
  defaultValue?: DefaultValue;
  utc: boolean;
};

const maybeUTC = (d: Dayjs, utc: boolean) => (utc ? d.utc() : d);

function isDefaultCustom(v: DefaultValue | undefined): v is DateRange {
  return typeof v == "object";
}

function ValueContainerWithCalendar<T>({
  children,
  ...props
}: ValueContainerProps<T>) {
  return (
    <components.ValueContainer
      {...props}
      className="!flex !flex-row !flex-nowrap !items-center"
    >
      <Icon icon="calendar" className="mr-8" />
      <div className="flex flex-row">{children}</div>
    </components.ValueContainer>
  );
}

export const RelativeDateRangeSelector: React.FC<
  RelativeDateRangeSelectorProps
> = (props) => {
  const [selectedOption, setSelectedOption] = React.useState<
    RelativeDateRanges | "custom" | undefined
  >(isDefaultCustom(props.defaultValue) ? "custom" : props.defaultValue);

  const [customRange, setCustomRange] = React.useState<MenuDateInterval>([
    isDefaultCustom(props.defaultValue)
      ? props.defaultValue.inclusiveStart
      : null,
    isDefaultCustom(props.defaultValue)
      ? dayjs(props.defaultValue.exclusiveEnd).subtract(1, "day").toDate()
      : null,
  ]);

  useEffect(() => {
    if (selectedOption !== "custom") {
      setCustomRange([null, null]);
    }
  }, [selectedOption]);

  useEffect(() => {
    // call props.onChange with the selected range
    if (selectedOption === "custom") {
      if (customRange.every((d) => !!d)) {
        props.onChange({
          inclusiveStart: maybeUTC(dayjs(customRange[0]), props.utc)
            .startOf("day")
            .toDate(),
          exclusiveEnd: maybeUTC(dayjs(customRange[1]), props.utc)
            .add(1, "day")
            .startOf("day")
            .toDate(),
        });
      }
    } else if (selectedOption) {
      const exclusiveEnd = maybeUTC(dayjs(), props.utc)
        .add(1, "day")
        .startOf("day");
      switch (selectedOption) {
        case "7d":
          props.onChange({
            inclusiveStart: exclusiveEnd.subtract(7, "day").toDate(),
            exclusiveEnd: exclusiveEnd.toDate(),
          });
          return;
        case "30d":
          props.onChange({
            inclusiveStart: exclusiveEnd.subtract(30, "day").toDate(),
            exclusiveEnd: exclusiveEnd.toDate(),
          });
          break;
        case "60d":
          props.onChange({
            inclusiveStart: exclusiveEnd.subtract(60, "day").toDate(),
            exclusiveEnd: exclusiveEnd.toDate(),
          });
          break;
        case "90d":
          props.onChange({
            inclusiveStart: exclusiveEnd.subtract(90, "day").toDate(),
            exclusiveEnd: exclusiveEnd.toDate(),
          });
          break;
      }
    }
  }, [selectedOption, customRange]);

  const isUtc = props.utc;
  const customRangeInLocal: MenuDateInterval =
    customRange[0] && customRange[1]
      ? [
          utcToZonedTime(customRange[0] as Date, UTC_TIMEZONE),
          utcToZonedTime(customRange[1] as Date, UTC_TIMEZONE),
        ]
      : [null, null];

  return (
    <div className={styles.container}>
      <Select
        __internalComponentOverrides={{
          ValueContainer: ValueContainerWithCalendar,
          Menu: (props: MenuProps<OptionType>) => (
            <Menu
              isCustomSelected={selectedOption === "custom"}
              selectedRange={isUtc ? customRangeInLocal : customRange}
              setIsCustomSelected={(v) =>
                setSelectedOption(v ? "custom" : undefined)
              }
              onRangeChange={(range) => {
                if (isUtc) {
                  const start = zonedTimeToUtc(range[0] as Date, UTC_TIMEZONE);
                  const end = zonedTimeToUtc(range[1] as Date, UTC_TIMEZONE);
                  range = [start, end];
                }

                setSelectedOption("custom");
                setCustomRange(range);
                // Call this to cause the menu to close
                props.selectOption({ value: "custom", label: "Custom" });
              }}
              {...props}
            />
          ),
          Option: (props: OptionProps<OptionType>) => (
            <Option setSelectedOption={setSelectedOption} {...props} />
          ),
          SingleValue: (props: SingleValueProps<OptionType>) => {
            if (
              selectedOption !== "custom" ||
              !customRange[0] ||
              !customRange[1]
            ) {
              return <components.SingleValue {...props} />;
            }
            return (
              <components.SingleValue
                {...{
                  ...props,
                  children: renderDateRange(
                    customRange[0],
                    customRange[1],
                    { isUtc },
                    false,
                  ),
                }}
              />
            );
          },
        }}
        onChange={(value) => {
          setSelectedOption(value as RelativeDateRanges);
        }}
        value={selectedOption}
        placeholder="Select date range"
        options={[
          {
            label: "Last 7 days",
            value: "7d",
          },
          {
            label: "Last 30 days",
            value: "30d",
          },
          {
            label: "Last 60 days",
            value: "60d",
          },
          {
            label: "Last 90 days",
            value: "90d",
          },
          {
            label: "Custom",
            value: "custom",
          },
        ]}
      />
    </div>
  );
};
