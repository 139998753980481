import React from "react";
import Decimal from "decimal.js";

import { ButtonDropdown } from "design-system";
import {
  AdditionalTermsTable,
  AdditionalTermRow,
} from "pages/Contracts/components/AdditionalTermsTable";
import { ContractPricing } from "pages/Contracts/lib/ContractPricing";
import { maybeToDayjs, toDayjs, useNow, earliest, latest } from "lib/date";

import { Schema } from "../../Schema";
import { Section } from "../../components/Section";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { resolveRecurringSchedule } from "../../lib/recurringSchedule";
import { findCreditType } from "pages/Contracts/lib/CreditTypes";
import { CreditType } from "types/credit-types";
import { USD_CREDIT_TYPE } from "lib/credits";
import { useFeatureFlag } from "lib/launchdarkly";

interface Props {
  ctrl: Schema.Types.CreateSharedCtrl;
  pricing: ContractPricing.Types.ProductIds &
    ContractPricing.Types.ProductNames;
  fiatCreditTypes?: CreditType[]; // TODO: make non-optional after dealing with all other additional term types
  onAdd: (type: Schema.Types.AdditionalTermType) => void;
  onEdit: (type: Schema.Types.AdditionalTermType, id: string) => void;
  options?: {
    resellerRoyaltiesEnabled?: boolean;
    discountsEnabled?: boolean;
    proServicesEnabled?: boolean;
  };
}

function sortRows(
  a: AdditionalTermRow,
  b: AdditionalTermRow,
  primary: "startDate" | "endDate",
  fallback?: "endDate",
): number {
  const aDate = a[primary];
  const bDate = b[primary];

  if (aDate && bDate) {
    return aDate.diff(bDate) || (fallback ? sortRows(a, b, fallback) : 0);
  }

  if (!aDate && !bDate) {
    return 0;
  }

  return aDate ? -1 : 1;
}

export const AdditionalTermsSection: React.FC<Props> = (props) => {
  const now = useNow();
  const timeframe = DefaultTimeframe.useFromContext();

  const showCurrencyWork = useFeatureFlag<boolean>(
    "contract-currencies",
    false,
  );

  function getRowForTerm(
    type: "scheduled_charge" | "discount",
    term: Schema.Types.Discount | Schema.Types.ScheduledCharge,
  ): AdditionalTermRow {
    const dates =
      term.billingSchedule.type === "recurring"
        ? [term.billingSchedule.startDate, term.billingSchedule.endDate]
        : term.billingSchedule.items.map((si) => si.date).sort();

    const items =
      term.billingSchedule.type === "recurring"
        ? resolveRecurringSchedule(term.billingSchedule).items
        : term.billingSchedule.items;

    const invoiceCount = items.length;
    const total = items.reduce(
      (sum, si) => sum.add(new Decimal(si.unitPrice).mul(si.quantity)),
      new Decimal(0),
    );

    const startDate = dates.at(0);
    const endDate = dates.length > 1 ? dates.at(-1) : null;

    const creditType = showCurrencyWork
      ? findCreditType(
          term.billingScheduleCreditTypeId ?? USD_CREDIT_TYPE.id,
          props.fiatCreditTypes ?? [USD_CREDIT_TYPE],
        )
      : USD_CREDIT_TYPE;

    return {
      name: ContractPricing.getProductName(props.pricing, term.productId, now),
      type: type === "scheduled_charge" ? "Scheduled charge" : "Discount",
      onClick: () => props.onEdit(type, term.id),
      invoiceCount,
      startDate: startDate ? toDayjs(startDate) : null,
      endDate: endDate ? toDayjs(endDate) : null,
      rate:
        term.billingSchedule.type === "fixed"
          ? {
              type: "scheduled",
              invoiceCount,
              total,
              creditType,
            }
          : {
              type: "scheduled_recurring",
              invoiceCount,
              total,
              frequency:
                Schema.RECURRING_FREQUENCY_MAP[term.billingSchedule.frequency],
              creditType,
            },
    };
  }

  const royaltyNameMap = {
    aws: "AWS royalty fee",
    awsProService: "AWS (professional service) royalty fee",
    gcp: "GCP royalty fee",
    gcpProService: "GCP (professional service) royalty fee",
  };

  const rows = [
    ...(props.ctrl.get("scheduledCharges") ?? []).map((sc) =>
      getRowForTerm("scheduled_charge", sc),
    ),

    ...(props.ctrl.get("discounts") ?? []).map((d) =>
      getRowForTerm("discount", d),
    ),

    ...(props.ctrl.get("resellerRoyalties") ?? []).map(
      (rr): AdditionalTermRow => ({
        name: royaltyNameMap[rr.type],
        type: "Royalty fee",
        onClick: () => props.onEdit("reseller_royalty", rr.type),
        invoiceCount: null,
        startDate: toDayjs(latest(rr.startingAt, timeframe.startingAt)),
        endDate:
          maybeToDayjs(earliest(rr.endingBefore, timeframe.endingBefore)) ??
          null,
        rate: {
          type: "royalty",
          fraction: new Decimal(rr.percentage).div(100).toString(),
        },
      }),
    ),

    ...(props.ctrl.get("proServices") ?? []).map(
      (ps): AdditionalTermRow => ({
        name: ContractPricing.getProductName(props.pricing, ps.productId, now),
        type: "Professional service",
        onClick: () => props.onEdit("pro_service", ps.id),
        invoiceCount: null,
        startDate: null,
        endDate: null,
        rate: { type: "pro_service", unitPrice: new Decimal(ps.unitPrice) },
      }),
    ),
  ].sort((a, b) => sortRows(a, b, "startDate", "endDate"));

  return (
    <Section
      title="Additional terms"
      description="Add additional products and terms outside of your rate card."
      actions={
        <ButtonDropdown
          title="Add a term"
          buttonType="fill"
          buttonTheme="primary"
          faket10={true}
          items={[
            {
              label: "Scheduled charge...",
              onClick() {
                props.onAdd("scheduled_charge");
              },
            },
            ...(props.options?.discountsEnabled
              ? [
                  {
                    label: "Discount...",
                    onClick() {
                      props.onAdd("discount");
                    },
                  },
                ]
              : []),
            ...(props.options?.resellerRoyaltiesEnabled
              ? [
                  {
                    label: "Reseller royalties...",
                    onClick() {
                      props.onAdd("reseller_royalty");
                    },
                    disabled:
                      (props.ctrl.get("resellerRoyalties") ?? []).length === 4,
                    disabledTooltip:
                      "A contract can have at most only one of each type of royalty.",
                  },
                ]
              : []),
            ...(props.options?.proServicesEnabled
              ? [
                  {
                    label: "Professional services...",
                    onClick() {
                      props.onAdd("pro_service");
                    },
                  },
                ]
              : []),
          ]}
        />
      }
    >
      {rows.length === 0 ? (
        <div className="flex h-[120px] items-center justify-center rounded-medium border border-deprecated-gray-100 bg-gray-50 text-sm text-gray-600">
          No additional terms
        </div>
      ) : (
        <AdditionalTermsTable
          className="mt-12"
          rows={rows}
          renderT10Table={false}
        />
      )}
    </Section>
  );
};
