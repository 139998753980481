import React from "react";
import initials from "initials";
import { idToColor } from "design-system";
import { twMerge } from "tenaissance/twMerge";

// The initials lib tries to be clever by trusting any string in parens (such as in "Cosmo Wolfe (cannot be trusted), taking 'cannot be trusted' as the initials verbatium.")
// This behavior isn't problematic, unless the string in the parens is too long. Give it a try, and if it's too long try
// stuff to ensure we return a string that is short enough.
function dontBlindlyTrustLongInitials(userName: string) {
  const MAX_LENGTH = 3;

  let result = initials(userName);
  if (result.length <= MAX_LENGTH) {
    return result;
  }

  const nameWithoutParens = userName.replace(/\(([^)]+)\)/, "");
  result = initials(nameWithoutParens);
  return result.slice(0, MAX_LENGTH);
}

export const Avatar: React.FC<{
  userName: string;
  size: number;
  userID: string;
  disabled?: boolean;
}> = ({ userName, size, userID, disabled }) => {
  return (
    <div
      className="flex shrink-0 items-center justify-center overflow-hidden rounded-[100%] font-default font-semibold uppercase text-white"
      style={{
        height: size,
        width: size,
        backgroundColor: disabled ? "#ccc" : idToColor(userID),
        fontSize: size * 0.4,
      }}
    >
      {dontBlindlyTrustLongInitials(userName)}
    </div>
  );
};

export const AvatarWithName: React.FC<{
  name: string;
  id: string;
  deprecated_at: string | null;
}> = ({ id, name, deprecated_at }) => {
  return (
    <div
      className={twMerge(
        "gap-md flex items-center",
        deprecated_at && "filter-[grayscale(100%)] opacity-60",
      )}
    >
      <Avatar
        size={24}
        userID={id}
        userName={name}
        disabled={deprecated_at !== null}
      />
      <span className="text-sm font-normal text-gray-600">{`${name} ${deprecated_at ? "(Deactivated)" : ""}`}</span>
    </div>
  );
};
