type ConditionalWrapper = {
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element | null;
  children: JSX.Element;
};

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapper) => (condition ? wrapper(children) : children);
