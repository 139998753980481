import React from "react";
import Decimal from "decimal.js";
import { Input } from "design-system";
import { useState } from "react";

type PercentageInputProps = {
  className?: string;
  /** Decimal from 0 to 100 */
  value?: Decimal;
  /** Callback that returns Decimal from 0 to 100 */
  onChange: (percent: Decimal) => void;
  onError?: (message: string) => void;
  placeholder: string;
  disabled?: boolean;
  name?: string;
};

/**
 * Form input for a percentage from 0 to 100.
 */
export const PercentageInput: React.FC<PercentageInputProps> = (props) => {
  const [error, setError] = useState<string>();
  const [percent, setPercent] = useState<Decimal | undefined>(props.value);
  const [input, setInput] = useState<string | undefined>(
    props.value?.toString(),
  );

  // Reset state if props change;
  if (props.value?.toString() !== percent?.toString()) {
    setInput(props.value?.toString());
    setPercent(props.value);
  }

  const handleError = (message: string | undefined) => {
    setError(message);
    if (message) {
      props.onError?.(message);
    }
  };

  return (
    <Input
      name={props.name}
      className={props.className}
      placeholder={props.placeholder}
      value={input ?? ""}
      error={error}
      disabled={props.disabled}
      suffix="%"
      onChange={(value) => {
        handleError(undefined);
        setInput(value);

        let newPercent: Decimal;
        try {
          newPercent = new Decimal(value);
        } catch (error) {
          handleError("Must be a number");
          return;
        }

        if (newPercent.greaterThan(100) || newPercent.lessThan(0)) {
          handleError("Must be between 0 to 100");
          return;
        }
        props.onChange(newPercent);
      }}
    />
  );
};
