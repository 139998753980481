import { BillingProviderEnum_Enum } from "types/generated-graphql/__types__";

export const billingProviderOptions: Array<{
  label: string;
  value: BillingProviderEnum_Enum | "";
}> = [
  {
    label: "None",
    value: "",
  },
  {
    label: "Stripe",
    value: BillingProviderEnum_Enum.Stripe,
  },
  {
    label: "NetSuite",
    value: BillingProviderEnum_Enum.Netsuite,
  },
  {
    label: "Custom",
    value: BillingProviderEnum_Enum.Custom,
  },
];
