import React, { useState } from "react";
import { AvatarWithName, Badge, Icon } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import {
  Column,
  SortFunctions,
  SortRule,
  Table,
  TableSkeleton,
} from "components/Table";
import { renderDateTime } from "lib/time";
import styles from "./index.module.less";
import {
  AddCustomFieldKeysDocument,
  DeleteCustomFieldKeyDocument,
  GetCustomFieldKeysQuery,
  UpdateCustomFieldKeysDocument,
} from "../../queries.graphql";
import { PopoverMenu } from "components/PopoverMenu";
import classnames from "classnames";
import { EmptyState } from "tenaissance/components/EmptyState";
import { gatedAction, useAuthCheck } from "lib/useAuthCheck";
import { CopyableID } from "components/CopyableID";
import { GatedButton } from "components/GatedButton";

export type CustomFieldKey = GetCustomFieldKeysQuery["ManagedFieldKey"][0];
interface CustomFieldsTableProps {
  customFieldKeys: Array<CustomFieldKey>;
  loading?: boolean;
  onEdit?: (item: CustomFieldKey) => void;
  onDelete?: (item: CustomFieldKey) => void;
  onCreate?: () => void;
}

const PAGE_SIZE = 20;
const DEFAULT_SORT: SortRule = { id: "date-created" as const, desc: true };

export const CustomFieldsTable: React.FC<CustomFieldsTableProps> = ({
  customFieldKeys,
  loading,
  onEdit,
  onDelete,
  onCreate,
}) => {
  const [sortState] = useState<SortRule[]>([DEFAULT_SORT]);
  const canEditCustomField = !!useAuthCheck(UpdateCustomFieldKeysDocument, true)
    .allowed;
  const canArchiveCustomField = !!useAuthCheck(
    DeleteCustomFieldKeyDocument,
    true,
  ).allowed;

  const columns: Column<CustomFieldKey>[] = [
    {
      header: "Entity: Key",
      id: "name",
      render: (c: CustomFieldKey) => (
        <>
          <CopyableID id={c.id} label="custom field id" hideID />
          <Badge
            theme="primary"
            type="light"
            className={styles.entityFieldName}
          >
            {c.entity.toUpperCase()}: {c.key}
          </Badge>
        </>
      ),
      sortable: true,
      comparator: SortFunctions.String((c) => `${c.entity}: ${c.key}`),
      textWrappable: true,
    },
    {
      header: "Unique values only",
      id: "unique-values-only",
      sortable: false,
      render: (c) => (c.enforce_uniqueness ? <>Yes</> : <>No</>),
    },
    {
      header: "Created by",
      id: "created-by",
      sortable: true,
      comparator: SortFunctions.String((c) => {
        return c.created_by;
      }),
      render: (c) => (c.Creator ? <AvatarWithName {...c?.Creator} /> : <></>),
    },
    {
      header: "Date created",
      id: "date-created",
      sortable: true,
      comparator: SortFunctions.Date((c) => {
        return new Date(c.created_at);
      }),
      render: (c) => renderDateTime(new Date(c.created_at), true),
    },
    {
      header: "",
      id: "options",
      render: (c) => (
        <PopoverMenu
          positions={["bottom"]}
          align="end"
          options={[
            gatedAction(canEditCustomField, {
              onClick: (e: React.MouseEvent<any>) => {
                onEdit ? onEdit(c) : null;
              },
              content: (
                <div className={classnames(styles.option, styles.optionEdit)}>
                  <Icon
                    className={classnames(styles.paddedIcon, styles.optionIcon)}
                    icon="pencil"
                  />
                  Edit custom field...
                </div>
              ),
            }),
            gatedAction(canArchiveCustomField, {
              onClick: (e: React.MouseEvent<any>) => {
                onDelete ? onDelete(c) : null;
              },
              content: (
                <div className={classnames(styles.option, styles.optionDelete)}>
                  <Icon
                    className={classnames(styles.paddedIcon, styles.optionIcon)}
                    icon="trash"
                  />
                  Archive custom field...
                </div>
              ),
            }),
          ]}
        >
          {(onClick) => (
            <IconButton
              onClick={onClick}
              theme="tertiary"
              icon="dotsVertical"
            />
          )}
        </PopoverMenu>
      ),
    },
  ];

  return !loading && customFieldKeys.length === 0 ? (
    <EmptyState
      icon="barLineChart"
      mainText="No custom fields found."
      supportingText="Start by defining a new field key on an entity."
      actions={[
        <GatedButton
          onClick={onCreate}
          doc={AddCustomFieldKeysDocument}
          text="Add new field key"
          theme="primary"
          leadingIcon="plus"
        />,
      ]}
    />
  ) : loading ? (
    <TableSkeleton numRows={15} columnNames={columns.map((c) => c.header)} />
  ) : (
    <Table
      loading={loading}
      defaultSortBy={sortState}
      maxPageSize={PAGE_SIZE}
      data={customFieldKeys}
      emptyState={<div id={styles.spinner}></div>}
      columns={columns}
    />
  );
};
