/* 6c4dc46328791e574703af422de39b752506f8da
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductListFragment = { __typename?: 'Product', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, active_plan_count: number, updated_at: string, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, ProductPricingFactors_aggregate: { __typename?: 'ProductPricingFactor_aggregate', aggregate: { __typename?: 'ProductPricingFactor_aggregate_fields', count: number } | null } };

export type ProductsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  types: Array<Types.ProductTypeEnum> | Types.ProductTypeEnum;
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['uuid']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.ProductsSortingInput>;
}>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, active_plan_count: number, updated_at: string, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, ProductPricingFactors_aggregate: { __typename?: 'ProductPricingFactor_aggregate', aggregate: { __typename?: 'ProductPricingFactor_aggregate_fields', count: number } | null } }> };

export const ProductListFragmentDoc = gql`
    fragment ProductList on Product {
  id
  name
  description
  created_at
  deprecated_at
  active_plan_count
  updated_at
  Actor {
    name
    id
    deprecated_at
  }
  ProductPricingFactors_aggregate {
    aggregate {
      count
    }
  }
  __environment_type: environment_type
}
    `;
export const ProductsDocument = gql`
    query Products($environment_type: EnvironmentTypeEnum_enum!, $types: [ProductTypeEnum!]!, $limit: Int!, $cursor: uuid, $search: String, $sort: ProductsSortingInput) {
  products(
    environment_type: $environment_type
    types: $types
    limit: $limit
    cursor: $cursor
    search: $search
    sort: $sort
  ) {
    id
    ...ProductList
    __environment_type: environment_type
  }
}
    ${ProductListFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      types: // value for 'types'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;