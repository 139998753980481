import React, { useState } from "react";
import { Popup } from "../Popup";
import { Body, Select } from "design-system";
import { Button } from "tenaissance/components/Button";
import { useSnackbar } from "../Snackbar";
import {
  Environment,
  useEnvironment,
} from "../../lib/environmentSwitcher/context";
import { useNavigate } from "react-router-dom";
import { reportToSentry } from "lib/errors/sentry";

interface CopyAssetToEnvironmentModalProps {
  assetName: string;
  assetType: string;
  messageSuffix?: string;
  onClose: () => void;
  copyAsset: (targetEnvironment: Environment) => Promise<string>;
}

const CopyAssetToEnvironmentModal: React.FC<
  CopyAssetToEnvironmentModalProps
> = ({ assetName, assetType, onClose, copyAsset, messageSuffix }) => {
  const { environments: allEnvironments, environment: currentEnvironment } =
    useEnvironment();
  const [isCopying, setIsCopying] = useState(false);

  const navigate = useNavigate();

  const availableEnvironments = allEnvironments.filter(
    (e) => e !== currentEnvironment,
  );
  const defaultEnv = availableEnvironments[0];
  const [targetEnvironment, setTargetEnvironment] = useState<
    Environment | undefined
  >(defaultEnv);

  const [targetUrl, setTargetUrl] = useState("");

  const pushMessage = useSnackbar();
  return (
    <Popup
      actions={
        <>
          <Button
            onClick={onClose}
            text={!!targetUrl ? "Done" : "Cancel"}
            theme="linkGray"
          />
          {!!targetUrl ? (
            <Button
              key="primary"
              onClick={() => {
                onClose();
                navigate(targetUrl);
              }}
              text={`Copy ${assetType}`}
              theme="primary"
            />
          ) : (
            <Button
              key="primary"
              disabled={isCopying || !targetEnvironment?.type}
              loading={isCopying}
              onClick={async () => {
                try {
                  setIsCopying(true);

                  if (targetEnvironment) {
                    const url = await copyAsset(targetEnvironment);
                    setTargetUrl(url);
                  }
                } catch (err) {
                  reportToSentry(err);

                  pushMessage({
                    content: "Something went wrong.  Please try again",
                    type: "error",
                  });
                } finally {
                  setIsCopying(false);
                }
              }}
              text={`Copy ${assetType}`}
              theme="primary"
            />
          )}
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title={
        !!targetUrl
          ? `${assetType} copied to new environment`
          : `Copy ${assetType} to environment`
      }
    >
      {!targetUrl ? (
        <>
          <Body level={2}>
            Are you sure you want to copy <strong>{assetName}</strong> to the{" "}
            <strong>{targetEnvironment?.name}</strong> environment?{" "}
            {messageSuffix}
          </Body>
          {availableEnvironments.length > 1 && (
            <Select
              value={targetEnvironment?.id}
              options={availableEnvironments.map((e) => ({
                label: e.name,
                value: e.id,
              }))}
              onChange={(val) => {
                setTargetEnvironment(
                  availableEnvironments.find((e) => e.id === val),
                );
              }}
              placeholder="Select environment"
            />
          )}
        </>
      ) : (
        <Body level={2}>
          {assetName} has been copied to {targetEnvironment?.name}.
        </Body>
      )}
    </Popup>
  );
};

export default CopyAssetToEnvironmentModal;
