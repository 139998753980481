import React from "react";
import { AppShell } from "components/PageContainer";
import { RouteObject } from "react-router";
import { AuditLogsTab } from "pages/GeneralSettings/components/AuditLogsTab";
import { Team } from "./tabs/Team";

const Settings: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AppShell
      title="Settings"
      headerProps={{
        tabs,
        basePath: "settings",
      }}
    >
      {children}
    </AppShell>
  );
};

const tabs = [
  {
    name: "Team",
    path: "team",
    handle: {
      noEnv: true,
    },
    element: (
      <Settings>
        <Team />
      </Settings>
    ),
  },
  {
    name: "Audit logs",
    path: "audit-logs",
    children: [
      {
        index: true,
        element: (
          <Settings>
            <AuditLogsTab />
          </Settings>
        ),
      },
    ],
  },
];
export const SettingsRoutes: RouteObject[] = [
  {
    path: "settings",
    children: [...tabs],
  },
];
