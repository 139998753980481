import React, { useState } from "react";
import { BlockConfiguration } from "lib/plans/types";
import { Popup } from "components/Popup";
import { Body, Checkbox, NumericInput, RadioGroup } from "design-system";
import { Button } from "tenaissance/components/Button";
import { RoundingBehaviorEnum_Enum } from "types/generated-graphql/__types__";
import styles from "./index.module.less";

interface BlockPricingModalProps {
  initialBlockPricing: BlockConfiguration | undefined;
  onRequestClose: (blockPricing?: BlockConfiguration) => void;
}

export const BlockPricingModal: React.FC<BlockPricingModalProps> = ({
  initialBlockPricing,
  onRequestClose,
}) => {
  const [blockPricing, setBlockPricing] = useState<
    Partial<BlockConfiguration> | undefined
  >(initialBlockPricing);
  const [blockSizeError, setBlockSizeError] = useState<boolean>(false);

  const [roundingEnabled, setRoundingEnabled] = useState<boolean>(
    !!initialBlockPricing?.roundingBehavior,
  );

  function validateBlockSize(blockSize: number | undefined) {
    return blockSize && blockSize > 0 && Number.isInteger(blockSize);
  }

  const isValid =
    validateBlockSize(blockPricing?.size) &&
    (!roundingEnabled || blockPricing?.roundingBehavior);

  return (
    <Popup
      className={styles.popup}
      actions={[
        <Button
          onClick={() => onRequestClose()}
          key="cancel-button"
          text="Cancel"
          theme="linkGray"
        />,
        <Button
          onClick={() => {
            if (isValid) {
              onRequestClose(blockPricing as BlockConfiguration);
            }
          }}
          disabled={!isValid}
          key="save-button"
          text="Save"
          theme="primary"
        />,
      ]}
      title="Quantity conversion"
      isOpen={true}
      onRequestClose={() => onRequestClose()}
    >
      <Body level={2} className={styles.description}>
        You can specify a division factor and optionally rounding that will be
        applied to the quantity before pricing.
      </Body>
      <NumericInput
        name="Division factor"
        placeholder="0"
        value={blockPricing?.size}
        onChange={(v) => {
          setBlockPricing({ ...blockPricing, size: v || undefined });
          setBlockSizeError(!validateBlockSize(v || undefined));
        }}
        error={
          blockSizeError
            ? "Division factor must be a positive integer"
            : undefined
        }
        className={styles.blockSizeInput}
      />
      <Body level={2}>
        Rounding behavior (optional): when the quantity (post division) is not a
        whole number, should Metronome round to the nearest integer? Note that
        if rounding is disabled, quantities will be rounded to 20 decimal
        places.
      </Body>
      <div className={styles.roundingBehaviorSelector}>
        <Checkbox
          checked={roundingEnabled}
          label="Enable rounding"
          onChange={(v) => {
            setRoundingEnabled(v);
            if (!v) {
              setBlockPricing({ ...blockPricing, roundingBehavior: undefined });
            }
          }}
        />
        <RadioGroup
          value={blockPricing?.roundingBehavior}
          options={[
            {
              value: RoundingBehaviorEnum_Enum.Floor,
              label: "Round down",
              disabled: !roundingEnabled,
            },
            {
              value: RoundingBehaviorEnum_Enum.Ceiling,
              label: "Round up",
              disabled: !roundingEnabled,
            },
          ]}
          onChange={(value) =>
            setBlockPricing({
              ...blockPricing,
              roundingBehavior: value as RoundingBehaviorEnum_Enum,
            })
          }
        />
      </div>
    </Popup>
  );
};
