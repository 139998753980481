import React, { useState } from "react";
import classNames from "classnames";
import { dayjs } from "lib/dayjs";
import { Select, TextArea, DateInput, Input } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { RightPane } from "components/Popup";
import { useSnackbar } from "components/Snackbar";
import { Body, Headline } from "design-system";
import { GetNonExpiredCreditGrantsDocument } from "../../data/credit-overview.graphql";
import styles from "./index.module.less";
import {
  useLatestInvoiceFinalizationDateQuery,
  useUpdateCreditGrantMutation,
} from "./queries.graphql";
import {
  getUserFacingErrorMessage,
  giveUserFacingErrorMessage,
} from "lib/errors/errorHandling";
import { clearCustomerInvoicesFromCache } from "../../lib/cache";
import { BillingProviderEnum_Enum } from "types/generated-graphql/__types__";
import { useFeatureFlag } from "lib/launchdarkly";
import { billingProviderOptions } from "lib/billingProvider/billingProviderOptions";
import {
  RolloverSettingsInput,
  useEnableRolloverCreditGrants,
} from "lib/rolloverSettings";
import { RolloverSettingsInputs } from "components/RolloverSettingsInputs";
import { CreditType } from "types/credit-types";

export type EditCreditGrantInput = {
  id: string;
  customerId: string;
  name: string;
  reason?: string;
  effectiveAt: Date;
  expiresBefore: Date;
  billingProvider?: BillingProviderEnum_Enum | null;
  voidedAt: Date | null;
  creditGrantType?: string;
  rolloverSettingsInput?: RolloverSettingsInput;
  amountGrantedCreditType: CreditType;
  priority: string;
};

interface EditCreditGrantPaneProps {
  creditGrant: EditCreditGrantInput;
  onClose: () => void;
  commit?: boolean;
}
const EditCreditGrantPane: React.FC<EditCreditGrantPaneProps> = ({
  creditGrant,
  onClose,
  commit,
}) => {
  const pushMessage = useSnackbar();

  const [grantName, setGrantName] = useState<string>(creditGrant.name);
  const [reason, setReason] = useState<string>(creditGrant.reason ?? "");
  const [creditGrantType, setCreditGrantType] = useState<string>(
    creditGrant.creditGrantType ?? "",
  );
  const [expiresBefore, setExpiresBefore] = useState<Date>(
    creditGrant.expiresBefore,
  );
  const [billingProvider, setBillingProvider] = useState<
    BillingProviderEnum_Enum | undefined
  >(creditGrant.billingProvider ?? undefined);
  const [rolloverSettingsFormState, setRolloverSettingsFormState] = useState<{
    value: RolloverSettingsInput | undefined;
    error?: string;
  }>({ value: creditGrant.rolloverSettingsInput });

  const canSetBillingProviderOnCreditGrant = useFeatureFlag(
    "set-billing-provider-on-credit-grant",
    false,
  );

  const creditGrantTypeEnabled = useFeatureFlag<boolean>(
    "credit-grant-type-ui",
    false,
  );

  const canBackdateGrants =
    useFeatureFlag<boolean>(
      "allow-backdate-credit-grants-past-invoice-dates",
      false,
    ) ?? false;

  const enableRolloverCreditGrants = useEnableRolloverCreditGrants();

  const [formErrorMsg, setFormErrorMsg] = useState<string>("");

  const { data, loading } = useLatestInvoiceFinalizationDateQuery({
    variables: {
      customer_id: creditGrant.customerId,
    },
  });

  const minExpiryDate =
    data?.customer?.end_date_of_last_finalized_invoice && !canBackdateGrants
      ? new Date(data.customer.end_date_of_last_finalized_invoice)
      : creditGrant.effectiveAt;

  const [updateCreditGrant, updateCreditGrantResults] =
    useUpdateCreditGrantMutation();

  const fieldsAreValid = () => {
    return !(
      grantName.trim() === "" ||
      !expiresBefore ||
      rolloverSettingsFormState.error !== undefined
    );
  };

  const submitForm = async () => {
    if (!fieldsAreValid()) {
      return;
    }
    try {
      if (!canSetBillingProviderOnCreditGrant && billingProvider) {
        const error = new Error();
        giveUserFacingErrorMessage(
          error,
          "Client is not enabled to set billing provider on credit grant",
        );
        throw error;
      }

      await updateCreditGrant({
        variables: {
          id: creditGrant.id,
          name: grantName.trim() || creditGrant.name,
          expires_before: (
            expiresBefore ?? creditGrant.expiresBefore
          ).toISOString(),
          reason: reason.trim() ?? creditGrant.reason,
          billing_provider: billingProvider,
          credit_grant_type:
            creditGrantType.trim() !== "" ? creditGrantType.trim() : null,
          rollover_settings: rolloverSettingsFormState.value ?? null,
        },
        refetchQueries: [
          {
            query: GetNonExpiredCreditGrantsDocument,
            variables: {
              customer_id: creditGrant.customerId,
            },
          },
        ],
        update(cache) {
          clearCustomerInvoicesFromCache(cache, creditGrant.customerId);
        },
      });
      setFormErrorMsg("");
      pushMessage({ content: "Grant successfully updated!", type: "success" });
      onClose();
    } catch (e) {
      const msg = getUserFacingErrorMessage(e);
      setFormErrorMsg(msg);
      pushMessage({
        content: `Grant failed to update: ${msg}`,
        type: "error",
      });
      return;
    }
  };

  return (
    <RightPane isOpen onRequestClose={onClose}>
      <div className={styles.titleSection}>
        <Headline level={6}>Edit credit grant</Headline>
        <IconButton onClick={onClose} theme="secondary" icon="xClose" />
      </div>
      {canSetBillingProviderOnCreditGrant && (
        <Body level={2} className={styles.description}>
          Edit the name, reason, billing provider and expiry date of the grant.
          If additional modifications are required, you can void the grant and
          issue a new one.
        </Body>
      )}
      {!canSetBillingProviderOnCreditGrant && (
        <Body level={2} className={styles.description}>
          Edit the name, reason, and expiry date of the grant. If additional
          modifications are required, you can void the grant and issue a new
          one.
        </Body>
      )}
      {formErrorMsg && (
        <Body level={2} className={styles.error}>
          {formErrorMsg}
        </Body>
      )}
      <div className="flex flex-col gap-16">
        <Input
          className={classNames(styles.gray)}
          name="Grant name"
          placeholder={grantName}
          value={grantName}
          success={grantName.trim() !== ""}
          onChange={setGrantName}
        />
        <TextArea
          className={classNames(styles.gray)}
          name="Reason (optional)"
          placeholder={reason || "Enter reason"}
          success={reason.trim() !== ""}
          value={reason}
          onChange={setReason}
        />
        {creditGrantTypeEnabled && (
          <Input
            className="[&>label]:text-gray-600"
            name="Credit grant type (optional)"
            tooltip="Credit grant type is an optional metadata field designed for organizing credit grants and enabling more advanced filtering capabilities."
            placeholder="Enter a value"
            value={creditGrantType}
            success={creditGrantType.trim() !== ""}
            onChange={setCreditGrantType}
          />
        )}
        {canSetBillingProviderOnCreditGrant && (
          <Select
            options={billingProviderOptions}
            value={billingProvider ?? ""}
            placeholder="Select"
            name="Billing provider"
            tooltip="The billing provider associated with this credit grant."
            onChange={(bp) => {
              if (bp === "") {
                setBillingProvider(undefined);
              } else {
                setBillingProvider(bp as BillingProviderEnum_Enum);
              }
            }}
          />
        )}
        <div className={styles.datePickers}>
          <DateInput
            className={styles.gray}
            name="Expiry date"
            tooltip={`Expiry date cannot be less than the effective date${canBackdateGrants ? "" : ", nor less than the end date of the most recent finalized usage invoice"}. We recommend aligning the expiration to your customer's billing period.`}
            value={expiresBefore}
            minDate={minExpiryDate}
            success={!!expiresBefore}
            isUTC
            onChange={(d) => setExpiresBefore(dayjs.utc(d).toDate())}
            disabled={dayjs.utc(expiresBefore).isBefore(minExpiryDate)}
          />
        </div>
        {enableRolloverCreditGrants && (
          <RolloverSettingsInputs
            value={rolloverSettingsFormState.value}
            onChange={(input) => {
              setRolloverSettingsFormState((s) => ({
                ...s,
                value: input,
                error: undefined,
              }));
            }}
            onError={(error) => {
              setRolloverSettingsFormState((s) => ({ ...s, error }));
            }}
            initialPriority={creditGrant.priority}
            pricingUnit={creditGrant.amountGrantedCreditType}
          />
        )}
      </div>
      <div className={styles.actions}>
        <Button onClick={onClose} text="Cancel" theme="linkGray" />
        <Button
          loading={loading || updateCreditGrantResults.loading}
          disabled={!fieldsAreValid() || updateCreditGrantResults.loading}
          onClick={submitForm}
          text="Save"
          theme="primary"
        />
      </div>
    </RightPane>
  );
};

export default EditCreditGrantPane;
