import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import React, { useCallback, useState } from "react";
import { Button } from "tenaissance/components/Button";
import { Icon } from "tenaissance/components/Icon";
import { Link } from "react-router-dom";
import { useNavigate } from "lib/useNavigate";
import { components, type MenuProps } from "react-select";
import { type Option } from "design-system";
import { Takeover } from "tenaissance/components/Takeover";
import { TextInput } from "tenaissance/components/Input";
import { SectionHeader } from "tenaissance/components/SectionHeader";
import { OptionGroup } from "tenaissance/components/OptionGroup";
import { InputDropdown } from "tenaissance/components/InputDropdown";
import { DropdownItem } from "tenaissance/components/Dropdown";
import { ProductCreatedModal } from "./ProductCreatedModal";
import { ProductCreateLandingPage, shouldShowPreviewPage } from "./LandingPage";
import { RadioButton } from "tenaissance/components/RadioButton";
import { ProductContext } from "./ProductContext";
import { UsageProductFields } from "./UsageProductFields";
import { SubscriptionProductFields } from "./SubscriptionProductFields";
import { CompositeProductFields } from "./CompositeProductFields";
import { NetSuiteFields } from "./NetSuiteFields";
import { ProfessionalServiceProductFields } from "./ProfessionalServiceProductFields";
import { useDocsLink } from "lib/docs-link";

type LinkOptions = {
  url: string;
  label: string | JSX.Element;
};

/** CustomMenu will allow devs to prepend a clickable link to the top of a Select, ie BillableMetrics dropdown */
export const CustomMenu = ({
  menuProps,
  linkOptions,
}: {
  menuProps: MenuProps<Option>;
  linkOptions?: LinkOptions;
}) => {
  return (
    <components.Menu {...menuProps}>
      {!!linkOptions && (
        <Link
          className="flex items-center justify-between border-b border-grey-50 px-12 py-8 text-success-700 hover:bg-grey-50"
          to={linkOptions.url}
          relative="path"
          target="_blank"
        >
          {linkOptions.label}
          <Icon icon="share03" size={12} />
        </Link>
      )}
      {menuProps.children}
    </components.Menu>
  );
};

interface ProductModalProps {
  onClose?: (newProductId?: string) => void;
  enforceType?: "usage" | "fixed" | "composite" | "subscription" | "proService";
}

export const fuseParams = {
  ignoreLocation: true,
  includeScore: true,
  threshold: 0.3,
};

const ProductCreate: React.FC<ProductModalProps> = (props) => {
  const {
    productsError,
    clearSnapshot,
    productType,
    billableMetricsError,
    isSubmitting,
    allowProfessionalServices,
    setProductName,
    productName,
    tags,
    setTags,
    setProductType,
    existingTagValues,
    isSaveDisabled,
    showProductCreatedModal,
    setShowProductCreatedModal,
    useOnSubmit,
    onSubmitAndAddAnother,
    createdProductId,
  } = ProductContext.useContainer();

  const navigate = useNavigate();

  const { onClose } = props;

  const tagOptions = Array.from(existingTagValues);

  const [showPreviewPage, setShowPreviewPage] = useState(shouldShowPreviewPage);

  const docsLink = useDocsLink({
    contractsPath: "pricing-packaging/create-products-contracts/",
  });

  const handleProductNameChange = useCallback(
    (meta: { value: string }) => {
      setProductName(meta.value.trim());
    },
    [setProductName],
  );

  const handleSubmit = useOnSubmit(onClose);

  const handleCloseTakeOver = useCallback(() => {
    onClose ? onClose() : navigate("/offering/products");
    clearSnapshot();
  }, [onClose]);

  const handleBackClick = useCallback(() => {
    if (shouldShowPreviewPage()) {
      setShowPreviewPage(true);
    } else {
      navigate("/offering/products");
    }
  }, [shouldShowPreviewPage]);

  const handleProductCreatedOnClose = useCallback(() => {
    navigate(`/offering/products?product_id=${createdProductId}`);
    setShowProductCreatedModal(false);
  }, [createdProductId]);

  if (billableMetricsError) {
    return (
      <ErrorEmptyState
        title="Could not fetch billable metrics"
        error={billableMetricsError}
      />
    );
  }
  if (productsError) {
    return (
      <ErrorEmptyState title="Could not fetch products" error={productsError} />
    );
  }

  return (
    <Takeover
      maxContainerWidth="max-w-[1400px]"
      isOpen={true}
      onClose={handleCloseTakeOver}
      title="Create a product"
      headerButtons={[
        <Button
          text="View docs"
          theme="secondary"
          leadingIcon="share03"
          linkTo={docsLink}
          isExternalLink
        />,
      ]}
      footerTrailingButtons={[
        <Button
          className="w-[128px] justify-center"
          onClick={onSubmitAndAddAnother}
          disabled={isSaveDisabled}
          text="Add another"
          theme="secondary"
        />,
        <Button
          className="w-[128px] justify-center"
          onClick={handleSubmit}
          disabled={isSaveDisabled}
          loading={isSubmitting}
          text="Save"
          theme="primary"
          type="submit"
        />,
      ]}
      footerLeadingButton={
        <Button
          className="w-[128px] justify-center"
          text="Back"
          onClick={handleBackClick}
          theme="secondary"
        />
      }
      children={
        <div>
          {showProductCreatedModal && (
            <ProductCreatedModal onClose={handleProductCreatedOnClose} />
          )}
          {showPreviewPage ? (
            <ProductCreateLandingPage setShowPreviewPage={setShowPreviewPage} />
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <input type="submit" className="hidden" />
              <div className="grid pb-[32px]">
                <div className="grid gap-[24px]">
                  <TextInput
                    fullWidth
                    placeholder="Product name"
                    label="Name"
                    helpText="Enter a name for the product"
                    value={productName}
                    onChange={handleProductNameChange}
                  />
                  <InputDropdown
                    value={tags}
                    hintText="Use tags to group products, allowing for actions like straightforward discounting on contracts"
                    label="Tags"
                    placeholder="Add tags"
                    tagsVariant={true}
                    onChangeTags={(meta: { value: string[] }) => {
                      setTags(meta.value);
                    }}
                    leadingIcon="searchSm"
                    disabled={productType === "proService"}
                    helpText={
                      productType === "proService"
                        ? "Tags are not supported for professional service products"
                        : ""
                    }
                    fullWidth
                  >
                    {tagOptions.map((u, index) => (
                      <DropdownItem
                        label={u}
                        value={u}
                        key={"tag" + index}
                        onClick={({ selected }) => {
                          const prevTags = tags;
                          selected
                            ? setTags(prevTags.filter((t) => t !== u))
                            : setTags([...prevTags, u]);
                        }}
                        selected={tags.includes(u)}
                      />
                    ))}
                  </InputDropdown>
                </div>
                <div className="mt-[40px] grid gap-[24px]">
                  <SectionHeader
                    title="Select the product type"
                    subtitle="A product is a line item on a customer’s invoice. These types determine how to charge a customer."
                    bottomBorder={false}
                  />
                  <div className="ml-4">
                    <OptionGroup className="w-full">
                      <RadioButton
                        label="Usage"
                        checked={productType === "usage"}
                        onChange={() => setProductType("usage")}
                        supportingText="Usage products are based on usage events that match the underlying billable metric."
                        value=""
                      />
                      <RadioButton
                        label="Subscription"
                        checked={productType === "subscription"}
                        onChange={() => setProductType("subscription")}
                        supportingText="Charge a fixed, pro-rated amount per billing cycle."
                        value=""
                      />
                      <RadioButton
                        label="Composite"
                        checked={productType === "composite"}
                        onChange={() => setProductType("composite")}
                        supportingText="Charge a percent of the total cost of other Usage or Subscription products."
                        value=""
                      />
                      <RadioButton
                        label="Fixed"
                        checked={productType === "fixed"}
                        onChange={() => setProductType("fixed")}
                        supportingText="Power credits, commits, and scheduled charges."
                        value=""
                      />
                      {allowProfessionalServices && (
                        <RadioButton
                          label="Professional Services"
                          checked={productType === "proService"}
                          onChange={() => setProductType("proService")}
                          supportingText="Charge based on professional services milestones, or time and materials."
                          value=""
                        />
                      )}
                    </OptionGroup>
                  </div>
                </div>
                {productType === "usage" && <UsageProductFields />}
                {productType === "subscription" && (
                  <SubscriptionProductFields />
                )}
                {productType === "composite" && <CompositeProductFields />}
                {productType === "proService" && (
                  <ProfessionalServiceProductFields />
                )}
                {productType === "fixed" && <NetSuiteFields />}
              </div>
            </form>
          )}
        </div>
      }
    ></Takeover>
  );
};

export const CreateProduct: React.FC = () => {
  return (
    <ProductContext.Provider>
      <ProductCreate />
    </ProductContext.Provider>
  );
};
