import React from "react";
import { dayjs } from "lib/dayjs";
import { Breadcrumbs } from "lib/breadcrumbs";
import { downloadCSV } from "lib/reports";
import { AppShell } from "components/PageContainer";
import { Card } from "tenaissance/components/Card";
import { Table, TableProps } from "tenaissance/components/Table";
import { Badge } from "tenaissance/components/Badge";
import {
  CensusDestinationTypeEnum,
  CensusSyncRun,
  CensusSyncRunStatusEnum,
} from "types/generated-graphql/__types__";
import { EmptyState } from "tenaissance/components/EmptyState";
import { useGetObjectSyncReportsQuery } from "./queries.graphql";
import { ApolloError } from "@apollo/client";
import { Tooltip } from "tenaissance/components/Tooltip";
import { Button } from "tenaissance/components/Button";
import { renderDateTimeInUTC } from "lib/time";
import { useSnackbar } from "components/Snackbar";
import Decimal from "decimal.js";

const ROUTING_PROPS = {
  [CensusDestinationTypeEnum.Salesforce]: {
    label: "Salesforce",
    subPath: "salesforce",
  },
};

const UNAVAILABLE_FAILED_RECORDS_SAMPLE_ERROR =
  "Failed records sample is expired or not available";

const FAILED_INCOMPLETE_SYNC_DEFAULT_ERROR =
  "A Metronome representative has been notified about this failure";

export const CensusDashboard: React.FC<{
  destinationType: CensusDestinationTypeEnum;
  showIncompleteSyncs?: boolean;
}> = ({ destinationType, showIncompleteSyncs }) => {
  if (destinationType !== CensusDestinationTypeEnum.Salesforce) {
    throw new Error(
      `CensusDashboard does not support ${destinationType} destination type`,
    );
  }
  const destinationLabel = ROUTING_PROPS[destinationType].label;
  return (
    <AppShell
      title="Latest syncs"
      headerProps={{
        basePath: "connections/integrations",
        tabs: [
          {
            name: "Completed",
            path: "salesforce",
            exactMatch: true,
          },
          {
            name: "Incomplete",
            path: "salesforce/incomplete",
          },
        ],
        breadcrumbs: Breadcrumbs.from(
          {
            label: "Connections",
            routePath: "/connections/events",
          },
          {
            label: "Integrations",
            routePath: "/connections/integrations",
          },
          {
            label: destinationLabel,
          },
        ),
      }}
    >
      <CensusDashboardContent
        destinationType={destinationType}
        showIncompleteSyncs={showIncompleteSyncs !== undefined}
      />
    </AppShell>
  );
};

const objectNameColumn: TableProps<CensusSyncReportData>["columns"][number] = {
  id: "object_name",
  header: "Sync",
  accessorFn: (row) => row.object_name,
  cell: (props) => props.getValue(),
  enableSorting: false,
};
const statusColumn: TableProps<CensusSyncReportData>["columns"][number] = {
  id: "status",
  header: "Status",
  size: 175,
  accessorFn: (row) => row,
  cell: (props) => {
    const syncRun = props.getValue();
    return <CensusSyncStatusBadge syncRun={syncRun}></CensusSyncStatusBadge>;
  },
  isDisplay: true,
  enableSorting: false,
};
const CensusCompletedSyncsReportTab: React.FC<{
  totalRows: number;
  successfulRows: number;
  failedRows: number;
  loading: boolean;
  objectSyncReports: LatestSyncRunReport[];
}> = ({
  totalRows,
  successfulRows,
  failedRows,
  loading,
  objectSyncReports,
}) => {
  return (
    <CensusSyncReportTab
      cards={[
        {
          label: "Total Rows",
          value: totalRows,
        },
        {
          label: "Successful",
          value: successfulRows,
          valueClassName: "text-deprecated-success-500",
        },
        {
          label: "Failed",
          value: failedRows,
          valueClassName: "text-error-600",
        },
      ]}
      emptyStateText="No completed syncs"
      tableColumns={[
        objectNameColumn,
        {
          id: "completed_at",
          header: "Date",
          size: 300,
          accessorFn: (row) => row.completed_at,
          cell: (props) => {
            const completedAt = props.getValue();
            return completedAt
              ? renderDateTimeInUTC(dayjs.utc(completedAt).toDate(), false)
              : undefined;
          },
          enableSorting: false,
        },
        {
          id: "records_processed",
          header: "Total rows",
          size: 175,
          accessorFn: (row) => row.records_processed || 0,
          cell: (props) => props.getValue(),
          enableSorting: false,
        },
        {
          id: "records_updated",
          header: "Successful",
          size: 175,
          accessorFn: (row) => row.records_updated || 0,
          cell: (props) => props.getValue(),
          enableSorting: false,
        },
        {
          id: "records_failed",
          header: "Failed",
          size: 175,
          accessorFn: (row) => row.records_failed || 0,
          cell: (props) => props.getValue(),
          enableSorting: false,
        },
        statusColumn,
      ]}
      loading={loading}
      tableData={objectSyncReports
        ?.map((objectSyncReport, index) =>
          "completed_sync_run" in objectSyncReport &&
          !!objectSyncReport.completed_sync_run
            ? {
                ...objectSyncReport.completed_sync_run,
                object_name: objectSyncReport.object_name,
                id: index.toString(),
              }
            : undefined,
        )
        .filter((value): value is CensusSyncReportData => !!value)}
    />
  );
};

const CensusIncompleteSyncsReportTab: React.FC<{
  inProgressSyncs: number;
  queuedSyncs: number;
  failedSyncs: number;
  loading: boolean;
  objectSyncReports: LatestSyncRunReport[];
}> = ({
  inProgressSyncs,
  queuedSyncs,
  failedSyncs,
  loading,
  objectSyncReports,
}) => {
  return (
    <CensusSyncReportTab
      cards={[
        {
          label: "Syncs in progress",
          value: inProgressSyncs,
          valueClassName: "text-core-indigo",
        },
        {
          label: "Queued syncs",
          value: queuedSyncs,
        },
        {
          label: "Failed syncs",
          value: failedSyncs,
          valueClassName: "text-error-600",
          tooltipContent:
            failedSyncs > 0 ? FAILED_INCOMPLETE_SYNC_DEFAULT_ERROR : undefined,
        },
      ]}
      emptyStateText="No incomplete syncs"
      loading={loading}
      tableColumns={[
        objectNameColumn,
        {
          id: "scheduled_execution_time",
          header: "Date",
          size: 500,
          accessorFn: (row) => row.completed_at || row.scheduled_execution_time,
          cell: (props) => {
            const scheduledExecutionTime = props.getValue();
            return scheduledExecutionTime
              ? renderDateTimeInUTC(
                  dayjs.utc(scheduledExecutionTime).toDate(),
                  false,
                )
              : undefined;
          },
          enableSorting: false,
        },
        statusColumn,
      ]}
      tableData={objectSyncReports
        ?.map((objectSyncReport, index) =>
          "uncompleted_sync_run" in objectSyncReport &&
          !!objectSyncReport.uncompleted_sync_run
            ? {
                ...objectSyncReport.uncompleted_sync_run,
                object_name: objectSyncReport.object_name,
                id: index.toString(),
              }
            : undefined,
        )
        .filter((value): value is CensusSyncReportData => !!value)}
    />
  );
};

const CensusDashboardContent: React.FC<{
  destinationType: CensusDestinationTypeEnum;
  showIncompleteSyncs: boolean;
}> = ({ destinationType, showIncompleteSyncs }) => {
  const queryResult = useGetObjectSyncReportsQuery({
    variables: {
      destination_type: destinationType,
    },
  });
  const { data, loading, error } = queryResult;
  const objectSyncReports =
    data?.object_sync_reports?.map((objectSyncReport) =>
      getLatestSyncRunReport(
        objectSyncReport.object_name,
        objectSyncReport.sync_runs || [],
      ),
    ) || [];
  const {
    totalRows,
    successfulRows,
    failedRows,
    failedSyncs,
    inProgressSyncs,
    queuedSyncs,
  } = getSyncSummary(objectSyncReports, loading, error);

  return error ? (
    error.message === "Census workspace is not set up" ? (
      <EmptyState
        mainText={error.message}
        supportingText="Please complete setting up your Census workspace."
        actions={[
          <Button
            text="Back to Integrations"
            linkTo="/connections/integrations"
          />,
        ]}
        icon="settings01"
        className="w-full"
      />
    ) : (
      <EmptyState
        mainText="An unknown error occurred while getting sync report"
        icon="alertCircle"
        className="w-full"
      />
    )
  ) : (
    <>
      {showIncompleteSyncs ? (
        <CensusIncompleteSyncsReportTab
          inProgressSyncs={inProgressSyncs}
          queuedSyncs={queuedSyncs}
          failedSyncs={failedSyncs}
          loading={loading}
          objectSyncReports={objectSyncReports}
        />
      ) : (
        <CensusCompletedSyncsReportTab
          totalRows={totalRows}
          successfulRows={successfulRows}
          failedRows={failedRows}
          loading={loading}
          objectSyncReports={objectSyncReports}
        />
      )}
    </>
  );
};

type CensusSyncReportData = CensusSyncRun & { object_name: string };
const CensusSyncReportTab: React.FC<{
  cards: {
    label: string;
    value: number;
    valueClassName?: string;
    tooltipContent?: string;
  }[];
  loading: boolean;
  emptyStateText: string;
  tableColumns: TableProps<CensusSyncReportData>["columns"];
  tableData: CensusSyncReportData[];
}> = ({ cards, loading, emptyStateText, tableColumns, tableData }) => {
  return (
    <>
      <div className="grid grid-cols-3 gap-lg">
        {cards.map(
          ({ label, value, valueClassName, tooltipContent }, index) => (
            <Card
              loading={loading}
              key={`card-${index}`}
              metrics={[
                {
                  label,
                  value: new Decimal(value),
                  valueClassName,
                  tooltipContent,
                },
              ]}
            />
          ),
        )}
      </div>
      <div className="mt-lg">
        <Table<CensusSyncRun & { object_name: string }>
          emptyState={
            <EmptyState
              mainText={emptyStateText}
              icon="checkCircleBroken"
              className="w-full"
            />
          }
          columns={tableColumns}
          loading={loading}
          data={tableData}
        />
      </div>
    </>
  );
};

const CensusSyncStatusBadge: React.FC<{
  syncRun: CensusSyncRun;
}> = ({ syncRun }) => {
  const pushMessage = useSnackbar();
  switch (syncRun.status) {
    case CensusSyncRunStatusEnum.Completed:
      const numFailedRecords = syncRun.records_failed || 0;
      const hasFailedRecords = numFailedRecords > 0;
      const sampledFailedRecords = syncRun.sampled_failed_records || [];
      const failedRecordsSampleAvailable =
        hasFailedRecords && sampledFailedRecords.length > 0;
      return hasFailedRecords ? (
        failedRecordsSampleAvailable ? (
          <Badge
            label={`${numFailedRecords} errors`}
            theme="error"
            icon="download01"
            iconPosition="right"
            onClick={() => {
              const reportCSV = failedRecordsToCSV(sampledFailedRecords);
              downloadCSV(
                `failed-records-${dayjs.utc(syncRun.completed_at || undefined)}.csv`,
                reportCSV,
              );
            }}
          ></Badge>
        ) : (
          <Tooltip label={UNAVAILABLE_FAILED_RECORDS_SAMPLE_ERROR}>
            <Badge
              label={`${numFailedRecords} errors`}
              theme="error"
              icon="alertCircle"
              iconPosition="right"
            ></Badge>
          </Tooltip>
        )
      ) : (
        <Badge label="Completed" theme="success"></Badge>
      );
    case CensusSyncRunStatusEnum.Failed:
      return (
        <Tooltip label={FAILED_INCOMPLETE_SYNC_DEFAULT_ERROR}>
          <Badge
            label="Failed"
            theme="error"
            icon="alertCircle"
            iconPosition="right"
          ></Badge>
        </Tooltip>
      );
    case CensusSyncRunStatusEnum.Working:
      return <Badge label="In progress" theme="indigo"></Badge>;
    case CensusSyncRunStatusEnum.Queued:
      return <Badge label="Queued" theme="gray"></Badge>;
    case CensusSyncRunStatusEnum.Skipped:
      return <Badge label="Skipped" theme="warning"></Badge>;
    default:
      syncRun.status satisfies never;
      pushMessage({ content: `Invalid syncRun status ${syncRun.status}` });
      return <Badge label="Unknown" theme="gray"></Badge>;
  }
};

const failedRecordsToCSV = (
  records: NonNullable<CensusSyncRun["sampled_failed_records"]>,
) => {
  const recordsWithFlattenedAttributes = records.map(
    (r: { error_message: string; record_attributes: any }) => ({
      error_message: r.error_message,
      ...r.record_attributes,
    }),
  );
  return convertJsonToStringArrayWithHeader(recordsWithFlattenedAttributes);
};

function convertJsonToStringArrayWithHeader(
  jsonList: Record<string, any>[],
): string[][] {
  if (jsonList.length === 0) {
    return [];
  }

  const headers = Object.keys(jsonList[0]);
  const dataRows = jsonList.map((obj) =>
    headers.map((key) => String(obj[key])),
  );

  return [headers, ...dataRows];
}

const getSyncSummary = (
  objectSyncReports: LatestSyncRunReport[],
  loading: boolean,
  error: ApolloError | undefined,
): {
  totalRows: number;
  successfulRows: number;
  failedRows: number;
  inProgressSyncs: number;
  queuedSyncs: number;
  failedSyncs: number;
  objectsWithoutSyncs?: string[];
} => {
  const unloadedState = {
    totalRows: 0,
    successfulRows: 0,
    failedRows: 0,
    inProgressSyncs: 0,
    queuedSyncs: 0,
    failedSyncs: 0,
  };

  if (loading || error) {
    return unloadedState;
  }

  const syncSummary = unloadedState;
  const objectsWithoutSyncs: string[] = [];
  for (const objectSyncReport of objectSyncReports) {
    if ("error" in objectSyncReport) {
      objectsWithoutSyncs.push(objectSyncReport.object_name);
      continue;
    }
    const completedSyncRun = objectSyncReport.completed_sync_run;

    syncSummary.totalRows += completedSyncRun?.records_processed ?? 0;
    syncSummary.successfulRows += completedSyncRun?.records_updated ?? 0;
    syncSummary.failedRows += completedSyncRun?.records_failed ?? 0;

    const uncompletedSyncRun = objectSyncReport.uncompleted_sync_run;
    syncSummary.inProgressSyncs +=
      uncompletedSyncRun?.status === "working" ? 1 : 0;
    syncSummary.queuedSyncs += uncompletedSyncRun?.status === "queued" ? 1 : 0;
    syncSummary.failedSyncs += uncompletedSyncRun?.status === "failed" ? 1 : 0;
  }

  return {
    ...syncSummary,
    objectsWithoutSyncs,
  };
};

type LatestSyncRunReport = {
  object_name: string;
} & (
  | {
      completed_sync_run?: CensusSyncRun;
      uncompleted_sync_run?: CensusSyncRun;
    }
  | {
      error: string;
    }
);

const getLatestSyncRunReport = (
  objectName: string,
  latestSyncRuns: CensusSyncRun[],
): LatestSyncRunReport => {
  if (!latestSyncRuns.length) {
    return {
      object_name: objectName,
      error: getNoSyncRunsFoundError(objectName),
    };
  }

  const latestSyncRun = latestSyncRuns[0];

  if (latestSyncRun.status === CensusSyncRunStatusEnum.Completed) {
    return {
      object_name: objectName,
      completed_sync_run: latestSyncRun,
    };
  } else {
    const mostRecentCompletedSyncRun =
      getMostRecentCompletedSyncRun(latestSyncRuns);
    return {
      object_name: objectName,
      completed_sync_run: mostRecentCompletedSyncRun,
      uncompleted_sync_run: latestSyncRun,
    };
  }
};

const getMostRecentCompletedSyncRun = (
  recentSyncRuns: CensusSyncRun[],
): CensusSyncRun | undefined => {
  for (const syncRun of recentSyncRuns) {
    if (syncRun.status === CensusSyncRunStatusEnum.Completed) {
      return syncRun;
    }
  }
  return undefined;
};

const getNoSyncRunsFoundError = (objectName: string): string => {
  return `Could not find any sync runs for object ${objectName}`;
};
