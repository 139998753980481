import React from "react";
import { Navigate, RouteObject, useLocation } from "react-router-dom";

import { useAuth } from "lib/auth";

import APITokens from "pages/APITokens";
import { BillableMetricRoutes } from "pages/BillableMetric";
import BillableMetrics from "pages/BillableMetrics";
import Seats from "pages/BillableMetrics/seats";
import { GeneralSettingsRoute } from "pages/GeneralSettings";
import StripeRedirect from "pages/StripeRedirect";
import NotFoundPage from "pages/404";
import Products from "pages/Products";
import Product from "pages/Product";
import { NewMetricRoutes } from "pages/NewBillableMetric/BillableMetricV2";
import NewProduct from "pages/NewProduct";
import EditProduct from "pages/EditProduct";
import Plans from "pages/Plans";
import { PlanRoute } from "pages/Plan";
import NewPlan from "pages/PlanWizards/NewPlan";
import EditPlan from "pages/PlanWizards/EditPlan";
import Events from "pages/Events";
import Webhooks from "pages/Webhooks";
import Account from "pages/Account";
import Alerts from "pages/Alerts";
import Alert from "pages/Alert";
import NewAlert from "pages/NewAlert";
import NewDataExportDestination from "pages/NewDataExportDestination";
import UpdateDataExportDestination from "pages/UpdateDataExportDestination";
import CustomFields from "pages/CustomFields";
import { EnvSlug$ } from "lib/environmentSwitcher/context";
import { ContractCustomersRoutes } from "pages/Contracts/ContractRoutes";
import { LegacyDesignCensusDashboard } from "pages/GeneralSettings/CensusDashboard";
import { CensusDestinationTypeEnum } from "./types/generated-graphql/__types__";
import Reporting from "./pages/Reporting";
import { NewUIRouteSwitch } from "tenaissance/lib/routeSwitch";
import { ConnectionsRoutes } from "tenaissance/pages/Connections";
import { OfferingRoutes } from "tenaissance/pages/Offering";
import { SettingsRoutes } from "./tenaissance/pages/Settings";
import { EnvironmentRedirect } from "lib/environmentSwitcher/EnvironmentRedirect";

export const CustomFieldsRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/custom-fields([\/?#]|$)/,
    "/connections/custom-fields$1",
  );
  return <Navigate to={newPath} replace />;
};

const Logout: React.FC = () => {
  const auth = useAuth();
  auth.logout();
  return null;
};

const ENV_SLUGS = ["/sandbox", "/env-uat", "/env-qa", "/env-staging"];

/**
 * Routes that exist both in and out of sandbox mode.
 */
const EnvRoutes: RouteObject[] = [
  ...ContractCustomersRoutes,

  GeneralSettingsRoute,
  ...OfferingRoutes,
  ...ConnectionsRoutes,
  ...SettingsRoutes,
  {
    path: "settings/stripe/redirect_callback/*",
    element: <StripeRedirect />,
  },
  {
    path: "developer/events/*",
    element: (
      <NewUIRouteSwitch
        disabled={<Events />}
        enabled={<EnvironmentRedirect to="/connections/events" />}
      />
    ),
  },
  {
    path: "developer/tokens",
    element: (
      <NewUIRouteSwitch
        disabled={<APITokens />}
        enabled={<EnvironmentRedirect to="/connections/api-tokens-webhooks" />}
      />
    ),
  },
  {
    path: "developer/webhooks",
    element: (
      <NewUIRouteSwitch
        disabled={<Webhooks />}
        enabled={<EnvironmentRedirect to="/connections/api-tokens-webhooks" />}
      />
    ),
  },
  {
    path: "products",
    element: (
      <NewUIRouteSwitch
        disabled={<Products />}
        enabled={<EnvironmentRedirect to="/offering/products" />}
      />
    ),
  },
  {
    path: "products/new",
    element: (
      <NewUIRouteSwitch
        disabled={<NewProduct />}
        enabled={<EnvironmentRedirect to="/offering/products/create" />}
      />
    ),
  },
  {
    path: "products/:id/edit",
    element: (
      <NewUIRouteSwitch
        disabled={<EditProduct />}
        enabled={<EnvironmentRedirect to="/offering/products" />}
      />
    ),
  },
  {
    path: "products/:id",
    element: (
      <NewUIRouteSwitch
        disabled={<Product />}
        enabled={<EnvironmentRedirect to="/offering/products" />}
      />
    ),
  },
  {
    path: "billable-metrics",
    element: (
      <NewUIRouteSwitch
        disabled={<BillableMetrics />}
        enabled={<EnvironmentRedirect to="/offering/billable-metrics" />}
      />
    ),
  },
  {
    path: "billable-metrics/seats",
    element: (
      <NewUIRouteSwitch
        disabled={<Seats />}
        enabled={<EnvironmentRedirect to="/offering/billable-metrics/seats" />}
      />
    ),
  },
  ...NewMetricRoutes,
  ...BillableMetricRoutes,
  {
    path: "plans",
    element: (
      <NewUIRouteSwitch
        disabled={<Plans />}
        enabled={<EnvironmentRedirect to="/offering/plans" />}
      />
    ),
  },
  {
    path: "plans/new/:id?",
    element: (
      <NewUIRouteSwitch
        disabled={<NewPlan />}
        enabled={<EnvironmentRedirect to="/offering/plans/new" />}
      />
    ),
  },
  {
    path: "plans/edit/:id",
    element: (
      <NewUIRouteSwitch
        disabled={<EditPlan />}
        enabled={<EnvironmentRedirect to="/offering/plans" />}
      />
    ),
  },
  PlanRoute,
  {
    path: "alerts",
    element: (
      <NewUIRouteSwitch
        disabled={<Alerts />}
        enabled={<EnvironmentRedirect to="/connections/alerts" />}
      />
    ),
  },
  {
    path: "alerts/new",
    element: (
      <NewUIRouteSwitch
        disabled={<NewAlert />}
        enabled={<EnvironmentRedirect to="/connections/alerts/new" />}
      />
    ),
  },
  {
    path: "alerts/:id",
    element: (
      <NewUIRouteSwitch
        disabled={<Alert />}
        enabled={<EnvironmentRedirect to="/connections/alerts" />}
      />
    ),
  },
  //TODO: Remove this once we've migrated to new UI. Referenced by old UI
  {
    path: "reports",
    element: <EnvironmentRedirect to="/reporting" />,
  },
  {
    path: "reporting",
    element: <Reporting />,
  },
  {
    path: "custom-fields/:entity/:id",
    element: (
      <NewUIRouteSwitch
        disabled={<CustomFields />}
        enabled={<CustomFieldsRedirect />}
      />
    ),
  },
  // Data Export edit.  Yes data export can only be done once per client, but confluent
  // has a special client which doesnt have the PRODUCTION metronome env enabled
  // so this 404s.  But they also cant run the one at root because then it will use
  // the production scoped jwt, which doesnt have perms to data export :(
  {
    path: "settings/general/integrations/data-export/update/:destinationId",
    element: (
      <NewUIRouteSwitch
        disabled={<UpdateDataExportDestination />}
        enabled={<EnvironmentRedirect to="/connections/data-export" />}
      />
    ),
  },
  {
    path: "settings/general/integrations/salesforce",
    element: (
      <NewUIRouteSwitch
        disabled={
          <LegacyDesignCensusDashboard
            destinationType={CensusDestinationTypeEnum.Salesforce}
          />
        }
      />
    ),
  },
  {
    path: "settings/general/integrations/salesforce/incomplete",
    element: (
      <NewUIRouteSwitch
        disabled={
          <LegacyDesignCensusDashboard
            destinationType={CensusDestinationTypeEnum.Salesforce}
            showIncompleteSyncs
          />
        }
      />
    ),
  },
];

export const AppRoutes: RouteObject[] = [
  /* --------------------------------------------------- */
  /* Redirects from old pages to new pages */
  /* --------------------------------------------------- */
  {
    path: "/developer/integration-pipeline/*",
    element: <EnvironmentRedirect to="/developer/events" />,
  },
  {
    path: "/sandbox/developer/integration-pipeline/*",
    element: <EnvironmentRedirect to="/developer/events" />,
  },
  {
    path: "/settings/billing-configuration",
    element: <EnvironmentRedirect to="/settings/general/integrations" />,
  },
  {
    path: "/settings/billing-configuration/billing-providers",
    element: <EnvironmentRedirect to="/settings/general/integrations" />,
  },
  {
    path: "/settings/billing-configuration/pricing-units",
    element: <EnvironmentRedirect to="/settings/general/pricing-units" />,
  },
  {
    path: "/settings/stripe",
    element: <EnvironmentRedirect to="/settings/general/integrations" />,
  },

  /* --------------------------------------------------- */
  /* Routes that don't exist in sandbox mode */
  /* --------------------------------------------------- */
  {
    path: "/settings/general/integrations/data-export/new",
    element: (
      <NewUIRouteSwitch
        disabled={<NewDataExportDestination />}
        enabled={<EnvironmentRedirect to="/connections/data-export" />}
      />
    ),
  },
  {
    path: "/account",
    element: <Account />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },

  /* --------------------------------------------------- */
  /* Routes that exist in and outside environments */
  /* --------------------------------------------------- */
  ...ENV_SLUGS.map(
    (slug): RouteObject => ({
      path: `${slug}/*`,
      handle: {
        [EnvSlug$]: slug,
      },
      children: [...EnvRoutes, { path: "*", element: <NotFoundPage /> }],
    }),
  ),
  // include the default "PRODUCTION" environment
  {
    path: "/*",
    handle: {
      [EnvSlug$]: "",
    },
    children: [...EnvRoutes, { path: "*", element: <NotFoundPage /> }],
  },
];
