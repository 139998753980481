import React from "react";

import { useNavigate } from "lib/useNavigate";
import { useOptionalParam } from "lib/routes/params";
import { useSearchParam } from "lib/routes/useSearchParam";
import { useRequiredParam } from "lib/routes/params";

import { CustomerContractLayout } from "../CustomerContractLayout";
import { CommitCard, CommitTab, parseCommitTab } from "./CommitCard";
import { EmptyState } from "tenaissance/components/EmptyState";
import { useAsyncCommitUsage } from "../Usage";
import { useApolloResp } from "pages/Contracts/lib/ApolloResp";
import { useContractCommitsQuery } from "./data.graphql";
import { getAllCommits } from "./findCommit";
import { AppShell } from "components/PageContainer";
import { Breadcrumbs } from "lib/breadcrumbs";
import { ContractOrPlan } from "pages/Contracts/lib/ContractOrPlan";
import { Commit } from "pages/Contracts/lib/Commit";
import { dayjs } from "lib/dayjs";
import { useFeatureFlag } from "lib/launchdarkly";
export const CustomerContractCommits: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");
  const commitId = useOptionalParam("commitId");
  const canEditContract = useFeatureFlag<boolean>(
    "allow-contract-editing",
    false,
  );

  const [activeTabs, setActiveTabs] = React.useState<Record<string, CommitTab>>(
    {},
  );
  const activeTabParam = parseCommitTab(useSearchParam("tab")[0]);
  const req = useApolloResp(
    useContractCommitsQuery({
      variables: {
        customerId,
        contractId,
        include_v2_fields: canEditContract ?? false,
      },
    }),
  );
  const asyncUsage = useAsyncCommitUsage({
    contractId,
    customerId,
    include_v2_fields: canEditContract ?? false,
  });

  React.useEffect(() => {
    if (commitId && activeTabParam) {
      setActiveTabs((prevTabs) => ({
        ...prevTabs,
        [commitId]: activeTabParam,
      }));
    }
  }, [commitId, activeTabParam]);

  if (!commitId) {
    return (
      <LegacyDesignCustomerContractCommits
        customerId={customerId}
        contractId={contractId}
        commitId={commitId}
        req={req}
        asyncUsage={asyncUsage}
        activeTabs={activeTabs}
        setActiveTabs={setActiveTabs}
      />
    );
  }

  if (req.state === "loading") {
    return (
      <AppShell
        title={{ state: "loading" }}
        headerProps={{
          breadcrumbs: Breadcrumbs.from(
            { label: "Customers", routePath: "/customers" },
            { type: "loading", label: "" },
            { label: "Contracts" },
            { type: "loading", label: "" },
            {
              label: "Balances",
              routePath: `/customers/${customerId}/contracts/${contractId}/commits-and-credits`,
            },
          ),
        }}
      />
    );
  }

  const allCommits =
    req.state === "success" && req.customer.contract
      ? getAllCommits(req.customer.contract)
      : [];
  const commit = commitId
    ? allCommits.find((c) => c.id === commitId)
    : undefined;

  const content = commit ? (
    <CommitCard
      key={commit.id}
      commit={commit}
      activeTab={activeTabs[commit.id] || "ledger"}
      asyncUsage={asyncUsage}
      onTabChange={(tab) => {
        setActiveTabs((prevTabs) => ({
          ...prevTabs,
          [commit.id]: tab,
        }));
      }}
    />
  ) : (
    <EmptyState
      icon="bankNote03"
      mainText="Commit not found"
      supportingText="The requested commit does not exist."
    />
  );

  return (
    <AppShell
      title={commit ? Commit.getName(commit, dayjs()) : "Balances"}
      headerProps={{
        breadcrumbs:
          req.state === "success" && req.customer.contract
            ? Breadcrumbs.from(
                { label: "Customers", routePath: "/customers" },
                {
                  label: req.customer.name ?? "",
                  routePath: `/customers/${customerId}`,
                },
                { label: "Contracts" },
                {
                  label: ContractOrPlan.getName(req.customer.contract),
                  routePath: `/customers/${customerId}/contracts/${contractId}`,
                },
                {
                  label: "Balances",
                  routePath: `/customers/${customerId}/contracts/${contractId}/commits-and-credits`,
                },
              )
            : undefined,
      }}
    >
      {content}
    </AppShell>
  );
};

const LegacyDesignCustomerContractCommits: React.FC<{
  customerId: string;
  contractId: string;
  commitId?: string;
  req: any; // Replace with proper type
  asyncUsage: any; // Replace with proper type
  activeTabs: Record<string, CommitTab>;
  setActiveTabs: React.Dispatch<
    React.SetStateAction<Record<string, CommitTab>>
  >;
}> = ({
  customerId,
  contractId,
  commitId,
  req,
  asyncUsage,
  activeTabs,
  setActiveTabs,
}) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (
      commitId &&
      req.state === "success" &&
      req.customer.contract &&
      !getAllCommits(req.customer.contract).some((c) => c.id === commitId)
    ) {
      navigate(
        `/customers/${customerId}/contracts/${contractId}/commits-and-credits`,
        { replace: true },
      );
    }
  }, [commitId, req, customerId, contractId, navigate]);

  return (
    <CustomerContractLayout
      rootReq={req}
      disableContainerScroll={({ contract }) =>
        getAllCommits(contract).length === 0
      }
      content={({ contract }) => {
        const allCommits = getAllCommits(contract);

        return !allCommits?.length ? (
          <EmptyState
            icon="bankNote03"
            mainText="No commits"
            supportingText="This contract has not been issued a commit."
          />
        ) : (
          allCommits.map((commit) => (
            <CommitCard
              key={commit.id}
              commit={commit}
              focus={commit.id === commitId}
              activeTab={activeTabs[commit.id] || "ledger"}
              asyncUsage={asyncUsage}
              onTabChange={(tab) => {
                setActiveTabs((prevTabs) => ({
                  ...prevTabs,
                  [commit.id]: tab,
                }));
              }}
            />
          ))
        );
      }}
    />
  );
};
