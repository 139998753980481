import React from "react";
import { AppShell } from "components/PageContainer";
import { RouteObject } from "react-router";
import { useUIMode } from "lib/useUIMode";
import { NewUIRouteSwitch } from "tenaissance/lib/routeSwitch";
import { RateCardDetails } from "./tabs/RateCards/RateCardDetails";
import { RateCardsList } from "pages/Contracts/Pricing/RateCardsList";
import { RateCardCreateV2 } from "./tabs/RateCards/RateCardCreate";
import { Step1 } from "./tabs/RateCards/Step1";
import { Step2 } from "./tabs/RateCards/Step2";
import { Step3 } from "./tabs/RateCards/Step3";
import { CreateProduct } from "pages/Contracts/Pricing/CreateAndEditProductV2/CreateProduct";
import Plans from "pages/Plans";
import NewPlan from "pages/PlanWizards/NewPlan";
import EditPlan from "pages/PlanWizards/EditPlan";
import {
  PlanAlerts,
  PlanAuditLogs,
  PlanCustomers,
  PlanOverview,
  PlanTab,
} from "pages/Plan";
import Product from "pages/Product";
import EditProduct from "pages/EditProduct";
import NewProduct from "pages/NewProduct";
import { BillableMetricsContainer } from "./tabs/BillableMetrics/BillableMetricsContainer";
import { BillableMetricV2 } from "pages/NewBillableMetric/BillableMetricV2";
import AddRatePage from "./tabs/RateCards/AddRatePage";
import { PricingUnits } from "./tabs/PricingUnits";
import { ProductListContainer } from "./tabs/Products";

export const Offering: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { mode } = useUIMode();
  const tabs = tabPathsForMode[mode].map(
    (path) => allTabs[path as keyof typeof allTabs],
  );
  return (
    <AppShell
      title="Offering"
      headerProps={{
        tabs,
        basePath: "offering",
      }}
    >
      {children}
    </AppShell>
  );
};

const allTabs = {
  "rate-cards": {
    name: "Rate cards",
    path: "rate-cards",
    enabledModes: ["contracts-only", "contracts-and-plans"],
    children: [
      {
        index: true,
        element: (
          <NewUIRouteSwitch
            enabled={
              <Offering>
                <RateCardsList />
              </Offering>
            }
          />
        ),
      },
      {
        path: "new",
        element: <NewUIRouteSwitch enabled={<RateCardCreateV2 />} />,
        children: [
          {
            path: "",
            element: <NewUIRouteSwitch enabled={<Step1 />} />,
            index: true,
          },
          {
            path: "dimensional-pricing",
            element: <NewUIRouteSwitch enabled={<Step2 />} />,
          },
          {
            path: "rate-products",
            element: <NewUIRouteSwitch enabled={<Step3 />} />,
          },
        ],
      },
      {
        path: ":id",
        children: [
          {
            index: true,
            element: <NewUIRouteSwitch enabled={<RateCardDetails />} />,
          },
          {
            path: "add-rate",
            element: <NewUIRouteSwitch enabled={<AddRatePage />} />,
          },
        ],
      },
    ],
  },
  products: {
    name: "Products",
    path: "products",
    children: [
      {
        index: true,
        element: (
          <NewUIRouteSwitch
            enabled={
              <Offering>
                <ProductListContainer />
              </Offering>
            }
          />
        ),
      },
      {
        path: "create",
        element: <CreateProduct />,
      },
    ],
    enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
  },
  plans: {
    name: "Plans",
    path: "plans",
    enabledModes: ["plans-only", "contracts-and-plans"],
    children: [
      {
        index: true,
        element: (
          <NewUIRouteSwitch
            enabled={
              <Offering>
                <Plans />
              </Offering>
            }
            enabledModes={["plans-only", "contracts-and-plans"]}
          />
        ),
      },
      {
        path: "new",
        enabledModes: ["plans-only", "contracts-and-plans"],
        children: [
          {
            index: true,
            element: <NewUIRouteSwitch enabled={<NewPlan />} />,
          },
          {
            path: ":id?",
            element: <NewUIRouteSwitch enabled={<NewPlan />} />,
          },
        ],
      },
      {
        path: "edit/:id",
        element: <NewUIRouteSwitch enabled={<EditPlan />} />,
      },
      {
        path: ":id",
        children: [
          { index: true, element: <PlanTab View={PlanOverview} /> },
          { path: "customers", element: <PlanTab View={PlanCustomers} /> },
          { path: "alerts", element: <PlanTab View={PlanAlerts} /> },
          { path: "audit-logs", element: <PlanTab View={PlanAuditLogs} /> },
        ],
      },
      {
        path: "products",
        children: [
          {
            index: true,
            element: (
              <NewUIRouteSwitch
                enabled={
                  <Offering>
                    <Plans />
                  </Offering>
                }
              />
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: <NewUIRouteSwitch enabled={<Product />} />,
              },
              {
                path: "edit",
                element: <NewUIRouteSwitch enabled={<EditProduct />} />,
              },
            ],
          },
          {
            path: "new",
            element: <NewUIRouteSwitch enabled={<NewProduct />} />,
          },
        ],
      },
    ],
  },
  "billable-metrics": {
    name: "Billable metrics",
    path: "billable-metrics",
    children: [
      {
        index: true,
        element: (
          <NewUIRouteSwitch
            enabled={
              <Offering>
                <BillableMetricsContainer />
              </Offering>
            }
          />
        ),
      },
      {
        path: "seats",
        element: (
          <NewUIRouteSwitch
            enabled={
              <Offering>
                <BillableMetricsContainer />
              </Offering>
            }
          />
        ),
      },
      {
        path: "new",
        children: [
          {
            index: true,
            element: <BillableMetricV2 metricType="billable" />,
          },
          {
            path: ":id",
            element: <BillableMetricV2 metricType="billable" />,
          },
        ],
      },
    ],
    enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
  },
  "pricing-units": {
    name: "Pricing units",
    path: "pricing-units",
    enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
    element: (
      <NewUIRouteSwitch
        enabled={
          <Offering>
            <PricingUnits />
          </Offering>
        }
      />
    ),
    children: [
      {
        path: "custom-pricing-units",
        element: (
          <NewUIRouteSwitch
            enabled={
              <Offering>
                <PricingUnits />
              </Offering>
            }
          />
        ),
      },
    ],
  },
};

const tabPathsForMode = {
  "contracts-only": [
    "rate-cards",
    "products",
    "billable-metrics",
    "pricing-units",
  ],
  "plans-only": ["plans", "products", "billable-metrics", "pricing-units"],
  "contracts-and-plans": [
    "rate-cards",
    "products",
    "plans",
    "billable-metrics",
    "pricing-units",
  ],
  off: [],
};

export const OfferingRoutes: RouteObject[] = [
  {
    path: "offering",
    children: [...Object.values(allTabs)],
  },
];

export default Offering;
