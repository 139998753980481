import React, { useState } from "react";

import styles from "./index.module.less";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { Input } from "design-system";
import { useSnackbar } from "components/Snackbar";
import { useUpsertNetSuiteCustomerIdMutation } from "./queries.graphql";

type NetSuiteConnectionModalProps = {
  isOpen: boolean;
  onClose: (deleteConfig?: boolean) => void;
  customerId: string;
  netSuiteCustomerId?: string;
};

export const NetSuiteConnectionModal: React.FC<
  NetSuiteConnectionModalProps
> = ({ isOpen, onClose, customerId, ...props }) => {
  const [netSuiteCustomerId, setNetSuiteCustomerId] = useState(
    props.netSuiteCustomerId ?? "",
  );
  const pushMessage = useSnackbar();
  const [upsertNetSuiteCustomerId, { loading }] =
    useUpsertNetSuiteCustomerIdMutation();
  const validate = (cId: string) => cId.length > 0;
  const isEdit = netSuiteCustomerId !== undefined;

  return (
    <Popup
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      actions={
        <>
          {isEdit ? (
            <Button
              onClick={() => onClose(true)}
              text="Delete Customer ID"
              theme="linkGray"
            />
          ) : (
            <Button onClick={() => onClose()} text="Cancel" theme="linkGray" />
          )}

          <Button
            onClick={async () => {
              await upsertNetSuiteCustomerId({
                variables: {
                  customer_id: customerId,
                  netsuite_customer_id: netSuiteCustomerId,
                },
                update: (cache) => {
                  cache.evict({
                    fieldName: "CustomerConfig",
                  });
                },
              });
              pushMessage({
                content: "NetSuite customer ID saved",
                type: "success",
              });
              onClose();
            }}
            loading={loading}
            disabled={loading || !validate(netSuiteCustomerId)}
            text="Add Customer ID"
            theme="primary"
          />
        </>
      }
      title="NetSuite customer ID"
      className={styles.netSuiteConnectionModal}
    >
      <Body level={1} className={styles.description}>
        Connect this customer to NetSuite by adding their NetSuite customer ID.
      </Body>
      <Input
        className={styles.netSuiteCustomerIdInput}
        placeholder="0015500001"
        value={netSuiteCustomerId}
        onChange={setNetSuiteCustomerId}
        name="NetSuite customer ID"
        error={netSuiteCustomerId && !validate(netSuiteCustomerId)}
        success={validate(netSuiteCustomerId)}
      />
    </Popup>
  );
};
