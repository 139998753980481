import React from "react";

import { Badge } from "tenaissance/components/Badge";
import { AuditLogStatusEnum } from "types/generated-graphql/__types__";

export const AuditLogStatusPill: React.FC<{
  status: AuditLogStatusEnum;
  size: "sm" | "md";
}> = ({ status, size }) => {
  const theme = (
    {
      [AuditLogStatusEnum.Success]: "success",
      [AuditLogStatusEnum.Failure]: "error",
      [AuditLogStatusEnum.Pending]: "warning",
    } as const
  )[status];

  return (
    <div>
      <Badge
        label={status.charAt(0) + status.slice(1).toLowerCase()}
        theme={theme}
        size={size}
      />
    </div>
  );
};
