/* 39d78c34baa59ffa4f4d011522f666ba74caf36b
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditNetPaymentTermsMutationVariables = Types.Exact<{
  customer_plan_id: Types.Scalars['uuid'];
  net_payment_terms?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type EditNetPaymentTermsMutation = { __typename?: 'Mutation', update_CustomerPlan_by_pk: { __typename?: 'CustomerPlan', id: string, net_payment_terms_days: number | null } | null };


export const EditNetPaymentTermsDocument = gql`
    mutation EditNetPaymentTerms($customer_plan_id: uuid!, $net_payment_terms: Int) {
  update_CustomerPlan_by_pk(
    pk_columns: {id: $customer_plan_id}
    _set: {net_payment_terms_days: $net_payment_terms}
  ) {
    id
    net_payment_terms_days
  }
}
    `;
export type EditNetPaymentTermsMutationFn = Apollo.MutationFunction<EditNetPaymentTermsMutation, EditNetPaymentTermsMutationVariables>;

/**
 * __useEditNetPaymentTermsMutation__
 *
 * To run a mutation, you first call `useEditNetPaymentTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNetPaymentTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNetPaymentTermsMutation, { data, loading, error }] = useEditNetPaymentTermsMutation({
 *   variables: {
 *      customer_plan_id: // value for 'customer_plan_id'
 *      net_payment_terms: // value for 'net_payment_terms'
 *   },
 * });
 */
export function useEditNetPaymentTermsMutation(baseOptions?: Apollo.MutationHookOptions<EditNetPaymentTermsMutation, EditNetPaymentTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditNetPaymentTermsMutation, EditNetPaymentTermsMutationVariables>(EditNetPaymentTermsDocument, options);
      }
export type EditNetPaymentTermsMutationHookResult = ReturnType<typeof useEditNetPaymentTermsMutation>;
export type EditNetPaymentTermsMutationResult = Apollo.MutationResult<EditNetPaymentTermsMutation>;
export type EditNetPaymentTermsMutationOptions = Apollo.BaseMutationOptions<EditNetPaymentTermsMutation, EditNetPaymentTermsMutationVariables>;