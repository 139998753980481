import React from "react";
import { Popup, RightPane } from "components/Popup";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { Body, Headline } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { CellWithSubtitle } from "../CellWithSubtitle";
import { RateCardProductPositionInput } from "types/generated-graphql/__types__";
import { ProductListItem } from "../../lib/ProductListItem";
import { Dayjs } from "lib/dayjs";
import { useNow } from "lib/date";
import { FooterBar } from "../../Customer/Contracts/Create/components/FooterBar";
import {
  useGetProductOrderQuery,
  useMoveRateCardProductsMutation,
} from "./data.graphql";
import { useSnackbar } from "components/Snackbar";
import { getUserFacingErrorMessage } from "lib/errors/errorHandling";

export type ProductInfo = ProductListItem.IdFragment &
  ProductListItem.NameFragment;

interface Props {
  rateCardId: string;
  onClose: () => void;
}

const DraggableRow = ({ row, now }: { row: ProductInfo; now: Dayjs }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="rounded-medium border border-gray-light"
    >
      <div className="flex flex-row p-12">
        <div className="flex flex-col">
          <CellWithSubtitle
            withIndicator
            title={ProductListItem.getName(row, now)}
            subtitle={
              row.__typename === "UsageProductListItem" ? "Usage" : "Composite"
            }
          />
        </div>
      </div>
    </div>
  );
};

export function SortProductsFlyover({ rateCardId, onClose }: Props) {
  const now = useNow();

  const [moves, setMoves] = React.useState<RateCardProductPositionInput[]>([]);
  const [confirmingClose, setConfirmingClose] = React.useState(false);
  const snackbar = useSnackbar();

  const productsReq = useGetProductOrderQuery({
    variables: {
      rateCardId,
    },
  });

  const products = productsReq.data?.contract_pricing.rate_card.products;
  const [sortedProducts, setSortedProducts] = React.useState<typeof products>();

  const [save, saveResp] = useMoveRateCardProductsMutation({
    update(cache) {
      cache.evict({ id: `RateCardMetadata:${rateCardId}` });
      cache.evict({ id: `RateCard:${rateCardId}` });
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over && active.id !== over?.id) {
      setSortedProducts((state = products) => {
        if (!state) {
          return;
        }

        const position = (over.data.current as any).sortable.index as number;

        setMoves((curMoves) => [
          ...curMoves,
          { position, product_id: active.id as string },
        ]);

        const oldIndex = state.findIndex((item) => item.id === active.id);
        const newIndex = state.findIndex((item) => item.id === over.id);
        return arrayMove(state, oldIndex, newIndex);
      });
    }
  }

  function hasChanges() {
    return (
      products &&
      sortedProducts &&
      moves.length &&
      sortedProducts.some((p, i) => p.id !== products[i].id)
    );
  }

  function confirmClose() {
    if (saveResp.loading) {
      // dont do anything if we are in the process of saving
      return;
    }

    if (hasChanges()) {
      setConfirmingClose(true);
      return;
    }

    onClose();
  }

  const list = sortedProducts || products || [];
  return (
    <>
      {confirmingClose && (
        <Popup
          title="Discard changes?"
          isOpen
          onRequestClose={() => setConfirmingClose(false)}
          actions={
            <div className="flex justify-end gap-12">
              <Button
                onClick={() => setConfirmingClose(false)}
                text="Cancel"
                theme="secondary"
              />
              <Button onClick={onClose} text="Confirm" theme="secondary" />
            </div>
          }
        >
          <p>
            Are you sure you want to close this form? You have unsaved changes
          </p>
        </Popup>
      )}
      <RightPane
        isOpen={true}
        onRequestClose={confirmClose}
        size="lg"
        contentClassName="!p-0"
      >
        <header className="flex items-center border-b border-gray-100 bg-gray-50 px-12 py-8">
          <Headline level={6} className="grow">
            Sort products
          </Headline>
          <IconButton
            className="m-0"
            onClick={onClose}
            theme="secondary"
            icon="xClose"
          />
        </header>
        <Body level={1} className="m-12 text-gray-700">
          Order the list of products. This determines how products will appear
          on the rate card and invoices.
        </Body>
        <div className="flex h-full grow flex-col overflow-y-auto p-12">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext
              items={list}
              strategy={verticalListSortingStrategy}
              disabled={saveResp.loading}
            >
              <div className="flex flex-col gap-4">
                {list.map((row, i) => {
                  return <DraggableRow key={row.id} row={row} now={now} />;
                })}
              </div>
            </SortableContext>
          </DndContext>
        </div>
        <FooterBar
          right={
            <>
              <Button
                disabled={saveResp.loading}
                onClick={onClose}
                text="Cancel"
                theme="linkGray"
              />
              <Button
                disabled={saveResp.loading}
                loading={saveResp.loading}
                onClick={async () => {
                  // only save if there are real changes
                  if (!hasChanges()) {
                    onClose();
                    return;
                  }

                  save({
                    variables: {
                      input: {
                        rate_card_id: rateCardId,
                        product_moves: moves,
                      },
                    },
                  }).then(
                    () => {
                      snackbar({
                        type: "success",
                        content: "Rate card products ordered successfully",
                      });
                      onClose();
                    },
                    (error) => {
                      snackbar({
                        type: "error",
                        content: `Failed to move products in rate card: ${getUserFacingErrorMessage(error)}`,
                      });
                    },
                  );
                }}
                text="Save"
                theme="primary"
              />
            </>
          }
        />
      </RightPane>
    </>
  );
}
