import React from "react";
import { TableSkeleton } from "components/Table";
import { WizardFullPage } from "components/Wizard";
import { planToDraftPlan } from "lib/plans/draftPlan";
import { PlanDetails } from "lib/plans/types";
import styles from "./index.module.less";
import { usePlanActiveAndFutureCustomersQuery } from "../../../../data/queries.graphql";
import { useDraftPlan } from "../../../../context";
import { reportToSentry } from "lib/errors/sentry";
import { EmptyState } from "tenaissance/components/EmptyState";
import { useFeatureFlag } from "lib/launchdarkly";
import { CustomerImpactTable } from "./CustomerImpactTable";

interface Props {
  previousPlan: PlanDetails;
}

// 200 is arbitrarily chosen. The customer impact table doesn't load
// if the plan has lots of customers.
const MAX_NUM_CUSTOMERS_FOR_CUSTOMER_IMPACT_TABLE = 200;

export const CustomerImpact: React.FC<Props> = (props) => {
  const largePlans: string[] | undefined = useFeatureFlag(
    "large-plan-edit-ids",
    [],
  );
  const skipLoadingActiveCustomersData = !!largePlans?.includes(
    props.previousPlan.id,
  );
  const {
    data: customerCountsData,
    loading: customerCountsLoading,
    error: customerCountsDataError,
  } = usePlanActiveAndFutureCustomersQuery({
    variables: {
      plan_id: props.previousPlan.id,
    },
    skip: skipLoadingActiveCustomersData,
  });
  const { draftPlan } = useDraftPlan();
  const prevPlan = planToDraftPlan(props.previousPlan);
  const showRampChanges = !!draftPlan.ramps?.length || !!prevPlan.ramps.length;

  if (customerCountsLoading) {
    return (
      <WizardFullPage>
        <div className={styles.customerImpact}>
          <TableSkeleton
            columnNames={[
              "Customer name",
              "Plan start",
              "Plan end",
              ...(showRampChanges
                ? ["Current pricing ramp", "New pricing ramp"]
                : []),
              "Invoice impacted",
            ]}
          />
        </div>
      </WizardFullPage>
    );
  }
  const numCustomers =
    (customerCountsData?.Plan_by_pk?.active_customer_count ?? 0) +
    (customerCountsData?.Plan_by_pk?.future_customer_count ?? 0);

  if (
    !customerCountsData ||
    customerCountsDataError ||
    numCustomers > MAX_NUM_CUSTOMERS_FOR_CUSTOMER_IMPACT_TABLE
  ) {
    if (customerCountsDataError) {
      reportToSentry(customerCountsDataError);
    }
    return (
      <WizardFullPage>
        <div className={styles.customerImpact}>
          <div className={styles.customerImpactTooLarge}>
            <EmptyState
              icon="users01"
              mainText="This plan has too many customers to display here."
              supportingText="Please ask your Metronome representative if you would like a report on the customers affected by this edit."
            />
          </div>
        </div>
      </WizardFullPage>
    );
  }

  return (
    <WizardFullPage>
      <div className={styles.customerImpact}>
        <CustomerImpactTable
          previousPlan={props.previousPlan}
          numCustomers={numCustomers}
          numMarketplacesCustomers={
            customerCountsData.current_marketplace_customers?.aggregate
              ?.count ?? 0
          }
        />
      </div>
    </WizardFullPage>
  );
};
