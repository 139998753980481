import { DraftPlan } from "lib/plans/types";
import { billingPeriodIndexForDate } from "lib/plans/planPeriods";

import { dayjs } from "lib/dayjs";

// Returns a string representing what ramp a customer would be in
// now if they started `plan` on the given `planStart` date.
export const currentRampForPlan = (
  plan: Pick<DraftPlan, "billingFrequency" | "billingDayOfPeriod" | "ramps">,
  planStart: Date,
  now: Date,
) => {
  if (!plan.billingFrequency || !plan.billingDayOfPeriod) {
    return "--";
  }
  let billingPeriodIndex = billingPeriodIndexForDate(
    plan.billingFrequency,
    plan.billingDayOfPeriod,
    dayjs.utc(planStart),
    dayjs.utc(now),
  );
  if (billingPeriodIndex === undefined) {
    return "--";
  }
  const ramps = plan.ramps || [];
  for (let i = 0; i < ramps.length; i++) {
    const rampDuration = ramps[i].durationInPeriods;
    if (!rampDuration) {
      return "--";
    }
    if (billingPeriodIndex < rampDuration) {
      // We 1-index ramps.
      return `Ramp ${i + 1}`;
    }
    billingPeriodIndex -= rampDuration;
  }
  return "Rest of plan";
};
