import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "../IconButton";
import { Icon, IconName } from "../Icon";
import { twMerge } from "tenaissance/twMerge";
import { EnvironmentSwitcher } from "../EnvironmentSwitcher";
import { MetronomeLogo } from "../MetronomeLogo";
import { Dropdown, DropdownHR, DropdownItem } from "../Dropdown";
import { useRoutePathMatches } from "lib/routes/useRoutePathMatches";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useDocsLink } from "lib/docs-link";
import { useApolloNetworkStatus as useApolloNetworkStatus } from "lib/apollo";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAuthCheck } from "lib/useAuthCheck";
import { Launch_Sox_Report_GeneratorDocument } from "lib/reports/reportTypes/soxReports/queries.graphql";
import { REPORTS } from "lib/reports";
import { InternalLink } from "components/Typography";
import { useUIMode } from "lib/useUIMode";

const SidebarLink: React.FC<{
  /** The route to navigate to */
  to: string;
  /** The text to display in the Sidebar */
  name: string;
  /** The icon to display in the Sidebar */
  icon: IconName;
  /** Set if the link should strictly match **/
  strict?: boolean;
  /** Set if the `to` link is different from the selection prefix link **/
  matchingPrefix?: string;
}> = ({ to, name, icon, strict = false, matchingPrefix }) => {
  const { prefixUrl, environments } = useEnvironment();
  let matchPatterns = [matchingPrefix ? matchingPrefix : to];
  if (to === "/settings" || matchingPrefix === "/settings") {
    matchPatterns = matchPatterns.concat(
      environments.map((e) => `${e.urlSlug}/settings/team`),
    );
  }
  const match = useRoutePathMatches(matchPatterns, strict);
  return (
    <Link
      to={prefixUrl(to)}
      className={twMerge(
        "flex rounded-sm p-md text-md font-semibold text-gray-500",
        match
          ? "bg-core-slate text-white"
          : "hover:bg-gray-50 hover:text-gray-800",
      )}
    >
      <>
        <Icon icon={icon} className={match ? "text-white" : "text-black"} />
        <div className="ml-lg">{name}</div>
      </>
    </Link>
  );
};

/**
 * The Sidebar is the primary left navigation of the website.
 * It contains links to the top-level pages of the application, the environment selector, and the user's account page
 */
export const Sidebar: React.FC = () => {
  const apolloNetworkStatus = useApolloNetworkStatus();
  const ldClient = useLDClient();
  const canGenerateSOXReport = !!useAuthCheck(
    Launch_Sox_Report_GeneratorDocument,
  ).allowed;

  const availableReports = REPORTS.filter(
    (report) =>
      report.isAllowedForUser(ldClient) &&
      (report.type !== "email" || canGenerateSOXReport),
  );
  const { mode } = useUIMode();

  return (
    <div className="flex h-full w-[240px] flex-col border-r border-r-gray-200 p-xl">
      <InternalLink
        routePath="/"
        className="h-[48px] border-b border-b-gray-200"
      >
        <MetronomeLogo
          theme="neon"
          className="h-4xl"
          animated={
            apolloNetworkStatus.numPendingMutations > 0 ||
            apolloNetworkStatus.numPendingQueries > 0
          }
        />
      </InternalLink>
      <div className="my-lg flex grow flex-col gap-y-sm">
        <SidebarLink to="/" name="Overview" icon="grid01" strict />
        <SidebarLink to="/customers" name="Customers" icon="users01" />
        {mode === "plans-only" ? (
          <SidebarLink
            to="/offering/plans"
            name="Offering"
            icon="package"
            matchingPrefix="/offering"
          />
        ) : (
          <SidebarLink
            to="/offering/rate-cards"
            name="Offering"
            icon="package"
            matchingPrefix="/offering"
          />
        )}
        {availableReports.length > 0 && (
          <SidebarLink
            to="/reporting"
            name="Reporting"
            icon="barChartSquare03"
          />
        )}
        <SidebarLink
          to="/connections/events"
          name="Connections"
          icon="dataflow03"
          matchingPrefix="/connections"
        />
      </div>
      <SidebarLink
        to="/settings/team"
        name="Settings"
        icon="settings01"
        matchingPrefix="/settings"
      />
      <div className="mt-lg flex gap-4 border-t border-t-gray-200 pt-xl">
        <EnvironmentSwitcher />
        <Dropdown icon="userCircle" buttonTheme="secondary" buttonSize="sm">
          <DropdownItem
            icon="user01"
            label="Account settings"
            linkTo="/account"
            value="account"
          />
          <DropdownHR />
          <DropdownItem
            icon="logOut01"
            label="Logout"
            linkTo="/logout"
            value="logout"
          />
        </Dropdown>
        <IconButton
          linkTo={useDocsLink()}
          isExternalLink
          icon="bookOpen01"
          theme="secondary"
          size="sm"
        />
      </div>
    </div>
  );
};
