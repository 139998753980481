import React from "react";
import {
  BaseIntegrationCard,
  IntegrationCardProps,
} from "./BaseIntegrationCard";
import { ReactComponent as AWSLogo } from "tenaissance/icons/aws.svg";

type AWSIntegrationCardProps = Omit<
  IntegrationCardProps,
  "title" | "subheader" | "description" | "logo"
>;

export const AwsIntegrationCard = (propOverrides: AWSIntegrationCardProps) => {
  return (
    <BaseIntegrationCard
      title="AWS Marketplace"
      subheader="Marketplace billing provider"
      description="The AWS marketplace enables qualified partners to market and sell their software to AWS customers."
      {...propOverrides}
      logo={<AWSLogo />}
    />
  );
};
