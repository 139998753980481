import { Checkbox, DateInput } from "design-system";
import { Button } from "tenaissance/components/Button";
import Papa from "papaparse";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { Popup } from "components/Popup";
import React, { useState } from "react";
import {
  GetAllRateOnEffectiveDateQuery,
  useGetAllRateOnEffectiveDateLazyQuery,
} from "./data.graphql";
import { reportToSentry } from "lib/errors/sentry";
import {
  formatRate,
  getRateCreditTypeId,
  stringToCsvDownload,
} from "pages/Contracts/lib/ContractPricing/RatesDownload";
import { Dayjs, dayjs } from "lib/dayjs";
import { RateScheduleEntitledFilter } from "types/generated-graphql/__types__";
import { useFeatureFlag } from "lib/launchdarkly";

function asCsv(
  dataPages: GetAllRateOnEffectiveDateQuery[],
  at: Dayjs,
  allowCommitRate?: boolean,
) {
  const productsById = new Map(
    dataPages
      .map(
        (data) =>
          data.contract_pricing.rate_card.rate_schedule.products_on_segments,
      )
      .flat()
      .map((product) => [product.id, product]),
  );

  const getProduct = (id: string) => {
    const product = productsById.get(id);
    if (!product) {
      throw new Error(`Product with id ${id} not found`);
    }
    return product;
  };

  const rows = dataPages
    .map(
      (data) => data.contract_pricing.rate_card.rate_schedule.scalar_segments,
    )
    .flat()
    .map(
      ({
        starting_at,
        ending_before,
        entitled,
        pricing_groups,
        product_id,
        rate,
        commit_rate,
      }) => {
        const product = getProduct(product_id);
        const product_name = ProductListItem.getName(product, at);
        const product_tags = ProductListItem.getTags(product, at);
        const formattedRate = formatRate(rate);
        const formattedCommitRate = commit_rate
          ? formatRate(commit_rate)
          : null;
        const formattedManagedFields = product.managed_fields.length
          ? Object.fromEntries(
              product.managed_fields.map(({ ManagedFieldKey, value }) => [
                ManagedFieldKey.key,
                value,
              ]),
            )
          : {};
        const row = {
          starting_at,
          ending_before,
          entitled,
          pricing_group_values: JSON.stringify(pricing_groups) ?? "{}",
          product_id: product.id,
          product_name_current: product_name,
          product_tags_current: JSON.stringify(product_tags),
          custom_fields_current: JSON.stringify(formattedManagedFields),
          credit_type_id: getRateCreditTypeId(rate),
          rate: JSON.stringify(formattedRate),
        };
        return allowCommitRate
          ? { ...row, commit_rate: JSON.stringify(formattedCommitRate) }
          : row;
      },
    );
  const csvContent = Papa.unparse(rows);
  return csvContent;
}

interface RateCardDownloadModalProps {
  rateCardId: string;
  at: Dayjs;
  onStartDownload: () => void;
  onComplete: () => void;
  onError?: () => void;
  onCancel: () => void;
}

export const RateCardDownloadModal: React.FC<RateCardDownloadModalProps> = (
  props,
) => {
  const allowCommitRate = useFeatureFlag("commit-rates", false);
  const [ratesRequest, ratesResult] = useGetAllRateOnEffectiveDateLazyQuery();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    dayjs.utc(props.at).startOf("day"),
  );
  const [filterForEntitled, setFilterForEntitled] = useState<boolean>(true);

  const loading = ratesResult.loading;
  async function onSubmit() {
    props.onStartDownload();
    let next_page: string | null = null;
    const dataPages = [];
    const at = selectedDate.toISOString();
    do {
      const result = await ratesRequest({
        variables: {
          rateCardId: props.rateCardId,
          limit: "10000",
          cursor: next_page,
          at,
          entitled_filter: filterForEntitled
            ? RateScheduleEntitledFilter.Entitled
            : RateScheduleEntitledFilter.All,
        },
      });
      if (result.error) {
        reportToSentry(ratesResult.error);
        if (props.onError) {
          props.onError();
        }
      }
      if (!result.data) {
        return;
      }
      dataPages.push(result.data);
      next_page =
        result.data.contract_pricing.rate_card.rate_schedule.next_page;
    } while (next_page);
    const csvContent = asCsv(dataPages, props.at, allowCommitRate);
    stringToCsvDownload(
      csvContent,
      `rates-for-card-${props.rateCardId}-on-${at}.csv`,
    );
    props.onComplete();
  }

  return (
    <Popup
      isOpen
      onRequestClose={props.onCancel}
      title="Download Rates CSV"
      actions={
        <>
          <Button
            loading={false}
            onClick={props.onCancel}
            text="Cancel"
            theme="linkGray"
          />
          <Button
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
            text="Download"
            theme="primary"
            type="submit"
          />
        </>
      }
    >
      <p className="my-8">
        Download rates at UTC midnight on the selected date.
      </p>
      <DateInput
        className="my-8"
        name="Effective Date"
        onChange={(v) => {
          setSelectedDate(dayjs.utc(v).startOf("day"));
        }}
        value={selectedDate.toDate()}
        isUTC={true}
      />
      <Checkbox
        checked={filterForEntitled}
        onChange={(v) => setFilterForEntitled(v)}
        label="Only include entitled rates"
      />
    </Popup>
  );
};
