export type FilterState = {
  duplicates?: boolean;
  eventID?: string;
  transactionIDs?: string[];
  customerID?: string;
  ingestAliases?: string[];
  startingOn?: Date;
  endingBefore?: Date;
  billableMetrics?: string[];
};

function maybeGetDate(date: string | null) {
  if (!date) {
    return undefined;
  }
  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) {
      return undefined;
    }
    return d;
  } catch (e) {
    return undefined;
  }
}

export function queryStringToFilter(queryString: string): FilterState {
  const params = new URLSearchParams(queryString);
  const filter: FilterState = {};
  if (params.get("duplicates")) {
    filter.duplicates = params.get("duplicates") === "true";
  }
  if (params.get("eventID")) {
    filter.eventID = params.get("eventID") ?? undefined;
  }
  if (params.get("transactionID")) {
    filter.transactionIDs = params.getAll("transactionID");
  }
  if (params.get("billableMetricID")) {
    filter.billableMetrics = params.getAll("billableMetricID");
  }
  if (params.get("customerID")) {
    filter.customerID = params.get("customerID") ?? undefined;
  }
  if (params.get("ingestAlias")) {
    filter.ingestAliases = params.getAll("ingestAlias");
  }
  if (params.get("startingOn")) {
    filter.startingOn = maybeGetDate(params.get("startingOn"));
  }
  if (params.get("endingBefore")) {
    filter.endingBefore = maybeGetDate(params.get("endingBefore"));
  }
  return filter;
}

export function filterToQueryString(filter: FilterState): string {
  const params = new URLSearchParams();
  if (typeof filter.duplicates != "undefined") {
    params.set("duplicates", filter.duplicates.toString());
  }
  if (filter.eventID) {
    params.set("eventID", filter.eventID);
  }
  if (filter.transactionIDs) {
    filter.transactionIDs.forEach((id) => params.append("transactionID", id));
  }
  if (filter.billableMetrics) {
    filter.billableMetrics.forEach((id) =>
      params.append("billableMetricID", id),
    );
  }
  if (filter.customerID) {
    params.set("customerID", filter.customerID);
  }
  if (filter.ingestAliases) {
    filter.ingestAliases.forEach((alias) =>
      params.append("ingestAlias", alias),
    );
  }
  if (filter.startingOn) {
    params.set("startingOn", filter.startingOn.toISOString());
  }
  if (filter.endingBefore) {
    params.set("endingBefore", filter.endingBefore.toISOString());
  }
  return params.toString();
}
