import React from "react";
import { Schema } from "../../../../../pages/Contracts/Pricing/Schema";
import { CreditType } from "types/credit-types";
import { TextInput } from "tenaissance/components/Input";
import { Button } from "tenaissance/components/Button";
import { CurrencyInput } from "./CurrencyInput";
import { USD_CREDIT_ID, USD_CREDIT_TYPE } from "lib/credits";
import { IconName } from "tenaissance/components/Icon";

interface Props {
  disabled?: boolean;
  rate: Schema.Types.Rate;
  fiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  creditTypeConversions: Schema.Types.CreditTypeConversion[];
  onChange: (rate: Schema.Types.Rate) => void;
  onConversionRateChange: (
    conversion: Schema.Types.CreditTypeConversion,
  ) => void;
  allowEditCreditConversions: boolean;
  disableChangingCreditTypes: boolean;
}

export const RateTiered: React.FC<Props> = ({
  rate,
  onChange,
  creditTypeConversions,
  fiatCreditType,
  onConversionRateChange,
  customCreditTypes,
  disabled,
  allowEditCreditConversions,
  disableChangingCreditTypes,
}) => {
  if (rate.price.type !== "tiered") {
    throw new Error("RateTiered component can only be used with tiered rates");
  }

  const leadingIcon: IconName | undefined =
    rate.creditType?.id === USD_CREDIT_ID ? "currencyDollar" : undefined;
  const tiers = rate.price.tiers;
  return (
    <div className="flex flex-col gap-8">
      {tiers.map((tier, index) => (
        <div
          className="flex w-full flex-row gap-4"
          key={`${tier.lastUnit}_${index}`}
        >
          <TextInput
            value={index === 0 ? "0" : tiers[index - 1].lastUnit?.toString()}
            className="w-[80px]"
            disabled={true}
            fullWidth={false}
            disableClear
            onChange={({ value }) => {
              onChange({
                ...rate,
                price: {
                  type: "tiered",
                  tiers: [
                    ...tiers.slice(0, index - 1),
                    {
                      ...tiers[index - 1],
                      lastUnit: Number(value),
                    },
                    ...tiers.slice(index),
                  ],
                },
              });
            }}
          />
          <TextInput
            value={tier.lastUnit?.toString() ?? "∞"}
            className="w-[80px]"
            disabled={!tier.lastUnit || disabled}
            fullWidth={false}
            disableClear
            onChange={({ value }) => {
              onChange({
                ...rate,
                price: {
                  type: "tiered",
                  tiers: [
                    ...tiers.slice(0, index),
                    {
                      ...tiers[index],
                      lastUnit: Number(value),
                    },
                    ...tiers.slice(index + 1),
                  ],
                },
              });
            }}
          />
          {index === 0 ? (
            <CurrencyInput
              disabled={disabled}
              creditTypeConversions={creditTypeConversions}
              customCreditTypes={customCreditTypes}
              price={tier.unitPrice}
              rateCreditType={rate.creditType ?? USD_CREDIT_TYPE}
              rateCardFiatCreditType={fiatCreditType}
              onCreditTypeChange={(creditType, conversion) => {
                onChange({
                  ...rate,
                  creditType,
                });

                if (conversion) {
                  onConversionRateChange(conversion);
                }
              }}
              onPriceChange={(price) => {
                onChange({
                  ...rate,
                  price: {
                    type: "tiered",
                    tiers: [
                      {
                        ...tiers[0],
                        unitPrice: price,
                      },
                      ...tiers.slice(1),
                    ],
                  },
                });
              }}
              allowEditCreditConversions={allowEditCreditConversions}
              disableChangingCreditTypes={disableChangingCreditTypes}
            />
          ) : (
            <TextInput
              disabled={disabled}
              value={
                tier.unitPrice !== undefined
                  ? tier.unitPrice.toString()
                  : undefined
              }
              fullWidth
              disableClear
              variantOptions={{
                variant: "iconButton",
                leadingIcon,
                button: {
                  icon: "trash01",
                  theme: "secondary",
                  onClick: () => {
                    if (rate.price.type !== "tiered") {
                      return;
                    }

                    if (rate.price.tiers.length === 2) {
                      // remove tiers
                      onChange({
                        ...rate,
                        price: {
                          type: "flat",
                          price: rate.price.tiers[0].unitPrice,
                        },
                      });
                    } else {
                      const newRate: Schema.Types.Rate = {
                        ...rate,
                        price: {
                          type: "tiered",
                          tiers: [
                            ...tiers.slice(0, index),
                            ...tiers.slice(index + 1),
                          ],
                        },
                      };

                      // the last tier should not have a lastUnit, have to
                      // do this check because of typescript
                      if (newRate.price.type === "tiered") {
                        newRate.price.tiers[newRate.price.tiers.length - 1] = {
                          unitPrice: undefined,
                        };
                      }
                      onChange(newRate);
                    }
                  },
                  disabled: tiers.length === 2,
                },
              }}
              onChange={({ value }) => {
                onChange({
                  ...rate,
                  price: {
                    type: "tiered",
                    tiers: [
                      ...tiers.slice(0, index),
                      {
                        ...tiers[index],
                        unitPrice: value ? Number(value) : undefined,
                      },
                      ...tiers.slice(index + 1),
                    ],
                  },
                });
              }}
            />
          )}
        </div>
      ))}
      <div className="flex justify-end">
        <Button
          disabled={disabled}
          text="Add tier"
          leadingIcon="plus"
          theme="linkGray"
          onClick={() => {
            onChange({
              ...rate,
              price: {
                type: "tiered",
                tiers: [
                  ...tiers.slice(0, tiers.length - 1),
                  {
                    ...tiers[tiers.length - 1],
                    lastUnit: (tiers[tiers.length - 2].lastUnit ?? 0) + 1,
                  },
                  {
                    unitPrice: undefined,
                  },
                ],
              },
            });
          }}
        />
      </div>
    </div>
  );
};
