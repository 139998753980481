import React from "react";

import { Breadcrumbs } from "lib/breadcrumbs";

import { useCustomerFromRoute } from "../../lib/Customer";
import { CustomerLayout } from "../CustomerLayout";
import { CreditsTabContent } from "pages/Customer/tabs/Credits";

export const CustomerCredits: React.FC = () => {
  const req = useCustomerFromRoute();

  return (
    <CustomerLayout
      rootReq={req}
      breadcrumbs={({ customer }) =>
        Breadcrumbs.from(
          {
            type: "back",
            label: "Back to customer list",
            routePath: "/customers",
          },
          {
            label: customer.name,
            routePath: `/customers/${customer.id}`,
          },
        )
      }
      content={({ customer }) => (
        <div className="flex h-full flex-col pt-12">
          <CreditsTabContent customerId={customer.id} />
        </div>
      )}
    />
  );
};
