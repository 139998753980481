import classNames from "classnames";
import { compareAsc, compareDesc, isEqual } from "date-fns";
import { dayjs } from "lib/dayjs";
import Decimal from "decimal.js";
import React, { useState, useEffect } from "react";

import {
  AvatarWithName,
  Badge,
  Icon,
  Tooltip,
  Body,
  Caption,
  Headline,
  Subtitle,
} from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { MenuItemProps, PopoverMenu } from "components/PopoverMenu";
import { BlockSkeleton } from "components/Skeleton";
import { displayCostBasis, RoundedCurrency } from "lib/credits";
import { renderDate, renderDateRange } from "lib/time";
import { CreditType } from "types/credit-types";
import { IssuedCreditGrant } from "../..";
import CreditLedger from "../CreditLedger";
import EditCreditGrantPane, {
  EditCreditGrantInput,
} from "../EditCreditGrantPane";
import VoidCreditGrantModal from "../VoidCreditGrantModal";
import { useGetCreditGrantDeductionsQuery } from "./queries.graphql";
import { useNavigate } from "lib/useNavigate";
import { gatedAction, useAuthCheck } from "lib/useAuthCheck";
import { UpdateCreditGrantDocument } from "../EditCreditGrantPane/queries.graphql";
import { VoidCreditGrantDocument } from "../VoidCreditGrantModal/queries.graphql";
import { useFeatureFlag } from "lib/launchdarkly";
import { giveUserFacingErrorMessage } from "lib/errors/errorHandling";
import { CopyableID } from "components/CopyableID";
import { formatBillingProvider } from "../../../../../../lib/billingProvider/formatBillingProvider";
import { rolloverSettingsInputFromGqlType } from "lib/rolloverSettings";
import { useUIMode } from "lib/useUIMode";

const grantHasExpired = (
  creditGrant: Pick<IssuedCreditGrant, "expiresBefore">,
) => {
  return creditGrant.expiresBefore < new Date();
};

const grantIsPending = (
  creditGrant: Pick<IssuedCreditGrant, "effectiveAt">,
) => {
  return creditGrant.effectiveAt > new Date();
};
const grantIsVoid = (creditGrant: Pick<IssuedCreditGrant, "voidedAt">) => {
  return creditGrant.voidedAt !== null;
};

type CreditGrantToSort = Pick<
  IssuedCreditGrant,
  | "expiresBefore"
  | "effectiveAt"
  | "createdAt"
  | "priority"
  | "billingProvider"
  | "voidedAt"
  | "products"
>;
export const sortCreditGrants = (
  a: CreditGrantToSort,
  b: CreditGrantToSort,
) => {
  if (grantIsVoid(a) && !grantIsVoid(b)) {
    return 1;
  } else if (!grantIsVoid(a) && grantIsVoid(b)) {
    return -1;
  } else {
    if (grantHasExpired(a) === grantHasExpired(b)) {
      if (a.priority === b.priority) {
        if (isEqual(a.expiresBefore, b.expiresBefore)) {
          if (!!a.products === !!b.products) {
            if (isEqual(a.effectiveAt, b.effectiveAt)) {
              return compareAsc(a.createdAt, b.createdAt);
            } else {
              return compareAsc(a.effectiveAt, b.effectiveAt);
            }
          } else {
            return a.products ? -1 : 1;
          }
        } else {
          return compareDesc(a.expiresBefore, b.expiresBefore);
        }
      } else {
        return new Decimal(a.priority || 0).comparedTo(b.priority || 0);
      }
    }
    return compareDesc(a.expiresBefore, b.expiresBefore);
  }
};

type CreditGrantBalanceProps = {
  creditGrant: IssuedCreditGrant;
};

export const CreditGrantBalance: React.FC<CreditGrantBalanceProps> = ({
  creditGrant,
}) => {
  if (grantIsVoid(creditGrant)) {
    return (
      <div className="flex">
        <Caption level={2} className="mr-4 uppercase text-gray-600">
          Total Voided:
        </Caption>
        <Subtitle level={4} className="text-warning-600">
          <RoundedCurrency
            amount={new Decimal(creditGrant.amountGranted)}
            creditType={creditGrant.amountGrantedCreditType}
          />
        </Subtitle>
      </div>
    );
  }
  if (grantHasExpired(creditGrant)) {
    return (
      <div className="flex">
        <Caption level={2} className="mr-4 uppercase text-gray-600">
          Total Expired:
        </Caption>

        <Subtitle level={4} className="text-error-600">
          <RoundedCurrency
            amount={new Decimal(creditGrant.ledger.expired)}
            creditType={creditGrant.amountGrantedCreditType}
          />
        </Subtitle>
      </div>
    );
  }
  if (grantIsPending(creditGrant)) {
    return (
      <div className="flex">
        <Caption level={2} className="mr-4 uppercase text-gray-600">
          Pending Balance:
        </Caption>
        <Subtitle level={4} className="text-gray-600">
          <RoundedCurrency
            amount={new Decimal(creditGrant.amountGranted)}
            creditType={creditGrant.amountGrantedCreditType}
          />
        </Subtitle>
      </div>
    );
  }
  return (
    <div className="flex">
      <Caption level={2} className="mr-4 uppercase text-gray-600">
        Available Balance:
      </Caption>
      <Subtitle level={4} className="text-success-700">
        <RoundedCurrency
          amount={new Decimal(creditGrant.ledger.availableBalance)}
          creditType={creditGrant.amountGrantedCreditType}
        />
      </Subtitle>
    </div>
  );
};

type CreditGrantBadgeProps = {
  creditGrant: IssuedCreditGrant;
};
const CreditGrantBadge: React.FC<CreditGrantBadgeProps> = ({ creditGrant }) => {
  if (grantIsVoid(creditGrant)) {
    return (
      <Badge
        theme="warning"
        type="light"
        className="h-[20px] uppercase text-warning-600"
      >
        Voided
      </Badge>
    );
  }
  if (grantHasExpired(creditGrant)) {
    return (
      <Badge
        theme="error"
        type="light"
        className="h-[20px] uppercase text-error-600"
      >
        Expired
      </Badge>
    );
  }
  if (grantIsPending(creditGrant)) {
    return (
      <Badge
        theme="grey"
        type="light"
        className="h-[20px] uppercase text-gray-600"
      >
        Pending
      </Badge>
    );
  }
  return null;
};

type CreditGrantActionsProps = {
  creditGrant: IssuedCreditGrant;
  menuOptions: MenuItemProps[];
};

// Action menu in the upper right of the credit grant card
const CreditGrantActions: React.FC<CreditGrantActionsProps> = ({
  creditGrant,
  menuOptions,
}) => {
  return grantIsVoid(creditGrant) ? null : (
    <PopoverMenu positions={["bottom"]} align="end" options={menuOptions}>
      {(onClick) => (
        <IconButton
          className="ml-8"
          onClick={onClick}
          theme="secondary"
          icon="dotsVertical"
        />
      )}
    </PopoverMenu>
  );
};

type CreditGrantProps = {
  creditGrant: IssuedCreditGrant;
  customerId: string;
};

const CreditGrant: React.FC<CreditGrantProps> = ({
  creditGrant,
  customerId,
}) => {
  const navigate = useNavigate();
  const { newUIEnabled } = useUIMode();
  const [creditLedgerOpen, setCreditLedgerOpen] = useState<boolean>(false);
  const [grantToBeEdited, setGrantToBeEdited] =
    useState<EditCreditGrantInput | null>(null);
  const [voidModalIsOpen, setVoidModalIsOpen] = useState<boolean>(false);
  const [grantToBeVoided, setGrantToBeVoided] =
    useState<IssuedCreditGrant | null>(null);

  useEffect(() => {
    if (grantToBeVoided) {
      setVoidModalIsOpen(true);
    }
    return () => {
      setVoidModalIsOpen(false);
    };
  }, [grantToBeVoided]);

  const { data: data } = useGetCreditGrantDeductionsQuery({
    variables: { credit_grant_id: creditGrant.id },
  });
  const hasDeductions = !!data?.CreditGrantDeduction?.length;
  const alreadyInvoiced = !!(
    creditGrant.invoice &&
    dayjs(creditGrant.invoice.issued_at).isBefore(dayjs.utc().startOf("day"))
  );

  const canSetBillingProvider = useFeatureFlag(
    "set-billing-provider-on-credit-grant",
    false,
  );

  const canEditGrant = !!useAuthCheck(UpdateCreditGrantDocument, true).allowed;
  const canVoidGrant = !!useAuthCheck(VoidCreditGrantDocument, true).allowed;

  const editCreditGrantInput: EditCreditGrantInput = {
    id: creditGrant.id,
    customerId: customerId,
    name: creditGrant.name,
    reason: creditGrant.reason ?? "",
    expiresBefore: creditGrant.expiresBefore,
    billingProvider: creditGrant.billingProvider ?? null,
    effectiveAt: creditGrant.effectiveAt,
    voidedAt: creditGrant.voidedAt,
    creditGrantType: creditGrant.creditGrantType ?? "",
    rolloverSettingsInput: creditGrant.rolloverSettings
      ? rolloverSettingsInputFromGqlType(creditGrant.rolloverSettings)
      : undefined,
    amountGrantedCreditType: creditGrant.amountGrantedCreditType,
    priority: creditGrant.priority,
  };
  const editOption: MenuItemProps = gatedAction(canEditGrant, {
    onClick: () => {
      setGrantToBeEdited(editCreditGrantInput);
    },
    content: (
      <Tooltip
        content={
          creditGrant.voidedAt
            ? "Voided grants cannot be edited."
            : creditGrant.rolledOverFromId
              ? "This grant is a rollover grant, so it cannot be edited."
              : undefined
        }
      >
        Edit grant...
      </Tooltip>
    ),
    disabled: !!creditGrant.voidedAt || creditGrant.rolledOverFromId,
  });

  const voidOption: MenuItemProps = gatedAction(canVoidGrant, {
    onClick: () => {
      setGrantToBeVoided(creditGrant);
    },
    content: (
      <Tooltip
        content={
          hasDeductions
            ? "This grant has already been applied to a finalized invoice, so it cannot be voided."
            : alreadyInvoiced
              ? "The customer has already been invoiced for this grant, so it cannot be voided."
              : creditGrant.rolledOverFromId
                ? "This grant is a rollover grant, so it cannot be voided."
                : undefined
        }
      >
        Void grant...
      </Tooltip>
    ),
    disabled: hasDeductions || alreadyInvoiced || creditGrant.rolledOverFromId,
  });

  const customFieldsOption: MenuItemProps = {
    content: "Manage custom fields...",
    onClick: () =>
      navigate(
        `${newUIEnabled ? "/connections" : ""}/custom-fields/credit-grant/${creditGrant.id}`,
      ),
  };

  if (!canSetBillingProvider && creditGrant.billingProvider) {
    const error = new Error();
    giveUserFacingErrorMessage(
      error,
      "Client is not enabled to have billing provider on credit grant",
    );
    throw error;
  }

  const creditGrantTypeEnabled = useFeatureFlag<boolean>(
    "credit-grant-type-ui",
    false,
  );

  return (
    <>
      <div className="rounded-md mb-12 mt-12 box-border border border-gray-100 [&:last-child]:mb-12">
        <div className="p-8">
          <div className="flex justify-between">
            <Headline level={6} className="mb-4">
              {creditGrant.name}
            </Headline>
            <div className="flex items-center">
              <CreditGrantBadge creditGrant={creditGrant} />
              <CreditGrantActions
                creditGrant={creditGrant}
                menuOptions={[customFieldsOption, editOption, voidOption]}
              />
            </div>
          </div>
          <Body level={2} className="mb-8 max-w-[600px] text-gray-800">
            {creditGrant.reason}
          </Body>
          <div className="mb-8 flex items-center">
            <AvatarWithName
              name={creditGrant.creatorName || "Metronome team"}
              id={creditGrant.creatorId || "metronome-team"}
              deprecated_at={null}
            />
            <Body level={2} className="mb-0 ml-8 text-gray-700">
              {`(created ${renderDate(creditGrant.createdAt, {
                isUtc: false,
              })})`}
            </Body>
            <div className="ml-12">
              <CopyableID id={creditGrant.id} label="credit grant ID" />
            </div>
          </div>
          <div className="flex">
            <div className="mr-32">
              <Caption level={1} className="uppercase">
                Total Grant
                {creditGrant.invoice ? (
                  <>
                    {" - "}
                    <span className="text-primary-600">Customer Invoiced</span>
                  </>
                ) : null}
              </Caption>
              <Subtitle level={4}>
                <RoundedCurrency
                  amount={new Decimal(creditGrant.amountGranted)}
                  creditType={creditGrant.amountGrantedCreditType}
                />
                {` / `}
                <RoundedCurrency
                  amount={new Decimal(creditGrant.amountPaid)}
                  creditType={creditGrant.amountPaidCreditType}
                />
              </Subtitle>
            </div>
            <div className="mr-32">
              <Caption level={1} className="uppercase">
                Cost Basis
              </Caption>
              <Subtitle level={4}>
                {displayCostBasis(
                  new Decimal(creditGrant.costBasis),
                  creditGrant.amountGrantedCreditType,
                  creditGrant.amountPaidCreditType,
                )}
              </Subtitle>
            </div>
            <div className="mr-32">
              <Caption level={1} className="uppercase">
                Effective Date
              </Caption>
              <Subtitle level={4}>
                {renderDateRange(
                  creditGrant.effectiveAt,
                  creditGrant.expiresBefore,
                  {
                    isUtc: true,
                    excludeUtcLabel: true,
                  },
                  false,
                )}
              </Subtitle>
            </div>
            <div className="mr-32">
              <Caption level={1} className="uppercase">
                Priority
              </Caption>
              <Subtitle level={4}>{creditGrant.priority}</Subtitle>
            </div>
            {creditGrant.products ? (
              <div className="mr-32">
                <Caption level={1} className="uppercase">
                  Credits apply to
                </Caption>
                <Subtitle level={4}>
                  {creditGrant.products.map((p) => p.name).join(", ")}
                </Subtitle>
              </div>
            ) : null}
            {canSetBillingProvider && creditGrant.billingProvider && (
              <div className="mr-32">
                <Caption level={1} className="uppercase">
                  Billing Provider
                </Caption>
                <Subtitle level={4}>
                  {formatBillingProvider(creditGrant.billingProvider)}
                </Subtitle>
              </div>
            )}
            {creditGrantTypeEnabled && creditGrant.creditGrantType && (
              <div className="mr-32">
                <Caption level={1} className="uppercase">
                  Credit Grant Type
                </Caption>
                <Subtitle level={4}>{creditGrant.creditGrantType}</Subtitle>
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(
            "rounded-b-lg rounded-t-lg flex w-full cursor-pointer items-center justify-between border-t border-gray-100 bg-gray-50 p-12 [&:hover]:bg-primary-50",
            creditLedgerOpen && "rounded-b-none",
          )}
          onClick={() => setCreditLedgerOpen(!creditLedgerOpen)}
        >
          <div className="flex items-center">
            {creditLedgerOpen ? (
              <Icon icon="chevronDown" className="mr-4 text-gray-600" />
            ) : (
              <Icon icon="chevronForward" className="mr-4 text-gray-600" />
            )}
            <Caption level={2} className="text-gray-600">
              Credit ledger
            </Caption>
          </div>
          <CreditGrantBalance creditGrant={creditGrant} />
        </div>

        {creditLedgerOpen && (
          <div className="m-0 mx-12 -mb-12">
            <CreditLedger
              loading={false}
              ledger={creditGrant.ledger}
              columnsToHide={new Set(["cost_basis"])}
              noBottomBorder={true}
            />
          </div>
        )}
      </div>
      {grantToBeEdited && (
        <EditCreditGrantPane
          creditGrant={editCreditGrantInput}
          onClose={() => {
            setGrantToBeEdited(null);
          }}
        />
      )}
      {voidModalIsOpen && (
        <VoidCreditGrantModal
          onClose={() => {
            setVoidModalIsOpen(false);
          }}
          creditGrantId={creditGrant.id}
          creditGrantName={creditGrant.name}
          customerId={customerId}
        />
      )}
    </>
  );
};
export const CreditGrantListSkeleton: React.FC = () => {
  return (
    <div>
      <div className="mt-12 h-[200px]">
        <BlockSkeleton />
      </div>
      <div className="mt-12 h-[200px]">
        <BlockSkeleton />
      </div>
    </div>
  );
};

type CreditGrantListProps = {
  issuedCreditGrants: IssuedCreditGrant[];
  selectedCreditType: CreditType;
  customerId: string;
};
export const CreditGrantList: React.FC<CreditGrantListProps> = ({
  issuedCreditGrants,
  selectedCreditType,
  customerId,
}) => {
  const sortedCreditGrants = issuedCreditGrants
    .filter((cg) => cg.amountGrantedCreditType.id === selectedCreditType.id)
    .sort(sortCreditGrants);

  return (
    <div>
      {Object.values(sortedCreditGrants).map((cg, idx) => (
        <CreditGrant key={idx} creditGrant={cg} customerId={customerId} />
      ))}
    </div>
  );
};
