import React from "react";

import { useDocsLink } from "lib/docs-link";
import { Button } from "tenaissance/components/Button";

export const DocsLink: React.FC<{
  plansPath?: string;
  contractsPath?: string;
}> = (props) => {
  return (
    <Button
      theme="tertiary"
      linkTo={useDocsLink(props)}
      isExternalLink
      trailingIcon="linkExternal01"
      text="Learn more"
    />
  );
};
