import { ApolloLink, Operation, NextLink } from "@apollo/client";
import { GraphQLError } from "graphql";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";

// If you change this here you need to change it in codegen-plugins/environment-type.js
// easier to enforce this with a comment than it is to try to figure out JS/TS cross imports.
const KEY_NAME = "__environment_type";

type UnknownObject = { [key: string]: any };

// This function takes a response (basically what you'd get from fetch().json()) with a query and calls the provided mapper function
// on each "item" in the response.
function mapResponse(
  input: UnknownObject,
  mapper: (object: UnknownObject) => UnknownObject,
): any {
  if (input === null) {
    return input;
  } else if (Array.isArray(input)) {
    return input.map((v) => mapResponse(v, mapper));
  } else if (typeof input === "object") {
    if (input.__typename) {
      // If we've found an object with a __typename then we know it's an actual item
      return mapper(input);
    }
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => [
        key,
        mapResponse(value, mapper),
      ]),
    );
  } else {
    return input;
  }
}

export class EnvironmentTypeLink extends ApolloLink {
  constructor(private environmentType: EnvironmentTypeEnum_Enum) {
    super();
  }

  request(operation: Operation, forward: NextLink) {
    return forward(operation).map((data) => {
      const envTypes = new Set<string>();

      mapResponse(data, (value) => {
        if (value[KEY_NAME]) {
          envTypes.add(value[KEY_NAME]);
        }
        return value;
      });

      // If no envTypes were found then all the data in this query was cross-environment.
      if (envTypes.size === 0) {
        return data;
      }

      // If we found more than 1 envType then there's a bug in permissions/query logic, fail the request.
      if (envTypes.size > 1) {
        return {
          data: null,
          errors: [
            new GraphQLError(
              `Found more than one environment type in the response.`,
            ),
          ],
        };
      } else if (!envTypes.has(this.environmentType.toUpperCase())) {
        return {
          data: null,
          errors: [
            new GraphQLError(
              `Found ${Array.from(envTypes.keys())[0]} data when user was in ${
                this.environmentType
              } mode.`,
            ),
          ],
        };
      }
      return data;
    });
  }
}
