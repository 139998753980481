import React from "react";
import { RateCardQuery } from "./data.graphql";
import { RightPane } from "components/Popup";
import { Caption, Headline, Subtitle } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { TablePanel } from "components/TablePanel";
import { displayCreditsInCurrencyWithoutRounding } from "lib/credits";
import Decimal from "decimal.js";
import { CreditType } from "types/credit-types";

export interface Props {
  fiatCreditType: CreditType;
  creditTypeConversions: NonNullable<
    RateCardQuery["contract_pricing"]["rate_card"]["credit_type_conversions"]
  >;
  onRequestClose: () => void;
}

export const RateCardCPUFlyover: React.FC<Props> = ({
  fiatCreditType,
  creditTypeConversions,
  onRequestClose,
}) => {
  return (
    <RightPane
      size="lg"
      isOpen
      onRequestClose={onRequestClose}
      contentClassName="!p-0"
    >
      <header className="flex items-center bg-gray-50 px-12 py-8">
        {/* left */}
        <div className="grow">
          <Headline level={6} className="grow">
            Custom pricing units
          </Headline>
        </div>
        {/* right */}
        <div className="flex items-center">
          <IconButton
            className="grow-0"
            onClick={onRequestClose}
            theme="secondary"
            icon="xClose"
          />
        </div>
      </header>
      <div className="flex flex-col gap-24 p-12">
        <div>
          <Caption className="mb-8">Currency</Caption>
          <Subtitle level={4}>{fiatCreditType.name}</Subtitle>
        </div>
        <div>
          <TablePanel
            title="Custom pricing units"
            columns={[
              {
                id: "name",
                header: "Name",
                render: (row) => row.custom_credit_type.name,
              },
              {
                id: "conversion",
                header: "Conversion",
                textWrappable: true,
                align: "right",
                render: (row) => (
                  <>
                    {displayCreditsInCurrencyWithoutRounding(
                      new Decimal(row.fiat_per_custom_credit),
                      fiatCreditType,
                    )}
                  </>
                ),
              },
            ]}
            data={creditTypeConversions}
          />
        </div>
      </div>
    </RightPane>
  );
};
