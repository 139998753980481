import React from "react";
import { RecentInvoiceFragment } from "../data.graphql";
import Graph from "components/Graph";
import { dayjs } from "lib/dayjs";
import { Invoice } from "pages/Contracts/lib/Invoice";
import { idToGraphColor } from "lib/idToColor";
import { groupBy, groupByAndMap, mapValues } from "lib/util";
import { InvoiceStatusEnum } from "types/generated-graphql/__types__";
import { displayCreditsInCurrencyWithoutRounding } from "lib/credits";
import { CreditType } from "types/credit-types";
import Decimal from "decimal.js";

type Props = {
  loading: boolean;
  invoices: RecentInvoiceFragment[];
  dateRange: unknown;
  creditType?: CreditType;
};

const dateKey = (invoice: RecentInvoiceFragment) => {
  if ("exclusive_end_date" in invoice) {
    return dayjs
      .utc(invoice.exclusive_end_date)
      .subtract(1, "s")
      .endOf("month")
      .toISOString();
  } else {
    return dayjs.utc(invoice.issued_at).endOf("month").toISOString();
  }
};

export const RecentInvoicesBarChart: React.FC<Props> = ({
  loading,
  invoices,
  creditType,
}) => {
  // filter for non-voided invoices, map all invoice dates to end of month
  const invoiceData = invoices
    .filter((invoice) => invoice.status !== InvoiceStatusEnum.Void)
    .map((invoice) => ({
      value: Number(invoice.total),
      dateKey: dateKey(invoice),
      seriesKey: Invoice.getContractOrPlanName(invoice),
    }));

  // collect/dedup all dates
  const dateKeys = [...new Set(invoiceData.map((invoice) => invoice.dateKey))];
  const datesZeroTotals = Object.fromEntries(
    dateKeys.map((dateKey) => [dateKey, 0]),
  );

  // sum invoice totals by plan & date
  const series = groupByAndMap(
    invoiceData,
    (invoice) => invoice.seriesKey,
    (invoices, seriesKey) => {
      const invoicesByDate = groupBy(invoices, (invoice) => invoice.dateKey);
      const totalsByDate = mapValues(invoicesByDate, (invoices) => {
        return invoices.reduce((acc, invoice) => acc + invoice.value, 0);
      });
      return {
        color: idToGraphColor(seriesKey),
        name: seriesKey,
        data: Object.values(
          mapValues(
            // data for the Graph component assumes all series
            // have exactly the same dates
            Object.assign({}, datesZeroTotals, totalsByDate),
            (value, date) => ({
              value,
              date: new Date(date),
            }),
          ),
        ).sort((a, b) => a.date.getTime() - b.date.getTime()),
      };
    },
  );

  return (
    <div className="h-[300px]">
      <Graph
        loading={loading}
        bars={Object.values(series)}
        stackBars={true}
        showDay={false}
        yValueFormatter={(value) =>
          creditType
            ? displayCreditsInCurrencyWithoutRounding(
                new Decimal(value.toFixed(0)),
                creditType,
                true,
                true,
              )
            : ""
        }
        showLegend="below"
      />
    </div>
  );
};
