import { z } from "zod";
import { Refine } from "../Create/Schema";

export namespace Schema {
  export const AddManualCommitLedgerEntryInput = z.object({
    reason: z.string(),
    segmentId: z.string().uuid(),
    amount: z.number(),
    timestamp: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .optional(),
  });

  export const ChangeEndDateInput = z.object({
    endAccessAt: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .optional(),
    endInvoicesAt: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .optional(),
  });
}
