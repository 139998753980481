import React from "react";
import "style/index.css";
import { twMerge } from "tailwind-merge";
import { Button } from "../Button";

export type ButtonProps = {
  text: string;
  isActive?: boolean;
  onClick: () => void;
};

interface ButtonGroupProps {
  /**
   * Each button should have text and an onClick property to help support switching between them.
   * State should be managed by the caller.
   * */
  buttons: ButtonProps[];
  /** Customize the component with additional Tailwind classes */
  className?: string;
}

/**
 * !! PARTIAL IMPLEMENTATION !!
 * Button groups combine sets of buttons into toolbars or split buttons for more complex components.
 * Button groups are also useful for acting as mini “tabs” in UI, for example, switching between date ranges.
 */
export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttons,
  className,
}) => {
  const classnames = twMerge("inline-flex items-center", className);
  return (
    <div className={classnames}>
      {buttons.map((b, i) => (
        <Button
          className={twMerge(
            "rounded-none border-r-0 first:rounded-l-md last:rounded-r-md last:border-r",
            b.isActive && "bg-gray-200",
          )}
          key={i}
          text={b.text}
          theme="secondary"
          onClick={b.onClick}
        />
      ))}
    </div>
  );
};
