import React from "react";
import { WizardSection } from "components/Wizard";
import { CreatePlanDataQuery } from "../../data/queries.graphql";
import { DraftPlan } from "lib/plans/types";
import {
  AddCreditGrantPage,
  addRecurringCreditGrantIsDone,
} from "./components/AddCreditGrantPage";

export const addRecurringCreditGrantSection: (
  draftPlan: DraftPlan,
  data: CreatePlanDataQuery,
) => WizardSection = (draftPlan, data) => ({
  title: "Set credit grant",
  isDone: addRecurringCreditGrantIsDone(draftPlan),
  icon: "cash",
  subStepGroups: [
    {
      isDone: addRecurringCreditGrantIsDone(draftPlan),
      subSteps: [
        {
          header: "Add credit grant",
          title: "Credit grants",
          component: <AddCreditGrantPage data={data} />,
          isDone: addRecurringCreditGrantIsDone(draftPlan),
        },
      ],
    },
  ],
});
