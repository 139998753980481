import React from "react";
import styles from "./index.module.less";
import { DateInput } from "design-system";
import { validatePlanDates } from "./validatePlanDates";
import { Checkbox } from "design-system";
import {
  planEndDateWarning,
  planStartDateWarning,
  FixedPricedProduct,
} from "lib/plans/planPeriods";
import {
  BillingFrequencyEnum_Enum,
  CustomerPlan,
} from "types/generated-graphql/__types__";
import { BillingDayOfPeriod } from "lib/plans/types";
import { dayjs } from "lib/dayjs";

type PlanDates = {
  startDate: Date | undefined;
  endDate: Date | null | undefined;
  error: string | null | undefined;
};

interface PlanLengthInputProps {
  pricedProducts: FixedPricedProduct[];
  existingPlansForCustomer: Pick<
    CustomerPlan,
    "start_date" | "cancellation_date"
  >[];
  planBillingFrequency?: BillingFrequencyEnum_Enum;
  planServicePeriodStartType?: BillingDayOfPeriod;
  value: PlanDates;
  onChange: (v: PlanDates) => void;
  planDefaultLength?: number;
  lastFinalizedInvoiceDate?: Date;
}

export const PlanLengthInput: React.FC<PlanLengthInputProps> = (props) => {
  let startDateWarning = null;
  let shortStartDateWarning = null;
  let endDateWarning = null;
  let shortEndDateWarning = null;
  if (props.planBillingFrequency && props.planServicePeriodStartType) {
    const hasSomeProratedCharges = props.pricedProducts.some((pp) =>
      pp.PricedProductPricingFactors.some((pppf) => pppf.flatFee?.isProrated),
    );
    startDateWarning = planStartDateWarning(
      props.planBillingFrequency,
      props.planServicePeriodStartType,
      props.pricedProducts,
      props.value.startDate,
      new Date(),
    );
    shortStartDateWarning =
      startDateWarning && hasSomeProratedCharges
        ? "Partial billing period"
        : null;
    endDateWarning = planEndDateWarning(
      props.planBillingFrequency,
      props.planServicePeriodStartType,
      props.pricedProducts,
      props.value.startDate,
      props.value.endDate,
      new Date(),
    );
    shortEndDateWarning =
      endDateWarning && hasSomeProratedCharges
        ? "Partial billing period"
        : null;
  }

  return (
    <>
      <div className={styles.pairedDateInputs}>
        <div className={styles.dateInputStack}>
          <DateInput
            onChange={(v) => {
              props.onChange({
                startDate: v,
                endDate: props.value.endDate,
                error: validatePlanDates(
                  v,
                  props.value.endDate,
                  props.existingPlansForCustomer,
                  props.lastFinalizedInvoiceDate,
                ),
              });
            }}
            onBlur={() => {
              const endDate =
                props.planDefaultLength && props.value.endDate === undefined
                  ? dayjs(props.value.startDate)
                      .add(props.planDefaultLength, "month")
                      .add(-1, "day")
                      .toDate()
                  : props.value.endDate;
              props.onChange({
                startDate: props.value.startDate,
                endDate: endDate,
                error: validatePlanDates(
                  props.value.startDate,
                  endDate,
                  props.existingPlansForCustomer,
                  props.lastFinalizedInvoiceDate,
                ),
              });
            }}
            name="Plan start date"
            value={props.value.startDate}
            success={props.value.error === null}
            error={props.value.error ?? undefined}
            warning={startDateWarning ?? undefined}
            shortWarning={shortStartDateWarning ?? undefined}
            placement="bottom-start"
          />
        </div>
        <div className={styles.dateInputStack}>
          <DateInput
            onChange={(v) => {
              props.onChange({
                endDate: v,
                startDate: props.value.startDate,
                error: validatePlanDates(
                  props.value.startDate,
                  v,
                  props.existingPlansForCustomer,
                  props.lastFinalizedInvoiceDate,
                ),
              });
            }}
            onBlur={() => {
              const startDate =
                props.planDefaultLength &&
                props.value.startDate === undefined &&
                props.value.endDate
                  ? dayjs(props.value.endDate)
                      .add(-1 * props.planDefaultLength, "month")
                      .add(1, "day")
                      .toDate()
                  : props.value.startDate;
              props.onChange({
                endDate: props.value.endDate,
                startDate: startDate,
                error: validatePlanDates(
                  startDate,
                  props.value.endDate,
                  props.existingPlansForCustomer,
                  props.lastFinalizedInvoiceDate,
                ),
              });
            }}
            name="Plan end date"
            disabled={props.value.endDate === null}
            value={props.value.endDate ?? undefined}
            success={!!(props.value.endDate && props.value.error === null)}
            error={
              props.value.error && props.value.startDate && props.value.endDate
                ? props.value.error
                : undefined
            }
            warning={endDateWarning ?? undefined}
            shortWarning={shortEndDateWarning ?? undefined}
          />
          <Checkbox
            checked={props.value.endDate === null}
            onChange={(v) =>
              props.onChange({
                startDate: props.value.startDate,
                endDate: v ? null : undefined,
                error: validatePlanDates(
                  props.value.startDate,
                  v ? null : undefined,
                  props.existingPlansForCustomer,
                  props.lastFinalizedInvoiceDate,
                ),
              })
            }
            label="No end date (perpetual plan)"
          />
        </div>
      </div>
    </>
  );
};
