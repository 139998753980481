import React from "react";
import { InvoicesLayout } from "pages/Contracts/components/Invoices";
import { useRequiredParam } from "lib/routes/params";
import { useParams, useSearchParams } from "react-router-dom";
import { NavigateOptions, useNavigate } from "lib/useNavigate";
import { useApolloResp } from "pages/Contracts/lib/ApolloResp";
import { useCustomerInvoicesQuery } from "./queries.graphql";
import { reportToSentry } from "lib/errors/sentry";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Button } from "tenaissance/components/Button";
import { Invoice } from "components/Invoice/types";

export const CustomerInvoices: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const navigate = useNavigate();
  const activeInvoiceId = useParams<{ invoiceId: string }>().invoiceId;
  const [searchParams] = useSearchParams();

  const invoicesResp = useApolloResp(
    useCustomerInvoicesQuery({ variables: { customerId } }),
  );

  function setActiveInvoiceId(invoiceId?: string, navOpts?: NavigateOptions) {
    navigate(
      `/customers/${customerId}/invoices${
        invoiceId ? `/${invoiceId}` : ""
      }?${searchParams.toString()}`,
      navOpts,
    );
  }
  const invoices =
    invoicesResp.state === "success"
      ? invoicesResp.Customer_by_pk.mri_invoices.invoices
      : [];
  React.useEffect(() => {
    if (invoices.length >= 190) {
      reportToSentry(
        new Error(
          `Contract has >= 190 invoices, consider raising MAX_PAGE on mri_invoices and limit on mri_invoices query`,
        ),
      );
    }
    if (!activeInvoiceId && invoices.length > 0) {
      setActiveInvoiceId(invoices[0].id, {
        replace: true,
      });
    }
  }, [invoices, activeInvoiceId]);
  if (invoicesResp.state === "not found") {
    return (
      <div className="flex justify-center">
        <EmptyState
          mainText={`Customer ${customerId} not found`}
          actions={[
            <Button
              key={0}
              text="View customers"
              onClick={() => navigate("/customers")}
            />,
          ]}
          icon="faceFrown"
        />
      </div>
    );
  }
  return (
    <InvoicesLayout
      customerId={customerId}
      loading={invoicesResp.state === "loading"}
      error={invoicesResp.state === "error" ? invoicesResp.error : undefined}
      invoices={
        invoicesResp.state === "success"
          ? invoicesResp.Customer_by_pk.mri_invoices.invoices
          : []
      }
      activeInvoiceId={activeInvoiceId}
      setActiveInvoiceId={setActiveInvoiceId}
      renderInvoicePlanOrContractLink={renderInvoicePlanOrContractLink}
    />
  );
};

type ContractOrPlanId = {
  contractId?: string;
  contractName?: string | null;
  customerPlanId?: string;
  planName?: string;
};
function getContractOrPlanId(invoice: Invoice): ContractOrPlanId {
  switch (invoice.__typename) {
    case "ContractUsageInvoice":
    case "AdhocContractUsageInvoice":
    case "ContractPostpaidTrueupInvoice":
    case "ContractProServiceInvoice":
    case "ContractScheduledInvoice":
    case "ContractRefundInvoice":
      const contractName = invoice.contract.name;
      return { contractId: invoice.contract.id, contractName };
    case "AdvanceInvoice":
    case "ArrearsInvoice":
      const planName = invoice.customer_plan?.Plan.name;
      return { customerPlanId: invoice.customer_plan?.id, planName };
    case "AdHocPlanInvoice":
    case "ParentInvoice":
    case "CreditPurchaseInvoice":
    case "SeatPurchaseInvoice":
    case "CorrectionInvoice":
    default:
      return {};
  }
}

function truncateString(str: string, length: number) {
  return str.length > length ? str.substring(0, length) + "..." : str;
}

const MAX_NAME_LENGTH = 30;

function renderInvoicePlanOrContractLink(customerId: string, invoice: Invoice) {
  // Render a link to the contract or plan from the invoice view
  // The button renders next to the actions menu button
  const contractOrPlanId = getContractOrPlanId(invoice);
  return (
    <>
      {contractOrPlanId.contractId && (
        <Button
          text={
            contractOrPlanId.contractName
              ? truncateString(contractOrPlanId.contractName, MAX_NAME_LENGTH)
              : "Contract"
          }
          theme="tertiary"
          leadingIcon="arrowNarrowUpRight"
          linkTo={`/customers/${customerId}/contracts/${contractOrPlanId.contractId}`}
        />
      )}
      {contractOrPlanId.customerPlanId && (
        <Button
          text={
            contractOrPlanId?.planName
              ? truncateString(contractOrPlanId.planName, MAX_NAME_LENGTH)
              : "Plan"
          }
          theme="tertiary"
          leadingIcon="arrowNarrowUpRight"
          linkTo={`/customers/${customerId}/plans/${contractOrPlanId.customerPlanId}`}
        />
      )}
    </>
  );
}
