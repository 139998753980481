export const enum BillingConfigFilters {
  None = "none",
  ChargeAutomatically = "charge_automatically",
  SendInvoice = "send_invoice",
  AwsMarketplace = "aws_marketplace",
}

export const ALL_BILLING_CONFIG_FILTERS = [
  BillingConfigFilters.None,
  BillingConfigFilters.ChargeAutomatically,
  BillingConfigFilters.SendInvoice,
  BillingConfigFilters.AwsMarketplace,
];
