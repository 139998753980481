import React from "react";

import { Breadcrumbs } from "lib/breadcrumbs";
import { useCustomerFromRoute } from "../../lib/Customer";
import { CustomerLayout } from "../CustomerLayout";
import { AuditLogTable } from "components/AuditLogTable";

export const CustomerAuditLogs: React.FC = () => {
  const req = useCustomerFromRoute();

  return (
    <CustomerLayout
      rootReq={req}
      breadcrumbs={({ customer }) =>
        Breadcrumbs.from(
          {
            type: "back",
            label: "Back to customer list",
            routePath: "/customers",
          },
          {
            label: customer.name,
            routePath: `/customers/${customer.id}`,
          },
        )
      }
      content={({ customer }) => (
        <div className="pt-12">
          <AuditLogTable resourceType="customer" resourceID={customer.id} />
        </div>
      )}
    />
  );
};
