import z from "zod";
import { Mri_CreditGrant } from "types/generated-graphql/__types__";
import { DeepPartial } from "react-hook-form";
import { useFeatureFlag } from "./launchdarkly";
import { Decimal } from "decimal.js";

type RolloverSettings = NonNullable<Mri_CreditGrant["rollover_settings"]>;

export const rolloverSettingsInputSchema = z.object({
  priority: z
    .string()
    .refine(isPositiveNumber, { message: "Must be a positive number." }),
  expiry_date: z.string(),
  rollover_amount: z.union([
    z.object({
      max_amount: z.object({
        value: z.string(),
      }),
    }),
    z.object({
      max_percentage: z.object({
        value: z.string(),
      }),
    }),
  ]),
});

export type RolloverSettingsInput = z.input<typeof rolloverSettingsInputSchema>;
export type RolloverAmountInput = RolloverSettingsInput["rollover_amount"];
export type RolloverSettingsInputFieldErrors =
  z.typeToFlattenedError<RolloverSettingsInput>["fieldErrors"];

export type RolloverSettingsFormData = Partial<RolloverSettingsInput>;
export type RolloverAmountFormData = DeepPartial<RolloverAmountInput>;

export function rolloverSettingsInputFromGqlType(
  rolloverSettings: RolloverSettings,
): RolloverSettingsInput {
  let rollover_amount: RolloverSettingsInput["rollover_amount"];

  if (rolloverSettings.rollover_amount.__typename === "RolloverMaxAmount") {
    rollover_amount = {
      max_amount: { value: rolloverSettings.rollover_amount.value },
    };
  } else if (
    rolloverSettings.rollover_amount.__typename === "RolloverMaxPercentage"
  ) {
    rollover_amount = {
      max_percentage: { value: rolloverSettings.rollover_amount.value },
    };
  } else {
    rolloverSettings.rollover_amount.__typename satisfies undefined;
    throw new Error(
      `Unknown rollover amount config: ${rolloverSettings.rollover_amount}`,
    );
  }
  return {
    expiry_date: rolloverSettings.expiry_date,
    priority: rolloverSettings.priority,
    rollover_amount,
  };
}

/**
 * Flag to enable rollovers feature.
 */
export function useEnableRolloverCreditGrants() {
  const enableRolloverCreditGrants = useFeatureFlag<boolean>(
    "credit-rollovers-for-plans",
    false,
  );
  return process.env.NODE_ENV === "development" || enableRolloverCreditGrants;
}

function isPositiveNumber(value: string): boolean {
  let decimal;
  try {
    decimal = new Decimal(value);
  } catch (e) {
    return false;
  }
  return decimal.greaterThan(0);
}
