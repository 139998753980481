import React, { useState, useEffect } from "react";
import styles from "./index.module.less";
import Decimal from "decimal.js";

import {
  CreditType,
  CustomCreditType,
  FiatCreditType,
} from "types/credit-types";
import {
  displayCreditTypeName,
  prefixForCreditType,
  creditsFromInput,
  valueStringFromCredits,
} from "lib/credits";

import { InputContainerProps, InputContainer } from "design-system";
import { Body, Subtitle } from "design-system";

import { CreditTypeConversion } from "lib/plans/types";
import { PricingUnitSelector } from "../PricingUnitSelector";

export type CreditInputProps = InputContainerProps & {
  placeholder: string;
  creditType: CreditType;
  onChange?: (credits: number | null) => void;
  initialValue?: string;
  allowZeroAmounts?: boolean;
  allowNegativeAmounts?: boolean;
  hideErrorText?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
};

interface ParseCreditInputValueOptions {
  creditType: CreditType;
  allowZeroAmounts?: boolean;
  allowNegativeAmounts?: boolean;
}

function validateCreditInputValue(
  value: Decimal,
  allowZeroAmounts: boolean,
  allowNegativeAmounts: boolean,
): string | undefined {
  if (value.isNaN()) {
    return "Must be numerical.";
  }

  if (!allowZeroAmounts && !allowNegativeAmounts && value.lte(0)) {
    return "Must be a positive amount.";
  }

  if (allowZeroAmounts && !allowNegativeAmounts && value.lt(0)) {
    return "Cannot be a negative amount.";
  }
}

export function parseCreditInputValue(
  input: string,
  opts: ParseCreditInputValueOptions,
) {
  const cleanInput = input.replace(/[^0-9.-]/g, "");
  let value, error;
  try {
    value = creditsFromInput(cleanInput || "0", opts.creditType);
    error = validateCreditInputValue(
      value,
      !!opts.allowZeroAmounts,
      !!opts.allowNegativeAmounts,
    );
  } catch (e) {
    value = new Decimal(0);
    error = "Must be numerical.";
  }

  return { cleanInput, value, error };
}

export const CreditInput: React.FC<CreditInputProps> = (props) => {
  let initialValue = "";
  if (props.initialValue) {
    try {
      initialValue = valueStringFromCredits(
        props.initialValue,
        props.creditType.id,
      );
    } catch (e) {}
  }
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | boolean>("");

  useEffect(() => setValue(initialValue), [props.creditType]);
  useEffect(() => {
    if (value === "" || props.disabled) {
      setValue(initialValue);
      /* Clear the input and the error */
    } else if (props.initialValue === undefined) {
      setValue("");
      setError("");
    }
  }, [props.initialValue]);

  const onChange = (input: string) => {
    const { cleanInput, value, error } = parseCreditInputValue(input, {
      creditType: props.creditType,
      allowZeroAmounts: props.allowZeroAmounts,
      allowNegativeAmounts: props.allowNegativeAmounts,
    });

    setValue(cleanInput);
    setError((props.hideErrorText ? !!error : error) || "");
    if (props.onChange) {
      props.onChange(error ? null : value.toNumber());
    }
  };

  return (
    <InputContainer
      {...props}
      error={props.hideErrorText ? "" : error || props.error}
      prefix={props.prefix ?? prefixForCreditType(props.creditType)}
      suffix={
        props.suffix ?? prefixForCreditType(props.creditType) === ""
          ? displayCreditTypeName(props.creditType)
          : ""
      }
    >
      <input
        value={value}
        type="string" // type=number inputs don't emit onChange events when you type strings, which makes it hard to use the component, so instead just use text here :/
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
    </InputContainer>
  );
};

type CreditTypeConversionInputProps = InputContainerProps & {
  customCreditType: CustomCreditType;
  fiatCreditType: FiatCreditType;
  setFiatCreditType: (ct: FiatCreditType) => void;
  allowedCreditTypeIds: Set<string> | undefined;
  overageConversion?: CreditTypeConversion;
  onChange: (conversionValue: number | null) => void;
};
export const CreditTypeConversionInput: React.FC<
  CreditTypeConversionInputProps
> = (props) => {
  return (
    <div className={styles.creditTypeConversion}>
      <Subtitle level={4}>
        Overage price for {displayCreditTypeName(props.customCreditType)}
      </Subtitle>
      <div className={styles.conversion}>
        <CreditInput
          creditType={props.fiatCreditType}
          initialValue={props.overageConversion?.toFiatConversionFactor?.toString()}
          placeholder="123.45"
          success={!!props.overageConversion}
          allowZeroAmounts
          onChange={(v) => props.onChange(v)}
          className={styles.conversionInput}
        />
        <div className={styles.fiatCurrency}>
          <PricingUnitSelector
            onChange={(ct: CreditType) =>
              props.setFiatCreditType(ct as FiatCreditType)
            }
            fiatOnly={true}
            allowedCreditTypeIds={props.allowedCreditTypeIds}
            selectedCreditTypeId={props.fiatCreditType?.id}
            showName={false}
          />
        </div>
        <Body>per {displayCreditTypeName(props.customCreditType)} unit</Body>
      </div>
    </div>
  );
};

type NumberInputProps = InputContainerProps & {
  placeholder: string;
  onChange?: (num: number | null) => void;
  initialValue?: string;
  allowZeroAmounts?: boolean;
  allowNegativeAmounts?: boolean;
  maxValue?: number;
  prefix?: string;
  suffix?: string;
  hideErrorText?: boolean;
};

export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const [value, setValue] = useState(props.initialValue || "");
  const [error, setError] = useState<string | boolean>("");

  useEffect(() => {
    if (value === "") {
      setValue(props.initialValue || "");
      /* Clear the input and the error */
    } else if (props.initialValue === undefined) {
      setValue("");
      setError("");
    }
  }, [props.initialValue]);

  const onChange = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9.-]/g, "");
    setValue(sanitizedValue);

    let valueNumber: Decimal | null = null;
    let error = "";
    if (sanitizedValue) {
      try {
        valueNumber = new Decimal(sanitizedValue);
        if (valueNumber.isNaN()) {
          error = "Must be numerical.";
        } else if (
          !props.allowZeroAmounts &&
          !props.allowNegativeAmounts &&
          valueNumber.lessThanOrEqualTo(0)
        ) {
          error = "Must be a positive amount.";
        } else if (
          props.allowZeroAmounts &&
          !props.allowNegativeAmounts &&
          valueNumber.lessThan(0)
        ) {
          error = "Cannot be a negative amount.";
        } else if (props.maxValue && valueNumber.greaterThan(props.maxValue)) {
          error = `Cannot be greater than ${props.maxValue}`;
        }
      } catch (err: unknown) {
        error = "Must be numerical.";
      }
      setError(props.hideErrorText ? !!error : error);
    }

    if (props.onChange) {
      props.onChange(
        error || valueNumber === null || value === ""
          ? null
          : valueNumber.toNumber(),
      );
    }
  };

  return (
    <InputContainer
      {...props}
      error={error || props.error}
      prefix={props.prefix}
      suffix={props.suffix}
    >
      <input
        value={value}
        type="string" // type=number inputs don't emit onChange events when you type strings, which makes it hard to use the component, so instead just use text here :/
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </InputContainer>
  );
};
