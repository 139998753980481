import React from "react";
import { useRequiredParam } from "lib/routes/params";
import classnames from "classnames";

import { useCustomerInfoQuery } from "../../data/customer_details.graphql";

import { CustomerPageTabs } from "pages/Customer";
import { TabProps } from "components/Tabs";
import { PageContainer } from "components/PageContainer";

import styles from "./index.module.less";
import NotFoundPage from "pages/404";
import { EditCustomerNameButton } from "../EditCustomerNameButton";
import { useHasAccessToAlerts } from "lib/alerts";

export function LoadingState() {
  return (
    <PageContainer title="Loading ...">
      <div className={styles.customerContent} />
    </PageContainer>
  );
}

interface CustomerTabProps extends React.PropsWithChildren {
  className?: string;
  noScroll?: boolean;
}

export const CustomerTab: React.FC<CustomerTabProps> = ({
  noScroll,
  children,
}) => {
  const customerId = useRequiredParam("customerId");
  const { data: customerInfo, loading } = useCustomerInfoQuery({
    variables: {
      customer_id: customerId,
    },
  });
  const alertsEnabled = useHasAccessToAlerts();

  const tabs = CustomerPageTabs.map(
    (t): TabProps => ({
      name: t.name,
      activePatterns: t.paths.map((p) => `/customers/:id/${p}`),
      routePath: `/customers/${customerId}${
        t.paths[0] ? `/${t.paths[0]}` : ""
      }`,
    }),
  ).filter((t) => alertsEnabled || t.name !== "Alerts");

  if (loading) {
    return <LoadingState />;
  }

  if (!customerInfo?.Customer) {
    return <NotFoundPage />;
  }

  return (
    <PageContainer
      title={customerInfo.Customer.name}
      tabs={tabs}
      editButton={
        <EditCustomerNameButton
          customerId={customerId}
          currentName={customerInfo.Customer.name}
        />
      }
      badge={
        customerInfo.Customer.archived_at !== null
          ? { theme: "warning", type: "dark", children: "ARCHIVED" }
          : undefined
      }
    >
      <div
        className={classnames(styles.customerContent, {
          [styles.noScroll]: noScroll ? noScroll : false,
        })}
      >
        {children}
      </div>
    </PageContainer>
  );
};
