import { GetBillingProvidersSettingsQuery } from "../../pages/Customer/tabs/Settings/sections/BillingProvider/queries.graphql";
import { parseISO } from "date-fns";
import {
  BillingProviderDeliveryMethod_Enum,
  BillingProviderEnum_Enum,
} from "../../types/generated-graphql/__types__";

export type SalesforceConnectionCustomerSettings = {
  salesforceAccountId?: string;
  connectionDate?: Date;
};

export type NetSuiteConnectionCustomerSettings = {
  netSuiteCustomerId?: string;
  connectionDate?: Date;
};

export type AWSMarketplaceCustomerSettings = {
  customerId?: string;
  productCode?: string;
  region?: string;
  expirationTimestamp?: Date;
  connectionDate?: Date;
  meteringDisabled?: boolean;
  isSubscriptionProduct?: boolean;
};

export type AzureMarketplaceCustomerSettings = {
  customerId?: string;
  subscriptionId?: string;
  subscriptionStatus?: string;
  planId?: string;
  startDate?: Date;
  expirationDate?: Date;
  connectionDate?: Date;
  meteringDisabled?: boolean;
};

export type StripeCustomerSettings = {
  customerId?: string;
  collectionMethod?: string;
  connectionDate?: Date;
};

export type GCPMarketplaceCustomerSettings = {
  connectionDate?: Date;
  expirationDate?: Date;
};

export type FirstClassIntegrations =
  | BillingProviderEnum_Enum.AwsMarketplace
  | BillingProviderEnum_Enum.Azure
  | BillingProviderEnum_Enum.Gcp
  | BillingProviderEnum_Enum.Stripe;

export type BPCustomerSettings<
  T extends
    | SelfServeBillingProviderSettings
    | NetSuiteConnectionCustomerSettings
    | SalesforceConnectionCustomerSettings,
> = T & {
  customerBillingProviderConfigurationID?: string;
  billingProviderCustomerID?: string;
};

export type MultiBPCustomerSettings<
  T extends SelfServeBillingProviderSettings,
> = BPCustomerSettings<T>[];

type SelfServeBillingProviderSettings =
  | AzureMarketplaceCustomerSettings
  | AWSMarketplaceCustomerSettings
  | StripeCustomerSettings;

export type CustomerIntegrationSettingsInfo = {
  clientHasAws: boolean;
  awsMultiSettings: MultiBPCustomerSettings<AWSMarketplaceCustomerSettings>;

  clientHasAzure: boolean;
  azureMultiSettings: MultiBPCustomerSettings<AzureMarketplaceCustomerSettings>;

  clientHasStripe: boolean;
  stripeSettings: MultiBPCustomerSettings<StripeCustomerSettings>;

  clientHasGCP: boolean;
  gcpSettings: MultiBPCustomerSettings<GCPMarketplaceCustomerSettings>;

  hasStripeBillingProviderToken: boolean;
  hasStripeBillingProviderDeliveryMethod: boolean;

  salesforceConnectionSettings: SalesforceConnectionCustomerSettings;
  netSuiteConnectionSettings: NetSuiteConnectionCustomerSettings;
  hasBillingProviderOnPlan: boolean;
  hasStripeBillingProviderConfiguration: boolean;
  hasStripeBillingProviderCustomer: boolean;
};

export const parseIntegrationCustomerSettings = (
  data: GetBillingProvidersSettingsQuery,
): CustomerIntegrationSettingsInfo => {
  const {
    clientHasStripe,
    stripeSettings,
    hasStripeBillingProviderToken,
    hasStripeBillingProviderConfiguration,
    hasStripeBillingProviderCustomer,
  } = parseStripeCustomerSettingsForPlansAndContracts(
    data.stripe_billing_provider_configs,
    data.billingProviderTokens,
    data.customer?.BillingProviderCustomers ?? [],
    data.customerConfigs,
  );
  const { clientHasAws, awsSettings } =
    parseAWSMarketplaceCustomerMultiSettings(
      data.aws_billing_provider_configs,
      data.billingProviderTokens,
      data.customer?.BillingProviderCustomers ?? [],
      data.customerConfigs,
    );
  const { clientHasAzure, azureSettings } =
    parseAzureMarketplaceCustomerMultiSettings(
      data.azure_billing_provider_configs,
      data.billingProviderTokens,
      data.customer?.BillingProviderCustomers ?? [],
      data.customerConfigs,
    );

  const { clientHasGCP, gcpSettings } = parseGCPMultiCustomerSettings(
    data.gcp_billing_provider_configs,
    data.billingProviderTokens,
    data.customer?.BillingProviderCustomers ?? [],
    data.customerConfigs,
  );
  const salesforceConnectionSettings =
    parseSalesforceConnectionCustomerSettings(data.customerConfigs);
  const netSuiteConnectionSettings = parseNetSuiteConnectionCustomerSettings(
    data.customerConfigs,
  );

  const hasStripeBillingProviderDeliveryMethod =
    data.list_delivery_methods.delivery_methods.some(
      (dm) =>
        dm.billing_provider === BillingProviderEnum_Enum.Stripe &&
        dm.delivery_method ===
          BillingProviderDeliveryMethod_Enum.DirectToBillingProvider,
    );

  const hasBillingProviderOnPlan =
    !!data.customer?.BillingProviderCustomers.length &&
    data.customer?.BillingProviderCustomers.length > 0;

  return {
    clientHasAws,
    awsMultiSettings: awsSettings,
    clientHasAzure,
    azureMultiSettings: azureSettings,
    clientHasStripe,
    stripeSettings,
    clientHasGCP,
    gcpSettings,
    salesforceConnectionSettings,
    netSuiteConnectionSettings,
    hasBillingProviderOnPlan,
    hasStripeBillingProviderToken,
    hasStripeBillingProviderDeliveryMethod,
    hasStripeBillingProviderConfiguration,
    hasStripeBillingProviderCustomer,
  };
};

export const billingProviderToName: Record<FirstClassIntegrations, string> = {
  [BillingProviderEnum_Enum.Azure]: "Azure",
  [BillingProviderEnum_Enum.AwsMarketplace]: "AWS",
  [BillingProviderEnum_Enum.Stripe]: "Stripe",
  [BillingProviderEnum_Enum.Gcp]: "GCP",
};

function getUniqueMultiBillingProviderSettings<
  T extends SelfServeBillingProviderSettings,
>(multiSettings: MultiBPCustomerSettings<T>) {
  // use a set of stringified settings to avoid duplicates
  const uniqueSettingsCache: Map<string, MultiBPCustomerSettings<T>[0]> =
    new Map();

  for (const settings of multiSettings) {
    let cacheKey = "";
    if ("productCode" in settings) {
      const awsSettings = settings satisfies AWSMarketplaceCustomerSettings;
      cacheKey = makeUniqueAwsSettingsKey(awsSettings);
    } else if ("subscriptionId" in settings) {
      const azureSettings = settings satisfies AzureMarketplaceCustomerSettings;
      cacheKey = makeUniqueAzureSettingsKey(azureSettings);
    } else if ("customerId" in settings) {
      const stripeSettings = settings satisfies StripeCustomerSettings;
      cacheKey = makeUniqueStripeSettingsKey(stripeSettings);
    } else {
      // cache key remains ""
      continue;
    }

    if (!uniqueSettingsCache.has(cacheKey)) {
      uniqueSettingsCache.set(cacheKey, settings);
    } else {
      // When de-duping, capture the fact that configs might be shared between contracts and plans.
      // Track the customerBillingProviderConfigurationID (contracts) and billingProviderCustomerID (plans)
      // across duplicate configs, so we can correctly show which of either are connected.
      const existingSettings = uniqueSettingsCache.get(cacheKey);
      if (existingSettings) {
        uniqueSettingsCache.set(cacheKey, {
          ...existingSettings,
          customerBillingProviderConfigurationID:
            existingSettings?.customerBillingProviderConfigurationID ||
            settings.customerBillingProviderConfigurationID,
          billingProviderCustomerID:
            existingSettings?.billingProviderCustomerID ||
            settings.billingProviderCustomerID,
        });
      }
    }
  }

  return Array.from(uniqueSettingsCache.values());
}

function parseStripeCustomerSettingsForPlans(
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
): {
  stripeSettings: BPCustomerSettings<StripeCustomerSettings>;
  clientHasStripe: boolean;
  hasStripeBillingProviderToken: boolean;
} {
  let clientHasStripe = false;
  let hasStripeBillingProviderToken = false;
  const stripeSettings: BPCustomerSettings<StripeCustomerSettings> = {};

  // parse configs for plans
  const stripeToken = billingProviderTokens?.find(
    (t) => t.billing_provider === BillingProviderEnum_Enum.Stripe,
  );
  if (stripeToken) {
    clientHasStripe = true;
    hasStripeBillingProviderToken = true;

    const stripeBillingProviderCustomer = billingProviderCustomers?.find(
      (bpCustomer) =>
        bpCustomer.billing_provider === BillingProviderEnum_Enum.Stripe,
    );

    if (stripeBillingProviderCustomer) {
      stripeSettings.billingProviderCustomerID =
        stripeBillingProviderCustomer.billing_provider_customer_id;
      stripeSettings.customerId =
        stripeBillingProviderCustomer.billing_provider_customer_id;
      stripeSettings.connectionDate = parseISO(
        stripeBillingProviderCustomer?.created_at,
      );
      const stripeCollectionMethodConfig = customerConfigs?.find(
        (customerConfig) => customerConfig.key === "stripe_collection_method",
      );
      if (stripeCollectionMethodConfig) {
        stripeSettings.collectionMethod = stripeCollectionMethodConfig.value;
      }
    }
  }

  return {
    clientHasStripe,
    stripeSettings: stripeSettings,
    hasStripeBillingProviderToken,
  };
}

const makeUniqueStripeSettingsKey = (settings: StripeCustomerSettings) => {
  return JSON.stringify({
    customerId: settings.customerId,
  });
};

function parseStripeCustomerSettingsForPlansAndContracts(
  stripe_billing_provider_configs: GetBillingProvidersSettingsQuery["stripe_billing_provider_configs"],
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
): {
  stripeSettings: MultiBPCustomerSettings<StripeCustomerSettings>;
  clientHasStripe: boolean;
  hasStripeBillingProviderToken: boolean;
  hasStripeBillingProviderConfiguration: boolean;
  hasStripeBillingProviderCustomer: boolean;
} {
  let clientHasStripe = false;

  const contractsMultiSettings: MultiBPCustomerSettings<StripeCustomerSettings> =
    [];

  // parse configs for contracts
  if (stripe_billing_provider_configs?.length) {
    // assume there's a delivery method if any cpbc rows exist
    clientHasStripe = true;

    for (const stripeCustomerBillingProviderConfig of stripe_billing_provider_configs) {
      const stripeConfig = stripeCustomerBillingProviderConfig.configuration;
      contractsMultiSettings.push({
        customerId: stripeConfig.stripe_customer_id?.toString(),
        collectionMethod: stripeConfig.stripe_collection_method?.toString(),
        connectionDate: parseISO(
          stripeCustomerBillingProviderConfig?.created_at,
        ),
        customerBillingProviderConfigurationID:
          stripeCustomerBillingProviderConfig?.id,
      });
    }
  }

  // parse configs for plans
  const plansOnlySettings = parseStripeCustomerSettingsForPlans(
    billingProviderTokens,
    billingProviderCustomers,
    customerConfigs,
  );
  clientHasStripe = clientHasStripe || plansOnlySettings.clientHasStripe;
  contractsMultiSettings.push(plansOnlySettings.stripeSettings);
  const stripeSettings = getUniqueMultiBillingProviderSettings(
    contractsMultiSettings,
  );

  return {
    clientHasStripe,
    stripeSettings: getUniqueMultiBillingProviderSettings(
      contractsMultiSettings,
    ),
    hasStripeBillingProviderToken:
      plansOnlySettings.hasStripeBillingProviderToken,
    hasStripeBillingProviderConfiguration: stripeSettings?.some(
      (s) => !!s.customerBillingProviderConfigurationID,
    ),
    hasStripeBillingProviderCustomer: stripeSettings?.some(
      (s) => !!s.billingProviderCustomerID,
    ),
  };
}

const makeUniqueAwsSettingsKey = (settings: AWSMarketplaceCustomerSettings) => {
  return JSON.stringify({
    customerId: settings.customerId,
    productCode: settings.productCode,
    region: settings.region,
  });
};

function parseAWSMarketplaceCustomerMultiSettings(
  aws_billing_provider_configs: GetBillingProvidersSettingsQuery["azure_billing_provider_configs"],
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  let clientHasAws = false;
  const awsSettings: MultiBPCustomerSettings<AWSMarketplaceCustomerSettings> =
    [];

  // parse configs for contracts
  if (aws_billing_provider_configs?.length) {
    // assume there's a delivery method if any cpbc rows exist
    clientHasAws = true;

    for (const awsBillingProviderConfig of aws_billing_provider_configs) {
      const awsConfig = awsBillingProviderConfig.configuration;
      awsSettings.push({
        customerId: awsConfig.aws_customer_id?.toString(),
        productCode: awsConfig.aws_product_code?.toString(),
        region: awsConfig.aws_region?.toString(),
        isSubscriptionProduct:
          awsConfig.aws_is_subscription_product?.toString() === "true",
        connectionDate: parseISO(awsBillingProviderConfig?.created_at),
        customerBillingProviderConfigurationID: awsBillingProviderConfig?.id,
        meteringDisabled:
          awsConfig.aws_metering_disabled?.toString() === "true",
      });
    }
  }

  // parse configs for plans
  const awsToken = billingProviderTokens?.find(
    (t) => t.billing_provider === BillingProviderEnum_Enum.AwsMarketplace,
  );
  if (awsToken) {
    clientHasAws = true;

    const awsBillingProviderCustomer = billingProviderCustomers?.find(
      (bpCustomer) =>
        bpCustomer.billing_provider === BillingProviderEnum_Enum.AwsMarketplace,
    );

    const plansAwsSettings: MultiBPCustomerSettings<AWSMarketplaceCustomerSettings>[0] =
      {};
    if (awsBillingProviderCustomer) {
      // used to distinguish from contracts
      plansAwsSettings.billingProviderCustomerID =
        awsBillingProviderCustomer.billing_provider_customer_id;
      plansAwsSettings.customerId =
        awsBillingProviderCustomer.billing_provider_customer_id;
      plansAwsSettings.connectionDate = parseISO(
        awsBillingProviderCustomer.created_at,
      );
      for (const customerConfig of customerConfigs ?? []) {
        if (customerConfig.key === "aws_product_code") {
          plansAwsSettings.productCode = customerConfig.value;
        } else if (customerConfig.key === "aws_region") {
          plansAwsSettings.region = customerConfig.value;
        } else if (customerConfig.key === "aws_expiration_timestamp") {
          plansAwsSettings.expirationTimestamp = parseISO(customerConfig.value);
        } else if (customerConfig.key === "aws_metering_disabled") {
          plansAwsSettings.meteringDisabled = customerConfig.value === "true";
        } else if (customerConfig.key === "aws_is_subscription_product") {
          plansAwsSettings.isSubscriptionProduct =
            customerConfig?.value?.toString() === "true";
        }
      }
      awsSettings.push(plansAwsSettings);
    }
  }

  return {
    clientHasAws,
    awsSettings: getUniqueMultiBillingProviderSettings(awsSettings),
  };
}

function parseGcpCustomerSettings(
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  const gcpSettings: BPCustomerSettings<GCPMarketplaceCustomerSettings> = {};

  const gcpBillingProviderCustomer = billingProviderCustomers?.find(
    (bpCustomer) =>
      bpCustomer.billing_provider === BillingProviderEnum_Enum.AwsMarketplace,
  );

  if (gcpBillingProviderCustomer) {
    gcpSettings.billingProviderCustomerID =
      gcpBillingProviderCustomer.billing_provider_customer_id;
    gcpSettings.connectionDate = parseISO(
      gcpBillingProviderCustomer.created_at,
    );
  }
  for (const customerConfig of customerConfigs ?? []) {
    if (customerConfig.key === "gcp_expiration_date") {
      gcpSettings.expirationDate = parseISO(customerConfig.value);
    }
  }

  return {
    gcpSettings: Object.keys(gcpSettings).length > 0 ? [gcpSettings] : [],
  };
}

export function parseGCPMultiCustomerSettings(
  gcp_billing_provider_configs: GetBillingProvidersSettingsQuery["gcp_billing_provider_configs"],
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
): {
  gcpSettings: MultiBPCustomerSettings<GCPMarketplaceCustomerSettings>;
  clientHasGCP: boolean;
} {
  let clientHasGCP = false;

  const contractsMultiSettings: MultiBPCustomerSettings<GCPMarketplaceCustomerSettings> =
    [];

  // parse configs for contracts
  if (gcp_billing_provider_configs?.length) {
    // assume there's a delivery method if any cpbc rows exist
    clientHasGCP = true;

    for (const gcpCustomerBillingProviderConfig of gcp_billing_provider_configs) {
      contractsMultiSettings.push({
        connectionDate: parseISO(gcpCustomerBillingProviderConfig?.created_at),
        customerBillingProviderConfigurationID:
          gcpCustomerBillingProviderConfig?.id,
      });
    }
  }

  // parse configs for plans
  const plansOnlySettings = parseGcpCustomerSettings(
    billingProviderTokens,
    billingProviderCustomers,
    customerConfigs,
  );

  return {
    clientHasGCP,
    gcpSettings: contractsMultiSettings.concat(
      plansOnlySettings?.gcpSettings?.[0]
        ? [
            {
              connectionDate: plansOnlySettings.gcpSettings[0].connectionDate,
              customerBillingProviderConfigurationID:
                plansOnlySettings.gcpSettings[0].billingProviderCustomerID,
            },
          ]
        : [],
    ),
  };
}

const makeUniqueAzureSettingsKey = (
  settings: AzureMarketplaceCustomerSettings,
) => {
  return JSON.stringify({
    subscriptionId: settings.subscriptionId,
  });
};

function parseAzureMarketplaceCustomerMultiSettings(
  azure_billing_provider_configs: GetBillingProvidersSettingsQuery["azure_billing_provider_configs"],
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  let clientHasAzure = false;
  const azureSettings: MultiBPCustomerSettings<AzureMarketplaceCustomerSettings> =
    [];

  // parse configs for contracts
  if (azure_billing_provider_configs?.length) {
    clientHasAzure = true;

    for (const azureBillingProviderConfig of azure_billing_provider_configs) {
      const azureConfig = azureBillingProviderConfig.configuration;
      azureSettings.push({
        subscriptionId: azureConfig.azure_subscription_id?.toString(),
        subscriptionStatus:
          azureConfig.azure_subscription_status?.toString() ?? "",
        planId: azureConfig.azure_plan_id?.toString(),
        startDate: azureConfig.azure_start_date
          ? parseISO(azureConfig.azure_start_date?.toString())
          : undefined,
        expirationDate: azureConfig.azure_expiration_date
          ? parseISO(azureConfig.azure_expiration_date?.toString())
          : undefined,
        meteringDisabled: azureConfig.azure_metering_disabled === "true",
        connectionDate: parseISO(azureBillingProviderConfig?.created_at),
        customerBillingProviderConfigurationID: azureBillingProviderConfig?.id,
      });
    }
  }

  // parse configs for plans
  const azureToken = billingProviderTokens?.find(
    (t) => t.billing_provider === BillingProviderEnum_Enum.Azure,
  );
  if (azureToken) {
    clientHasAzure = true;

    const azureBillingProviderCustomer = billingProviderCustomers?.find(
      (bpCustomer) =>
        bpCustomer.billing_provider === BillingProviderEnum_Enum.Azure,
    );

    const plansAzureSettings: MultiBPCustomerSettings<AzureMarketplaceCustomerSettings>[0] =
      {};
    if (azureBillingProviderCustomer) {
      // used to distinguish from contracts
      plansAzureSettings.billingProviderCustomerID =
        azureBillingProviderCustomer.billing_provider_customer_id;
      plansAzureSettings.subscriptionId =
        azureBillingProviderCustomer.billing_provider_customer_id;
      plansAzureSettings.connectionDate = parseISO(
        azureBillingProviderCustomer.created_at,
      );
      for (const customerConfig of customerConfigs ?? []) {
        if (customerConfig.key === "azure_subscription_status") {
          plansAzureSettings.subscriptionStatus = customerConfig.value;
        } else if (customerConfig.key === "azure_plan_id") {
          plansAzureSettings.planId = customerConfig.value;
        } else if (customerConfig.key === "azure_start_date") {
          plansAzureSettings.startDate = parseISO(customerConfig.value);
        } else if (customerConfig.key === "azure_expiration_date") {
          plansAzureSettings.expirationDate = parseISO(customerConfig.value);
        } else if (customerConfig.key === "azure_metering_disabled") {
          plansAzureSettings.meteringDisabled = customerConfig.value === "true";
        }
      }
      azureSettings.push(plansAzureSettings);
    }
  }

  return {
    clientHasAzure,
    azureSettings: getUniqueMultiBillingProviderSettings(azureSettings),
  };
}

function parseSalesforceConnectionCustomerSettings(
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  const salesforceConnectionSettings: SalesforceConnectionCustomerSettings = {};

  for (const customerConfig of customerConfigs ?? []) {
    if (customerConfig.key === "salesforce_account_id") {
      salesforceConnectionSettings.salesforceAccountId = customerConfig.value;
      salesforceConnectionSettings.connectionDate = parseISO(
        customerConfig.updated_at,
      );
    }
  }

  return salesforceConnectionSettings;
}

function parseNetSuiteConnectionCustomerSettings(
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  const netSuiteConnectionSettings: NetSuiteConnectionCustomerSettings = {};

  for (const customerConfig of customerConfigs ?? []) {
    if (customerConfig.key === "netsuite_customer_id") {
      netSuiteConnectionSettings.netSuiteCustomerId = customerConfig.value;
      netSuiteConnectionSettings.connectionDate = parseISO(
        customerConfig.updated_at,
      );
    }
  }

  return netSuiteConnectionSettings;
}

export interface CustomerSettingsWritePlansAndOrContracts {
  // Support either:
  // 1) writing BPCustomer and CustomerBPConfig upon setting up the
  //   first integration for a customer, setting the default BP for the plan and
  //   allowing new contracts to attach the same BP configuration OR
  // 2) only writing a new CustomerBPConfig when setting up a new integration
  //    for a contract on a customer that already has a BPCustomer or
  //    CustomerBPConfig
  plansAndOrContracts?: "plans_and_contracts" | "contracts_only" | "plans_only";
}

export function getNetSuiteCustomerIntegrationCardVisibility(
  netSuiteConnectionSettings: NetSuiteConnectionCustomerSettings,
  netSuiteFeatureFlagEnabled: boolean,
): {
  isAvailable: boolean;
  isConnected: boolean;
} {
  const netSuiteCustomerConfigExists =
    !!netSuiteConnectionSettings?.connectionDate;
  return {
    isConnected: netSuiteFeatureFlagEnabled
      ? netSuiteCustomerConfigExists
      : false,
    isAvailable: netSuiteFeatureFlagEnabled && !netSuiteCustomerConfigExists,
  };
}
