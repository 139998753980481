import React from "react";
import "/src/tenaissance/tenaissance.css";

import { BillingsCard } from "./components/BillingsCard";
import { ContractsAndPlansTable } from "./ContractAndPlansTable";
import { InvoicesTable } from "./InvoicesTable";
import { useAuthCheck } from "lib/useAuthCheck";
import { useUIMode } from "lib/useUIMode";
import {
  useCheckCustomerHasContractQuery,
  useCheckCustomerHasPlanQuery,
} from "./queries.graphql";
import { useRequiredParam } from "lib/routes/params";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Button } from "tenaissance/components/Button";
import { Dropdown, DropdownItem } from "tenaissance/components/Dropdown";
import { CreateContractDocument } from "pages/Contracts/Customer/Contracts/Create/data.graphql";
import { AddPlanToCustomerDocument } from "pages/AddPlanToCustomer/queries.graphql";
import { LoadingBlob } from "tenaissance/components/LoadingBlob";

export const CustomerOverview: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const { mode } = useUIMode();
  const canCreateContract = useAuthCheck(CreateContractDocument, false);
  const canCreatePlan = useAuthCheck(AddPlanToCustomerDocument, false);
  const { data: customerHasPlanData, loading: customerHasPlanLoading } =
    useCheckCustomerHasPlanQuery({
      skip: mode === "contracts-only",
      variables: {
        customerId: customerId,
      },
    });

  const { data: customerHasContractData, loading: customerHasContractLoading } =
    useCheckCustomerHasContractQuery({
      skip: mode === "plans-only",
      variables: {
        customerId: customerId,
      },
    });

  const loading = customerHasPlanLoading || customerHasContractLoading;

  if (
    !loading &&
    customerHasPlanData?.customer?.CustomerPlans &&
    customerHasContractData?.customer?.contracts
  ) {
    const customerHasPlanOrContract =
      customerHasPlanData?.customer?.CustomerPlans?.length > 0 ||
      customerHasContractData?.customer?.contracts?.length > 0;

    let supportingLabel = "contract or plan";
    if (mode === "contracts-only") {
      supportingLabel = "contract";
    } else if (mode === "plans-only") {
      supportingLabel = "plan";
    }

    if (!customerHasPlanOrContract) {
      return (
        <div className="flex w-full justify-center">
          <EmptyState
            icon="userEdit"
            mainText={`No ${
              mode === "contracts-only"
                ? "contracts"
                : mode === "plans-only"
                  ? "plans"
                  : "contracts or plans"
            } found`}
            supportingText={`This customer has not been connected to a ${supportingLabel}. Add one to begin invoicing.`}
            actions={[
              mode === "contracts-and-plans" ? (
                <Dropdown
                  key="contract or plan dropdown"
                  icon="plus"
                  label="Add"
                  buttonTheme="primary"
                  hideChevron={true}
                  children={[
                    <DropdownItem
                      value="Contract"
                      label="Contract"
                      disabled={!canCreateContract}
                      linkTo={`/customers/${customerId}/contracts/add`}
                    />,
                    <DropdownItem
                      value="Plan"
                      label="Plan"
                      disabled={!canCreatePlan}
                      linkTo={`/customers/${customerId}/plans/add`}
                    />,
                  ]}
                />
              ) : (
                <Button
                  disabled={
                    mode === "plans-only" ? !canCreatePlan : !canCreateContract
                  }
                  text="Add"
                  leadingIcon="plus"
                  theme="primary"
                  linkTo={
                    mode === "plans-only"
                      ? `/customers/${customerId}/plans/add`
                      : `/customers/${customerId}/contracts/add`
                  }
                />
              ),
            ]}
          />
        </div>
      );
    }
  }

  if (loading) {
    return <LoadingBlob />;
  }

  return (
    <div className="gap-xs flex flex-col">
      <div className="gap-y-4xl flex flex-col">
        <BillingsCard />
        <ContractsAndPlansTable />
        <InvoicesTable />
      </div>
    </div>
  );
};
