import { LDClient } from "launchdarkly-js-client-sdk";
import billedRevenueSummary from "./reportTypes/billedRevenueSummary";
import agentSyncRevRecReport from "./reportTypes/agentsyncRevRec";
import billingProviderInvoicesReport from "./reportTypes/billingProviderInvoices";
import {
  soxComplianceInvoiceReport,
  soxAuditLogReport,
  usageBasedBillingFinalizationReport,
} from "./reportTypes/soxReports";
import netsuiteInvoiceDelivery from "./reportTypes/netsuiteInvoiceDelivery";
import creditGrantIssuanceReport from "./reportTypes/creditGrantIssuance";
import creditGrantRevenueReport from "./reportTypes/creditGrantUsage";
import { ReportConfig, SOXReport } from "./reportTypes/reportConfig";

export const useHasAccessToReports = (ldClient: LDClient | undefined) => {
  if (!ldClient) {
    return false;
  }
  const availableReports = REPORTS.filter((report) =>
    report.isAllowedForUser(ldClient),
  );
  return availableReports.length > 0;
};

export const emailValidation = /^.+@.+\..+$/;

export const REPORTS: (ReportConfig<any> | SOXReport)[] = [
  billedRevenueSummary,
  agentSyncRevRecReport,
  billingProviderInvoicesReport,
  creditGrantIssuanceReport,
  creditGrantRevenueReport,
  netsuiteInvoiceDelivery,
  soxComplianceInvoiceReport,
  soxAuditLogReport,
  usageBasedBillingFinalizationReport,
].sort((a, b) => a.name.localeCompare(b.name));

export { downloadCSV } from "./downloadCSV";
