import React from "react";
import classnames from "classnames";

import styles from "./index.module.less";
import { Body, Caption, Icon, IconName } from "design-system";
import pluralize from "pluralize";

interface StatisticProps extends React.PropsWithChildren {
  caption: string;
  className?: string;
}
export const Statistic: React.FC<StatisticProps> = ({
  caption,
  className,
  children,
}) => (
  <div className={classnames(styles.statistic, className)}>
    <Caption>{caption}</Caption>
    {children}
  </div>
);

export const Statistics: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className={styles.statistics}>{children}</div>
);

export type MinimalStatisticValue = number | "N/A";

export interface MinimalStatisticProps {
  icon: IconName;
  value?: MinimalStatisticValue;
  label: string;
}

export const MinimalStatistic: React.FC<MinimalStatisticProps> = (props) => {
  return (
    <div className={styles.minimalStatistic}>
      <Icon icon={props.icon} />
      <Body level={2} className={styles.label}>
        {props.value !== undefined
          ? `${props.value.toLocaleString()} ${
              props.value === 1
                ? pluralize.singular(props.label)
                : pluralize.plural(props.label)
            }`
          : props.label}
      </Body>
    </div>
  );
};
