import React from "react";

import { useUpdateCustomerNameMutation } from "pages/Customer/components/EditCustomerNameButton/queries.graphql";
import { EditNameButton } from "./EditNameButton";

interface EditNameModalProps {
  customerId: string;
  currentName: string;
}

export const EditCustomerNameButton: React.FC<EditNameModalProps> = ({
  customerId,
  currentName,
}) => {
  const [updateCustomerNameMutation, updateNameResults] =
    useUpdateCustomerNameMutation();
  return (
    <EditNameButton
      title="Edit customer name"
      modalOptions={{
        supportingText: "Change this customer's name in Metronome.",
      }}
      label="Customer name"
      currentName={currentName}
      updateName={async (name: string) => {
        await updateCustomerNameMutation({
          variables: {
            customer_id: customerId,
            name,
          },
          update(cache) {
            cache.evict({
              id: customerId,
              fieldName: "Customer",
            });
          },
        });
      }}
      loading={updateNameResults.loading}
    />
  );
};
