import React, { useMemo } from "react";
import "/src/tenaissance/tenaissance.css";
import { Tooltip } from "../Tooltip";
import { format, utcToZonedTime } from "date-fns-tz";

const UTC_TIMEZONE = "Etc/UTC";
const FORMAT_STRINGS = {
  tzDateTime: "zzz: MMM d, yyyy h:mm a",
  dateTime: "MMM d, yyyy h:mm a",
  date: "MMM d, yyyy",
  short: "MMM d",
};

interface TimestampProps {
  /** UTC Date, used to render and calculate local time from */
  dateTime: Date;
  /** Optional end date for a range; UTC Date, used to render and calculate local time from */
  endDateTime?: Date;
  /** Optional - will only show the date, and the tooltip hover will show date+time */
  dateOnly?: boolean;
}

/**
 * Timestamps let users see the exact date in UTC and local time.
 *  The default timestamp is in UTC with a hover to see more details.
 */
export const Timestamp: React.FC<TimestampProps> = ({
  dateTime,
  endDateTime,
  dateOnly,
}) => {
  const tooltipLabel = useMemo(() => {
    const localDateString = format(dateTime, FORMAT_STRINGS.tzDateTime);
    const utcDateString = format(
      utcToZonedTime(dateTime, UTC_TIMEZONE),
      FORMAT_STRINGS.tzDateTime,
      {
        timeZone: UTC_TIMEZONE,
      },
    );
    const endDateString = endDateTime
      ? format(endDateTime, FORMAT_STRINGS.dateTime)
      : undefined;
    const endUtcDateString = endDateTime
      ? format(
          utcToZonedTime(endDateTime, UTC_TIMEZONE),
          FORMAT_STRINGS.dateTime,
          {
            timeZone: UTC_TIMEZONE,
          },
        )
      : undefined;

    const local = endDateTime
      ? `${localDateString} - ${endDateString}`
      : localDateString;

    const utc = endUtcDateString
      ? `${utcDateString} - ${endUtcDateString}`
      : utcDateString;

    return (
      <>
        <time className="block font-normal">
          <span className="font-medium">{`${local.split(":")[0]}`}</span>
          {local.substring(local.indexOf(":"))}
        </time>
        <time className="block font-normal">
          <span className="font-medium">{`${utc.split(":")[0]}`}</span>
          {utc.substring(utc.indexOf(":"))}
        </time>
      </>
    );
  }, [dateTime, endDateTime]);

  const formatType = dateOnly ? FORMAT_STRINGS.date : FORMAT_STRINGS.dateTime;
  const renderedDateTime = useMemo(() => {
    const start = format(utcToZonedTime(dateTime, UTC_TIMEZONE), formatType, {
      timeZone: UTC_TIMEZONE,
    });
    const end = endDateTime
      ? format(utcToZonedTime(endDateTime, UTC_TIMEZONE), formatType, {
          timeZone: UTC_TIMEZONE,
        })
      : undefined;
    return endDateTime
      ? `${start} - ${end}`
      : format(utcToZonedTime(dateTime, UTC_TIMEZONE), formatType, {
          timeZone: UTC_TIMEZONE,
        });
  }, [dateTime, endDateTime]);

  return (
    <Tooltip subLabel={tooltipLabel}>
      <time className="rounded-2xl py-xs px-md hover:bg-gray-200">
        {renderedDateTime}
      </time>
    </Tooltip>
  );
};
