interface BreadcrumbOption {
  readonly content: React.ReactNode;
  readonly routePath?: string;
}

interface BreadcrumbInput {
  readonly type?: "breadcrumb" | "loading" | "back";
  readonly label: string;
  readonly key?: string;
  readonly routePath?: string;
  readonly options?: BreadcrumbOption[];
  readonly badge?: React.ReactNode;
}

interface ActualBreadcrumb extends BreadcrumbInput {
  readonly type: "breadcrumb";
  readonly key: string;
  readonly isLast: boolean;
  readonly badge?: React.ReactNode;
}

interface PlaceholderBreadcrumb extends BreadcrumbInput {
  readonly type: "loading";
  readonly key: string;
}

interface BackButtonBreadcrumb extends BreadcrumbInput {
  readonly type: "back";
  readonly key: string;
}

export type Breadcrumb =
  | BackButtonBreadcrumb
  | PlaceholderBreadcrumb
  | ActualBreadcrumb;

/**
 * Class for managing breadcrumbs that will eventually be rendered in the header
 */
export class Breadcrumbs {
  static loading = new Breadcrumbs({ type: "loading", label: "Loading…" });
  static none = new Breadcrumbs();

  static from(
    ...input: Array<Breadcrumbs | BreadcrumbInput | false | null | undefined>
  ) {
    return new Breadcrumbs(...input);
  }

  private readonly crumbs: Array<BreadcrumbInput>;
  private constructor(
    ...input: Array<Breadcrumbs | BreadcrumbInput | false | null | undefined>
  ) {
    this.crumbs = input.flatMap((input) => {
      if (!input) {
        return [];
      }

      if (input instanceof Breadcrumbs) {
        return input.crumbs;
      }

      return input;
    });
  }

  backBreadcrumb() {
    return this.crumbs.find((c) => c.type === "back");
  }

  toPageTitle() {
    const chunks: string[] = [];

    for (const crumb of this.crumbs) {
      if (crumb.type === "loading") {
        return "Loading…";
      }

      if (crumb.type === "back") {
        continue;
      }

      chunks.push(
        crumb.label.length > 20 ? `${crumb.label.slice(0, 18)}…` : crumb.label,
      );
    }

    return chunks.reverse().join(" | ");
  }

  toArray(withBackButton = true): Array<Breadcrumb> {
    return this.crumbs
      .filter((c) => withBackButton || c.type !== "back")
      .map((crumb, i, list) => {
        if (crumb.type === "loading") {
          const placeholder: PlaceholderBreadcrumb = {
            ...crumb,
            type: "loading",
            key: `loading-${i}`,
          };

          return placeholder;
        }

        if (crumb.type === "back") {
          const back: BackButtonBreadcrumb = {
            ...crumb,
            type: "back",
            key: `back-${i}`,
          };

          return back;
        }

        const actual: ActualBreadcrumb = {
          type: "breadcrumb",
          label: crumb.label,
          key: crumb.key ?? `breadcrumb-${i}`,
          routePath: crumb.routePath,
          options: crumb.options,
          isLast: i === list.length - 1,
          badge: crumb.badge,
        };

        return actual;
      });
  }
}
