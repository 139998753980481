import React from "react";
import { displayCreditTypeName } from "lib/credits";
import { Button } from "tenaissance/components/Button";
import { TextInput } from "tenaissance/components/Input";
import { Modal } from "tenaissance/components/Modal";
import { CreditType } from "types/credit-types";

type Props = {
  onClose: () => void;
  onSave: (data: {
    customCreditType: CreditType;
    conversionRate: number;
  }) => void;
  fiatCreditType: CreditType;
  customCreditType?: CreditType;
  conversionRate?: number;
};

export function ConversionModal({
  onClose,
  onSave,
  customCreditType,
  fiatCreditType,
  conversionRate: currentConversionRate,
}: Props) {
  const [name, setName] = React.useState(
    customCreditType ? displayCreditTypeName(customCreditType) : "",
  );
  const [conversionRate, setConversionRate] = React.useState<
    number | undefined
  >(currentConversionRate);

  const fiatCreditName = displayCreditTypeName(fiatCreditType);

  // TODO(ekaragiannis) - show the currency symbol based on fiatCreditType
  return (
    <Modal
      icon="coinsHand"
      title="Add a custom pricing unit"
      supportingText="Custom pricing units enable you to charge on non-fiat currencies."
      isOpen
      onClose={onClose}
      buttonLayout="horizontal"
      modalButtons={[
        <Button
          theme="primary"
          type="submit"
          text="Confirm"
          showFullWidth
          disabled={!name || !conversionRate}
          onClick={() => {
            if (customCreditType) {
              onSave({
                customCreditType,
                conversionRate: conversionRate ?? 0, // keep typescript happy
              });
            } else {
              // TODO(ekaragiannis) - create the custom credit type and save
              onClose();
            }
          }}
        />,
        <Button
          theme="secondary"
          type="button"
          text="Cancel"
          showFullWidth
          onClick={onClose}
        />,
      ]}
    >
      <div className="flex flex-col gap-12">
        {!customCreditType && (
          <TextInput
            label="Pricing unit name"
            hintText="Enter name as it should appear on invoices"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e.value);
            }}
          />
        )}
        <TextInput
          label="Conversion rate"
          placeholder="Enter conversion"
          fullWidth
          value={conversionRate?.toString()}
          onChange={(e) => {
            if (e.value) {
              setConversionRate(Number(e.value));
            } else {
              setConversionRate(undefined);
            }
          }}
          hintText={
            conversionRate
              ? `${conversionRate} ${fiatCreditName} per ${name}`
              : ""
          }
        />
      </div>
    </Modal>
  );
}
