import React, { useState } from "react";
import { Badge } from "design-system";
import { SortFunctions, SortRule, Table } from "components/Table";
import { dayBefore, renderDate, renderDateRange } from "lib/time";
import { planToDraftPlan } from "lib/plans/draftPlan";
import { PlanDetails } from "lib/plans/types";
import styles from "./index.module.less";
import {
  PlanActiveCustomerPlanDataQuery,
  usePlanActiveCustomerPlanDataQuery,
} from "../../../../data/queries.graphql";
import {
  Order_By,
  BillingProviderEnum_Enum,
} from "types/generated-graphql/__types__";
import { useDraftPlan } from "../../../../context";
import { currentRampForPlan } from "./ramps";
import { filterInvoiceByType } from "lib/invoices/typeGuard";
import { reportToSentry } from "lib/errors/sentry";
import { EmptyState } from "tenaissance/components/EmptyState";

type CustomerPlanInfo = PlanActiveCustomerPlanDataQuery["CustomerPlan"][0];

interface Props {
  previousPlan: PlanDetails;
  numCustomers: number;
  numMarketplacesCustomers: number;
}

const PAGE_SIZE = 20;

export const CustomerImpactTable: React.FC<Props> = ({
  previousPlan,
  numCustomers,
  numMarketplacesCustomers,
}) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [currentSort, setCurrentSort] = useState<SortRule[]>([
    { id: "plan start" },
  ]);

  const {
    data: customerPlanData,
    loading: customerPlanDataLoading,
    error: customerPlanDataError,
  } = usePlanActiveCustomerPlanDataQuery({
    variables: {
      plan_id: previousPlan.id,
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      order_by: currentSort.flatMap((rule) => {
        switch (rule.id) {
          case "plan start":
            return { start_date: rule.desc ? Order_By.Desc : Order_By.Asc };
          case "plan end":
            return {
              cancellation_date: rule.desc
                ? Order_By.DescNullsFirst
                : Order_By.AscNullsLast,
            };
          case "customer":
            return {
              Customer: { name: rule.desc ? Order_By.Desc : Order_By.Asc },
            };
          default:
            return [];
        }
      }),
    },
  });
  const { draftPlan } = useDraftPlan();
  const prevPlan = planToDraftPlan(previousPlan);
  const showRampChanges = !!draftPlan.ramps?.length || !!prevPlan.ramps.length;

  if (customerPlanDataLoading) {
    return null;
  }
  if (!customerPlanData || customerPlanDataError) {
    if (customerPlanDataError) {
      reportToSentry(customerPlanDataError);
    }
    return (
      <EmptyState
        icon="users01"
        mainText="This plan has too many customers to display here."
        supportingText="Please ask your Metronome representative if you would like a report on the customers affected by this edit."
      />
    );
  }

  return (
    <Table
      data={customerPlanData?.CustomerPlan ?? []}
      columns={[
        {
          header: "Customer name",
          id: "customer",
          render: (customerPlan: CustomerPlanInfo) =>
            customerPlan.Customer.name,
          sortable: true,
          comparator: SortFunctions.String(
            (plan: CustomerPlanInfo) => plan.Customer.name,
          ),
          textWrappable: true,
        },
        {
          header: "Plan start",
          id: "plan start",
          render: (customerPlan: CustomerPlanInfo) =>
            renderDate(new Date(customerPlan.start_date), {
              isUtc: true,
              excludeUtcLabel: true,
            }),
          sortable: true,
          comparator: SortFunctions.Date(
            (plan: CustomerPlanInfo) => new Date(plan.start_date),
          ),
        },
        {
          header: "Plan end",
          id: "plan end",
          render: (customerPlan: CustomerPlanInfo) =>
            customerPlan.cancellation_date
              ? renderDate(
                  dayBefore(new Date(customerPlan.cancellation_date)),
                  { isUtc: true, excludeUtcLabel: true },
                )
              : "Recurring",
          sortable: true,
          comparator: SortFunctions.Date((plan: CustomerPlanInfo) =>
            plan.cancellation_date
              ? new Date(plan.cancellation_date)
              : undefined,
          ),
        },
        ...(showRampChanges
          ? [
              {
                header: "Current pricing ramp",
                id: "current ramp",
                render: (customerPlan: CustomerPlanInfo) =>
                  currentRampForPlan(
                    prevPlan,
                    new Date(customerPlan.start_date),
                    new Date(),
                  ),
              },
              {
                header: "New pricing ramp",
                id: "new ramp",
                render: (customerPlan: CustomerPlanInfo) => {
                  const prevRamp = currentRampForPlan(
                    prevPlan,
                    new Date(customerPlan.start_date),
                    new Date(),
                  );
                  const newRamp = currentRampForPlan(
                    draftPlan,
                    new Date(customerPlan.start_date),
                    new Date(),
                  );
                  return (
                    <div
                      className={
                        newRamp !== prevRamp ? styles.changed : undefined
                      }
                    >
                      {newRamp}
                    </div>
                  );
                },
              },
            ]
          : []),
        {
          header: "Invoice impacted",
          id: "invoice",
          render: (customerPlan: CustomerPlanInfo) => {
            // If there's more than one active service period (say, because we're in the
            // lock period of the previous billing period), show the first (earliest) one.
            const firstInvoice =
              customerPlan.Customer.active_invoices.invoices?.find(
                filterInvoiceByType("ArrearsInvoice"),
              );
            if (!firstInvoice) {
              return "--";
            } else {
              return (
                <>
                  <Badge theme="grey" type="light">
                    DRAFT
                  </Badge>{" "}
                  {renderDateRange(
                    new Date(firstInvoice.inclusive_start_date),
                    dayBefore(new Date(firstInvoice.exclusive_end_date)),
                    {
                      isUtc: true,
                      excludeUtcLabel: true,
                    },
                    false,
                  )}
                </>
              );
            }
          },
        },
        ...(numMarketplacesCustomers > 0
          ? [
              {
                header: "Marketplace billing",
                id: "marketplace",
                render: (customerPlan: CustomerPlanInfo) => {
                  return customerPlan.Customer.BillingProviderCustomers.some(
                    (c) =>
                      c.billing_provider ===
                      BillingProviderEnum_Enum.AwsMarketplace,
                  )
                    ? "Yes"
                    : "No";
                },
              },
            ]
          : []),
      ]}
      pageIndex={pageIndex}
      manualPagination={{
        pageCount: Math.ceil(numCustomers / PAGE_SIZE),
        onPageChanged: ({ newPageIndex }) => setPageIndex(newPageIndex),
        numItems: numCustomers,
      }}
      onSortChanged={(sortRules) => setCurrentSort(sortRules)}
      defaultSortBy={currentSort}
    />
  );
};
