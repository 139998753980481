import {
  BillableMetricsQuery,
  useBillableMetricsLazyQuery,
} from "pages/BillableMetrics/queries.graphql";
import React, { useEffect, useState } from "react";
import { useNavigate } from "lib/useNavigate";
import { Filter, FilterOptions, OptionType } from "components/Filter";
import {
  BillableMetricGroupKeyFilterEnum,
  BillableMetricSortingOrderByEnum,
  BillableMetricTypeEnum,
} from "types/generated-graphql/__types__";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useActions } from "lib/billableMetrics/actions";
import MetricBadge from "pages/BillableMetrics/components/MetricBadge";
import { AvatarWithName } from "tenaissance/components/Avatar";
import { GatedButton } from "components/GatedButton";
import { InsertBillableMetricDocument } from "pages/NewBillableMetric/queries.graphql";
import { ButtonGroup, ButtonProps } from "tenaissance/components/ButtonGroup";
import { Table } from "tenaissance/components/Table";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Badge } from "tenaissance/components/Badge";
import { dayjs } from "lib/date";
import { Timestamp } from "tenaissance/components/Timestamp";

interface MetricsTableProps extends React.PropsWithChildren {
  titleButtons: ButtonProps[];
}

const NUM_ROWS = 25;

const BillableMetrics: React.FC<MetricsTableProps> = (props) => {
  const navigate = useNavigate();

  const statusOptions: OptionType[] = [
    {
      label: "Active",
      value: BillableMetricTypeEnum.Active,
      group: "status",
      type: "multi",
    },
    {
      label: "Archived",
      value: BillableMetricTypeEnum.Archived,
      group: "status",
      type: "multi",
    },
  ];

  const groupKeyOptions: OptionType[] = [
    {
      label: "Billable Metrics with group keys",
      value: BillableMetricGroupKeyFilterEnum.NonEmpty,
      group: "group_key",
      type: "multi",
    },
    {
      label: "Billable Metrics without group keys",
      value: BillableMetricGroupKeyFilterEnum.Empty,
      group: "group_key",
      type: "multi",
    },
  ];

  const filterOptions: FilterOptions = {
    status: {
      label: "Billable Metric Status",
      options: statusOptions,
    },
    group_key: {
      label: "Group key",
      options: groupKeyOptions,
    },
  };

  const [filters, setFilters] = useState<readonly OptionType[]>([
    statusOptions[0],
    ...filterOptions.group_key.options,
  ]);

  const resetFilters = () => {
    setFilters([statusOptions[0], ...filterOptions.group_key.options]);
  };

  const [search, setSearch] = useState<string | undefined>();

  const [page, setPage] = useState(0);
  const [metrics, setMetrics] = useState<
    BillableMetricsQuery["billable_metrics"]
  >([]);
  const [cursor, setCursor] = useState<string>();
  const [noMore, setNoMore] = useState(false);

  const { environmentType } = useEnvironment();

  const groupKeyFilters = filters.filter((f) => f.group === "group_key");
  const [sortOrder, setSortOrder] = useState<
    | {
        id: string;
        desc: boolean;
      }
    | undefined
  >(undefined);

  const variables = {
    environment_type: environmentType,
    types: filters
      .filter((f) => f.group === "status")
      .map((f) => f.value as BillableMetricTypeEnum),
    search: search,
    limit: NUM_ROWS + 1,
    group_key_filter:
      groupKeyFilters.length === 1
        ? (groupKeyFilters[0].value as BillableMetricGroupKeyFilterEnum)
        : undefined,
    sort: sortOrder
      ? {
          // if you want to add support for sorting by other columns, you can add them here
          order_by: BillableMetricSortingOrderByEnum.CreatedAt,
          ascending: sortOrder.desc === false,
        }
      : undefined,
  };

  const [getBillableMetrics, { loading }] = useBillableMetricsLazyQuery({
    variables,
  });

  useEffect(() => {
    setMetrics([]);
    setPage(0);
    setCursor(undefined);
    void (async () => {
      const { data } = await getBillableMetrics({
        variables: { ...variables, search, cursor: undefined },
      });
      if (!data) {
        return;
      }
      setMetrics(data.billable_metrics);

      setCursor(
        data.billable_metrics.length
          ? data.billable_metrics[data.billable_metrics.length - 1].id
          : undefined,
      );
      setNoMore(data.billable_metrics.length < NUM_ROWS + 1);
    })();
  }, [search, filters, sortOrder]);

  const { getDropdownMenu, archiveModal, copyMetricModal } = useActions();

  const table = (
    <Table
      title={<ButtonGroup buttons={props.titleButtons} />}
      loading={loading}
      rowRoutePath={(row) => `/offering/billable-metrics/${row.id}`}
      sortOptions={{
        onSortingChange: (_updater) => {
          if (sortOrder === undefined) {
            setSortOrder({ id: "last_edited", desc: true });
          } else if (sortOrder.desc) {
            setSortOrder({ id: "last_edited", desc: false });
          } else {
            setSortOrder(undefined);
          }
        },
        sortOrder: sortOrder ? [sortOrder] : [],
      }}
      columns={[
        {
          id: "name",
          header: "Name",
          cell: (props) => props.getValue(),
          accessorFn: (m) => (
            <span>
              <MetricBadge deletedAt={m.deleted_at} />
              {m.name}
            </span>
          ),
          enableSorting: false,
        },
        {
          id: "aggregate",
          accessorKey: "aggregate",
          cell: ({ row }) => {
            const aggregate = row.original.aggregate;
            const sql = row.original.sql;

            const getBadgeLabel = () => {
              if (sql) {
                return "SQL";
              }
              switch (aggregate) {
                case "sum":
                  return "Sum";
                case "count":
                  return "Count";
                case "max":
                  return "Max";
                case "unique":
                  return "Unique";
                default:
                  return "";
              }
            };

            const badgeLabel = getBadgeLabel();

            const getBadgeTheme = () => {
              if (sql) {
                return "success";
              }
              switch (aggregate) {
                case "sum":
                  return "indigo";
                case "count":
                  return "deep-denim";
                case "max":
                  return "warning";
                case "unique":
                  return "midnight";
                default:
                  return undefined;
              }
            };

            const badgeTheme = getBadgeTheme();

            return <Badge label={badgeLabel} theme={badgeTheme} />;
          },
          header: "Aggregate",
          enableSorting: false,
        },
        {
          id: "user",
          header: "User",
          cell: (props) => props.getValue(),
          accessorFn: (m) => m.Creator && <AvatarWithName {...m.Creator} />,
          enableSorting: false,
        },
        {
          id: "last_edited",
          header: "Last edited (UTC)",
          cell: (props) => (
            <Timestamp dateTime={props.getValue()} dateOnly={true} />
          ),
          accessorFn: (m) => dayjs.utc(m.created_at).toDate(),
        },
        {
          id: "actions",
          header: "",
          cell: (props) => props.getValue(),
          accessorFn: (row) => (
            <div>{getDropdownMenu({ ...row, metricType: "billable" })}</div>
          ),
          enableSorting: false,
        },
      ]}
      data={metrics.slice(page * NUM_ROWS, (page + 1) * NUM_ROWS)}
      paginationOptions={{
        type: "prevNext",
        paginationButtons: [
          {
            page: "prev",
            onClick: () => {
              setPage(page - 1);
              setNoMore(false);
            },
            disabled: page === 0,
          },
          {
            page: "next",
            disabled: noMore,
            onClick: async () => {
              const numItemsForNextPage = (page + 2) * NUM_ROWS;
              if (numItemsForNextPage >= metrics.length) {
                const { data } = await getBillableMetrics({
                  variables: {
                    ...variables,
                    cursor,
                  },
                });
                if (!data) {
                  return;
                }
                const newMetrics = data.billable_metrics.filter(
                  (newMetric) =>
                    !metrics.some((oldMetric) => oldMetric.id === newMetric.id),
                );
                setMetrics([...metrics, ...newMetrics]);
                setNoMore(
                  metrics.length + newMetrics.length < numItemsForNextPage,
                );
                setCursor(
                  data.billable_metrics.length
                    ? data.billable_metrics[data.billable_metrics.length - 1].id
                    : undefined,
                );
              } else {
                setNoMore(metrics.length < numItemsForNextPage);
              }
              setPage(page + 1);
            },
          },
        ],
      }}
      topBarActions={[
        <div className="flex flex-row items-center" key={0}>
          <Filter
            value={filters}
            options={filterOptions}
            onChange={setFilters}
            onReset={resetFilters}
          />
          <GatedButton
            doc={InsertBillableMetricDocument}
            onClick={() => navigate("/offering/billable-metrics/new")}
            className="ml-12"
            text="Add"
            theme="primary"
            leadingIcon="plus"
            size="sm"
          />
        </div>,
      ]}
      emptyState={
        <EmptyState
          mainText={
            search ? "Billable metric not found" : "No billable metrics"
          }
          supportingText={
            !search
              ? "Billable metrics enable you to determine how events are filtered and aggregated."
              : undefined
          }
          icon="barLineChart"
        />
      }
      searchOptions={{
        showSearch: true,
        // not sure why but empty string and undefined are different when passed into the search query
        onSearch: (v) => setSearch(v.length ? v : undefined),
      }}
    />
  );

  return (
    <>
      {archiveModal}
      {copyMetricModal}
      {table}
    </>
  );
};

export default BillableMetrics;
