import React, { useState } from "react";
import { DefaultInput, TextInput } from "../Input";
import { dayjs } from "lib/dayjs";

export const TimeInput: React.FC<DefaultInput<Date | undefined>> = (props) => {
  const [inputState, setInputState] = useState<string>(
    props.value ? dayjs(props.value).format("hh:mm A UTC") : "",
  );
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  if (
    props.value &&
    inputState !== dayjs(props.value).format("hh:mm A UTC") &&
    !isInvalid
  ) {
    setInputState(props.value ? dayjs(props.value).format("hh:mm A UTC") : "");
  }

  const handleOnChange = ({ value: userValue }: { value: string }) => {
    if (!props.onChange || !props.value) return;

    const value = userValue.replace(/\D/g, "");
    let formattedValue = "";

    if (value.length >= 1) {
      formattedValue = value.substring(0, 2);
    }
    if (value.length >= 2) {
      formattedValue += ":" + value.substring(2, 4);
    }

    const amPmMatch = userValue.match(/(am|pm|AM|PM)/i);
    const amPmPart = amPmMatch ? amPmMatch[0].toUpperCase() : "";
    const formattedTime = formattedValue + (amPmPart ? " " + amPmPart : "");

    const finalTime = dayjs(
      dayjs(props.value).format("MM/DD/YYYY") + " " + formattedTime,
    );

    if (formattedTime.length > 7 && finalTime.isValid()) {
      setIsInvalid(false);
      props.onChange({ value: finalTime.toDate() });
    } else {
      setIsInvalid(true);
    }
    setInputState(formattedTime);
  };

  return (
    <TextInput
      size="sm"
      placeholder="HH:MM AM"
      onChange={handleOnChange}
      value={inputState}
      disableClear={true}
      disabled={props.disabled}
      isInvalid={isInvalid}
    />
  );
};
