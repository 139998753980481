import React from "react";
import { Popup } from "components/Popup";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import {
  useArchiveBillableMetricMutation,
  useArchiveSeatMetricMutation,
} from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";
import { MetricType } from "lib/billableMetrics/types";

interface ArchiveBillableMetricModalProps {
  onClose: () => void;
  billableMetricId: string;
  billableMetricName: string;
  metricType: MetricType;
}

const ArchiveBillableMetricModal: React.FC<ArchiveBillableMetricModalProps> = ({
  onClose,
  billableMetricId,
  billableMetricName,
  metricType,
}) => {
  const [archiveBillableMetric, archiveBillableMetricResults] =
    useArchiveBillableMetricMutation({
      update(cache) {
        // billable_metrics is queried in some places looking for only active
        // metrics. When marking a metric as archived, we need to evict the
        // billable_metrics cache so that list gets updated.
        cache.evict({
          fieldName: "billable_metrics",
        });
      },
    });

  const [archiveSeatMetric, archiveSeatMetricResults] =
    useArchiveSeatMetricMutation({
      update(cache) {
        // seat_metrics is queried in some places looking for only active
        // metrics. When marking a metric as archived, we need to evict the
        // seat_metrics cache so that list gets updated.
        cache.evict({
          fieldName: "seat_metrics",
        });
      },
    });

  const loading =
    archiveBillableMetricResults.loading || archiveSeatMetricResults.loading;

  const pushMessage = useSnackbar();

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            key="primary"
            loading={loading}
            disabled={loading}
            onClick={async () => {
              try {
                if (metricType === "billable") {
                  await archiveBillableMetric({
                    variables: {
                      billable_metric_id: billableMetricId,
                    },
                  });
                } else {
                  await archiveSeatMetric({
                    variables: {
                      seat_metric_id: billableMetricId,
                    },
                  });
                }
              } catch (e: any) {
                pushMessage({
                  content: `Failed to archive billable metric: ${e.message}`,
                  type: "error",
                });
              }
              onClose();
            }}
            text="Archive metric"
            theme="linkDestructive"
          />
        </>
      }
      isOpen
      onRequestClose={onClose}
      title="Are you sure you want to archive this metric?"
    >
      <Body level={2}>
        Are you sure you want to archive the{" "}
        <strong>{billableMetricName}</strong> billable metric? Once a billable
        metric is archived it can no longer be used, and will no longer appear
        on the overview by default.
      </Body>
    </Popup>
  );
};

export default ArchiveBillableMetricModal;
