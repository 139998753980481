import React from "react";
import { Badge } from "design-system";

import { TextSkeleton } from "components/Skeleton";
import { TinyErrorState } from "lib/errors/TinyErrorState";
import { StatusPills } from "components/Invoice/components/StatusPills";

import {
  StatusPillsFieldsFragment,
  StatusPillsFieldsFragmentDoc,
} from "./fragments.graphql";
import { useInfoFromInvoicesById } from "./useInfoFromInvoicesById";

export function useInvoiceStatusPillsForId(ids: string[]) {
  const invoiceStatusPillInfo =
    useInfoFromInvoicesById<StatusPillsFieldsFragment>(
      ids,
      StatusPillsFieldsFragmentDoc,
      "StatusPillsFields",
    );

  return React.useCallback(
    function invoiceStatusPillsForId(id: string): React.ReactElement {
      if (invoiceStatusPillInfo.type === "loading") {
        return <TextSkeleton className="!m-0 inline-block !w-[70px]" />;
      }

      if (invoiceStatusPillInfo.type === "error") {
        if (
          invoiceStatusPillInfo.error instanceof Error &&
          invoiceStatusPillInfo.error.message.includes("Invoice not found")
        ) {
          // most likely invoice is yet to be created by heartbeat or it's a future true up invoice
          return <> </>;
        }
        const err = invoiceStatusPillInfo.error;
        return (
          <TinyErrorState
            message={`Failed to load the invoice status${
              err instanceof Error ? `: ${err.message}` : ""
            }`}
          />
        );
      }

      const info = invoiceStatusPillInfo.data.get(id);
      if (!info) {
        return (
          <Badge theme="warning" type="light">
            UNKNOWN
          </Badge>
        );
      }

      return <StatusPills invoice={info} compact light />;
    },
    [invoiceStatusPillInfo],
  );
}
