import { dayjs } from "lib/dayjs";
import { ApolloError } from "@apollo/client";
import { CreditType } from "types/credit-types";
import {
  GetAllCreditGrantsAndLedgersQuery,
  useGetAllCreditGrantsAndLedgersQuery,
} from "types/generated-graphql/src/pages/Customer/tabs/Credits/data/credit-overview.graphql";
import Decimal from "decimal.js";
import {
  IssuedCreditGrant,
  parseCreditGrant,
  parseLedger,
} from "pages/Customer/tabs/Credits";

type GraphqlLedger = NonNullable<
  GetAllCreditGrantsAndLedgersQuery["customer"]
>["ledgers"][0];

type LedgerEntry = {
  __typename: NonNullable<
    GetAllCreditGrantsAndLedgersQuery["customer"]
  >["ledgers"][0]["ledger_entries"][0]["__typename"];
  creditType: CreditType;
  grant: {
    id: string;
    name: string;
    costBasis: string;
    AmountPaidCreditType: CreditType;
    voidedAt: string | null;
    Voider: {
      id: string | null;
      name: string | null;
    } | null;
  };
  amount: string;
  runningBalance: string;
  effectiveAt: Date;
  memo: string;
};

type Ledger = {
  creditType: CreditType;
  availableBalance: Decimal;
  consumed: Decimal;
  expired: Decimal;
  totalGranted: Decimal;
  ledgerEntries: LedgerEntry[];
};

type UseCreditGrantsArgs = {
  /**
   * Get credit grants by customer
   */
  customerId: string;
};

type CreditGrantsData = {
  customer?: GetAllCreditGrantsAndLedgersQuery["customer"];
  creditTypesById: { [k: string]: CreditType };
  ledgersByCreditTypeId: { [k: string]: GraphqlLedger };
  formattedLedgersByCreditTypeId: { [k: string]: Ledger };
  creditGrantsById: { [k: string]: IssuedCreditGrant };
};

type UseCreditGrantsResult = {
  data: CreditGrantsData;
  loading: boolean;
  error: ApolloError | undefined;
};

/**
 * Fetch credit grants and ledgers, then return CreditGrantsData
 */
export function useCreditGrants({
  customerId,
}: UseCreditGrantsArgs): UseCreditGrantsResult {
  const { data, loading, error } = useGetAllCreditGrantsAndLedgersQuery({
    variables: { customer_id: customerId },
  });

  const creditTypesById: { [k: string]: CreditType } = {};
  const ledgersByCreditTypeId: { [k: string]: GraphqlLedger } = {};
  const formattedLedgersByCreditTypeId: { [k: string]: Ledger } = {};
  const creditGrantsById: { [k: string]: IssuedCreditGrant } = {};

  // When calculating the available balance for a ledger we do not want to include
  // credit grants that start in later billing periods. availableBalanceCutoffDate
  // will either be the end of the current billing period or the end of today
  // depending on whichever is later
  const availableBalanceCutoffDate = (
    data?.customer?.active_invoices.invoices ?? []
  ).reduce((latestEndDate, invoice) => {
    if (invoice.__typename !== "ArrearsInvoice") {
      return latestEndDate;
    }
    const invoiceEndDate = new Date(invoice.exclusive_end_date);
    return latestEndDate.valueOf() > invoiceEndDate.valueOf()
      ? latestEndDate
      : invoiceEndDate;
  }, dayjs.utc().endOf("day").toDate());

  if (!loading && data && data.customer && data.customer.ledgers.length > 0) {
    data.customer.ledgers.forEach((ledger) => {
      creditTypesById[ledger.credit_type.id] = ledger.credit_type;
      ledgersByCreditTypeId[ledger.credit_type.id] = ledger;
      formattedLedgersByCreditTypeId[ledger.credit_type.id] = parseLedger(
        ledger,
        availableBalanceCutoffDate,
      );
    });
    data.customer.CreditGrants.forEach((cg) => {
      creditGrantsById[cg.id] = parseCreditGrant(
        cg,
        ledgersByCreditTypeId[cg.AmountGrantedCreditType.id],
        availableBalanceCutoffDate,
      );
    });
  }

  return {
    data: {
      customer: data?.customer,
      creditTypesById: creditTypesById,
      ledgersByCreditTypeId: ledgersByCreditTypeId,
      formattedLedgersByCreditTypeId: formattedLedgersByCreditTypeId,
      creditGrantsById: creditGrantsById,
    },
    error: error,
    loading: loading,
  };
}
