import React from "react";
import Decimal from "decimal.js";
import { RecurringSchedule } from "@metronome-industries/schedule-utils";

import { Schema } from "../../Schema";
import { RoundedCurrency } from "lib/credits";
import { EmptyState } from "tenaissance/components/EmptyState";
import { printDate, toDayjs } from "lib/date";
import { TablePanel } from "components/TablePanel";
import { ColWidths } from "pages/Contracts/lib/ColWidths";
import { useMode } from "./mode";
import { CreditType } from "types/credit-types";
import { Quantity } from "components/Invoice/components/LineItem/Quantity";

type Action = { type: "UPDATE"; value?: Schema.Types.BillingSchedule };
type State = {
  invalid: boolean;
  items?: Array<{
    date: Date;
    unitPrice: Decimal;
    quantity: Decimal;
    amount: Decimal;
  }>;
};
const initialState: State = { invalid: false };

function Reducer(state: State, action: Action): State {
  switch (action.type) {
    case "UPDATE":
      if (!action.value) {
        return { invalid: true, items: state.items };
      }

      if (action.value.type === "fixed") {
        return {
          invalid: false,
          items: action.value.items.map((item) => {
            const quantity = new Decimal(item.quantity);
            const unitPrice = new Decimal(item.unitPrice);
            return {
              date: new Date(item.date),
              quantity,
              unitPrice,
              amount: unitPrice.times(quantity),
            };
          }),
        };
      }

      try {
        return {
          invalid: false,
          items: RecurringSchedule.resolve({
            startDate: new Date(action.value.startDate),
            endDate: new Date(action.value.endDate),
            frequency: action.value.frequency,
            amountDistribution: action.value.amountDistribution,
            quantity: new Decimal(action.value.quantity),
            unitPrice: new Decimal(action.value.unitPrice),
          }).items,
        };
      } catch {
        return { invalid: true, items: state.items };
      }
  }
}

export const InvoicePreview: React.FC<{
  schedule?: Schema.Types.BillingSchedule;
  creditType: CreditType;
}> = ({ schedule, creditType }) => {
  const mode = useMode();
  const title = mode === "invoice" ? "Invoice preview" : "Discount preview";

  const [{ invalid, items }, dispatch] = React.useReducer(
    Reducer,
    initialState,
  );

  React.useEffect(() => {
    dispatch({ type: "UPDATE", value: schedule });
  }, [schedule]);

  if (!items) {
    return null;
  }

  return (
    <TablePanel
      title={title}
      data={items}
      className={invalid ? "opacity-50" : undefined}
      emptyState={
        <EmptyState
          mainText="No invoices will be produced"
          icon="file05"
        ></EmptyState>
      }
      columns={[
        {
          id: "date",
          header: "Date",
          render: (row) => printDate(toDayjs(row.date)),
        },
        {
          id: "quantity",
          header: "Quantity",
          align: "right",
          cellClassName:
            "w-[100px] max-w-[100px] overflow-hidden overflow-ellipsis",
          render: (row) => <Quantity quantity={row.quantity} />,
        },
        {
          id: "unitPrice",
          header: "Unit price",
          align: "right",
          cellClassName: ColWidths.CREDITS,
          render: (row) => (
            <RoundedCurrency
              amount={
                mode === "invoice" ? row.unitPrice : row.unitPrice.negated()
              }
              creditType={creditType}
            />
          ),
        },
        {
          id: "amount",
          header: "Amount",
          align: "right",
          cellClassName: ColWidths.CREDITS,
          render: (row) => (
            <RoundedCurrency
              amount={mode === "invoice" ? row.amount : row.amount.negated()}
              creditType={creditType}
            />
          ),
        },
      ]}
    />
  );
};
