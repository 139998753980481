import React from "react";
import { NetSuiteFields } from "./NetSuiteFields";

export const ProfessionalServiceProductFields: React.FC = () => {
  return (
    <div className="mt-[24px] flex flex-col gap-[24px]">
      <NetSuiteFields />
    </div>
  );
};
