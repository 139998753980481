import React from "react";
import { useInRouterContext, Link } from "react-router-dom";

import { twMerge } from "../../twMerge";
import { useDesignSystemConfig } from "../../config";
import { Icon, IconName } from "../Icon";
import { LoadingSpinner } from "../LoadingSpinner";

export type Deprecated_ButtonThemes = "primary" | "gray" | "error";
type Deprecated_ButtonType = "fill" | "outline" | "link";
interface Deprecated_ButtonProps {
  icon?: IconName;
  iconMirror?: "horizontal" | "vertical";
  iconEdge?: "leading" | "trailing";
  theme: Deprecated_ButtonThemes;
  type: Deprecated_ButtonType;
  size?: "small" | "regular";
  /**
   * If you specify a routePath then the button will be rendered as an <a> tag
   * so that it can be clicked to navigate and supports the standard browser
   * behavior of opening in a new tab, etc.
   */
  routePath?: string;
  /**
   * If this button is for submitting a form, set this to true so that the
   * button will be rendered as a <button type="submit">. Ignored if href
   * is specified.
   */
  isSubmit?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  children?: React.ReactNode;
  ["data-testid"]?: string;
}

export const Deprecated_Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  Deprecated_ButtonProps
>(
  (
    {
      icon,
      iconMirror,
      iconEdge = "leading",
      onClick,
      routePath,
      children,
      theme,
      type,
      className,
      size = "regular",
      disabled = false,
      loading = false,
      isSubmit = false,
      "data-testid": dataTestId,
    },
    ref,
  ) => {
    const { routePathToHref } = useDesignSystemConfig();
    const inRouterContext = useInRouterContext();
    const i =
      icon && loading ? (
        <LoadingSpinner
          overrideColor={true}
          className="h-[10px] w-[10px] border-[currentColor]"
        />
      ) : icon ? (
        <Icon
          className={
            size === "small"
              ? "h-[10px] w-[10px] fill-[currentColor]"
              : "h-[16px] w-[16px] fill-[currentColor]"
          }
          icon={icon}
          mirror={iconMirror}
        />
      ) : null;
    const label = children ? (
      <span
        className={[
          "text-xxs font-bold uppercase",
          size === "small" ? "leading-3" : "leading-4",
        ]
          .filter((s) => !!s)
          .join(" ")}
      >
        {children}
        {loading ? "..." : null}
      </span>
    ) : null;

    const commonProps = {
      onClick: (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
      ) => {
        if (disabled) {
          event.preventDefault();
          return;
        }

        if (onClick) {
          return onClick(event);
        }
      },
      disabled,
      className: twMerge(
        "cursor-pointer font-default inline-flex items-center justify-center rounded-medium shrink-0 whitespace-nowrap",

        type === "fill" && [
          "text-white fill-white",

          disabled && [
            theme !== "gray"
              ? "bg-deprecated-gray-light focus-visible:bg-deprecated-gray-light hover:bg-deprecated-gray-light"
              : "bg-gray-50 focus-visible:bg-gray-50 hover:bg-gray-50 text-gray-200 fill-gray-200",
          ],

          !disabled && [
            theme === "primary" && [
              // background
              "bg-deprecated-primary-medium hover:bg-deprecated-primary-dark focus-visible:bg-deprecated-primary-dark active:bg-deprecated-primary-medium",
              // outline
              "focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-deprecated-primary-light",
            ],

            theme === "gray" && [
              // text
              "text-gray-500 fill-gray-600",
              // background
              "bg-gray-50 hover:bg-deprecated-gray-100 focus-visible:bg-deprecated-gray-100 active:bg-deprecated-gray-100",
              // outline
              "focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-deprecated-gray-dark",
            ],

            theme === "error" && [
              // background
              "bg-deprecated-red-dark hover:bg-deprecated-red-darkest focus-visible:bg-deprecated-red-darkest active:bg-deprecated-red-dark",
              // outline
              "focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-deprecated-red-lightest",
            ],
          ],
        ],

        type === "outline" && [
          disabled &&
            "text-deprecated-gray-light dark:text-deprecated-gray-medium border border-deprecated-gray-light dark:border-deprecated-gray-darkest",

          !disabled && [
            theme === "primary" && [
              // border
              "border border-deprecated-primary-medium active:border-deprecated-primary-dark",
              // background
              "bg-transparent hover:bg-deprecated-primary-medium focus-visible:bg-deprecated-primary-medium active:bg-deprecated-primary-dark",
              // text
              "text-deprecated-primary-medium hover:text-white focus-visible:text-white active:text-white",
              // fill
              "fill-deprecated-primary-medium focus-visible:fill-white hover:fill-white active:fill-white",
              // outline
              "focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-deprecated-primary-light",
            ],

            theme === "gray" && [
              // border
              "border border-deprecated-gray-medium active:border-deprecated-gray-dark",
              // background
              "hover:bg-deprecated-gray-medium focus-visible:bg-deprecated-gray-medium active:bg-deprecated-gray-dark",
              // text
              "text-deprecated-gray-medium hover:text-white focus-visible:text-white",
              // fill
              "fill-deprecated-gray-medium hover:fill-white focus-visible:fill-white",
              // outline
              "focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-deprecated-gray-light",
            ],

            theme === "error" && [
              // border
              "border border-deprecated-red-medium active:border-deprecated-red-dark",
              // background
              "hover:bg-deprecated-red-medium focus-visible:bg-deprecated-red-medium active:bg-deprecated-red-dark",
              // text
              "text-deprecated-red-medium hover:text-white focus-visible:text-white",
              // fill
              "fill-deprecated-red-medium hover:fill-white focus-visible:fill-white",
              // outline
              "focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-deprecated-red-light",
            ],
          ],
        ],

        type === "link" && [
          disabled &&
            "text-deprecated-gray-light dark:text-deprecated-gray-medium",

          !disabled && [
            theme === "primary" && [
              // background
              "hover:bg-deprecated-primary-lightest focus-visible:bg-deprecated-primary-lightest active:bg-deprecated-primary-lightest",
              // text
              "text-deprecated-primary-medium active:text-deprecated-primary-dark",
              // outline
              "focus-visible:outline-0",
            ],

            theme === "gray" && [
              // background
              "hover:bg-deprecated-gray-lightest focus-visible:bg-deprecated-gray-lightest active:bg-deprecated-gray-lightest",
              // text
              "text-deprecated-gray-medium active:text-deprecated-gray-dark",
              // outline
              "focus-visible:outline-0",
            ],

            theme === "error" && [
              // background
              "hover:bg-deprecated-red-lightest focus-visible:bg-deprecated-red-lightest active:bg-deprecated-red-lightest",
              // text
              "text-deprecated-red-medium active:text-deprecated-red-dark",
              // outline
              "focus-visible:outline-0",
            ],
          ],
        ],

        disabled && "cursor-default",
        size === "regular" && ["h-32 px-[16px] gap-8", !label && "p-0 w-32"],
        size === "small" && ["h-24 px-4 min-w-[24px] gap-4", !label && "p-0"],
        className,
      ),
      "data-testid": dataTestId,
    };

    const content = (
      <>
        {iconEdge === "leading" ? i : null}
        {label}
        {iconEdge === "trailing" ? i : null}
      </>
    );

    if (routePath && inRouterContext) {
      return (
        <Link
          {...commonProps}
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
          to={routePathToHref(routePath)}
        >
          {content}
        </Link>
      );
    }

    if (routePath) {
      return (
        <a
          {...commonProps}
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
          href={routePathToHref(routePath)}
        >
          {content}
        </a>
      );
    }

    return (
      <button
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        {...commonProps}
        type={isSubmit ? "submit" : "button"}
      >
        {content}
      </button>
    );
  },
);
