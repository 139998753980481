import React from "react";
import {
  CommitFlyoverController,
  PostpaidCommitAccessScheduleController,
  PostpaidCommitBillingScheduleController,
  PostpaidCommitTermsController,
  usePostpaidCommitAccessScheduleController,
  usePostpaidCommitAccessScheduleItemController,
  usePostpaidCommitBillingScheduleController,
  usePostpaidCommitBillingScheduleItemController,
  usePostpaidCommitTermsController,
} from ".";
import { CreditInput } from "components/Input";
import { USD_CREDIT_TYPE } from "lib/credits";
import { NumericInput, DateInput, Subtitle, Select } from "design-system";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import Decimal from "decimal.js";

export const PostpaidCommitAccessScheduleItem: React.FC<{
  id: string;
  parent: PostpaidCommitAccessScheduleController;
  amount: number | undefined;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitAccessScheduleItemController(
    props.parent,
    props.id,
    timeframe,
    props.amount,
  );
  return (
    <>
      <DateInput
        {...ctrl.props.DateInput("date", {
          name: "Starting at",
        })}
      />
      <DateInput
        {...ctrl.props.DateInput("endDate", {
          name: "Ending before",
          minDate: ctrl.get("date"),
        })}
      />
      <div className="flex gap-12">
        <div className="w-full">
          <CreditInput
            {...ctrl.props.CreditInput("amount", {
              placeholder: "123.45",
              name: "Amount",
              creditType: USD_CREDIT_TYPE,
              tooltip: "Must match total amount in billing schedule.",
              disabled: true,
            })}
          />
        </div>
      </div>
    </>
  );
};

export const PostpaidCommitBillingScheduleItem: React.FC<{
  id: string;
  parent: PostpaidCommitBillingScheduleController;
  accessScheduleEnd: string | null;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitBillingScheduleItemController(
    props.parent,
    props.id,
    timeframe,
    props.accessScheduleEnd,
  );
  return (
    <div className="col-span-3 grid grid-cols-3 gap-12">
      <DateInput
        {...ctrl.props.DateInput("date", {
          name: "Invoice at",
          minDate: props.accessScheduleEnd ?? undefined,
          tooltip:
            "The date of the true up invoice. Must be at or after access schedule end date.",
        })}
      />
      <NumericInput
        {...ctrl.props.NumericInput("quantity", {
          name: "Quantity",
          placeholder: "1",
        })}
      />

      <div className="flex gap-12">
        <div className="grow">
          <CreditInput
            {...ctrl.props.CreditInput("unitPrice", {
              name: "Unit price",
              placeholder: "123.45",
              creditType: USD_CREDIT_TYPE,
              allowZeroAmounts: false,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export const PostpaidCommitAccessSchedule: React.FC<{
  parent: PostpaidCommitTermsController;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitAccessScheduleController(
    props.parent,
    timeframe,
  );
  const accessSchedule = ctrl.get("accessSchedule");
  const billingCtrl = usePostpaidCommitBillingScheduleController(
    props.parent,
    timeframe,
  );
  const billingSchedule = billingCtrl.get("billingSchedule");
  return (
    <div className="flex flex-col gap-12">
      <Subtitle level={4}>Access schedule</Subtitle>
      <div className="grid grid-cols-3 gap-12">
        {accessSchedule && accessSchedule.length && (
          <PostpaidCommitAccessScheduleItem
            id={accessSchedule[0].id}
            parent={ctrl}
            amount={
              billingSchedule
                ? new Decimal(billingSchedule[0].quantity ?? 0)
                    .mul(billingSchedule[0].unitPrice ?? 0)
                    .toNumber()
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
};

export const PostpaidCommitBillingSchedule: React.FC<{
  parent: PostpaidCommitTermsController;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitBillingScheduleController(
    props.parent,
    timeframe,
  );
  const billingSchedule = ctrl.get("billingSchedule");
  const accessCtrl = usePostpaidCommitAccessScheduleController(
    props.parent,
    timeframe,
  );
  const accessSchedule = accessCtrl.get("accessSchedule");
  return (
    <div className="flex flex-col gap-12">
      <Subtitle level={4}>Billing schedule</Subtitle>
      <div className="grid grid-cols-3 gap-12">
        {billingSchedule && billingSchedule.length && (
          <PostpaidCommitBillingScheduleItem
            id={billingSchedule[0].id}
            parent={ctrl}
            accessScheduleEnd={
              accessSchedule ? accessSchedule[0].endDate : null
            }
          />
        )}
      </div>
    </div>
  );
};

export const PostpaidCommitTerms: React.FC<{
  parent: CommitFlyoverController;
  level: "contract" | "customer";
  contracts?: { id: string; name?: string | null }[];
}> = (props) => {
  const ctrl = usePostpaidCommitTermsController(props.parent, props.level);
  return (
    <div className="flex flex-col gap-[32px]">
      <div>
        <PostpaidCommitAccessSchedule parent={ctrl} />
      </div>
      <div>
        <PostpaidCommitBillingSchedule parent={ctrl} />
      </div>
      {props.level === "customer" && (
        <Select
          {...ctrl.props.Select("invoiceContractId", {
            name: "Invoice contract",
            placeholder: "Select",
            tooltip:
              "Select the contract that will be used to issue a true up invoice for this commit.",
            loading: !props.contracts,
            options:
              props.contracts?.map((c) => ({
                value: c.id,
                label: c.name ?? c.id,
              })) ?? [],
          })}
        />
      )}
      <div className="flex flex-col gap-12">
        <Subtitle>Priority</Subtitle>
        <NumericInput
          {...ctrl.props.NumericInput("priority", {
            name: "Priority",
            tooltip:
              "Dictates the order that commits are applied. Commits with a lower priority will be applied first.",
            placeholder: "100",
          })}
        />
      </div>
      {props.level === "contract" && (
        <div className="flex flex-col gap-12">
          <Subtitle>Rollover terms</Subtitle>
          <div className="grid grid-cols-3 gap-12">
            <NumericInput
              {...ctrl.props.NumericInput("rolloverFraction", {
                name: "Rollover amount (optional)",
                tooltip:
                  "If this contract is renewed, up to this percent of the total commitment will roll over to the new contract.",
                placeholder: "0.0",
                suffix: "%",
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};
