import React from "react";
import classnames from "classnames";
import styles from "./index.module.less";
import { Tooltip, Icon, IconName } from "design-system";
import { Caption, Subtitle } from "design-system";
import { MetronomeLogo } from "tenaissance/components/MetronomeLogo";
import { InternalLink } from "components/Typography";

export type CurrentStep = {
  section: number;
  group: number;
  subStep: number;
};

export type WizardSection = {
  title: string;
  tooltip?: string;
  isDone: boolean;
  subStepGroups: {
    title?: string;
    tooltip?: string;
    subSteps: WizardSubStep[];
    isDone: boolean;
  }[];
  icon?: IconName;
};

export type WizardSubStep = {
  header: string;
  component: React.ReactNode;
  title?: string;
  tooltip?: string;
  isDone: boolean;
  isNavigable?: boolean;
  skipIfDone?: boolean; // check whether this substep is done when advancing, and if it is, skip it
  navigationKey?: string;
};

interface SidebarSectionProps extends React.PropsWithChildren {
  title: string;
  tooltip?: string;
  isSelected: boolean;
  isCompleted: boolean;
  onClick?: () => void;
  isEmpty: boolean;
  icon?: IconName;
}
const SidebarSection: React.FC<SidebarSectionProps> = (props) => {
  const element = (
    <div
      className={classnames(styles.sidebarSection, {
        [styles.selected]: props.isSelected,
        [styles.completed]: props.isCompleted,
        [styles.disabled]: !props.onClick,
      })}
    >
      <Subtitle
        level={3}
        className={classnames({
          [styles.completed]: props.isCompleted,
        })}
      >
        <a onClick={props.onClick}>
          {props.icon ? (
            <Icon
              icon={props.icon}
              className={classnames(styles.customIcon, styles.icon)}
            />
          ) : (
            <Icon icon="checkmarkCircle" className={styles.icon} />
          )}
          {props.title}
        </a>
      </Subtitle>
      {props.isSelected && props.children}
    </div>
  );
  return !props.onClick && !props.isSelected && !props.isEmpty ? (
    <Tooltip content="Complete the previous steps first">{element}</Tooltip>
  ) : (
    element
  );
};

interface SidebarGroupProps extends React.PropsWithChildren {
  isSelected: boolean;
  title?: string;
  tooltip?: string;
  onClick?: () => void;
}
const SidebarGroup: React.FC<SidebarGroupProps> = (props) => {
  return (
    <div className={styles.sidebarGroup}>
      {props.title && (
        <Subtitle
          level={3}
          className={classnames(styles.sidebarGroupLabel, {
            [styles.disabled]: !props.onClick,
            [styles.selected]: props.isSelected,
          })}
        >
          {props.tooltip ? (
            <Tooltip content={props.tooltip}>
              <a onClick={props.onClick}>{props.title}</a>
            </Tooltip>
          ) : (
            <a onClick={props.onClick}>{props.title}</a>
          )}
        </Subtitle>
      )}
      {props.isSelected && props.children}
    </div>
  );
};

interface SidebarSubStepProps extends React.PropsWithChildren {
  name: string;
  tooltip?: string;
  isSelected: boolean;
  onClick?: () => void;
}
const SidebarSubStep: React.FC<SidebarSubStepProps> = (props) => {
  const element = (
    <Subtitle
      level={3}
      className={classnames(styles.subStepTitle, {
        [styles.disabled]: !props.onClick,
        [styles.selected]: props.isSelected,
      })}
    >
      <a onClick={props.onClick} className={styles.link}>
        <Icon icon="ellipseOutline" className={styles.icon} />
        {props.tooltip ? (
          <Tooltip content={props.tooltip}>
            <span className={styles.label}>{props.name}</span>
          </Tooltip>
        ) : (
          <span className={styles.label}>{props.name}</span>
        )}
      </a>
    </Subtitle>
  );

  return !props.onClick && !props.isSelected ? (
    <Tooltip content="Complete the previous steps first">{element}</Tooltip>
  ) : (
    element
  );
};

interface WizardSidebarProps extends React.PropsWithChildren {
  sections: WizardSection[];
  currentStep: CurrentStep;
  setCurrentStep: (currStep: CurrentStep) => void;
}

export const WizardSidebar: React.FC<WizardSidebarProps> = (props) => {
  return (
    <div className={styles.sidebar}>
      <InternalLink routePath="/" className={styles.logo}>
        <MetronomeLogo theme="greyscale" badgeOnly />
      </InternalLink>
      <Caption className={styles.yourProgress}>Your progress</Caption>
      {props.sections.map((section, i) => {
        return (
          <SidebarSection
            key={i}
            title={section.title}
            tooltip={section.tooltip}
            isSelected={props.currentStep.section === i}
            isCompleted={section.isDone}
            isEmpty={props.sections[i].subStepGroups.length === 0}
            onClick={
              i === 0 || props.sections[i - 1].isDone
                ? () => {
                    props.setCurrentStep({ section: i, group: 0, subStep: 0 });
                  }
                : undefined
            }
            icon={section.icon}
          >
            {section.subStepGroups.map((group, j) => {
              return (
                <SidebarGroup
                  key={j}
                  title={group.title}
                  tooltip={group.tooltip}
                  isSelected={
                    props.currentStep.section === i &&
                    props.currentStep.group === j
                  }
                  onClick={
                    group.title &&
                    group.subSteps.length &&
                    ((i === 0 && j === 0) ||
                      (i > 0 && j === 0 && props.sections[i - 1].isDone) ||
                      (j > 0 && props.sections[i].subStepGroups[j - 1].isDone))
                      ? () =>
                          props.setCurrentStep({
                            section: i,
                            group: j,
                            subStep: 0,
                          })
                      : undefined
                  }
                >
                  {group.subSteps.map((subStep, k) => {
                    return (
                      subStep.title && (
                        <SidebarSubStep
                          key={k}
                          name={subStep.title}
                          tooltip={subStep.tooltip}
                          isSelected={
                            props.currentStep.section === i &&
                            props.currentStep.group === j &&
                            props.currentStep.subStep === k
                          }
                          onClick={
                            subStep.isNavigable ||
                            (i === 0 && j === 0 && k === 0) ||
                            (i > 0 &&
                              j === 0 &&
                              k === 0 &&
                              props.sections[i - 1].isDone) ||
                            (j > 0 &&
                              k === 0 &&
                              props.sections[i].subStepGroups[j - 1].isDone) ||
                            (k > 0 &&
                              props.sections[i].subStepGroups[j].subSteps[k - 1]
                                .isDone)
                              ? () =>
                                  props.setCurrentStep({
                                    section: i,
                                    group: j,
                                    subStep: k,
                                  })
                              : undefined
                          }
                        />
                      )
                    );
                  })}
                </SidebarGroup>
              );
            })}
          </SidebarSection>
        );
      })}
    </div>
  );
};
