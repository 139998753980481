import React from "react";

import { Breadcrumbs } from "lib/breadcrumbs";
import { useCustomerFromRoute } from "../../lib/Customer";
import { CustomerLayout } from "../CustomerLayout";
import { Usage } from "pages/Customer/tabs/Usage";

export const CustomerUsage: React.FC = () => {
  const customerReq = useCustomerFromRoute();

  return (
    <CustomerLayout
      rootReq={customerReq}
      breadcrumbs={({ customer }) =>
        Breadcrumbs.from(
          {
            type: "back",
            label: "Back to customer list",
            routePath: "/customers",
          },
          {
            label: customer.name,
            routePath: `/customers/${customer.id}`,
          },
        )
      }
      content={
        customerReq.state === "success" && (
          <div className="flex h-full flex-col pt-12">
            <Usage />
          </div>
        )
      }
    />
  );
};
