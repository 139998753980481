import React, { useEffect, useState } from "react";
import styles from "./index.module.less";
import {
  AddCustomFieldKeysDocument,
  useDeleteCustomFieldKeyMutation,
  useGetCustomFieldKeysQuery,
} from "../../queries.graphql";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Body, Headline, Select, Subtitle, Tooltip } from "design-system";
import { Button } from "tenaissance/components/Button";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { TabProps } from "../../../../components/Tabs";
import { PageContainer } from "../../../../components/PageContainer";
import { CustomFieldsTable } from "../CustomFieldsTable";
import {
  CreateOrEditCustomFieldModal,
  EditableCustomFieldKey,
} from "../CreateOrEditCustomFieldModal";
import { useSnackbar } from "components/Snackbar";
import { getUserFacingErrorMessage } from "lib/errors/errorHandling";
import { GatedButton } from "components/GatedButton";
import { useFeatureFlag } from "lib/launchdarkly";
import { INVOICING_TAB_FEATURE_FLAG, TabFilter } from "pages/GeneralSettings";
import { Popup } from "components/Popup";
import classnames from "classnames";
import { useApolloClient } from "@apollo/client";
import { downloadCSV } from "lib/reports";
import {
  chargeCustomFieldsReport,
  creditGrantCustomFieldsReport,
  customerCustomFieldsReport,
  productCustomFieldsReport,
} from "lib/reports/reportTypes/customFieldReports";
import { ReportConfig } from "lib/reports/reportTypes/reportConfig";
import { useUIMode } from "../../../../lib/useUIMode";
import NewCustomFields from "./NewCustomFields";

interface CustomFieldsTabProps {
  tabs: TabProps[];
}

const CustomFieldsTab: React.FC<CustomFieldsTabProps> = ({ tabs }) => {
  const { newUIEnabled } = useUIMode();
  const { environmentType } = useEnvironment();
  const { data, loading, error } = useGetCustomFieldKeysQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const [deleteCustomFieldKeyMutation] = useDeleteCustomFieldKeyMutation({
    update(cache) {
      cache.evict({
        fieldName: "ManagedFieldKey",
      });
    },
  });
  const [customFieldModalState, setCustomFieldModalState] = useState<{
    open: boolean;
    mode: "create" | "edit";
    activeItem?: EditableCustomFieldKey;
  }>({ open: false, mode: "create" });
  const [exportFieldValuesModalState, setExportFieldValuesModalState] =
    useState<{
      open: boolean;
    }>({ open: false });
  const pushMessage = useSnackbar();

  const invoicingEnabled =
    useFeatureFlag(INVOICING_TAB_FEATURE_FLAG, false) || false;

  const content = (
    <>
      {error ? (
        <EmptyState
          icon="layersThree02"
          mainText="We ran into an issue loading this page"
          supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
        />
      ) : loading ? (
        <div id={styles.spinner}></div>
      ) : (
        <div className={styles.customFields}>
          <div className="flex w-full flex-row items-end justify-between">
            <div className="flex-grow">
              <Headline className={styles.name} level={6}>
                Your custom fields
              </Headline>
              <Body level={2} className={styles.maxWidth}>
                Custom fields allow businesses to capture additional information
                on Metronome entities that are not covered by standard Metronome
                fields. Users can store additional information on predefined
                keys such as external IDs related to customers, products, credit
                grants, or charges.
              </Body>
            </div>
          </div>

          <CustomFieldsTable
            customFieldKeys={data?.ManagedFieldKey ?? []}
            loading={loading}
            onCreate={() =>
              setCustomFieldModalState({ mode: "create", open: true })
            }
            onEdit={(item) => {
              setCustomFieldModalState({
                mode: "edit",
                open: true,
                activeItem: {
                  ...item,
                  updated_at: new Date().toISOString(),
                },
              });
            }}
            onDelete={(item) => {
              deleteCustomFieldKeyMutation({
                variables: {
                  keyId: item.id,
                },
              })
                .then(() => {})
                .catch((err) => {
                  pushMessage({
                    type: "error",
                    content: getUserFacingErrorMessage(err),
                  });
                });
            }}
          ></CustomFieldsTable>

          {customFieldModalState.open && (
            <CreateOrEditCustomFieldModal
              customFieldKey={customFieldModalState?.activeItem}
              mode={customFieldModalState.mode}
              onClose={() => {
                setCustomFieldModalState((s) => ({ ...s, open: false }));
              }}
            />
          )}

          {exportFieldValuesModalState.open && (
            <ExportCustomFieldsValuesModal
              availableReports={[
                customerCustomFieldsReport,
                productCustomFieldsReport,
                creditGrantCustomFieldsReport,
                chargeCustomFieldsReport,
              ]}
              onClose={() => setExportFieldValuesModalState({ open: false })}
            />
          )}
        </div>
      )}
    </>
  );

  return newUIEnabled ? (
    <>
      <NewCustomFields
        loading={loading}
        customFieldKeys={data?.ManagedFieldKey ?? []}
      />
    </>
  ) : (
    <PageContainer
      title="General settings"
      // this filtering has to happen here, since we can't modify the routes in
      // GeneralSettings/index.tsx at runtime
      tabs={tabs.filter(new TabFilter(invoicingEnabled).filter)}
      tabsAction={
        <div className={classnames(styles.tabActions, "flex flex-row")}>
          <Button
            onClick={() => setExportFieldValuesModalState({ open: true })}
            text="Export field values"
            theme="secondary"
            leadingIcon="download02"
          />

          <GatedButton
            onClick={() =>
              setCustomFieldModalState({ mode: "create", open: true })
            }
            doc={AddCustomFieldKeysDocument}
            text="Add new field key"
            theme="primary"
            leadingIcon="plus"
          />
        </div>
      }
    >
      {content}
    </PageContainer>
  );
};

export const ExportCustomFieldsValuesModal: React.FC<{
  availableReports: ReportConfig<any>[];
  onClose: () => void;
}> = ({ availableReports, onClose }) => {
  const [selectedReport, setSelectedReport] =
    useState<ReportConfig<unknown> | null>();
  const [buildingReport, setBuildingReport] = useState(false);
  const client = useApolloClient();
  const pushMessage = useSnackbar();

  useEffect(() => {
    if (availableReports.length && !selectedReport) {
      setSelectedReport(availableReports[0]);
    }
  }, [availableReports, selectedReport]);

  const generateReport = async () => {
    if (!selectedReport) {
      return;
    }
    if (buildingReport) {
      return;
    }
    const { pageSize } = selectedReport;
    setBuildingReport(true);

    const query = selectedReport.queryDocument;
    const pages = [];
    let cursor = undefined;
    try {
      while (true) {
        const response = await client.query({
          query,
          variables: {
            limit: pageSize + 1,
            cursor,
          },
        });
        const page: any = response.data;
        pages.push(page);
        const nextCursor = selectedReport.nextCursor(page);
        if (nextCursor) {
          cursor = nextCursor;
          continue;
        }
        break;
      }
    } catch (e: any) {
      pushMessage({
        content: "Failed to generate report.",
        type: "error",
      });
      /* Enable more downloads */
      setBuildingReport(false);
      return;
    }
    let rows: string[][] = [];
    if (!selectedReport.needsDates && !selectedReport.needsEnvironment) {
      rows = selectedReport.dataToCSV(pages);
    }
    const reportName = selectedReport.name;
    downloadCSV(`${reportName}.csv`, rows);
    /* Enable more downloads */
    setBuildingReport(false);
  };

  return (
    <Popup
      actions={
        <>
          <Button
            onClick={() => {
              onClose();
            }}
            text="Cancel"
            theme="linkGray"
          />
          <Tooltip
            disabled={!!selectedReport}
            content="Select an entity to export its custom fields"
          >
            <Button
              onClick={generateReport}
              loading={buildingReport}
              text="Download CSV"
              theme="primary"
              leadingIcon="download02"
            />
          </Tooltip>
        </>
      }
      isOpen={true}
      onRequestClose={() => onClose()}
      title="Export custom field values"
      className={classnames(styles.popover, "pl-2")}
    >
      <Body level={2}>Select the entity you’d like to export.</Body>
      <div
        className={classnames(
          styles.formGroup,
          styles.exportToolBody,
          "pl-2 flex flex-row",
        )}
      >
        <div className="flex w-[200px] flex-col">
          <Subtitle level={4} className="text-grey-600">
            Select an entity
          </Subtitle>
          <Select
            placeholder="Select an entity"
            options={availableReports.map((report) => ({
              label: report.name.slice(0, -1 * "Custom Fields".length),
              value: report.name,
            }))}
            value={selectedReport?.name}
            onChange={(value) => {
              setSelectedReport(
                availableReports.find((report) => report.name === value) ??
                  null,
              );
            }}
            disabled={buildingReport}
          />
        </div>
      </div>
    </Popup>
  );
};
export default CustomFieldsTab;
