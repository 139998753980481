import React from "react";
import { useFeatureFlag } from "lib/launchdarkly";
import { MenuItemProps, PopoverMenu } from "components/PopoverMenu";
import { Tooltip } from "design-system";
import { CollectionSchedule } from "lib/plans/types";
import { CompositeChargeTypeEnum_Enum } from "../../../../../../types/generated-graphql/__types__";
import { IconButton } from "../../../../../../tenaissance/components/IconButton";

interface FlatFeeChargeOptionsButtonProps {
  collectionSchedule: CollectionSchedule | undefined;
  canBeTiered: boolean;
  isTiered: boolean;
  setTiered: (tiered: boolean) => void;
  canBeAdvanced: boolean;
  editAdvanceCharge: () => void;
  rampStart: number;
  isProrated: boolean;
  setProrated: (prorated: boolean) => void;
  disabled: boolean;
  inFlightAdvancedCharge: boolean;
}

export const FlatFeeChargeOptionsButton: React.FC<
  FlatFeeChargeOptionsButtonProps
> = ({
  collectionSchedule,
  canBeTiered,
  setTiered,
  isTiered,
  canBeAdvanced,
  editAdvanceCharge,
  rampStart,
  isProrated,
  setProrated,
  disabled,
  inFlightAdvancedCharge,
}) => {
  const tieredFlatFeesEnabled = useFeatureFlag<boolean>(
    "tiered-flatfees-ui",
    false,
  );

  const advanced: MenuItemProps = {
    onClick: editAdvanceCharge,
    content:
      collectionSchedule === "ARREARS" ? (
        <Tooltip
          content="Go back to the first ramp to edit this in-advance charge"
          disabled={rampStart === 0}
        >
          Collect in advance...
        </Tooltip>
      ) : (
        <Tooltip
          content="Go back to the first ramp of the advanced charge collection to edit this in-advance charge"
          disabled={
            (rampStart !== 0 && collectionSchedule !== "ADVANCE") ||
            !inFlightAdvancedCharge
          }
        >
          Edit advanced charge...
        </Tooltip>
      ),
    disabled:
      (rampStart !== 0 && collectionSchedule !== "ADVANCE") ||
      inFlightAdvancedCharge,
    underlined: true,
  };

  const tiered: MenuItemProps = {
    onClick: () => {
      setTiered(!isTiered);
    },
    content: (
      <Tooltip content="Tiers on a fixed charge allows for graduated pricing based on usage. Keep in mind that usage is being calculated based on the quantity.">
        {!isTiered ? "Tiered charge" : "Remove tiers"}
      </Tooltip>
    ),
    disabled: inFlightAdvancedCharge,
  };

  const prorated: MenuItemProps = {
    onClick: () => setProrated(!isProrated),
    content: (
      <Tooltip
        content={
          rampStart === 0
            ? collectionSchedule === "ONE_TIME_ADVANCE"
              ? "One-time advanced charges cannot be prorated"
              : "Prorate this charge in the event of a shortened billing period, for example, starting or ending the plan in the middle of a billing period."
            : "Proration is either enabled or disabled for the life of a plan. To modify, go back to the first ramp."
        }
      >
        {!isProrated ? "Prorate charge" : "Remove proration"}
      </Tooltip>
    ),
    disabled:
      rampStart !== 0 ||
      collectionSchedule === "ONE_TIME_ADVANCE" ||
      inFlightAdvancedCharge,
  };
  const menuOptions: MenuItemProps[] = [];
  if (tieredFlatFeesEnabled && canBeTiered) {
    menuOptions.push(tiered);
  }
  menuOptions.push(prorated);
  if (canBeAdvanced) {
    menuOptions.push(advanced);
  }
  return <ChargeOptionsButton menuOptions={menuOptions} disabled={disabled} />;
};

interface UsageChargeOptionsButtonProps {
  isTiered: boolean;
  setTiered: (tiered: boolean) => void;
  hasCustomPricing: boolean | undefined;
  openBlockPricingModal: () => void;
  hasBlockPricing: boolean;
  removeBlockPricing: () => void;
  hasVolumePricing: boolean;
  setVolumePricing: (volumePricing: boolean) => void;
  openTierResetFrequencyModal: () => void;
  disabled: boolean;
  hasRamps?: boolean;
  editing?: boolean;
}

export const UsageChargeOptionsButton: React.FC<
  UsageChargeOptionsButtonProps
> = ({
  isTiered,
  setTiered,
  hasCustomPricing,
  openBlockPricingModal,
  hasBlockPricing,
  removeBlockPricing,
  hasVolumePricing,
  setVolumePricing,
  openTierResetFrequencyModal,
  disabled,
  hasRamps,
  editing,
}) => {
  const tiered: MenuItemProps = {
    onClick: () => {
      setTiered(!isTiered);
    },
    content: (
      <div className="w-full">
        <Tooltip
          content={`Tiered pricing is based on the quantity consumed in a billing period. All units will be priced at a graduated rate based on each tier that’s hit.${
            hasCustomPricing
              ? " Tiers cannot be modified if this charge has custom pricing."
              : ""
          }`}
        >
          {!isTiered ? "Tiered" : "Remove tiers"}
        </Tooltip>
      </div>
    ),
    disabled: hasCustomPricing,
  };

  const options = [tiered];

  options.push({
    onClick: () => {
      setVolumePricing(!hasVolumePricing);
    },
    content: (
      <div className="w-full">
        <Tooltip content="Volume pricing is based on the total quantity consumed in a billing period. All units will be priced the same based on the largest tier that’s hit.">
          {hasVolumePricing ? "Remove volume-based" : "Volume-based"}
        </Tooltip>
      </div>
    ),
    disabled: hasCustomPricing,
  });

  options.push({
    onClick: openBlockPricingModal,
    content: (
      <div className="w-full">
        <Tooltip content="Quantity conversion is used when the quantity computed in the billable metric needs to be converted before being priced, for example, dividing by 1000. The converted quantity can also be rounded up or down.">
          {!hasBlockPricing
            ? "Add quantity conversion..."
            : "Edit quantity conversion..."}
        </Tooltip>
      </div>
    ),
  });
  if (hasBlockPricing) {
    options.push({
      onClick: removeBlockPricing,
      content: "Remove quantity conversion...",
    });
  }
  if (isTiered && !hasVolumePricing) {
    options.push({
      onClick: openTierResetFrequencyModal,
      content: (
        <div className="w-full">
          <Tooltip
            content={
              hasRamps
                ? "Tier reset period cannot be modified if this plan has ramps."
                : editing
                  ? "Tier reset period cannot be modified while editing."
                  : ""
            }
          >
            Edit tier reset period...
          </Tooltip>
        </div>
      ),
      disabled: hasRamps || editing,
    });
  }

  return <ChargeOptionsButton menuOptions={options} disabled={disabled} />;
};

interface CompositeChargeOptionsButtonProps {
  openCompositeChargeModal: (type: CompositeChargeTypeEnum_Enum) => void;
  disabled: boolean;
}

export const CompositeChargeOptionsButton: React.FC<
  CompositeChargeOptionsButtonProps
> = ({ openCompositeChargeModal, disabled }) => {
  const options = [
    {
      onClick: () =>
        openCompositeChargeModal(CompositeChargeTypeEnum_Enum.Percentage),
      content: "Percent-based composite charge...",
    },
    {
      onClick: () =>
        openCompositeChargeModal(CompositeChargeTypeEnum_Enum.Minimum),
      content: "Minimum-based composite charge...",
    },
  ];
  return <ChargeOptionsButton menuOptions={options} disabled={disabled} />;
};

interface ChargeOptionsButtonProps {
  menuOptions: MenuItemProps[];
  disabled: boolean;
}

const ChargeOptionsButton: React.FC<ChargeOptionsButtonProps> = ({
  menuOptions,
  disabled,
}) => {
  return (
    <PopoverMenu positions={["bottom"]} align="end" options={menuOptions}>
      {(onClick) => (
        <IconButton
          onClick={onClick}
          disabled={disabled}
          theme="secondary"
          icon="dotsVertical"
        />
      )}
    </PopoverMenu>
  );
};
