import { useFeatureFlag } from "../launchdarkly";

export type UI_MODE =
  | "off"
  | "contracts-only"
  | "plans-only"
  | "contracts-and-plans";

export function useUIMode(): {
  newUIEnabled: boolean;
  mode: UI_MODE;
} {
  const uiMode = useFeatureFlag<UI_MODE>("tenaissance-ui-mode", "off") ?? "off";
  return {
    newUIEnabled: uiMode !== "off",
    mode: uiMode,
  };
}
