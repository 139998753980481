import React from "react";
import "/src/tenaissance/tenaissance.css";

interface SectionHeaderProps {
  /** Label string for the main header */
  title: string;
  /** Label string for the sub-header */
  subtitle: string | string[];
  /** If provided, actions will be added to the section header */
  actions?: React.ReactNode[];
  /** Set to false to remove the bottom border */
  bottomBorder?: boolean;
}

/**
 * !! PARTIAL IMPLEMENTATION !!
 * Section headers provide content and actions related to a section
 * of the current screen.
 *
 * TODO:
 * - implement action buttons
 *   - only button group remaining
 * - implement tabs
 * - implement search bar
 */
export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  actions,
  bottomBorder = true,
}) => {
  const subTitleLines = Array.isArray(subtitle) ? subtitle : [subtitle];
  return (
    <>
      <div className="flex justify-between">
        <div className="flex-row">
          <div className="text-lg font-semibold text-gray-900">{title}</div>
          {subTitleLines.length > 0 && (
            <div className="gap-md flex flex-col">
              {subTitleLines.map((line, idx) => (
                <div key={idx} className="text-sm font-normal text-gray-600">
                  {line}
                </div>
              ))}
            </div>
          )}
        </div>
        {actions && (
          <div className="gap-lg float-right flex flex-row-reverse justify-between">
            {actions.map((action, i) => (
              <div key={i}>{action}</div>
            ))}
          </div>
        )}
      </div>
      {bottomBorder && (
        <hr className="h-px mt-16 flex w-full border-b border-t-0 border-gray-200 bg-gray-200" />
      )}
    </>
  );
};
