import React from "react";
import { Popup } from "components/Popup";

import { Button } from "tenaissance/components/Button";
import { Form, FormController } from "lib/FormController";

export function useConfirmClose<S extends Form.Shape>(
  ctrl: FormController<S>,
  onConfirmedCancel: () => void,
): [confirmingCloseEl: React.ReactNode | undefined, confirmClose: () => void] {
  const snapshot = React.useMemo(() => ctrl.snapshot(), []);
  const [confirmingClose, setConfirmingClose] = React.useState(false);

  const confirmingCloseEl = confirmingClose ? (
    <Popup
      title="Discard changes?"
      isOpen
      onRequestClose={() => setConfirmingClose(false)}
      actions={
        <div className="flex justify-end gap-12">
          <Button
            onClick={() => setConfirmingClose(false)}
            text="Cancel"
            theme="secondary"
          />
          <Button
            onClick={onConfirmedCancel}
            text="Confirm"
            theme="secondary"
          />
        </div>
      }
    >
      <p>Are you sure you want to close this form? You have unsaved changes.</p>
    </Popup>
  ) : undefined;

  function confirmClose() {
    if (ctrl.matchesSnapshot(snapshot)) {
      onConfirmedCancel();
      return;
    }

    setConfirmingClose(true);
  }

  return [confirmingCloseEl, confirmClose];
}
