import React, { useCallback, useMemo, useReducer } from "react";
import { Column, Table } from "tenaissance/components/Table";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { useNow } from "lib/date";
import { Badge } from "tenaissance/components/Badge";
import { getDateStringInUTC } from "lib/time";
import RateCell from "./RateCell";
import {
  RateScheduleRow,
  RateScheduleTable,
} from "pages/Contracts/components/RateScheduleTable";
import {
  GetRateScheduleQuery,
  useGetRateScheduleQuery,
  useGetRateCardProductsQuery,
} from "pages/Contracts/components/RateSchedulePanel/data.graphql";
import { useSearcher } from "lib/search/useSearcher";

type Props = {
  rateCardId: string;
};

type SegmentProduct = {
  id: string;
  name: string;
  typeName: string;
  type: GetRateScheduleQuery["contract_pricing"]["rate_card"]["rate_schedule"]["products_on_segments"][number]["__typename"];
};

export type RateScheduleRowWithId = RateScheduleRow & { id: string };

const MAX_SORTABLE_SEARCHABLE_SIZE = 1000;

type State = {
  search: string;
  debouncedSearch: string;
  pagination: Pagination;
};

interface Pagination {
  size: number;
  pointer: Pointer;
  history: Array<Pointer>;
}

const DEFAULT_STATE: State = {
  search: "",
  debouncedSearch: "",
  pagination: {
    size: 50,
    pointer: { cursor: null, offset: 0 },
    history: [],
  },
};

type Pointer = { cursor: string | null; offset: number };

function pickNextPaginationState(
  state: Pagination,
  allRowCount: number,
  nextCursor: string | null,
) {
  if (state.pointer.offset + state.size < allRowCount) {
    return {
      ...state,
      pointer: {
        cursor: state.pointer.cursor,
        offset: state.pointer.offset + state.size,
      },
      history: state.history,
    };
  }

  if (nextCursor) {
    return {
      ...state,
      pointer: { cursor: nextCursor, offset: 0 },
      history: [state.pointer, ...state.history],
    };
  }

  return null;
}

function pickPrevPaginationState(state: Pagination) {
  if (state.pointer.offset > 0) {
    return {
      ...state,
      pointer: {
        cursor: state.pointer.cursor,
        offset: Math.max(0, state.pointer.offset - state.size),
      },
      history: state.history,
    };
  }

  if (state.history.length) {
    return {
      ...state,
      pointer: state.history[0],
      history: state.history.slice(1),
    };
  }

  return null;
}

type Action =
  | {
      type: "search";
      search: string;
    }
  | {
      type: "searchDebounced";
    }
  | {
      type: "newPageSize";
      newPageSize: number;
    }
  | {
      type: "paginate";
      pagination: Pagination;
    };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "search":
      return {
        ...state,
        search: action.search,
      };
    case "searchDebounced":
      return {
        ...state,
        debouncedSearch: state.search,
        pagination: {
          ...DEFAULT_STATE.pagination,
          size: state.pagination.size,
        },
      };
    case "newPageSize":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          size: action.newPageSize,
        },
      };
    case "paginate":
      return {
        ...state,
        pagination: action.pagination,
      };
  }
}

export default function RateCardTable({ rateCardId }: Props) {
  const now = useNow();
  const [{ search, debouncedSearch, pagination }, dispatch] = useReducer(
    reducer,
    DEFAULT_STATE,
  );

  // trigger searchDebounced action after 700ms of no search changes
  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: "searchDebounced" });
    }, 700);

    return () => clearTimeout(timer);
  }, [search]);

  const { loading: loadingProducts, data: productResp } =
    useGetRateCardProductsQuery({
      variables: {
        rateCardId: rateCardId,
      },
    });

  const searchableProducts = React.useMemo(
    () =>
      !loadingProducts && productResp
        ? productResp.contract_pricing.rate_card.products.map((p) => ({
            id: p.id,
            names: ProductListItem.getAllNames(p),
            name: ProductListItem.getName(p, now),
            type: ProductListItem.printType(p),
          }))
        : [],
    [now, productResp],
  );

  const searchProducts = useSearcher(searchableProducts, {
    keys: ["names"],
    uuidKeys: ["id"],
  });
  const matchedProducts = searchProducts(debouncedSearch);

  const { loading, data } = useGetRateScheduleQuery({
    variables: {
      rateCardId,
      cursor: pagination.pointer.cursor,
      limit: String(MAX_SORTABLE_SEARCHABLE_SIZE),
      tryProductOrderSort: true,
      selectors: debouncedSearch
        ? [
            matchedProducts
              .map((p) => ({
                product_id: p.id,
              }))
              .slice(
                0,
                Math.min(MAX_SORTABLE_SEARCHABLE_SIZE, pagination.size),
              ),
          ].flat()
        : null,
    },
  });

  const productsMap = useMemo(() => {
    const products = new Map<string, SegmentProduct>();
    if (data) {
      data.contract_pricing.rate_card.rate_schedule.products_on_segments.forEach(
        (product) => {
          products.set(product.id, {
            id: product.id,
            name: ProductListItem.getName(product, now),
            typeName: ProductListItem.printType(product),
            type: product.__typename,
          });
        },
      );
    }

    return products;
  }, [data]);

  const allSegmentRows = React.useMemo(() => {
    if (!data?.contract_pricing.rate_card.rate_schedule.scalar_segments) {
      return [];
    }

    const rateSchedule = data.contract_pricing.rate_card.rate_schedule;

    return RateScheduleTable.rowsFromSegments(
      now,
      rateSchedule.scalar_segments ?? [],
      rateSchedule.products_on_segments ?? [],
      rateSchedule.credit_types_on_segments ?? [],
    ).map((row, idx) => ({ ...row, id: rateSchedule.scalar_segments[idx].id }));
  }, [now, data]);

  const hasCommitRates = useMemo(() => {
    if (data) {
      return data.contract_pricing.rate_card.rate_schedule.scalar_segments.some(
        (s) => s.commit_rate,
      );
    }

    return false;
  }, [data]);

  const rows = React.useMemo(() => {
    return allSegmentRows.slice(
      pagination.pointer.offset,
      pagination.pointer.offset + pagination.size,
    );
  }, [allSegmentRows, pagination.pointer.offset, pagination.size]);

  const nextPageState = pickNextPaginationState(
    pagination,
    allSegmentRows.length,
    data?.contract_pricing.rate_card.rate_schedule.next_page ?? null,
  );
  const prevPageState = pickPrevPaginationState(pagination);

  const columns: Column<RateScheduleRowWithId>[] = useMemo(() => {
    const memoizedColumns: Column<RateScheduleRowWithId>[] = [];
    memoizedColumns.push({
      id: "1",
      header: "Name & dimensional pricing values",
      accessorKey: "product.name",
      supportingText: (row) => {
        return row.pricingGroupValues
          ? Object.values(row.pricingGroupValues).join(", ")
          : row.rate.type === "percentage" && row.rate.useListPrices
            ? "Use list rates"
            : "";
      },
      cell: (props: { getValue: () => string }) => {
        return props.getValue();
      },
    });
    memoizedColumns.push({
      id: "2",
      header: "Type",
      accessorFn: (props) => props,
      cell: (props: { getValue: () => RateScheduleRowWithId }) => {
        const row = props.getValue();
        const product = row.product;
        if (product) {
          const getBadgeTheme = () => {
            switch (product.type) {
              case "Subscription":
                return "indigo";
              case "Usage":
                return "gray";
              case "Composite":
                return "vibrant-magenta";
              default:
                return undefined;
            }
          };

          const badgeTheme = getBadgeTheme();

          return <Badge label={product.type} theme={badgeTheme} />;
        }
        return null;
      },
    });
    memoizedColumns.push({
      id: "3",
      header: "Starting at (UTC)",
      accessorKey: "startingAt",
      size: 220,
      cell: (props: { getValue(): Date }) => {
        const date = props.getValue();
        const text = date ? getDateStringInUTC(date) : "--";
        return text;
      },
    });
    memoizedColumns.push({
      id: "4",
      header: "Ending before (UTC)",
      accessorKey: "endingBefore",
      size: 220,
      cell: (props: { getValue(): Date }) => {
        const date = props.getValue();
        const text = date ? getDateStringInUTC(date) : "--";
        return text;
      },
    });
    memoizedColumns.push({
      id: "entitled",
      header: "Entitlement",
      accessorKey: "entitlement.entitled",
      cell: (props: { getValue(): boolean }) => {
        const entitled = props.getValue();
        return entitled ? "Enabled" : "Disabled";
      },
    });
    if (hasCommitRates) {
      memoizedColumns.push({
        id: "commitRate",
        header: "Commit rate",
        size: 200,
        accessorFn: (props) => props,
        cell: (props: { getValue(): RateScheduleRowWithId }) => {
          const rateSegment = props.getValue();
          return <RateCell rateSegment={rateSegment} isCommitRate={true} />;
        },
      });
    }

    memoizedColumns.push({
      id: "rate",
      header: "Rate",
      size: 200,
      accessorFn: (props) => props,
      cell: (props: { getValue(): RateScheduleRowWithId }) => {
        const rateSegment = props.getValue();
        return <RateCell rateSegment={rateSegment} isCommitRate={false} />;
      },
    });
    return memoizedColumns;
  }, [productsMap, hasCommitRates]);

  const handleSearch = useCallback(
    (search: string) => {
      dispatch({ type: "search", search });
    },
    [search],
  );

  return (
    <Table
      searchOptions={{
        showSearch: true,
        onSearch: handleSearch,
      }}
      title="Rates"
      columns={columns}
      data={rows}
      loading={loading || loadingProducts}
      paginationOptions={{
        type: "prevNext",
        paginationButtons: [
          {
            page: "prev",
            onClick: () => {
              if (prevPageState) {
                dispatch({ type: "paginate", pagination: prevPageState });
              }
            },
            disabled: !prevPageState,
          },
          {
            page: "next",
            disabled: !nextPageState,
            onClick: () => {
              if (nextPageState) {
                dispatch({ type: "paginate", pagination: nextPageState });
              }
            },
          },
        ],
      }}
      rowRoutePath={(row) => {
        return `/offering/rate-cards/${rateCardId}?productId=${row.original.product.id}`;
      }}
      rowRoutePathReplace={true}
    />
  );
}
