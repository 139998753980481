import { DraftPlan, PriceRamp } from "./types";

// At read time, add an extra price ramp at the end to account for
// "regular" (post-ramp or non-ramp) billing periods.
export function getRamps(draftPlan: DraftPlan): PriceRamp[] {
  return draftPlan.hasPriceRamps && draftPlan.ramps
    ? draftPlan.ramps.concat([{}])
    : [{}];
}

export function getPriceRampDurations(
  allStartPeriods: Set<number>,
): PriceRamp[] {
  const sortedStartPeriods = Array.from(allStartPeriods).sort((a, b) => a - b);
  return sortedStartPeriods
    .map((sp, i) => {
      if (i === 0) {
        return {};
      } else {
        return { durationInPeriods: sp - sortedStartPeriods[i - 1] };
      }
    })
    .filter((pr) => pr.durationInPeriods !== undefined);
}

export function getRampStartPeriod(rampIndex: number, ramps: PriceRamp[]) {
  return ramps
    .slice(0, rampIndex)
    .reduce((acc, curr) => acc + (curr.durationInPeriods || 0), 0);
}

export function getRampStartPeriods(ramps: PriceRamp[]) {
  return ramps.reduce<number[]>(
    (prev, curr, i) => {
      if (!curr.durationInPeriods) {
        return prev;
      }
      return prev.concat([(prev[i] ?? 0) + curr.durationInPeriods]);
    },
    [0],
  );
}
