import React, { useState } from "react";

import { Input } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { useSnackbar } from "components/Snackbar";

import styles from "./index.module.less";

import { useCreateUserMutation } from "../../queries.graphql";

type CreateUserModalProps = {
  onClose: () => void;
  refetchUsers: () => void;
  existingUserEmailDomains: Set<string>;
};

export const CreateUserModal: React.FC<CreateUserModalProps> = ({
  onClose,
  refetchUsers,
  existingUserEmailDomains,
}) => {
  const pushMessage = useSnackbar();
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();

  const [createUser, createUserResult] = useCreateUserMutation({
    update: (cache) => {
      cache.evict({ fieldName: "all_users" });
    },
  });

  const emailIsValid = /^\S+@\S+$/.test(email || "");

  const createUserAction = async () => {
    if (!name || !email || !emailIsValid) {
      return;
    }

    const results = await createUser({
      variables: {
        name,
        email,
      },
    });

    if (!results.data?.create_user.id) {
      return;
    }

    pushMessage({
      content: (
        <>
          <b>{name}</b>&nbsp;added
        </>
      ),
      type: "success",
    });

    refetchUsers();
    onClose();
  };

  const badEmailMessage =
    createUserResult.called &&
    createUserResult.error?.graphQLErrors[0].extensions?.code ===
      "BAD_USER_INPUT" &&
    createUserResult.error?.graphQLErrors[0].extensions?.argument === "email"
      ? createUserResult.error.graphQLErrors[0].message
      : undefined;

  const emailDoesNotMatchExistingDomainMessage =
    !badEmailMessage &&
    email &&
    emailIsValid &&
    existingUserEmailDomains.size > 0 &&
    !existingUserEmailDomains.has(email?.split("@")[1])
      ? "Note: this email domain doesn't match any current domains in your account"
      : undefined;

  return (
    <Popup
      actions={
        <Button
          key="primary"
          disabled={!(!!name && emailIsValid) || createUserResult.loading}
          onClick={createUserAction}
          loading={createUserResult.loading}
          text={createUserResult.loading ? "Saving" : "Add user"}
          theme="primary"
        />
      }
      isOpen={true}
      onRequestClose={onClose}
      title="Add user"
    >
      <div className={styles.content}>
        <Input
          name="Name"
          placeholder="User's name"
          value={name}
          success={!!name}
          error={name === "" ? "Name is required" : undefined}
          autoFocus={true}
          onChange={(e) => {
            setName(e);
          }}
        />
        <Input
          name="Email"
          placeholder="example@example.com"
          value={email}
          success={emailIsValid}
          error={email && !emailIsValid ? "Email is invalid" : undefined}
          onChange={(e) => {
            setEmail(e);
          }}
        />
        {badEmailMessage && (
          <span className={styles.error}>{badEmailMessage}</span>
        )}
        {emailDoesNotMatchExistingDomainMessage && (
          <span className={styles.note}>
            {emailDoesNotMatchExistingDomainMessage}
          </span>
        )}
      </div>
    </Popup>
  );
};
