import React from "react";
import { twMerge } from "tenaissance/twMerge";

interface LoadingBlobProps {
  className?: string;
}

export const LoadingBlob: React.FC<LoadingBlobProps> = ({ className }) => {
  return (
    <div
      className={twMerge(
        "py-xl px-3xl h-sm rounded-sm my-sm flex w-full min-w-[50px] max-w-[300px] animate-pulse bg-gray-200 align-middle",
        className,
      )}
    ></div>
  );
};
