import { Form, FormController } from "lib/FormController";
import { useEffect, useRef } from "react";
import { CreditType } from "types/credit-types";

export function useEffectToClearInputIfPricingUnitDropdownChanges<
  S extends Form.Shape,
>(ctrl: FormController<S>, creditType: CreditType, inputFieldToClear: string) {
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (!!ctrl.get(inputFieldToClear)) {
      // Clear input when selected credit type is changed except for first render
      // We want input to stay populated on first render during an edit
      ctrl.update({
        [inputFieldToClear]: undefined,
      } as Form.Update<S>);
    }
  }, [creditType]);
}
