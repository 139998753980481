import React from "react";
import { useDraftPlan } from "../../../../context";
import { InputSection, WizardSplitPage } from "components/Wizard";
import { ToggleButtons } from "components/ToggleButtons";
import { Select } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Customer } from "types/generated-graphql/__types__";
import { TermPreview } from "../TermPreview";
import { renderDate } from "lib/time";
import { DraftPlan } from "lib/plans/types";
import { Subtitle } from "design-system";

import styles from "./index.module.less";

export function isAddCustomerToPlanDone(draftPlan: DraftPlan) {
  return !!(
    draftPlan.hasCustomer !== undefined &&
    (!draftPlan.hasCustomer ||
      (draftPlan.hasCustomer && draftPlan.customerPlanInfo))
  );
}

export const MAX_ALLOWED_CUSTOMERS_FOR_ADD_PLAN_TO_CUSTOMER = 10000;

interface AddCustomerToPlanProps {
  customers: Pick<Customer, "id" | "name" | "created_at">[];
}

export const AddCustomerToPlan: React.FC<AddCustomerToPlanProps> = (props) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const currentCustomer =
    draftPlan.hasCustomer && draftPlan.customerPlanInfo
      ? props.customers.find(
          (c) => c.id === draftPlan.customerPlanInfo?.customerId,
        )
      : undefined;
  return (
    <WizardSplitPage
      preview={
        <TermPreview
          billingFrequency={draftPlan.billingFrequency}
          billingAnchor={draftPlan.billingDayOfPeriod}
          startDate={draftPlan.customerPlanInfo?.startDate}
          endDate={draftPlan.customerPlanInfo?.endDate}
          trialLength={draftPlan.trialSpec?.length}
          draft={!draftPlan.hasCustomer}
          hasCustomer={draftPlan.hasCustomer ?? false}
          customerName={draftPlan.customerPlanInfo?.customerName}
          planName={draftPlan.name ?? ""}
          planDescription={draftPlan.description ?? ""}
        />
      }
    >
      <InputSection
        header="Is this plan for a specific customer?"
        subtitle="If you would like to add a specific customer to this plan, you can do that now. You’ll also be able to add future customers to this plan at any point."
      >
        <ToggleButtons
          buttonProps={[
            {
              value: "yes",
              label: "Yes",
              selected: !!draftPlan.hasCustomer,
            },
            {
              value: "no",
              label: "No",
              selected: draftPlan.hasCustomer === false,
            },
          ]}
          value={
            draftPlan.hasCustomer === undefined
              ? undefined
              : draftPlan.hasCustomer
                ? "yes"
                : "no"
          }
          defaultButtonProps={{
            onChange: (v) => {
              setDraftPlan({
                ...draftPlan,
                hasCustomer: v === "yes",
                customerPlanInfo:
                  v === "yes" ? draftPlan.customerPlanInfo : undefined,
              });
            },
          }}
        />
      </InputSection>
      <InputSection
        header="What customer do you want to add to this plan?"
        subtitle=""
      >
        <Select
          placeholder="Search"
          options={props.customers.map((c) => ({ label: c.name, value: c.id }))}
          value={draftPlan.customerPlanInfo?.customerId ?? ""}
          disabled={!draftPlan.hasCustomer}
          onChange={(customerId: string | null) => {
            if (customerId) {
              setDraftPlan({
                ...draftPlan,
                customerPlanInfo: {
                  ...(draftPlan.customerPlanInfo || {}),
                  customerId: customerId,
                  customerName:
                    props.customers.find((c) => c.id === customerId)?.name ??
                    "",
                },
              });
            } else {
              setDraftPlan({
                ...draftPlan,
                customerPlanInfo: undefined,
              });
            }
          }}
        />
        {draftPlan.customerPlanInfo && (
          <div className={styles.selectedCustomer}>
            <Subtitle level={1}>
              {draftPlan.customerPlanInfo.customerName}
            </Subtitle>
            <div className={styles.createdAt}>
              {currentCustomer && (
                <Subtitle level={4}>
                  Created on{" "}
                  {renderDate(new Date(currentCustomer.created_at), {
                    isUtc: false,
                  })}
                </Subtitle>
              )}
              <IconButton
                onClick={() => {
                  setDraftPlan({ ...draftPlan, customerPlanInfo: undefined });
                }}
                theme="tertiary"
                icon="xCircle"
              />
            </div>
          </div>
        )}
      </InputSection>
    </WizardSplitPage>
  );
};
