import React from "react";
import { useRouteError } from "react-router-dom";

import { ErrorEmptyState } from "./ErrorEmptyState";

export const RootErrorBoundary: React.FC = () => {
  const error = useRouteError();

  return (
    <div className="flex h-screen items-center bg-white">
      <ErrorEmptyState
        title="We ran into an issue loading this page"
        error={error}
      />
    </div>
  );
};
