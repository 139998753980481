import React, { useState } from "react";

import { Body, Input, Select } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { useSnackbar } from "components/Snackbar";

import {
  InsertCustomerBillingProviderConfigurationMutationResult,
  UpdateStripeBillingProviderCustomerMutationResult,
  useGetStripeCustomerQuery,
  useInsertCustomerBillingProviderConfigurationMutation,
  useIsDeltaStreamEnabledQuery,
  useUpdateStripeBillingProviderCustomerMutation,
} from "./queries.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { ApolloCache } from "@apollo/client";
import { reportToSentry } from "../../../../../../lib/errors/sentry";
import { CustomerSettingsWritePlansAndOrContracts } from "../../../../../../lib/billingProvider/billingProviderSettings";

type StripeProvisioningStatus = {
  hasBillingProviderOnPlan: boolean;
  hasStripeOnPlan: boolean;
  hasStripeOnContract: boolean;
  hasStripeBillingProviderToken: boolean;
  hasStripeBillingProviderDeliveryMethod: boolean;
};

type StripeSettingsModalProps = {
  onClose: (
    deleteConfig?: boolean,
    status?: {
      isSuccess: true;
    },
  ) => void;
  customerId: string;
  edit?: boolean;
  stripeCustomerID?: string;
  stripeCollectionMethod?: string;
  hasBillingProviderToken: boolean;
} & CustomerSettingsWritePlansAndOrContracts;

const evictCacheOptions = {
  update(cache: ApolloCache<any>) {
    cache.evict({
      fieldName: "stripe_billing_provider_configs",
    });
    cache.evict({
      fieldName: "customer_billing_provider_configurations",
    });
    cache.evict({
      fieldName: "BillingProviderCustomer",
    });
    cache.evict({
      fieldName: "CustomerConfig",
    });
    cache.evict({
      fieldName: "Customer_aggregate",
    });
  },
};

export const PLANS_ONLY_EDITING_MESSAGE =
  "Please note: Changes to the configuration apply only to plans; contract configurations will remain unchanged.";

type CreateOrEditStripeActions = {
  pushMessageAndClose: (message: string, type: "error" | "success") => void;
  upsertBillingProviderCustomer: () => Promise<UpdateStripeBillingProviderCustomerMutationResult>;
  upsertCustomerBillingProviderConfiguration: () => Promise<InsertCustomerBillingProviderConfigurationMutationResult>;
  upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration: () => Promise<void>; // todo this mutation does not exist yet
};

export const editStripeSettingsAction = async (
  {
    pushMessageAndClose,
    upsertBillingProviderCustomer,
    upsertCustomerBillingProviderConfiguration,
    upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration,
  }: CreateOrEditStripeActions,
  {
    hasBillingProviderOnPlan,
    hasStripeOnPlan,
    hasStripeOnContract,
  }: StripeProvisioningStatus,
) => {
  if (!hasBillingProviderOnPlan && !hasStripeOnPlan && !hasStripeOnContract) {
    pushMessageAndClose(
      "Cannot edit a nonexisting Stripe configuration",
      "error",
    );
    reportToSentry("Cannot edit a nonexisting Stripe configuration");
    return;
  } else if (
    hasStripeOnPlan &&
    hasBillingProviderOnPlan &&
    !hasStripeOnContract
  ) {
    await upsertBillingProviderCustomer();
  } else if (
    !hasStripeOnPlan &&
    !hasBillingProviderOnPlan &&
    hasStripeOnContract
  ) {
    await upsertCustomerBillingProviderConfiguration();
  } else if (
    hasBillingProviderOnPlan &&
    !hasStripeOnPlan &&
    hasStripeOnContract
  ) {
    await upsertCustomerBillingProviderConfiguration();
  } else if (
    hasBillingProviderOnPlan &&
    hasStripeOnPlan &&
    hasStripeOnContract
  ) {
    await upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration();
  } else {
    pushMessageAndClose("An unexpected error occured", "error");
    return;
  }

  pushMessageAndClose("Stripe configuration saved", "success");
};

export const createStripeSettingsAction = async (
  {
    pushMessageAndClose,
    upsertBillingProviderCustomer,
    upsertCustomerBillingProviderConfiguration,
    upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration,
  }: CreateOrEditStripeActions,
  {
    hasBillingProviderOnPlan,
    hasStripeOnPlan,
    hasStripeOnContract,
    hasStripeBillingProviderToken,
    hasStripeBillingProviderDeliveryMethod,
  }: StripeProvisioningStatus,
) => {
  if (hasStripeBillingProviderToken && hasStripeBillingProviderDeliveryMethod) {
    if (!hasBillingProviderOnPlan && !hasStripeOnPlan && !hasStripeOnContract) {
      await upsertBillingProviderCustomerAndCustomerBillingProviderConfiguration();
    } else if (
      hasBillingProviderOnPlan &&
      hasStripeOnPlan &&
      !hasStripeOnContract
    ) {
      await upsertCustomerBillingProviderConfiguration();
    } else if (
      !hasBillingProviderOnPlan &&
      !hasStripeOnPlan &&
      hasStripeOnContract
    ) {
      await upsertBillingProviderCustomer();
    } else if (
      (hasBillingProviderOnPlan || hasStripeOnPlan) &&
      hasStripeOnContract
    ) {
      pushMessageAndClose(
        "Only one stripe configuration is allowed at a time",
        "error",
      );
      return;
    } else {
      pushMessageAndClose("An unexpected error occured", "error");
      return;
    }
  } else if (hasStripeBillingProviderToken) {
    if (!hasBillingProviderOnPlan && !hasStripeOnPlan) {
      await upsertBillingProviderCustomer();
    } else if (hasBillingProviderOnPlan && hasStripeOnPlan) {
      pushMessageAndClose(
        "Only one stripe configuration is allowed at a time",
        "error",
      );
      return;
    } else {
      pushMessageAndClose("An unexpected error occured", "error");
      return;
    }
  } else if (hasStripeBillingProviderDeliveryMethod) {
    if (!hasStripeOnContract) {
      await upsertCustomerBillingProviderConfiguration();
    } else if (hasStripeOnContract) {
      pushMessageAndClose(
        "Only one stripe configuration is allowed at a time",
        "error",
      );
      return;
    } else {
      pushMessageAndClose("An unexpected error occured", "error");
      return;
    }
  } else {
    pushMessageAndClose(
      "Account-level Stripe configuration is required.  Contact your Metronome representative for assistance.",
      "error",
    );
    return;
  }

  pushMessageAndClose("Stripe configuration saved", "success");
};

export const StripeSettingsModal: React.FC<StripeSettingsModalProps> = ({
  onClose,
  customerId,
  edit = false,
  stripeCustomerID = "",
  stripeCollectionMethod = "charge_automatically",
  plansAndOrContracts = "plans_and_contracts",
  hasBillingProviderToken,
}) => {
  const { environmentType } = useEnvironment();
  const pushMessage = useSnackbar();
  const [updatedStripeCustomerId, setUpdatedStripeCustomerId] = useState<
    string | null
  >(edit ? stripeCustomerID : null);
  const [updatedStripeCollectionMethod, setUpdatedStripeCollectionMethod] =
    useState<string | null>(edit ? stripeCollectionMethod : null);
  const [updateSettings] =
    useUpdateStripeBillingProviderCustomerMutation(evictCacheOptions);
  const [updateSettingsContractsOnly] =
    useInsertCustomerBillingProviderConfigurationMutation(evictCacheOptions);
  const { data, loading } = useGetStripeCustomerQuery({
    skip: updatedStripeCustomerId === null,
    variables: {
      environment_type: environmentType,
      stripe_customer_id: updatedStripeCustomerId ?? "",
    },
  });

  const isDeltaStreamEnabled =
    useIsDeltaStreamEnabledQuery().data?.is_delta_stream_enabled;

  const updateSettingsAction = async () => {
    if (updatedStripeCustomerId && updatedStripeCollectionMethod) {
      if (isDeltaStreamEnabled) {
        await updateSettingsContractsOnly({
          variables: {
            customer_id: customerId,
            configuration: {
              stripe_customer_id: updatedStripeCustomerId,
              stripe_collection_method: updatedStripeCollectionMethod,
            },
          },
        });
      } else if (hasBillingProviderToken) {
        // ^ this prevents us from writing a billing provider customer if all the client has is a delivery method set up
        await updateSettings({
          variables: {
            billing_provider_customer_id: updatedStripeCustomerId,
            stripe_collection_method: updatedStripeCollectionMethod,
            customer_id: customerId,
          },
        });
      } else {
        // right now our only two options are delta stream (confluent) and plans-level configuration for stripe
        pushMessage({
          content:
            "Account-level Stripe configuration is required.  Contact your Metronome representative for assistance.",
          type: "error",
        });
        reportToSentry(
          "Expected a billing provider token but did not find one",
        );
        return;
      }
      pushMessage({
        content: "Stripe configuration saved",
        type: "success",
      });

      onClose(undefined, {
        isSuccess: true,
      });
    }
  };
  const actionButtons = (
    <>
      {edit && (
        <Button
          key="delete"
          onClick={() => onClose(true)}
          text="Disable Stripe"
          theme="linkGray"
        />
      )}
      <Button
        key="primary"
        disabled={!updatedStripeCustomerId || !updatedStripeCollectionMethod}
        onClick={updateSettingsAction}
        text={edit ? "Save changes" : "Add Stripe"}
        theme="primary"
      />
    </>
  );

  return (
    <Popup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={() => onClose(false)}
      title="Stripe configuration"
    >
      <Body level={2}>
        {edit
          ? "Configuration details. " + PLANS_ONLY_EDITING_MESSAGE
          : `To add the Stripe profile you need the customer’s associated Stripe customer ID. Once entered we’ll display the customer name.${
              environmentType === EnvironmentTypeEnum_Enum.Sandbox
                ? " This is a sandbox customer so it can only be linked to a Stripe test mode account."
                : ""
            }`}
      </Body>
      <Input
        name="Stripe ID"
        placeholder="Enter Stripe customer ID"
        value={updatedStripeCustomerId ?? ""}
        onChange={setUpdatedStripeCustomerId}
        autoFocus
        success={data?.stripeCustomer?.name != null}
        error={
          updatedStripeCustomerId != null &&
          !loading &&
          data?.stripeCustomer?.name == null
            ? "Enter a valid Stripe ID"
            : undefined
        }
      />
      <Body level={2}>{data?.stripeCustomer?.name ?? ""}</Body>
      <Select
        name="Collection Method"
        options={[
          { label: "Charge automatically", value: "charge_automatically" },
          { label: "Send invoice", value: "send_invoice" },
        ]}
        onChange={setUpdatedStripeCollectionMethod}
        value={updatedStripeCollectionMethod ?? ""}
        placeholder="Select"
      />
    </Popup>
  );
};

export default StripeSettingsModal;
