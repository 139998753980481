import { USD_CREDIT_ID } from "lib/credits";
import { CreditType } from "types/credit-types";
import * as Sentry from "@sentry/react";

export const filterAndSortCreditTypes = (creditTypes: CreditType[]) => {
  const fiatCreditTypes =
    creditTypes
      .filter((ct) => ct.client_id === null && ct.environment_type === null)
      .sort((a, b) =>
        // USD first, then the rest alphabetized
        a.id === USD_CREDIT_ID
          ? -1
          : b.id === USD_CREDIT_ID
            ? 1
            : a.name.localeCompare(b.name),
      ) ?? [];
  const customCreditTypes =
    creditTypes
      .filter((ct) => ct.client_id !== null && ct.environment_type !== null)
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  return {
    fiatCreditTypes,
    customCreditTypes,
  };
};

export const findCreditType = (id: string, creditTypes: CreditType[]) => {
  const ct = creditTypes.find((ct) => ct.id === id);
  if (!ct) {
    Sentry.setContext("context", {
      creditTypeId: id,
      creditTypes: JSON.stringify(creditTypes, null, 2),
    });
    throw new Error(
      `Unable to find credit type ${id} in creditTypes array, this is unexpected`,
    );
  }
  return ct;
};

export const isFiat = (creditType: CreditType) => {
  if (creditType.client_id === null && creditType.environment_type === null) {
    return true;
  }
  return false;
};
